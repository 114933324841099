<h2 class="title">
  <p>{{ (replacement.track.singular | replacement:'do curso') | uppercase }}</p>
  {{ track?.title }}
</h2>
<div class="info">
  <p>
    <span>Duração Total</span><br>
    {{ formatedDuration }} h
  </p>
  <p>
    <span>Turma</span><br>
    {{ track?.studentsCount }} alunos
  </p>
  <p>
    <span>Notas atualizadas diariamente</span>
    <br>
    às 00:00h
  </p>
</div>
