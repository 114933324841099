<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      <img src="./assets/img/arrow-back.png" />
      Voltar
    </a>
    <h2 *ngIf="response">{{ response.userName }}</h2>
    <h2 *ngIf="response">{{ response.userRegisterId || '--' }}</h2>
  </nav>

  <div class="middle" *ngIf="response">
    <div class="actions">
      <div>
        <button class="btn-test" (click)="exportAnswers()" *ngIf="response.answers[0].type !== 3">
          Exportar Respostas
        </button>
        <button class="btn-test" (click)="download(response.answers[0].curseWorkFile['downloadLink'])"
          *ngIf="response.answers[0].type === 3">
          Baixar Trabalho
        </button>
      </div>
    </div>
    <div class="question" *ngFor="let item of response?.answers">
      <p>{{item.question}}
        ({{ item.type != 4 ? item.grade != null ? item.grade.toFixed(2) + '/' + item.percentage.toFixed(2) : 'valor: ' + item.percentage.toFixed(2) : ('NPS') }})
        {{item.canceled ? '(Anulada)': ''}}
      </p>
      <p></p>
      <p>{{ item.answer }}</p>
      <p *ngIf="item.type === 3"> Observações: {{ item.comments }}</p>
      <mat-form-field *ngIf="item.type !== 1 && item.type !== 4">
        <input matInput type="number" [disabled]="item.canceled" min="1"
          [placeholder]="item.type !== 3 ? 'Nota da Questão' : 'Nota do Trabalho'" [(ngModel)]="item.grade" required />
      </mat-form-field>
    </div>
    <div class="grade-actions">
      <button class="btn-test primary" (click)="gradeProfileTestAnswers()">
        Salvar
      </button>
    </div>
  </div>
</div>
