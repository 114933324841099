import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { UtilService } from 'src/app/shared/services/util.service';
import { CurriculumComponent } from './curriculum.component';

@NgModule({
  declarations: [
    CurriculumComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
  ],
  providers: [
    SettingsUsersService,
    UtilService
  ]
})
export class CurriculumModule { }
