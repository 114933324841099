import { Component, Directive, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { NotificationClass } from '../../classes/notification';
import { ExcelService } from '../../services/excel.service';
import { IErroList } from './Models/erro-list.interface';
import { IImportExcelFiles } from './Models/import-excel-files.interface';

export abstract class ImportExcelFilesComponent extends NotificationClass implements IImportExcelFiles {

  public readonly collums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  public abstract loading: boolean;
  public abstract erroList: IErroList[];
  public abstract readonly name: string;
  public abstract readonly subtitle: string;
  public abstract readonly showRadionButtons: boolean;
  public abstract readonly pathTemplate: string;
  public abstract readonly downloadName: string;
  public showDownloadTemplateButton: boolean = true;
  public startRow: number = null;
  public startCollumn: number = null;
  public headers: any[];
  private _mandatoryCollumns: string[];
  public get mandatoryCollumns(): string[] {
    if (this._mandatoryCollumns.length === 0) throw new Error('Necessário informar as colunas mandatórias');
    return this._mandatoryCollumns;
  }
  public set mandatoryCollumns(v: string[]) {
    this._mandatoryCollumns = v;
  }

  constructor(
    protected _excelService: ExcelService,
    protected _snackBar: MatSnackBar,
  ) {
    super(_snackBar);
  }
  public abstract dismiss(): void;
  public abstract validateJson(contentJson, headers): IErroList[];
  public abstract setValues(contentJson, headers): void;
  public abstract save(): void;

  public openFileUpload(): void {
    const input = document.getElementById('subjectsInputFile') as HTMLInputElement;
    input.value = '';
    input.click();
  }

  public setDocumentFile(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
        this.notify('Tipo de arquivo inválido. Apenas \'xls\', \'xlsx\' e \'csv\' são permitidos.');
        return;
      }

      this.loading = true;
      this._readExcel(file);
    }
  }

  private _readExcel(file) {
    const callback = this._importQuestion.bind(this);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let binary = '';
      const bytes = new Uint8Array(this.result as any);

      for (let i = 0; i < bytes.byteLength; i++)
        binary += String.fromCharCode(bytes[i]);

      callback(binary);
    };
    reader.readAsArrayBuffer(file);
  }

  private _importQuestion(file: string) {
    this._excelService._getExcelContentAsJson(
      file,
      this.mandatoryCollumns,
      this.startCollumn,
      this.startRow
    ).subscribe(response => {
      this.headers = response.headers;
      this.erroList = this.validateJson(response.contentJson, response.headers);
      if (this.erroList.length === 0) {
        this.setValues(response.contentJson, response.headers);
      }
      this.loading = false;
    }, error => {
      this.erroList = [{ description: error }];
      this.loading = false;
    });
  }

  public getCollum(header: string) {
    const collumn = this.headers.find(h => h.headear === this.normalizeString(header)).collumn;
    return isNil(collumn) ? collumn : this.collums[collumn];
  }

  public normalizeString(value: string) {
    if (isNil(value)) return null;

    return this._excelService.normalizeString(value);
  }

}
