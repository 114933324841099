import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatters, diff } from 'jsondiffpatch';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss']
})
export class LogsDialogComponent {
  @ViewChild('#Diff', { static: true }) private _diff: Element;

  public difference: string;
  public showUnchanged: boolean;

  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LogsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { newValues, oldValues, hideUnchanged },
  ) {

    this.showUnchanged = data.hideUnchanged || false;
    const delta = diff(data.oldValues ? data.oldValues : {}, data.newValues);

    if (!this.showUnchanged) {
      setTimeout(() => {
        this.difference = formatters.html.format(delta, data.newValues);
        formatters.html.hideUnchanged(this._diff);
      }, 0);
    } else {
      this.difference = '';
      setTimeout(() => {
        formatters.html.showUnchanged(this.showUnchanged, this._diff, 150);
      }, 0);
    }
  }

  public dismiss() {
    this.dialogRef.close();
  }

  public toggleDiff() {
    this.showUnchanged = !this.showUnchanged;
    setTimeout(() => {
      formatters.html.showUnchanged(this.showUnchanged, this._diff, 150);
    }, 0);
  }


}
