import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TrackDescription } from 'src/app/settings/tracks/track-overview/components/track-description/models/track-description.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-track-description',
  templateUrl: './track-description.component.html',
  styleUrls: ['./track-description.component.scss']
})
export class TrackDescriptionComponent {

  private _track: TrackDescription;
  @Input()
  public get track(): TrackDescription { return this._track; }
  public set track(track: TrackDescription) {
    this._track = track;
    this.formatedDuration = this._getFormattedHour(this.track);
  }

  public formatedDuration: string;
  public replacement = environment.replecement;

  constructor(private _utilService: UtilService) { }


  private _getFormattedHour(track: TrackDescription): string {
    if (!track || !track.duration) return '--';
    return this._utilService.formatSecondsToHourMinute(track.duration);
  }

}
