import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { EStudyPlanItem } from 'src/app/models/enums/study-plan-item.enum';
import { EStudyPlan } from 'src/app/models/enums/study-plan.enum';
import { Module } from 'src/app/models/module.model';
import { StudyPlan } from 'src/app/models/study-plan.model';
import { IRequestFilter } from 'src/app/pages/my-courses/Interface/request-filter';
import { IRequesrtSort } from 'src/app/pages/my-courses/Interface/request-sort';
import { UserCategoryFilterSearchOption } from '../manage-team/filters/filters.interface';

@Injectable({
    providedIn: 'root'
})
export class StudyPlanService {

    private _defaultParams = {
        getPagedFilteredUsersList: {
            studyPlanId: null,
            page: 1
            , pageSize: 10
            , searchValue: ''
            , blocked: false
            , sort: ''
            , sortAscending: false
            , categoryFilters: []
            , dependent: ''
        }
    };

    constructor(private _httpService: BackendService) {
    }

    public CreateStudyPlan(studyPlan: StudyPlan): Observable<any> {
        return this._httpService.post('CreateStudyPlan', studyPlan);
    }
    public UpdateStudyPlan(studyPlan: StudyPlan): Observable<any> {
        return this._httpService.put('UpdateStudyPlan', studyPlan);
    }
    public GetStudyPlanById(studyPlanId: string, pageUser: number, pageSizeUser: number): Observable<any> {
        return this._httpService.get('GetStudyPlanById', [], [
            { 'name': 'studyPlanId', 'value': studyPlanId },
            { 'name': 'pageUser', 'value': pageUser.toString() },
            { 'name': 'pageSizeUser', 'value': pageSizeUser.toString() },
        ]);
    }

    public GetAllStudyPlan(
        pageUser: number, pageSizeUser: number, searchValue: string, userId: string = '', notIn: string = ''): Observable<any> {
        return this._httpService.get('GetAllStudyPlan', [], [
            { 'name': 'page', 'value': pageUser.toString() },
            { 'name': 'pageSize', 'value': pageSizeUser.toString() },
            { 'name': 'name', 'value': searchValue.toString() },
            { 'name': 'userId', 'value': userId },
            { 'name': 'notIn', 'value': notIn }
        ]);
    }

    public AddStudents(studyPlanId: string, usersId: string[]): Observable<any> {
        return this._httpService.post('AddStudy', {
            studyPlanId,
            usersId
        });
    }

    public GetUsersByStudyPlanId(studyPlanId: string, pageUser: number, pageSizeUser: number, searchTerm: string): Observable<any> {
        return this._httpService.get('GetUsersByStudyPlanId', [], [
            { 'name': 'studyPlanId', 'value': studyPlanId },
            { 'name': 'pageUser', 'value': pageUser.toString() },
            { 'name': 'pageSizeUser', 'value': pageSizeUser.toString() },
            { 'name': 'searchTerm', 'value': searchTerm },
        ]);
    }
    public GetUsersPaged(args: {
        studyPlanId: string,
        page?: number,
        pageSize?: number,
        searchValue?: string,
        blocked?: boolean,
        sort?: string,
        sortAscending?: boolean,
        categoryFilters?: Array<UserCategoryFilterSearchOption>,
        dependent?: string,
        notIn?: string[],
    } = this._defaultParams.getPagedFilteredUsersList): Observable<BackendResponse<any>> {
        const filters = {
            name: args.searchValue,
            blocked: args.blocked,
            sortBy: args.sort,
            isSortAscending: args.sortAscending,
            categoryFilter: args.categoryFilters,
            dependent: args.dependent,
            notIn: args.notIn
        };
        return this._httpService.post('GetUsersPagedStudyPlan', {
            studyPlanId: args.studyPlanId,
            page: args.page,
            pageSize: args.pageSize,
            filters: filters
        });
    }

    public DeleteStudyPlan(studyPlanId: string): Observable<any> {
        return this._httpService.delete('DeleteStudyPlan', [
            { 'name': 'studyPlanId', 'value': studyPlanId }
        ]);
    }
    public DeleteAllStudyPlanUsers(studyPlanId: string): Observable<any> {
        return this._httpService.delete('DeleteAllStudyPlanUsers', [
            { 'name': 'studyPlanId', 'value': studyPlanId }
        ]);
    }
    public DeleteStudyPlanUser(studyPlanId: string, userId: string): Observable<any> {
        return this._httpService.delete('DeleteStudyPlanUser', [
            { 'name': 'studyPlanId', 'value': studyPlanId },
            { 'name': 'userId', 'value': userId}
        ]);
    }

    public GetMyJourney(
        page: number, pageSize: number, studyPlanType: EStudyPlan, sectors: IRequestFilter[], sort: IRequesrtSort
    ): Observable<any> {
        return this._httpService.post('GetMyJourney', {
            page,
            pageSize,
            studyPlanType,
            filters: {
                sectors: sectors
            },
            sort
        });
    }

    public GetInstitutionalTraining(page: number, pageSize: number, sectors: IRequestFilter[], sort: IRequesrtSort): Observable<any> {
        return this._httpService.post('GetInstitutionalTraining', {
            page,
            pageSize,
            filters: {
                sectors: sectors
            },
            sort
        });
    }

    public GetMandatoryTraining(page: number, pageSize: number, sectors: IRequestFilter[], sort: IRequesrtSort): Observable<any> {
        return this._httpService.post('GetMandatoryTraining', {
            page,
            pageSize,
            filters: {
                sectors: sectors
            },
            sort
        });
    }

    public GetAllCourseFiltered(page: number,
        pageSize: number,
        sectorId: string = null,
        sectors: IRequestFilter[],
        sort: IRequesrtSort,
        showUnavailable = true): Observable<any> {
        return this._httpService.post('getAllCourses', {
            page,
            pageSize,
            filters: {
                sectorId,
                sectors,
                showUnavailable
            },
            sort
        });
    }

    public GetMyFavoriteCourse(
        page: number, pageSize: number, sectorId: string = null, sectors: IRequestFilter[], sort: IRequesrtSort): Observable<any> {
        return this._httpService.post('getMyFavoriteCourses', {
            page,
            pageSize,
            filters: {
                sectorId,
                sectors
            },
            sort
        });
    }

    public GetMyLibrary(
        page: number, pageSize: number, sectors: IRequestFilter[], sort: IRequesrtSort): Observable<any> {
        return this._httpService.post('getMyLibrary', {
            page,
            pageSize,
            filters: {
                sectors
            },
            sort
        });
    }

    public ParseStudyPlanItem(courses: Array<any>): Array<any> {
        return courses.map(value => value.type === EStudyPlanItem.module
            ? new Module(value)
            : value);
    }

    public ImportPlanStudyBlocks(fileContent: string): Observable<any> {
        return this._httpService.post('importStudyPlanBlocks', {
          fileContent
        });
      }

}
