import { Injectable, EventEmitter } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export type Base64 = string;
@Injectable()
export class UploadService {

  public showLoader$ = new EventEmitter();
  public progress: number;
  public message: string;

  constructor(private http: HttpClient) { }

  public getLoaderSubject() {
    return this.showLoader$;
  }

  public setLoaderValue(value: boolean) {
    this.showLoader$.next(value);
  }

  public uploadFile(file: File | Base64, fileName?: string, contentType: string = null, folderPath: string = null): Observable<string> {

    const formData = new FormData();
    fileName = (fileName ? fileName : (file as File).name) || 'arquivo';
    file instanceof File ?
      formData.append(fileName, file, fileName) :
      formData.append(fileName, this.dataURItoBlob(file), fileName);

    formData.append('type', contentType);
    formData.append('folderPath', folderPath);

    const uploadReq = new HttpRequest('POST', environment.apiUrl + '/api/moduleDraft/sendFileToS3', formData, {
      reportProgress: true,
    });

    return new Observable(observer => {

      this.http.request<string>(uploadReq).subscribe(event => {

        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        }

        if (event.type === HttpEventType.Response) {
          if (event.status === 200) {
            this.message = event.body['data'].toString();
            observer.next(this.message);
          }
          observer.complete();
        }

      });
    });
  }


  private dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
}
