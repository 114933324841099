import { NgModule } from '@angular/core';
import { StatusTagComponent } from './status-tag.component';

@NgModule({
  declarations: [
    StatusTagComponent
  ],
  exports: [
    StatusTagComponent
  ]
})
export class StatusTagModule { }
