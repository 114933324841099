<ng-container *ngFor="let testTrack of test?.testTracks">
  <app-valuation-test-release-toggle [title]=testTrack.title [isClosed]="!testTrack.toggled"
    (toggle)="testTrack.toggled = !testTrack.toggled;"></app-valuation-test-release-toggle>
  <div class="bottom" [hidden]="testTrack.toggled">
    <ng-container *ngIf="test.type == 1">
      <mat-form-field style="width: 100%;">
        <input matInput type="number" min="0" max="100" placeholder="Aparição na trilha em quantos %"
          [(ngModel)]="testTrack.percent" required />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="test.type == 2">
      <mat-form-field style="width: 100%;">
        <mat-select [(ngModel)]="testTrack.order" placeholder="Selecione o item da trilha relacionado" required>
          <mat-option [value]="-1">Livre</mat-option>
          <mat-option *ngFor="let eventConfiguration of testTrack.eventsConfiguration"
            [value]="eventConfiguration.order">
            {{ eventConfiguration.title }}
          </mat-option>
          <mat-option *ngFor="let moduleConfiguration of testTrack.modulesConfiguration"
            [value]="moduleConfiguration.order">
            {{ moduleConfiguration.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="test.type == 3">
      <mat-form-field style="width: 100%;">
        <input matInput placeholder="Data de entrega" [ngxMatDatetimePicker]="picker"
          [(ngModel)]="testTrack.deliveryDate" />
        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSeconds]="true" [stepHour]="1" [stepMinute]="1" [stepSecond]="1">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngFor="let testModule of test?.testModules">
  <app-valuation-test-release-toggle [title]=testModule.title [isClosed]="!testModule.toggled"
    (toggle)="testModule.toggled = !testModule.toggled"></app-valuation-test-release-toggle>
  <div class="bottom" [hidden]="testModule.toggled">
    <ng-container *ngIf="test.type == 1">
      <mat-form-field style="width: 100%;">
        <input matInput type="number" min="0" max="100" placeholder="Aparição no módulo em quantos %"
          [(ngModel)]="testModule.percent" required />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="test.type == 2">
      <mat-form-field style="width: 100%;">
        <mat-select placeholder="Ordem de aparição no módulo" [(ngModel)]="testModule.type" required>
          <mat-option [value]="1">Pré módulo</mat-option>
          <mat-option [value]="2">Pós módulo</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="test.type == 3">
      <mat-form-field style="width: 100%;">
        <input matInput placeholder="Data de entrega" [ngxMatDatetimePicker]="pickerModule"
          [(ngModel)]="testModule.deliveryDate" />
        <mat-datepicker-toggle matPrefix [for]="pickerModule"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #pickerModule [showSeconds]="true" [stepHour]="1" [stepMinute]="1" [stepSecond]="1">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngFor="let testSchedules of test?.testSchedules">
  <app-valuation-test-release-toggle [title]="testSchedules.title" [isClosed]="!testSchedules.toggled"
    (toggle)="testSchedules.toggled = !testSchedules.toggled"></app-valuation-test-release-toggle>
  <div class="bottom" [hidden]="testSchedules.toggled">
    <ng-container *ngIf="test.type == 1">
      <mat-form-field style="width: 100%;">
        <input matInput type="number" min="0" max="100" placeholder="Aparição no módulo em quantos %"
          [(ngModel)]="testSchedules.percent" required />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="test.type == 2">
      <mat-form-field style="width: 100%;">
        <mat-select placeholder="Ordem de aparição no evento" [(ngModel)]="testSchedules.type" required>
          <mat-option [value]="-1">Diagnostica</mat-option>
          <mat-option [value]="2">Somativa</mat-option>
          <mat-option [value]="3">Inscreva-se aqui</mat-option>
          <mat-option [value]="4">NPS</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>
