<div class="user-summary">
  <div class="personal">
    <img [src]="user?.imageUrl ? user?.imageUrl : './assets/img/user-image-placeholder.png'">
    <h3>{{ user?.name ? user.name : '-'}}</h3>
    <p>Data de admissão: {{ user?.admissionDate ? (user.admissionDate | date: 'dd/MM/yyyy') : '-' }} </p>
    <p>Matrícula: {{ user?.registrationId ? user.registrationId : '-' }}</p>
    <p>Info: {{ user?.info ? user.info : '-' }}</p>
  </div>

  <div class="knowledge">
    <p class="sub-title">CONHECIMENTO ADQUIRIDO</p>
    <div class="chips" [ngClass]="{ 'hide': !showAllTagUsers }">
      <app-concept-tag *ngFor="let concept of user?.acquiredKnowledge; let index = index" [concept]="concept.name"
        [color]="progressService.getLevelColor(concept.level)">
      </app-concept-tag>
    </div>
    <p class="view-all" (click)="showAllTagUsers = !showAllTagUsers">
      {{ showAllTagUsers ? 'ocultar' : 'ver todos' }}
    </p>
    <h5 *ngIf="user?.acquiredKnowledge?.length === 0">Não iniciado
    </h5>
  </div>

  <div class="actions">
    <p>AÇÕES DISPONÍVEIS</p>
    <button class="update" (click)="manageUser()">
      Atualizar informações
    </button>
    <button *ngIf="hasFeatureUserCarrer" class="update-prof" (click)="editUserCareer()">
      Atualizar perfil profissional
    </button>
    <button class="block" *ngIf="cacheUser && cacheUser.role !== 'Student'" (click)="blockUser()">
      {{ user?.isBlocked ? 'Desbloquear' : 'Bloquear' }}
    </button>

    <a (click)="goToRecomendation()" *ngIf="hasFeatureRecruitment">
      <button class="rec">
        Ver card de recomendação
      </button>
    </a>
    <a [href]="linkedIn" *ngIf="hasLoginLinkedin">
      <button class="linkedIn">
        Associar conta ao linkedIn
      </button>
    </a>
  </div>

</div>
