import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm.dialog';


export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    constructor(private _dialog: MatDialog) {
    }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> | Promise<boolean> {
        const canDeactive = component.canDeactivate();
        return canDeactive ?
            true :

            this.openDialog();
    }


    public openDialog(): Promise<boolean> {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: { message: 'Você possui alterações não salvas. Tem certeza que deseja deixar essa página sem salvar suas alterações ?' }
        });

        return dialogRef.afterClosed()
            .toPromise()
            .then((result: boolean) => result);
    }
}
