import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { orderBy } from 'lodash';
import { CockpitService } from 'src/app/pages/_services/cockpit.service';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { Eselect, ImetricsStudents, Istudents, IstudentsRanking, ItrackInfo } from '../../models/trackStudentsSubordinates';

@Component({
  selector: 'app-cockpit-module-class',
  templateUrl: './cockpit-module-class.component.html',
  styleUrls: ['./cockpit-module-class.component.scss'],
})
export class CockpitModuleClassComponent implements OnInit {
  public moduleId: string;
  public studentsRanking: Array<IstudentsRanking> = [];
  public studentsMetrics: ImetricsStudents = {} as ImetricsStudents;

  constructor(
    public _cockpitService: CockpitService,
    public _moduleService: ContentModulesService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
   this.moduleId = this._activatedRoute.snapshot.params['moduleId'];
    this._cockpitService.setSelectedStudents(Eselect.AllStudents);
    this._cockpitService.getStudents().subscribe(students => {
      const studentsIds =  students.map((student) => student.id);
      this.loadModule(this.moduleId, studentsIds);
    });
  }

  public goToStudent(student: Istudents) {
    this._cockpitService.setSelectedStudents(student);
    const selectedTrack = this._cockpitService.getSelectedTrack().getValue();
    this._router.navigate(['empenho-desempenho/students/module/' + selectedTrack.id + '/' + student.id + '/' + this.moduleId]);
  }

  public loadModule(moduleId: string, studentsIds: string[]) {
    const selectedTrack = this._cockpitService.getSelectedTrack().getValue();

    if (studentsIds === undefined || selectedTrack === undefined) return;

    this._moduleService.getCockpitModuleMetrics(moduleId, studentsIds, selectedTrack.id).subscribe((response) => {
      this._cockpitService.setTitle(response.data.title);
      this._loadStudentsRanking(response.data.students);
      this.studentsMetrics = this._cockpitService.setStudentsModuleMetrics(response.data);
    });
  }
  private _loadStudentsRanking(students: Array<any>): void {
    this.studentsRanking = [];
    this._cockpitService
      .getStudents()
      .getValue()
      .forEach((student) => {
        students.forEach((stdranking) => {
          if (student.id === stdranking.id) {
            const ranking: IstudentsRanking = {
              id: student.id,
              name: student.name,
              email: student.email,
              grade: stdranking.grade,
              ranking: stdranking.ranking,
              learning: stdranking.learning,
              averageEventGrade: stdranking.averageEventGrade,
              interactionUntilDay: stdranking.interactionUntilDayinHours || 0,
              interactionUntilDayInHours: stdranking.interactionUntilDayInHours || 0,
              progressUntilDay: stdranking.progressUntilDay || 0,
              diagnostic: stdranking.diagnostic || 0,
              valuation: stdranking.valuation || 0,
            };

            this.studentsRanking.push(ranking);
          }
        });
      });

    this.studentsRanking = orderBy(this.studentsRanking, ['grade'], ['desc']);
  }
}
