export enum mandatoryCollumnEnum {
    id = 'idquestao',
    type = 'tipo',
    text = 'questaoresposta',
    answareOrWeight = 'nivelvalorresposta',
    concepts = 'conceitoscorretosnaresposta',
    subjectId = 'assuntoid',
    time = 'tempo',
    comment = 'comentario'
}
