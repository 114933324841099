import { Component, EventEmitter, Output, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-list-search-items',
  template: `
    <ul class="user-list" *ngIf="dataSource && dataSource.length > 0" (clickOutside)="resetSearch()">
              <li *ngFor="let data of dataSource" (click)="add(data)">
                <span>{{ data[propListName] }}</span>
              </li>
            </ul>
            <div class="chips" *ngIf="selectedData && selectedData.length > 0"
              [ngClass]="{ 'hide': !showAllselectedEnrolledTracks }">
              <p *ngFor="let data of selectedData">
                {{ data[propListName]  }}
                <span (click)="remove(data)">X</span>
              </p>
            </div>
            <p class="view-all" *ngIf="selectedData && selectedData.length > 4"
              (click)="showAllSelectedDataSource = !showAllSelectedDataSource">
              {{ showAllSelectedDataSource ? 'ocultar' : 'ver todos' }}
            </p>`,
  styleUrls: ['./list-search-items.component.scss']
})
export class ListSearchItemsComponent {


  @Input() dataSource: any[] = [];
  private _dataSelected: any[] = [];
  @Input()
  public get dataSelected(): any[] {
    return this._dataSelected;
  }
  public set dataSelected(value: any[]) {
    if (value) {
      this._dataSelected = value;
      this.selectedData = value;
    }

  }
  @Input() propListName: string = 'title';
  @Output() selectData: EventEmitter<any> = new EventEmitter();

  public selectedData: any = [];
  public showAllSelectedDataSource: boolean = false;

  public add(data: any) {
    const userExists = this.selectedData.find(u => u.id === data.id);
    if (!userExists)
      this.selectedData.push(data);
    this.resetSearch();
    this.selectData.emit(this.selectedData);
  }

  public remove(item: any) {
    const index = this.selectedData.findIndex(x => x.id === item.id);
    this.selectedData.splice(index, 1);
    this.selectData.emit(this.selectedData);
  }

  public resetSearch(): void {
    (<HTMLInputElement>document.getElementById('list-search-input')).value = '';
    this.dataSource = [];
  }


}
