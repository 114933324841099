import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { differenceInSeconds, isValid } from 'date-fns';
import { chain, cloneDeep, differenceWith, isArray, isEmpty, isEqual, isNil } from 'lodash';
import { forkJoin } from 'rxjs';
import { Program } from 'src/app/settings/programs/Models/program.model';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { NotificationClass } from 'src/app/shared/classes/notification';

export enum EProgramItem {
  Track = 1,
  Module,
  Event,
  Valuation,
}

@Component({
  selector: 'app-settings-program-items',
  templateUrl: './program-items.component.html',
  styleUrls: ['./program-items.component.scss']
})
export class ProgramItemsComponent extends NotificationClass implements OnInit {


  private _form: FormGroup = this._manageForm();
  public get form(): FormGroup { return this._form; }
  public set form(value: FormGroup) {
    this._form = value;
    this.formChange.emit(this.form);
  }
  @Output() public formChange = new EventEmitter<FormGroup>();


  private _value: Partial<Program>;
  @Input()
  public get value(): Partial<Program> { return this._value; }
  public set value(value: Partial<Program>) {
    this._value = value;
    this.form = this._manageForm(value.items);
  }

  public EProgramItem = EProgramItem;
  public items: any[] = [];
  public selectedItems: any[] = [];
  public hasClickedOnExpansionPanel = false;
  public isLoadingItems = false;

  public programItemType = {
    [EProgramItem.Event]: 'Evento',
    [EProgramItem.Module]: 'Módulo',
    [EProgramItem.Valuation]: 'Avaliação',
    [EProgramItem.Track]: 'Trilha'
  };

  private get _programItems(): FormArray {
    return this.form.get('programItems') as FormArray;
  }
  private _typeNameId = {
    [EProgramItem.Event]: 'eventId',
    [EProgramItem.Module]: 'moduleId',
    [EProgramItem.Valuation]: 'testId',
    [EProgramItem.Track]: 'trackId'
  };

  private _temporarySelectedItems: any[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _tracksService: SettingsTracksService
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadItems();
  }

  public hasConfigurations(index: number) {
    const selectedItem = this.selectedItems[index];
    return selectedItem && (selectedItem.modulesConfiguration || []).length ||
      (selectedItem.eventsConfiguration || []).length ||
      (selectedItem.valuationConfiguration || []).length;
  }

  public handleSearchItem(itemName: string) {
    this._loadItems(itemName);
  }

  public handleSelectionItemChange(evt: any[]) {
    const items = isArray(evt) ? [...evt] : [evt];
    this._temporarySelectedItems = items;
  }

  public handleSelectOpenedChange(isOpened: boolean) {
    if (!isOpened) {
      const updatedItens = chain(this.selectedItems)
        .concat(...this._temporarySelectedItems)
        .filter(item => item.id)
        .uniqBy(item => item.id)
        .forEach((item, index) => item.order = isNil(item.order) ? index + 1 : item.order)
        .orderBy(item => item.order, 'asc')
        .value();

      const itensToFormUpdate = differenceWith(updatedItens, this.selectedItems, isEqual);

      this._manageForm(itensToFormUpdate);

      this.selectedItems = [...updatedItens];
    }
    this._temporarySelectedItems = [];
  }

  public handleDroppedItem(evt: CdkDragDrop<any[]>) {

    this.selectedItems = cloneDeep(this._programItems.getRawValue());
    moveItemInArray(this.selectedItems, evt.previousIndex, evt.currentIndex);
    this.selectedItems.forEach((selectedItem, index) => selectedItem.order = index + 1);

    this._programItems.clear();
    this._createFormItems(this.selectedItems).forEach(item => this._programItems.push(item));
    this._programItems.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  public removeItem(itemIndex: number, item: any) {
    if (itemIndex !== -1) {
      this.selectedItems.splice(itemIndex, 1);
      const formArray = this._programItems;
      formArray.removeAt(itemIndex);
    }
  }

  private _manageForm(programItems: any[] = []) {

    const formItems = this._createFormItems(programItems);

    if (this.form) {
      if (!isEmpty(formItems)) {
        formItems.forEach(item => this._programItems.push(item));
        this.selectedItems = this._programItems.getRawValue();
      }
      return this.form;
    } else {
      return this._formBuilder.group({
        programItems: this._formBuilder.array(formItems),
        messageErros: [[], Validators.maxLength(0)]
      });
    }

  }

  private _createFormItems(programItem: any[]) {
    const formValues = programItem.map(item => this._formBuilder.group({
        id: this._formBuilder.control(item.id),
        title: this._formBuilder.control(item.title),
        order: this._formBuilder.control(item.order),
        type: this._formBuilder.control(item.type),
        eventsConfiguration: this._formBuilder.array(
          !!item.eventsConfiguration ?
            item.eventsConfiguration.map(config => this._createFormItemConfigurations(config, EProgramItem.Event)) : []
        ),
        modulesConfiguration: this._formBuilder.array(
          !!item.modulesConfiguration ?
            item.modulesConfiguration.map(config => this._createFormItemConfigurations(config, EProgramItem.Module)) : []
        ),
        valuationsConfiguration: this._formBuilder.array(
          !!item.valuationsConfiguration ?
            item.valuationsConfiguration.map(config => this._createFormItemConfigurations(config, EProgramItem.Valuation)) : []
        ),
      })) ;

    return isEmpty(programItem) ? [] : formValues;
  }

  private _createFormItemConfigurations(item: any = {}, typeConfig: EProgramItem): FormGroup {
    const getValue = (prop: string) => isNil(item[prop]) ? null : item[prop];
    const alwaysAvailable = getValue('alwaysAvailable');

    return this._formBuilder.group({
      [this._typeNameId[typeConfig]]: [getValue(this._typeNameId[typeConfig])],
      type: [typeConfig],
      title: [getValue('title')],
      alwaysAvailable: [alwaysAvailable],
      openDate: [{ value: getValue('openDate'), disabled: alwaysAvailable }],
      cutOffDate: [{ value: getValue('cutOffDate'), disabled: typeConfig === EProgramItem.Event || alwaysAvailable }],
      valuationDate: [{ value: getValue('valuationDate'), disabled: alwaysAvailable }]
    });
  }

  private _loadItems(itemName: string = ''): void {
    const PAGE_SIZE = 10;
    this.isLoadingItems = true;
    forkJoin({
      tracks: this._tracksService.getPagedFilteredTracksList(null, PAGE_SIZE, itemName)
    }).subscribe(response => {
      const tracks = response.tracks.data.tracks;
      this.isLoadingItems = false;

      const trackItems = this._setupProgramTrackItems(tracks);

      this.items = [...trackItems];
    });

  }


  public toggleModuleAvailability(programItemIndex: number, itemIndex: number, configControl: AbstractControl) {

    const updateConfigurations = { emitEvent: true, onlySelf: true };
    const configType = configControl.get('type').value as EProgramItem;

    const typeConfigurations = {
      [EProgramItem.Event]: 'eventsConfiguration',
      [EProgramItem.Module]: 'modulesConfiguration',
      [EProgramItem.Valuation]: 'valuationsConfiguration',
    };

    const item = this._programItems.
      at(programItemIndex)
      .get(typeConfigurations[configType])
      .get(itemIndex.toString());

    const alwaysAvailable = item.get('alwaysAvailable');
    const openDate = item.get('openDate');
    const cutOffDate = item.get('cutOffDate');
    const valuationDate = item.get('valuationDate');
    const itemType = item.get('type').value;

    alwaysAvailable.patchValue(!alwaysAvailable.value);
    if (alwaysAvailable.value) {
      openDate.setValue(null);
      cutOffDate.setValue(null);
      valuationDate.setValue(null);
      openDate.disable(updateConfigurations);
      cutOffDate.disable(updateConfigurations);
      valuationDate.disable(updateConfigurations);
    } else {
      openDate.enable(updateConfigurations);
      if (itemType !== EProgramItem.Event) cutOffDate.enable(updateConfigurations);
      valuationDate.enable(updateConfigurations);
    }

    openDate.updateValueAndValidity(updateConfigurations);
    cutOffDate.updateValueAndValidity(updateConfigurations);
    valuationDate.updateValueAndValidity(updateConfigurations);
    alwaysAvailable.updateValueAndValidity(updateConfigurations);
  }

  public getInvalidConfigurationsDates(trackModulesEvents: Array<any>) {

    const items = trackModulesEvents
      .filter(item => item.type === EProgramItem.Track)
      .flat()
      .map(item => {

        item.modulesConfiguration.forEach(config => config.trackTitle = item.title);
        item.eventsConfiguration.forEach(config => config.trackTitle = item.title);
        item.valuationsConfiguration.forEach(config => config.trackTitle = item.title);

        return [
          ...item.modulesConfiguration,
          ...item.eventsConfiguration,
          ...item.valuationsConfiguration,
        ];
      })
      .flat();

    const filteredItens: any[] = items.filter(item => !item.alwaysAvailable);

    return this._checkDates(filteredItens) || [];


  }

  private _checkDates(trackModulesEvents: Array<any>) {

    const errors = [];

    trackModulesEvents.forEach(item => {
      const start = new Date(item.openDate);
      const end = new Date(item.cutOffDate || item.valuationDate);

      const itemTypeText = {
        [EProgramItem.Module]: `O módulo ${item.title}`,
        [EProgramItem.Valuation]: `A avaliação ${item.title}`,
        [EProgramItem.Event]: `O evento ${item.title}`
      };

      const errorMessage = `${itemTypeText[item.type]} da trilha "${item.trackTitle}" contém uma configuração de data inválida.`;

      if (!this._checkDate(start, end) || !this._checkDate(start, end) || !isValid(start)) {
        errors.push(errorMessage);
      } else if (item.cutOffDate && item.valuationDate && differenceInSeconds(item.cutOffDate, item.valuationDate) > 0) {
        errors.push(`${errorMessage}\nA data de corte não pode ser maior que a data de término.\n`);
      }
    });


    return errors;
  }

  private _setupProgramTrackItems(tracks: any[]) {
    const getConfiguration = (config, type: EProgramItem) => {
      const clone = cloneDeep(config);
      const hasInvalidDate = clone.alwaysAvailable === false && isNil(clone.openDate);

      clone[this._typeNameId[type]] = config[this._typeNameId[type]];
      clone.type = type;
      clone.valuationDate = hasInvalidDate ? null : clone.valuationDate;
      clone.cutOffDate = hasInvalidDate ? null : clone.cutOffDate;
      clone.alwaysAvailable = isNil(clone.alwaysAvailable) || hasInvalidDate || clone.alwaysAvailable;
      return clone;
    };

    return (tracks || []).map(track => {
      let modulesConfiguration = [];
      let eventsConfiguration = [];
      let valuationsConfiguration = { modules: [], tracks: [] };

      if (track && track.modulesConfiguration) {
        const modules = cloneDeep(track.modulesConfiguration);
        modulesConfiguration = modules.map((trackModule: any) => getConfiguration(trackModule, EProgramItem.Module));
      }

      if (track && track.eventsConfiguration) {
        const events = cloneDeep(track.eventsConfiguration);
        eventsConfiguration = events.map((trackEvent: any) => getConfiguration(trackEvent, EProgramItem.Event));
      }

      if (track && track.valuations) {
        valuationsConfiguration = cloneDeep(track.valuations);
        valuationsConfiguration.modules = valuationsConfiguration.modules || [];
        valuationsConfiguration.tracks = valuationsConfiguration.tracks || [];

        valuationsConfiguration.modules = valuationsConfiguration.modules.map((valuation: any) =>
          getConfiguration(valuation, EProgramItem.Valuation));
        valuationsConfiguration.tracks = valuationsConfiguration.tracks.map((valuation: any) =>
          getConfiguration(valuation, EProgramItem.Valuation));
      }

      let items: Array<any> = [...modulesConfiguration, ...eventsConfiguration];

      if (!isNil(valuationsConfiguration)) {
        items.push(...valuationsConfiguration.modules, ...valuationsConfiguration.tracks);
      }

      items = items.sort((a, b) => a.order - b.order);
      return {
        id: track.id,
        order: track.order,
        title: track.title,
        type: EProgramItem.Track,
        modulesConfiguration: modulesConfiguration || [],
        eventsConfiguration: eventsConfiguration || [],
        valuationsConfiguration: [...valuationsConfiguration.modules, ...valuationsConfiguration.tracks] || [],
      };
    });
  }

  private _checkDate(fromDate: Date, toDate: Date): boolean {
    if (fromDate && toDate) {
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      if (fromDate > toDate) {
        return false;
      } else {
        return true;
      }
    } else if (fromDate) {
      return true;
    } else {
      return false;
    }
  }
}
