import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation-back',
  template: `
    <nav>
      <a href="javascript:history.back()">
        <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
        voltar
      </a>
    </nav>
  `,
  styleUrls: ['./navigation-back.component.scss']
})
export class NavigationBackComponent {

  constructor() { }

}
