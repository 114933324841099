import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';

@Component({
  selector: 'app-users-selected',
  templateUrl: './users-selected.component.html',
  styleUrls: ['./users-selected.component.scss']
})
export class UsersSelectedComponent implements OnInit {

  public page: number = 1;
  public pageSize: number = 10;
  public users: UserPreview[] = [];
  public itemsCount: number = 0;
  public readonly displayedColumns: string[] = [
    'name', 'userName', 'registerId', 'email'
  ];


  constructor(
    public dialogRef: MatDialogRef<UsersSelectedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[],
    private _settingsUsersService: SettingsUsersService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this._loadUsers();
    }, 0);
  }

  public goToPage(page: number) {
    if (page !== this.page) {
      this.page = page;
      this._loadUsers();
    }
  }

  public _loadUsers() {
    this._settingsUsersService.getPagedFilteredUsersList(
      { page: this.page, pageSize: this.pageSize, in: this.data })
      .subscribe(response => {
        this.users = response.data.userItems;
        this.itemsCount = response.data.itemsCount;
      });
  }
}
