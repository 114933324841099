import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOptionSelectionChange } from '@angular/material/core';
import { debounceTime, startWith } from 'rxjs/operators';
import { CategoryFilterOption, CategoryFilter } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy {


  private _filter: CategoryFilter;
  @Input()
  public get filter(): CategoryFilter { return this._filter; }
  public set filter(filter: CategoryFilter) {
    this._filter = filter;
  }

  @Input() hint: string;

  @Output() change = new EventEmitter<CategoryFilterOption>();
  @Output() remove = new EventEmitter<CategoryFilterOption>();
  @Output() triggerSearch: EventEmitter<string> = new EventEmitter();

  @ViewChild('AutoComplete') autocomplete: MatAutocomplete;
  @ViewChild('AutocompleteInput') autocompleteInput: ElementRef;

  public searchOptions = new Subject<CategoryFilterOption[]>();
  public separatorKeysCodes = [ENTER, COMMA];
  public formControl = new FormControl();

  private _valueChangesSub: Subscription;
  private _searchSubject: Subject<string> = new Subject();

  public displayLabels = (description: string): string => {
    const descriptions = this.filter.selectedOptions.map(option => option.description);
    return descriptions.length > 2 ?
      `${descriptions[descriptions.length - 1]} e mais ${descriptions.length - 1} outros`
      : descriptions.join(' , ');
  };

  ngOnInit() {
    this._setSearchSubscription();
  }

  ngOnDestroy() {
    if (this._valueChangesSub)
      this._valueChangesSub.unsubscribe();
    if (this._searchSubject)
      this._searchSubject.unsubscribe();
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  public optionSelectionChange(option: CategoryFilterOption, matEvent: MatOptionSelectionChange) {
    if (matEvent.isUserInput) {
      this.setFilters(this.filter, option);
      this.change.emit(option);
    }
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  public setFilters(filter: CategoryFilter, option: CategoryFilterOption) {
    option.checked = this.filter.selectedOptions.findIndex(x => x.id === option.id) === -1;
    const index = filter.selectedOptions.findIndex(x => x.id === option.id);
    if (filter.isSingleOption) {
      filter.selectedOptions = [option];
    } else {
      index === -1 ?
        filter.selectedOptions.push(option)
        : filter.selectedOptions.splice(index, 1);
    }
  }

  public removeOption(option: CategoryFilterOption): void {
    const index = this.filter.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.filter.selectedOptions.splice(index, 1);
    }
    this.remove.emit(option);

  }


  private _setSearchSubscription() {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.triggerSearch.emit(searchValue);
    });
  }


}
