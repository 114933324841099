import { Component, OnInit, ViewChild, Input, QueryList, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackOverview, TrackItem } from 'src/app/pages/instructor/components/module-tracks-table/models/module-track.model';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-module-tracks-table',
  templateUrl: './module-tracks-table.component.html',
  styleUrls: ['./module-tracks-table.component.scss']
})
export class ModuleTracksTableComponent implements OnInit {

  @ViewChild('Table', { static: true }) private _table: DatatableComponent;

  public data: TrackItem[];
  public replacement = environment.replecement;
  private _tracks: TrackOverview;
  @Input()
  public get tracks(): TrackOverview { return this._tracks; }
  public set tracks(tracks: TrackOverview) {
    this._tracks = tracks;
    if (tracks) this.data = this.tracks.trackItems;
  }

  public isLoading: boolean;
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public messages = {
    emptyMessage: 'Buscando dados...', totalMessage: 'Total', selectedMessag: 'selecionado',
  };


  private _moduleId: string;

  public rowIdentity = (row) => row.id;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this._moduleId = this._activatedRoute.snapshot.paramMap.get('moduleId');
    this.isLoading = true;
  }



  public goToTrackDetail(event) {
    if (event.type === 'click') {
      const track = (event.row as TrackItem);
      this._router.navigate([`instrutor/modulo/${this._moduleId}/${track.id}`], { state: { track } });
    }
  }


}
