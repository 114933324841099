import { Component, OnInit } from '@angular/core';
import { CockpitService } from 'src/app/pages/_services/cockpit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { of } from 'rxjs';
import { IeffortPerformance, IstudentMetric, IeffortMetric, IstudentProgress } from '../../models/studentMetrics';
import { isEmpty, isNil } from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { TrackModule } from 'src/app/models/track-module.model';

@Component({
  selector: 'app-cockpit-student-track',
  templateUrl: './cockpit-student-track.component.html',
  styleUrls: ['./cockpit-student-track.component.scss']
})
export class CockpitStudentTrackComponent implements OnInit {

  public trackId: string;
  public studentId: string;
  public studentTrack: IstudentProgress = {} as IstudentProgress;
  public effortPerformance: IeffortPerformance = {} as IeffortPerformance;
  public effortMetric: IeffortMetric = {} as IeffortMetric;
  public studentMetric: IstudentMetric;
  public classMetrics: any;
  constructor(
    public _activatedRoute: ActivatedRoute,
    public _tracksService: ContentTracksService,
    public _cockpitService: CockpitService,
    public _router: Router
  ) { }

  ngOnInit() {
    this.studentId = this._activatedRoute.snapshot.params['studentId'];
    this._activatedRoute.queryParams.subscribe(params => {
        this._cockpitService.getSelectedTrack().pipe(
        ).subscribe(track => {
          if (!isEmpty(track)) {
            this.trackId = track.id;
            const student = track.students.find(stdn => stdn.id === this.studentId);
            this._cockpitService.setTitle(track.title);
            this._cockpitService.setSelectedStudents(student);
            this.loadStudentsMetrics(this.trackId, this.studentId);
          }
        });
    });
  }

  public loadStudentsMetrics(trackId: string, studentId: string) {
    this._tracksService.getCockpitTrackMetricsStudentCurrent(
      trackId,
      studentId
    ).subscribe((response) => {
      this.studentMetric = response.data;
      this.studentTrack.moduleConfigurations = response.data.moduleConfigurations;

      this._cockpitService.getStudentsMetrics().pipe(
        takeWhile(() => !this.classMetrics)
      ).subscribe(classMetrics => {
        if (!isEmpty(classMetrics)) {
         this.mountStudentMetrics(classMetrics);
        }
      });
    });
  }

  public mountStudentMetrics(classMetrics: any) {

   const student = this._cockpitService.getStudentsRankings()
    .find(s => s.id === this.studentId);

    this.studentTrack.ranking = !isNil(student) ? student.ranking : 0;

    const top10 = {
      averageDiagnostic: classMetrics.averageDiagnosticTestTop10 || 0,
      averageEvent: classMetrics.averageEventsTop10 || 0,
      averageValuation: classMetrics.averageTestTop10 || 0,
    };
    const students = {
      averageDiagnostic: classMetrics.averageDiagnosticTest || 0,
      averageEvent: classMetrics.averageEventsTop10 || 0,
      averageValuation: classMetrics.averageTest || 0,
    };

    this.effortPerformance = {
      top10: top10,
      class: students,
      student: this.studentMetric
    };

    this.effortMetric = {
      studentProgressUntilDay: this.studentMetric.progressUntilDay || 0,
      studentInteractionUntilDay: !isNil(student) ? student.interactionUntilDay || 0 : 0,
      studentInteractionUntilDayInHours: !isNil(student) ? student.interactionUntilDayInHours || 0 : 0,
      studentParticipationUntilDay: this.studentMetric.participationUntilDay || 0,
      classProgressUntilDay:  classMetrics.engagement.real,
      classInteractionUntilDay: classMetrics.effort.real,
      classInteractionUntilDayInHours: classMetrics.effort.realInHours,
      classParticipationUntilDay: classMetrics.participation.real,
      expectedProgressUntilDay: classMetrics.engagement.expected || 0,
      expectedInteractionUntilDay: classMetrics.effort.expected  || 0,
      expectedInteractionUntilDayInHours: classMetrics.expectedInteractionUntilDayInHours || 0
    };
  }

  public goToModule(module: TrackModule) {
    this._cockpitService.setTitle(module.title);
    this._router.navigate(['empenho-desempenho/students/module/' + this.trackId + '/' + this.studentId + '/' + module.moduleId]);
  }
}
