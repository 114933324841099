import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Module, ModuleQuestion, ModuleQuestionAnswer, ModuleQuestionValuation } from 'src/app/models/module.model';
import { Subject } from 'src/app/models/subject.model';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-module-new-valuation',
  templateUrl: './module-new-valuation.component.html',
  styleUrls: ['./module-new-valuation.component.scss'],
})
export class ModuleNewValuationComponent extends NotificationClass implements OnInit {

  @Input() readonly subject: Subject;
  @Input() readonly moduleQuestion: ModuleQuestion;
  @Output() next = new EventEmitter<any>();
  @Output() goBack = new EventEmitter();

  public valuation: ModuleQuestionValuation;
  constructor(protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  ngOnInit() {
    this.valuation = {
      textValuation: 5,
      answersValuation: 5,
      conceptsValuation: 5,
      conclusion: false,
      conclusionText: ''
    };
  }

  public changeTextValuation(value: number) {
    this.valuation.textValuation = value;
  }

  public changeAnswersValuation(value: number) {
    this.valuation.answersValuation = value;
  }

  public getSubjectConcepts(): string[] {
    const concepts: any[] = this.moduleQuestion.concepts;
    return concepts.map(x => x.name);
  }

  public getAnswerConceptStatus(answer: ModuleQuestionAnswer, concept: string): string {
    const answerConcepts: any[] = answer.concepts;
    const concepts = answerConcepts.map(x => x.name);
    if (concepts.some(x => x === concept)) {
      return './assets/img/denied.png';
    } else {
      return './assets/img/approved.png';
    }
  }

  public changeConceptsValuation(value: number) {
    this.valuation.conceptsValuation = value;
  }

  public getValuationName(valuation: number) {
    switch (valuation) {
      case 0:
      case 1:
        return 'Péssimo';
      case 2:
      case 3:
        return 'Ruim';
      case 4:
      case 5:
        return 'Razoável';
      case 6:
      case 7:
        return 'Bom';
      case 8:
      case 9:
        return 'Muito bom';
      case 10:
        return 'Excelente';
      default:
        return 'Razoável';
    }
  }

  public save() {
    if (this.valuation.conclusion !== true && this.valuation.conclusion !== false) {
      this.notify('Preencha todos os campos obrigatórios para adicionar a questão');
      return;
    }
    if (!this.valuation.conclusionText || this.valuation.conclusionText.trim() === '') {
      this.notify('Preencha o campo de justificativa da conclusão');
      return;
    }
    this.next.emit(this.valuation);
  }
}
