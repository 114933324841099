<div class="module-info step">
  <h2>Informações do Módulo</h2>
  <form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="two-columns">
      <div class="validation-field">
        <mat-form-field>
          <mat-select placeholder="Tipo de Módulo" formControlName="published" required>
            <mat-option [value]="false">Fechado</mat-option>
            <mat-option [value]="true">Aberto</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="validation-field" style="margin-top: -18px">
        <label>Instrutores *</label>
        <app-list-search placeholder="Digite o nome de um usuário para adicioná-lo como instrutor *" [noPadding]="true"
          (triggerSearch)="triggerUserSearch($event)"></app-list-search>
        <ul class="user-list" *ngIf="instructors && instructors.length > 0" (clickOutside)="resetInstructorSearch()">
          <li *ngFor="let instructor of instructors" (click)="addInstructor(instructor)">
            <img class="logo"
              [src]="instructor.imageUrl ? instructor.imageUrl : './assets/img/user-image-placeholder.png'" />
            <span>{{ instructor.name }}</span>
          </li>
        </ul>
        <div class="chips" *ngIf="selectedInstructors && selectedInstructors.length > 0">
          <p *ngFor="let instructor of selectedInstructors">
            <img class="logo"
              [src]="instructor.imageUrl ? instructor.imageUrl : './assets/img/user-image-placeholder.png'" />
            {{ instructor.name }}
            <span (click)="removeSelectedInstructor(instructor)">X</span>
          </p>
        </div>
      </div>
    </div>
    <div class="two-columns">
      <div class="validation-field">
        <mat-form-field>
          <mat-select placeholder="Disponibilidade" formControlName="availability" required>
            <mat-option [value]="courseAvailabilityEnum.available">Disponível</mat-option>
            <mat-option [value]="courseAvailabilityEnum.unavailable">Indisponível</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" placeholder="Valido por quantos dias?" formControlName="validFor" />
        </mat-form-field>
        <mat-form-field style="margin-top: 8px">
          <mat-select placeholder="Possui criação/avaliação de questões obrigatorias"
            formControlName="questionCreationValuation" required>
            <mat-option [value]="true">Sim</mat-option>
            <mat-option [value]="false">Não</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="margin-top: 8px" *ngIf="practiceQuestionsEnabled">
          <mat-select placeholder="Praticar BdQ habilitado?" formControlName="practiceQuestions" required>
            <mat-option [value]="true">Sim</mat-option>
            <mat-option [value]="false">Não</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="validation-field">
          <app-cascate-select #cascateSelect [selectedSegment]="module.segment" [selectedSector]="module.sector">
          </app-cascate-select>
        </div>
        <ng-container *ngIf="hasEcommerceIntegration && !module.ecommerceId">
          <mat-form-field style="margin-top: 8px">
            <mat-select placeholder="Criar Módulo no Ecommerce" formControlName="createInEcommerce" required>
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="hasEcommerceIntegration && module.ecommerceId">
          <mat-form-field>
            <input matInput placeholder="Id do Ecommerce" formControlName="ecommerceId" />
          </mat-form-field>
        </ng-container>
      </div>

      <div class="validation-field">
        <label>Tutores</label>
        <app-list-search [noPadding]="true" placeholder="Digite o nome de um usuário para adicioná-lo como tutor"
          (triggerSearch)="triggerTutorSearch($event)"></app-list-search>
        <ul class="user-list" *ngIf="tutors && tutors.length > 0" (clickOutside)="resetTutorSearch()">
          <li *ngFor="let tutor of tutors" (click)="addTutor(tutor)">
            <img class="logo" [src]="tutor.imageUrl ? tutor.imageUrl : './assets/img/user-image-placeholder.png'" />
            <span>{{ tutor.name }}</span>
          </li>
        </ul>
        <div class="chips" *ngIf="selectedTutors && selectedTutors.length > 0">
          <p *ngFor="let tutor of selectedTutors">
            <img class="logo" [src]="tutor.imageUrl ? tutor.imageUrl : './assets/img/user-image-placeholder.png'" />
            {{ tutor.name }}
            <span (click)="removeSelectedTutor(tutor)">X</span>
          </p>
        </div>
      </div>

    </div>
    <div class="validation-field">
      <mat-form-field style="width: 100%;">
        <input matInput placeholder="Nome" formControlName="title" required />
      </mat-form-field>
      <control-messages [control]="formGroup.get('title')"></control-messages>
    </div>
    <div class="validation-field">
      <mat-form-field>
        <input matInput placeholder="Tempo de duração" required formControlName="duration" />
      </mat-form-field>
    </div>
    <div class="two-columns">
      <div class="validation-field">
        <mat-form-field>
          <textarea matInput placeholder="Resumo (max 600 caracteres)" formControlName="excerpt" required></textarea>
        </mat-form-field>
        <control-messages [control]="formGroup.get('excerpt')"></control-messages>
      </div>
      <div class="validation-field">
        <mat-form-field>
          <textarea matInput placeholder="Mini Bio (max 600 caracteres)" formControlName="instructorMiniBio"></textarea>
        </mat-form-field>
        <control-messages [control]="formGroup.get('instructorMiniBio')"></control-messages>
      </div>
    </div>
    <div class="two-columns">
      <div class="image-upload">
        <p class="title">Imagem de Capa (420x210)</p>
        <img [src]="formGroup.get('imageUrl').value" />
        <button type="button" class="btn-test btn-420" (click)="uploadImage(420, 210, 'imageUrl')">
          Fazer Upload de Imagem
        </button>
      </div>
      <div class="image-upload">
        <p class="title" [ngPlural]="selectedInstructors.length">
          <ng-template ngPluralCase="one">Foto do Instrutor (360x360)</ng-template>
          <ng-template ngPluralCase="other">Foto dos Instrutores (360x360)</ng-template>
        </p>
        <img class="btn-240" [src]="formGroup.get('instructorImageUrl').value" />
        <button type="button" class="btn-test btn-240" (click)="uploadImage(360, 360, 'instructorImageUrl')">
          Fazer Upload de Imagem
        </button>
      </div>
    </div>
    <div class="one-column">
      <p class="title">TAGS</p>
      <div class="tags">
        <img class="busca" src="./assets/img/seach-black.png" />
        <input matInput required (keyup.enter)="addTag($event)"
          placeholder="Escreva os temas pertinentes a este módulo para adicioná-los como TAGS" />
        <div class="tag" *ngFor="let tag of formGroup.get('tags').value; let index = index">
          {{ tag }}
          <img (click)="removeTag(index)" src="./assets/img/close.png" />
        </div>
      </div>
    </div>
  </form>
</div>
