import { Component, Input, OnInit, Injectable } from '@angular/core';
import { TrackStudentOverview } from 'src/app/models/track-overview.interface';
import * as Chart from 'chart.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { TrackUserProgress } from 'src/app/models/track-user-progress.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserModuleItem } from 'src/app/models/user-module-item.model';
import { addDays, differenceInSeconds, format } from 'date-fns';
import { FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { groupBy, isEmpty, isNil, orderBy, uniq } from 'lodash';
import { nextSortDir, SortType, SortDirection } from '@swimlane/ngx-datatable';
import pt from 'date-fns/locale/pt';

@Injectable()
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(48, 1200, 2000); // (itemSize, minBufferPx, maxBufferPx)
  }
}


@Component({
  selector: 'app-track-modules-progress',
  templateUrl: './track-modules-progress.component.html',
  styleUrls: ['./track-modules-progress.component.scss'],
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }]

})
export class TrackModulesProgressGanttComponent extends NotificationClass implements OnInit {

  @Input() readonly title: string;
  @Input() readonly track: TrackStudentOverview;
  @Input() readonly labels: Array<number>;
  @Input() readonly dataset: any;
  @Input() readonly titleCallback: Function;
  @Input() readonly tooltipCallback: Function;
  @Input() readonly showLegends: boolean = true;
  @Input() readonly totalDays: number;
  @Input() readonly expectedProgress: number;
  @Input() public set userProgress(userProgress: any[]) { if (userProgress) this._setUsersProgress(userProgress); }

  public stackedChart;
  public users: Array<TrackUserProgress> = [];
  public readonly displayedColumns: string[] = ['title', 'grade', 'started', 'finished', 'nivel'];
  public readonly eventsDisplayedColumns: string[] = ['title', 'date', 'finalGrade', 'applyed'];
  public panelOpenState = false;
  public currentSortDir = SortDirection.desc;
  public departmentInformation: string;
  public popverText: string;
  public popverTitleName: string;

  private _businessGroup: string;
  private _businessUnit: string;
  private _segment: string;
  private _tempUsers: TrackUserProgress[];

  constructor(
    protected _snackBar: MatSnackBar,
    private _tracksService: SettingsTracksService,
    private _authService: AuthService
  ) {
    super(_snackBar);
  }

  ngOnInit() {

    this.stackedChart = new Chart('stackedProgressCanvas', {
      type: 'horizontalBar',
      data: {
        labels: this.labels,
        datasets: this.dataset
      },
      options: {
        legend: {
          display: true
        },
        tooltips: {
          enabled: false,
          titleFontSize: 15,
          bodyFontSize: 10,
          custom: function (tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            tooltipModel.body = null;
            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              let innerHtml = '<thead>';

              titleLines.forEach(function (title) {
                innerHtml += '<tr><th>' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';
              innerHtml += '<tr><td>teste</td></tr>';
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            const position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          },
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: true
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontSize: 15
            },
            stacked: true,
            // barPercentage: 0.7
          }],
        }
      }
    });

    this.stackedChart.generateLegend();
    this.stackedChart.update(true);

  }

  private _setUsersProgress(progress): void {

    this.users = progress.students;

    const getDangerProgressRule = (userProgress: TrackUserProgress) =>
      userProgress.modules
        .filter(module => module.openDate)
        .filter((module: UserModuleItem) => {
          const today = new Date().getTime();
          const moduleIsAvailable = today > new Date(module.openDate).getTime();
          const moduleTimeExpired = today > new Date((module.cutOffDate || module.valuationDate)).getTime();
          // tslint:disable-next-line: no-shadowed-variable
          const progress = userProgress.progressUntilDay;
          return moduleIsAvailable && parseFloat(progress) < (this.expectedProgress - 20) && moduleTimeExpired;
        }).length > 0;

    const getWarningProgressRule = (userProgress: TrackUserProgress) =>
      userProgress.modules
        .filter(module => module.openDate)
        .filter((module: UserModuleItem) => {
          const today = new Date().getTime();
          const moduleIsAvailable = today > new Date(module.openDate).getTime() &&
            today < new Date((module.cutOffDate || module.valuationDate)).getTime();

          // tslint:disable-next-line: no-shadowed-variable
          const progress = userProgress.progressUntilDay;
          return (parseFloat(progress) < (this.expectedProgress) && parseFloat(progress) > (this.expectedProgress - 20) ||
            moduleIsAvailable && parseFloat(progress) <= (this.expectedProgress) &&
            parseFloat(progress) >= (this.expectedProgress - 20)) &&
            addDays(new Date(), 3).getTime() > new Date((module.cutOffDate || module.valuationDate)).getTime();
        }).length > 0;

    const getSuccessProgressRule = (userProgress: TrackUserProgress) =>
      userProgress.modules
        .filter(module => module.openDate)
        .filter((module: UserModuleItem) => {
          const today = new Date().getTime();
          const moduleTimeExpired = new Date((module.cutOffDate || module.valuationDate)).getTime() < today;
          // tslint:disable-next-line: no-shadowed-variable
          const progress = userProgress.progressUntilDay;
          return moduleTimeExpired && parseFloat(progress) >= this.expectedProgress;
        }).length > 0;

    const getStyleClassForUserProgres = user => {
      if (getDangerProgressRule(user)) {
        return 'low-progress';
      } else if (getWarningProgressRule(user)) {
        return 'average-progress';
      } else if (getSuccessProgressRule(user)) {
        return 'high-progress';
      }
    };


    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i];

      user.currentProgress = parseFloat(user.currentProgress.toFixed(2));
      user.finalGrade = parseFloat(user.finalGrade.toFixed(2));
      user.progressColor = getStyleClassForUserProgres(user);


      for (let k = 0; k < this.users[i].modules.length; k++) {
        const currentUserModule = user.modules[k];

        currentUserModule.moduleStatus = 1;
        /*
          Modulo Fechado        = 1 // moduleStatus
          Modulo Aberto         = 2 // moduleStatus
          Modulo Encerrado      = 3 // moduleStatus

          Modulo Não Iniciado   = 1 // userModuleStatus
          Modulo Em Andamento   = 2 // userModuleStatus
          Modulo Finalizado     = 3 // userModuleStatus
        */

        currentUserModule.grade = parseFloat(currentUserModule.grade.toFixed(2));

        const moduleOpenDate = new Date(currentUserModule.openDate);
        const moduleValuationDate = new Date(currentUserModule.valuationDate);
        const _today = new Date();

        if (moduleOpenDate > _today) {
          currentUserModule.moduleStatus = 1;
        } else {
          if (moduleOpenDate < _today && moduleValuationDate > _today) {
            currentUserModule.moduleStatus = 2;
          } else {
            currentUserModule.moduleStatus = 3;
          }
        }



      }

      for (let k = 0; k < this.users[i].events.length; k++) {
        this.users[i].events[k].finalGrade =
          parseFloat(this.users[i].events[k].finalGrade ? this.users[i].events[k].finalGrade.toFixed(2) : 0);
        this.users[i].events[k].date = this.users[i].events[k].date ? new Date(this.users[i].events[k].date) : null;
      }
    }

    this.sortRanking();
    this._tempUsers = [...this.users];
  }

  public getStatusImage(moduleProgress, user, expectedProgress: number): string {
    const filterModuleId = module => module.moduleId === moduleProgress.moduleId;
    const filterProgress = user.progressList.filter(filterModuleId);
    const filteredModule = user.modules.filter(filterModuleId);

    const progress = filterProgress.length ? filterProgress[0].progress : 0;
    const grade = filteredModule.length ? filteredModule[0].grade : 0;

    return (progress >= expectedProgress || moduleProgress.isFinished || (expectedProgress !== 1 && grade > 0)) ?
      './assets/img/approved.png'
      : './assets/img/approved-disabled.png';

  }


  public calcSortClass(sortDir: SortDirection): string {
    if (sortDir === SortDirection.asc) {
      return `datatable-icon-up`;
    } else if (sortDir === SortDirection.desc) {
      return `datatable-icon-down`;
    }
  }

  public sortRanking(): void {
    const tempUsers = [...this.users];
    this.users = [];
    this.currentSortDir = nextSortDir(SortType.single, this.currentSortDir);
    const usersWithRanking = orderBy(tempUsers.filter(user => user.ranking), user => user.ranking, this.currentSortDir);
    const userWithoutRanking = tempUsers.filter(user => !user.ranking);

    this.users = [...usersWithRanking, ...userWithoutRanking];
  }

  public getEventStatusImage(date: string): string {

    if (date) {
      return './assets/img/approved.png';
    }

    return './assets/img/approved-disabled.png';
  }

  public getPercentage(value: number): number {
    // return parseFloat((value * 100 / this.results.itemsCount).toFixed(2));
    return parseFloat((value * 100 / 6).toFixed(2));
  }

  public getDepartmentInformation(student: TrackUserProgress) {
    const { businessGroup, businessUnit, segment } = student;
    const getFormattedName = (name: string) => `${name.charAt(0).toUpperCase()}${name.slice(1, name.length).toLowerCase()}`;

    this._businessGroup = `${businessGroup ? 'Businnes group: ' + getFormattedName(businessGroup) : ''}`;
    this._businessUnit = `${businessUnit ? 'Businnes unit: ' + getFormattedName(businessUnit) : ''}`;
    this._segment = `${segment ? 'Segment: ' + getFormattedName(segment) : ''}`;


    const departments = `${this._businessGroup}\n${this._businessUnit}\n${this._segment}`;

    delete this.departmentInformation;
    if (departments.trim()) this.departmentInformation = departments;
  }

  public setPopoverText(studentName: string, item) {
    this.popverTitleName = studentName;
    const hasAnswerAfterCutOffDate = differenceInSeconds(item.lastAnswerDate, item.cutOffDate) > 0;

    this.popverText = `${item.calcMemoryText}`;
    if (item.cutOffDate && hasAnswerAfterCutOffDate) {
      this.popverText += `\n<b>Data de corte</b>: ${format(item.cutOffDate, 'DD/MM/YYYY HH:mm:ss')}`;
      this.popverText += `\n<b>Data da última resposta</b>: ${format(item.lastAnswerDate, 'DD/MM/YYYY HH:mm:ss', { locale: pt })}`;
    }
    this.popverText += `\n\n<b>Expressão </b>: ${item.calcMemory}`;
  }

  public getModule(items) {
    return items.filter(item => item.hasQuestions === true);
  }
}
