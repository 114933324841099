<div class="header">
  <p>{{ header }} ({{ applications.length }})</p>

  <div class="search">
    <input *ngIf="showSearchInput && startApplications.length > 0"
      placeholder="Buscar por aluno"
      [(ngModel)]="searchValue"
      (ngModelChange)="filterApplications()" />
    <img
      (click)="startSearch()"
      src="./assets/img/search-white.png" />
  </div>
</div>

<table mat-table [dataSource]="applications" class="mat-elevation-z8">

  <ng-container matColumnDef="logo">
    <th width="6%" mat-header-cell *matHeaderCellDef> </th>
    <td width="6%" mat-cell *matCellDef="let row">
      <img class="logo" [src]="row?.user?.imageUrl ? row?.user?.imageUrl : './assets/img/user-image-placeholder.png'" />
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th width="16%" mat-header-cell *matHeaderCellDef> Nome do Usuário </th>
    <td width="16%" mat-cell *matCellDef="let row">
      {{ row?.user?.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th width="16%" mat-header-cell *matHeaderCellDef> E-mail </th>
    <td width="16%" mat-cell *matCellDef="let row">
      {{ row?.user?.email }}
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th width="12%" mat-header-cell *matHeaderCellDef> Categoria </th>
    <td width="12%" mat-cell *matCellDef="let row">
      {{ row?.user?.rank ? row?.user?.rank?.name : '-' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="requestDate">
    <th width="8%" mat-header-cell *matHeaderCellDef> {{ dateLabel }} </th>
    <td width="8%" mat-cell *matCellDef="let row">
      {{
        row?.approved === null || row?.approved === undefined || row?.applicationStatus === 0 ?
          (row?.requestedDate | date : 'dd/MM/yyyy') :
          (row?.resolutionDate | date : 'dd/MM/yyyy')
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="requirements">
    <th width="8%" mat-header-cell *matHeaderCellDef> Pré-requisitos </th>
    <td width="8%" mat-cell *matCellDef="let row">
      <img
        [src]="getCheckImgSrc(row?.eventRequirement)" />
    </td>
  </ng-container>

  <ng-container matColumnDef="answers">
    <th width="8%" mat-header-cell *matHeaderCellDef> Respostas </th>
    <td width="8%" mat-cell *matCellDef="let row">
      <img class="icon"
        src="./assets/img/question.png"
        (click)="viewAnswers(row)" />
    </td>
  </ng-container>

  <ng-container matColumnDef="profile">
    <th width="8%" mat-header-cell *matHeaderCellDef> Perfil </th>
    <td width="8%" mat-cell *matCellDef="let row">
      <img class="icon"
        src="./assets/img/profile.png"
        (click)="viewUserProfile(row?.user)" />
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th width="18%" mat-header-cell *matHeaderCellDef> Ações </th>
    <td width="18%" mat-cell *matCellDef="let row">
      <img class="icon"
        title="Rejeitar"
        *ngIf="(row?.applicationStatus == 0 || row?.applicationStatus == 1) && row?.eventRequirement"
        src="./assets/img/denied.png"
        (click)="resolveApplication(row, false)" />
      <img class="icon"
        title="Aprovar"
        *ngIf="(row?.applicationStatus == 0 || row?.applicationStatus == 2)  && row?.eventRequirement"
        src="./assets/img/approved.png"
        (click)="resolveApplication(row, true)" />
      <img title="Mudar Dia/Horário"
        class="icon"
        (click)="changeEventDateDialog(row.user.id)"
        [src]="'./assets/img/edit-icon.png'" />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
