import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventPreview } from '../../../models/previews/event.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ERole } from 'src/app/models/enums/role.enum';

@Component({
  selector: 'app-settings-event-card',
  template: `
    <div class="event-card" >
      <img class="main-img" [src]="event.imageUrl" />

      <div class="draft-tag" [ngClass]="{ 'draft': event.isDraft }" >
        {{ event.isDraft ? 'RASCUNHO': 'PUBLICADO' }}
      </div>

      <div class="preview" >
        <div>
          <h3>
            {{ event.title }}<br>
            <small *ngIf="isAdmin" >
              Id: {{ event.id }}
            </small>
          </h3>
        </div>
      </div>

      <div class="edit">
        <img src="./assets/img/edit.png" (click)="editEvent.emit(event)" />
        <img src="./assets/img/view.png" (click)="viewEvent.emit(event)" />
        <img src="./assets/img/trash.png" (click)="deleteEvent.emit(event)" />
      </div>
    </div>`,
  styleUrls: ['./event-card.component.scss']
})
export class SettingsEventCardComponent {

  @Input() readonly event: EventPreview;
  @Output() viewEvent = new EventEmitter<EventPreview>();
  @Output() editEvent = new EventEmitter<EventPreview>();
  @Output() deleteEvent = new EventEmitter<EventPreview>();

  public isAdmin: boolean = false;

  constructor(
    private _authService: AuthService
  ) {
    this.isAdmin = this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources);
  }

}
