import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Module } from '../../../models/module.model';
import { UtilService } from '../../../shared/services/util.service';
import { UserService } from '../../_services/user.service';
import * as moment from 'moment';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { MaterialDialogComponent } from './material-dialog/material-dialog.component';
import { MaterialDialog } from 'src/app/pages/module/module-header/material-dialog/support-material/models/material-dialog.model';
import { environment } from 'src/environments/environment';
import differenceInSeconds from 'date-fns/difference_in_seconds';


@Component({
  selector: 'app-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss']
})
export class ModuleHeaderComponent {

  // Features
  public hasFeatureLevels = environment.features.levels;

  private _module: Module;
  @Input()
  public get module(): Module { return this._module; }
  public set module(module: Module) {
    this._module = module;
    if (module.calculateGradeAt)
      // tslint:disable-next-line: max-line-length
      this.updatedAtText = `Última data de atualização da nota: ${moment(this.module.calculateGradeAt).format('DD/MM/YYYY [às] HH:mm')} horas.`;
  }

  @Input() moduleProgress: any;
  @Input() levels: any;
  @Input() levelList: any;
  @Input() disableGrade: boolean;

  public updatedAtText: string;

  public get hasAnswerAfterCutOffDate() {
    return this._hasCutOffDate &&
      differenceInSeconds(this.module.lastAnswerDate, new Date(this.module.moduleConfiguration.cutOffDate)) > 0;
  }

  private get _hasCutOffDate() {
    return this.module.moduleConfiguration && this.module.moduleConfiguration.cutOffDate;
  }

  constructor(
    private _utilService: UtilService,
    private _userService: UserService,
    private _dialog: MatDialog,
  ) {
  }

  public getFormattedHour(): string {
    if (!this.module || !this.module.duration) return '';
    return this._utilService.formatSecondsToHourMinute(this.module.duration);
  }

  public getLevelClass(level) {
    return this._userService.getLevelClass(level);
  }

  public getLevelIcon(level: number, currentLevel: number) {
    if (!currentLevel || currentLevel <= level)
      return 'empty-badge';
    else
      return this._userService.getLevelIcon(level);
  }

  public getLevelColor(level: number, currentLevel: number) {
    if (currentLevel > level)
      return this._userService.getLevelColor(level);
    else
      return 'var(--semi-primary-color)';
  }

  public openGradeDialog() {

    if (!this.module.moduleConfiguration) return;

    let message = '';
    const moduleWeight = this.module.moduleConfiguration.bdqWeight;
    const evaluationWeight = this.module.moduleConfiguration.evaluationWeight;

    if (moduleWeight === 0 &&
      evaluationWeight === 0) {
      message = 'A nota do módulo foi calculada pela média aritmética entre a nota do BDQ e a nota da prova.';
    } else {
      if (moduleWeight === 0 && evaluationWeight > 0) {
        message = 'A nota do módulo foi calculada pela sua nota do BDQ.';
      } else if (moduleWeight > 0 && evaluationWeight === 0) {
        message = 'A nota do módulo foi calculada pela nota da sua prova.';
      } else if (moduleWeight > 0 && evaluationWeight > 0) {
        message = 'A nota do módulo foi calculada pela média ponderada entre a nota da sua prova e a nota do BDQ.';
      }
    }

    if (this._hasCutOffDate) {
      const formattedCutOffDate = moment(this.module.moduleConfiguration.cutOffDate).format('DD/MM/YYYY HH:mm:ss');
      message = `A nota acima é baseada na data de corte de: ${formattedCutOffDate}.` + '\n' + message;
    }

    this._dialog.open(SimpleDialogComponent, {
      maxWidth: '600px',
      data: {
        message,
        positiveTextAction: 'Fechar',
      } as SimpleDialog
    });
  }

  public getMaterial() {
    this._dialog.open(MaterialDialogComponent, {
      maxWidth: '800px',
      data: {
        moduleId: this.module.id,
        supportMaterials: this.module.supportMaterials
      } as MaterialDialog
    });
  }
}
