import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { GroupPageItems, Groups } from 'src/app/models/groups.model';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    constructor(private _httpService: BackendService) { }

    public createGroup(group: Groups): Observable<BackendResponse<Groups>> {
        return this._httpService.post('CreateGroups', group);
    }

    public updateGroup(group: Groups): Observable<BackendResponse<Groups>> {
        return this._httpService.put('UpdateGroups', group);
    }

    public DeleteGroups(groupId: string): Observable<BackendResponse<boolean>> {
        return this._httpService.delete('DeleteGroups', [
            { 'name': 'id', 'value': groupId }
        ]);
    }
    public getAll(page: number, pageSize: number, searchTerm: string): Observable<BackendResponse<GroupPageItems>> {
        return this._httpService.post('GetAllGroups', {
            page,
            pageSize,
            filters: {
                term: searchTerm
            }
        });
    }

    public GetById(id: string): Observable<BackendResponse<Groups>> {
        return this._httpService.get('GetById', [], [
            { name: "groupId", value: id }
        ]);
    }



}
