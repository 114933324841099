import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentModulesService } from '../_services/modules.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-module-curatorship',
  templateUrl: './module-curatorship.component.html',
  styleUrls: ['./module-curatorship.component.scss']
})
export class ModuleCuratorshipComponent {

  public tab: number = 1;
  public tagPendingCount = 0;
  public questionPendingCount = 0;
  constructor(
    public moduleService: ContentModulesService
  ) {
    this.getCuratorshipPendingCount();
  }

  public getCuratorshipPendingCount() {
    this.moduleService.getCuratorshipPendingCount().subscribe(res => {
      this.tagPendingCount = res.data.tags;
      this.questionPendingCount = res.data.questions;
    });
  }
}
