import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { Content } from 'src/app/models/content.model';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['../content-config.scss', './link-dialog.component.scss']
})
export class LinkDialogComponent extends NotificationClass implements OnInit {

  public url: string = '';
  constructor(
    public dialogRef: MatDialogRef<LinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public content: Content,
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.url = this.content.value;
  }

  public referencesTrackBy(index: number, obj: any) {
    return index;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this.isValidUrl(this.url)) {
      this.content.value = this.url.replace(/(^http:\/\/|https:\/\/)+/g, '');
      this.dialogRef.close(this.content);
    } else {
      this.notify('Forneça uma URL válida');
    }
  }

  public isValidUrl(url: string) {

    if (isNil(url)) return false;
    const regex = new RegExp(
      // tslint:disable-next-line: max-line-length
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    );

    return regex.test(url);
  }

}
