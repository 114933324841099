<div class="required-module"
  [ngClass]="{ 'last': last, 'management': isManagement }">
  <div class="header">
    <h4>{{title}}</h4>
    <!-- <button>Completo</button> -->
  </div>
  <div class="content">
    <p>
      Proficiência {{requirement.optional? 'sugerida' :'exigida'}}<br>
      <span [ngClass]="{ 'basic': levels[requirement.level] === 'Nível-1',
      'inter': levels[requirement.level] === 'Nível-2',
      'advanced': levels[requirement.level] === 'Nível-3',
      'expert': levels[requirement.level] === 'Nível-4' }">{{ levels[requirement.level] }}</span>
    </p>
    <p *ngIf="requirement.percentage > 0">
      Aproveitamento<br>
      {{ requirement.percentage * 100 }}%
    </p>
    <div class="badge" *ngIf="!isManagement">
      <img [src]="userService.getCompletedLevelImage(progress?.level, progress?.progress)" />
      <p>
        Meu nível<br>
        <span *ngIf="!progress">Não Iniciado</span>
        <span *ngIf="progress && progress.level > 0"
          [ngClass]="{ 'basic': progress.level === 1,
          'inter': progress.level === 2,
          'advanced': progress.level === 3,
          'expert': progress.level === 4 }">{{levels[progress.level-1]}}</span>
        <span *ngIf="progress && progress.level === 0">Em Andamento</span>
      </p>
    </div>
  </div>
  <p class="level" *ngIf="!isManagement">
    <span>{{levels[progress?.level]}}</span> {{progress?.progress*100 | number:'1.0-0'}}%
  </p>
</div>
