<div class="step">
  <h2>Data Liberação Itens</h2>
  <p class="subtitle">
    Defina a data de liberação de cada item da trilha.
  </p><br>

  <table mat-table matSort [dataSource]="event.valuationConfiguration">

    <ng-container matColumnDef="modality">
      <th width="20%" mat-header-cell *matHeaderCellDef> Conteúdo </th>
      <td width="40%" mat-cell *matCellDef="let row">
        <p>
          {{ row.title }}<br>
          <span>Avaliação</span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="alwaysAvailable">
      <th width="10%" mat-header-cell *matHeaderCellDef> </th>
      <td width="20%" mat-cell *matCellDef="let row">
        <div class="choice-box">
          <div class="answer" (click)="toggleModuleAvailability(row) ">
            <div class="checkbox" [ngClass]="{ 'selected': row.alwaysAvailable }"></div>
          </div>
          <p>sempre disponível</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="openDate">
      <th width="10%" mat-header-cell *matHeaderCellDef class="text-center"> Início da Disponibilidade </th>
      <td width="10%" mat-cell *matCellDef="let row" class="text-center">
        <mat-form-field>
          <input matInput autocomplete="off" placeholder="Escolha a data" [disabled]="row.alwaysAvailable"
            [ngxMatDatetimePicker]="pickerOpenDate" (focus)="pickerOpenDate.open()" [(ngModel)]="row.openDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerOpenDate"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerOpenDate [showSeconds]="true" autocomplete="off" [stepHour]="1"
            [enableMeridian]="false" [stepMinute]="1" [stepSecond]="1">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="valuationDate">
      <th width="10%" mat-header-cell *matHeaderCellDef class="text-center"> Fim da Disponibilidade </th>
      <td width="10%" mat-cell *matCellDef="let row" class="text-center">
        <mat-form-field>
          <input matInput placeholder="Escolha a data" [disabled]="row.alwaysAvailable"
            [ngxMatDatetimePicker]="pickerValuationDate" (focus)="pickerValuationDate.open()"
            [(ngModel)]="row.valuationDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerValuationDate"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerValuationDate [showSeconds]="true" [stepHour]="1" [enableMeridian]="false"
            [stepMinute]="1" [stepSecond]="1">
          </ngx-mat-datetime-picker>
        </mat-form-field>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
