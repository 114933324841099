import { Component, Input } from '@angular/core';
import { Requirement } from '../../../settings/modules/new-module/models/new-requirement.model';
import { Level } from '../../../models/shared/level.interface';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-event-required-module',
  templateUrl: './required-module.component.html',
  styleUrls: ['./required-module.component.scss']
})
export class EventRequiredModuleComponent {

  private _requirement: Requirement;
  @Input() public get requirement(): Requirement { return this._requirement; }
  public set requirement(value: Requirement) {
    this._requirement = value;
    if (this._requirement) {
      this.title = `${this._requirement.optional ?
        this._requirement.title + ' (opcional)' : this._requirement.title}`;
    }
  }

  @Input() levels: any = {};
  @Input() last: boolean = false;
  @Input() isManagement?: boolean = false;
  @Input() progress: any = {};
  public title: string;
  constructor(public userService: UserService) { }
}
