<h3>{{ content.title }}</h3>
<h4>{{ content.subjectTitle }}</h4>

<div id="htmlEditor"></div>

<div class="content">
  <div class="info">
    <mat-form-field>
      <textarea matInput placeholder="Sobre (máx. MMM caracteres)"
        [(ngModel)]="content.excerpt" required></textarea>
    </mat-form-field>

    <p class="label">
      Referêcias Úteis
    </p>
    <div class="references">
      <input matInput placeholder="URL"
        *ngFor="let ref of content.referenceUrls; let index = index; trackBy: referencesTrackBy"
        [(ngModel)]="content.referenceUrls[index]" required />
    </div>
    <button class="btn-test" (click)="addReference()">
      + Adicionar Referência
    </button>
  </div>

  <div class="concepts">
    <p class="title">Instruções</p>
    <p>
      Os textos são configurados na linguagem markdown. Você pode achar a explicação
      <a href="markdown" terget="_blank">clicando aqui</a> ou formatar o texto usando
      os comandos acima do texto. Para configurar a posição dos conceitos no texto,
      basta colocar o código da ancora <b>[](#ancora)</b> na posição desejada, substituindo
      a palavra <b>ancora</b> pela descrição desejada, e depois colocando esta mesma palavra no conceito abaixo.
    </p>

    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Conceitos</th>
            <th>Âncora</th>
          </tr>
        </thead>
        <tbody>
          <tr class="concept" *ngFor="let concept of content.concepts">
            <td>
              <div class="checkbox"
                [ngClass]="{ 'checked': concept.checked }"
                (click)="setConcept(concept)"></div>
              {{ concept.name }}
            </td>
            <td>
              <input type="text" matInput placeholder="Âncora"
                *ngIf="concept.checked" required
                [(ngModel)]="concept['anchors'][0]" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<div class="actions">
  <button class="btn-outline" (click)="dismiss()">
    Cancelar
  </button>
  <button class="btn-test" (click)="save()">
    Salvar
  </button>
</div>
