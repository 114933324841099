<ng-container *ngIf="tracks && tracks.length > 0">
  <div class="path">
    <h4 *ngIf="!isManagerView">{{ (replacement.track.plural | replacement:'minhas') | uppercase }}</h4>
    <h4 *ngIf="isManagerView">{{ replacement.track.plural | uppercase }} </h4>
    <div class="all-modules">
      <span (click)="changeViewTypeTracks()"><i class="icon visao-icon icon-visao_lista"></i></span>
    </div>
  </div>

  <div class="suggested" [ngClass]="{ 'list': viewTypeTracks === 'list' }">
    <ng-container *ngIf="hasHomeV2">
      <app-course-card *ngFor="let track of tracks" [course]="track" [progress]="trackProgress[track.id]">
      </app-course-card>
    </ng-container>

    <ng-container *ngIf="!hasHomeV2">
      <app-track-card *ngFor="let track of tracks" [track]="track" [progress]="trackProgress[track.id]"
        [viewType]="viewTypeTracks" [showRecommended]="true" [showText]="true" [showModule]="true" [showEvents]="true"
        [hours]="true" [showContent]="true" [showButtonKnowMore]="true" [showPrice]="true" [showButtonSubscribe]="false"
        [redirectWhenRecommended]="true">
      </app-track-card>
    </ng-container>

  </div>

  <app-pagination *ngIf="tracksCount" [setPagination]="{ 'itemsCount': tracksCount, 'pageSize': 8 }"
    (goToPage)="goToPageTrack($event)">
  </app-pagination>
</ng-container>

<ng-container *ngIf="allModules && allModules.length > 0">
  <div class="all-modules">
    <h4 *ngIf="!isManagerView">MEUS CURSOS</h4>
    <h4 *ngIf="isManagerView">CURSOS</h4>
    <span (click)="changeViewTypeModules()"><i class="icon visao-icon icon-visao_lista"></i></span>
  </div>

  <div class="suggested" [ngClass]="{ 'list': viewTypeModules === 'list' }">
    <ng-container *ngIf="hasHomeV2">
      <app-course-card *ngFor="let module of allModules" [course]="module" [progress]="moduleProgress[module.id]">
      </app-course-card>
    </ng-container>

    <ng-container *ngIf="!hasHomeV2">
      <app-module-card *ngFor="let module of allModules" [module]="module" [viewType]="viewTypeModules"
        [progress]="moduleProgress[module.id]" [levels]="levelDict" [showRecommended]="true" [showText]="true"
        [showInstructor]="true" [showContent]="true" [hours]="true" [showButtonKnowMore]="true" [showPrice]="true"
        [showButtonSubscribe]="true" [redirectWhenRecommended]="true">
      </app-module-card>
    </ng-container>


  </div>

  <app-pagination *ngIf="modulesCount" [setPagination]="{
        'itemsCount': modulesCount,
        'pageSize': 8
      }" (goToPage)="goToPage($event)"></app-pagination>
</ng-container>

<ng-container *ngIf="hasTest()">
  <div class="profile-test">
    <p>
      Faça nosso Teste de Perfil para saber quais os produtos mais recomendados para você.
    </p>
    <button class="btn-test primary" (click)="goToProfileTest()">
      Fazer Teste de Perfil
    </button>
  </div>
</ng-container>
