import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormArray } from '@angular/forms';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { SettingsUsersService } from '../../../_services/users.service';
import { Institute } from '../../user-models/user-career';

@Component({
  selector: 'app-academic-education',
  templateUrl: './academic-education.component.html',
  styleUrls: ['../manage-user-career.component.scss']
})
export class AcademicEducationComponent extends NotificationClass {

  @Input() formGroup: FormGroup;
  @Output() addEducation = new EventEmitter();
  @Output() setValidator = new EventEmitter();

  public institutes: Array<Array<Institute>> = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _settingsUsersService: SettingsUsersService
  ) {
    super(_snackBar);
  }

  public removeForm(key: string, index: number) {
    const formArray = this.formGroup.get(key) as FormArray;
    formArray.removeAt(index);
    if (key === 'colleges')
      this.institutes.pop();
  }

  private _loadColleges(searchValue: string = '', index: number): void {
    this._settingsUsersService.getUserInstitutions(searchValue).subscribe(response => {
      this.institutes[index] = response.data;
    });
  }

  public triggerCollegeSearch(searchValue: string, formGroup: FormGroup, index: number) {
    if (searchValue && searchValue.trim() !== '') {
      formGroup.get('title').setValue(searchValue);
      this._loadColleges(searchValue, index);
    }
  }

  public resetCollegeSearch(index: number): void {
    this.institutes[index] = [];
  }

  public addCollegeIntoForm(college: any, formGroup: FormGroup, index: number) {
    formGroup.get('instituteId').setValue(college.id);
    formGroup.get('title').setValue(college.name);
    this.institutes[index] = [];
  }

  public changePeriodDisabled(formGroup: FormGroup, value: string) {
    if (value === 'Completo') {
      formGroup.get('completePeriod').enable();
    } else {
      formGroup.get('completePeriod').setValue('');
      formGroup.get('completePeriod').disable();
    }
  }

  public validField(): void {
    const career = this.formGroup.getRawValue();
    const getMonthToNumber = date => {
      const month = date.substring(0, 2);
      return Number(month);
    };
    let checkEducationField = true;
    if (career.colleges) {
      career.colleges.forEach(x => {
        if ((x.title.length === 0) || (x.name.length === 0) ||
          (x.status.length === 0) || (x.academicDegree.length === 0) ||
          (x.endDate.length === 0)) {
          this.notify('Preencha em Histórico Acadêmico todos os campos obrigatórios (*)');
          checkEducationField = false;
        }
        if (getMonthToNumber(x.endDate) > 12 || getMonthToNumber(x.startDate) > 12) {
          this.notify('Mês Invalido');
          checkEducationField = false;
        }
      });
    } if (career.colleges.length === 0) {
      this.notify('É obrigatório ter pelo menos uma Instituição em Histórico Acadêmico');
      checkEducationField = false;
    }

    this.setValidator.emit({key: 'education', value: checkEducationField});
  }
}
