<div class="inner-content">
  <h2>LOGS DE ACESSO</h2>

  <div class="filter-wrapper">
    <div class="filter">

      <mat-form-field>
        <mat-label>Filtrar por área de atividade</mat-label>
        <mat-select [(ngModel)]="entityType" name="activity">
          <mat-option *ngFor="let entity of entityList" [value]="entity.value">
            {{entity?.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-date-range-picker #Picker
        (rangeSelected)="filterByDateRange($event)"></app-date-range-picker>
    </div>

    <button class="btn-test" (click)="applyFilter()">Filtrar</button>

  </div>

  <ngx-datatable
    #Table
    class="material fullscreen"
    trackByProp="id"
    [rows]="logs"
    [rowIdentity]="rowIdentity"
    [messages]="messages"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="29"
    [virtualization]="false"
    [columnMode]="ColumnMode.force"
    [scrollbarH]="false"
    [scrollbarV]="true"
    [externalPaging]="true"
    [count]="logsCount"
    [limit]="pageSize"
    (activate)="onRowClick($event)"
    (page)="goToPage($event)">

    <ngx-datatable-column prop="userName" [maxWidth]="200" name="Usuário">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="date" [maxWidth]="185" name="Data">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{value | date: 'dd/MM/yyyy HH:mm:ss'}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="actionDescription" name="Atividade">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column [maxWidth]="105" name="Ação" headerClass="align-right" cellClass="align-right">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <mat-icon title="Comparar alterações">compare_arrows</mat-icon>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>

</div>
