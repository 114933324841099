import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../../shared/classes/notification';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Module } from 'src/app/models/module.model';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { mandatoryCollumnEnum } from './model/mandatoryCollums.interface';
import { isNil } from 'lodash';
import { Subject, UserProgress } from 'src/app/models/subject.model';
import { Level } from 'src/app/models/shared/level.interface';
import { ProgressTypeEnum } from 'src/app/models/enums/progress-type.enum';
import { ExternalService } from 'src/app/shared/services/external.service';

@Component({
  selector: 'app-upload-subjects-dialog',
  templateUrl: './upload-subjects.dialog.html',
  styleUrls: ['./upload-subjects.dialog.scss']
})
export class UploadSubjectsDatabaseDialogComponent extends NotificationClass {

  public module: Module;
  public levels: Level[];
  public addSubjetcs = true;
  public showCloseButton = true;
  public subjects: Subject[] = [];
  public loading: boolean = false;
  public erroList: any[];
  private readonly _rightAnsware = '2';
  private readonly _enunciated = 'enunciado';
  public readonly collums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
  'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  constructor(
    protected _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<UploadSubjectsDatabaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { module: Module, levels: Level[] },
    public _sharedService: SharedService,
    private _excelService: ExcelService,
    private _externalService: ExternalService
  ) {
    super(_snackBar);
    this.module = this.data.module;
    this.levels = this.data.levels;
  }
  public normalizeString = (value) => isNil(value) ? null : this._excelService.normalizeString(value);

  public dismiss(): void {
    this._dialogRef.close();
  }

  public save(): void {
    this._dialogRef.close({
      subjects: this.subjects,
      addSubjects: this.addSubjetcs
    });
  }

  public openFileUpload(): void {
    const input = document.getElementById('subjectsInputFile') as HTMLInputElement;
    input.value = '';
    input.click();
  }

  public setDocumentFile(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
        this.notify('Tipo de arquivo inválido. Apenas \'xls\', \'xlsx\' e \'csv\' são permitidos.');
        return;
      }

      this.loading = true;
      this._readExcel(file);
    }
  }

  private _readExcel(file) {
    const callback = this._importQuestion.bind(this);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let binary = '';
      const bytes = new Uint8Array(this.result as any);

      for (let i = 0; i < bytes.byteLength; i++)
        binary += String.fromCharCode(bytes[i]);

      callback(binary);
    };
    reader.readAsArrayBuffer(file);
  }

  private _importQuestion(file: string) {
    const mandatoryCollumns = [
      mandatoryCollumnEnum.subjectName,
      mandatoryCollumnEnum.subjectExcerpt,
      mandatoryCollumnEnum.subjectPercents,
      mandatoryCollumnEnum.subjectConcepts
    ];

    this._excelService._getExcelContentAsJson(file, mandatoryCollumns).subscribe(response => {
      this._setSubjects(response.contentJson, response.headers);
    }, error => {
      this.erroList = [{ description: error}];
      this.loading = false;
    });
  }

  private _setSubjects(subjects: any[], headers: any[]) {
    this.erroList = [];
    let index = 0;
    this._validateExcell(subjects, headers);
    if (this.erroList.length === 0) {
      while (index < subjects.length) {
        const excelSubject = subjects[index];
        let newSubject = false;
        const dataSubject = this._findSubject(excelSubject[mandatoryCollumnEnum.subjectName]);
        if (dataSubject.title === '') {
          newSubject = true;
        }
        this._setSubject(dataSubject, newSubject,
          excelSubject[mandatoryCollumnEnum.subjectName],
          excelSubject[mandatoryCollumnEnum.subjectExcerpt],
          excelSubject[mandatoryCollumnEnum.subjectPercents],
          excelSubject[mandatoryCollumnEnum.subjectConcepts]
        );
        if (newSubject) {
          this.subjects.push(dataSubject);
        }
        index++;
      }
    }
    this.loading = false;
  }

  private _validateExcell(excellSubjects: any[], headers: any[]) {
    const getCollum = (header: string) => {
      const collumn = headers.find(h => h.headear === header).collumn;
      return isNil(collumn) ? collumn : this.collums[collumn];
    };

    let index = 0;
    while (index < excellSubjects.length) {
      const excelSubject = excellSubjects[index];

      if (isNil(excelSubject[mandatoryCollumnEnum.subjectName])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.subjectName),
          row: excelSubject['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      if (isNil(excelSubject[mandatoryCollumnEnum.subjectExcerpt])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.subjectExcerpt),
          row: excelSubject['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      if (!isNil(excelSubject[mandatoryCollumnEnum.subjectPercents])) {
        const percents: string[] = excelSubject[mandatoryCollumnEnum.subjectPercents].split(';');
        if (percents.length !== this.levels.length) {
          this.erroList.push({
            collum: getCollum(mandatoryCollumnEnum.subjectPercents),
            row: excelSubject['row'],
            description: 'Este campo so pode ser nulo ou ter todos os ' + this.levels.length + ' percentuais preenchidos'
          });
        }
      }

      index++;
    }
  }

  private _setSubject(subject: Subject, newSubject: boolean, subjectName: string, subjectExcerpt: string,
    subjectPercents: string, subjectConcepts: string) {
    if (newSubject) {
      subject.title = subjectName;
      subject.excerpt = subjectExcerpt;
      if (!isNil(subjectConcepts)) {
        const concepts = subjectConcepts.split(';');
        subject.concepts = concepts;
      }
      if (this.levels && this.levels.length > 0) {
        if (!isNil(subjectPercents)) {
          const percents = subjectPercents.split(';');
          let index = 0;
          this.levels.forEach(level => {
            subject.userProgresses.push(
              new UserProgress(level, ProgressTypeEnum.SubjectProgress, +percents[index] / 100)
            );
            index++;
          });
        } else {
          this.levels.forEach(level => {
            subject.userProgresses.push(
              new UserProgress(level)
            );
          });
        }
      }
    }
  }

  private _findSubject(subjectName: string): Subject {
      const subjectIndex = this.subjects.findIndex(x => x.title === subjectName);
      if (isNil(subjectName) || subjectIndex === -1) {
        return {
          title: '',
          excerpt: '',
          concepts: [],
          contents: [],
          userProgresses: []
        };
      }
      return this.subjects[subjectIndex];
  }
}
