<div class="step">

  <div class="header-wrapper">
    <h2>
      Assuntos deste Módulo
      <small>Arraste e solte para ordenar os assuntos</small>
    </h2>
    <button type="button" class="btn-test add-subject" (click)="addSubject()">
      + Adicionar Assunto
    </button>
  </div>

  <div style="margin-bottom: 20px;">
    <a class="link-button" href="./../../../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_ASSUNTOS.xlsx"
      download="Planilha_Modelo_de_Importação_dos_Assuntos.xlsx">
      BAIXAR MODELO
    </a>
    <button class="btn-test primary" style="margin-left: 10px;" (click)="openUploadDialog(module)">
      IMPORTAR EXCEL
    </button>
    <button class="btn-test primary" style="margin-left: 10px;" (click)="exportSubjects()">
      EXPORTAR EXCEL
    </button>
  </div>


  <mat-accordion class="subjects" cdkDropList (cdkDropListDropped)="droppedSubject($event)">

    <mat-expansion-panel class="subject" cdkDrag *ngFor="let subject of module?.subjects; let index = index"
      [expanded]="index === module?.subjects?.length - 1 && !subject.id">
      <mat-expansion-panel-header>
        <mat-panel-title>{{subject?.title}}</mat-panel-title>
        <mat-panel-description>
          <img class="trash" src="./assets/img/trash.png" (click)="removeSubject(index)" />
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="two-columns">
        <div class="validation-field">
          <mat-form-field>
            <input matInput placeholder="Nome" [(ngModel)]="subject.title" required />
          </mat-form-field>

          <p class="subtitle">
            Aproveitamento Necessário para Conseguir Nível
          </p>
          <div class="percentages">
            <div>
              <p class="level" *ngFor="let progress of subject.userProgresses">
                {{ getLevelDescription(progress.level) }} (%)
              </p>
            </div>
            <div>
              <input *ngFor="let progress of subject?.userProgresses" matInput type="number" required
                placeholder="Aproveitamento" [ngModel]="progress.percentage | asPercentage"
                (blur)="setProgressPercentage($event, progress)" />
            </div>
          </div>
        </div>
        <div class="validation-field">
          <mat-form-field style="width: 97%">
            <textarea matInput placeholder="Resumo (max 400 caracteres)" required
              [(ngModel)]="subject.excerpt"></textarea>
          </mat-form-field>
        </div>
      </div>

      <app-concepts-register
        #Concepts
        [concepts]="subject?.concepts"
        [subjectId]="subject.id"
        [module]="module"
        (updateConcepts)="updateConcepts($event, subject)"></app-concepts-register>
    </mat-expansion-panel>
  </mat-accordion>



</div>
