import { UserRelationalItem } from 'src/app/settings/users/user-models/user-relational-item';
import { Event } from '../event.model';

export class EventPreview {
  id?: string;
  eventId?: string;
  title: string;
  excerpt?: string;
  instructorId?: string;
  instructor?: string;
  date?: Date;
  imageUrl: string;
  subscriptionDue?: Date;
  status?: string;
  nextSchedule: NextSchedule;
  published?: boolean;
  hasUserProgess?: boolean;
  schedules?: any;
  requirements?: any;
  moduleProgressInfo?: any;
  isDraft?: boolean;
  isEnrolled?: boolean;
  location?: UserRelationalItem;

  checked?: boolean;

  constructor(event: Event, schedule: NextSchedule, moduleProgress = null) {
    this.id = event.id;
    this.title = event.title;
    this.instructor = event.instructor;
    this.date = new Date(schedule.eventDate);
    this.imageUrl = event.imageUrl;
    this.subscriptionDue = null;
    this.status = null;
    this.nextSchedule = schedule;
    this.published = event.published;
    this.requirements = event.requirements;
    this.moduleProgressInfo = moduleProgress;
    this.location = event.location;
    this.excerpt = event.excerpt;
  }
}

export interface NextSchedule {
  id: string;
  duration: number;
  eventDate: string | Date;
  published: boolean;
  subscriptionEndDate: string | Date;
  subscriptionStartDate: string | Date;
}
