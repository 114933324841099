<div class="students" *ngIf="students?.length > 0">
    <p class="title" >
      PROGRESSO ALUNOS
    </p>
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Objetivo</th>
          <th class="align-text">Nível Alcançado</th>
          <th>Download Arquivos
          <!-- <a style="cursor: pointer;" (click)="downloadAllFiles()">
            <img src="./assets/img/download_azul.svg" *ngIf="hasDownload" ></a> --></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let student of students" >
          <td width="40%" >
            <img [src]="getUserImgUrl(student)" />
            {{ student.name }}
          </td>
          <td width="20%" >
            <p class="status" *ngIf="!student.finished" >
              <app-progress-bar
                [currentProgress]="student.objective | asPercentage"
                [height]="15"
              ></app-progress-bar>
              {{ student.objective | asPercentage }}%
            </p>
            <p class="finished" *ngIf="student.finished" >
              Finalizado
              <img src="./assets/img/status-success.png" />
            </p>
          </td>
          <td width="20%" >
            <p class="level"
              [ngClass]="{
                'none': student.level === null || student.level === 0,
                'beginner': student.level === 1 && student.objective !== 0,
                'intermediate': student.level === 2,
                'advanced': student.level === 3,
                'expert': student.level === 4
              }" >
              <img [src]="getBadgeImgByLevel(student)" />
              {{
                student.level === null || student.level === 0 ?
                  'Sem nível' :
                  levelDictionary[student.level]
              }}
            </p>
          </td>
          <td width="20%" >
          <a  href="{{student.userFiles.downloadLink}}" target="_blank" download *ngIf="student.userFiles">
            <img class="approved btn-download" *ngIf="hasDownload" src="./assets/img/download_azul.svg" />
          </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>