import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventPreview } from 'src/app/models/previews/event.interface';

@Component({
  selector: 'app-delete-event-dialog',
  template: `
    <div class="created-event" >
      <!--img src="./assets/img/event-created.png" /-->
      <h2 *ngIf="hasUserProgress">
        Este evento possui alunos associados a ele.
        <br>
        Deseja deletar mesmo assim?
      </h2>
      <h2 *ngIf="!hasUserProgress">{{deleteMessage}}</h2>
    </div>
    <p style="padding-bottom: 20px;" (click)="dismiss(true)">OK</p>
    <p (click)="dismiss(false)" >Cancelar</p>`,
  styleUrls: ['./delete-event.dialog.scss']
})
export class DeleteEventDialogComponent {
  public hasUserProgess = false;
  public deleteMessage: string;

  public get hasUserProgress(): boolean {
    return this._event.hasUserProgess ? this._event.hasUserProgess : false;
  }

  constructor(
    private _dialogRef: MatDialogRef<DeleteEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _event: EventPreview
  ) {
    this.deleteMessage = `Ter certeza que deseja deletar este evento ${this._event.isDraft ? 'e seu rascunho ?' : '?'}`;
  }

  public dismiss(state: boolean): void {
    this._dialogRef.close(state);
  }

}
