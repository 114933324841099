<div class="flex-column">
    <h5>SELEÇÃO DE QUESTÕES</h5>
    <ng-container [ngSwitch]="index">
        <div *ngSwitchCase="1" class="flex-column">
            <h6>Selecione qual produto você deseja gerar a avaliação</h6>
            <mat-form-field>
                <mat-select 
                    [ngModel]="filters.target" 
                    (ngModelChange)="handleFiltersTarget($event)"
                    placeholder="Tipo"
                >
                  <mat-option [value]="1">Módulo</mat-option>
                  <mat-option [value]="2">Trilha</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div *ngSwitchCase="2" class="flex-column">
            <h6>Selecione {{filters.target === 1 ? 'o módulo' : 'a trilha'}}</h6>
            <app-autocomplete *ngIf="filters.target === 1"
                [filter]="filters?.modules"
                (triggerSearch)="setupUpdateFilterNoOptions(filters?.modules,$event)"
                (change)="handleUpdateFilters(filters?.modules, $event)"
                (remove)="removeFilters(filters?.modules, $event)">
            </app-autocomplete>
            <app-autocomplete *ngIf="filters.target === 2"
                [filter]="filters?.track"
                (triggerSearch)="setupUpdateFilterNoOptions(filters?.track,$event)"
                (change)="handleUpdateFilters(filters?.track, $event)"
                (remove)="removeFilters(filters?.track, $event)">
            </app-autocomplete> 
            <app-generate-valuation-test-accordion
                #accordionComponent
                [data]="list"
            >
            </app-generate-valuation-test-accordion>
        </div>
    </ng-container>
    <div class="content-buttons">
        <button 
            *ngIf="index !== 1" 
            class="btn-test primary" 
            (click)="moveStep(-1)">Voltar</button>
        <button 
            *ngIf="index !== 2" 
            class="btn-test primary" 
            (click)="moveStep(1)">Próximo</button>
        <button 
            *ngIf="index === 2"
            class="btn-test primary" 
            (click)="generateQuestions()"
            >GERAR QUESTÕES</button>
    </div>
</div>

