import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })

export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number, viewMore: boolean): string {

    return value.length < limit || viewMore

      ? value

      : value.slice(0, limit) + '...';

  }

}
