import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionDialogComponent } from '../subscription-dialog/subscription-dialog.component';
import { Event } from '../../../models/event.model';
import { UtilService } from '../../../shared/services/util.service';
import { EventSchedule } from '../../../models/event-schedule.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { EventApplication, PrepQuizAnswer } from 'src/app/models/event-application.model';
import { ContentEventsService } from '../../_services/events.service';
import { ApplicationStatus } from 'src/app/models/enums/application-status';
import { ModuleProgress } from 'src/app/models/previews/module-progress.interface';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { isNil } from 'lodash';
import { SettingsEventsDraftsService } from 'src/app/settings/_services/events-drafts.service';
import { catchError } from 'rxjs/operators';
import { MaterialDialogComponent } from 'src/app/pages/module/module-header/material-dialog/material-dialog.component';
import { MaterialDialog } from 'src/app/pages/module/module-header/material-dialog/support-material/models/material-dialog.model';
import { EventScheduleTypeEnum } from 'src/app/models/enums/event-schedule.enum';
import { SettingsEventsService } from 'src/app/settings/_services/events.service';

@Component({
  selector: 'app-classroom-lesson-header',
  templateUrl: './lesson-header.component.html',
  styleUrls: ['./lesson-header.component.scss']
})
export class ClassroomLessonHeaderComponent extends NotificationClass {


  @Input() progress?;
  @Input() set setEvent(event: Event) {
    this.event = event;
    if (this.event && this.event.schedules && this.event.schedules.length > 0) {
      const scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');

      this.currentSchedule = scheduleId ?
        this.event.schedules.find(x => x.id === scheduleId) :
        this.event.schedules[0];
    }
  }
  @Input() set setEventApplication(eventApplication: EventApplication) {
    this.eventApplication = new EventApplication();
    if (eventApplication && eventApplication.eventId != null) {
      this.eventApplication = eventApplication;
      switch (this.eventApplication.applicationStatus) {
        case ApplicationStatus.Pending:
          this.subscriptionStatus = 'Em Análise';
          break;
        case ApplicationStatus.Rejected:
          this.subscriptionStatus = 'Rejeitado';
          break;
        case ApplicationStatus.Approved:
          this.subscriptionStatus = 'Aprovado';
          break;
        case ApplicationStatus.Full:
          this.subscriptionStatus = 'Lotado';
          break;
        default:
          break;
      }
    }
  }
  @Input() isManagement?: boolean = false;
  @Output() showSubscriptionMessage = new EventEmitter();

  public today = new Date();
  public event: Event;
  public eventApplication: EventApplication;
  public currentSchedule: EventSchedule;
  public scheduleEnum = EventScheduleTypeEnum;
  public subscriptionStatus: string;

  constructor(
    public _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _eventsService: ContentEventsService,
    private _settingsEventsDraftsService: SettingsEventsDraftsService,
    private _utilService: UtilService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _settingsEventsService: SettingsEventsService,
  ) {
    super(_snackBar);
  }

  public getDuration(): string {
    if (!this.currentSchedule) return '--';
    return this._utilService.formatSecondsToHourMinute(this.currentSchedule.duration);
  }

  public openSubscriptionDialog() {
    const isApproved = this.eventApplication.applicationStatus === ApplicationStatus.Approved;
    this.eventApplication.eventId = this.event.id;
    this.eventApplication.scheduleId = this.currentSchedule.id;

    const createDialogSubscription = () => {
      const questionnaire = new Array<any>();
      this.eventApplication.prepQuizAnswers = this.eventApplication.prepQuizAnswers ? this.eventApplication.prepQuizAnswers : [];
      this.event.prepQuizQuestionList.forEach((q, index) => {
        const answer = this.eventApplication.prepQuizAnswers[index] || '';
        questionnaire.push({ question: q, answer: answer });
      });

      const dialogRef = this._dialog.open(SubscriptionDialogComponent, {
        width: '1000px',
        maxHeight: '90vh',
        data: questionnaire
      });

      dialogRef.afterClosed().subscribe(async (resolution: any) => {
        if (resolution) {
          this.eventApplication.prepQuizAnswers = resolution.map(x => x.answer);
          this.eventApplication.prepQuizAnswersList = new Array<PrepQuizAnswer>();
          for (let i = 0; i < resolution.length; i++) {
            this.eventApplication.prepQuizAnswersList.push({
              'answer': resolution[i].answer,
              'fileAsAnswer': resolution[i].fileAsAnswer || false
            });
          }

          this._apply(this.eventApplication);
        }
      });
    };

    if (this.event.prepQuizQuestionList) {
      let dialogVerification: MatDialogRef<SimpleDialogComponent> = null;

      if (isApproved) {
        dialogVerification = this._dialog.open(SimpleDialogComponent, {
          hasBackdrop: true,
          closeOnNavigation: false,
          disableClose: true,
          data: {
            // tslint:disable-next-line: max-line-length
            message: 'Submissão já enviada e aprovada pelo instrutor, caso queira refazer as questões, submeta novamente.\nDeseja continuar?',
            positiveTextAction: 'Sim',
            negativeTextAction: 'Não'
          } as SimpleDialog
        });
      }

      if (isNil(dialogVerification)) {
        createDialogSubscription();
      } else {
        dialogVerification.afterClosed().subscribe(isAccepted => {
          if (isAccepted) createDialogSubscription();
        });
      }

    } else {
      !isApproved ? this._apply(this.eventApplication) : this.notify('Já existe uma submissão para esse evento');
      return;
    }

  }

  private _apply(eventApplication: EventApplication): void {
    this._eventsService.applyToEvent(eventApplication).subscribe((response) => {
      if (response.success) {
        this.notify('Seu pedido foi enviado e esta sendo analizado');
        eventApplication.applicationStatus = ApplicationStatus.Pending;
        this.subscriptionStatus = 'Em Análise';
        this.showSubscriptionMessage.emit(true);
      }
    });
  }

  // public goToDashboard(event: MouseEvent) {
  //   event.stopPropagation();
  //   this._router.navigate([`instrutor/modulo/${this.module.id}`], { state: { module: this.module } });
  // }

  private _canSubscribe(): string | undefined {
    if (!this.currentSchedule) return undefined;
    const subscriptionEnd = new Date(
      this.currentSchedule.subscriptionEndDate.toString()
    );
    const currentTime = new Date();
    // const adjustedLimit = subscriptionEnd + 86400000;

    if (subscriptionEnd < currentTime)
      return 'O evento esta fora da data de inscrição.';

    if (this.event.requirements.length > 0) {
      const requirementReasonMessage = 'Necessário possuir os requisitos mínimos para realizar a inscrição';
      if (!this.progress) { return requirementReasonMessage; }

      const hasAllRequirements = this.event.requirements.every(req => {
        if (req.optional) return true;

        const progress: ModuleProgress = this.progress[req.moduleId];
        return (progress && progress.level > req.level) || progress && progress.isExempt;
      });

      return hasAllRequirements ? undefined : requirementReasonMessage;

    }
    return undefined;
  }

  public goEditEvent() {
    this.loadEvent(this.event)
      .pipe(catchError(_ => {
        this._router.navigate([`/configuracoes/evento/${this.event.id}`]);
        return _;
      }))
      .subscribe(response => {
        if(this.event.isDraft){
          this._router.navigate([`/configuracoes/evento/${response.data.id}/rascunho`]);
        } else {
          this._router.navigate([`/configuracoes/evento/${response.data.id}`]);
        }
      });
  }

  public canApply(): void {
    const exception = this._canSubscribe();
    if (exception) {
      this.notify(`Você não pode aplicar-se para esse evento.\n${exception}`, 'Fechar', { duration: 99999999 });
      return;
    }
    this.openSubscriptionDialog();
  }

  public openDialogForSupportMaterial() {
    this._dialog.open(MaterialDialogComponent, {
      maxWidth: '800px',
      data: {
        eventId: this.event.id,
        supportMaterials: this.event.supportMaterials
      } as MaterialDialog
    });
  }

  public loadEvent(event: Event){
    return event.isDraft 
    ? this._settingsEventsDraftsService.getEventDraftByEventId(event.id)
    : this._settingsEventsService.getEventById(event.id);
  }

}
