import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';

export class NotificationClass {

  constructor(protected _snackBar: MatSnackBar) { }

  protected notify(message: string, actionText: string = 'OK', config?: MatSnackBarConfig): MatSnackBarRef<any> {
    return this._snackBar.open(
      message,
      actionText,
      {
        ...config,
        panelClass: 'snackbar'
      },
    );
  }

  protected getErrorNotification(response): string {
    if (!response || !response.status)
      return 'Ocorreu um erro, por favor tente novamente mais tarde';

    switch (response.status) {
      case 401:
        return 'Você precisa estar logado para finalizar o cadastro';
      case 500:
        return 'Ocorreu um erro, por favor tente novamente mais tarde';
      default:
        if (response.error && response.error.errors && response.error.errors.length > 0)
          return response.error.errors[0];
        return 'Ocorreu um erro, por favor tente novamente mais tarde';
    }
  }
}
