import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyDataComponent } from './empty-data.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [EmptyDataComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [EmptyDataComponent]
})
export class EmptyDataModule { }
