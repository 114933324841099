<div class="inner-content">
  <div class="center-btn">
    <button class="btn-test" (click)="goToValuationGroup()">
      CRIAR GRUPO
    </button>
  </div>
  <div class="content-tests">
    <div class="filters">
      <app-filters-valuation-tests (setValuationTestTermFilter)="setValuationTestTermFilter($event)"
        [showTracks]="false" [showModules]="false" [showCreatedSince]="false">
      </app-filters-valuation-tests>
    </div>
    <div class="tests">
      <ng-container *ngIf="groups && groups.length > 0">
        <ng-container *ngFor="let group of groups; let index = index">
          <app-generic-card [data]="{ id:group.id, title: group.title}" [hasDelete]="true"
            (delete)="DeleteGroup($event.id)" (edit)="goToValuationGroup($event.id)">
            <ng-container first>
              <div class="content-info">
                <span class="valuation-count"> {{group.group.length}} avaliações </span>
                <!-- <span class="link" (click)="exportAnswersExcel(group)"> exportar grupo </span> -->
                <span class="link" (click)="chooseTemplateExportDialog(index)"> exportar grupo </span>

                <ng-template #templateChooseTemplateExportDialog let-value>
                  <div class="wrapper-export-dialog">
                    <header class="header-export-dialog">
                      <h3>Selecione o modelo de importação que você deseja: </h3>
                    </header>

                    <div class="buttons-wrapper">
                      <button class="btn-test"
                        (click)="exportAnswersExcel(group, exportTemplates.discursive_nps)">Discursiva/NPS</button>
                      <button class="btn-test"
                        (click)="exportAnswersExcel(group, exportTemplates.multiple_choice)">Multipla Escolha</button>
                    </div>
                  </div>
                </ng-template>

              </div>
            </ng-container>
          </app-generic-card>
        </ng-container>

      </ng-container>
      <p class="emptyState" *ngIf="groups && groups.length === 0">
        Ainda não há nenhum grupo criado.
      </p>
    </div>
    <app-pagination class="pagination" *ngIf="groups && groups.length > 0" [setPagination]=" {
            'itemsCount':itemsCount,
            'pageSize': pageSize
          }" (goToPage)="goToPage($event)"></app-pagination>
  </div>

</div>
