<div class="content">
    <div class="header">
      <h2>INCLUIR USUÁRIOS</h2>
    </div>
    <div class="inner-content">
      <div>
        <h4 class="subtitle">
          <span class="subtitle-title">USUÁRIOS</span>
          <button type="button" class="btn-test add-subject" (click)="addUser()">
            + Adicionar Usuário
          </button>
        </h4>
        <ng-container *ngFor="let userInfo of userInfos; let index = index">
          <div class="user-info">
            <div class="user-info-grid">
              <div class="user-info-id">
                <div class="user-info-name">
                  <mat-form-field class="form-field">
                    <input matInput placeholder="Nome" [(ngModel)]="userInfo.name" required />
                  </mat-form-field>
                  <mat-form-field class="form-field">
                    <input matInput placeholder="Sobrenome" [(ngModel)]="userInfo.lastName" required />
                  </mat-form-field>
                </div>
                <div class="user-info-name">
                  <mat-form-field class="form-field">
                    <input matInput placeholder="Email" [(ngModel)]="userInfo.email" required />
                  </mat-form-field>
                  <mat-form-field class="form-field">
                    <input matInput placeholder="Email do responsavel" [(ngModel)]="userInfo.responsibleEmail" />
                  </mat-form-field>
                </div>
              </div>
              <div class="user-info-text">
                <!--mat-form-field class="form-field text-field">
                  <textarea matInput placeholder="Mensagem" [(ngModel)]="userInfo.message"></textarea>
                </mat-form-field-->
                <img (click)="removeUser(index)" src="./assets/img/trash.png"/>
              </div>
            </div>
            <h3 *ngIf="userInfo.expirationTracks.length > 0">Expiração Trilhas</h3>
            <ng-container *ngFor="let expirationTrack of userInfo.expirationTracks; let i = index">
              <div class="expiration-container">
                <p>{{expirationTrack.title}}</p>
                <mat-form-field>
                  <input matInput [matDatepicker]="track_i" placeholder="Data de Expiração"
                  [(ngModel)]="expirationTrack.expirationDate"/>
                  <mat-datepicker-toggle matSuffix [for]="track_i"></mat-datepicker-toggle>
                  <mat-datepicker #track_i></mat-datepicker>
                </mat-form-field>
              </div>
            </ng-container>
            <h3 *ngIf="userInfo.expirationModules.length > 0">Expiração Modulos</h3>
            <ng-container *ngFor="let expirationModule of userInfo.expirationModules; let j = index">
              <div class="expiration-container">
                <p>{{expirationModule.title}}</p>
                <mat-form-field>
                  <input matInput [matDatepicker]="module_j" placeholder="Data de Expiração"
                  [(ngModel)]="expirationModule.expirationDate"/>
                  <mat-datepicker-toggle matSuffix [for]="module_j"></mat-datepicker-toggle>
                  <mat-datepicker #module_j></mat-datepicker>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="connect-container">
        <div>
          <h4>TRILHAS</h4>
          <app-generate-valuation-test-check-options 
            #checkedTrackComponent 
            (search)="triggerTrackSearch($event)"
            (selectedData)="handleTracksSelected($event)"
            [dataSource]="{data: tracks}"
            firstTitle="Trilhas Encontradas"
            secondTitle="Trilhas Adicionadas"
            [fixedSelected]="true"
          >
          </app-generate-valuation-test-check-options>
        </div>
        <div>
          <h4>MÓDULOS</h4>
          <app-generate-valuation-test-check-options 
            #checkedModuleComponent 
            (search)="triggerModuleSearch($event)"
            (selectedData)="handleModuleSelected($event)"
            [dataSource]="{data: modules}"
            firstTitle="Módulos Encontrados"
            secondTitle="Módulos Adicionados"
            [fixedSelected]="true"
          >
          </app-generate-valuation-test-check-options>
        </div>
      </div>
    </div>
    <div class="footer">
      <button type="button" class="btn-test add-subject" (click)="save()">
        Incluir
      </button>
    </div>
</div>
  