import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { Sector } from 'src/app/models/sector.model';

@Injectable({
    providedIn: 'root'
})
export class SectorService {

    constructor(
        private _httpService: BackendService) { }

    public GetAllSectors(): Observable<any> {
        return this._httpService.get('GetAllSectors', [], []);
    }

    public GetFilters(): Observable<any> {
        return this._httpService.get('GetFilters', [], []);
    }

    public GetPagedSector(page: number, pageSize: number): Observable<any> {
        return this._httpService.get('GetSectorsPaged', [], [
            { name: 'page', value: page.toString() },
            { name: 'pageSize', value: pageSize.toString() }
        ]);
    }

    public addSector(name: string, segmentsIds = []): Observable<BackendResponse<Sector>> {
        return this._httpService.post('addSector', {
            name,
            segmentsIds
        });
    }

    public getById(id: string): Observable<any> {
        return this._httpService.get('GetSectorById', [], [{
            name: 'id', value: id
        }]);
    }

    public updateSector(name: string, sectorId: string, segmentsIds = []): Observable<any> {
        return this._httpService.put('updateSector', {
            sectorId,
            name,
            segmentsIds
        });
    }

    public deleteSector(sectorId: string): Observable<any> {
        return this._httpService.delete('deleteSector', [{
            name: 'sectorId', value: sectorId
        }]);
    }
}
