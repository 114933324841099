import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfReference } from 'src/app/models/content.model';
import { ContentTypeEnum } from 'src/app/models/enums/content-type.enum';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-pdf-tag-dialog',
  templateUrl: './pdf-tag-dialog.component.html',
  styleUrls: ['./pdf-tag-dialog.component.scss']
})
export class PdfTagDialogComponent extends NotificationClass {

  public conceptName: string = '';
  public conceptDescription: string = '';
  public page: string = '';

  public selectedTab: number = 0;

  public moduleTags: any[] = [];
  public moduleTagValuations: any[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _moduleService: ContentModulesService,
    private _utilService: UtilService,
    public dialogRef: MatDialogRef<PdfTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(_snackBar);
    this.moduleTags = data.moduleTags;
    this.moduleTagValuations = data.moduleTagValuations;
  }

  public onTabSelected($event: MatTabChangeEvent) {
    this.selectedTab = $event.index;
  }

  public getOtherUserTags() {
    const contentTags = [];
    for (let i = 0; i < this.moduleTags.length; i++) {
      const moduleTag = this.moduleTags[i];
      if (moduleTag.userId !== this.data.userId) {
        contentTags.push(moduleTag);
      }
    }
    return contentTags;
  }

  public getConceptTag(position: number): string {
    switch (this.data.content.type) {
      case ContentTypeEnum.Pdf:
        return position.toString();
      default:
        return '';
    }
  }

  public getValuationStatus(tag: any, like: boolean) {
    const valuationIndex = this.moduleTagValuations.findIndex(x => x.moduleUserTagId === tag.id);
    if (valuationIndex !== -1) {
      const valuation = this.moduleTagValuations[valuationIndex];
      return valuation.like === like;
    }
    return null;
  }

  public valuateTag(tag: any, like: boolean) {
    this._moduleService.valuateModuleUserTag(tag.id, like).subscribe((res) => {
      const valuationIndex = this.moduleTagValuations.findIndex(x => x.moduleUserTagId === tag.id);
      if (valuationIndex !== -1) {
        const valuation = this.moduleTagValuations[valuationIndex];
        valuation.like = like;
      } else {
        this.moduleTagValuations.push(res.data);
      }
    });
  }

  public save() {
    if (!this.conceptName || !this.page || !this.conceptDescription) {
      this.notify('Preencha todos os campos obrigatórios');
      return;
    }
    const contentId = this.data.content.id;
    const subjectId = this.data.subjectId;
    const moduleId = this.data.moduleId;
    const concept: PdfReference = {
      name: this.conceptName,
      positions: [this.page]
    };
    this._moduleService.addModuleUserTag(moduleId, subjectId, contentId,
      concept, this.conceptDescription).subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
