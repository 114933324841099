export enum ValuationTestTypeEnum {
  Percentile = 1,
  Free = 2,
  Coursework = 3,
  Mock = 4
}

export enum ValuationTestModuleTypeEnum {
  BeforeModule = 1,
  AfterModule = 2,
  Coursework = 3,
}

export enum ValuationTestTypeGeneredEnum {
  Manually = 1,
  Automatically = 2,
}

export enum ValuationScheduleTypeEnum {
  Diagnostic = -1,
  Valuation = 2,
  Register = 3,
  NPS = 4,
}
