import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ProgressBarModule } from '../layout/progress-bar/progress-bar.module';
import { ModuleSidebarComponent } from './module-sidebar.component';
import { DirectivesModule } from '../../directives/directives.module';
import { ForumQuestionCardModule } from 'src/app/pages/forum/question-card/question-card.module';
import { ForumQuestionDialogModule } from 'src/app/shared/dialogs/forum-question/forum-question.module';
import { EventForumQuestionDialogModule } from '../../dialogs/event-forum-question/event-forum-question.module';
import { SidebarEventApplicationNoteDialogComponent } from './sidebar-event-application-note/sidebar-event-application-note.dialog';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        ModuleSidebarComponent,
        SidebarEventApplicationNoteDialogComponent
    ],
    imports: [
        BrowserModule,
        ProgressBarModule,
        MatDialogModule,
        DirectivesModule,
        ForumQuestionCardModule,
        ForumQuestionDialogModule,
        EventForumQuestionDialogModule,
        PipesModule
    ],
    exports: [
        ModuleSidebarComponent
    ]
})
export class ModuleSidebarModule { }
