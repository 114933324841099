<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      voltar
    </a>
  </nav>

  <div class="header">
    <h2>
      <p>Trilha do curso</p>
      {{ track?.title }}
    </h2>
    <div class="info">
      <p>
        <span>Duração Total</span><br>
        {{ getFormattedHour() }} h
      </p>
      <p>
        <span>Turma</span><br>
        {{ track?.studentsCount }} alunos
      </p>
    </div>
    <p>
      <span>Notas atualizadas diariamente</span>
      <br>
      às 00:00h
    </p>
  </div>

  <div class="subheader">
    <h3>{{ track?.moduleTitle }}</h3>
    <p>MÓDULO ONLINE</p>
  </div>
<ng-container *ngIf="track?.modulesConfiguration[0].hasQuestions">
  <app-track-overview-badges
    [progresses]="track?.studentsProgress"
    [modulesConfiguration]="track?.modulesConfiguration"
    [students]="track?.students"></app-track-overview-badges>

  <div class="content" *ngIf="track">
    <app-track-overview-performance-radar
      [title]="'PERFORMANCE DA TURMA NOS ASSUNTOS'"
      [labels]="getRadarLabels()"
      [titleCallback]="getRadarTitleCallback.bind(this)"
      [dataset]="getRadarDataset()"
      [tooltipCallback]="getRadarTooltipCallback.bind(this)"
      [showLegends]="false"></app-track-overview-performance-radar>

    <app-track-overview-content-views
      [contents]="track?.viewedContents"
      [studentsCount]="track?.studentsCount"></app-track-overview-content-views>

    <app-track-overview-wrong-concepts
      [concepts]="track?.wrongConcepts"
      [showAll]="false"
      emptyStateEntity="módulo"></app-track-overview-wrong-concepts>
  </div>

  <app-track-overview-students-progress
    [students]="track?.students"
    [moduleName]="track?.moduleTitle"></app-track-overview-students-progress>
</ng-container>
</div>
