import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackModule } from 'src/app/models/track-module.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cockpit-modules-progress',
  templateUrl: './cockpit-modules-progress.component.html',
  styleUrls: ['./cockpit-modules-progress.component.scss']
})
export class CockpitModulesProgressComponent {

  @Input() modules: Array<TrackModule>;
  @Output() goToModule = new EventEmitter<TrackModule>();
  constructor(private _router: Router) { }



}
