import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CommunicationService } from '../../_services/communications.service';
import { Communication } from '../Models/communication.model';

import Editor from '@toast-ui/editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

@Component({
  selector: 'app-communication-dialog',
  templateUrl: './communication-dialog.component.html',
  styleUrls: ['./communication-dialog.component.scss']
})
export class CommunicationDialogComponent implements OnInit {

  public formGroup: FormGroup;
  public editor: Editor;
  constructor(
    public dialogRef: MatDialogRef<CommunicationDialogComponent>,
    private _communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (!this.data.id) {
      this.formGroup = this._createFormGroup();
      setTimeout(() => {
        this.editor = this.createEditor();
      }, 100);
    } else {
      setTimeout(() => {
        this._communicationService.getCommunicationById(this.data.id).subscribe(communication => {
          this.formGroup = this._createFormGroup(communication.data);
          setTimeout(() => {
            this.editor = this.createEditor();
            this.editor.setMarkdown(communication?.data?.text || '');
          }, 100);
        });
      }, 0);
    }


  }

  public createEditor(): Editor {
    return new Editor({
      el: document.querySelector('#htmlEditor'),
      initialEditType: 'wysiwyg',
      hideModeSwitch: true,
      previewStyle: 'vertical',
      height: '200px',
      plugins: [colorSyntax],
    });


  }
  public save(): void {
    if (this.formGroup.valid) {
      const communication = this.formGroup.getRawValue() as Communication;
      communication.text = this.editor.getHTML();
      if (!this.data.id) {
        this._communicationService.createCommunications(
          communication.title, communication.text, communication.startDate, communication.endDate)
          .subscribe(communicationId => {
            communication.id = communicationId.data;
            this.dialogRef.close(communication);
          });
      } else {
        this._communicationService.updateCommunications(
          this.data.id, communication.title, communication.text, communication.startDate, communication.endDate)
          .subscribe(upadatedCommunication => {
            this.dialogRef.close();
          });
      }
    }
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  private _createFormGroup(communication: Communication = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(communication ? communication.title : '', [Validators.required]),
      'startDate': new FormControl(communication ? communication.startDate : '', [Validators.required]),
      'endDate': new FormControl(communication ? communication.endDate : '', [Validators.required]),
    });
  }
}
