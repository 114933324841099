import { NgModule } from '@angular/core';
import { DebugPipe } from 'projects/debug/src/lib/debug.pipe';
import { DebugComponent } from './debug.component';

@NgModule({
  declarations: [DebugComponent, DebugPipe],
  imports: [
  ],
  exports: [DebugComponent, DebugPipe]
})
export class DebugModule { }
