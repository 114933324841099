<div id="ClassroomLesson">
  <ng-template #EmptyValuationTemplate let-test>
    <ng-container *ngIf="!test.answered">
      <div class="empty-valuation-wrapper" *ngIf="!test.isAvailable && test.hasStarted">
        <span>{{test.title}}</span>
        <b>{{test.statusText}}</b>
      </div>
      <div class="empty-valuation-wrapper" *ngIf="!test.hasStarted">
        <span>{{test.title}}</span>
        <b>Aguardando início</b>
      </div>
    </ng-container>
  </ng-template>

  <div class="inner-content">
    <nav>
      <a href="javascript:history.back()">
        <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
        Voltar
      </a>
      <h2>{{ event?.title }}</h2>
      <a></a>
    </nav>

    <app-classroom-lesson-header [setEvent]="event" [setEventApplication]="eventApplication" [progress]="moduleProgress"
      (showSubscriptionMessage)="showNotification()"></app-classroom-lesson-header>

    <div class="content">
      <div class="main">

        <p class="webinar" *ngIf="showWebinar()">
          <span>
            ACESSE O WEBINAR
          </span>
          <span>
            <span class="event-adress">
              {{ getWebinarUrl() }}
            </span>
            <button class="btn-test primary" (click)="goToEvent()">
              ir para o evento
            </button>
          </span>
        </p>

        <div id="videoContent" style="width: 100%; visibility: hidden;"></div>

        <div *ngIf="event && videoUrlIsValid(event.videoUrl)">
          <p class="title">
            <span>Por que Fazer Este Curso?</span>
            <small>{{ getVideoDuration() }} minuto(s)</small>
          </p>
          <iframe class="videoIframe" [src]=" event.videoUrl | safeUrl" height="400" width="100%" frameborder="0"
            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
        <div class="subscription-notif" *ngIf="showSubscriptionMessage">
          <img src="./assets/img/close.png" (click)="dismissNotification()" />
          <h4>SUA APLICAÇÃO FOI RECEBIDA E SERÁ ANALISADA!</h4>
          <p>Vamos analisar suas respostas e logo sua inscrição será concluída.</p>
        </div>

        <ng-container *ngIf="!eventApplication?.finalGrade && event?.requirements && event?.requirements.length > 0">
          <h3>PRÉ-REQUISITOS DESTE EVENTO</h3>
          <app-event-required-module *ngFor="let requirement of event.requirements; let last = last"
            [requirement]="requirement" [levels]="levelDict" [last]="last"
            [progress]="moduleProgress ? moduleProgress[requirement.moduleId] : null" (click)="goToModule(requirement)"
            style="cursor: pointer;"></app-event-required-module>
        </ng-container>
        <div class="tests" *ngIf="testsRegister.length > 0">
          <div class="header">
            <p>
              Inscreva-se aqui
            </p>
          </div>
          <div class="test-content" *ngIf="testsRegisterExpanded">
            <div class="level" *ngFor="let test of testsRegister">
              <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">

                <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                  <p *ngIf="levels">
                    {{test.title}}
                  </p>
                  <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                    class="btn-test test-disabled-button" *ngIf="test.answered"
                    (click)="viewQuestionsAndAnswersDialog(test)">
                    Ver Respostas
                  </button>
                  <button class="btn-test" *ngIf="!test.answered" (click)="goToTest(test)"
                    [attr.title]="!eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved ? disableTestMessage : null"
                    [disabled]="eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved || test.answered || test.isDisabled">
                    Fazer o teste
                  </button>
                </div>
                <p class="grade" *ngIf="test.answered">
                  <span>Nota </span>
                  <span>{{calculateGrade(test)}}</span>
                </p>
              </ng-container>
              <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
              </ng-container>
            </div>
          </div>
          <div class="expand" (click)="testsRegisterExpanded = !testsRegisterExpanded"
            [ngClass]="{ 'moduleTestsBeforeExpanded' : testsRegisterExpanded }">
            <span *ngIf=!testsRegisterExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=testsRegisterExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>
        <div class="tests" *ngIf="testsBefore.length > 0">
          <div class="header">
            <p>
              Avaliação preliminar diagnóstica
            </p>
          </div>
          <div class="test-content" *ngIf="testsBeforeExpanded">
            <div class="level" *ngFor="let test of testsBefore">
              <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">

                <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                  <p *ngIf="levels">
                    {{test.title}}
                  </p>
                  <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                    class="btn-test test-disabled-button" *ngIf="test.answered"
                    (click)="viewQuestionsAndAnswersDialog(test)">
                    Ver Respostas
                  </button>
                  <button class="btn-test" *ngIf="!test.answered" (click)="goToTest(test)"
                    [attr.title]="!eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved ? disableTestMessage : null"
                    [disabled]="eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved || test.answered || test.isDisabled">
                    Fazer o teste
                  </button>
                </div>
                <p class="grade" *ngIf="test.answered">
                  <span>Nota </span>
                  <span>{{calculateGrade(test)}}</span>
                </p>
              </ng-container>
              <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
              </ng-container>
            </div>
          </div>
          <div class="expand" (click)="testsBeforeExpanded = !testsBeforeExpanded"
            [ngClass]="{ 'moduleTestsBeforeExpanded' : testsBeforeExpanded }">
            <span *ngIf=!testsBeforeExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=testsBeforeExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>
        <div class="tests" *ngIf="testsAfter.length > 0">
          <div class="header">
            <p>
              Avaliação final somativa
            </p>
          </div>
          <div class="test-content" *ngIf="testsAfterExpanded">
            <div class="level" *ngFor="let test of testsAfter">
              <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">
                <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                  <p *ngIf="levels">
                    {{test.title}}
                  </p>
                  <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                    class="btn-test test-disabled-button" *ngIf="test.answered"
                    (click)="viewQuestionsAndAnswersDialog(test)">
                    Ver Respostas
                  </button>
                  <button class="btn-test" *ngIf="!test.answered" (click)="goToTest(test)"
                    [attr.title]="!eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved ? disableTestMessage : null"
                    [disabled]="eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved || test.answered || test.isDisabled">
                    Fazer o teste
                  </button>
                </div>
                <p class="grade" *ngIf="test.answered">
                  <span>Nota
                  </span>
                  <span>{{calculateGrade(test)}}</span>
                </p>
              </ng-container>
              <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
              </ng-container>
            </div>
          </div>
          <div class="expand" (click)="testsAfterExpanded = !testsAfterExpanded"
            [ngClass]="{ 'moduleTestsAfterExpanded' : testsAfterExpanded }">
            <span *ngIf=!testsAfterExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=testsAfterExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>
        <div class="tests" *ngIf="testsNps.length > 0">
          <div class="header">
            <p>
              NPS
            </p>
          </div>
          <div class="test-content" *ngIf="testsNpsExpanded">
            <div class="level" *ngFor="let test of testsNps">
              <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">
                <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                  <p *ngIf="levels">
                    {{test.title}}
                  </p>
                  <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                    class="btn-test test-disabled-button" *ngIf="test.answered"
                    (click)="viewQuestionsAndAnswersDialog(test)">
                    Ver Respostas
                  </button>
                  <button class="btn-test" *ngIf="!test.answered" (click)="goToTest(test)"
                    [attr.title]="!eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved ? disableTestMessage : null"
                    [disabled]="eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved || test.answered || test.isDisabled">
                    Fazer o teste
                  </button>
                </div>
                <p class="grade" *ngIf="test.answered">
                  <span>Nota
                  </span>
                  <span>{{calculateGrade(test)}}</span>
                </p>
              </ng-container>
              <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
              </ng-container>
            </div>
          </div>
          <div class="expand" (click)="testsNpsExpanded = !testsNpsExpanded"
            [ngClass]="{ 'moduleTestsAfterExpanded' : testsNpsExpanded }">
            <span *ngIf=!testsNpsExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=testsNpsExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>
        <div class="tests" *ngIf="testsResearch.length > 0">
          <div class="header">
            <p>
              Pesquisas
            </p>
          </div>
          <div class="test-content" *ngIf="testsResearchExpanded">
            <div class="level" *ngFor="let test of testsResearch">
              <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">
                <p *ngIf="levels">
                  {{test.title}}
                </p>
                <button class="btn-test" (click)="goToTest(test)"
                  [attr.title]="!eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved ? disableTestMessage : null"
                  [disabled]="!eventApplication.applicationStatus !== this.EnumApplicationStatus.Approved || disablePercentButtonLogic(test, 1,  2)">
                  {{
                  disablePercentButtonLogicText(test.answered, 1, test, 2)
                  }}
                </button>
              </ng-container>
              <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
              </ng-container>
            </div>
          </div>
          <div class="expandResearch" (click)="testsResearchExpanded = !testsResearchExpanded"
            [ngClass]="{ 'moduleTestsResearchExpanded' : testsResearchExpanded }">
            <span *ngIf=!testsResearchExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=testsResearchExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>


        <div class="final-grade" *ngIf="eventScheduleCompleted(eventApplication)">
          <div class="info">
            <p>
              SUA AVALIAÇÃO<br>
              <small>
                avaliação referente ao curso presencial
                {{
                  getGradedSchedule(eventApplication.scheduleId) ?
                    'realizado em ' + (getGradedSchedule(eventApplication.scheduleId) | date : 'dd/MM/yyyy' ) : ''
                }}
              </small>
            </p>
            <button class="btn-test" (click)="generateCertificatePDF()" *ngIf="event.certificateUrl">
              Gerar Certificado
            </button>
          </div>
          <div class="grade">
            <p>NOTA FINAL</p>
            <div>
              {{ eventApplication?.finalGrade }}
            </div>
          </div>
        </div>

      </div>

      <app-module-sidebar [module]="event" [isEvent]="true" [eventApplication]="eventApplication"
        [disabledQuestionBtn]="disabledQuestionBtn" [forumQuestionsPreview]="forumQuestionsPreview"
        (reloadForumQuestionsPreview)="loadEventForumPreview($event)"></app-module-sidebar>
    </div>
  </div>
</div>
