<div class="step">
  <h2>
    Conteúdos
    <small>Arraste e solte para ordenar os conteúdos</small>
  </h2>

  <div style="margin-bottom: 20px;">
    <a class="link-button" href="./../../../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_CONTEUDOS.xlsx"
      download="Planilha_Modelo_de_Importação_dos_Conteudos.csv">
      BAIXAR MODELO
    </a>
    <button class="btn-test primary" style="margin-left: 10px;" (click)="openUploadDialog(module)">
      IMPORTAR EXCEL
    </button>
    <button class="btn-test primary" style="margin-left: 10px;" (click)="exportContents()">
      EXPORTAR EXCEL
    </button>
  </div>

  <mat-tab-group>
    <mat-tab [label]="subject?.title" *ngFor="let subject of module?.subjects">
      <div cdkDropList (cdkDropListDropped)="droppedContent(subject, $event)">

        <div cdkDrag class="content" *ngFor="let content of subject.contents; let index = index">
          <img class="trash" src="./assets/img/trash.png" (click)="removeContent(subject, index)" />
          <mat-form-field>
            <input matInput placeholder="Nome" [(ngModel)]="content.title" required />
          </mat-form-field>

          <mat-form-field>
            <mat-select placeholder="Tipo" [(ngModel)]="content.type" required>
              <mat-option [value]="contentTypeEnum.Video">Vídeo</mat-option>
              <mat-option [value]="contentTypeEnum.Pdf">PDF</mat-option>
              <mat-option [value]="contentTypeEnum.Text">Texto</mat-option>
              <mat-option [value]="contentTypeEnum.Zip">Zip</mat-option>
              <mat-option [value]="contentTypeEnum.XLSX">XLSX</mat-option>
              <mat-option [value]="contentTypeEnum.Scorm">SCORM</mat-option>
              <mat-option [value]="contentTypeEnum.Link">Link</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="content.type === contentTypeEnum.Video;">
            <input matInput placeholder="Tempo (hh:mm:ss)" mask="00:00:00"
              (ngModelChange)="setDuration(content, $event)" [(ngModel)]="content.formatedDuration" required />
          </mat-form-field>

          <button class="btn-test primary" (click)="openConfigDialog(content, subject)">
            Configurar
          </button>
        </div>
      </div>

      <button class="btn-test add-content" (click)="addContent(subject)">
        + Adicionar Conteúdo
      </button>
    </mat-tab>
  </mat-tab-group>
</div>
