import { NgModule } from '@angular/core';
import { SaveActionDirective } from './save-action/save-action.directive';
import { AnalyticsService } from '../services/analytics.service';
import { DebounceClickDirective } from './double-click/double-click.directives';

@NgModule({
  declarations: [SaveActionDirective, DebounceClickDirective],
  exports: [SaveActionDirective, DebounceClickDirective],
  providers: [AnalyticsService],
})
export class DirectivesModule {}
