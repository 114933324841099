import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { ImportExcelFilesComponent } from "src/app/shared/components/import-excel-files/import-excel-files.component";
import { IErroList } from 'src/app/shared/components/import-excel-files/Models/erro-list.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { mandatoryCollumnEnum } from './enums/mandatoryCollumn';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './../../../../../shared/components/import-excel-files/import-excel-files.component.html',
  styleUrls: ['./../../../../../shared/components/import-excel-files/import-excel-files.component.scss']
})
export class ImportDialogComponent extends ImportExcelFilesComponent {
  public pathTemplate: string = "./../../../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_ALUNOS_EXISTENTE.xlsx";
  public downloadName: string = "PLANILHA_MODELO_IMPORTAÇÃO_ALUNOS_EXISTENTE.xlsx";
  public loading: boolean = false;
  public erroList: IErroList[];
  public name: string = 'usuários';
  public subtitle: string;
  public showRadionButtons: boolean = false;
  public selectUsers: string[] = [];

  public get mandatoryCollumns(): string[] {
    return [
      mandatoryCollumnEnum.email
    ];
  }
  constructor(_snackBar: MatSnackBar,
    _excelService: ExcelService,
    public dialogRef: MatDialogRef<ImportDialogComponent>,
    private _usersService: SettingsUsersService,
  ) {
    super(_excelService, _snackBar);

  }

  public dismiss() {
    this.dialogRef.close();
  }
  public validateJson(contentJson: any, headers: any): IErroList[] {
    let index = 0;
    const erroList: IErroList[] = [];

    const validateEmail = (email) => email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    while (index < contentJson.length) {
      const excelContent = contentJson[index];

      if (isNil(excelContent[mandatoryCollumnEnum.email])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.email),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      } else if (!validateEmail(excelContent[mandatoryCollumnEnum.email])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.email),
          row: excelContent['row'],
          description: 'Este campo não possui um formato de email válido'
        });
      }

      index++;
    }
    return erroList;
  }
  public setValues(contentJson: any, headers: any): void {
    let index = 0;
    while (index < contentJson.length) {
      const excelContent = contentJson[index];
      this.selectUsers.push(excelContent[mandatoryCollumnEnum.email]);
      index++;
    }
  }
  public save(): void {
    this.loading = true;
    this._usersService.GetUsersByEmail(this.selectUsers).subscribe(response => {
      const users = response.data.map(user => ({
        id: user.id,
        title: user.name,
        imageUrl: user.imageUrl,
      }));
      this.loading = false;
      this.dialogRef.close(users);
    }, error => {
      this.loading = false;
      error.error.errors.forEach(err => (
        this.erroList.push({ description: err })
      ));
    });
  }

}