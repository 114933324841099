import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ExamQuestionComponent } from './exam-question/exam-question.component';
import { ContentExamService } from '../_services/exam.service';
import { UserService } from '../_services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';
import { Module } from '../../models/module.model';
import { Subject } from '../../models/subject.model';
import { Answer, Question } from 'src/app/models/question.model';
import { Content } from 'src/app/models/content.model';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ActionInfoPageEnum, ActionInfoTypeEnum, ActionInfo } from 'src/app/shared/directives/save-action/action-info.interface';
import { Level } from 'src/app/models/shared/level.interface';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ExamDialogBlockedComponent } from './exam-dialog-blocked/exam-dialog-blocked.component';

// ROLLBACK BDQ
@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent extends NotificationClass implements OnInit, OnDestroy {

  @ViewChild('questions') questionsComponent: ExamQuestionComponent;

  public conceptContents: Array<Content> = [];
  public currentQuestion: Question;
  public canMoveToNextQuestion: boolean = false;
  public examFinished: boolean = false;
  public reviewingConcept: any;

  public questionWidth: number = 50;
  public reviewWidth: number = 50;
  private _questionDiff: number = 0;
  private _reviewDiff: number = 0;
  private subjectId: string;
  private levels: Array<Level>;
  public levelDict: {};
  public userProgress: any;
  private module: Module;
  public subject: Subject;
  public answerResult: Answer;
  public cutOffDateWarningText: string;

  private nextQuestion: any;
  private _confirmingAnswer: boolean = false;
  private actionId: string;
  private questionActionId: string;
  private reviewActionId: string;
  private _examStarted: boolean = false;
  private _trackId: string;
  private newLevel: any = null;
  private _answersCount: number = 0;
  private _canGetMixedQuestion: boolean = false;
  private _wasBdqBlocked: boolean = false;

  public hasComment: boolean = environment.features.questionComment;
  public showComment: boolean = false;
  public isMock: boolean = false;

  public hasFeaturePracticeQuestions = environment.features.practiceQuestions;
  public isPracticing: boolean = false;

  constructor(
    protected _snackBar: MatSnackBar,
    private _examService: ContentExamService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private _dialog: MatDialog
  ) {

    super(_snackBar);
  }

  ngOnInit() {
    document.body.classList.add('hide-inbenta');
    this._trackId = this._activatedRoute.snapshot.queryParams['trackId'];

    this._activatedRoute.data.subscribe(data => {
      this._loadLevels(data.levels);
      this.subjectId = this._activatedRoute.snapshot.paramMap.get('subjectId');
      this.module = data.module;
      this.subject = this.module.subjects.find(x => x.id === this.subjectId);
      if (this.module.moduleConfiguration) {
        const cutOffDate = this.module.moduleConfiguration.cutOffDate;
        const formattedCutOffDate = moment(cutOffDate).format('DD/MM/YYYY HH:mm:ss');
        if (cutOffDate)
          this.cutOffDateWarningText = `Para efeito de cálculo de nota, serão consideradas as respostas até: ${formattedCutOffDate}`;
      }
      this._setExamStartAction();
      this._loadUserSubjectProgress(this.module.id, this.subjectId);
    });

  }
  ngOnDestroy() {
    if (this.actionId)
      this._saveWaitingAction(this.actionId);
    if (this.questionActionId)
      this._saveWaitingAction(this.questionActionId);
    if (this.reviewActionId)
      this._saveWaitingAction(this.reviewActionId);
    if (this.isPracticing && !this.examFinished) {
      localStorage.setItem(
        `practice-exam-${this.module.id}-${this.subjectId}`,
        JSON.stringify(this.currentQuestion)
      );
    }

    document.body.classList.remove('hide-inbenta');

  }

  public startExam(): void {
    if (!this._examStarted) {
      this._examStarted = true;
      this._examService.startExam(this.module.id, this.subjectId).subscribe(response => {
        this.currentQuestion = response.data;
        this._setExamQuestionStartAction();
        this.canMoveToNextQuestion = false;
        this.examFinished = false;
        this._examStarted = false;
        if (this.isPracticing) {
          this.userProgress.progress = (this.currentQuestion.index + 1) / this.currentQuestion.totalQuestions;
        }
      }, (error) => {
        this.notify(
          this.getErrorNotification(error)
        );
      });
    }
  }

  public goToNextQuestion(): void {
    if (this.hasComment && this.currentQuestion.comment) {
      this.showComment = false;
    }

    if (this._wasBdqBlocked) {
      this.showDialogBdqBlocked();
      return;
    }

    !this.nextQuestion ? this._finishExam() : this._continueExam();
  }

  public navigateQuestion(previous: boolean = false): void {
    const _trackId = this._activatedRoute.snapshot.queryParams['trackId'];
    this._examService.navigatePracticeQuestion(
      this.module.id, this.subjectId, this.currentQuestion.id, _trackId, previous
    ).subscribe(response => {
      if (response.data.answers === null) {
        this.notify('Navegação não permitida');
      } else {
        this.nextQuestion = response.data;
        this.goToNextQuestion();
      }
    }, err => {
      this.notify(this.getErrorNotification(err));
    });
  }

  public showDialogBdqBlocked() {
    const dialogRef = this._dialog.open(ExamDialogBlockedComponent);

    dialogRef.afterClosed().subscribe(() => {
      const extras: NavigationExtras = this._trackId ? { queryParams: { trackId: this._trackId } } : {};
      this._router.navigate(['modulo/' + this.module.id], extras);
    });
  }

  public confirmAnswer(answer: Answer): void {
    if (!this._confirmingAnswer) {
      this._confirmingAnswer = true;

      if (this.hasComment && this.currentQuestion.comment) {
        this.showComment = true;
      }

      this._answersCount++;
      if (this._answersCount === 4 && !this._canGetMixedQuestion) {
        this._canGetMixedQuestion = true;
      } else if (this.currentQuestion.isMixedQuestion) {
        this._canGetMixedQuestion = false;
        this._answersCount = 0;
      }

      const concepts = answer.concepts ? answer.concepts.map(c => c.concept) : [];
      const _trackId = this._activatedRoute.snapshot.queryParams['trackId'];
      this._examService.answerQuestion(
        this.module.id, this.subjectId, this.currentQuestion.id, answer.id,
        this.module.title, concepts, _trackId, false, this.currentQuestion.isMixedQuestion, this._canGetMixedQuestion,
        this.currentQuestion.type
      ).subscribe(response => {
        this._setExamQuestionFinishAction();
        this._wasBdqBlocked = response.data.bdqBlocked;

        answer.isRight = response.data.hasAnsweredCorrectly;
        answer.concepts = response.data.concepts;
        if (this.isPracticing) {
          answer.correctAnswerId = response.data.correctAnswerId;
        }
        this.answerResult = answer;
        this.nextQuestion = response.data.nextQuestion;

        if (response.data.hasAchievedNewLevel) {
          if (this.hasComment && this.currentQuestion.comment) {
            this.newLevel = response.data.levelAchieved +
              (response.data.progress === 1 && response.data.levelAchieved === 3 ? 1 : 0);
          } else {
            this.userProgress.level = response.data.levelAchieved +
              (response.data.progress === 1 && response.data.levelAchieved === 3 ? 1 : 0);
            this.nextQuestion = null;
            this.answerResult = null;
            this._setAchievedNewLevelAction();
            this._finishExam();
          }
        }

        if (this.isPracticing) {
          this.userProgress.progress = (this.currentQuestion.index + 1) / this.currentQuestion.totalQuestions;
        } else {
          this.userProgress.progress = response.data.progress;
        }

        this.canMoveToNextQuestion = true;
        this._confirmingAnswer = false;
      }, err => {
        this.notify(this.getErrorNotification(err));
        this._confirmingAnswer = false;
      });
    }
  }

  public skippedQuestion() {
    const _trackId = this._activatedRoute.snapshot.queryParams['trackId'];
    this._examService.answerQuestion(
      this.module.id, this.subjectId, this.currentQuestion.id, '',
      this.module.title, [], _trackId, true
    ).subscribe(response => {
      this.nextQuestion = response.data.nextQuestion;
      this.canMoveToNextQuestion = true;
      this.goToNextQuestion();

    }, err => {
      this.notify(this.getErrorNotification(err));
      this._confirmingAnswer = false;
    });
  }

  public openReview(concept: string): void {
    if (this.subject && this.subject.contents && concept !== this.reviewingConcept) {
      this.subject.contents.forEach(subjectContent => {
        if (subjectContent.concepts && subjectContent.concepts.find(x => x.name === concept))
          this.conceptContents.push(subjectContent);
      });
      this.reviewingConcept = concept;
      this._setReviewStartAction(concept);
    }
  }

  public closeReview(): void {
    this.reviewingConcept = null;
    this.conceptContents = [];
    this._setReviewFinishAction();
  }

  public resizeWindows(offset: number): void {
    const totalWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth;
    const halfWidth = totalWidth / 2;

    let questionWidth = (50 * (halfWidth - offset)) / halfWidth;
    let reviewWidth = (50 * (halfWidth + offset)) / halfWidth;

    questionWidth = questionWidth + this._questionDiff;
    reviewWidth = reviewWidth + this._reviewDiff;

    if (questionWidth < 30) {
      this.questionWidth = 30;
      this.reviewWidth = 70;
    } else if (reviewWidth < 30) {
      this.questionWidth = 70;
      this.reviewWidth = 30;
    } else {
      this.questionWidth = questionWidth;
      this.reviewWidth = reviewWidth;
    }
  }

  public setFinalOffset(): void {
    this._questionDiff = this.questionWidth - 50;
    this._reviewDiff = this.reviewWidth - 50;
  }

  public backToModule() {
    this._goToModule(this.module.id);
  }
  public finish() {
    this._goToModule(this.module.id);
  }

  private _goToModule(moduleId: string) {
    const hasLinkedTrack = !!this._trackId;
    const extras: NavigationExtras = hasLinkedTrack ? { queryParams: { trackId: this._trackId } } : {};
    return this._router.navigate(['/modulo/' + moduleId], extras);
  }

  private _finishExam(): void {
    if (this.newLevel) {
      this.userProgress.level = this.newLevel;
      this.nextQuestion = null;
      this.answerResult = null;
      this._setAchievedNewLevelAction();
      this.newLevel = null;
    }
    this.examFinished = true;
    this._setExamFinishAction();
  }

  private _continueExam(): void {
    this.currentQuestion = this.nextQuestion;
    this.nextQuestion = null;
    this.answerResult = null;
    this.canMoveToNextQuestion = false;
    this._setExamQuestionStartAction();

    if (this.questionsComponent) {
      this.questionsComponent.resetAnswer();
    }
    if (this.isPracticing) {
      this.userProgress.progress = (this.currentQuestion.index + 1) / this.currentQuestion.totalQuestions;
    }
  }

  private _loadLevels(levels): void {
    this.levels = levels;
    this.levelDict = {};
    this.levels.forEach(level => {
      this.levelDict[level.id] = level.description;
    });
  }

  private _loadUserSubjectProgress(moduleId, subjectId) {
    this._userService.getUserSubjectProgress(moduleId, subjectId).subscribe((response) => {
      this.userProgress = response.data;
      if (this.userProgress.level === 4) {
        if (this.hasFeaturePracticeQuestions && this.module.practiceQuestions) {
          this.isPracticing = true;
          const lastQuestion = localStorage.getItem(`practice-exam-${this.module.id}-${this.subjectId}`);
          if (lastQuestion) {
            this.currentQuestion = JSON.parse(lastQuestion);
            localStorage.removeItem((`practice-exam-${this.module.id}-${this.subjectId}`));
            this.userProgress.progress = (this.currentQuestion.index + 1) / this.currentQuestion.totalQuestions;
          }
        } else {
          this.notify('Você já atingiu o nível máximo neste assunto.');
          this.finish();
        }
      }
    });
  }

  /* Actions Methods */
  private _setExamStartAction(): void {
    const actionInfo = this._getActionInfo('exam-start', ActionInfoTypeEnum.Access);
    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      const waitingAction = this._getActionInfo('exam-leave', ActionInfoTypeEnum.CloseTab);
      this.actionId = this._analyticsService.setWaitingAction(waitingAction);
    },
      (error) => { console.error(error); });
  }

  private _setExamFinishAction(): void {
    const actionInfo = this._getActionInfo('exam-finish', ActionInfoTypeEnum.Finish);
    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      this._analyticsService.clearWaitingAction(this.actionId);
      this.actionId = null;
    },
      (error) => { console.error(error); });
  }

  private _setExamQuestionStartAction(): void {
    const actionInfo = this._getActionInfo('exam-question-start', ActionInfoTypeEnum.Access);
    actionInfo.questionId = this.currentQuestion.id;

    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      const waitingAction = this._getActionInfo('exam-question-leave', ActionInfoTypeEnum.CloseTab);
      waitingAction.questionId = this.currentQuestion.id;
      this.questionActionId = this._analyticsService.setWaitingAction(waitingAction);
    },
      (error) => { console.error(error); });
  }

  private _setExamQuestionFinishAction(): void {
    const actionInfo = this._getActionInfo('exam-question-finish', ActionInfoTypeEnum.Finish);
    actionInfo.questionId = this.currentQuestion.id;

    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      this._analyticsService.clearWaitingAction(this.questionActionId);
      this.questionActionId = null;
    },
      (error) => { console.error(error); });
  }

  private _setReviewStartAction(concept: string): void {
    const actionInfo = this._getActionInfo('exam-concept-review-start', ActionInfoTypeEnum.Access);
    actionInfo.concept = concept;
    actionInfo.questionId = this.currentQuestion.id;

    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      const waitingAction = this._getActionInfo('exam-concept-review-leave', ActionInfoTypeEnum.CloseTab);
      waitingAction.concept = concept;
      waitingAction.questionId = this.currentQuestion.id;
      this.reviewActionId = this._analyticsService.setWaitingAction(waitingAction);
    },
      (error) => { console.error(error); });
  }

  private _setReviewFinishAction(): void {
    const actionInfo = this._getActionInfo('exam-concept-review-finish', ActionInfoTypeEnum.Finish);
    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      this._analyticsService.clearWaitingAction(this.reviewActionId);
      this.reviewActionId = null;
    },
      (error) => { console.error(error); });
  }

  private _setAchievedNewLevelAction(): void {
    const actionInfo = this._getActionInfo('achieved-new-level', ActionInfoTypeEnum.LevelUp);
    actionInfo.page = ActionInfoPageEnum.Subject;
    actionInfo.questionId = this.currentQuestion.id;

    this._analyticsService.saveAction(actionInfo).subscribe(() => {
      this._analyticsService.clearWaitingAction(this.questionActionId);
      this.questionActionId = null;
    },
      (error) => { console.error(error); });
  }

  private _getActionInfo(description: string, type: ActionInfoTypeEnum): ActionInfo {
    return {
      'page': ActionInfoPageEnum.Exam,
      'description': description,
      'type': type,
      'moduleId': this.module.id,
      'subjectId': this.subjectId
    };
  }

  private _saveWaitingAction(actionId: string): void {
    const action = this._analyticsService.getStorageWaitingActionById(actionId);
    if (action) {
      this._analyticsService.saveAction(action).subscribe(
        () => { },
        (error) => { console.error(error); }
      );
      this._analyticsService.clearWaitingAction(actionId);
    }
  }


}
