<div class="pdf-content" [ngClass]="{ 'resumed': resumedView }">
  <div class="inner-content">

    <div class="pdf-viewer">
      <div class="scrollable" allowfullscreen id="scrollable-div">
        <button class="btn-fullscreen" (click)="toogleFullScreen()">
          <mat-icon aria-hidden="false" aria-label="Example home icon">fullscreen</mat-icon>
        </button>
        <ng2-pdfjs-viewer #PdfViewer [viewerId]="uniquePdfId" [openFile]="false" [download]="false" [fullScreen]="false"
          (onPageChange)="handlePageChange($event)">
        </ng2-pdfjs-viewer>
      </div>

      <div class="pagination">
        <span>Página {{ pageNumber }} de {{ totalPages }}</span>
        <div>
          <button (click)="changePagePrevious()" [disabled]="changingPage || pageNumber <= 1">
            Anterior
          </button>
          <button (click)="changePageNext()" [disabled]="changingPage || pageNumber === totalPages">
            Próximo
          </button>
        </div>
      </div>
    </div>

    <div class="concepts" *ngIf="!resumedView">
      <p>Conceitos Abordados</p>
      <ul>
        <li class="concept" *ngFor="let marker of markers" (click)="goToPage(marker.position)"
          [ngClass]="{ 'active': marker.position === pageNumber }">
          {{ marker.concept }}
        </li>
      </ul>
      <button class="btn-test add-tag-button" (click)="OpenTagDialog()" *ngIf="canCreateTags">
        Adicionar/Avaliar Conceito
      </button>
    </div>
  </div>
</div>
