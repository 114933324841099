import { Component, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from 'src/app/shared/components/collapsed-list/item/models/item.model';

@Component({
  selector: 'app-expanded-list-dialog',
  templateUrl: './expanded-list-dialog.component.html',
  styleUrls: ['./expanded-list-dialog.component.scss']
})
export class ExpandedListDialogComponent {
  private _originalItens: Item[];

  constructor(
    public dialogRef: MatDialogRef<ExpandedListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string, itens: Item[],
      filterTemplate: TemplateRef<HTMLElement>
      itemTemplate: TemplateRef<HTMLElement>
    }
  ) {
    this._originalItens = data ? data.itens : [];
  }

  public dismiss(): void {
    this.dialogRef.close(this._originalItens);
  }


}
