import { Injectable } from '@angular/core';

import { validarCPF } from './functions/document/cpf.validation';
import { validarCNPJ } from './functions/document/cnpj.validation';
import { validarTamanhoDocumento } from './functions/document/document-size.validation';

import { validarEmail } from './functions/user-info/email.validation';
import { validarNome } from './functions/user-info/nome.validation';
import { validarCep } from './functions/user-info/cep.validation';
import {
  validarTelefoneCelular,
  validarTelefoneFixo
} from './functions/user-info/telefone.validation';

import {
  retirarNumeros,
  retirarNumerosMenosTraco,
  retirarCaracterEspecialMenosTraco,
  retirarCaracterEspecial,
  retirarLetras,
  transformarLetrasAcentuadas
} from './functions/cleaners/cleaners';

import {
  validateCreditCardNumber,
  validateExpirationDate,
  getCreditCardFlag
} from './functions/payment/payment.validation';

@Injectable()
export class ValidationService {

  constructor() { }

  public static getValidatorErrorMessage(validatorName: string, validatorValue?, selectedLanguage?: string): string {

    const languages = {
      'pt-BR': {
        'required': 'Campo Obrigatório.',
        'invalidCpf': 'Digite um CPF válido.',
        'invalidPeriod': 'A data escolhida deve ser menor que a data de hoje.',
        'invalidEmail': 'E-mail inválido.',
        'invalidCep': 'Formato de CEP inválido.',
        'requiredCheckBox': 'Campo obrigatório',
        'minlength': `Tamanho mínimo ${validatorValue.requiredLength}.`,
        'maxlength': `Tamanho máximo ${validatorValue.requiredLength}.`,
        'lengthNameError': 'Digite seu nome completo.',
        'invalidTel': 'Telefone inválido.',
        'invalidCnpj': 'O CNPJ informado é inválido. Por favor, verifique e atualize a informação.',
        'nameError': 'Digite o nome corretamente.',
        'invalidText': 'O texto não pode conter caracter especial.',
        'invalidPassword': 'A senha deve ter mais do que 6 caracteres e conter letra e número.',
        'textError': 'Somente letras são permitidas',
        'invalidDeclaracaoNascidoVivo': 'Digite um nº de Declaração Nascido Vivo válido.',
        'invalidCartaoNacionalSaude': 'Digite um nº de Cartão Nacional de Saúde válido.',
        'invalidPisPasep': 'Digite um nº de PIS/PASEP válido.',
        'invalidCreditCard': 'Número de cartão de crédito inválido.',
        'invalidExpirationDate': 'Validade do cartão de crédito inválida.'
      },

      'en-US': {
        'required': 'Required Field.',
        'invalidCpf': 'Please enter a valid CPF.',
        'invalidPeriod': 'The chosen date must be less than todays date.',
        'invalidEmail': 'Invalid email.',
        'invalidCep': 'Invalid ZIP code format.',
        'requiredCheckBox': 'Required Field.',
        'minlength': `Minimum size ${validatorValue.requiredLength}.`,
        'maxlength': `Maximum size ${validatorValue.requiredLength}.`,
        'lengthNameError': 'Enter your full name.',
        'invalidTel': 'Invalid phone number.',
        'invalidCnpj': 'The CNPJ informed is invalid. Please check and update the information.',
        'nameError': 'Enter the name correctly.',
        'invalidText': 'The text cannot contain a special character.',
        'invalidPassword': 'Password must be longer than 6 characters and contain both letter and number.',
        'textError': 'Only letters are allowed.',
        'invalidDeclaracaoNascidoVivo': 'Enter a valid Born Alive Declaration number.',
        'invalidCartaoNacionalSaude': 'Enter a valid National Health Card number.',
        'invalidPisPasep': 'Please enter a valid PIS/PASEP number.',
        'invalidCreditCard': 'Invalid credit card number.',
        'invalidExpirationDate': 'Invalid credit card validity.'
      },

      'es-ES': {
        'required': 'Campo Obligatorio.',
        'invalidCpf': 'Ingrese un CPF válido.',
        'invalidPeriod': 'La fecha elegida debe ser menor que la fecha de hoy.',
        'invalidEmail': 'Email inválido.',
        'invalidCep': 'Formato de código postal no válido.',
        'requiredCheckBox': 'Campo Obligatorio.',
        'minlength': `Talla minima ${validatorValue.requiredLength}.`,
        'maxlength': `Talla máxima ${validatorValue.requiredLength}.`,
        'lengthNameError': 'Ingrese su nombre completo.',
        'invalidTel': 'Numero de telefono invalido.',
        'invalidCnpj': 'El CNPJ informado no es válido. Verifique y actualice la información.',
        'nameError': 'Ingrese el nombre correctamente.',
        'invalidText': 'El texto no puede contener un carácter especial.',
        'invalidPassword': 'La contraseña debe tener más de 6 caracteres y contener tanto una letra como un número.',
        'textError': 'Solo se permiten letras.',
        'invalidDeclaracaoNascidoVivo': 'Ingrese un número de declaración de nacido vivo válido.',
        'invalidCartaoNacionalSaude': 'Ingrese un número de Tarjeta Nacional de Salud válido.',
        'invalidPisPasep': 'Introduzca un número PIS / PASEP válido.',
        'invalidCreditCard': 'Número de tarjeta de crédito no válido.',
        'invalidExpirationDate': 'Validez de la tarjeta de crédito no válida.'
      }
    };

    return languages[selectedLanguage][validatorName];
  }


  public static validarCNPJ(control) {
    if (verificarControlInvalido(control)) return null;
    return validarCNPJ(control.value) ? null : { 'invalidCnpj': true };
  }

  public static validarCPF(control) {
    if (verificarControlInvalido(control)) return null;
    return validarCPF(control.value) ? null : { 'invalidCpf': true };
  }

  public static validarPisPasep(control) {
    if (verificarControlInvalido(control)) return null;
    return validarTamanhoDocumento(control.value, 11) ? null : { 'invalidPisPasep': true };
  }

  public static validarDeclaracaoNascidoVivo(control) {
    if (verificarControlInvalido(control)) return null;
    return validarTamanhoDocumento(control.value, 11) ? null : { 'invalidDeclaracaoNascidoVivo': true };
  }

  public static validarCartaoNacionalSaude(control) {
    if (verificarControlInvalido(control)) return null;
    return validarTamanhoDocumento(control.value, 15) ? null : { 'invalidCartaoNacionalSaude': true };
  }

  public static validarEmail(control) {
    if (verificarControlInvalido(control)) return null;
    return validarEmail(control.value) ? null : { 'invalidEmail': true };
  }

  public static validarNome(control) {
    if (verificarControlInvalido(control)) return null;
    return validarNome(control.value) ? null : { 'nameError': true };
  }

  public static validarCep(control) {
    if (verificarControlInvalido(control)) return null;
    return validarCep(control.value) ? null : { 'invalidCep': true };
  }

  public static retirarNumeros(control) {
    if (verificarControlInvalido(control)) return null;
    const valor = retirarNumeros(control.value);
    atualizarControl(control, valor);
  }

  public static retirarNumerosMenosTraco(control) {
    if (verificarControlInvalido(control)) return null;
    const valor = retirarNumerosMenosTraco(control.value);
    atualizarControl(control, valor);
  }

  public static retirarCaracterEspecialMenosTraco(control) {
    if (verificarControlInvalido(control)) return null;
    const valor = retirarCaracterEspecialMenosTraco(control.value);
    atualizarControl(control, valor);
  }

  public static retirarCaracterEspecial(control) {
    if (verificarControlInvalido(control)) return null;

    const nome = retirarCaracterEspecial(control.value);
    if (nome && (control.value !== nome))
      control.patchValue(nome, { emitEvent: false });
  }

  public static retirarLetras(control) {
    if (verificarControlInvalido(control)) return null;
    const valor = retirarLetras(control.value);
    atualizarControl(control, valor);
  }

  public static transformarLetrasAcentuadas(control) {
    if (verificarControlInvalido(control)) return null;
    return transformarLetrasAcentuadas(control.value);
  }

  public static validarTelefoneCelular(control) {
    if (verificarControlInvalido(control)) return null;
    return validarTelefoneCelular(control.value) ? null : { 'invalidTel': true };
  }

  public static validarTelefoneFixo(control) {
    if (verificarControlInvalido(control)) return null;
    return validarTelefoneFixo(control.value) ? null : { 'invalidTel': true };
  }

  public static validateCreditCardNumber(control) {
    if (verificarControlInvalido(control)) return null;
    return validateCreditCardNumber(control.value) ? null : { 'invalidCreditCard': true };
  }

  public static validateExpirationDate(control) {
    if (verificarControlInvalido(control)) return null;
    return validateExpirationDate(control.value) ? null : { 'invalidExpirationDate': true };
  }

  public static getCreditCardFlag(control) {
    if (verificarControlInvalido(control)) return null;
    return getCreditCardFlag(control.value);
  }
}

function verificarControlInvalido(control): boolean {
  return !control || !control.value;
}

function atualizarControl(control, valor) {
  if (control.value !== valor)
    control.setValue(valor);
}
