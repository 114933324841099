export interface SupportMaterial {
  id?: string;
  title: string;
  fileName?: string;
  description?: string;
  downloadLink: string;
  type?: SupportMaterialType;
  imageUrl?: string;
}

export enum SupportMaterialType {
  Link = 1,
  File = 2
}
