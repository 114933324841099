import { Component, OnInit, ViewChild } from '@angular/core';
import { Data } from 'src/app/shared/components/generate-valuation-test-check-options/model/data.model';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { SettingsUsersService } from '../../_services/users.service';
import { UserDataSource } from './DataSource/user.dataSource';
import { Team, TeamPrograms } from '../Models/team.model';
import { TeamsFiltersService } from './filters/services/team-filters.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil, uniqBy } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GenerateValuationTestCheckOptionsComponent
} from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.component';
import { Program } from '../../programs/Models/program.model';
import { TeamsService } from 'src/app/settings/teams/services/teams.service';
import { ImportDialogComponent } from './dialogs/import-user-dialog/import-user-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent extends NotificationClass implements OnInit {

  @ViewChild('checkedComponent', { static: true })
  checkedComponent: GenerateValuationTestCheckOptionsComponent;

  public team: Team = {
    programs: []
  } as Team;
  public teamId: string;
  public readonly pageSize: number = 5;
  public page: number = 1;
  public user: LoginResponse;
  public users: UserDataSource;
  public selectedUsers: any[] = [];
  public selectedPrograms: Program[] = [];

  constructor(
    private _dialog: MatDialog,
    _usersService: SettingsUsersService,
    private _teamsService: TeamsService,
    _authService: AuthService,
    _teamFiltersService: TeamsFiltersService,
    private _activatedRoute: ActivatedRoute,
    protected _snackBar: MatSnackBar,
    private _router: Router
  ) {
    super(_snackBar);
    _authService.isLoggedIn().subscribe((x) => {
      this.user = _authService.getLoggedUser();
    });

    this.users = new UserDataSource(_usersService, _authService, _teamFiltersService);
  }

  ngOnInit() {
    this.teamId = this._activatedRoute.snapshot.params['teamId'];
    if (!isNil(this.teamId)) {
      this._teamsService.getTeamById(this.teamId).subscribe(response => {
        this.team = response.data;
        this.selectedPrograms = this.team.
          programs.map(value => ({
              id: value.programId,
              title: value.title
            } as Program));
        this.checkedComponent.dataSelected = this.team.students.map(student => ({
            id: student.userId,
            title: student.name,
            checked: true,
          } as Data));
      });
    }
  }

  public handleSelectedUsers(users: any): void {
    this.selectedUsers = users;
  }

  public save() {

    this.selectedUsers = this.checkedComponent.dataSelected;

    if (this.team.name === '' || isNil(this.team.name)) {
      return this.notify('Nome é um campo obrigatório');
    }
    if (isNil(this.selectedUsers) || this.selectedUsers.length === 0) {
      return this.notify('Uma turma necessita ter ao menos um aluno');
    }

    const students = this.selectedUsers.map(value => value.id);
    const programsId = this.team.programs.map(v => v.programId);
    if (isNil(this.teamId)) {
      this._teamsService.createTeams(
        this.team.name,
        programsId,
        this.team.companyId,
        students)
        .subscribe(response => {
          this.teamId = response.data.id;
          this.notify('Salvo com sucesso!');
        });
    } else {
      this._teamsService.updateTeam(
        this.team.name,
        programsId,
        this.team.companyId,
        students,
        this.teamId)
        .subscribe(response => {
          this.teamId = response.data.id;
          this.notify('Salvo com sucesso!');
        });
    }

  }

  public handleSelectionItemChange(event: Program[]) {
    this.team.programs = event.map(value => ({
        programId: value.id
      } as TeamPrograms));
  }

  public cancel() {
    this._router.navigate(['configuracoes/turmas']);
  }

  public openDialogImport() {
    const dialogRef = this._dialog.open(ImportDialogComponent, {
      width: '800px',
      restoreFocus: false,
      closeOnNavigation: false,
    });

    dialogRef.afterClosed().subscribe(users => {
      if (users && users.length > 0) {
        let students = users.map(student => ({
          id: student.id,
          title: student.title,
          checked: true,
        } as Data));

        students.concat(this.selectedUsers);

        if (this.team && this.team.students && this.team.students.length > 0) {
          const teamStudents = this.team.students.map(student => ({
            id: student.userId,
            title: student.name,
            checked: true,
          } as Data));

          students = [...students, ...teamStudents];
        }

        students = uniqBy(students, "id");

        this.checkedComponent.dataSelected = students;
      }


    });
  }
}
