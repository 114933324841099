import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComunicationsComponent } from './comunications/comunications.component';
import { GenericCardModule } from 'src/app/shared/components/generic-card/generic-card.module';
import { CommunicationDialogComponent } from './communication-dialog/communication-dialog.component';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';

@NgModule({
    declarations: [ComunicationsComponent, CommunicationDialogComponent],
    imports: [
        CommonModule,
        GenericCardModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        PaginationModule,
    ]
})
export class ComunicationModule { }
