import { Component, Input } from '@angular/core';
import { Module } from 'src/app/models/module.model';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { ECardType } from '../Enum/card.enum';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent {
  public cardType: ECardType;
  public cardTypeEnum = ECardType;
  private _course: Module | TrackPreview | EventPreview;
  @Input()
  public get course(): Module | TrackPreview | EventPreview {
    return this._course;
  }
  public set course(value: Module | TrackPreview | EventPreview) {
    this.cardType = this.getCourseType(value);
    this._course = value;
  }


  public getCourseType(value: Module | TrackPreview | EventPreview) {
    if (value instanceof Module) {
      return ECardType.module;
    } else if (value instanceof EventPreview) {
      return ECardType.event;
    } else {
      return ECardType.track;
    }

  }
}
