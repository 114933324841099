<div class="view-pdf">
  <div class="content" [ngStyle]="{'border-color': getBorderColor(certificate?.borderColor)}">
    <div class="logo margin-bottom-50px">
      <img src="{{certificate?.company.logoUrl ? (certificate?.company.logoUrl | addHttps ) : defaultLogoUrl}}"
        alt="logo empresa" />
    </div>
    <div class="title margin-bottom-32px">
      <p>{{data?.title}}</p>
    </div>
    <div class="text">
      <p>A {{certificate?.company.businessName | uppercase}} confere o presente certificado, referente à conclusão
        <ng-container [ngSwitch]="where">
          <ng-container *ngSwitchCase="'track'">
            {{ replacement.track.singular | replacement:'da':'' | lowercase }}
          </ng-container>
          <ng-container *ngSwitchCase="'module'">
            {{ replacement.module.singular | replacement:'do':'' | lowercase }}
          </ng-container>
        </ng-container>
        {{data?.title}}, com carga horária de {{getFormattedHour()}} hora(s) a {{user?.name | uppercase}}.
      </p>
      <p>Treinamento concluído em {{progress?.completedAt | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="date margin-bottom-50px">
      <p>{{certificate?.company?.city}}, {{date}}</p>
    </div>
    <div class="signatures">
      <div class="content-siginature">
        <div class="signature">
          <img src="{{certificate?.signature}}" />
        </div>
        <p class="font-bold padding-signature">{{certificate?.name | uppercase}}</p>
      </div>
      <div class="content-siginature">
        <p class="font-bold padding-signature">{{user?.name | uppercase}}</p>
      </div>
    </div>
  </div>
