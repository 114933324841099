import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ModuleSidebarModule } from '../../shared/components/module-sidebar/module-sidebar.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { SettingsTracksModule } from 'src/app/settings/tracks/tracks.module';
import { MatIconModule } from '@angular/material/icon';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUtilModule } from '@tg4/ng-util';
import { NewModuleModule } from 'src/app/settings/modules/new-module/new-module.module';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModuleCuratorshipComponent } from './module-curatorship.component';
import { ModuleQuestionCuratorshipComponent } from './module-question-curatorship/module-question-curatorship.component';
import { ModuleQuestionDialogComponent } from './module-question-curatorship/module-question-dialog/module-question-dialog.component';
import { ModuleQuestionComponent } from './module-question-curatorship/module-question-dialog/module-question/module-question.component';
import { ModuleValuationComponent } from './module-question-curatorship/module-question-dialog/module-valuation/module-valuation.component';
import { ModuleTagCuratorshipComponent } from './module-tag-curatorship/module-tag-curatorship.component';
import { ModuleTagDialogComponent } from './module-tag-curatorship/module-tag-dialog/module-tag-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { ModuleQuestionApprovalDialogComponent } from './module-question-curatorship/module-question-approval-dialog/module-question-approval-dialog.component';

@NgModule({
    declarations: [
        ModuleCuratorshipComponent,
        ModuleQuestionCuratorshipComponent,
        ModuleQuestionDialogComponent,
        ModuleQuestionComponent,
        ModuleValuationComponent,
        ModuleTagCuratorshipComponent,
        ModuleTagDialogComponent,
        ModuleQuestionApprovalDialogComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        RouterModule,
        ModuleSidebarModule,
        PipesModule,
        DirectivesModule,
        SettingsTracksModule,
        FormsModule,
        ReactiveFormsModule,
        NgUtilModule,
        NewModuleModule,
        PaginationModule,
        MatIconModule,
        ListSearchModule,
        MaterialComponentsModule,
        NgxMaskModule
    ],
    exports: []
})
export class ModuleCuratorshipModule { }
