import { Component, Output, EventEmitter } from '@angular/core';
import { NotificationClass } from '../../classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-date-range-picker',
  template: `
    <div class="range-picker" >
        <mat-form-field>
          <input matInput
            placeholder="Data (dd/mm/aaaa)"
            [ngxMatDatetimePicker]="pickerFrom"
            (focus)="pickerFrom.open()"
            placeholder="De (dd/mm/aaaa)"
            [(ngModel)]="fromDate" />
          <mat-datepicker-toggle matPrefix [for]="pickerFrom"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerFrom
            (closed)="hasValidDate()"
            [showSeconds]="true"
            [stepHour]="1"
            [enableMeridian]="false"
            [stepMinute]="1"
            [stepSecond]="1">
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
          <input matInput
            placeholder="Data (dd/mm/aaaa)"
            [ngxMatDatetimePicker]="pickerTo"
            (focus)="pickerTo.open()"
            placeholder="Até (dd/mm/aaaa)"
            [(ngModel)]="toDate" />
          <mat-datepicker-toggle matPrefix [for]="pickerTo"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerTo
            (closed)="hasValidDate()"
            [showSeconds]="true"
            [stepHour]="1"
            [enableMeridian]="false"
            [stepMinute]="1"
            [stepSecond]="1">
          </ngx-mat-datetime-picker>
        </mat-form-field>

    </div>`,
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent extends NotificationClass {

  @Output() rangeSelected: EventEmitter<Array<Date>> = new EventEmitter();

  public fromDate: Date;
  public toDate: Date;

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  public hasValidDate(): boolean {
    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.notify('A data final não pode ser maior do que a inicial');
        this.toDate = null;
      } else if (this.toDate) {
        this.rangeSelected.emit(
          [this.fromDate, this.toDate]
        );
        return true;
      }
      return false;
    }
    return false;
  }

}
