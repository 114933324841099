import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpBackend
} from '@angular/common/http';
import { BaseUrlService } from '../services/url-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface BackendParam {
    name: string;
    value: string;
}

export interface BackendError {
    errorKey: string;
    errorMessage: string;
}

export interface BackendResponse<T> {
    success: boolean;
    errors: BackendError[];
    data: T;
}

@Injectable()
export class BackendService {
    httpRaw: HttpClient;

    constructor(
        private http: HttpClient,
        private urlService: BaseUrlService,
        rawHandler: HttpBackend
    ) {
        this.httpRaw = new HttpClient(rawHandler);
    }

    private getHttpClient(disableInterceptors): HttpClient {
        return disableInterceptors ? this.httpRaw : this.http;
    }

    private parseParams(params: BackendParam[]): HttpParams {
        let httpParams: HttpParams = new HttpParams();

        params.forEach(x => {
            httpParams = httpParams.append(x.name, x.value);
        });

        return httpParams;
    }

    private addHeaders() {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return headers;
    }

    public get<T>(
        urlKey: string,
        urlParts: string[] = [],
        params: BackendParam[] = [],
        disableInterceptors = false
    ) {
        const queryString = this.parseParams(params);
        const headers = this.addHeaders();
        return this.getHttpClient(disableInterceptors).get<BackendResponse<T>>(
            this.urlService.getUrl(urlKey, ...urlParts),
            {
                headers: headers,
                params: queryString
            }
        );
    }

    public post<T>(urlKey: string, data: any, disableInterceptors = false) {
        let headers = this.addHeaders();
        return this.getHttpClient(disableInterceptors).post<BackendResponse<T>>(
            this.urlService.getUrl(urlKey),
            data,
            {
                headers: headers
            }
        );
    }

    public put<T>(urlKey: string, data: any, disableInterceptors = false) {
        let headers = this.addHeaders();
        return this.getHttpClient(disableInterceptors).put<BackendResponse<T>>(
            this.urlService.getUrl(urlKey),
            data,
            {
                headers: headers
            }
        );
    }

    public delete<T>(
        urlKey: string,
        params: BackendParam[] = [],
        disableInterceptors = false
    ) {
        let parsedParams = this.parseParams(params);
        let headers = this.addHeaders();

        return this.getHttpClient(disableInterceptors).delete<
            BackendResponse<T>
        >(this.urlService.getUrl(urlKey), {
            headers: headers,
            params: parsedParams
        });
    }
}
