import { Component, Inject, OnInit, TemplateRef, DoCheck, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { IstudentsRanking } from '../../models/trackStudentsSubordinates';
import { CockpitRankingComponent } from '../cockpit-ranking.component';
@Component({
  selector: 'app-dialog-ranking',
  templateUrl: './dialog-ranking.component.html',
  styleUrls: ['./dialog-ranking.component.scss']
})
export class DialogRankingComponent implements  AfterViewInit {
  public ranking: Array<IstudentsRanking> = [];

  constructor(
    public dialogRef: MatDialogRef<CockpitRankingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      ranking: BehaviorSubject<Array<IstudentsRanking>>,
      filterTemplate?: TemplateRef<HTMLElement>,
      titleTemplate?: TemplateRef<HTMLElement>,
      showFactor: boolean
    }
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.data.ranking.subscribe(r => this.ranking = r );
    }, 0);
  }


}
