<div class="inner-content">
  <div class="user-info">
    <h2>PARAMETRIZAÇÃO CERTIFICADO</h2>
    <form [formGroup]="formGroup">

      <div class="font-container">
        <span class="font-name"> Borda: &nbsp;</span>
        <p>(cor da borda do certificado)</p>
        <mat-form-field class="font-input color">
          <input matInput type="color" placeholder="Color" formControlName="borderColor" />
        </mat-form-field>
      </div>

      <div class="font-container">
        <span class="font-name"> Nome: &nbsp;</span>
        <p>(nome do emissor)</p>
        <mat-form-field class="font-input">
          <input matInput placeholder="Nome" formControlName="name" />
        </mat-form-field>
      </div>

      <div class="font-container">
        <span class="font-name"> Assinatura: &nbsp;</span>
        <p>(logo usado no certificado)</p>
        <mat-form-field id="input-folder">
          <input matInput readonly (click)="fileInput.click()" placeholder="Assinatura" formControlName="signature" />
        </mat-form-field>
        <label class="label">*Dimensões ideais 157 x 90</label>
        <input style="display: none" type="file" (change)="uploadImages($event)" #fileInput />
        <div class="icon-folder" for="input">
          <span style="cursor: pointer" (click)="fileInput.click()" class="material-icons">
            folder
          </span>
        </div>
      </div>

      <div style="text-align: center;">
        <button class="btn-test" type="button" (click)="saveCertificateEdit()">
          Salvar Alterações
        </button>
      </div>
    </form>
  </div>
</div>
