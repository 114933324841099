import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { NotificationClass } from '../../../shared/classes/notification';
import { CdkStep } from '@angular/cdk/stepper';
import { ProgramInfoComponent } from 'src/app/settings/programs/program/steps/1_program-info/program-info.component';
import { ProgramTeamsComponent } from 'src/app/settings/programs/program/steps/2_program-teams/program-teams.component';
import { ProgramItemsComponent } from 'src/app/settings/programs/program/steps/3_program-items/program-items.component';
import { EcommerceComponent } from 'src/app/settings/programs/program/steps/4_program-ecommerce/ecommerce.component';
import { isEmpty } from 'lodash';
import { FormGroup } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Program } from 'src/app/settings/programs/Models/program.model';
import { ProgramsService } from '../../_services/programs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramItemConfiguration } from 'src/app/settings/programs/program/steps/3_program-items/models/program-item-configurations.model';

type StepComponent = ProgramInfoComponent | ProgramTeamsComponent | ProgramItemsComponent | EcommerceComponent;
@Component({
  selector: 'app-settings-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ]

})
export class ProgramComponent extends NotificationClass implements AfterViewInit {

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('programInfo', { static: false }) private programInfo: ProgramInfoComponent;
  @ViewChild('programTeam', { static: false }) private programTeam: ProgramTeamsComponent;
  @ViewChild('programItems', { static: false }) private programItems: ProgramItemsComponent;
  @ViewChild('programEcommerce', { static: false }) private programEcommerce: EcommerceComponent;

  public form: FormGroup;
  public stepIndex: number = 0;
  public program: Program;
  public stepsCount = 0;
  public programId: string;

  constructor(
    protected _snackBar: MatSnackBar,
    private _programsService: ProgramsService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    super(_snackBar);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.programId = this._activatedRoute.snapshot.params['programId'];

      if (!this.programId) {
        this.form = this._createForm();
      } else {
        this._programsService.getProgramById(this.programId)
          .subscribe(response => {
            const data: Program = response.data;
            this.form = this._createForm(data);
            this.stepsCount = this.stepper.steps.length;
          });
      }
    }, 0);
  }

  public saveContent(): void {
    const componentName = this._getComponentNameByStep(this.stepper.selected);
    const component: StepComponent = this[componentName];
    this.stepper.selected.hasError = component.form.invalid;
    const rawValue = this.form.getRawValue();
    const { info, team, items, ecommerce } = rawValue;
    const programItems = (items.programItems as ProgramItemConfiguration[]) || [];
    const teamIds = (team.teamIds as string[]) || [];
    const value: Partial<Program> = {
      ...info,
      ...{ teams: teamIds },
      ...{ items: programItems.flat() },
      ...ecommerce
    };

    if (this.form.valid) {
      !this.programId ? this._createProgram(value) : this._updateProgram(value);
    }

  }

  private _createProgram(value: Partial<Program>) {
    this._programsService.createProgram(value).subscribe(response => {
      this._router.navigate([`configuracoes/programa/${response.data.id}`])
        .then(() => this.notify('Cadastrado com sucesso.'));
    });
  }

  private _updateProgram(value: Partial<Program>) {
    value.id = this.programId;
    this._programsService.updateProgram(value).subscribe(response => {
      value.id = response.data.id;
      this.notify('Atualizado com sucesso.');
    });
  }

  private _createForm(initialValue: any = null) {

    if (!isEmpty(initialValue)) {
      this.programInfo.value = initialValue;
      this.programTeam.value = initialValue;
      this.programItems.value = initialValue;
      this.programEcommerce.value = initialValue;
    }

    const form = new FormGroup({
      info: this.programInfo.form,
      team: this.programTeam.form,
      items: this.programItems.form,
      ecommerce: this.programEcommerce.form
    });

    return form;

  }

  private _getComponentNameByStep(step: CdkStep): string {
    return step.content.elementRef.nativeElement.parentElement.getAttribute('id');
  }
}
