<div class="track-module-card"
  [ngClass]="{ 'darker': trackModule.order % 2 !== 0 }"
  (mouseenter)="trackModule.hovered = true"
  (mouseleave)="trackModule.hovered = false"
>
  <div class="previous-order"
    *ngIf="trackModule.order % 3 === 0"
    [ngClass]="{ 'first': trackModule.order === 0 }"
  >
    <span *ngIf="trackModule.order !== 0" >
      {{ trackModule.order }}
    </span>
    <span *ngIf="trackModule.order === 0" >
      I<br>n<br>í<br>c<br>i<br>o
    </span>
  </div>
  <div class="content" >
    <h3>{{ trackModule.title }}</h3>
    <div>
      <p *ngIf="!trackModule.isEvent" >
        {{ getLevelDescription(trackModule.level) }}
      </p>
      <p class="type" >
        {{ trackModule.isEvent ? 'Evento' : 'Módulo' }}
      </p>
    </div>
    <img
      src="./assets/img/trash.png"
      (click)="removeModuleFromTrack(trackModule.order)"
    />
  </div>
  <div class="arrow-right"
    [ngClass]="{ 'darker': trackModule.order % 2 !== 0 }"
  ></div>
  <div class="order" >
    <ng-container *ngIf="trackModule.hovered" >
      <img class="previous"
        *ngIf="trackModule.order > 0"
        (click)="changeModuleOrder(trackModule.order - 1)"
        src="./assets/img/arrow-back-white.png"
      />
      <img class="next"
        *ngIf="trackModule.order !== (modulesLength - 1)"
        (click)="changeModuleOrder(trackModule.order + 1)"
        src="./assets/img/arrow-back-white.png"
      />
    </ng-container>
    <mat-select id="selectOrder"
      [ngModel]="trackModule.order"
      (selectionChange)="changeModuleOrder($event.value)"
    >
      <mat-option [value]="index" *ngFor="let num of (modulesLength | doLoop); let index = index" >
        {{ index + 1 }}
      </mat-option>
    </mat-select>
  </div>
</div>
