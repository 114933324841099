export interface Data {
    id: string;
    subjectId: string;
    description: string;
    easy: number;
    medium: number;
    hard: number;
    tooltip?: string;
    checked: boolean;
}

export enum Type {
    Module = 1,
    Track = 2
}
