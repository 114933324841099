import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'addHttps'})
export class AddHttpsPipe implements PipeTransform {
    transform(url: any): any {
        const hasHttps = url.match(/(^http:\/\/|https:\/\/)+/g) !== null;
        if (hasHttps) return url;

        return 'https:' + url;
    }
}
