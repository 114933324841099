import { NgModule } from '@angular/core';
import { CardTagComponent } from './card-tag.component';

@NgModule({
  declarations: [
    CardTagComponent
  ],
  exports: [
    CardTagComponent
  ]
})
export class CardTagModule { }
