import { TruncatePipe } from './truncate.pipe';
import { NgModule } from '@angular/core';
import { SafeUrlPipe } from './safe-url.pipe';
import { LoopPipe } from './loop.pipe';
import { KeysLoopPipe } from './keys-loop.pipe';
import { PercentagePipe } from './percentage.pipe';
import { PhonePipe } from './phone.pipe';
import { AddHttpsPipe } from './url.pipe';
import { ReplecementPipe } from './replecement.pipe';
import { SecondToMinute } from './seconds-to-minute.pipe';
import { CompletedLevelImage } from './completed-level-image.pipe';
import { LevelColor } from './level-color.pipe';
import { ContainsPipe } from './contains.pipe';

@NgModule({
  declarations: [
    SafeUrlPipe,
    LoopPipe,
    KeysLoopPipe,
    PercentagePipe,
    PhonePipe,
    AddHttpsPipe,
    ReplecementPipe,
    TruncatePipe,
    SecondToMinute,
    ContainsPipe,
    CompletedLevelImage,
    LevelColor
  ],
  exports: [
    SafeUrlPipe,
    LoopPipe,
    KeysLoopPipe,
    PercentagePipe,
    PhonePipe,
    AddHttpsPipe,
    ReplecementPipe,
    TruncatePipe,
    SecondToMinute,
    ContainsPipe,
    CompletedLevelImage,
    LevelColor,
  ]
})
export class PipesModule { }
