import { Component, Input } from '@angular/core';
import { StudentProp } from 'src/app/shared/components/student-avatar/models/student.avatar.model';

@Component({
  selector: 'app-student-avatar',
  template: `
      <ng-content select="[before]"></ng-content>
      <div class="avatar-wrapper">
        <img [src]="student?.image || './assets/img/user-image-placeholder.png'" />
        <h3 class="title">
          <span>{{ student?.name }}</span>
          <small class="is-blocked" *ngIf="student?.isBlocked">Perfil bloqueado</small>
        </h3>
      </div>
      <ng-content select="[after]"></ng-content>
  `,
  styleUrls: ['./student-avatar.component.scss']
})
export class StudentAvatarComponent {

  private _student: StudentProp;
  @Input()
  public get student(): StudentProp { return this._student; }
  public set student(student: StudentProp) {
    this._student = student;
  }

}
