import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProgressBarModule } from '../../shared/components/layout/progress-bar/progress-bar.module';
import { ProgramCardModule } from '../../shared/components/program-card/program-card.module';
import { RouterModule } from '@angular/router';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { ContentModulesService } from '../_services/modules.service';
import { BaseUrlService, BackendService } from '@tg4/http-infrastructure';
import { UrlService } from '../../shared/services/url.service';
import { UserService } from '../_services/user.service';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ProgramsService } from '../_services/programs.service';
import { ProgramTeamComponent } from './program-team.component';
import { MatIconModule } from '@angular/material/icon';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    ProgramTeamComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ProgramCardModule,
    RouterModule,
    PaginationModule,
    HttpClientModule,
    ProgressBarModule,
    PipesModule,
    MatIconModule,
    NavigationBackModule,
    MatSelectModule
  ],
  providers: [
    ProgramsService,
    UserService,
    BackendService,
    { provide: BaseUrlService, useClass: UrlService }
  ]
})
export class ProgramTeamModule { }
