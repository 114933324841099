import { Component, OnInit } from '@angular/core';
import { SettingsEventsService } from 'src/app/settings/_services/events.service';
import { ActivatedRoute } from '@angular/router';
import { EventApplication } from 'src/app/models/event-application.model';
import { StudentGrade } from 'src/app/shared/components/student-grade/models/student-grade.model';

@Component({
  selector: 'app-event-student-grade',
  templateUrl: './event-student-grade.component.html',
  styleUrls: ['./event-student-grade.component.scss']
})
export class EventStudentGradeComponent implements OnInit {

  public eventApplication: EventApplication[];
  public students: StudentGrade[] = [];
  public eventName: string;

  private _scheduleId: string;
  private _eventId: string;
  constructor(
    private _eventsService: SettingsEventsService,
    private _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this._eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    this._scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');

    this._eventsService.getEventsApplicationsByEventId(
      this._eventId, this._scheduleId
    ).subscribe((response) => {
      this.eventApplication = response.data.applications;
      this.eventName = response.data.event.title;
      const hasCustomGrade = this.eventApplication && this.eventApplication.length > 0 &&
        this.eventApplication[0].customEventGradeValues && this.eventApplication[0].customEventGradeValues.length > 0;
      this.students = this.eventApplication.map(event => {
        const { id, name, userName, imageUrl } = event.user;
        const grade = hasCustomGrade ?
          event.customEventGradeValues.map(c => c.grade).reduce((a, b) => a + b, 0) / event.customEventGradeValues.length
          : (event.organicGrade + event.inorganicGrade) / 2;

        return {
          id,
          name,
          userName,
          imageUrl,
          grade
        } as StudentGrade;

      });
    });
  }


}
