import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-cockpit-menu-card',
  templateUrl: './cockpit-menu-card.component.html',
  styleUrls: ['./cockpit-menu-card.component.scss']
})
export class CockpitMenuCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() progress?: number;
  @Input() showBoxShadow?: boolean = true;
  public primary_color: string;
  constructor() { }

  ngOnInit() {
    const style = getComputedStyle(document.body);
    this.primary_color = style.getPropertyValue('--primary-color');
  }

  public canShow(value: any): boolean {
    return !isNil(value);
  }
}
