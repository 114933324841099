<mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
<form autocomplete="off" [formGroup]="formGroup" class="mat-filter" [class.no-results]="noResults"
  [ngStyle]="{'background-color': color ? color : 'white'}">
  <input autocomplete="false" name="hidden" type="text" style="display:none;">

  <div class="input-wrapper">
    <mat-form-field class="input-container" appearance="fill">
      <input matInput #input class="mat-filter-input" formControlName="filter"
        placeholder="{{placeholder}}" (keydown)="handleKeydown($event)">
      <button *ngIf="input.value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="clearInput(input)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</form>
