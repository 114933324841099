import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISelectedItem } from '../../dialogs/connect-dialog/interface/selected-item.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EStudyPlanItem } from 'src/app/models/enums/study-plan-item.enum';

@Component({
  selector: 'app-draging-list',
  templateUrl: './draging-list.component.html',
  styleUrls: ['./draging-list.component.scss']
})
export class DragingListComponent {

  @Input() dataSource: ISelectedItem[];
  @Input() disableDrag: boolean = false;
  @Output() remove = new EventEmitter<ISelectedItem>();
  public EnumStudyPlanItem = EStudyPlanItem;
  constructor() { }

  public dropped(evt: CdkDragDrop<ISelectedItem[]>) {
    moveItemInArray(this.dataSource, evt.previousIndex, evt.currentIndex);
    this.dataSource = [...this.dataSource];
  }
}
