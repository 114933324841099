import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged } from 'rxjs/operators';
import { EStudyPlanItem } from 'src/app/models/enums/study-plan-item.enum';
import { EStudyPlan } from 'src/app/models/enums/study-plan.enum';
import { Module } from 'src/app/models/module.model';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { StudyPlan } from 'src/app/models/study-plan.model';
import { StudyPlanService } from 'src/app/settings/_services/study-plan.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IRequestFilter } from '../Interface/request-filter';
import { IRequesrtSort } from '../Interface/request-sort';
import { MyCoursesService } from '../services/my-courses.service';

@Component({
  selector: 'app-study-plan',
  templateUrl: './study-plan.component.html',
  styleUrls: ['./study-plan.component.scss']
})
export class StudyPlanComponent implements OnInit, OnDestroy {

  public readonly pageSize: number = 8;

  public courses: Array<TrackPreview | ModulePreview> = [];
  public coursesPage: number = 1;
  public coursesCount: number = 0;

  public coursesIndividual: Array<TrackPreview | ModulePreview> = [];
  public coursesIndividualPage: number = 1;
  public coursesIndividualCount: number = 0;

  public mandatoryTraining: Array<TrackPreview | ModulePreview> = [];
  public mandatoryTrainingPage: number = 1;
  public mandatoryTrainingCount: number = 0;

  public institutionalTraining: Array<TrackPreview | ModulePreview> = [];
  public institutionalTrainingPage: number = 1;
  public institutionalTrainingCount: number = 0;

  public sectorFilters: IRequestFilter[];
  public sort: IRequesrtSort;

  public userId: string;
  private subscriptionArray: Subscription[] = [];

  constructor(
    private _studyPlanService: StudyPlanService,
    private _authService: AuthService,
    private _myCoursesService: MyCoursesService
  ) { }

  ngOnInit() {
    this.callFunctionLoad();
    this.setSubscriptionFilters();
    this.setSubscriptionSort();
    this.userId = this._authService.getLoggedUserId();
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  public LoadMyIndividualJourney(page: number) {
    this._studyPlanService.GetMyJourney(page, this.pageSize, EStudyPlan.individual, this.sectorFilters, this.sort).subscribe(response => {
      this.coursesIndividual = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
      this.coursesIndividualCount = response.data.itemsCount;
    });
  }

  public LoadMyTeamJourney(page: number) {
    this._studyPlanService.GetMyJourney(page, this.pageSize, EStudyPlan.team, this.sectorFilters, this.sort).subscribe(response => {
      this.courses = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
      this.coursesCount = response.data.itemsCount;
    });
  }

  public LoadMandatoryTraining(page: number) {
    this._studyPlanService.GetMandatoryTraining(page, this.pageSize, this.sectorFilters, this.sort).subscribe(response => {
      this.mandatoryTraining = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
      this.mandatoryTrainingCount = response.data.itemsCount;
    });
  }

  public LoadInstitutionalTraining(page: number) {
    this._studyPlanService.GetInstitutionalTraining(page, this.pageSize, this.sectorFilters, this.sort).subscribe(response => {
      this.institutionalTraining = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
      this.institutionalTrainingCount = response.data.itemsCount;
    });
  }

  public setSubscriptionFilters() {
    this.subscriptionArray.push(this._myCoursesService.getFilter().pipe(distinctUntilChanged()).subscribe(value => {
      this.sectorFilters = value;
      this.callFunctionLoad();
    }));
  }

  public setSubscriptionSort() {
    this.subscriptionArray.push(this._myCoursesService.getSort().pipe(distinctUntilChanged()).subscribe(value => {
      this.sort = value;
      this.callFunctionLoad();
    }));
  }

  public callFunctionLoad() {
    this.LoadMyTeamJourney(this.coursesPage);
    this.LoadMyIndividualJourney(this.coursesIndividualPage);
    this.LoadMandatoryTraining(this.mandatoryTrainingPage);
    this.LoadInstitutionalTraining(this.institutionalTrainingPage);
  }


  public goToPage(data: {
    page: number,
    type: 'myjourney' | 'mandatoryTraining' | 'institutionalTraining' | 'myjourneyindividual'
  }) {
    const functions = {
      'myjourney': () => {
        this.coursesPage = data.page;
        this.LoadMyTeamJourney(this.coursesPage);
      },
      'mandatoryTraining': () => {
        this.mandatoryTrainingPage = data.page;
        this.LoadMandatoryTraining(this.mandatoryTrainingPage);
      },
      'institutionalTraining': () => {
        this.institutionalTrainingPage = data.page;
        this.LoadInstitutionalTraining(this.institutionalTrainingPage);
      },
      'myjourneyindividual': () => {
        this.coursesIndividualPage = data.page;
        this.LoadMyIndividualJourney(this.coursesIndividualPage);
      }
    };

    functions[data.type]();
  }
}
