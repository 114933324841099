import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { of } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ErrorsService } from 'src/app/shared/services/error.service';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler extends ErrorsService implements ErrorHandler {

  private _error = { message: undefined };

  constructor(_snackBar: MatSnackBar, _injector: Injector, private zone: NgZone) {
    super(_snackBar, _injector);
  }

  private unwrap(err) {
    let res = err.rejection ? err.rejection : err;
    res = res.originalError ? res.originalError : res.originalStack ? res.originalStack : res;
    return res;
  }

  private _sendErrorsToSentry(error: any) {

    console.error(error);
    if (environment.production && error.status!=333)
      Sentry.captureException(error);
  }

  private _goToErrorPage(error: any, callback?) {
    const originalUrl = window.location.href;
    const splitedUrl = originalUrl.split('url=').pop();

    of(decodeURIComponent(splitedUrl))
      .pipe(distinctUntilChanged())
      .toPromise()
      .then(url => {
        if (`${originalUrl}/` !== url || `${originalUrl}` !== url) {
          this._router
            .navigate(['erro'], { queryParams: { 'url': url } })
            .then(() => {
              this._sendErrorsToSentry(error);
              if (!isNil(callback)) callback();
            });
        }
      });
  }

  handleError(error: Error) {
    error = this.unwrap(error);

		if (error instanceof HttpErrorResponse) {
			// handle 4xx Client errors
			const isBadRequest = error.status >= HttpStatusCode.BadRequest &&
				error.status !== HttpStatusCode.Unauthorized &&
				error.status !== HttpStatusCode.Forbidden;

			if (isBadRequest && error.status < HttpStatusCode.InternalServerError) {
				const errorToDisplay = error.error;
				if (errorToDisplay && errorToDisplay.errors) {
					this.notify(errorToDisplay.errors.join(';\n'), 'Ok', { duration: 30000 });
				}
			} else {
				this.notify('Ocorreu um erro na aplicação. '
        + 'Informações sobre o ocorrido foram salvas e estamos trabalhando para resolver o problema. '
        + 'Solicitamos que você contate o suporte.', 'OK', {
					duration: 30000
				});
			}

		} else {
			this._sendErrorsToSentry(error);
		}
	}
}


