import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { isEmpty, isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IeffortPerformance } from '../../../models/studentMetrics';

@Component({
  selector: 'app-cockpit-chart-performance',
  template: ` <div class="chart-wapper">
    <canvas width="100%" height="100%" id="cockpit-radar-performance"></canvas>
    <div class="labels top">
      <h1>Prova</h1>
      <span class="material-icons"> help_outline </span>
    </div>
    <div class="labels left">
      <h1>{{ isTrack ? 'Evento' : 'BDQ' }}</h1>
      <span class="material-icons"> help_outline </span>
    </div>
    <div class="labels right">
      <h1>Diagnóstica</h1>
      <span class="material-icons"> help_outline </span>
    </div>
  </div>`,
  styleUrls: ['./cockpit-chart.component.scss'],
})
export class CockpitChartPerformanceComponent {
  public chart: any;
  private _effortPerformance: IeffortPerformance;
  @Input() isTrack: boolean;
  @Input()
  public get effortPerformance() {
    return this._effortPerformance;
  }
  public set effortPerformance(value: IeffortPerformance) {
    this._effortPerformance = value;
    if (!isNil(this._effortPerformance) && !isEmpty(this._effortPerformance)) {
      this.chart = new Chart('cockpit-radar-performance', {
        type: 'radar',
        data: {
          labels: ['', '', ''],
          datasets: [
            {
              data: [
                this.effortPerformance.student.valuation,
                this.effortPerformance.student.diagnostic,
                this.isTrack
                  ? this.effortPerformance.student.averageEventGrade
                  : this.effortPerformance.student.bdqGrade,
              ],
              label: 'Aluno',
              backgroundColor: 'rgba(35, 188, 209, 0.35)',
              borderColor: 'rgb(35, 188, 209)',
              pointBackgroundColor: 'rgb(35, 188, 209)',
            },
            {
              data: [
                this.effortPerformance.class.averageValuation,
                this.effortPerformance.class.averageDiagnostic,
                this.isTrack
                  ? this.effortPerformance.class.averageEvent
                  : this.effortPerformance.class.averageBdq,
              ],
              label: 'Turma',
              backgroundColor: 'rgba(255, 224, 141, 0.35)',
              borderColor: 'rgb(244, 214, 134)',
              pointBackgroundColor: 'rgb(255, 244, 141)',
            },
            {
              data: [
                this.effortPerformance.top10.averageValuation,
                this.effortPerformance.top10.averageDiagnostic,
                this.isTrack
                  ? this.effortPerformance.top10.averageEvent
                  : this.effortPerformance.top10.averageBdq,
              ],
              label: 'Top10',
              backgroundColor: 'rgba(1, 64, 117, 0.35)',
              borderColor: 'rgb(1, 64, 117)',
              pointBackgroundColor: 'rgb(1, 64, 117)',
            },
          ],
        },
        options: {
          responsive: true,
          legend: { display: false },
          scale: {
            ticks: {
              beginAtZero: true,
              max: 10,
              min: 0,
              stepSize: 1,
            },
            pointLabels: {
              fontSize: 26,
              fontColor: '#c4c4c4',
              fontStyle: 'bold',
            },
          },
        },
      });
    }
  }

  constructor() {}
}
