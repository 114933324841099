import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CascateSelectComponent } from './cascate-select.component';
import { MaterialComponentsModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [CascateSelectComponent],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [CascateSelectComponent]
})
export class CascateSelectModule { }
