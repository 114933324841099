<div class="track" [ngStyle]="{'margin-right': hasFeatureCalender ? '30px' : '0px'}">
  <div class="title">
    <p class="track-title">
      {{replacement.track.singular | uppercase}}
    </p>
    <div class="title-buttons">
      <button [disabled]="!this.track?.id" *ngIf="canCalculateGrade &&allowOverview " class="btn-test"
        (click)="calculateGrade()"> Calcular notas </button>
      <button [disabled]="!this.track?.id" *ngIf="canExport()" class="btn-test" (click)="exportGrades()"> Exportar notas
      </button>
      <button [disabled]="!this.track?.id" *ngIf="canExport()" class="btn-test" (click)="exportStatus()"> Exportar
        status </button>
      <button [disabled]="!this.track?.id"
        *ngIf="!allowOverview && this.track && this.track.modulesConfiguration && this.track.modulesConfiguration.length > 0"
        class="btn-test" (click)="seeReportCard()"> Ver
        boletim </button>
      <button [disabled]="!this.track?.id"
        *ngIf="!allowOverview && hasFeatureMyPerformace && this.track && this.track.modulesConfiguration && this.track.modulesConfiguration.length > 0"
        class="btn-test" (click)="seeStatus()"> Meu desempenho
      </button>
      <button *ngIf="canGenerateCertificate() && canGenerateCertificates" class="btn-test"
        [disabled]="!this.track?.id && getLogicDisableButtonCertificate(trackTestsResearch)" (click)="getCertificate()">
        Certificado </button>
    </div>
  </div>
  <div class="tests" *ngIf="trackTestsFree.length > 0">
    <div class="header">
      <p>
        <span class="valuation-index">0</span>
        Avaliações
      </p>
      <p class="arrow-box">
        <span *ngIf="!trackTestsFreeExpanded">
          <img (click)="trackTestsFreeExpanded = !trackTestsFreeExpanded" class="turn-up"
            src="./assets/img/arrow-back.png" />
        </span>
        <span *ngIf="trackTestsFreeExpanded">
          <img (click)="trackTestsFreeExpanded = !trackTestsFreeExpanded" class="turn-down"
            src="./assets/img/arrow-back.png" />
        </span>
      </p>
    </div>
    <div class="test-content" *ngIf="trackTestsFreeExpanded">
      <div class="level" *ngFor="let test of trackTestsFree">
        <div class="test-name-and-button" [style.width]="test.answered ? '80%' : '100%'">
          <p>
            {{test.title}}
          </p>
          <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
            class="btn-test test-disabled-button" (click)="viewQuestionsAndAnswersDialog(test)" *ngIf="test.answered"
            [matTooltipDisabled]="!(completedPercentage != 100 && test.answered)">
            {{ completedPercentage != 100 ? disablePercentButtonLogicText(test.answered, completedPercentage, getModuleTestPercent(test), test) : 'Ver respostas' }}
          </button>
          <button class="btn-test" (click)="goToTest(test)" *ngIf="!test.answered"
            matTooltip="Fora do período de disponibilidade" [matTooltipDisabled]="test.enableBtnTest"
            [disabled]="!test.enableBtnTest || disablePercentButtonLogic(test.answered, completedPercentage, getModuleTestPercent(test), test)">
            {{ disablePercentButtonLogicText(test.answered, completedPercentage, getModuleTestPercent(test), test) }}
          </button>
        </div>
        <p class="grade" *ngIf="test.answered">
          <span>Nota
          </span>
          <span>{{calculateTestGrade(test)}}</span>
        </p>
      </div>
    </div>
  </div>
  <ul *ngIf="canLockTrack()" id="list-modules">
    <!--[ngClass]="{'disabled-item': item.blocked}"-->
    <li *ngFor="let item of getTrackCards(); let index = index" [ngClass]="item.blocked ? 'disabled-item' : ''">
      <div class="list-content" *ngIf=" !item.isEvent || 
        item.isEvent && item.eventScheduleType !== scheduleEventType.SynchronousExperience || 
        item.isEvent && item.eventScheduleType === scheduleEventType.SynchronousExperience && item.studentIsEnrolled"
        [ngClass]="{'has-click': !allowOverview, 'border-right': !hasCalenderFeature && index < indexModulesAndEvents}"
        (click)="!allowOverview && goToStudentContent(item)">
        <p class="index">
          {{ index + 1 }}
        </p>
        <p class="title">
          {{ item.title }}<br>
          <span>{{ item.isEvent ? 'EVENTO PRESENCIAL' : 'MÓDULO ONLINE' }}</span>
        </p>
        <div class="card-right">
          <div class="progress"
            *ngIf="(!item.isEvent || hasSubprogress) && !item.studentFinished && item.hasQuestions && !item.isExemption">
            <app-progress-bar [currentProgress]="getProgress(item)" [height]="12" [color]="checkLateEvent(item)">
            </app-progress-bar>
            <span [ngStyle]="{'color': checkLateEvent(item)}">
              {{ getProgress(item).toFixed(0) }}%
            </span>
          </div>
          <div class="finished" *ngIf="item.studentFinished || item.isExemption">
            <div class="status">
              {{item.isExemption ? "Isento" : "Finalizado"}}
              <img src="./assets/img/status-success.png" />
            </div>
            <div *ngIf="item.blockedLevel" class="status-legend">Nível {{item.blockedLevel}}</div>
          </div>
          <p class="sub-progress" *ngIf="hasSubprogress && item.hasQuestions"
            [ngStyle]="{'color': checkLateEvent(item)}">
            <ng-container *ngIf="item.isEvent && item.incompleteRequirementStudents">
              <b>{{track.studentsCount - item.incompleteRequirementStudents.length}}</b>/{{track.studentsCount}}
              pré-requisitos
            </ng-container>
            <ng-container *ngIf="!item.isEvent">
              <b>{{item.completeStudents}}</b>/{{track.studentsCount}} finalizados
            </ng-container>
          </p>
        </div>
        <div class="late" *ngIf="!hasSubprogress && hasLateStudents()">
          <p>alunos<br>atrasados</p>
          <img src="./assets/img/status-warning.png" />
        </div>
        <div class="card-right">
          <img *ngIf="!allowOverview" class="go" src="./assets/img/chevron-right-black.png" />

          <button *ngIf="allowOverview" mat-icon-button [matMenuTriggerFor]="ModuleContentLink">
            <mat-icon>more_vert</mat-icon>
          </button>


          <mat-menu #ModuleContentLink="matMenu">
            <button mat-menu-item (click)="goToStudentContent(item)">
              Ir para conteúdos
            </button>

            <button mat-menu-item (click)="goToOverViewContent(item)">
              Ir para visão geral
            </button>
            <button mat-menu-item (click)="goToDashboardInstructor(item)" *ngIf="isInstructor">Ir para dashboard do
              instrutor</button>
          </mat-menu>

        </div>
      </div>
      <div class="tests" *ngIf="checkEventItemValuation(item)">
        <div class="header">
          <p>
            <span class="valuation-index">{{ index + 1 }}</span>
            Avaliações de {{ item.title }}
          </p>
          <p class="arrow-box">
            <span *ngIf="!trackTestsOrderedExpanded[index]">
              <img (click)="trackTestsOrderedExpanded[index] = !trackTestsOrderedExpanded[index]" class="turn-up"
                src="./assets/img/arrow-back.png" />
            </span>
            <span *ngIf="trackTestsOrderedExpanded[index]">
              <img (click)="trackTestsOrderedExpanded[index] = !trackTestsOrderedExpanded[index]" class="turn-down"
                src="./assets/img/arrow-back.png" />
            </span>
          </p>
        </div>
        <div class="test-content" *ngIf="trackTestsOrderedExpanded[index]">
          <div class="level" *ngFor="let test of listEventItemValuation(item)">
            <div class="test-name-and-button" [style.width]="test.answered ? '80%' : '100%'">
              <p>
                {{test.title}}
              </p>
              <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                *ngIf="test.answered" class="btn-test test-disabled-button"
                (click)="viewQuestionsAndAnswersDialog(test)"
                [matTooltipDisabled]="!(completedPercentage != 100 && test.answered)">
                {{ completedPercentage != 100 ? disablePercentButtonLogicText(test.answered, item.studentFinished ? 100 : getProgress(item), 1, test) : 'Ver respostas' }}
              </button>
              <button *ngIf="!test.answered" class="btn-test" (click)="goToTest(test)"
                [disabled]="disablePercentButtonLogic(test.answered, item.studentFinished ? 100 : getProgress(item), 1, test)">
                {{ disablePercentButtonLogicText(test.answered, item.studentFinished ? 100 : getProgress(item), 1, test)
                }}
              </button>
            </div>
            <p class="grade" *ngIf="test.answered" (click)="viewQuestionsAndAnswersDialog(test)">
              <span>Nota
              </span>
              <span>{{calculateTestGrade(test)}}</span>
            </p>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="tests" *ngIf="trackTestsResearch.length > 0">
    <div class="header">
      <p>
        <span class="valuation-index">{{getTrackCards().length + 1}}</span>
        Avaliação de Reação
      </p>
      <p class="arrow-box">
        <span *ngIf="!trackTestsResearchExpanded">
          <img (click)="trackTestsResearchExpanded = !trackTestsResearchExpanded" class="turn-up"
            src="./assets/img/arrow-back.png" />
        </span>
        <span *ngIf="trackTestsResearchExpanded">
          <img (click)="trackTestsResearchExpanded = !trackTestsResearchExpanded" class="turn-down"
            src="./assets/img/arrow-back.png" />
        </span>
      </p>
    </div>
    <div class="test-content" *ngIf="trackTestsResearchExpanded">
      <div class="level" *ngFor="let test of trackTestsResearch">
        <p>
          {{test.title}}
        </p>
        <button class="btn-test" (click)="goToTest(test)"
          [disabled]="disablePercentButtonLogic(test.answered, completedPercentage, getModuleTestPercent(test), test)">
          {{ disablePercentButtonLogicText(test.answered, completedPercentage, getModuleTestPercent(test), test) }}
        </button>
      </div>
    </div>
  </div>

  <div class="tests" *ngIf="trackCourseWork.length > 0">
    <div class="header">
      <p>
        <span class="valuation-index">{{getTrackCards().length + 1}}</span>
        TRABALHO FINAL
      </p>
      <p class="arrow-box">
        <span *ngIf="!trackCourseWorkExpanded">
          <img (click)="trackCourseWorkExpanded = !trackCourseWorkExpanded" class="turn-up"
            src="./assets/img/arrow-back.png" />
        </span>
        <span *ngIf="trackCourseWorkExpanded">
          <img (click)="trackCourseWorkExpanded = !trackCourseWorkExpanded" class="turn-down"
            src="./assets/img/arrow-back.png" />
        </span>
      </p>
    </div>
    <div class="test-content" *ngIf="trackCourseWorkExpanded">
      <div class="level" *ngFor="let test of trackCourseWork">
        <div class="test-name-and-button" [style.width]="test.answered ? '80%' : '100%'">
          <p>
            {{test.title}}
          </p>
          <button class="btn-test" (click)="goToTest(test)"
            [disabled]="disablePercentButtonLogic(test.answered, completedPercentage, 1, test)">
            {{ disablePercentButtonLogicText(test.answered, completedPercentage, 1, test) }}
          </button>
        </div>
        <p class="grade" *ngIf="test.answered" (click)="viewQuestionsAndAnswersDialog(test)">
          <span>Nota
          </span>
          <span>{{calculateTestGrade(test)}}</span>
        </p>
      </div>
    </div>
  </div>
</div>
