import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { concatMap } from 'rxjs/operators';
import { ERole } from 'src/app/models/enums/role.enum';

@Injectable()
export class InstructorGuard extends NotificationClass implements CanActivate {

  constructor(
    private _router: Router,
    private _authService: AuthService,
    protected _snackBar: MatSnackBar
  ) { super(_snackBar); }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {

    const hasAcess = this._authService.isSeenHowInstructorModule() ||
      this._authService.isSeenHowInstructorEvent() ||
      this._authService.hasRole(ERole.Admin) ||
      this._authService.hasRole(ERole.HumanResources);

    return of(hasAcess)
      .pipe(
        concatMap(isInstructor => {
          if (!isInstructor) this._actionWhenNotAuthorized();
          return of(isInstructor);
        })
      );
  }

  private _actionWhenNotAuthorized() {
    this.notify('Você não tem permissões para acessar essa área');
    return EMPTY;
  }


}
