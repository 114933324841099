import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { Activation } from 'src/app/models/activation.model';

@Component({
  selector: 'app-nps-dialog',
  template: `<app-nps-answer [data]="data" (sliderValue)="setSliderValue($event)"></app-nps-answer>`
})
export class NpsDialogComponent {

  private _valuating: boolean = false;

  constructor(public dialogRef: MatDialogRef<NpsDialogComponent>,
              private _userService: SettingsUsersService,
              @Inject(MAT_DIALOG_DATA) public data: Activation) { }

  public dismiss(): void {
    this.dialogRef.close(null);
  }

  public setSliderValue(value: number) {
    if (!this._valuating) {
      this._valuating = true;
      this._userService.saveNpsValuation(value).subscribe(res => {
        this.dialogRef.close('Avaliação salva com sucesso');
      }, () => {
        this.dialogRef.close('Ocorreu um erro ao salvar sua avaliação, tente novamente mais tarde');
      });
    }
  }

}
