import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Content, PdfReference } from '../../../models/content.model';
import { PdfMarker } from './marker.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { EffortService } from 'src/app/shared/services/effort.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PdfTagDialogComponent } from './pdf-tag-dialog/pdf-tag-dialog.component';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { UtilService } from 'src/app/shared/services/util.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { isNil } from 'lodash';
import * as pdfjsLib from 'pdfjs-dist';

@Component({
  selector: 'app-content-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PDFContentComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('PdfViewer', { static: true }) private _pdfViewer: PdfJsViewerComponent;

  public moduleTags: any[] = [];
  public contentTags: any[] = [];
  public moduleTagValuations: any[] = [];
  public isFullScreenMode: boolean = false;

  @Input() readonly canCreateTags: boolean = false;
  @Input() readonly resumedView?: boolean = false;
  @Input() set setContent(content: Content) {
    if (!isNil(content)) {
      this.content = content;
      this._createMarkers();
      this.totalPages = this.content.numPages || 1;
      this._pdfViewer.pdfSrc = this.content.value;
      pdfjsLib.getDocument(this.content.value).promise.then((doc) => {
        if (doc && doc.numPages) {
          this.totalPages = doc.numPages;
        }

        this._pageChanged$.next({ page: this.pageNumber, canUpdatePdfPage: true });
      });

      this._pdfViewer.refresh();
    }
  }

  @Input() set setModuleTags(moduleTags: any[]) {
    if (moduleTags && moduleTags.length > 0) {
      this.moduleTags = moduleTags;
      const params = this._route.snapshot.params;
      const userId = this._auth.getLoggedUserId();
      const subjectId = params['subjectId'];
      const contentId = this.content.id;
      const contentUserTags = [];
      this.contentTags = [];
      for (let i = 0; i < moduleTags.length; i++) {
        const moduleTag = moduleTags[i];
        if (
          moduleTag.userId === userId &&
          moduleTag.subjectId === subjectId &&
          moduleTag.contentId === contentId
        ) {
          contentUserTags.push(moduleTag);
        } else if (
          moduleTag.subjectId === subjectId &&
          moduleTag.contentId === contentId
        ) {
          this.contentTags.push(moduleTag);
        }
      }
      contentUserTags.forEach((contentTag: any) => {
        if (contentTag.concept && contentTag.concept.positions) {
          // eslint-disable-next-line prefer-spread
          this.markers.push.apply(
            this.markers,
            contentTag.concept.positions.map((pos: number) => {
              const mark = new PdfMarker(contentTag.concept.name, pos);
              mark.tag = true;
              return mark;
            })
          );
        }
      });
      this._pdfViewer.refresh();
    }
  }

  @Input() set setModuleTagValuations(moduleTagValuations: any[]) {
    if (moduleTagValuations && moduleTagValuations.length > 0) {
      this.moduleTagValuations = moduleTagValuations;
    }
  }
  @Output() savePdfFinishedAction: EventEmitter<string> = new EventEmitter();
  @Output() updateModuleTags: EventEmitter<any> = new EventEmitter();

  public uniquePdfId = this._utilService.createUniqueId();
  public content: Content;
  public totalPages: number;
  public pageNumber: number = 1;
  public markers: Array<PdfMarker> = [];
  public changingPage: boolean = false;
  private _finishedPdf: boolean = false;
  listenerFn: () => void;

  private _pageChanged$ = new Subject<{ page: number, canUpdatePdfPage: boolean }>();

  constructor(
    private _dialog: MatDialog,
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _sharedService: SharedService,
    private _utilService: UtilService,
    private _effortService: EffortService,
    el: ElementRef) {
    this.listenerFn = _effortService.listenEvent(el.nativeElement, 'mousemove');
    this._sharedService.forumQuestion.subscribe(() => {
      this._sharedService.forumQuestionResponse.next('Página ' + this.pageNumber.toString());
    });
  }
  ngOnInit(): void {
    const scrollabeDiv = document.getElementById('scrollable-div');
    scrollabeDiv.onfullscreenchange = (evt) => this.fullScreenChange(evt);
  }
  ngOnDestroy(): void {
    this._effortService.finishInteraction();
    if (this.listenerFn) this.listenerFn();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setInitialValuePage();
    }, 2);

    this._pageChanged$
      .subscribe((pdfCOnfig) => {
        if (pdfCOnfig.canUpdatePdfPage)
          this._pdfViewer.page = pdfCOnfig.page;

        if (this._hasFinishedContent()) {
          this.savePdfFinishedAction.emit(this.content.id);
        }
      });
  }


  public setInitialValuePage() {
    if (this.content.initialValue) {
      const positionInitial = this.markers.filter(m => m.concept === this.content.initialValue);
      if (positionInitial)
        this.goToPage(positionInitial[0].position);
    }
  }

  public changePagePrevious() {
    this.pageNumber = this.pageNumber - 1;
    this._pageChanged$.next({ page: this.pageNumber, canUpdatePdfPage: true });
  }

  public changePageNext() {
    this.pageNumber = this.pageNumber + 1;
    this._pageChanged$.next({ page: this.pageNumber, canUpdatePdfPage: true });
  }

  public goToPage(page: number) {
    if (page !== this.pageNumber &&
      page > 0 &&
      page <= this.totalPages
    ) {
      this.pageNumber = page;
      this._pageChanged$.next({ page: this.pageNumber, canUpdatePdfPage: true });
    }

  }

  public handlePageChange(currentPage: number) {
    this.pageNumber = currentPage;
    this._pageChanged$.next({ page: this.pageNumber, canUpdatePdfPage: false });
  }

  private _hasFinishedContent(): boolean {
    return this.pageNumber === this.totalPages && !this.content.watched;
  }

  private _createMarkers(): void {
    this.content.concepts.forEach((concept: PdfReference) => {
      if (concept.positions) {
        // eslint-disable-next-line prefer-spread
        this.markers.push.apply(
          this.markers,
          concept.positions.map((pos: number) => new PdfMarker(concept.name, pos))
        );
      }
    });
  }

  public OpenTagDialog() {
    const params = this._route.snapshot.params;
    const subjectId = params['subjectId'];
    const moduleId = params['moduleId'];
    const dialogRef = this._dialog.open(PdfTagDialogComponent, {
      width: '600px',
      data: {
        content: this.content,
        subjectId: subjectId,
        moduleId: moduleId,
        userId: this._auth.getLoggedUserId(),
        moduleTags: this.contentTags,
        moduleTagValuations: this.moduleTagValuations
      }
    });

    dialogRef.afterClosed().subscribe((update: any) => {
      if (update) {
        this.updateModuleTags.emit();
      }
    });
  }

  public toogleFullScreen() {
    const scrollabeDiv = document.getElementById('scrollable-div') as HTMLDivElement;
    if (!this.isFullScreenMode) {
      scrollabeDiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  public fullScreenChange(evt) {
    this.isFullScreenMode = !this.isFullScreenMode;
  }
}
