<div class="inner-content">

  <div class="header">
    <!--div class="empty-block"></div-->
    <h2>RESULTADO DAS AVALIAÇÕES, PESQUISAS E TRABALHOS NOS CURSOS</h2>
    <!--div class="actions" >
      <button class="btn-test" (click)="createNewTest()" >
          NOVA AVALIAÇÃO OU PESQUISA
      </button>
    </div-->
  </div>

  <p>
    Nesta seção é possível visualizar respostas de provas, avaliações de reação acadêmica
    e pesquisas diversas associadas a cursos, eventos {{replacement.track.plural | replacement:'ou' }}.
    Elas aparecem para os alunos no corpo do curso, evento {{replacement.track.singular | replacement:'ou' }} como mais
    uma tarefa.
  </p>


  <div class="tests">
    <app-settings-valuation-test-results-card *ngFor="let test of tests" (viewTest)="viewTest($event)"
      (getAnswersExcel)="getAnswersExcel($event)" [test]="test"></app-settings-valuation-test-results-card>
  </div>

  <p class="emptyState" *ngIf="tests && tests.length === 0">
    Ainda não há nenhuma avaliação criada.
  </p>

</div>
