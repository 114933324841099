<div class="content">
  <app-cockpit-title type="title" templateName="effort" titleText="Métricas de EMPENHO">
  </app-cockpit-title>
  <div class="content-effort">
    <div>
      <app-effort-progress [metrics]="metrics.engagement"
        text="Engajamento médio dos alunos da turma {{ (replacement.track.singular | replacement:'na') }}">
        <app-cockpit-title type="subTitle" titleText="Engajamento"
          legend="Métrica relacionada ao percentual de atingimento de prazos estabelecidos">
        </app-cockpit-title>
      </app-effort-progress>
    </div>
    <div>
      <app-effort-progress [metrics]="metrics.participation" text="Participação média dos alunos da turma {{ (replacement.track.singular | replacement:'na') }}">
        <app-cockpit-title
          type="subTitle"
          titleText="Participação"
          legend="Métrica relacionada ao percentual de participação na criação de questões ou conceitos">
        </app-cockpit-title>
      </app-effort-progress>
    </div>
    <div>
      <app-effort-progress [metrics]="metrics.effort"
        text="Esforço médio dos alunos da turma {{ (replacement.track.singular | replacement:'na') }}">
        <app-cockpit-title type="subTitle" titleText="Esforço"
          legend="Quantidade de horas dedicadas ao uso da plataforma">
        </app-cockpit-title>
      </app-effort-progress>
    </div>
  </div>
</div>
