<div class="module-info step">
  <h2>Informações do Evento</h2>
  <form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="info">
      <div class="validation-field">
        <mat-form-field>
          <input matInput placeholder="Nome" formControlName="title" required />
        </mat-form-field>
        <control-messages [control]="formGroup.get('title')"></control-messages>
      </div>
      <div class="validation-field" *ngIf="hasEcommerceIntegration">
        <ng-container *ngIf="!event.ecommerceId">
          <mat-form-field>
            <mat-select placeholder="Criar Evento no Ecommerce" formControlName="createInEcommerce" required>
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="event.ecommerceId">
          <mat-form-field>
            <input matInput placeholder="Id do Ecommerce" formControlName="ecommerceId" />
          </mat-form-field>
        </ng-container>
      </div>
      <div class="validation-field">
        <ng-container>
          <mat-form-field>
            <mat-select placeholder="Forçar Problem Statement" formControlName="forceProblemStatement">
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
      <div class="validation-field">
        <mat-form-field>
          <input matInput placeholder="URL do Produto" formControlName="storeUrl" />
        </mat-form-field>
        <control-messages [control]="formGroup.get('storeUrl')"></control-messages>
      </div>
      <div class="validation-field">
        <label>Tutores</label>
        <app-list-search [noPadding]="true" placeholder="Digite o nome de um usuário para adicioná-lo como tutor"
          (triggerSearch)="triggerTutorSearch($event)"></app-list-search>
        <ul class="user-list" *ngIf="tutors && tutors.length > 0" (clickOutside)="resetTutorSearch()">
          <li *ngFor="let tutor of tutors" (click)="addTutor(tutor)">
            <img class="logo" [src]="tutor.imageUrl ? tutor.imageUrl : './assets/img/user-image-placeholder.png'" />
            <span>{{ tutor.name }}</span>
          </li>
        </ul>
        <div class="chips" *ngIf="selectedTutors && selectedTutors.length > 0">
          <p *ngFor="let tutor of selectedTutors">
            <img class="logo" [src]="tutor.imageUrl ? tutor.imageUrl : './assets/img/user-image-placeholder.png'" />
            {{ tutor.name }}
            <span (click)="removeSelectedTutor(tutor)">X</span>
          </p>
        </div>
      </div>

      <div class="validation-field">
        <mat-form-field>
          <textarea matInput placeholder="Resumo (max 600 caracteres)" formControlName="excerpt" required></textarea>
        </mat-form-field>
        <control-messages [control]="formGroup.get('excerpt')"></control-messages>
      </div>
      <div class="validation-field">
        <mat-form-field>
          <textarea matInput placeholder="Mini Bio (max 600 caracteres)" formControlName="instructorMiniBio"></textarea>
        </mat-form-field>
        <control-messages [control]="formGroup.get('instructorMiniBio')"></control-messages>
      </div>
      <div class="tags">
        <p class="title">TAGS</p>
        <div class="tags">
          <img class="busca" src="./assets/img/seach-black.png" />
          <input matInput required (keyup.enter)="addTag($event)"
            placeholder="Escreva os temas pertinentes a este módulo para adicioná-los como TAGS" />
          <div class="tag" *ngFor="let tag of formGroup.get('tags').value; let index = index">
            {{ tag }}
            <img (click)="removeTag(index)" src="./assets/img/close.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="images">
      <div class="image-upload">
        <p class="title">Imagem de Capa (240x240)</p>
        <img [src]="formGroup.get('imageUrl').value" />
        <button type="button" class="btn-test btn-240" (click)="uploadImage(240, 240, 'imageUrl')">
          Fazer Upload de Imagem
        </button>
      </div>
      <div class="image-upload">
        <p class="title">Imagem do Instrutor (360x360)</p>
        <img [src]="formGroup.get('instructorImageUrl').value" />
        <button type="button" class="btn-test btn-240" (click)="uploadImage(360, 360, 'instructorImageUrl')">
          Fazer Upload de Imagem
        </button>
      </div>
    </div>
  </form>
</div>
