import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { forkJoin, Observable } from 'rxjs';
import { Track } from '../../models/track.model';
import { TrackEvent } from 'src/app/models/track-event.model';
import { EcommerceProduct } from 'src/app/models/ecommerce-product.model';
import { DownloadService } from 'src/app/shared/services/download.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { map } from 'rxjs/operators';
import { TrackModule } from 'src/app/models/track-module.model';
import { InstructorItem, TrackInstructor } from 'src/app/pages/track/models/track-instructor.model';

export interface TrackResponsible { instructors: InstructorItem[]; managers: string[]; }
@Injectable()
export class SettingsTracksService {


  constructor(
    private _httpService: BackendService,
    private _downloadService: DownloadService,
    private _authService: AuthService) { }

  public getTrackById(trackId: string): Observable<BackendResponse<Track>> {
    return this._httpService.get('getTrackById', [], [{
      'name': 'id', 'value': trackId
    }]);
  }

  public getTracksGrades(trackIds: string): Observable<any> {
    return this._httpService.get('getTrackCurrentStudentOverview', [], [{
      'name': 'trackId', 'value': trackIds
    }]);
  }
  public getTrackReportStudentsFiltered(
    { page, pageSize, sort, sortAscending, startDate, endDate, trackIds }): Observable<any> {
    return this._httpService.post('getTrackReportStudentsFiltered', {
      page: page,
      pageSize: pageSize,
      filters: {
        startDate,
        endDate,
        trackIds,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }

  public calculateGrade(trackId: string, skipBdqVerification = false) {
    return this._httpService.post('calculateGrade', {
      'trackId': trackId,
      'skipBdqVerification': skipBdqVerification
    });
  }

  public getPagedFilteredTracksList(
    page: number = 1, pageSize: number = 10,
    searchValue: string = '', published: boolean = null
  ): Observable<any> {
    return this._httpService.post('getPagedFilteredTracksList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'published': published
      }
    });
  }

  public manageTrackInfo(track: Track): Observable<any> {
    return this._httpService.post('manageTrackInfo', track);
  }

  public deleteTrackById(id: string): Observable<any> {
    return this._httpService.post('deleteTrackById', {
      'trackId': id
    });
  }

  public getTrackOverview(
    trackId: string, page: number = 1, searchTerm: string = '', pageSize: number = 10
  ): Observable<any> {
    return this._httpService.get('getTrackOverview', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'page', 'value': page.toString() },
      { 'name': 'pageSize', 'value': pageSize.toString() },
      { 'name': 'searchTerm', 'value': searchTerm },
    ]);
  }

  public getTrackOverviewEventInfo({ trackId }: { trackId: string }): Observable<any> {
    return this._httpService.get('getTrackOverviewEventInfo', [], [
      { 'name': 'trackId', 'value': trackId },
    ]);
  }

  public getTrackStudentOverview(trackId: string, studentId: string): Observable<any> {
    return this._httpService.get('getTrackStudentOverview', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'studentId', 'value': studentId },
    ]);
  }

  public getTrackStudentReportCard(trackId: string, studentId: string): Observable<any> {
    return this._httpService.get('getTrackStudentReportCard', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'studentId', 'value': studentId }
    ]);
  }

  public getTrackModuleOverview(trackId: string, moduleId: string): Observable<any> {
    return this._httpService.get('getTrackModuleOverview', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'moduleId', 'value': moduleId }
    ]);
  }

  public manageCalendarEvents(trackId: string, calendarEvents: Array<TrackEvent>): Observable<any> {
    return this._httpService.put('manageCalendarEvents', {
      'trackId': trackId,
      'calendarEvents': calendarEvents
    });
  }

  public manageEcommerceProducts(trackId: string, products: Array<EcommerceProduct>): Observable<any> {
    return this._httpService.put('manageEcommerceProducts', {
      'trackId': trackId,
      'products': products
    });
  }

  public getTrackOverviewGrades(trackId: string): Observable<any> {
    return this._httpService.get('getTrackOverviewGrades', [], [
      { 'name': 'trackId', 'value': trackId }
    ]);
  }

  public getTrackOverviewStudents(data: { trackId: string, getBinaryResponse: true }): Observable<any> {
    return data.getBinaryResponse ?
      this._downloadService._downloadFile('getTrackOverviewStudents', data)
      : this._httpService.post('getTrackOverviewStudents', data);
  }

  public getTrackStudentByEventId(eventId: string): Observable<any> {
    return this._httpService.post('getTrackStudentByEventId', { eventId });
  }

  public getPagedFilteredManagerTracksList(page: number = 1, pageSize: number = 10, searchValue: string = ''): Observable<any> {
    return this._httpService.post('getPagedFilteredManagerTracksList', {
      'page': page,
      'pageSize': pageSize,
      'filters': { 'term': searchValue }
    });
  }

  public addCalendarEventsFromFile(id: string, fileContent: string, concatEvents: boolean = true): Observable<any> {
    return this._httpService.post('addCalendarEventsFromFile', {
      'trackId': id,
      'fileContent': fileContent,
      concatEvents
    });
  }

  public getAllContent(id: string): Observable<any> {
    return this._httpService.get('getAllTrackContent', [], [{ 'name': 'trackId', 'value': id }]);
  }

  public getAlltracks(): Observable<any> {
    return this._httpService.get('getAllTracks', [], []);
  }

  public getTrackReportStudents(): Observable<any> {
    return this._httpService.get('getTrackReportStudents', [], []);
  }

  public getTrackNps(): Observable<any> {
    return this._httpService.get('getTrackNps', [], []);
  }

  public getUsersProgress(trackId: string, studentId: string = ''): Observable<any> {
    return this._httpService.get('getUsersProgress', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'studentId', 'value': studentId }
    ]);
  }

  public getTrackInstructors(trackId: string): Observable<BackendResponse<TrackInstructor>> {
    return this._httpService.post('getTrackInstructors', { trackId });
  }

  public getModuleConfiguration(trackId: string, moduleId: string): Observable<BackendResponse<TrackModule>> {
    return this._httpService.get('getModuleConfiguration', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'moduleId', 'value': moduleId }
    ]);
  }


  public getTrackResponsibles(trackId: string): Observable<TrackResponsible> {
    return forkJoin({
      responsibles: this.getTrackInstructors(trackId)
    })
      .pipe(
        map(response => ({ responsibles: response.responsibles.data })),
        map(response => ({
          managers: [],
          instructors: [...response.responsibles.eventInstructors, ...response.responsibles.moduleInstructors]
        }))
      );
  }

  public getPagedFilteredTrackAreas(page: number, pageSize: number): Observable<any> {
    return this._httpService.post('getPagedFilteredTrackAreas', {
      page: page,
      pageSize: pageSize
    });
  }
  public clone(trackId: string, isDraft: boolean): Observable<BackendResponse<[]>> {
    return this._httpService.post('cloneTrack', {
      trackId,
      isDraft
    });
  }

  public getTrackAreaById(trackAreaId: string): Observable<any> {
    return this._httpService.get('getTrackAreaById', [], [
      { 'name': 'trackAreaId', 'value': trackAreaId },
    ]);
  }

  public addTrackArea(trackAreaTitle: string): Observable<any> {
    return this._httpService.post('addTrackArea', {
      'title': trackAreaTitle
    });
  }

  public updateTrackArea(trackAreaId: string, trackAreaTitle: string): Observable<any> {
    return this._httpService.post('updateTrackArea', {
      'id': trackAreaId,
      'title': trackAreaTitle
    });
  }

  public deleteTrackAreaById(trackAreaId: string): Observable<any> {
    return this._httpService.post('deleteTrackAreaById', {
      'trackAreaId': trackAreaId
    });
  }

  public getPagedFilteredTrackSubAreas(page: number, pageSize: number): Observable<any> {
    return this._httpService.post('getPagedFilteredTrackSubAreas', {
      page: page,
      pageSize: pageSize
    });
  }

  public getTrackSubAreaById(trackSubAreaId: string): Observable<any> {
    return this._httpService.get('getTrackSubAreaById', [], [
      { 'name': 'trackSubAreaId', 'value': trackSubAreaId },
    ]);
  }

  public addTrackSubArea(trackSubAreaTitle: string): Observable<any> {
    return this._httpService.post('addTrackSubArea', {
      'title': trackSubAreaTitle
    });
  }

  public updateTrackSubArea(trackSubAreaId: string, trackSubAreaTitle: string): Observable<any> {
    return this._httpService.post('updateTrackSubArea', {
      'id': trackSubAreaId,
      'title': trackSubAreaTitle
    });
  }

  public deleteTrackSubAreaById(trackSubAreaId: string): Observable<any> {
    return this._httpService.post('deleteTrackSubAreaById', {
      'trackSubAreaId': trackSubAreaId
    });
  }


}
