import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ValuationTest } from 'src/app/models/valuation-test.interface';
import { cloneDeep } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';

@Component({
  selector: 'app-settings-valuation-test-card',
  template: `
    <div class="test-card" >
      <div class="preview" (click)="viewTest.emit(test)" title="Visualizar respostas">
        <h3>
          {{ test.title }}
        </h3>
        <p class="content">
          {{ test.questions.length }} {{test.type !== 3 ? 'PERGUNTAS' : 'TRABALHO'}}<br>
          <span (click)="getAnswersExcel.emit(test)" *ngIf="test.type !== 3 && test.type !== 4">
            exportar respostas
          </span>
        </p>
      </div>

      <div class="edit" >
        <img src="./assets/img/edit.png" (click)="editTest.emit(test)" />
        <img style="margin-top: 15px;"  src="./assets/img/icon_trilha.png" (click)="emitClonedTest(test)" />
        <!-- <img style="margin-top: 15px;" src="./assets/img/edit.png"  /> -->
        <img style="margin-top: 12px;" src="./assets/img/trash.png" (click)="deleteTest.emit(test)" />
      </div>
    </div>
  `,
  styleUrls: ['./valuation-test-card.component.scss']
})
export class SettingsValuationTestCardComponent {

  @Input() test: ValuationTest;
  @Output() viewTest = new EventEmitter<ValuationTest>();
  @Output() editTest = new EventEmitter<ValuationTest>();
  @Output() deleteTest = new EventEmitter<ValuationTest>();
  @Output() cloneTest = new EventEmitter<ValuationTest>();
  @Output() getAnswersExcel = new EventEmitter<ValuationTest>();


  constructor(
    private _dialog: MatDialog
  ) {

  }

  public emitClonedTest(test: ValuationTest) {

    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      data: {
        message: 'Deseja duplicar esse item?',
        positiveTextAction: 'Sim',
        negativeTextAction: 'Cancelar'
      } as SimpleDialog
    });


    dialogRef.afterClosed().subscribe(canDuplicate => {
      if (canDuplicate) {
        this.cloneTest.emit(this._createClone(test));
      }
    });

  }


  private _createClone(test: ValuationTest): ValuationTest {
    const clone = cloneDeep(test);
    clone.title = `Cópia de ${clone.title}`;
    delete clone.id;
    delete clone.hasAnswers;
    clone.testModules = [];
    clone.testModules = [];
    clone.testQuestions.forEach(question => {
      delete question.id;
      delete question.testId;
    });
    return clone;
  }

}
