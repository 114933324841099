<h1>
  {{ newEvent && newEvent.title ? newEvent.title : 'NOVO EVENTO'}}
</h1>
<div class="subheader">
  <div class="draft-title" [ngClass]="{ 'opened': showDraftOptions, disabled: !isDraft}">
    <span (click)="showDraftOptions = !showDraftOptions" *ngIf="isDraft">
      MODIFICAÇÕES EM RASCUNHO &nbsp;&nbsp;&nbsp;&nbsp;
      <img src="./assets/img/arrow-back-white.png" />
    </span>
    <span *ngIf="!isDraft">
      SEM MODIFICAÇÕES
    </span>
    <ul class="draft-options" *ngIf="showDraftOptions">
      <li (click)="publishDraftChanges()">
        PUBLICAR ALTERAÇÕES
      </li>
      <li (click)="rejectDraftChanges()">
        REJEITAR ALTERAÇÕES
      </li>
    </ul>
  </div>
</div>

<div class="new-module inner-content">
  <mat-horizontal-stepper #stepper [ngClass]="{ 'no-action': !allowEditing }"
    (selectionChange)="stepChanged($event, false)">

    <ng-container *ngIf="newEvent$">

      <mat-step id="eventInfo" *ngIf="!isTrackManager" [completed]="allowEditing">
        <ng-template matStepLabel> Informações </ng-template>
        <app-new-event-event-info #eventInfo [event$]="newEvent$" (setEventInfo)="setEventInfo($event)">
        </app-new-event-event-info>
      </mat-step>

      <mat-step id="eventDate" [completed]="allowEditing">
        <ng-template matStepLabel> Datas </ng-template>
        <app-new-event-date #eventDate [event$]="newEvent$" (setEventDates)="setEventDates($event)"
          [updateSchedules]="childUpdateSchedule.bind(this)" [deleteSchedule]="deleteSchedule.bind(this)">
        </app-new-event-date>
      </mat-step>

      <mat-step id="eventVideo" *ngIf="!isTrackManager" [completed]="allowEditing">
        <ng-template matStepLabel> Vídeo </ng-template>
        <app-new-event-video #eventVideo [event$]="newEvent$" (setEventVideo)="setEventVideo($event)">
        </app-new-event-video>
      </mat-step>

      <mat-step id="eventMaterials" [completed]="allowEditing">
        <ng-template matStepLabel> Materiais </ng-template>
        <app-new-event-support-materials #eventMaterials [event$]="newEvent$"
          (addEventSupportMaterials)="addEventSupportMaterials($event)"></app-new-event-support-materials>
      </mat-step>

      <mat-step id="eventRequirements" *ngIf="!isTrackManager" [completed]="allowEditing">
        <ng-template matStepLabel> Requisitos </ng-template>
        <app-new-event-requirements #eventRequirements [event$]="newEvent$" [levels]="levels"
          (setRequirements)="setRequirements($event)"></app-new-event-requirements>
      </mat-step>

      <mat-step id="eventQuestions" [completed]="allowEditing">
        <ng-template matStepLabel> Perguntas </ng-template>
        <app-new-event-questions #eventQuestions [event$]="newEvent$" (addEventQuestions)="addEventQuestions($event)">
        </app-new-event-questions>
      </mat-step>

      <mat-step id="relevantDate" [completed]="allowEditing">
        <ng-template matStepLabel> Avaliações </ng-template>
        <app-relevant-date #relevantDate [event$]="newEvent$" (addValuationDate)="addValuationDate($event)">
        </app-relevant-date>
      </mat-step>
    </ng-container>
  </mat-horizontal-stepper>

  <div class="footer">
    <button class="previous" type="button" mat-button [hidden]="stepIndex === 0" (click)="saveStep(true, false)">
      Voltar
    </button>
    <button class="save" type="button" mat-button (click)="saveStep(false, false)">
      Salvar {{ stepIndex === this.stepper?.steps?.length - 1 ? 'e Finalizar' : '' }}
    </button>
    <button class="next" type="button" mat-button [hidden]="stepIndex === this.stepper?.steps?.length - 1"
      (click)="saveStep(true, true)">
      Salvar e Continuar
    </button>
  </div>
</div>
