import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramPreview } from '../../../models/previews/program.interface';
import { UserProgress } from 'src/app/models/subject.model';
import { UserService } from 'src/app/pages/_services/user.service';
import { UtilService } from '../../services/util.service';
import { AuthService } from '../../services/auth.service';
import { Recommendations } from 'src/app/settings/users/user-models/user-track';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../classes/notification';
import { TrackHeightChangeEnum } from 'src/app/models/enums/track-height-change.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ERole } from 'src/app/models/enums/role.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss']
})
export class ProgramCardComponent extends NotificationClass implements OnChanges {

  @Input() program: ProgramPreview;
  @Input() viewType?: string = 'cards';
  @Input() showModule: boolean = false;
  @Input() showEvents: boolean = false;
  @Input() showRecommended: boolean = false;
  @Input() showText: boolean = false;
  @Input() showContent: boolean = false;
  @Input() hours: boolean = false;
  @Input() showPrice: boolean = false;
  @Input() showButtonSubscribe: boolean = false;
  @Input() redirectWhenRecommended: boolean = true;
  private _showButtonKnowMore: boolean;

  public hasFeatureKnowMore = environment.features.knowMore;

  @Input()
  public get showButtonKnowMore(): boolean {
    return true;
  }
  public set showButtonKnowMore(showButtonKnowMore: boolean) { this._showButtonKnowMore = showButtonKnowMore; }

  public height: number = 170;

  constructor(
    private _router: Router,
    public userService: UserService,
    private _usersService: SettingsUsersService,
    protected _snackBar: MatSnackBar,
    private _utilService: UtilService,
    private _authService: AuthService
  ) {
    super(_snackBar);
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (this.viewType === 'list') {
      this.height = 150;
    } else {
      this.height = 170;
    }
  }

  public goToPageProgramUrl(event) {
    event.stopPropagation();
    this._router.navigate(['programa/' + this.program.id]);
  }

}
