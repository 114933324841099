<h1 *ngIf="!summaryForm">
  <a href="javascript:history.back()">
    voltar
  </a>
  {{ isEditNewUser ? 'ALTERAR DADOS' : 'NOVO USUÁRIO' }}
  <span *ngIf="canUpdatePassword()"
    style="position: absolute; left: 80%; font-size: 15px; cursor: pointer; min-width: 100px;"
    (click)="changePassword()">
    alterar senha
  </span>
</h1>
<div class="inner-content">

  <div class="user-info">
    <form [formGroup]="formGroup" onsubmit="save()">
      <h2 *ngIf="!summaryForm">INFORMAÇÕES BÁSICAS</h2>
      <mat-form-field>
        <input matInput placeholder="Nome" formControlName="name" required />
      </mat-form-field>
      <control-messages [control]="formGroup.get('name')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Matrícula" formControlName="registrationId" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('registrationId')"></control-messages>
      <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Data de Nascimento" formControlName="dateBorn" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <control-messages [control]="formGroup.get('dateBorn')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="ID/Documento" formControlName="cpf" required />
      </mat-form-field>
      <control-messages [control]="formGroup.get('cpf')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Usuário" formControlName="userName" required />
      </mat-form-field>
      <control-messages [control]="formGroup.get('userName')"></control-messages>
      <mat-form-field *ngIf="!isEditNewUser">
        <input matInput placeholder="Senha" formControlName="password" required />
      </mat-form-field>
      <control-messages [control]="formGroup.get('password')"></control-messages>
      <div style="width: 100%;">
        <app-list-search placeholder="Responsável" [showRemoveButton]="true" [inputValue]="responsible"
          [noPadding]="true" [disabled]="disableEdit || lockChangeUserInfo" (removedItens)="removeResponsibleFromForm()"
          (triggerSearch)="triggerResponsibleSearch($event)"></app-list-search>
        <ul class="user-list" *ngIf="responsibles && responsibles.length > 0" (clickOutside)="resetResponsibleSearch()">
          <li *ngFor="let responsible of responsibles" (click)="addResponsibleIntoForm(responsible)">
            <span>{{ responsible.name }}</span>
          </li>
        </ul>
      </div>
      <mat-form-field>
        <input matInput placeholder="Mini Bio" formControlName="info" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('info')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="LinkedIn" formControlName="linkedIn" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('linkedIn')"></control-messages>
      <mat-form-field>
        <mat-select formControlName="specialNeeds" placeholder="Portador de deficiência" [compareWith]="compareFn"
          (selectionChange)="changeSpecialNeeds($event.value)">
          <mat-option [value]="true">Sim</mat-option>
          <mat-option [value]="false">Não</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Descrever deficiência" formControlName="specialNeedsDescription" />
      </mat-form-field>
      <div class="cep-search">
        <mat-form-field>
          <input matInput placeholder="CEP" formControlName="zipCode" />
        </mat-form-field>
        <button class="btn-test" type="button" (click)="searchCep()" [disabled]="lockChangeUserInfo">
          Pesquisar
        </button>
      </div>
      <control-messages [control]="formGroup.get('zipCode')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Cidade" formControlName="city" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('city')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Estado" formControlName="state" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('state')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Rua" formControlName="street" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('street')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Complemento" formControlName="addressDetails" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('addressDetails')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Bairro" formControlName="district" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('district')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Faculdade Atual" formControlName="currentCollege" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('currentCollege')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Ano de conclusão" maxlength="5" formControlName="conclusionYear" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('conclusionYear')"></control-messages>

      <h2>CONTATO</h2>
      <mat-form-field>
        <input matInput placeholder="E-mail" formControlName="email" required />
      </mat-form-field>
      <control-messages [control]="formGroup.get('email')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Telefone" formControlName="phone" />
      </mat-form-field>
      <control-messages [control]="formGroup.get('phone')"></control-messages>
      <mat-form-field>
        <input matInput placeholder="Telefone 2" formControlName="phone2" />
      </mat-form-field>

      <ng-container *ngIf="canSetRole()">
        <h2>FÓRUM</h2>
        <mat-form-field>
          <mat-select formControlName="forumActivities" placeholder="Receber email de atividade dos fóruns?"
            [compareWith]="compareFn" (selectionChange)="changeForumActivity($event.value)">
            <mat-option [value]="true">Sim</mat-option>
            <mat-option [value]="false">Não</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="E-mail" formControlName="forumEmail" />
        </mat-form-field>
      </ng-container>

      <div *ngIf="hasRecruitment">
        <h2>DOCUMENTO</h2>
        <mat-form-field>
          <mat-select formControlName="document" placeholder="Documento">
            <mat-option [value]="1">RG</mat-option>
            <mat-option [value]="2">CNH</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Nº" formControlName="documentNumber" />
        </mat-form-field>

        <control-messages [control]="formGroup.get('documentNumber')"></control-messages>
        <mat-form-field>
          <input matInput placeholder="Órgão emissor" formControlName="documentEmitter" />
        </mat-form-field>
        <control-messages [control]="formGroup.get('documentEmitter')"></control-messages>
        <mat-form-field>
          <input matInput [matDatepicker]="startDate" placeholder="Data de emissão" formControlName="emitDate" />
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <control-messages [control]="formGroup.get('emitDate')"></control-messages>
        <mat-form-field>
          <input matInput [matDatepicker]="endDate" placeholder="Validade" formControlName="expirationDate" />
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
        <control-messages [control]="formGroup.get('expirationDate')"></control-messages>
      </div>

      <div *ngIf="summaryForm" style="text-align: center;">
        <button class="btn-test" type="submit" [disabled]="!loadedCategories || !loadedUser">
          Salvar Alterações
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="!summaryForm" class="user-logo">
    <div class="img-container">
      <img [src]="formGroup.get('imageUrl').value" />
      <div class="edit-icon" (click)="uploadImage(240, 240, formGroup.get('imageUrl'))">
        <img src="./assets/img/edit-white.png" />
      </div>
      <div class="remove-icon" (click)="removeImage(formGroup.get('imageUrl'))">
        <img src="./assets/img/wrong-answer.png" />
      </div>
    </div><br>
    <mat-form-field>
      <mat-select placeholder="Perfil de Usuário" [(ngModel)]="userRole" [disabled]="!canSetRole()">
        <mat-option value="Student">Usuário</mat-option>
        <mat-option value="Secretary">Secretaria</mat-option>
        <mat-option value="HumanResources">RH</mat-option>
        <mat-option value="Admin">Administrador</mat-option>
        <mat-option value="Recruiter">Recrutador</mat-option>
        <mat-option value="BusinessManager">Empresa</mat-option>
        <mat-option value="Author">Autor</mat-option>
        <mat-option value="Sales">Vendas</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!summaryForm" class="footer">
  <button class="btn-test" type="button" (click)="save()" [disabled]="!loadedCategories || !loadedUser">
    Salvar Alterações
  </button>
</div>
