import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialDialog } from 'src/app/pages/module/module-header/material-dialog/support-material/models/material-dialog.model';

@Component({
  selector: 'app-material-dialog',
  templateUrl: './material-dialog.component.html',
  styleUrls: ['./material-dialog.component.scss']
})
export class MaterialDialogComponent {

  public entity: MaterialDialog;

  constructor(
    public dialogRef: MatDialogRef<MaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.entity = this.data;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }



}
