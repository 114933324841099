import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { AutocompleteModule } from 'src/app/shared/components/autocomplete/autocomplete.module';
import { UserSearchComponent } from './user-search.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    CommonModule,
    ListSearchModule,
    AutocompleteModule,
    ClickOutsideModule
  ],
  declarations: [UserSearchComponent],
  exports: [UserSearchComponent],
})
export class UserSearchModule { }
