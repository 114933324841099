import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';
import { Router } from '@angular/router';
import { SettingsTracksService } from '../_services/tracks.service';
import { TrackPreview } from '../../models/previews/track.interface';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DeleteTrackDialogComponent } from './delete-track/delete-track.dialog';
import { TrackDraftService } from '../_services/track-draft.service';
import { TrackDraft } from 'src/app/models/track-draft.model';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';

@Component({
  selector: 'app-settings-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss']
})
export class SettingsTracksComponent extends NotificationClass implements OnInit {

  public tracks: Array<TrackDraft> = [];
  public tracksCount: number = 0;
  private _tracksPage: number = 1;
  private _searchSubject: Subject<string> = new Subject();

  constructor(
    protected _snackBar: MatSnackBar,
    private _tracksService: SettingsTracksService,
    private _trackDraftService: TrackDraftService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadTracks(this._tracksPage);
    this._setSearchSubscription();
  }

  public goToPage(page: number) {
    if (page !== this._tracksPage) {
      this._tracksPage = page;
      this._loadTracks(this._tracksPage);
    }
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  public gotoTrackAreas(): void {
    this._router.navigate([ '/configuracoes/trilhas-areas' ]);
  }

  public gotoTrackSubAreas(): void {
    this._router.navigate([ '/configuracoes/trilhas-subareas' ]);
  }

  public createNewTrack(): void {
    this._router.navigate(['/configuracoes/trilha']);
  }

  public editTrack(track: TrackDraft) {
    this._getTrackById(track).subscribe((response) => {
      response.data['trackAreaId']=response?.data?.area?.id;
      response.data['trackSubAreaId']=response?.data?.subArea?.id;
      this._router.navigate([`/configuracoes/trilha/${response.data.id}`], { state: { editingTrack: response.data } });
    });
  }

  private _getTrackById(track: TrackDraft): Observable<any> {
    return track.isDraft
      ? this._trackDraftService.getTrackDraftById(track.id)
      : this._tracksService.getTrackById(track.id);
  }

  public deleteTrack(track: TrackDraft) {
    const dialogRef = this._dialog.open(DeleteTrackDialogComponent, {
      width: '1000px'
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const id = track.isDraft ? track.trackId : track.id;
        this._tracksService.deleteTrackById(id).subscribe((response) => {
          this.notify('Trilha deletada com sucesso');
          const index = this.tracks.findIndex(x => x.id === track.id);
          this.tracks.splice(index, 1);
        });
      }
    });
  }

  private _loadTracks(page: number, searchValue: string = ''): void {
    this._trackDraftService.getPagedTrackAndDraft(
      page, 20, searchValue
    ).subscribe((response) => {
      this.tracks = response.data.pagedItem;
      this.tracksCount = response.data.itemsCount;
    });
  }

  private _setSearchSubscription() {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this._loadTracks(this._tracksPage, searchValue);
    });
  }

  public clone(track: TrackDraft) {

    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      data: {
        message: 'Tem certeza que deseja duplicar esta trilha ?',
        positiveTextAction: 'Sim',
        negativeTextAction: 'Cancelar'
      } as SimpleDialog
    });

    dialogRef.afterClosed().subscribe(canDuplicate => {
      if (canDuplicate) {
        this._tracksService.clone(track.id, track.isDraft).subscribe(response => {
          this._tracksPage = 1;
          this._loadTracks(this._tracksPage);

          this.notify('Trilha duplicado com sucesso.');
        });
      }
    });
  }

}
