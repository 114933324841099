<div class="contents-menu">
  <div class="arrow">
    <span>CONTEÚDO</span>
    <img src="./assets/img/expand-arrow.png" (click)="closeMenu()" />
  </div>
  <div *ngIf="menuContents && menuContents.modules">
    <div class="module-item" *ngFor="let mod of menuContents.modules;" [ngClass]="{ 'active': mod.selected === true }"
      (click)="toggleSelection($event, mod)">
      <div class="module-title">{{ mod.title }}</div>

      <div class="subject-item" *ngFor="let subject of mod.subjects;"
        [ngClass]="{ 'active': subject.selected === true }" (click)="toggleSelection($event, subject)">

        <div class="subject-title">{{ subject.title }}</div>

        <div class="content content-item" *ngFor="let content of subject.contents; index as contentIndex"
          [ngClass]="{ 'active': contentIndex === currentIndex }"
          (click)="goToNewContent($event, mod.id, subject.id, contentIndex);finishContent(mod.id, subject.id, content)">
          <div class="content-title">
            <mat-checkbox [checked]="content.watched" [disableRipple]="true" [class.mat-checkbox-disabled]="false"
              (click)=" $event.stopPropagation()"></mat-checkbox>
            {{ content.title }}
          </div>
          <br>
          <div class="progress" *ngIf="!isNullOrUndefined(content.duration) && content.duration !== 0">
            <span>{{ utilService.formatSecondsToMinutes(content.duration) }} min</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="menuContents && !menuContents.modules">
    <div class="subject-item" *ngFor="let subject of menuContents.subjects; index as subjectIndex"
      [ngClass]="{ 'active': subject.selected === true, 'no-border': subjectIndex === menuContents.subjects.length - 1 && hasFeatureLevels}"
      (click)="toggleSelection($event, subject)">

      <div class="subject-title">{{ subject.title }}</div>
      <ng-container *ngIf="subject.selected === true">
        <div class="content content-item" *ngFor="let content of subject.contents; index as contentIndex"
          [ngClass]="{ 'active': contentIndex === currentIndex, 'no-border': (contentIndex === subject.contents.length - 1)}"
          (click)="goToNewContent($event, moduleId, subject.id, contentIndex)">
          <div class="content-title">
            <mat-checkbox [checked]="content.watched" [disableRipple]="true" [class.mat-checkbox-disabled]="false"
              (click)=" $event.stopPropagation()"></mat-checkbox>
            {{ content.title }}
          </div>
          <br>
          <div class="progress" *ngIf="!content.duration && content.duration !== 0">
            <span>{{ utilService.formatSecondsToMinutes(content.duration) }} min</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="hasFeatureLevels">
    <ng-container *ngIf="hasQuestionsSubject">
      <p class="section">AVALIAÇÃO</p>
      <div class="rating">
        <app-progress-bar [currentProgress]="(subjectProgress ? subjectProgress.progress : 0) | asPercentage"
          [height]="8" [roundedBorder]="true"></app-progress-bar>
        <span>
          {{ getLevelDescription(subjectProgress ? subjectProgress.level : null) }}
          {{ (subjectProgress ? subjectProgress.progress : 0) | asPercentage }}%
        </span>
      </div>
    </ng-container>

    <p class="section">NÍVEIS CONQUISTADOS</p>
    <div class="badges">
      <img [src]="getBadgesProgressImageSrc(subjectProgress ? subjectProgress.level : null)" />
      {{ getLevelDescription(subjectProgress ? subjectProgress.level : null) }}
    </div>
  </ng-container>
</div>
