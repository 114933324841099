import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Module, ModuleQuestion, ModuleQuestionAnswer, ModuleQuestionValuation } from 'src/app/models/module.model';
import { Subject } from 'src/app/models/subject.model';

@Component({
  selector: 'app-module-valuation',
  templateUrl: './module-valuation.component.html',
  styleUrls: ['./module-valuation.component.scss'],
})
export class ModuleValuationComponent implements OnInit {

  @Input() readonly subject: Subject;
  @Input() readonly moduleQuestion: ModuleQuestion;
  @Input() readonly moduleQuestionValuations: ModuleQuestionValuation[];
  @Output() next = new EventEmitter<any>();
  @Output() goBack = new EventEmitter();

  public valuations: ModuleQuestionValuation[] = [];
  public conclusionStep: number = 0;
  constructor() {}

  ngOnInit() {
    if (this.moduleQuestionValuations) {
      this.valuations = this.moduleQuestionValuations;
    }
  }

  public getTextValuationGrade(): number {
    let textValuationGrade = 0;
    if (this.valuations && this.valuations.length > 0) {
      for (let i = 0; i < this.valuations.length; i++) {
        const valuation = this.valuations[i];
        textValuationGrade += valuation.textValuation;
      }
      textValuationGrade /= this.valuations.length;
    }
    return textValuationGrade;
  }

  public getAnswersValuationGrade(): number {
    let answersValuationGrade = 0;
    if (this.valuations && this.valuations.length > 0) {
      for (let i = 0; i < this.valuations.length; i++) {
        const valuation = this.valuations[i];
        answersValuationGrade += valuation.answersValuation;
      }
      answersValuationGrade /= this.valuations.length;
    }
    return answersValuationGrade;
  }

  public getConceptsValuationGrade(): number {
    let conceptsValuationGrade = 0;
    if (this.valuations && this.valuations.length > 0) {
      for (let i = 0; i < this.valuations.length; i++) {
        const valuation = this.valuations[i];
        conceptsValuationGrade += valuation.conceptsValuation;
      }
      conceptsValuationGrade /= this.valuations.length;
    }
    return conceptsValuationGrade;
  }

  public getSubjectConcepts(): string[] {
    const concepts: any[] = this.moduleQuestion.concepts;
    return concepts.map(x => x.name);
  }

  public getAnswerConceptStatus(answer: ModuleQuestionAnswer, concept: string): string {
    const answerConcepts: any[] = answer.concepts;
    const concepts = answerConcepts.map(x => x.name);
    if (concepts.some(x => x === concept)) {
      return './assets/img/denied.png';
    } else {
      return './assets/img/approved.png';
    }
  }

  public getModuleQuestionPositive(): number {
    if (
      this.moduleQuestionValuations &&
      this.moduleQuestionValuations.length > 0
    ) {
      let positive = 0;
      for (let i = 0; i < this.moduleQuestionValuations.length; i++) {
        const moduleQuestionValuation = this.moduleQuestionValuations[i];
        let valuationGrade = moduleQuestionValuation.textValuation +
        moduleQuestionValuation.conceptsValuation +
        moduleQuestionValuation.answersValuation;
        valuationGrade = valuationGrade / 3;
        if (valuationGrade > 5) {
          positive++;
        }
      }
      return positive;
    } else {
      return 0;
    }
  }

  public getModuleQuestionNegative(): number {
    if (
      this.moduleQuestionValuations &&
      this.moduleQuestionValuations.length > 0
    ) {
      let negative = 0;
      for (let i = 0; i < this.moduleQuestionValuations.length; i++) {
        const moduleQuestionValuation = this.moduleQuestionValuations[i];
        let valuationGrade = moduleQuestionValuation.textValuation +
        moduleQuestionValuation.conceptsValuation +
        moduleQuestionValuation.answersValuation;
        valuationGrade = valuationGrade / 3;
        if (valuationGrade <= 5) {
          negative++;
        }
      }
      return negative;
    } else {
      return 0;
    }
  }

  public onTabSelected(tabEvent: MatTabChangeEvent) {
    this.conclusionStep = tabEvent.index;
  }

  public getPositiveModuleQuestionValuations(): ModuleQuestionValuation[] {
    const valuations = [];
    if (
      this.moduleQuestionValuations &&
      this.moduleQuestionValuations.length > 0
    ) {
      for (let i = 0; i < this.moduleQuestionValuations.length; i++) {
        const moduleQuestionValuation = this.moduleQuestionValuations[i];
        if (moduleQuestionValuation.conclusion) {
          valuations.push(moduleQuestionValuation);
        }
      }
      return valuations;
    } else {
      return valuations;
    }
  }

  public getNegativeModuleQuestionValuations(): ModuleQuestionValuation[] {
    const valuations = [];
    if (
      this.moduleQuestionValuations &&
      this.moduleQuestionValuations.length > 0
    ) {
      for (let i = 0; i < this.moduleQuestionValuations.length; i++) {
        const moduleQuestionValuation = this.moduleQuestionValuations[i];
        if (!moduleQuestionValuation.conclusion) {
          valuations.push(moduleQuestionValuation);
        }
      }
      return valuations;
    } else {
      return valuations;
    }
  }
  public getValuationName(valuation: number) {
    if (valuation >= 0 && valuation < 2) {
      return 'Péssimo';
    } else if (valuation >= 2 && valuation < 4) {
      return 'Ruim';
    } else if (valuation >= 4 && valuation < 6) {
      return 'Razoável';
    } else if (valuation >= 6 && valuation < 8) {
      return 'Bom';
    } else if (valuation >= 8 && valuation < 10) {
      return 'Muito bom';
    } else if (valuation >= 10) {
      return 'Excelente';
    }
  }
}
