<header *ngIf="data?.title">
  <h2>{{data?.title}}</h2>
</header>

<mat-dialog-content>
  <div class="message" *ngIf="!data?.template; else templateWrapper" [innerHtml]="data?.message"></div>
  <ng-template #templateWrapper>
    <ng-container [ngTemplateOutlet]="data?.template" [ngTemplateOutletContext]="{ $implicit: value  }"></ng-container>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions class="footer" *ngIf="data?.positiveTextAction || data?.negativeTextAction">
  <button *ngIf="data?.positiveTextAction" class="btn-test primary"
    (click)="dismiss(true)">{{data?.positiveTextAction}}</button>
  <button *ngIf="data?.negativeTextAction" class="btn-test btn-outline"
    (click)="dismiss(false)">{{data?.negativeTextAction}}</button>
</mat-dialog-actions>
