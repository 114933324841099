import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Locale } from '../models/Locale';
import { ValidationService } from './validation.service';



@Component({
  selector: 'control-messages',
  template: `<p *ngIf="errorMessage !== null" >{{errorMessage}}</p>`
})
export class CustomControlMessagesComponent {

  @Input() control: FormControl;
  @Input() locale: Locale = 'pt-BR';

  constructor() { }

  get errorMessage(): string {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched)
        return ValidationService.getValidatorErrorMessage(
          propertyName, this.control.errors[propertyName], this.locale);
    }
    return '';
  }
}
