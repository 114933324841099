import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Player, { Options } from '@vimeo/player';
import { Content, VideoReference } from 'src/app/models/content.model';
import { ContentTypeEnum } from 'src/app/models/enums/content-type.enum';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-video-tag-dialog',
  templateUrl: './video-tag-dialog.component.html',
  styleUrls: ['./video-tag-dialog.component.scss']
})
export class VideoTagDialogComponent extends NotificationClass implements OnInit {

  public conceptName: string = '';
  public conceptDescription: string = '';
  public videoPosition: string = '';

  public dialogPlayer: Player;
  public videoUrl: string = '';

  public selectedTab: number = 0;

  public moduleTags: any[] = [];
  public	moduleTagValuations: any[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _moduleService: ContentModulesService,
    private _utilService: UtilService,
    public dialogRef: MatDialogRef<VideoTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(_snackBar);
    this.videoUrl = data.content.value;
    this.moduleTags = data.moduleTags;
    this.moduleTagValuations = data.moduleTagValuations;
    if (data && data.time) {
      this.videoPosition = this._utilService.formatSecondsToMinutes(data.time);
    }
  }

  ngOnInit(): void {
    const options: Options = { url: this.videoUrl, height: 200, width: 300};
    setTimeout(() => {
      this.dialogPlayer = new Player('dialogVideoContent', options);
      this.dialogPlayer.ready();
    }, 10);
  }

  public onTabSelected($event: MatTabChangeEvent) {
    this.selectedTab = $event.index;
    setTimeout(() => {
      const options: Options = { url: this.videoUrl, height: 200, width: 300};
      this.dialogPlayer = new Player('dialogVideoContent', options);
      this.dialogPlayer.ready();
    }, 10);
  }

  public getOtherUserTags() {
    const contentTags = [];
    for (let i = 0; i < this.moduleTags.length; i++) {
      const moduleTag = this.moduleTags[i];
      if (moduleTag.userId !== this.data.userId) {
        contentTags.push(moduleTag);
      }
    }
    return contentTags;
  }

  public getConceptTag(position: number): string {
    switch (this.data.content.type) {
      case ContentTypeEnum.Video:
        return this._utilService.formatSecondsToMinutes(position);
      default:
        return '';
    }
  }

  public getValuationStatus(tag: any, like: boolean) {
    const valuationIndex = this.moduleTagValuations.findIndex(x => x.moduleUserTagId === tag.id);
    if (valuationIndex !== -1) {
      const valuation = this.moduleTagValuations[valuationIndex];
      return valuation.like === like;
    }
    return null;
  }

  public valuateTag(tag: any, like: boolean) {
    this._moduleService.valuateModuleUserTag(tag.id, like).subscribe((res) => {
      const valuationIndex = this.moduleTagValuations.findIndex(x => x.moduleUserTagId === tag.id);
      if (valuationIndex !== -1) {
        const valuation = this.moduleTagValuations[valuationIndex];
        valuation.like = like;
      } else {
        this.moduleTagValuations.push(res.data);
      }
    });
  }

  public save() {
    if (!this.conceptName || !this.videoPosition || !this.conceptDescription) {
      this.notify('Preencha todos os campos obrigatórios');
      return;
    }
    const contentId = this.data.content.id;
    const subjectId = this.data.subjectId;
    const moduleId = this.data.moduleId;
    const concept: VideoReference = {
      name: this.conceptName,
      positions: [this._utilService.getDurationFromFormattedHour(this.videoPosition)]
    };
    this._moduleService.addModuleUserTag(moduleId, subjectId, contentId,
    concept, this.conceptDescription).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
}
