import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ContentTracksService } from '../../_services/tracks.service';
import { TrackStudentOverview } from 'src/app/models/track-overview.interface';
import { UserService } from '../../_services/user.service';
import * as pdfform from 'pdfform.js/pdfform';
import { UtilService } from 'src/app/shared/services/util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { HttpClient } from '@angular/common/http';
import Player from '@vimeo/player';
import { VideoDialogComponent } from 'src/app/shared/components/video-dialog/video.dialog';
import { ValuationTestTypeEnum } from 'src/app/models/enums/valuation-test-type-enum';
import { EffortProgress } from 'src/app/pages/track/track-overview/track-effort-progress/models/trackEffort.model';
import { environment } from 'src/environments/environment';
import { isNil } from 'lodash';
import { IeffortMetric } from '../../cockpit/models/studentMetrics';
import { CockpitService } from '../../_services/cockpit.service';
import { ERole } from 'src/app/models/enums/role.enum';
import { filter } from 'rxjs/operators';
import { EFavoriteEnum } from 'src/app/models/enums/favorite.enum';

@Component({
  selector: 'app-track-overview',
  templateUrl: './track-overview.component.html',
  styleUrls: ['./track-overview.component.scss']
})
export class TrackOverviewComponent extends NotificationClass implements OnInit {

  // Features
  public hasEffortFeature = environment.features.effortPerformance;
  public hasCalenderFeature = environment.features.calender;
  public hasLevelsFeature = environment.features.levels;

  // Component Props
  @Input() showHeader: boolean = true;

  public track: TrackStudentOverview;
  public trackTests: any[] = [];
  public lockTrack: boolean = false;
  public hasValidConfigurate: boolean = true;
  public player;
  public coursePlayer;
  public userTrackInfo: any = {};
  public trackTestsResearch: any[] = [];
  public trackTestsFree: any[] = [];
  public trackTestsOrdered: any[] = [];
  public trackCourseWork: any[] = [];
  public dataset = new EffortProgress();
  public replacement = environment.replecement;
  public effortMetric: IeffortMetric = {} as IeffortMetric;
  public programId: string;
  public favoriteEnum = EFavoriteEnum;

  public hasFeatureCockpitMetrics = environment.features.cockpitMetrics;

  constructor(
    protected _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _tracksService: ContentTracksService,
    private _userService: UserService,
    private _authService: AuthService,
    private _utilService: UtilService,
    private _httpClient: HttpClient,
    private _dialog: MatDialog,
    private _router: Router,
    private _testService: SettingsValuationTestsService,
    public _cockpitService: CockpitService
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    const trackId = this._activatedRoute.snapshot.paramMap.get('trackId');
    this._activatedRoute.queryParams
      .pipe(filter((params) => params.programId))
      .subscribe((params) => (this.programId = params.programId));

    this._tracksService.getTrackCurrentStudentOverview(
      trackId, this.programId
    ).subscribe((response) => {
      this.track = response.data;
      this.hasValidConfigurate = !isNil(this.track.progressUntilDay);
      this.dataset = {
        progressUntilDay: this.track.progressUntilDay || 0,
        expectedProgressUntilDay: this.track.expectedProgressUntilDay || 0,
        averageStudentProgress: this.track.averageStudentProgress || 0,
      };

      this._loadTrackTests(this.track.id);

      this._setIntroductionVideo();
    });

    this._loadUserTrackInfo(trackId);
    this.loadStudentsMetrics(trackId);
    const isAdmin = this._authService.getLoggedUserRole() === ERole.Admin;
    this.hasFeatureCockpitMetrics = isAdmin ? isAdmin : this.hasFeatureCockpitMetrics;
  }

  public fillUserCareer() {
    this._router.navigate(['configuracoes/usuarios/carreira/' + this._authService.getLoggedUser().user_id]);
  }

  public getCalendarEvents(): Array<TrackEvent> {
    const trackEvents: Array<TrackEvent> = [];

    if (this.track.eventsConfiguration)
      // eslint-disable-next-line prefer-spread
      trackEvents.push.apply(trackEvents, this.track.eventsConfiguration);

    if (this.track.calendarEvents)
      // eslint-disable-next-line prefer-spread
      trackEvents.push.apply(trackEvents, this.track.calendarEvents);

    return trackEvents;
  }

  public generateCertificatePDF(): void {
    this._httpClient.get(
      this.track.certificateUrl, { responseType: 'arraybuffer' }
    ).subscribe(
      (response) => {
        const fields = {};
        fields['nome_trilha'] = [this.track.title];
        fields['nome_aluno'] = [this._authService.getLoggedUser().name];
        fields['data_conclusao'] = [this._utilService.formatDateToDDMMYYYY(new Date())];
        fields['data_conclusao_extenso'] = [this._utilService.formatDateToName(new Date())];
        const out_buf = pdfform().transform(response, fields);

        const blob = new Blob([out_buf], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }, () => {
        this.notify('Ocorreu um erro ao carregar o certificado');
      });
  }

  public getVideoDurationFormatted(): string {
    return this._utilService.formatDurationToHour(this.track.videoDuration);
  }

  private _setIntroductionVideo(): void {
    if (this.track.videoUrl && this.track.videoUrl !== '') {
      const options = {
        url: this.track.videoUrl
      };
      if (document.getElementById('videoContent')) {
        this.player = new Player('videoContent', options);
        this._handleVideoLoaded(this.player);
      }
    } else {
      const videoEl = document.getElementById('videoContent');
      if (videoEl)
        videoEl.innerHTML = '';
    }
  }

  private _handleVideoLoaded(player): void {
    player.on('loaded', () => {
      const frame = document.querySelector('iframe');
      if (frame) { frame.style.width = '100%'; }
      const divFrame = document.getElementById('videoContent');
      divFrame.style.visibility = 'initial';
    });
  }

  public watchMandatoryVideo(): void {
    const dialogRef = this._dialog.open(VideoDialogComponent, {
      data: { videoUrl: this.track.courseVideoUrl }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result)
        this._tracksService.markMandatoryVideoViewed(this.track.id).subscribe(() => {
          this.track.trackInfo.viewedMandatoryVideo = true;
        });
    });
  }

  private _loadTrackTests(trackId: string) {
    this._testService.getTrackValuationTests(trackId).subscribe(res => {
      if (res.data.some(track => track.type === ValuationTestTypeEnum.Percentile)) {
        this.trackTestsResearch = res.data.filter(track => track.type === ValuationTestTypeEnum.Percentile);
      }
      if (res.data.some(track => track.type === ValuationTestTypeEnum.Coursework)) {
        this.trackCourseWork = res.data.filter(track => track.type === ValuationTestTypeEnum.Coursework);
      }
      if (res.data.some(track => track.type === ValuationTestTypeEnum.Free)) {
        const trackTestsFree = res.data.filter(track => track.type === ValuationTestTypeEnum.Free);
        if (trackTestsFree.some(track => track.testTracks.some(y => y.order === -1))) {
          this.trackTestsFree = trackTestsFree.filter(track =>
            track.testTracks.some(y => y.order === -1)
          );
        }
        if (trackTestsFree.some(track => track.testTracks.some(y => y.order !== -1))) {
          this.trackTestsOrdered = trackTestsFree.filter(track =>
            track.testTracks.some(y => y.order !== -1)
          );
        }
        this._setEnableBtnTest(trackTestsFree);
      }
    }, err => {
      this.notify(this.getErrorNotification(err));
    });
  }

  private _setEnableBtnTest(trackTestsFree: any) {
    trackTestsFree.map((valuation) => {
      if (!valuation.alwaysAvailable && !isNil(valuation.openDate) && !isNil(valuation.valuationDate)) {
        const today = new Date();
        const openDate = new Date(valuation.openDate);
        const valuationDate = new Date(valuation.valuationDate);
        if (!(today <= valuationDate && today >= openDate))
          valuation.enableBtnTest = false;
        else
          valuation.enableBtnTest = true;
      } else {
        valuation.enableBtnTest = true;
      }
      return valuation;
    });
  }

  private _loadUserTrackInfo(trackId: string): void {
    this._userService.getBasicProgressInfo(trackId).subscribe((response) => {
      this.userTrackInfo = response.data;
      this.userTrackInfo.tracksInfo.forEach(element => {
        if (element.validFor && element.validFor > 0 && element.id === trackId) {
          if (new Date(element.dueDate).getTime() < new Date().getTime()) {
            localStorage.setItem('expiredTrack', 'Seu acesso à trilha expirou');
            this._router.navigate(['home']);
          }
        }
      });
    });
  }

  public loadStudentsMetrics(trackId: string) {
    this._tracksService.getOverviewTrackMetricCurrentStudent(trackId).subscribe((response) => {
      this.effortMetric = {
        studentProgressUntilDay: response.data.studentProgressUntilDay || 0,
        studentInteractionUntilDay: response.data.studentInteractionUntilDay || 0,
        studentInteractionUntilDayInHours: response.data.studentInteractionUntilDayInHours || 0,
        studentParticipationUntilDay: response.data.studentParticipationUntilDay || 0,
        classProgressUntilDay: response.data.classProgressUntilDay || 0,
        classInteractionUntilDay: response.data.classInteractionUntilDay || 0,
        classInteractionUntilDayInHours: response.data.classInteractionUntilDayInHours || 0,
        classParticipationUntilDay: response.data.classParticipationUntilDay || 0,
        expectedProgressUntilDay: response.data.expectedProgressUntilDay || 0,
        expectedInteractionUntilDay: response.data.expectedInteractionUntilDay || 0,
        expectedInteractionUntilDayInHours: response.dataexpectedInteractionUntilDayInHours || 0
      };

    });
  }
}
