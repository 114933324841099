import { Component, OnInit } from '@angular/core';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { TrackItem } from 'src/app/pages/instructor/components/module-tracks-table/models/module-track.model';
import { WrongItem } from 'src/app/shared/components/most-wrong-item/models/wrong-item.model';
import {
  ViewQuestionTextDialog
} from 'src/app/pages/instructor/components/view-question-text-dialog/models/view-question-text-dialog.model';
import {
  ViewQuestionTextDialogComponent
} from 'src/app/pages/instructor/components/view-question-text-dialog/view-question-text-dialog.component';
import { SettingsModulesService } from 'src/app/settings/_services/modules.service';

@Component({
  selector: 'app-module-track-detail',
  templateUrl: './module-track-detail.component.html',
  styleUrls: ['./module-track-detail.component.scss']
})
export class ModuleTrackDetailComponent extends NotificationClass implements OnInit {


  private _track: TrackItem;
  public get track(): TrackItem { return this._track; }
  public set track(track: TrackItem) {
    this._track = track;
    if (track) {
      this.wrongConcepts = this.track.wrongConcepts;
      this.wrongQuestions = this.track.wrongQuestions;
      this.correctQuestions = this.track.correctQuestions;
      this.wrongSubjects = this.track.wrongSubjects;
    }
  }

  public wrongConcepts: WrongItem[] = [];
  public wrongQuestions: WrongItem[] = [];
  public correctQuestions: WrongItem[] = [];
  public wrongSubjects: WrongItem[] = [];
  private _trackId: string;
  private _moduleId: string;


  constructor(
    protected _snackBar: MatSnackBar,
    private _moduleService: SettingsModulesService,
    private _tracksService: ContentTracksService,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
  ) {
    super(_snackBar);
  }
  ngOnInit() {

    this._trackId = this._activatedRoute.snapshot.paramMap.get('trackId');
    this._moduleId = this._activatedRoute.snapshot.paramMap.get('moduleId');

    this._tracksService.getInstructorTracksOverview(this._moduleId, this._trackId).subscribe(response => {
      this.track = response.data;
    }, e => {
      const snackbarRef = this.notify(this.getErrorNotification(e), 'Voltar', { duration: 9999999 });
      snackbarRef.onAction().subscribe(() => {
        history.back();
      });
    });

  }

  public replaceBase64(text: string): string {
    return text.replace(/!\[[+[a-zA-Z]+.*]+\(+.*\)/gm, '').trim();
  }

  public viewFullQuestion(item: WrongItem) {
    const users = this.track.studentProgress.map(student => student.id);
    this._moduleService
      .getQuestionOverview(
        this._moduleId,
        users,
        item.itemId
      ).subscribe(response => {
        const dialogRef = this._dialog.open(ViewQuestionTextDialogComponent, {
          maxWidth: '60vw',
          data: {
            answers: response.data,
            users: users,
            questionText: item.title
          } as ViewQuestionTextDialog
        });


      });
  }
}
