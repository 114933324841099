import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImportDialogComponent } from "./import-users-dialog.component";

@NgModule({
    declarations: [
        ImportDialogComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ImportDialogComponent
    ]
})
export class ImportUsersDialogModule { }