import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuationTestsGroupComponent } from './valuation-tests-group.component';
import { NewValuationTestGroupComponent } from './new-valuation-test-group/new-valuation-test-group.component';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { GenerateValuationTestCheckOptionsModule } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SettingsValuationTestsModule } from '../valuation-tests/valuation-tests.module';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { FormsModule } from '@angular/forms';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GenericCardModule } from 'src/app/shared/components/generic-card/generic-card.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';



@NgModule({
  declarations: [
    ValuationTestsGroupComponent,
    NewValuationTestGroupComponent
  ],
  imports: [
    CommonModule,
    PaginationModule,
    GenerateValuationTestCheckOptionsModule,
    PipesModule,
    SettingsValuationTestsModule,
    NavigationBackModule,
    FormsModule,
    MaterialComponentsModule,
    MatFormFieldModule,
    GenericCardModule,
    DirectivesModule
  ]
})
export class ValuationTestsGroupModule { }
