import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserCareer } from '../../user-models/user-career';
import { UserService } from 'src/app/pages/_services/user.service';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-user-career',
  templateUrl: './user-career.component.html',
  styleUrls: ['./user-career.component.scss']
})
export class SettingsUserCareerComponent extends NotificationClass implements OnInit {

  @Input() career: UserCareer;
  public userId: string;
  constructor(
    public _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userService: UserService) {
      super(_snackBar);
  }
  ngOnInit() {
    this.userId = this._activatedRoute.snapshot.paramMap.get('userId');
  }
  public editUserCareer() {
    this._router.navigate(['configuracoes/usuarios/carreira/' + this._activatedRoute.snapshot.paramMap.get('userId')]);
  }

 public getUserCarrerPdf(): void {
    this._userService.getUserCareerPdf(this.userId).subscribe(() => {}, () => {
      this.notify('Ocorreu um erro ao emitir o certificado');
    });
 }
}
