import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({ name: 'replacement' })
export class ReplecementPipe implements PipeTransform {
    transform(value: string, prefix: string, sufix: string): string {

        if (isNil(value) || value === '') {
            return '';
        }
        let text = value;

        if (!isNil(prefix) && prefix !== '')
            text = `${prefix} ${text}`;

        if (!isNil(sufix) && sufix !== '')
            text = `${text} ${sufix}`;

        return text;
    }
}
