<div class="inner-content" >
  <div class="header" >
    <h2>Associação Automatica</h2>
  </div>

  <!--p>
    Esta seção gerencia a criação de provas, avaliações de reação acadêmica
    e pesquisas diversas associadas a cursos, eventos ou trilhas.
    Elas aparecem para os alunos no corpo do curso, evento ou trilha como mais uma tarefa.
  </p-->

  <div class="center-btn" >
    <button class="btn-test" (click)="runSync()" >
      Rodar processo de associação
    </button>
    <button class="btn-test" (click)="createNewAutomaticSync()" >
      Nova associação
    </button>
  </div>

  <div class="display-content">
    <div class="tests" >
      <app-automatic-sync-card
        *ngFor="let automaticSync of automaticSyncs"
        (editAutomaticSync)="manageAutomaticSync($event)"
        (deleteAutomaticSync)="deleteAutomaticSync($event)"
        [automaticSync]="automaticSync"
      ></app-automatic-sync-card>
    </div>
    <app-pagination class="pagination" *ngIf="automaticSyncs && automaticSyncs.length > 0"
      [setPagination]=" {
        'itemsCount':_itemsCount,
        'pageSize': 10
      }"
      (goToPage)="goToPage($event)"
    ></app-pagination>
  </div>
  <p class="emptyState"*ngIf="automaticSyncs && automaticSyncs.length === 0" >
    Ainda não foi criada nenhuma regra de associação
  </p>
</div>
