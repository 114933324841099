import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { TestTrack } from 'src/app/models/valuation-test.interface';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';

@Component({
  selector: 'app-filters-valuation-tests',
  templateUrl: './filters-valuation-tests.component.html',
  styleUrls: ['./filters-valuation-tests.component.scss']
})
export class FiltersValuationTestsComponent {

  @Output() setValuationTestTermFilter: EventEmitter<string> = new EventEmitter();
  @Output() setCreatedSince: EventEmitter<Date> = new EventEmitter();
  @Output() setModules: EventEmitter<any[]> = new EventEmitter();
  @Output() setTracks: EventEmitter<any[]> = new EventEmitter();

  @Input() showCreatedSince: boolean = true;
  @Input() showModules: boolean = true;
  @Input() showTracks: boolean = true;

  public createdSince: Date;
  public tracks: Array<TestTrack> = [];
  public selectedTracks: Array<TestTrack> = [];
  public modules: Array<any> = [];
  public selectedModules: Array<any> = [];

  constructor(
    private _tracksService: SettingsTracksService,
    private _modulesService: ContentModulesService,
  ) { }



  public triggerUserSearch(searchValue: string) {
    this.setValuationTestTermFilter.emit(searchValue);
  }

  public maxDateFilter(currentDate: Date): boolean {
    const today = new Date();
    return currentDate <= today;
  }

  public triggerTrackSearch(searchValue: string) {
    this._loadTracks(searchValue);
  }

  private _loadTracks(searchValue: string = ''): void {
    if (searchValue === '') {
      this.tracks = [];
      return;
    }

    this._tracksService.getPagedFilteredTracksList(
      1, 20, searchValue
    ).subscribe(response => {
      response.data.tracks.forEach((tck: TrackPreview) => {
        tck['checked'] = false;
      });
      this.tracks = response.data.tracks;
    });
  }

  public triggerModuleSearch(searchValue: string) {
    this._loadModules(searchValue);
  }
  private _loadModules(searchValue: string = '') {
    if (searchValue === '') {
      this.modules = [];
      return;
    }

    this._modulesService.getPagedFilteredModulesList(
      1, 20, searchValue, null, true
    ).subscribe(response => {
      this.modules = response.data.modules;
      response.data.modules.forEach((module) => {
        module['checked'] = false;
      });
    });
  }

  public changeFilterModules() {
    const modulesSelected = this.modules
      .filter(module => module.checked)
      .map(module => module.id);

    this.setModules.emit(modulesSelected);
  }

  public changeFilterTracks() {
    const tracksSelected = this.tracks
      .filter(track => track.checked)
      .map(track => track.id);

    this.setTracks.emit(tracksSelected);
  }
}
