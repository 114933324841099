import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormArray } from '@angular/forms';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-professional-experience',
  templateUrl: './professional-experience.component.html',
  styleUrls: ['../manage-user-career.component.scss']
})
export class ProfessionalExperienceComponent extends NotificationClass {

  @Input() formGroup: FormGroup;
  @Output() addExperience = new EventEmitter();
  @Output() setValidator = new EventEmitter();

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  public selectProfessionalExperience(value: boolean) {
    this.formGroup.get('professionalExperience').setValue(value);
    value ? this.addExperience.emit() : this.clearFormArray(this.formGroup.get('professionalExperiences') as FormArray) ;
  }

  public clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  public removeForm(key: string, index: number) {
    const formArray = this.formGroup.get(key) as FormArray;
    formArray.removeAt(index);
  }

  public validField(): void {
    const career = this.formGroup.getRawValue();
    let checkProfExpField = true;
    if (career.professionalExperiences) {
      career.professionalExperiences.forEach(x => {
        if ((x.title.length === 0) || (x.description.length === 0 ) ||
          (x.startDate === null)) {
              this.notify('Preencha em Experiência Profissional todos os campos obrigatórios (*)');
              checkProfExpField = false;
        }

        if (x.endDate !== null) {
          const start = new Date(x.startDate);
          const end = new Date(x.endDate);

          if (start > end) {
            this.notify('A data de ínicio não pode ser menor que a data de saída');
            checkProfExpField = false;
          }
        }
      });
    }
      this.setValidator.emit({key: 'professionalExperience', value: checkProfExpField });
  }
}
