import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseUrlService {
    private Urls: Map<string, string> = new Map<string, string>();

    constructor() {}

    protected addUrls(baseUrl: string, urls: Map<string, string>) {
        urls.forEach((val, key) => {
            this.Urls.set(key, `${baseUrl}/${val}`);
        });
    }

    public getUrl(key: string, ...urlPart: string[]): string {
        let baseUrl = this.Urls.get(key);

        if (urlPart) {
            urlPart.forEach((str, ix) => {
                // baseUrl = baseUrl.replace('{' + ix + '}', str);
                baseUrl = baseUrl + '/' + str;
            });
        }

        return baseUrl;
    }
}
