import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EventPreview } from '../../models/previews/event.interface';
import { NotificationClass } from '../../shared/classes/notification';
import { SettingsEventsService } from '../_services/events.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SettingsEventsDraftsService } from '../_services/events-drafts.service';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-settings-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class SettingsEventsComponent extends NotificationClass implements OnInit {

  public events: Array<EventPreview> = [];
  public eventsCount: number = 0;
  private _eventsPage: number = 1;
  private _searchSubject: Subject<string> = new Subject();
  private _isDraft = this._router.url.includes('rascunho');

  constructor(
    protected _snackBar: MatSnackBar,
    private _eventsService: SettingsEventsService,
    private _draftsService: SettingsEventsDraftsService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadEvents(this._eventsPage);
    this._setSearchSubscription();
  }

  public goToPage(page: number) {
    if (page !== this._eventsPage) {
      this._eventsPage = page;
      this._loadEvents(this._eventsPage);
    }
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  public createNewEvent(): void {
    this._router.navigate(['/configuracoes/evento']);
  }

  public editEvent(event: EventPreview) {
    this._router.navigate([this._getEditEventUrl(event)]);
  }

  public viewEvent(event: EventPreview) {
    this._router.navigate([this._getViewEventUrl(event)], {queryParams: {isDraft: event.isDraft}});
  }

  public deleteEvent(event: EventPreview) {

    const deleteEventDraft = id => this._draftsService
      .rejectEventDraft(id)
      .subscribe(() => {
        this._loadEvents(this._eventsPage).add(() => this.notify('Rascunho excluído com sucesso'));
      });

    const deleteOriginalEvent = id => this._eventsService
      .deleteEventById(id)
      .subscribe(() => {
        this.notify('Evento excluído com sucesso');
        const index = this.events.findIndex(x => x.id === event.id);
        this.events.splice(index, 1);
      });

    const deleteMessage = event.isDraft ?
      `Tem certeza que deseja excluir o <b>rascunho</b> desse evento?`
      : `Tem certeza que deseja excluir este evento?`;

    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      width: '510px',
      data: {
        message: deleteMessage,
        negativeTextAction: 'CANCELAR',
        positiveTextAction: 'OK',
      } as SimpleDialog
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

        const id = event.isDraft ? event.eventId : event.id;
        event.isDraft ? deleteEventDraft(id) : deleteOriginalEvent(id);
      }
    });
  }

  private _loadEvents(page: number, searchValue: string = ''): Subscription {
    return this._draftsService.getPagedEventsAndDrafts(
      page, 20, searchValue
    ).subscribe((response) => {
      this.events = response.data.events;
      this.eventsCount = response.data.itemsCount;

    });
  }

  private _getEditEventUrl(event: EventPreview) {
    const prefix = 'configuracoes/evento';
    return event.isDraft ?
      `${prefix}/${event.id}/rascunho` : `${prefix}/${event.id}`;
  }

  private _getViewEventUrl(event: EventPreview) {
    const prefix = 'configuracoes/gerenciar-eventos/';
    return `${prefix}/${event.isDraft ? event.eventId : event.id}`;
  }

  private _setSearchSubscription(): void {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this._loadEvents(this._eventsPage, searchValue);
    });
  }
}
