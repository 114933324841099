import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialComponentsModule } from '../shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUtilModule } from '@tg4/ng-util';
import { NewModuleModule } from 'src/app/settings/modules/new-module/new-module.module';
import { RouterModule } from '@angular/router';
import { SettingsEventsModule } from './events/events.module';
import { SettingsModulesModule } from './modules/modules.module';
import { SettingsTracksModule } from './tracks/tracks.module';
import { SettingsUsersModule } from './users/users.module';
import { SettingsUsersSyncModule } from './users-sync/users-sync.module';
import { SettingsEventsApplicationsModule } from './events-applications/events-applications.module';
import { SettingsManageTeamModule } from './manage-team/manage-team.module';
import { SettingsEventsApplicationsGradesModule } from './events-applications-grades/events-applications-grades.module';
import { SettingsEventsValuationModule } from './events-valuation/events-valuation.module';
import { SettingsCustomEmailSentModule } from './custom-email-sent/custom-email-sent.module';
import { SettingsProfileTestsModule } from './profile-tests/profile-tests.module';
import { SettingsProductSuggestionModule } from './product-suggestion/product-suggestion.module';
import { SettingsFormulasModule } from './formulas/formulas.module';
import { LogsModule } from './logs/logs.module';
import { ColorEditModule } from './color-edit/color-edit.module';
import { CertificateEditModule } from './certificate-edit/certificate-edit.module';
import { SettingsValuationTestsModule } from './valuation-tests/valuation-tests.module';
import { ValuationTestModule } from './valuation-test/valuation-test.module';
import { SettingsNpsModule } from './nps/nps.module';
import { EffortPerformanceModule } from '../pages/effort-performance/effort-performance.module';
import { SettingsManageUserRegisterModule } from './manage-user-register/manage-user-register.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SettingsManageUserIncludeModule } from './manage-user-include/manage-user-include.module';
import { ComunicationModule } from './comunication/comunication.module';
import { AutomaticSyncModule } from './automatic-sync/automatic-sync.module';
import { StudyPlanModule } from './study-plan/study-plan.module';
import { SectorModule } from './sector/sector.module';
import { SegmentModule } from './segment/segment.module';
import { TeamsModule } from './teams/teams.module';
import { ProgramsModule } from './programs/programs.module';
import { UserSubjectBlockedModule } from './user-subject-blocked/user-subject-blocked.module';
import { ValuationTestsGroupModule } from './valuation-tests-group/valuation-tests-group.module';
import { ImportUsersDialogModule } from '../shared/components/import-users-dialog/import-users-dialog.module';

@NgModule({
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgUtilModule,
    NewModuleModule,
    RouterModule,
    SettingsEventsModule,
    SettingsModulesModule,
    SettingsTracksModule,
    SettingsUsersModule,
    SettingsUsersSyncModule,
    SettingsEventsApplicationsModule,
    SettingsEventsApplicationsGradesModule,
    SettingsEventsValuationModule,
    SettingsManageTeamModule,
    SettingsCustomEmailSentModule,
    SettingsProfileTestsModule,
    SettingsProductSuggestionModule,
    SettingsFormulasModule,
    LogsModule,
    ColorEditModule,
    CertificateEditModule,
    SettingsValuationTestsModule,
    ValuationTestModule,
    SettingsNpsModule,
    EffortPerformanceModule,
    SettingsManageUserRegisterModule,
    SettingsManageUserIncludeModule,
    PipesModule,
    ComunicationModule,
    UserSubjectBlockedModule,
    AutomaticSyncModule,
    StudyPlanModule,
    SectorModule,
    SegmentModule,
    TeamsModule,
    ProgramsModule,
    UserSubjectBlockedModule,
    ValuationTestsGroupModule,
    ImportUsersDialogModule,
    SectorModule,
    SegmentModule
  ],
})
export class SettingsModule { }
