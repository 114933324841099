import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exam-question-discursive',
  templateUrl: './exam-question-discursive.component.html',
  styleUrls: ['./exam-question-discursive.component.scss']
})
export class ExamQuestionDiscursiveComponent {

  @Input() readonly question: any;
  @Input() readonly title?: string;
  @Input() readonly showButton?: boolean = false;
  @Input() readonly showComment: boolean = false;
  @Input() readonly isMock: boolean = false;
  @Input() readonly last: boolean = false;

  @Output() confirmAnswer = new EventEmitter();
  @Output() goToNextQuestion = new EventEmitter();
  @Output() finished = new EventEmitter();

  public formGroup: FormGroup;
  public answered: boolean = false;
  public oldAnswer: string = '';
  public get getValueAnswer(): any { return this.formGroup.get('answer').value; }
  public hasComment: boolean = environment.features.questionComment;
  constructor(private _dialog: MatDialog) {
    this.formGroup = new FormGroup({
      'answer': new FormControl(''),
    });
  }



  public confirm(): void {
    if (this.canFinish()) {
      this.finish();
    } else if (this.canGoToNextQuestion()) {
      this.goToNextQuestion.emit();
      this.hasAnswered(this.formGroup.get('answer').value);
    } else {
      this.hasAnswered(this.formGroup.get('answer').value);
      this.oldAnswer = this.formGroup.get('answer').value;
      this.confirmAnswer.emit(this.formGroup.get('answer').value);
    }
  }

  public finish(): void {
    this.finished.emit();
  }

  public canFinish() {
    return this.answered && this.last && !this.hasChange();
  }

  public canGoToNextQuestion() {
    return this.answered && !this.hasChange();
  }

  public hasChange(): boolean {
    return this.oldAnswer !== this.formGroup.get('answer').value;
  }

  // Esse método é ativado unica e exclusivamente pela classe pai.
  public setAnswer(value: string): void {
    this.oldAnswer = value;
    this.formGroup.get('answer').setValue(value);
    this.hasAnswered(value);
  }

  public resetAnswer(): void {
    this.formGroup.get('answer').setValue('');
    this.hasAnswered(this.formGroup.get('answer').value);
  }

  public hasAnswered(value): void {
    if (!this.isMock) {
      this.answered = value !== '';
    }
  }

  public seeComment() {
    this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      data: {
        title: 'Comentário',
        message: this.question.comment,
        positiveTextAction: 'Ok'
      } as SimpleDialog
    });
  }
}
