<div class="inner-content">
    <div class="card">
        <div>
            <p class="title-color">ATIVIDADE DE CONCLUSÃO DE CURSO</p>
            <h2>Criar/Validar Questão</h2>
            <h3>{{subject.title}}</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna 
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                Duis aute irure dolor in reprehenderit in voluptate velit esse 
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                cupidatat non proident, sunt in culpa qui officia deserunt mollit 
                anim id est laborum.
            </p>
            <div class="btn-div">
                <button class="btn-test" (click)="next.emit(true)" >
                    Começar Atividade
                </button>
                <button class="btn-test btn-white" (click)="goBack.emit()" >
                    Deixar para Depois
                </button>
            </div>
        </div>
        <div>
            <iframe
            [src]="'https://www.youtube.com/embed/DcJFdCmN98s'| safeUrl"
            height="100%"
            width="100%"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            >
            </iframe>
        </div>
    </div>
</div>