import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Data } from 'src/app/shared/components/generic-card/data.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { CommunicationService } from '../../_services/communications.service';
import { CommunicationDialogComponent } from '../communication-dialog/communication-dialog.component';
import { Communication } from '../Models/communication.model';

@Component({
  selector: 'app-comunications',
  templateUrl: './comunications.component.html',
  styleUrls: ['./comunications.component.scss']
})
export class ComunicationsComponent implements OnInit {

  public communications: Communication[] = [];
  public communicationCount: number = 0;
  private _communicationPage: number = 1;
  private _searchSubject: Subject<string> = new Subject();
  public readonly pageSize = 20;

  constructor(
    private _dialog: MatDialog,
    private _communicationService: CommunicationService) { }

  ngOnInit() {
    this.loadCommunications(this._communicationPage);
    this._setSearchSubscription();
  }

  public openDialogComunication(data: Data = null) {
    const dialogRef = this._dialog.open(CommunicationDialogComponent, {
      width: '600px',
      data: {
        ...(data !== null && { id: data.id })
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.communications.push(result);
      }
    });
  }

  public loadCommunications(page: number, searchTerm: string = null) {
    this._communicationService.getPagedCommunications(page, this.pageSize, searchTerm).subscribe(pagedCommunications => {
      if(pagedCommunications.data){
      this.communications = pagedCommunications.data.communications;
      this.communicationCount = pagedCommunications.data.itemsCount;}
    });
  }

  public DeleteCommunications(data: Data) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja remover este comunicado?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._communicationService.deleteCommunications(data.id).subscribe(() => {
          this.communications = this.communications.filter(communication => communication.id !== data.id);
        });
      }
    });
  }

  public goToPage(page: number) {
    if (page !== this._communicationPage) {
      this._communicationPage = page;
      this.loadCommunications(this._communicationPage);
    }
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  private _setSearchSubscription(): void {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.loadCommunications(this._communicationPage, searchValue);
    });
  }
}
