import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { TrackArea } from 'src/app/models/track.model';
import { SettingsTracksService } from '../../_services/tracks.service';
import { NotificationClass } from '../../../shared/classes/notification';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AbstractControl } from '@angular/forms';
import { UploadResource } from 'src/app/models/shared/upload-resource.interface';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';

@Component({
    selector: 'app-settings-track-areas',
    templateUrl: './track-areas.component.html',
    styleUrls: ['./track-areas.component.scss']
})
export class SettingsTrackAreasComponent extends NotificationClass implements OnInit {

    public readonly pageSize: number = 10;
    public readonly displayedColumns: string[] = [
        'title', 'actions'
    ];
    public trackAreas: Array<TrackArea> = [];
    public trackAreasCount: number = 0;
    private _trackAreasPage: number = 1;

    public trackAreaTitle: string = '';

    constructor(
        protected _snackBar: MatSnackBar,
        private _tracksService: SettingsTracksService,
        private _router: Router,
        private _sharedService: SharedService,
        private _dialog: MatDialog
    ) {
        super(_snackBar);
    }

    ngOnInit() {
        this._loadTrackAreas();
    }

    public add() {
        this._tracksService.addTrackArea(this.trackAreaTitle).subscribe((response) => {
            this.trackAreaTitle = "";
            this.trackAreasCount++;
            this._loadTrackAreas();
        });
    }

    private _loadTrackAreas(): void {
        this._tracksService.getPagedFilteredTrackAreas(this._trackAreasPage, this.pageSize).subscribe((response) => {
            this.trackAreas = response.data.trackAreas;
            this.trackAreasCount = response.data.itemsCount;
        });
    }

    public removeConfirm(trackAreaId: string) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            hasBackdrop: true,
            closeOnNavigation: false,
            disableClose: true,
            width: '400px',
            data: { message: 'Deseja realmente excluir?' }
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this._tracksService.deleteTrackAreaById(trackAreaId).subscribe((response) => {
                    this._loadTrackAreas();
                });
            }
        });
    }

    public goToPage(page: number) {
        this._trackAreasPage = page;
        this._loadTrackAreas();
    }

}
