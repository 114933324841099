<ng-container *ngIf="route !== 'generate'; then standardlayout; else certificatelayout"> </ng-container>

<ng-template #standardlayout>
  <app-toolbar-header (OpenMenu)="toogleSideNav()"></app-toolbar-header>

  <mat-sidenav-container class="side-nav-container" [hasBackdrop]="true">
    <mat-sidenav [(opened)]="opened" mode="side">
      <div id="SideMenuContainer">
        <app-menu (closeMenu)="toogleSideNav()"></app-menu>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div id="Content">
        <div [@animRoutes]="getPage(appOutlet)" style="position: relative; height: -webkit-fill-available;">
          <router-outlet #appOutlet="outlet"></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

<ng-template #certificatelayout>
  <router-outlet #appOutlet="outlet"></router-outlet>
</ng-template>
