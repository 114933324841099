import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpStatusCode,
    HttpEvent
} from '@angular/common/http';
import { Injector, Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ErrorsService } from 'src/app/shared/services/error.service';
import { BaseUrlService } from '../services/url-service';
import { AuthService } from '../services/auth-service';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { tap, catchError, switchMap, filter, take, finalize, concatMap } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/shared/services/token-storage.service';
import { isEmpty, isNil } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendResponse } from '../services';

@Injectable()
export class AuthInterceptor extends ErrorsService implements HttpInterceptor {
    urlService: BaseUrlService;
    authService: AuthService;
    tokenService: TokenStorageService;
    refreshTokenHasFailed: boolean;
    refreshTokenInProgress = false;
    private httpErrorSubject: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject<HttpErrorResponse>(null);
    STATUS_CODE_INVALID_SESSION = 333;
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(private injector: Injector, private _sharedService: SharedService, _snackBar: MatSnackBar,) {
        super(_snackBar, injector);
        this.authService = this.injector.get(AuthService);
        this.urlService = this.injector.get(BaseUrlService);
        this.tokenService = this.injector.get(TokenStorageService);
    }

    addAuthHeader(request: HttpRequest<any>) {
        if (!this.authService.isAuthenticated())
            return request;

        const authHeader = this.authService.getAuthorizationHeader();
        if (authHeader) {
            return request.clone({
                setHeaders: {
                    Authorization: authHeader
                }
            });
        }
        return request;
    }

    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.authService.refreshToken().pipe(
                tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next();
                })
            );
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let authReq = request;
        request = this.addAuthHeader(request);
        const token = this.tokenService.getToken();
        const isAuthenticated = !!token;
        const hasToken = !isNil(token) && !isEmpty(token);


        if (hasToken) {
            authReq = this.addTokenHeader(request, token);
        }

        return next.handle(request)
            .pipe(
                tap(() => {
                    if (isAuthenticated)
                        this.tokenService.renewTokenTimeExpiration();

                }),
                catchError((response: HttpErrorResponse) => {
                    const NotImpersonatedUser = isEmpty(localStorage.getItem('ImpersonatedUser'));
                    if (!NotImpersonatedUser){
                        return throwError(response);
                    }
                    const isUnauthorized = response.status === HttpStatusCode.Unauthorized;
                    const hasInvalidRefreshToken = authReq.url.toLocaleLowerCase().includes('refreshToken'.toLocaleLowerCase()) &&
                        !(response.error as BackendResponse<any>).success;
                    if (hasInvalidRefreshToken) {
                        this.isRefreshing = false;
                        return this.logout();                       
                    }

                    if (token && response instanceof HttpErrorResponse && !authReq.url.includes('login') && isUnauthorized) {
                        return this.handle401Error(authReq, next);
                    }

                    if (isUnauthorized || response.status === this.STATUS_CODE_INVALID_SESSION) {
                        return this.logout();               
                    }

                    return throwError(response);
                })

            );
    }
    private addTokenHeader(request: HttpRequest<any>, token: string) {
        token = token.replace('Bearer ', '');
        return request.clone({ headers: request.headers.set("Authorization", `Bearer ${token}`) });
    }
    private _parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const token = this.tokenService.getRefreshToken();

            if (!token) {
                this.isRefreshing = false;
                return this.logout();
            }


            return this.authService.refreshsToken(token).pipe(

                switchMap(response => {
                    this.isRefreshing = false;
                    const authData = response.data;
                    authData.access_token = this.addBearer(authData.access_token);
                    const token = authData.access_token;

                    this.tokenService.saveAllTokens(authData);
                    this.refreshTokenSubject.next(token);

                    return next.handle(this.addTokenHeader(request, token));
                })
            );


        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }
    private _tokenHasExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
    private logout() {
        const isLoggedIn = localStorage.getItem('auth_data') !== null;
        if(isLoggedIn){
        this.notify('Sua sessão expirou, autentique-se novamente', 'OK', { duration: 4000, });
        this._redirectToLoginPage(this._router.routerState.snapshot.url);
        }
        return EMPTY;
        
    }
    private _redirectToLoginPage = (returnUrl?: string): Promise<boolean> => {
        this._sharedService.setLoaderValue(false);
        localStorage.clear();
        return this._router.navigate([''], !returnUrl ? {} : { queryParams: { returnUrl } });
    };
    private addBearer(acess_token) {
        return 'Bearer ' + acess_token;
    }
}
