import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';

import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { ContentTracksService } from '../_services/tracks.service';
import { ContentModulesService } from '../../pages/_services/modules.service';
import { UtilService } from '../../shared/services/util.service';

import { User } from 'src/app/models/user.model';
import { Certificate } from 'src/app/models/certificate';
import { environment } from 'src/environments/environment';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})

export class CertificateComponent extends NotificationClass implements OnInit {

  public replacement = environment.replecement;

  public id: string;
  public userId: string;
  public where: string;
  public date: string;
  public resposibleId: string;

  public user: User;
  public progress: any;
  public data: any;
  public certificate: Certificate;
  public defaultLogoUrl: string = `./../../../assets/img/logo-${environment.logo}`;

  constructor(
    private route: ActivatedRoute,
    private _usersService: SettingsUsersService,
    private _modulesService: ContentModulesService,
    private _tracksService: ContentTracksService,
    private _utilService: UtilService,
    protected _snackBar: MatSnackBar,
    private _userServicePage: UserService,) {
    super(_snackBar);
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userId = this.route.snapshot.params['userId'];
    this.where = this.route.snapshot.params['where'];
    this.resposibleId = this.route.snapshot.params['resposibleId'] || '';

    this.loadData(this.where, this.userId, this.id);
    this._loadSettingsCertificate();
  }

  public loadData(where: string, userId: string, id: string): void {
    this._loadUser(userId);
    switch (where) {
      case 'track':
        this._loadTrack(id);
        this._loadTrackProgress(id);
        break;
      case 'module':
        this._loadModule(id);
        this._loadModuleProgress(id);
        break;
      default:
        break;
    }
    this.date = this._getDate();
  }

  private _loadUser(userId: string): void {
    this._usersService.getUserById(
      userId
    ).subscribe((response) => {
      this.user = response.data;

    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }


  private _loadTrack(id: string): void {
    this._tracksService.getTrackById(
      id, true
    ).subscribe((response) => {
      this.data = response.data;
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  private _loadModuleProgress(id: string): void {
    this._userServicePage.getUserModuleProgress(
      id
    ).subscribe((response) => {
      this.progress = response.data;
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  private _loadTrackProgress(id: string): void {
    this._userServicePage.getUserTrackProgress(
      id
    ).subscribe((response) => {
      this.progress = response.data;
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  private _loadModule(id: string): void {
    this._modulesService.getModuleById(id).subscribe((response) => {
      this.data = response.data;
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  private _loadSettingsCertificate(): void {
    this._usersService.getUserCertificate(this.resposibleId).subscribe((response) => {
      this.certificate = response.data;
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  private _getDate(): string {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return day + ' de ' + months[month] + ' de ' + year;
  }

  public getFormattedHour(): string {
    if (!this.data || !this.data.duration) return '';
    return this._utilService.SecondToHours(this.data.duration).toString();
  }

  public getBorderColor(borderColor: string): string {
    if (borderColor !== '' ||
      borderColor !== null) {
      return borderColor;
    } else {
      return '#6D9EEB';
    }
  }
  getBase64ImageFromURL(url: string): any {
    return new Observable((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }
  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}


