<h3>{{ data.testTitle }}</h3>

<div class="content" >
  <div id="htmlEditor" ></div>
</div>

<div class="text-inputs" >
  <mat-form-field>
    <input matInput type="number"
      min="1" max="100"
      placeholder="Peso da Questão (1 a 100)"
      [(ngModel)]="data.percentage" required
    />
  </mat-form-field>
  
  <mat-form-field>
    <mat-select placeholder="Tipo de Pergunta" [(ngModel)]="data.type" required >
      <mat-option [value]="1" >Múltipla-Escolha</mat-option>
      <mat-option [value]="2" >Discursiva</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="answers" *ngIf="data.type && data.type === 1" >
  <table>
    <thead>
      <tr>
        <th>Resposta</th>
        <th></th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let option of data.options; let index = index" >
        <td>
          <textarea matInput placeholder="Resposta"
            [(ngModel)]="option.text" required ></textarea>
        </td>
        <td>
          <img [src]="getAnswerIconSrc(option)"
            (click)="toggleAnswer(option)"
          />
        </td>
        <td>
          <img
            src="./assets/img/trash.png"
            (click)="removeAnswer(index)"
          />
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <button class="btn-test primary" (click)="addAnswer()" >
            + Adicionar Resposta
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<div class="actions" >
  <button class="btn-outline" (click)="dismiss()" >
    Cancelar
  </button>
  <button class="btn-test" (click)="save()" >
    Salvar
  </button>
</div>
