import { NgModule } from '@angular/core';

import { UsersDialogComponent } from './users-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    NgxDatatableModule
  ],
  exports: [],
  declarations: [UsersDialogComponent],
  providers: [],
})
export class UsersDialogModule { }
