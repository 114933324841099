import { EStudyPlanItem } from './enums/study-plan-item.enum';

export class StudyPlanItem {
    public id: string;
    public name?: string;
    public type: EStudyPlanItem;
    public order: number;

    constructor(studyPlanItem: StudyPlanItem = null) {
        if (studyPlanItem) Object.keys(studyPlanItem).forEach(key => this[key] = studyPlanItem[key]);
    }
}

