<div class="inner-content">
  <header>
    <h1>PROGRAMAS</h1>
    <div class="actions">
      <a class="btn-outline" href="./../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_PROGRAMAS.xlsx"
        download="PLANILHA_MODELO_IMPORTAÇÃO_PROGRAMAS.xlsx">Baixar template</a>
      <button class="btn-test primary-color" (click)="importProgram()">Importar programas</button>
      <button class="btn-test width-larger" (click)="goToProgram()">+Adicionar programa</button>
    </div>
  </header>
  <div class="main">
    <div class="table">
      <div class="action-wrapper">
        <div class="search">
          <app-list-search placeholder="Pesquise" [noPadding]="true" [showRemoveButton]="true"
            (removedItens)="handleResetFilter()" (triggerSearch)="triggerSearch($event)">
          </app-list-search>
        </div>
      </div>
      <table mat-table matSort [dataSource]="programs">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-header">Nome do Programa</div>
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.title | uppercase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th width="180px" mat-header-cell *matHeaderCellDef>
            <div class="table-header">Período</div>
          </th>
          <td class="padding" mat-cell *matCellDef="let row">
            <span class="date start-date"> {{row.startDate | date: 'dd/MM/yyyy'}} </span>
            <span class="divider">-</span>
            <span class="date end-date"> {{row.endDate | date: 'dd/MM/yyyy'}} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th width="100px" mat-header-cell *matHeaderCellDef>
            <div class="table-header">Data Criação</div>
          </th>
          <td class="padding" mat-cell *matCellDef="let row">
            <span class="date createdAt">{{row.createdAt | date: 'dd/MM/yyyy'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="countTeams">
          <th width="30px" mat-header-cell *matHeaderCellDef>
            <div class="table-header">Turmas</div>
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.countTeams}}
          </td>
        </ng-container>

        <ng-container matColumnDef="countTeamStudents">
          <th width="30px" mat-header-cell *matHeaderCellDef>
            <div class="table-header">Nº de Alunos</div>
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.countTeamStudents}}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th width="85px" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="icon-wrapper">
              <img class="icon" src="./assets/img/edit.png" (click)="viewProgramDetails(row)" title="Editar Programa">
              <img class="icon" src="./assets/img/trash.png" (click)="deleteProgram(row)" title="Excluir Programa">
            </div>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="footer-wrapper">
        <app-pagination *ngIf="programsCount" [setPagination]="{'itemsCount': programsCount, 'pageSize': pageSize  }"
          (goToPage)="goToPage($event)">
        </app-pagination>
      </div>


    </div>
  </div>
