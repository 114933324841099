import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { isEmpty, isNil, isNull } from 'lodash';
import { UserInclude } from 'src/app/models/previews/user.interface';
import { TestModule, TestTrack } from 'src/app/models/valuation-test.interface';
import { NotificationClass } from '../../shared/classes/notification';
import { SettingsModulesService } from '../_services/modules.service';
import { SettingsTracksService } from '../_services/tracks.service';
import { SettingsUsersService } from '../_services/users.service';
@Component({
  selector: 'app-settings-manage-user-include',
  templateUrl: './manage-user-include.component.html',
  styleUrls: ['./manage-user-include.component.scss']
})
export class SettingsManageUserIncludeComponent extends NotificationClass implements OnInit {

  public userInfos: UserInclude[] = [];
  public modules: Array<TestModule> = [];
  public tracks: Array<TestTrack> = [];
  public selectedModules: Array<TestModule> = [];
  public selectedTracks: Array<TestTrack> = [];
  public pageSize: number = 8;

  constructor(
    protected _snackBar: MatSnackBar,
    private _modulesService: SettingsModulesService,
    private _tracksService: SettingsTracksService,
    private _usersService: SettingsUsersService,
    private _router: Router
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.addUser();
    this._loadModules();
    this._loadTracks();
  }

  public addUser(name: string = '', lastName: string = '',
    email: string = '', message: string = '', responsibleEmail: string = '') {
    this.userInfos.push({
      name: name,
      lastName: lastName,
      email: email,
      message: message,
      responsibleEmail: responsibleEmail,
      expirationModules: [],
      expirationTracks: []
    });
    this.updateUserInfoTrack();
    this.updateUserInfoModule();
  }

  public removeUser(index: number) {
    this.userInfos.splice(index, 1);
  }

  public triggerModuleSearch(searchValue: string) {
    this._loadModules(searchValue, this.pageSize);
  }

  private _loadModules(searchValue: string = '', pagesize: number = this.pageSize): void {
    this._modulesService.getPagedFilteredModulesList(
      1, pagesize, searchValue
    ).subscribe(response => {
      this.selectedModules.forEach((mod: TestModule) => {
        const index = response.data.modules.findIndex(m => m.id === mod.id);
        if (index !== -1) {
          response.data.modules.splice(index, 1);
        }
      });
      this.modules = response.data.modules;
    });
  }

  public handleModuleSelected(modules: TestModule[]) {
    const selectedModules = modules.map(module => ({
        id: module.id,
        title: module.title,
        checked: true
      }));
    this.selectedModules = selectedModules;
    this.updateUserInfoModule();
  }

  public triggerTrackSearch(searchValue: string) {
    this._loadTracks(searchValue, this.pageSize);
  }

  private _loadTracks(searchValue: string = '', pageSize: number = this.pageSize): void {
    this._tracksService.getPagedFilteredTracksList(
      1, pageSize, searchValue
    ).subscribe(response => {
      this.selectedTracks.forEach((tck: TestTrack) => {
        const index = response.data.tracks.findIndex(t => t.id === tck.id);
        if (index !== -1) {
          response.data.tracks.splice(index, 1);
        }
      });
      this.tracks = response.data.tracks;
    });
  }

  public handleTracksSelected(tracks: TestTrack[]) {
    const selectedTracks = tracks.map(track => ({
        id: track.id,
        title: track.title,
        checked: true
      }));
    this.selectedTracks = selectedTracks;
    this.updateUserInfoTrack();
  }

  public updateUserInfoTrack() {
    for (let i = 0; i < this.userInfos.length; i++) {
      const userInfo = this.userInfos[i];
      const trackIds = userInfo.expirationTracks.map(x => x.id);
      for (let j = 0; j < trackIds.length; j++) {
        const trackId = trackIds[j];
        const findIndex = this.selectedTracks.findIndex(x => x.id === trackId);
        if (findIndex === -1) {
          const removeIndex = userInfo.expirationTracks.findIndex(x => x.id === trackId);
          if (removeIndex !== -1) {
            userInfo.expirationTracks.splice(removeIndex, 1);
          }
        }
      }
      for (let j = 0; j < this.selectedTracks.length; j++) {
        const selectedTrack = this.selectedTracks[j];
        const findIndex = userInfo.expirationTracks.findIndex(x => x.id === selectedTrack.id);
        if (findIndex === -1) {
          userInfo.expirationTracks.push({
            id: selectedTrack.id,
            title: selectedTrack.title,
            expirationDate: null
          });
        }
      }
    }
  }

  public updateUserInfoModule() {
    for (let i = 0; i < this.userInfos.length; i++) {
      const userInfo = this.userInfos[i];
      const moduleIds = userInfo.expirationModules.map(x => x.id);
      for (let j = 0; j < moduleIds.length; j++) {
        const moduleId = moduleIds[j];
        const findIndex = this.selectedModules.findIndex(x => x.id === moduleId);
        if (findIndex === -1) {
          const removeIndex = userInfo.expirationModules.findIndex(x => x.id === moduleId);
          if (removeIndex !== -1) {
            userInfo.expirationModules.splice(removeIndex, 1);
          }
        }
      }
      for (let j = 0; j < this.selectedModules.length; j++) {
        const selectedmodule = this.selectedModules[j];
        const findIndex = userInfo.expirationModules.findIndex(x => x.id === selectedmodule.id);
        if (findIndex === -1) {
          userInfo.expirationModules.push({
            id: selectedmodule.id,
            title: selectedmodule.title,
            expirationDate: null
          });
        }
      }
    }
  }

  public save() {
    if (isNil(this.userInfos) || isEmpty(this.userInfos)) {
      this.notify('É necessário adicionar pelo menos um usuário');
      return;
    }
    for (let i = 0; i < this.userInfos.length; i++) {
      const userInfo = this.userInfos[i];
      if (isNil(userInfo.name) || userInfo.name === '') {
        this.notify('O ' + (i + 1).toString() + 'º usuário esta com o campo obrigatório nome vazio');
        return;
      } else if (isNil(userInfo.lastName) || userInfo.lastName === '') {
        this.notify('O ' + (i + 1).toString() + 'º usuário esta com o campo obrigatório sobrenome vazio');
        return;
      } else if (isNil(userInfo.email) || userInfo.email === '') {
        this.notify('O ' + (i + 1).toString() + 'º usuário esta com o campo obrigatório email vazio');
        return;
      }
      const emailArray = userInfo.email.split('@');
      if (emailArray.length !== 2) {
        this.notify('O campo email do ' + (i + 1).toString() + 'º usuário não esta no formato correto');
        return;
      }
      const subEmailArray = emailArray[1].split('.');
      if (subEmailArray.length < 2) {
        this.notify('O campo email do ' + (i + 1).toString() + 'º usuário não esta no formato correto');
        return;
      }
      if (userInfo.responsibleEmail) {
        const responsibleEmailArray = userInfo.responsibleEmail.split('@');
        if (responsibleEmailArray.length !== 2) {
          this.notify('O campo email do responsavel do ' + (i + 1).toString() + 'º usuário não esta no formato correto');
          return;
        }
        const responsibleSubEmailArray = responsibleEmailArray[1].split('.');
        if (responsibleSubEmailArray.length < 2) {
          this.notify('O campo email do responsavel do ' + (i + 1).toString() + 'º usuário não esta no formato correto');
          return;
        }
      }
      const today = new Date();
      if (userInfo.expirationTracks && userInfo.expirationTracks.length > 0) {
        for (let j = 0; j < userInfo.expirationTracks.length; j++) {
          const expirationTrack = userInfo.expirationTracks[j];
          if (expirationTrack.expirationDate && (expirationTrack.expirationDate < today)) {
            this.notify('O ' + (i + 1).toString() + 'º usuário possui itens com data de expiração menor que o dia de hoje');
            return;
          }
        }
      }
      if (userInfo.expirationModules && userInfo.expirationModules.length > 0) {
        for (let j = 0; j < userInfo.expirationModules.length; j++) {
          const expirationModule = userInfo.expirationModules[j];
          if (expirationModule.expirationDate && (expirationModule.expirationDate < today)) {
            this.notify('O ' + (i + 1).toString() + 'º usuário possui itens com data de expiração menor que o dia de hoje');
            return;
          }
        }
      }
    }

    const selectedTrackIds = this.selectedTracks.map(x => x.id);
    const selectedModuleIds = this.selectedModules.map(x => x.id);

    this._usersService.includeNewUsers(this.userInfos, selectedTrackIds, selectedModuleIds).subscribe(res => {
      this.notify('Usuários incluidos com sucesso');
      this._router.navigate(['/configuracoes/gerenciar-equipe']);
    }, err => {
      this.notify(this.getErrorNotification(err));
    });
  }
}
