<div class="inner-content">
    <div class="main">
        <app-effort-metric 
            class="effort-metric"
            [metrics]="studentsMetrics"
        ></app-effort-metric>
        <app-performance-metric 
            class="performance-metric"
            [metrics]="studentsMetrics" 
            [isModule]="true"   
        ></app-performance-metric>
        <app-cockpit-ranking 
            [ranking]="studentsRanking"
            class="ranking">
        </app-cockpit-ranking>
        <app-cockpit-menu 
            [students]="_cockpitService.getStudents().getValue()"
            [showModules]="false"
            (goToStudent)="goToStudent($event)"
            class="cockpit-menu">
        </app-cockpit-menu>
    </div>
</div>
