import { Component, ViewChild } from '@angular/core';
import {
  FilterReportStudentMetricComponent
} from 'src/app/pages/report/filters/filter-report-student-metric/filter-report-student-metric.component';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { CategoryFilterSearchOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import {
  FilterReportStudentMetric
} from 'src/app/pages/report/filters/filter-report-student-metric/models/filter-report-student-metric';
import { find, isEmpty, isNil } from 'lodash';
import { ReportType } from 'src/app/pages/report/report-student-metric/enums/report-type.enum';
import { environment } from 'src/environments/environment';
import format from 'date-fns/format';

interface ReportParameters {
  page: string;
  pageSize: string;
  sort: string;
  sortAscending: string;
  categories: CategoryFilterSearchOption[];
  startDate: string;
  endDate: string;
  userId: string;
}

@Component({
  selector: 'app-report-student-metric',
  templateUrl: './report-student-metric.component.html',
  styleUrls: ['./report-student-metric.component.scss']
})
export class ReportStudentMetricComponent extends NotificationClass {

  @ViewChild('FilterComponent', { static: true })
  public filterComponent: FilterReportStudentMetricComponent;

  public readonly pageSize: number = 20;
  public get filter() { return this.filterComponent.filter; }
  public ReportType = ReportType;
  public user: LoginResponse;
  public environment = environment;

  constructor(
    protected _snackBar: MatSnackBar,
    private _reportService: ReportsService,
    private _excelService: ExcelService,
    _authService: AuthService
  ) {
    super(_snackBar);
    _authService.isLoggedIn().subscribe((x) => {
      this.user = _authService.getLoggedUser();
    });
  }



  public exportReport(reportType: ReportType) {
    this.notify('Dependendo da seleção do filtro, a visualização dos dados pode levar alguns minutos');
    const track = this.filter.categories.find(categorie => categorie.columnName === 'track.id');
    const module = this.filter.categories.find(categorie => categorie.columnName === 'module.id');

    if (reportType === ReportType.Interaction &&
      track.contentNames.length === 0 &&
      module.contentNames.length > 0
    ) {
      reportType = ReportType.InteractionModule;
    }

    this._loadDataReport(this.filter, reportType);
  }


  private _findDescriptionByColumnName(columnName: string) {
    const hasCategories = !isNil(this.filter.categories) || !isEmpty(this.filter.categories);
    const filterCategory = find(this.filter.categories, category => category.columnName === columnName);
    const hasContentNames = filterCategory.contentNames;

    return hasCategories && !isEmpty(hasContentNames) ?
      filterCategory.contentNames
        .map(c => c.description)
        .reduce((acc, next) => acc.concat(`, ${next}`))
      : ' - ';
  }

  private _getInteractionReportHeader(): string[][] {
    return [
      ['ALUNO:', this.filter.student ? this.filter.student.name : '-']
      , ['DATA INICIAL E FINAL :',
        `${format(new Date(this.filter.date.start), 'DD/MM/YYYY HH:MM:SS')} à ${format(new Date(this.filter.date.end), 'DD/MM/YYYY HH:MM:SS')}`]
      , ['SEGMENT', this._findDescriptionByColumnName('segment.name')]
      , ['BUSSINES UNIT', this._findDescriptionByColumnName('businessUnit.name')]
      , ['BUSSINES GROUP', this._findDescriptionByColumnName('businessGroup.name')]
      , []
    ];
  }

  private _getEffortReportHeader() {
    return [
      ['ALUNO:', this.filter.student ? this.filter.student.name : '-']
      , ['TRILHA', this._findDescriptionByColumnName('track.id')]
      , ['SEGMENT', this._findDescriptionByColumnName('segment.name')]
      , ['BUSSINES UNIT', this._findDescriptionByColumnName('businessUnit.name')]
      , ['BUSSINES GROUP', this._findDescriptionByColumnName('businessGroup.name')]
      , []
    ];
  }

  private _createEffortReport(parameters: ReportParameters) {

    this._reportService.getStudentEffort(parameters).subscribe((rows) => {

      if (isNil(rows) || isEmpty(rows.data) || isEmpty(rows.data.efforties)) {
        this.notify('Não foi encontrado nenhum registro com os filtros informados ou não há progresso realizado.');
        return;
      }

      const reportExcel =
        rows.data.efforties.map(row => ({
          'Identificação do aluno': row.userId,
          'Trilha': row.trackName,
          'Área': row.trackAreaTitle,
          'Sub-Área': row.trackSubAreaTitle,
          'Aluno': row.userName,
          'Segment': row.segmentName,
          'Bussines Group': row.bussinesGroupName,
          'Bussines Unit': row.bussinesUnitName,
          'Progresso realizado (%)': row.progressUntilDay.toFixed(2),
          'Progresso esperado (%)': row.expectedProgressUntilDay.toFixed(2),
          'Progresso da turma (%)': row.averageStudentProgress.toFixed(2),
          'Matrícula': row.registrationId
        }));

      this._excelService
        .exportAsExcelFile(reportExcel, 'MÉTRICA ENGAJAMENTO DOS ALUNOS - ', this._getEffortReportHeader());
    });

  }

  private _createInteractionModuleReport(rows: any) {

    if (isNil(rows) || isEmpty(rows.data) || isEmpty(rows.data.actions)) {
      this.notify('Não foi encontrado nenhum registro com os filtros informados.');
      return;
    }
    const reportExcel = [];
    rows.data.actions.forEach(row => {
      row.interacts.forEach(module => {
        const interactions = {
          'Identificação': row.userId,
          'Nome': row.userName,
          'Data': new Date(row.updatedAt),
          'Segment': row.segmentName,
          'Bussines Group': row.bussinesGroupName,
          'Bussines Unit': row.bussinesUnitName,
          'Trilha': ' ',
          'Módulo': module.title,
          'Duração': (this.toHHMMSS(module.interaction) as unknown as Date),
          'Criado Em': row.createdAt,
          'Matrícula': row.registrationId,
          'Código do modulo': module.id,
          'Código da trilha': ' '
        };
        reportExcel.push(interactions);
      });
    });
    this._excelService.exportAsExcelFile(reportExcel, 'MÉTRICA INTERAÇÃO DOS ALUNOS - ', this._getInteractionReportHeader());

  }

  private _createInteractionTrackReport(rows: any) {

    if (isNil(rows) || isEmpty(rows.data) || isEmpty(rows.data.actions)) {
      this.notify('Não foi encontrado nenhum registro com os filtros informados.');
      return;
    }
    const reportExcel = [];
    rows.data.actions.forEach(row => {
      row.interacts.forEach(track => {
        if (track.interactionTrack !== null) {
          track.interactionTrack.forEach(module => {
            const interactions = {
              'Identificação': row.userId,
              'Nome': row.userName,
              'Data': new Date(row.updatedAt),
              'Segment': row.segmentName,
              'Bussines Group': row.bussinesGroupName,
              'Bussines Unit': row.bussinesUnitName,
              'Trilha': track.title,
              'Area': track.area,
              'Sub-Área': track.subArea,
              'Módulo': module.title,
              'Duração': (this.toHHMMSS(module.interaction) as unknown as Date),
              'Criado Em': row.createdAt,
              'Matrícula': row.registrationId,
              'Código do modulo': module.id,
              'Código da Trilha': track.id
            };
            reportExcel.push(interactions);
          });
        }
      });
    });
    this._excelService.exportAsExcelFile(reportExcel, 'MÉTRICA INTERAÇÃO DOS ALUNOS - ', this._getInteractionReportHeader());

  }

  private toHHMMSS(secondsToConvert: number) {
    let hours: any = Math.floor(secondsToConvert / 3600);
    let minutes: any = Math.floor((secondsToConvert - (hours * 3600)) / 60);
    let seconds: any = secondsToConvert - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = '0' + hours; }
    if (minutes < 10) { minutes = '0' + minutes; }
    if (seconds < 10) { seconds = '0' + seconds; }
    return `${hours}:${minutes}:${seconds}`;
  }

  private _getFilterCategories() {
    return this.filter.categories.map(category => ({
      columnName: category.columnName,
      contentNames: category.contentNames.map(c => category.columnName.includes('.id') ? c.id : c.description)
    }) as CategoryFilterSearchOption);
  }

  private _loadDataReport(filter: FilterReportStudentMetric, reportType: ReportType): void {
    if (!filter) return;

    const parameters: ReportParameters = {
      page: null,
      pageSize: null,
      sort: null,
      sortAscending: null,
      categories: this._getFilterCategories(),
      startDate: filter.date.start,
      endDate: filter.date.end,
      userId: filter.student ? filter.student.id : undefined
    };

    const interactionReport = () => this.interactionTrackReport(parameters);
    const EffortReport = () => this._createEffortReport(parameters);
    const interactionModuleReport = () => this.interactionModuleReport((parameters));

    const createReport = {
      [ReportType.Interaction]: interactionReport,
      [ReportType.Effort]: EffortReport,
      [ReportType.InteractionModule]: interactionModuleReport
    };

    createReport[reportType]();

  }

  public interactionTrackReport(parameters: ReportParameters) {
    this._reportService.getStudentInteraction(parameters).subscribe((rows) => {
      this._createInteractionTrackReport(rows);
    });
  }

  public interactionModuleReport(parameters: ReportParameters) {
    this._reportService.getStudentModuleInteraction(parameters).subscribe((rows) => {
      this._createInteractionModuleReport(rows);
    });
  }
}
