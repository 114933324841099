import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UserService } from 'src/app/pages/_services/user.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { cloneDeep, isNil } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-progress-card',
  template: `
    <div class="progress-card" >
      <img class="main-img" (click)="getRouterLink()" [src]="progressItem.imageUrl" />
      <div class="gradient-bar" ></div>
      <img class="badge" [src]="userService.getCompletedLevelImage(progressItem?.level)" />
      <ng-container *ngIf="hasGeneralPermission && progressItem?.canBlock">
        <img *ngIf="progressItem?.blocked" (click)="blockUserMaterial()" class="delete" src="./assets/img/lock-user-red.png" />
        <img *ngIf="!progressItem?.blocked" (click)="blockUserMaterial()" class="delete" src="./assets/img/unlock-user-black.png" />
      </ng-container>
      <p class="title" (click)="getRouterLink()">
        {{ progressItem.title }}<br>
      </p>
      <ng-container *ngIf="showProgress">
        <div class="wrapper">
          <p class="bold" >
             <mat-form-field>
              <input matInput
                [disabled]="user.role !== 'Admin'"
                placeholder="Válido até"
                [ngxMatDatetimePicker]="picker"
                (focus)="picker.open()"
                [(ngModel)]="progressItem.dueDate" />
              <mat-datepicker-toggle  matPrefix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker
                (closed)="updateDueDate()"
                [showSeconds]="true"
                [stepHour]="1"
                [enableMeridian]="false"
                [stepMinute]="1"
                [stepSecond]="1">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </p>
          <p class="bold" *ngIf="levelDict" >
            <span>{{ levelDict[progressItem.level] }}</span>
            <ng-container *ngIf="progressItem.level !== 4">
              {{ progressItem.percentage * 100 | number:'1.0-0' }}%
            </ng-container>
            <ng-container *ngIf="progressItem.level === 4">
              Nível-4
            </ng-container>
          </p>
        </div>

        <app-progress-bar
          [currentProgress]="progressItem.percentage*100"
          [height]="16"
        ></app-progress-bar>
      </ng-container>
    </div>`,
  styleUrls: ['./progress-card.component.scss']
})
export class UserProgressCardComponent extends NotificationClass implements OnInit {

  @Input() progressItem: any = {};
  @Input() showProgress: boolean = true;
  @Input() isTrack: boolean = false;
  @Input() isEvent: boolean = false;
  @Input() isModule: boolean = false;

  public levelDict: any;
  public hasGeneralPermission: boolean;
  public user: LoginResponse;

  private _originalDueDate: any;
  public get originalDueDate(): any { return this._originalDueDate; }
  public set originalDueDate(originalDueDate: any) {
    this._originalDueDate = originalDueDate;
  }

  private get _hasChangedDate() {
    if (isNil(this.progressItem) || isNil(this.progressItem.dueDate)) return false;
    return new Date(this.originalDueDate).getTime() !== new Date(this.progressItem.dueDate).getTime();
  }

  public studentId = this._activatedRoute.snapshot.paramMap.get('userId');

  constructor(
    protected _snackBar: MatSnackBar,
    private _sharedService: SharedService,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    public userService: UserService,
    public _settingsUsersService: SettingsUsersService,
    private router: Router) {
    super(_snackBar);
    this.user = this._authService.getLoggedUser();
    this.hasGeneralPermission = this.user.role === 'Admin' || this.user.role === 'HumanResources';
  }

  ngOnInit() {
    this._loadLevels();
    this.originalDueDate = new Date(this.progressItem.dueDate);
  }
  private _loadLevels(): void {
    this._sharedService.getLevels(true).subscribe((response) => {
      this.levelDict = {};
      response.data.forEach(level => {
        this.levelDict[level.id] = level.description;
      });
    });
  }

  public updateDueDate() {
    if (!this._hasChangedDate) return;

    const fnUpdateUserDueDate = (fnUpdateDueDate: Observable<any>) => fnUpdateDueDate
          .subscribe(() => {
            this.originalDueDate = new Date(this.progressItem.dueDate);
            this.notify('Data atualizada');
          });

    const updateUserDueDate = () => {
     if (this.isTrack) {
        fnUpdateUserDueDate(this._settingsUsersService.updateTrackDueDate({
          userId: this.studentId,
          dueDate: this.progressItem.dueDate,
          trackId: this.progressItem.id
        }));
      } else {
        fnUpdateUserDueDate(this._settingsUsersService.updateModuleDueDate({
          userId: this.studentId,
          dueDate: this.progressItem.dueDate,
          moduleId: this.progressItem.id
        }));
      }
    };

    updateUserDueDate();
  }

  public getRouterLink() {
    if (this.isEvent) {
      this.router.navigate(['evento/' + this.progressItem.id + '/' + this.progressItem.scheduleId]);
    } else if (this.isTrack) {
      this.router.navigate(['configuracoes/trilha-de-curso/' + this.progressItem.id + '/' + this.user.user_id]);
    } else {
      this.router.navigate(['home/modulo/' + this.progressItem.id]);
    }
  }

  public blockUserMaterial() {
    if (this.isEvent) {
      this.userService.blockUserMaterial(this.studentId, '', '', this.progressItem.scheduleId).subscribe(() => {
        this.progressItem.blocked = !this.progressItem.blocked;
        this.progressItem.blocked ? this.notify('Evento bloqueado com sucesso') :
          this.notify('Evento desbloqueado com sucesso');
      }, err => {
        this.notify('ocorreu um erro em mudar o status de bloqueio deste evento');
      });
    } else if (this.isTrack) {
      this.userService.blockUserMaterial(this.studentId, this.progressItem.id, '', '').subscribe(() => {
        this.progressItem.blocked = !this.progressItem.blocked;
        this.progressItem.blocked ? this.notify('Trilha bloqueada com sucesso') :
          this.notify('Trilha desbloqueada com sucesso');
      }, err => {
        this.notify('ocorreu um erro em mudar o status de bloqueio desta trilha');
      });
    } else if (this.isModule) {
      this.userService.blockUserMaterial(this.studentId, '', this.progressItem.id, '').subscribe(() => {
        this.progressItem.blocked = !this.progressItem.blocked;
        this.progressItem.blocked ? this.notify('Módulo bloqueado com sucesso') :
          this.notify('Módulo desbloqueado com sucesso');
      }, err => {
        this.notify('ocorreu um erro em mudar o status de bloqueio deste módulo');
      });
    }
  }

}
