<div class="content">
  <div class="side-content">
    <h3>Filtros</h3>
    <div class="filter">
      <app-filter-report-student-dashboard #FilterComponent> </app-filter-report-student-dashboard>
    </div>
  </div>
  <div class="main-content">
    <div class="table">
      <div class="item">
        <div class="files">
          <span>Acompanhamento dos alunos</span>
        </div>
        <div class="margin-20-top-botton">
          <button class="btn-outline" (click)="exportReport()"> EXPORTAR </button>
          <button class="btn-test" (click)="openDialog()"> VISUALIZAR </button>
        </div>
      </div>
      <div class="item">
        <div class="files">
          <span>Acompanhamento dos alunos por assunto</span>
        </div>
        <div class="margin-20-top-botton">
          <button class="btn-outline" (click)="exportReportBySubject()"> EXPORTAR </button>
          <button class="btn-test" (click)="openBySubjectDialog()"> VISUALIZAR </button>
        </div>
      </div>
    </div>
  </div>
</div>
