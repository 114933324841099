import { Injectable } from '@angular/core';
import { BackendService, BackendResponse } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { CategoryFilterEnum } from 'src/app/models/enums/category-filter.enum';
import { Parent, CategoryFilterSearchOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { EffortReport, PagedEffortReport } from 'src/app/pages/report/report-student-metric/models/effort-report.model';
import { InteractionReport } from 'src/app/pages/report/report-student-metric/models/interaction-report.model';

export interface CategoryParams {
  category: CategoryFilterEnum;
  searchTerm?: string;
  page?: number;
  pageSize?: number;
  parent?: Parent;
  parents?: Parent[];
  isAlternate?: boolean;
}

@Injectable()
export class ReportsService {

  constructor(
    private _httpService: BackendService,
    private _authService: AuthService,
    private _urlService: UrlService,
    private _sharedService: SharedService) { }

  public getUserProgresses(categories: CategoryFilterSearchOption[]): Observable<any> {
    return this._httpService.post('getUserProgressReport', { categories });
  }

  public getTracksGrades(categories: CategoryFilterSearchOption[]): Observable<any> {
    return this._httpService.post('getTracksGrades', { categories });
  }

  public getFinanceReport(): Observable<any> {
    return this._httpService.get('getFinanceReport', [], [{
      'name': 'trackIds', 'value': 'trilha'
    }]);
  }

  public getCategory(
    { category, searchTerm = '', page = 1, pageSize = 10, parent, parents, isAlternate = false }: CategoryParams): Observable<any> {
    return this._httpService.post('getCategories', {
      category,
      searchTerm,
      page,
      pageSize,
      parent,
      parents,
      isAlternate
    });
  }

  public getStudentDashboard({ page, pageSize, sort, sortAscending, userId, categories }): Observable<any> {
    return this._httpService.post('getStudentDashboard', {
      page: page,
      pageSize: pageSize,
      filters: {
        categories,
        userId,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }

  public getStudentDashboardBySubject({ page, pageSize, sort, sortAscending, userId, categories }): Observable<any> {
    return this._httpService.post('getStudentDashboardBySubject', {
      page: page,
      pageSize: pageSize,
      filters: {
        categories,
        userId,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }


  public getStudentInteraction({ page, pageSize, sort, sortAscending, categories, startDate, endDate, userId }):
    Observable<BackendResponse<{ actions: InteractionReport[] }>> {
    return this._httpService.post('getStudentInteraction', {
      page: page,
      pageSize: pageSize,
      filters: {
        categories,
        startDate,
        endDate,
        userId,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }

  public getStudentModuleInteraction({ page, pageSize, sort, sortAscending, categories, startDate, endDate, userId }):
    Observable<BackendResponse<{ actions: InteractionReport[] }>> {
    return this._httpService.post('getStudentModuleInteraction', {
      page: page,
      pageSize: pageSize,
      filters: {
        categories,
        startDate,
        endDate,
        userId,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }

  public getStudentEffort({ page, pageSize, sort, sortAscending, categories, startDate, endDate, userId }):
    Observable<BackendResponse<PagedEffortReport>> {
    return this._httpService.post('getStudentEffort', {
      page: page,
      pageSize: pageSize,
      filters: {
        categories,
        startDate,
        endDate,
        userId,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }

  public getTrackAnswers(
    categories: CategoryFilterSearchOption[],
    userId: string,
    getBinaryResponse = true): Observable<BackendResponse<any>> {
    return this._downloadFile('getTrackAnswers', { categories, userId, getBinaryResponse });
  }

  public getAtypicalMovements(
    categories: CategoryFilterSearchOption[],
    userId: string, getBinaryResponse = true): Observable<BackendResponse<any>> {
    return this._downloadFile('getAtypicalMovements', { categories, userId, getBinaryResponse });
  }

  private _downloadFile(url: string, data: any): any {
    const applicationsUrl = this._urlService.getUrl(url);
    const user = this._authService.getLoggedUser();
    this._sharedService.setLoaderValue(true);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': user.access_token,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    const impersonatedUser = this._authService.getImpersonatedUser();
    if (impersonatedUser && impersonatedUser.userId) {
      options.headers['UserIdToImpersonate'] = impersonatedUser.userId;
    }
    return this._httpService.httpRaw.post(applicationsUrl, data, options)
      .pipe(
        map(this._extractContent),
        catchError(async (responseError: HttpErrorResponse) => {
          if (responseError.error instanceof Blob) {
            const errorText = await responseError.error['text']();
            const serializedError: BackendResponse<any> = JSON.parse(errorText);
            this._sharedService.setLoaderValue(false);
            return serializedError;
          }
          this._sharedService.setLoaderValue(false);
          return responseError;
        })
      );
  }

  private _extractContent(res: any) {
    const blob: Blob = res.body;
    const contentDisposition = res.headers.get('content-disposition');
    const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    const url = window.URL.createObjectURL(blob);

    const element = document.createElement('a');
    element.href = url;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    this._sharedService.setLoaderValue(false);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      element.remove();
    }, 0);

  }

  public getEffectivenessIndicators(userId: string, categories: CategoryFilterSearchOption[]): Observable<any> {
    return this._httpService.post('effectivenessIndicators', { userId, categories });
  }

  public getTracksReport(): Observable<any> {
    return this._httpService.post('getTracksReport', {});
  }

  public getStatusReport({
    page, pageSize, sort, sortAscending, startDate, endDate, student, responsibles, categories
  }): Observable<any> {
    return this._httpService.post('getStatusReport', {
      page: page,
      pageSize: pageSize,
      filters: {
        categories,
        startDate,
        endDate,
        student,
        responsibles,
        sortBy: sort || '',
        isSortAscending: sortAscending || false
      }
    });
  }
  public getModulesReport(): Observable<any> {
    return this._httpService.post('getModulesReport', {});
  }

  public getSynchronousExperienceReport(startDate: string, endDate: string, IsSortAscending: boolean, sortBy: string, trackIds: string[]): Observable<any> {
    return this._httpService.post('getSynchronousExperienceReport', {
      filters: {
        startDate,
        endDate,
        IsSortAscending,
        sortBy,
        trackIds
      }
    });
  }

  public getStudyPlanReport(): Observable<any> {
    return this._httpService.post('getStudyPlanReport',{});
  }

}
