import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EFavoriteEnum } from 'src/app/models/enums/favorite.enum';
import { UserService } from 'src/app/pages/_services/user.service';
import { NotificationClass } from '../../classes/notification';

@Component({
  selector: 'app-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss']
})
export class FavoriteButtonComponent extends NotificationClass {

  @Input() isFavorite: boolean = false;
  @Input() id: string;
  @Input() type: EFavoriteEnum;

  constructor(private _userService: UserService,
    protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }


  public RemoveOrAddFavorite() {
    this.isFavorite = !this.isFavorite;
    this._userService.FavoriteCourse(this.id, this.type, this.isFavorite).subscribe(response => {

      this.notify(`${this.isFavorite ? 'Item adicionado aos favoritos' : 'Item removido dos favoritos'}`);
    });
  }

}
