import { Component, Input, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ContentEventsService } from 'src/app/pages/_services/events.service';
import { ActivatedRoute } from '@angular/router';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { EventApplication } from 'src/app/models/event-application.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { UsersDialogComponent } from 'src/app/shared/dialogs/users/users-dialog.component';
import { Student } from 'src/app/shared/dialogs/users/models/student.model';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { isNil } from 'lodash';

@Component({
  selector: 'app-settings-applications-header',
  template: `
    <div class="header" >
      <div class="title" >
        <h3>{{eventTitle}}</h3>
          <p><span>Realização:</span> <small>{{eventDate | date : 'dd/MM/yyyy'}}</small></p>
          <p>
            <span>Período de Inscrição:</span> <small>{{scheduleStartDate | date : 'dd/MM'}} - {{scheduleEndDate | date : 'dd/MM'}}</small>
            <app-progress-bar app-progress-bar [currentProgress]="percentage" [height]="18" ></app-progress-bar>
          </p>
      </div>
        <div class="button-wraper">
        <a class="link-button" href="./../../../../assets/download/PLANILHA_DE_IMPORTAÇÃO_DE_USUÁRIOS_EM_EVENTOS.xlsx"
          download="PLANILHA_DE_IMPORTAÇÃO_DE_USUÁRIOS_EM_EVENTOS.xlsx">
          BAIXAR MODELO
        </a>
      <button class="btn-test" (click)="showUploadContainer() ">Importar alunos </button>
      <button class="btn-test" (click)="showIncludeDialog() ">Adicionar alunos </button>
      <input *ngIf="fileContainerVisible" class="input-file" type="file" accept=".csv,.xlsx" hidden
        (change)="setDocumentFile($event.target.files)" #file />
    </div>
    </div>`,
  styleUrls: ['./applications-header.component.scss']
})
export class SettingsApplicationsHeaderComponent extends NotificationClass {

  @Input() readonly applications: Array<EventApplication> = [];
  @Input() readonly eventTitle: string;
  @Input() readonly eventDate: Date;
  @Input() readonly scheduleStartDate: Date;
  @Input() readonly scheduleEndDate: Date;
  @Input() readonly percentage: number = 0;
  @ViewChild('file') private _file: ElementRef;

  private _eventId: string;
  private _scheduleId: string;

  @Output() public usersAdded = new EventEmitter<void>();

  public fileContainerVisible: boolean;

  constructor(
    protected _snackBar: MatSnackBar,
    private _eventsContentService: ContentEventsService,
    private _cd: ChangeDetectorRef,
    private _dialogService: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _excelService: ExcelService,
    private _trackService: SettingsTracksService,
    private _userService: SettingsUsersService
  ) {
    super(_snackBar);
    this._eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    this._scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
  }

  public setDocumentFile(files: FileList) {
    const file = files.item(0);
    const callback = this._sendToServer.bind(this);
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    reader.onloadend = function (e) {
      callback(
        this.result as string,
      );
      that.toggleFileContainer();
    };

    reader.readAsDataURL(file);
  }

  public showIncludeDialog() {
    this.notify('Buscando a lista de alunos');

    this._userService.getAllUsers().subscribe(response => {
      const users = response.data;

      const dialogRef = this._dialogService.open(UsersDialogComponent, {
        maxWidth: '100vw',
        width: '100vw',
        minHeight: '100vh',
        height: '100vh',
        hasBackdrop: false,
        data: users as Student[]
      });

      dialogRef.afterClosed().subscribe((rowsFromDialog: []) => {
        if (rowsFromDialog) {
          const userToSend = rowsFromDialog.map((user: any) => ({
            userId: user.id,
            name: user.name,
            email: user.email
          }));
          this._sendToServer(undefined, userToSend);
        }
      });

    });

  }

  public exportAnswers() {
    const excelModule = [];
    for (let useridx = 0; useridx < this.applications.length; useridx++) {
      const user = this.applications[useridx];
      if (user.prepQuizAnswers && user.prepQuizAnswers.length > 0) {
        for (let idx = 0; idx < user.prepQuizAnswers.length; idx++) {
          const answer = user.prepQuizAnswers[idx];
          excelModule.push({
            user: user.user.name, question: !isNil(user.prepQuiz) ? user.prepQuiz.questions[idx] : '',
            answer: answer, applicationStatus: user.applicationStatus
          });
        }
      }
    }
    this._excelService.exportAsExcelFile(excelModule, 'Respostas');
  }

  public showUploadContainer() {
    if (!this._file) this.toggleFileContainer();
    this._file.nativeElement.click();
  }

  private toggleFileContainer() {
    this.fileContainerVisible = !this.fileContainerVisible;
    this._cd.detectChanges();
  }


  private _sendToServer(result, users) {
    this._eventsContentService.postEventUsers(this._eventId, this._scheduleId, result, users || []).subscribe(() => {
      this.usersAdded.emit();
    });
  }
}
