<div class="inner-content">
    <div class="card">
        <h4 class="sub-title">Enunciado da Questão</h4>
        <div class="gray-card">
            <p>{{moduleQuestion.text}}</p>
        </div>
        <p class="explanation">
            Como avalia o enunciado em relação á pertinência ao assunto 
            {{subject.title}}, uso formal da língua, objetividade, simplicidade 
            e clareza.
        </p>
        <div class="slider-container">
            <p class="slider-value">{{getTextValuationGrade() | number: '1.1-1'}} - {{getValuationName(getTextValuationGrade())}}</p>
            <mat-slider class="slider" color="primary" 
                [value]="getTextValuationGrade()"
                [min]="0"
                [max]="10"
                [disabled]="true"
            ></mat-slider>
            <div class="slider-levels">
                <p class="slider-level">0 - Péssimo</p>
                <p class="slider-level">10 - Excelente</p>
            </div>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Alternativas de Respostas</h4>
        <div class="gray-card">
            <div class="answers-valuation-container">
                <div class="answers-valuation-grid">
                    <p><b>100% Correta</b></p>
                    <p>{{moduleQuestion.answers[0].text}}</p>
                </div>
                <div class="answers-valuation-grid">
                    <p><b>Parcialmente Correta</b></p>
                    <p>{{moduleQuestion.answers[1].text}}</p>
                </div>
                <div class="answers-valuation-grid">
                    <p><b>Errada</b></p>
                    <p>{{moduleQuestion.answers[2].text}}</p>
                </div>
                <div class="answers-valuation-grid">
                    <p><b>Absurda</b></p>
                    <p>{{moduleQuestion.answers[3].text}}</p>
                </div>
            </div>
        </div>
        <p class="explanation">
            Como avalia as alternativas em realação á pertinência ao enunciado 
            da questão e ás categorias associadas (100% correta, parcialmente 
            correta, errada e absurda).
        </p>
        <div class="slider-container">
            <p class="slider-value">{{getAnswersValuationGrade() | number: '1.1-1'}} - {{getValuationName(getAnswersValuationGrade())}}</p>
            <mat-slider class="slider" color="primary" 
                [value]="getAnswersValuationGrade()"
                [min]="0"
                [max]="10"
                [disabled]="true"
            ></mat-slider>
            <div class="slider-levels">
                <p class="slider-level">0 - Péssimo</p>
                <p class="slider-level">10 - Excelente</p>
            </div>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Conceitos abordados</h4>
        <div class="gray-card">
            <div class="concepts-valuation-container">
                <div class="concepts-valuation-grid">
                    <p></p>
                    <p class="answer-title">{{moduleQuestion.answers[0].text}}</p>
                    <p class="answer-title">{{moduleQuestion.answers[1].text}}</p>
                    <p class="answer-title">{{moduleQuestion.answers[2].text}}</p>
                    <p class="answer-title">{{moduleQuestion.answers[3].text}}</p>
                </div>
                <div class="concepts-valuation-grid">
                    <p>Conceitos</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
                <ng-container *ngFor="let concept of getSubjectConcepts();">
                    <div class="concepts-valuation-grid">
                        <p class="answer-box first">{{concept}}</p>
                        <p class="answer-box"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[0], concept)"/></p>
                        <p class="answer-box"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[1], concept)"/></p>
                        <p class="answer-box"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[2], concept)"/></p>
                        <p class="answer-box last"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[3], concept)"/></p>
                    </div>
                </ng-container>
            </div>
        </div>
        <p class="explanation">
            Como avalia os conceitos em realação á pertinência ao enunciado 
            e a configuração (se certo ou errado) e correlação ás alternativas.
        </p>
        <div class="slider-container">
            <p class="slider-value">{{getConceptsValuationGrade() | number: '1.1-1'}} - {{getValuationName(getConceptsValuationGrade())}}</p>
            <mat-slider class="slider" color="primary" 
                [value]="getConceptsValuationGrade()"
                [min]="0"
                [max]="10"
                [disabled]="true"
            ></mat-slider>
            <div class="slider-levels">
                <p class="slider-level">0 - Péssimo</p>
                <p class="slider-level">10 - Excelente</p>
            </div>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Conclusão</h4>
        <mat-tab-group [selectedIndex]="conclusionStep" (selectedTabChange)="onTabSelected($event)" animationDuration="0ms">
            <mat-tab
                [label]="moduleQuestion.likes + ' Aprovações'">
            </mat-tab>
            <mat-tab
            [label]="moduleQuestion.dislikes + ' Reprovações'">
            </mat-tab>
        </mat-tab-group>
        <ng-container *ngIf="conclusionStep === 0">
            <div class="comments-wrap">
                <ng-container *ngFor="let moduleQuestionValuation of getPositiveModuleQuestionValuations()">
                    <div class="comment-container">
                        <p class="comment-title">Comentário feito em {{moduleQuestionValuation.createdAt | date : 'dd/MM/yyyy'}}</p>
                        <p class="comment">{{moduleQuestionValuation.conclusionText}}</p>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="conclusionStep === 1">
            <div class="comments-wrap">
                <ng-container *ngFor="let moduleQuestionValuation of getNegativeModuleQuestionValuations()">
                    <div class="comment-container">
                        <p class="comment-title">Comentário feito em {{moduleQuestionValuation.createdAt | date : 'dd/MM/yyyy'}}</p>
                        <p class="comment">{{moduleQuestionValuation.conclusionText}}</p>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="card-footer">
        <button class="btn-outline" (click)="goBack.emit()">
          Voltar
        </button>
        <button class="btn-test" (click)="next.emit()">
          Ok
        </button>
      </div>
</div>