import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISelectedItem } from '../../dialogs/connect-dialog/interface/selected-item.interface';

@Component({
  selector: 'app-paged-list',
  templateUrl: './paged-list.component.html',
  styleUrls: ['./paged-list.component.scss']
})
export class PagedListComponent {

  @Input() users: ISelectedItem[];
  @Input() itensCount: number;
  @Input() pageSize: number;
  @Output() goToPage = new EventEmitter<number>();
  @Output() search = new EventEmitter<string>();
  @Output() removeUser = new EventEmitter<string>();
  constructor() { }

}
