import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Certificate } from 'src/app/models/certificate';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-certificate-preview',
  templateUrl: './certificate-preview.component.html',
  styleUrls: ['./certificate-preview.component.scss']
})
export class CertificatePreviewComponent {

  public url: string;
  public defaultLogoUrl: string = `./../../../assets/img/logo-${environment.logo}`;
  constructor(
    public dialogRef: MatDialogRef<CertificatePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public certificate: any
  ) { }

  public dismiss(state: boolean): void {
    this.dialogRef.close(state);
  }

  public getBorderColor(): string {
    if (this.certificate.borderColor !== '' ||
      this.certificate.borderColor !== null) {
      return this.certificate.borderColor;
    } else {
      return '#6D9EEB';
    }
  }
}
