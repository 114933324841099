<div class="inner-content" >
  <nav>
    <a href="javascript:history.back()" >
        <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      voltar
    </a>
    <h2>RESULTADO DA AVALIAÇÃO</h2>
    <a></a>
  </nav>

  <div class="results" >
    <div class="header" >
      <h1>
        {{ results?.eventName }}
        <small>edição: {{ results?.eventDate | date : 'dd/MM/yyyy' }}</small>
      </h1>
      <p>
        Para que possamos sempre aperfeiçoar nossos eventos,
        nos conte como foi sua experiência na aula presencial em que participou,
        avaliando os tópicos abaixo:
      </p>
    </div>
    <div class="subheader" >
      <p>
        {{ results?.itemsCount }} de {{ results?.studentsCount }} alunos responderam
        <a [routerLink]="['/configuracoes/gerenciar-inscricoes-notas/' + eventId + '/' + scheduleId ]" >
          ver lista
        </a>
      </p>
    </div>

    <ng-container *ngIf="!results || (results && results.itemsCount > 0)" >
      <div class="graph-result" >
        <h3>AVALIAÇÃO ATRIBUTOS</h3>
        <div class="stacked" >
          <canvas id="stackedCanvas" ></canvas>

          <div class="labels" >
            <div class="label" >
              <div style="background: #9afa00" ></div><br>
              <p>Excelente</p>
            </div>
            <div class="label" >
              <div style="background: #24bcd1" ></div><br>
              <p>Bom</p>
            </div>
            <div class="label" >
              <div style="background: #bd62ff" ></div><br>
              <p>Satisfatório</p>
            </div>
            <div class="label" >
              <div style="background: #ffa63e" ></div><br>
              <p>Insatisfatório</p>
            </div>
            <div class="label" >
              <div style="background: #ff4376" ></div><br>
              <p>Ruim</p>
            </div>
          </div>
        </div>
      </div>

      <div class="graph-result middle" >
        <h3>EXPECTATIVA x REALIDADE</h3>
        <div class="pie" >
          <canvas id="pieCanvas" ></canvas>

          <div class="labels" *ngIf="results" >
            <p class="good" >
              <span>{{ getPercentage(results.expectation.exceedExpectation) }}%</span><br>
              superou a expectativa
            </p>
            <p class="regular" >
              <span>{{ getPercentage(results.expectation.asExpected) }}%</span><br>
              dentro da expectativa
            </p>
            <p class="bad" >
              <span>{{ getPercentage(results.expectation.belowExpectation) }}%</span><br>
              abaixo da expectativa
            </p>
          </div>
        </div>
      </div>

      <div class="graph-result" >
        <h3>COMENTÁRIOS/SUGESTÕES</h3>
        <div class="comment" *ngFor="let sugObj of results?.suggestions" >
          <p>
            {{ sugObj.suggestion }}
          </p>
          <button class="btn-test"
            *ngIf="results?.canApprove"
            [ngClass]="{ 'approved': sugObj.approved }"
            (click)="manageSuggestion(sugObj)" >
            {{ sugObj.approved ? 'Aprovado' : 'Aprovar' }}
          </button>
        </div>

        <p *ngIf="results && (!results.suggestions || results.suggestions.length === 0)" >
          Não há comentários ou sugestões.
        </p>

        <app-pagination *ngIf="results"
          [hidden]="results.suggestions === 0"
          [setPagination]="{
            'itemsCount': results.suggestions.length,
            'pageSize': 5
          }"
          (goToPage)="goToPage($event)"
        ></app-pagination>
      </div>
    </ng-container>

    <p class="no-answer" *ngIf="results && results.itemsCount === 0" >
      Nenhum aluno respondeu a Avaliação de Reação ainda.
    </p>
  </div>
</div>
