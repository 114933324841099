import { SupportMaterial } from './support-material.interface';
import { Requirement } from '../settings/modules/new-module/models/new-requirement.model';
import { Subject } from './subject.model';
import { Content } from './content.model';
import { Question } from './question.model';
import { TutorInfo } from './previews/tutor-info.interface';
import { ModuleGradeTypeEnum } from './enums/ModuleGradeTypeEnum';
import { ModuleConfiguration } from './module-configuration';
import { EcommerceProduct } from './ecommerce-product.model';
import { isNil } from 'lodash';
import { differenceInSeconds } from 'date-fns';
import { Segment } from './segment.model';
import { Sector } from './sector.model';
import { StudyPlanDependencies } from './study-plan-dependencies.model';
import { ValuationsConfiguration } from './valuation-configuration';
import { ECourseAvailability } from './enums/course-availability.enum';

export class Module {
  public id: string;
  public moduleId: string;
  public title: string;
  public instructor: string;
  public excerpt: string;
  public instructorMiniBio: string;
  public imageUrl: string;
  public instructorImageUrl: string;
  public duration: number;
  public videoUrl?: string;
  public videoDuration?: number;
  public videoId?: string;
  public videoTitle?: string;
  public storeUrl?: string;
  public ecommerceUrl?: string;
  public createInEcommerce?: boolean;
  public ecommerceId?: number;
  public tags: Array<string>;
  public supportMaterials: Array<SupportMaterial>;
  public requirements: Array<Requirement> = [];
  public requiredModules: Array<Requirement>;
  public optionalModules: Array<Requirement>;
  public subjects: Array<Subject>;
  public moduleWeights: Array<ModuleWeights>;
  public contents: Array<Content>;
  public questions: Array<Question> = [];
  public instructorId: string;
  public tutorsIds: string[];
  public tutors?: TutorInfo[];
  public certificateUrl: string;
  public hasCertificate?: boolean;
  public published?: boolean;
  public hasQuestions: boolean = false;
  public questionsLimit?: number = null;
  public hasNewLearningTechniques?: boolean = false;
  public isDraft?: boolean;
  public moduleGradeType: ModuleGradeTypeEnum = 1;
  public ecommerceProducts?: Array<EcommerceProduct> = [];
  public grade?: number;
  public extraInstructorIds: string[];
  public extraInstructors?: TutorInfo[];
  public moduleConfiguration: ModuleConfiguration;
  public valuationsConfiguration: ValuationsConfiguration = new ValuationsConfiguration();
  public validFor: string;
  public hasUserProgress: boolean;
  public updatedAt: string;
  public calculateGradeAt: Date;
  public lastAnswerDate: Date;
  public get isAvailability(): boolean { return isAvailability(this.moduleConfiguration); }
  public hovered?: boolean;
  public isEnrolled?: boolean;
  public tests?: any[];
  public questionCreationValuation?: boolean;
  public moduleQuestions?: any[];
  public opinion?: boolean;
  public practiceQuestions?: boolean;
  public isStarted?: boolean;
  public isExempt?: boolean;
  public segment?: Segment;
  public sector?: Sector;
  requirement?: StudyPlanDependencies[];
  public availability?: ECourseAvailability = ECourseAvailability.available;
  public isInactive?: boolean;

  constructor(module: Module = null) {
    if (module)
      Object.keys(module).forEach(key => this[key] = module[key]);
  }

  public setModuleInfo(moduleInfo: Module) {
    this.title = moduleInfo.title;
    this.instructor = moduleInfo.instructor;
    this.excerpt = moduleInfo.excerpt;
    this.instructorMiniBio = moduleInfo.instructorMiniBio;
    this.imageUrl = moduleInfo.imageUrl;
    this.instructorImageUrl = moduleInfo.instructorImageUrl;
    this.tags = moduleInfo.tags;
    this.instructorId = moduleInfo.instructorId;
    this.published = moduleInfo.published;
    this.duration = moduleInfo.duration;
    this.tutorsIds = moduleInfo.tutorsIds;
    this.extraInstructorIds = moduleInfo.extraInstructorIds;
    this.storeUrl = moduleInfo.storeUrl;
    this.createInEcommerce = moduleInfo.createInEcommerce;
    this.validFor = moduleInfo.validFor;
    this.ecommerceProducts = moduleInfo.ecommerceProducts;
    this.questionCreationValuation = moduleInfo.questionCreationValuation;
    this.practiceQuestions = moduleInfo.practiceQuestions;
    this.sector = moduleInfo.sector;
    this.segment = moduleInfo.segment;
    this.availability = moduleInfo.availability;
  }

  public getInstructorLabelFormatted(): string {

    const instructors = [
      this.instructor,
      ...(this.extraInstructors ? this.extraInstructors : []).map(instructor => instructor.name)
    ];

    if (instructors.length === 1)
      return this.instructor;
    if (instructors.length === 2)
      return `${instructors[0]} e ${instructors[1]}`;
    if (instructors.length === 3)
      return `${instructors[0]}, ${instructors[1]} e ${instructors[2]}`;
    if (instructors.length > 3)
      return `${instructors[0]}, ${instructors[1]} e mais outros ${instructors.length - 2}`;

  }

  public setVideoInfo(module: Module) {
    this.videoUrl = module.videoUrl;
    this.videoDuration = module.videoDuration;
    this.videoId = module.videoId;
    this.videoTitle = module.videoTitle;
  }

  public addSupportMaterials(materials: Array<SupportMaterial>) {
    this.supportMaterials = materials;
  }

  public setRequirements(mandatory: Array<Requirement>, optional: Array<Requirement>) {
    this.requiredModules = mandatory;
    this.optionalModules = optional;
    this.requirements = this.requiredModules;
    this.requirements = this.requirements.concat(this.optionalModules);
  }

  public addSubjects(subjects: Array<Subject>) {
    this.subjects = subjects;
  }

  public addModulesWeights(weights: Array<ModuleWeights>, hasQuestions: boolean) {
    this.hasQuestions = hasQuestions;
    this.moduleWeights = weights;
  }

  public addContents(contents: Array<Content>) {
    contents.forEach(content => {
      content.concepts = content.concepts.filter(conc => conc.checked);
    });

    this.subjects.forEach(sub => {
      sub.contents = contents.filter(cont => cont.subjectId === sub.id);
    });
  }

  public addQuestions(questions: Array<Question>) {
    this.questions = questions;
  }

  public setInitialWeight() {
    this.moduleWeights = [
      {
        content: 'AvalDiagnostica',
        weight: 0,
        label: 'AVALIAÇÃO DIAGNÓSITCA'
      },
      {
        content: 'BqdDinamico',
        weight: 0,
        label: 'BQD DINÁMICO',
      },
      {
        content: 'AvalFinal',
        weight: 0,
        label: 'AVALIAÇÃO FINAL',
      },
    ];
  }
}

export function isAvailability(
  configuration: {
    moduleId: string, alwaysAvailable?: boolean, openDate?: string, valuationDate?: string, cutOffDate?: string
  }) {
  if (!configuration) return true;

  const endDate = configuration.cutOffDate || configuration.valuationDate;
  const openDate = configuration.openDate;

  let _isAvailability: boolean = false;

  const isAlwaysAvailable: boolean = configuration && (
    configuration.alwaysAvailable ||
    isNil(configuration.alwaysAvailable) ||
    (configuration.alwaysAvailable === false && isNil(openDate))
  );

  const hasValidConfiguration = isAlwaysAvailable || !!endDate;
  const hasValuationDate = !!configuration.valuationDate;

  if (!hasValidConfiguration)
    throw new Error(`moduleId: ${configuration.moduleId} está com a configuração incorreta das datas.`);

  const now = new Date();
  const diffOpen = differenceInSeconds(new Date(openDate), now);
  const isOpened = diffOpen <= 0;
  const hasFinishedModule = hasValuationDate && differenceInSeconds(new Date(configuration.valuationDate), now) <= 0;

  if (isOpened) {
    _isAvailability = isNil(endDate) ? true : !hasFinishedModule;
  }

  return isAlwaysAvailable || _isAvailability;
}

export function setModuleStatus(module: {
  moduleId: string,
  openDate?: string,
  cutOffDate?: string,
  valuationDate?: string,
  completedAt?: string,
  alwaysAvailable?: boolean,
  isFinished: boolean
}) {

  let status: { color: 'success' | 'error' | 'none', description: string } = { color: 'none', description: '--' };
  const now = new Date();
  const openDate = module.openDate && new Date(module.openDate);
  const endDate = (module.cutOffDate || module.valuationDate) && new Date(module.cutOffDate || module.valuationDate);
  const isAlwaysAvailable = !!module.alwaysAvailable;
  const completedAt = module.completedAt && new Date(module.completedAt);
  const hasScheduled = !isAlwaysAvailable && endDate && openDate;
  const finishedAfterCutOffDate = !!(hasScheduled && (completedAt > endDate));
  const _isAvailability = isAvailability({
    moduleId: module.moduleId,
    alwaysAvailable: module.alwaysAvailable,
    openDate: module.openDate,
    valuationDate: module.valuationDate,
    cutOffDate: module.cutOffDate,
  });

  if (openDate > now) {
    status = { color: 'none', description: 'Módulo indisponível' };
  } else if (openDate && !endDate) {
    status = { color: 'none', description: 'Módulo sem configuração' };
  } else if (module.isFinished && finishedAfterCutOffDate) {
    status = { color: 'success', description: 'Concluído fora prazo' };
  } else if (module.isFinished && hasScheduled && !finishedAfterCutOffDate) {
    status = { color: 'success', description: 'Concluído no prazo' };
  } else if (module.isFinished) {
    status = { color: 'success', description: 'Concluído' };
  } else if (!module.isFinished && !_isAvailability) {
    status = { color: 'error', description: 'Não concluído no prazo' };
  } else if (!module.isFinished && _isAvailability) {
    status = { color: 'none', description: 'Em andamento' };
  }

  return status;

}

export interface ModuleWeights {
  content: string;
  weight: number;
  label: string;
}

export interface ModuleQuestion {
  id?: string;
  userId?: string;
  moduleId?: string;
  subjectId?: string;
  text: string;
  concepts: string[];
  answers: ModuleQuestionAnswer[];
  likes?: number;
  dislikes?: number;
}

export enum ModuleQuestionAnswerTypeEnum {
  Correct = 1,
  PartiallyCorrect = 2,
  Wrong = 3,
  Absurd = 4
}

export interface ModuleQuestionAnswer {
  type: ModuleQuestionAnswerTypeEnum;
  text: string;
  concepts: string[];
}

export interface ModuleQuestionValuation {
  id?: string;
  moduleQuestionId?: string;
  userId?: string;
  textValuation: number;
  answersValuation: number;
  conceptsValuation: number;
  conclusion: boolean;
  conclusionText: string;
}

export interface QuestionModules {
  questions: Array<Question>;
  questionsLimit?: number;
  moduleGradeType: ModuleGradeTypeEnum;
}
