<div class="content">
  <app-cockpit-title type="title" templateName="performance" titleText="MÉTRICAS DE DESEMPENHO">
  </app-cockpit-title>
  <table>
    <tr>
      <th class="left">Tipo</th>
      <th class="center">Média Turma</th>
      <th class="center">Top 10</th>
    </tr>
    <tr>
      <td class="left">
        <app-cockpit-title type="subTitle" titleText="Aprendizagem"
          legend=" É a diferença entre a média das avaliação(ões) final (ais) e a média das avaliação(ões) final (ais). Pode indicar se houve ou não aprendizado.">
        </app-cockpit-title>
      </td>
      <td class="center">{{!metrics.learning ? '-' : metrics.learning.toFixed(1)}}</td>
      <td class="center">{{!metrics.learningTop10 ? '-' : metrics.learningTop10.toFixed(1)}}</td>
    </tr>
    <tr>
      <td class="left">
        <app-cockpit-title type="subTitle" titleText="Diagnóstica"
          legend="É a média da(s) avaliação (ões) conduzida(s) antes da obtenção do conhecimento">
        </app-cockpit-title>
      </td>
      <td class="center">{{!metrics.averageDiagnosticTest ? '-' : metrics.averageDiagnosticTest.toFixed(1)}}</td>
      <td class="center">{{!metrics.averageDiagnosticTestTop10 ? '-' : metrics.averageDiagnosticTestTop10.toFixed(1)}}
      </td>
    </tr>
    <tr *ngIf="!isModule">
      <td class="left">
        <app-cockpit-title type="subTitle" titleText="Eventos"
          legend="É a média das notas obtidas durante a  participação em eventos (atividades com instrutores presenciais)">
        </app-cockpit-title>
      </td>
      <td class="center">{{!metrics.averageEvents ? '-' : metrics.averageEvents.toFixed(1)}}</td>
      <td class="center">{{!metrics.averageEventsTop10 ? '-' : metrics.averageEventsTop10.toFixed(1)}}</td>
    </tr>
    <tr *ngIf="isModule">
      <td class="left">
        <app-cockpit-title type="subTitle" titleText="BDQ"
          legend="É a nota que é obtida ao responder o banco de questões">
        </app-cockpit-title>
      </td>
      <td class="center">{{!metrics.averageBdqGrade ? '-' : metrics.averageBdqGrade.toFixed(1)}}</td>
      <td class="center">{{!metrics.averageBdqGradeTop10 ? '-' : metrics.averageBdqGradeTop10.toFixed(1)}}</td>
    </tr>
    <tr>
      <td class="left">
        <app-cockpit-title type="subTitle" titleText="Prova"
          legend="É a média da(s) avaliação (ões) conduzida(s) após a obtenção do conhecimento">
        </app-cockpit-title>
      </td>
      <td class="center">{{!metrics.averageTest ? '-' : metrics.averageTest.toFixed(1)}}</td>
      <td class="center">{{!metrics.averageTestTop10 ? '-' : metrics.averageTestTop10.toFixed(1)}}</td>
    </tr>
  </table>
</div>
