<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'title'" [ngTemplateOutlet]="titleLayout"></ng-container>
  <ng-container *ngSwitchCase="'subTitle'" [ngTemplateOutlet]="subTitleLayout"></ng-container>
  <ng-container *ngSwitchCase="'custom'" [ngTemplateOutlet]="customLayout"></ng-container>
</ng-container>

<ng-template #titleLayout>
  <div class="title">
    <h1>{{titleText}}</h1>
    <span (click)="dispatchDialog(title, templateName)" [matTooltip]="legend" class="material-icons click">
      error_outline
    </span>
  </div>
</ng-template>

<ng-template #subTitleLayout>
  <div class="subTitle">
    <h1>{{titleText}}</h1>
    <span [matTooltip]="legend" class="material-icons click">
    </span>
  </div>
</ng-template>

<ng-template #customLayout>
  <div class="custom">
    <h1>{{titleText}}</h1>
    <ng-content></ng-content>
  </div>
</ng-template>


<ng-template #understandDialogEffortTemplate>
  <app-understand-content title="Entenda Métricas de Empenho"
    text="métricas relacionadas a dedicação da pessoa seja em horas ou cumprimento de prazos" (close)="dialog.close()">
  </app-understand-content>
</ng-template>

<ng-template #understandDialogPerformanceTemplate>
  <app-understand-content title="Entenda as Métricas de Desempenho"
    text="métricas relacionadas a performance do aluno respondendo ao Banco de Questões e na participação em eventos (atividades com instrutores presenciais)"
    (close)="dialog.close()">
  </app-understand-content>
</ng-template>
