import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/pages/_services/user.service';

@Pipe({ name: 'completedLevelImage' })
export class CompletedLevelImage implements PipeTransform {

    constructor(private _userService: UserService) { }
    transform(level) {
        const icon = this._userService.getLevelIcon(level);
        return icon;
    }

}
