import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomTooltipComponent } from './custom-tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    CustomTooltipComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    MatTooltipModule
  ],
  exports: [
    CustomTooltipComponent
  ]
})
export class CustomTooltipModule { }
