<div class="inner-content">
  <h2>LOGS DE INTEGRAÇÃO COM O ECOMMERCE</h2>

  <div class="filter-wrapper">
    <div class="filter">
      <app-date-range-picker #Picker (rangeSelected)="filterByDateRange($event)"></app-date-range-picker>
    </div>

    <button class="btn-test" (click)="applyFilter()">Filtrar</button>
    <button class="btn-test" (click)="exportRequest()">Exportar</button>

  </div>

  <ngx-datatable #Table class="material fullscreen" trackByProp="id" [rows]="logs" [rowIdentity]="rowIdentity"
    [messages]="messages" [headerHeight]="50" [footerHeight]="50" [rowHeight]="29" [virtualization]="false"
    [columnMode]="ColumnMode.force" [scrollbarH]="false" [scrollbarV]="true" [externalPaging]="true" [count]="logsCount"
    [limit]="pageSize" (page)="goToPage($event)" (activate)=" onRowClick($event)">

    <ngx-datatable-column prop="tag" [maxWidth]="200" name="Tag">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="createdAt" [maxWidth]="185" name="Data">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{value | date: 'dd/MM/yyyy HH:mm:ss'}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="errorMessage" name="Mensagem de Erro">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column [maxWidth]="105" name="Ação" headerClass="align-right" cellClass="align-right">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <mat-icon title="Comparar alterações">compare_arrows</mat-icon>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>

</div>
