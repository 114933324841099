import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-details-progress',
  templateUrl: './user-progress.component.html',
  styleUrls: ['./user-progress.component.scss']
})
export class SettingsUserDetailsProgressComponent {

  @Input() modules: any; // ModulePreview;
  @Input() tracks: any; // TrackPreview;
  @Input() events: any; // EventPreview;

}
