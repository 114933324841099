import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CreateModuleProgressGuard implements CanActivate {
  constructor(private _userService: SettingsUsersService, private _auth: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const moduleId = route.paramMap.get('moduleId');
    const userId = this._auth.getLoggedUserId();
    const progressKey = `${userId}:${moduleId}`;
    const stringToParse = localStorage.getItem(LocalStorageService.key.userModuleProgress);
    const progress: string[] = JSON.parse(stringToParse) || [];

    const hasProgress = progress.includes(progressKey);

    return hasProgress ? of(true) :
      this._userService.createModuleProgres({ moduleId, userId }).pipe(map(result => {
        localStorage.setItem(LocalStorageService.key.userModuleProgress, JSON.stringify([...progress, progressKey]));
        return result.success;
      }));
  }
}
