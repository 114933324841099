<h3>A MUDANÇA DO EVENTO DO ALUNO ESTÁ QUASE COMPLETA!</h3>
<p class="subtitle">
  Consulte as datas dos eventos para mudança de evento.
</p>

<div class="form">
  <div class="question">
    <p class="index">1</p>
    <div class="answer">
      <p>Dia/Horário do evento atual do Aluno:</p>
      <mat-form-field>
        <input matInput readonly>
        {{ currentSchedule.eventDate | date : 'dd/MM/yyyy'}}
      </mat-form-field>
    </div>
  </div>
  <div class="question">
    <p class="index">2</p>
    <div class="answer">
      <p>Dia/Horário à ser mudado:</p>
      <mat-form-field>
        <mat-select
          [(ngModel)]="selectedScheduleId"
          [placeholder]="eventData?.allSchedules?.length ?  'Selecione o evento à ser realocado': 'Não há outros eventos agendados'">
          <mat-option
            *ngFor="let schedule of eventData.allSchedules"
            [value]="schedule.id">
            {{ schedule.eventDate | date : 'dd/MM/yyyy'}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="actions">
  <button class="outline" (click)="dismiss()">
    Cancelar
  </button>
  <button class="btn-test" (click)="changeSchedule()">
    Salvar e Mudar Evento
  </button>
</div>
