import {
  Component,
  Input,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Content, VideoReference } from '../../../models/content.model';
import { VideoMarker } from './marker.model';
import Player, { Options } from '@vimeo/player';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { VideoTagDialogComponent } from './video-tag-dialog/video-tag-dialog.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TimeUpdate } from './Models/PlayerEvent.model';
import { TokenRefresherService } from '../../../shared/services/refresh-token.service';

@Component({
  selector: 'app-content-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoContentComponent extends NotificationClass implements OnInit, OnDestroy {

  public moduleTags: any[] = [];
  public contentTags: any[] = [];
  public moduleTagValuations: any[] = [];

  public tooltip1: boolean = false;
  public tooltip2: boolean = false;

  private _percentageToFinish = 0.90;
  private _hasFinishedContent: boolean;

  @Input() readonly canCreateTags: boolean = false;
  @Input() readonly resumedView?: boolean = false;
  @Input() readonly preventPointerEvents?: boolean = false;

  @Input() set setContent(content: Content) {
    this.markers = this._setVideoMarkers(content);
    this.content = content;
    if (content.viewPercentCompleteContent !== null && content.viewPercentCompleteContent !== 90)
      this._percentageToFinish = content.viewPercentCompleteContent / 100;
    if (content) this.loadPlayer(content);
    this._watchedVideo = false;
  }

  @Input() set setPosition(position: number) {
    if (position) this.setVideoPosition(position);
  }

  @Input() set setModuleTags(moduleTags: any[]) {

    if (moduleTags && moduleTags.length > 0) {
      this.moduleTags = moduleTags;
      const params = this._route.snapshot.params;
      const userId = this._auth.getLoggedUserId();
      const subjectId = params['subjectId'];
      const contentId = this.content.id;
      const contentUserTags = [];
      this.contentTags = [];
      for (let i = 0; i < moduleTags.length; i++) {
        const moduleTag = moduleTags[i];
        if (
          moduleTag.userId === userId &&
          moduleTag.subjectId === subjectId &&
          moduleTag.contentId === contentId
        ) {
          contentUserTags.push(moduleTag);
        } else if (
          moduleTag.subjectId === subjectId &&
          moduleTag.contentId === contentId
        ) {
          this.contentTags.push(moduleTag);
        }
      }
      contentUserTags.forEach((contentTag: any) => {
        if (contentTag.concept && contentTag.concept.positions) {
          const mark = contentTag.concept.positions.map((pos: number) => new VideoMarker(contentTag.concept.name, pos))[0];
          mark.tag = true;
          this.markers.push(mark);
        }
      });
    }
  }

  @Input() set setModuleTagValuations(moduleTagValuations: any[]) {
    if (moduleTagValuations && moduleTagValuations.length > 0) {
      this.moduleTagValuations = moduleTagValuations;
    }
  }

  @Input() set setHelpCreateTags(active: boolean) {
    if (active) {
      this.setTooltip(1);
    }
  }

  @Output() saveVideoPlayedAction: EventEmitter<string> = new EventEmitter();
  @Output() saveVideoFinishedAction: EventEmitter<string> = new EventEmitter();
  @Output() updateModuleTags: EventEmitter<any> = new EventEmitter();

  private _player: Player;
  public get player(): Player {
    return this._player;
  }
  public set player(player: Player) {
    this._player = player;
    if (player) this._handleVideoPlayed(player);
  }

  public markers: Array<VideoMarker> = [];
  public content: Content;
  private _watchedVideo: boolean = false;
  private _hasPendingVideoReload = false;
  private _contentEmitter$ = new Subject<string>();


  @ViewChild('timelineBar') timelineBar: any;
  @HostListener('document:click', ['$event']) onMouseEnter(element: any) {
    if (this.canCreateTags) {
      const targetElement = element.target;
      const clickedInside = this.timelineBar && this.timelineBar.nativeElement.contains(targetElement);
      if (clickedInside) {
        const clickPosition = element.clientX - targetElement.offsetLeft;
        const elementWidth = targetElement.offsetWidth;
        const time = this.content.duration * (clickPosition / elementWidth);
        this.OpenTagDialog(time);
      }
    }
  }

  constructor(
    private _sharedService: SharedService,
    private _router: Router,
    private _route: ActivatedRoute,
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _auth: AuthService,
    private _elementRef: ElementRef,
    private _tokenRefresher: TokenRefresherService
  ) {
    super(_snackBar);
  }

  ngOnInit(): void {
    this._contentEmitter$.pipe(distinctUntilChanged())
      .subscribe(contentId => {
        this.saveVideoFinishedAction.emit(contentId);
        this._hasFinishedContent = true;
      });
    this._tokenRefresher.startTokenRefresh();
  }

  public getMarkerPosition(position: number, offset: number = 0): string {
    
    if (!this.content) return '';

    const time = (700 * position) / this.content.duration;
    return time + offset + 'px';
  }

  private clearCues(data: any) {
    this.markers.forEach((marker: VideoMarker) => {
      marker.hovered = false;
    });
  }

  private setCues(data) {
    this.markers.forEach((marker: VideoMarker) => {
      marker.hovered = false;
    });
    this.markers[data.data].hovered = true;
  }

  public setVideoPosition(position: number) {
    if (this.player) this.player.setCurrentTime(position);
  }

  private _setVideoMarkers(content: Content): Array<VideoMarker> {
    const markers = [];
    content.concepts.forEach((concept: VideoReference) => {
      if (concept.positions) {
        const mark = concept.positions.map((pos: number) => new VideoMarker(concept.name, pos))[0];
        markers.push(mark);
      }
    });
    return markers;
  }

  private _handleVideoPlayed(player: Player) {
    player.on('play', () => {
      this._tokenRefresher.startTokenRefresh();
      if (!this._watchedVideo) {
        this._watchedVideo = true;
        this.saveVideoPlayedAction.emit(this.content.id);
      }
    });
    player.on('pause', () => {this.saveVideoPlayedAction.emit(this.content.id); this._tokenRefresher.stopTokenRefresh();});
    player.on('ended', (data) => { this.clearCues(data); this._tokenRefresher.stopTokenRefresh();});
    player.on('cuepoint', (data) => { this.setCues(data); });
    player.on('loaded', () => {
      const frame = document.querySelector('iframe');
      if (frame) frame.style.height = '100%';
      frame.style.width = '100%';
    });

    player.on('timeupdate', (data: TimeUpdate) => {
      if (data.percent >= this._percentageToFinish && !this._hasFinishedContent && !this.content.watched) {
        this._contentEmitter$.next(this.content.id);
      }
    });

    this._sharedService.forumQuestion.subscribe(() => {
      player.pause();
      player.getCurrentTime().then(seconds => {
        const date = new Date(null);
        date.setSeconds(seconds);
        const result = date.toISOString().substr(14, 5);
        this._sharedService.forumQuestionResponse.next(result);
      });
    });
  }

  private _showVideoException(error: Error) {
    const params = this._route.snapshot.params;
    const contentId = this.content.id;
    const subjectId = params['subjectId'];
    const moduleId = params['moduleId'];
    const titleException = 'Não foi possível carregar o vídeo.';
    const ref = this.notify(titleException, 'Ir para a página de suporte', { duration: 6000 });

    const message = `Título do conteúdo: ${this.content.title}\ncontentId: ${contentId}\nsubjectId: ${subjectId}\nmoduleId: ${moduleId}`;
    const errorStack = `${message}\n${error.stack}`;
    ref.afterOpened().subscribe(() => {
      error.stack = errorStack;
      throw error;
    });

    ref.onAction().subscribe(() =>
      this._router.navigate(['atendimento'], {
        state: { error, errorStack, title: titleException },
      })
    );
  }

  private _getIdFromVimeoURL(url: string): number {
    return +/(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
  }

  private async loadPlayer(content: Content) {
    const initSetup = (player: Player) =>
      player
        .ready()
        .then(async (response) => await this._setupVideoConfiguration(this.player))
        .catch((error) => this._showVideoException(error));

    if (!this.player) {
      const options: Options = { url: content.value, height: 470 };
      const element = this._elementRef.nativeElement.querySelector('#videoContent');
      const returnPlayer = new Player(element, options);
      this.player = returnPlayer;
      await initSetup(this.player);

    } else {
      const id = this._getIdFromVimeoURL(this.content.value);

      if (!this._hasPendingVideoReload) {
        this._hasPendingVideoReload = true;
        await this.player
          .loadVideo(+id)
          .then(() => {
            this.player.ready().then(response => {
              this.player.off('ended');
              this.player.off('cuepoint');
            });
          })
          .catch((error) => this._showVideoException(error))
          .finally(() => {
            this._hasPendingVideoReload = false;
          });

        await initSetup(this.player);
      }
    }
  }

  private async _setupVideoConfiguration(player: Player) {
    const cuePoints = await player.getCuePoints();
    cuePoints.forEach(async (cue) => {
      await player.removeCuePoint(cue.id);
    });

    this.markers = this.markers.sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });

    this.markers.forEach((marker: VideoMarker, idx) => {
      if (!(marker.position > this.content.duration)) player.addCuePoint(marker.position as any, idx as any);
    });

    if (this.content.initialValue) {
      const positionInitial = this.markers.filter(m => m.concept === this.content.initialValue);
      if (positionInitial && positionInitial[0])
        this.setVideoPosition(positionInitial[0].position);
    }

    this._hasFinishedContent = false;
  }

  public OpenTagDialog(time: number = null) {
    const params = this._route.snapshot.params;
    const subjectId = params['subjectId'];
    const moduleId = params['moduleId'];
    const dialogRef = this._dialog.open(VideoTagDialogComponent, {
      width: '600px',
      data: {
        content: this.content,
        subjectId: subjectId,
        moduleId: moduleId,
        userId: this._auth.getLoggedUserId(),
        moduleTags: this.contentTags,
        moduleTagValuations: this.moduleTagValuations,
        time: time
      }
    });

    dialogRef.afterClosed().subscribe((update: any) => {
      if (update) {
        this.updateModuleTags.emit();
      }
    });
  }

  public setTooltip(index: number = 0) {
    if (index === 0) {
      this.tooltip1 = false;
      this.tooltip2 = false;
    } else if (index === 1) {
      this.tooltip1 = true;
      this.tooltip2 = false;
    } else if (index === 2) {
      this.tooltip1 = false;
      this.tooltip2 = true;
    }
  }
  ngOnDestroy(){
    this._tokenRefresher.stopTokenRefresh();
  }
}
