import { NgModule, ModuleWithProviders } from '@angular/core';

import { PipesModule } from './pipes/pipes.module';
import { ValidationModule } from './validation/validation.module';
import { ValidationService } from './validation/validation.service';

@NgModule({
  declarations: [],
  imports: [
    PipesModule,
    ValidationModule
  ],
  exports: [
    PipesModule,
    ValidationModule
  ],
  providers: [
    ValidationService
  ]
})
export class NgUtilModule {
  static forRoot(): ModuleWithProviders<NgUtilModule> {
    return {
      ngModule: NgUtilModule,
      providers: [
        ValidationService
      ]
    };
  }
}
