<h1>
  {{ moduleTitle !== '' ? moduleTitle : 'NOVO MÓDULO' }}
</h1>
<div class="subheader">
  <div class="draft-title" [ngClass]="{ 'opened': showDraftOptions, 'disabled': !newModule?.isDraft }">
    <span (click)="showDraftOptions = !showDraftOptions" *ngIf="newModule?.isDraft">
      MODIFICAÇÕES EM RASCUNHO &nbsp;&nbsp;&nbsp;&nbsp;
      <img src="./assets/img/arrow-back-white.png" />
    </span>
    <span *ngIf="!newModule?.isDraft">
      SEM MODIFICAÇÕES
    </span>
    <ul class="draft-options" *ngIf="showDraftOptions">
      <li (click)="publishDraftChanges()">
        PUBLICAR ALTERAÇÕES
      </li>
      <li (click)="rejectDraftChanges()">
        REJEITAR ALTERAÇÕES
      </li>
    </ul>
  </div>
</div>

<div class="new-module inner-content">
  <mat-horizontal-stepper #stepper [ngClass]="{ 'no-action': !allowEditing }"
    (selectionChange)="stepChanged($event, false)">
    <mat-step>
      <ng-template matStepLabel>
        Informações
      </ng-template>
      <app-new-module-module-info #moduleInfo [module$]="newModule$" [showCertification]="false"
        (setModuleInfo)="setModuleInfo($event)"></app-new-module-module-info>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Preview
      </ng-template>
      <app-new-module-video #moduleVideo [module$]="newModule$" (setModuleVideo)="setModuleVideo($event)">
      </app-new-module-video>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Materiais
      </ng-template>
      <app-new-module-support-materials #moduleMaterials [module$]="newModule$"
        (addSupportMaterials)="addSupportMaterials($event)"></app-new-module-support-materials>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Requisitos
      </ng-template>
      <app-new-module-requirements #moduleRequirements [module$]="newModule$" [levels]="levels"
        (setRequirements)="setRequirements($event)"></app-new-module-requirements>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Pesos Itens
      </ng-template>
      <app-new-modules-weight #moduleWeight (addModulesWeights)="addModulesWeights($event)" [module$]="newModule$"
        [totalWeight]="totalWeight"></app-new-modules-weight>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Assuntos
      </ng-template>
      <app-new-module-subjects #moduleSubjects [module$]="newModule$" [setLevels]="levels"
        (addSubjects)="addSubjects($event)" (updateImportSubjects)="updateImportSubjects($event)"
        [createNewModuleDraft]="createNewModule.bind(this)">
      </app-new-module-subjects>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Conteúdos
      </ng-template>
      <app-new-module-contents #moduleContents [module$]="newModule$" (addContents)="addContents($event)"
        (updateImportSubjects)="updateImportSubjects($event)" [createNewModuleDraft]="createNewModule.bind(this)">
      </app-new-module-contents>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        E-commerce
      </ng-template>
      <app-module-ecommerce #ecommerce [module$]="newModule$" (manageEcommerceInfo)="manageEcommerceInfo($event)">
      </app-module-ecommerce>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Perguntas
      </ng-template>
      <app-new-module-questions #moduleQuestions [module$]="newModule$" [newModule2]="newModule2" [levels]="levels"
        [updateQuestions]="upadateModuleQuestions()" (addQuestions)="addQuestions($event)"
        (loadQuestionsEvent)="loadQuestions($event)" [createNewModuleDraft]="createNewModule.bind(this)">
      </app-new-module-questions>
    </mat-step>

  </mat-horizontal-stepper>

  <div class="footer">
    <button class="previous" type="button" mat-button [hidden]="stepIndex === 0" (click)="saveStep(true, false)">
      voltar
    </button>
    <button class="save" type="button" mat-button (click)="saveStep(false, false)">
      Salvar {{ stepIndex === 8 ? 'e Finalizar' : '' }}
    </button>
    <button class="next" type="button" mat-button [disabled]="loading" [hidden]="stepIndex === 8"
      (click)="saveStep(true, true)">
      continuar e salvar
    </button>
  </div>
</div>
