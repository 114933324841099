import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-module-question-approval-dialog',
  templateUrl: './module-question-approval-dialog.component.html',
  styleUrls: ['./module-question-approval-dialog.component.scss']
})
export class ModuleQuestionApprovalDialogComponent {

  public level: number = -1;
  constructor(
    public dialogRef: MatDialogRef<ModuleQuestionApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
