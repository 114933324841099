import { ModulePreview } from "../previews/module.interface";
import { TrackPreview } from "../previews/track.interface";

export class Course{
    public title: string;
    public id: string;
    public page: number;
    public count: number;
    public programs: Array<TrackPreview | ModulePreview>;
    constructor() {
        this.title = '';
        this.page = 1;
        this.count = 0;
        this.programs = [];
    }
}

