import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, NavigationBackModule],
  exports: [HeaderComponent],
  providers: [],
})
export class HeaderModule { }
