import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EStudyPlanItem } from 'src/app/models/enums/study-plan-item.enum';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { UserPreview } from 'src/app/models/previews/user.interface';

import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { environment } from 'src/environments/environment';
import { SettingsModulesService } from '../../../_services/modules.service';
import { StudyPlanService } from '../../../_services/study-plan.service';
import { SettingsUsersService } from '../../../_services/users.service';
import { Econnect } from './Enum/connect.enum';
import { ISelectedItem } from './interface/selected-item.interface';

@Component({
  selector: 'app-connect-dialog',
  templateUrl: './connect-dialog.component.html',
  styleUrls: ['./connect-dialog.component.scss']
})
export class ConnectDialogComponent implements OnInit {

  public replecement = environment.replecement;
  public searchValue: string = '';
  private searchSubject: Subject<string> = new Subject();
  public showSearchFullscren: boolean = false;
  public results: Array<ModulePreview> = [];
  public tracks: Array<TrackPreview> = [];
  public selectedItem: Array<ISelectedItem> = [];
  public inputPlacedolher: string = '';
  public EnumStudyPlanItem = EStudyPlanItem;
  constructor(
    public dialogRef: MatDialogRef<ConnectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: Econnect,
      subject: Subject<{ type: Econnect, data: ISelectedItem, action: 'add' | 'remove' }>,
      notIn: string[],
      studtPlanId: string
    },
    private _modulesService: SettingsModulesService,
    private _tracksService: ContentTracksService,
    private _studyPlanService: StudyPlanService) { }

  ngOnInit() {
    this.inputPlacedolher = this.data.type === Econnect.users ? 'Digite o nome do usuário ou email' : 'Digite o nome do módulo ou trilha';
    this.data.notIn = this.data.notIn || [];
    this._setSearchSubscription();
    setTimeout(() => { this.callLoadFunction(); }, 0);
  }

  private _setSearchSubscription() {
    this.searchSubject.pipe(debounceTime(500))
      .subscribe((searchTextValue) => {
        this.callLoadFunction(searchTextValue);
      });
  }

  private _searchModules(searchValue: string = null) {
    this._modulesService.getPagedFilteredModulesList(
      1, 4, searchValue, this.data.notIn
    ).subscribe((response) => {
      this._loadTracks(searchValue);
      this.results = response.data.modules.map(module => Object.assign(module, { type: EStudyPlanItem.module }));
    });
  }

  private _loadTracks(searchValue: string = null): void {
    this._tracksService.getPagedFilteredTracksList(
      1, 4, searchValue, null, null, null, this.data.notIn
    ).subscribe((response) => {
      const tracks = response.data.tracks.map(track => Object.assign(track, { type: EStudyPlanItem.track }));
      this.results = [...this.results, ...tracks];
    });
  }
  private _loadUsers(searchValue: string = null): void {
    this._studyPlanService.GetUsersPaged(
      { studyPlanId: this.data.studtPlanId, page: 1, pageSize: 8, searchValue: searchValue, notIn: this.data.notIn }
    ).subscribe((response) => {
      this.results = response.data.userItems;
    });
  }

  public doSearch(value: string): void {
    this.searchSubject.next(value);
  }

  public selectItem(item: ModulePreview | TrackPreview | UserPreview) {
    this.data.notIn = [...this.data.notIn, item.id];
    const selectedItem = { id: item.id, type: item['type'] } as ISelectedItem;
    if (this.data.type === Econnect.users) {
      selectedItem.title = (item as UserPreview).name;
      selectedItem.imageUrl = (item as UserPreview).imageUrl;
    } else {
      selectedItem.title = (item as ModulePreview).title;
    }
    this.data.subject.next({ type: this.data.type, data: selectedItem, action: 'add' });
  }

  public callLoadFunction(searchValue = null) {
    if (this.data.type === Econnect.users) {
      this._loadUsers(searchValue);
    } else {
      this._searchModules(searchValue);
    }
  }
}
