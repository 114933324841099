import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CockpitComponent } from './cockpit.component';
import { CockpitHeaderComponent } from './cockpit-header/cockpit-header.component';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { FormsModule } from '@angular/forms';
import { EffortMetricComponent } from './cockpit-metrics/effort-metric/effort-metric.component';
import { PerformanceMetricComponent } from './cockpit-metrics/performance-metric/performance-metric.component';
import { CockpitRankingComponent } from './cockpit-ranking/cockpit-ranking.component';
import { CockpitMenuComponent } from './cockpit-menu/cockpit-menu.component';
import { CockpitTitleComponent } from './components/cockpit-title/cockpit-title.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffortProgressComponent } from './cockpit-metrics/effort-metric/effort-progress/effort-progress.component';
import { ProgressBarModule } from 'src/app/shared/components/layout/progress-bar/progress-bar.module';
import { TableRankingComponent } from './cockpit-ranking/table-ranking/table-ranking.component';
import { CockpitMenuCardComponent } from './cockpit-menu/cockpit-menu-card/cockpit-menu-card.component';
import { CockpitStudentTrackComponent } from './cockpit-student-display/cockpit-student-track/cockpit-student-track.component';
import { CockpitStudentModuleComponent } from './cockpit-student-display/cockpit-student-module/cockpit-student-module.component';
import { DialogRankingComponent } from './cockpit-ranking/dialog-ranking/dialog-ranking.component';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { CockpitModuleClassComponent } from './cockpit-students-class/cockpit-module-class/cockpit-module-class.component';
import { CockpitTrackClassComponent } from './cockpit-students-class/cockpit-track-class/cockpit-track-class.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CockpitService } from '../_services/cockpit.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CockpitRankingCardComponent } from './cockpit-student-display/cockpit-ranking-card/cockpit-ranking-card.component';
import { CockpitWarningComponent } from './cockpit-student-display/cockpit-warning/cockpit-warning.component';
import { MatIconModule } from '@angular/material/icon';
import {
  EffortProgressStudentsComponent
} from './cockpit-metrics/effort-metric/effort-progress/effort-progress-students/effort-progress-students.component';
import { CockpitDetailsComponent } from './components/cockpit-details/cockpit-details.component';
import { CockpitChartComponent } from './components/cockpit-chart/cockpit-chart.component';
import {
  CockpitChartPerformanceComponent
} from './components/cockpit-chart/cockpit-chart-performance/cockpit-chart-performance.component';
import {
  EffortPerformanceStudentsComponent
} from './cockpit-metrics/performance-metric/effort-performance-students/effort-performance-students.component';
import { CockpitModulesProgressComponent } from './cockpit-student-display/cockpit-modules-progress/cockpit-modules-progress.component';
import { UnderstandContentComponent } from './cockpit-metrics/understand-content/understand-content.component';
import { FilterRankingComponent } from './cockpit-ranking/filter-ranking/filter-ranking.component';
import { NgxMaskModule } from 'ngx-mask';
import { CockpitButtonsComponent } from './components/cockpit-buttons/cockpit-buttons.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
    declarations: [
        CockpitComponent,
        CockpitHeaderComponent,
        EffortMetricComponent,
        PerformanceMetricComponent,
        CockpitRankingComponent,
        CockpitMenuComponent,
        CockpitTitleComponent,
        EffortProgressComponent,
        TableRankingComponent,
        CockpitMenuCardComponent,
        CockpitStudentTrackComponent,
        CockpitStudentModuleComponent,
        DialogRankingComponent,
        CockpitModuleClassComponent,
        CockpitTrackClassComponent,
        CockpitRankingCardComponent,
        CockpitWarningComponent,
        EffortProgressStudentsComponent,
        CockpitDetailsComponent,
        CockpitChartComponent,
        EffortPerformanceStudentsComponent,
        CockpitChartPerformanceComponent,
        CockpitModulesProgressComponent,
        UnderstandContentComponent,
        FilterRankingComponent,
        CockpitButtonsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatTooltipModule,
        ProgressBarModule,
        ListSearchModule,
        RouterModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        ScrollingModule,
        MatIconModule,
        MaterialComponentsModule,
        NgxMaskModule,
        PipesModule
    ],
    providers: [
        CockpitService
    ]
})
export class CockpitModule { }
