import { Component, Input } from '@angular/core';
import { Item } from 'src/app/shared/components/collapsed-list/item/models/item.model';

@Component({
  selector: 'app-item',
  template: `
    <p class="description">
        {{ item?.title }}
        <small *ngIf="item?.subtitle">{{ item?.subtitle }}</small>
    </p>
    <p class="count"  *ngIf="item?.count"> {{ item.count }} </p>
 `,
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {

  @Input() readonly item: Item;

}
