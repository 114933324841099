import { Component, Input, ViewChild } from '@angular/core';
import { Requirement } from '../../../settings/modules/new-module/models/new-requirement.model';
import { Level } from '../../../models/shared/level.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { TestTrack } from 'src/app/models/valuation-test.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { FilterTrackModule } from 'src/app/pages/report/filters/filter-track-module/models/filter-track-module.model';
import { CategoryFilterSearchOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { FilterTrackModuleComponent } from 'src/app/pages/report/filters/filter-track-module/filter-track-module.component';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { isNil, find, isEmpty } from 'lodash';
import { environment } from 'src/environments/environment';
import { BackendResponse } from '@tg4/http-infrastructure';

@Component({
  selector: 'app-report-learning-assessment-objects',
  templateUrl: './report-learning-assessment-objects.component.html',
  styleUrls: ['./report-learning-assessment-objects.component.scss']
})
export class ReportLearningAssessmentObjectsComponent extends NotificationClass {

  @Input() requirement: Requirement;
  @Input() levels: Array<Level>;
  @Input() last: boolean = false;
  @ViewChild('FilterComponent', { static: true }) public filterComponent: FilterTrackModuleComponent;

  public replacement = environment.replecement;
  public modules: Array<TestTrack> = [];
  public get filter() { return this.filterComponent.filter; }

  constructor(
    private _reportService: ReportsService,
    protected _snackBar: MatSnackBar,
  ) {
    super(_snackBar);
  }

  public exportBdqStatistics() {
    const userId = this.filter.student && this.filter.student.id;
    this._createReport(() =>
      this._reportService
        .getTrackAnswers(this._getCategories(this.filter), userId)
        .subscribe(response => this._handleError(response))
    );
  }

  public exportAtypicalMovements() {
    const userId = this.filter.student && this.filter.student.id;
    this._createReport(() =>
      this._reportService
        .getAtypicalMovements(this._getCategories(this.filter), userId)
        .subscribe(response => this._handleError(response))
    );

  }

  private _handleError(response) {
    const hasError = !isNil(response) && !isEmpty(response.errors);
    if (hasError) this.notify(response.errors[0]);
  }

  private _createReport(callBack: Function) {
    const hasCategories = !isNil(this.filter.categories) || !isEmpty(this.filter.categories);
    const filterCategory = find(this.filter.categories, category => category.columnName === 'track.id');
    const hasContentNames = filterCategory.contentNames;

    if (hasCategories && isEmpty(hasContentNames)) {
      this.notify('Selecione uma trilha para exportar os dados.');
      return;
    } else {
      this.notify('A exportação pode levar alguns minutos');
    }

    callBack();
  }

  private _getCategories(filter: FilterTrackModule) {
    if (!filter) return;

    return filter.categories.map<CategoryFilterSearchOption>(category => ({
      columnName: category.columnName,
      contentNames: category.contentNames.map(c => c.id)
    }));

  }

}
