import {
  CategoryFilter,
  CategoryFilterOption,
  CategoryFilterSearch,
  CategoryFilterSearchOption,
} from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { CategoryFilterEnum } from 'src/app/models/enums/category-filter.enum';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { isNil } from 'lodash';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';

export class ReportFilter extends NotificationClass {

  public categoryFilters: Array<CategoryFilter> = [];
  protected selectedFilters: Array<CategoryFilterSearchOption> = [];
  private _searchValue: string = '';

  constructor(
    protected _reportSertivce: ReportsService,
    protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  public removeFilter(filter: CategoryFilter, opt: CategoryFilterOption) {


    if (filter.category === CategoryFilterEnum.Tracks) {
      this._resetOptionsFilterModule();
      this._resetOptionsFilterSubject();
    }

    if (filter.category === CategoryFilterEnum.Modules) {
      this._resetOptionsFilterSubject(opt.id);
      const filterSubjectIndex = this.categoryFilters.findIndex(x => x.category === CategoryFilterEnum.Subjects && !isNil(x.parents));
      const filterSubject = this.categoryFilters[filterSubjectIndex];
      if (filterSubject) {
        this.setupUpdateFilter(filterSubject, '', true);
      }
    }

    const indexOptions = filter.options.findIndex(x => x.id === opt.id);
    if (indexOptions >= 0) {
      filter.options[indexOptions].checked = false;
    }

  }

  public removeFilterNoOptions(filter: CategoryFilter, opt: CategoryFilterOption) {
    const indexOptions = filter.options.findIndex(x => x.id === opt.id);
    if (indexOptions >= 0) {
      filter.options[indexOptions].checked = false;
    }
  }

  public filterNotify(mensage: string): void {
    this.notify(mensage);
  }

  public setupUpdateFilter(filter: CategoryFilter, searchValue: string = this._searchValue, hasParent = false) {

    if (filter.category === CategoryFilterEnum.Tracks) {
      this._resetOptionsFilterModule();
      this._resetOptionsFilterSubject();
    }

    this._searchValue = searchValue;
    filter.page = 1;
    const filterSearch: CategoryFilterSearch = {
      value: this._searchValue,
      filter: filter,
      page: filter.page,
      prop: filter.isAlternate ? 'title' : 'name'
    };
    if (
      (filter.category === CategoryFilterEnum.Modules && filter.parent && filter.parent.key === null) ||
      (filter.category === CategoryFilterEnum.Subjects && filter.parents && filter.parents.length === 0)
    ) {
      return;
    }
    this._searchFilters(filterSearch, hasParent);
  }

  public setupUpdateFilterNoOptions(filter: CategoryFilter, searchValue: string = this._searchValue) {

    this._searchValue = searchValue;
    filter.page = 1;
    const filterSearch: CategoryFilterSearch = {
      value: this._searchValue,
      filter: filter,
      page: filter.page,
      prop: filter.isAlternate ? 'title' : 'name'
    };
    if (filter.parent && filter.parent.key === null) return;
    this._searchFilters(filterSearch);
  }

  public searchMoreItens(filter: CategoryFilter) {
    this.setupUpdateFilter(filter);
  }

  public maxDateFilter(currentDate: Date): boolean {
    const today = new Date();
    return currentDate <= today;
  }

  public inputChange(value: string, opt: CategoryFilterOption) {
    opt.description = value;
    opt.id = value;
  }

  public moreInputs(filter: CategoryFilter) {
    filter.options.push({ checked: false, id: '', description: '' });
  }

  public updateFilterOptions(filter: CategoryFilter, option: CategoryFilterOption) {
    if (filter.filterColumn === 'track.id') {
      const filterModuleIndex = this.categoryFilters.findIndex(x => x.filterColumn === 'module.id' && !isNil(x.parent));
      const optionIndex = filter.selectedOptions.findIndex(x => x.id === option.id);
      const hasOptions = optionIndex !== -1;
      if (hasOptions) {
        const filterModule = this.categoryFilters[filterModuleIndex];
        filter.selectedOptions[optionIndex].checked = hasOptions;
        if (filterModule) {
          filterModule.parent.key = option.id;
          this.setupUpdateFilter(filterModule, '', true);
        }
      }
    } else if (filter.filterColumn === 'module.id') {
      const filterSubjectIndex = this.categoryFilters.findIndex(x => x.filterColumn === 'subject.id' && !isNil(x.parents));
      const optionIndex = filter.selectedOptions.findIndex(x => x.id === option.id);
      const hasOptions = optionIndex !== -1;
      if (hasOptions) {
        const filterSubject = this.categoryFilters[filterSubjectIndex];
        filter.selectedOptions[optionIndex].checked = hasOptions;
        if (filterSubject) {
          filterSubject.parent.key = option.id;
          const parentIndex = filterSubject.parents.findIndex(x => x.key === option.id);
          if (parentIndex === -1) {
            filterSubject.parents.push({
              category: filter.category,
              key: option.id
            });
          }
          this.setupUpdateFilter(filterSubject, '', true);
        }
      } else {
        const filterSubject = this.categoryFilters[filterSubjectIndex];
        this._resetOptionsFilterSubject(option.id);
        this.setupUpdateFilter(filterSubject, '', true);
      }
    }
  }

  public updateFilterNoOptions(filter: CategoryFilter, option: CategoryFilterOption) {
    const optionIndex = filter.selectedOptions.findIndex(x => x.id === option.id);
    const hasOptions = optionIndex !== -1;

    if (hasOptions) {
      filter.selectedOptions[optionIndex].checked = hasOptions;
    }
  }

  protected updateFilters(filters: Array<CategoryFilter>) {
    for (let i = 0; i < filters.length; i++) {
      if (isNil(this.selectedFilters[i])) {
        this.selectedFilters[i] = { contentNames: undefined, columnName: undefined };
      }
      this.selectedFilters[i].contentNames = [];
      filters[i].selectedOptions.forEach(opt => {
        const value = filters[i].demandsId ? opt.id : opt.description;
        this.selectedFilters[i].contentNames.push(value);
      });
    }
  }

  private _resetOptionsFilterModule() {
    const filterModuleIndex = this.categoryFilters.findIndex(x => x.category === CategoryFilterEnum.Modules && !isNil(x.parent));
    const filterModule = this.categoryFilters[filterModuleIndex];
    if (filterModule) {
      filterModule.parent.key = null;
      filterModule.options = [];
      filterModule.selectedOptions = [];
    }
  }

  private _resetOptionsFilterSubject(id: string = null) {
    const filterSubjectIndex = this.categoryFilters.findIndex(x => x.category === CategoryFilterEnum.Subjects && !isNil(x.parents));
    const filterSubject = this.categoryFilters[filterSubjectIndex];
    if (filterSubject) {
      if (isNil(id)) {
        filterSubject.parent.key = null;
        filterSubject.parents = [];
        filterSubject.options = [];
        filterSubject.selectedOptions = [];
      } else {
        if (filterSubject.parents) {
          const parentIndex = filterSubject.parents.findIndex(x => x.key === id);
          if (parentIndex !== -1) {
            filterSubject.parents.splice(parentIndex, 1);
            if (filterSubject.parents.length === 0) {
              filterSubject.parent.key = null;
              filterSubject.parents = [];
              filterSubject.options = [];
              filterSubject.selectedOptions = [];
            } else {
              filterSubject.parent.key = filterSubject.parents[0].key;
              filterSubject.selectedOptions = [];
            }
          } else {
            filterSubject.parent.key = null;
            filterSubject.parents = [];
            filterSubject.options = [];
            filterSubject.selectedOptions = [];
          }
        }
      }
    }
  }

  private _searchFilters(search: CategoryFilterSearch, hasParent = false) {
    return this._reportSertivce.getCategory({
      category: search.filter.category,
      page: search.page,
      pageSize: 10,
      parent: search.filter.parent,
      parents: search.filter.parents,
      searchTerm: search.value,
      isAlternate: search.filter.isAlternate
    }).subscribe(response => this._updateFilter(search, response, hasParent));
  }


  private _updateFilter(search: CategoryFilterSearch, response, hasParent = false): void {

    const filterIndex = hasParent
      ? this.categoryFilters.findIndex(x => x.name === search.filter.name && !isNil(x.parent))
      : this.categoryFilters.findIndex(x => x.name === search.filter.name && isNil(x.parent));

    if (filterIndex !== -1) {
      this.categoryFilters[filterIndex].page = search.filter.page;
      this.categoryFilters[filterIndex].itemsCount = response.data.itemsCount;
      this.categoryFilters[filterIndex].options = search.page > 1 ? this.categoryFilters[filterIndex].options : [];
      for (let index = 0; index < response.data.items.length; index++) {
        this.categoryFilters[filterIndex].options.push({
          id: response.data.items[index].id,
          description: response.data.items[index][search.prop],
          checked: this.categoryFilters[filterIndex].selectedOptions.findIndex(x => x.id === response.data.items[index].id) !== -1
        });
      }
      this.categoryFilters[filterIndex].maxLength = this.categoryFilters[filterIndex].options.length;
    }

  }


}
