<div class="sidebar">
  <div class="about-instructor" [ngClass]="{ 'no-pad': !module || !module.instructorMiniBio }">
    <img [src]="module?.instructorImageUrl" />
    <p class="instructor">
      <span>{{ module?.instructor }}</span><br>
      <span *ngFor="let extraInstructor of module?.extraInstructors; let index = index">
        {{ (module.extraInstructors.length > 1 && index === module.extraInstructors.length - 1) ? 'e ' : '' }}{{ extraInstructor.name }}{{ (module.extraInstructors.length > 2 && index < module.extraInstructors.length - 2) ? ', ' : ''}}
      </span>
      {{ module?.instructorMiniBio ? 'Sobre' : '' }}
    </p>
    <p *ngIf="module?.instructorMiniBio && module?.instructorMiniBio.length > 600">
      {{module.instructorMiniBio | truncate:600:viewMore}}
      <a (click)="viewMore = !viewMore" >{{viewMore ? 'Ver menos' : 'Ver mais'}}</a>
    </p>
    <p *ngIf="module?.instructorMiniBio && 600 >= module?.instructorMiniBio.length">
      {{module.instructorMiniBio}}
    </p>
    <ng-container *ngIf="module?.tutors?.length > 0">
      <p class="tutor">
        <span>Tutores</span>
      </p>
      <div class="chips">
        <p class="instructor" *ngFor="let tutor of module?.tutors">
          <img class="logo" [src]="tutor.imageUrl ? tutor.imageUrl : './assets/img/user-image-placeholder.png'" />
          {{ tutor.name }}
        </p>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="isEvent && eventApplication != null">
    <div class="support-material">
      <h4>Participação Transcrita</h4>
      <img
        (click)="openParticipation()"
        src="./assets/img/search.png" />
    </div>
  </ng-container>

  <ng-container *ngIf="hasForum">
    <p class="forum">
      Fórum
    </p>
    <p class="forum-sub">
      Perguntas Deste {{isEvent ? 'Evento' : 'Módulo'}}
    </p>

    <ng-container *ngIf="forumQuestionsPreview.length > 0">
      <app-forum-question-card
        *ngFor="let question of forumQuestionsPreview"
        [question]="question"
        [hasTags]="false"
        (manageLike)="manageLike($event)"
        (goToQuestion)="goToQuestion($event)">

      </app-forum-question-card>
    </ng-container>
    <ng-container *ngIf="forumQuestionsPreview.length == 0">
      <p style="margin-bottom: 20px;">
        Ainda não há perguntas no Fórum
      </p>
    </ng-container>
    <div style="padding: 0px 20px;">
      <button style="width: 100%;" class="btn-test" (click)="goToForum()">
        Ver Fórum Completo
      </button>

      <!--
      <div class="upload-btn-wrapper">
        <button style="width: 100%;" class="btn-test btn-send"
        (click)="openFileUpload()">Enviar Arquivo</button>
        <input class="input-file" type="file" id="inputFile" hidden
        (change)="setDocumentFile($event, childFormGroup, true)"
          />
      </div>
    -->

      <button class="btn-test btn-test-style" (click)="openQuestionModal()" [disabled]="disabledQuestionBtn">
        Enviar Pergunta
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="moduleProgress?.level > 0 && module?.certificateUrl">
    <p class="support">
      Certificado
    </p>
    <div style="padding: 0px 20px;">
      <button class="btn-test btn-test-style" (click)="generateCertificatePDF()">
        Gerar Certificado
      </button>
    </div>
  </ng-container>
</div>
