<div class="inner-content">
    
    <div>
      <h4>REMUNERAÇÃO</h4>
      <span> R$: {{job?.employment?.benefits?.salary}}</span>
    </div>

    <div class="benefics">
      <h4 class="exp">BENEFÍCIOS</h4>
      <div class="cards-slider" >
        <div class="slider-grid">
          <ng-container *ngFor="let complementaryBenefit of job.employment.benefits.complementaryBenefits">
            <div class="progress-card">
                <p class="title">{{complementaryBenefit.name ? complementaryBenefit.name : '-'}}</p>
                <p style="color: rgba(0, 0, 0, 0.42);">{{complementaryBenefit.level ? complementaryBenefit.level : 'a definir'}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
</div>