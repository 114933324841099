<div class="suggested-module" [style.height]="height + 'px'" (click)="this.showRecommended && goToModule($event)"
  (mouseenter)="setHoveredValue(true)" (mouseleave)="setHoveredValue(false)"
  [ngClass]="{ 'list': viewType === 'list', 'clickable': this.showRecommended || isInstructor }">
  <app-card-tag [ngClass]="{ 'attending': this.module.hasUserProgress && this.showRecommended  }"
    [text]="module?.isExempt ? 'Isento' : reachedMaxLevel? 'Finalizado' : module?.isStarted ?  'Cursando' : 'Não iniciado' ">
  </app-card-tag>
  <img class="course-img" [src]="module.imageUrl" />

  <span class="requirement-level" *ngIf="module.requirements && module.requirements.length > 0">
    <span [ngSwitch]="module.requirements[0].requirementValue.level">
      <span *ngSwitchCase="1">NÍVEL-2</span>
      <span *ngSwitchCase="2">NÍVEL-4</span>
      <span *ngSwitchDefault>NÍVEL-1</span>
    </span>
  </span>
  <span class="requirement-text" *ngIf="module.requirements && module.requirements.length > 0">
    Nivel Necessário <br>
    <b [ngSwitch]="module.requirements[0].requirementValue.level">
      <span *ngSwitchCase="1">NÍVEL-2</span>
      <span *ngSwitchCase="2">NÍVEL-4</span>
      <span *ngSwitchDefault>NÍVEL-1</span>
    </b> <br>
    <b>{{module.requirements[0].requirementValue.percentage * 100}}%</b>
  </span>

  <div class="content">
    <p class="title" *ngIf="showText">
      {{ module.title }}
    </p>

    <p class="instructor" [ngPlural]="module?.extraInstructors?.length">
      <ng-template ngPluralCase="=0">Instrutor: {{module?.instructor}}</ng-template>
      <ng-template ngPluralCase="other">Instrutores: {{instructorLabel}}</ng-template>
    </p>

    <p class="excerpt" *ngIf="showContent">
      {{ getExcerptContent() }}
    </p>

  </div>
  <div class="buttons-and-price">
    <div class="buttons">
      <div class="button-module" *ngIf=" showButtonKnowMore">
        <button (click)="goToPageStoreUrl($event)">
          Saiba Mais
        </button>
      </div>

      <div class="dashboard-button" *ngIf="isInstructor || isAdmin">
        <button (click)="goToDashboard($event)"> Dashboard </button>
      </div>

      <div class="button-subscribe" *ngIf="!module?.hasUserProgress && showRecommended">
        <button *ngIf="module?.ecommerceProducts && module?.ecommerceProducts[0] && module?.ecommerceProducts[0].price
        && !module?.hasUserProgress && showPrice && showButtonSubscribe" (click)="goToPageEcommerceUrl($event)">
          Matricular
        </button>
      </div>
      <div class="button-subscribe-off" *ngIf="!module?.hasUserProgress && module?.ecommerceProducts && module?.ecommerceProducts[0]
          && !module?.ecommerceProducts[0].price && showButtonSubscribe">
        <p class="price">GRÁTIS</p>
        <button (click)="goToPageEcommerceUrl($event)">
          Matricular
        </button>
      </div>
    </div>
    <div class="module-price">
      <p *ngIf="module?.ecommerceProducts && module?.ecommerceProducts[0] && module?.ecommerceProducts[0].price
          && !module?.hasUserProgress && showPrice">
        {{ module.ecommerceProducts[0].price | currency:'BRL' }}
      </p>
    </div>
  </div>
  <app-progress-bar class="progress-bar" *ngIf="viewType === 'cards'"
    [currentProgress]="reachedMaxLevel ? 100: module?.progress*100"></app-progress-bar>
</div>
