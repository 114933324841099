<mat-form-field>
  <mat-select #select [value]="value" [disabled]="disabled" [compareWith]="compareFn" disableOptionCentering
    [multiple]="multiple" [placeholder]="placeholder" (openedChange)="openedChange$($event)"
    (selectionChange)="selectionChange$($event)">

    <acd-array-filter *ngIf="showFilter && select.focused" [placeholder]="filterPlaceholder"
      [displayMember]="displayMember" [data]="data" [isLoading]="isLoading" [showProgress]="true"
      (valueChange)="filterChange.emit($event)" (filteredReturn)="filterValues($event)">
    </acd-array-filter>

    <mat-select-trigger *ngIf="data?.length > 0 && !disabledSelectedItems">
      {{value?.length > 0? value[0][displayMember] : ''}}
      <span *ngIf="value?.length > 1 && multiple &&!disabledSelectedItems" class="custom-trigger">
        ( +{{value.length - 1}} {{value?.length === 2 ? 'outra' : 'outras'}})
      </span>
    </mat-select-trigger>

    <mat-option class="no-checkbox" *ngIf="isLoading" disabled>
      <div class="loading-container">
        <span>Carregando dados...</span>
        <mat-spinner class="spinner" diameter="20"></mat-spinner>
      </div>
    </mat-option>

    <ng-container *ngIf="data?.length || showFilter && filteredReturn?.length ">
      <ng-container *ngFor="let item of filteredReturn">
        <mat-option [ngStyle]="{'display': item['showValue'] ? 'flex' : 'none'}" [value]="item"
          (click)="selectionChangeMultiple(item)">
          {{item[displayMember]}}
        </mat-option>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data?.length  && !showFilter">
      <ng-container *ngFor="let item of data">
        <mat-option [ngStyle]="{'display': item['showValue'] ? 'flex' : 'none'}" [value]="item"
          (click)="selectionChangeMultiple(item)">
          {{item[displayMember]}}
        </mat-option>
      </ng-container>
    </ng-container>

    <mat-option *ngIf="!data?.length && !isLoading" class="no-checkbox" disabled>
      <span>Não há dados para exibir</span>
    </mat-option>

  </mat-select>
</mat-form-field>
