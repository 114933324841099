<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <h2>AGILIDADE DO APRENDIZADO - {{itensCount}} ALUNOS BLOQUEADOS</h2>

  <div class="header">
    <div class="search">
      <i class="logo icon-lupa search-icon"></i>
      <input type="text" placeholder="Digite o nome ou email do usuário" (keyup)="updateSearch($event.target.value)" />
    </div>
  </div>

  <table mat-table matSort [dataSource]="usersSubjectBlocked" class="mat-elevation-z8 table">

    <ng-container matColumnDef="user">
      <th width="30%" mat-header-cell *matHeaderCellDef> Alunos </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{row.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th width="30%" mat-header-cell *matHeaderCellDef> Email </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subjectBlockedCount">
      <th width="20%" mat-header-cell *matHeaderCellDef> Assuntos Bloqueados </th>
      <td width="20%" mat-cell center *matCellDef="let row">
        {{ row.blockedSubjectCount}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th width="20%" mat-header-cell *matHeaderCellDef> Ações </th>
      <td width="20%" mat-cell *matCellDef="let row">
        <img src="./assets/img/view.png" style="cursor: pointer;" (click)="goToUser(row.userId)" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <app-pagination *ngIf="itensCount" [setPagination]="{
            'itemsCount': itensCount,
            'pageSize': pageSize
          }" (goToPage)="goToPage($event)"></app-pagination>
</div>
