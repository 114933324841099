import { IEnvironment } from 'src/environments/environment.model';

export const environment: IEnvironment = {
  production: true,
  apiUrl: '/backend',
  logo: 'proseek.png',
  linkedInRedirect: 'https://academia.proseek.com.br',
  linkedInId: '789ldtqyaq94n7',
  linkedInSecret: 'Wqhygt2HuUpPmMOt',
  analyticsId: '',
  ibentaKey: '',
  interactionIntervalMinutes: 8,
  timeToCloseInteractionDialogMinutes: 2,
  replecement: {
    track: { plural: 'Trilhas', singular: 'Trilha' },
    module: { plural: 'Módulos', singular: 'Módulo' },
    program: { plural: 'Programas', singular: 'Programa' }
  },
  features: {
    businessManager: true,
    certificate: true,
    effortTimeCalculation: true,
    effortPerformance: true,
    firstLoginPersonalDataForm: true,
    sso: {
      feature: false,
      forceLoginSso: false,
      domains: []
    },
    autoGenerateRegistrationId: true,
    registration: true,
    profileTest: true,
    formulas: true,
    loginLinkedIn: true,
    userUploadFilesToSecretary: true,
    recruitment: true,
    career: true,
    eCommerce: true,
    questionComment: true,
    colorPalette: true,
    calender: true,
    knowMore: true,
    myPerformance: true,
    levels: true,
    userInfoIsCompleted: false,
    comunication: true,
    practiceQuestions: false,
    cockpitMetrics: false,
    navigateExternatUrl: true,
    automaticSync: false,
    showRanking: true,
    hasHomeV2: true,
    canGenerateCertificate: true
  },
  externatUrl: 'https://guiadoestudante.abril.com.br/curso-enem-play/',
  ecommerceIntegration: true,
  contentTypesAccepted: [
    'application/pdf',
    'text/plain',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/zip',
    'application/x-zip-compressed',
    'video/mp4',
    'audio/mp4',
    'application/mp4',
    'video/quicktime',
    'video/x-ms-wmv',
    'application/msword',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ],
  salt: '3FZVVauDCkYPiVJ9',
};
