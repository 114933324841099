import { Subject } from './subject.model';
import { Content, ConceptReference, VideoReference, PdfReference, TextReference } from './content.model';

export class ModuleContent {
  public id: string;
  public title: string;
  public hasQuestions: boolean;
  public subjects: Array<Subject>;

  constructor(module: ModuleContent = null) {
    if (module)
      Object.keys(module).forEach(key => this[key] = module[key]);
  }
}
