import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CardTagModule } from 'src/app/shared/components/layout/card-tag/card-tag.module';
import { ProgressBarModule } from 'src/app/shared/components/layout/progress-bar/progress-bar.module';
import { ModuleCardModule } from 'src/app/shared/components/module-card/module-card.module';
import { EventCardModule } from 'src/app/shared/components/event-card/event-card.module';
import { TrackPathModule } from './track-path/track-path.module';
import { TrackOverviewComponent } from './track-overview.component';
import { TrackOverviewWarningsComponent } from './track-warnings/track-warnings.component';
import { TrackMandatoryVideoComponent } from './track-mandatory-video/track-mandatory-video.component';
import { VideoDialogComponent } from 'src/app/shared/components/video-dialog/video.dialog';
import { TrackCalendarModule } from './track-calendar/track-calendar.module';
import { MatIconModule } from '@angular/material/icon';
import { TrackEffortProgressComponent } from './track-effort-progress/track-effort-progress.component';
import { TrackEffortProgressStudentsComponent } from './track-effort-progress-students/track-effort-progress-students.component';
import { TrackEffortProgressChartComponent } from './track-effort-progress-students/track-effort-progress-chart/track-effort-progress-chart.component';
import { TrackEffortProgressDetailsComponent } from './track-effort-progress-students/track-effort-progress-details/track-effort-progress-details.component';
import { FavoriteButtonModule } from 'src/app/shared/components/favorite-button/favorite-button.module';

@NgModule({
    declarations: [
        TrackOverviewComponent,
        TrackOverviewWarningsComponent,
        TrackMandatoryVideoComponent,
        VideoDialogComponent,
        TrackEffortProgressComponent,
        TrackEffortProgressStudentsComponent,
        TrackEffortProgressChartComponent,
        TrackEffortProgressDetailsComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        CardTagModule,
        ProgressBarModule,
        ModuleCardModule,
        EventCardModule,
        TrackPathModule,
        PipesModule,
        TrackCalendarModule,
        MatIconModule,
        FavoriteButtonModule
    ],
    exports: [
        TrackOverviewComponent
    ]
})
export class TrackOverviewModule { }
