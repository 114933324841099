import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../../shared/classes/notification';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Module } from 'src/app/models/module.model';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { mandatoryCollumnEnum } from './model/mandatoryCollums.interface';
import { isNil } from 'lodash';
import { Subject } from 'src/app/models/subject.model';
import { Content } from 'src/app/models/content.model';
import { ContentTypeEnum } from 'src/app/models/enums/content-type.enum';
import { ExternalService } from 'src/app/shared/services/external.service';

@Component({
  selector: 'app-upload-contents-dialog',
  templateUrl: './upload-contents.dialog.html',
  styleUrls: ['./upload-contents.dialog.scss']
})
export class UploadContentsDatabaseDialogComponent extends NotificationClass {

  public addContents = true;
  public showCloseButton = true;
  public subjects: Subject[] = [];
  public loading: boolean = false;
  public erroList: any[];
  public readonly collums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
  'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  constructor(
    protected _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<UploadContentsDatabaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { module: Module },
    public _sharedService: SharedService,
    private _excelService: ExcelService,
    private _externalService: ExternalService
  ) {
    super(_snackBar);
    this.data.module.subjects.forEach(subject => {
      this.subjects.push({
        title: subject.title,
        excerpt: '',
        concepts: [],
        contents: [],
        userProgresses: []
      });
    });
  }
  public normalizeString = (value) => isNil(value) ? null : this._excelService.normalizeString(value);

  public dismiss(): void {
    this._dialogRef.close();
  }

  public save(): void {
    this._dialogRef.close({
      subjects: this.subjects,
      addContents: this.addContents
    });
  }

  public openFileUpload(): void {
    const input = document.getElementById('subjectsInputFile') as HTMLInputElement;
    input.value = '';
    input.click();
  }

  public setDocumentFile(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
        this.notify('Tipo de arquivo inválido. Apenas \'xls\', \'xlsx\' e \'csv\' são permitidos.');
        return;
      }

      this.loading = true;
      this._readExcel(file);
    }
  }

  private _readExcel(file) {
    const callback = this._importQuestion.bind(this);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let binary = '';
      const bytes = new Uint8Array(this.result as any);

      for (let i = 0; i < bytes.byteLength; i++)
        binary += String.fromCharCode(bytes[i]);

      callback(binary);
    };
    reader.readAsArrayBuffer(file);
  }

  private _importQuestion(file: string) {
    const mandatoryCollumns = [
      mandatoryCollumnEnum.subjectName,
      mandatoryCollumnEnum.contentName,
      mandatoryCollumnEnum.contentType,
      mandatoryCollumnEnum.contentValue,
      mandatoryCollumnEnum.contentExcerpt,
      mandatoryCollumnEnum.contentUrls
    ];

    this._excelService._getExcelContentAsJson(file, mandatoryCollumns).subscribe(response => {
      this._setContents(response.contentJson, response.headers);
    }, error => {
      this.erroList = [{ description: error}];
      this.loading = false;
    });
  }

  private _setContents(contents: any[], headers: any[]) {
    const getCollum = (header: string) => {
      const collumn = headers.find(h => h.headear === header).collumn;
      return isNil(collumn) ? collumn : this.collums[collumn];
    };
    this.erroList = [];
    let index = 0;
    this._validateExcell(contents, headers);
    if (this.erroList.length === 0) {
      while (index < contents.length) {
        const excelContent = contents[index];
        const dataSubject = this._findSubject(excelContent[mandatoryCollumnEnum.subjectName]);
        if (dataSubject.title === '') {
          this.erroList.push({
            collum: getCollum(mandatoryCollumnEnum.subjectName),
            row: excelContent['row'],
            description: 'Não existe um assunto com este nome'
          });
          index++;
          continue;
        }
        if (this.erroList.length === 0) {
          this._setSubject(dataSubject,
            excelContent[mandatoryCollumnEnum.contentName],
            excelContent[mandatoryCollumnEnum.contentType],
            excelContent[mandatoryCollumnEnum.contentValue],
            excelContent[mandatoryCollumnEnum.contentExcerpt],
            excelContent[mandatoryCollumnEnum.contentUrls]
          );
        }
        index++;
      }
    }
    this.loading = false;
  }

  private _validateExcell(excelContents: any[], headers: any[]) {
    const getCollum = (header: string) => {
      const collumn = headers.find(h => h.headear === header).collumn;
      return isNil(collumn) ? collumn : this.collums[collumn];
    };

    let index = 0;
    while (index < excelContents.length) {
      const excelContent = excelContents[index];

      if (isNil(excelContent[mandatoryCollumnEnum.subjectName])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.subjectName),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      if (isNil(excelContent[mandatoryCollumnEnum.contentName])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.contentName),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      if (isNil(excelContent[mandatoryCollumnEnum.contentType])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.contentType),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.contentValue),
          row: excelContent['row'],
          description: 'Para validar este campo é necessário que o campo "tipo" seja preenchido corretamente'
        });
      } else {
        const type = this._excelService.normalizeString(excelContent[mandatoryCollumnEnum.contentType]);
        if (!(
            type === 'video' ||
            type === 'texto' ||
            type === 'pdf' ||
            type === 'zip' ||
            type === 'scorm' ||
            type === 'link'
          )
        ) {
          this.erroList.push({
            collum: getCollum(mandatoryCollumnEnum.contentType),
            row: excelContent['row'],
            description: 'Este campo esta em um formato inválido'
          });
          this.erroList.push({
            collum: getCollum(mandatoryCollumnEnum.contentValue),
            row: excelContent['row'],
            description: 'Para validar este campo é necessário que o campo "tipo" seja preenchido corretamente'
          });
        } else {
          if (
            (type === 'video' || type === 'texto' || type === 'link') &&
            isNil(excelContent[mandatoryCollumnEnum.contentValue])
          ) {
            this.erroList.push({
              collum: getCollum(mandatoryCollumnEnum.contentValue),
              row: excelContent['row'],
              description: 'Dado "tipo" preenchido este campo não pode ser nulo'
            });
          } else if (
            (type === 'pdf' || type === 'zip' || type === 'scorm') &&
            !isNil(excelContent[mandatoryCollumnEnum.contentValue])
          ) {
            this.erroList.push({
              collum: getCollum(mandatoryCollumnEnum.contentValue),
              row: excelContent['row'],
              description: 'Dado "tipo" preenchido este campo deve ser nulo'
            });
          } else if (type === 'video') {
            const videoId = this._externalService.getVideoIdFromUrlIfValid(excelContent[mandatoryCollumnEnum.contentValue]);
            if (!videoId) {
              this.erroList.push({
                collum: getCollum(mandatoryCollumnEnum.contentValue),
                row: excelContent['row'],
                description: 'Dado "tipo" preenchido este campo esta em um formato inválido'
              });
            }
          } else if (type === 'link') {
            if (!this._isValidUrl(excelContent[mandatoryCollumnEnum.contentValue])) {
              this.erroList.push({
                collum: getCollum(mandatoryCollumnEnum.contentValue),
                row: excelContent['row'],
                description: 'Dado "tipo" preenchido este campo esta em um formato inválido'
              });
            }

            if (!isNil(excelContent[mandatoryCollumnEnum.contentUrls])) {
              this.erroList.push({
                collum: getCollum(mandatoryCollumnEnum.contentUrls),
                row: excelContent['row'],
                description: 'Dado "tipo" preenchido este campo deve ser nulo'
              });
            }
          }
        }
      }

      if (isNil(excelContent[mandatoryCollumnEnum.contentExcerpt])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.contentExcerpt),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      index++;
    }
  }

  private _setSubject(subject: Subject, contentName: string, contentType: string,
    contentValue: string, contentExcerpt: string, contentUrls: string) {
    const content = this._findContent(subject.contents, contentName);
    let newContent = false;
    if (content.title === '') {
      newContent = true;
    }
    this._setContent(content, contentName, contentType, contentValue, contentExcerpt, contentUrls);
    if (newContent) {
      subject.contents.push(content);
    }
  }

  private _findSubject(subjectName: string): Subject {
    const subjectIndex = this.subjects.findIndex(x => x.title === subjectName);
    if (isNil(subjectName) || subjectIndex === -1) {
      return {
        title: '',
        excerpt: '',
        concepts: [],
        contents: [],
        userProgresses: []
      };
    }
    return this.subjects[subjectIndex];
  }

  private _setContent(content: Content, contentName: string, contentType: string,
    contentValue: string, contentExcerpt: string, contentUrls: string) {
    content.title = contentName;
    content.excerpt = contentExcerpt;
    const type = this._excelService.normalizeString(contentType);

    switch (type) {
      case 'video':
        content.type = ContentTypeEnum.Video;
        content.value = contentValue;
        break;
      case 'texto':
        content.type = ContentTypeEnum.Text;
        content.value = contentValue;
        break;
      case 'link':
        content.type = ContentTypeEnum.Link;
        content.value = contentValue.replace(/(^http:\/\/|https:\/\/)+/g, '');
        break;
      case 'pdf':
        content.type = ContentTypeEnum.Pdf;
        break;
      case 'scorm':
        content.type = ContentTypeEnum.Scorm;
      break;
      case 'zip':
        content.type = ContentTypeEnum.Zip;
      break;
      default:
      break;
    }

    if (!isNil(contentUrls)) {
      const urls = contentUrls.split(';');
      content.referenceUrls = urls;
    }
  }

  private _findContent(contents: Content[], contentName: string): Content {
    const contentIndex = contents.findIndex(x => x.title === contentName);
    if (isNil(contentName) || contentIndex === -1) {
      return {
        type: null,
        title: '',
        excerpt: '',
        duration: null,
        referenceUrls: [],
        concepts: [],
        value: ''
      };
    }
    return contents[contentIndex];
  }

  private _isValidUrl(url: string) {

    if (isNil(url)) return false;
    const regex = new RegExp(
      // tslint:disable-next-line: max-line-length
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    );

    return regex.test(url);
  }
}
