import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramsSelectComponent } from './programs-select.component';
import { SelectModule } from '@academia/select';



@NgModule({
  declarations: [ProgramsSelectComponent],
  imports: [
    CommonModule,
    SelectModule
  ],
  exports: [ProgramsSelectComponent]
})
export class ProgramsSelectModule { }
