<div class="wrapper-header">
  <h2 class="header">
    {{isNewAutomaticSync ? 'Nova associação automatica' : 'Editar associação automatica'}}
  </h2>
</div>
<div class="inner-content">

  <div class="main-wrapper">
    <div class="section">
      <h4 class="title">CONFIGURAÇÃO DE EXIBIÇÃO</h4>
      <p class="subtitle">Defina as configurações de exibição </p>
      <div class="inputs">

        <mat-form-field>
          <input matInput placeholder="Descrição" [(ngModel)]="automaticSync.title" required />
        </mat-form-field>
      </div>
    </div>

    <div class="section">
      <h4 class="title">Regras</h4>
      <p class="subtitle">Defina as relações que serão incluidas e excluidas da associação automática</p>
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Incluidas">
          <ng-template matTabContent>
            <div class="search-wrapper">
              <mat-form-field>
                <mat-select placeholder="Atributos"
                  [(ngModel)]="selectedType">
                  <mat-option [value]="ruleItemTypeEnum.Jobs">Jobs</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Ranks">Ranks</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Segments">Segments</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Sectors">Sectors</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Companies">Companies</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.BusinessGroups">BusinessGroups</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.BusinessUnits">BusinessUnits</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.FrontBackOffices">FrontBackOffices</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Countries">Countries</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Locations">Locations</mat-option>
                </mat-select>
              </mat-form-field>
              <app-list-search placeholder="Nome do atributo" (triggerSearch)="triggerRelationalItemSearch($event)"> </app-list-search>
            </div>
            <div class="result">
              <ul class="user-list" *ngIf="relationalItems && relationalItems.length > 0">
                <li *ngFor="let relationalItem of relationalItems" (click)="addRule(relationalItem, actionTypeEnum.Include)">
                  <span>{{ relationalItem.name }}</span>
                </li>
              </ul>

              <div class="chips" *ngIf="automaticSync && automaticSync.rules && automaticSync.rules.length > 0" [ngClass]="{ 'hide': false }">
                <p *ngFor="let rule of getRulesByActionType(actionTypeEnum.Include)">
                  {{ rule.name }}
                  <span (click)="removeRule(rule)">X</span>
                </p>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Excluidas">
          <ng-template matTabContent>
            <div class="search-wrapper">
              <mat-form-field>
                <mat-select placeholder="Atributos"
                  [(ngModel)]="selectedType">
                  <mat-option [value]="ruleItemTypeEnum.Jobs">Jobs</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Ranks">Ranks</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Segments">Segments</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Sectors">Sectors</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Companies">Companies</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.BusinessGroups">BusinessGroups</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.BusinessUnits">BusinessUnits</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.FrontBackOffices">FrontBackOffices</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Countries">Countries</mat-option>
                  <mat-option [value]="ruleItemTypeEnum.Locations">Locations</mat-option>
                </mat-select>
              </mat-form-field>
              <app-list-search placeholder="Nome do atributo" (triggerSearch)="triggerRelationalItemSearch($event)"> </app-list-search>
            </div>
            <div class="result">
              <ul class="user-list" *ngIf="relationalItems && relationalItems.length > 0">
                <li *ngFor="let relationalItem of relationalItems" (click)="addRule(relationalItem, actionTypeEnum.Exclude)">
                  <span>{{ relationalItem.name }}</span>
                </li>
              </ul>

              <div class="chips" *ngIf="automaticSync && automaticSync.rules && automaticSync.rules.length > 0" [ngClass]="{ 'hide': false }">
                <p *ngFor="let rule of getRulesByActionType(actionTypeEnum.Exclude)">
                  {{ rule.name }}
                  <span (click)="removeRule(rule)">X</span>
                </p>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="section">
      <h4 class="title">Alvos</h4>
      <p class="subtitle">
        Escolha as trilhas que serão associadas automaticamente perante a regra criada acima
      </p>
      <app-list-search placeholder="Pesquisar por nome ou conceito" (triggerSearch)="triggerTrackSearch($event)"></app-list-search>
      <div class="chips">
        <p *ngFor="let target of getTargetsByType(targetItemTypeEnum.Track)">
          {{ target.name }}
          <span (click)="removeSelectedTarget(target.id)">X</span>
        </p>
      </div>
      <div class="tracks">
        <app-automatic-sync-track-card-select
          *ngFor="let track of tracks"
          [track]="track"
          (updateCollection)="updateTracks()">
        </app-automatic-sync-track-card-select>

      </div>
    </div>
  </div>

  <div class="footer">
    <button class="save" type="button" mat-button
      (click)="saveAutomaticSync()"> Salvar </button>
  </div>
</div>
