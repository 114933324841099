import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ExternalService } from '../../../../../shared/services/external.service';
import { UtilService } from '../../../../../shared/services/util.service';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Module } from '../../../../../models/module.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-module-video',
  templateUrl: './video.component.html',
  styleUrls: ['../new-module-steps.scss', './video.component.scss']
})
export class NewModuleVideoComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() public module$: Observable<Module>;
  public module: Module;
  @Output() setModuleVideo = new EventEmitter();

  public formGroup: FormGroup;
  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    private _externalService: ExternalService,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = moduleItems;
        if (this.formGroup && moduleItems) {
          this.updateComponent(moduleItems);
        } else {
          this.formGroup = this._createFormGroup(moduleItems);
        }
      });
    }
  }

  public updateComponent(module: Module) {
    this.formGroup.patchValue(
      {
        'videoUrl': module && module.videoUrl ? module.videoUrl : '',
        'videoDuration': module && module.videoDuration ? this._utilService.formatDurationToHour(module.videoDuration) : '',
        'videoId': module && module.videoUrl ? this._externalService.getVideoIdFromUrlIfValid(module.videoUrl) : null,
        'videoTitle': null
      }
    );
  }

  public nextStep(): void {
    const videoInfo: Module = this.formGroup.getRawValue();

    if (videoInfo.videoId && videoInfo.videoId !== '') {
      videoInfo.videoUrl = 'https://player.vimeo.com/video/' + videoInfo.videoId;
      videoInfo.videoDuration = this._utilService.getDurationFromFormattedHour(
        (videoInfo.videoDuration as any).split(':').join('')
      );
    } else {
      videoInfo.videoUrl = null;
      videoInfo.videoDuration = null;
    }

    delete videoInfo.videoTitle;
    delete videoInfo.videoId;

    this.setModuleVideo.emit(videoInfo);
  }

  private _createFormGroup(module: Module): FormGroup {
    return new FormGroup({
      'videoUrl': new FormControl(
        module && module.videoUrl ? module.videoUrl : ''
      ),
      'videoDuration': new FormControl(
        module && module.videoDuration ?
          this._utilService.formatDurationToHour(module.videoDuration) : ''
      ),
      'videoId': new FormControl(
        module && module.videoUrl ?
          this._externalService.getVideoIdFromUrlIfValid(module.videoUrl) : null
      ),
      'videoTitle': new FormControl(null)
    });
  }
}
