import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ListSearchComponent } from './list-search.component';
import { CommonModule } from '@angular/common';
import { ListSearchItemsComponent } from './list-search-items/list-search-items.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [
    ListSearchComponent,
    ListSearchItemsComponent
  ],
  imports: [
    CommonModule,
    ClickOutsideModule
  ],
  exports: [
    ListSearchComponent,
    ListSearchItemsComponent
  ]
})
export class ListSearchModule { }
