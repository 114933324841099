import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragingListComponent } from './draging-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { EmptyDataModule } from 'src/app/shared/components/empty-data/empty-data.module';



@NgModule({
  declarations: [
    DragingListComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    DragDropModule,
    MatIconModule,
    MatMenuModule,
    EmptyDataModule
  ],
  exports: [DragingListComponent]
})
export class DragingListModule { }
