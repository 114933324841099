<mat-tab-group [selectedIndex]="selectedTab"
  (selectedTabChange)="onTabSelected($event)"
  animationDuration="0ms">

  <mat-tab label="NOVO CONCEITO">
    <ng-template matTabContent>
    </ng-template>
  </mat-tab>

  <mat-tab [disabled]="getOtherUserTags().length === 0" label="AVALIAR CONCEITO">
    <ng-template matTabContent>
    </ng-template>
  </mat-tab>

</mat-tab-group>

<ng-container *ngIf="selectedTab === 0">
  <p class="subtitle">
    Crie um novo conceito dentro do conteúdo apresentado. Os conceitos
    auxiliam no estudo do conteúdo e tambem podem ser avaliadas na avaliação
  </p>
  <div class="inputs">
    <mat-form-field>
      <input matInput
        placeholder="Nome do conceito"
        type="text" 
        [(ngModel)]="conceptName" 
        required 
      />
    </mat-form-field>
    
    <mat-form-field>
      <input matInput
        placeholder="Breve Descrição"
        type="text"
        [(ngModel)]="conceptDescription" 
        required 
      />
    </mat-form-field>
    
    <mat-form-field>
      <input matInput
        placeholder="Página"
        [(ngModel)]="page"
        required
      />
    </mat-form-field>
  </div>
  
  <div class="card-footer">
    <button class="btn-outline" (click)="dialogRef.close()">
      Cancelar
    </button>
    <button class="btn-test" (click)="save()">
      Salvar
    </button>
  </div>
</ng-container>

<ng-container *ngIf="selectedTab === 1">
  <p class="subtitle">
    Avalie os conceitos criados por outros alunos
  </p>

  <div class="otherStudentsTags">
    <ul>
      <li class="concept" *ngFor="let tag of getOtherUserTags()">
        <div>{{ tag.concept.name }}</div>
        <ng-container *ngFor="let position of tag.concept['positions']">
          <div *ngIf="position > 0">
            {{ getConceptTag(position) }}
          </div>
        </ng-container>
        <div class="like-dislike">
          <div class="icon-custom icon-custom-happy"
            [ngClass]="{ 'selected': getValuationStatus(tag, true)}" 
            (click)="valuateTag(tag, true)"
          ></div>
          <div class="icon-custom icon-custom-normal"
            [ngClass]="{ 'selected': getValuationStatus(tag, false)}"
            (click)="valuateTag(tag, false)"
          ></div>
        </div>
      </li>
    </ul>
  </div>
  
  <div class="card-footer">
    <button style="width: 100%;" class="btn-outline" (click)="dialogRef.close()">
      Sair
    </button>
  </div>
</ng-container>
