import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Module } from 'src/app/models/module.model';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { catchError, map } from 'rxjs/operators';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })

export class ModuleResolver extends NotificationClass implements Resolve<Module> {
  constructor(
    private _modulesService: ContentModulesService,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Module> {
    const trackId = route.queryParams['trackId'];
    const programId = route.queryParams['programId'];
    const moduleId = route.paramMap.get('moduleId');

    if (moduleId === 'undefined') return throwError(Error('Necessário informar o identificador do módulo'));

    return this._modulesService
      .getModuleById(moduleId, trackId, programId)
      .pipe(
        catchError(error => {
          this.notify(this.getErrorNotification(error));
          return error;
        }),
        map((response: any) => response.data),
      );
  }
}
