export class AutomaticSync {
  public id?: string;
  public createdAt?: Date;
  public title: string;
  public rules: Rule[];
  public targets: Target[];
}

export class Rule {
  public ruleItemType: RuleItemTypeEnum;
  public id: string;
  public name: string;
  public actionType: ActionTypeEnum;
}

export class Target {
  public targetItemType: TargetItemTypeEnum;
  public id: string;
  public name: string;
}

export enum ActionTypeEnum {
  Include = 1,
  Exclude = 2
}

export enum RuleItemTypeEnum {
  Jobs = 1,
  Ranks = 2,
  Segments = 3,
  Sectors = 4,
  Companies = 5,
  BusinessGroups = 6,
  BusinessUnits = 7,
  FrontBackOffices = 8,
  Countries = 9,
  Locations = 10
}

export enum TargetItemTypeEnum {
  Track = 1,
  Module = 2,
  Event = 3
}
