import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentAvatarComponent } from './student-avatar.component';

@NgModule({
  declarations: [StudentAvatarComponent],
  exports: [StudentAvatarComponent],
  imports: [
    CommonModule
  ]
})
export class StudentAvatarModule { }
