import { Injectable, HostListener, RendererFactory2, Renderer2 } from '@angular/core';
import { differenceInSeconds } from 'date-fns';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ActionInfoTypeEnum, ActionInfoPageEnum } from 'src/app/shared/directives/save-action/action-info.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { isNil, isEmpty } from 'lodash';
import { Router, NavigationEnd } from '@angular/router';
import { TypeTargetEffort } from '../enumerations/effort';
import { ERole } from 'src/app/models/enums/role.enum';

let hasInitialized = false;

enum StatusVideo {
  PAUSED = 'pause',
  PLAYING = 'playing',
  TIMEUPDate = 'timeupdate',
  CUEPOINT = 'cuepoint',
}

@Injectable({ providedIn: 'root' })
export class EffortService {
  StatusVideo = StatusVideo;

  private _dialogRef: MatDialogRef<SimpleDialogComponent> = null;
  private _dialogClosingTime = null;
  private _stopInterval = false;
  private _interval$ = new Subscription();
  private _renderer: Renderer2;
  private _statusVideo: StatusVideo;
  private interactStart: Date = null;
  private InteractId: string = null;
  private interacts = [];
  public typeInteraction: TypeTargetEffort;

  private _minutesToMilliseconds = (minutes: number) => minutes * 60000;
  private _minutesToSeconds = (minutes: number) => minutes * 60;
  private get _interactionIntervalSeconds() { return Math.floor(this._minutesToSeconds(environment.interactionIntervalMinutes)); }
  private get _interactionInterval() { return this._minutesToMilliseconds(environment.interactionIntervalMinutes); }

  private get _skipInteraction() {
    return !environment.features.effortTimeCalculation ||
      this._authService.hasRole(ERole.Admin) ||
      this._authService.isImpersonatedUser() ||
      this._authService.getLoggedUserRole() === ERole.BusinessManager ||
      !this._authService.isLoggedIn({ getRawValue: true });
  }


  constructor(
    private _analyticsService: AnalyticsService,
    private _dialogService: MatDialog,
    private _authService: AuthService,
    private rendererFactory2: RendererFactory2,
    private router: Router,
  ) {

  }

  public listenEvent(target: any, eventName: string, config = {}) {
    return this._renderer.listen(target, eventName, evt => this._updateTime(evt, target));
  }

  public startCountInteraction(moduleId: string, type: TypeTargetEffort) {
    this.interactStart = new Date();
    this.InteractId = moduleId;
    this.typeInteraction = type;
  }

  public finishInteraction(cleanInteraction = true) {
    if (this.interactStart !== null && this.InteractId !== null && this.typeInteraction !== null) {
      const now = new Date();
      const interactTime = differenceInSeconds(new Date(), this.interactStart);
      this.setInteracts(interactTime, this.InteractId, this.typeInteraction, this.interactStart, now);

      if (cleanInteraction) {
        this.cleanInteraction();
      } else {
        this.stopInteract();
      }
    }

  }

  private cleanInteraction() {
    this.interactStart = null;
    this.InteractId = null;
    this.typeInteraction = null;
  }

  private stopInteract() {
    this.interactStart = null;
  }

  public restarCountInteract() {
    this.interactStart = new Date();
  }

  private setInteracts(interactTime: number, moduleId: string, typeInteraction: TypeTargetEffort, startDate: Date, endDate: Date) {
    const hasInteract = this.interacts.find(interact => interact.id === moduleId);
    if (hasInteract) {
      const index = this.interacts.findIndex(interact => interact.id === moduleId);
      this.interacts[index].interaction += interactTime;
    } else {
      this.interacts.push({
        id: moduleId,
        typeInteraction: typeInteraction,
        interaction: interactTime,
        startDate: startDate,
        endDate: endDate
      });
    }
  }

  public init() {
    if (hasInitialized) return;
    hasInitialized = true;
    this._renderer = this.rendererFactory2.createRenderer(null, null);
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this._updateTime();
      }
    });

    this.listenEvent('window', 'message');
    this.listenEvent('document', 'onMessage');
    // this.listenEvent('document', 'keypress');
    // console.debug('Verificação: ', environment.interactionIntervalMinutes);
    // console.debug('Checkpoint: ', environment.interactionIntervalMinutes * 2);

    interval(this._interactionInterval * 2)
      .pipe(filter(_ => !this._stopInterval && !this._skipInteraction && !isNil(this.interactStart)))
      .subscribe(_ => {
        this.finishInteraction(false);
        this.restarCountInteract();
        this._saveInteraction(this.interacts);
      });

    // console.debug('Primeira contagem');
    this._updateTime();
  }

  private _updateTime(evt?: MouseEvent | KeyboardEvent | TouchEvent | MessageEvent, target?: any) {

    if (evt instanceof MessageEvent) this._statusVideo = evt.data.event;
    const hasVideoMessage = this._statusVideo === StatusVideo.PAUSED ||
      this._statusVideo === StatusVideo.TIMEUPDate ||
      this._statusVideo === StatusVideo.CUEPOINT;

    if (this._skipInteraction || evt instanceof MessageEvent && !hasVideoMessage) return;

    if (evt && this.isInactive()) this.startCountInteraction(this.InteractId, this.typeInteraction);

    this._stopInterval = false;

    this._interval$.unsubscribe();
    this._interact();
  }

  private isInactive() {
    return this.interactStart === null && this.InteractId !== null && this.typeInteraction !== null;
  }

  private _interact() {

    this._interval$ = interval(this._interactionInterval)
      .pipe(filter(_ => !this._stopInterval))
      .subscribe(_ => {
        if (this.interactStart !== null) {
          const interactionTime = differenceInSeconds(new Date(), this.interactStart);
          if (this._isIdle(interactionTime) && this._statusVideo !== StatusVideo.PLAYING && this._statusVideo !== StatusVideo.TIMEUPDate &&
            this._statusVideo !== StatusVideo.CUEPOINT) this._showInteractionDialog();
        } else if (this.interactStart === null && this.interacts.length > 0) {
          this._saveInteraction(this.interacts);
        }
      });

  }

  private _isIdle(idleTime: number) {
    const intervalInSeconds = this._interactionIntervalSeconds;
    const isIdle = idleTime >= intervalInSeconds;
    return isIdle;
  }

  private _showInteractionDialog() {
    this._stopInterval = true;
    this.finishInteraction(false);
    this._saveInteraction(this.interacts);
    // console.debug('Contagem pausada: ', this._stopInterval);

    this._dialogRef = this._dialogService.open(SimpleDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '500px',
      panelClass: 'effort-dialog',
      data: {
        message: `Continua estudando ?`,
        positiveTextAction: 'SIM',
      } as SimpleDialog
    });

    this._createDialogSubscription();

  }

  private _createDialogSubscription() {

    const INTERVAL_TIME = this._minutesToMilliseconds(environment.timeToCloseInteractionDialogMinutes);

    this._dialogRef.afterClosed().subscribe(hasAccepted => {
      if (hasAccepted) {
        this._stopInterval = false;
        this.startCountInteraction(this.InteractId, this.typeInteraction);
        this._updateTime();
        clearTimeout(this._dialogClosingTime);
      }

    });

    this._dialogClosingTime = setTimeout(() => {
      // console.debug('Esta inativo');
      this._stopInterval = true;
      this.stopInteract();
      this._dialogRef.close(false);
    }, INTERVAL_TIME);

  }

  private _saveInteraction(interacts: any) {
    if (!this._skipInteraction) {
      if (this.interacts.length > 0) {
        this._analyticsService.saveInteractions(interacts, environment.interactionIntervalMinutes).subscribe();
        this.interacts = [];
      }
    }

  }

}
