<div id="Header">
  <div class="header-content">
    <mat-progress-bar *ngIf="showLoader" class="loader" mode="indeterminate"></mat-progress-bar>
    <div>
      <a *ngIf="isLoggedIn" href="javascript:;" (click)="open()">
        <i class="icon icon-menu burguer-menu"></i>
      </a>

      <a (click)="navigateTo()">
        <div class='content-logo'>
          <img class="logo" src="{{logo}}" />
        </div>
      </a>
    </div>
    <div *ngIf="isLoggedIn && user" class="user-info">
      <img class="notification" title="Ver Como" *ngIf="types.length" [src]="getSeeHowImgSrc()"
        (click)="toggleSeeHowAction()" />
      <div #seeHowDiv class="notifications" *ngIf="toggleSeeHow">
        <ul>
          <li *ngFor="let type of types" (click)="type?.action()">
            <div class="circle"></div>
            <div class="content">
              <p>
                <b>{{ type?.name }}</b>
              </p>
            </div>
          </li>
        </ul>
      </div>

      <img class="notification" title="Notificações" *ngIf="notifications && notifications.length > 0"
        [ngClass]="{ 'read': hasNotification() }" [src]="getNotificationImgSrc()"
        (click)="toggleNotifications = !toggleNotifications" />
      <div class="notifications" *ngIf="toggleNotifications">
        <div class="arrow-up"></div>
        <ul>
          <li *ngFor="let notification of notifications" (click)="goToNotification(notification)">

            <div class="circle" [ngClass]="{ 'read': notification.read }"></div>
            <div class="content">
              <p>
                <b>{{ notification?.title }}</b><br>
                {{ notification?.text }}
              </p>
              <p>
                <small>{{ notification?.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}</small>
              </p>
            </div>
          </li>
        </ul>
      </div>

      <img src="{{user?.image_url ? user?.image_url : './assets/img/user-image-placeholder.png'}}"
        *ngIf="user?.role != 'Admin' && user?.role != 'Secretary'" />
      <p class="user">
        <a class="user-name" href="javascript:;" (click)="goTouserInfo()">
          {{ user?.name }}
        </a><br>
        <a class="logout" (click)="logout()">
          Sair
        </a>
      </p>
    </div>
  </div>
  <div *ngIf="isImpersonating" class="impersonate-block">
    <p>PERSONIFICANDO: {{ user?.name }}</p>
    <button class="btn-test" (click)="impersonateLogout()">
      sair da personificação
    </button>
  </div>
</div>
