import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ExternalService } from '../../../../../shared/services/external.service';
import { UtilService } from '../../../../../shared/services/util.service';
import { Event } from '../../../../../models/event.model';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep, isNil } from 'lodash';

@Component({
  selector: 'app-new-event-video',
  templateUrl: './video.component.html',
  styleUrls: ['../new-event-steps.scss', './video.component.scss']
})
export class NewEventVideoComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() event$: Observable<Event>;
  public event: Event;
  @Output() setEventVideo = new EventEmitter();

  private _unsubscribeAll = new Subject<any>();

  public formGroup: FormGroup;
  constructor(
    protected _snackBar: MatSnackBar,
    private _externalService: ExternalService,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((eventItems) => {
        this.event = cloneDeep(eventItems);
        if (this.formGroup) {
          this._updateComponent(eventItems);
        } else {
          this.formGroup = this._createFormGroup(eventItems);
          // this._createVideoUrlSubscription(this.formGroup);
        }
      });
    }
  }

  public _updateComponent(event: Event) {

    this.formGroup.patchValue({
      'videoUrl': event && event.videoUrl ? event.videoUrl : null,

      'videoDuration': event && event.videoDuration ? this._utilService.formatDurationToHour(event.videoDuration) : null,

      'videoId': event && !isNil(event.videoUrl) && !event.videoUrl.match('/null')
      ? this._externalService.getVideoIdFromUrlIfValid(event.videoUrl) : null,

      'videoTitle': null,

      'origin' : event && event.videoUrl && !event.videoUrl.match('/null') ? this.checkOrigin(event.videoUrl) : null
    });
  }

  public nextStep(): void {
    const videoInfo: Event = this.formGroup.getRawValue();
    videoInfo.videoUrl = this.getUrl();
    videoInfo.videoDuration = videoInfo.videoDuration ? this._utilService.getDurationFromFormattedHour(
      (videoInfo.videoDuration as any).split(':').join('')) : null;
    delete videoInfo.videoTitle;
    delete videoInfo.videoId;
    this.setEventVideo.emit( videoInfo );
  }

  private _createFormGroup(event: Event): FormGroup {
    return new FormGroup({
      'videoUrl': new FormControl(
        event && event.videoUrl ? event.videoUrl : null, [ Validators.required ]
      ),
      'videoDuration': new FormControl(
        event && event.videoDuration ?
          this._utilService.formatDurationToHour(event.videoDuration) : null,
          [ Validators.required ]
      ),
      'videoId': new FormControl(
        event && !isNil(event.videoUrl) ?
          this._externalService.getVideoIdFromUrlIfValid(event.videoUrl) : null
      ),
      'videoTitle': new FormControl(null),

      'origin' : new FormControl( event && event.videoUrl ?
        this.checkOrigin(event.videoUrl) : null)
    });
  }

  public checkOrigin (url: string): string {
      const urlYoutube = url.match('youtube.com');
      const urlVimeo = url.match('vimeo.com');
    if (urlYoutube !== null) {
      return 'Youtube' ;
    }

    if (urlVimeo !== null) {
      return 'Vimeo';
    }

  }

  public getUrl(): string {
    const videoInfo: Event = this.formGroup.getRawValue();
    if (this.formGroup.get('origin').value === 'Youtube') {
      return 'https://youtube.com/embed/' + videoInfo.videoId;
    } else {
      return 'https://player.vimeo.com/video/' + videoInfo.videoId;
    }
}

  private _createVideoUrlSubscription(formGroup: FormGroup): void {
    formGroup.get('videoUrl').valueChanges.subscribe((url: string) => {
      const videoId = this._externalService.getVideoIdFromUrlIfValid(url);
      if (videoId) {
        formGroup.get('videoId').setValue(videoId);
        this._externalService.getVideoInfoFromVimeo(videoId).subscribe((response) => {
          if (response && response.length > 0) {
            formGroup.get('videoTitle').setValue(response[0].title);

            const duration = this._utilService.formatDurationToHour(response[0].duration);
            formGroup.get('videoDuration').setValue(duration);
          }
        }, () => {
          this.notify('Ocorreu um erro ao buscar as informações do vídeo');
        });
      }
    });
  }
}
