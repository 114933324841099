<div class="module-info step">
  <h2>Edições do evento</h2>

  <form [formGroup]="formGroup" [hidden]="formGroup.get('schedules')['controls'].length === 0" *ngIf="formGroup">
    <ng-container *ngFor="let childFormGroup of formGroup.get('schedules')['controls']; let i = index"
      formArrayName="schedules">
      <div class="schedule" [formGroupName]="i">
        <h3>Inscrições</h3>
        <div class="subscription">
          <div class="column">
            <div class="validation-field">
              <mat-form-field>
                <input matInput [matDatepicker]="subStartPicker" formControlName="subscriptionStartDate"
                  placeholder="Data de Início">
                <mat-datepicker-toggle matSuffix [for]="subStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #subStartPicker></mat-datepicker>
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('subscriptionStartDate')"></control-messages>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput [matDatepicker]="subEndPicker" formControlName="subscriptionEndDate"
                  placeholder="Data de Término">
                <mat-datepicker-toggle matSuffix [for]="subEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #subEndPicker></mat-datepicker>
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('subscriptionEndDate')"></control-messages>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput formControlName="endHour" mask="00:00"
                  placeholder="Horário de término das inscrições(hh:mm)">
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('endHour')"></control-messages>
            </div>
          </div>
          <div class="column">
            <div class="validation-field">
              <mat-form-field>
                <mat-select placeholder="Status" formControlName="published" required>
                  <mat-option [value]="false">Oculto</mat-option>
                  <mat-option [value]="true">Publicado</mat-option>
                </mat-select>
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('published')"></control-messages>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <mat-select placeholder="Tipos de evento" formControlName="eventScheduleType" required>
                  <mat-option [value]="eventScheduleType.DefaultScheduleType">Padrão</mat-option>
                  <mat-option [value]="eventScheduleType.SynchronousExperience">Experiência Síncrona</mat-option>
                </mat-select>
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('eventScheduleType')"></control-messages>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput placeholder="Limite de usuários" type="number" formControlName="applicationLimit" />
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('applicationLimit')"></control-messages>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput placeholder="Area Comercial" type="text" formControlName="commercialArea" />
              </mat-form-field>
              <control-messages [control]="childFormGroup.get('commercialArea')"></control-messages>
            </div>
          </div>
        </div>

        <ng-container *ngIf="hasForum">
          <h3>Fórum de Discussão</h3>
          <div class="subscription">
            <div class="column">
              <div class="validation-field">
                <mat-form-field>
                  <input matInput [matDatepicker]="forumStartPicker" formControlName="forumStartDate"
                    placeholder="Data de Início">
                  <mat-datepicker-toggle matSuffix [for]="forumStartPicker"></mat-datepicker-toggle>
                  <mat-datepicker #forumStartPicker></mat-datepicker>
                </mat-form-field>
                <control-messages [control]="childFormGroup.get('forumStartDate')"></control-messages>
              </div>
              <div class="validation-field">
                <mat-form-field>
                  <input matInput [matDatepicker]="forumEndPicker" formControlName="forumEndDate"
                    placeholder="Data de Término">
                  <mat-datepicker-toggle matSuffix [for]="forumEndPicker"></mat-datepicker-toggle>
                  <mat-datepicker #forumEndPicker></mat-datepicker>
                </mat-form-field>
                <control-messages [control]="childFormGroup.get('forumEndDate')"></control-messages>
              </div>
            </div>
          </div>
        </ng-container>

        <h3>Evento</h3>
        <div class="event">
          <div class="validation-field">
            <mat-form-field>
              <input matInput [matDatepicker]="startPicker" formControlName="eventDate" placeholder="Data de Início">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('eventDate')"></control-messages>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <input matInput placeholder="Hora (hh:mm)" required mask="00:00" formControlName="startHour" />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('startHour')"></control-messages>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <input matInput placeholder="Duração Total (hh:mm:ss)" required mask="00:00:00"
                formControlName="duration" />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('duration')"></control-messages>
          </div>
        </div>
        <div class="event">
          <div class="validation-field">
            <mat-form-field>
              <input matInput placeholder="Link para Webinar" formControlName="webinarUrl" />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('webinarUrl')"></control-messages>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <mat-select placeholder="Localidade" formControlName="location" [(ngModel)]="selectedLocations[i]"
                (valueChange)="changeLocation($event, i)">
                <mat-option [value]="location.id" *ngFor="let location of locations">
                  {{ location.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('location')"></control-messages>
          </div>
        </div>
        <div class="event">
          <div class="validation-field">
            <app-list-search placeholder="Instrutor *" [inputValue]="childFormGroup.get('instructor')?.value"
              [noPadding]="true" (triggerSearch)="triggerUserSearch($event, i)"></app-list-search>
            <ul class="user-list" *ngIf="users && users.length > 0" (clickOutside)="resetUserSearch()">
              <li *ngFor="let user of users[i]" (click)="addUser(user, i)">
                <img class="logo" [src]="user.imageUrl ? user.imageUrl : './assets/img/user-image-placeholder.png'" />
                <span>{{ user.name }}</span>
              </li>
            </ul>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <input matInput placeholder="Produto Abordado" formControlName="productAddressed" />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('productAddressed')"></control-messages>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <input matInput placeholder="Classe do Produto" formControlName="productClass" />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('productClass')"></control-messages>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <input matInput placeholder="Tipo de Treinamento" formControlName="trainingType" />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('trainingType')"></control-messages>
          </div>

        </div>
        <div class="button-wrapper">
          <button class="btn-test primary" (click)="scheduleUpdate(i)">
            Salvar
          </button>
          <button class="btn-test remove-btn" (click)="confirmRemoveSchedule(i)">
            Excluir
          </button>
        </div>
      </div>
    </ng-container>
  </form>

  <button class="btn-test add" (click)="addSchedule()">
    + Adicionar dia de evento
  </button>
</div>
