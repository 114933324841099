import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExemptionService {

    constructor(private _httpService: BackendService) { }

    public createUserExemption(exemption: any): Observable<any> {
        return this._httpService.post('createExemptions', {
            UsersModulesExemptionsItems: exemption
        });
    }
}
