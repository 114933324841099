<cdk-virtual-scroll-viewport itemSize="30" [style.height.%]="100">
<table>
    <tr>
        <th class="center">Posição</th>
        <th class="left">Nome do Aluno</th>
        <th class="right">Média Nota</th>
    </tr>
      <ng-container *cdkVirtualFor="let student of ranking; let i = index">
        <tr>
          <td class="center">
            #{{i + 1}}
          </td>
          <td class="left">{{student?.name}}</td>
          <td class="right">{{showFactor ? student.factor?.toFixed(2) : student.grade?.toFixed(2)}}</td>
          <!-- {{student.factor?.toFixed(2)}}  -->
        </tr>
      </ng-container>
</table>
</cdk-virtual-scroll-viewport>

