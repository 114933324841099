import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { EStudyPlan } from 'src/app/models/enums/study-plan.enum';
import { ConnectDialogComponent } from '../connect-dialog/connect-dialog.component';
import { Econnect } from '../connect-dialog/Enum/connect.enum';
import { ISelectedItem } from '../connect-dialog/interface/selected-item.interface';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit, OnDestroy {

  public EnumStudyPlan = EStudyPlan;
  public dialogRefConnect: MatDialogRef<ConnectDialogComponent>;
  private selectedCourseSubject: Subject<{ type: Econnect, data: ISelectedItem }> = new Subject();
  constructor(public dialogRef: MatDialogRef<CreateDialogComponent>,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this.selectedCourseSubject.subscribe(value => {
      this.dialogRefConnect.close();
      this.dialogRef.close({ type: EStudyPlan.individual, userId: value.data.id, name: value.data.title });
    });
  }

  ngOnDestroy(): void {
    this.selectedCourseSubject.unsubscribe();
  }

  public dimiss() {
    this.dialogRef.close();
  }

  public selectType(type: EStudyPlan) {
    if (type === EStudyPlan.team) {
      this.dialogRef.close({ type });
    } else {
      this.openDialog();
    }
  }

  public openDialog() {
    this.dialogRefConnect = this._dialog.open(ConnectDialogComponent, {
      width: '800px',
      height: '80vh',
      closeOnNavigation: false,
      data: {
        type: Econnect.users,
        subject: this.selectedCourseSubject,
        notIn: []
      }
    });
  }
}
