// tslint:disable-next-line: max-line-length
const mobileRegex = new RegExp(/^(11|12|13|14|15|16|17|18|19|22|21|24|27|28|31|32|33|34|35|37|38|41|42|43|44|45|46|47|48|49|51|53|54|55|61|62|63|64|65|66|67|68|69|71|73|74|75|77|79|81|82|83|84|85|86|87|88|89|91|92|93|94|95|96|97|98|99){1}(([9][0-9]{1}[0-9]{3}[0-9]{4})|([6-9]{1}[0-9]{3}[0-9]{4}))$/mg);
// tslint:disable-next-line: max-line-length
const phoneRegex = new RegExp(/^(11|12|13|14|15|16|17|18|19|22|21|24|27|28|31|32|33|34|35|37|38|41|42|43|44|45|46|47|48|49|51|53|54|55|61|62|63|64|65|66|67|68|69|71|73|74|75|77|79|81|82|83|84|85|86|87|88|89|91|92|93|94|95|96|97|98|99){1}(([2-5]{1}[0-9]{7}))$/mg);

export function validarTelefoneCelular(tel) {
  const digitos = prepararTelefone(tel);

  if (digitos.length !== 11)
    return false;

  return mobileRegex.test(digitos);
}

export function validarTelefoneFixo(tel) {
  const digitos = prepararTelefone(tel);
  return phoneRegex.test(digitos);
}

function prepararTelefone(tel) {
  return tel.toString().replace(/[^\w\s]/gi, '').replace(/ /g, '');
}
