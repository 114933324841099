<div class="graph-result">

  <div class="stacked">
    <canvas id="stackedProgressCanvas" width="1200" height="400"></canvas>
  </div>
</div>

<div class="expected-progress">
  <h3>PROGRESSO ESPERADO: {{expectedProgress}}%</h3>
  <span>Notas atualizadas diariamente às 00:00h</span>
</div>

<mat-accordion>
  <mat-expansion-panel [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
      </mat-panel-title>
      <mat-panel-description>
        Nome
      </mat-panel-description>
      <mat-panel-description
        title="PROGRESSSO NA TRILHA (%): Total de Módulos Concluídos na Trilha / Total de Módulos da Trilha">
        Progresso Atual
      </mat-panel-description>
      <mat-panel-description>
        Nota Final
      </mat-panel-description>
      <mat-panel-description class="sort-btn" (click)="sortRanking()">
        Ranking <span [ngClass]="calcSortClass(currentSortDir)"></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel #Painel *ngFor="let item of users">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img class="logo" [src]="item.imageUrl ? item.imageUrl : './assets/img/user-image-placeholder.png'" />
      </mat-panel-title>
      <mat-panel-description [attr.title]="departmentInformation">
        <div (mouseenter)="getDepartmentInformation(item)">{{ item.name | uppercase}} </div>
      </mat-panel-description>
      <mat-panel-description>
        <div class="chips">
          <p class="progress-color" [ngClass]="item.progressColor">
            {{ item.progressUntilDay ? item.progressUntilDay : item.currentProgress| number: '1.2-2'}}%
          </p>
        </div>
      </mat-panel-description>
      <mat-panel-description [mdePopoverTriggerFor]="Popover" (opened)="setPopoverText(item.name, item)">
        {{ item.finalGrade }}
      </mat-panel-description>
      <mat-panel-description>
        {{ item?.isExemptionInAllModules ? 'Isento' : item?.ranking ? item.ranking + 'º' : 'Sem progresso' }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="table-wrap">
        <table mat-table *ngIf="item?.modules?.length > 0" [dataSource]="getModule(item.modules)"
          class="mat-elevation-z8">
          <ng-container matColumnDef="title">
            <th width="80%" mat-header-cell *matHeaderCellDef>Módulo</th>
            <td width="80%" mat-cell *matCellDef="let row" class="cell-module-name">
              {{row.title}}
              <div class="chips" *ngIf="row.isExemption">
                <p class="progress-color">
                  ISENTO
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="grade">
            <th width="10%" mat-header-cell *matHeaderCellDef>Nota</th>
            <td width="10%" mat-cell *matCellDef="let row">
              <div [mdePopoverTriggerFor]="Popover" (opened)="setPopoverText(item.name, row)">
                <span *ngIf="row?.grade"> {{row.grade | number: '1.2-2' }} </span>
                <span *ngIf="!row.grade"> - </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="started">
            <th width="10%" mat-header-cell *matHeaderCellDef> Iniciado </th>
            <td width="10%" mat-cell *matCellDef="let row; let i = index">
              <img class="table-img" [src]="getStatusImage(row,item, 0.1)" />
            </td>
          </ng-container>

          <ng-container matColumnDef="finished">
            <th width="10%" mat-header-cell *matHeaderCellDef> Finalizado </th>
            <td width="10%" mat-cell *matCellDef="let row">
              <img class="table-img" [src]="getStatusImage(row,item, 1)" />
            </td>
          </ng-container>

          <ng-container matColumnDef="nivel">
            <th width="10%" mat-header-cell *matHeaderCellDef> Nível </th>
            <td width="10%" mat-cell *matCellDef="let row">
              {{row.blockedLevel ? row.blockedLevel : row.isFinished && row.level === 4 ? 'Máximo' :  '-'}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

      <div class="table-wrap">
        <table mat-table *ngIf="item?.events?.length > 0" [dataSource]="item.events" class="mat-elevation-z8">
          <ng-container matColumnDef="title">
            <th width="80%" mat-header-cell *matHeaderCellDef>Evento</th>
            <td width="80%" mat-cell *matCellDef="let row">
              {{row.eventName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="applyed">
            <th width="10%" mat-header-cell *matHeaderCellDef> Inscrito </th>
            <td width="10%" mat-cell *matCellDef="let row">
              <img class="table-img" [src]="getEventStatusImage(row.date)" />
            </td>
          </ng-container>

          <ng-container matColumnDef="finalGrade">
            <th width="10%" mat-header-cell *matHeaderCellDef> Nota </th>
            <td width="10%" mat-cell *matCellDef="let row">
              <span *ngIf="row.finalGrade">{{row.finalGrade | number: '1.2-2' }} </span>
              <span *ngIf="!row.finalGrade"> - </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th width="10%" mat-header-cell *matHeaderCellDef> Data </th>
            <td width="10%" mat-cell *matCellDef="let row">
              <span *ngIf="row.date"> {{row.date | date: 'dd/MM/yyyy'}} </span>
              <span *ngIf="!row.date"> - </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="eventsDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: eventsDisplayedColumns;"></tr>
        </table>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

<mde-popover #Popover="mdePopover" mdePopoverPositionX="after" mdePopoverPositionY="below" mdePopoverTriggerOn="hover"
  [mdePopoverEnterDelay]="1000" [mdePopoverOverlapTrigger]="false">
  <mat-card class="calMemory-card">
    <mat-card-title>{{popverTitleName}} - Memória de cálculo</mat-card-title>
    <mat-card-content>
      <pre [innerHTML]="popverText"></pre>
    </mat-card-content>
  </mat-card>
</mde-popover>
