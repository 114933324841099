<app-content-menu *ngIf="showMenu" [contents]="contents" [menuContents]="allContents" [currentIndex]="index"
  [subjectProgress]="subjectProgress" [levels]="levels" [hasQuestionsSubject]="hasQuestionsSubject"
  (toggleMenu)="toggleMenu()" (goToContent)="goToContent($event)"></app-content-menu>

<div class="header" [ngClass]="{ 'offset': showMenu }">
  <div class="inner-content">
    <a href="javascript:history.back()">
      <img class="back" src="./assets/img/arrow-back-white.png" />
    </a>

    <p>{{ contents ? contents[index]?.title : '' }}</p>

    <div><img class="sub-menu" *ngIf="!showMenu" (click)="showMenu = !showMenu"
        src="./assets/img/menu-icon-white.png" /></div>
  </div>
</div>

<ng-container *ngIf="contents" [ngSwitch]="contents[index]?.type">

  <div class="menu" [ngClass]="{ 'offset': showMenu }">
    <app-content-video *ngSwitchCase="contentTypeEnum.Video" [setContent]="contents[index]"
      [setPosition]="conceptPosition" [canCreateTags]="canCreateTags" [setModuleTags]="moduleTags"
      [setModuleTagValuations]="moduleTagValuations" [setHelpCreateTags]="helpCreateTags"
      (saveVideoPlayedAction)="saveVideoPlayedAction()" (updateModuleTags)="getModuleTags()"
      (saveVideoFinishedAction)="saveVideoFinishedAction($event)">
    </app-content-video>

    <app-content-pdf *ngSwitchCase="contentTypeEnum.Pdf" #pdfContent [setContent]="contents[index]"
      [canCreateTags]="canCreateTags" [setModuleTags]="moduleTags" [setModuleTagValuations]="moduleTagValuations"
      (updateModuleTags)="getModuleTags()" (savePdfFinishedAction)="savePdfFinishedAction($event)">
    </app-content-pdf>

    <app-content-scorm *ngSwitchCase="contentTypeEnum.Scorm" (saveScormFinishedAction)="saveLinkFinishedAction($event)"
      [setContent]="contents[index]"></app-content-scorm>

    <app-content-html *ngSwitchCase="contentTypeEnum.Link" [setContent]="contents[index]"
      (saveHtmlFinishedAction)="saveLinkFinishedAction($event)"></app-content-html>

    <app-content-text *ngSwitchCase="contentTypeEnum.Text" [content]="contents[index]"
      (saveTextFinishedAction)="saveTextFinishedAction($event)">
    </app-content-text>

    <app-download *ngSwitchDefault (saveDownloadFinishedAction)="saveDownloadFinishedAction($event)"
      [setContent]="contents[index]"></app-download>


    <app-content-description *ngIf="contents[index]?.type != contentTypeEnum.Zip" #contentDescription
      [content]="contents[index]" [contentNote]="userContentNote" (goToPosition)="goToPosition($event)"
      (goToAnchor)="goToAnchor($event)" (saveConceptViewAction)="saveConceptViewAction($event)"
      (saveUserContentNote)="updateUserContentNote($event)" (helpCreateTag)="helpCreateTag($event)">
    </app-content-description>

    <app-content-footer *ngIf="contents[index]?.type != contentTypeEnum.Zip" [hasMultiple]="contents.length > 1"
      [isFirst]="index === 0" [isLast]="index === (contents.length - 1)" [contentId]="contents[index]?.id"
      [module]="module" [levels]="this.levels" [subjectId]="subjectId" [hasQuestions]="hasQuestions"
      [reachedMaxLevel]="reachedMaxLevel()" [hasFinishedRequirements]="hasFinishedRequirements"
      [isExemption]="isExemption" (forumQuestion)="openForumQuestionModal()" (goToPrevious)="goToPrevious()"
      (goToNext)="goToNext()">
    </app-content-footer>
  </div>

</ng-container>
