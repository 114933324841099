<cdk-virtual-scroll-viewport *ngIf="dataSource.length > 0" cdkDropList (cdkDropListDropped)="dropped($event)"
  itemSize="10" class="main">
  <div class="content-drop" [ngClass]="{'disable-point-event': disableDrag}" cdkDrag
    *cdkVirtualFor=" let data of dataSource; let i = index">
    <div class="content-wrapper">
      <b>{{ i + 1}}</b>
      <div class="content-sub-wrapper">
        <div>{{data.title | uppercase}}</div>
        <div class="type">{{data.type == EnumStudyPlanItem.module ? 'MÓDULO' : 'TRILHA'}}</div>
      </div>
    </div>
    <button mat-icon-button *ngIf="!disableDrag" [matMenuTriggerFor]="ModuleContentLink">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #ModuleContentLink="matMenu" style="position: absolute;">
      <button mat-menu-item (click)="remove.emit(data)">
        Remover
      </button>
    </mat-menu>
  </div>
</cdk-virtual-scroll-viewport>
<div class="empty" *ngIf="dataSource.length == 0">
  <app-empty-data></app-empty-data>
</div>
