import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-study-plan-header',
  templateUrl: './study-plan-header.component.html',
  styleUrls: ['./study-plan-header.component.scss']
})
export class StudyPlanHeaderComponent {

  @Input() defaultValue: string;
  @Output() planStudyNameEmiiter = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter();
  constructor() { }

}
