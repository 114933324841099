import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UploadService } from 'src/app/shared/services/upload.service';
import { RecruitingCompanyService } from 'src/app/recruitment/_services/recruiting-company.service';
import { SettingsUsersService } from '../_services/users.service';
import { NotificationClass } from 'src/app/shared/classes/notification';

import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CertificateEditDialogComponent } from './certificate-edit-dialog/certificate-edit.dialog';
import { CertificatePreviewComponent } from './certificate-preview/certificate-preview.component';

import { Certificate } from 'src/app/models/certificate';
import { RecruiterCompany } from 'src/app/models/recruiter-company.model';
import { isNil } from 'lodash';
@Component({
  selector: 'app-certificate-edit',
  templateUrl: './certificate-edit.component.html',
  styleUrls: ['./certificate-edit.component.scss']
})

export class CertificateEditComponent extends NotificationClass implements OnInit {

  public formGroup: FormGroup;
  public company: RecruiterCompany;
  public certificate: Certificate;

  constructor(
    private _settingsUsersService: SettingsUsersService,
    protected _snackBar: MatSnackBar,
    protected _uploadService: UploadService,
    private _usersService: SettingsUsersService,
    protected _recruitingCompanyService: RecruitingCompanyService,
    private _dialog: MatDialog,
    private _router: Router,
  ) {
    super(_snackBar);
  }

  ngOnInit(): void {
    this.formGroup = this._createCertificateEditForm();
    this._loadSettingsCertificate();
    this.checkInfoCompany();
  }

  private _createCertificateEditForm(): FormGroup {
    return new FormGroup({
      'borderColor': new FormControl(''),
      'name': new FormControl(''),
      'signature': new FormControl(''),
    });
  }

  private _loadSettingsCertificate(): void {
    this._usersService.getUserCertificateManange().subscribe((response) => {
      if (response.data) {
        this.formGroup.get('borderColor')
          .setValue((isNil(response.data.borderColor) || response.data.borderColor === '') ? '#6D9EEB' : response.data.borderColor);
        this.formGroup.get('name').setValue(response.data.name);
        this.formGroup.get('signature').setValue(response.data.signature);
      }
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  /*private _sendToServer(file) {
      this. _uploadService.uploadFile(file).subscribe((response) => {
        this.formGroup.get('signature').setValue(response);
        console.log(response);
      }, () => {
        this.notify('Ocorreu um erro ao anexar o arquivo, por favor tente novamente mais tarde');
      });
  }
*/

  public checkInfoCompany(): void {
    const Role = JSON.parse(localStorage.getItem('auth_data')).role;
    if (Role !== 'Admin') {
      this._recruitingCompanyService.getRecruitingCompany().subscribe(response => {
        this.company = response.data;
        if (this.company.socialName === 'prencher' ||
          this.company.address.city === '')
          this.dialog();
      });
    }

  }

  private dialog(): void {
    const dialogRef = this._dialog.open(CertificateEditDialogComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._router.navigate(['configuracoes/identificacao-empresa']);
      }
    });
  }

  public async toBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(
        this.formGroup.get('signature').setValue(reader.result));
      reader.onerror = error => reject(error);
    });
  }
  public async uploadImages(event): Promise<void> {
    const file = event.target.files[0];
    if (file) await this.toBase64(file);
  }

  public saveCertificateEdit() {
    const dialogRef = this._dialog.open(CertificatePreviewComponent, {
      width: '990px',
      data: Object.assign({}, this.company, this.formGroup.getRawValue()),
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const formGroup: Certificate = this.formGroup.getRawValue();
        this._settingsUsersService.updateUserCertificate(formGroup).subscribe(() => {
          this.notify('Certificado atualizado com sucesso');
        }, err => {
          this.notify(this.getErrorNotification(err));
        });
      }
    });
  }
}
