import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProgramPreview } from '../programs/Models/programPreview.model';
import { ProgramsService } from '../_services/programs.service';
import { take } from 'lodash';
import { Program } from './Models/program.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { DialogProgramsImportComponent } from './Dialogs/dialog-programs-import';
@Component({
  selector: 'app-settings-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {

  public readonly pageSize: number = 10;
  public readonly displayedColumns: string[] = [
    'name', 'startDate', 'createdAt', 'countTeams', 'countTeamStudents', 'action'
  ];
  public programs: Array<ProgramPreview> = [];
  public tempPrograms: Array<ProgramPreview> = [];
  public programsCount: number = 0;
  public firstLoad: boolean = false;

  private _page: number = 1;
  private _searchValue: string;

  constructor(
    protected _snackBar: MatSnackBar,
    private _programsService: ProgramsService,
    private _router: Router,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._loadPrograms();
  }

  public triggerSearch(searchValue: string) {
    this._searchValue = searchValue;
    this._loadPrograms(this._page, this._searchValue);
  }

  public goToPage(page: number) {
    if (page !== this._page) {
      this._page = page;
      this._loadPrograms(this._page, this._searchValue);
    }
  }

  public goToProgram(): void {
    this._router.navigate(['/configuracoes/programa']);
  }

  public viewProgramDetails(program: ProgramPreview) {
    this._router.navigate(['/configuracoes/programa/' + program.id]);
  }

  public handleResetFilter() {
    this._searchValue = '';
    this._loadPrograms(this._page, this._searchValue);
  }

  private _loadPrograms(page: number = this._page, searchTerm: string = ''): void {
    this._programsService.getPagedPrograms(
      page, this.pageSize,
      searchTerm
    ).subscribe((response) => {
      this.programs = take(response.data.programs, this.pageSize);
      this.programsCount = response.data.itemsCount;
      this.tempPrograms = [...this.programs];
    });
  }

  public deleteProgram(program: Program) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: { message: 'Tem certeza que excluir este programa?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._programsService.deleteProgram(program.id).subscribe(() => {
          this._loadPrograms(this._page, this._searchValue);
        });
      }
    });
  }

  public importProgram() {
    const dialogRef = this._dialog.open(DialogProgramsImportComponent, {
      width: '800px',
      closeOnNavigation: false,
      restoreFocus: false,
      data: { message: 'Tem certeza que excluir este programa?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._loadPrograms(this._page, this._searchValue);
      }
    });
  }


}
