import { UtilService } from 'src/app/shared/services/util.service';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { TrackEvent } from '../../../../../models/track-event.model';
import { ModuleWeights, Module } from 'src/app/models/module.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-new-modules-weight',
  templateUrl: './modules-weight.component.html',
  styleUrls: ['./modules-weight.component.scss']
})
export class NewModulesWeightComponent extends NotificationClass implements OnInit, OnDestroy {

  public readonly displayedColumns: string[] = [
    'content', 'weight'
  ];
  @Output() addModulesWeights = new EventEmitter<any>();
  public weight: ModuleWeights[];

  @Input() totalWeight: number = 0;
  @Input() module$: Observable<Module>;
  public module: Module;


  public events: Array<TrackEvent> = [];

  public moduleWeights: ModuleWeights[];
  public hasQuestions: boolean;
  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);

  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {

    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = cloneDeep(moduleItems);
        this.hasQuestions = moduleItems.hasQuestions ? moduleItems.hasQuestions : false;
        this.weight = cloneDeep(moduleItems.moduleWeights);
        if (moduleItems.hasQuestions === false)
          this.zeraWeights();
      });
    }
  }

  public nextStep(): void {

    if (this.totalWeight === 100 || this.hasQuestions === false) {
      this.addModulesWeights.emit({ moduleWeights: cloneDeep(this.weight), hasQuestions: cloneDeep(this.hasQuestions) } as Module);
    } else {
      this.notify('A soma dos pesos dos itens deve dar 100');
    }
  }

  public getCurrentProgress(): string {
    return this.totalWeight > 100 ? (0).toString() + '%' :
      (100 - this.totalWeight).toString() + '%';
  }

  public zeraWeights(): void {
    if(this.weight) this.weight.map(x => x.weight = 0);
    this.totalWeight = 0;
  }

  public setTotalValue() {
    this.totalWeight = 0;
    const weights = this.weight.map(x => x.weight);
    weights.forEach(weight => {
      this.totalWeight += weight ? weight : 0;
    });
  }


  public setRowValue(value: number, row: ModuleWeights) {

    if (value < 0) {
      this.notify('Valores negativos não são permitidos.');
      row.weight = 0;
      return false;
    }

    row.weight = +value;
    this.setTotalValue();
  }

  public setHasQuestionsValue(event): void {
    this.hasQuestions = !this.hasQuestions;
    if (this.hasQuestions === false) {
      this.zeraWeights();
    }
  }

  public isDisable(): boolean {
    return !this.hasQuestions;
  }

}
