<div class="inner-content" style="margin-top: 25px; margin-bottom: 30px;">
    <h2>GERENCIAR OPORTUNIDADES</h2>
    <!--div class="top">
        <img class="logo" [src]="logoUrl" style="max-height: 34px;"/>
        <button class="btn-test primary cursor-pointer" (click)="newJob()" >
            NOVA VAGA
        </button>
    </div-->
    <div class="center">
        <div class="top-down" >
            <!--div class="top-talent">
                <h1>
                    <span>{{availableCandidates.length}}</span> TALENTOS DISPONÍVEIS
                </h1>
                <button class="btn-test primary cursor-pointer" (click)="searchTalents()" >
                    BUSCAR TALENTO
                </button>
            </div-->
            <div class="down">
                <div class="down-title">
                    <h3>
                        <span>{{ num_openJobs }}</span> OPORTUNIDADES <b> &nbsp; PENDENTES DE AVALIAÇÃO</b>
                    </h3>
                    <p>
                        <button class="btn-test cursor-pointer" (click)="newJob()">
                            Nova Vaga
                        </button>
                    </p>
                </div>
                <ng-container style="text-align: center" *ngIf="num_openJobs === 0">
                    <div class="new-job">
                        <p><b>Você não tem vagas</b></p>
                        <p>Abra uma vaga e encontre os melhores talentos para sua empresa</p>
                    </div>
                </ng-container>
                <table mat-table [dataSource]="openJobs" *ngIf="num_openJobs > 0">
                    <ng-container matColumnDef="priority">
                        <th width="10%" mat-header-cell *matHeaderCellDef style="padding-left: 10px"> Prioridade </th>
                        <td width="10%" mat-cell *matCellDef="let row">
                            <div class="priority-color"
                            [ngClass]="{ 'high': row.priority === 1,
                            'medium': row.priority === 2,
                            'low': row.priority === 3}">
                            </div>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="name">
                        <th width="30%" mat-header-cell *matHeaderCellDef> Nome da Vaga </th>
                        <td width="30%" mat-cell *matCellDef="let row">
                            {{ row.title }}
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="date_due">
                        <th width="20%" mat-header-cell *matHeaderCellDef> Prazo de Conclusão </th>
                        <td width="20%" mat-cell *matCellDef="let row">
                            {{ row.dueTo | date:'dd/MM/yyy' }}
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="candidate_num">
                        <th width="30%" mat-header-cell *matHeaderCellDef> Nº de Candidatos </th>
                        <td width="30%" mat-cell *matCellDef="let row">
                            {{ row.candidatesCount ? row.candidatesCount : '0' }} Candidato(s)
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="edit">
                        <th width="10%" mat-header-cell *matHeaderCellDef></th>
                        <td width="10%" mat-cell *matCellDef="let row" >
                            <img src="./assets/img/edit.png" (click)="editJob(row.id)" />
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="arrow">
                        <th width="10%" mat-header-cell *matHeaderCellDef></th>
                        <td width="10%" mat-cell *matCellDef="let row" >
                            <i class="icon-seta_esquerda" style="color: rgba(0, 0, 0, 0.54);"></i>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="openDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: openDisplayedColumns;"
                        (click)="viewJobDetails(row)"
                    ></tr>
                </table>
                    
                <h2>
                    <span>{{ num_closedJobs }}</span> OPORTUNIDADES <b> &nbsp; FECHADAS</b>
                </h2>
                <table mat-table [dataSource]="closedJobs" *ngIf="num_closedJobs > 0">
                    <ng-container matColumnDef="priority">
                        <th width="10%" mat-header-cell *matHeaderCellDef style="padding-left: 10px"> Prioridade </th>
                        <td width="10%" mat-cell *matCellDef="let row">
                            <div class="priority-color"></div>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="name">
                        <th width="30%" mat-header-cell *matHeaderCellDef> Nome Vaga </th>
                        <td width="30%" mat-cell *matCellDef="let row">{{ row.title }}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="date_due">
                        <th width="20%" mat-header-cell *matHeaderCellDef> Prazo de Conclusão </th>
                        <td width="20%" mat-cell *matCellDef="let row">{{ row.dueTo | date:'dd/MM/yyy' }}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="candidate_num">
                        <th width="30%" mat-header-cell *matHeaderCellDef> Nº de Candidatos </th>
                        <td width="30%" mat-cell *matCellDef="let row">{{ row.candidatesCount ? row.candidatesCount : '0' }} Candidatos</td>
                    </ng-container>
            
                    <ng-container matColumnDef="arrow">
                        <th width="10%" mat-header-cell *matHeaderCellDef></th>
                        <td width="10%" mat-cell *matCellDef="let row" >
                            <i class="icon-seta_esquerda" style="color: rgba(0, 0, 0, 0.54);"></i>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="closedDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: closedDisplayedColumns;"
                        (click)="viewJobDetails(row)"
                    ></tr>
                </table>
            </div>
        </div>
        <div class="content" >
            <app-top-bottom-talents
                [talents]="numCadidatesFavorite"
            ></app-top-bottom-talents>
        </div>
    </div>
    
</div>
