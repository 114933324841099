<div class="header">
  <mat-form-field>
    <mat-label>Nome do Plano de Estudos</mat-label>
    <input matInput class="input-search" [value]="defaultValue"
      (change)="planStudyNameEmiiter.emit($event.target.value)">
  </mat-form-field>
  <button class="btn-test" appDebounceClick (debounceClick)="buttonClick.emit()">
    <mat-icon style="margin-right: 10px;">save</mat-icon>
    Salvar Alterações
  </button>
</div>
