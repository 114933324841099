import { Component, Input } from '@angular/core';
import { AttendedEvent } from 'src/app/models/track-overview.interface';

@Component({
  selector: 'app-track-overview-events-participation',
  templateUrl: './events-participation.component.html',
  styleUrls: ['./events-participation.component.scss']
})
export class TrackOverviewEventsParticipationComponent {

  @Input() readonly events: Array<AttendedEvent> = [];

  public getEventStatus(event: AttendedEvent): string {
    if (event.presence === null) return '--';
    return event.presence ? 'Finalizado' : 'Não Compareceu';
  }

}
