import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Filters } from 'src/app/models/filters.model';
import { IRequestFilter } from '../Interface/request-filter';
import { IRequesrtSort } from '../Interface/request-sort';

@Injectable({
    providedIn: 'root'
})
export class MyCoursesService {

    private sort: Subject<IRequesrtSort> = new Subject();
    private filter: Subject<IRequestFilter[]> = new Subject();
    constructor() {
    }

    public getFilter(): Subject<IRequestFilter[]> {
        return this.filter;
    }

    public getSort(): Subject<IRequesrtSort> {
        return this.sort;
    }

    public setFilters(filters: IRequestFilter[]) {
        this.filter.next(filters);
    }

    public setSort(sort: IRequesrtSort) {
        this.sort.next(sort);
    }
}
