import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { SettingsValuationTestsService } from '../_services/valuation-tests.service';
import { ValuationTest, ValuationTestExcel, ValuationTestResponse } from 'src/app/models/valuation-test.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ValuationTestTypeEnum } from 'src/app/models/enums/valuation-test-type-enum';
import { environment } from 'src/environments/environment';
import { isNil } from 'lodash';

@Component({
  selector: 'app-settings-valuation-tests',
  templateUrl: './valuation-tests.component.html',
  styleUrls: ['./valuation-tests.component.scss']
})
export class ValuationTestsComponent extends NotificationClass implements OnInit {

  public tests: Array<ValuationTest>;
  public readonly pageSize: number = 10;
  private _testsPage: number = 1;
  private _searchValue: string;
  private _createdSince: Date = null;
  public _itemsCount: number = 0;
  private tracksSelected: Array<string> = [];
  private modulesSelected: Array<string> = [];
  public replacement = environment.replecement;

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: MatDialog,
    private _testsService: SettingsValuationTestsService,
    private _excelService: ExcelService
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    localStorage.removeItem('editingValuationTest');
    this._loadTests();
  }

  public createNewTest(): void {
    this._router.navigate(['configuracoes/teste-de-avaliacao']);
  }

  public viewTest(test: ValuationTest): void {
    if (test && test.type !== ValuationTestTypeEnum.Mock) {
      this._router.navigate(['configuracoes/teste-de-avaliacao/repostas/' + test.id]);
    }
  }

  public manageTest(test: ValuationTest): void {
    const testStringify = JSON.stringify(test);
    localStorage.setItem('editingValuationTest', testStringify);
    this._router.navigate(['configuracoes/teste-de-avaliacao']);
  }

  public deleteTest(test: ValuationTest) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '1000px',
      data: { message: 'Tem certeza que deseja remover este teste?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._testsService.deleteValuationTest(test.id).subscribe(() => {
          this._loadTests();
          this.notify('Teste deletado com sucesso');

        });
      }
    });
  }

  public getAnswersExcel(test: ValuationTest): void {
    this._testsService.getAllValuationTestResponses(
      test.id
    ).subscribe((response) => {
      this._excelService.exportAsExcelFile(
        this._prepareAnswersForExport(response.data),
        test.title
      );

    }, (error) => this.notify(this.getErrorNotification(error)));
  }

  private _loadTests(): void {
    this._testsService.getValuationTests(
      this._testsPage,
      this.pageSize,
      this._searchValue,
      this._createdSince,
      this.modulesSelected,
      this.tracksSelected
    ).subscribe((response) => {
      this.tests = response.data.tests;
      this._itemsCount = response.data.itemsCount;
    });
  }

  public goToPage(page: number): void {
    if (page !== this._testsPage) {
      this._testsPage = page;
      this._loadTests();
    }
  }

  private _prepareAnswersForExport(responses: Array<ValuationTestResponse>): Array<ValuationTestExcel> {
    const answers: Array<ValuationTestExcel> = [];

    responses.forEach(response => {
      response.answers.forEach(x => {
        const answer = {
          aluno: response.userName,
          matricula: response.userRegisterId,
          questao: x.question,
          answer: x.answer,
          data: response.createdAt
        };
        if (!isNil(x.moduleTitle)) {
          answer['Módulo'] = x.moduleTitle;
          answer['Código do Módulo'] = x.moduleId;
        }
        if (!isNil(x.subjectTitle)) {
          answer['Assunto'] = x.subjectTitle;
          answer['Código do Assunto'] = x.subjectId;
        }
        answers.push(answer);
      });
    });

    return answers;
  }

  public setValuationTestTermFilter($event: string) {
    this._searchValue = $event;
    this._loadTests();
  }

  public setCreatedSince($event: Date) {
    this._createdSince = $event;
    this._loadTests();
  }

  setModules($event: Array<string>) {
    this.modulesSelected = $event;
    this._loadTests();
  }

  setTracks($event: Array<string>) {
    this.tracksSelected = $event;
    this._loadTests();
  }

  public goToPageValuationGroup() {
    return this._router.navigate(['configuracoes/grupo-avaliações']);
  }
}
