import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserCategoryFilterSearchOption } from 'src/app/settings/manage-team/filters/filters.interface';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { Data } from 'src/app/shared/components/generate-valuation-test-check-options/model/data.model';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { IFilter } from '../filters/services/interface/filter.interface';
import { TeamsFiltersService } from '../filters/services/team-filters.service';

export class UserDataSource extends DataSource<Data | undefined> {
    public cachedFacts = Array.from<Data>({ length: 0 });
    public dataStream = new BehaviorSubject<(Data | undefined)[]>(this.cachedFacts);
    private subscription = new Subscription();

    public readonly pageSize: number = 20;
    public page: number = 1;
    private _createdSince: Date = null;
    private _sortDirection: boolean = null;
    private _sortActive: string = '';
    private _selectedFilters: Array<UserCategoryFilterSearchOption> = [];
    public user: LoginResponse;

    private lastPage = 1;

    constructor(private _usersService: SettingsUsersService,
        private _authService: AuthService,
        private _teamsFiltersService: TeamsFiltersService
    ) {
        super();
        _authService.isLoggedIn().subscribe((x) => {
            this.user = _authService.getLoggedUser();
        });
        this._teamsFiltersService.getFilters().subscribe(value => {
            this.cachedFacts = [];
            this._loadUsers(1, value);
        });


    }

    connect(collectionViewer: CollectionViewer): Observable<(Data | undefined)[] | ReadonlyArray<Data | undefined>> {
        this.subscription.add(collectionViewer.viewChange.subscribe(range => {

            const currentPage = this._getPageForIndex(range.end);

            if (currentPage && range) {
                console.log(currentPage, this.lastPage);
            }

            if (currentPage > this.lastPage) {
                this.lastPage = currentPage;
                this._loadUsers(this.lastPage);
            }
        }));
        return this.dataStream;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subscription.unsubscribe();
    }

    private _loadUsers(page: number, filters: IFilter = {} as IFilter): void {
        this._usersService.getFilteredUserToManage(
            page, this.pageSize,
            filters.searchTerm, filters.createdSince,
            filters.categoryFilters,
            this.user.have_dependents ? this.user.name : '',
            this._sortActive, this._sortDirection,
            true
        ).subscribe((response) => {
            const users = response.data.userItems.map(user => ({
                    id: user.id,
                    title: user.name,
                    checked: false
                } as Data));

            this.cachedFacts = this.cachedFacts.concat(users);
            this.dataStream.next(this.cachedFacts);
        });
    }

    private _getPageForIndex(i: number): number {
        const offset = Math.floor(i / (this.pageSize * this.lastPage));
        return offset === 1 ? this.lastPage + 1 : offset;
    }

}
