<div class="nps-answer">
  <h4 *ngIf="data.title">{{ data.title }}</h4>
  <p [ngClass]="{'left': textAlignLeft }" translate="no" [innerHTML]="data.text | MarkdownToHtml" ></p>

  <div>
    <div class="radio-options">
      <ng-container *ngFor="let item of [].constructor(11); let i = index">
        <label class="container">
          <input type="radio" name="answer" (click)="setSliderValue(i)"
            [checked]="formGroup.get('answer').value === i" />
          <span class="checkmark"></span>
          <span class="radio-value">{{i}}</span>
        </label>
      </ng-container>
    </div>
  </div>
</div>
