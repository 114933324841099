export interface IRequesrtSort {
     alphabetic: boolean;
     dueDate: boolean;
     progress: boolean;
}

export enum ERequesrtSort {
     alphabetic = 'alphabetic',
     dueDate = 'dueDate',
     progress = 'progress',
}
