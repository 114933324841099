import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { MaterialComponentsModule } from '../../shared/material.module';
import { DateRangePickerModule } from '../../shared/components/date-range-picker/date-range.picker.module';
import { LogsComponent } from './logs.component';
import { LogsService } from '../_services/logs.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatIconModule } from '@angular/material/icon';
import { LogsDialogComponent } from './logs-dialog/logs-dialog.component';
import { FormsModule } from '@angular/forms';
import { LogsIntegrationsComponent } from './logs-integrations/logs-integrations.component';

@NgModule({
    declarations: [
        LogsComponent,
        LogsDialogComponent,
        LogsIntegrationsComponent,
    ],
    imports: [
        BrowserModule,
        MaterialComponentsModule,
        PaginationModule,
        DateRangePickerModule,
        NgxDatatableModule,
        MatIconModule,
        FormsModule
    ],
    providers: [
        LogsService
    ]
})
export class LogsModule { }
