<div>
  <h3>{{ data.testTitle }}</h3>

  <div class="content">
    <div id="htmlEditor"></div>
  </div>

  <div class="text-inputs">

    <mat-form-field [ngClass]="{'dislabe-points-events': data.testGenerated === testGenerated.Automatically}">
      <mat-select placeholder="Tipo de Pergunta" [(ngModel)]="data.type" required>
        <mat-option [value]="questionType.MultipleChoice">
          Múltipla-Escolha
        </mat-option>
        <mat-option [value]="questionType.Discursive">
          Discursiva
        </mat-option>
        <mat-option [value]="questionType.NPS">
          NPS
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="data.type !== 4">
      <input matInput type="number" min="1" max="100"
        placeholder="Peso da Questão (1 a 100)"
        [(ngModel)]="data.percentage" required />
    </mat-form-field>

    <div class="div-checkbox" *ngIf="data.type !== 4">
      <mat-checkbox name="canceled" [(ngModel)]="data.canceled"> Anular Questão </mat-checkbox>
      <label for="canceled">*Anulando uma questão você estará dando a pontuação da questão a todos os alunos que
        responderam ou irão responder a avaliação</label>
    </div>
  </div>
  <div [ngClass]="{'dislabe-points-events': data.testGenerated === testGenerated.Automatically}">
    <div class="answers" *ngIf="data.type && data.type === questionType.MultipleChoice">
      <table>
        <thead>
          <tr>
            <th>Resposta</th>
            <th></th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let option of data.options; let index = index">
            <td>
              <textarea matInput placeholder="Resposta" [ngClass]="{'dislabe-points-events': data.notEditable}"
                [(ngModel)]="option.text" required></textarea>
            </td>
            <td>
              <img [src]="getAnswerIconSrc(option)" (click)="toggleAnswer(option)" />
            </td>
            <td>
              <img src="./assets/img/trash.png" (click)="removeAnswer(index)" />
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="data.testGenerated !== testGenerated.Automatically">
          <tr>
            <td>
              <button [disabled]="data.notEditable" class="btn-test primary" (click)="addAnswer()">
                + Adicionar Resposta
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <ng-container *ngIf="hasComment">
    <h3 class="comment-title">Comentário</h3>
    <div class="editor-wrapper comment-wrapper">
      <div id="htmlCommentEditor"></div>
    </div>
  </ng-container>

  <ng-container>
    <div class="actions">
      <button class="btn-outline" (click)="dismiss()">
        Cancelar
      </button>
      <button class="btn-test" (click)="save()">
        Salvar
      </button>
    </div>
  </ng-container>
</div>
