import { Injectable } from '@angular/core';
import { isEmpty, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ImetricsStudents,
  Istudents,
  IstudentsRanking,
  ItrackInfo,
  ItrackModule,
  ItracksStudentsSubordinates,
} from '../cockpit/models/trackStudentsSubordinates';

@Injectable()
export class CockpitService {
  private tracksStudentsSubordinates: Array<ItracksStudentsSubordinates>;
  private selectedTrack = new BehaviorSubject<ItracksStudentsSubordinates>({} as ItracksStudentsSubordinates);
  private students = new BehaviorSubject<Array<Istudents>>([]);
  private title = new BehaviorSubject<string>('');
  private studentsRanking: Array<IstudentsRanking> = [];
  private trackModules: Array<ItrackModule> = [];
  private studentsMetrics = new BehaviorSubject<ImetricsStudents>({} as ImetricsStudents);
  private studentsModuleMetrics: ImetricsStudents = {} as ImetricsStudents;
  private selectedStudents = new BehaviorSubject<any>({});

  constructor() {}

  public setTracksStudentsSubordinates(tracksStudentsSubordinates: Array<ItracksStudentsSubordinates>) {
    this.tracksStudentsSubordinates = tracksStudentsSubordinates;
  }

  public setStudents(students: Array<Istudents>) {
    this.students.next(students);
  }

  public setStudentsRankings(studentsRanking: Array<IstudentsRanking>) {
    this.studentsRanking = studentsRanking;
  }

  public setTrackModules(trackModules: Array<ItrackModule>) {
    this.trackModules = trackModules;
  }

  public setStudentsMetrics(studentsMetrics: ImetricsStudents) {
    this.studentsMetrics.next(studentsMetrics);
  }

  public setStudentsModuleMetrics(data): ImetricsStudents {
    const studentsMetrics: ImetricsStudents = {} as ImetricsStudents;
    studentsMetrics.averageDiagnosticTest = data.averageDiagnosticTest;
    studentsMetrics.averageDiagnosticTestTop10 = data.top10.averageDiagnosticTest;
    studentsMetrics.averageTest = data.averageTest;
    studentsMetrics.averageTestTop10 = data.top10.averageTest;
    studentsMetrics.learning = data.learning;
    studentsMetrics.learningTop10 = data.top10.averageLearning;
    studentsMetrics.averageBdqGrade = data.averageBdqGrade;
    studentsMetrics.averageBdqGradeTop10 = data.top10.averageBdq;
    studentsMetrics.expectedInteractionUntilDayInHours = data.expectedInteractionUntilDayInHours;

    studentsMetrics.effort = {
      expected: data.expectedInteractionUntilDay || 0,
      real: data.averageStudentsInteraction || 0,
      expectedInHours: data.expectedInteractionUntilDayInHours,
      realInHours: data.averageStudentsInteractionInHours

    };
    studentsMetrics.engagement = {
      expected: data.expectedProgressUntilDay || 0,
      real:
      // O valor é multiplicado por 10 para deixar os valores em base 100
        (data.students.reduce((total, currentValue) => total + currentValue.progressUntilDay, 0) /
          (this.getStudents().getValue().length + 1) || 0) * 10,
    };
    studentsMetrics.participation = {
      real: data.averageStudentsParticipation
    };

    this.studentsModuleMetrics = studentsMetrics;

    return this.studentsModuleMetrics;
  }

  public setTitle(title: string) {
    this.title.next(title);
  }

  public setSelectedTrack(selectedTrack: ItracksStudentsSubordinates) {
    this.selectedTrack.next(selectedTrack);
  }
  public setSelectedStudents(selectedStudents) {
    this.selectedStudents.next(selectedStudents);
  }

  public getTracksStudentsSubordinates() {
    return this.tracksStudentsSubordinates;
  }

  public getStudents() {
    return this.students;
  }

  public getStudentsRankings() {
    return this.studentsRanking;
  }

  public getTrackModules() {
    return this.trackModules;
  }

  public getStudentsMetrics() {
    return this.studentsMetrics;
  }

  public getTitle() {
    return this.title;
  }

  public getSelectedTrack() {
    return this.selectedTrack;
  }

  public getSelectedStudents() {
    return this.selectedStudents;
  }

  public getStudentsModuleMetrics() {
    return this.studentsModuleMetrics;
  }
}
