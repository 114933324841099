import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil, orderBy } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, skip, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CockpitService } from '../../_services/cockpit.service';
import { ContentTracksService } from '../../_services/tracks.service';
import {
  Eselect,
  Ifilter,
  ImetricsStudents,
  Istudents,
  IstudentsRanking,
  ItrackInfo,
  ItrackModule,
  ItracksStudentsSubordinates,
} from '../models/trackStudentsSubordinates';

const heightMatOption = 48;
@Component({
  selector: 'app-cockpit-header',
  templateUrl: './cockpit-header.component.html',
  styleUrls: ['./cockpit-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() selectedTrackChange = new EventEmitter<Ifilter>();
  @Output() selectedStudentsChange = new EventEmitter<Ifilter>();

  public replacement = environment.replecement;
  public selectedTrack: ItracksStudentsSubordinates;
  public selectedStudent: any;
  public AllStudents = Eselect.AllStudents;
  public tracks: Array<ItracksStudentsSubordinates>;
  public userNotCameFromTrack: boolean;
  public trackId: string;
  public moduleId: string;
  public filters: Ifilter = {} as Ifilter;
  public title: string = '';
  private subscription: Subscription;
  private subscriptionStudents: Subscription;
  public students: Array<Istudents> = [];
  public studentsRanking: Array<IstudentsRanking> = [];
  public trackModules: Array<ItrackModule> = [];
  public studentsMetrics: ImetricsStudents = {} as ImetricsStudents;
  public inputSubscription: Subject<string> = new Subject();

  constructor(
    private _cockpitService: CockpitService,
    private _cdr: ChangeDetectorRef,
    private _tracksService: ContentTracksService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.trackId = this._activatedRoute.firstChild.snapshot.params['trackId'];
    this.userNotCameFromTrack = (this._activatedRoute.firstChild.snapshot.routeConfig.path === ':trackId');

    this.inputSubscription.pipe(skip(1), debounceTime(500)).subscribe((value) => this._loadTracks(value));

    this._loadTracks('', this.handleSelectTrackFilterChange.bind(this));
  }

  ngAfterViewInit(): void {
    this.subscription = this._cockpitService.getTitle().subscribe((title) => {
      if (title) this.title = title;
      this._cdr.detectChanges();
    });

    this.subscriptionStudents = this._cockpitService.getSelectedStudents().subscribe((student) => {
      if (student) this.selectedStudent = student;
      this._cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();

    if (this.subscriptionStudents) this.subscriptionStudents.unsubscribe();
  }

  private _loadTracks(searchTerm: string = '', callback: Function = null): void {
    this._tracksService.getTrackHasStudentsSubordinate(searchTerm).subscribe((response) => {
      this.tracks = response.data;
      this.selectedStudent = this.selectedStudent || Eselect.AllStudents;

      this._cockpitService.setTracksStudentsSubordinates(this.tracks);

      if (callback !== null) {
        const trackId = isNil(this.trackId) ? this.tracks[0] : { id: this.trackId, title: '' };
        callback(trackId, this.userNotCameFromTrack);
      }

      this._cdr.detectChanges();
    });
  }

  public handleSelectTrackFilterChange(selectedTrack: ItracksStudentsSubordinates, userNotCameFromModule: boolean = true): void {
    const trackId = selectedTrack.id;
    this._tracksService.GetStudentsSubordinateByTrackId(trackId).subscribe((response) => {
      if (selectedTrack.title === '') {
        const trackInfo = response.data[0].trackInfo.find(track => track.id === selectedTrack.id);
        selectedTrack.title = trackInfo.title;

      }
      this.selectedTrack = selectedTrack;
      this.selectedTrack.students = response.data;
      this.students = selectedTrack.students;
      this._cockpitService.setSelectedTrack(selectedTrack);
      this._cockpitService.setTitle(selectedTrack.title);
      this._cockpitService.setStudents(this.students);
      const studentIds = this.students.map((student) => student.id);

      this._tracksService.getCockpitTrackMetrics(trackId, studentIds).subscribe((resp) => {
        this._cockpitService.setTrackModules(resp.data.moduleConfigurates);
        this._loadStudentsRanking(resp.data.students);
        this._loadMetricsStudents(resp.data);
      });

      if (userNotCameFromModule) this.goTotrack(trackId);

    });
  }

  public goTotrack(trackId) {
    this._router.navigate(['empenho-desempenho/' + trackId]);
  }

  public handleSelectStudentsFilterChange($event) {
    this._cockpitService.setSelectedStudents($event);
  }

  private _loadStudentsRanking(students: Array<any>): void {
    this.studentsRanking = [];
    this.students.forEach((student) => {
      students.forEach((stdranking) => {
        if (student.id === stdranking.id) {
          const ranking: IstudentsRanking = {
            id: student.id,
            name: student.name,
            email: student.email,
            grade: stdranking.grade,
            ranking: stdranking.ranking,
            learning: stdranking.learning,
            averageEventGrade: stdranking.averageEventGrade,
            interactionUntilDay: stdranking.interactionUntilDay,
            interactionUntilDayInHours: stdranking.interactionUntilDayInHours || 0,
            progressUntilDay: stdranking.progressUntilDay,
            diagnostic: stdranking.diagnostic || 0,
            valuation: stdranking.valuation || 0,
            participation: stdranking.participation || 0
          };

          this.studentsRanking.push(ranking);
        }
      });
    });
    this.studentsRanking = orderBy(this.studentsRanking, ['grade'], ['desc']);
    this._cockpitService.setStudentsRankings(this.studentsRanking);
  }

  private _loadMetricsStudents(response): void {
    this.studentsMetrics.averageDiagnosticTest = response.averageDiagnosticTest;
    this.studentsMetrics.averageDiagnosticTestTop10 = response.top10.averageDiagnosticTest;
    this.studentsMetrics.averageEvents = response.averageEvents;
    this.studentsMetrics.averageEventsTop10 = response.top10.averageEvents;
    this.studentsMetrics.averageTest = response.averageTest;
    this.studentsMetrics.averageTestTop10 = response.top10.averageTest;
    this.studentsMetrics.learning = response.learning;
    this.studentsMetrics.learningTop10 = response.top10.averageLearning;
    this.studentsMetrics.expectedInteractionUntilDayInHours = response.expectedInteractionUntilDayInHours;

    this.studentsMetrics.effort = {
      expected: response.expectedInteractionUntilDay || 0,
      real: response.averageStudentsInteraction,
      expectedInHours: response.expectedInteractionUntilDayInHours,
      realInHours: response.averageStudentsInteractionInHours
    };
    this.studentsMetrics.engagement = {
      expected: response.expectedProgressUntilDay || 0,
      real: response.averageProgressUntilDay || 0,
    };
    this.studentsMetrics.participation = {
      real: response.averageStudentsParticipation
    };

    this._cockpitService.setStudentsMetrics(this.studentsMetrics);
  }

  public onKey(searchTerm: string) {
    this.inputSubscription.next(searchTerm);
  }
  public openedChange(event) {
    if (event) {
      const input = document.getElementById('input-search') as HTMLInputElement;
      input.value = '';
    } else {
      this.inputSubscription.next('');
    }
  }
}
