import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable, of } from 'rxjs';
import { ContentTypeEnum } from 'src/app/models/enums/content-type.enum';
import { EMixedAnswersQuestions } from 'src/app/models/enums/mixed-answers-questions.enum';

@Injectable()
export class ContentExamService {

  constructor(private _httpService: BackendService) { }

  public startExam(moduleId: string, subjectId: string): Observable<any> {
    return this._httpService.post('examstart', {
      'moduleId': moduleId,
      'subjectId': subjectId
    });
  }

  public answerQuestion(
    moduleId: string, subjectId: string, questionId: string, answerId: string,
    moduleName = '', concepts: Array<string> = [], trackId?: string, skipped: boolean = false, isMixedQuestion: boolean = false,
    canGetMixedQuestion: boolean = false, type: EMixedAnswersQuestions = null
  ): Observable<any> {
    return this._httpService.post('examanswer', {
      'moduleId': moduleId,
      'subjectId': subjectId,
      'questionId': questionId,
      'answerId': answerId,
      'moduleName': moduleName,
      'concepts': concepts,
      'trackId': trackId,
      'skipped': skipped,
      'isMixedQuestion': isMixedQuestion,
      'canGetMixedQuestion': canGetMixedQuestion,
      'type': type
    });
  }

  public navigatePracticeQuestion(
    moduleId: string, subjectId: string, questionId: string, trackId?: string,
    previous: boolean = false
  ): Observable<any> {
    return this._httpService.post('navigatePracticeQuestion', {
      'moduleId': moduleId,
      'subjectId': subjectId,
      'questionId': questionId,
      'trackId': trackId,
      'previous': previous
    });
  }
}
