import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-certificate-edit-dialog',
    template: `
    <div class="certificate-dialog" >
      <h2>
        Para configurar o certificado você necessita completar o cadastro de sua empresa
      </h2>
    </div>
    <p style="padding-bottom: 20px;" (click)="dismiss(true)">OK</p>`,
    styleUrls: ['./certificate-edit.dialog.scss']
})
export class CertificateEditDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<CertificateEditDialogComponent>,
    ) { }

    public dismiss(state: boolean): void {
        this.dialogRef.close(state);
    }
 }
