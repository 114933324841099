import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryEnum } from 'src/app/models/enums/category.enum';
import {
  CategoryFilterOption,
  UserCategoryFilter,
  UserCategoryFilterSearch,
  UserCategoryFilterSearchOption
} from 'src/app/settings/manage-team/filters/filters.interface';
import { TeamsFiltersService } from './services/team-filters.service';

@Component({
  selector: 'app-filters-team',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @Input() filters: Array<UserCategoryFilter> = [];
  @Input() userActive = true;
  @Input() dateActive = true;
  @Output() search: EventEmitter<UserCategoryFilterSearch> = new EventEmitter();
  @Output() setCreatedSince: EventEmitter<Date> = new EventEmitter();
  @Output() setUserTermFilter: EventEmitter<string> = new EventEmitter();

  public createdSince: Date;
  private _searchValue: string = '';
  private _selectedFilters: Array<UserCategoryFilterSearchOption> = [];

  constructor(private _teamsFiltersService: TeamsFiltersService) { }

  ngOnInit() {
    this._getCategories();
  }

  public removeFilter(filter: UserCategoryFilter, opt: CategoryFilterOption) {
    const index = filter.options.findIndex(x => x.id === opt.id);
    if (index !== -1) {
      filter.options[index].checked = false;
    }
    this.setFilters(filter, opt);
  }

  public setFilters(filter: UserCategoryFilter, opt: CategoryFilterOption) {
    const index = filter.selectedOptions.findIndex(x => x.id === opt.id);
    if (index === -1) {
      filter.selectedOptions.push(opt);
    } else {
      filter.selectedOptions.splice(index, 1);
    }
    this.updateFilters(this.filters);
  }

  public updateFilters(filters: Array<UserCategoryFilter>) {

    for (let i = 0; i < filters.length; i++) {
      this._selectedFilters[i].contentNames = [];
      filters[i].selectedOptions.forEach(opt => {
        const value = filters[i].demandsId ? opt.id : opt.description;
        this._selectedFilters[i].contentNames.push(value);
      });
    }

    this._teamsFiltersService.setUserCategoryFilterSearchOption(this._selectedFilters);

  }

  public triggerUserSearch(searchValue: string) {
    this._teamsFiltersService.setSearchTerm(searchValue);
    this.setUserTermFilter.emit(searchValue);
  }

  public createSince(value: Date) {
    this._teamsFiltersService.setCreatedSince(value);
  }

  public triggerSearch(searchValue: string, filter: UserCategoryFilter) {
    this._searchValue = searchValue;
    filter.page = 1;

    this.search.emit(
      {
        value: searchValue,
        filter: filter,
        page: filter.page,
        prop: filter.isAlternate ? 'title' : 'name'
      }
    );
  }

  public moreItens(filter: UserCategoryFilter) {
    filter.page = filter.page + 1;
    this.search.emit(
      {
        value: this._searchValue,
        filter: filter,
        page: filter.page,
        prop: filter.isAlternate ? 'title' : 'name'
      }
    );
  }

  public maxDateFilter(currentDate: Date): boolean {
    const today = new Date();
    return currentDate <= today;
  }

  public inputChange(value: string, opt: CategoryFilterOption) {
    opt.description = value;
    opt.id = value;
  }

  public moreInputs(filter: UserCategoryFilter) {
    filter.options.push(
      {
        customInput: true,
        checked: false,
        id: '',
        description: ''
      }
    );
  }

  private _getCategories() {

    const userTypes: any[] = [
      { id: 'student', name: 'Estudante' },
      { id: 'secretary', name: 'Secretaria' },
      { id: 'humanResources', name: 'RH' },
      { id: 'recruiter', name: 'Recrutador' },
      { id: 'admin', name: 'Admin' },
      { id: 'blocked', name: 'Bloqueados' },
      { id: 'notBlocked', name: 'Não Bloqueados' }
    ];

    const userTeam: any[] = [
      { id: 'noClass', name: 'Sem turma' },
    ];

    this._setFilter('Outros', CategoryEnum.Users, 'userTeam',
      userTeam.length, userTeam, 'name', true, true);

    this._setFilter('Perfis de Usuário', CategoryEnum.Users, 'userType',
      userTypes.length, userTypes, 'name', true, true);

  }

  private _setFilter(
    filterName: string, category: number, filterColumn: string,
    itemsCount: number, collection: any[], prop: string = 'name',
    demandsId: boolean = false, hideSearch: boolean = false
  ) {
    const newFilter: UserCategoryFilter = {
      name: filterName,
      category: category,
      filterColumn: filterColumn,
      page: 1,
      itemsCount: itemsCount,
      maxLength: collection.length,
      options: [],
      selectedOptions: [],
      isAlternate: prop === 'title',
      demandsId: demandsId,
      hideSearch: hideSearch
    };
    for (let index = 0; index < collection.length; index++) {
      newFilter.options.push({
        id: collection[index].id,
        description: collection[index][prop],
        checked: false
      });
    }

    let arrayPosition = 0;

    if (this.filters.length > 0) {
      arrayPosition = this.filters.filter(f =>
        f.category < category
      ).length;
    }

    this.filters.splice(arrayPosition, 0, newFilter);
    this._selectedFilters.splice(
      arrayPosition, 0,
      { columnName: filterColumn, contentNames: [] }
    );
  }
}
