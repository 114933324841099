import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SettingsUsersService } from '../../_services/users.service';
import { IUsersItemBlocked } from '../Models/users-items-blocked.interface';

@Component({
  selector: 'app-users-items-blocked',
  templateUrl: './users-items-blocked.component.html',
  styleUrls: ['./users-items-blocked.component.scss']
})
export class UsersItemsBlockedComponent implements OnInit {

  public readonly pageSize: number = 10;
  public page: number = 1;
  public readonly displayedColumns = ['module', 'subject', 'actions'];
  public itensCount: number = 0;
  public name: string;
  public usersSubjectBlocked: Array<IUsersItemBlocked> = [];
  public userId: string;
  private _searchUser: Subject<string> = new Subject();
  constructor(private _usersService: SettingsUsersService,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userId = this._activatedRoute.snapshot.params['studentId'];
    this._loadUsersSubjectBlocked();
    this._setSearchSubscription();
  }

  private _loadUsersSubjectBlocked(searchValue: string = '') {
    this._usersService.getPagedBlockedSubjectsByUserId(
      { page: this.page, blocked: true, pageSize: this.pageSize, searchValue: searchValue, userId: this.userId }).subscribe(result => {
        this.usersSubjectBlocked = result.data.pagedItem;
        this.name = result.data.name;
        this.itensCount = result.data.itemsCount;
      });
  }

  public goToPage(page: number) {
    if (page !== this.page) {
      this.page = page;
      this._loadUsersSubjectBlocked();
    }
  }

  public unlockUserSubject(moduleId: string, userId: string, subjectId: string) {
    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      data: {
        // tslint:disable-next-line: max-line-length
        message: 'Atenção a ação que você está preste a fazer é destrutiva, ao confirmar a ação todo o progresso desse usuário será apagado.\nDeseja continuar?',
        positiveTextAction: 'Sim',
        negativeTextAction: 'Não'
      } as SimpleDialog
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._usersService.unlockUserSubject(userId, subjectId, moduleId).subscribe(() => {
          const index = this.usersSubjectBlocked.findIndex(subBlocked =>
            subBlocked.moduleId === moduleId && subBlocked.subjectId === subjectId);

          if (index === -1) throw new Error('Assunto não encontrado');
          this.usersSubjectBlocked.splice(index, 1);
          this.usersSubjectBlocked = [...this.usersSubjectBlocked];
        });
      }
    });
  }

  public updateSearch(searchTextValue: string) {
    this._searchUser.next(searchTextValue);
  }

  private _setSearchSubscription() {
    this._searchUser.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.page = 1;
      this._loadUsersSubjectBlocked(searchValue);
    });
  }
}
