<h3 mat-dialog-title>{{ data?.questionIndex !== undefined ? 'Questão ' + (data.questionIndex + 1) : 'Nova Questão' }}</h3>

<mat-dialog-content>
  <div class="content" [class.is-fullscreen]="isFullScreen">
    <div class="editor-wrapper">
      <div id="htmlEditor"></div>
    </div>
    <div *ngIf="!isFullScreen" class="info">
      <mat-form-field>
        <mat-select placeholder="Dificuldade" required
          [(ngModel)]="data.question.level">
          <mat-option [value]="level.id" *ngFor="let level of data.levels">
            {{ level.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="isString(data.question.duration)">
        <mat-form-field>
          <input matInput placeholder="Tempo de Resposta Esperado (hh:mm:ss)"
            mask="00:00:00" required
            [(ngModel)]="data.question.duration" />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!isString(data.question.duration)">
        {{ getFormattedByDuration(data.question.duration) }}
      </ng-container>

      <div *ngIf="!isFullScreen">
        <button class="btn-test" (click)="toogleFullScreen()">
          Visualizar questão
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isFullScreen">
    <div class="concepts">
      <app-concepts-select
        [setConcepts]="concepts"
        [selectedConcepts]="selectedConcepts"
        (updateConcepts)="updateConcepts($event)"></app-concepts-select>
    </div>

    <div class="answers">
      <table>
        <thead>
          <tr>
            <th>Conteúdo</th>
            <th>Pontuação</th>
            <th *ngFor="let concept of selectedConcepts">
              {{ concept }}
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let answer of data.question.answers; let index = index">
            <td>
              <textarea matInput placeholder="Resposta"
                [(ngModel)]="answer.description" required></textarea>
            </td>
            <td>
              <mat-select [(ngModel)]="answer.points">
                <mat-option [value]="-1">-1</mat-option>
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
              </mat-select>
            </td>
            <td *ngFor="let concept of selectedConcepts">
              <img [src]="getAnswerIconSrc(answer, concept)"
                (click)="toggleAnswer(answer, concept)" />
            </td>
            <td>
              <img
                src="./assets/img/trash.png"
                (click)="removeAnswer(index)" />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <button class="btn-test primary" (click)="addAnswer()">
                + Adicionar Resposta
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <ng-container *ngIf="hasComment">      
      <h3 class="comment-title">Comentário</h3>
      <div class="editor-wrapper comment-wrapper">
        <div id="htmlCommentEditor"></div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="!isFullScreen">
    <button class="btn-outline" (click)="dismiss()">
      Cancelar
    </button>
    <button class="btn-test" (click)="save()">
      Salvar
    </button>
  </ng-container>
  <ng-container *ngIf="isFullScreen">
    <button class="btn-test" (click)="toogleFullScreen()">
      Voltar para edição da questão
    </button>
  </ng-container>
</mat-dialog-actions>
