import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Content } from 'src/app/models/content.model';

@Component({
  selector: 'app-content-scorm',
  templateUrl: './scorm.component.html',
  styleUrls: ['./scorm.component.scss']
})
export class ScormComponent {

  @Input() set setContent(content: Content) {
    this.content = content;
  }
  @Output() saveScormFinishedAction: EventEmitter<string> = new EventEmitter();
  public content: Content;

  constructor() { }

  public openPopUp(url: string) {
    window.open(
      url,
      'pagina',
      'width=350, height=255, top=100, left=110, scrollbars=no');
  }
}
