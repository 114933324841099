<ng-container *ngIf="career?.shortDateObjectives || career?.longDateObjectives">
  <h4 class="exp">OBJETIVO PROFISSIONAL</h4>
  <div style="border-bottom: 1px solid #999;"></div>
  <div *ngIf="career?.shortDateObjectives">
      <h4 class="company">Objetivos de curto prazo</h4>
      <p class="occupation">{{career?.shortDateObjectives}}</p>
  </div>
  <div *ngIf="career?.longDateObjectives">
      <h4 class="company">Objetivos de longo prazo</h4>
      <p class="occupation">{{career?.longDateObjectives}}</p>
  </div>
  <br>
</ng-container>

<ng-container *ngIf="career?.professionalExperiences?.length > 0">
  <h4 class="exp">EXPERIÊNCIA PROFISSIONAL</h4>
  <div style="border-bottom: 1px solid #999;"></div>
  <div *ngFor="let professionalExperience of career?.professionalExperiences">
    <h4 class="company">{{professionalExperience?.title}}</h4>
    <p class="occupation">{{professionalExperience?.role}}</p>
    <p class="period">
      {{ professionalExperience.startDate | date: 'dd/MM/yyyy' }} - 
      {{ professionalExperience.endDate ? (professionalExperience.endDate | date: 'dd/MM/yyyy') : 'atualmente' }}
    </p>
    <p class="description">{{professionalExperience?.description}}</p>
    <br>
    <div style="border-bottom: 1px solid #999;"></div>
  </div>
  <br>
</ng-container>

<ng-container *ngIf="career?.colleges?.length > 0">
  <h4 class="exp">HISTÓRICO ACADÊMICO</h4>
  <div style="border-bottom: 1px solid #999;"></div>
  <div *ngFor="let college of career?.colleges">
    <h4 class="company">{{ college.title }} {{ college.campus ? '(' + college.campus + ')' : '' }}</h4>
    <p class="occupation">{{college.name}}</p>
    <p class="occupation">CR: {{ college.cr }}</p>
    <p class="period">{{ college.startDate | date: 'dd/MM/yyyy' }} - {{ college.endDate | date: 'dd/MM/yyyy' }}</p>
    <br>
    <div style="border-bottom: 1px solid #999;"></div>
  </div>
  <br>
</ng-container>

<ng-container *ngIf="career?.certificates?.length > 0">
  <h4 class="exp">CERTIFICADOS</h4>
  <div style="border-bottom: 1px solid #999;"></div>
  <div *ngFor="let certificate of career?.certificates; let last = last">
    <h4 class="company">{{ certificate.title }}</h4>  
    <br>
    <div *ngIf="last === true" style="border-bottom: 1px solid #999;"></div>
  </div>
  <br>
</ng-container>

<ng-container *ngIf="career?.rewards?.length > 0">
  <h4 class="exp">PRÊMIO E RECONHECIMENTOS</h4>
  <div style="border-bottom: 1px solid #999;"></div>
  <div *ngFor="let reward of career?.rewards">
    <div class="reward">
      <div>
        <h4 class="company">{{reward?.title}}</h4>
        <p class="occupation">{{reward?.name}}</p>
        <p class="period">{{reward?.date | date: 'dd/MM/yyyy'}}</p>
      </div>
      <ng-container *ngIf="reward?.link != null && reward?.link != ''">
        <a [href]="reward?.link" target="_blank" style="height: fit-content; margin: auto 0px;">
          <button class="btn-test">Ver</button>
        </a>
      </ng-container>
    </div>
    <br>
    <div style="border-bottom: 1px solid #999;"></div>
  </div>
  <br>
</ng-container>

<ng-container *ngIf="career?.languages?.length > 0">
  <h4 class="exp">IDIOMAS</h4>
  <div class="cards-slider" >
    <div class="slider-grid">
      <div class="progress-card" *ngFor="let language of career?.languages">
        <p class="title">{{language?.names}}</p>
        <p style="color: rgba(0, 0, 0, 0.32);">{{language?.level}}</p>
      </div>
    </div>
  </div>
  <br>
</ng-container>

<ng-container *ngIf="career?.abilities?.length > 0">
  <h4 class="exp">FORMAÇÃO COMPLEMENTAR</h4>
  <div class="cards-slider" >
    <div class="slider-grid">
      <div class="progress-card" *ngFor="let skill of career?.abilities">
          <p class="title">{{skill?.name}}</p>
          <p style="color: rgba(0, 0, 0, 0.32);">{{skill?.level}}</p>
      </div>
    </div>
  </div>
  <br>
</ng-container>

<div class="travel-info" *ngIf=career?.travelAvailability>
  <p>Disponível para Viagens</p>
</div>
