import { NgModule } from '@angular/core';
import { ConceptTagComponent } from './concept-tag.component';

@NgModule({
  declarations: [
    ConceptTagComponent
  ],
  exports: [
    ConceptTagComponent
  ]
})
export class ConceptTagModule { }
