<div class="filters">

  <app-user-search
    (userRemoved)="removeStudent($event)"
    (userSelected)="setStudent($event)">
  </app-user-search>

  <app-autocomplete
    [filter]="filters?.track"
    (triggerSearch)="setupUpdateFilter(filters?.track,$event)"
    (change)="updateFilterOptions(filters?.track, $event)"
    (remove)="removeFilter(filters?.track, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.modules"
    (triggerSearch)="setupUpdateFilter(filters?.modules,$event,true)"
    (change)="updateFilterOptions(filters?.modules, $event)"
    (remove)="removeFilter(filters?.modules, $event)">
  </app-autocomplete>

</div>
