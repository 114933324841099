import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { SettingsValuationTestsService } from '../../_services/valuation-tests.service';
// tslint:disable-next-line: max-line-length
import {
  ValuationTest,
  ValuationTestQuestion,
  TestModule,
  TestTrack,
  TestSubject,
  ValuationTestQuestionTypeEnum,
  TestSchedules,
} from 'src/app/models/valuation-test.interface';
import { ManageValuationQuestionDialogComponent } from './manage-valuation-question/manage-valuation-question.dialog';
import { UploadValuationTestDialogComponent } from './upload-valuation-test/upload-valuation-test-dialog/upload-valuation-test.dialog';
import { CourseworkDialogComponent } from './coursework-dialog/coursework.dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { SettingsModulesService } from '../../_services/modules.service';
import { SettingsTracksService } from '../../_services/tracks.service';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';

import { GenereteValuationTestQuestionsDialogComponent } from './generete-valuation-test-questions-dialog/generete-valuation-test-questions-dialog.component';
import { isNil } from 'lodash';
import {
  ValuationTestTypeEnum,
  ValuationTestTypeGeneredEnum,
} from 'src/app/models/enums/valuation-test-type-enum';
import { SettingsManageValuationTestReleaseComponent } from '../manage-valuation-test-release/manage-valuation-test-release.component';
import { Type } from './generete-valuation-test-questions-dialog/model/generate-valuation-test';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { ValuationTestUploadResponse } from './upload-valuation-test/model-upload/model-upload';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
// tslint:disable-next-line: max-line-length
import { GenerateValuationTestCheckOptionsComponent } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.component';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { EventSchedule } from 'src/app/models/event-schedule.model';
import { SettingsEventsService } from '../../_services/events.service';


@Component({
  selector: 'app-settings-manage-valuation-test',
  templateUrl: './manage-valuation-test.component.html',
  styleUrls: ['./manage-valuation-test.component.scss'],
})
export class SettingsManageValuationTestComponent extends NotificationClass implements OnInit {
  @ViewChild('ManageValuationTestRelease', { static: true })
  public manageValuationTestRelease: SettingsManageValuationTestReleaseComponent;
  @ViewChild('checkedComponent')
  public checkedComponent: GenerateValuationTestCheckOptionsComponent;
  @ViewChild('checkedModuleComponent')
  public checkedModuleComponent: GenerateValuationTestCheckOptionsComponent;

  public test: ValuationTest;
  public modules: Array<TestModule> = [];
  public tracks: Array<TestTrack> = [];
  public subjects: Array<TestSubject> = [];
  public selectedModules: Array<TestModule> = [];
  public selectedTracks: Array<TestTrack> = [];
  public selectedModuleTracksDisplayed: Array<TestTrack> = [];
  public selectedsSubjects: Array<TestSubject> = [];
  public events: Array<EventPreview> = [];
  public selectedEvents: Array<TestSchedules> = [];
  public subjectToAdd: TestSubject;
  public subjectIdToRemove: string;
  public moduleToAdd: TestModule;

  public get getTest() {
    return this.manageValuationTestRelease.getTest;
  }
  public get getTrackThatModuleWillBeDisplayed() {
    return this.checkedComponent.dataSelected;
  }

  public testType = ValuationTestTypeEnum;
  public testTypeGenerated = ValuationTestTypeGeneredEnum;
  public GenerateTarget = Type;
  public tracksContainsModuleById = [];
  public pageSize: number = 4;
  public readonly key: string = 'moduleTracks';
  public readonly allContent: number = 0;
  public isTargetModule = false;

  constructor(
    protected _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _testsService: SettingsValuationTestsService,
    private _router: Router,
    private _dialog: MatDialog,
    private _contentTracksService: ContentTracksService,
    private _modulesService: SettingsModulesService,
    private _tracksService: SettingsTracksService,
    private _eventsService: SettingsEventsService,
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadModules();
    this._loadTracks();
    this._loadEvents();

    const valuationStr = localStorage.getItem('editingValuationTest');
    if (valuationStr && valuationStr.trim() !== '') {
      this.test = JSON.parse(valuationStr);
      this.selectedModules = this.test.testModules;
      this.selectedTracks = this.test.testTracks;
      this.selectedEvents = this.test.testSchedules || [];
      if (this.test.generated === ValuationTestTypeGeneredEnum.Automatically) {
        this._loadModules('', this.allContent);
        if (this.test.generatedBy === Type.Module) {
          this.selectedModules[0].tracks.forEach((track) => (track.checked = true));
          this.isTargetModule = true;
          this.pageSize = 60;
          this.loadTrackContainsModulesbyId(this.selectedModules);
        } else {
          this._loadTracks('', this.allContent);
        }
      }
      this.updateModules();
      this.updateTracks();
    } else {
      this.test = new ValuationTest();
    }
  }

  public openQuestionDialog(question: ValuationTestQuestion = null) {
    if (question == null && this.test.hasAnswers && this.test.type === ValuationTestTypeEnum.Free) return this.openWarningDialog();

    if (!question) question = new ValuationTestQuestion();

    question.testTitle = this.test.title;
    question.testId = this.test.id;
    question.testGenerated = this.test.generated || null;
    question.notEditable = this.test.hasAnswers || false;
    question.isMock = this.test.type === this.testType.Mock;
    const dialogRef = this._dialog.open(ManageValuationQuestionDialogComponent, {
      width: '1000px',
      maxHeight: '100vh',
      data: question,
    });

    dialogRef.afterClosed().subscribe((editedQuestion: ValuationTestQuestion) => {
      if (this.test.generated !== ValuationTestTypeGeneredEnum.Automatically) {
        if (editedQuestion) {
          question = editedQuestion;
          question.hasChange = true;
          if (!question.id && question.newQuestion) {
            question.hasChange = false;
            this.test.testQuestions.push(question);
            question.newQuestion = false;
          }
        }
        this.test.generated = ValuationTestTypeGeneredEnum.Manually;
      }
    });
  }

  public confirmRemoveQuestion(index: number): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja remover esta pergunta?' },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this._removeQuestion(index);
    });
  }

  public save(): void {
    const sumPercentages = this.test.testQuestions.filter((question) =>
      question.type !== ValuationTestQuestionTypeEnum.NPS).reduce((sum, q) => sum + q.percentage, 0);

    const saveTest = () => {
      this._testsService.manageValuationTest(this.test).subscribe(
        () => {
          this.notify('Salvo com sucesso!');
          this._router.navigate(['configuracoes/testes-de-avaliacao']);
        },
        (error) => this.notify(this.getErrorNotification(error))
      );
    };

    if (sumPercentages && +sumPercentages.toFixed(2) !== 100) {
      this.notify('A soma dos pesos das questões deve ser 100. Total:' + sumPercentages.toFixed(2));
      return;
    }

    if (this.manageValuationTestRelease) {
      if (this.manageValuationTestRelease.checkTest()) {
        this.test = this.getTest;
      }
    }

    if (this.test.generated === ValuationTestTypeGeneredEnum.Automatically && this.isTargetModule) {
      if (this.selectedModules.length > 0) {
        this.selectedModules.forEach((module) => {
          const selectedModule = this.getTrackThatModuleWillBeDisplayed.find((moduleTracks) => moduleTracks.id === module.id);
          module.tracks = !isNil(selectedModule) ? selectedModule[this.key] : [];
        });
      }
    }

    this.test.moduleIds = this.selectedModules.map((x) => x.id);
    this.test.trackIds = this.selectedTracks.map((x) => x.id);
    this.test.testModules = this.selectedModules;
    this.test.testTracks = this.selectedTracks;

    if (this.test.type === this.testType.Mock) {
      this.test.subjectIds = this.selectedsSubjects.map((x) => x.id);
      this.test.testSubjects = this.selectedsSubjects;
    }

    if (!isNil(this.test.hasAnswers) && this.test.hasAnswers) {
      const dialogRef = this._dialog.open(SimpleDialogComponent, {
        hasBackdrop: true,
        closeOnNavigation: false,
        disableClose: true,
        data: {
          // tslint:disable-next-line: max-line-length
          message: 'Esse teste já foi respondindo por alguns alunos.\nVocê realmente deseja continuar com essa alteração ?',
          positiveTextAction: 'Sim',
          negativeTextAction: 'Não',
        } as SimpleDialog,
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) saveTest();
      });
    } else {
      saveTest();
    }
  }

  private _removeQuestion(index: number): void {
    this.test.testQuestions.splice(index, 1);
  }

  public checkTest(test: ValuationTest): boolean {
    this.test.moduleIds = this.selectedModules.map((x) => x.id);
    this.test.trackIds = this.selectedTracks.map((x) => x.id);
    this.test.testModules = this.selectedModules;
    this.test.testTracks = this.selectedTracks;

    this.test.testSchedules = this.selectedEvents;

    if ((this.test && this.test.testTracks) || (this.test && this.test.testModules) || (this.test && this.test.testSchedules)) return true;

    return false;
  }

  private _loadModules(searchValue: string = '', pagesize: number = 4): void {
    this._modulesService.getPagedFilteredModulesList(1, pagesize, searchValue).subscribe((response) => {
      response.data.modules.forEach((mod: ModulePreview) => {
        mod.checked = this.selectedModules.find((t) => t.id === mod.id) && true;
      });
      this.modules = response.data.modules;
    });
  }

  private _loadTracks(searchValue: string = '', pageSize: number = 4): void {
    this._tracksService.getPagedFilteredTracksList(1, pageSize, searchValue).subscribe((response) => {
      response.data.tracks.forEach((tck: TrackPreview) => {
        tck.checked = this.selectedTracks.find((t) => t.id === tck.id) && true;
      });
      this.tracks = response.data.tracks;
    });
  }

  public updateModules(): void {
    const prevSelected = this.selectedModules.filter((x) => !this.modules.find((t) => t.id === x.id));
    const selectedModules = this.modules.filter((x) => x.checked);
    selectedModules.forEach((mod) => {
      mod.toggled = true;
    });
    this.selectedModules = [...prevSelected, ...selectedModules];
    if (this.test.type === this.testType.Mock) {
      this.selectedModules.forEach(selectedModule => {
        setTimeout(() => {
          this.moduleToAdd = selectedModule;
        }, 10);
      });
      this.subjects = this.test.otherSubjects;
      this.selectedsSubjects = this.test.testSubjects;
      this.selectedsSubjects.forEach(selectedsSubject => {
        setTimeout(() => {
          this.subjectToAdd = selectedsSubject;
        }, 10);
      });
    }
  }

  public updateTracks(): void {
    const prevSelected = this.selectedTracks.filter((x) => !this.tracks.find((t) => t.id === x.id));
    const selectedTracks = this.tracks.filter((x) => x.checked);
    this.selectedTracks.forEach((track) => (track.toggled = true));
    this.selectedTracks = [...prevSelected, ...selectedTracks];
  }

  public triggerModuleSearch(searchValue: string) {
    this._loadModules(searchValue, this.pageSize);
  }

  public triggerTrackSearch(searchValue: string) {
    this._loadTracks(searchValue);
  }

  public removeSelectedModule(id: string): void {
    const selectedIndex = this.selectedModules.findIndex((x) => x.id === id);
    if (selectedIndex !== -1) {
      this.selectedModules.splice(selectedIndex, 1);
    }

    const moduleIndex = this.modules.findIndex((x) => x.id === id);
    if (moduleIndex !== -1) {
      this.modules[moduleIndex].checked = false;
    }
  }

  public removeSelectedTrack(id: string) {
    const selectedTrackIndex = this.selectedTracks.findIndex((x) => x.id === id);
    this.selectedTracks.splice(selectedTrackIndex, 1);
    const trackIndex = this.tracks.findIndex((x) => x.id === id);
    if (trackIndex !== -1) {
      this.tracks[trackIndex].checked = false;
    }
  }

  public removeSelectedModuleTracksDisplayed(id: string) {
    const selectedTrackIndex = this.selectedModules[0].tracks.findIndex((x) => x.id === id);
    this.selectedModules[0].tracks.splice(selectedTrackIndex, 1);
  }

  public openCourseworkDialog(question: ValuationTestQuestion = null): void {
    if (!question) question = new ValuationTestQuestion();

    question.testTitle = this.test.title;
    question.testId = this.test.id;

    const dialogRef = this._dialog.open(CourseworkDialogComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: question,
    });

    dialogRef.afterClosed().subscribe((curseWork: ValuationTestQuestion) => {
      if (curseWork) this.test.testQuestions[0] = curseWork;

      this.test.generated = ValuationTestTypeGeneredEnum.Manually;
    });
  }

  public opendDialogGenerateValuationTest(question: ValuationTestQuestion = null): void {
    if (this.test.hasAnswers) return this.openWarningDialog();

    if (!question) question = new ValuationTestQuestion();

    question.testTitle = this.test.title;
    question.testId = this.test.id;

    const dialogRef = this._dialog.open(GenereteValuationTestQuestionsDialogComponent, {
      width: '1000px',
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe((generateTest: { questions: ValuationTestQuestion[]; module: any; track: any; target: number }) => {
      if (generateTest) {
        if (generateTest.questions.length) {
          this.test.testQuestions = generateTest.questions;
        }
        if (generateTest.target === Type.Module) {
          this.selectedModules[0] = {
            id: generateTest.module.id,
            title: generateTest.module.description,
            checked: true,
            tracks: [],
          };
          this.isTargetModule = true;
          this.loadTrackContainsModulesbyId(this.selectedModules);
          this.test.generatedBy = Type.Module;
        } else {
          this.selectedTracks.push({
            id: generateTest.track.id,
            title: generateTest.track.description,
            modulesConfiguration: generateTest.track.listModules.map((module, index) => ({
              id: module.id,
              title: module.description,
              order: index,
            })),
            checked: true,
          });
          this._loadTracks('', this.allContent);
          this.test.generatedBy = Type.Track;
        }
        this.test.generated = ValuationTestTypeGeneredEnum.Automatically;
        this._loadModules('', this.allContent);
      }
    });
  }

  public getText(generated: ValuationTestTypeGeneredEnum, type: string) {
    if (this.test.type === this.testType.Mock) {
      if (type === 'track') {
        return 'Escolha a(s) trilha(s) que o simulado será aplicado';
      } else if (type === 'module') {
        return 'Escolha o(s) módulo(s) que o simulado será aplicado';
      } else {
        return 'Escolha o(s) assunto(s) que o simulado será aplicado. É necessário escolher um módulo antes';
      }
    } else {
      if (type === 'track') {
        if (generated === ValuationTestTypeGeneredEnum.Automatically) {
          return 'Trilha(s) que o teste será aplicado';
        } else {
          return 'Escolha a(s) trilha(s) que o teste será aplicado';
        }
      } else if (type === 'module') {
        if (generated === ValuationTestTypeGeneredEnum.Automatically) {
          return 'Módulo(s) que o teste será aplicado';
        } else {
          return 'Escolha o(s) módulo(s) que o teste será aplicado';
        }
      }
    }
  }

  public loadTrackContainsModulesbyId(module: TestModule[]) {
    const moduleIds = module.map(mod => mod.id);
    this._contentTracksService.getTrackContainsModuleById(moduleIds).subscribe(response => {
      const tracksContainsModuleById = response.data.map(modules => {
        const moduleTrack = this.test.testModules.find(m => m.id === modules.id);
        modules[this.key].forEach(moduletrack => {
          const hasTrack = moduleTrack.tracks.filter(t => t.id === moduletrack.id).length;
          moduletrack['checked'] = Boolean(hasTrack);
        });
        return modules;
      });
      this.tracksContainsModuleById = tracksContainsModuleById;
    });
  }

  public handleModuleSelected(modules: any[]) {
    if (this.test.type === this.testType.Mock) {
      const selectedModules = modules.map((module) => ({
        id: module.id,
        title: module.title,
        checked: true,
        tracks: [],
        subjects: module.subjects ? module.subjects.map((subject) => ({
          id: subject.id,
          title: subject.title
        })) : []
      }));
      this.subjects = [];
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        if (module.subjects && module.subjects.length > 0) {
          for (let j = 0; j < module.subjects.length; j++) {
            const subject = module.subjects[j];
            this.subjects.push({
              id: subject.id,
              title: subject.title,
              module: {
                id: module.id,
                title: module.title
              }
            });
          }
        }
      }
      for (let i = 0; i < this.selectedModules.length; i++) {
        const selectedModule = this.selectedModules[i];
        if (!modules.some(x => x.id === selectedModule.id)) {
          for (let j = 0; j < selectedModule.subjects.length; j++) {
            const selectedModuleSubject = selectedModule.subjects[j];
            setTimeout(() => {
              this.subjectIdToRemove = selectedModuleSubject.id;
              const index = this.selectedsSubjects.findIndex(x => x.id === selectedModuleSubject.id);
              if (index !== -1) {
                this.selectedsSubjects.splice(index, 1);
              }
            }, 10);
          }
        }
      }
      this.selectedModules = selectedModules;
    } else {
      const selectedModules = modules.map((module) => ({
        id: module.id,
        title: module.title,
        checked: true,
        tracks: []
      }));
      this.selectedModules = selectedModules;
      if (this.selectedModules) this.loadTrackContainsModulesbyId(this.selectedModules);
    }
  }

  public handleTrackSelected(tracks: TestTrack[]) {
    if (!this.isTargetModule) {
      const selectedTracks = tracks.map((track) => ({
        id: track.id,
        title: track.title,
        modulesConfiguration: track.modulesConfiguration.map((module, index) => ({
          id: module.moduleid,
          title: module.title,
          order: module.order
        })),
        checked: true
      }));
      this.selectedTracks = selectedTracks;
    }
  }

  public handleSubjectSelected(subjects: TestSubject[]) {
    const selectedSubjects = subjects.map((subject) => ({
      id: subject.id,
      title: subject.title,
      module: subject.module,
      checked: true
    }));
    this.selectedsSubjects = selectedSubjects;
  }

  public openDialogImportQuestion(): void {
    const dialogRef = this._dialog.open(UploadValuationTestDialogComponent, {
      width: '1000px',
      data: { title: this.test.title, testId: this.test.id },
    });

    dialogRef.afterClosed().subscribe((result: ValuationTestUploadResponse) => {
      if (result) {
        if (result.addQuestions) {
          result.questions.forEach((quest) => {
            this.test.testQuestions.push(quest);
          });
        } else {
          this.test.testQuestions = result.questions;
        }
      }
    });
  }

  public openWarningDialog(): void {
    const ref = this._dialog.open(SimpleDialogComponent, {
      autoFocus: true,
      data: {
        message: `Essa avalição já possui resposta de alguns alunos.
      Para substituir ou incluir mais questões a esse avaliação faça uma cópia dela. `,
        positiveTextAction: 'Ok',
      } as SimpleDialog,
    });
  }

  public triggerEventSearch(searchValue: string) {
    this._loadEvents(searchValue);
  }

  private _loadEvents(searchValue: string = ''): void {
    this._eventsService.getPagedFilteredEventsList(
      1, 4, searchValue, true
    ).subscribe(response => {
      response.data.events.forEach((ev: EventPreview) => {
        ev.checked = this.selectedEvents.find(t => t.id === ev.id) && true;
      });
      this.events = response.data.events;
    });
  }

  public removeSelectedEvent(schedule: EventSchedule) {
    const selectedIndex = this.selectedEvents.findIndex(x => x.id === schedule.id);
    this.selectedEvents.splice(selectedIndex, 1);

    const eventIndex = this.events.findIndex(x => x.id === schedule.eventId);
    if (eventIndex === -1) return;
    this.events[eventIndex]['checked'] = false;
  }

  public updateEvents(schedule: EventSchedule): void {
    const currentEvent = this.events.find(ev => ev.id === schedule.eventId);
    this.selectedEvents = currentEvent.checked ?
      [...this.selectedEvents, {
        id: schedule.id,
        eventId: schedule.eventId,
        title: schedule.eventTitle,
        eventDate: schedule.eventDate
      } as TestSchedules] :
      this.selectedEvents.filter(ev => ev.eventId !== schedule.eventId);
  }
}
