import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamsComponent } from './teams.component';
import { TeamComponent } from './team/team.component';
import { FiltersComponent } from './filters/filters.component';
import { FiltersComponent as FiltersComponentTeam } from './team/filters/filters.component';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { MatCardModule } from '@angular/material/card';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { GenerateValuationTestCheckOptionsModule } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.module';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { ProgramsSelectModule } from 'src/app/shared/components/programs-select/programs-select.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MatIconModule } from '@angular/material/icon';
import { ImportExcelFilesModule } from 'src/app/shared/components/import-excel-files/import-excel-files.module';
import { ImportDialogComponent } from './team/dialogs/import-user-dialog/import-user-dialog';
import { DialogTeamsImportComponent } from './Dialogs/dialog-teams-import';


@NgModule({
  declarations: [
    TeamsComponent,
    TeamComponent,
    FiltersComponent,
    FiltersComponentTeam,
    DialogTeamsImportComponent,
    ImportDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    MatCardModule,
    ListSearchModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    GenerateValuationTestCheckOptionsModule,
    NavigationBackModule,
    ProgramsSelectModule,
    DirectivesModule,
    MatIconModule,
    ImportExcelFilesModule
  ]
})
export class TeamsModule { }
