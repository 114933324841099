<div class="content">
  <span class="warning">
    <!-- <mat-icon class="material-icons-outlined">info</mat-icon> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
      <g id="Elipse_309" data-name="Elipse 309" fill="none" [attr.stroke]="primary_color" stroke-width="2">
        <circle cx="23.5" cy="23.5" r="23.5" stroke="none" />
        <circle cx="23.5" cy="23.5" r="22.5" fill="none" />
      </g>
      <text id="i" transform="translate(9 8)" [attr.fill]="primary_color" font-size="25"
        font-family="Montserrat-SemiBold, Montserrat" font-weight="600">
        <tspan x="10.888" y="24">i</tspan>
      </text>
    </svg>

  </span>
  <div class="warning-legend">
    <p class="title">Porque as métricas de empenho são importantes ?</p>
    <p class="sub-title">As métricas de empenho mostram a dedicação dada pela pessoa à plataforma. Seja através da
      contabilização do esforço em horas da pessoa no uso da plataforma, quanto esteve engajada na finalização de
      atividades ou quanto contribuiu na identificação de novos conceitos e criação de questões.</p>
  </div>
</div>
