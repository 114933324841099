<div class="main">
  <div class="header">
    <h1>Novo Plano de Estudos</h1>
    <button class="btn-transparent" (click)="dimiss()">
      <mat-icon>close</mat-icon>
    </button>

  </div>
  <div class="main-content">
    <div class="content individual-wrapper" (click)="selectType(EnumStudyPlan.individual)">
      <svg xmlns="http://www.w3.org/2000/svg" width="94.777" height="92.333" viewBox="0 0 94.777 92.333">
        <g id="Grupo_2981" data-name="Grupo 2981" transform="translate(-445 -300.5)">
          <g id="person_black_24dp" transform="translate(445 306.907)">
            <path id="Caminho_1150" data-name="Caminho 1150" d="M0,0H85.926V85.926H0Z" fill="none" />
            <path id="Plus" data-name="Caminho 1151"
              d="M32.642,32.642A14.321,14.321,0,1,0,18.321,18.321,14.317,14.317,0,0,0,32.642,32.642Zm0,7.16C23.083,39.8,4,44.6,4,54.123v7.16H61.284v-7.16C61.284,44.6,42.2,39.8,32.642,39.8Z"
              transform="translate(10.321 10.321)" fill="#3cbcd1" />
          </g>
          <g id="add_black_24dp" transform="translate(504.222 300.5)">
            <path id="Caminho_1152" data-name="Caminho 1152" d="M0,0H35.555V35.555H0Z" fill="none" />
            <path id="Individual" data-name="Caminho 1153"
              d="M25.741,16.852H16.852v8.889H13.889V16.852H5V13.889h8.889V5h2.963v8.889h8.889Z"
              transform="translate(2.407 2.407)" fill="#3cbcd1" />
          </g>
        </g>
      </svg>

      <h5>Novo Plano de estudos individual</h5>
    </div>
    <div class="content team-wrapper" (click)="selectType(EnumStudyPlan.team)">
      <svg xmlns="http://www.w3.org/2000/svg" width="127.777" height="110" viewBox="0 0 127.777 110">
        <g id="Grupo_2984" data-name="Grupo 2984" transform="translate(-655 -289.5)">
          <g id="Grupo_2982" data-name="Grupo 2982" transform="translate(240 -2)">
            <g id="add_black_24dp" transform="translate(507.222 299.5)">
              <path id="Caminho_1152" data-name="Caminho 1152" d="M0,0H35.555V35.555H0Z" fill="none" />
              <path id="plus_team" data-name="Caminho 1153"
                d="M25.741,16.852H16.852v8.889H13.889V16.852H5V13.889h8.889V5h2.963v8.889h8.889Z"
                transform="translate(2.407 2.407)" fill="#3cbcd1" />
            </g>
          </g>
          <g id="groups_black_24dp" transform="translate(655 289.5)">
            <rect id="Retângulo_3332" data-name="Retângulo 3332" width="110" height="110" fill="none" />
            <g id="Grupo_2983" data-name="Grupo 2983" transform="translate(0 27.398)">
              <path id="team" data-name="Caminho 1154"
                d="M54.8,36.823a48.453,48.453,0,0,1,19.361,4.11A13.628,13.628,0,0,1,82.194,53.4v7.4H27.4V53.444a13.532,13.532,0,0,1,8.037-12.466A47.674,47.674,0,0,1,54.8,36.823ZM18.265,37.964a9.133,9.133,0,1,0-9.133-9.133A9.16,9.16,0,0,0,18.265,37.964Zm5.16,5.023a31.885,31.885,0,0,0-5.16-.457A31.738,31.738,0,0,0,5.571,45.179,9.182,9.182,0,0,0,0,53.627V60.8H20.549V53.444A20.543,20.543,0,0,1,23.425,42.987Zm67.9-5.023a9.133,9.133,0,1,0-9.133-9.133A9.16,9.16,0,0,0,91.327,37.964Zm18.265,15.663a9.182,9.182,0,0,0-5.571-8.448,31.043,31.043,0,0,0-17.854-2.192,20.543,20.543,0,0,1,2.877,10.457V60.8h20.549ZM54.8,6A13.7,13.7,0,1,1,41.1,19.7,13.681,13.681,0,0,1,54.8,6Z"
                transform="translate(0 -6)" fill="#3cbcd1" />
            </g>
          </g>
        </g>
      </svg>

      <h5>Novo Plano de estudos em grupo</h5>
    </div>
  </div>
</div>
