<div class="item-wrapper">
  <p class="title"> {{title}} </p>
  <ul>
    <li *ngFor="let item of getChoppedItens(); let index = index" (click)="click.emit(item)">
      <app-wrong-item *ngIf="!itemTemplate; else templateWrapper" [item]="item"></app-wrong-item>
      <ng-template #templateWrapper>
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </ng-template>
    </li>
  </ul>
  <p class="no-itens" *ngIf="itens && itens.length === 0">
    Não há erros para exibir
  </p>
  <div class="action" *ngIf="showAll && itens && itens.length > 0">
    <button (click)="viewAllItens()"> VER TODOS </button>
  </div>
</div>
