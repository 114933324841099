import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Team } from './Models/team.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material/dialog';
import { TeamsService } from 'src/app/settings/teams/services/teams.service';
import { DialogTeamsImportComponent } from './Dialogs/dialog-teams-import';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  public teams: Team[] = [];
  public teamCount: number = 0;
  private _teamPage: number = 1;
  public readonly pageSize = 20;
  private _searchTerm: string = '';
  private _createdAt: Date;

  public readonly displayedColumns = ['id', 'name', 'programName', 'createDate', 'studentNumber', 'action'];
  constructor(
    private _router: Router,
    private _teamsService: TeamsService,
    private _dialog: MatDialog) { }

  ngOnInit() {
    this.loadTeams(this._teamPage);
  }

  public loadTeams(page: number, programsId = null) {
    this._teamsService.getPagedTeams(page, this.pageSize, this._searchTerm, this._createdAt, programsId).subscribe(paged => {
      this.teams = paged.data.teams;
      this.teamCount = paged.data.itemsCount;
    });
  }

  public goToTeam(teamId: string = null) {
    const routerPath = `configuracoes/turma${teamId !== null ? '/' + teamId : ''}`;
    this._router.navigate([routerPath]);
  }

  public searchName(search: string) {
    this._searchTerm = search;
    this.loadTeams(this._teamPage);
  }

  public createdSince(date: Date) {
    this._createdAt = date;
    this.loadTeams(this._teamPage);
  }

  public programsFilter(programsId: string[]) {
    this.loadTeams(this._teamPage, programsId);
  }

  public deleteTeam(team: Team) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: { message: 'Tem certeza que excluir esta turma?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._teamsService.deleteTeam(team.id).subscribe(() => {
          this.loadTeams(this._teamPage);
        });
      }
    });
  }

  public openImportDialogTeam() {
    const dialogRef = this._dialog.open(DialogTeamsImportComponent, {
      width: '800px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadTeams(this._teamPage);
      }
    });
  }
}
