import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class ProgramsService {

  constructor(private _httpService: BackendService, private _authService: AuthService) { }

  public getPagedProgramsFilteredList(
    page: number = 1, pageSize: number = 10, programTitle: string = ''
  ): Observable<any> {
    return this._httpService.post('getPagedProgramsFilteredList', {
      'page': page,
      'pageSize': pageSize,
      'programTitle': programTitle
    });
  }

  public getProgramByTeamId(
    programId: string, teamId: string
  ): Observable<any> {
    return this._httpService.post('getProgramByTeamId', {
      'programId': programId,
      'teamId': teamId
    });
  }

  public getProgramTeamOptions(
    programId: string
  ): Observable<any> {
    return this._httpService.post('getProgramTeamOptions', {
      'programId': programId
    });
  }

  public ImportProgram(programItem: any): Observable<any> {
    return this._httpService.post('importProgram', {
      'programItem': programItem
    });
  }

}
