import { Component, Input, Output, EventEmitter, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Answer } from '../../../models/question.model';
import { Subject } from 'src/app/models/subject.model';
import { EffortService } from 'src/app/shared/services/effort.service';
import { ActivatedRoute } from '@angular/router';
import { TypeTargetEffort } from 'src/app/shared/enumerations/effort';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { isNil } from 'lodash';
import { EMixedAnswersQuestions } from 'src/app/models/enums/mixed-answers-questions.enum';

@Component({
  selector: 'app-exam-question',
  templateUrl: './exam-question.component.html',
  styleUrls: ['./exam-question.component.scss']
})
export class ExamQuestionComponent implements OnInit, OnDestroy {

  @Input() readonly subject?: Subject;
  @Input() readonly question: any;
  @Input() readonly title?: string;
  @Input() readonly questionNumber: number;
  @Input() readonly showButton?: boolean = false;
  @Input() readonly last: boolean = false;
  @Input() readonly reviewingConcept?: any;
  @Input() readonly isQuestionBdq?: boolean = true;
  @Input() readonly hasNewLearningTechniques?: boolean = true;
  @Input() readonly levels?: any;
  @Input() set evalAnswer(answer: Answer) {
    if (answer) {
      this.answered = answer ? true : false;
      this.selectedAnswer = answer;
      if (this.isPracticing) {
        this.correctAnswerId = answer.correctAnswerId;
      }
    } else {
      this.answered = false;
      this.selectedAnswer = null;
      if (this.isPracticing) {
        this.correctAnswerId = null;
      }
    }
  }
  @Input() readonly showComment: boolean = false;
  @Input() readonly isMock?: boolean = false;
  @Input() readonly isPracticing: boolean = false;
  @Output() finished = new EventEmitter();
  @Output() confirmAnswer = new EventEmitter();
  @Output() goToNextQuestion = new EventEmitter();
  @Output() skippedQuestion = new EventEmitter();
  @Output() openReview = new EventEmitter<any>();

  public get getSelectedAnswer(): any {
    return this.selectedAnswer;
  }
  public selectedAnswer: Answer;
  public oldAnswer: Answer;
  public answered: boolean = false;
  public selectedConcept: any;
  public id: string;
  public hasComment: boolean = environment.features.questionComment;
  public mixedAnswersType = EMixedAnswersQuestions;

  listenerFn: () => void;
  public correctAnswerId: string;
  constructor(
    private _effortService: EffortService,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    el: ElementRef) {
    this.listenerFn = _effortService.listenEvent(el.nativeElement, 'mousemove');
  }

  ngOnInit() {
    if (this.question != null && this.question.answers != null && this.question.answers.length > 0) {
      this.question.answers = this.shuffleArray(this.question.answers);
    }
    this.id = this._activatedRoute.snapshot.paramMap.get('moduleId');
    this._effortService.startCountInteraction(this.id, TypeTargetEffort.module);


  }
  ngOnDestroy(): void {
    this._effortService.finishInteraction();
    if (this.listenerFn) this.listenerFn();
  }
  private shuffleArray(array: any[]): any[] {
    let currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
  public setAnswerinit(answer: any): void {
    this.oldAnswer = answer;
    this.selectedAnswer = answer;
    this.hasAnswered(answer);
  }
  public setAnswer(answer: any): void {
    this.selectedAnswer = answer;
    this.hasAnswered(answer);
  }

  public confirm(): void {
    if (this.answered && this.last && !this.hasChange()) {
      this.finish();
    } else if (this.answered && !this.hasChange()) {
      this.goToNextQuestion.emit();
      this.hasAnswered(this.selectedAnswer);
    } else {
      this.confirmAnswer.emit(this.selectedAnswer);
      this.oldAnswer = this.selectedAnswer;
      this.hasAnswered(this.selectedAnswer);
    }
  }

  public finish(): void {
    this.finished.emit();
  }

  public hasAnswered(value): void {
    if (!this.isMock && !this.isQuestionBdq) {
      this.answered = value !== null && value !== '';
    }
  }

  public hasChange(): boolean {
    if (!this.isQuestionBdq) {
      return isNil(this.oldAnswer) || isNil(this.selectedAnswer) || this.oldAnswer['text'] !== this.selectedAnswer['text'];
    } else {
      return false;
    }
  }


  public resetAnswer(): void {
    this.answered = false;
    this.selectedAnswer = null;
  }

  public reviewConcept(concept): void {
    if (typeof concept === 'object') {
      concept = concept.concept;
    }
    this.openReview.emit(concept);
  }

  public seeComment() {
    this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      data: {
        title: 'Comentário',
        message: this.question.comment,
        positiveTextAction: 'Ok'
      } as SimpleDialog
    });
  }
}
