import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from 'src/app/shared/services/upload.service';
import Editor from '@toast-ui/editor';
import { format } from 'date-fns';
import { Answer, ConceptAnswer } from 'src/app/models/question.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { UtilService } from 'src/app/shared/services/util.service';
import { Subject } from 'src/app/models/subject.model';
import { Module, ModuleQuestion, ModuleQuestionAnswer, ModuleQuestionAnswerTypeEnum } from 'src/app/models/module.model';

@Component({
  selector: 'app-module-new-question',
  templateUrl: './module-new-question.component.html',
  styleUrls: ['./module-new-question.component.scss']
})
export class ModuleNewQuestionComponent extends NotificationClass implements OnInit {

  @Input() readonly subject: Subject;
  @Input() readonly moduleQuestion: ModuleQuestion;
  @Output() next = new EventEmitter<ModuleQuestion>();
  @Output() goBack = new EventEmitter();

  public question: ModuleQuestion;
  public concepts: Array<string> = [];
  public concepts2: Array<string> = [];
  public concepts3: Array<string> = [];
  public concepts4: Array<string> = [];
  public showAllSelectedConcepts: boolean = false;
  public editor: Editor;

  constructor(
    protected _snackBar: MatSnackBar,
    private _uploadService: UploadService
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    if (this.moduleQuestion) {
      const concepts: any[] = this.moduleQuestion.concepts;
      const answers: ModuleQuestionAnswer[] = [];
      this.moduleQuestion.answers.forEach(answer => {
        const answerConcepts: any[] = answer.concepts;
        answers.push({
          type: answer.type,
          text: answer.text,
          concepts: answerConcepts.map(x => x.name),
        });
      });
      this.question = {
        id: this.moduleQuestion.id,
        userId: this.moduleQuestion.userId,
        moduleId: this.moduleQuestion.moduleId,
        subjectId: this.moduleQuestion.subjectId,
        text: this.moduleQuestion.text,
        concepts: concepts.map(x => x.name),
        answers: answers
      };
    } else {
      const answers: ModuleQuestionAnswer[] = [
        { type: ModuleQuestionAnswerTypeEnum.Correct, text: '', concepts: [] },
        { type: ModuleQuestionAnswerTypeEnum.PartiallyCorrect, text: '', concepts: [] },
        { type: ModuleQuestionAnswerTypeEnum.Wrong, text: '', concepts: [] },
        { type: ModuleQuestionAnswerTypeEnum.Absurd, text: '', concepts: [] }
      ];
      this.question = {
        text: '',
        concepts: [],
        answers: answers
      };
    }
    this._configureEditor();
  }

  getConcepts(type: number = 1): string[] {
    if (type === 1) {
      return this.concepts;
    } else if (type === 2) {
      return this.concepts2;
    } else if (type === 3) {
      return this.concepts3;
    } else if (type === 4) {
      return this.concepts4;
    }
  }

  public triggerConceptSearch(searchValue: string, type: number = 1) {
    if (searchValue && searchValue.trim() !== '') {
      const filteredConcepts = [];
      for (let i = 0; i < this.subject.concepts.length; i++) {
        const concept: any = this.subject.concepts[i];
        if (concept.name.includes(searchValue)) {
          filteredConcepts.push(concept.name);
        }
      }
      if (type === 1) {
        this.concepts = filteredConcepts;
      } else if (type === 2) {
        this.concepts2 = filteredConcepts;
      } else if (type === 3) {
        this.concepts3 = filteredConcepts;
      } else if (type === 4) {
        this.concepts4 = filteredConcepts;
      }
    }
  }

  public addConcept(concept: string, type: number = 1) {
    let conceptExists = '';
    if (type === 1) {
      conceptExists = this.question.concepts.find(x => x === concept);
    } else if (type === 2) {
      conceptExists = this.question.answers[1].concepts.find(x => x === concept);
    } else if (type === 3) {
      conceptExists = this.question.answers[2].concepts.find(x => x === concept);
    } else if (type === 4) {
      conceptExists = this.question.answers[3].concepts.find(x => x === concept);
    }
    if (!conceptExists) {
      if (type === 1) {
        this.question.concepts.push(concept);
      } else if (type === 2) {
        this.question.answers[1].concepts.push(concept);
      } else if (type === 3) {
        this.question.answers[2].concepts.push(concept);
      } else if (type === 4) {
        this.question.answers[3].concepts.push(concept);
      }
    }
    this.resetConceptSearch(type);
  }

  public removeSelectedConcept(concept: string, type: number = 1) {
    let index = -1;
    if (type === 1) {
      index = this.question.concepts.findIndex(x => x === concept);
      this.question.concepts.splice(index, 1);
    } else if (type === 2) {
      index = this.question.answers[1].concepts.findIndex(x => x === concept);
      this.question.answers[1].concepts.splice(index, 1);
    } else if (type === 3) {
      index = this.question.answers[2].concepts.findIndex(x => x === concept);
      this.question.answers[2].concepts.splice(index, 1);
    } else if (type === 4) {
      index = this.question.answers[3].concepts.findIndex(x => x === concept);
      this.question.answers[3].concepts.splice(index, 1);
    }
  }

  public resetConceptSearch(type: number = 1): void {
    if (type === 1) {
      this.concepts = [];
    } else if (type === 2) {
      this.concepts2 = [];
    } else if (type === 3) {
      this.concepts3 = [];
    } else if (type === 4) {
      this.concepts4 = [];
    }
  }

  public save(): void {
    this.question.text = this.editor.getMarkdown();
    if (!this._validateQuestion()) return;
    this.next.emit(this.question);
  }

  private _configureEditor(): void {
    this.editor = new Editor({
      el: document.querySelector('#htmlEditor'),
      initialEditType: 'markdown',
      previewStyle: 'vertical',
      height: '200px',
      hooks: {
        addImageBlobHook: (file: File, callback) => {
          this._uploadService.uploadFile(file).subscribe(uploadedImageURL => {
            callback(uploadedImageURL, file.name);
          });
        }
      }
    });
    this.editor.off('scroll');
    this.editor.setMarkdown(
      this.question.text
    );
  }

  private _validateQuestion(): boolean {
    if (!this.question.text || this.question.text.trim() === '') {
      this.notify('Preencha todos os campos obrigatórios para adicionar a questão');
      return false;
    }

    if (this.question.concepts.length === 0) {
      this.notify('Selecione pelo menos um conceito para ser avaliado pelas perguntas');
      return false;
    }

    const countAnswers = this.question.answers.filter(a => a.text.length <= 0);
    if (countAnswers.length > 1) {
      this.notify('Todas respostas devem ter uma descrição');
      return false;
    }

    const countConcepts = [];
    for (let i = 0; i < this.question.answers.length; i++) {
      const answer = this.question.answers[i];
      let questionConceptCount = 0;
      for (let j = 0; j < answer.concepts.length; j++) {
        const answerConcept = answer.concepts[j];
        if (this.question.concepts.some(x => x === answerConcept)) {
          questionConceptCount++;
        }
      }
      if (questionConceptCount === 0) {
        countConcepts.push(answer);
      }
    }
    if (countConcepts.length > 1) {
      this.notify('Todas respostas devem ter pelo menos um conceito errado abordado na questão');
      return false;
    }

    return true;
  }

  public getAnswerType(answer: ModuleQuestionAnswer): string {
    if (answer.type === ModuleQuestionAnswerTypeEnum.Correct) {
      return '100% Correta';
    } else if (answer.type === ModuleQuestionAnswerTypeEnum.PartiallyCorrect) {
      return 'Parcialmente Correta';
    } else if (answer.type === ModuleQuestionAnswerTypeEnum.Wrong) {
      return 'Errada';
    } else if (answer.type === ModuleQuestionAnswerTypeEnum.Absurd) {
      return 'Absurda';
    } else {
      return '';
    }
  }
}
