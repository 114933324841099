<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      voltar
    </a>
  </nav>

  <div class="header">
    <h2>
      <p>Trilha do curso</p>
      {{ track?.title }}
    </h2>
    <div class="info">
      <p>
        <span>Duração Total</span><br>
        {{ getFormattedHour() }} h
      </p>
      <p>
        <span>Turma</span><br>
        {{ track?.studentsCount }} alunos
      </p>
      <p>
        <span>Notas atualizadas diariamente</span>
        <br>
        às 00:00h
      </p>
    </div>
  </div>

  <div class="subheader">
    <div class="left">
      <app-student-avatar [student]="track?.student"></app-student-avatar>
    </div>
  </div>

  <div class="participation" *ngIf="hasFeatureCockpitMetrics">
    <div class="participation-title">
      <p class="title" >
        PONTOS DE PARTICIPAÇÃO 
      </p>
    </div>
    <div class="participation-progress">
      <p class="participation-progress-bar" *ngIf="participationUntilDay" [style.width.%]="participationUntilDay"></p>
      <div class="participation-points">
        <p class="participation-progress-bar" *ngIf="participation"></p>
        <p class="participation-progress-points">{{participation}} PONTOS CONQUISTADOS</p>
      </div>
    </div>
  </div>

  <div class="content" *ngIf="track">
    <app-track-overview-performance-radar
      *ngIf="track.modulesConfiguration"
      [title]="'PERFORMANCE INDIVIDUAL X TURMA'"
      [track]="track"
      [labels]="getRadarLabels()"
      [titleCallback]="getRadarTitleCallback.bind(this)"
      [dataset]="getRadarDataset()"
      [tooltipCallback]="getRadarTooltipCallback.bind(this)"></app-track-overview-performance-radar>

    <app-track-overview-wrong-concepts
      *ngIf="track.student"
      [concepts]="track?.student.wrongConcepts"></app-track-overview-wrong-concepts>
  </div>

  <app-track-overview-events-participation
    *ngIf="track && track.student"
    [events]="track.student.events"></app-track-overview-events-participation>
</div>
