<div class="inner-content">

  <app-header [title]="track?.title" [subTitle]="track?.moduleName"></app-header>

  <div class="content">

    <div class="column">
      <div class="row">
        <app-most-wrong-item
          [itens]="wrongConcepts"
          [viewElements]="3"
          title="CONCEITOS COM MAIS ERROS"
          emptyStateEntity="conceito">
        </app-most-wrong-item>

      </div>
      <div class="row">
        <app-most-wrong-item
          [itens]="wrongSubjects"
          [viewElements]="3"
          title="ASSUNTOS COM MAIS ERROS"
          emptyStateEntity="assunto">
        </app-most-wrong-item>
      </div>
    </div>
    <div class="column">
      <app-student-grade style="height: calc(100vh - 220px);" [students]="track?.studentProgress"></app-student-grade>
    </div>
    <div class="column">
      <div class="row">
        <app-most-wrong-item
          [itens]="wrongQuestions"
          [title]="'PERGUNTAS COM MAIS ERROS'"
          [viewElements]="3"
          [itemTemplate]="wrongAnswer"
          [showAll]="false"
          emptyStateEntity="perguntas">
          <ng-template #wrongAnswer let-item>
            <a title='Visualizar pergunta completa' class="custom-item item-wrapper" (click)="viewFullQuestion(item,0)">
              <span class="line-clamp"> {{replaceBase64(item.title)}} </span>
              <!-- <small>{{item.subtitle}}</small> -->
              <span class="material-icons">visibility</span>
            </a>
          </ng-template>
        </app-most-wrong-item>

      </div>
      <div class="row">
        <app-most-wrong-item
          [itens]="correctQuestions"
          [title]="'PERGUNTAS COM MAIS ACERTOS'"
          [itemTemplate]="correctAnswer"
          [viewElements]="3"
          [showAll]="false"
          emptyStateEntity="perguntas">
          <ng-template #correctAnswer let-item>
            <a title='Visualizar pergunta completa' class="custom-item item-wrapper" (click)="viewFullQuestion(item,1)">
              <span class="line-clamp">
                {{replaceBase64(item.title)}}
                <!-- <small class="line-clamp">{{item.subtitle}}</small> -->
              </span>
              <span class="material-icons">visibility</span>
            </a>
          </ng-template>
        </app-most-wrong-item>

      </div>
    </div>
  </div>
</div>
