import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadResource } from '../../../models/shared/upload-resource.interface';
import { SharedService } from '../../../shared/services/shared.service';
import { UserFile } from 'src/app/models/user-file.interface';
import { AuthService } from '../../../shared/services/auth.service';
import { SettingsUsersService } from '../../../settings/_services/users.service';
import { ActivatedRoute } from '@angular/router';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent {

  public Questions: Array<any>;
  public hasUpload: boolean = true;
  private selectedFile: File;
  public arquivos: Array<UserFile> = [];
  private currentQuestionIndex: number = null;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    private _uploadService: UploadService,
    private _settingsUsersService: SettingsUsersService,
    private _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public questions: Array<any> = []
  ) {
    this.questions = questions;
  }

  public changeAnswer(event, question) {
    question.answer = event.target.value;
  }

  public dismiss(resolution: Array<any>): void {
    this.dialogRef.close(resolution);
  }

  public openFileUpload(index: number): void {
    this.currentQuestionIndex = index;
    const element: HTMLElement = document.getElementById('inputEventFile' + index);
    element.click();
  }

  public setDocumentFile(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];

      this._sendFileToServer(event.target.files[0], this.selectedFile.name);
    }
  }

  private _sendFileToServer(result: string, fileName: string) {
    const resource: UploadResource = {
      data: result,
      filename: fileName,
      resource: 'event_assesment'
    };

    this._uploadService.uploadFile(result).subscribe((response) => {
      const arquivo: UserFile = {
        downloadLink: response,
        description: null,
        title: fileName,
        id: null
      };

      this.arquivos.push(arquivo);
      const user = this._authService.getLoggedUser();

      this._settingsUsersService.addUserFiles(
        user.user_id,
        this.arquivos
      ).subscribe(uploadResponse => {
        const question = this.questions[this.currentQuestionIndex];
        question.answer = arquivo.downloadLink;
        question.fileAsAnswer = true;
        question.fileName = resource.filename || arquivo.title;
        this.currentQuestionIndex = null;
        this.arquivos = [];
      });
    }, () => {
      this.arquivos = [];
    });
  }

}
