<div class="filter" #FilterComponent>
  <app-autocomplete #Autocomplete [filter]="filters?.track" mdePopoverPositionY="above"
    (triggerSearch)="setupUpdateFilter(filters?.track,$event)" (change)="updateFilterOptions(filters?.track, $event)"
    (remove)="removeFilter(filters?.track, $event)">
  </app-autocomplete>
  <app-autocomplete *ngIf="filters?.track?.selectedOptions.length > 0" id="1" [filter]="filters?.trackModule"
    (triggerSearch)="setupUpdateFilter(filters?.trackModule,$event,true)"
    (change)="updateFilterOptions(filters?.trackModule, $event, true)"
    (remove)="removeFilter(filters?.trackModule, $event)">
  </app-autocomplete>

  <app-autocomplete #Autocomplete *ngIf="filters?.track?.selectedOptions.length === 0" id="2" [filter]="filters?.module"
    mdePopoverPositionY="above" (triggerSearch)="setupUpdateFilterNoOptions(filters?.module,$event)"
    (change)="updateFilterNoOptions(filters?.module, $event)" (remove)="removeFilterNoOptions(filters?.module, $event)">
  </app-autocomplete>

  <app-user-search placeholder="Pesquise um aluno..." (userRemoved)="removeStudent($event)"
    (userSelected)="setStudent($event)">
  </app-user-search>
  <app-user-search placeholder="Pesquise um responsavél..." (userRemoved)="removeResponsible($event)"
    (userSelected)="setResponsible($event)">
  </app-user-search>

  <mat-form-field style="width:100%">
    <input matInput [matDatepicker]="dateInitial" placeholder="Data Inicial" [(ngModel)]="filter?.date.start">
    <mat-datepicker-toggle matPrefix [for]="dateInitial"></mat-datepicker-toggle>
    <mat-datepicker #dateInitial></mat-datepicker>
  </mat-form-field>

  <mat-form-field style="width:100%">
    <input matInput [matDatepicker]="dateFinished" placeholder="Data Final" [(ngModel)]="filter?.date.end">
    <mat-datepicker-toggle matPrefix [for]="dateFinished"></mat-datepicker-toggle>
    <mat-datepicker #dateFinished></mat-datepicker>
  </mat-form-field>
</div>
