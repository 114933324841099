
import { BaseUrlService } from '@tg4/http-infrastructure';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UrlService extends BaseUrlService {

  constructor() {
    super();
    this.addUrls(environment.apiUrl, new Map<string, string>([
      /* Login and Authentication*/
      ['login', 'api/account/login'],
      ['loginLinkedIn', 'api/account/linkedIn'],
      ['bindLinkedIn', 'api/account/bindLinkedIn'],
      ['register', 'api/account/register'],
      ['refresh_token', 'api/account/RefreshToken'],
      ['sendVerificationEmail', 'api/account/sendVerificationEmail'],
      ['verifyEmailCode', 'api/account/verifyEmailCode'],
      ['firstAccess', 'api/account/firstAccess'],
      ['forgotPassword', 'api/account/forgotPassword'],
      /* Pages > Queries */
      ['getPagedModulesList', 'api/module'],
      ['getAllContent', 'api/module/getAllContent'],
      ['getModuleById', 'api/module/byId'],
      ['deleteModuleById', 'api/module/deleteModule'],
      ['getModuleOverview', 'api/module/overview'],
      ['getForumByModule', 'api/forum/forumByModule'],
      ['getForumQuestionById', 'api/forum/question'],
      ['getForumPreviewByModule', 'api/forum/previewForumQuestions'],
      ['getNotifications', 'api/user/getNotifications'],
      ['getTrackCurrentStudentOverview', 'api/track/overview/student/current'],
      ['getInstructorTracksOverview', 'api/track/overview/instructor'],
      ['getTracksByItemId', 'api/track/GetByItemId'],
      ['getTrackInstructors', 'api/track/instructors'],
      ['getContactAreas', 'api/user/getContactAreas'],
      ['getEventForumByEventSchedule', 'api/eventForum/eventForumByEventSchedule'],
      ['getEventForumQuestionById', 'api/eventForum/eventForumQuestion'],
      ['getEventForumPreviewByEventSchedule', 'api/eventForum/previewEventForumQuestions'],
      ['getUserEventForumPreviewByEventSchedule', 'api/eventForum/userPreviewEventForumQuestions'],
      ['getTrackHasStudentsSubordinat', 'api/track/cockpit/tracks/subordinates'],
      /* Pages > Commands  */
      ['saveForumQuestion', 'api/forum/saveQuestion'],
      ['manageQuestionLike', 'api/forum/manageQuestionLike'],
      ['manageAnswerLike', 'api/forum/question/answer/manageLike'],
      ['saveForumQuestionAnswer', 'api/forum/question/answer'],
      ['removeForumQuestion', 'api/forum/question/remove'],
      ['removeForumAnswer', 'api/forum/question/remove/answer'],
      ['manageNotification', 'api/user/manageNotification'],
      ['sendMessage', 'api/user/sendMessage'],
      ['sendCustomEmail', 'api/user/sendCustomEmail'],
      ['pagedCustomEmails', 'api/user/pagedCustomEmails'],
      ['markMandatoryVideoViewed', 'api/track/overview/video/markViewed'],
      ['saveEventForumQuestion', 'api/eventForum/saveEventForumQuestion'],
      ['manageEventForumQuestionLike', 'api/eventForum/manageEventForumQuestionLike'],
      ['manageEventForumAnswerLike', 'api/eventForum/eventForumQuestion/answer/manageLike'],
      ['saveEventForumQuestionAnswer', 'api/eventForum/eventForumQuestion/answer'],
      ['removeEventForumQuestion', 'api/eventForum/eventForumQuestion/remove'],
      ['removeEventForumAnswer', 'api/eventForum/eventForumQuestion/remove/answer'],
      /* Settings > Queries */
      ['getPagedFilteredModulesList', 'api/module/filtered'],
      ['getPagedManagerFilteredModulesList', 'api/module/filtered/manager'],
      ['getPagedMyCoursesFilteredModulesList', 'api/module/filtered/mycourses'],
      ['getPagedProgramsFilteredList', 'api/program/user/getUserPrograms'],
      ['getProgramByTeamId', 'api/program/user/getUserProgramByTeamId'],
      ['getProgramByTeamId', 'api/program/user/getUserProgramByTeamId'],
      ['getProgramTeamOptions', 'api/program/user/getProgramTeamOptions'],
      ['getPagedEffortPerformancesFilteredModulesList', 'api/module/filtered/effortPerformance'],
      ['getPagedHomeModulesList', 'api/module/home'],
      ['getPagedQuestionsList', 'api/question'],
      ['getPagedFilteredQuestionsList', 'api/question/filtered'],
      ['getQuestionsList', 'api/question/getAllQuestions'],
      ['getQuestionOverview', 'api/question/getQuestionOverview'],
      ['validateModuleQuestions', 'api/question/validateModuleQuestions'],
      ['getPagedFilteredTracksList', 'api/track/filtered'],
      ['getNotRecommendedTracks', 'api/track/getNotRecommendedTracks'],
      ['getCatalogTracks', 'api/track/getCatalogTracks'],
      ['getPagedFilteredMyCoursesTracksList', 'api/track/filtered/mycourses'],
      ['getPagedFilteredEffortPerformancesTracksList', 'api/track/filtered/effortPerformance'],
      ['getTrackById', 'api/track'],
      ['getTrackOverview', 'api/track/overview'],
      ['getTrackOverviewGrades', 'api/track/overview/grades'],
      ['getTrackOverviewStudents', 'api/track/overview/students'],
      ['getTrackOverviewEventInfo', 'api/track/overview/eventInfo'],
      ['getTrackStudentOverview', 'api/track/overview/student'],
      ['getTrackStudentReportCard', 'api/track/reportCard/student'],
      ['getTrackModuleOverview', 'api/track/overview/module'],
      ['getAllTrackContent', 'api/track/getAllContent'],
      ['getAllTracks', 'api/track/getAllTracks'],
      ['getPagedFilteredTrackAreas', 'api/track/area/filtered'],
      ['getTrackAreaById', '/api/track/area'],
      ['getPagedFilteredTrackSubAreas', 'api/track/subarea/filtered'],
      ['getTrackSubAreaById', '/api/track/subarea'],
      ['getModuleConfiguration', 'api/track/GetModuleConfiguration'],
      ['getAllFilteredTracksList', 'api/track/getAllFilteredTracksList'],
      ['getPagedFilteredEventsList', 'api/event/filtered'],
      ['addCalendarEventsFromFile', 'api/track/addCalendarEventsFromFile'],
      ['getTrackContainsModuleById', 'api/track/getTrackContainsModuleById'],
      ['getHomeEventsList', 'api/event/homeEvents'],
      ['getEventById', 'api/event'],
      ['getEventReactions', 'api/event/getEventReactions'],
      ['getPastEvents', 'api/event/pastEvents'],
      ['addEventUsersGradeBaseValues', 'api/event/addEventUsersGradeBaseValues'],
      ['addEventUsers', 'api/event/AddEventUsers'],
      ['getEventStudentList', 'api/event/getEventStudentList'],
      ['getUserTypes', 'api/user/userTypes'],
      ['getUserFiles', 'api/user/getUserFiles'],
      ['import', 'api/user/import'],
      ['getProfileTests', 'api/profileTest'],
      ['deleteProfileTest', 'api/profileTest'],
      ['getProfileTestById', 'api/profileTest/byId'],
      ['getProfileTestResponses', 'api/profileTest/responses'],
      ['getProfileTestResponseById', 'api/profileTest/responses/byId'],
      ['getSuggestedProducts', 'api/profileTest/suggested'],
      ['getAllProfileTestResponses', 'api/profileTest/responses/all'],
      ['getUsersProgress', 'api/track/overview/getTrackBusinessManagerOverviewQuery'],
      ['getCommunicationById', 'api/communications/byId'],
      ['getAllCommunication', 'api/communications/getAll'],
      ['getTeamById', 'api/team/byId'],
      ['getAllTeams', 'api/team'],
      ['getProgramById', 'api/program/byId'],
      ['getAllPrograms', 'api/program'],
      ['DeleteProgram', 'api/program'],
      ['CreateProgram', 'api/program'],
      ['UpdateProgram', 'api/program'],
      ['getTeamOptions', 'api/program/teamOptions'],
      ['importProgram', 'api/program/import/program'],

      /* Settings > Commands  */
      ['addNewModule', 'api/module'],
      ['updateModuleInfo', 'api/module/updateModule'],
      ['manageSubjects', 'api/module/manageSubjects'],
      ['manageContents', 'api/module/manageContents'],
      ['manageSupportMaterials', 'api/module/manageSupportMaterials'],
      ['manageRequirements', 'api/module/manageRequirements'],
      ['getAccessAvailability', 'api/module/GetAccessAvailability'],
      ['manageQuestion', 'api/question'],
      ['removeQuestion', 'api/question'],
      ['manageTrackInfo', 'api/track'],
      ['addTrackArea', 'api/track/area'],
      ['updateTrackArea', 'api/track/area'],
      ['deleteTrackAreaById', 'api/track/area/delete'],
      ['addTrackSubArea', 'api/track/subarea'],
      ['updateTrackSubArea', 'api/track/subarea'],
      ['deleteTrackSubAreaById', 'api/track/subarea/delete'],
      ['deleteTrackById', 'api/track/deleteTrack'],
      ['removeTrack', 'api/track'],
      ['manageEvent', 'api/event'],
      ['manageEventSupportMaterials', 'api/event/manageSupportMaterials'],
      ['manageEventRequirements', 'api/event/manageRequirements'],
      ['manageEventSchedule', 'api/event/manageSchedule'],
      ['getEventApplicationByUserQuery', 'api/event/getEventApplicationByUserQuery'],
      ['getEventApplicationByEventId', 'api/event/getEventApplicationByEventId'],
      ['getEventSchedulesByEventId', 'api/event/getEventSchedulesByEventId'],
      ['changeEventPublishedStatus', 'api/event/changeEventPublishedStatus'],
      ['changeEventUserApplicationStatus', 'api/event/changeEventUserApplicationStatus'],
      ['changeEventUserGrade', 'api/event/changeEventUserGrade'],
      ['changeSynchronousExperienceUserGrade', 'api/event/changeSynchronousExperienceUserGrade'],
      ['changeEventUserForumGrade', 'api/event/changeEventUserForumGrade'],
      ['applyToEvent', 'api/event/applyToEvent'],
      ['deleteEventById', 'api/event/deleteEvent'],
      ['addEventReaction', 'api/event/addEventReaction'],
      ['manageEventReaction', 'api/event/manageEventReaction'],
      ['manageUserPresence', 'api/event/manageUserPresence'],
      ['importUserPresence', 'api/event/importUserPresence'],
      ['finishEvent', 'api/event/finishEvent'],
      ['sendEventEvaluationEmail', 'api/event/sendEventReactionEvaluation'],
      ['changeUserEventApplicationSchedule', 'api/event/changeUserEventApplicationSchedule'],
      ['importQdb', 'api/question/importQdb'],
      ['manageCalendarEvents', 'api/track/manageCalendarEvents'],
      ['calculateGrade', 'api/track/CalculateGrade'],
      ['manageEcommerceProducts', 'api/track/manageEcommerceProducts'],
      ['manageUserFiles', 'api/user/manageUserFiles'],
      ['addUserFiles', 'api/user/addUserFiles'],
      ['addAssesmentUserFiles', 'api/user/addAssesmentUserFiles'],
      ['setQuestionsLimit', 'api/module/setQuestionsLimit'],
      ['manageEcommerceModuleDraft', 'api/moduleDraft/manageEcommerceModuleDraft'],
      ['createModuleProgres', 'api/user/createModuleProgres'],
      ['manageSubjectProgress', 'api/module/ManageSubjectProgress'],
      ['includeNewUsers', 'api/user/includeNewUsers'],
      ['setModuleOpinion', 'api/module/SetModuleOpinion'],
      ['addModuleUserTag', 'api/module/AddModuleUserTag'],
      ['updateModuleUserTag', 'api/module/UpdateModuleUserTag'],
      ['getModuleTags', 'api/module/GetModuleTags'],
      ['valuateModuleUserTag', 'api/module/ValuateModuleUserTag'],
      ['updateModuleActivity', 'api/module/updateModuleActivity'],
      ['getFilteredUserTagValuations', 'api/module/GetFilteredUserTagValuations'],
      ['updateModuleTagStatus', 'api/module/UpdateModuleTagStatus'],
      ['getCuratorshipPendingCount', 'api/module/GetCuratorshipPendingCount'],
      ['cloneTrack', 'api/track/clone'],
      ['getTracksById', 'api/module/getTracksById'],
      // Profile Tests
      ['manageProfileTest', 'api/profileTest/manage'],
      ['suggestProducts', 'api/profileTest/suggest'],
      ['suggestProfileTest', 'api/profileTest/suggest/test'],
      ['saveProfileTestResponse', 'api/profileTest/response/save'],
      ['gradeProfileTestAnswers', 'api/profileTest/responses/grade'],
      // Formulas
      ['getFormulas', 'api/formula'],
      ['getFormulaById', 'api/formula/byId'],
      ['addFormula', 'api/formula'],
      ['manageFormula', 'api/formula/manage'],
      ['deleteFormula', 'api/formula/delete'],
      ['getFormulaVariables', 'api/formula/variables/byType'],
      // Modules Drafts
      ['getPagedModulesAndDrafts', 'api/moduleDraft/paged'],
      ['addNewModuleDraft', 'api/moduleDraft'],
      ['updateModuleDraft', 'api/moduleDraft/updateModule'],
      ['cloneModule', 'api/module/clone'],
      ['manageDraftSubjects', 'api/moduleDraft/manageSubjects'],
      ['manageModuleWeight', 'api/moduleDraft/manageModuleWeight'],
      ['manageDraftContents', 'api/moduleDraft/manageContents'],
      ['manageDraftSupportMaterials', 'api/moduleDraft/manageSupportMaterials'],
      ['manageDraftRequirements', 'api/moduleDraft/manageRequirements'],
      ['publishDraft', 'api/moduleDraft/publishDraft'],
      ['rejectDraft', 'api/moduleDraft/deleteModule'],
      ['getDraftById', 'api/moduleDraft/byId'],
      ['getNewDraftByModule', 'api/moduleDraft/createDraft'],
      ['setDraftQuestionsLimit', 'api/moduleDraft/setQuestionsLimit'],
      ['sendFileToS3', 'api/moduleDraft/sendFileToS3'],
      ['importDraftSubjects', 'api/moduleDraft/importSubjects'],
      ['importDraftContents', 'api/moduleDraft/importContents'],
      // Events Drafts
      ['getPagedEventsAndDrafts', 'api/eventDraft/paged'],
      ['getEventDraftById', 'api/eventDraft/byId'],
      ['getEventDraftByEventId', 'api/eventDraft/byEventId'],
      ['addNewEventDraft', 'api/eventDraft'],
      ['manageEventDraftSchedule', 'api/eventDraft/manageSchedule'],
      ['deleteSchedule', 'api/eventDraft/deleteSchedule'],
      ['manageEventDraftSupportMaterials', 'api/eventDraft/manageSupportMaterials'],
      ['manageEventDraftRequirements', 'api/eventDraft/manageRequirements'],
      ['publishEventDraft', 'api/eventDraft/publishDraft'],
      ['rejectEventDraft', 'api/eventDraft/deleteEvent'],
      // Questions Drafts
      ['manageQuestionDraft', 'api/questionDraft'],
      ['getAllQuestionsDraft', 'api/questionDraft/all'],
      ['getPagedQuestionsDraft', 'api/questionDraft/paged'],
      ['removeQuestionDraft', 'api/questionDraft'],
      ['importDraftQdb', 'api/questionDraft/importQdb'],
      // Recruiting Company
      ['manageRecruitingCompany', 'api/recruitingCompany/manage'],
      ['getRecruitingCompany', 'api/recruitingCompany'],
      ['addRecruitmentFavorite', 'api/recruitingCompany/favorite'],
      ['removeRecruitmentFavorite', 'api/recruitingCompany/favorite'],
      ['getTalentsList', 'api/recruitingCompany/talents/list'],
      /* exam and Progress */
      ['getsubjectprogress', 'api/user/getsubjectprogress'],
      ['getmoduleprogress', 'api/user/getmoduleprogress'],
      ['getmodulesprogress', 'api/user/getmodulesprogress'],
      ['gettracksprogress', 'api/user/gettracksprogress'],
      ['getTrackProgress', 'api/user/getTrackProgress'],
      ['examstart', 'api/exam/startexam'],
      ['examanswer', 'api/exam/answer'],
      ['navigatePracticeQuestion', 'api/exam/navigatePracticeQuestion'],
      /* Shared */
      ['uploadImage', 'api/uploadImage/base64'],
      ['uploadFile', 'api/uploadfile/base64'],
      ['getLevels', 'api/level'],
      /* User > Queries */
      ['getUserById', 'api/user'],
      ['getTrackStudentByEventId', 'api/track/GetTrackStudentByEventId'],
      ['getUserProfile', 'api/user/userProfile'],
      ['getUserRecommendationBasicInfo', 'api/user/userRecommendationBasicInfo'],
      ['getPagedUser', 'api/user/pagedUser'],
      ['getFilteredPagedUser', 'api/user/filteredPagedUser'],
      ['getUserCategories', 'api/user/getUserCategories'],
      ['pagedUsersSyncProcesse', 'api/user/pagedUsersSyncProcesse'],
      ['getProfessors', 'api/user/getProfessors'],
      ['getUserContentNote', 'api/user/getUserContentNote'],
      ['getUserCareer', 'api/user/getUserCareer'],
      ['getUserCareerPdf', 'api/user/userCareer/pdf'],
      ['getUserInstitutions', 'api/user/getUserInstitutions'],
      ['exportUsersCareer', 'api/user/exportUsersCareer'],
      ['exportUsersGrade', 'api/user/exportUsersGrade'],
      ['getUserSkills', 'api/user/getUserSkills'],
      ['exportUsersEffectivenessIndicators', 'api/module/effectivenessIndicators'],
      ['getCEP', 'api/user/GetCEP'],
      ['exportCareerUsers', 'api/report/exportCareerUsers'],
      ['getUserColorPalette', 'api/user/getUserColorPalette'],
      ['getUserCertificate', 'api/user/GetUserCertificate'],
      ['getUserCertificateManange', 'api/user/getUserCertificateManange'],
      ['getBasicProgressInfo', 'api/user/getBasicProgressInfo'],
      ['getUserToImpersonate', 'api/user/getUserToImpersonate'],
      ['checkProgressByModule', 'api/user/CheckProgressByModule'],
      ['favorite', 'api/user/favoriteCourse'],
      ['getPagedUsersSubjectBlocked', 'api/user/getUsersBlockedSubject'],
      ['getBlockedSubjectsByUserId', 'api/user/getBlockedSubjectsByUserId'],
      ['unlockUserSubject', 'api/user/unlockUser'],
      ['getUsersByEmail', 'api/user/getUsersByEmail'],
      ['GetUsersToSendEmail', 'api/user/getUsers/toSendEmail'],
      ['ResendCustomEmail', 'api/user/resendCustomEmail'],
      ['UpdateCustomEmail', 'api/user/updateCustomEmail'],
      ['DeleteCustomEmail', 'api/user/deleteCustomEmail'],
      ['GetCustomEmailById', 'api/user/GetCustomEmailById'],
      /* User > Commands */
      ['updateTrackDueDate', 'api/user/UpdateTrackDueDate'],
      ['getAllUsers', 'api/user/getAllUsers'],
      ['updateModuleDueDate', 'api/user/UpdateModuleDueDate'],
      ['changeUserBlockedStatus', 'api/user/changeUserBlockedStatus'],
      ['addOrModifyUser', 'api/user/createUpdateUser'],
      ['addUsersSyncProcess', 'api/user/AddUsersSyncProcess'],
      ['updateUserRecommendations', 'api/user/updateUserRecommendations'],
      ['changePassword', 'api/account/ChangePassword'],
      ['adminChangePassword', 'api/account/AdminChangePassword'],
      ['blockUserMaterial', 'api/user/blockUserMaterial'],
      ['updateUserContentNote', 'api/user/updateUserContentNote'],
      ['updateUserCareer', 'api/user/updateUserCareer'],
      ['allowRecommendation', 'api/user/allowRecommendation'],
      ['allowSecretaryRecommendation', 'api/user/secretary/allowRecommendation'],
      ['getAllEventsByUser', 'api/event/GetAllEventsByUser'],
      ['seeHow', 'api/user/seeHow'],
      ['updateUserColorPalette', 'api/user/updateUserColorPalette'],
      ['UpdateUserCertificate', 'api/user/UpdateUserCertificate'],
      ['getCertificatePdf', 'api/user/certificate/pdf'],
      /* Analytics */
      ['saveAction', 'api/analytics'],
      ['saveInteractions', 'api/analytics/saveInteraction'],
      ['removeAction', 'api/analytics/removeAction'],
      /* Responsible > Commands */
      ['createResponsibleTree', 'api/responsible/createResponsibleTree'],
      /* Audit Log */
      ['getAllUpdatedQuestionsDraft', 'api/auditLog/allQuestions'],
      ['getPagedAuditLogs', 'api/auditLog/paged'],
      ['getAllLogs', 'api/auditLog/allLogs'],
      ['getErrorEcommerceIntegration', 'api/errorLogs/paged/erro/ecommerceIntegrations'],
      /* Report Log */
      ['getTrackReportStudents', 'api/report/getTrackReportStudents'],
      ['getTrackReportStudentsFiltered', 'api/report/getTrackReportStudentsFiltered'],
      ['effectivenessIndicators', 'api/report/effectivenessIndicators'],
      ['getUserProgressReport', 'api/report/userProgress'],
      ['getCategories', 'api/report/getCategoriesQuery'],
      ['getTracksGrades', 'api/report/trackGrades'],
      ['getFinanceReport', 'api/report/getFinanceReport'],
      ['getTrackAnswers', 'api/report/getTrackAnswers'],
      ['getAtypicalMovements', 'api/report/getAtypicalMovements'],
      ['getStudentDashboard', 'api/report/getStudentDashboardQuery'],
      ['getStudentDashboardBySubject', 'api/report/getStudentDashboardBySubjectQuery'],
      ['getStudentInteraction', 'api/report/getStudentInteraction'],
      ['getStudentModuleInteraction', 'api/report/getStudentModuleInteraction'],
      ['getStudentEffort', 'api/report/getStudentEffort'],
      ['getTrackNps', 'api/report/getTrackNps'],
      ['getStatusReport', 'api/report/getStatusReport'],
      ['getModuleOpinion', 'api/report/GetModuleOpinion'],
      ['getTracksReport', 'api/report/getTracksReport'],
      ['getModulesReport', 'api/report/getModulesReport'],
      ['getSynchronousExperienceReport', 'api/report/getSynchronousExperienceReport'],
      ['getStudyPlanReport', 'api/report/getStudyPlanReport'],
      /* RecruitingCompany */
      ['getJobPositions', 'api/recruitingCompany/positions'],
      ['getJobPositionById', 'api/recruitingCompany/positionById'],
      ['addJobPosition', 'api/recruitingCompany/position'],
      ['deleteCandidateJobPosition', 'api/recruitingCompany/candidate'],
      ['updateJobPosition', 'api/recruitingCompany/position'],
      ['updateJobPositionStatus', 'api/recruitingCompany/positionStatus'],
      ['addCandidatesJobPosition', 'api/recruitingCompany/candidate'],
      ['approveCandidateToJobPosition', 'api/recruitingCompany/candidate/approve'],
      ['rejectCandidateToJobPosition', 'api/recruitingCompany/candidate/reject'],
      ['getAvailableCandidates', 'api/recruitingCompany/availableCandidates'],
      ['getUserJobPosition', 'api/recruitingCompany/user/positions'],
      ['approveUserJobPosition', 'api/recruitingCompany/acceptJob'],
      ['getJobsList', 'api/recruitingCompany/jobs/list'],
      ['applyTojob', 'api/recruitingCompany/user/applyTojob'],
      ['getUserJobPositionsById', 'api/recruitingCompany/user/positionById'],
      ['getUserJobNotifications', 'api/recruitingCompany/user/jobNotifications'],
      /* Valuatiom Test */
      ['getValuationTests', 'api/valuationTest'],
      ['getValuationPreview', 'api/valuationTest/valuation/preview'],
      ['deleteValuationTest', 'api/valuationTest'],
      ['downloadFromS3', 'api/valuationTest/downloadFromS3'],
      ['getValuationTestById', 'api/valuationTest/byId'],
      ['getValuationsUser', 'api/valuationTest/getValuation/byId'],
      ['getValuationTestResponses', 'api/valuationTest/responses'],
      ['getValuationTestResponseById', 'api/valuationTest/responses/byId'],
      ['getDraftValuationTestResponses', 'api/valuationTest/getResponses/draft/byId'],
      ['getAllValuationTestResponses', 'api/valuationTest/responses/all'],
      ['getAllValuationTestResponsesByGroup', 'api/valuationTest/group/responses/all'],
      ['manageValuationTest', 'api/valuationTest/manage'],
      ['gradeValuationTestAnswers', 'api/valuationTest/responses/grade'],
      ['getModuleValuationTests', 'api/valuationTest/module'],
      ['getTrackValuationTests', 'api/valuationTest/track'],
      ['getScheduleValuationTests', 'api/valuationTest/event'],
      ['checkTestAnswered', 'api/valuationTest/CheckTestAnswered'],
      ['getRandomicQuestions', 'api/valuationTest/getRandomicQuestions'],
      ['saveValuationTestResponse', 'api/valuationTest/responses/save'],
      ['saveDraftValuationTestResponse', 'api/valuationTest/responses/draft/save'],
      ['getAllLocations', 'api/location/getAll'],
      ['getPendingValuationsUser', 'api/valuationTest/user/pending/valuations'],
      /* Nps */
      ['getUserNpsInfos', 'api/user/getFilteredNps'],
      ['saveNpsValuation', 'api/user/saveNps'],
      ['getAllNpsInfos', 'api/user/getAllNps'],
      ['getUserNpsAvailability', 'api/user/getUserNpsAvailability'],
      /* Activations */
      ['getActivations', 'api/activation/getActivations'],
      ['getActivationByType', 'api/activation/GetActivationByType'],
      ['updateActivationStatus', 'api/activation/updateActivationStatus'],
      ['createCustomActivation', 'api/activation/createCustomActivation'],
      ['updateCustomActivation', 'api/activation/updateCustomActivation'],
      ['deleteActivation', 'api/activation'],
      /* Cockpit */
      ['getStudentsSubordinateByTrackId', 'api/track/cockpit/subordinates/tracks'],
      ['getCockpitModulesMetrics', 'api/module/cockpit/modules/metrics'],
      ['getCockpitTrackMetrics', 'api/track/cockpit/tracks/metrics'],
      ['getCockpitTrackMetricsStudentCurrent', 'api/track/cockpit/tracks/metrics/current/student'],
      ['getCockpitModuleMetricsStudentCurrent', 'api/module/cockpit/modules/metrics/current/student'],
      ['getOverviewTrackMetrics', 'api/track/overview/metrics'],
      ['getOverviewTrackMetricCurrentStudent', 'api/track/overview/metrics/current/student'],
      /* Exemptions */
      ['createExemptions', 'api/exemption/'],
      /* Exemptions Reasons */
      ['GetExemptionReasonsByUsersIds', 'api/exemptionReason/'],
      /* Module Question Valuation */
      ['getUserQuestionValuation', 'api/module/GetUserQuestionValuation'],
      ['addModuleUserQuestion', 'api/module/AddModuleUserQuestion'],
      ['updateModuleUserQuestion', 'api/module/UpdateModuleUserQuestion'],
      ['addModuleUserQuestionValuation', 'api/module/AddModuleUserQuestionValuation'],
      ['getFilteredUserQuestionValuations', 'api/module/GetFilteredUserQuestionValuations'],
      ['updateModuleQuestionStatus', 'api/module/UpdateModuleQuestionStatus'],
      /* Communications */
      ['CreateCommunication', 'api/communications/'],
      ['UpdateCommunication', 'api/communications/update'],
      ['DeleteCommunication', 'api/communications/delete'],

      ['InbentaLogin', 'api/account/inbenta'],
      /* TrackDraft */
      ['createTrackDraft', 'api/trackDraft/'],
      ['updateTrackDraft', 'api/trackDraft/'],
      ['deleteTrackDraft', 'api/trackDraft/'],
      ['getTrackDraftById', 'api/trackDraft/'],
      ['getAllTracksAndDraft', 'api/trackDraft/paged'],
      ['publishTrackDraft', 'api/trackDraft/publishTrackDraft'],
      /* StudyPlan */
      ['CreateStudyPlan', 'api/studyPlan'],
      ['UpdateStudyPlan', 'api/studyPlan/'],
      ['DeleteStudyPlan', 'api/studyPlan/'],
      ['DeleteAllStudyPlanUsers', 'api/studyPlan/deleteAllStudyPlanUsers'],
      ['DeleteStudyPlanUser', 'api/studyPlan/user'],
      ['GetStudyPlanById', 'api/studyPlan/byId'],
      ['GetAllStudyPlan', 'api/studyPlan'],
      ['AddStudy', 'api/studyPlan/user'],
      ['GetUsersByStudyPlanId', 'api/studyPlan/user'],
      ['GetUsersPagedStudyPlan', 'api/studyPlan/users/paged'],
      ['GetMyJourney', 'api/studyPlan/myJourney'],
      ['GetMandatoryTraining', 'api/studyPlan/mandatoryTraining'],
      ['GetInstitutionalTraining', 'api/studyPlan/institutionalTraining'],
      ['getAllCourses', 'api/studyPlan/getAllCourses'],
      ['getMyFavoriteCourses', 'api/studyPlan/myFavorites'],
      ['getMyLibrary', 'api/studyPlan/myLibrary'],
      ['importStudyPlanBlocks', 'api/studyPlan/importBlocks'],
      /* Groups */
      ['CreateGroups', 'api/groups/'],
      ['UpdateGroups', 'api/groups/'],
      ['DeleteGroups', 'api/groups/'],
      ['GetAllGroups', 'api/groups/GetAll'],
      ['GetById', 'api/groups/ById'],
      /* Sector */
      ['GetAllSectors', 'api/sector/'],
      ['addSector', 'api/sector/'],
      ['GetSectorById', 'api/sector/ById'],
      ['updateSector', 'api/sector/'],
      ['GetAllSectors', 'api/sector/'],
      ['GetFilters', 'api/sector/filters'],
      ['GetSectorsPaged', 'api/sector/paged/sector'],
      ['deleteSector', 'api/sector/'],
      /* Segment */
      ['GetBySectorId', 'api/segment/bySectorId'],
      ['GetAllSegment', 'api/segment/'],
      ['GetAllSegmentPaged', 'api/segment/paged'],
      ['updateSegment', 'api/segment/'],
      ['addSegment', 'api/segment/'],
      ['UnlinkSector', 'api/segment/unlinkSector'],
      ['GetSegmentById', 'api/segment/ById'],
      ['deleteSegment', 'api/segment/'],
      /* Teams */
      ['CreateTeam', 'api/team'],
      ['UpdateTeam', 'api/team'],
      ['DeleteTeam', 'api/team'],
      ['ImportTeam', 'api/team/import/team'],
      ['GetStudents', 'api/team/students/byIds'],
      /* Sector */
      ['GetAllSectors', 'api/sector/'],
      ['addSector', 'api/sector/'],
      ['GetSectorById', 'api/sector/ById'],
      ['updateSector', 'api/sector/'],
      ['GetAllSectors', 'api/sector/'],
      ['GetFilters', 'api/sector/filters'],
      ['GetSectorsPaged', 'api/sector/paged/sector'],
      ['deleteSector', 'api/sector/'],
      /* Segment */
      ['GetBySectorId', 'api/segment/bySectorId'],
      ['GetAllSegment', 'api/segment/'],
      ['GetAllSegmentPaged', 'api/segment/paged'],
      ['updateSegment', 'api/segment/'],
      ['addSegment', 'api/segment/'],
      ['UnlinkSector', 'api/segment/unlinkSector'],
      ['GetSegmentById', 'api/segment/ById'],
      ['deleteSegment', 'api/segment/'],
      /* Groups */
      ['CreateGroups', 'api/groups/'],
      ['UpdateGroups', 'api/groups/'],
      ['DeleteGroups', 'api/groups/'],
      ['GetAllGroups', 'api/groups/GetAll'],
      ['GetById', 'api/groups/ById'],
      /* TrackDraft */
      ['createTrackDraft', 'api/trackDraft/'],
      ['updateTrackDraft', 'api/trackDraft/'],
      ['deleteTrackDraft', 'api/trackDraft/'],
      ['getTrackDraftById', 'api/trackDraft/'],
      ['getAllTracksAndDraft', 'api/trackDraft/paged'],
      ['publishTrackDraft', 'api/trackDraft/publishTrackDraft'],
      /* AutomaticSync */
      ['getAutomaticSyncs', 'api/automaticSync/getAutomaticSyncs'],
      ['updateAutomaticSync', 'api/automaticSync/updateAutomaticSync'],
      ['deleteAutomaticSync', 'api/automaticSync/deleteAutomaticSync'],
      ['automaticSyncs', 'api/automaticSync/automaticSyncs'],
      ['getPagedRelationalItens', 'api/automaticSync/getPagedRelationalItens']
    ]));
  }

}
