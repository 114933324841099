<div class="header">
    <div class="inner-content"> 
        <p class="title">CURADORIA</p>
        <div class="button-div">
            <p class="button-text first" [ngClass]="{'selected': tab === 1}" (click)="tab = 1">
                <span>TAGS</span>
                <span *ngIf="tagPendingCount > 0" class="count" [ngClass]="{'selected': tab === 1}">
                    {{tagPendingCount}}
                </span>
            </p>
            <p class="button-text second" [ngClass]="{'selected': tab === 2}" (click)="tab = 2">
                <span>QUESTÕES</span>
                <span *ngIf="questionPendingCount > 0" class="count" [ngClass]="{'selected': tab === 2}">
                    {{questionPendingCount}}
                </span>
            </p>
        </div>
    </div>
</div>
<ng-container *ngIf="tab === 1">
    <app-module-tag-curatorship
        (updateModuleCount)="getCuratorshipPendingCount()"
    ></app-module-tag-curatorship>
</ng-container>

<ng-container *ngIf="tab === 2">
    <app-module-question-curatorship
        (updateModuleCount)="getCuratorshipPendingCount()"
    ></app-module-question-curatorship>
</ng-container>