<div class="inner-content">
  <app-exam-header [subTitle]="subject?.title" [levels]="levels" [showButton]="showButton" [isMock]="isMock"
    [isPracticing]="isPracticing" (finished)="finish()">
  </app-exam-header>

  <div class="content notranslate" translate="no"
    [ngClass]="{ 'reviewing': reviewingConcept, 'no-border': !isQuestionBdq }">
    <div [ngClass]="{'answers': true,'no-border': !isQuestionBdq }">
      <p class="number">
        {{title ? title : 'Questão'}}
      </p>
      <p class="subtitle" *ngIf="question.answerCount && hasNewLearningTechniques">
        Tentativas restantes ({{question.answerCount + 1}}/3)
        <mat-icon aria-hidden="false" matTooltipClass="tooltip-class"
          matTooltip="Errar ou chutar muitas questões repetidamente pode fazer com que você seja impedido de progredir nesse nivél.">
          info</mat-icon>
      </p>
      <p class="subtitle" *ngIf="question.type && isQuestionBdq">
        {{question.type === mixedAnswersType.Anticipated ? "Antecipação" : "Recuperação"}} de Questões
        <mat-icon aria-hidden="false" matTooltipClass="tooltip-class"
          matTooltip="Essa é uma questão {{question.type === mixedAnswersType.Anticipated ? 'antecipada' : 'recuperada'}} e não influenciará no seu progresso do assunto!">
          info</mat-icon>
      </p>

      <p class="statement notranslate" translate="no" [innerHtml]="question.text || question.title | MarkdownToHtml">
      </p>

      <app-exam-answer *ngFor="let answer of question?.answers || question?.options" [answer]="answer"
        [selected]="selectedAnswer === answer" [confirmed]="answered && isQuestionBdq"
        (selectAnswer)="setAnswer($event)"></app-exam-answer>

      <br>
      <button class="btn-test bdq" (click)="confirm()" [disabled]="!selectedAnswer"
        [ngClass]="{ 'answered': answered }">
        {{ answered && !this.hasChange() ? last ? 'Finalizar' : 'Próxima Pergunta' : 'Confirmar Resposta' }}
      </button>

      <button class="btn-outline bdq" (click)="skippedQuestion.emit()"
        *ngIf="isQuestionBdq && hasNewLearningTechniques && !question.skipped && !question.isMixedQuestion && !isPracticing && !answered">
        Pular Questão </button>

      <ng-container *ngIf="hasComment && showComment && question && question.comment">
        <div class="inner-content-padding">
          <button class="btn-test primary" (click)="seeComment()">
            Ver comentário
          </button>
        </div>
      </ng-container>
    </div>
    <div class="concepts" *ngIf="question.concepts && question.concepts.length > 0 && isQuestionBdq">
      <p>Revisar Conceitos Abordados</p>
      <ul *ngIf="!selectedAnswer || !selectedAnswer.concepts">
        <li class="concept" *ngFor="let concept of question.concepts" (click)="reviewConcept(concept)"
          [ngClass]="{ 'selected': reviewingConcept === concept }">
          {{ concept }}
        </li>
      </ul>
      <ul *ngIf="selectedAnswer && selectedAnswer.concepts">
        <li class="concept" *ngFor="let concept of selectedAnswer.concepts" (click)="reviewConcept(concept)"
          [ngClass]="{ 'selected': reviewingConcept === concept }">
          {{ concept.concept }}
          <img *ngIf="answered && !concept.isRight" src="./assets/img/wrong-answer-colored.png" />
          <img *ngIf="answered && concept.isRight" src="./assets/img/right-answer-colored.png" />
        </li>
      </ul>
    </div>
  </div>

</div>
