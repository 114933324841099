<div class="content">
  <p class="title">METRICA DE EMPENHO</p>
  <app-cockpit-buttons type="understand" (clickButton)="dispatchDialog()"></app-cockpit-buttons>
  <app-cockpit-chart [effortMetric]="effortMetric"></app-cockpit-chart>
  <app-cockpit-details title="engajamento" class="diagnostic column-end">
    <ng-container first>
      <p class="items-title cyan">Meu Progresso</p>
      <p class="items-subtitle">{{effortMetric?.studentProgressUntilDay?.toFixed(1)}}%</p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Progresso Turma</p>
      <p class="items-subtitle">{{effortMetric?.classProgressUntilDay?.toFixed(1)}}%</p>
    </ng-container>
    <ng-container third>
      <p class="items-title dark-blue">Progresso Esperado</p>
      <p class="items-subtitle">{{effortMetric?.expectedProgressUntilDay?.toFixed(1)}}%</p>
    </ng-container>
  </app-cockpit-details>

  <app-cockpit-details title="participação" class="events column-end">
      <ng-container first>
          <p class="items-title cyan">Minha Part.</p>
          <p class="items-subtitle">{{effortMetric?.studentParticipationUntilDay?.toFixed(1)}}%</p>
      </ng-container>
      <ng-container second>
          <p class="items-title yellow">Part. Turma</p>
          <p class="items-subtitle">{{effortMetric?.classParticipationUntilDay?.toFixed(1)}}%</p>
      </ng-container>
  </app-cockpit-details>

  <app-cockpit-details title="esforço" class="valuation column-end">
    <ng-container first>
      <p class="items-title cyan">Meu Esforço.</p>
      <p class="items-subtitle">{{effortMetric?.studentInteractionUntilDayInHours?.toFixed(1)}}h</p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Esforço Turma</p>
      <p class="items-subtitle">{{effortMetric?.classInteractionUntilDayInHours?.toFixed(1)}}h</p>
    </ng-container>
    <ng-container third>
      <p class="items-title red">Progresso Esperado</p>
      <p class="items-subtitle">{{effortMetric?.expectedInteractionUntilDayInHours?.toFixed(1)}}h</p>
    </ng-container>
  </app-cockpit-details>

  <ng-template #understandDialogTemplate>
    <app-understand-content title="Entenda Métricas de Empenho"
      text="métricas relacionadas a dedicação da pessoa seja em horas ou cumprimento de prazos"
      (close)="dialog.close()">
    </app-understand-content>
  </ng-template>
</div>
