<div class="subject-content"
  [ngClass]="{ 'last': index === subject.contents.length - 1 }"
  (click)="goToContent()"
>
  <div style="width: 100%">
    <div class="header" >
      <p class="title" >
        {{ subject.contents[index].title }}
      </p>
    </div>
    <p class="description" >
      {{ subject.contents[index].excerpt }}
    </p>
  </div>
  <i class="icon-seta_esquerda"></i>
</div>
