import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCardComponent } from './course-card.component';
import { ProgressBarModule } from '../layout/progress-bar/progress-bar.module';
import { HeaderComponent } from './header/header.component';
import { InfoComponent } from './info/info.component';
import { CardTagModule } from '../layout/card-tag/card-tag.module';
import { ExcerptPipe } from './Pipes/excerpt.pipe';
import { SubscriptionEndPipe } from './Pipes/subscriptionEnd.pipe';
import { RequirementPipe } from './Pipes/requirement.pipe';
import { MatIconModule } from '@angular/material/icon';
import { hasRequirementPipe } from './Pipes/hasRequirement.pipe';



@NgModule({
  declarations: [CourseCardComponent, HeaderComponent, InfoComponent, ExcerptPipe, SubscriptionEndPipe, RequirementPipe, hasRequirementPipe],
  imports: [
    CommonModule,
    ProgressBarModule,
    CardTagModule,
    MatIconModule
  ],
  exports: [CourseCardComponent]
})
export class CourseCardModule { }
