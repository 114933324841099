export class QuestionAndAnswer {
  questionId: string;
  title: string;
  answer: string;
  type: ValuationTestQuestionType;
  grade?: number;
  percentage?: number;
  comment?: string;
  canceled: boolean;

  constructor(answer: any) {
    this.questionId = answer.questionId;
    this.title = answer.question;
    this.answer = answer.answer;
    this.type = answer.type;
    this.grade = answer.grade;
    this.percentage = answer.percentage;
    this.comment = answer.comment;
    this.canceled = answer.canceled;
  }
}

export enum ValuationTestQuestionType {
  MultipleChoice = 1,
  Discursive = 2,
  Coursework = 3,
  Nps = 4
}
