import { Component, EventEmitter, Output, Input } from '@angular/core';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { UserPreview } from 'src/app/models/previews/user.interface';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent {

  @Input() selectedUser: UserPreview;
  @Input() readonly placeholder: string = 'Nome/e-mail';
  @Output() userRemoved = new EventEmitter<UserPreview>();
  @Output() userSelected = new EventEmitter<UserPreview>();


  public users: Array<UserPreview> = [];

  constructor(private _userService: SettingsUsersService) { }

  public selectUser(user: UserPreview) {
    this.selectedUser = user;
    this.resetUserSearch();
    this.userSelected.emit(this.selectedUser);
  }

  public removeSelectedUser() {
    this.userRemoved.emit(this.selectedUser);
    this.selectedUser = null;
  }

  public resetUserSearch(): void {
    this.users = [];
  }


  public searchUsers(searchValue: string) {
    if (searchValue === '') {
      this.removeSelectedUser();
    }
    if (searchValue && searchValue.trim() !== '')
      this._loadUsers(searchValue);
  }

  private _loadUsers(searchValue: string = ''): void {
    this._userService
      .getPagedFilteredUsersList({ page: 1, pageSize: 4, searchValue })
      .subscribe(response => {
        this.users = response.data.userItems;
      });
  }

}
