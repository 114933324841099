import { CategoryFilterSearchOption, CategoryFilterOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { UserPreview } from 'src/app/models/previews/user.interface';

export class StudentReport {
  public id: string;
  public trackId: string;
  public moduleId: string;
  public userId: string;
  public userName: string;
  public businessGroup: string;
  public businessUnit: string;
  public segment: string;
  public trackTitle: string;
  public trackAreaTitle: string;
  public trackSubAreaTitle: string;
  public moduleName: string;
  public startedModule: string;
  public level: number;
  public goalAchieved: string;
  public bdqStartDate: string;
  public bdqEndDate: string;
  public cutOffDate: string;
  public percentageOfCorrectAnswers: number;
  public percentageOfWrongAnswers: number;
  public answerBeginnerReport: AnswerLevelReport;
  public answerIntermediateReport: AnswerLevelReport;
  public answerAdvancedReport: AnswerLevelReport;
  public gradeAsProof: number;
  public grade: number;
  public bdqGrade: number;
  public beforeModuleGrade: number;
  public afterModuleGrade: number;
  public totalQuestionAnswered: number;
  public totalModuleQuestion: number;
  public levelUntilCutOfDate: number;
  public exemption: boolean;
  public subjectId: string;
  public subjectName: string;
  public finishedSubject: string;
  public totalSubjectQuestion: number;
  public totalSubjectQuestionAnswered: number;
  public registrationId: string;
  public totalContentCompleted?: number;
  public totalModuleContent?: number;
  public totalSubjectContent?: number;
}

export class AnswerLevelReport {
  public level: number;
  public answer: AnswerReport;
}


export class AnswerReport {
  correct: number;
  wrong: number;
}


export interface AnswerReportFilter {
  categories: { columnName: string, contentNames: CategoryFilterOption[] }[];
  student: UserPreview;
}
