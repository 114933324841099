<div class="main">
  <app-my-course-sectors title="Favoritos" type="myFavorite" [coursers]="courses" [itemsCount]="coursesCount"
    [pageSize]="pageSize" (goToPage)="goToPage($event)">
  </app-my-course-sectors>

  <app-my-course-sectors title="Minha Biblioteca" type="myLibrary" [coursers]="library" [itemsCount]="libraryCount"
    [pageSize]="pageSize" (goToPage)="goToPage($event)">
  </app-my-course-sectors>

</div>
