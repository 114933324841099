<div class="list-wrapper">
  <div class="list-header">
    <span class="title"> {{title}} </span>
    <ng-content select="[header-button]"></ng-content>
  </div>
  <ul class="list">
    <li class="item" *ngFor="let item of getChoppedItens(); let index = index" (click)="click.emit(item)">
      <app-item *ngIf="!itemTemplate; else templateWrapper" [item]="item"></app-item>
      <ng-template #templateWrapper>
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </ng-template>
    </li>
  </ul>
  <p class="no-itens" *ngIf="itens && itens.length === 0"> Não há itens para exibir. </p>
  <div class="action" *ngIf="showAll && itens && itens.length > 0 && itens.length >= viewElements">
    <button (click)="viewAllItens()"> VER TODOS </button>
  </div>
</div>
