<div class="inner-content">
  <h4>PRINCIPAIS ATIVIDADES</h4>
      <p class="perfil-info-und">
        {{job?.employment?.activities?.activity}}<br>
      </p>
      <h4>PERFIL DESEJADO / CARACTERÍSTICAS</h4>
      <p class="perfil-info-und">
        {{job?.employment?.activities?.character}}<br>
      </p>
      <h4>HABILIDADES COMPORTAMENTAIS</h4>
      <p class="perfil-info-und">
        {{job?.employment?.activities?.abilities}}<br>
      </p>
      <h4>LINHA DE SUPORTE</h4>
      <p class="perfil-info-und">
        {{job?.employment?.activities?.report}}
      </p>
</div>
