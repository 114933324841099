import { Component, Input, OnInit } from '@angular/core';
import { SupportMaterial } from '../../../../../models/support-material.interface';
import { ActionInfoPageEnum, ActionInfoTypeEnum, ActionInfo } from 'src/app/shared/directives/save-action/action-info.interface';
import { isNil } from 'lodash';

@Component({
  selector: 'app-support-material',
  templateUrl: './support-material.component.html',
  styleUrls: ['./support-material.component.scss']
})
export class SupportMaterialComponent implements OnInit {

  @Input() readonly moduleId?: string;
  @Input() readonly eventId?: string;

  public linkToDownload: string;

  private _material: SupportMaterial;
  @Input()
  public get material(): SupportMaterial { return this._material; }
  public set material(material: SupportMaterial) {
    this._material = material;
    if (this.material) this.linkToDownload = (this.material.downloadLink.indexOf('//') < 0 ? '//' : '') + material.downloadLink;
  }

  private _isEvent: boolean;

  constructor() { }

  ngOnInit(): void {

    const hasModule = !isNil(this.moduleId);
    const hasEvent = !isNil(this.eventId);

    this._isEvent = !hasModule && hasEvent;

    if (!hasEvent && !hasModule) throw new Error('Necessário declarar pelo menos um tipo de entidade');
  }

  public getActionInfo(description: string): ActionInfo {
    return {
      page: this._isEvent ? ActionInfoPageEnum.Event : ActionInfoPageEnum.Module,
      description: description,
      type: ActionInfoTypeEnum.Click,
      moduleId: this.moduleId || null,
      eventId: this.eventId || null,
      supportMaterialId: this.material.id
    };
  }

}
