import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { Valuations } from 'src/app/models/my-course-valuation.model';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';

@Component({
  selector: 'app-my-course-sectors',
  templateUrl: './my-course-sectors.component.html',
  styleUrls: ['./my-course-sectors.component.scss']
})
export class MyCourseSectorsComponent {

  @Input() title: string;
  @Input() type: string;
  @Input() coursers: Array<ModulePreview | TrackPreview | EventPreview | Valuations>;
  @Input() itemsCount: number;
  @Input() pageSize: number;
  @Output() goToPage = new EventEmitter<any>();
  constructor() { }

}
