import { Component, Input } from '@angular/core';
import { WrongItem } from 'src/app/shared/components/most-wrong-item/models/wrong-item.model';

@Component({
  selector: 'app-wrong-item',
  template: `
    <p class="description">
        {{ item?.title }}
        <small *ngIf="item?.subtitle">{{ item?.subtitle }}</small>
    </p>
    <p class="count"> {{ item.count }} </p>
 `,
  styleUrls: ['./wrong-item.component.scss']
})
export class WrongItemComponent {

  @Input() readonly item: WrongItem;

}
