import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'contains' })
export class ContainsPipe implements PipeTransform {

    transform(id: string, array: string[] = []) {
        return array.includes(id);
    }

}
