<div class="content">
  <div class="content-box">
    <div class="border-bottom">
      <p class="title" *ngIf="dataSize"><strong>{{dataSize}}</strong> {{ firstTitle }}
      </p>
      <app-list-search *ngIf="!isTree && !disableSearch" [placeholder]="searchPlaceholder" [noPadding]="true"
        (triggerSearch)="search.emit($event)"></app-list-search>
    </div>
    <div>
      <div class="sub-title border-bottom">
        <div>
          <p class="title"><strong>{{getTrackChecked()}}</strong> selecionados</p>
          <span class="link" *ngIf="!isTree" (click)="deSelectAlldata()">
            Limpar Seleção
          </span>
          <span class="link" *ngIf="!isTree" (click)="selectAlldata()">
            Selecionar todos
          </span>
        </div>
        <span class="material-icons" (click)="selectdataChecked()">
          forward
        </span>
      </div>
      <ng-container>
        <ul *ngIf="!isTree && !hasInfiniteScroll">
          <li class="border-bottom" *ngFor="let item of data">
            <div class="track-item">
              <div>
                <mat-checkbox [(ngModel)]="item.checked" class="example-margin"></mat-checkbox>
                <p>{{item.title}}</p>
                <mat-icon *ngIf="item.info" [matTooltip]="item.info.text">insert_link</mat-icon>
              </div>
              <span class="material-icons" (click)="selectTrack(item)">
                forward
              </span>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="!isTree && hasInfiniteScroll">

        <cdk-virtual-scroll-viewport itemSize="44" class="content-ul" *ngIf="!isTree && hasInfiniteScroll">
          <li class="border-bottom" [ngClass]="{'disabled':  item.id | canDisable: dataSelected}"
            *cdkVirtualFor="let item of data">
            <div class="track-item">
              <div>
                <mat-checkbox [(ngModel)]="item.checked" [disabled]="item.id | canDisable: dataSelected"
                  class="example-margin"></mat-checkbox>
                <p>{{item.title}}</p>

              </div>
              <span class="material-icons" (click)="selectTrack(item)"
                [ngClass]="{'disabled':  item.id | canDisable: dataSelected}">
                forward
              </span>
            </div>
          </li>
        </cdk-virtual-scroll-viewport>
      </ng-container>

      <div *ngIf="isTree">
        <mat-accordion class="example-headers-align" *ngFor="let item of data" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{item.title}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul *ngIf="!isNil(item[key])">
              <li class="border-bottom" *ngFor="let i of item[key]">
                <div class="track-item">
                  <div>
                    <p>{{i.title}}</p>
                  </div>
                  <span class="material-icons" (click)="selectTreeData(i, item, $event)">
                    forward
                  </span>
                </div>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </div>
  </div>
  <div class="content-box">
    <div class="border-bottom">
      <p class="title"><strong>{{dataSelected.length}}</strong> {{ secondTitle }}</p>
    </div>
    <ul *ngIf="!isTree" class="ul-selected">
      <li class="border-bottom" *ngFor="let item of dataSelected">
        <div class="track-item">
          <div>
            <p>{{item.title}}</p>
          </div>
          <span class="material-icons color-red" (click)="deselectData(item)">
            delete
          </span>
        </div>
      </li>
    </ul>
    <div *ngIf="isTree && !isNil(dataSelected)">
      <mat-accordion class="example-headers-align" *ngFor="let item of dataSelected" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{item.title}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul *ngIf="!isNil(item[key])">
            <li class="border-bottom" *ngFor="let i of item[key]">
              <div class="track-item">
                <div>
                  <p>{{i.title}}</p>
                </div>
                <span class="material-icons color-red" (click)="deselectTreeData(i, item)">
                  delete
                </span>
              </div>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

</div>
