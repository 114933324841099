import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import { AuthService } from '../../../../projects/http-infrastructure/src/lib/services/auth-service';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const REFRESH_TOKEN_BUFFER = 30; // 30 seconds
const IntervalTime = 10*1000; // 10 Seconds

@Injectable()
export class TokenRefresherService {
  private tokenExpiration: number;
  private intervalId: any;
  private isRefreshActive = false;
  private isRefresing = false;

  constructor(private _tokenService: TokenStorageService, private _authService: AuthService) { }

  startTokenRefresh() {
    this.isRefreshActive = true;
    this.intervalId = setInterval(() => {
        if (!this.isRefreshActive) {
          clearInterval(this.intervalId);
          return;
        }
      const currentTime = new Date().getTime() / 1000;
      const token = this._tokenService.getToken();
      if(token){
      this.tokenExpiration = (JSON.parse(atob(token.split('.')[1]))).exp;
      const timeToExpire = this.tokenExpiration - currentTime;
      if (timeToExpire < REFRESH_TOKEN_BUFFER && !this.isRefresing) {
        this.isRefresing = true;
        this._tokenService.renewTokenTimeExpiration();
        this.refreshToken();

      }
    }
    }, IntervalTime);
  }
  stopTokenRefresh() {
    this.isRefreshActive = false;
  }

  refreshToken(){
    const token = this._tokenService.getRefreshToken();
    this._authService.refreshsToken(token).subscribe((response) =>{
        const authData = response.data;
        authData.access_token = this.addBearer(authData.access_token);
        const token = authData.access_token;

        this._tokenService.saveAllTokens(authData);
        this.isRefresing = false;
    });
  }

  private addBearer(acess_token) {
    return 'Bearer ' + acess_token;
  }
}