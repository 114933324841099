import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-exam-header',
  templateUrl: './exam-header.component.html',
  styleUrls: ['./exam-header.component.scss']
})
export class ExamHeaderComponent {

  @Input() readonly subTitle?: string;
  @Input() readonly levels?: string;
  @Input() readonly showButton?: boolean = false;
  @Input() readonly isMock?: boolean = false;
  @Input() readonly isPracticing: boolean = false;

  @Output() finished = new EventEmitter();
  constructor() { }


  public finish(): void {
    this.finished.emit();
  }

}
