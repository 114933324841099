import { Injectable, EventEmitter } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { FirstAccessInfo } from 'src/app/models/login.model';
import { environment } from '../../../environments/environment';
import { UserPreviewInterceptor } from '../interfaces/user-preview-interceptor.interface';
import { ERole } from 'src/app/models/enums/role.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { TokenStorageService } from 'src/app/shared/services/token-storage.service';
import { addSeconds } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { Local } from 'protractor/built/driverProviders';

export class Result<T> {
  public data: T;
  public success: boolean;
  public errors: string[];
}

export class LoginResponse {
  public access_token: string;
  public expires_in: number;
  public refresh_token: string;
  public name: string;
  public username: string;
  public user_id: string;
  public role: string;
  public completed_registration: boolean;
  public first_access: boolean;
  public email_verified: boolean;
  public adminAccesses?: Array<EAccess>;
  public have_dependents?: boolean;
  public info_is_completed: boolean;
}

@Injectable()
export class AuthService {

  public personificationChanged$ = new EventEmitter<boolean>();

  private emailVerificationKey = 'email_verification';
  private isLoginSubject: BehaviorSubject<boolean>;
  private setColorPalette: BehaviorSubject<boolean>;

  public getBearer(token: string) { return `Bearer ${token}`; }

  constructor(private _httpService: BackendService,
    private router: Router,
    private tokenService: TokenStorageService,
    private _cookieService: CookieService) {
    const hasAuthData = this.hasToken();
    this.isLoginSubject = new BehaviorSubject<boolean>(hasAuthData);
    this.setColorPalette = new BehaviorSubject<boolean>(hasAuthData);
  }

  public async login(username: string, password: string): Promise<any> {
    let result: any = { success: false };
    try {
      result =
        await this._httpService.post<LoginResponse>('login', {
          'UserName': username,
          'Password': password
        }).toPromise();
    } catch (err) {
      if (err.error) {
        result = err.error;
      }
    }
    if (result.success) {
      if (result.data.user.logoUrl && result.data.user.logoUrl.length > 0) {
        localStorage.setItem(LocalStorageService.key.logoUrl, result.data.user.logoUrl);
      }
      if (result.data.user.colorBaseValues && result.data.user.colorBaseValues.length > 0) {
        localStorage.setItem(LocalStorageService.key.colorPalette, JSON.stringify(result.data.user.colorBaseValues));
        this.setColorPalette.next(true);
      }
      if (environment.features.firstLoginPersonalDataForm) {
        if (!result.data.tokenInfo.first_access && result.data.tokenInfo.email_verified) {
          result.data.tokenInfo.access_token = this.getBearer(result.data.tokenInfo.access_token);
          localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(result.data.tokenInfo));
          this.isLoginSubject.next(true);
        } else {
          localStorage.setItem(this.emailVerificationKey, JSON.stringify({
            userId: result.data.tokenInfo.user_id,
            email: result.data.tokenInfo.email
          }));
        }
      } else {
        if (result.data.tokenInfo.email_verified) {
          result.data.tokenInfo.access_token = this.getBearer(result.data.tokenInfo.access_token);
          localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(result.data.tokenInfo));
          this.isLoginSubject.next(true);
        } else {
          localStorage.setItem(this.emailVerificationKey, JSON.stringify({
            userId: result.data.tokenInfo.user_id,
            email: result.data.tokenInfo.email
          }));
        } 
      }
      this.tokenService.saveAllTokens({ ...result.data.tokenInfo, remember: true });
      this._cookieService.set('auth-token', result.data.tokenInfo.access_token, addSeconds(new Date(), result.data.tokenInfo.expiresIn), '/', undefined, false, 'Lax');
    }
    return result;
  }

  public async ssoLogin(tokenInfo: LoginResponse) {
    tokenInfo.access_token = this.getBearer(tokenInfo.access_token);
    if (!tokenInfo.first_access || !environment.features.firstLoginPersonalDataForm) {
      localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(tokenInfo));
      this.isLoginSubject.next(true);
    }
  }

  public async firstAccess(credentials: FirstAccessInfo): Promise<any> {
    let result: any = { success: false };
    try {
      result =
        await this._httpService.post<LoginResponse>('firstAccess', credentials).toPromise();
    } catch (err) {
      if (err.error) {
        result = err.error;
      }
    }
    if (result.success) {
      if (result.data.email_verified) {
        result.data.access_token = this.getBearer(result.data.access_token);
        localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(result.data));
        this.isLoginSubject.next(true);
      } else {
        localStorage.setItem(this.emailVerificationKey, JSON.stringify({
          userId: result.data.user_id,
          email: result.data.email
        }));
      }
    }
    return result;
  }

  public logout(): void {
    this.isLoginSubject.next(false);
    this.setColorPalette.next(false);
    localStorage.clear();
  }

  public isLoggedIn({ getRawValue }: { getRawValue: boolean }): boolean;
  public isLoggedIn(): Observable<boolean>;
  public isLoggedIn({ getRawValue } = { getRawValue: false }) {
    if (!this.hasToken()) return getRawValue ? false : this.isLoginSubject.asObservable();
    return getRawValue ? this.isLoginSubject.getValue() : this.isLoginSubject.asObservable();
  }

  public setUserColorPalette(): Observable<boolean> {
    return this.setColorPalette.asObservable();
  }

  public updateColorPallete() {
    this.setColorPalette.next(true);
  }

  public async register(newUser: User): Promise<any> {
    let result: any = { success: false };
    try {
      result =
        await this._httpService.post<LoginResponse>('register', newUser).toPromise();
    } catch (err) {
      if (err.error) {
        result = err.error;
      }
    }
    if (result.success) {
      localStorage.setItem(this.emailVerificationKey, JSON.stringify({
        userId: result.data.user_id,
        email: result.data.email
      }));
    }
    return result;
  }

  public async sendVerificationEmail(newEmail: boolean = false): Promise<any> {
    let result: any = { success: false };
    try {
      const userId = JSON.parse(localStorage.getItem(this.emailVerificationKey)).userId;
      result =
        await this._httpService.post<LoginResponse>('sendVerificationEmail',
          {
            'userId': userId,
            'newEmail': newEmail
          }).toPromise();
    } catch (err) {
      if (err.error) {
        result = err.error;
      }
    }
    return result;
  }

  public async verifyEmailCode(code: string): Promise<any> {
    let result: any = { success: false };
    try {
      const userId = JSON.parse(localStorage.getItem(this.emailVerificationKey)).userId;
      result =
        await this._httpService.post<LoginResponse>('verifyEmailCode',
          {
            'userId': userId,
            'code': code
          }).toPromise();
    } catch (err) {
      if (err.error) {
        result = err.error;
      }
    }
    if (result.success) {
      result.data.access_token = this.getBearer(result.data.access_token);
      localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(result.data));
      this.isLoginSubject.next(true);
    }
    return result;
  }

  public getLoggedUser(): LoginResponse {
    const authData = localStorage.getItem(LocalStorageService.key.authData);
    return authData ? JSON.parse(authData) : null;
  }

  public getEmailVerification() {
    const authEmailData = localStorage.getItem(this.emailVerificationKey);
    return authEmailData ? JSON.parse(authEmailData) : null;
  }

  public getLoggedUserId(): string {
    const authData = this.getLoggedUser();
    return authData ? authData.user_id : null;
  }

  public getLoggedUserRole(): string {
    const authDataString = localStorage.getItem(LocalStorageService.key.authData);
    const parsedData: LoginResponse = !!authDataString ? JSON.parse(authDataString) : authDataString;
    return parsedData !== null ? parsedData.role : null;
  }

  public hasRole(role: ERole): boolean {
    const user = this.getLoggedUser();
    return user && user.role && user.role === role.toString();
  }

  public LoggedUserisAdmin(): boolean {
    const user = JSON.parse(localStorage.getItem('loggedUser')) ? JSON.parse(localStorage.getItem('loggedUser')) : null;
    return user && user.role && (
      user.role === ERole.Admin || user.role === ERole.HumanResources
    );
  }

  public hasToken() {
    return localStorage.getItem(LocalStorageService.key.authData) ? true : false;
  }

  public forgotPass(username: string): Observable<any> {
    return this._httpService.post('forgotPassword', { 'username': username });
  }

  public setUserRoleToSeeHow(role: ERole): void {
    const userRole = role as string;
    localStorage.setItem(LocalStorageService.key.seeHowRole, userRole);
    localStorage.setItem(LocalStorageService.key.originalRole, this.getLoggedUserRole());
    const authData = this.getLoggedUser();
    authData.role = userRole;
    localStorage.removeItem(LocalStorageService.key.authData);
    localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(authData));
  }

  public getUserRoleToSeeHow(): string {
    return localStorage.getItem(LocalStorageService.key.seeHowRole);
  }

  public getOriginalRole(): string {
    return localStorage.getItem(LocalStorageService.key.originalRole);
  }

  public restoreOriginalAccessAndRoles(): void {
    localStorage.removeItem(LocalStorageService.key.seeHowRole);
    localStorage.removeItem(LocalStorageService.key.userToSeeHowManager);
    localStorage.removeItem(LocalStorageService.key.selectedAccess);
    const loggedUserRole = localStorage.getItem(LocalStorageService.key.originalRole);
    localStorage.removeItem(LocalStorageService.key.originalRole);
    const authData = this.getLoggedUser();
    authData.role = loggedUserRole ? loggedUserRole : authData.role;
    localStorage.removeItem(LocalStorageService.key.authData);
    localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(authData));
  }

  public setAccess(access: EAccess): void {
    localStorage.setItem(LocalStorageService.key.selectedAccess, access);
  }
  public setInfoIsCompleted(isCompleted: boolean): void {
    const authData = JSON.parse(localStorage.getItem(LocalStorageService.key.authData));
    authData[LocalStorageService.key.infoIsCompleted] = isCompleted;
    localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(authData));
  }
  public getSelectedAccess(): string {
    return localStorage.getItem(LocalStorageService.key.selectedAccess);
  }

  public isSeenHowManager(): boolean {
    return this.isSeenHowLineManager() ||
      this.isSeenHowTrackManager() ||
      this.getLoggedUserRole() === ERole.BusinessManager;
  }

  public isSeenHowSales(): boolean {
    return this.isSeenHowUserSales() ||
      this.getLoggedUserRole() === ERole.Sales;
  }

  public isSeenHowUserSales(): boolean {
    return this.getSelectedAccess() === EAccess.Sales;
  }

  public isSeenHowTrackManager(): boolean {
    return this.hasSelectedAccess(EAccess.TrackManager);
  }

  public isSeenHowLineManager(): boolean {
    return this.hasSelectedAccess(EAccess.LineManager);
  }

  public hasSelectedAccess(access: EAccess) {
    return this.getSelectedAccess() === access;
  }

  public hasAccess(access: EAccess) {
    const user = this.getLoggedUser();
    return user && (
      user.adminAccesses &&
      (user.adminAccesses instanceof Array) && user.adminAccesses.some(existingAccess => existingAccess === access));
  }

  public isSeenHowInstructorEvent(): boolean {
    return this.hasSelectedAccess(EAccess.InstructorEvent);
  }

  public isSeenHowInstructorModule(): boolean {
    return this.hasSelectedAccess(EAccess.InstructorModule);
  }

  public clearUserToSeeHowManager(): void {
    localStorage.removeItem(LocalStorageService.key.userToSeeHowManager);
  }

  public clearSelectedAccess(): void {
    localStorage.removeItem(LocalStorageService.key.selectedAccess);
  }

  public setImpersonationInfo(user: UserPreviewInterceptor): void {
    localStorage.setItem(
      LocalStorageService.key.impersonatedUser, JSON.stringify(user)
    );
    const currentAuthData = this.getLoggedUser();
    localStorage.setItem(LocalStorageService.key.loggedUser, JSON.stringify(currentAuthData));
    currentAuthData.user_id = user.userId;
    currentAuthData.role = user.userRole;
    currentAuthData.name = user.userName;
    currentAuthData.username = user.userName;
    currentAuthData.adminAccesses = user.adminAccesses;
    currentAuthData.access_token = this.getBearer(user.accessToken);
    localStorage.removeItem(LocalStorageService.key.authData);
    localStorage.setItem(LocalStorageService.key.authData, JSON.stringify(currentAuthData));
    this.isLoginSubject.next(true);
    this.personificationChanged$.next(true);
  }

  public getImpersonatedUser(): UserPreviewInterceptor {
    const data = localStorage.getItem(LocalStorageService.key.impersonatedUser);
    return data ? JSON.parse(data) : null;
  }

  public isImpersonatedUser(): boolean {
    return !!this.getImpersonatedUser();
  }

  public clearImpersonationInfo(): void {
    const loggedUser = localStorage.getItem(LocalStorageService.key.loggedUser);
    const authData = loggedUser ? loggedUser : null;
    localStorage.setItem(LocalStorageService.key.authData, authData);
    localStorage.removeItem(LocalStorageService.key.impersonatedUser);
    localStorage.removeItem(LocalStorageService.key.loggedUser);
    this.isLoginSubject.next(true);
    this.personificationChanged$.next(false);
  }

}
