<header>
  <h2 mat-dialog-title>LOG - Alterações</h2>
  <div class="legend-wrapper">
    <span class="legend-title">Legenda:</span>
    <div class="legend">
      <div class="description">Adicionou</div>
      <div class="color color-add"></div>
    </div>
    <div class="legend">
      <div class="description">Removeu</div>
      <div class="color color-remove"></div>
    </div>
  </div>
</header>

<mat-dialog-content>

  <ng-container *ngIf="difference || showUnchanged; else elseTemplate">
    <div *ngIf="difference !== ''" #Diff [innerHTML]="difference"></div>
    <pre *ngIf="difference === ''">
      <code>
        {{data.newValues| json}}
      </code>
    </pre>

  </ng-container>
  <ng-template #elseTemplate>
    <h4>Não há alterações para exibir</h4>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button *ngIf="difference" class="btn-test" (click)="toggleDiff()">
    {{showUnchanged ? 'Esconder':'Mostrar' }} propriedades não modificadas
  </button>
  <button class="btn-test btn-outline" (click)="dismiss()">Fechar</button>
</mat-dialog-actions>
