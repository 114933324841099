<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'understand'" [ngTemplateOutlet]="understandLayout"></ng-container>
    <ng-container *ngSwitchCase="'block'" [ngTemplateOutlet]="genericButtonLayout"></ng-container>
  </ng-container>
  
  <ng-template #understandLayout>
    <button class="understand"
        (click)="clickButton.emit($event)"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <g id="Grupo_13" data-name="Grupo 13" transform="translate(0 0.067)">
              <g id="Elipse_3" data-name="Elipse 3" transform="translate(0 -0.067)" fill="none" [attr.stroke]="primary_color" stroke-width="1">
                <circle cx="9.5" cy="9.5" r="9.5" stroke="none"/>
                <circle cx="9.5" cy="9.5" r="9" fill="none"/>
              </g>
              <text id="i" transform="translate(0 1.933)" [attr.fill]="primary_color" font-size="12" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="7.826" y="12">i</tspan></text>
            </g>
          </svg> 
        Entenda
    </button>
  </ng-template>

  <ng-template #genericButtonLayout>
    <button class="btn-test"
    [ngStyle]="{'width.px': width, 'height.px':height}"
    (click)="clickButton.emit($event)">
    {{title}}
  </button>
  </ng-template>
