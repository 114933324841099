<h3>Respostas : {{ valuationTitle }}</h3>
<div class="content">
  <div class="questions-and-answers" *ngFor="let question of questionsAndAnswers; let i = index">
    <div class="question">
      <ng-container *ngIf="question.type !== valuationTestTypeEnum.NPS">
        <div class="grade">
          <p class="{{ question | questionGradeClass}}">
            {{ question | questionGrade }}
          </p>
        </div>
      </ng-container>
      <div class="title">
        <p>Questão {{i + 1}}: </p>
      </div>
      <div class="description">
        <p class="statement notranslate" translate="no" [innerHtml]="question.title | MarkdownToHtml"></p>
      </div>
    </div>
    <div class="answer-field">
      <p class="your-answer-text">Sua resposta</p>
      <div class="student-answer">
        <ng-container *ngIf="question.type === valuationTestTypeEnum.MultipleChoice">
          <div>
            <div class="checkbox selected">
              <img *ngIf="!question.grade" src="./assets/img/wrong-answer.png" />
              <img *ngIf="question.grade" src="./assets/img/right-answer.png" />
            </div>
            <p class="answer-text">{{ question.answer }}</p>
          </div>
          <p class="right-answer-text" *ngIf="!question.grade">Resposta Correta</p>

          <div *ngIf="!question.grade">
            <div class="checkbox selected">
              <img src="./assets/img/right-answer.png" />
            </div>
            <p class="answer-text">{{ getRightAnswer(question) }}</p>
          </div>
        </ng-container>

        <ng-container *ngIf="question.type === valuationTestTypeEnum.Discursive || question.type === valuationTestTypeEnum.NPS">
          <div class="text">
            <p class="answer-text">{{ question.answer }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <p class="final-grade">Nota final : {{ finalGrade }}</p>
</div>
