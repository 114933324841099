<div class="video step" >
  <h2>Por que Fazer este Curso?</h2>

  <form [formGroup]="formGroup" *ngIf="this.formGroup" >
    <div class="column" >
      <div class="validation-field" >
        <div class="id-video">
          <mat-form-field>
            <input matInput placeholder="Id do Video"
              formControlName="videoId" required />
          </mat-form-field>
          <control-messages
            [control]="formGroup.get('videoId')"
          ></control-messages>
      </div>
      <div class="video-origin">
        <mat-form-field>
        <mat-select placeholder="Origem"
            formControlName="origin" required >
            <mat-option value="Youtube">Youtube</mat-option>
            <mat-option value="Vimeo">Vimeo</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    </div>
      <div class="validation-field" >
        <mat-form-field>
          <input matInput placeholder="Duração do Vídeo (hh:mm:ss)"
            formControlName="videoDuration" required
            mask="00:00:00"
          />
        </mat-form-field>
        <control-messages
          [control]="formGroup.get('videoDuration')"
        ></control-messages>
      </div>
    </div>
  </form>

  <div *ngIf="this.formGroup && formGroup.get('videoId').value" >
    <iframe
      class="videoIframe"
      [src]=" getUrl() | safeUrl"
      height="400"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
    <p class="video-title" >
      {{ formGroup.get('videoTitle').value }}
    </p>
  </div>
</div>
