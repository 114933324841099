import { Component, OnInit } from '@angular/core';
import { ValuationTest } from 'src/app/models/valuation-test.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationClass } from '../../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'app-coursework',
  templateUrl: './coursework.component.html',
  styleUrls: ['./coursework.component.scss']
})
export class CourseworkComponent extends NotificationClass implements OnInit {

  public test: ValuationTest;
  public supportMaterials: any[] = [];
  constructor(
    private _activatedRoute: ActivatedRoute,
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _testService: SettingsValuationTestsService,
    private _uploadService: UploadService,
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    const testId = this._activatedRoute.snapshot.paramMap.get('testId');
    this._loadTest(testId);
  }

  public async uploadImages(event, index): Promise<void> {
    const file = event.target.files[0];
    if (file) this._sendToServer(file, index);
  }

  private _sendToServer(file: File, index) {
    this._uploadService.uploadFile(file).subscribe((response) => {
      this.test.testQuestions[index]['curseWorkFile'] = {
        fileName: file.name,
        downloadLink: response
      };
      this.notify('Arquivo enviado com sucesso.');
    }, () => {
      this.notify('Ocorreu um erro ao enviar o arquivo, por favor tente novamente mais tarde');
    });
  }

  public save(): void {
    this._testService.saveValuationTestResponse(
      this.test
    ).subscribe(() => {
      this.notify('Resposta salva com sucesso!');
      window.history.back();
    }, (error) => {
      this.notify(this.getErrorNotification(error));
      this._router.navigate(['home']);
    });
  }

  public download(url): void {
    this._testService.downloadFileFromServe(url).subscribe();
    }

private _loadTest(testId: string) {
  this._testService.getValuationTestById(
    testId
  ).subscribe((response) => {
    this.test = response.data;
    this.test.testQuestions.forEach(coursework => coursework.supportMaterials.map(materials => this.supportMaterials.push(materials)));
    console.log(this.supportMaterials);
  }, (error) => {
    this.notify(this.getErrorNotification(error));
    this._router.navigate(['home']);
  });
}
}
