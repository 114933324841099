import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { MaterialComponentsModule } from '../../shared/material.module';
import { SettingsUsersService } from '../_services/users.service';
import { ListSearchModule } from '../../shared/components/list-search/list-search.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { SuccessDialogModule } from 'src/app/shared/dialogs/success/success.module';
import { MarkdownToHtmlModule, MarkdownToHtmlPipe } from 'markdown-to-html-pipe';
import { SettingsManageUserIncludeComponent } from './manage-user-include.component';
// tslint:disable-next-line: max-line-length
import { GenerateValuationTestCheckOptionsModule } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.module';

@NgModule({
  declarations: [
    SettingsManageUserIncludeComponent
  ],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    RouterModule,
    PaginationModule,
    ListSearchModule,
    PipesModule,
    FormsModule,
    ClickOutsideModule,
    SuccessDialogModule,
    ReactiveFormsModule,
    MarkdownToHtmlModule,
    GenerateValuationTestCheckOptionsModule
  ],
  providers: [
    SettingsUsersService,
    MarkdownToHtmlPipe
  ]
})
export class SettingsManageUserIncludeModule { }
