<div class="opa-background">
    <div class="card">
        <div class="top">
            <p class="first">Deixe sua opinião!</p>
            <p class="second">Tem alguma sugestão ou encontrou algum problema, conte pra nós no link abaixo.</p>
        </div>
        <div class="center">
            <p>
                Como foi sua experiência?
            </p>
            <div class="emoji-container">
                <div class="icon-custom icon-custom-happy"
                    [ngClass]="{ 'selected': satisfaction === 1}" 
                    (click)="changeSatisfaction(1)"
                ></div>
                <div class="icon-custom icon-custom-normal"
                    [ngClass]="{ 'selected': satisfaction === 2}"
                    (click)="changeSatisfaction(2)"
                ></div>
                <div class="icon-custom icon-custom-sad"
                    [ngClass]="{ 'selected': satisfaction === 3}"
                    (click)="changeSatisfaction(3)"
                ></div>
            </div>
        </div>

        <div class="text-container" >
            <textarea class="text-area"
                [(ngModel)]="text"
                placeholder="Descreva sua experiência"
            ></textarea>
        </div>

        <div class="radio-container">
            <mat-radio-group [(ngModel)]="type" >
                <mat-radio-button [value]="1" >
                    Sugestão
                </mat-radio-button>
                <mat-radio-button [value]="2" >
                    Erros
                </mat-radio-button>
                <mat-radio-button [value]="3" >
                    Outro
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="bottom">
            <button class="btn-test" (click)="dialogRef.close()" >
                Voltar
            </button>
            <button class="btn-test" (click)="save()" >
                Avaliar
            </button>
        </div>
    </div>
</div>