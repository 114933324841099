import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Player, { Options } from '@vimeo/player';
import { Content, PdfReference, VideoReference } from 'src/app/models/content.model';
import { ContentTypeEnum } from 'src/app/models/enums/content-type.enum';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-module-tag-dialog',
  templateUrl: './module-tag-dialog.component.html',
  styleUrls: ['./module-tag-dialog.component.scss']
})
export class ModuleTagDialogComponent extends NotificationClass implements OnInit {

  public conceptName: string = '';
  public conceptDescription: string = '';
  public videoPosition: string = '';
  public page: string = '';

  public dialogPlayer: Player;
  public videoUrl: string = '';

  public moduleTag: any;

  constructor(
    protected _snackBar: MatSnackBar,
    private _moduleService: ContentModulesService,
    private _utilService: UtilService,
    public dialogRef: MatDialogRef<ModuleTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(_snackBar);
    this.moduleTag = data.moduleTag.moduleTag;
    this.conceptName = data.moduleTag.moduleTag.concept.name;
    this.conceptDescription = data.moduleTag.moduleTag.description;
    if (data.moduleTag.content.type === 0) {
      this.videoUrl = data.moduleTag.content.value;
      this.videoPosition = this._utilService.formatDurationToHour(data.moduleTag.moduleTag.concept.positions[0]);
    } else if (data.moduleTag.content.type === 2) {
      this.page = data.moduleTag.moduleTag.concept.positions[0];
    }
  }

  ngOnInit(): void {
    if (this.data.moduleTag.content.type === 0) {
      const options: Options = { url: this.videoUrl, height: 200, width: 300};
      setTimeout(() => {
        this.dialogPlayer = new Player('dialogVideoContent', options);
        this.dialogPlayer.ready();
      }, 100);
    }
  }

  public save() {
    if (this.data.moduleTag.content.type === 0) {
      if (!this.conceptName || !this.videoPosition || !this.conceptDescription) {
        this.notify('Preencha todos os campos obrigatórios');
        return;
      }
      const concept: VideoReference = {
        name: this.conceptName,
        positions: [this._utilService.getDurationFromFormattedHour(this.videoPosition)]
      };
      this._moduleService.updateModuleUserTag(this.moduleTag.id, concept, this.conceptDescription).subscribe(() => {
        this.dialogRef.close(true);
      });
    } else if (this.data.moduleTag.content.type === 2) {
      if (!this.conceptName || !this.page || !this.conceptDescription) {
        this.notify('Preencha todos os campos obrigatórios');
        return;
      }
      const concept: PdfReference = {
        name: this.conceptName,
        positions: [this.page]
      };
      this._moduleService.updateModuleUserTag(this.moduleTag.id, concept, this.conceptDescription).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }
}
