import { UtilService } from 'src/app/shared/services/util.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Module, ModuleWeights, QuestionModules } from '../../../models/module.model';
import { SupportMaterial } from '../../../models/support-material.interface';
import { Requirement } from './models/new-requirement.model';
import { Subject } from '../../../models/subject.model';
import { Content } from '../../../models/content.model';
import { Question } from '../../../models/question.model';
import { CreatedModuleDialogComponent } from './steps/8_created/created-module.dialog';
import { NewModuleModuleInfoComponent } from './steps/1_module-info/module-info.component';
import { NewModuleSupportMaterialsComponent } from './steps/3_support-materials/support-materials.component';
import { NewModuleRequirementsComponent } from './steps/4_requirements/requirements.component';
import { NewModuleSubjectsComponent } from './steps/5_subjects/subjects.component';
import { NewModuleContentsComponent } from './steps/6_contents/contents.component';
import { NewModuleQuestionsComponent } from './steps/7_questions/questions.component';
import { NotificationClass } from '../../../shared/classes/notification';
import { NewModuleVideoComponent } from './steps/2_video/video.component';
import { SharedService } from '../../../shared/services/shared.service';
import { Level } from '../../../models/shared/level.interface';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { SettingsModulesDraftsService } from '../../_services/modules-drafts.service';
import { ModuleGradeTypeEnum } from 'src/app/models/enums/ModuleGradeTypeEnum';
import { ModuleEcommerceComponent } from './steps/9_ecommerce/ecommerce.component';
import { EcommerceProduct } from 'src/app/models/ecommerce-product.model';
import { SettingsModulesService } from '../../_services/modules.service';
import { NewModulesWeightComponent } from './steps/4.5_weight/modules-weight.component';
import { BehaviorSubject, Subject as SubjectRxjs } from 'rxjs';
import { isNil, pickBy, cloneDeep } from 'lodash';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ERole } from 'src/app/models/enums/role.enum';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-settings-new-module',
  templateUrl: './new-module.component.html',
  styleUrls: ['./new-module.component.scss'],
})
export class SettingsNewModuleComponent extends NotificationClass implements OnDestroy, AfterViewInit {

  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  @ViewChild('moduleInfo', { static: true })
  moduleInfo: NewModuleModuleInfoComponent;
  @ViewChild('moduleVideo', { static: true })
  moduleVideo: NewModuleVideoComponent;
  @ViewChild('moduleMaterials', { static: true })
  moduleMaterials: NewModuleSupportMaterialsComponent;
  @ViewChild('moduleRequirements', { static: true })
  moduleRequirements: NewModuleRequirementsComponent;
  @ViewChild('moduleWeight', { static: true })
  moduleWheight: NewModulesWeightComponent;
  @ViewChild('moduleSubjects', { static: true })
  moduleSubjects: NewModuleSubjectsComponent;
  @ViewChild('moduleContents', { static: true })
  moduleContents: NewModuleContentsComponent;
  @ViewChild('moduleQuestions', { static: true })
  moduleQuestions: NewModuleQuestionsComponent;
  @ViewChild('ecommerce', { static: true }) ecommerce: ModuleEcommerceComponent;

  public levels: Array<Level> = [];

  public newModule: Module;
  public newModule$: BehaviorSubject<Module> = new BehaviorSubject(new Module());
  public moduleTitle: string = '';

  public stepIndex: number = 0;
  public loading: boolean = false;
  public allowEditing: boolean = false;
  public showDraftOptions: boolean = false;
  public totalWeight: number = 0;
  public newModule2 = new BehaviorSubject<Module>(new Module());

  public arraySteps = [];
  public stepLength: number;
  public isDraft = this._router.url.includes('rascunho');

  public moduleWeights: ModuleWeights[];
  private _shouldFinish: boolean = true;
  private _isAdmin = false;
  private get _moduleStr() { return localStorage.getItem('editingModule'); }
  private get _moduleJson() { return JSON.parse(this._moduleStr) || {}; }
  private _unsubscribeAll = new SubjectRxjs<any>();
  private _finalized: boolean;
  public hasModifications: boolean;

  private _questions: {
    questions: Array<Question>,
    questionsLimit?: number,
    hasNewLearningTechniques?: boolean,
    moduleGradeType: ModuleGradeTypeEnum
  };

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: MatDialog,
    private _draftsService: SettingsModulesDraftsService,
    private _moduleService: SettingsModulesService,
    private _modulesService: ContentModulesService,
    private _utilService: UtilService,
    private _sharedService: SharedService,
    private _userService: SettingsUsersService,
    private _authService: AuthService,
    private cdr: ChangeDetectorRef,
    private _moduleDraftService: SettingsModulesDraftsService,
    private _activatedRoute: ActivatedRoute,
    private _location: Location
  ) {
    super(_snackBar);
    this._isAdmin = this._authService.getLoggedUserRole() === ERole.Admin;
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    this._loadLevels();
    const moduleId = this._activatedRoute.snapshot.paramMap.get('moduleId');

    const getModuleWeights = (label: 'AvalDiagnostica' | 'BqdDinamico' | 'AvalFinal'): number => (
      this.newModule.moduleWeights != null &&
        this.newModule.moduleWeights.length > 0
        ? this.newModule.moduleWeights.find((x) => x.content === label).weight
        : 0);

    const setModule = (module) => {
      this.newModule = new Module(module);
      this.newModule$.next(this.newModule);
      this.moduleTitle = this.newModule.title;
      this.allowEditing = true;
      this.moduleWeights = [
        {
          content: 'AvalDiagnostica',
          weight: getModuleWeights('AvalDiagnostica'),
          label: 'AVALIAÇÃO DIAGNÓSITCA',
        },
        {
          content: 'BqdDinamico',
          weight: getModuleWeights('BqdDinamico'),
          label: 'BQD DINÁMICO',
        },
        {
          content: 'AvalFinal',
          weight: getModuleWeights('AvalFinal'),
          label: 'AVALIAÇÃO FINAL',
        },
      ];
      this.setTotalValue();
      this.hasModifications = this.newModule.isDraft ? true : false;
    };

    if (moduleId) {
      if (this.isDraft) {
        this._draftsService.getDraftById(moduleId).subscribe((response) => {
          response.data['isDraft'] = true;
          setModule(response.data);
        });
      } else {
        this._modulesService.getModuleById(moduleId).subscribe((response) => {
          response.data['isDraft'] = false;
          setModule(response.data);
        });
      }
    }

    if (isNil(moduleId)) {
      setTimeout(() => (this.newModule = new Module()), 0);
      return;
    }


  }

  public setTotalValue() {
    this.totalWeight = 0;
    const weights = this.moduleWeights.map((x) => x.weight);
    weights.forEach((weight) => {
      this.totalWeight += weight ? weight : 0;
    });

    this.newModule.moduleWeights = this.moduleWeights;
    this.newModule$.next(this.newModule);
  }

  private _setLocationDraft() {
    this.newModule.isDraft = true;
    this.isDraft = true;
    this.allowEditing = true;
    this._location.replaceState(`configuracoes/modulo/${this.newModule.id}/rascunho`);
  }

  public byPassIfAdmin(functionToApply?: Function) {
    // Verificar necessidade
    if (functionToApply) {
      return functionToApply();
    }

    const moduleId = this._moduleJson.moduleId;
    this._userService.checkProgressByModule(moduleId)
      .subscribe(hasStudentProgress => {

        if (functionToApply && !hasStudentProgress.data) {
          return functionToApply();
        }

        if (hasStudentProgress.data && !this._isAdmin) {
          this.notify('Não foi possível salvar módulo, pois ele já foi iniciado por um ou mais alunos.');
          return;
        }

        if (functionToApply && hasStudentProgress.data) {
          return functionToApply();
        }
      });
  }

  public nextStep() {
    if (this.arraySteps.length === 0 || isNil(this.arraySteps)) {

      this.arraySteps = [
        this.moduleInfo,
        this.moduleVideo,
        this.moduleMaterials,
        this.moduleRequirements,
        this.moduleWheight,
        this.moduleSubjects,
        this.moduleContents,
        this.ecommerce,
        this.moduleQuestions,
      ];

      this.arraySteps = this.arraySteps.filter((x) => !isNil(x));

      if (!isNil(this.stepper) && !isNil(this.stepper.steps)) {
        this.stepLength = this.stepper.steps.length - 1;
      }

    }

    this.arraySteps[this.stepper.selectedIndex].nextStep();

    this.loading = false;
  }

  public saveStep(shouldContinue: boolean, foward: boolean) {
    this._finalized = this.stepIndex === 8 ? !shouldContinue && !foward : null;
    if (shouldContinue && foward) {
      this.stepper.next();
    } else if (shouldContinue && !foward) {
      this.stepper.previous();
    } else {
      this.nextStep();
    }
  }

  public previousStep() {

    if (this.stepper.selectedIndex - 1 === 6)
      this.moduleContents.ngOnInit();

    this.stepper.previous();
  }

  public stepChanged(event, shouldFinish: boolean = null) {
    this.stepIndex = event.selectedIndex;
    this._shouldFinish = shouldFinish || environment.features.eCommerce ? event.selectedIndex === 8 : event.selectedIndex === 7;
    this.nextStep();
  }

  public setModuleInfo(moduleInfo: Module) {

    const currentModule = cloneDeep(this.newModule);

    this.newModule.setModuleInfo(moduleInfo);

    if (this._utilService.equalsObj(pickBy(currentModule), pickBy(this.newModule))) {
      return;
    }

    this.confirmChanges(
      currentModule,
      this.newModule,
      this.newModule.id ? this._updateModuleInfo.bind(this) : this.createNewModule.bind(this));
  }

  public setModuleVideo(eventVideo: Module) {

    const currentModule = cloneDeep(this.newModule);

    this.newModule.setVideoInfo(eventVideo);

    if (this._utilService.equalsObj(pickBy(currentModule), pickBy(this.newModule))) {
      return;
    }

    this.confirmChanges(
      currentModule,
      this.newModule,
      this.newModule.id ? this._updateModuleInfo.bind(this) : this.createNewModule.bind(this));
  }

  public addSupportMaterials(materials: Array<SupportMaterial>) {

    const updatedModule = cloneDeep(this.newModule);
    const currentModule = cloneDeep(this.newModule);

    updatedModule.addSupportMaterials(materials);

    // Para efeito de comparação, eu precisei deletar as seguintes propriedades: numpages que sao iguais a null, formatedDuration e checked
    const updatedModuleComparison = this._removeUnusableFieldsForComparison(updatedModule);
    const currentModuleComparison = this._removeUnusableFieldsForComparison(currentModule);

    const areMaterialsSame = this._utilService.equalsObj(
      currentModuleComparison.supportMaterials, updatedModuleComparison.supportMaterials);

    if (areMaterialsSame) {
      return;
    }

    const oldModule = cloneDeep(this.newModule);
    this.newModule.addSupportMaterials(materials);

    this.confirmChanges(
      oldModule,
      this.newModule,
      this._updateSupportMaterials.bind(this));
  }

  public setRequirements(requirements: Array<Array<Requirement>>) {

    const currentModule = cloneDeep(this.newModule);

    this.newModule.setRequirements(requirements[0], requirements[1]);

    const areMandatoryRequirementsSame = this._utilService.equalsObj(currentModule.requirements[0], this.newModule.requirements[0]);
    const areOptionalRequirementsSame = this._utilService.equalsObj(currentModule.requirements[1], this.newModule.requirements[1]);

    if (areMandatoryRequirementsSame && areOptionalRequirementsSame) {
      return;
    }

    const requirmentsList = [...requirements[0], ...requirements[1]];
    requirmentsList.forEach((req) => { delete req.module; delete req.editing; });

    this.newModule.requirements = requirmentsList;


    this.confirmChanges(
      currentModule,
      this.newModule,
      this._updateRequirements.bind(this));
  }

  public addModulesWeights(module: Module) {
    const currentModule = cloneDeep(this.newModule);

    this.newModule.addModulesWeights(module.moduleWeights, module.hasQuestions);

    const areWeightModificationsSame = this._utilService.equalsObj(currentModule.moduleWeights, this.newModule.moduleWeights);

    if (areWeightModificationsSame && currentModule.hasQuestions === module.hasQuestions) {
      return;
    }


    this.confirmChanges(
      currentModule,
      this.newModule,
      this._updateModuleWeights.bind(this));
  }

  private _removeUnusableFieldsForComparison(originalModule: Module): Module {

    const cloneModule = cloneDeep(originalModule);

    if (!cloneModule.supportMaterials) {
      return cloneModule;
    }

    cloneModule.supportMaterials.forEach(mat => {
      delete mat.fileName;
    });

    if (!cloneModule.subjects) {
      return cloneModule;
    }

    cloneModule.subjects.forEach(sub => {
      delete sub.id;
      sub.userProgresses.forEach(prog => {
        delete prog.progressType;
      });

      if (!sub.contents) {
        return;
      }

      sub.contents.forEach(cont => {
        if (isNil(cont.numPages)) {
          delete cont.numPages;
          delete cont.subjectId;
        }
        if (cont.formatedDuration || cont.formatedDuration === '') {
          delete cont.formatedDuration;
          if (cont.subjectTitle) {
            delete cont.subjectTitle;
          }
          cont.concepts.forEach(concept => {
            delete concept.checked;
          });
        }
      });
    });

    return cloneModule;
  }

  public addSubjects(subjects: Array<Subject>) {

    const updatedModule = cloneDeep(this.newModule);
    const currentModule = cloneDeep(this.newModule);

    updatedModule.addSubjects(subjects);

    // Para efeito de comparação, eu precisei deletar as seguintes propriedades: numpages que sao iguais a null, formatedDuration e checked
    const updatedModuleComparison = this._removeUnusableFieldsForComparison(updatedModule);
    const currentModuleComparison = this._removeUnusableFieldsForComparison(currentModule);

    const areSubjectsSame = this._utilService.equalsObj(currentModuleComparison.subjects, updatedModuleComparison.subjects);

    if (areSubjectsSame) {
      return;
    }

    const oldModule = cloneDeep(this.newModule);
    this.newModule.addSubjects(subjects);

    this.confirmChanges(
      oldModule,
      this.newModule,
      this._updateSubjects.bind(this));
  }

  public addContents(contents: Array<Content>) {

    const updatedModule = cloneDeep(this.newModule);
    const currentModule = cloneDeep(this.newModule);

    updatedModule.addContents(contents);

    // Para efeito de comparação, eu precisei deletar as seguintes propriedades: numpages que sao iguais a null, formatedDuration e checked
    const updatedModuleComparison = this._removeUnusableFieldsForComparison(updatedModule);
    const currentModuleComparison = this._removeUnusableFieldsForComparison(currentModule);

    const areContentsSame = this._utilService.equalsObj(currentModuleComparison.subjects, updatedModuleComparison.subjects);

    if (areContentsSame) {
      return;
    }

    const oldModule = cloneDeep(this.newModule);
    this.newModule.addContents(contents);

    this.newModule.contents = contents;

    this.confirmChanges(
      oldModule,
      this.newModule,
      this._updateContents.bind(this));
  }

  public manageEcommerceInfo(ecommerceProducts: Array<EcommerceProduct>) {

    if ((this.newModule.ecommerceProducts === null
      )
      && (ecommerceProducts === null
      )) {
      return;
    }

    this.loading = true;

    const areProductSame = this._utilService.equalsObj(this.newModule.ecommerceProducts, ecommerceProducts);

    if (areProductSame) {
      return;
    }

    const currentModule = cloneDeep(this.newModule);


    this.newModule.ecommerceProducts = ecommerceProducts;

    this.confirmChanges(
      currentModule,
      this.newModule,
      this._updateEcommerceProduct.bind(this));
  }

  public async addQuestions(result: {
    questions: Array<Question>,
    questionsLimit?: number,
    moduleGradeType: ModuleGradeTypeEnum,
    hasNewLearningTechniques: boolean,
    nextStep: boolean
  }) {
    if (!result.nextStep) {
      this.newModule.questions = result.questions;
      this.newModule.moduleGradeType = result.moduleGradeType;
      this.newModule.questionsLimit = result.questionsLimit;
      this.newModule.hasNewLearningTechniques = result.hasNewLearningTechniques;
      this.nextState(this.newModule);
      return;
    }

    const arrayFunctions: Function[] = [this._updateQuestions.bind(this), this._updateModuleInfo.bind(this)];

    const currentModule = cloneDeep(this.newModule);

    delete result.nextStep;

    if (isNil(this._questions)) {
      if (this._finalized) {
        this._dialog.open(CreatedModuleDialogComponent);
        this._router.navigate(['configuracoes/modulos']);
      }
      return;
    }

    const areQuestionsSame = this._utilService.equalsObj(this._questions.questions, result.questions);
    const areLimitsSame = this._questions.questionsLimit === result.questionsLimit;
    const areModuleGradeTypesSame = this._questions.moduleGradeType === result.moduleGradeType;
    const areNewLearningTechniquesValueSame = this._questions.hasNewLearningTechniques === result.hasNewLearningTechniques;

    this.newModule.questions = result.questions;

    if (!areModuleGradeTypesSame || !areLimitsSame || !areNewLearningTechniquesValueSame) {

      this.newModule.moduleGradeType = result.moduleGradeType;
      this.newModule.questionsLimit = result.questionsLimit;
      this.newModule.hasNewLearningTechniques = result.hasNewLearningTechniques;

      this.confirmChanges(
        currentModule,
        this.newModule,
        arrayFunctions);
      return;
    }


    if (areQuestionsSame && areLimitsSame && areModuleGradeTypesSame
      || (!this._questions && result.questions.length === 0)) {
      if (this._finalized) {
        this._dialog.open(CreatedModuleDialogComponent);
        this._router.navigate(['configuracoes/modulos']);
      }
      return;
    }
  }

  public loadQuestions(questions: {
    questions: Array<Question>,
    questionsLimit?: number,
    hasNewLearningTechniques?: boolean,
    moduleGradeType: ModuleGradeTypeEnum
  }) {
    this._questions = questions;
  }

  public publishDraftChanges(): void {
    const moduleId = this._moduleJson.moduleId;
    this._userService.checkProgressByModule(moduleId).subscribe(hasStudentProgress => {
      const message = hasStudentProgress.data
        ? 'O módulo já foi iniciado por um ou mais alunos e será substituído pela versão em rascunho.'
        : 'O módulo será substituído pela versão em rascunho.' + 'Deseja continuar?';

      const dialogRef = this._dialog.open(SimpleDialogComponent, {
        width: '400px',
        data: {
          positiveTextAction: 'SIM',
          negativeTextAction: 'NÃO',
          message:
            (hasStudentProgress.data
              ? 'O módulo já foi iniciado por um ou mais alunos e será substituído pela versão em rascunho.'
              : 'O módulo será substituído pela versão em rascunho.') +
            ' Deseja continuar?',
        } as SimpleDialog,
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this._draftsService
            .publishDraft(this.newModule.id)
            .subscribe(() => {
              this.notify('Módulo publicado com sucesso!');
              this._router.navigate(['configuracoes/modulos']);
            });
        }
      });
    });
  }

  public rejectDraftChanges(): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Tem certeza que deseja rejeitar as alterações em rascunho? Todas as alterações serão perdidas.'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._draftsService.rejectDraft(this.newModule.id).subscribe(() => {
          this.notify('Alterações rejeitadas com sucesso!');
          this._router.navigate(['configuracoes/modulos']);
        });
      } else {
        this._router.navigate(['configuracoes/modulos']);
      }
    });
  }

  public async createNewModule(module: Module, callback: Function = null): Promise<Module> {
    if (!module.isDraft && !isNil(module.id)) {
      return await this.createNewModuleDraft(module, callback);
    } else if (isNil(module.id)) {
      return await this.addNewModuleDraft(module);
    }
  }

  public async addNewModuleDraft(module: Module): Promise<Module> {
    this.loading = true;
    return await this._draftsService.addNewModuleDraft(module).toPromise().then((response) => {
      this.newModule.id = response.data.id;
      this.newModule.ecommerceId = response.data.ecommerceId;
      this.moduleInfo.setEcommerceId(this.newModule.ecommerceId);
      this._setLocationDraft();
      this.loading = false;

      this.notify('Modificacões Salvas em Rascunho');

      return this.newModule;

    }, () => {
      this._errorHandlingFunc();
      return this.newModule;
    });
  }
  public async createNewModuleDraft(module: Module, callback: Function = null): Promise<Module> {
    this.loading = true;
    return await this._draftsService.getNewDraftByModule(module).toPromise().then((response) => {
      this.newModule = new Module(response.data);
      this._setLocationDraft();
      this.newModule$.next(this.newModule);
      if (callback) callback(this.newModule);
      this.loading = false;

      this.notify('Modificacões Salvas em Rascunho');

      return this.newModule;

    }, () => {
      this._errorHandlingFunc();
      return this.newModule;
    });
  }

  private _updateModuleInfo(module: Module) {
    this.loading = true;
    this._setLocationDraft();
    this._draftsService.updateModuleDraft(module).subscribe(() => {
      if (!this._shouldFinish) this._shouldFinish = true;
      this.loading = false;

      this.notify('Modificacões Salvas em Rascunho');

    }, () => this._errorHandlingFunc());
  }

  private _updateSupportMaterials(module: Module) {
    this.loading = true;
    this._setLocationDraft();
    this._draftsService.manageDraftSupportMaterials(module.id, module.supportMaterials).subscribe(() => {
      if (!this._shouldFinish) this._shouldFinish = true;
      this.loading = false;

      this.notify('Modificacões Salvas em Rascunho');

    }, () => this._errorHandlingFunc());
  }

  private _updateRequirements(module: Module) {
    this.loading = true;
    this._setLocationDraft();
    this._draftsService.manageDraftRequirements(module.id, module.requirements).subscribe(() => {
      if (!this._shouldFinish) this._shouldFinish = true;
      this.loading = false;

      this.notify('Modificacões Salvas em Rascunho');

    }, () => this._errorHandlingFunc());
  }

  private _updateSubjects(module: Module) {
    this.loading = true;

    module.subjects.forEach(sub => {
      if (!sub.contents) return;
      sub.contents.forEach(cont => {
        cont.concepts.forEach((conc: any) => {
          if (isNil(conc) || isNil(conc.positions)) return;
          conc.positions = conc.positions.map(pos => {
            if (typeof pos === 'string') {
              pos = this._utilService.getDurationFromFormattedHour(pos);
              return pos;
            }
            return pos;
          });
        });
      });
    });

    this._draftsService.manageDraftSubjects(module.id, module.subjects).subscribe((response) => {

      const storageModule = JSON.parse(localStorage.getItem('editingModule')) as Module;

      this.newModule.subjects = this._adjustSubjects(response.data);
      this.moduleSubjects.setUserProgess();

      storageModule.subjects = [...this.newModule.subjects];
      localStorage.setItem('editingModule', JSON.stringify(storageModule));

      if (!this._shouldFinish) this._shouldFinish = true;
      this.loading = false;
      this._setLocationDraft();

      this.nextState(this.newModule);

      this.notify('Modificacões Salvas em Rascunho');

    }, () => this._errorHandlingFunc());
  }


  private _updateModuleWeights(module: Module) {
    this.loading = true;
    this._setLocationDraft();
    this._draftsService.manageDraftModuleWeight(module.id, module.moduleWeights, module.hasQuestions).subscribe(res => {
      if (!this._shouldFinish) this._shouldFinish = true;
      this.loading = false;

      this.notify('Modificacões Salvas em Rascunho');

    }, () => this._errorHandlingFunc());
  }

  private _updateContents(module: Module) {
    this.loading = true;
    this._draftsService.manageDraftContents(module.id, module.contents).subscribe(() => {
      const storageModule = JSON.parse(localStorage.getItem('editingModule')) as Module;
      storageModule.subjects = [...this.newModule.subjects];
      localStorage.setItem('editingModule', JSON.stringify(storageModule));
      this.moduleQuestions.loadQuestions();
      if (!this._shouldFinish) this._shouldFinish = true;
      this.newModule2.next(this.newModule);
      this.loading = false;
      this.nextState(module);

      this.notify('Modificacões Salvas em Rascunho');

    }, () => this._errorHandlingFunc());
  }

  private _errorHandlingFunc() {
    this.loading = false;
    this.notify('Ocorreu um erro, por favor tente novamente mais tarde');
  }

  private _updateEcommerceProduct(module: Module) {
    this._moduleService.manageEcommerceProducts(
      module.id, module.ecommerceProducts
    ).subscribe(() => {
      this._setLocationDraft();
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  private async _updateQuestions(module: Module) {
    const setLimits = (localModule: Module) => this._draftsService.setDraftQuestionsLimit(
      localModule.id, localModule.questionsLimit
    ).subscribe(() => {
      this.loading = false;
      this._setLocationDraft();
      this.newModule$.next(module);
      if (this._finalized) {
        this._dialog.open(CreatedModuleDialogComponent);
        this._router.navigate(['configuracoes/modulos']);
      }
    }, () => this._errorHandlingFunc());

    if (module.isDraft) {
      setLimits(module);
    } else {
      await this.createNewModule(module, setLimits.bind(this));
    }
  }

  private _adjustSubjects(subjects: Array<Subject>): Array<Subject> {

    subjects.forEach(subject => {
      subject.contents.forEach((content: any) => {
        content.subjectId = subject.id;
        content.formatedDuration = this._utilService.formatDurationToHour(content.duration);
        content.concepts.forEach(conc => {
          if (conc.positions && conc.positions.length > 0) {
            conc.checked = true;
            conc.positions = conc.positions.map((pos: number) =>
              this._utilService.formatDurationToHour(pos)
            );
          }
        });
      });
    });
    return subjects;
  }

  private _loadLevels(): void {
    this._sharedService.getLevels().subscribe((response) => {
      this.levels = response.data;

    });
  }
  public upadateModuleQuestions(): boolean {
    const updateModule = localStorage.getItem(LocalStorageService.key.questionModuleReload);
    return updateModule === 'true';
  }

  public updateImportSubjects(subjects: Array<Subject>) {
    const storageModule = JSON.parse(localStorage.getItem('editingModule')) as Module;

    this.newModule.subjects = this._adjustSubjects(subjects);
    this.moduleSubjects.setUserProgess();

    storageModule.subjects = [...this.newModule.subjects];
    localStorage.setItem('editingModule', JSON.stringify(storageModule));
    this.newModule$.next(this.newModule);
  }

  public confirmChanges(module: Module, moduleUpdate: Module, callBack: Function | Array<Function> = null): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Deseja salvar alterações?' }
    });

    const executesCallBack = (modUpdated) => {
      if (callBack instanceof Array) {
        for (let index = 0; index < callBack.length; index++) {
          callBack[index](modUpdated);
        }
      } else {
        callBack(modUpdated);
      }
    };

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.newModule.isDraft) {
          executesCallBack(moduleUpdate);
        } else {
          if (moduleUpdate.id) {
            this._moduleDraftService.getNewDraftByModule(moduleUpdate).subscribe((response) => {
              moduleUpdate.id = response.data.id;
              moduleUpdate.isDraft = true;
              this.nextState(moduleUpdate);
              executesCallBack(moduleUpdate);
            });
          } else {
            this.nextState(moduleUpdate);
            executesCallBack(moduleUpdate);
          }
        }
        this.hasModifications = true;
      } else {
        this.nextState(module);
      }
    });
  }

  public nextState(module: Module) {
    this.newModule$.next(module);
    this.newModule = this.newModule$.getValue();
    this.cdr.detectChanges();
  }
}
