<div class="required-module"
  [ngClass]="{ 'last': last }"
  (click)="goToModule()" >
  <div class="header" >
    <h4>{{ requirement.title }}</h4>
  </div>
  <div class="content" >
    <p>
      Proficiência Exigida<br>
      {{ getLevelDescription(requirement.level) }}
    </p>
    <p *ngIf="requirement.percentage > 0">
      Aproveitamento<br>
      {{ requirement.percentage * 100 }}%
    </p>
    <div class="badge" >
      <img [src]="getBadgesProgressImageSrc(requirement.progress)" />
      <p>
        Meu nível<br>
        <span>
          {{ requirement.progress ?
              getLevelDescription(requirement.progress.level) :
              'Não Iniciado'
          }}
        </span>
      </p>
    </div>
  </div>
  <ng-container *ngIf="requirement.progress" >
    <p class="level" *ngIf="requirement.progress.level < 3">
      <span>{{ getLevelDescription(requirement.progress.level) }}</span>
      {{ requirement.progress.progress | asPercentage }}%
    </p>
    <p class="level" *ngIf="requirement.progress.level >= 4">
      100%
    </p>
  </ng-container>
</div>
