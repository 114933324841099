<div class="content" >
  <!--div class="side-content">
    <h3>Filtros</h3>
    <div class="filter">
      <app-list-search placeholder="Pesquisar {{replacement.track.singular}}"
        (triggerSearch)="triggerTrackSearch($event)" [noPadding]="true"></app-list-search>
      <div class="chips">
        <p *ngFor="let track of selectedTracks">
          {{ track.title }}
          <span (click)="removeSelectedTrack(track.id)">X</span>
        </p>
      </div>
      <div class="tracks">
        <app-track-list-filter *ngFor="let track of tracks" [track]="track" (updateCollection)="updateTracks()">
        </app-track-list-filter>
      </div>
    </div>
  </div-->
  <div class="main-content">
    <div class="table">
      <div class="item">
        <div class="files">
          <span>Pesquisa de Satistação - NPS</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table"
            (click)="getNpsInfo()">
            EXPORTAR
          </button>
        </div>
      </div>
      <div class="item">
        <div class="files">
          <span>Pesquisa de Satistação - Experiência nos Modulos</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table"
            (click)="getExpModulesInfo()">
            EXPORTAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
