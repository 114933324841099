import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import { Data } from '../model/data.model';

@Pipe({ name: 'canDisable' })
export class CanDisablePipe implements PipeTransform {
    transform(id: any, dataSource: Partial<Data>[]): any {
        if (isNil(dataSource)) return false;
        const index = dataSource.findIndex(value => value.id === id);

        return index !== -1 ? true : false;
    }
}
