import { EStudyPlanItem } from './enums/study-plan-item.enum';
import { StudyPlanDependencies } from './study-plan-dependencies.model';

export class StudyPlanRequeriments {
    public id: string;
    public type: EStudyPlanItem;
    public dependencies: StudyPlanDependencies[] = new Array<StudyPlanDependencies>();

    constructor(studyPlanRequeriments: StudyPlanRequeriments = null) {
        if (studyPlanRequeriments) Object.keys(studyPlanRequeriments).forEach(key => this[key] = studyPlanRequeriments[key]);
    }
}
