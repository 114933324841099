import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgUtilModule } from '@tg4/ng-util';
import { MaterialComponentsModule } from '../../shared/material.module';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { AutocompleteModule } from 'src/app/shared/components/autocomplete/autocomplete.module';
import { MatCardModule } from '@angular/material/card';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { AutomaticSyncCardComponent } from './automatic-sync-card/automatic-sync-card.component';
import { AutomaticSyncComponent } from './automatic-sync.component';
import { AutomaticSyncTrackCardSelectComponent } from './manage-automatic-sync/automatic-sync-track-card-select/automatic-sync-track-card-select.component';
import { ManageAutomaticSyncComponent } from './manage-automatic-sync/manage-automatic-sync.component';
import { AutomaticSyncService } from '../_services/automatic-sync.service';
@NgModule({
  declarations: [
    AutomaticSyncComponent,
    ManageAutomaticSyncComponent,
    AutomaticSyncTrackCardSelectComponent,
    AutomaticSyncCardComponent
  ],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ListSearchModule,
    NgUtilModule,
    AutocompleteModule,
    MatCardModule,
    PaginationModule,
    PipesModule,
    MatTabsModule
  ],
  providers: [
    AutomaticSyncService
  ]
})
export class AutomaticSyncModule { }
