import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { Injectable } from '@angular/core';
import { addSeconds } from 'date-fns';
import { chain, isEmpty } from 'lodash';
import { CookieService } from 'ngx-cookie-service';

export type TokenInfo = Partial<LoginResponse> & { remember: boolean };

@Injectable()
export class TokenStorageService {

    constructor(private _cookieService: CookieService) {
	}
    public renewTokenTimeExpiration(): void {
		const tokenInfoJson = this.getAuthData();
		if (tokenInfoJson) {
			const tokenInfo = { ...tokenInfoJson, remember: false };
			this.setTokenTimeExpiration(tokenInfo);
		}
	}
    public setTokenTimeExpiration(tokenInfo: TokenInfo) {
		const token = tokenInfo.access_token;
		this._cookieService.set("auth-token", token, addSeconds(new Date(), tokenInfo.expires_in), '/', undefined, false, 'Lax');
	}

    public getAuthData(): LoginResponse {
		const authDataToParse = window.localStorage.getItem('auth_data');

		if (isEmpty(authDataToParse) || authDataToParse === 'null' || authDataToParse === 'undefined') return null;

		return JSON.parse(authDataToParse) as LoginResponse;

	}
    public getToken(): string | null {
		const value = this._cookieService.get("auth-token");

		if (isEmpty(value) || value === 'null' || value === 'undefined') return null;

		return value;

	}
    public saveToken(tokenInfo: TokenInfo): void {
		this.setTokenTimeExpiration(tokenInfo);
		this.saveAuthData(tokenInfo);
		this.saveRefreshToken(tokenInfo.refresh_token);
	}
	public saveAllTokens(tokenInfo: TokenInfo): void {
		this.saveToken(tokenInfo);
		this.saveRefreshToken(tokenInfo.refresh_token);
		this.saveAuthData(tokenInfo);

	}
    public hasSavedAuthentication(): boolean {
		const ret = !!JSON.parse(window.localStorage.getItem("auth-remember"));
		return ret;
	}
    public saveAuthData(authData: TokenInfo): void {
		window.localStorage.removeItem("auth_data");
		window.localStorage.setItem("auth_data", JSON.stringify(authData));
	}
    public saveRefreshToken(token: string): void {
		window.localStorage.removeItem("auth-refreshtoken");
		window.localStorage.setItem("auth-refreshtoken", token);
	}
	public clearStorageKeys(args: { keysToOmit?: string[] } = {}): void {
		if (args?.keysToOmit?.length) {
			const keys: string[] = chain(Object.keys(localStorage))
				.omitBy(key => args?.keysToOmit?.includes(key))
				.values()
				.value();

			keys.forEach(key => localStorage.removeItem(key));
		} else {
			window.localStorage.clear();
		}

		this._cookieService.delete("auth-token", '/', undefined, false, 'Lax');
	}
	public getRefreshToken(): string | null {
		return window.localStorage.getItem("auth-refreshtoken");
	}
}