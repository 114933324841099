import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {

  public static readonly key = {
    trackOverviewTab: 'track-overview-tab'
  };


  constructor() { }

}
