<div class="search">
  <div class="input-container">
    <i class="icon icon-lupa lupa-home"></i>
    <input id="SearchDisplay" type="text"
      placeholder="Digite o nome do curso {{replacement.track.singular | replacement:'ou' }}" (click)="openSearch()" />
  </div>
  <button class="btn-test" (click)="openSearch()">
    buscar
  </button>
</div>

<div class="search-fullscreen" *ngIf="showSearchFullscren">
  <img class="close" src="./assets/img/close-colored.png" (click)="closeSearch()" />

  <div class="inner-content">
    <div class="input-container">
      <i class="icon-lupa lupa-home" style="position: absolute;"></i>
      <input id="Search" type="text"
        placeholder="Digite o nome do módulo {{replacement.track.singular | replacement:'ou' }}"
        [(ngModel)]="searchValue" (ngModelChange)="doSearch()" />
    </div>

    <p class="view-all" *ngIf="searchValue && searchValue !== ''" (click)="goToAllResults(searchValue)">
      Ver todos os Resultados
    </p>

    <h3 *ngIf="results && results.length > 0">
      {{ replacement.module.plural }}
    </h3>
    <div class="results" *ngFor="let module of results">
      <p (click)="goToModuleResult(module)">
        {{ module.title }}
      </p>
    </div>
    <h3 *ngIf="tracks && tracks.length > 0">
      {{replacement.track.plural }}
    </h3>
    <div class="results" *ngFor="let track of tracks">
      <p (click)="goToTrackResult(track)">
        {{ track.title }}
      </p>
    </div>
  </div>
</div>
