import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-exam-dialog-blocked',
  templateUrl: './exam-dialog-blocked.component.html',
  styleUrls: ['./exam-dialog-blocked.component.scss']
})
export class ExamDialogBlockedComponent {

  constructor(
    public dialogRef: MatDialogRef<ExamDialogBlockedComponent>,
  ) { }


}
