import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { Program } from '../programs/Models/program.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  constructor(private _httpService: BackendService) { }

  public getPagedPrograms(
    page: number = 1, pageSize: number = 10, searchTerm: string = ''
  ): Observable<any> {
    return this._httpService.get('getAllPrograms', [], [
      { 'name': 'page', 'value': page.toString() },
      { 'name': 'pageSize', 'value': pageSize.toString() },
      { 'name': 'title', 'value': searchTerm },
    ]);
  }
  public getProgramById(programId: string): Observable<any> {
    return this._httpService.get('getProgramById', [], [{
      'name': 'programId', 'value': programId
    }]);
  }
  public createProgram(
    value: Partial<Program>
  ): Observable<any> {
    return this._httpService.post('CreateProgram', value);
  }

  public updateProgram(
    value: Partial<Program>
  ): Observable<any> {
    return this._httpService.put('UpdateProgram', value);
  }

  public deleteProgram(programId: string): Observable<any> {
    return this._httpService.delete('DeleteProgram', [{
      name: 'programId', value: programId
    }]);
  }
}
