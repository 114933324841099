<div class="inner-content">
  <app-navigation-back></app-navigation-back>

  <div class="header">
    <div class="header-content">
      <div class="program-title align-items-center">{{ program?.title }}</div>

      <div class="team-options">
        <label>TURMA</label>
        <div *ngIf="userRole === 'Student'" class="team-title-fixed">{{selectedTeam?.title}}</div>
        <mat-select *ngIf="userRole !== 'Student'" class="select" (selectionChange)="changeTeam($event.value)">
          <mat-option *ngFor="let teamOption of programTeamOptions" value="{{teamOption.id}}">{{teamOption?.title}}
          </mat-option>
        </mat-select>
      </div>
      <div class="time-course">
        <div class="title">PERÍODO</div>
        <div class="dates">{{program?.startDate | date:'dd/MM/yyyy' }} - {{program?.endDate | date:'dd/MM/yyyy' }}</div>
      </div>
      <div class="total-students">
        <div class="count-number">
          {{ countTeamStudents }}
        </div>
        <span>Alunos</span>
      </div>

    </div>
    <div class="description">
      <p>
        {{ program?.description }}
      </p>
    </div>

  </div>



  <ul class="program-items" *ngIf="program && program.items">

    <li class="program-item-caption">
      TRILHAS NESTE PROGRAMA
    </li>

    <li class="program-item" *ngFor="let item of program.items">
      <div class="item-label">
        <div class="category">Finanças</div>
        <div class="title">{{item.title}}</div>
      </div>
      <div class="item-progress">

        <div class="progress">
          <div class="progress-content">
            <app-progress-bar [currentProgress]="item.progress" [height]="8" [roundedBorder]="true"
              [color]="primary_color"></app-progress-bar>
          </div>
          <label><strong>{{item.progress}}% completo</strong></label>
        </div>

      </div>
      <div class="item-grade">
        <div class="label">MÉDIA TURMA</div>
        <div class="nota">{{item.grade}}</div>
      </div>
      <div class="item-link" (click)="goToPageTrack(item.id)">
        <div class="label">Ver Trilha</div>
        <div class="arrow">></div>
      </div>
    </li>

  </ul>

</div>
