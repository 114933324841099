import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Injectable } from '@angular/core';
import { ProfileTestQuestion } from 'src/app/models/profile-test.interface';
import { ValuationTestQuestion } from 'src/app/models/valuation-test.interface';
import { differenceWith, isEqual, isNil, values, xor } from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable(
)
export class ExcelService {
  constructor() { }


  public exportAsExcelFile(json: any[], excelFileName: string, custonHeader?: any[][]): void {

    let worksheet: XLSX.WorkSheet;
    const headers = json[0];
    if (custonHeader && headers) {
      custonHeader.push(Object.keys(headers));
      json.forEach(rows => custonHeader.push(values(rows)));
      worksheet = XLSX.utils.aoa_to_sheet(custonHeader, { cellDates: true });
    } else {
      worksheet = XLSX.utils.json_to_sheet(json, { cellDates: true });
    }
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellDates: true });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public getExcelContentAsJson(excelFile: any): Array<ProfileTestQuestion | ValuationTestQuestion> {
    const workbook = XLSX.read(excelFile, { type: 'binary' });
    return XLSX.utils.sheet_to_json(
      workbook.Sheets[
      workbook.SheetNames[0]
      ], {
      raw: true,
      header: ['title', 'type', 'options', 'percentage']
    }
    );
  }

  public exportFromTable(table: HTMLTableElement, fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.getFileName(fileName));
  }

  public getExcelFromBase64(excelFile: any, fileName: string) {
    const workbook = XLSX.read(excelFile, { type: 'base64' });
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, this.getFileName(fileName));
  }

  private getFileName(fileName: string) {
    const date = new Date();
    return fileName + new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, -1) + EXCEL_EXTENSION;
  }

  public buildExportUsersGrade(res: any): any[] {
    const exportArray: any[] = [];
    const moduleArray: any[] = [];
    const eventArray: any[] = [];
    const eventPresenceArray: any[] = [];
    for (let i = 0; i < res.data.length; i++) {
      exportArray.push([]);
      exportArray[i]['Alunos'] = res.data[i].name;
      exportArray[i]['Emails'] = res.data[i].email;
    }
    for (let i = 0; i < res.data.length; i++) {
      for (let j = 0; j < res.data[i].moduleGrade.length; j++) {
        if (!moduleArray.includes(res.data[i].moduleGrade[j].moduleName))
          moduleArray.push(res.data[i].moduleGrade[j].moduleName);
      }
      for (let k = 0; k < res.data[i].eventGrade.length; k++) {
        if (!eventArray.includes(res.data[i].eventGrade[k].eventName))
          eventArray.push(res.data[i].eventGrade[k].eventName);
      }
      for (let l = 0; l < res.data[i].eventPresence.length; l++) {
        if (!eventPresenceArray.includes(res.data[i].eventPresence[l].eventName))
          eventPresenceArray.push(res.data[i].eventPresence[l].eventName);
      }
    }
    for (let i = 0; i < res.data.length; i++) {
      for (let j = 0; j < moduleArray.length; j++) {
        exportArray[i][moduleArray[j]] = '';
      }
      for (let j = 0; j < res.data[i].moduleGrade.length; j++) {
        exportArray[i][res.data[i].moduleGrade[j].moduleName] = res.data[i].moduleGrade[j].points;
      }
    }
    for (let i = 0; i < res.data.length; i++) {
      for (let k = 0; k < eventArray.length; k++) {
        exportArray[i][eventArray[k]] = '';
      }
      for (let k = 0; k < res.data[i].eventGrade.length; k++) {
        exportArray[i][res.data[i].eventGrade[k].eventName] = res.data[i].eventGrade[k].finalGrade;
      }
    }
    for (let i = 0; i < res.data.length; i++) {
      for (let l = 0; l < eventPresenceArray.length; l++) {
        exportArray[i][eventPresenceArray[l]] = '';
      }
      for (let l = 0; l < res.data[i].eventPresence.length; l++) {
        exportArray[i][res.data[i].eventPresence[l].eventName] = res.data[i].eventPresence[l].userPresence;
      }
    }
    return exportArray;
  }

  public buildExportUsersEffectiveness(res: any): any[] {
    const exportArray: any[] = [];
    for (let i = 0; i < res.data.length; i++) {
      exportArray.push([]);
      exportArray[i]['Aluno'] = res.data[i].userName;
    }
    for (let i = 0; i < res.data.length; i++) {
      for (let j = 0; j < res.data[i].modulesInfos.length; j++) {
        const currentModule = res.data[i].modulesInfos[j];
        const moduleNumbe = (j + 1).toString();
        exportArray[i]['Módulo ' + moduleNumbe] = currentModule.moduleName;
        exportArray[i]['BDQ Certas ' + moduleNumbe] = currentModule.bdqPerformances.correctQuestions;
        exportArray[i]['BDQ Respondidas ' + moduleNumbe] = currentModule.bdqPerformances.answeredQuestions;
        exportArray[i]['BDQ Efetividade ' + moduleNumbe + '%'] = currentModule.bdqPerformances.effectiveness;
        exportArray[i]['Consumo Vídeo Início ' + moduleNumbe] = currentModule.videoConsummation.started;
        exportArray[i]['Consumo Vídeo Fim ' + moduleNumbe] = currentModule.videoConsummation.finished;
        exportArray[i]['Conceitos Obtidos ' + moduleNumbe] = currentModule.conceptPerformances.acquiredConcepts;
        exportArray[i]['Conceitos Módulo ' + moduleNumbe] = currentModule.conceptPerformances.moduleConcepts;
        exportArray[i]['Conceitos Cobertura ' + moduleNumbe + '%'] = currentModule.conceptPerformances.effectiveness;
      }
    }
    return exportArray;
  }

  public buildExportUserProgressReport(res: any): any[] {
    const exportArray: any[] = [];
    for (let i = 0; i < res.length; i++) {
      exportArray.push([]);
      exportArray[i]['Aluno'] = res[i].name;
      exportArray[i]['Dias apos matrícula'] = res[i].fromStartDays;
      exportArray[i]['Prazo restante'] = res[i].remainingDays;
      exportArray[i]['Progresso'] = res[i].trackProgress;
      exportArray[i]['Progresso esperado'] = res[i].trackExpectedProgress;
      exportArray[i]['Desvio padrão'] = res[i].standardDeviation;
      for (let j = 0; j < res[i].eventDetailItems.length; j++) {
        const currentEventDetailItem = res[i].eventDetailItems[j];
        exportArray[i]['Evento' + j] = currentEventDetailItem.name;
        exportArray[i]['Evento Prazo restante' + j] = currentEventDetailItem.remainingDays;
      }
      for (let j = 0; j < res[i].moduleDetailItems.length; j++) {
        const currentModuleDetailItem = res[i].moduleDetailItems[j];
        exportArray[i]['Módulo' + j] = currentModuleDetailItem.name;
        exportArray[i]['Módulo Prazo restante' + j] = currentModuleDetailItem.remainingDays;
        exportArray[i]['Dias apos 1º interação módulo' + j] = currentModuleDetailItem.firstInteractionDays;
        exportArray[i]['Dias apos 1º interação bdq' + j] = currentModuleDetailItem.bdqFirstInteractionDays;
      }
    }
    return exportArray;
  }

  public buildExportNpsReport(res: any): any[] {
    const exportArray: any[] = [];
    for (let i = 0; i < res.length; i++) {
      exportArray.push([]);
      exportArray[i]['Name'] = res[i].name;
      exportArray[i]['CPF'] = res[i].cpf;
      exportArray[i]['E-mail'] = res[i].email;
      exportArray[i]['Data'] = res[i].date;
      if (res[i].tracksInfo != null) {
        for (let j = 0; j < res[i].tracksInfo.length; j++) {
          const currentTrackInfoDetail = res[i].tracksInfo[j];
          exportArray[i]['Informação das trilhas'] = currentTrackInfoDetail.name;
        }
      } else {
        exportArray[i]['Informação das trilhas'] = '';
      }
      if (res[i].modulesInfo != null) {
        for (let j = 0; j < res[i].modulesInfo.length; j++) {
          const currentModuleInfoDetail = res[i].modulesInfo[j];
          exportArray[i]['Informação dos Módulos'] = currentModuleInfoDetail.name;
        }
      } else {
        exportArray[i]['Informação dos Módulos'] = '';
      }
      if (res[i].eventsInfo != null) {
        for (let j = 0; j < res[i].eventsInfo.length; j++) {
          const currentEventsInfoDetail = res[i].eventsInfo[j];
          exportArray[i]['Informação dos Eventos'] = currentEventsInfoDetail.name;
        }
      } else {
        exportArray[i]['Informação dos Eventos'] = '';
      }
    }
    return exportArray;
  }

  public buildExportModuleOpinionReport(res: any): any[] {
    const exportArray: any[] = [];
    for (let i = 0; i < res.length; i++) {
      exportArray.push([]);
      exportArray[i]['Módulo'] = res[i].module.title;
      exportArray[i]['Id'] = res[i].module.id;
      if (res[i].opinions != null) {
        for (let j = 0; j < res[i].opinions.length; j++) {
          const opinion = res[i].opinions[j];
          if (opinion.reactionType === 1) {
            exportArray[i]['Satisfação'] = 'Feliz';
          } else if (opinion.reactionType === 2) {
            exportArray[i]['Satisfação'] = 'Ok';
          } else {
            exportArray[i]['Satisfação'] = 'Triste';
          }
          exportArray[i]['Texto'] = opinion.text;
          if (opinion.opinionType === 1) {
            exportArray[i]['Tipo'] = 'Sugestão';
          } else if (opinion.opinionType === 2) {
            exportArray[i]['Tipo'] = 'Erro';
          } else {
            exportArray[i]['Tipo'] = 'Outro';
          }
        }
      }
    }
    return exportArray;
  }

  public flattenObject(obj): any {
    const toReturn = {};

    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) continue;

      if ((typeof obj[i]) === 'object' && obj[i] !== null) {
        const flatObject = this.flattenObject(obj[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = obj[i];
      }
    }
    return toReturn;
  }

  public flattenObjectOwnProperty(obj): any {
    const toReturn = {};

    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) continue;

      if ((typeof obj[i]) === 'object' && obj[i] !== null) {
        const flatObject = this.flattenObject(obj[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[x] = flatObject[x];
        }
      } else {
        toReturn[i] = obj[i];
      }
    }
    return toReturn;
  }
  public isEmpty(obj): boolean {
    return Object.keys(obj).length === 0;
  }


  private getCollumnsValue(sheet: XLSX.WorkSheet, headers: any[], columnStart: number = null, rowStart: number = null) {
    const value = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let rows = rowStart || range.s.r; /* start in the first row */
    let hdr = {};
    for (rows = range.s.r; rows <= range.e.r; ++rows) {
      hdr = {};
      let row;
      for (let i = 0; i < headers.length; ++i) {
        const cell = sheet[XLSX.utils.encode_cell({ c: headers[i]['collumn'], r: rows })];
        row = rows + 1;
        hdr[headers[i]['headear']] = null;
        if (cell && cell.t) {
          hdr[headers[i]['headear']] = XLSX.utils.format_cell(cell);
        }
      }
      const empty = Object.values(hdr).every(v => isNil(v));

      if (!empty) {
        hdr['row'] = row;
        value.push(hdr);
      }
    }
    value.shift();

    return value;
  }

  private getHeaderValues(sheet: XLSX.WorkSheet, mandatoryCollumns: string[], columnStart: number = null, rowStart: number = null): any[] {
    const headers = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let collumns = columnStart || range.s.c; /* start in the first collumn */
    const row = rowStart || range.s.r; /* start in the first row */

    for (collumns = range.s.c; collumns <= range.e.c; ++collumns) {
      const cell = sheet[XLSX.utils.encode_cell({ c: collumns, r: row })];
      let hdr = 'UNKNOWN' + collumns;
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        if (mandatoryCollumns.filter(collumnName => this.normalizeString(hdr) === this.normalizeString(collumnName)).length > 0) {
          headers.push({
            headear: this.normalizeString(hdr),
            collumn: collumns
          });
        }
      }
    }
    return headers;
  }

  public normalizeString(text: string): string {
    return text.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
  }

  public _getExcelContentAsJson(
    excelFile: any,
    mandatoryCollumns: string[],
    columnStart: number = null,
    rowStart: number = null): Observable<any> {
    return new Observable(observer => {
      const workbook = XLSX.read(excelFile, { type: 'binary' });
      const wsname: string = workbook.SheetNames[0];
      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];
      const headers = this.getHeaderValues(ws, mandatoryCollumns, columnStart, rowStart);

      if (headers.length !== mandatoryCollumns.length) {
        const headersName = headers.map(header => header.headers);
        const collumnsNotContains = mandatoryCollumns
          .filter(mandatoryCollumn => headers.filter(header => header.headear === mandatoryCollumn).length === 0);
        console.log(collumnsNotContains);
        const mensageError = collumnsNotContains.length > 1 ?
          collumnsNotContains.reduce((accumulator, currentValue) => accumulator + ', ' + currentValue)
          : collumnsNotContains[0];
        observer.error(`Campos obrigatórios não foram encontrados ${mensageError}`);
        observer.complete();
      }

      const contentJson = this.getCollumnsValue(ws, headers, columnStart, rowStart).filter(value => !this.isEmpty(value));
      observer.next({ contentJson, headers });
      observer.complete();

    });
  }
}
