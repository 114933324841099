<div class="inner-content">
  <ng-container *ngIf="showHeader">
    <nav>
      <a href="/home">
        <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
        voltar
      </a>

      <app-favorite-button [id]="track?.id" [isFavorite]="track?.isFavorite" [type]="favoriteEnum.track">
      </app-favorite-button>
    </nav>

    <div class="header">
      <div class="collumn">
        <p>{{replacement.track.singular | replacement:'':'de Curso' }}</p>
        <h4>
          {{ track?.title }}
        </h4>
      </div>
      <div class="progress-overview row" *ngIf="track?.anyModuleHasQuestions && hasLevelsFeature"
        [ngStyle]="{'minWidth': hasShowRankingFeature ? '225px' : 0, 'paddingRight': hasShowRankingFeature ? '30px' : 0}">
        <div class="collumn">
          <span>
            <span class="highlight">{{track?.grade | number : '1.2-2'}}</span>
            MÉD. {{replacement.track.singular | uppercase }}
          </span>
          <span
            title="PROGRESSSO {{ (replacement.track.singular | replacement:'NA') | uppercase }} (%): Total de módulos concluídos {{replacement.track.singular | replacement:'na' }} / total de módulos {{replacement.track.singular | replacement:'da' }}">
            <span class="highlight">{{ track?.conclusion | asPercentage }}%</span>
            CONCLUÍDO
          </span>
        </div>
        <div class="row" *ngIf="hasShowRankingFeature">
          <span class="highlight">{{track?.ranking}}º</span>
          <mat-icon class="material-icons-outlined">emoji_events</mat-icon>
        </div>
      </div>

    </div>
    <app-progress-bar
      title="PROGRESSSO {{ (replacement.track.singular | replacement:'NA') | uppercase }} (%): Total de módulos concluídos {{replacement.track.singular  | replacement:'na' }} / total de módulos {{replacement.track.singular | replacement:'da' }}"
      [currentProgress]="track?.conclusion | asPercentage" [height]="24"></app-progress-bar>
  </ng-container>
  <div class="content">
    <div class="app-track-overview">
      <ng-container *ngIf="track?.blockedByUserCareer">
        <ng-container *ngIf="track?.blockedByUserCareer">
          <div class="mandatory">
            <div>
              <p class="mandatory-title">
                <!-- INFORMAÇÃO DE CARREIRA OBRIGATÓRIA -->
                INFORMAÇÃO DE PERFIL PROFISSIONAL OBRIGATÓRIA
              </p>
              <p class="mandatory-subtitle">
                <!-- Preencha suas informalçoes de carreira para desbloquar o conteúdo da trilha -->
                Preencha suas informalçoes de perfil profissional para desbloquar o conteúdo
                {{replacement.track.singular | replacement:'da' }}
              </p>
            </div>
            <button class="btn-test mandatory-btn" (click)="fillUserCareer()">
              Preencher
            </button>
          </div>
        </ng-container>
        <div class="locked-track">
          <p>{{replacement.track.singular | uppercase}}</p>
          <img src="./assets/img/lock-icon.png" />
        </div>
      </ng-container>
      <ng-container *ngIf="!track?.blockedByUserCareer">
        <app-track-mandatory-video *ngIf="track?.mandatoryCourseVideo && !track?.trackInfo.viewedMandatoryVideo"
          (watchVideo)="watchMandatoryVideo()">
        </app-track-mandatory-video>
        <ng-container *ngIf="!track?.mandatoryCourseVideo ||
          (track?.mandatoryCourseVideo && track?.trackInfo.viewedMandatoryVideo)">
          <app-track-mandatory-video *ngIf="track?.mandatoryCourseVideo" [seen]="true"
            (watchVideo)="watchMandatoryVideo()">
          </app-track-mandatory-video>
          <app-track-overview-path [track]="track" [completedPercentage]="track?.conclusion | asPercentage"
            [trackTestsResearch]="trackTestsResearch" [trackTestsFree]="trackTestsFree"
            [trackCourseWork]="trackCourseWork" [setTrackTestsOrdered]="trackTestsOrdered" [programId]="programId">
          </app-track-overview-path>
        </ng-container>
      </ng-container>
    </div>
    <div class="content-wrapper"
      *ngIf="(hasEffortFeature && hasValidConfigurate || hasFeatureCockpitMetrics ||track && hasCalenderFeature )">
      <app-track-effort-progress *ngIf="hasEffortFeature && hasValidConfigurate" [dataset]="dataset">
      </app-track-effort-progress>
      <app-track-effort-progress-students *ngIf="hasFeatureCockpitMetrics" [effortMetric]="effortMetric">
      </app-track-effort-progress-students>
      <app-track-overview-calendar *ngIf="track && hasCalenderFeature" [setEvents]="getCalendarEvents()">
      </app-track-overview-calendar>
      <app-track-overview-warnings *ngIf="track && hasCalenderFeature" [warnings]="track?.warnings">
      </app-track-overview-warnings>
      <div id="videoContent" style="width: 100%; visibility: hidden;"></div>
      <ng-container *ngIf="track && track?.videoUrl && track?.videoUrl !== ''">
        <p class="title">
          <span>Introdução curso</span>
          <small>{{ getVideoDurationFormatted() }} minuto(s)</small>
        </p>
      </ng-container>
    </div>
  </div>
</div>
