<div class="exam-finish" >
  <div class="content" >
    <h3>
      Parabéns, você
      {{ achievedLevel < 3 ? 'completou o ' + levels[achievedLevel] : 
      isPracticing ? 'terminou de responder todas as perguntas do assunto' : 'atingiu o nível máximo' }}!
    </h3>
    <p *ngIf="achievedLevel < 3" >
      Continue para conquistar o Nível {{levels[achievedLevel+1]}}
    </p>

    <div class="progress" >
      <img [src]="getBadgesProgressImageSrc(achievedLevel)" *ngIf="achievedLevel < 3" />
      <div class="expert" *ngIf="achievedLevel == 3 && !isPracticing" >
        <img src="./assets/img/brain-dark-icon-shadow.png" />
        <p>
          Você agora é<br>
          <span>{{levels[achievedLevel]}} 100%</span>
        </p>
      </div>
    </div>

    <div class="actions" >
      <ng-container *ngIf="achievedLevel < 3" >
        <button class="continue" (click)="continue.emit()" >
          Continuar
        </button>
        <button class="later" (click)="finish.emit()" >
          Deixar para Depois
        </button>
      </ng-container>
      <button class="continue" (click)="finish.emit()" *ngIf="achievedLevel == 3" >
        Ver Outros Assuntos
      </button>
    </div>
  </div>
</div>
