<div class="concepts" >
  <p class="concept-title">OBJETOS ASSOCIADOS</p>
  <ul *ngIf="data.tracks">
    <p class="list-title">
      Trilhas
    </p>
    <li *ngFor="let track of data.tracks">
      <p class="item-title" >
        {{ track.name }}
      </p>
    </li>
  </ul>
  <ul *ngIf="data.modules">
    <p class="list-title">
      Modulos
    </p>
    <li *ngFor="let module of data.modules">
      <p class="item-title" >
        {{ module.name }}
      </p>
    </li>
  </ul>
  <ul *ngIf="data.events">
    <p class="list-title">
      Eventos
    </p>
    <li *ngFor="let event of data.events">
      <p class="item-title" >
        {{ event.name }}
      </p>
    </li>
  </ul>
</div>
<p class="dismiss" (click)="dismiss()" >
  fechar
</p>
