import { ContentTypeEnum } from './enums/content-type.enum';

export type Concept = VideoReference | PdfReference | TextReference;
export class Content {
  public id?: string;
  public type: ContentTypeEnum;
  public title: string;
  public excerpt: string;
  public duration: number;
  public formatedDuration?: string = '';
  public referenceUrls: Array<string>;
  public moduleId?: string;
  public subjectId?: string;
  public concepts: ConceptReference[];
  public value: string;
  public originalValue?: string;
  public numPages?: number;
  public initialValue?: string;
  public subjectTitle?: string;
  public accessed?: boolean;
  public watched?: boolean;
  public viewPercentCompleteContent?: number = 90;

  constructor(subjectId: string, concepts: Array<ConceptReference> = []) {
    this.subjectId = subjectId;
    this.concepts = concepts;
    this.referenceUrls = [''];
  }
}

export interface ConceptReference {
  name: string;
  checked?: boolean;
}

export interface VideoReference extends ConceptReference {
  positions: Array<number | string>;
}

export interface PdfReference extends ConceptReference {
  positions: Array<number | string>;
}

export interface TextReference extends ConceptReference {
  anchors: Array<string>;
}

export interface HtmlReference extends ConceptReference {
  positions: Array<number | string>;
}
export class ContentExcel {
  public Nome_do_Assunto: string;
  public Nome_do_Conteudo: string;
  public Tipo_do_Conteudo: string;
  public Valor_do_Conteudo: string;
  public Descricao_do_Conteudo: string;
  public Urls_de_Referencia: string;
}
