export enum TrackHeightChangeEnum {
    showText = 60,
    showContent = 100,
    showModule = 30,
    showEvents = 30,
    hours = 32,
    showButtonKnowMore = 32,
    showPrice = 32,
    showButtonSubscribe = 32
}
