<h3>{{ data?.testTitle }}</h3>

<div class="content">
    <div id="htmlEditor"></div>
</div>

<div class="module-info step">
    <form [formGroup]="formGroup">
        <div class="support-material two-columns"
            *ngFor="let childFormGroup of formGroup.get('materials')['controls']; let i = index"
            formArrayName="materials">
            <ng-container [formGroupName]="i">
                <div>
                    <div class='header'>
                        <label><strong>Material de Suporte {{i + 1}}</strong></label>
                        <img class="trash" src="./assets/img/trash.png" (click)="removeMaterial(i)" />
                    </div>
                    <div class="validation-field">
                        <mat-form-field>
                            <input matInput placeholder="Nome do Material" formControlName="title" required />
                        </mat-form-field>
                        <control-messages [control]="childFormGroup.get('title')"></control-messages>
                    </div>
                    <mat-form-field>
                        <mat-select placeholder="Tipo de Material" formControlName="type" required>
                            <mat-option [value]="1">Link</mat-option>
                            <mat-option [value]="2">Arquivo</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="upload" *ngIf="childFormGroup.get('type').value === 2">
                        <p class="file-name" *ngIf="!childFormGroup.get('downloadLink').value">
                            Escolher Arquivo *
                        </p>
                        <a class="file-name" *ngIf="childFormGroup.get('downloadLink').value" target="_blank"
                            [href]="childFormGroup.get('downloadLink').value">
                            {{ childFormGroup.get('fileName').value }}
                        </a>
                        <button type="button" (click)="openFileUpload(i)">
                            {{ childFormGroup.get('fileName').value ? 'Editar' : 'Upload' }}
                        </button>
                        <input class="input-file" type="file" id="inputFile{{i}}" hidden
                            (change)="setDocumentFile($event, childFormGroup, true)" />
                    </div>

                    <div class="upload" *ngIf="childFormGroup.get('type').value === 1">
                        <mat-form-field>
                            <input matInput placeholder="Link do Material" formControlName="downloadLink" required />
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>

        <button class="btn-test add-material" (click)="addMaterial()">
            + Adicionar Material
        </button>
    </form>
</div>

<div class="actions" >
  <button class="btn-outline" (click)="dismiss()" >
    Cancelar
  </button>
  <button class="btn-test" (click)="save()" >
    Salvar
  </button>
</div>
