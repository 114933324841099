import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-pass-dialog',
  templateUrl: './forgot-pass-dialog.component.html',
  styleUrls: ['./forgot-pass-dialog.component.scss']
})
export class ForgotPassDialogComponent {

  public formGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<ForgotPassDialogComponent>,
  ) {
    this.formGroup = new FormGroup({
      'credential': new FormControl('', [Validators.required]),
    });
  }


  public dismiss(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const credential = this.formGroup.get('credential').value;
    this.dialogRef.close(credential);
  }
}
