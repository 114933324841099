import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forEach, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ExemptionReasonService } from '../../_services/exemption-reason.service';
import { ExemptionService } from '../../_services/exemption.service';
import { SettingsModulesService } from '../../_services/modules.service';
import { IExemptionUsers } from '../exemption-user-dialog/Models/exemption-users.interface';
import { IFormUser, IExemption } from './Models/form-users';

@Component({
  selector: 'app-exemption-user-dialog-manually',
  templateUrl: './exemption-user-dialog-manually.component.html',
  styleUrls: ['./exemption-user-dialog-manually.component.scss']
})
export class ExemptionUserDialogManuallyComponent extends NotificationClass implements OnInit {

  public searchModuleResults: Array<Array<ModulePreview>> = [];
  private _searchModuleSubject: Subject<any> = new Subject();
  public usersExemptions: IFormUser[] = [];
  public selectedExemptions: IExemption[] = [];
  public displayedColumns: string[] = ['title', 'reason', 'action'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { users: IFormUser[] },
    private _modulesService: SettingsModulesService,
    private _exemptionService: ExemptionService,
    private _cdr: ChangeDetectorRef,
    protected _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<ExemptionUserDialogManuallyComponent>) {
    super(_snackBar);
  }

  ngOnInit() {
    this._setModuleSearchSubscription();
    this.usersExemptions = this.data.users;
    this.selectedExemptions = this.data.users.map(() => ({} as IExemption));
  }

  public updateModuleSearch(searchTextValue: string, index) {
    if (searchTextValue === '') {
      this.searchModuleResults = [];
      return;
    }
    this._searchModuleSubject.next({ searchTextValue, index });
  }
  private _setModuleSearchSubscription() {
    this._searchModuleSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue) => {
      this._modulesService.getPagedFilteredModulesList(1, 4, searchValue.searchTextValue).subscribe((response) => {
        this.searchModuleResults[searchValue.index] = response.data.modules;
      });
    });
  }

  public setModule(module: ModulePreview, index: number) {
    this.selectedExemptions[index] = {
      title: module.title, moduleId: module.id, reason:
        isNil(this.selectedExemptions[index]['reason']) ? null as unknown as string : this.selectedExemptions[index]['reason']
    } as IExemption;
    this.searchModuleResults[index] = [];
    (document.getElementById(`moduleInput_${index}`) as HTMLInputElement).value = module.title;
  }

  public addExemption(studentId: string, index: number) {
    if (this.selectedExemptions[index] &&
      (isNil(this.selectedExemptions[index].moduleId) || this.selectedExemptions[index].moduleId === '')) {
      return this.notify('é preciso selecionar um módulo');
    }
    if (this.selectedExemptions[index] &&
      (isNil(this.selectedExemptions[index].reason) || this.selectedExemptions[index].reason === '')) {
      return this.notify('é necessário informar um motivo de isenção');
    }
    const userExemption = this.usersExemptions.find(user => user.userId === studentId);
    const wasModuleAdd = userExemption.exemptions.find(exemption => exemption.moduleId === this.selectedExemptions[index].moduleId);
    if (isNil(wasModuleAdd)) {
      userExemption.exemptions = [...userExemption.exemptions, this.selectedExemptions[index]];
    } else if (wasModuleAdd.fromDb && wasModuleAdd.canExemption === false) {
      wasModuleAdd.canExemption = true;
    }
    (document.getElementById(`moduleInput_${index}`) as HTMLInputElement).value = '';
    this.selectedExemptions[index] = {} as IExemption;
  }

  public removeExemption(studentId: string, index: number) {
    const userExemption = this.usersExemptions.find(user => user.userId === studentId);
    if (userExemption.exemptions[index].fromDb) {
      userExemption.exemptions[index].canExemption = false;
      this._cdr.detectChanges();
    } else {
      userExemption.exemptions.splice(index, 1);
    }
  }

  public save() {
    const hasInvalidConfiguration = this.usersExemptions.some(exempt => exempt.exemptions.length === 0);
    if (hasInvalidConfiguration) return this.notify('é necessário adicionar pelo menos uma isenção a cada usuário');
    const exemptions: IExemptionUsers[] = [];
    this.usersExemptions.forEach(user => {
      user.exemptions.forEach(exemption => {
        exemptions.push({
          name: user.name,
          userName: user.userName,
          email: user.email,
          canExemption: (isNil(exemption.canExemption) || exemption.canExemption) ? 'Sim' : 'Não',
          moduleId: exemption.moduleId,
          reason: exemption.reason
        } as IExemptionUsers);
      });
    });

    this._exemptionService.createUserExemption(exemptions).subscribe(response => {
      this._dialogRef.close();
    });
  }
  public dismiss() {
    this._dialogRef.close();
  }
}
