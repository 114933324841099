import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EcommerceProduct } from 'src/app/models/ecommerce-product.model';
import { Program } from 'src/app/settings/programs/Models/program.model';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent extends NotificationClass {

  private _form: FormGroup = this._createForm();
  public get form(): FormGroup { return this._form; }
  public set form(value: FormGroup) {
    this._form = value;
    this.formChange.emit(this.form);
  }

  @Output() formChange = new EventEmitter<FormGroup>();

  private _value: Partial<Program>;
  @Input()
  public get value(): Partial<Program> { return this._value; }
  public set value(value: Partial<Program>) {
    this._value = value;
    this.form = this._createForm(this._value.ecommerce);
  }

  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    super(_snackBar);
  }



  private _createForm(value: EcommerceProduct[] = null) {
    return new FormGroup({
      'ecommerce': value === null
        ? this._formBuilder.array([])
        : this._formBuilder.array(value.map(v => this._createEcommerceProductForm(v)))
    });
  }

  public addEcommerceId(): void {
    const products = this.form.get('ecommerce') as FormArray;
    products.push(
      this._createEcommerceProductForm()
    );
  }
  public removeEcommerceProduct(index: number): void {
    const products = this.form.get('ecommerce') as FormArray;
    products.removeAt(index);
  }

  private _createEcommerceProductForm(ecommerceProduct: EcommerceProduct = null): FormGroup {
    return this._formBuilder.group({
      'ecommerceId': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.ecommerceId : null, [Validators.compose([Validators.required, Validators.min(1)])]),
      'usersToRegisterPerPurchase': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.usersToRegisterPerPurchase : ''),
      'disableEcommerce': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.disableEcommerce : false, [Validators.required]
      ),
      'price': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.price : '', [Validators.compose([Validators.required, Validators.min(1)])]),
      'disableFreeTrial': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.disableFreeTrial : false, [Validators.required]
      ),
      'linkEcommerce': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.linkEcommerce : ''),
      'linkProduct': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.linkProduct : ''),
      'subject': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.subject : ''),
      'workload': this._formBuilder.control(
        ecommerceProduct ? ecommerceProduct.workload : '')
    });
  }

}
