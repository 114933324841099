<div class="card">
    <span class="ranking">
        <span class="highlight">{{ranking === 0 ? '-' : ranking + 'º'}}</span>
        <mat-icon class="material-icons-outlined">emoji_events</mat-icon>
    </span>
    <div class="ranking-legend">
        <p class="title">RANKING NA TURMA</p>
        <p class="sub-title">O ranking calcula o desempenho do aluno através da média de desempenho nas provas</p>
        <div class="action">
            <button (click)="seeStatus()">VER DETALHES DO DESEMPENHO</button>
        </div>
    </div>
</div>
