import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-debug',
  template: `
    <p>
      debug works!
    </p>
  `,
  styles: []
})
export class DebugComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
