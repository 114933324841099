import { Injectable  } from '@angular/core';
import { BackendService, BackendResponse } from '@tg4/http-infrastructure';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UrlService } from 'src/app/shared/services/url.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';


@Injectable()
export class DownloadService {

  constructor(
    private _httpService: BackendService,
    private _authService: AuthService,
    private _urlService: UrlService,
    private _sharedService: SharedService
    ) { }

  public _downloadFile(url: string, data: any): any {
    const applicationsUrl = this._urlService.getUrl(url);
    const user = this._authService.getLoggedUser();
    this._sharedService.setLoaderValue(true);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': user.access_token,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    const impersonatedUser = this._authService.getImpersonatedUser();
    if (impersonatedUser && impersonatedUser.userId) {
      options.headers['UserIdToImpersonate'] = impersonatedUser.userId;
    }
    return this._httpService.httpRaw.post(applicationsUrl, data, options)
      .pipe(
        map(this._extractContent),
        catchError(async (responseError: HttpErrorResponse) => {
          if (responseError.error instanceof Blob) {
            const errorText = await responseError.error['text']();
            const serializedError: BackendResponse<any> = JSON.parse(errorText);
            return serializedError;
          }
          this._sharedService.setLoaderValue(false);
          return responseError;
        })
      );
  }

  private _extractContent(res: any) {
    const blob: Blob = res.body;
    const contentDisposition = res.headers.get('content-disposition');
    const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    const url = window.URL.createObjectURL(blob);

    const element = document.createElement('a');
    element.href = url;
    element.download = filename ? filename : 'arquivo';
    document.body.appendChild(element);
    element.click();
    this._sharedService.setLoaderValue(false);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      element.remove();
    }, 0);

  }
}
