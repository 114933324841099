import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Sector } from 'src/app/models/sector.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { SectorService } from '../_services/sector.service';

@Component({
  selector: 'app-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.scss']
})
export class SectorComponent implements OnInit {

  public page: number = 1;
  public readonly pageSize: number = 7;
  public itemsCount: number = 0;
  public sectors: Sector[] = [];

  constructor(private _sectorService: SectorService,
    private _router: Router,
    private _dialog: MatDialog) { }


  ngOnInit() {
    this.loadSector();
  }

  public loadSector() {
    this._sectorService.GetPagedSector(this.page, this.pageSize).subscribe(response => {
      this.sectors = response.data.sectors;
      this.itemsCount = response.data.itemsCount;
    });
  }

  public goToPage(page: number) {
    this.page = page;
    this.loadSector();
  }

  public goTo(sector: Sector = null) {
    const url = 'configuracoes/setor' + (sector === null ? '' : '/' + sector.id);
    this._router.navigate([url]);
  }

  public delete(sector: Sector) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: { message: 'Tem certeza que excluir este setor ?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._sectorService.deleteSector(sector.id).subscribe(value => {
          this.loadSector();
        });
      }

    });
  }
}
