import { MenuSection } from './menu-item.interface';
import { environment } from 'src/environments/environment';
import { ERole } from 'src/app/models/enums/role.enum';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { Http2ServerRequest } from 'http2';
import { BackendService } from '@tg4/http-infrastructure';

export function allMenuItems(): Array<MenuSection> {
  const menu = [{
    title: 'Início',
    items: [{
      url: 'home', title: 'Home',
      iconClass: 'icon icon-home sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, /*{
      url: 'performance', title: 'Desempenho',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Student],
      checkProfileTest: false, checkFormulas: false
    }
    {
      url: 'catalogo-de-cursos', title: 'Catálogo de Cursos',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.eCommerce
    }, ,*/{
      url: 'configuracoes/detalhes-usuario/:userId', title: 'Meus Dados',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true,
      blockAccess: [ERole.BusinessManager]
    }, /*, {
      url: 'atendimento', title: 'Mercado',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Student],
      checkProfileTest: false, checkFormulas: false
    }*/, {
      url: 'atendimento', title: 'Suporte & Atendimento',
      iconClass: 'icon icon-atendimento sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }],
    permittedRoles: [ERole.Student, ERole.Admin],
    isRunningFeature: true
  }, {
    title: 'Aprendizagem',
    items: [{
      url: 'meus-cursos', title: 'Meus Cursos e Avaliações',
      iconClass: 'icon icon-modulo sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'meus-programas', title: 'Meus Programas',
      iconClass: 'icon icon-modulo sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'home/calendario', title: 'Calendário de Atividades',
      iconClass: 'icon icon-eventos sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.calender
    }, {
      url: 'performance', title: 'Meu Desempenho',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.myPerformance
    }],
    permittedRoles: [ERole.Student, ERole.Admin],
    isRunningFeature: true
  }, {
    title: 'Desenvolvimento Profissional',
    items: [{
      url: 'configuracoes/card-recomendacao/:userId', title: 'Meu Perfil Profissional',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.career,
      blockAccess: [ERole.BusinessManager]
    }, /*{
      url: 'minha-candidatura', title: 'Minhas Oportunidades',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.career,
      blockAccess: [ERole.BusinessManager]
    }*/, {
      url: 'buscar-vagas-aluno', title: 'Oportunidades',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.career,
      blockAccess: [ERole.BusinessManager]
    }],
    permittedRoles: [ERole.Student, ERole.Admin],
    isRunningFeature: environment.features.career
  }, {
    title: 'Gerenciamento de Programas',
    items: [{
      url: 'configuracoes/enturmacao-matricula', title: 'Enturmação e Matrícula',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/gerenciar-equipe', title: 'Gerenciar Matrículas',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/emails-enviados', title: 'Gerenciar Contatos',
      iconClass: 'icon icon-historico_emails sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }],
    permittedRoles: [ERole.Admin, ERole.Secretary],
    isRunningFeature: true
  }, {
    title: 'Acompanhamento de Programas',
    items: [{
      url: 'empenho-desempenho', title: 'Empenho e Desempenho',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true,
    }, {
      url: 'configuracoes/resultado-testes-de-avaliacao', title: 'Resultado de Avaliações nos Cursos',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/resultado-pesquisa-na-base', title: 'Resultado de Pesquisas',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/resultado-pesquisa-na-base-nps', title: 'Resultado de Pesquisas - NPS',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'relatorios', title: 'Relatórios Analíticos',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Secretary, ERole.Student, ERole.HumanResources],
      checkAccess: [EAccess.TrackManager, EAccess.LineManager, EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }],
    permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.BusinessManager, ERole.Secretary, ERole.Student],
    isRunningFeature: true
  }, {
    title: 'Gestão de Competências',
    items: [{
      url: 'mapa-de-competencias', title: 'Mapa de Competências',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.recruitment
    }, {
      url: 'desempenho-da-capacitacao', title: 'Desempenho da Capacitação',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.recruitment
    }, {
      url: 'historico-de-performance-profissional', title: 'Histórico de Performance Profissional',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.recruitment
    }],
    permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Secretary, ERole.Student, ERole.Recruiter, ERole.BusinessManager],
    isRunningFeature: environment.features.recruitment
  }, {
    title: 'Talentos & Oportunidades',
    items: [{
      url: 'configuracoes/vagas-empresa', title: 'Gerenciar Oportunidades',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.recruitment
    }, {
      url: 'configuracoes/buscar-talentos', title: 'Banco de Talentos',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.recruitment
    }],
    permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter],
    isRunningFeature: environment.features.recruitment
  }, {
    title: 'Desenho Instrucional',
    items: [{
      url: 'configuracoes/modulos', title: 'Cursos e Avaliações',
      iconClass: 'icon icon-modulo sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Author, ERole.Student],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true,
      checkAccess: [EAccess.InstructorModule, EAccess.TrackManager, EAccess.LineManager]
    }, {
      url: 'configuracoes/eventos', title: 'Eventos',
      iconClass: 'icon icon-eventos sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Author, ERole.Student],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true,
      checkAccess: [EAccess.InstructorEvent, EAccess.TrackManager, EAccess.LineManager]
    }, {
      url: 'configuracoes/trilhas', title: 'Trilhas',
      iconClass: 'icon icon-trilhas sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Author, ERole.Student],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true,
      checkAccess: [EAccess.InstructorEvent, EAccess.TrackManager, EAccess.LineManager]
    }, {
      url: 'configuracoes/programas', title: 'Programas',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin],
      checkAccess: [],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    } /*, {
      url: 'configuracoes/testes-de-avaliacao', title: 'Avaliações',
      iconClass: 'icon icon-usuarios sidemenu-icon',
      permittedRoles: [ERole.Student, ERole.BusinessManager],
      checkProfileTest: false, checkFormulas: false,
      checkAccess: ['Manager']
    }, {
      url: 'configuracoes/formulas', title: 'Fórmulas',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin],
      checkProfileTest: false, checkFormulas: true, isRunningFeature: environment.features.formulas
    }*/],
    permittedRoles: [ERole.Admin
      , ERole.HumanResources
      , ERole.Secretary
      , ERole.Student
      , ERole.Recruiter
      , ERole.BusinessManager
      , ERole.Author],
    isRunningFeature: true
  }, {
    title: 'Avaliações e Pesquisas',
    items: [/*{
      url: 'banco-questoes-tags', title: 'Banco de Questões & Tags',
      iconClass: 'icon icon-usuarios sidemenu-icon',
      permittedRoles: [ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    },*/ {
        url: 'configuracoes/testes-de-avaliacao', title: 'Avaliações, Pesquisas e Trabalhos nos Cursos',
        iconClass: 'icon icon-usuarios sidemenu-icon',
        permittedRoles: [ERole.Admin],
        checkProfileTest: false, checkFormulas: false, isRunningFeature: true
      }, {
        url: 'configuracoes/pesquisa-na-base', title: 'Pesquisas na Base',
        iconClass: 'icon icon-usuarios sidemenu-icon',
        permittedRoles: [ERole.Admin],
        checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.profileTest
      }, {
        url: 'configuracoes/pesquisa-na-base-nps', title: 'Pesquisas na Base - NPS',
        iconClass: 'icon icon-usuarios sidemenu-icon',
        permittedRoles: [ERole.Admin],
        checkProfileTest: false, checkFormulas: false, isRunningFeature: true
      }, {
        url: 'curadoria', title: 'Curadoria',
        iconClass: 'icon icon-usuarios sidemenu-icon',
        permittedRoles: [ERole.Admin],
        checkProfileTest: false, checkFormulas: false, isRunningFeature: true
      }],
    permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Secretary, ERole.Student, ERole.Recruiter, ERole.BusinessManager],
    isRunningFeature: true
  }, {
    title: 'Cadastro',
    items: [{
      url: 'configuracoes/incluir-novos-usuarios', title: 'Incluir novos usuários',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Student],
      checkAccess: [EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/gerenciar-equipe', title: 'Gerenciar Usuários',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Student],
      checkAccess: [EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/turmas', title: 'Gerenciar Turmas',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Student],
      checkAccess: [EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/banco-de-questão-bloqueado', title: 'Assuntos Bloqueados',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Student],
      checkAccess: [EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/processos-de-sincronia', title: 'Carregamento em Lote',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Student],
      checkAccess: [EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }, {
      url: 'configuracoes/plano-de-estudos', title: 'Plano de Estudo',
      iconClass: 'icon icon-processos_sincronia sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.Student],
      checkAccess: [EAccess.Sales],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }
    ],
    permittedRoles: [ERole.Admin, ERole.Student],
    isRunningFeature: true
  }, {
    title: 'Parametrização',
    items: [{
      url: 'home/color', title: 'Paletas de cores',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.BusinessManager],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.colorPalette
    }, {
      url: 'home/certificate', title: 'Certificado',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: ['Admin', 'BusinessManager'],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.certificate
    }, {
      url: 'configuracoes/comunicacoes', title: 'Comunicações',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: ['Admin'],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.comunication
    }, {
      url: 'configuracoes/associacao-automatica', title: 'Associação Automatica',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.BusinessManager],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: environment.features.automaticSync
    },
    {
      url: 'configuracoes/setores', title: 'Setores',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.BusinessManager],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    },
    {
      url: 'configuracoes/segmentos', title: 'Segmentos',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin, ERole.BusinessManager],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }],
    permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Secretary, ERole.Student, ERole.Recruiter, ERole.BusinessManager],
    isRunningFeature: environment.features.colorPalette || environment.features.certificate
  }/*, {
    title: 'Relatórios Analíticos',
    items: [{
      url: 'configuracoes/logs', title: 'Logs de Acesso',
      iconClass: 'icon icon-gerenciar_equipe sidemenu-icon',
      permittedRoles: [ERole.Admin],
      checkProfileTest: false, checkFormulas: false, isRunningFeature: true
    }],
    permittedRoles: [ERole.Admin],
    isRunningFeature: true
  }*/];
  // if (environment.features.recruitment) {
  //   menu.push({
  //     title: 'Recrutamento',
  //     items: [{
  //       url: 'configuracoes/identificacao-empresa', title: 'Perfil da Empresa',
  //       iconClass: 'icon icon-usuarios sidemenu-icon',
  //       permittedRoles: [ERole.Recruiter],
  //       checkProfileTest: false, checkFormulas: false
  //     }, {
  //       url: 'configuracoes/buscar-talentos', title: 'Buscar Talentos',
  //       iconClass: 'icon icon-trilhas sidemenu-icon',
  //       permittedRoles: [ERole.Admin, ERole.HumanResources],
  //       checkProfileTest: false, checkFormulas: false
  //     }, {
  //       url: 'configuracoes/vagas-empresa', title: 'Minhas Vagas',
  //       iconClass: 'icon icon-trilhas sidemenu-icon',
  //       permittedRoles: [ERole.Recruiter],
  //       checkProfileTest: false, checkFormulas: false
  //     }],
  //     permittedRoles: [ERole.Admin, ERole.HumanResources, ERole.Recruiter]
  //   });
  // }
  return menu;
}
