import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Module } from '../../../../../models/module.model';
import { Question } from '../../../../../models/question.model';
import { NewQuestionDialogComponent } from './new-question/new-question.dialog';
import { UtilService } from '../../../../../shared/services/util.service';
import { Level } from '../../../../../models/shared/level.interface';
import { ConfirmDialogComponent } from '../../../../../shared/dialogs/confirm/confirm.dialog';
import { Subject as rxjsSubject, BehaviorSubject, Observable, Subject as SubjectRxjs, Subscription, Observer } from 'rxjs';
import { concatMap, debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from '../../../../../models/subject.model';
import { UploadQuestionDatabaseDialogComponent } from './upload-qdb/upload-qdb.dialog';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { SettingsModulesDraftsService } from 'src/app/settings/_services/modules-drafts.service';
import { ModuleGradeTypeEnum } from 'src/app/models/enums/ModuleGradeTypeEnum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { cloneDeep, isNil } from 'lodash';
import { environment } from 'src/environments/environment';
import { SettingsModulesService } from 'src/app/settings/_services/modules.service';
@Component({
  selector: 'app-new-module-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['../new-module-steps.scss', './questions.component.scss']
})
export class NewModuleQuestionsComponent extends NotificationClass implements OnInit, OnDestroy {

  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  @Input() module$: Observable<Module>;

  @Input() createNewModuleDraft: Function;

  public module: Module;
  @Input() readonly levels: Array<Level>;
  @Input()
  set updateQuestions(value) {
    if (value) {
      this.loadQuestions();
    }
  }
  get updateQuestions() {
    return this.updateQuestions;
  }
  @Output() addQuestions = new EventEmitter();

  @Output() loadQuestionsEvent = new EventEmitter();


  public questions: Array<Question> = [];
  public newQuestion: Question;
  public searchValue: string = '';
  public questionsCount: number = 0;
  @Input() newModule2: BehaviorSubject<Module>;

  private _unsubscribeAll = new SubjectRxjs<any>();
  private _currentPage: number = 1;
  private _searchSubject: rxjsSubject<string> = new rxjsSubject();

  public hasComment: boolean = environment.features.questionComment;
  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _utilService: UtilService,
    private _excelService: ExcelService,
    private _draftsService: SettingsModulesDraftsService,
    private _moduleService: SettingsModulesService
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = cloneDeep(moduleItems);
        this.module.moduleGradeType = moduleItems.moduleGradeType ?
          moduleItems.moduleGradeType : ModuleGradeTypeEnum.SubjectsLevel;
        this.module.hasNewLearningTechniques = moduleItems.hasNewLearningTechniques || false;
        this.loadQuestions();
        this._setSearchSubscription();
      });
    }
  }

  public confirmRemoveQuestion(questionId: string): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja remover esta questão?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (!this.module.isDraft) {
          this.createNewModuleDraft(this.module).then(() => {
            this.module.isDraft = true;
            this.loadQuestions().add(() => this._removeQuestion(questionId));
          });
        } else {
          this._removeQuestion(questionId);
        }
      }

    });
  }

  public openQuestionDialog(subject: Subject) {
    const dialogRef = this._dialog.open(NewQuestionDialogComponent, {
      width: '1000px',
      data: {
        'question': new Question(subject.id, [], this.questions.length),
        'concepts': subject.concepts,
        'levels': this.levels
      }
    });

    dialogRef.afterClosed().subscribe((question: Question) => {
      if (question) {
        this._addQuestion(question);
      }
    });
  }

  public getLevel(level): string {
    switch (level) {
      case 0:
        return 'Fácil';
      case 1:
        return 'Médio';
      case 2:
        return 'Difícil';
    }
  }

  public exportSubjectQuestions() {
    this.getAllQuestions().subscribe(res => {
      const excelQuestions = [];
      for (let questionIndex = 0; questionIndex < res.data.length; questionIndex++) {
        const question = res.data[questionIndex];
        const newExcelLine = {};
        if (this.module.subjects.find(s => s.id === question.subjectId)) {
          const subjectTitle = this.module.subjects.find(s => s.id === question.subjectId).title;
          newExcelLine['Assunto'] = subjectTitle;
          newExcelLine['Id Questao'] = question.id;
          newExcelLine['Tipo'] = 'Enunciado';
          newExcelLine['Questao Resposta'] = question.text;
          newExcelLine['Nivel Valor Resposta'] = this.getLevel(question.level);
          newExcelLine['Conceitos Corretos na Resposta'] = question.concepts.join(',');
          newExcelLine['Assunto Id'] = question.subjectId;
          newExcelLine['Tempo'] = this._utilService.SecondToMinute(question.duration);
          if (this.hasComment) {
            newExcelLine['Comentário'] = question.comment;
          }
          excelQuestions.push(newExcelLine);
          for (let answerIndex = 0; answerIndex < question.answers.length; answerIndex++) {
            const answer = question.answers[answerIndex];
            const newExcelQuestionLine = {};
            newExcelQuestionLine['Assunto'] = subjectTitle;
            newExcelQuestionLine['Id Questao'] = question.id;
            newExcelQuestionLine['Tipo'] = 'R' + (answerIndex + 1).toString();
            newExcelQuestionLine['Questao Resposta'] = answer.description;
            newExcelQuestionLine['Nivel Valor Resposta'] = answer.points;
            newExcelQuestionLine['Conceitos Corretos na Resposta'] = answer.concepts.filter(x => x.isRight).map(x => x.concept).join(',');
            newExcelQuestionLine['Assunto Id'] = question.subjectId;
            newExcelQuestionLine['Tempo'] = this._utilService.SecondToMinute(question.duration);
            if (this.hasComment) {
              newExcelQuestionLine['Comentário'] = question.comment;
            }
            excelQuestions.push(newExcelQuestionLine);
          }
        }
      }
      this._excelService.exportAsExcelFile(excelQuestions, 'BDQ - ' + this.module.title);
    });
  }

  public getAllQuestions(): Observable<any> {
    if (this.module.isDraft) {
      return this._draftsService.getAllQuestionsDraft(this.module.id);
    } else {
      return this._moduleService.getQuestionsList(this.module.id);
    }
  }

  public openUploadDialog(module: Module) {
    const dialogRef = this._dialog.open(UploadQuestionDatabaseDialogComponent, {
      width: '1000px',
      maxHeight: '90vh',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        'module': module,
      }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (!isNil(data) && !isNil(data.questions)) {
        this.getDraft().subscribe((response: Module | any) => {
          const res = response instanceof Module ? response : response.data;
          this._draftsService.importDraftQdb(data.questions, res.id, data.addQuestions)
            .subscribe(() => {
              this.loadQuestions().add(() => localStorage.setItem('editingModule', JSON.stringify(this.module)));
              this.notify('Modificacões Salvas em Rascunho');
            }, (err) => this.notify(this.getErrorNotification(err), 'Fechar', { duration: 9000000000000 }));
        });
      }
    });
  }

  public getDraft(): Observable<Module | any> {
    if (!this.module.isDraft) {
      this.nextStep(false);
      return new Observable((observer: Observer<any>) => {
        this.createNewModuleDraft(this.module).then(() => observer.next(this.module));
      });
    } else {
      return this._draftsService.getDraftById(this.module.id);
    }
  }

  public editQuestion(subject: Subject, dbQuestion: Question, questionIndex: number) {
    if (typeof dbQuestion.duration !== 'string') {
      dbQuestion.duration = (
        this._utilService.formatDurationToHour(dbQuestion.duration) as any
      );
    }

    const dialogRef = this._dialog.open(NewQuestionDialogComponent, {
      width: '1000px',
      data: {
        questionIndex,
        'question': dbQuestion,
        'concepts': subject.concepts,
        'levels': this.levels
      }
    });

    dialogRef.afterClosed().subscribe((question: Question) => {
      if (question) {
        this._addQuestion(question);
        this.notify('Modificacões Salvas em Rascunho');
      }
    });
  }

  public getQuestionDuration(duration: number | string): string {
    if (typeof duration === 'string') {
      const durStr = duration.split(':').join('');
      return durStr.length > 5 ?
        durStr.substring(2, 4) + ':' + durStr.substring(4, 6) : duration;
    }
    return this._utilService.formatSecondsToMinutes(duration);
  }

  public getLevelDescription(levelId: number): string {
    if (!this.levels) return '';
    const level = this.levels.find(lev => lev.id === levelId);
    return level ? level.description : '';
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  public goToPage(page: number) {
    if (page !== this._currentPage) {
      this._currentPage = page;
      this.loadQuestions();
    }
  }

  public nextStep(value: boolean = true): void {
    const response = Object.assign(this.getObjectEmit(), { 'nextStep': value });
    this.addQuestions.emit(response);
  }

  private getObjectEmit() {
    return {
      'questions': this.questions,
      'questionsLimit': this.module.questionsLimit,
      'moduleGradeType': this.module.moduleGradeType,
      'hasNewLearningTechniques': this.module.hasNewLearningTechniques,
    };
  }

  private _addQuestion(question: Question): void {
    if (question) this.questions.push(question);

    if (this.module.isDraft) {
      this._manageQuestion(question);
    } else {
      this.createNewModuleDraft(this.module).then(() => {
        this._loadAndUpdateCurrentQuestion(question, this._manageQuestion.bind(this));
      });
      this.module.isDraft = true;
    }
  }

  private _loadAndUpdateCurrentQuestion(question: Question, callback: Function): Subscription {
    return this.loadQuestionsEvent.subscribe((arrayQuestions) => {
      const selectedQuestion = arrayQuestions.questions.find((quest) => quest.questionId === question.id);
      if (!isNil(selectedQuestion)) {
        question.id = selectedQuestion.id;
        question.questionId = selectedQuestion.questionId;
        callback(question);
      }
    });
  }

  private _manageQuestion(question: Question) {
    this._draftsService.manageQuestionDraft(this.module.id, [question]).subscribe(() => {
      this.addQuestions.emit(this.getObjectEmit());
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  private async _removeQuestion(questionId: string): Promise<void> {
    const draftQuestion = this.questions.find(question => question.questionId === questionId);
    const draftQuestionId = draftQuestion && !isNil(draftQuestion.questionId) ? draftQuestion.id : questionId;
    this._deleteQuestion(draftQuestionId);
  }

  private _deleteQuestion(questionId: string) {
    this._draftsService.removeQuestionDraft(questionId).subscribe(() => {
      this.loadQuestions();
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  public loadQuestions(): Subscription {
    if (this.module.subjects) {
      const subject = this.module.subjects[this.tabGroup.selectedIndex || 0];
      if (subject && subject.id) {

        return this._draftsService.getPagedQuestionsDraft(
          subject.id, this.module.id, this._currentPage, 10, this.searchValue, this.module.isDraft
        ).subscribe((response) => {
          this.questions = response.data.questions;
          this.questionsCount = response.data.itemsCount;
          localStorage.setItem(LocalStorageService.key.questionModuleReload, 'false');

          this.loadQuestionsEvent.emit(this.getObjectEmit());
        });
      }
    }
  }
  private _setSearchSubscription() {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.searchValue = searchValue;
      this.loadQuestions();
    });
  }
}
