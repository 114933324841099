<div class="header">
  <div class="level">
    <ng-container *ngIf="!moduleProgress?.isExemption && hasFeatureLevels">
      <ng-container *ngIf="module?.hasQuestions else headerWithoutBdq">
        <p>
          Duração Total<br />
          <span>{{ getFormattedHour() }} h</span>
        </p>
        <div class="current-level">
          <p>
            Progresso:
            <span *ngIf="levels">{{ levels[moduleProgress?.level - 1] }}</span>
          </p>
          <app-progress-bar [currentProgress]="moduleProgress?.progress * 100" [roundedBorder]="true" [height]="14">
          </app-progress-bar>
        </div>
        <div>
          <p>Níveis Conquistados</p>
          <div class="badges">
            <div class="badge" [style.color]="getLevelColor(level.id, moduleProgress?.level)"
              *ngFor="let level of levelList">
              <mat-icon [svgIcon]="getLevelIcon(level.id, moduleProgress?.level)"
                [ngClass]="getLevelIcon(level.id, moduleProgress?.level)">
              </mat-icon>
              <span>{{ levels[level.id] }}</span>
            </div>
          </div>
        </div>
        <p class="grade" (click)="openGradeDialog()">
          <span>Nota
            <mat-icon title="Há respostas após a data de corte" *ngIf="hasAnswerAfterCutOffDate">report_problem
            </mat-icon>
            <mat-icon title="Nota sendo apurada"
              *ngIf="(moduleProgress?.level === 4 || moduleProgress?.progress === 1) && module?.grade === null">
              report_problem</mat-icon>
          </span>
          <span *ngIf="module?.grade !== null">{{ module?.grade | number: '1.2-2' }}</span>
          <span *ngIf="module?.grade === null">-</span>
        </p>
        <!-- </ng-container> -->
      </ng-container>
    </ng-container>

  </div>
  <div class="updated-time" *ngIf="updatedAtText">
    {{updatedAtText}}
  </div>
  <div class="description">
    <p>
      {{ module?.excerpt }}
    </p>
    <button class="btn-test" (click)="getMaterial()" *ngIf="module?.supportMaterials?.length > 0">Material
      suporte</button>
  </div>

</div>

<ng-template #headerWithoutBdq>
  <div class="current-level">
    <p>
      Progresso:
    </p>
    <app-progress-bar [currentProgress]="moduleProgress?.level === 4? 100 : moduleProgress?.progress * 100"
      [roundedBorder]="true" [height]="14"></app-progress-bar>
  </div>
</ng-template>
