import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Program } from 'src/app/settings/programs/Models/program.model';
import { ProgramsService } from 'src/app/settings/_services/programs.service';

@Component({
  selector: 'app-programs-select',
  templateUrl: './programs-select.component.html',
  styleUrls: ['./programs-select.component.scss']
})
export class ProgramsSelectComponent implements OnInit {

  public programs: Program[] = [];
  public isLoadingItems: boolean = false;

  @Input() public value: Program | Program[];
  @Input() public multiple: boolean;
  @Output() public selectionChange = new EventEmitter<Program[] | Program>();
  constructor(
    private _programService: ProgramsService
  ) { }

  ngOnInit() {
    this.loadPrograms();
  }

  private loadPrograms(searchTerm: string = '') {
    this.isLoadingItems = true;
    this._programService.getPagedPrograms(1, 4, searchTerm).subscribe(response => {
      this.programs = response.data.programs;
      this.isLoadingItems = false;
    });
  }
  public handleSearchItem(searchTerm: string) {
    this.loadPrograms(searchTerm);
  }
  public handleSelectionItemChange(event: Program[]) {
    this.selectionChange.emit(event);
  }
}
