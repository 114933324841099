import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { AutomaticSync } from 'src/app/models/automatic-sync.model';
import { AutomaticSyncService } from '../_services/automatic-sync.service';

@Component({
  selector: 'app-automatic-sync',
  templateUrl: './automatic-sync.component.html',
  styleUrls: ['./automatic-sync.component.scss']
})
export class AutomaticSyncComponent extends NotificationClass implements OnInit {

  public automaticSyncs: AutomaticSync[] = [];
  public readonly pageSize: number = 10;
  private _automaticSyncsPage: number = 1;
  public _itemsCount: number = 0;
  public syncRunning: boolean = false;

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: MatDialog,
    private _automaticSyncService: AutomaticSyncService) {
    super(_snackBar);
  }

  ngOnInit() {
    localStorage.removeItem('editingAutomaticSync');
    this._loadAutomaticSyncs();
  }

  public createNewAutomaticSync(): void {
    this._router.navigate(['configuracoes/associacao-automatica/conteudo']);
  }

  public manageAutomaticSync(automaticSync: AutomaticSync): void {
    localStorage.setItem('editingAutomaticSync', JSON.stringify(automaticSync));
    this._router.navigate(['configuracoes/associacao-automatica/conteudo']);
  }

  public deleteAutomaticSync(automaticSync: AutomaticSync) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '1000px',
      data: { message: 'Tem certeza que deseja remover esta associação automatica?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._automaticSyncService.deleteAutomaticSync(automaticSync.id).subscribe(() => {
          this._loadAutomaticSyncs();
          this.notify('Associação automatica deletada com sucesso');
        });
      }
    });
  }

  private _loadAutomaticSyncs(): void {
    this._automaticSyncService.getAutomaticSyncs(this._automaticSyncsPage, this.pageSize).subscribe((response) => {
      this.automaticSyncs = response.data.automaticSyncs;
      this._itemsCount = response.data.itemsCount;
    });
  }

  public goToPage(page: number): void {
    if (page !== this._automaticSyncsPage) {
      this._automaticSyncsPage = page;
      this._loadAutomaticSyncs();
    }
  }

  public runSync() {
    if (this.syncRunning) {
      this.notify('O processo de associação ainda esta em execução');
    } else {
      this.notify('O processo de associação esta em execução');
      this.syncRunning = true;
      this._automaticSyncService.automaticSyncs().subscribe(() => {
      this.notify('Processo de associação executado com sucesso');
        this.syncRunning = false;
      }, () => {
        this.notify('Ocorreu um erro no processo de associação');
        this.syncRunning = false;
      });
    }
  }
}
