import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { isEmpty, isNil } from 'lodash';
import { concatMap, filter, map, mapTo } from 'rxjs/operators';
import { BackendResponse } from '@tg4/http-infrastructure';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { ProgramFullPreview, ProgramItemPreview } from 'src/app/models/previews/program-full.interface';
import { ProgramsService } from '../_services/programs.service';

@Component({
  selector: 'app-program-team',
  templateUrl: './program-team.component.html',
  styleUrls: ['./program-team.component.scss']
})
export class ProgramTeamComponent implements OnInit {

  public program: ProgramFullPreview;
  private _programId: string;
  public selectedTeam: any;
  public countTeamStudents: number;
  public programTeamOptions: any;

  @Input() performance: boolean = true;
  public userId: string;
  public user: User;
  public isManagerView: boolean = false;
  public userRole: string;


  constructor(
    protected _snackBar: MatSnackBar,
    private _programsService: ProgramsService,
    private _sharedService: SharedService,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _usersService: SettingsUsersService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this._programId = this._activatedRoute.snapshot.paramMap.get('programId');
    this._loadProgramTeamOptions();
    this.userId = this._authService.getLoggedUserId();
    this.isManagerView = this._authService.isSeenHowManager();
    this.userRole = this._authService.getLoggedUserRole();
  }

  changeTeam(teamId: string) {
    this.selectedTeam = this.programTeamOptions.find(team => team.id === teamId);
    this._loadProgramByTeamId(this.selectedTeam.id);
  }

  _loadProgramTeamOptions() {
    this._programsService.getProgramTeamOptions(this._programId).subscribe((response) => {
      this.programTeamOptions = response.data;
      if (this.programTeamOptions.length > 0) {
        this.selectedTeam = this.programTeamOptions[0];
        this._loadProgramByTeamId(this.selectedTeam.id);
      }
    });
  }

  _loadProgramByTeamId(teamId: string) {
    this._programsService.getProgramByTeamId(this._programId, teamId).subscribe((response) => {
      this.program = response.data;
      this.countTeamStudents = this.program.team.map(p => p.countTeamStudents).reduce((prev, next) => prev + next);
    });
  }

  goToPageTrack(trackId: string) {
    const extras: NavigationExtras = { queryParams: { programId: this._programId } };
    if (this.userRole === 'Student')
      this._router.navigate(['trilha-de-curso/' + trackId], extras);
    else
      this._router.navigate(['configuracoes/trilha-de-curso/' + trackId]);
  }

}
