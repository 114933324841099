import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectorComponent } from './sector.component';
import { NewSectorComponent } from './new-sector/new-sector.component';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { MatIconModule } from '@angular/material/icon';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { GenerateValuationTestCheckOptionsModule } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SectorComponent, NewSectorComponent],
  imports: [
    CommonModule,
    NavigationBackModule,
    PaginationModule,
    MatIconModule,
    MaterialComponentsModule,
    GenerateValuationTestCheckOptionsModule,
    FormsModule,
  ]
})
export class SectorModule { }
