import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'asPhone' })
export class PhonePipe implements PipeTransform {
    transform(number) {
        if (!number) {
            return '';
        }
        number = number.replace(' ', '');
        number = number.replace('-', '');
        let dd: number;
        let tell: string;
        switch (number.length) {
            case 10: // 21######## ->(21) ####-####
                dd = number.slice(0, 2);
                tell = `(${dd}) ${number.slice(2, 6)}-${number.slice(6)}`;
                break;

            case 11: // 021######## ->(21) ####-####
                dd = number.slice(1, 2);
                tell = `(${dd}) ${number.slice(2, 6)}-${number.slice(6)}`;
                break;

            case 12: // 219######## ->(21) 9####-####
                dd = number.slice(0, 2);
                tell = tell = `(${dd}) ${number.slice(2, 7)}-${number.slice(7)}`;
                break;
            case 13: // 0219######## ->(21) 9####-####
                dd = number.slice(1, 2);
                tell = tell = `(${dd}) ${number.slice(2, 7)}-${number.slice(7)}`;
                break;
            default:
                return number;
        }

        return tell;
    }

}
