import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { ReportFilter } from 'src/app/pages/report/filters/shared/report-filter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import {
  CategoryFilter, CategoryFilterOption, Parent,
} from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { CategoryFilterEnum } from 'src/app/models/enums/category-filter.enum';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
  GenerateValuationTestAccordionComponent
} from './generate-valuation-test-accordion/generate-valuation-test-accordion.component';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { Data, Type } from './model/generate-valuation-test';
import { type } from 'os';

@Component({
  templateUrl: './generete-valuation-test-questions-dialog.component.html',
  styleUrls: ['./generete-valuation-test-questions-dialog.component.scss']
})
export class GenereteValuationTestQuestionsDialogComponent extends ReportFilter implements OnInit {

  @ViewChild('accordionComponent')
  public accordionComponent: GenerateValuationTestAccordionComponent;

  public index: number = 1;
  public questions: any;
  public get getData() { return this.accordionComponent.getData; }
  public list: Data[] = [];
  public trackId: string;
  public moduleId: string;
  public filters = {
    modules: null,
    track: null,
    target: 1,
    factor: 1,
    checked: true,
    listTracks: [],
    listModules: [],
  };

  public track: any;

  constructor(
    private _settingsValuationTestsService: SettingsValuationTestsService,
    private _contentTracksService: ContentTracksService,
    private _contentModulesService: ContentModulesService,
    public dialogRef: MatDialogRef<GenereteValuationTestQuestionsDialogComponent>,
    protected _reportSertivce: ReportsService,
    protected _snackBar: MatSnackBar) {
    super(_reportSertivce, _snackBar);
  }

  ngOnInit() {
    setTimeout(() => { this._setCategory(); }, 0);
  }

  private _setCategory() {

    forkJoin({
      track: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Tracks }),
      module: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Modules }),
    }).subscribe(response => {

      this._setFilter('Trilha', CategoryFilterEnum.Tracks, 'track.id',
        response.track.data.itemsCount, response.track.data.items, 'title', true, true);

      this._setFilter('Módulos', CategoryFilterEnum.Modules, 'module.id',
        response.module.data.itemsCount, response.module.data.items, 'title', true, true);

      this.filters.track = this.categoryFilters[0];
      this.filters.modules = this.categoryFilters[1];
    });

  }
  private _setFilter(
    filterName: string,
    category: number,
    filterColumn: string,
    itemsCount: number,
    collection: any[],
    prop: string = 'name',
    demandsId: boolean = false,
    isSingleOption: boolean = true,
    parent?: Parent,
  ) {

    const newFilter: CategoryFilter = {
      name: filterName,
      category: category,
      filterColumn: filterColumn,
      page: 1,
      itemsCount: itemsCount,
      maxLength: collection.length,
      options: [],
      selectedOptions: [],
      isAlternate: prop === 'title',
      demandsId: demandsId,
      isSingleOption,
      parent,
    };


    for (let index = 0; index < collection.length; index++) {
      newFilter.options.push({
        id: collection[index].id,
        description: collection[index][prop],
        checked: false
      });
    }

    this.categoryFilters.push(newFilter);
    this.selectedFilters.push({ columnName: filterColumn, contentNames: [] });
  }

  public moveStep(value): void {
  if (value > 0 && this.index === 2) {
    if (this.filters.target === Type.Track) {
      if (this.filters.track.selectedOptions.length === 0) {
        this.notify('Selecione uma trilha');
        return;
      }
    } else {
      if (this.filters.modules.selectedOptions.length === 0) {
        this.notify('Selecione um módulo');
        return;
      }
    }
  }
    this.index += value;
}

  public fnUpdateList(arr: any, list: Data[]) {
    for (let index = 0; index < arr.length; index++) {
      list.push({
        id: arr[index].moduleId || null,
        subjectId: arr[index].id || null,
        description: arr[index].title,
        easy: 1,
        medium: 1,
        hard: 1,
        checked: false
      });
    }
  }

  public updateList() {
    if (this.filters.target === 1) {
      this._contentModulesService.getModuleById(this.filters.modules.selectedOptions[0].id).subscribe(response => {
        const subjects = response.data.subjects;
        subjects.forEach(subject => subject['moduleId'] = this.moduleId);
        this.fnUpdateList(subjects, this.list);
      });
    } else if (this.filters.target === 2) {
      this._contentTracksService.getTrackById(this.filters.track.selectedOptions[0].id).subscribe(response => {
        this.fnUpdateList(response.data.modulesConfiguration, this.list);
      });
    }
  }

  public handleUpdateFilters(filter: CategoryFilter, option: CategoryFilterOption) {
    this.updateFilterNoOptions(filter, option);
    if (this.filters.track.selectedOptions.length > 0 || this.filters.modules.selectedOptions.length > 0) {
      this.updateList();
    }
  }

  public removeFilters(filter: CategoryFilter, option: CategoryFilterOption) {
    this.removeFilterNoOptions(filter, option);

    this.filters.listModules = [];
    this.filters.listTracks = [];
    this.list = [];
  }

  // public async updateFiltes() {
  //   if (this.filters.target === 1) {
  //     this.filters.listModules[0] = this.filters.modules.selectedOptions[0];
  //     if (this.filters.checked) { // if checked true utilize todas as trilhas
  //       this.filters.listTracks = this.list;
  //     } else {
  //       this.filters.listTracks = this.list.filter(track => track.checked);
  //     }
  //   } else {
  //     this.filters.listTracks = this.filters.track.selectedOptions[0];
  //     if (this.filters.checked) { // if checked true utilize todos os módulos
  //       this.filters.listModules = this.list;
  //     } else {
  //       this.filters.listModules = this.list.filter(module => module.checked);
  //     }
  //   }
  // }

  // private _addOrUpdate(list: Data[], item: any): Data[] {
  //   const data = list;
  //   const dataIndex = data.findIndex(value => value.id === item.id);
  //   if (dataIndex !== -1) {
  //     data[dataIndex].checked = item.checked;
  //   } else {
  //     data.push(item);
  //   }

  //   return data;
  // }

  // public selectedData(event: Data) {
  //   if (this.filters.target === 1) {
  //     this.filters.listTracks = this._addOrUpdate(this.filters.listTracks, event).filter(value => value.checked);
  //     this.filters.listModules[0] = this.filters.modules.selectedOptions[0];
  //   } else {
  //     this.filters.listModules = this._addOrUpdate(this.filters.listModules, event).filter(value => value.checked);
  //     this.filters.listTracks = this.filters.track.selectedOptions[0];
  //   }
  // }
  public async generateQuestions() {

    this._settingsValuationTestsService.getRandomicQuestions(this.getData).subscribe(response => {

      const module = this.filters.modules.selectedOptions[0];
      const track = this.filters.track.selectedOptions[0];
      if (track) track.listModules = this.list;

      this.dialogRef.close({
        questions: response.data.questions,
        module: module,
        track: track,
        target: this.filters.target
      });
    });
  }

  public handleFiltersTarget(value: number) {
    this.filters.target = value;
    this.list = [];
    this.filters.checked = true;
  }
}
