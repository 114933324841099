import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LoginComponent } from './login.component';
import { MaterialComponentsModule } from '../../shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUtilModule } from '@tg4/ng-util';
import { BtgLoginModule } from './btg-login/btg-login.module';
import { BtgLoginComponent } from './btg-login/btg-login.component';
import { NgxMaskModule } from 'ngx-mask';
import { EmailConfirmationComponent } from './email-validation/email-confirmation.component';
import { RouterModule } from '@angular/router';
import { WarningDialogComponent } from 'src/app/pages/login/warning-dialog/waning-dialog.component';
import { ForgotPassDialogComponent } from './forgot-pass-dialog/forgot-pass-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';

@NgModule({
    declarations: [
        LoginComponent,
        EmailConfirmationComponent,
        WarningDialogComponent,
        ForgotPassDialogComponent
    ],
    imports: [
        BrowserModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgUtilModule,
        NgxMaskModule,
        BtgLoginModule,
        RouterModule,
        MatIconModule,
        MarkdownToHtmlModule
    ],
    exports: [
        LoginComponent,
        EmailConfirmationComponent
    ]
})
export class LoginModule { }
