import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from 'src/app/models/module.model';
import { Subject } from 'src/app/models/subject.model';

@Component({
  selector: 'app-question-valuation-explanation',
  templateUrl: './question-valuation-explanation.component.html',
  styleUrls: ['./question-valuation-explanation.component.scss'],
})
export class QuestionValuationExplanationComponent {

  @Input() readonly subject: Subject;
  @Output() next = new EventEmitter<any>();
  @Output() goBack = new EventEmitter();

  constructor() { }


}
