import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { ValuationTest, ValuationTestAnswer, ValuationTestResponse } from 'src/app/models/valuation-test.interface';
import { SuggestedProduct } from 'src/app/models/previews/suggested-product.interface';
import { DownloadService } from 'src/app/shared/services/download.service';
import { Data } from '../valuation-tests/manage-valuation-test/generete-valuation-test-questions-dialog/model/generate-valuation-test';
import { ValuationTestTypeEnum } from 'src/app/models/enums/valuation-test-type-enum';

@Injectable()
export class SettingsValuationTestsService {

  constructor(private _httpService: BackendService,
    private _downloadService: DownloadService) { }

  public getValuationTests(
    page: number,
    pageSize: number,
    searchValue: string = '',
    createdSince: Date = null,
    modules: Array<string> = [],
    tracks: Array<string> = [],
    sort: string = '',
    sortAscending: boolean = false,
  ): Observable<any> {
    return this._httpService.post('getValuationTests',
      {
        'page': page,
        'pageSize': pageSize,
        'filters': {
          'term': searchValue,
          'createdSince': createdSince,
          'tracks': tracks,
          'modules': modules,
          'sortBy': sort,
          'isSortAscending': sortAscending
        }
      });
  }

  public getValuationTestById(testId: string): Observable<any> {
    return this._httpService.get('getValuationTestById', [], [
      { 'name': 'testId', 'value': testId }
    ]);
  }

  public getValuationUser(testId: string): Observable<any> {
    return this._httpService.get('getValuationsUser', [], [
      { 'name': 'testId', 'value': testId }
    ]);
  }

  public manageValuationTest(test: ValuationTest): Observable<any> {
    delete test.questions;
    return this._httpService.post('manageValuationTest', test);
  }

  public downloadFileFromServe(url: string): Observable<any> {
    return this._downloadService._downloadFile('downloadFromS3', {
      'url': url
    });

  }

  public getValuationTestResponses(testId: string,
    page: number = 1, pageSize: number = 10
  ): Observable<any> {
    return this._httpService.post('getValuationTestResponses', {
      'page': page,
      'pageSize': pageSize,
      'testId': testId
    });
  }

  public getValuationTestResponseById(responseId: string): Observable<any> {
    return this._httpService.get('getValuationTestResponseById', [], [
      { 'name': 'responseId', 'value': responseId }
    ]);
  }

  public suggestProducts(responseId: string, products: Array<SuggestedProduct>): Observable<any> {
    return this._httpService.post('suggestProducts', {
      'responseId': responseId,
      'products': products
    });
  }

  public getSuggestedProducts(): Observable<any> {
    return this._httpService.get('getSuggestedProducts');
  }

  public suggestValuationTest(usersIds: Array<string>, testId: string): Observable<any> {
    return this._httpService.post('suggestValuationTest', {
      'usersIds': usersIds,
      'testId': testId
    });
  }

  public saveValuationTestResponse(test: ValuationTest): Observable<any> {
    return this._httpService.post('saveValuationTestResponse', {
      'id': test.id,
      'testQuestions': test.testQuestions
    });
  }

  public saveDraftValuationTestResponse(test: ValuationTest): Observable<any> {
    return this._httpService.post('saveDraftValuationTestResponse', {
      'id': test.id,
      'testQuestions': test.testQuestions
    });
  }

  public getDraftValuationTestResponses(testId: string): Observable<any> {
    return this._httpService.get('getDraftValuationTestResponses', [], [
      { 'name': 'testId', 'value': testId }
    ]);
  }

  public deleteValuationTest(testId: string): Observable<any> {
    return this._httpService.delete('deleteValuationTest', [
      { 'name': 'id', 'value': testId }
    ]);
  }

  public getAllValuationTestResponses(testId: string): Observable<any> {
    return this._httpService.get('getAllValuationTestResponses', [], [
      { 'name': 'testId', 'value': testId }
    ]);
  }

  public gradeValuationTestAnswers(responseId: string, answers: Array<ValuationTestAnswer>): Observable<any> {
    return this._httpService.put('gradeValuationTestAnswers', {
      'id': responseId,
      'answers': answers
    });
  }

  public getModuleValuationTests(moduleId: string, trackId: string): Observable<any> {
    return this._httpService.get('getModuleValuationTests', [], [
      { 'name': 'moduleId', 'value': moduleId },
      { 'name': 'trackId', 'value': trackId }
    ]);
  }

  public getTrackValuationTests(trackId: string): Observable<any> {
    return this._httpService.get('getTrackValuationTests', [], [
      { 'name': 'trackId', 'value': trackId }
    ]);
  }

  public getSchedulesValuationTests(scheduleId: string, eventId: string): Observable<any> {
    return this._httpService.get('getScheduleValuationTests', [], [
      { 'name': 'scheduleId', 'value': scheduleId },
      { 'name': 'eventId', 'value': eventId }
    ]);
  }

  public checkTestAnswered(testId: string):
    Observable<BackendResponse<{ id: string, title: string, type: ValuationTestTypeEnum, answered: boolean }>> {
    return this._httpService.get('checkTestAnswered', [], [
      { 'name': 'testId', 'value': testId },
    ]);
  }

  public getRandomicQuestions(filters: any[]): Observable<any> {
    return this._httpService.post('getRandomicQuestions', {
      filters: filters
    });
  }

  public getPendingValuationsUser(): Observable<any> {
    return this._httpService.get('getPendingValuationsUser', [], []);
  }

  public GetAllValuationTestResponsesByGroup(groupId: string): Observable<BackendResponse<any>> {
    return this._httpService.get('getAllValuationTestResponsesByGroup', [], [
      { 'name': 'id', 'value': groupId },
    ]);
  }

  public GetAllPreview(): Observable<BackendResponse<Array<{ id: string, title: string }>>> {
    return this._httpService.get('getValuationPreview', [], []);
  }
}
