import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { StudyPlanDependencies } from 'src/app/models/study-plan-dependencies.model';
import { StudyPlanItem } from 'src/app/models/study-plan-items.model';
import { StudyPlanRequeriments } from 'src/app/models/study-plan-requeriments.models';
import { StudyPlan } from 'src/app/models/study-plan.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { Item } from 'src/app/shared/components/collapsed-list/item/models/item.model';
import { ComponentCanDeactivate } from 'src/app/shared/guards/pending-changes.guard';
import { StudyPlanService } from '../../_services/study-plan.service';
import { ConnectDialogComponent } from '../dialogs/connect-dialog/connect-dialog.component';
import { Econnect } from '../dialogs/connect-dialog/Enum/connect.enum';
import { ISelectedItem } from '../dialogs/connect-dialog/interface/selected-item.interface';
import { ImportDialogComponent } from '../dialogs/import-dialog/import-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';

@Component({
  selector: 'app-new-group-study-plan',
  templateUrl: './new-group-study-plan.component.html',
  styleUrls: ['./new-group-study-plan.component.scss']
})
export class NewGroupStudyPlanComponent extends NotificationClass implements OnInit, ComponentCanDeactivate {

  constructor(private _dialog: MatDialog,
    private _studyPlanService: StudyPlanService,
    private _activatedRoute: ActivatedRoute,
    protected _snackBar: MatSnackBar

  ) {
    super(_snackBar);
  }

  private selectedCourseSubject: Subject<{ type: Econnect, data: ISelectedItem }> = new Subject();

  private studtPlanId: string;
  private isEdited: string;

  public studyPlansItems: ISelectedItem[] = [];
  public mandatoryTraining: ISelectedItem[] = [];
  public institutionalTraining: ISelectedItem[] = [];

  public studyPlan: StudyPlan = new StudyPlan();

  public readonly pageSize: number = 8;
  public page: number = 1;
  public itensCount: number = 0;
  public bdItensCount: number = 0;

  private selectedUsers: ISelectedItem[] = [];
  public pagedUsers: ISelectedItem[] = [];
  public cachedUser: ISelectedItem[] = [];

  public hasModification: boolean = false;
  public searchTerm: string;


  public EnumConnect = Econnect;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasModification;
  }

  ngOnInit() {
    this.studtPlanId = this._activatedRoute.snapshot.params['studyPlanId'];
    if (!isNil(this.studtPlanId)) {
      this.loadStudyPlan(this.studtPlanId, this.page, this.pageSize);
    }

    this.selectedCourseSubject.subscribe(value => {
      this.hasModification = true;
      if (value.type === Econnect.course) {
        this.studyPlansItems = [...this.studyPlansItems, value.data];
      } else if (value.type === Econnect.users) {
        if (value.data instanceof Array) {
          this.selectedUsers = [...this.selectedUsers, ...value.data];
          this.paginateUsers(value.data);
        } else {
          this.selectedUsers.push(value.data);
          this.paginateUsers([value.data]);
        }

      } else if (value.type === Econnect.mandatoryTraining) {
        this.mandatoryTraining = [...this.mandatoryTraining, value.data];
      } else {
        this.institutionalTraining = [...this.institutionalTraining, value.data];
      }
    });
  }



  public openDialog(type: Econnect) {
    const dialogRef = this._dialog.open(ConnectDialogComponent, {
      width: '800px',
      height: '80vh',
      data: {
        type: type,
        subject: this.selectedCourseSubject,
        notIn: type === Econnect.users
          ? this.selectedUsers.map(item => item.id)
          : this.studyPlansItems.map(item => item.id)
            .concat(this.mandatoryTraining.map(item => item.id))
            .concat(this.institutionalTraining.map(item => item.id)),
        studtPlanId: this.studtPlanId
      }
    });
  }

  public confirmRemoveAllUsers(): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que desvincular todos os usuários?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result)
        this.RemoveAllUsers();
    });
  }

  public openDialogImport() {
    const dialogRef = this._dialog.open(ImportDialogComponent, {
      width: '800px',
      restoreFocus: false,
      closeOnNavigation: false,
      data: {
        type: Econnect.users,
        subject: this.selectedCourseSubject,
      }
    });
  }

  public paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  public setStudyPlanName(name: string) {
    this.hasModification = true;
    this.studyPlan.name = name;
  }

  public save() {
    this.studyPlan = this.getFormatedStudyPlan();

    if (this.studyPlan.name === '' || isNil(this.studyPlan.name)) {
      return this.notify('Preencha o nome do plano de estudos');
    }

    if (isNil(this.studyPlan.items) || this.studyPlan.items.length === 0) {
      return this.notify('Vincule ao menos um item ao seu plano de estudos');
    }
    if (!isNil(this.studtPlanId)) {
      this._studyPlanService.UpdateStudyPlan(this.studyPlan).subscribe(() => {
        if (this.selectedUsers.length > 0) {
          this._studyPlanService.AddStudents(this.studtPlanId, this.selectedUsers.map(user => user.id)).subscribe(() => {
          }, er => {
            this.notify(er.error.errors[0]);
          });
        }
        this.hasModification = false;
        this.notify('Plano de estudo salvo com sucesso');
      });
    } else {
      this._studyPlanService.CreateStudyPlan(this.studyPlan).subscribe(response => {
        this.studtPlanId = response.data.id;
        this.studyPlan.id = this.studtPlanId;
        this.hasModification = false;
        this.notify('Plano de estudo salvo com sucesso');
      }, er => this.notify(er.error.errors[0]));
    }

  }

  public getFormatedStudyPlan() {
    this.studyPlan.users = this.selectedUsers.map(user => user.id);
    this.studyPlan.items = this.GetStudentItem(this.studyPlansItems);
    this.studyPlan.mandatoryTraining = this.GetStudentItem(this.mandatoryTraining);
    this.studyPlan.institutionalTraining = this.GetStudentItem(this.institutionalTraining);

    this.studyPlan.requirements = this.studyPlansItems.map((studyItems, index) => {
      const dependenciesIndex = index - 1;
      const dependencies = dependenciesIndex < 0
        ? new Array<StudyPlanDependencies>()
        : [new StudyPlanDependencies({
          id: this.studyPlansItems[dependenciesIndex].id,
          type: this.studyPlansItems[dependenciesIndex].type
        })];
      return new StudyPlanRequeriments({ id: studyItems.id, type: studyItems.type, dependencies: dependencies });
    });

    return this.studyPlan;
  }

  private GetStudentItem(data: ISelectedItem[]) {
    return data.map((studyItems, index) => (
      new StudyPlanItem({ id: studyItems.id, type: studyItems.type, order: index }))
    );
  }


  public loadStudyPlan(studyPlanId: string, page: number, pageSize: number) {
    this._studyPlanService.GetStudyPlanById(studyPlanId, page, pageSize).subscribe(response => {
      this.studyPlan = response.data;
      const usersResponse = response.data.pageUsers.users.map(value => (
        { id: value.id, title: value.name, isBdvalue: true } as ISelectedItem));
      this.bdItensCount = response.data.pageUsers.itemsCount;
      this.paginateUsers(usersResponse, true);
      this.studyPlansItems = this.getIselectedItem(this.studyPlan.items);
      this.mandatoryTraining = this.getIselectedItem(this.studyPlan.mandatoryTraining);
      this.institutionalTraining = this.getIselectedItem(this.studyPlan.institutionalTraining);
    });
  }

  private getIselectedItem(data: StudyPlanItem[]) {
    return data.map(value => ({
      id: value.id,
      title: value.name,
      type: value.type
    } as ISelectedItem));
  }
  public loadUsersStudyPlan(studyPlanId: string, page: number, pageSize: number) {
    this._studyPlanService.GetUsersByStudyPlanId(studyPlanId, page, pageSize, this.searchTerm).subscribe(response => {
      const usersResponse = response.data.users.map(value => (
        { id: value.id, title: value.name, isBdvalue: true } as ISelectedItem)
      );
      this.bdItensCount = response.data.itemsCount;
      this.paginateUsers(usersResponse, true);
    });
  }

  public goToPage($event) {
    this.page = $event;
    this.paginateUsers();
  }

  public RemoveAllUsers(){
    this._studyPlanService.DeleteAllStudyPlanUsers(this.studtPlanId).subscribe(() => {
      this.pagedUsers=[];
      this.cachedUser=[];
      this.selectedUsers=[];
      this.notify('Todos os alunos foram desvinculados com sucesso!');

    });
  }

  public paginateUsers(data: ISelectedItem[] = [], dataIsLoad: boolean = false) {

    if (this.searchTerm !== '' && !isNil(this.searchTerm)) {
      this.cachedUser = this.selectedUsers.filter(user => user.title.includes(this.searchTerm));
    }


    if (data.length > 0)
      this.cachedUser = [...this.cachedUser, ...data];
    this.itensCount = this.bdItensCount + this.selectedUsers.length;
    const numberOfPages = Math.ceil(this.bdItensCount / this.pageSize);


    if (this.cachedUser.length >= this.page * this.pageSize || this.cachedUser.length === this.itensCount) {
      this.pagedUsers = this.paginate(this.cachedUser, this.pageSize, this.page);
    } else if (this.page - Math.ceil(this.selectedUsers.length / this.pageSize) <= numberOfPages && !dataIsLoad) {
      const valueBdPage = Math.ceil(this.cachedUser.filter(value => value.isBdvalue).length / this.pageSize);
      const pageBd = valueBdPage + 1;
      this.loadUsersStudyPlan(this.studtPlanId, pageBd, this.pageSize);
    } else {
      this.pagedUsers = this.paginate(this.cachedUser, this.pageSize, this.page);
    }

  }

  public remove(item: ISelectedItem, type: Econnect) {
    this.hasModification = true;
    switch (type) {
      case Econnect.course:
        this.studyPlansItems = this.studyPlansItems.filter(value => value.id !== item.id);
        break;
      case Econnect.mandatoryTraining:
        this.mandatoryTraining = this.mandatoryTraining.filter(value => value.id !== item.id);
        break;
      case Econnect.institutionalTraining:
        this.institutionalTraining = this.institutionalTraining.filter(value => value.id !== item.id);
        break;
      default:
        break;
    }

  }

  public search(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.loadUsersStudyPlan(this.studtPlanId, this.page, this.pageSize);
  }
  public removeUser(userId: string){
    this._studyPlanService.DeleteStudyPlanUser(this.studtPlanId, userId).subscribe(() => {
      this.pagedUsers = this.pagedUsers.filter(e=> e.id!=userId);
      this.cachedUser = this.cachedUser.filter(e=> e.id!=userId);
      this.selectedUsers = this.selectedUsers.filter(e=> e.id!=userId);
    });
  }
}
