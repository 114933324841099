<div class="header">
  <div>
    <h2>{{isMock ? 'SIMULADO' : 'AVALIAÇÃO'}}</h2>
    <p class="title">
      {{subTitle}}
    </p>
  </div>

  <div>
    <p class="level" *ngIf="levels && !isPracticing">
      Nível<br>
      <span *ngIf="levels">{{levels[question?.level]}}</span>
    </p>
  </div>
  <button class="btn-test" *ngIf="isMock || showButton" appDebounceClick (debounceClick)="finish()">
    Finalizar {{isMock ? 'Simulado' : 'Avaliação'}}
  </button>
</div>
