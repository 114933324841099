<div class="filters">
  <mat-accordion>

    <mat-expansion-panel hideToggle="true" disabled="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat-title" style="font-size: 14px;">
            FILTROS POR CATEGORIA
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            BUSCAR POR NOME
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-list-search [noPadding]="true" (triggerSearch)="triggerUserSearch($event)"></app-list-search>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            Data de Criação
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <input matInput [(ngModel)]="createdSince" [matDatepickerFilter]="maxDateFilter" [matDatepicker]="sincePicker"
          (ngModelChange)="createSince(createdSince)" placeholder="Desde de..." />
        <mat-datepicker-toggle matSuffix [for]="sincePicker"></mat-datepicker-toggle>
        <mat-datepicker #sincePicker></mat-datepicker>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngFor="let filter of filters">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            {{ filter.name }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="options">
        <app-list-search *ngIf="!filter.hideSearch" placeholder="filtrar resultados" [noPadding]="true"
          (triggerSearch)="triggerSearch($event, filter)"></app-list-search>
        <ng-container *ngFor="let option of filter.options; let i = index">
          <mat-checkbox [(ngModel)]="option.checked" (ngModelChange)="setFilters(filter, option)">
            <input class="custom-input" *ngIf="option.customInput" type="text"
              (change)="inputChange($event.target.value, option)" />
            <ng-container *ngIf="!option.customInput">{{option.description}}</ng-container>
          </mat-checkbox>
        </ng-container>
        <p *ngIf="filter.maxLength < filter.itemsCount" (click)="moreItens(filter)"
          style="cursor: pointer; text-decoration: underline;">
          Carregar mais...
        </p>
        <p *ngIf="filter.haveCustomInput" (click)="moreInputs(filter)"
          style="cursor: pointer; text-decoration: underline;">
          Adicionar mais...
        </p>
      </div>
    </mat-expansion-panel>


  </mat-accordion>
</div>
