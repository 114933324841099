<div class="step">
  <header>
    <div class="title-wrapper">
      <span class="title">Itens</span>
      <span class="subtitle">Adicione os itens pesquisando por nome. Segure e arraste para ordená-los</span>
    </div>
  </header>
  <div class="wrapper">
    <div class="action-wrapper">
      <div class="search">
        <acd-select
          [data]="items"
          placeholder="Busque por um item para adiciona-lo a lista abaixo"
          filterPlaceholder="Busque por um item para adiciona-lo a lista abaixo"
          [disabledSelectedItems]="true"
          [isLoading]="isLoadingItems"
          [multiple]="true"
          [displayMember]="'title'"
          [showFilter]="true"
          (selectionChange)="handleSelectionItemChange($event)"
          (openedChange)="handleSelectOpenedChange($event)"
          (filterChange)="handleSearchItem($event)">
        </acd-select>
      </div>
    </div>
    <form [formGroup]="form" autocomplete="off" ngNativeValidate>
      <input autocomplete="false" name="hidden" type="text" style="display:none;">

      <mat-accordion cdkDropList formArrayName="programItems" class="item-list" (cdkDropListDropped)="handleDroppedItem($event)"
        *ngIf="selectedItems?.length; else EmptySelection">

        <mat-expansion-panel cdkDrag class="panel-item" cdkDragLockAxis="y"
          *ngFor="let trackControl of form.get('programItems')?.controls; let index = index"
          [cdkDragData]="selectedItems[index]"
          [formGroupName]="index"
          [disabled]="hasClickedOnExpansionPanel"
          [expanded]="index === synchronizationConfiguration?.length - 1 && !automaticSync.id"
          (click)="hasClickedOnExpansionPanel=false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="icon-wrapper">
                <div class="icon-up"></div>
                <div class="icon-down"></div>
              </div>
              <div class="order">{{selectedItems[index]?.order}}</div>
              <span class="separator"></span>
              <div class="title-detail">({{programItemType[selectedItems[index]?.type]}})</div>
              <div class="title">{{selectedItems[index]?.title}}</div>
            </mat-panel-title>
            <mat-panel-description>
              <button class="btn-accordion trash" mat-icon-button title="Excluir item" (click)="removeItem(index,selectedItems[index])">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container *ngIf="hasConfigurations(index); else EmptyProgramItemConfiguration">
            <ng-container *ngIf="selectedItems[index]?.type === EProgramItem.Track; else moduleStandalone">
              <div formArrayName="modulesConfiguration"
                *ngFor="let control of trackControl?.get('modulesConfiguration')?.controls; let formGroupName = index">
                <ng-container [ngTemplateOutletContext]="{$implicit: { index:index,control:control,formGroupName:formGroupName}}"
                  [ngTemplateOutlet]="ProgramItems">
                </ng-container>
              </div>
              <div formArrayName="eventsConfiguration"
                *ngFor="let control of trackControl?.get('eventsConfiguration')?.controls; let formGroupName = index">
                <ng-container [ngTemplateOutletContext]="{$implicit: { index:index,control:control,formGroupName:formGroupName}}"
                  [ngTemplateOutlet]="ProgramItems">
                </ng-container>
              </div>
              <div formArrayName="valuationConfiguration"
                *ngFor="let control of trackControl?.get('valuationConfiguration')?.controls; let formGroupName = index">
                <ng-container [ngTemplateOutletContext]="{$implicit: { index:index,control:control,formGroupName:formGroupName}}"
                  [ngTemplateOutlet]="ProgramItems">
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #moduleStandalone>
            <!-- COLOCAR AQUI O CÓDIGO PARA MÓDULO AVULSO -->
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </form>



    <ng-template #EmptySelection>
      <p class="empty-list">Não há itens selecionados para exibir</p>
    </ng-template>

    <ng-template #EmptyProgramItemConfiguration>
      <div class="item-wrapper empty-list">
        <span>Essa trilha não contém módulos ou eventos, não há configurações para exibir.</span>
      </div>
    </ng-template>

    <ng-template #ProgramItems let-config>
      <div [formGroup]="config.control">
        <div class="item-wrapper">
          <span class="item-title">
            <div class="title-detail">({{programItemType[config.control.get('type').value]}})</div>
            <span class="title">{{config.control.get('title').value}}</span>
          </span>

          <mat-form-field>
            <mat-label>Data de abertura</mat-label>
            <input matInput
              [ngxMatDatetimePicker]="pickerOpenDate"
              (focus)="pickerOpenDate.open()"
              formControlName="openDate" />
            <mat-datepicker-toggle matSuffix [for]="pickerOpenDate"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerOpenDate
              [showSeconds]="true"
              [stepHour]="1"
              [enableMeridian]="false"
              [stepMinute]="1"
              [stepSecond]="1">
            </ngx-mat-datetime-picker>
            <mat-error>É necessário informar uma data</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Data de corte</mat-label>
            <input matInput
              [ngxMatDatetimePicker]="pickerCutOffDate"
              (focus)="pickerCutOffDate.open()"
              formControlName="cutOffDate" />
            <mat-datepicker-toggle matSuffix [for]="pickerCutOffDate"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerCutOffDate
              [showSeconds]="true"
              [stepHour]="1"
              [enableMeridian]="false"
              [stepMinute]="1"
              [stepSecond]="1">
            </ngx-mat-datetime-picker>
            <mat-error>É necessário informar uma data</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Data de fim de disponibilidade</mat-label>
            <input matInput
              [ngxMatDatetimePicker]="pickerValuationDate"
              (focus)="pickerValuationDate.open()"
              formControlName="valuationDate" />
            <mat-datepicker-toggle matSuffix [for]="pickerValuationDate"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerValuationDate
              [showSeconds]="true"
              [stepHour]="1"
              [enableMeridian]="false"
              [stepMinute]="1"
              [stepSecond]="1">
            </ngx-mat-datetime-picker>
            <mat-error>É necessário informar uma data</mat-error>
          </mat-form-field>

          <div class="choice-box" (click)="toggleModuleAvailability(config.index, config.formGroupName, config.control)">
            <div class="checkbox" [ngClass]="{ 'selected': config.control.get('alwaysAvailable').value }"></div>
            <span class="checkbox-description">Sempre disponível</span>
          </div>
        </div>
      </div>
    </ng-template>

  </div>

</div>
