import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from 'src/app/shared/services/upload.service';
import { ValuationTestQuestion, ValuationTestQuestionOption, ValuationTestQuestionTypeEnum } from 'src/app/models/valuation-test.interface';
import Editor from '@toast-ui/editor';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ValuationTestTypeGeneredEnum } from 'src/app/models/enums/valuation-test-type-enum';
import { environment } from 'src/environments/environment';
import { isNil, isEmpty } from 'lodash';

@Component({
  selector: 'app-manage-valuation-question-dialog',
  templateUrl: './manage-valuation-question.dialog.html',
  styleUrls: ['./manage-valuation-question.dialog.scss'],
})
export class ManageValuationQuestionDialogComponent extends NotificationClass implements OnInit {
  public editor: Editor;
  public questionType = ValuationTestQuestionTypeEnum;
  public testGenerated = ValuationTestTypeGeneredEnum;
  public commentEditor: Editor;
  public hasComment: boolean = environment.features.questionComment;
  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ManageValuationQuestionDialogComponent>,
    private _uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) public data: ValuationTestQuestion
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._configureEditor();
  }

  public addAnswer(): void {
    this.data.options = this.data.options || [];
    this.data.options.push(new ValuationTestQuestionOption());
  }

  public removeAnswer(index: number): void {
    this.data.options.splice(index, 1);
  }

  public getAnswerIconSrc(answer: ValuationTestQuestionOption): string {
    return answer.correct ? './assets/img/right-answer-full.png' : './assets/img/wrong-answer-full.png';
  }

  public toggleAnswer(answer: ValuationTestQuestionOption): void {
    answer.correct = !answer.correct;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this._checkQuestion()) {
      this.data.title = this.editor.getMarkdown();
      if (this.commentEditor) this.data.comment = this.commentEditor.getMarkdown();
      this.dialogRef.close(this.data);
    }
  }

  private _configureEditor(): void {
    this.editor = new Editor({
      el: document.querySelector('#htmlEditor'),
      initialEditType: 'markdown',
      previewStyle: 'vertical',
      height: '200px',
      hooks: {
        addImageBlobHook: (file: File, callback) => {
          this._uploadService.uploadFile(file).subscribe((uploadedImageURL) => {
            callback(uploadedImageURL, file.name);
          });
        },
      },
    });
    this.editor.off('scroll');
    this.editor.setMarkdown(this.data.title);

    if (this.hasComment) {
      setTimeout(() => {
        this.commentEditor = new Editor({
          el: document.querySelector('#htmlCommentEditor'),
          initialEditType: 'markdown',
          previewStyle: 'vertical',
          height: '200px',
          hooks: {
            addImageBlobHook: (file: File, callback) => {
              this._uploadService.uploadFile(file).subscribe(uploadedImageURL => {
                callback(uploadedImageURL, file.name);
              });
            }
          }
        });
        this.commentEditor.off('scroll');
        this.commentEditor.setMarkdown(
          this.data.comment
        );
      }, 100);
    }
  }

  private _checkQuestion(): boolean {

    if (this.data.type === ValuationTestQuestionTypeEnum.MultipleChoice) {
      if (isNil(this.data.options) || this.data.options.length < 2) {
        this.notify('Deve haver pelo menos duas respostas, uma correta e uma errada.');
        return false;
      } else if (!this.data.options.some(o => o.correct)) {
        this.notify('Deve haver pelo menos uma resposta correta');
        return false;
      } else if (this.data.options.filter(o => o.correct).length > 1) {
        this.notify('Só pode haver uma resposta correta');
        return false;
      } else if (this.data.options.some(op => isEmpty(op.text))) {
        this.notify('Todas respostas devem ser preenchidas');
        return false;
      }
  }

    if (!this.data.percentage && this.data.type !== ValuationTestQuestionTypeEnum.NPS) {
      this.notify('O peso da questão deve ser informado');
      return false;
    } else if (this.data.percentage < 1 || this.data.percentage > 100) {
      this.notify('O peso da questão deve ser maior que 0 e menor ou igual a 100');
      return false;
    }

    return true;
  }
}
