<div class="inner-content" >
  <h2>{{ test?.title }}</h2>

  <div class="content" *ngIf="test" >
    <div class="question" *ngFor="let question of test.testQuestions" >
      <p class="title">
        {{ question.title }}
      </p>

      <ng-container *ngIf="question.options" >
        <mat-radio-group [(ngModel)]="question.answer" >
          <mat-radio-button *ngFor="let option of question.options" [value]="option.text" >
            {{ option.text }}
          </mat-radio-button>
        </mat-radio-group>
      </ng-container >

      <div class="text-box" *ngIf="!question.options" >
        <textarea class="text-area"
          [(ngModel)]="question.answer"
          placeholder="Escreva aqui sua Resposta"
        ></textarea>
      </div>
    </div>

    <button class="btn-test"
      (click)="sendResponse()" >
      Enviar
    </button>
  </div>
</div>
