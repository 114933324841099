<h3>{{ content.title }}</h3>
<h4>{{ content.subjectTitle }}</h4>

<div class="content">
  <div class="info">
    <p class="label">
      Arquivo *
    </p>
    <div class="file-upload">

      <a *ngIf="formGroup?.get('fileName')?.value;else emptyFile" class="link" target="_blank" [href]="downloadLink">
        {{formGroup.get('fileName').value }}
      </a>
      <ng-template #emptyFile>
        <p class="link">Escolha um arquivo</p>
      </ng-template>

      <ng-container></ng-container>

      <button class=" btn-test primary" (click)="openFileUpload()">
        {{ formGroup.get('fileName').value ? 'Editar' : 'Upload' }}
      </button>
      <input #file accept="application/pdf" class="input-file" value="" type="file" id="inputFile" hidden
        (change)="setDocumentFile($event, formGroup)" />
    </div>

    <mat-form-field>
      <textarea matInput placeholder="Sobre (máx. MMM caracteres)"
        [(ngModel)]="content.excerpt" required></textarea>
    </mat-form-field>

    <p class="label">
      Referêcias Úteis
    </p>
    <div class="references">
      <input matInput placeholder="URL"
        *ngFor="let ref of content.referenceUrls; let index = index; trackBy: referencesTrackBy"
        [(ngModel)]="content.referenceUrls[index]" required />
    </div>
    <button class="btn-test" (click)="addReference()">
      + Adicionar Referência
    </button>
  </div>

  <div class="concepts">
    <p class="title">Instruções</p>
    <p>
      Para configurar o conteúdo em PDF basta fazer upload do conteúdo no campo acima na esquerda
      e configurar os conceitos colocando a página condizente com o conceito para ativar a navegação por conceitos
    </p>

    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Conceitos</th>
            <th>Página</th>
          </tr>
        </thead>
        <tbody>
          <tr [ngClass]="{concept: true,disabled: !formGroup.get('fileName').value}" *ngFor="let concept of content.concepts">
            <td>
              <div class="checkbox"
                [ngClass]="{ 'checked': concept.checked }"
                (click)="setConcept(concept)"></div>
              {{ concept.name }}
            </td>
            <td class="td-input">
              <input #inputPage matInput 
                type="number" 
                [ngClass] = "{'error': showError(concept)}"
                placeholder="página"
                *ngIf="concept.checked" required
                [disabled]="!formGroup.get('fileName').value"
                min="1"
                [max]="formGroup.get('numPages').value"
                [(ngModel)]="concept['positions'][0]"
                />
                <label *ngIf="showError(concept)">Número de páginas invalido</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<div class="actions">
  <button class="btn-outline" (click)="dismiss()">
    Cancelar
  </button>
  <button 
    [disabled]="isLoading || !formGroup.get('downloadLink').value"
    class="btn-test" 
    (click)="save()">
    Salvar
  </button>
</div>
