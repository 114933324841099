import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit {

  public tooltipActive = false;
  public tooltipText: string = '';

  @Input() headerText: string = '';
  @Input() text: string = '';
  @Input() footerText: string = '';
  @Input() postion: string = 'above';
  @Input() set showTooltip(active: boolean) {
    if (active) {
      this.manualTooltip.show();
      setTimeout(() => {
        this.tooltipActive = true;
      }, 1000);
    }
  }

  @Output() closeTooltip: EventEmitter<any> = new EventEmitter();

  @ViewChild('tooltip', { static: true }) manualTooltip: MatTooltip;
  @HostListener('document:click', ['$event.target']) onMouseEnter() {
    if (this.tooltipActive) {
      this.tooltipActive = false;
      this.closeTooltip.emit();
    }
  }


  constructor() {}

  ngOnInit() {
    this._setTooltipText();
  }

  private _setTooltipText() {
    this.tooltipText = '';
    if (this.headerText) {
      this.tooltipText += this.headerText;
      if (this.text) {
        this.tooltipText += '\n\n';
      }
    }
    if (this.text) {
      this.tooltipText += this.text;
      if (this.footerText) {
        this.tooltipText += '\n\n';
      }
    }
    if (this.footerText) {
      this.tooltipText += this.footerText;
    }
  }
}

