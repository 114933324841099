import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CockpitGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const user = this._authService.getLoggedUser();

    if (user.role === 'Student') {
        this._router.navigate(['empenho-desempenho/students/track/' + user.user_id],
        { queryParams : {trackId: null}});
        return of(true);
    }
    return of(true);
  }
}
