import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { clone, isNil } from 'lodash';
import { Module } from 'src/app/models/module.model';
import { Question } from 'src/app/models/question.model';
import { SettingsModulesDraftsService } from 'src/app/settings/_services/modules-drafts.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { SimpleDialog } from '../simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-concepts-register',
  template: `
    <div class="concepts" >
      <img src="./assets/img/search-gray.png" />
      <input type="text" [placeholder]="placeholder" (keyup.enter)="addConcept($event)" />

      <div class="tags" >
        <div class="concept" *ngFor="let concept of concepts; let index = index" >
          {{ concept }}
          <img src="./assets/img/close.png" (click)="removeConcept(index, concept)" />
        </div>
      </div>
    </div>`,
  styleUrls: ['./concepts-register.component.scss']
})
export class ConceptsRegisterComponent {

  @Input() placeholder?: string = 'Conceitos';
  private _concepts: Array<string>;
  @Input()
  set concepts(value) {
    if (!isNil(value))
      this._concepts = clone(value);
  }
  get concepts() {
    return this._concepts;
  }
  @Output() updateConcepts = new EventEmitter<Array<string>>();
  @Input() subjectId?: any;
  @Input() module: Module;
  constructor(
    private _draftsService: SettingsModulesDraftsService,
    private _dialog: MatDialog,
  ) { }

  private readonly _allQuestions = 0;
  public addConcept(event): void {
    const concepts = event.target.value;
    if (concepts.length !== 0) {
      const conceptsArr = concepts.split(',');
      conceptsArr.forEach((concept: string) => {
        if (concept && concept.trim() !== '') {
          this.concepts = this.concepts || [];
          this.concepts.push(concept.trimStart().trimEnd());
          event.target.value = '';
        }
        this.updateConcepts.emit(this.concepts);
      });
    }
  }

  public removeConcept(index: number): void {
    const excludedConcept = this.concepts[index];
    if (this.subjectId) {
      this._draftsService.getPagedQuestionsDraft(
        this.subjectId, this.module.id, 1, this._allQuestions, '', this.module.isDraft
      ).subscribe((response) => {
        const questions = response.data.questions as Question[];
        const affectedIssues = questions.filter(question => question.concepts.filter(concept => concept === excludedConcept).length);

        if (affectedIssues.length === 0) {
          this.concepts.splice(index, 1);
          this.updateConcepts.emit(this.concepts);
        }
        else {
        let text = '';
        affectedIssues.forEach((question, i) => text += (i + 1) + ')' + question.text + '\n\n');
        const message = `<strong>Ao excluir este conceito você estará afentando as Seguintes questões do BDQ:</strong>\n\n
        ${text}
        \nDeseja continuar?
        `;
        const dialogRef = this._dialog.open(SimpleDialogComponent, {
          width: '460px',
          hasBackdrop: true,
          closeOnNavigation: false,
          data: {
            message: message,
            positiveTextAction: 'SIM',
            negativeTextAction: 'NÃO',
          } as SimpleDialog
        });

        if (dialogRef) {
          dialogRef.afterClosed()
            .subscribe(confirm => {
              if (confirm) {
                this.concepts.splice(index, 1);
                this.updateConcepts.emit(this.concepts);
                this.updateQuestions(affectedIssues, excludedConcept);
              }
            });
        }
      }
      });
    } else {
      this.concepts.splice(index, 1);
      this.updateConcepts.emit(this.concepts);
    }
  }


  public updateQuestions(questions: Question[], excludedConcept: string): void {
    questions.forEach(question => {
      const i = question.concepts.findIndex(concept => concept === excludedConcept);
      question.concepts.splice(i, 1);
      question.answers.forEach(answers => {
        const index = answers.concepts.findIndex(concept => concept.concept === excludedConcept);
        answers.concepts.splice(index, 1);
      });
    });

    this._draftsService.manageQuestionDraft(this.module.id, questions).subscribe(_ => {
      localStorage.setItem(LocalStorageService.key.questionModuleReload, 'true');
    }, error => {
      localStorage.setItem(LocalStorageService.key.questionModuleReload, 'false');
    });
  }
}
