import { Component, OnInit } from '@angular/core';
import { NotificationClass } from '../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { SettingsUsersService } from '../_services/users.service';
import Editor from '@toast-ui/editor';
import { MarkdownToHtmlPipe } from 'markdown-to-html-pipe';
import { Track } from 'src/app/models/track.model';
import { SettingsTracksService } from '../_services/tracks.service';
import { isNil, uniqBy } from 'lodash';
import { ImportDialogComponent } from 'src/app/shared/components/import-users-dialog/import-users-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersSelectedComponent } from './users-selected.dialog.ts/users-selected/users-selected.component';
import { UploadService } from 'src/app/shared/services/upload.service';
import { ProgramsService } from '../_services/programs.service';
import { TeamsService } from '../teams/services/teams.service';
import { Program } from '../programs/Models/program.model';
import { Team } from '../teams/Models/team.model';

@Component({
  selector: 'app-settings-custom-email',
  templateUrl: './custom-email.component.html',
  styleUrls: ['./custom-email.component.scss']
})
export class SettingsCustomEmailComponent extends NotificationClass implements OnInit {

  public formGroup: FormGroup;
  public editor: Editor;
  public users: Array<any> = [];
  public selectedUsers: Array<any> = [];
  public showAllSelectedUsers: boolean = false;
  public usersTracks: Array<Track> = [];
  public programs: Array<Program> = [];
  public teams: Array<Team> = [];
  public tracks: Array<Track> = [];
  public enrolledCourses: Array<{ id: string, title: string, type: 'track' | 'team' | 'program' }> = [];

  public groupUsers: any[] = [];
  public groupId: string;
  public isNewGroup: boolean = true;

  //Filters
  public createAtStartDate: Date = null;
  public createAtEndDate: Date = null;
  public selectedEnrolledCourses = [];
  public selectedNotAccessEnrolledCourses = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _usersService: SettingsUsersService,
    private _settingsTracksService: SettingsTracksService,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _uploadService: UploadService,
    private _programsService: ProgramsService,
    private _teamsService: TeamsService
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    const usersStr = localStorage.getItem('SelectedUsers');
    this.groupId = this._activatedRoute.snapshot.paramMap.get('groupId');
    this.isNewGroup = isNil(this.groupId);

    this.editor = new Editor({
      el: document.querySelector('#htmlEditor'),
      initialEditType: 'wysiwyg',
      hideModeSwitch: true,
      previewStyle: 'vertical',
      height: '400px',
      hooks: {
        addImageBlobHook: (file: File, callback) => {
          this._uploadService.uploadFile(file, null, null, "email").subscribe(uploadedImageURL => {
            callback(uploadedImageURL, file.name);
          });
        }
      }
    });

    if (!this.isNewGroup) {
      this._usersService.GetCustomEmailById(this.groupId).subscribe(response => {
        const customEmail = response.data;
        this.formGroup = this._createForm(customEmail.title);
        this.editor.setHTML(customEmail.text);
        this.groupUsers = customEmail.usersIds.map(value => ({ id: value }));
        this.createAtStartDate = response.data.createAtStartDate;
        this.createAtEndDate = response.data.createAtEndDate;
        this.selectedEnrolledCourses = response.data.enrolledCourses || [];
        this.selectedNotAccessEnrolledCourses = response.data.notAccessEnrolledCourses || [];
      });
    } else {
      this.formGroup = this._createForm();
    }

    if (usersStr && usersStr.trim() !== '')
      this.selectedUsers = JSON.parse(usersStr);


  }

  public triggerUserSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '')
      this._loadUsers(searchValue);
  }

  public sendMail(): void {
    if ((!this.selectedUsers || this.selectedUsers.length === 0)
      && (!this.groupUsers && this.groupUsers.length === 0)) {
      this.notify('É necessario selecionar pelo menos um usuário');
      return;
    }
    const formInfo = this.formGroup.getRawValue();
    const markdown = this.editor.getHTML();
    const usersIds = this.selectedUsers.map(x => x.id).concat(this.groupUsers.map(x => x.id));
    (this.isNewGroup
      ? this._usersService.sendCustomEmail(
        usersIds,
        formInfo.title,
        markdown,
        this.createAtStartDate,
        this.createAtEndDate,
        this.selectedEnrolledCourses,
        this.selectedNotAccessEnrolledCourses)
      : this._usersService.UpdateCustomEmail({
        groupId: this.groupId,
        text: markdown,
        title: formInfo.title,
        usersIds: usersIds,
        startDate: this.createAtStartDate,
        endDate: this.createAtEndDate,
        enrolledCourses: this.selectedEnrolledCourses,
        notAccessEnrolledCourses: this.selectedNotAccessEnrolledCourses
      }
      )).subscribe(() => {
        this.notify('Mensagem enviada com sucesso!');
        this._router.navigate([`configuracoes/emails-enviados`]);
      }, () => {
        this.notify('Ocorreu um erro, por favor tente novamente maist arde');
      });
  }



  private _createForm(title: string = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(!isNil(title) ? title : null, [Validators.required])
    });
  }

  private _loadUsers(searchValue: string = ''): void {
    this._usersService
      .getPagedFilteredUsersList({ searchValue, page: 1, pageSize: 8 })
      .subscribe(response => {
        this.users = response.data.userItems;
      });
  }

  private _loadTracks(searchValue: string = '', type: 'tracks' | 'usersTracks'): void {
    this._settingsTracksService.getPagedFilteredTracksList(1, 3, searchValue).subscribe((response) => {
      if (type === 'tracks') {
        this.tracks = response.data.tracks;
        this.setSearch();
      } else {
        this.usersTracks = response.data.tracks;
      }

    });
  }

  private _loadProgram(searchValue: string = '') {
    this._programsService.getPagedPrograms(1, 3, searchValue).subscribe(response => {
      this.programs = response.data.programs;
      this.setSearch();
    });
  }

  private _loadTeam(searchValue: string = '',) {
    this._teamsService.getPagedTeams(1, 3, searchValue).subscribe(response => {
      this.teams = response.data.teams;
      this.setSearch();
    });
  }

  public triggerTrackSearch(searchTerm: string, type: 'tracks' | 'usersTracks') {
    this._loadTracks(searchTerm, type);
    this._loadProgram(searchTerm);
    this._loadTeam(searchTerm);
  }

  public setSearch() {

    const convertItems = (dataSoruce: any, property: string, type: 'track' | 'team' | 'program') => dataSoruce.map(value => ({
      id: value.id,
      title: value[property],
      type: type
    }));

    this.enrolledCourses = [
      ...convertItems(this.tracks, 'title', 'track'),
      ...convertItems(this.programs, 'title', 'program'),
      ...convertItems(this.teams, 'name', 'team')
    ];
  }

  public loadUsersByFilter() {
    this._usersService.GetUsersToSendEmail(
      {
        createAtStartDate: this.createAtStartDate,
        createAtEndDate: this.createAtEndDate,
        enrolledCoursesIds: this.selectedEnrolledCourses.map(track => track.id),
        notAccessEnrolledCoursesIds: this.selectedNotAccessEnrolledCourses.map(track => track.id)
      }).subscribe(response => {
        this.groupUsers = response.data;
      });
  }

  public setEnrolledTracks(item: any) {
    this.selectedEnrolledCourses = item;
    this.loadUsersByFilter();
  }

  public setNotEnrolledTracks(tracks: any) {
    this.selectedNotAccessEnrolledCourses = tracks;
    this.loadUsersByFilter();
  }

  public setManuallyUsers(users) {
    this._setUsers(users);
  }

  public openDialogImport() {
    const dialogRef = this._dialog.open(ImportDialogComponent, {
      width: '800px',
      restoreFocus: false,
      closeOnNavigation: false,
    });

    dialogRef.afterClosed().subscribe(users => {
      if (users && users.length > 0) {
        this._setUsers(users);
      }
    });
  }

  private _setUsers(users) {
    let students = [...this.selectedUsers, ...users];
    students = uniqBy(students, "id");
    this.selectedUsers = students;
  }

  public SeeUsers() {
    const dialogRef = this._dialog.open(UsersSelectedComponent, {
      width: '800px',
      restoreFocus: false,
      closeOnNavigation: false,
      data: this.selectedUsers.map(x => x.id).concat(this.groupUsers.map(x => x.id))
    });

  }
}
