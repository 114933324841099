import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AutomaticSync } from 'src/app/models/automatic-sync.model';

@Component({
  selector: 'app-automatic-sync-card',
  template: `
    <div class="test-card" >
      <div class="preview">
        <h3>
          {{ automaticSync.title }}
        </h3>
        <p class="content" >
          Criado em:
          {{ automaticSync.createdAt ? (automaticSync.createdAt | date : 'dd/MM/yyyy') : '--' }}
        </p>
      </div>

      <div class="edit" >
        <img src="./assets/img/edit.png" (click)="editAutomaticSync.emit(automaticSync)" />
        <img style="margin-top: 27px;" src="./assets/img/trash.png" (click)="deleteAutomaticSync.emit(automaticSync)" />
      </div>
    </div>
  `,
  styleUrls: ['./automatic-sync-card.component.scss']
})
export class AutomaticSyncCardComponent {

  @Input() automaticSync: AutomaticSync;
  @Output() editAutomaticSync = new EventEmitter<AutomaticSync>();
  @Output() deleteAutomaticSync = new EventEmitter<AutomaticSync>();


  constructor() {
  }
}
