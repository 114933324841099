<div class="main">
  <mat-form-field>
    <mat-label>Setores</mat-label>
    <mat-select [(ngModel)]="selectedSector" [compareWith]="compareFn" [value]="selectedSector"
      (selectionChange)="handleSelectSector($event.value)" (closed)="emitChangeValues('sector')">
      <mat-option *ngFor="let sector of sectors" [value]="sector">
        {{sector.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Segmentos</mat-label>
    <mat-select [(value)]="selectedSegment" [compareWith]="compareFn" [disabled]="segments.length === 0 " (closed)="emitChangeValues('segment')">
      <mat-option *ngFor="let segment of segments" [value]="segment">
        {{segment.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
