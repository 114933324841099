import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ContentModulesService } from '../../_services/modules.service';

@Component({
  selector: 'app-module-opinion-dialog',
  templateUrl: './module-opinion-dialog.component.html',
  styleUrls: ['./module-opinion-dialog.component.scss']
})
export class ModuleOpinionDialogComponent extends NotificationClass {

  public satisfaction: number = -1;
  public text: string = '';
  public type: number = 1;
  private moduleId: string;

  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModuleOpinionDialogComponent>,
    private _modulesService: ContentModulesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(_snackBar);
    this.moduleId = data.moduleId;
  }

  public changeSatisfaction(value: number) {
    if (this.satisfaction === value) {
      this.satisfaction = -1;
    } else {
      this.satisfaction = value;
    }
  }

  public save() {
    if (this.satisfaction === -1) {
      this.notify('É necessário definir como foi sua experiência');
      return;
    }
    this._modulesService.setModuleOpinion(this.moduleId, this.satisfaction, this.text, this.type).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
}
