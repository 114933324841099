import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EStudyPlanItem } from 'src/app/models/enums/study-plan-item.enum';
import { EStudyPlan } from 'src/app/models/enums/study-plan.enum';
import { StudyPlan } from 'src/app/models/study-plan.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { StudyPlanService } from '../_services/study-plan.service';
import { CreateDialogComponent } from './dialogs/create-dialog/create-dialog.component';

@Component({
  selector: 'app-study-plan',
  templateUrl: './study-plan.component.html',
  styleUrls: ['./study-plan.component.scss']
})
export class StudyPlanComponent implements OnInit {

  constructor(private _router: Router,
    private _studyPlanService: StudyPlanService,
    @Inject(MatDialog) private _dialog: MatDialog) { }

  public studyPlan: StudyPlan[];

  public readonly pageSize: number = 8;
  public page: number = 1;
  public itemsCount: number = 0;

  public EnumStudyPlan = EStudyPlan;

  ngOnInit() {
    this.loadStudyPlan();
  }

  public search(searchValue) {
    this.loadStudyPlan(searchValue);
  }

  public createStudyPlan() {
    const dialogRef = this._dialog.open(CreateDialogComponent, {
      width: '571px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.type === EStudyPlan.team) {
          this._router.navigate(['configuracoes/plano-de-estudo/group']);
        } else {
          this._router.navigate(['configuracoes/plano-de-estudo/individual'],
            { queryParams: { userId: result.userId, name: result.name } });
        }
      }

    });
  }

  public edit(item: StudyPlan) {
    const urlNavigate = `configuracoes/plano-de-estudo/${item.type === EStudyPlan.individual ? 'individual' : 'group'}/${item.id}`;
    this._router.navigate([urlNavigate]);
  }

  public delete(item: StudyPlan) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: { message: 'Tem certeza que excluir este plano de estudo ?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._studyPlanService.DeleteStudyPlan(item.id).subscribe(value => {
          this.loadStudyPlan();
        });
      }

    });
  }
  public loadStudyPlan(serchValue = '') {
    this._studyPlanService.GetAllStudyPlan(this.page, this.pageSize, serchValue).subscribe(response => {
      this.studyPlan = response.data.studyPlans.map(value => {
        value.items = value.items.map(itemsValue => {
          itemsValue.type = itemsValue.type.id;
          return itemsValue;
        });
        value.moduleCount = value.items.filter(item => item.typr === EStudyPlanItem.module).length;
        value.trackCount = value.items.filter(item => item.type === EStudyPlanItem.track).length;
        return value;
      });
      this.itemsCount = response.data.itemsCount;
    });

  }

  public goToPage(page) {
    this.page = page;
    this.loadStudyPlan();
  }
}
