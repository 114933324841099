import { Component, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { TrackModule } from 'src/app/models/track-module.model';
import { TrackEvent } from 'src/app/models/track-event.model';
import {
  TrackOverview,
  TrackStudentOverview,
} from 'src/app/models/track-overview.interface';
import { Router } from '@angular/router';

import { ExcelService } from 'src/app/shared/services/excel.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { UserService } from 'src/app/pages/_services/user.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TrackPreview } from 'src/app/models/previews/track.interface';
import { environment } from 'src/environments/environment';
import { ValuationTest } from 'src/app/models/valuation-test.interface';

import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { Level } from 'src/app/shared/classes/level';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import {
  ValuationTestTypeEnum,
  ValuationTestModuleTypeEnum,
} from 'src/app/models/enums/valuation-test-type-enum';

import * as moment from 'moment/moment';
import { ERole } from 'src/app/models/enums/role.enum';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { QuestionsAndAnswersDialogComponent } from 'src/app/shared/dialogs/questions-and-answers/questions-and-answers.dialog';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { take } from 'rxjs/operators';
import { isNil } from 'lodash';
import { EventScheduleTypeEnum } from 'src/app/models/enums/event-schedule.enum';

@Component({
  selector: 'app-track-overview-path',
  templateUrl: './track-path.component.html',
  styleUrls: ['./track-path.component.scss'],
})
export class TrackOverviewPathComponent
  extends NotificationClass
  implements OnInit {

  // Features
  public hasFeatureCertificate = environment.features.certificate;
  public canGenerateCertificates = environment.features.canGenerateCertificate;
  public hasFeatureCalender = environment.features.calender;
  public hasFeatureMyPerformace = environment.features.myPerformance;
  public scheduleEventType;

  // Components Props
  private _track: TrackOverview | TrackStudentOverview;
  @Input()
  public get track(): TrackOverview | TrackStudentOverview {
    return this._track;
  }
  public set track(value: TrackOverview | TrackStudentOverview) {
    if (isNil(this._track) && !isNil(value)) {
      this._track = value;
      const totalLenght = this.track.modulesConfiguration.length + this.track.eventsConfiguration.length;
      this.indexModulesAndEvents = Math.round(totalLenght / 2);
      this.setStyles();
    }
  }

  @Input() completedPercentage: number;
  @Input() programId: number;
  @Input() readonly lateStudents: boolean = false;
  @Input() readonly hasSubprogress: boolean = false;
  @Input() readonly allowOverview: boolean = false;
  @Input() readonly trackTestsResearch: any[] = [];
  @Input() readonly trackTestsFree: any[] = [];
  @Input() readonly trackCourseWork: any[] = [];
  @Input() set setTrackTestsOrdered(tests: any[]) {
    this.trackTestsOrdered = tests;
    tests.forEach(() => {
      this.trackTestsOrderedExpanded.push(false);
    });
  }
  public trackTestsOrdered: any[] = [];
  public lockTrack: boolean = false;
  public trackTestsResearchExpanded: boolean = false;
  public trackTestsFreeExpanded: boolean = false;
  public trackCourseWorkExpanded: boolean = false;
  public trackTestsOrderedExpanded: boolean[] = [];
  public canCalculateGrade: boolean;
  public isAdmin: boolean;
  public LoggedUserisAdmin: boolean;
  public isInstructor: boolean;
  public indexModulesAndEvents: number;
  public replacement = environment.replecement;

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _excelService: ExcelService,
    private _tracksService: SettingsTracksService,
    private _authService: AuthService,
    private _dialogService: MatDialog,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _valuationTestService: SettingsValuationTestsService,
    private _cdr: ChangeDetectorRef
  ) {
    super(_snackBar);
    this.LoggedUserisAdmin = this._authService.LoggedUserisAdmin();
    this.isAdmin = this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources);
    this.isInstructor = this._authService.isSeenHowInstructorEvent() || this._authService.isSeenHowInstructorModule();
    this.canCalculateGrade = this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources);
    this.scheduleEventType = EventScheduleTypeEnum;
  }

  ngOnInit(): void {
    this.hasFeatureCertificate = environment.features.certificate;
    this.canCalculateGrade = this.isAdmin && this.allowOverview;
  }

  public getTrackCards(): Array<TrackModule | TrackEvent> {
    if (!this.track) return [];

    this.track.eventsConfiguration
      ? this.track.eventsConfiguration.forEach((e) => (e.isEvent = true))
      : (this.track.eventsConfiguration = []);

    let modulesEvents = [
      ...this.track.modulesConfiguration,
      ...this.track.eventsConfiguration,
    ];
    modulesEvents = modulesEvents.sort((a, b) => a.order - b.order);
    return modulesEvents;
  }

  private _getLevelName = (level: number) => Level.getLevelName(level);

  public canLockTrack() {
    return (
      this.trackTestsFree.length === 0 ||
      this.trackTestsFree.every((x) => x.answered === true) ||
      this.allowOverview
    );
  }

  public exportStatus() {
    const track = this.track as TrackOverview;

    if (track) {
      const excelModule = [];
      if (this.track.modulesConfiguration) {
        for (let idx = 0; idx < this.track.modulesConfiguration.length; idx++) {
          const mod = this.track.modulesConfiguration[idx];
          if (mod.students) {
            for (let useridx = 0; useridx < mod.students.length; useridx++) {
              const user = mod.students[useridx];

              excelModule.push({
                module: mod.title,
                user: user.userName,
                cutOffDate: moment(user.cutOffDate).format(
                  'DD/MM/YYYY HH:mm:ss'
                ),
                points: user.points,
                level: this._getLevelName(user.level),
                acheivedGoal: user.acheivedGoal,
                group: user.businessGroup,
                unit: user.businessUnit,
                segment: user.segment,
              });
            }
          }
          if (track.topPerformants) {
            if (mod.students.length !== track.topPerformants.length) {
              for (
                let topIndex = 0;
                topIndex < track.topPerformants.length;
                topIndex++
              ) {
                const findIndex = mod.students.findIndex(
                  (x) => x.userId === track.topPerformants[topIndex].id
                );
                if (findIndex === -1) {
                  const user = track.topPerformants[topIndex];
                  excelModule.push({
                    module: mod.title,
                    user: user.name,
                    cutOffDate: undefined,
                    points: 0,
                    level: 'Não Iniciado',
                    acheivedGoal: false,
                    group: user.businessGroup,
                    unit: user.businessUnit,
                    segment: user.segment,
                  });
                }
              }
            }
          }
        }
      }
      if (track.topPerformants) {
        const notStarted = track.topPerformants.filter((x) => x.points === 0);
        if (notStarted) {
          for (let i = 0; i < notStarted.length; i++) {
            const user = notStarted[i];
            excelModule.push({
              module: 'Não iniciado',
              user: user.name,
              cutOffDate: undefined,
              points: user.points,
              level: '-',
              acheivedGoal: false,
              group: user.businessGroup,
              unit: user.businessUnit,
              segment: user.segment,
            });
          }
        }
      }
      this._excelService.exportAsExcelFile(excelModule, 'Status');
    }
  }

  public calculateGrade() {

    const ref = this._dialogService.open(SimpleDialogComponent, {
      autoFocus: true,
      data: {
        message: `Esse processo pode demorar alguns minutos.\nVocê será notificado após o termino.\nDeseja continuar?`,
        negativeTextAction: 'Cancelar',
        positiveTextAction: 'Continuar',
        title: 'Aviso',
      } as SimpleDialog,
    });

    ref.afterClosed().subscribe((hasAccepted) => {
      if (hasAccepted) {
        this._tracksService
          .calculateGrade(this.track.id, true)
          .subscribe((response) => {
            this.notify('Notas atualizadas');
          });
      }
    });
  }

  public canExport(): boolean {
    const trackPreview = localStorage.getItem(
      LocalStorageService.key.trackResponsible
    );
    if (trackPreview) {
      const track: TrackPreview = JSON.parse(trackPreview);
      if (track && track.subordinate) {
        return !track.subordinate && this.allowOverview;
      }
    }
    return this.allowOverview;
  }

  public hasLateStudents(): boolean {
    if (!this.track) {
      return false;
    }
    const track = this.track as TrackOverview;
    return track.lateStudents && track.lateStudents.length > 0;
  }

  public getProgress(item: TrackModule | TrackEvent): number {
    if (item.isEvent) {
      const eventItem = item as TrackEvent;
      if (this.hasSubprogress) {
        return !eventItem.incompleteRequirementStudents ||
          eventItem.incompleteRequirementStudents.length === 0
          ? 100
          : Math.floor(
            100 -
            (eventItem.incompleteRequirementStudents.length /
              this.track.studentsCount) *
            100
          );
      }
    } else {
      const moduleItem = item as TrackModule;
      if (this.hasSubprogress) {
        return moduleItem.completeStudents === this.track.studentsCount
          ? 100
          : Math.floor(
            (moduleItem.completeStudents / this.track.studentsCount) * 100
          );
      } else return moduleItem.studentPercentage * 100;
    }
    return 0;
  }

  public checkLateEvent(item: TrackModule | TrackEvent): string {
    if (!item.isEvent) {
      return 'var(--primary-color)';
    } else if (item.isEvent && !this._hasLateStudents(item as TrackEvent)) {
      return 'var(--primary-color)';
    } else {
      return '#FF4376';
    }
  }

  public goToStudentContent(item): void {
    item = { ...item, trackId: this.track.id, programId: this.programId };

    item.isEvent ?
      this._navigationService.goToStudentEventContent(item)
      : this._navigationService.goToStudentModuleContent(item);
  }

  public goToOverViewContent(item) {
    item = { ...item, trackId: this.track.id };

    item.isEvent ?
      this._navigationService.goToEventOverViewContent(item, this.track.eventsConfiguration as any)
      : this._navigationService.goToModuleOverViewContent(item);
  }

  public goToDashboardInstructor(item) {
    this._navigationService.goToDashboardInstructor(item);
  }

  private _hasLateStudents(item: TrackEvent): boolean {
    return (
      this.hasLateStudents() &&
      (this.track as TrackOverview).lateStudents.some((s) =>
        s.lateEvents.some((evId) => evId === item.eventId)
      )
    );
  }

  public seeStatus() {
    this._router.navigate([
      'configuracoes/trilha-de-curso/' +
      this.track.id +
      '/' +
      this._authService.getLoggedUser().user_id,
    ]);
  }

  public seeReportCard() {
    this._router.navigate([
      'configuracoes/trilha-de-curso/boletim/' +
      this.track.id +
      '/' +
      this._authService.getLoggedUser().user_id,
    ]);
  }

  public exportGrades() {
    this._tracksService.getTrackOverviewGrades(this.track.id).subscribe(
      (res) => {
        const exportArray: any[] = [];
        const moduleArray: any[] = [];
        const eventArray: any[] = [];
        const eventPresenceArray: any[] = [];
        for (let i = 0; i < res.data.length; i++) {
          exportArray.push([]);
          exportArray[i]['Alunos'] = res.data[i].name;
        }
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].moduleGrade.length; j++) {
            if (!moduleArray.includes(res.data[i].moduleGrade[j].moduleName)) {
              moduleArray.push(
                'Módulo: ' + res.data[i].moduleGrade[j].moduleName
              );
              moduleArray.push(
                'Módulo-Peso: ' + res.data[i].moduleGrade[j].moduleName
              );
            }
          }
          for (let k = 0; k < res.data[i].eventGrade.length; k++) {
            if (!eventArray.includes(res.data[i].eventGrade[k].eventName)) {
              eventArray.push('Evento: ' + res.data[i].eventGrade[k].eventName);
              eventArray.push(
                'Evento-Peso: ' + res.data[i].eventGrade[k].eventName
              );
            }
          }
          for (let l = 0; l < res.data[i].eventPresence.length; l++) {
            if (
              !eventPresenceArray.includes(
                res.data[i].eventPresence[l].eventName
              )
            ) {
              eventPresenceArray.push(res.data[i].eventPresence[l].eventName);
            }
          }
        }
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < moduleArray.length; j++) {
            exportArray[i][moduleArray[j]] = '';
          }
          for (let j = 0; j < res.data[i].moduleGrade.length; j++) {
            exportArray[i]['Módulo: ' + res.data[i].moduleGrade[j].moduleName] =
              res.data[i].moduleGrade[j].grade;
            exportArray[i][
              'Módulo-Peso: ' + res.data[i].moduleGrade[j].moduleName
            ] = res.data[i].moduleGrade[j].weightGrade;
          }
        }
        for (let i = 0; i < res.data.length; i++) {
          for (let k = 0; k < eventArray.length; k++) {
            exportArray[i][eventArray[k]] = '';
          }
          for (let k = 0; k < res.data[i].eventGrade.length; k++) {
            exportArray[i]['Evento: ' + res.data[i].eventGrade[k].eventName] =
              res.data[i].eventGrade[k].finalGrade;
            exportArray[i][
              'Evento-Peso: ' + res.data[i].eventGrade[k].eventName
            ] = res.data[i].eventGrade[k].weightGrade;
          }
        }
        for (let i = 0; i < res.data.length; i++) {
          for (let l = 0; l < eventPresenceArray.length; l++) {
            exportArray[i][eventPresenceArray[l]] = '';
          }
          for (let l = 0; l < res.data[i].eventPresence.length; l++) {
            exportArray[i][res.data[i].eventPresence[l].eventName] =
              res.data[i].eventPresence[l].userPresence;
          }
        }
        this._excelService.exportAsExcelFile(
          exportArray,
          'Notas-Trilha-' + this.track.id
        );
      },
      (err) => {
        this.notify(this.getErrorNotification(err));
      }
    );
  }

  public goToTest(test: ValuationTest): void {
    if (test.type !== ValuationTestTypeEnum.Coursework) {
      this._router.navigate(['/teste-de-avaliacao/' + test.id], { state: { accessByComponent: true } });
    } else {
      this._router.navigate(['configuracoes/trabalho-final/' + test.id]);
    }
  }

  public getModuleTestPercent(test: ValuationTest): number {
    return test.testTracks.find((x) => x.id === this.track.id).percent;
  }

  public disablePercentButtonLogic(
    answered: boolean,
    currentPercent: number,
    percentNeeded: number,
    test: ValuationTest
  ): boolean {
    const progress =
      currentPercent !== null && currentPercent > 1 ? currentPercent / 100 : 0;
    if (answered) {
      return true;
    }
    if (percentNeeded > 1) {
      percentNeeded = percentNeeded / 100;
    }
    if (test.type === ValuationTestTypeEnum.Coursework) {
      const today = new Date().getTime();
      const testTrackIndex = test.testTracks.findIndex(
        (track) => track.id === this.track.id
      );
      const deliveryDate = new Date(
        test.testTracks[testTrackIndex].deliveryDate
      ).getTime();
      return today > deliveryDate;
    }
    if (currentPercent == null) {
      return true;
    } else {
      if (progress >= percentNeeded) {
        return false;
      } else {
        return true;
      }
    }
  }

  public disablePercentButtonLogicText(
    answered: boolean,
    currentPercent: number,
    percentNeeded: number,
    test: ValuationTest
  ): string {
    const progress =
      currentPercent !== null && currentPercent > 1 ? currentPercent / 100 : 0;
    if (answered) {
      if (test.type === ValuationTestTypeEnum.Coursework) {
        return 'Trabalho Respondido';
      } else {
        return 'Teste respondido';
      }
    }
    if (percentNeeded > 1) {
      percentNeeded = percentNeeded / 100;
    }
    if (this.completedPercentage == null) {
      return 'Progresso necessário ' + (percentNeeded * 100).toString() + '%';
    } else {
      if (progress >= percentNeeded) {
        if (test.type === ValuationTestTypeEnum.Coursework) {
          const today = new Date().getTime();
          const testTrackIndex = test.testTracks.findIndex(
            (track) => track.id === this.track.id
          );
          const deliveryDate = new Date(
            test.testTracks[testTrackIndex].deliveryDate
          ).getTime();
          if (today > deliveryDate) {
            return 'DATA EXPIRADA';
          } else {
            return 'Visualizar trabalho';
          }
        } else {
          return 'Fazer o teste';
        }
      } else {
        return 'Progresso necessário ' + (percentNeeded * 100).toString() + '%';
      }
    }
  }

  public getLogicDisableButtonCertificate(TestsTrackResearch: any[]): boolean {
    if (TestsTrackResearch.length === 0 || this.track && this.track.modulesConfiguration &&
      this.track.modulesConfiguration.every(module => module.isExemption)) {
      return false;
    } else if (TestsTrackResearch.length > 0) {
      const answered = TestsTrackResearch.filter(
        (test) =>
          !test.answered && test.type === ValuationTestTypeEnum.Percentile
      );
      if (answered.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  public checkEventItemValuation(item: TrackModule | TrackEvent): boolean {
    if (
      this.trackTestsOrdered !== null &&
      this.trackTestsOrdered.length !== 0 &&
      this.trackTestsOrdered.some((x) =>
        x.testTracks.some((y) => y.order === item.order)
      )
    ) {
      return true;
    }
    return false;
  }

  public checkAlwaysAvailable(item: TrackModule | TrackEvent): boolean {
    if (
      item.alwaysAvailable !== null &&
      item.alwaysAvailable !== undefined &&
      !item.alwaysAvailable &&
      item.openDate
    ) {
      return true;
    }
    return false;
  }

  public listEventItemValuation(item: TrackModule | TrackEvent): any[] {
    if (
      this.trackTestsOrdered !== null &&
      this.trackTestsOrdered.length !== 0 &&
      this.trackTestsOrdered.some((x) =>
        x.testTracks.some((y) => y.order === item.order)
      )
    ) {
      return this.trackTestsOrdered.filter((x) =>
        x.testTracks.some((y) => y.order === item.order)
      );
    }
    return [];
  }

  public getCertificate(): void {
    this._userService
      .GenerateCertificatePdf(this.track.id, 'track')
      .subscribe((response) => {
        if (response.success === false) {
          this.notify(response.errors);
        } else {
          this.notify('Certificado emitido com sucesso');
        }
      });
  }


  public canGenerateCertificate(): boolean {
    return !this.allowOverview && 
      this.track &&
      this.track.hasCertificate &&
      this.hasFeatureCertificate &&
      this.track.modulesConfiguration && 
      this.track.modulesConfiguration.length > 0 &&
      (this.completedPercentage === 100 || this.track.modulesConfiguration.every(module => module.isExemption) || this.track.modulesConfiguration.every(module => module.studentFinished));

  }

  public calculateTestGrade(moduleTest) {
    const grade = this._setGrade(moduleTest.answers);
    if (grade === null) {
      return '-';
    } else {
      return grade.toFixed(2).replace('.', ',');
    }
  }

  private _setGrade(answers: Array<{ grade: number }>) {
    if (answers.every(a => a.grade != null)) {
      return answers.reduce((sum, a) => sum + a.grade, 0) / 10;
    } else {
      return null;
    }
  }

  public viewQuestionsAndAnswersDialog(test: any) {
    this._valuationTestService.getValuationTestById(test.id)
      .pipe(take(1))
      .subscribe(valuationTest => {
        this._dialogService.open(QuestionsAndAnswersDialogComponent, {
          width: '1000px',
          height: '90%',
          data: { test: test, valuationTest: valuationTest.data }
        });
      });
  }

  public setStyles() {
    if (!this.hasFeatureCalender) {
      const ul = document.getElementById('list-modules');
      if (ul) {
        ul.classList.add('list');
        ul.style.gridTemplateRows = `repeat(${Math.round(this.indexModulesAndEvents)},auto)`;
      }
    }
  }
}
