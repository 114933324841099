import { GroupsEnum } from "./enums/groups.enum";

export class Groups {
    public id: string;
    public title: string;
    public type: GroupsEnum;
    public group: Array<GroupItems> = [];
}

export interface GroupItems {
    id: string;
    title: string;
}

export interface GroupPageItems {
    page: number;
    pagedItem: Array<Groups>;
    itemsCount: number;
}
