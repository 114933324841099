import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { EStudyPlan } from 'src/app/models/enums/study-plan.enum';
import { StudyPlanDependencies } from 'src/app/models/study-plan-dependencies.model';
import { StudyPlanItem } from 'src/app/models/study-plan-items.model';
import { StudyPlanRequeriments } from 'src/app/models/study-plan-requeriments.models';
import { StudyPlan } from 'src/app/models/study-plan.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ComponentCanDeactivate } from 'src/app/shared/guards/pending-changes.guard';
import { StudyPlanService } from '../../_services/study-plan.service';
import { ConnectDialogComponent } from '../dialogs/connect-dialog/connect-dialog.component';
import { Econnect } from '../dialogs/connect-dialog/Enum/connect.enum';
import { ISelectedItem } from '../dialogs/connect-dialog/interface/selected-item.interface';

@Component({
  selector: 'app-new-individual-study-plan',
  templateUrl: './new-individual-study-plan.component.html',
  styleUrls: ['./new-individual-study-plan.component.scss']
})
export class NewIndividualStudyPlanComponent extends NotificationClass implements OnInit, ComponentCanDeactivate {

  constructor(
    private _studyPlanService: StudyPlanService,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  private studtPlanId: string;
  private userId: string;
  public userName: string;
  public studyPlan: StudyPlan = new StudyPlan();
  public studyPlansTeam: StudyPlan[];
  public studyPlansItems: ISelectedItem[] = [];

  public page: number = 1;
  public itemsCount: number = 1;
  public readonly pageSize: number = 1;

  public hasModification: boolean = false;
  private selectedCourseSubject: Subject<{ type: Econnect, data: ISelectedItem }> = new Subject();

  public EnumConnect = Econnect;
  ngOnInit() {
    this.studtPlanId = this._activatedRoute.snapshot.params['studyPlanId'];
    this._activatedRoute.queryParamMap.subscribe(value => {
      this.userId = value.get('userId');
      this.userName = value.get('name');
    });

    if (!isNil(this.studtPlanId)) {
      this.loadStudyPlanById(this.studtPlanId, 1, 1);
    }

    if (!isNil(this.userId)) {
      this.loadUserStudyPlan(this.userId);
    }

    this.selectedCourseSubject.subscribe(value => {
      this.hasModification = true;
      this.studyPlansItems = [...this.studyPlansItems, value.data];
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasModification;
  }

  public setStudyPlanName(name: string) {
    this.hasModification = true;
    this.studyPlan.name = name;
  }

  public loadStudyPlanById(studyPlanId: string, page: number, pageSize: number) {
    this._studyPlanService.GetStudyPlanById(studyPlanId, page, pageSize).subscribe(response => {
      this.userName = response.data.pageUsers.users[0].name;
      this.studyPlan = response.data;
      this.studyPlansItems = this.studyPlan.items.map(value => ({
        id: value.id,
        title: value.name,
        type: value.type
      } as ISelectedItem));
      this.userId = response.data.pageUsers.users[0].id;
      this.loadUserStudyPlan(this.userId);

    });
  }

  public loadUserStudyPlan(userId: string) {
    this._studyPlanService.GetAllStudyPlan(this.page, this.pageSize, '', userId, this.studtPlanId).subscribe(response => {
      this.itemsCount = response.data.itemsCount;
      this.studyPlansTeam = response.data.studyPlans;
    });
  }

  public openDialog(type: Econnect) {
    const dialogRef = this._dialog.open(ConnectDialogComponent, {
      width: '800px',
      height: '80vh',
      data: {
        type: type,
        subject: this.selectedCourseSubject,
        notIn: this.studyPlan.items.map(value => value.id).concat(...this.studyPlansTeam.map(value => {
          const items = value.items.map(v => v.id);
          const mandatory = value.mandatoryTraining ? value.mandatoryTraining.map(v => v.id) : [];
          const intitutional = value.institutionalTraining ? value.institutionalTraining.map(v => v.id) : [];

          return items.concat(mandatory).concat(intitutional);
        })),
        studtPlanId: this.studtPlanId
      }
    });
  }

  public goToPage(page) {
    this.page = page;
    this.loadUserStudyPlan(this.userId);
  }

  public save() {
    this.studyPlan = this.getFormatedStudyPlan();

    if (this.studyPlan.name === '' || isNil(this.studyPlan.name)) {
      return this.notify('Preencha o nome do plano de estudos');
    }

    if (isNil(this.studyPlan.items) || this.studyPlan.items.length === 0) {
      return this.notify('Vincule ao menos um item ao seu plano de estudos');
    }

    if (!isNil(this.studtPlanId)) {
      this._studyPlanService.UpdateStudyPlan(this.studyPlan).subscribe(() => {
        this.hasModification = false;
        this.notify('Plano de estudo salvo com sucesso');
      });
    } else {
      this._studyPlanService.CreateStudyPlan(this.studyPlan).subscribe(response => {
        this.studtPlanId = response.data.id;
        this.studyPlan.id = this.studtPlanId;
        this.hasModification = false;
        this.notify('Plano de estudo salvo com sucesso');
      }, er => this.notify(er.error.errors[0]));
    }

  }

  public getFormatedStudyPlan() {
    this.studyPlan.users = [this.userId];
    this.studyPlan.type = EStudyPlan.individual;
    this.studyPlan.items = this.studyPlansItems.map((studyItems, index) => (
      new StudyPlanItem({ id: studyItems.id, type: studyItems.type, order: index })
    ));
    this.studyPlan.requirements = this.studyPlansItems.map((studyItems, index) => {
      const dependenciesIndex = index - 1;
      const dependencies = dependenciesIndex < 0
        ? new Array<StudyPlanDependencies>()
        : [new StudyPlanDependencies({
          id: this.studyPlansItems[dependenciesIndex].id,
          type: this.studyPlansItems[dependenciesIndex].type
        })];
      return new StudyPlanRequeriments({ id: studyItems.id, type: studyItems.type, dependencies: dependencies });
    });

    return this.studyPlan;
  }

  public remove(item: ISelectedItem) {
    this.studyPlansItems = this.studyPlansItems.filter(value => value.id !== item.id);
  }
}
