import { Component, Input } from '@angular/core';
import { IstudentsRanking } from '../../models/trackStudentsSubordinates';

@Component({
  selector: 'app-table-ranking',
  templateUrl: './table-ranking.component.html',
  styleUrls: ['./table-ranking.component.scss']
})
export class TableRankingComponent {

  @Input() ranking: Array<IstudentsRanking>;
  @Input() showFactor: boolean = false;
  constructor() { }



}
