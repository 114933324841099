import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../../material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule
  ],
  // declarations: [ImportExcelFilesComponent],
  // exports: [ImportExcelFilesComponent]
})
export class ImportExcelFilesModule { }
