import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class ContentTracksService {

  constructor(private _httpService: BackendService, private _authService: AuthService) { }

  public getTrackById(trackId: string, getApplications: boolean = false, programId = ''): Observable<any> {
    return this._httpService.get('getTrackById', [], [
      { 'name': 'id', 'value': trackId },
      { 'name': 'getApplications', 'value': getApplications.toString() },
      { 'name': 'programId', 'value': programId }
    ]);
  }

  public getPagedFilteredTracksList(
    page: number = 1, pageSize: number = 10, searchValue: string = '',
    tags: Array<string> = [], published: boolean = true, attending: boolean = null, notIn = []
  ): Observable<any> {
    return this._httpService.post('getPagedFilteredTracksList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'tags': tags,
        'published': published,
        'attending': attending,
        'notIn': notIn
      }
    });
  }

  public getTrackHasStudentsSubordinate(searchTerm: string): Observable<any> {
    return this._httpService.get('getTrackHasStudentsSubordinat', [], [
      { 'name': 'searchTerm', 'value': searchTerm }
    ]);
  }

  public getCatalogTracks(
    tags: Array<string> = [], published: boolean = true, attending: boolean = null
  ): Observable<any> {
    return this._httpService.get('getCatalogTracks');
  }

  public getNotRecommendedTracks(
    page: number = 1, pageSize: number = 10, searchValue: string = '',
    tags: Array<string> = [], published: boolean = true, attending: boolean = null
  ): Observable<any> {
    return this._httpService.post('getNotRecommendedTracks', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'tags': tags,
        'published': published,
        'attending': attending
      }
    });
  }

  public getAllFilteredTracksList(
    searchValue: string = '',
    tags: Array<string> = [], published: boolean = true
  ): Observable<any> {
    return this._httpService.post('getAllFilteredTracksList', {
      'filters': {
        'term': searchValue,
        'tags': tags,
        'published': published
      }
    });
  }

  public getPagedFilteredMycoursesTracksList(
    page: number = 1, pageSize: number = 10, searchValue: string = '',
    tags: Array<string> = [], published: boolean = true
  ): Observable<any> {
    return this._httpService.post('getPagedFilteredMyCoursesTracksList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'tags': tags,
        'published': published
      }
    });
  }

  public getPagedFilteredEffortPerformancesTracksList(
    page: number = 1, searchValue: string = '', pageSize: number = 10
  ): Observable<any> {
    return this._httpService.post('getPagedFilteredEffortPerformancesTracksList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue
      }
    });
  }

  public getTrackCurrentStudentOverview(trackId: string, programId: string = ''): Observable<any> {
    return this._httpService.get('getTrackCurrentStudentOverview', [], [
      { 'name': 'trackId', 'value': trackId },
      { 'name': 'programId', 'value': programId },
    ]);
  }

  public getTracksByItemId(itemId: string): Observable<any> {
    return this._httpService.get('getTracksByItemId', [], [
      { 'name': 'itemId', 'value': itemId },
    ]);
  }

  public getInstructorTracksOverview(moduleId: string, trackId: string): Observable<any> {
    return this._httpService.get('getInstructorTracksOverview', [], [
      { 'name': 'moduleId', 'value': moduleId },
      { 'name': 'trackId', 'value': trackId }
    ]);
  }

  public markMandatoryVideoViewed(trackId: string): Observable<any> {
    return this._httpService.put('markMandatoryVideoViewed', {
      'trackId': trackId
    });
  }
  public getTrackContainsModuleById(moduleId: string[]): Observable<any> {
    return this._httpService.post('getTrackContainsModuleById', {
      moduleId: moduleId
    });
  }

  public getCockpitTrackMetrics(trackId: string, studentsId: string[]): Observable<any> {
    return this._httpService.post('getCockpitTrackMetrics', {
      trackid: trackId,
      students: studentsId
    });
  }

  public getCockpitTrackMetricsStudentCurrent(trackId: string, studentId: string): Observable<any> {
    return this._httpService.post('getCockpitTrackMetricsStudentCurrent', {
      trackid: trackId,
      studentId: studentId
    });
  }

  public GetStudentsSubordinateByTrackId(trackId: string): Observable<any> {
    return this._httpService.post('getStudentsSubordinateByTrackId', {
      trackId: trackId,
    });
  }

  public getOverviewTrackMetrics(trackId: string): Observable<any> {
    return this._httpService.post('getOverviewTrackMetrics', {
      trackid: trackId
    });
  }

  public getOverviewTrackMetricCurrentStudent(trackId: string): Observable<any> {
    return this._httpService.post('getOverviewTrackMetricCurrentStudent', {
      trackid: trackId
    });
  }
}
