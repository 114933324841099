import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NotificationClass } from '../classes/notification';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { ERole } from 'src/app/models/enums/role.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { EAccess } from 'src/app/models/enums/acccess.enum';

@Injectable()
export class RoleGuard extends NotificationClass implements CanActivate {

  private _responsibleTrackRoutes: string[] = [
    'configuracoes/trilha-de-curso/:trackId',
    'configuracoes/trilha-de-curso/:trackId/modulo/:moduleId',
  ];

  private _responsibleRoutes: string[] = [
    'configuracoes/testes-de-avaliacao',
    'configuracoes/testes-de-avaliacao/:testId',
    'configuracoes/testes-de-avaliacao/repostas/:testId',
    'configuracoes/testes-de-avaliacao/repostas/:testId/:responseId',
    'relatorios',
  ];

  private _secretaryRoutes: string[] = [
    'configuracoes/trilha',
    'configuracoes/trilhas',
    'configuracoes/recomendacoes-produtos',
    'configuracoes/gerenciar-equipe',
    'configuracoes/emails-enviados',
    'configuracoes/trilha-de-curso/:trackId',
    'configuracoes/trilha-de-curso/:trackId/modulo/:moduleId',
    'empenho-desempenho',
    'configuracoes/nps',
    'relatorios',
  ];

  private _authorRoutes: string[] = [
    'configuracoes/trilha',
    'configuracoes/trilhas',
    'configuracoes/trilha/:trackId',
    'configuracoes/recomendacoes-produtos',
    'configuracoes/gerenciar-equipe',
    'configuracoes/emails-enviados',
    'configuracoes/trilha-de-curso/:trackId',
    'configuracoes/trilha-de-curso/:trackId/modulo/:moduleId',
    'empenho-desempenho',
    'configuracoes/nps',
    'relatorios',
  ];

  private _instructorRouter: string[] = [
    'instrutor/modulo/:moduleId',
    'instrutor/modulo/:moduleId/trilha/:trackId',
    'instrutor/modulo/:moduleId/evento/:eventId/:scheduleId',
    'configuracoes/gerenciar-eventos/:eventId',
    'configuracoes/gerenciar-eventos/:eventId/resultados/:scheduleId',
    'configuracoes/gerenciar-inscricoes/:eventId/:scheduleId',
    'configuracoes/gerenciar-inscricoes-notas/:eventId/:scheduleId',
    'modulo/:moduleId/:subjectId/:contentIndex',
    'modulo/:moduleId',
  ];

  private _salesRouter: string[] = [
    'relatorios'
  ];

  constructor(
    protected _snackBar: MatSnackBar,
    private _authService: AuthService,
    private _router: Router
  ) {
    super(_snackBar);
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const trackPreview = localStorage.getItem(LocalStorageService.key.trackResponsible);

    const user = this._authService.getLoggedUser();

    if (!user || !user.role || user.role.trim() === '') {
      return this.preventAccess();
    }

    if (this._authService.hasRole(ERole.Admin)) {
      return of(true);
    }

    if (this._authService.hasRole(ERole.HumanResources)) {
      return of(true);
    }

    if (this._authService.isSeenHowInstructorModule() || this._authService.isSeenHowInstructorEvent() &&
      this._instructorRouter.find(x => x === route.routeConfig.path)) {
      return of(true);
    }

    if (user.role === ERole.Secretary &&
      this._secretaryRoutes.find(x => x === route.routeConfig.path)) {
      return of(true);
    }
    if (user.role === ERole.Author &&
      this._authorRoutes.find(x => x === route.routeConfig.path)) {
      return of(true);
    }
    if (this._responsibleRoutes.find(x => x === route.routeConfig.path)) {
      if (!user.adminAccesses || !(user.adminAccesses instanceof Array))
        return of(false);

      if (user.adminAccesses.some(access => access === EAccess.LineManager || access === EAccess.TrackManager))
        return of(true);
    }

    if (this._salesRouter.find(x => x === route.routeConfig.path)) {
      if (!user.adminAccesses || !(user.adminAccesses instanceof Array))
        return of(false);

      if (user.adminAccesses.some(access => access === EAccess.Sales))
        return of(true);
    }

    if (this._responsibleTrackRoutes.find(x => x === route.routeConfig.path)) {

      if (trackPreview) {
        const track: TrackPreview = JSON.parse(trackPreview);
        const isTrackManager = localStorage.getItem(LocalStorageService.key.selectedAccess) === EAccess.TrackManager;
        if (track && (track.subordinate || track.instructor || isTrackManager)) {
          return of(true);
        }
      }
    }

    return this.preventAccess();
  }

  private preventAccess(): Observable<boolean> {
    this.notify('Você não tem permissões para acessar essa área');
    return EMPTY;
  }


}
