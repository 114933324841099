import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { UtilService } from 'src/app/shared/services/util.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, MatStep } from '@angular/material/stepper';
import { Track } from '../../../models/track.model';
import { NewTrackTrackInfoComponent } from './steps/1_track-info/track-info.component';
import { NotificationClass } from '../../../shared/classes/notification';
import { SettingsTracksService } from '../../_services/tracks.service';
import { NewTrackModulesEventsComponent } from './steps/3_modules-events/modules-events.component';
import { TrackEvent } from '../../../models/track-event.model';
import { TrackModule } from '../../../models/track-module.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CreatedTrackDialogComponent } from './steps/6_created-track/created-track.dialog';
import { NewTrackVideoComponent } from './steps/2_video/video.component';
import { NewTrackRelevantDatesComponent } from './steps/4_relevant-dates/relevant-dates.component';
import { environment } from 'src/environments/environment';
import { NewTrackModulesEventsWeightComponent } from './steps/3.6_modules-weight/modules-weight.component';
import { NewTrackModulesGradesComponent } from './steps/7_modules-grades/modules-grades.component';
import { NewTrackEcommerceComponent } from './steps/5_ecommerce/ecommerce.component';
import { EcommerceProduct } from 'src/app/models/ecommerce-product.model';
import { NewTrackModulesEventsDatesComponent } from './steps/3.5_modules-dates/modules-dates.component';
import { pickBy, cloneDeep, isNil, has, isArray } from 'lodash';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { takeUntil } from 'rxjs/operators';
import { WeightConfiguration } from 'src/app/settings/tracks/new-track/steps/3.6_modules-weight/models/weight-configuration.model';
import { TrackDraft } from 'src/app/models/track-draft.model';
import { TrackDraftService } from '../../_services/track-draft.service';


@Component({
  selector: 'app-settings-new-track',
  templateUrl: './new-track.component.html',
  styleUrls: ['./new-track.component.scss']
})
export class SettingsNewTrackComponent extends NotificationClass implements OnInit, OnDestroy {

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('firstStep') firstStep: MatStep;
  @ViewChild('trackVideo') trackVideo: NewTrackVideoComponent;
  @ViewChild('trackInfo') trackInfo: NewTrackTrackInfoComponent;
  @ViewChild('modulesEvents') modulesEvents: NewTrackModulesEventsComponent;
  @ViewChild('modulesEventsWeight') modulesEventsWeight: NewTrackModulesEventsWeightComponent;
  @ViewChild('modulesEventsAvailability') modulesEventsAvailability: NewTrackModulesEventsDatesComponent;
  @ViewChild('relevantDates') relevantDates: NewTrackRelevantDatesComponent;
  @ViewChild('modulesGradesWeight') modulesGradesWeight: NewTrackModulesGradesComponent;
  @ViewChild('ecommerce') ecommerce: NewTrackEcommerceComponent;


  public newTrack: TrackDraft;

  private _newTrack: BehaviorSubject<TrackDraft> = new BehaviorSubject(new TrackDraft());
  private _unsubscribeAll = new Subject<any>();

  public stepIndex: number = 0;
  public lastStep: number = 6;
  public loading: boolean = false;
  public allowEditing: boolean = false;
  public showDraftOptions: boolean = false;
  public hasEcommerceIntegration = environment.ecommerceIntegration;

  private _savingContent: boolean = false;
  private _shouldFinish: boolean = true;
  private _finalized: boolean;

  constructor(
    protected _snackBar: MatSnackBar,
    private _tracksService: SettingsTracksService,
    private _trackDraftService: TrackDraftService,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _utilService: UtilService,
    private _cdr: ChangeDetectorRef,
    private _location: Location,
  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this._route.paramMap.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(paramMap => {
      const trackId = paramMap.get('trackId');

      if (trackId && !history.state.editingTrack) {
        this._trackDraftService.getTrackDraftById(trackId).subscribe(response => {
          this.nextState(new TrackDraft(response.data as any));
          this.allowEditing = this.newTrack.id !== null;

        });
      } else if (history.state.editingTrack) {
        this.nextState(new TrackDraft(history.state.editingTrack));
        this.allowEditing = this.newTrack.id !== null;
      } else {
        this.nextState((new TrackDraft()));
      }
    });
  }

  public nextState(track: TrackDraft) {
    this._newTrack.next(track);

    this.newTrack = this._newTrack.getValue();
    this._cdr.detectChanges();
  }

  public handleErrorModulesEventsAvailability() {
    this._savingContent = false;
  }

  public saveContent(): void {
    if (!this._savingContent) {
      this._savingContent = true;
      this._shouldFinish = (!this.hasEcommerceIntegration && this.stepIndex === this.lastStep) || this.stepIndex === this.lastStep;
      this.nextStep();
    }
  }

  public nextStep() {
    switch (this.stepper.selectedIndex) {
      case 0:
        this.trackInfo.nextStep();
        break;
      case 1:
        this.trackVideo.nextStep();
        break;
      case 2:
        this.modulesEvents.nextStep();
        break;
      case 3:
        this.modulesEventsWeight.nextStep();
        break;
      case 4:
        this.modulesEventsAvailability.nextStep();
        break;
      case 5:
        this.relevantDates.nextStep();
        break;
      case 6:
        if (this.hasEcommerceIntegration)
          this.ecommerce.nextStep();
        break;
      default:
        break;
    }
  }

  public previousStep() {
    this.stepIndex--;
    this.stepper.previous();
  }

  public stepChanged(event, shouldFinish: boolean = true) {
    this._shouldFinish = shouldFinish;
    this.stepIndex = event.selectedIndex;
    this.nextStep();
  }

  public saveStep(shouldContinue: boolean, foward: boolean) {
    this._finalized = !shouldContinue && !foward;
    if (shouldContinue && foward) {
      this.stepIndex++;
      this.stepper.next();
    } else if (shouldContinue && !foward) {
      this.stepIndex--;
      this.stepper.previous();
    } else {
      this.nextStep();
    }
  }

  public setTrackInfo(trackInfo: Track) {

    if (!trackInfo) {
      return;
    }

    const oldTrack = cloneDeep(this.newTrack);
    this.newTrack.setTrackInfo(trackInfo);

    if (this._utilService.equalsObj(pickBy(this.newTrack), pickBy(oldTrack))) {
      return;
    }

    this.confirmChanges(oldTrack, this.newTrack);
  }

  public setTrackVideo(trackVideo: Track) {

    if (!trackVideo) {
      return;
    }

    const oldTrack = cloneDeep(this.newTrack);
    this.newTrack.setVideoInfo(trackVideo);

    if (this._utilService.equalsObj(pickBy(this.newTrack), pickBy(oldTrack))) {
      return;
    }

    this.newTrack.setVideoInfo(trackVideo);
    this.confirmChanges(oldTrack, this.newTrack);
  }

  private _removeUnusableFieldsForComparison(originalTrack: Track): Track {

    const cloneTrack = cloneDeep(originalTrack);

    if (!isNil(cloneTrack.modulesConfiguration)) {
      cloneTrack.modulesConfiguration.forEach((mod) => {
        if (!isNil(mod.isEvent)) {
          delete mod.isEvent;
        }
        if (!isNil(mod.hovered)) {
          delete mod.hovered;
        }
      });
    }

    if (!isNil(cloneTrack.eventsConfiguration)) {
      cloneTrack.eventsConfiguration.forEach((evt) => {
        if (!isNil(evt.isEvent)) {
          delete evt.isEvent;
        }

        if (!isNil(evt.hovered)) {
          delete evt.hovered;
        }
      });
    }

    return cloneTrack;
  }

  public getModuleValuations(trackId) {
    this._trackDraftService.getTrackDraftById(trackId).subscribe((response) => {
      const trackUpdated = cloneDeep(this.newTrack);
      trackUpdated.setValuationConfiguration(response.data.valuations);
      this.nextState(trackUpdated);
    });
  }

  public addModulesAndEvents(modulesEvents: Array<Array<TrackModule | TrackEvent>>) {

    const newModuleConfigurations = [...modulesEvents[0]];
    const newEventConfigurations = [...modulesEvents[1]];

    const currentModulesConfigurations = this.newTrack.modulesConfiguration ? [...this.newTrack.modulesConfiguration] : [];
    const currentEventsConfigurations = this.newTrack.eventsConfiguration ? [...this.newTrack.eventsConfiguration] : [];

    const areModulesSame = this._utilService.equalsObj(currentModulesConfigurations, newModuleConfigurations);
    const areEventsSame = this._utilService.equalsObj(currentEventsConfigurations, newEventConfigurations);

    if (areModulesSame && areEventsSame) {
      return;
    }

    const trackUpdated = cloneDeep(this.newTrack);

    trackUpdated.modulesConfiguration = newModuleConfigurations as Array<TrackModule>;
    trackUpdated.eventsConfiguration = newEventConfigurations as Array<TrackEvent>;

    this.confirmChanges(
      this.newTrack,
      trackUpdated,
      !areModulesSame && (
        () => this.getModuleValuations(this.newTrack.id))
    );

  }

  public addModulesEventsWeight(weights: WeightConfiguration) {

    const updatedTrack = cloneDeep(this.newTrack);
    const currentTrack = cloneDeep(this.newTrack);

    updatedTrack.modulesConfiguration = [...weights.modules || [] as Array<TrackModule>];
    updatedTrack.eventsConfiguration = [...weights.events || []] as Array<TrackEvent>;

    const updatedTrackComparison = this._removeUnusableFieldsForComparison(updatedTrack);
    const currentTrackComparison = this._removeUnusableFieldsForComparison(currentTrack);

    const areModuleWeightsSame =
      this._utilService.equalsObj(currentTrackComparison.modulesConfiguration, updatedTrackComparison.modulesConfiguration);

    const areEventWeightsSame =
      this._utilService.equalsObj(currentTrackComparison.eventsConfiguration, updatedTrackComparison.eventsConfiguration);

    if (areModuleWeightsSame && areEventWeightsSame) {
      return;
    }

    const trackUpdated = cloneDeep(this.newTrack);

    const updateConfiguration = (configs, item: { weight: number }, key: string) => {
      const index = configs.findIndex(m => m[key] === item[key]);
      if (index !== -1) item.weight = configs[index].weight;
    };

    trackUpdated.modulesConfiguration.forEach(module => updateConfiguration(updatedTrack.modulesConfiguration, module, 'moduleId'));
    trackUpdated.eventsConfiguration.forEach(event => updateConfiguration(updatedTrack.eventsConfiguration, event, 'eventId'));

    trackUpdated.modulesConfiguration = updatedTrack.modulesConfiguration;
    trackUpdated.eventsConfiguration = updatedTrack.eventsConfiguration;

    this.confirmChanges(this.newTrack, trackUpdated);

  }

  public addModulesEventsDate({ modules, events, valuations }) {
    const updatedTrack = cloneDeep(this.newTrack);
    const currentTrack = cloneDeep(this.newTrack);

    updatedTrack.modulesConfiguration = [...modules] as Array<TrackModule>;
    updatedTrack.eventsConfiguration = [...events] as Array<TrackEvent>;
    updatedTrack.valuationsConfiguration = valuations;


    const updatedTrackComparison = this._removeUnusableFieldsForComparison(updatedTrack);
    const currentTrackComparison = this._removeUnusableFieldsForComparison(currentTrack);

    const objEvent1 =
      currentTrackComparison.eventsConfiguration ?
        currentTrackComparison.eventsConfiguration.map(event => pickBy(event)) :
        [];

    const objEvent2 =
      updatedTrackComparison.eventsConfiguration ?
        updatedTrackComparison.eventsConfiguration.map(event => pickBy(event)) :
        [];

    const areModuleDatesSame =
      this._utilService.equalsObj(pickBy(currentTrackComparison.modulesConfiguration),
        pickBy(updatedTrackComparison.modulesConfiguration));

    const areEventDatesSame =
      this._utilService.equalsObj(objEvent1, objEvent2);

    const areValuationDatesSame =
      this._utilService.equalsObj(currentTrack.valuations.tracks,
        updatedTrack.valuationsConfiguration.tracks) &&
      this._utilService.equalsObj(currentTrack.valuations.modules,
        updatedTrack.valuationsConfiguration.modules);


    if (areModuleDatesSame && areEventDatesSame && areValuationDatesSame) {
      return;
    }

    const trackUpdated = cloneDeep(this.newTrack);

    const dateProperties = [
      'cutOffDate',
      'openDate',
      'valuationDate',
      'alwaysAvailable',
    ];

    const findArrayObjectAndUpdate = (objectToUpdate, currentProperties) =>
      this._utilService.findArrayObjectAndUpdate(objectToUpdate, dateProperties, currentProperties);

    findArrayObjectAndUpdate(trackUpdated.modulesConfiguration, updatedTrack.modulesConfiguration);
    findArrayObjectAndUpdate(trackUpdated.eventsConfiguration, updatedTrack.modulesConfiguration);
    findArrayObjectAndUpdate(trackUpdated.valuationsConfiguration.tracks, updatedTrack.valuationsConfiguration.tracks);
    findArrayObjectAndUpdate(trackUpdated.valuationsConfiguration.modules, updatedTrack.valuationsConfiguration.modules);

    trackUpdated.modulesConfiguration = updatedTrack.modulesConfiguration;
    trackUpdated.eventsConfiguration = updatedTrack.eventsConfiguration;
    trackUpdated.setValuationConfiguration(updatedTrack.valuationsConfiguration);

    this.confirmChanges(this.newTrack, trackUpdated);
  }

  public manageRelevantDates(calendarEvents: Array<TrackEvent>) {

    const trackUpdated = cloneDeep(this.newTrack);

    if (this.newTrack.calendarEvents) {
      this.newTrack.calendarEvents.forEach(calEvents => {
        if (calEvents.eventDate && typeof calEvents.eventDate === 'string') {
          calEvents.eventDate = new Date(calEvents.eventDate);
        }
      });

      const oldCalendarEvents = this.newTrack.calendarEvents ? [...this.newTrack.calendarEvents] : [];
      const newCalendarEvents = [...calendarEvents];

      const areCalendarEventsSame = this._utilService.equalsObj(oldCalendarEvents, newCalendarEvents);

      if (areCalendarEventsSame) {
        return;
      }

      this._utilService.findArrayObjectAndUpdate(
        trackUpdated.calendarEvents,
        [
          'eventDate',
          'duration',
          'startHour',
          'title',
        ],
        newCalendarEvents);

      trackUpdated.calendarEvents = newCalendarEvents;

    } else {

      trackUpdated.calendarEvents = calendarEvents;
    }
    this.confirmChanges(this.newTrack, trackUpdated);
  }

  public manageRelevantDatesAndEcommerceProducts(calendarEvents?: Array<TrackEvent>, ecommerceProduct?: Array<EcommerceProduct>) {

    this.loading = true;

    if (!isNil(calendarEvents)) {
      this._tracksService.manageCalendarEvents(
        this.newTrack.id, calendarEvents
      ).subscribe(response => {

        this.newTrack.calendarEvents = response.data.calendarEvents;

        if (this._shouldFinish) {
          if (!this.hasEcommerceIntegration) {
            this._dialog.open(CreatedTrackDialogComponent);
            this._router.navigate(['configuracoes/trilhas']);
          } else {
            this.stepIndex++;
            this.stepper.next();
          }
        } else {
          this._shouldFinish = true;
        }

        this._savingContent = false;
        this.loading = false;
        this.notify('Trilha salva com sucesso');

      }, (error) => this._errorHandlingFunc(error));
    }

    if (!isNil(ecommerceProduct)) {
      this._tracksService.manageEcommerceProducts(
        this.newTrack.id, ecommerceProduct
      ).subscribe(() => {
        if (this._dialog.openDialogs.length <= 0 || this._finalized) {
          this._dialog.open(CreatedTrackDialogComponent);
          this._router.navigate(['configuracoes/trilhas']);
        }
        if (!this._finalized) this.notify('Trilha salva com sucesso');
      });
    }
  }

  public manageEcommerceInfo(ecommerceProducts: Array<EcommerceProduct>) {

    const trackUpdated = cloneDeep(this.newTrack);

    if (this.newTrack.ecommerceProducts) {

      const oldEcommerceProducts = [...this.newTrack.ecommerceProducts];
      const newEcommerceProducts = [...ecommerceProducts];

      const areEcommerceProductsSame = this._utilService.equalsObj(oldEcommerceProducts, newEcommerceProducts);

      if (areEcommerceProductsSame) {
        if (this._finalized) {
          this._dialog.open(CreatedTrackDialogComponent);
          this._router.navigate(['configuracoes/trilhas']);
        }
        return;
      }

      this._utilService.findArrayObjectAndUpdate(
        trackUpdated.ecommerceProducts,
        [
          'ecommerceId',
          'usersAmount',
          'disableEcommerce',
          'price',
          'disableFreeTrial',
          'linkEcommerce',
          'linkProduct',
          'subject',
          'hours'],
        newEcommerceProducts);

      trackUpdated.ecommerceProducts = newEcommerceProducts;

    } else {
      trackUpdated.ecommerceProducts = ecommerceProducts;
    }
    this.confirmChanges(
      this.newTrack, trackUpdated);
  }

  private _updateTrackInfo(track: TrackDraft, finish: boolean = false): Subscription {
    this.loading = true;
    return this._tracksService.manageTrackInfo(track).subscribe((response) => {
      if (!this.newTrack.id) {
        this.newTrack.id = response.data.id;
      }


      if (this._shouldFinish) {
        if (finish) {
          if (this._dialog.openDialogs.length <= 0) {
            this._dialog.open(CreatedTrackDialogComponent);
          }
          this._router.navigate(['configuracoes/trilhas']);
        } else { this._updateFooter(response.data, false); }

      } else {
        this._shouldFinish = true;
      }

      this._savingContent = false;
      this.loading = false;
      this.notify('Trilha salva com sucesso');

    }, (response) => this._errorHandlingFunc(response));
  }

  private _updateFooter(track: TrackDraft, triggerByButtonSave: boolean = true) {
    this.newTrack.id = track.id;
    this.loading = false;

    if (triggerByButtonSave) {
      this.stepIndex++;
      this.stepper.next();
    }
  }

  private _errorHandlingFunc(response) {
    this._savingContent = false;
    this.loading = false;
    this.notify(
      this.getErrorNotification(response)
    );
  }

  private _createTrackDraft(track: TrackDraft) {
    return this._trackDraftService.createTrackDraft(track.id).subscribe(response => {
      this.newTrack.trackId = response.data.trackId;
      this.newTrack.id = response.data.id;
      this.newTrack.isDraft = true;

      this._location.replaceState(`/configuracoes/trilha/${this.newTrack.id}`);
      this.allowEditing = true;

      this.nextState(this.newTrack);
    });
  }

  private _updateDraft(track: TrackDraft): Subscription {
    if (!track.isDraft) {
      return this._createTrackDraft(track).add(() => this._trackDraftService.updateTrackDraft(track).subscribe());
    } else {
      return this._trackDraftService.updateTrackDraft(track).subscribe();
    }
  }

  public confirmChanges(track: TrackDraft, trackUpdate: TrackDraft, callBack: Function = null): Subscription | void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      width: '400px',
      data: { message: 'Deseja salvar alterações?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.nextState(trackUpdate);
        if (!trackUpdate.isDraft && isNil(trackUpdate.id)) {
          this._updateTrackInfo(trackUpdate)
            .add(() => this._createTrackDraft(trackUpdate))
            .add(() => callBack !== null ? callBack() : null);
        } else {
          this._updateDraft(trackUpdate).add(() => callBack !== null ? callBack() : null);
        }

      } else {
        this.nextState(track);
      }
    });
  }

  public publishDraftChanges() {
    this._trackDraftService.publishTrackDraft(this.newTrack.trackId, this.newTrack.id).subscribe(() => {
      this.notify('Trilha publicado com sucesso!');
      this._router.navigate(['configuracoes/trilhas']);
    });
  }

  public rejectDraftChanges() {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Tem certeza que deseja rejeitar as alterações em rascunho? Todas as alterações serão perdidas.'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._trackDraftService.deleteTrackDraft(this.newTrack.id).subscribe(() => {
          this.notify('Alterações rejeitadas com sucesso!');
          this._router.navigate(['configuracoes/trilhas']);
        });
      }
    });
  }
}
