import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GenerateValuationTestCheckOptionsComponent } from './generate-valuation-test-check-options.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgUtilModule } from '@tg4/ng-util';
import { MaterialComponentsModule } from '../../material.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { ListSearchModule } from '../list-search/list-search.module';
import { PaginationModule } from '../pagination/pagination.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CanDisablePipe } from './pipes/disable.pipe';

@NgModule({
  declarations: [
    GenerateValuationTestCheckOptionsComponent,
    CanDisablePipe
  ],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ListSearchModule,
    NgUtilModule,
    AutocompleteModule,
    MatCardModule,
    PaginationModule,
    MatIconModule,
    MatTooltipModule,
    ScrollingModule
  ],
  exports: [
    GenerateValuationTestCheckOptionsComponent
  ]
})
export class GenerateValuationTestCheckOptionsModule { }
