<div class="inner-content">
  <h2>CURSOS E AVALIAÇÕES</h2>

  <div class="header">
    <div class="search">
      <i class="logo icon-lupa search-icon"></i>
      <input type="text"
        placeholder="Digite o nome do módulo"
        (keyup)="updateSearch($event.target.value)" />
    </div>
    <div class="actions">
      <button class="btn-test" (click)="createNewModule()">
        NOVO MÓDULO
      </button>
      <button *ngIf="user.role === 'Admin' || user.role === 'Rh'" class="btn-test " (click)="exportModules()">
        Exportar relatório
      </button>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab label="Ativos">
      <div class="modules">
        <app-settings-module-card
          *ngFor="let module of modules"
          [module]="module"
          (editModule)="editModule($event)"
          (cloneModule)="cloneModule($event)"
          (changeModuleActivity)="changeModuleActivity($event)"
          (deleteModule)="deleteModule($event)">
        </app-settings-module-card>
      </div>
  
      <app-pagination *ngIf="modulesCount"
        [setPagination]="{
          'itemsCount': modulesCount,
          'pageSize': 20
        }"
        (goToPage)="goToPage($event)">
      </app-pagination>
    </mat-tab>
    <mat-tab label="Inativos">
      <div class="modules">
        <app-settings-module-card
        *ngFor="let module of inactiveModules"
        [module]="module"
        (editModule)="editModule($event)"
        (cloneModule)="cloneModule($event)"
        (changeModuleActivity)="changeModuleActivity($event)"
        (deleteModule)="deleteModule($event)">
        </app-settings-module-card>
      </div>
      <h2 *ngIf="inactiveModules.length==0" class="empty-state">Não há módulos inativos no momento</h2>

      <app-pagination *ngIf="inactiveModulesCount"
        [setPagination]="{
          'itemsCount': inactiveModulesCount,
          'pageSize': 20
        }"
        (goToPage)="goToPageInactiveModules($event)">
      </app-pagination>
    </mat-tab>
  </mat-tab-group> 
</div>
