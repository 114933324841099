<div [formGroup]="formGroup" >
  
  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('fixedAbilities')['controls']; let i = index;"
    formArrayName="fixedAbilities"
  >
    <div class="two-columns" [formGroupName]="i" >
      <div class="card-info name" >
        <p>{{ formGroup.get('fixedAbilities')['controls'][i]?.get('name')?.value }}</p>
        <mat-form-field class="noBorder" floatLabel="never" >
          <mat-select formControlName="hasLevel" (selectionChange)="cleanLevel(i)" required>
            <mat-option [value]="true">Sim</mat-option>
            <mat-option [value]="false">Não</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="card-info level" >
        <p>Nível</p>
        <div style="display: flex;">
          <mat-form-field class="noBorder" floatLabel="never" >
            <mat-select formControlName="level" 
              [disabled]="!formGroup.get('fixedAbilities')['controls'][i]?.get('hasLevel')?.value" required >
              <mat-option [value]="'Básico'">Básico</mat-option>
              <mat-option [value]="'Intermediário'">Intermediário</mat-option>
              <mat-option [value]="'Avançado'">Avançado</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('abilities')['controls']; let i = index;"
    formArrayName="abilities"
  >
    <ng-container [formGroupName]="i" >
      <div class="two-columns">
        <div class="card-info name" >
          <p>Habilidade *</p>
          <mat-form-field
            class="noBorder"
            floatLabel="never">
            <input matInput formControlName="name" placeholder="Adicione uma nova habilidade" required/>
          </mat-form-field>
        </div>
        <div class="card-info level" >
          <p>Nível</p>
          <div style="display: flex;">
            <mat-form-field
              class="noBorder"
              floatLabel="never">
              <mat-select formControlName="level" required>
                <mat-option [value]="'Básico'">Básico</mat-option>
                <mat-option [value]="'Intermediário'">Intermediário</mat-option>
                <mat-option [value]="'Avançado'">Avançado</mat-option>
              </mat-select>
            </mat-form-field>
            <button class="btn-test remove-btn"
              type="button"
              (click)="removeForm('abilities', i)">
              Remover
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button class="btn-test add-button"
    type="button"
    (click)="addAbility.emit()" >
    + Adicionar Outras Habilidades
  </button>
</div>