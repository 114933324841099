<div class="content">
  <div class="side-content">
    <h3>Filtros</h3>
    <div class="filter">
      <app-filter-track-module #FilterComponent> </app-filter-track-module>
    </div>
  </div>
  <div class="main-content">
    <div class="table">
      <div class="item">
        <div class="files">
          <span>Exportação do Banco de Questões</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportBdqStatistics()">
            EXPORTAR
          </button>
        </div>
      </div>
      <div class="item">
        <div class="files">
          <span>Alerta de Movimentações Atípicas</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportAtypicalMovements()">
            EXPORTAR
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
