import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from '../../../models/module.model';
import { UserProgress } from 'src/app/models/subject.model';
import { UserService } from 'src/app/pages/_services/user.service';
import { NotificationClass } from '../../classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { Recommendations } from 'src/app/settings/users/user-models/user-track';
import { UtilService } from '../../services/util.service';
import { ModuleHeightChangeEnum } from 'src/app/models/enums/module-height-change.enum';
import { isNil, isEmpty } from 'lodash';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ERole } from 'src/app/models/enums/role.enum';
import { EAccess } from 'src/app/models/enums/acccess.enum';
@Component({
  selector: 'app-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.scss']
})
export class ModuleCardComponent extends NotificationClass implements OnInit, OnChanges {

  @Input() module: Module & { progress: number, points: number, isStarted: boolean, isExempt: boolean };
  @Input() progress: UserProgress;
  @Input() levels: any;
  @Input() viewType?: string = 'cards';
  @Input() completed = false;
  @Input() showRecommended = false;
  @Input() showText: boolean = false;
  @Input() showInstructor: boolean = false;
  @Input() showContent: boolean = false;
  @Input() hours: boolean = true;
  @Input() showPrice = false;
  @Input() showButtonSubscribe: boolean = false;
  @Input() redirectWhenRecommended: boolean = true;

  private _showButtonKnowMore: boolean;
  @Input()
  public get showButtonKnowMore(): boolean {
    const hasValidParameters =
      !isNil(this.module.ecommerceProducts) &&
      !isEmpty(this.module.ecommerceProducts) &&
      this.module.ecommerceProducts[0].linkProduct !== null;

    return this._showButtonKnowMore && hasValidParameters;
  }
  public set showButtonKnowMore(showButtonKnowMore: boolean) { this._showButtonKnowMore = showButtonKnowMore; }

  public height: number = 170;
  public isInstructor = false;
  public isAdmin: boolean;
  public userProgress: number;
  public get reachedMaxLevel(): boolean { return !isNil(this.progress) && this.progress.level === 4; }
  public instructorLabel: string;

  constructor(
    private _router: Router,
    protected _snackBar: MatSnackBar,
    private _usersService: SettingsUsersService,
    private _utilService: UtilService,
    private _authService: AuthService,
    public userService: UserService) {
    super(_snackBar);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.viewType === 'list') {
      this.height = 170;
    } else {
      this.height = 190;
      this.changeHeight();
    }
  }
  ngOnInit() {
    (this.module as any) = this.module instanceof Module ? this.module : new Module(this.module);

    this.isInstructor = this._authService.hasSelectedAccess(EAccess.InstructorEvent) ||
      this._authService.hasSelectedAccess(EAccess.InstructorModule);

    this.isAdmin = this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources);

    this.instructorLabel = this.module.getInstructorLabelFormatted();

  }

  public getExcerptContent(): string {
    if (!this.module) return '';

    if (this.module) {
      if (this.module?.excerpt?.length > 130) {
        return this.module.excerpt.substring(0, 130) + '...';
      } else {
        return this.module.excerpt;
      }
    }
  }

  public setHoveredValue(value: boolean): void {
    if (!this.module) return;
    this.module.hovered = value;
  }

  public goToModule(event) {

    event.stopPropagation();

    if (!this.redirectWhenRecommended && this.module.hasUserProgress) {
      return false;
    }
    this._router.navigate(['modulo/' + this.module.id]);
  }

  public goToPageEcommerceUrl(event) {

    event.stopPropagation();

    if (this.module.ecommerceProducts === null) {
      this.notify('As matrículas não foram aberta');
    }
    if (this.module.ecommerceProducts
      && this.module.ecommerceProducts[0]
      && this.module.ecommerceProducts[0].disableFreeTrial) {
      const recommendations: Array<Recommendations> = this._setRecommendations();
      this._usersService.updateUserRecommendations(
        recommendations
      ).subscribe(() => {
        this.module.hasUserProgress = true;
        this.notify('Inscrição feita com sucesso');
      }, () => this.notify('Ocorreu um erro ao tentar se matricular'));
    } else if (this.module.ecommerceProducts && this.module.ecommerceProducts[0]) {

      window.open(this.module.ecommerceProducts[0].linkEcommerce, '_blank');

    } else {
      this.notify('Curso sem configuração de ecommerce');
    }
  }

  private _setRecommendations(): Array<Recommendations> {
    const recommendations: Array<Recommendations> = [];
    const user = JSON.parse(localStorage.getItem(LocalStorageService.key.authData));
    const recommendation: Recommendations = {
      userId: user.user_id,
      tracks: [], modules: [{ id: this.module.id, name: this.module.title }], events: []
    };
    recommendations.push(recommendation);

    return recommendations;
  }

  public goToPageStoreUrl(event) {
    event.stopPropagation();
    window.open(this.module.ecommerceProducts[0].linkProduct, '_blank');
  }

  public goToDashboard(event: MouseEvent) {
    event.stopPropagation();
    this._router.navigate([`instrutor/modulo/${this.module.id}`], { state: { module: this.module } });
  }

  public getDuration(): string {
    return this._utilService.formatSecondsToHour(this.module.duration);
  }

  public changeHeight() {
    if (this.showText) {
      this.height += ModuleHeightChangeEnum.showText;
    }
    if (this.showInstructor) {
      this.height += ModuleHeightChangeEnum.showInstructor;
    }
    if (this.showContent) {
      this.height += ModuleHeightChangeEnum.showContent;
    }
    if (this.hours) {
      this.height += ModuleHeightChangeEnum.hours;
    }
    if (this.showPrice) {
      this.height += ModuleHeightChangeEnum.showPrice;
    }
    if (this.showButtonSubscribe) {
      this.height += ModuleHeightChangeEnum.showButtonSubscribe;
    }
    this.height += ModuleHeightChangeEnum.showButtonKnowMore;
  }
}
