<div [ngClass]="{ 'list': viewType === 'list'}" class="program-card" [style.height]="height + 'px'"
  (click)="goToPageProgramUrl($event)">
  
  <div class="content">
    <div class="header">
      <p>Programa</p>
    </div>    
    <p class="title">
      {{ program.title }}
    </p>
    <div class="description">
      {{ program.description }}
    </div>

  </div>  
</div>
