import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss']
})
export class SettingsHeaderComponent implements OnInit {

  @Input() serachInputPlaceholder: string;
  @Input() defaultButtonName: string;
  @Input() title: string;
  @Output() searchValue = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter();
  private _searchSubject: Subject<string> = new Subject();
  constructor() { }

  ngOnInit() {
    this._setSearchSubscription();
  }

  private _setSearchSubscription(): void {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.searchValue.emit(searchValue);
    });
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }
}
