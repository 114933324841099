import { Track } from "./track.model";

export class TrackDraft extends Track {

    constructor(track: TrackDraft = null) {
        super(track);
        if (track) {
            this.isDraft = track.isDraft;
            this.trackId = track.trackId;
        }
    }

    public isDraft: boolean;
    public trackId?: string;
}