import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { MaterialComponentsModule } from '../../material.module';
import { QuestionsAndAnswersDialogComponent } from './questions-and-answers.dialog';
import { QuestionGradePipe } from './pipes/question-grade.pipe';
import { QuestionGradeClassPipe } from './pipes/question-grade-class.pipe';

@NgModule({
    declarations: [QuestionsAndAnswersDialogComponent,
        QuestionGradePipe,
        QuestionGradeClassPipe],
    imports: [BrowserModule, MaterialComponentsModule, MarkdownToHtmlModule]
})
export class QuestionsAndAnswersDialogModule {}
