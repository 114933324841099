import { Component, Input } from '@angular/core';
import { ImetricsStudents } from '../../models/trackStudentsSubordinates';

@Component({
  selector: 'app-performance-metric',
  templateUrl: './performance-metric.component.html',
  styleUrls: ['./performance-metric.component.scss']
})
export class PerformanceMetricComponent {

  @Input() metrics: ImetricsStudents;
  @Input() isModule: boolean = false;
  constructor() { }

}
