import { UtilService } from 'src/app/shared/services/util.service';
import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Requirement } from '../../models/new-requirement.model';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Module } from '../../../../../models/module.model';
import { Level } from '../../../../../models/shared/level.interface';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-new-module-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['../new-module-steps.scss', './requirements.component.scss']
})
export class NewModuleRequirementsComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() module$: Observable<Module>;
  @Input() readonly levels: Array<Level>;
  @Output() setRequirements = new EventEmitter<Array<Array<Requirement>>>();

  public requirements: Array<Array<Requirement>> = [[], []];

  public module: Module;

  public requiredModules: Array<Requirement> = [];
  public newModule = new Requirement('', 0);
  public editing: boolean = false;

  public optionalModules: Array<Requirement> = [];
  public newOptionalModule = new Requirement('', 0);
  public editingOptional: boolean = false;

  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {

    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = cloneDeep(moduleItems);
        if (moduleItems && moduleItems.requirements) {
          this.requirements = [
            moduleItems.requirements.filter(r => !r.optional),
            moduleItems.requirements.filter(r => r.optional)
          ];
        }
      });
    }
  }

  public updateRequirements(requirements: Array<Array<Requirement>>): void {
    this.requirements = requirements;
  }

  public nextStep(): void {
    this.setRequirements.emit(this.requirements);
  }

}
