import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsUsersService } from '../../_services/users.service';
import {AcademicEducationComponent} from './academic-education/academic-education.component';
import {CareerComplementaryExperienceComponent} from './complementary-experience/complementary-experience.component';
import {CareerComplementaryInfoComponent} from './complementary-info/complementary-info.component';
import {ProfessionalExperienceComponent} from './professional-experience/professional-experience.component';
import {ProfessionalObjectivesComponent} from './professional-objectives/professional-objectives.component';
import { UserCareer, ProfessionalExperience, College, Reward,
  Perk, Certificate, Institute, PerkLanguage, Validator } from '../user-models/user-career';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-manage-user-career',
  templateUrl: './manage-user-career.component.html',
  styleUrls: ['./manage-user-career.component.scss']
})
export class SettingsManageUserCareerComponent extends NotificationClass implements OnInit, AfterViewChecked {

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  @ViewChild('academicEducations') academicEducations: AcademicEducationComponent;
  @ViewChild('complementaryExperiences') complementaryExperiences: CareerComplementaryExperienceComponent;
  @ViewChild('complementaryInfos') complementaryInfos: CareerComplementaryInfoComponent;
  @ViewChild('professionalExperiences') professionalExperiences: ProfessionalExperienceComponent;
  @ViewChild('professionalsObjectives') professionalsObjectives: ProfessionalObjectivesComponent;

  public travelAvailability: boolean = false;
  public movingAvailability: boolean = false;
  public institutes: Array<Array<Institute>> = [];
  public formGroup: FormGroup;
  public previousTab: number = 0;
  public validators: Validator = {
    education: false,
    professionalExperience: false,
    complementaryExperience: false,
    complementaryInfo: false,
    professionalObjectives: false,
  };
  public education: boolean  = false;
  public professionalExperience: boolean  = false;
  public complementaryExperience: boolean  = false;
  public complementaryInfo: boolean  = false;
  public professionalObjectives: boolean  = false;

  constructor(
    protected _snackBar: MatSnackBar,
    private _settingsUsersService: SettingsUsersService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private cdRef: ChangeDetectorRef) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadUserCareer();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public goBack() {
    this._router.navigate(['/configuracoes/detalhes-usuario/' + this._activatedRoute.snapshot.paramMap.get('userId')]);
  }

  public selectTravelAvailability(value: boolean) {
    this.travelAvailability = value;
  }

  public selectMovingAvailability(value: boolean) {
    this.movingAvailability = value;
  }

  public addProfessionalExperience(): void {
    const professionalExperiences = this.formGroup.get('professionalExperiences') as FormArray;
    professionalExperiences.push(
      this._createProfessionalExperienceForm()
    );
  }
  setValidator(validator): void {
    this.validators[validator.key] = validator.value;
  }

  public addCollege(): void {
    const colleges = this.formGroup.get('colleges') as FormArray;
    colleges.push(
      this._createCollegeForm()
    );
    this.institutes.push([]);
  }

  public addReward(): void {
    const rewards = this.formGroup.get('rewards') as FormArray;
    rewards.push(
      this._createRewardForm()
    );
  }

  public addCertificate(): void {
    const certificates = this.formGroup.get('certificates') as FormArray;
    certificates.push(
      this._createCertificateForm()
    );
  }

  public addLanguage(): void {
    const languages = this.formGroup.get('fixedLanguages') as FormArray;
    languages.push(
      this._createFixedLanguagesForm(),
    );
  }

  public addAbility(): void {
    const abilities = this.formGroup.get('abilities') as FormArray;
    abilities.push(
      this._createPerkForm()
    );
  }

  public addSkill(): void {
    const skills = this.formGroup.get('skills') as FormArray;
    skills.push(
      this._createSkillForm()
    );
  }

  private _loadUserCareer(): void {
    this._settingsUsersService.getUserCareer(
      this._activatedRoute.snapshot.paramMap.get('userId')
    ).subscribe(res => {
     this.formGroup = this._createFormGroup(res.data);

    }, () => {
      this.formGroup = this._createFormGroup( null );
    });
  }

  private _createFormGroup(career: UserCareer): FormGroup {
    return new FormGroup({
      'professionalExperience': new FormControl(career ? career.professionalExperience : false),
      'professionalExperiences': this._setProfessionalExperiencesFormArray(
        career && career.professionalExperiences ? career.professionalExperiences : []
      ),
      'colleges': career && career.colleges ? this._setCollegesFormArray(career.colleges) : new FormArray([this._createCollegeForm()]),
      'rewards': this._setRewardsFormArray(career && career.rewards ? career.rewards : []),
      'abilities': this._setPerksFormArray(career && career.abilities ? career.abilities : []),
      'fixedLanguages': this._setFixedLanguagesFormArray(career && career.languages ? career.languages : []),
      'fixedAbilities': new FormArray([
        this._createFixedAbilityForm('VBA *', career && career.abilities ? career.abilities : []),
        this._createFixedAbilityForm('Excel *', career && career.abilities ? career.abilities : []),
        this._createFixedAbilityForm('Software Estatístico', career && career.abilities ? career.abilities : []),
        this._createFixedAbilityForm('Pacote Office', career && career.abilities ? career.abilities : [])
      ]),
      'skills': this._setSkillsFormArray(career && career.skills ? career.skills : []),
      'certificates': this._setCertificatesFormArray(career && career.certificates ? career.certificates : []),
      'shortDateObjectives': new FormControl(career && career.shortDateObjectives ? career.shortDateObjectives : '', [Validators.required]),
      'longDateObjectives': new FormControl(career && career.longDateObjectives ? career.longDateObjectives : '', [Validators.required]),
      'desiredPosition': new FormControl(career && career.desiredPosition ? career.desiredPosition : '', [Validators.required])
    });
  }

  private _setProfessionalExperiencesFormArray(values: ProfessionalExperience[]): FormArray {
    return new FormArray(
      values.map((value) => this._createProfessionalExperienceForm(value))
    );
  }

  private _createProfessionalExperienceForm(value: ProfessionalExperience = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(value ? value.title : '', ),
      'role': new FormControl(value ? value.role : ''),
      'description': new FormControl(value ? value.description : ''),
      'startDate': new FormControl(value ? value.startDate : ''),
      'endDate': new FormControl(value ? value.endDate : '')
    });
  }

  private _setCollegesFormArray(values: College[]): FormArray {
    values.forEach(() => { this.institutes.push([]); });
      if (values.length > 0) {
        return new FormArray(values.map((value) => this._createCollegeForm(value)));
      }
  }

  private _createCollegeForm(value: College = null): FormGroup {
    return new FormGroup({
      'instituteId': new FormControl(value ? value.instituteId : '', [Validators.required]),
      'title': new FormControl(value ? value.title : '', [Validators.required]) ,
      'campus': new FormControl(value ? value.campus : ''),
      'name': new FormControl(value ? value.name : '', [Validators.required]),
      'academicDegree': new FormControl(value ? value.academicDegree : '', [Validators.required]),
      'status': new FormControl(value ? value.status : '', [Validators.required]),
      'completePeriod': new FormControl({value : value ? value.completePeriod : '',
        disabled: value && value.status !== 'Completo' }),
      'startDate': new FormControl(value ? moment(value.startDate).format('MM/YYYY') : '',  [Validators.required]),
      'endDate': new FormControl(value ? moment(value.endDate).format('MM/YYYY')  : '', [Validators.required]),
      'cr': new FormControl(value ? value.cr : '')
    });
  }

  private _setRewardsFormArray(values: Reward[]): FormArray {
    return new FormArray(
      values.map((value) => this._createRewardForm(value))
    );
  }

  private _createRewardForm(value: Reward = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(value ? value.title : ''),
      'name': new FormControl(value ? value.name : ''),
      'link': new FormControl(value ? value.link : ''),
      'date': new FormControl(value ? value.date : ''),
    });
  }

  private _setPerksFormArray(values: Perk[]): FormArray {
    const fixed = ['VBA *', 'Excel *', 'Software Estatístico', 'Pacote Office'];
    values = values.filter(v => !fixed.includes(v.name));
    return new FormArray(
      values.map((value) => this._createPerkForm(value))
    );
  }

  private _createPerkForm(value: Perk = null): FormGroup {
    return new FormGroup({
      'name': new FormControl(value ? value.name : ''),
      'level': new FormControl(value ? value.level : '')
    });
  }

  private _setSkillsFormArray(values: string[]): FormArray {
    return new FormArray(
      values.map((value) => this._createSkillForm(value))
    );
  }

  private _createSkillForm(value: string = ''): FormGroup {
    return new FormGroup({
      'name': new FormControl(value)
    });
  }

  private _setCertificatesFormArray(values: Certificate[]): FormArray {
    return new FormArray(
      values.map((value) => this._createCertificateForm(value))
    );
  }

  private _createCertificateForm(value: Certificate = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(value ? value.title : ''),
      'link': new FormControl(value ? value.link : '')
    });
  }

  private _createFixedAbilityForm(name: string, values: Perk[]): FormGroup {
    const hasPerk = values.find(v => v.name === name);

    return new FormGroup({
      'name': new FormControl(name),
      'hasLevel': new FormControl(values.length > 0 ? (hasPerk ? true : false) : null),
      'level': new FormControl(hasPerk ? hasPerk.level : '')
    });
  }

  private _setFixedLanguagesFormArray(values: PerkLanguage[]): FormArray {
    return new FormArray(
      values.map((value) => this._createFixedLanguagesForm(value))
    );
  }

  private _createFixedLanguagesForm(value: PerkLanguage = null): FormGroup {
    return new FormGroup({
      'names': new FormControl(value ? value.names : ''),
      'languages': new FormControl(value ? value.languages : ''),
      'level': new FormControl(value ? value.level : '')
    });
  }

  public nextStep(event: MatTabChangeEvent | number) {
    switch (this.tabGroup.selectedIndex) {
      case 1:
          this.academicEducations.validField();
          break;
      case 2:
          this.professionalExperiences.validField();
          break;
      case 3:
          this.complementaryExperiences.validField();
          break;
      case 4:
          this.complementaryInfos.validField();
          break;
      default:
        break;
    }
    this.nextStepClick(event);
  }

  public nextStepClick(event): void {
    if (typeof (event) === 'object') {
      this.tabGroup.selectedIndex = event.index;
    } else {
      this.tabGroup.selectedIndex = event;
    }
    this.previousTab = this.tabGroup.selectedIndex;
  }

  public checkAllForm(): void {
    this.academicEducations.validField();
    this.professionalExperiences.validField();
    this.professionalsObjectives.validField();
    this.complementaryExperiences.validField();
    this.complementaryInfos.validField();
  }

  public saveCareer(): void {
    const career = this.formGroup.getRawValue();
    const userId = this._activatedRoute.snapshot.paramMap.get('userId');
    this.checkAllForm();
      if (
          this.validators.professionalObjectives  &&
          this.validators.complementaryExperience &&
          this.validators.professionalExperience  &&
          this.validators.complementaryInfo &&
          this.validators.education
        ) {
        this._settingsUsersService.updateUserCareer(
          userId,
          this._adjustCareer(career)
        ).subscribe(() => {
          this.notify('Informações salvas com sucesso');
          this._router.navigate([`/configuracoes/detalhes-usuario/${userId}`]);
        }, (error) => this.notify(this.getErrorNotification(error)));
      } else {
        this.notify('Existe algum campo obrigatório em branco/ e ou invalido');
      }

}

  private _adjustCareer(career): UserCareer {
    career = this._adjustAcademicEducationDates(career);
    career.skills = career.skills.map(x => x.name);
    career.professionalExperiences = career.professionalExperience ? career.professionalExperiences : [];
    career.travelAvailability = this.travelAvailability;
    career.movingAvailability = this.movingAvailability;
    return career;
  }

  private _adjustAcademicEducationDates(career: UserCareer) {
    const datePattern = 'MMYYYY';
    if (career.colleges && career.colleges.length > 0) {
      career.colleges.forEach(college => {
        college.startDate = moment(college.startDate, datePattern).toDate();
        college.endDate = moment(college.endDate, datePattern).toDate();
      });
    }
    return career;
  }
  public checkDate(fromDate: Date, toDate: Date): boolean {
    if (fromDate && toDate) {
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      if (fromDate > toDate) {
        return false;
      } else {
        return true;
      }
    } else if (fromDate) {
      return true;
    } else {
      return false;
    }
  }
}
