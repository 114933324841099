import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { Sector } from 'src/app/models/sector.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { GenerateValuationTestCheckOptionsComponent } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.component';
import { Data } from 'src/app/shared/components/generate-valuation-test-check-options/model/data.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { SectorService } from '../../_services/sector.service';
import { SegmentService } from '../../_services/segments.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-sector',
  templateUrl: './new-sector.component.html',
  styleUrls: ['./new-sector.component.scss']
})
export class NewSectorComponent extends NotificationClass implements OnInit {

  @ViewChild('checkedComponent', { static: false })
  public checkedComponent: GenerateValuationTestCheckOptionsComponent;

  public segments: Data[];
  public sectorId: string;
  public sector: Sector = new Sector();
  constructor(
    private _segmentsService: SegmentService,
    private _sectorService: SectorService,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.sectorId = this._activatedRoute.snapshot.params['setorId'];
    if (!isNil(this.sectorId))
      this.loadSector();
    this.loadSegments();
  }

  public loadSegments() {
    this._segmentsService.GetAllSegments().subscribe(response => {
      this.segments = response.data.map(value => ({
        id: value.id,
        title: value.name,
        checked: value.sector !== null && value.sector.id === this.sectorId,
        info: value.sector !== null ? {
          id: value.sector.id,
          name: value.sector.name,
          text: `Vinculado ao setor: ${value.sector.name}`
        } : null
      } as Data));
    });
  }

  public loadSector() {
    this._sectorService.getById(this.sectorId).subscribe(response => {
      this.sector = response.data;
    });
  }

  public handleSegmenteSelected(segement: Data[]) {
    const hasLinked = segement.filter(segment => !isNil(segment.info) && !isNil(segment.info.id) && segment.info.id !== this.sectorId);

    if (hasLinked.length > 0) {
      let sectorNames = '';
      let segmentsNames = '';
      hasLinked.forEach((value, index) => sectorNames = index === 0 ? value.title : `${sectorNames},${value.title}`);
      hasLinked.forEach((value, index) => segmentsNames = index === 0 ? value.info.name : `${segmentsNames},${value.info.name}`);

      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        width: '500px',
        data: {
          message: `O(s) segmento(s) <strong>${sectorNames}</strong>
        estão vinculados ao(s) setore(s) <strong>${segmentsNames}</strong>. Gostária de desvincular ?`
        }
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        const ids = hasLinked.map(value => value.id);
        this.checkedComponent.dataSelected = this.checkedComponent.dataSelected
          .filter(segment => !ids.some(segmentId => segmentId === segment.id));
        if (result) {
          this._segmentsService.UnlinkSector(ids).subscribe(response => {
            this.checkedComponent.dataSelected = [...this.checkedComponent.dataSelected, ...response.data.map(value => ({
              id: value.id,
              title: value.name,
              checked: true
            } as Data))];
          });
        } else {
          this.segments = [...this.segments];
        }

      });
    }

  }

  public save() {
    const segmentsSelected = this.checkedComponent.getTrackThatModuleWillBeDisplayed;
    const ids = segmentsSelected ? segmentsSelected.map(value => value.id) : [];
    if (isNil(this.sectorId)) {
      this._sectorService.addSector(this.sector.name, ids).subscribe(response => {
        this._location.replaceState(`configuracoes/setor/${response.data.id}`);
        this.sectorId = response.data.id;
        this.sector.id = this.sectorId;
        this.notify("Setor salvo com sucesso");
      });
    } else {
      this._sectorService.updateSector(this.sector.name, this.sector.id, ids).subscribe(_ => {
        this.notify("Setor salvo com sucesso");
      });
    }
  }

}
