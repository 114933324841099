import { Component } from '@angular/core';

@Component({
  selector: 'app-professional-performance-history',
  templateUrl: './professional-performance-history.component.html',
  styleUrls: ['./professional-performance-history.component.scss']
})
export class ProfessionalPerformanceHistoryComponent {

  constructor() { }



}
