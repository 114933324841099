import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { Segment } from 'src/app/models/segment.model';

@Injectable({
    providedIn: 'root'
})
export class SegmentService {

    constructor(
        private _httpService: BackendService) { }


    public GetSegmentBySectorId(sectorId: string): Observable<any> {
        return this._httpService.get('GetBySectorId', [], [{
            'name': 'sectorId', 'value': sectorId
        }]);
    }

    public GetAllSegments(): Observable<any> {
        return this._httpService.get('GetAllSegment', [], []);
    }

    public GetAllSegmentsPaged(page: number, pageSize: number): Observable<any> {
        return this._httpService.get('GetAllSegmentPaged', [], [
            { 'name': 'page', 'value': page.toString() },
            { 'name': 'pageSize', 'value': pageSize.toString() }
        ]);
    }

    public UnlinkSector(segmentsIds: string[]): Observable<any> {
        return this._httpService.post('UnlinkSector', {
            segmentIds: segmentsIds
        });
    }

    public addSegment(name: string, sectorId: string): Observable<BackendResponse<Segment>> {
        return this._httpService.post('addSegment', {
            name,
            sectorId
        });
    }
    public deleteSegment(segmentId: string): Observable<any> {
        return this._httpService.delete('deleteSegment', [{
            name: 'segmentId', value: segmentId
        }]);
    }
    public updateSegment(name: string, sectorId: string, segmentId: string): Observable<any> {
        return this._httpService.put('updateSegment', {
            name,
            sectorId,
            segmentId
        });
    }
    public GetSegmentById(segmentId: string): Observable<any> {
        return this._httpService.get('GetSegmentById', [], [{
            'name': 'id', 'value': segmentId
        }]);
    }
}
