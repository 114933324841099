import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

// Components
import { GenericDialogComponent } from '../../dialogs/generic-dialog/generic-dialog.component';
// Models
import { DialogData } from './models/dialog-data.model';
import { DialogOptions } from './models/dialog-options.model';
// Services
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class DialogFactoryService<T = undefined> {
  constructor(private dialog: MatDialog) {}

  open(
    dialogData: DialogData<T>,
    options: DialogOptions = { width: 500, height: 500, disableClose: true }
  ): DialogService<T> {
    const dialogRef = this.dialog.open<GenericDialogComponent<T>, DialogData<T>>(
        GenericDialogComponent,
      {
        ...this.fetchOptions(options),
        data: dialogData,
        panelClass: 'custom-generic-dialog-class'
      }
    );

    dialogRef.afterClosed().pipe(first());

    return new DialogService(dialogRef);
  }

  private fetchOptions({
    width,
    height,
    disableClose
  }: DialogOptions): Pick<
    MatDialogConfig<DialogData<T>>,
    'width' | 'height' |'disableClose'
  > {
    return {
      width: `${width}px`,
      height: `${height}px`,
      disableClose
    };
  }
}
