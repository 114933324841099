import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropDialogComponent } from '../../../../../shared/dialogs/image-crop/image-crop.dialog';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { UtilService } from '../../../../../shared/services/util.service';
import { Track, TrackArea, TrackSubArea } from '../../../../../models/track.model';
import { environment } from 'src/environments/environment';
import { SettingsProfileTestsService } from 'src/app/settings/_services/profile-tests.service';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { ProfileTest } from 'src/app/models/profile-test.interface';
import { UploadService } from 'src/app/shared/services/upload.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { durationValidator } from '../../validators/duration.validator';
import { CascateSelectComponent } from 'src/app/shared/components/cascate-select/cascate-select.component';
import { EngagementMethodEnum } from 'src/app/models/enums/engagementMethod.enum';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { User } from 'src/app/settings/users/user-models/user';
import { ECourseAvailability } from 'src/app/models/enums/course-availability.enum';

@Component({
  selector: 'app-new-track-track-info',
  templateUrl: './track-info.component.html',
  styleUrls: ['../new-track-steps.scss', './track-info.component.scss']
})
export class NewTrackTrackInfoComponent extends NotificationClass implements OnInit, OnDestroy {

  @ViewChild('cascateSelect', { static: false }) cascateSelect: CascateSelectComponent;

  @Input() public track$: Observable<Track>;
  public track: Track;
  @Output() setTrackInfo = new EventEmitter<Track>();

  public formGroup: FormGroup;
  public requesters: Array<User>;
  public selectedRequester: string = '';
  public RequestersimageUrl: string = '';
  public hasEcommerceIntegration: boolean = environment.ecommerceIntegration;
  public hasProfileTest: boolean = environment.features.profileTest;
  public readonly engagementMethod = EngagementMethodEnum;
  public tests: Array<ProfileTest>;
  public selectedTest: string = '';
  public courseAvailabilityEnum = ECourseAvailability;

  private _unsubscribeAll = new Subject<any>();

  public trackAreas: TrackArea[] = [];
  public trackAreaId: string = '';
  public trackSubAreas: TrackSubArea[] = [];
  public trackSubAreaId: string = '';

  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _utilService: UtilService,
    private _tracksService: SettingsTracksService,
    private _usersService: SettingsUsersService,
    private _testsService: SettingsProfileTestsService,
    private _uploadService: UploadService,
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this._loadTrackAreas();
    this._loadTrackSubAreas();

    if (this.track$) {
      this.track$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(trackItems => {
        this.track = trackItems;
        this.trackAreaId = this.track?.area?.id;
        this.trackSubAreaId = this.track?.subArea?.id;
        this.RequestersimageUrl = this.track?.requesterImageUrl;
        this.selectedRequester = this.track?.requester;
        if (this.formGroup && trackItems) {
          this.updateComponent(trackItems);
        } else {
          this.formGroup = this._createFormGroup(trackItems);
        }
        if (trackItems.profileTestId && trackItems.profileTestName) {
          this.selectedTest = trackItems.profileTestName;
        }
      });
    }
  }

  private _loadTrackAreas(): void {
    this._tracksService.getPagedFilteredTrackAreas(1, 1000)
      .subscribe((response) => {
        this.trackAreas = response.data.trackAreas;
      });
  }

  public changeTrackAreaId(evt: any) {
    this.trackAreaId = evt.value;
  }

  private _loadTrackSubAreas(): void {
    this._tracksService.getPagedFilteredTrackSubAreas(1, 1000)
      .subscribe((response) => {
        this.trackSubAreas = response.data.trackSubAreas;
      });
  }

  public changeTrackSubAreaId(evt: any) {
    this.trackSubAreaId = evt.value;
  }

  public updateComponent(track: Track) {

    this.formGroup.patchValue(
      {
        'title': track ? track.title : '',
        'published': track ? track.published : '',
        'requireUserCareer': track ? track.requireUserCareer : '',
        'allowedPercentageWithoutCareerInfo': track ? track.allowedPercentageWithoutCareerInfo ?
          track.allowedPercentageWithoutCareerInfo : 0 : 0,
        disabled: track ? !track.requireUserCareer : true,
        'storeUrl': track ? track.storeUrl : '',
        'ecommerceUrl': track ? track.ecommerceUrl : '',
        'createInEcommerce': track ? track.createInEcommerce : false,
        'description': track ? track.description : '',
        'imageUrl': track && track.imageUrl ? track.imageUrl : './assets/img/420x210-placeholder.png',
        'tags': track && track.tags ? track.tags : [],
        'profileTestId': track ? track.profileTestId : '',
        'profileTestName': track ? track.profileTestName : '',
        'validFor': track ? track.validFor : '',
        'duration': track && track.duration ? this._utilService.SecondToHours(Number(track.duration)) : '',
        'timeInDaysToCompleteTheTrack': track && track.timeInDaysToCompleteTheTrack ? track.timeInDaysToCompleteTheTrack : '',
        'engagementMethod': track ? track.engagementMethod : 0,
        'trackAreaId': track ? track.trackAreaId : '',
        'trackSubAreaId': track ? track.trackSubAreaId : '',
        'requester': track ? track.requester : '',
        'requesterImageUrl': track ? track.requesterImageUrl : '',
        'availability': track && track.availability ? track.availability : ECourseAvailability.available
      }
    );
  }

  public uploadImage(imageWidth: number, imageHeight: number, controlName: string) {
    const dialogRef = this._dialog.open(ImageCropDialogComponent, {
      width: window.innerWidth < 768 ? '100vw' : '50vw',
      data: {
        'canvasWidth': window.innerWidth < 768 ? 250 : 300,
        'canvasHeight': window.innerWidth < 768 ? 250 : 300,
        'croppedWidth': imageWidth,
        'croppedHeight': imageHeight
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.image) {
        this._uploadImage(
          result.image,
          this.formGroup.get(controlName),
          result.file.name,
        );
      }
    });
  }

  public addTag(event): void {
    const tags = event.target.value;
    if (tags.length !== 0) {
      const tagsArr = tags.split(',');
      tagsArr.forEach(tag => {
        if (tag.trim() !== '') {
          if (tag.length > 30) {
            this.notify('As TAGs devem ter no máximo 30 caracteres');
          } else {
            const tagsArray = this.formGroup.get('tags') as FormArray;
            tagsArray.push(
              new FormControl(tag)
            );
            event.target.value = '';
          }
        }
      });
    }
  }

  public removeTag(index: number): void {
    const tagsArray = this.formGroup.get('tags') as FormArray;
    tagsArray.removeAt(index);
  }

  public nextStep(): void {
    if (this.formGroup.valid) {
      const trackInfo = this.formGroup.getRawValue();
      trackInfo.duration = this._utilService.HourtoSecond(String(trackInfo.duration));
      trackInfo.requester = this.selectedRequester;
      trackInfo.requesterImageUrl = this.RequestersimageUrl;
      const selectedCascate = this.cascateSelect.GetSelectedItems;
      trackInfo.sector = selectedCascate.sector;
      trackInfo.segment = selectedCascate.segment;

      this.setTrackInfo.emit(trackInfo);
    } else {
      this.formGroup = this._utilService.markFormControlsAsTouch(this.formGroup);
      this.notify('Por favor, preencha todos os campos obrigatórios');
    }
  }

  public getDuration(duration: string): string {
    return this._utilService.getDurationFromFormattedHour(duration).toString();
  }
  public triggerTestSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this._loadTests(searchValue);
      this.selectedTest = searchValue;
    } else
      this.tests = null;
  }

  public setTest(test: ProfileTest): void {
    this.selectedTest = test.title;
    this.formGroup.get('profileTestId').setValue(test.id);
    this.formGroup.get('profileTestName').setValue(test.title);
    this.tests = null;
  }

  public removeTest(): void {
    this.formGroup.get('profileTestId').setValue('');
    this.formGroup.get('profileTestName').setValue('');
    this.selectedTest = '';
    this.tests = null;
  }

  private _createFormGroup(track: Track): FormGroup {
    return new FormGroup({
      'title': new FormControl(
        track ? track.title : '', [Validators.required]
      ),
      'published': new FormControl(
        track ? track.published : '', [Validators.required]
      ),
      'requireUserCareer': new FormControl(
        track ? track.requireUserCareer : '', [Validators.required]
      ),
      'allowedPercentageWithoutCareerInfo': new FormControl({
        value: track ? track.allowedPercentageWithoutCareerInfo ?
          track.allowedPercentageWithoutCareerInfo : 0 : 0,
        disabled: track ? !track.requireUserCareer : true
      }),
      'storeUrl': new FormControl(
        track ? track.storeUrl : ''
      ),
      'ecommerceUrl': new FormControl(
        track ? track.ecommerceUrl : ''
      ),
      'createInEcommerce': new FormControl(
        track ? track.createInEcommerce : false
      ),
      'description': new FormControl(
        track ? track.description : '', [Validators.required]
      ),
      'imageUrl': new FormControl(
        track && track.imageUrl ? track.imageUrl : './assets/img/420x210-placeholder.png', [Validators.required]
      ),
      'tags': new FormArray(
        track && track.tags ? track.tags.map(tag => new FormControl(tag)) : []
      ),
      'profileTestId': new FormControl(
        track ? track.profileTestId : ''
      ),
      'profileTestName': new FormControl(
        track ? track.profileTestName : ''
      ),
      'validFor': new FormControl(
        track ? track.validFor : ''
      ),
      'duration': new FormControl(
        track && track.duration ? this._utilService.SecondToHours(Number(track.duration)) : '', [Validators.required, durationValidator()]
      ),
      'timeInDaysToCompleteTheTrack': new FormControl(
        track && track.timeInDaysToCompleteTheTrack ? track.timeInDaysToCompleteTheTrack : ''
      ),
      'engagementMethod': new FormControl(
          track && track.engagementMethod ? track.engagementMethod : 0
      ),
      'trackAreaId': new FormControl(
        track ? track.trackAreaId : ''
      ),
      'trackSubAreaId': new FormControl(
        track ? track.trackSubAreaId : ''
      ),
      'requester': new FormControl(
        track ? track.requester : ''
      ),
      'requesterImageUrl': new FormControl(
        track ? track.requesterImageUrl : './assets/img/240x240-placeholder.png'
      ),
      'availability': new FormControl(track && track.availability ? track.availability : ECourseAvailability.available)
    });
  }


  private _uploadImage(base64Image: string, formControl: AbstractControl, fileName: string) {

    this._uploadService.uploadFile(base64Image, fileName).subscribe(uploadedImageURL => formControl.setValue(uploadedImageURL)
      , () => {
        this.notify('Ocorreu um erro ao enviar a imagem, por favor tente novamente mais tarde');
      });

  }


  public triggerRequesterSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this._loadRequester(searchValue);

    }
  }
  private _loadRequester(searchValue: string = ''): void {
    this._usersService.getProfessors(searchValue).subscribe(response => {
      this.requesters = response.data;
    });
  }
  public resetRequestersSearch() {
    this.requesters = [];
  }
  public addRequester(requester: User) {
    if (requester.imageUrl)
      this.formGroup.get('requesterImageUrl').setValue(requester.imageUrl);
    this.RequestersimageUrl = requester.imageUrl;

    this.selectedRequester = requester.name;
    this.resetRequestersSearch();
  }
  public removeSelectedRequester() {
    this.selectedRequester = '';
  }



  public requiredUserCareerDisabled(value: boolean) {
    if (value) {
      this.formGroup.get('allowedPercentageWithoutCareerInfo').enable();
    } else {
      this.formGroup.get('allowedPercentageWithoutCareerInfo').setValue('0');
      this.formGroup.get('allowedPercentageWithoutCareerInfo').disable();
    }
  }

  private _loadTests(searchValue: string): void {
    this._testsService.getProfileTests().subscribe((response) => {
      this.tests = response.data;

    }, (error) => this.notify(this.getErrorNotification(error)));
  }
  public comparefn(t1: any, t2: any) {
    return t1 === t2;
  }


}
