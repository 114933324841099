import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event } from '../../../../../models/event.model';
import { ConfirmDialogComponent } from '../../../../../shared/dialogs/confirm/confirm.dialog';
import { PrepQuizQuestions } from 'src/app/prepQuizQuestions.interface';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-new-event-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['../new-event-steps.scss', './questions.component.scss']
})
export class NewEventQuestionsComponent implements OnInit, OnDestroy {

  @Input() event$: Observable<Event>;
  public event: Event;
  @Output() addEventQuestions = new EventEmitter<Array<PrepQuizQuestions>>();

  public questions: Array<PrepQuizQuestions> = [];

  private _unsubscribeAll = new Subject<any>();

  constructor(
    private _dialog: MatDialog
  ) { }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((eventItems) => {
        this.event = cloneDeep(eventItems);
        if (eventItems && eventItems.prepQuizQuestionList)
        this.questions = cloneDeep(eventItems.prepQuizQuestionList);
      });
    }
  }

  public addQuestion(): void {
    this.questions.push(new PrepQuizQuestions());
  }

  public confirmRemoveQuestion(index: number): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja remover esta pergunta?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result)
        this._removeQuestion(index);
    });
  }

  public trackByFunc(index: any) {
    return index;
 }

  public nextStep(): void {
    this.addEventQuestions.emit( cloneDeep(this.questions) );
  }

  private _removeQuestion(index: number): void {
    this.questions.splice(index, 1);
  }
}
