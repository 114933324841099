<div class="outer-content">
  <nav class="inner-content">
    <a href="javascript:history.back()">
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      voltar
    </a>
    <h2>CRIAR NOVO GRUPO</h2>
    <button class="btn-test new-group-button" type="button" [disabled]="!formGroup?.valid" (click)="sendMail()">
      SALVAR GRUPO E ENVIAR
    </button>
  </nav>

  <div class="content">
    <div class="inner-content">
      <div class="user-content">
        <ng-container>
          <div>
            <h4>CARREGAMENTO EM LOTE</h4>
            <button class="btn-test new-group-button" type="button" (click)="openDialogImport()">
              IMPORTAR USUÁRIOS
            </button>
          </div>
        </ng-container>

        <ng-container>
          <h4>USUÁRIOS</h4>
          <div>
            <app-list-search #usersSelected placeholder="Digite o nome de um usuário para adicioná-lo"
              [noPadding]="true" (triggerSearch)="triggerUserSearch($event)"></app-list-search>
          </div>
          <app-list-search-items [dataSource]="users" propListName="name" (selectData)="setManuallyUsers($event)">
          </app-list-search-items>
        </ng-container>
        <ng-container>
          <div>
            <h4>SELEÇÃO POR PERÍODO DE CADASTRO</h4>
            <div class="content-date">
              <mat-form-field appearance="fill">
                <mat-label>Data de início</mat-label>
                <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="createAtStartDate"
                  (ngModelChange)="loadUsersByFilter()">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Data limite</mat-label>
                <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="createAtEndDate"
                  (ngModelChange)="loadUsersByFilter()">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div>
            <h4> SELEÇÃO POR TRILHA, TURMA OU PROGRAMA </h4>
            <app-list-search #trackSelect placeholder="Pesquise o nome de uma trilha, turma ou programa"
              [noPadding]="true" (triggerSearch)="triggerTrackSearch($event, 'tracks')"></app-list-search>
            <app-list-search-items [dataSource]="enrolledCourses" [dataSelected]="selectedEnrolledCourses"
              propListName="title" (selectData)="setEnrolledTracks($event)">
            </app-list-search-items>
          </div>
        </ng-container>
        <ng-container>
          <div>
            <h4> SELEÇÃO POR USUÁRIOS QUE NÃO ACESSARAM A PLATAFORMA</h4>
            <app-list-search placeholder="Pesquise o nome de uma trilha" [noPadding]="true"
              (triggerSearch)="triggerTrackSearch($event, 'usersTracks')"></app-list-search>
            <app-list-search-items [dataSource]="usersTracks" [dataSelected]="selectedNotAccessEnrolledCourses"
              propListName="title" (selectData)="setNotEnrolledTracks($event)"></app-list-search-items>
          </div>
        </ng-container>

        <div class="user-select">
          <p><span>{{selectedUsers.length + groupUsers.length}}</span> Usuários selecionados</p>
          <p class="users-link" (click)="SeeUsers()">Ver usuários</p>
        </div>
      </div>
      <div class="text-content">
        <h4>EMAIL</h4>
        <p class="sub">
          Escreva abaixo o conteudo do email. É possivel adicionar variaveis.<br>
          Para adicionar uma variável no corpo do email basta colocar entre --.<br>
          * Exemplo: "Olá --Nome--, seja bem vindo ao nosso sistema".<br><br>
          Variáveis disponíveis para substituição:<br>
          - Nome
        </p>
        <ng-container *ngIf="formGroup">
          <form [formGroup]="formGroup">
            <label class="label">título da mensagem</label>
            <mat-form-field floatLabel="never">
              <mat-placeholder>Digite o título</mat-placeholder>
              <input matInput required formControlName="title" maxlength="50" />
            </mat-form-field>
          </form>
        </ng-container>
        <label class="label">conteúdo da mensagem</label>
        <div id="htmlEditor"></div>
      </div>
    </div>
  </div>
</div>
