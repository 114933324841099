import { Pipe, PipeTransform } from '@angular/core';
import { Module } from 'src/app/models/module.model';
import { Valuations } from 'src/app/models/my-course-valuation.model';
import { TrackPreview } from 'src/app/models/previews/track.interface';

@Pipe({ name: 'hasRequirement' })
export class hasRequirementPipe implements PipeTransform {
    transform(course: Module | TrackPreview | Valuations): any {
        return course && !(course instanceof Valuations) && course.requirement && course.requirement.length > 0;
    }
}
