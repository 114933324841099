import { Component } from '@angular/core';

@Component({
  selector: 'app-trainning-performance',
  templateUrl: './trainning-performance.component.html',
  styleUrls: ['./trainning-performance.component.scss']
})
export class TrainningPerformanceComponent {

  constructor() { }



}
