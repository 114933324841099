<div class="header">
    <div class="inner-content" >
        <nav>
          <a href="javascript:history.back()">
            <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
            Voltar
          </a>
          <h2>{{test?.title}}</h2>
          <a></a>
        </nav>

    <div class="level">
      <ng-container>
        <div class="hdesc">
          <span>Data de Entrega</span>
        </div>
        <div class="hdesc">
          <span>Horário</span>
        </div>
      </ng-container>
    </div>
  

  <div class="content" >
    <div class="main" >
      <div id="form-content" *ngFor="let courseWork of test?.testQuestions; let i= index">
        <h5 [innerHtml]="courseWork.title | MarkdownToHtml"></h5>
        <form>
          <div class="validation-field">
            <div class="upload-content">
              <mat-form-field 
              id="input-folder">
                <input matInput  
                  readonly
                  [ngModel]="courseWork?.curseWorkFile?.fileName"
                  (ngModelChange)="courseWork.curseWorkFile.fileName ? $event : null"
                  name="file"
                  (click)="fileInput.click()"
                  placeholder="Arquivo do trabalho" 
                  />
              </mat-form-field>
              <input
                  style="display: none"
                  type="file" (change)="uploadImages($event, i)"
                  #fileInput />
              <div class="upload" for="input">
                <button type="button" (click)="fileInput.click()"> UPLOAD </button>
              </div> 
            </div> 
            </div>
          <div class="validation-field">
            <mat-form-field>
              <mat-label>Observações</mat-label>
              <textarea 
              [(ngModel)]="courseWork.comments"
              name="comments"
              matInput  ></textarea>
            </mat-form-field>
          </div>
        </form>
        <button type="button" class="enviar" (click)="save()"> ENVIAR </button>
    </div>   
    </div>
    <div class="sidebar">
        <p class="support">
            Material de Suporte
          </p>
        <div class="support-material" *ngFor="let supportMaterial of supportMaterials">
            <div>
                <h4>{{supportMaterial.title}}</h4>
            </div>
            <a  target="_blank" (click)="download(supportMaterial.downloadLink)" download={{supportMaterial.title}}>
                <img src="./assets/img/download-icon.png" />
            </a>
        </div>
    </div> 
  </div>
</div>
