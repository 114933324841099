import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUtilModule } from '@tg4/ng-util';
import { RouterModule } from '@angular/router';
import { SettingsEventsComponent } from './events.component';
import { MaterialComponentsModule } from '../../shared/material.module';
import { SettingsEventCardComponent } from './event-card/event-card.component';
import { SettingsNewEventModule } from './new-event/new-event.module';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { SettingsEventDetailsComponent } from './event-details/event-details.component';
import { ModuleSidebarModule } from 'src/app/shared/components/module-sidebar/module-sidebar.module';
import { ClassroomLessonHeaderModule } from 'src/app/pages/classroom-lesson/lesson-header/lesson-header.module';
import { ClassroomLessonRequiredModule } from 'src/app/pages/classroom-lesson/required-module/required-module.module';
import { SettingsNextEventCardComponent } from './event-details/next-event-card/next-event-card.component';
import { SettingsPastEventCardComponent } from './event-details/past-event-card/past-event-card.component';
import { DeleteEventDialogComponent } from './delete-event/delete-event.dialog';
import { ContentEventsService } from 'src/app/pages/_services/events.service';
import { SettingsEventResultsComponent } from './event-results/event-results.component';
import { SettingsEventsDraftsService } from '../_services/events-drafts.service';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import {
  EventAverageStudentGradesComponent
} from 'src/app/settings/events/events-average-student-grades/events-average-student-grades.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StudentGradeModule } from 'src/app/shared/components/student-grade/student-grade.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { EventStudentGradeComponent } from 'src/app/settings/events/event-student-grade/event-student-grade.component';
import { HeaderModule } from 'src/app/shared/components/header/header.module';

@NgModule({
    declarations: [
        SettingsEventsComponent,
        SettingsEventCardComponent,
        SettingsEventDetailsComponent,
        SettingsEventResultsComponent,
        SettingsNextEventCardComponent,
        SettingsPastEventCardComponent,
        DeleteEventDialogComponent,
        EventAverageStudentGradesComponent,
        EventStudentGradeComponent
    ],
    exports: [EventAverageStudentGradesComponent, EventStudentGradeComponent],
    imports: [
        BrowserModule,
        RouterModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgUtilModule,
        SettingsNewEventModule,
        PaginationModule,
        ModuleSidebarModule,
        ClassroomLessonHeaderModule,
        ClassroomLessonRequiredModule,
        NgxDatatableModule,
        StudentGradeModule,
        MatDialogModule,
        MatIconModule,
        MatRadioModule,
        HeaderModule
    ],
    providers: [
        ContentEventsService,
        SettingsEventsDraftsService
    ]
})
export class SettingsEventsModule { }
