<div class="effort-content">
  <ng-content></ng-content>
  <p class="sub-title">{{text}}</p>
  <div class="progress-content">
    <div [ngStyle]="{'width.%': metrics?.real}">
      <app-progress-bar [currentProgress]="100" [height]="8" [roundedBorder]="true" [color]="primary_color" fillingColor="#FFFFFF"></app-progress-bar>
    </div>
    <span class="real">
      {{!isNullOrUndefined(metrics?.realInHours) ? metrics?.realInHours.toFixed(2) + 'h' : metrics?.real.toFixed(2) + '%'}}
    </span>
  </div>
  <div class="progress-content" *ngIf="!isNullOrUndefined(metrics?.expected) || !isNullOrUndefined(metrics?.expectedInHours)">
    <div [ngStyle]="{'width.%': metrics?.expected}">
      <app-progress-bar [height]="8" [roundedBorder]="true" color="#FF4376" [currentProgress]="100"
        fillingColor="#FFFFFF">
      </app-progress-bar>
    </div>
    <span class="expected">
      {{!isNullOrUndefined(metrics?.expectedInHours) ? metrics?.expectedInHours.toFixed(2) + 'h' : metrics?.expected.toFixed(2) + '%'}}
    </span>
  </div>
  <div>
    <ul class="legend">
      <li class="real"></li>
      <li class="expected" *ngIf="!isNullOrUndefined(metrics?.expected) || !isNullOrUndefined(metrics?.expectedInHours)"></li>
    </ul>
  </div>
</div>
