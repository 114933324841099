import { Component, OnInit, HostListener, ElementRef, OnDestroy, Inject } from '@angular/core';
import { animations } from './app.animations';
import { Location } from '@angular/common';
import { AuthService } from './shared/services/auth.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { ActionInfo } from './shared/directives/save-action/action-info.interface';
import { ColorKey } from './models/color-key';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserPreviewInterceptor } from './shared/interfaces/user-preview-interceptor.interface';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EffortService } from 'src/app/shared/services/effort.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LayoutService } from './shared/services/layout.service';
import { DOCUMENT } from '@angular/common';
import { SimpleDialog } from './shared/components/simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from './shared/components/simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleGuard } from './shared/guards/role.guard';
import { ERole } from './models/enums/role.enum';
import { isNil } from 'lodash';
import { ChatBotService } from './shared/chatbot.service';


declare const gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: animations
})
export class AppComponent implements OnInit, OnDestroy {

  public hasFeatureFocerUserCompletInfos = environment.features.userInfoIsCompleted;

  public opened = false;
  public route: string;
  public isImpersonating: boolean = false;
  public impersonatedInfo: UserPreviewInterceptor;
  private _impersonationSubscription: Subscription;
  public isLoggedIn: boolean = false;
  public navigateEndPath$: Subject<string> = new Subject<string>();
  constructor(
    private _authService: AuthService,
    private _actionsService: AnalyticsService,
    private elementRef: ElementRef,
    private _dialog: MatDialog,
    private _router: Router,
    private _chatBotService: ChatBotService,
    _effortService: EffortService,
    router: Router,
    location: Location,
    layoutService: LayoutService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    layoutService.setSvgIcons();
    _effortService.init();
    router.events.subscribe(() => {
      if (location.path() !== '') {
        this.route = location.path().split('/')[1];
      }
    });
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', environment.analyticsId, {
          'page_path': event.urlAfterRedirects.indexOf('ssosuccess=true') > 0 ? '/?ssosuccess=true' : event.urlAfterRedirects
        });
        this.navigateEndPath$.next(event.url);
      });
    const environmentName = environment.logo.split('.');
    this._document.getElementById('appFavicon').setAttribute('href', `/../assets/img/icone-${environmentName[0]}.png`);

    if (environment.ibentaKey !== '') {
      _chatBotService.inbentaLogin().subscribe(value => {
        _chatBotService.init(value.data, environment.ibentaKey);
      });
    }

  }

  ngOnInit() {
    this._authService.isLoggedIn().subscribe((isLogged) => {
      this.isLoggedIn = isLogged;
      if (!isLogged) {
        this.opened = false;
      } else {
        const loggedUser = this._authService.getLoggedUser();
        if (loggedUser && loggedUser.role === ERole.Student && this.hasFeatureFocerUserCompletInfos)
          this.forceUpdateUserInfos();
      }
    });

    this._authService.setUserColorPalette().subscribe((set) => {
      this._changeTheme(set);
    });

    this.impersonatedInfo = this._authService.getImpersonatedUser();
    this.isImpersonating = this.impersonatedInfo && true;

    this._impersonationSubscription = this._authService.personificationChanged$.subscribe(
      (isImpersonating) => {
        this.isImpersonating = isImpersonating;
        this.impersonatedInfo = this._authService.getImpersonatedUser();
      }
    );

    Chart.plugins.unregister(ChartDataLabels);
  }

  public getPage(outlet) {
    return outlet.activatedRouteData['page'] || 'home';
  }

  public toogleSideNav() {
    this.opened = !this.opened;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    // $event.returnValue = true;
    const actions = this._actionsService.getStorageWaitingActions();
    actions.forEach(action => this._setExamFinishAction(action));
    this._actionsService.clearAllActions();
  }

  private _setExamFinishAction(actionInfo: ActionInfo): void {
    this._actionsService.saveAction(actionInfo).subscribe(
      () => { },
      (error) => { console.error(error); });
  }

  private _changeTheme(isLogged: boolean) {
    const colorList = localStorage.getItem(LocalStorageService.key.colorPalette);
    if (isLogged) {
      if (colorList) {
        const parsedColorList: ColorKey[] = JSON.parse(colorList);
        parsedColorList.forEach(colorKey => {
          if (colorKey.key && colorKey.color) {
            this.elementRef.nativeElement.style.setProperty(colorKey.key, colorKey.color);
          }
        });
      }
    } else {
      this._setDefaultColors();
      localStorage.removeItem(LocalStorageService.key.colorPalette);
    }
  }

  private _setDefaultColors() {
    const style = getComputedStyle(document.body);
    this.elementRef.nativeElement.style.setProperty('--primary-color', style.getPropertyValue('--primary-color'));
    this.elementRef.nativeElement.style.setProperty('--alternate-primary-color', style.getPropertyValue('--alternate-primary-color'));
    this.elementRef.nativeElement.style.setProperty('--semi-primary-color', style.getPropertyValue('--semi-primary-color'));
    this.elementRef.nativeElement.style.setProperty('--card-primary-color', style.getPropertyValue('--card-primary-color'));
    this.elementRef.nativeElement.style.setProperty('--third-primary-color', style.getPropertyValue('--third-primary-color'));
    this.elementRef.nativeElement.style.setProperty('--divider-color', style.getPropertyValue('--divider-color'));
    this.elementRef.nativeElement.style.setProperty('--selected-color', style.getPropertyValue('--selected-color'));
    this.elementRef.nativeElement.style.setProperty('--progress-bar-uncompleted', style.getPropertyValue('--progress-bar-uncompleted'));
    this.elementRef.nativeElement.style.setProperty('--progress-bar-completed', style.getPropertyValue('--progress-bar-completed'));
    this.elementRef.nativeElement.style.setProperty('--danger-color', style.getPropertyValue('--danger-color'));
    this.elementRef.nativeElement.style.setProperty('--warn-color', style.getPropertyValue('--warn-color'));
    this.elementRef.nativeElement.style.setProperty('--success-color', style.getPropertyValue('--success-color'));
    this.elementRef.nativeElement.style.setProperty('--text-color', style.getPropertyValue('--text-color'));
    this.elementRef.nativeElement.style.setProperty('--alt-text-color', style.getPropertyValue('--alt-text-color'));
    this.elementRef.nativeElement.style.setProperty('--box-shadow', '1px 2px 6px 0px rgba(180, 180, 180, 1)');
    this.elementRef.nativeElement.style.setProperty('--header-accent', style.getPropertyValue('--header-accent'));
    this.elementRef.nativeElement.style.setProperty('--main-background', style.getPropertyValue('--main-background'));
    this.elementRef.nativeElement.style.setProperty('--light-color', style.getPropertyValue('--light-colorr'));
    this.elementRef.nativeElement.style.setProperty('--footer-background', style.getPropertyValue('--footer-background'));
    this.elementRef.nativeElement.style.setProperty('--menu-content-diveder', style.getPropertyValue('--menu-content-diveder'));
    this.elementRef.nativeElement.style.setProperty('--scroll-background', style.getPropertyValue('--scroll-background'));
    this.elementRef.nativeElement.style.setProperty('--buttons-default-color', style.getPropertyValue('--buttons-default-color'));
    this.elementRef.nativeElement.style.setProperty('--empty-badge', style.getPropertyValue('--empty-badge'));
    this.elementRef.nativeElement.style.setProperty('--glasses-icon', style.getPropertyValue('--glasses-icon'));
    this.elementRef.nativeElement.style.setProperty('--buttons-default-color', style.getPropertyValue('--buttons-default-color'));
    this.elementRef.nativeElement.style.setProperty('--brain-icon', style.getPropertyValue('--brain-iconr'));
    this.elementRef.nativeElement.style.setProperty('--brain-dark-icon-shadow', style.getPropertyValue('--brain-dark-icon-shadow'));
    this.elementRef.nativeElement.style.setProperty('--pencil-icon', style.getPropertyValue('--pencil-icon'));
  }
  public forceUpdateUserInfos() {
    const messageDialog = '<strong>Você não completou o seu cadastro !</strong>\n  ' +
      'Para continuar utilizando a plataforma você precisa finalizar de preencher todas suas informações cadastrais';
    const labelButton = 'Preencher';

    this.navigateEndPath$.subscribe(path => {
      const skipedRouter = path.match('generate/certificate/');
      const loggedUser = this._authService.getLoggedUser();
      const pathRouterRedirect = `/configuracoes/usuarios/${loggedUser.user_id}`;
      if (this.isLoggedIn &&
        !loggedUser.info_is_completed &&
        path !== pathRouterRedirect &&
        !this._authService.isImpersonatedUser() &&
        this.hasFeatureFocerUserCompletInfos &&
        isNil(skipedRouter))
        this.showDialog(messageDialog, labelButton, pathRouterRedirect);
    });
  }
  public showDialog(message: string, positiveTextAction: string, router: string) {
    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      maxWidth: '515px',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        message: message,
        positiveTextAction: positiveTextAction
      } as SimpleDialog
    });

    const goToPage = () => this._router.navigate([router]);

    let observer: MutationObserver = null;

    dialogRef.afterOpened().subscribe(() => {
      const node = document.querySelector('.cdk-overlay-container');
      if (!node) goToPage();
      setTimeout(() => {
        observer = new MutationObserver(mutations => {
          dialogRef.close();
        });
        observer.observe(node, { attributes: true, childList: true, characterData: true, subtree: true });
      }, 2000);
    });

    dialogRef.afterClosed().subscribe(() => {
      if (observer) observer.disconnect();
      goToPage();
    });

  }

  ngOnDestroy() {
    this._impersonationSubscription.unsubscribe();
  }
}
