import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cockpit-details',
  template: `
    <div class="content">
        <h1 class="title">{{title}}</h1>
        <div class="items">
            <ng-content select="[first]"></ng-content>
        </div>
        <div class="items">
            <ng-content select="[second]"></ng-content>
        </div>
        <div class="items">
            <ng-content select="[third]"></ng-content>
        </div>
    </div>`,
  styleUrls: ['./cockpit-details.component.scss']
})
export class CockpitDetailsComponent {

  @Input() title: string;
}
