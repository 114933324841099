import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ErrorLog } from 'src/app/models/error-logs.interface';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { LogsService } from '../../_services/logs.service';
import { LogsDialogComponent } from '../logs-dialog/logs-dialog.component';

@Component({
  selector: 'app-logs-integrations',
  templateUrl: './logs-integrations.component.html',
  styleUrls: ['./logs-integrations.component.scss']
})
export class LogsIntegrationsComponent extends NotificationClass implements OnInit {

  @ViewChild('Picker', { static: true }) private _picker: DateRangePickerComponent;

  public logs: ErrorLog[] = [];
  public logsCount: number = 0;
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public readonly pageSize: number = 19;
  private _sortDirection: boolean = false;
  private _fromDate: Date = null;
  private _toDate: Date = null;
  public filter: {
    page: number,
    sortValue: string,
    sortDirection: boolean,
    sortAscending: boolean,
    fromDate: Date,
    toDate: Date,
  };
  public messages = {
    emptyMessage: 'Buscando dados...', totalMessage: 'Total', selectedMessag: 'selecionado',
  };
  public rowIdentity = (row) => row.id;
  constructor(
    private _logsService: LogsService,
    private _dialogService: MatDialog,
    private _excelService: ExcelService,
    protected _snackBar: MatSnackBar,
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.applyFilter();
  }

  private _loadProcesses({ page, sortValue, sortAscending = false, fromDate = null, toDate = null }): void {
    this._logsService.getPagedErrorLogsEcommerceIntegration(
      page, this.pageSize, sortValue, sortAscending, fromDate, toDate
    ).subscribe((response) => {
      this.logs = response.data.errorLogItems;
      this.logsCount = response.data.itemsCount;
      if (!this.logs.length) {
        this.messages.emptyMessage = 'Não há dados para exibir.';
      }
    });
  }

  public filterByDateRange(dates: Array<Date>) {
    this._fromDate = dates[0];
    this._toDate = dates[1];
  }

  public applyFilter(pageNumber?: number) {
    this.filter = {
      page: pageNumber ? pageNumber : 1,
      sortAscending: false,
      sortValue: 'createdAt',
      sortDirection: this._sortDirection,
      fromDate: this._fromDate,
      toDate: this._toDate,
    };

    this._loadProcesses(this.filter);
  }

  public goToPage(pageInfo: any) {
    this.applyFilter(pageInfo.offset + 1);
  }

  public onRowClick(event: any) {
    if (!event) return;

    const hasClickedInActionProp =
      event.hasOwnProperty('column') && event.column.name === 'Ação' && event.type === 'click';

    if (hasClickedInActionProp) {
      const newValues = JSON.parse(event.row.errorJson);
      const oldValues = newValues;
      const hideUnchanged = true;

      const dialogRef = this._dialogService.open(LogsDialogComponent, {
        maxWidth: '100vw',
        width: '100vw',
        minHeight: '100vh',
        height: '100vh',
        hasBackdrop: false,
        data: { newValues, oldValues, hideUnchanged }
      });
    }
  }

  public exportRequest() {

    this._logsService.getPagedErrorLogsEcommerceIntegration(
      1, this.logsCount, 'createdAt', false, this._fromDate, this._toDate
    ).subscribe((response) => {
      const logs = response.data.errorLogItems;

      if (!logs.length) {
        return this.notify('Não há dados para exibir.');
      }

      const excelData = logs.map(log => ({
          'Data': log.createdAt,
          'Tag': log.tag,
          'Mensagem de Erro': log.errorMessage,
          'JSON': log.errorJson
        }));

      this._excelService.exportAsExcelFile(excelData, 'log_erros_integração');
    });

  }
}
