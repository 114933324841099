<div class="header">
  <div class="level">
    <ng-container *ngIf="isManagement">
      <div class="hdesc">
        <span>Edições abertas</span>
        <span>{{ event ? event.schedules.length : '0' }}</span>
      </div>
      <div class="button-wrapper">
        <button class="btn-test" (click)="goEditEvent()">Editar informações do Evento </button>
        <!-- <button class="btn-test" (click)="goToInstructorDashboard">Dashboard do instrutor </button> -->
      </div>
    </ng-container>

    <ng-container *ngIf="!isManagement">
      <div class="hdesc">
        <span>Data do Curso</span>
        <span>{{ currentSchedule ? (currentSchedule.eventDate | date : 'dd/MM/yyyy') : '--' }}</span>
      </div>
      <div class="hdesc">
        <span>Horário</span>
        <span>{{ currentSchedule ? (currentSchedule.eventDate | date : 'HH:mm') : '--' }} h</span>
      </div>
      <div class="hdesc">
        <span>Data Limite Inscrição</span>
        <span>{{ currentSchedule ? (currentSchedule.subscriptionEndDate | date : 'dd/MM/yyyy') : '--' }}</span>
      </div>
      <div class="hdesc">
        <span>Horário</span>
        <span>{{ currentSchedule ? (currentSchedule.subscriptionEndDate | date : 'HH:mm') : '--' }} h</span>
      </div>

      <div class="hdesc status"
        *ngIf="subscriptionStatus && currentSchedule?.eventScheduleType?.id !== scheduleEnum.SynchronousExperience">
        <span>Status</span>
        <span>{{ subscriptionStatus }}</span>
      </div>

      <button class="btn-test" (click)="canApply()"
        *ngIf="currentSchedule?.eventScheduleType?.id  !== scheduleEnum.SynchronousExperience">
        {{!subscriptionStatus || !event?.prepQuizQuestionList?.length? 'Aplicar-se' : 'Editar respostas' }}
      </button>

      <button class="btn-test" (click)="openDialogForSupportMaterial()"
        *ngIf="event?.supportMaterials?.length > 0">Material suporte</button>


    </ng-container>
  </div>
  <div class="description">
    <p>{{ event?.excerpt }}</p>
  </div>
</div>
