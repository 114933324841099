import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CockpitService } from 'src/app/pages/_services/cockpit.service';

@Component({
  selector: 'app-cockpit-ranking-card',
  templateUrl: './cockpit-ranking-card.component.html',
  styleUrls: ['./cockpit-ranking-card.component.scss']
})
export class CockpitRankingCardComponent {

  @Input() ranking: number;
  constructor(public _cockpitService: CockpitService,
    private _router: Router) { }

  public seeStatus() {
    const trackId = this._cockpitService.getSelectedTrack().getValue().id;
    const selectedStudent = this._cockpitService.getSelectedStudents().getValue().id;
    this._router.navigate(['configuracoes/trilha-de-curso/' + trackId + '/' + selectedStudent]);
  }
}
