import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Content } from 'src/app/models/content.model';
import { DownloadService } from 'src/app/shared/services/download.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {

  @Input() set setContent(content: Content) {
    this.content = content;
  }
  @Output() saveDownloadFinishedAction: EventEmitter<string> = new EventEmitter();
  public content: Content;

  constructor(private _downloadService: DownloadService) { }

  public download(url: string) {
    this._downloadService._downloadFile('downloadFromS3', {
      'url': url
    }).subscribe(response => {
      this.saveDownloadFinishedAction.emit(this.content.id);
    });
  }
}
