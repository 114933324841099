import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { clone, orderBy, sum, values } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Filters } from 'src/app/pages/cockpit/cockpit-ranking/filter-ranking/models/filters';
import { IstudentsRanking } from 'src/app/pages/cockpit/models/trackStudentsSubordinates';
@Component({
  selector: 'app-track-ranking-dialog',
  templateUrl: './track-ranking-dialog.component.html',
  styleUrls: ['./track-ranking-dialog.component.scss']
})
export class TrackRankingDialogComponent implements OnInit {
  public ranking: IstudentsRanking[] = [];
  public showFactor: boolean = false;
  public customRanking: IstudentsRanking[] = [];

  public filters: Filters = new Filters();
  public inputChange: Subject<any> = new Subject();
  public cloneRanking: Array<IstudentsRanking>;

  constructor(
    public dialogRef: MatDialogRef<TrackRankingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      ranking: IstudentsRanking[],
      showFactor: boolean
    }
  ) {
    this.ranking = data.ranking;
    this.showFactor = data.showFactor;
  }

  ngOnInit() {
    this.cloneRanking = clone(this.ranking);
    this.orderRanking();

    this.inputChange.pipe(debounceTime(650)).subscribe((data) => {
      const total =
        this.filters.effort +
        this.filters.engagement +
        this.filters.valuation +
        this.filters.event +
        this.filters.learning +
        this.filters.participation -
        this.filters[data.target];
      if (total + data.value > 100) {
        this.filters[data.target] = 100 - total;
      } else {
        this.filters[data.target] = data.value;
      }
      this.orderRanking();
    });
  }

  public handleFiltersChange(event: number, target: string): void {
    this.inputChange.next({ value: Number(event), target: target });
  }

  public handleFiltersStudentChange(value: string) {
    this.cloneRanking = this.ranking.filter((student) => student.name.toLowerCase().search(value.toLowerCase()) !== -1);
    this.customRanking = orderBy(this.cloneRanking, ['factor'], ['desc']);
  }

  public orderRanking() {
    this.cloneRanking.forEach((ranking) => {
      ranking.factor = (
        (this.filters.effort * ranking.interactionUntilDay) +
        (this.filters.engagement * (ranking.progressUntilDay / 10)) +
        (this.filters.valuation * ranking.valuation) +
        (this.filters.event * ranking.averageEventGrade) +
        (this.filters.learning * ranking.learning) +
        (this.filters.participation * ranking.participation)
        /
        sum(values(this.filters))
      );
    });

    this.customRanking = orderBy(
      this.cloneRanking,
      ['factor', 'valuation', 'averageEventGrade', 'learning', 'progressUntilDay', 'interactionUntilDay', 'participation'],
      ['desc']
    );
  }

  public closeDialog() {
   this.dialogRef.close();
  }
}
