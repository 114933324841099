import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { isNil } from "lodash";
import { ImportExcelFilesComponent } from "src/app/shared/components/import-excel-files/import-excel-files.component";
import { IErroList } from "src/app/shared/components/import-excel-files/Models/erro-list.interface";
import { ExcelService } from "src/app/shared/services/excel.service";
import { TeamsService } from "../services/teams.service";
import { mandatoryCollumnEnum } from "./Enum/mandatory-collumn.enum";

@Component({
    selector: 'app-teams-import-dialog',
    templateUrl: '../../../shared/components/import-excel-files/import-excel-files.component.html',
    styleUrls: ['../../../shared/components/import-excel-files/import-excel-files.component.scss']
})
export class DialogTeamsImportComponent extends ImportExcelFilesComponent {
    public showRadionButtons: boolean = false;
    public name = 'Turmas';
    public pathTemplate: string = './../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_TURMAS.xlsx';
    public downloadName: string = 'PLANILHA_MODELO_IMPORTAÇÃO_TURMAS.xlsx';
    public subtitle = '';
    public get mandatoryCollumns(): string[] {
        return [
            mandatoryCollumnEnum.email,
            mandatoryCollumnEnum.team,
            mandatoryCollumnEnum.blocked,
        ];
    }

    public loading: boolean = false;
    public erroList: any;
    public data: any[] = [];
    constructor(_excelService: ExcelService,
        _snackBar: MatSnackBar,
        private _teamsService: TeamsService,
        private _dialogRef: MatDialogRef<DialogTeamsImportComponent>) {
        super(_excelService, _snackBar);
    }


    public validateJson(contentJson: any, headers: any): IErroList[] {
        let index = 0;
        const erroList: IErroList[] = [];

        while (index < contentJson.length) {
            const excelContent = contentJson[index];

            if (isNil(excelContent[mandatoryCollumnEnum.email])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.email),
                    row: excelContent['row'],
                    description: 'O campo "email" não pode ser nulo'
                });
            }

            if (isNil(excelContent[mandatoryCollumnEnum.team])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.team),
                    row: excelContent['row'],
                    description: 'Este campo "turma" não pode ser nulo'
                });
            }

            if (isNil(excelContent[mandatoryCollumnEnum.blocked])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.blocked),
                    row: excelContent['row'],
                    description: 'Este campo "bloquear" não pode ser nulo'
                });
            } else if (this.normalizeString(excelContent[mandatoryCollumnEnum.blocked]) !== 'sim' &&
                this.normalizeString(excelContent[mandatoryCollumnEnum.blocked]) !== 'nao'
            ) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.blocked),
                    row: excelContent['row'],
                    description: 'Esperava receber sim ou não'
                });
            }


            index++;
        }
        return erroList;
    }
    public setValues(contentJson: any, headers: any): void {
        let index = 0;
        while (index < contentJson.length) {
            const excelContent = contentJson[index];
            this.data.push({
                email: (excelContent[mandatoryCollumnEnum.email] as string).trim().toLowerCase(),
                team: excelContent[mandatoryCollumnEnum.team],
                blocked: this.normalizeString(excelContent[mandatoryCollumnEnum.blocked]) === 'sim',
            });
            index++;
        }
    }
    public save(): void {
        this.loading = true;
        this._teamsService.ImportTeam(this.data).subscribe(response => {
            this.loading = false;
            this._dialogRef.close(true);
        }, error => {
            this.loading = false;
            error.error.errors.forEach(err => {
                this.erroList.push({ description: err });
            });
        });
    }

    public dismiss() {
        this._dialogRef.close();
    }

}