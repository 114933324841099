import { cloneDeep, intersectionBy, isEmpty, isNil } from 'lodash';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Track } from '../../../../../models/track.model';
import { ValuationsConfiguration } from 'src/app/models/valuation-configuration';
import { TrackModule } from '../../../../../models/track-module.model';
import { TrackEvent } from '../../../../../models/track-event.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/shared/services/util.service';
import { ETrackItem } from 'src/app/models/enums/track-item.enum';


export enum ETrackWeight {
  Module,
  Event,
  Valuation
}

export type ValuationConfiguration = ValuationsConfiguration & { type: ETrackWeight };

@Component({
  selector: 'app-new-track-modules-weight',
  templateUrl: './modules-weight.component.html',
  styleUrls: ['../new-track-steps.scss', './modules-weight.component.scss']
})
export class NewTrackModulesEventsWeightComponent extends NotificationClass implements OnInit, OnDestroy {

  public readonly displayedColumns: string[] = [
    'content', 'weight'
  ];

  @Input() public track: Observable<Track>;
  @Output() addModulesEventsWeight = new EventEmitter<{ modules, events }>();

  public modules: Array<TrackModule> = [];
  public events: Array<TrackEvent> = [];
  public valuations: ValuationsConfiguration;
  public trackModulesEvents: Array<TrackModule | TrackEvent | ValuationConfiguration> = [];
  public trackItemType = {};
  public totalWeight: number = 0;

  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar, private _utilService: UtilService
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (this.track) {
      this.track.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(track => {
        this.trackItemType = {
          [ETrackItem.Event]: 'Evento Presencial',
          [ETrackItem.Module]: 'Módulo Online',
        };

        this.modules = cloneDeep(track.modulesConfiguration);
        this.events = cloneDeep(track.eventsConfiguration);

        this.trackModulesEvents = this.getTrackCards();
        this.setTotalValue();
      });
    }
  }

  public getTrackCards(): Array<TrackModule | TrackEvent | ValuationConfiguration> {
    let modulesEvents = [... this.modules, ...this.events];

    modulesEvents = modulesEvents.sort((a, b) => a.order - b.order);

    return modulesEvents;
  }

  public stepIsNotValid() {
    return isEmpty(this.trackModulesEvents) &&
      isEmpty(this.modules) &&
      isEmpty(this.events);
  }

  public nextStep(): void {

    if (this.stepIsNotValid()) return;

    const getByType = (items: Array<any>, type: ETrackWeight) => items
      .filter(item => item.type === type);

    const getModules = items => getByType(items, ETrackWeight.Module);
    const getEvents = items => getByType(items, ETrackWeight.Event);

    const itens = cloneDeep(this.trackModulesEvents);

    if (!this.checkDates(itens.filter(item => !(item instanceof ValuationsConfiguration) && item.cutOffDate)))
      this.notify('A data limite do BDQ não pode ser menor que a data atual');

    if (this.totalWeight !== 100)
      this.notify('A soma dos pesos dos itens deve dar 100');

    const modules = getModules(itens);
    const events = getEvents(itens);

    this.emitModulesEventsValuationsWeight(modules, events);

  }

  public emitModulesEventsValuationsWeight(modules: any[], events: any[]) {
    this.addModulesEventsWeight.emit({
      events: events,
      modules: modules,
    });
  }

  public getCurrentProgress(): string {
    return this.totalWeight > 100 ? (0).toString() + '%' :
      (100 - this.totalWeight).toString() + '%';
  }

  public setTotalValue() {
    this.totalWeight = 0;
    const items = this.trackModulesEvents.filter(item => !(item instanceof ValuationsConfiguration));
    const weights = items.map(trackModulesEvents => (trackModulesEvents as TrackModule | TrackEvent).weight);
    weights.forEach(weight => {
      this.totalWeight += weight ? weight : 0;
    });
  }

  public setRowValue(value: number, row: TrackModule | TrackEvent) {

    if (value < 0) {
      this.notify('Valores negativos não são permitidos.');
      row.weight = 0;
      return false;
    }

    row.weight = +value;
    this.setTotalValue();
  }

  public checkDates(trackModulesEvents: Array<TrackModule | TrackEvent | ValuationConfiguration>) {
    let returnValue: boolean = true;
    for (let i = 0; i < trackModulesEvents.length; i++) {
      if (!(trackModulesEvents[i] instanceof ValuationsConfiguration)) {
        const cutOffDate = (trackModulesEvents[i] as TrackModule | TrackEvent).cutOffDate;
        if (this.checkDate(new Date(cutOffDate))) {
          returnValue = true;
          break;
        }
      }

    }
    return returnValue;
  }

  public checkDate(cutOffDate: Date) {
    if (cutOffDate) {
      const today = new Date();
      if (cutOffDate > today) {
        return false;
      } else {
        return true;
      }
    }
  }

}
