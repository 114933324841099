import { Pipe, PipeTransform } from '@angular/core';
import * as dateFn from 'date-fns';
import { NextSchedule } from 'src/app/models/previews/event.interface';

@Pipe({ name: 'subscriptionEnd' })
export class SubscriptionEndPipe implements PipeTransform {
  transform(nextSchedule: NextSchedule): any {
    if (nextSchedule) {
      const dueDate = dateFn.startOfDay(new Date(nextSchedule.subscriptionEndDate));
      const today = new Date();
      const diff = dateFn.differenceInCalendarDays(dueDate, today);
      const hourDiff = dateFn.differenceInHours(nextSchedule.subscriptionEndDate, today);
      if (diff > 0)
        return diff + ' dias';
      else        
        if (hourDiff > 0){
          return hourDiff + ' horas';
        }
        return 'Inscrições Encerradas.';
    }
    return '--';
  }
}
