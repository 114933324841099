import { Component, Input } from '@angular/core';
import { StudentProgress } from 'src/app/models/track-overview.interface';
import * as JSZip from 'jszip';
// import * as JSZipUtils from 'jszip-utils';

@Component({
  selector: 'app-track-overview-students-progress',
  templateUrl: './students-progress.component.html',
  styleUrls: ['./students-progress.component.scss']
})
export class TrackOverviewStudentsProgressComponent {

  @Input() readonly students: Array<StudentProgress> = [];
  @Input() readonly moduleName: string;
  public hasDownload = true;

  public levelDictionary = {
    0: 'SEM NÍVEL',
    1: 'NÍVEL-1',
    2: 'NÍVEL-2',
    3: 'NÍVEL-3',
    4: 'NÍVEL-4'
  };

  public getUserImgUrl(student: StudentProgress): string {
    return student && student.imageUrl && student.imageUrl.trim() !== '' ?
      student.imageUrl : './assets/img/user-image-placeholder.png';
  }

  public getBadgeImgByLevel(student: StudentProgress): string {
    if (student.level === null && student.objective === 0)
      return './assets/img/empty-badge.png';

    switch (student.level) {
      case 1:
        return './assets/img/pencil-icon-shadow.png';
      case 2:
        return './assets/img/glasses-icon-shadow.png';
      case 3:
        return './assets/img/brain-icon-shadow.png';
      case 4:
        return './assets/img/brain-dark-icon-shadow.png';
      default:
        return './assets/img/empty-badge.png';
    }
  }

  // public downloadAllFiles(): void {

  //   const urls = [];

  //   this.students.forEach(function(student) {

  //     if (student.userFiles) {
  //       urls.push(student.userFiles.downloadLink);
  //     }
  //   });

  //   const zip = new JSZip();
  //   let count = 0;
  //   const name = this.moduleName + '.zip';
  //   urls.forEach(function(url) {
  //     JSZipUtils.getBinaryContent(url, function (err, data) {
  //       if (err) {
  //         this.notify('Ocorreu um erro, por favor tente novamente mais tarde');
  //         throw err;
  //       }
  //       let split = url.split('/');
  //       split = split[split.length - 1].split('.');

  //       const file = split[split.length - 2] + '.' + split[split.length - 1];
  //       zip.file(file, data,  {binary: true});
  //       count++;
  //       if (count === urls.length) {
  //         zip.generateAsync({type: 'blob'}).then(function(content) {
  //           saveAs(content, name);
  //         });
  //       }
  //     });
  //   });
  // }
}
