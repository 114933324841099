import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableColumn, SelectionType, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StudentReport } from 'src/app/pages/report/report-student-dashboard/models/report-student-dashboard.component.model';
import { Level } from 'src/app/shared/classes/level';
import { get } from 'lodash';

@Component({
  selector: 'app-dialog-report-student-by-subject-dashboard',
  templateUrl: './dialog-report-student-by-subject-dashboard.component.html',
  styleUrls: ['./dialog-report-student-by-subject-dashboard.component.scss']
})
export class DialogReportStudentBySubjectDashboardComponent extends NotificationClass implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('Table', { static: true }) private _table: DatatableComponent;

  public loader = true;
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public messages = {
    emptyMessage: 'Não há dados para exibir', totalMessage: 'Total', selectedMessag: 'selecionado',
  };

  public rowsDetails: TableColumn[] = [
    , { prop: 'totalSubjectQuestion', name: 'Total de questões BDQ' }
    , { prop: 'totalSubjectQuestionAnswered', name: 'Total de questões respondidas' }
    , { prop: 'totalSubjectContent', name: 'Total de conteúdos' }
    , { prop: 'totalContentCompleted', name: 'Total de conteúdos visualizado' }
    , { prop: 'percentageOfCorrectAnswers', name: '% de acertos' }
    , { prop: 'percentageOfWrongAnswers', name: '% de erros' }
    , { prop: 'answerBeginnerReport.answer.correct', name: 'Questões nível-1 certas' }
    , { prop: 'answerBeginnerReport.answer.wrong', name: 'Questões nível-1 erradas' }
    , { prop: 'answerIntermediateReport.answer.correct', name: 'Questões nível-2 certas' }
    , { prop: 'answerIntermediateReport.answer.wrong', name: 'Questões nível-2 erradas' }
    , { prop: 'answerAdvancedReport.answer.correct', name: 'Questões nível-3/nível-4 certas' }
    , { prop: 'answerAdvancedReport.answer.wrong', name: 'Questões nível-3/nível-4 erradas' }
  ];

  public normalColumns: TableColumn[] = [
    { prop: 'userName', name: 'Nome', frozenLeft: true, width: 250 }
    , { prop: 'trackTitle', name: 'Trilha', width: 200 }
    , { prop: 'trackAreaTitle', name: 'Área', width: 200 }
    , { prop: 'trackSubAreaTitle', name: 'Sub-Área', width: 200 }
    , { prop: 'moduleName', name: 'Módulo', width: 200 }
    , { prop: 'subjectName', name: 'Assunto', width: 200 }
    , { prop: 'finishedSubject', name: 'Terminou assunto' }
    , { prop: 'level', name: 'Nível atual' }
    , { prop: 'bdqStartDate', name: 'Data início uso BDQ', width: 150 }
    , { prop: 'bdqEndDate', name: 'Data fim uso BDQ', width: 150 }
  ];
  public selectedRows: StudentReport[] = [];

  private _temp: StudentReport[] = [];
  private _searchSubject: Subject<string> = new Subject();

  public rowIdentity = (row) => row.id;
  public rowHeight = (row, height) => row.height = height || 45;

  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogReportStudentBySubjectDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StudentReport[]) {
    super(_snackBar);
    dialogRef.addPanelClass('student-report');
    this._temp = [...data];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._table.recalculate();
      this.loader = false;
    }, 1000);
  }

  ngOnInit() {
    this._setSearchSubscription();
  }

  ngOnDestroy(): void {
    if (this._searchSubject)
      this._searchSubject.unsubscribe();
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this._table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue.toLowerCase());
  }

  private _setSearchSubscription() {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this._updateFilter(searchValue);
    });
  }

  public exportData() {
    this.dialogRef.close(this.selectedRows.length > 0 ? this.selectedRows : this.data);
  }

  public dismiss() {
    this.dialogRef.close();
  }

  public getLevelName = (row: StudentReport): string => {
    if (row.exemption) {
      return '--';
    } else {
      return Level.getLevelName(row.level);
    }
  };

  private _updateFilter(userName: string) {
    const temp = this._temp.filter(row => row.userName.toLowerCase().indexOf(userName) !== -1 || !userName);
    this.data = temp;
    this._table.offset = 0;
  }

}
