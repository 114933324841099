import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import Editor from '@toast-ui/editor';

import { ValuationTestQuestion, ValuationTestQuestionTypeEnum } from 'src/app/models/valuation-test.interface';
import { SupportMaterial, SupportMaterialType } from 'src/app/models/support-material.interface';

import { FileUploadClass } from 'src/app/shared/classes/file-upload';
import { UploadService } from 'src/app/shared/services/upload.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-coursework.dialog',
  templateUrl: './coursework.dialog.component.html',
  styleUrls: ['./coursework.dialog.component.scss']
})
export class CourseworkDialogComponent extends FileUploadClass implements OnInit {

  public editor: Editor;
  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CourseworkDialogComponent>,
    protected _snackBar: MatSnackBar,
    protected _uploadService: UploadService,
    protected _sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: ValuationTestQuestion
  ) {
    super(_snackBar, _sharedService, _uploadService);
  }

  ngOnInit() {
    this._configureEditor();
    this.formGroup = this._createFormGroup(this.data);
  }

  private _configureEditor(): void {
    this.editor = new Editor({
      el: document.querySelector('#htmlEditor'),
      initialEditType: 'markdown',
      previewStyle: 'vertical',
      height: '200px',
      hooks: {
        addImageBlobHook: (file: File, callback) => {
          this._uploadService.uploadFile(file).subscribe(uploadedImageURL => {
            callback(uploadedImageURL, file.name);
          });
        }
      }
    });
    this.editor.off('scroll');
    this.editor.setMarkdown(
      this.data.title
    );
  }

  public dismiss(): void {
    this.dialogRef.close();
  }
  public save(): void {
    this.data.title = this.editor.getMarkdown();
    this.data.type = ValuationTestQuestionTypeEnum.Coursework as number;
    this.data.supportMaterials = this.formGroup.get('materials').value;
    this.data.percentage = 100;
    this.dialogRef.close(this.data);
  }
  public addMaterial(): void {
    const materials = this.formGroup.get('materials') as FormArray;
    materials.push(
      this._createMaterialForm()
    );
  }

  public removeMaterial(index: number): void {
    const materials = this.formGroup.get('materials') as FormArray;
    materials.removeAt(index);
  }

  public openFileUpload(index: number): void {
    const input = document.getElementById('inputFile' + index) as HTMLInputElement;
    input.value = '';
    input.click();
  }

  private _createFormGroup(data): FormGroup {
    return new FormGroup({
      'materials': this._setMaterialsFormArray(data)
    });
  }

  private _setMaterialsFormArray(data): FormArray {
    if (data && data.supportMaterials) {
      return new FormArray(
        data.supportMaterials.map((mat) => this._createMaterialForm(mat))
      );
    } else {
      return new FormArray([
        this._createMaterialForm()
      ]);
    }
  }


  private _createMaterialForm(material: SupportMaterial = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(material ? material.title : '', [Validators.required]),
      'fileName': new FormControl(material ? material.title : ''),
      'downloadLink': new FormControl(material ? material.downloadLink : '', [Validators.required]),
      'type': new FormControl(
        material && material.type ? material.type : SupportMaterialType.File, [Validators.required]
      )
    });
  }

}
