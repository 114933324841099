import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import * as LogRocket from 'logrocket';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = function () { };   // disable any console.log debugging statements in production mode


  Sentry.init({
    dsn: 'https://549052db80e34a27b38dcdca28ad4dac@o223261.ingest.sentry.io/4504248121294848',
    release: 'academia',
    integrations: [
      new RewriteFrames(),
    ]
  });

  LogRocket.init('thm68q/btg-app');

  Sentry.configureScope((scope) => {
    const authData = localStorage.getItem(LocalStorageService.key.authData);
    if (authData) {
      const user = JSON.parse(authData);
      scope.setUser({
        'id': user.user_id,
        'username': user.username
      });
    }
  });

}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
