import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Progress } from '../../../models/shared/progress.interface';
import { UserService } from '../../_services/user.service';
import { isObject } from 'lodash';
// ROLLBACK BDQ
@Component({
  selector: 'app-exam-footer',
  template: `
    <div class="exam-footer" >
      <div class="progress" [style.color]="getProgressBarColor()" *ngIf="showProgressBarr()">
        <ng-container *ngIf="!isPracticing">
          <div class="bar" >
            <app-progress-bar
              [roundedBorder]="true"
              [currentProgress]="levels ? progress.progress*100 : progress * 100"
              [height]="29"
              [color]="getProgressBarColor()"
              fillingColor="#C3C3C3"
            ></app-progress-bar>
          </div>
          <img [src]="getProgressIconSrc()" />
          <ng-container *ngIf="levels">
            {{ 100 - (progress.progress*100) | number:'1.0-0' }}% para completar o {{ (levels[progress.level]?.toLowerCase()) }}
          </ng-container>
          <ng-container *ngIf="!levels">
            {{ (progress * 100) | number:'1.0-0' }}% para completar a avaliação
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isPracticing">
          <div class="bar" >
            <app-progress-bar
              [roundedBorder]="true"
              [currentProgress]="progress.progress * 100"
              [height]="29"
            ></app-progress-bar>
          </div>
        </ng-container>
      </div>
      <div class="footer-btns">
        <button class="btn-test" *ngIf="isQuestionBdq" (click)="backToModule.emit()">
          {{backToModuleTitle ? backToModuleTitle : 'Voltar para o módulo'}}
        </button>
        <div class="navigation-btns">
          <a *ngIf="isPracticing || (showBackButton && isNotInitialQuestion)" (click)="back()">
            voltar
          </a>
          <a *ngIf="isPracticing || hasMoreQuestions" [ngClass]="{ 'disabled': !isPracticing && !hasAnswered }" (click)="next()">
            próximo
          </a>
        </div>
      </div>
    </div>`,
  styleUrls: ['./exam-footer.component.scss']
})
export class ExamFooterComponent {

  @Input() readonly progress: Progress;
  @Input() readonly levels?: any;
  @Input() readonly hasAnswered: boolean = false;
  @Input() readonly hasMoreQuestions: boolean = false;
  @Input() readonly isQuestionBdq?: boolean = true;
  @Input() readonly showBackButton?: boolean = false;
  @Input() readonly isNotInitialQuestion?: boolean = false;
  @Input() readonly backToModuleTitle: string = '';
  @Input() readonly isPracticing: boolean = false;
  @Output() goToNextQuestion = new EventEmitter();
  @Output() goToBackQuestion = new EventEmitter();
  @Output() backToModule = new EventEmitter();
  @Output() navigateQuestion = new EventEmitter<boolean>();

  constructor(private _userService: UserService) { }

  public next(): void {
    if (this.hasAnswered) {
      this.goToNextQuestion.emit();
    } else if (this.isPracticing) {
      this.navigateQuestion.emit(false);
    }
  }

  public back(): void {
    if (this.isPracticing) {
      this.navigateQuestion.emit(true);
    } else {
      this.goToBackQuestion.emit();
    }
  }

  public getProgressBarColor(): string {
    return this._userService.getLevelColor(this.progress.level);
  }

  public getProgressIconSrc(): string {
    return this._userService.getLevelGreyImage(this.progress.level);
  }

  public showProgressBarr(): boolean {
    return this.progress && isObject(this.progress) || this.progress !== null || this.progress !== undefined;
  }
}
