<div class="user-summary" >
  <div class="personal" >
    <img [src]="user?.userInfo.imageUrl ? user?.userInfo.imageUrl : './assets/img/user-image-placeholder.png'">
    <h3>{{ user?.userInfo.name ? user.userInfo.name : '-'}}</h3>
    <p class="title">{{ user?.userInfo?.address?.city || user?.userInfo?.address?.state ? user?.userInfo?.address?.city + ', ' + user?.userInfo?.address?.state : '-'}} </p>
    <p><b>{{dateBirth? dateBirth + ' ANOS' : 'Idade não informada'}} </b></p>
    <p><b>{{ user?.userInfo ? user.userInfo.info : '-' }} </b></p>
  </div>
  <div class="progresses">
    <p class="topic"><b>INFORMAÇÕES DE CONTATO</b></p>
    <p class="topic"><i class="icon-seta_esquerda"></i> {{ user?.userInfo.email ? user.userInfo.email : '-'}}</p>
    <p class="topic"><i class="icon-seta_esquerda"></i> {{ user?.userInfo.phone ? user.userInfo.phone : '-'}}</p>
  </div>
  <div class="progresses"
    *ngIf="user && user.userCareer && (user.userCareer.travelAvailability || user.userCareer.movingAvailability)" >
    <p class="topic"><b>DISPONÍVEL PARA</b></p>
    <p class="topic"><i class="icon-seta_esquerda" *ngIf="user?.userCareer.travelAvailability"></i> Viagens</p>
    <p class="topic"><i class="icon-seta_esquerda" *ngIf="user?.userCareer.movingAvailability"></i> Mudança de Residência</p>
  </div>
  <div class="progresses" *ngIf="user?.userInfo.linkedIn">
    <p class="topic"><b>ACESSE TAMBÉM</b></p>
    <a [href]="user?.userInfo.linkedIn" target="_blank">
      <p class="topic link"><span>LinkedIn</span><i class="icon-linkedin"></i></p>
    </a>
  </div>
</div>
