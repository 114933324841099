<div class="container">
  <div class="loading" *ngIf="loading">
    <mat-progress-spinner [diameter]="50" mode="indeterminate"></mat-progress-spinner>
    <div class="text-loading">
      <div class="loading__letter">L</div>
      <div class="loading__letter">o</div>
      <div class="loading__letter">a</div>
      <div class="loading__letter">d</div>
      <div class="loading__letter">i</div>
      <div class="loading__letter">n</div>
      <div class="loading__letter">g</div>
      <div class="loading__letter">.</div>
      <div class="loading__letter">.</div>
      <div class="loading__letter">.</div>
    </div>
  </div>
  <div class="header">
    <div class="title">
      <h3>Upload de {{name}}</h3>
      <a class="link-button" style="margin-right: 10px;" [href]="pathTemplate" [download]="downloadName" *ngIf="showDownloadTemplateButton">
        Baixar modelo
      </a>
    </div>

    <p>
      {{subtitle}}
    </p>
  </div>
  <form>
    <div class="content">

      <div class="question">
        <div class="content-ckGroup" *ngIf="showRadionButtons">
          <div class="ckGroup">
            <div class="checkbox" [ngClass]="{ 'checked': addContents }" (click)="addContents = true;"></div>
            <span>Adicionar {{name}}</span>
          </div>
          <div class="ckGroup">
            <div class="checkbox" [ngClass]="{ 'checked': !addContents }" (click)="addContents = false;"></div>
            <span>Substiuir {{name}}</span>
          </div>
        </div>
        <div class="upload">
          <p class="file-name">
            Escolher Arquivo *
          </p>
          <button type="button" (click)="openFileUpload()">
            Upload
          </button>
          <input class="input-file" type="file" id="subjectsInputFile" hidden (change)="setDocumentFile($event)" />
        </div>
      </div>
      <div class="display-erro" *ngIf="!loading && erroList?.length > 0">
        <div class="error-headear">
          <span class="material-icons">
            error
          </span>
          <p><strong>{{erroList?.length}}</strong> ERRO(S) ENCONTRADO(S)</p>
        </div>
        <div class="content">
          <div class="error-container" *ngFor="let error of erroList">
            <div class="error-content">
              <p *ngIf="error.collum">Coluna {{error.collum}},&nbsp;</p>
              <p *ngIf="error.row"> Linha {{error.row}}</p>
            </div>
            <div>{{ error?.description }}</div>
          </div>
        </div>
      </div>

      <div class="success" *ngIf="!loading && (erroList?.length === 0 || erroList === null)">
        <span class="material-icons">
          check_circle
        </span>
        <p><strong>Sucesso, clique em salvar para concluir a importação</strong></p>
      </div>
      <div class="actions">
        <button type="button" class="btn-outline" (click)="dismiss()">
          fechar
        </button>
        <button type="button" class="btn-test" (click)="save()" [disabled]="loading && erroList?.length > 0">
          Salvar
        </button>
      </div>
    </div>
  </form>
</div>
