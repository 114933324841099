<ng-container [ngSwitch]="cardType">
  <ng-template [ngSwitchCase]="cardTypeEnum.module">
    <div class="module-template">
      <p class="excerpt">{{ course?.excerpt | excerpt }}</p>
      <p *ngIf="course?.sector?.name" class="sector">Setor: <b>{{ course?.sector?.name }}</b></p>
      <p *ngIf="course?.segment?.name" class="segment">Segmento: <b>{{ course?.segment?.name }}</b></p>
      <p class="instructor">Instrutor: <b>{{ course?.instructor }}</b></p>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="cardTypeEnum.track">
    <div class="track-template">
      <p class="excerpt">{{ course?.description | excerpt }}</p>
      <p *ngIf="course?.sector?.name" class="sector">Setor: <b>{{ course?.sector?.name }}</b></p>
      <p *ngIf="course?.segment?.name" class="segment">Segmento: <b>{{ course?.segment?.name }}</b></p>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="cardTypeEnum.event">
    <p class="excerpt">{{ course?.excerpt | excerpt }}</p>
    <p class="subscriptionEnd">Fim das Inscrições: <b>{{ course?.nextSchedule | subscriptionEnd }}</b></p>
    <p class="instructor" *ngIf="course?.instructor"> Instrutor: <b>{{ course?.instructor }}</b></p>
    <p class="subscriptionEnd">Data do Curso: <b>
        {{ course?.nextSchedule ? (course?.nextSchedule.eventDate | date : 'dd/MM/yyyy') : '--' }}</b></p>
  </ng-template>


</ng-container>
