import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Module, ModuleQuestion, ModuleQuestionValuation } from 'src/app/models/module.model';
import { Subject } from 'src/app/models/subject.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ContentModulesService } from '../../../_services/modules.service';

@Component({
  selector: 'app-module-question-dialog',
  templateUrl: './module-question-dialog.component.html',
  styleUrls: ['./module-question-dialog.component.scss']
})
export class ModuleQuestionDialogComponent extends NotificationClass {

  public module: Module;
  public subject: Subject;
  public moduleQuestion: ModuleQuestion;
  public moduleQuestionValuations: ModuleQuestionValuation[];
  public step: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModuleQuestionDialogComponent>,
    protected _snackBar: MatSnackBar,
    public moduleService: ContentModulesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(_snackBar);
    this.module = data.module;
    this.subject = data.subject;
    this.moduleQuestion = data.moduleQuestion;
    this.moduleQuestionValuations = data.moduleQuestionValuations;
  }



  public onTabSelected(tabEvent: MatTabChangeEvent) {
    this.step = tabEvent.index;
  }

  goBack() {
    if (this.step > 0) {
      this.step--;
    } else {
      this.dialogRef.close();
    }
  }

  next(obj: any) {
    if (this.step === 0) {
      this.moduleService.updateModuleUserQuestion(this.moduleQuestion.id, obj).subscribe(() => {
        document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
        this.step++;
      });
    } else if (this.step === 1) {
      this.dialogRef.close();
    }
  }
}
