import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService, BackendResponse } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { Module, ModuleQuestion, ModuleQuestionValuation } from 'src/app/models/module.model';
import { VideoReference, PdfReference, TextReference, ConceptReference } from 'src/app/models/content.model';
import { IRequesrtSort } from '../my-courses/Interface/request-sort';

@Injectable()
export class ContentModulesService {

  constructor(private _httpService: BackendService, private _route: ActivatedRoute) { }

  public getPagedModulesList(page: number = 1, pageSize: number = 10): Observable<any> {
    return this._httpService.get('getPagedModulesList', [], [
      { 'name': 'page', 'value': page.toString() },
      { 'name': 'pageSize', 'value': pageSize.toString() }
    ]);
  }

  public getAllContent(moduleId: string): Observable<any> {
    return this._httpService.get('getAllContent', [], [
      { 'name': 'moduleId', 'value': moduleId }
    ]);
  }

  public getPagedFilteredModulesList(
    page: number = 1, pageSize: number = 10,
    searchValue: string = '', tags: Array<string> = [], onlyPublished: boolean = false
  ): Observable<any> {
    return this._httpService.post('getPagedFilteredModulesList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'tags': tags,
        'onlyPublished': onlyPublished
      }
    });
  }

  public getPagedFilteredMyCoursesModulesList(
    page: number = 1, pageSize: number = 10,
    searchValue: string = '', tags: Array<string> = [], onlyPublished: boolean = false, sort: IRequesrtSort = null
  ): Observable<any> {
    return this._httpService.post('getPagedMyCoursesFilteredModulesList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'tags': tags,
        'onlyPublished': onlyPublished
      },
      'sort': sort
    });
  }

  public getPagedHomeModulesList(
    page: number = 1, pageSize: number = 10,
    searchValue: string = '', tags: Array<string> = []
  ): Observable<any> {
    return this._httpService.post('getPagedHomeModulesList', {
      'page': page,
      'pageSize': pageSize,
      'filters': {
        'term': searchValue,
        'tags': tags
      }
    });
  }

  public getModuleById(moduleId: string, trackId?: string, programId: string = ''): Observable<BackendResponse<Module>> {
    trackId = trackId || (this._route.snapshot.paramMap.get('trackId') || this._route.snapshot.queryParams['trackId']);
    programId = programId || this._route.snapshot.queryParams['programId'];

    const hasAccessByTrack = !!trackId;
    const hasAccessByPrograms = !!programId;
    const params = [{ 'name': 'id', 'value': moduleId }];

    if (hasAccessByPrograms) params.push({ 'name': 'programId', 'value': programId });
    if (hasAccessByTrack) params.push({ 'name': 'trackId', 'value': trackId });

    return this._httpService.get('getModuleById', [], params);
  }

  public deleteModuleById(id: string, draftId: string = ''): Observable<any> {
    return this._httpService.post('deleteModuleById', {
      'moduleId': id,
      'draftId': draftId
    });
  }

  public getCockpitModuleMetrics(moduleId: string, studentsId: string[], trackId: string): Observable<any> {
    return this._httpService.post('getCockpitModulesMetrics', {
      moduleId: moduleId,
      students: studentsId,
      trackId: trackId
    });
  }

  public getCockpitModuleMetricsStudentCurrent(moduleId: string, studentId: string): Observable<any> {
    return this._httpService.post('getCockpitModuleMetricsStudentCurrent', {
      moduleId: moduleId,
      studentId: studentId
    });
  }

  public getUserQuestionValuation(moduleId: string, subjectId: string): Observable<any> {
    return this._httpService.get('getUserQuestionValuation', [], [
      { 'name': 'moduleId', 'value': moduleId },
      { 'name': 'subjectId', 'value': subjectId }
    ]);
  }

  public addModuleUserQuestion(moduleId: string, subjectId: string, moduleQuestion: ModuleQuestion): Observable<any> {
    return this._httpService.post('addModuleUserQuestion', {
      'moduleId': moduleId,
      'subjectId': subjectId,
      'moduleQuestion': moduleQuestion
    });
  }

  public updateModuleUserQuestion(moduleQuestionId: string, moduleQuestion: ModuleQuestion): Observable<any> {
    return this._httpService.post('updateModuleUserQuestion', {
      'moduleQuestionId': moduleQuestionId,
      'moduleQuestion': moduleQuestion
    });
  }

  public addModuleUserQuestionValuation(moduleQuestionId: string, moduleQuestionValuation: ModuleQuestionValuation): Observable<any> {
    return this._httpService.post('addModuleUserQuestionValuation', {
      moduleQuestionId: moduleQuestionId,
      moduleQuestionValuation: moduleQuestionValuation
    });
  }

  public getFilteredUserQuestionValuations(page: number, pageSize: number,
    searchValue: string, moduleId: string, subjectId: string
  ): Observable<any> {
    return this._httpService.post('getFilteredUserQuestionValuations', {
      page: page,
      pageSize: pageSize,
      searchValue: searchValue,
      moduleId: moduleId,
      subjectId: subjectId
    });
  }

  public updateModuleQuestionStatus(moduleQuestionId: string, status: number, level: number = null): Observable<any> {
    return this._httpService.post('updateModuleQuestionStatus', {
      'moduleQuestionId': moduleQuestionId,
      'status': status,
      'level': level
    });
  }

  public setModuleOpinion(moduleId: string, reactionType: number, text: string = '', opinionType: number): Observable<any> {
    return this._httpService.post('setModuleOpinion', {
      'moduleId': moduleId,
      'reactionType': reactionType,
      'text': text,
      'opinionType': opinionType
    });
  }

  public addModuleUserTag(moduleId: string, subjectId: string, contentId: string,
    concept: ConceptReference | VideoReference | PdfReference | TextReference,
    description: string): Observable<any> {
    return this._httpService.post('addModuleUserTag', {
      'moduleId': moduleId,
      'subjectId': subjectId,
      'contentId': contentId,
      'concept': concept,
      'description': description
    });
  }

  public updateModuleUserTag(moduleUserTagId: string,
    concept: ConceptReference | VideoReference | PdfReference | TextReference,
    description: string): Observable<any> {
    return this._httpService.post('updateModuleUserTag', {
      'moduleUserTagId': moduleUserTagId,
      'concept': concept,
      'description': description
    });
  }

  public updateModuleActivity(moduleId: string, draftId: string): Observable<any> {
    return this._httpService.post('updateModuleActivity', {
      'moduleId': moduleId,
      'draftId': draftId
    });
  }

  public getModuleTags(moduleId: string): Observable<any> {
    return this._httpService.get('getModuleTags', [], [
      { 'name': 'moduleId', 'value': moduleId }
    ]);
  }

  public valuateModuleUserTag(moduleUserTagId: string, like: boolean): Observable<any> {
    return this._httpService.post('valuateModuleUserTag', {
      'moduleUserTagId': moduleUserTagId,
      'like': like
    });
  }

  public getFilteredUserTagValuations(page: number, pageSize: number, searchValue: string,
    sort: string, sortDirection: boolean, moduleId: string, subjectId: string
  ): Observable<any> {
    return this._httpService.post('getFilteredUserTagValuations', {
      'page': page,
      'pageSize': pageSize,
      'sortBy': sort,
      'isSortAscending': sortDirection,
      'searchValue': searchValue,
      'moduleId': moduleId,
      'subjectId': subjectId
    });
  }

  public updateModuleTagStatus(moduleTagId: string, status: number): Observable<any> {
    return this._httpService.post('updateModuleTagStatus', {
      'moduleTagId': moduleTagId,
      'status': status
    });
  }

  public getCuratorshipPendingCount(): Observable<any> {
    return this._httpService.get('getCuratorshipPendingCount', [], []);
  }

  public getTracksById(moduleId: string): Observable<any> {
    return this._httpService.post('getTracksById', {
      'moduleId': moduleId,
    });
  }
}
