import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentGradeComponent } from 'src/app/shared/components/student-grade/student-grade.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [StudentGradeComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    MatCardModule
  ],
  exports: [StudentGradeComponent]
})
export class StudentGradeModule { }
