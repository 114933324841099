import { Component, OnInit } from '@angular/core';
import { NotificationClass } from '../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentEventsService } from '../_services/events.service';
import { Event } from '../../models/event.model';
import { Level } from '../../models/shared/level.interface';
import { SharedService } from '../../shared/services/shared.service';
import { EventApplication } from 'src/app/models/event-application.model';
import { UserService } from '../_services/user.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ActionInfoPageEnum, ActionInfoTypeEnum } from 'src/app/shared/directives/save-action/action-info.interface';
import Player, { Options } from '@vimeo/player';
import { ModuleProgress } from 'src/app/models/previews/module-progress.interface';
import { Requirement } from 'src/app/settings/modules/new-module/models/new-requirement.model';
import { formatDate } from '@angular/common';
import { EventForumQuestion } from 'src/app/models/event-forum.model';
import { isNil } from 'lodash';
import { ApplicationStatus } from 'src/app/models/enums/application-status';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { ValuationScheduleTypeEnum, ValuationTestTypeEnum } from 'src/app/models/enums/valuation-test-type-enum';
import { ValuationTest } from 'src/app/models/valuation-test.interface';
import { differenceInSeconds } from 'date-fns';
import { EventSchedule } from 'src/app/models/event-schedule.model';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { QuestionsAndAnswersDialogComponent } from 'src/app/shared/dialogs/questions-and-answers/questions-and-answers.dialog';

@Component({
  selector: 'app-classroom-lesson',
  templateUrl: './classroom-lesson.component.html',
  styleUrls: ['./classroom-lesson.component.scss']
})
export class ClassroomLessonComponent extends NotificationClass implements OnInit {

  public event: Event;
  public forumQuestionsPreview: EventForumQuestion[] = [];
  public eventApplication: EventApplication;
  public showSubscriptionMessage: boolean = false;
  public disabledQuestionBtn: boolean = false;
  public levels: Array<Level> = [];
  public levelDict: any = {};
  public moduleProgress: any;
  public player;
  private _urlStateGuard = undefined;
  private _watchedVideo: boolean = false;
  public EnumApplicationStatus = ApplicationStatus;
  public testsBeforeExpanded: boolean = false;
  public testsAfterExpanded: boolean = false;
  public testsResearchExpanded: boolean = false;
  public testsRegisterExpanded: boolean = false;
  public testsNpsExpanded: boolean = false;
  public testsBefore: any[] = [];
  public testsResearch: any[] = [];
  public testsAfter: any[] = [];
  public testsRegister: any[] = [];
  public testsNps: any[] = [];
  public currentSchedule: EventSchedule;

  constructor(
    protected _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _eventsService: ContentEventsService,
    private _sharedService: SharedService,
    private _userService: UserService,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private _testsService: SettingsValuationTestsService,
    private _dialogService: MatDialog,
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadLevels();
    this._loadEvent();
    this._loadEventApplication();
    this._loadProgress();
  }

  public showWebinar(): boolean {
    if (!this.event || !this.event.schedules || this.event.schedules.length === 0)
      return false;

    const scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
    const currentSchedule = scheduleId ?
      this.event.schedules.find(x => x.id === scheduleId) :
      this.event.schedules[0];

    if (!currentSchedule || !currentSchedule.webinarUrl || currentSchedule.webinarUrl.trim() === '')
      return false;

    const today = formatDate(new Date(), 'dd/MM/yyyy', 'pt-BR');
    const dayEvent = formatDate(currentSchedule.eventDate, 'dd/MM/yyyy', 'pt-BR');
    return today === dayEvent;
  }

  public goToEvent(): void {
    const webinarUrl = this.getWebinarUrl();
    const hasProtocol = webinarUrl.split('http').length > 1;
    const windowUrl = hasProtocol ? webinarUrl : '//' + webinarUrl;
    const newWindow = window.open(windowUrl, '_blank');
    newWindow.focus();
  }

  public getWebinarUrl(): string {
    const scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
    const schedule = scheduleId ?
      this.event.schedules.find(x => x.id === scheduleId) :
      this.event.schedules[0];
    return !schedule ? null : schedule.webinarUrl;
  }

  public getVideoDuration(): number {
    if (!this.event || !this.event.videoDuration) return 0;
    return Math.ceil(this.event.videoDuration / 60);
  }

  public showNotification() {
    this.showSubscriptionMessage = true;
  }

  public dismissNotification() {
    this.showSubscriptionMessage = false;
  }

  public eventScheduleCompleted(eventApplication: EventApplication): boolean {
    if (eventApplication && eventApplication.scheduleId && this.event && this.event.schedules) {
      const schedule = this.event.schedules.find(s => s.id === eventApplication.scheduleId);
      return schedule && schedule.finishedAt != null && eventApplication.finalGrade != null;
    }
    return false;
  }

  public eventScheduleDate(scheduleId: string): boolean {
    if (!this.event || !this.event.schedules) return false;
    return true;
  }

  public getGradedSchedule(scheduleId: string): Date {
    if (!this.event || !this.event.schedules) return null;
    const schedule = this.event.schedules.find(s => s.id === scheduleId);
    return schedule ? schedule.eventDate : null;
  }

  private _loadEvent(): void {
    const eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    this._eventsService.getEventById(eventId).subscribe((response) => {
      this.event = response.data;
      this._setIntroductionVideo();
      this._saveEventAccessAction();
      const scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
      this._checkForumDate(scheduleId);
      localStorage.setItem('eventForumQuestionDialog',
        JSON.stringify({
          eventId: this.event.id,
          eventScheduleId: scheduleId,
          eventName: this.event.title,
          position: '-'
        })
      );
      this.loadEventForumPreview(scheduleId);
      this.currentSchedule = scheduleId ?
        this.event.schedules.find(x => x.id === scheduleId) :
        this.event.schedules[0];

      this._loadSchedulesTests(this.currentSchedule.id, this.event.id);
    });
  }

  private _checkForumDate(scheduleId: string) {
    if (this.event) {
      const schedule = this.event.schedules.find(x => x.id === scheduleId);
      if (schedule) {
        const today = new Date();
        if (schedule.forumStartDate != null && schedule.forumEndDate != null) {
          if (new Date(schedule.forumStartDate) > today || new Date(schedule.forumEndDate) < today) {
            this.disabledQuestionBtn = true;
          }
        }
      }
    }
  }

  private _loadEventApplication(): void {
    const eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    const scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
    this._eventsService.getEventApplication(eventId, scheduleId).subscribe((response) => {
      this.eventApplication = response.data.eventId ? response.data : null;
    });
  }

  private _loadLevels(): void {
    this._sharedService.getLevels(true).subscribe((response) => {
      this.levels = response.data;
      this.levelDict = {};
      response.data.forEach(level => {
        this.levelDict[level.id] = level.description;
      });

    });
  }

  private _loadProgress(): void {
    this._userService.getUserModulesProgress().subscribe((response) => {
      this.moduleProgress = {};
      response.data.forEach((mP: ModuleProgress) =>
        this.moduleProgress[mP.moduleId] = mP
      );

    });
  }

  public loadEventForumPreview(eventScheduleId: string): void {
    this._userService.getEventForumPreview(eventScheduleId, 2).subscribe((response) => {
      this.forumQuestionsPreview = response.data;
    });
  }

  private _saveEventAccessAction(): void {
    this._analyticsService.saveAction({
      'page': ActionInfoPageEnum.Event,
      'description': 'event-access',
      'type': ActionInfoTypeEnum.Access,
      'eventId': this.event.id
    }).subscribe(
      () => { },
      (error) => { console.error(error); });
  }

  private _setIntroductionVideo(): void {
    if (this.event.videoUrl && this.event.videoUrl !== '') {
      const options: Options = {
        url: this.event.videoUrl,
        height: 470
      };
      if (document.getElementById('videoContent')) {
        this.player = new Player('videoContent', options);
        this._handleVideoLoaded(this.player);
        this._handleVideoPlayed(this.player);
      }
    }
  }

  private _handleVideoLoaded(player): void {
    player.on('loaded', () => {
      const frame = document.querySelector('iframe');
      if (frame) { frame.style.width = '100%'; }
      const divFrame = document.getElementById('videoContent');
      divFrame.style.visibility = 'initial';
    });
  }

  private _handleVideoPlayed(player) {
    player.on('play', () => {
      if (!this._watchedVideo) {
        this._watchedVideo = true;
        this._analyticsService.saveAction({
          'page': ActionInfoPageEnum.Event,
          'description': 'introduction-video',
          'type': ActionInfoTypeEnum.VideoPlay,
          'eventId': this.event.id
        }).subscribe(
          () => { },
          (error) => { console.error(error); });
      }
    });
  }

  public goToModule(req: Requirement): void {
    this._router.navigate(['/modulo/' + req.moduleId]);
  }

  private getEventScheduleFinishedDate(scheduleId: string): Date {
    if (!this.event || !this.event.schedules) return null;
    const schedule = this.event.schedules.find(s => s.id === scheduleId);
    return schedule ? new Date(schedule.finishedAt) : null;
  }

  public videoUrlIsValid(url: string) {
    // https://player.vimeo.com/video/{videoID}
    // https://youtube.com/embed/{videoID}

    if (isNil(url) || url === '') return false;

    const splitedUrl = url.split('/');
    const videoId = splitedUrl[splitedUrl.length - 1]; // the video id is the last element of the array

    return videoId !== 'null' && videoId !== '';
  }

  private _loadSchedulesTests(scheduleId: string, eventId: string) {
    this._testsService.getSchedulesValuationTests(scheduleId, eventId).subscribe(res => {
      const valuations = res.data;
      valuations.forEach(valuation => this._updateValuationProperties(valuation, scheduleId));

      if (valuations.some(x => x.type === ValuationTestTypeEnum.Percentile)) {
        this.testsResearch = valuations.filter(x => x.type === ValuationTestTypeEnum.Percentile);
        this.testsResearch.forEach(valuation => {
          valuation.isDisabled = this.disablePercentButtonLogic(valuation, 1, ValuationTestTypeEnum.Percentile);
          valuation.statusText = this.disablePercentButtonLogicText(valuation, 1, ValuationTestTypeEnum.Percentile);
        });
      }

      if (valuations.some(x => x.type === ValuationTestTypeEnum.Free)) {
        const moduleTestsFree = valuations.filter(x => x.type === ValuationTestTypeEnum.Free);
        //diagnostic
        if (moduleTestsFree.some(x => x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.Diagnostic))) {
          this.testsBefore = moduleTestsFree.filter(x =>
            x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.Diagnostic)
          );
          this.setValuationConfiguration(this.testsAfter, 0, ValuationTestTypeEnum.Free);
        }
        //register
        if (moduleTestsFree.some(x => x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.Register))) {
          this.testsRegister = moduleTestsFree.filter(x =>
            x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.Register)
          );
          this.setValuationConfiguration(this.testsAfter, 0, ValuationTestTypeEnum.Free);
        }

        //Nps
        if (moduleTestsFree.some(x => x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.NPS))) {
          this.testsNps = moduleTestsFree.filter(x =>
            x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.NPS)
          );
          this.setValuationConfiguration(this.testsAfter, 0, ValuationTestTypeEnum.Free);
        }

        if (moduleTestsFree.some(x => x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.Valuation))) {
          this.testsAfter = moduleTestsFree.filter(x =>
            x.testSchedules.some(y => y.type === ValuationScheduleTypeEnum.Valuation)
          );
          this.setValuationConfiguration(this.testsAfter, 0, ValuationTestTypeEnum.Free);
        }
      }
    });
  }

  public setValuationConfiguration(arr: any[], percent: number, type: ValuationTestTypeEnum) {
    arr.forEach(valuation => {
      valuation.isDisabled = this.disablePercentButtonLogic(valuation, percent, type);
      valuation.statusText = this.disablePercentButtonLogicText(valuation, percent, type);
    });
  }

  public disablePercentButtonLogic(tests: ValuationTest, percent: number, type: ValuationTestTypeEnum): boolean {
    const answered: boolean = tests.answered;

    if (answered || (!tests.isAvailable && type === ValuationTestTypeEnum.Percentile)) {
      return true;
    }

  }

  public disablePercentButtonLogicText(test: ValuationTest, percent: number, type: ValuationTestTypeEnum): string {

    if (test.answered) {
      return 'Teste respondido';
    }


    if (!test.isAvailable && type === ValuationTestTypeEnum.Percentile) {
      return `Avaliação fora do prazo`;
    }

    if (!test.isAvailable && type === ValuationTestTypeEnum.Coursework) {
      return `Trabalho fora do prazo`;
    }

    if (!test.isAvailable && test.valuationDate && new Date() > new Date(test.valuationDate)) {
      return `Avaliação fora do prazo`;
    }

    return 'Fazer o Teste';
  }

  private _updateValuationProperties(test: ValuationTest, scheduleId: string): void {
    const testSchedule = test.testSchedules.find(valuationSchedule => valuationSchedule.id === scheduleId);
    const now = new Date();
    test.hasStarted = true;
    test.isAvailable = now > this.currentSchedule.eventDate;


    const endDate = test.valuationDate;
    const openDate = test.openDate;

    let _isAvailability = false;

    const isAlwaysAvailable: boolean = test && (
      test.alwaysAvailable ||
      isNil(test.alwaysAvailable) ||
      (test.alwaysAvailable === false && isNil(openDate))
    );

    const hasValuationDate = !!test.valuationDate;

    const valuationAlreadyStarted = differenceInSeconds(openDate, now) <= 0;
    const isOnDeadLine = hasValuationDate && differenceInSeconds(now, test.valuationDate) < 0;

    if (valuationAlreadyStarted) {
      _isAvailability = isNil(endDate) ? true : isOnDeadLine;
    }


    test.hasStarted = valuationAlreadyStarted;
    test.isAvailable = isAlwaysAvailable || _isAvailability;
  }

  public goToTest(test: ValuationTest): void {
    this._setUrlStateGuard();
    this._router.navigate(['/teste-de-avaliacao/' + test.id], this._urlStateGuard);
  }

  private _setUrlStateGuard(): void {
    this._urlStateGuard = { state: { accessByComponent: true } };
  }

  public viewQuestionsAndAnswersDialog(test: any) {
    this._testsService.getValuationTestById(test.id)
      .pipe(take(1))
      .subscribe(valuationTest => {
        this._dialogService.open(QuestionsAndAnswersDialogComponent, {
          width: '1000px',
          height: '90%',
          data: { test: test, valuationTest: valuationTest.data }
        });
      });
  }
  public calculateGrade(moduleTest) {
    const grade = this._setGrade(moduleTest.answers);
    if (grade === null) {
      return '-';
    } else {
      return grade.toFixed(2).replace('.', ',');
    }
  }

  private _setGrade(answers: Array<{ grade: number }>) {
    if (answers.every(a => a.grade != null)) {
      return answers.reduce((sum, a) => sum + a.grade, 0) / 10;
    } else {
      return null;
    }
  }
}


