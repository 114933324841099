import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { TrackDraft } from 'src/app/models/track-draft.model';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';

@Injectable({
    providedIn: 'root'
})
export class TrackDraftService {
    constructor(private _httpService: BackendService) { }

    public getPagedTrackAndDraft(
        page: number = 1, pageSize: number = 10, searchTerm: string = ''
    ): Observable<BackendResponse<Pagination<TrackDraft>>> {
        return this._httpService.post('getAllTracksAndDraft', {
            'page': page,
            'pageSize': pageSize,
            'filters': {
                'term': searchTerm,
            }
        }).pipe(mergeMap((res: BackendResponse<Pagination<TrackDraft>>): Observable<BackendResponse<Pagination<TrackDraft>>> => {
            res.data.pagedItem = res.data.pagedItem.map(track => new TrackDraft(track));
            return of(res);
        }));
    }
    public getTrackDraftById(trackId: string): Observable<BackendResponse<TrackDraft>> {
        return this._httpService.get('getTrackDraftById', [], [{
            'name': 'draftId', 'value': trackId
        }]);
    }
    public createTrackDraft(trackId): Observable<BackendResponse<TrackDraft>> {
        return this._httpService.post('createTrackDraft', {
            trackId
        });
    }

    public updateTrackDraft(trackDraft: TrackDraft): Observable<BackendResponse<TrackDraft>> {
        return this._httpService.put('updateTrackDraft', trackDraft);
    }

    public publishTrackDraft(trackId: string, draftId: string): Observable<BackendResponse<[]>> {
        return this._httpService.post('publishTrackDraft', {
            trackId,
            draftId
        });
    }

    public deleteTrackDraft(draftId): Observable<BackendResponse<[]>> {
        return this._httpService.delete('deleteTrackDraft', [{
            'name': 'draftId', 'value': draftId
        }]);
    }

}
