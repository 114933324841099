<mat-dialog-content>
  <div class="item-wrapper">
    <ul>
      <li *ngFor="let item of itens; let index = index">
        <app-wrong-item [item]="item"></app-wrong-item>
      </li>
    </ul>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button class="btn-test" (click)="dismiss()">Fechar</button>
</mat-dialog-actions>
