import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Module } from 'src/app/models/module.model';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { TrackOverview } from 'src/app/pages/instructor/components/module-tracks-table/models/module-track.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendResponse } from '@tg4/http-infrastructure';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-instructor',
  templateUrl: './instructor.component.html',
  styleUrls: ['./instructor.component.scss']
})
export class InstructorComponent extends NotificationClass implements OnInit {

  public module: Module = history.state.module || new Module();
  public items: TrackOverview;
  public subTitle: string;
  public title: string;

  private _eventId: string;
  private _moduleId: string;
  private _id: string;
  private _isInstructorEvent = this._authService.isSeenHowInstructorEvent();
  private _isInstructorModule = this._authService.isSeenHowInstructorModule();
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _trackService: ContentTracksService,
    private _authService: AuthService,
    protected _snackBar: MatSnackBar,
  ) {
    super(_snackBar);


    if (this._isInstructorEvent) {
      this._eventId = this._activatedRoute.snapshot.params['eventId'];
      this.title = 'Eventos';
      this._id = this._eventId;
    } else if (this._isInstructorModule) {
      this._moduleId = this._activatedRoute.snapshot.params['moduleId'];
      this.subTitle = 'Módulo';
      this._id = this._moduleId;
    }

  }

  ngOnInit() {

    this._trackService.getTracksByItemId(this._id).subscribe((_response: BackendResponse<TrackOverview>) => {
      if (this._isInstructorModule) this.title = _response.data.moduleName;
      this.items = _response.data;
    }, e => {
      const snackbarRef = this.notify(this.getErrorNotification(e), 'Voltar', { duration: 9999999 });
      snackbarRef.onAction().subscribe(() => {
        history.back();
      });
    });

  }



}
