<h1>
  <a (click)="goBack()"
    style="cursor: pointer;" >
    voltar
  </a>
  VAGAS
</h1>
<div class="inner-content" *ngIf="formGroup" >
  <form [formGroup]="formGroup">

    <mat-tab-group>
      <mat-tab label="Cadastro de Vagas">
        <app-record
          [formGroup]="formGroup"
        ></app-record>
      </mat-tab>
      <mat-tab label="Atividades">
        <app-activities
          [formGroup]="formGroup"
        ></app-activities>
      </mat-tab>
      <mat-tab label="Pré Requisitos">
        <app-pre-requisites
          [formGroup]="formGroup"
          (addLanguage)="addLanguage()"
          (addOthers)="addOthers()"       
        ></app-pre-requisites>
      </mat-tab>
      <mat-tab label="Valores">
        <app-profissional-values
          [formGroup]="formGroup"
          (addReward)="addReward()"
        ></app-profissional-values>
      </mat-tab>
      <mat-tab label="Remuneração e Benefícios">
        <app-remuneration
          [formGroup]="formGroup"
        ></app-remuneration>
      </mat-tab>
    </mat-tab-group>
  </form>

</div>

<div class="footer" >
  <button class="btn-test"
    type="button"
    (click)="saveJob()"
    [disabled]="formGroup?.invalid" >
    Salvar Alterações
  </button>
</div>
