export class Level {

  public static getLevelName(level: number): string {
    switch (level) {
      case 1:
        return 'Nível-1';
      case 2:
        return 'Nível-2';
      case 3:
        return 'Nível-3';
      case 4:
        return 'Nível-4';
      default:
        return 'Sem nível';
    }
  }

}
