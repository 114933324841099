import { Component, OnInit, Input } from '@angular/core';
import { NotificationClass } from '../../shared/classes/notification';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { NpsDialogComponent } from 'src/app/shared/dialogs/nps/nps.dialog';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { Module } from 'src/app/models/module.model';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { Valuations } from 'src/app/models/my-course-valuation.model';
import { environment } from 'src/environments/environment';
import { IRequesrtSort, ERequesrtSort } from './Interface/request-sort';
import { SectorService } from 'src/app/settings/_services/sector.service';
import { Filters } from 'src/app/models/filters.model';
import { MyCoursesService } from './services/my-courses.service';
import { IRequestFilter } from './Interface/request-filter';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgramsService } from '../_services/programs.service';
import { ProgramPreview } from 'src/app/models/previews/program.interface';
import { ContentEventsService } from '../_services/events.service';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { ModuleProgress } from 'src/app/models/previews/module-progress.interface';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.scss']
})
export class MyCoursesComponent extends NotificationClass implements OnInit {

  public replacement = environment.replecement;
  public events: Array<EventPreview>;
  public hasHomeV2 = environment.features.hasHomeV2;

  public canOpenFilter: boolean = false;
  public EnumRequesrtSort = ERequesrtSort;
  public filters: Filters[];

  public programs: Array<ProgramPreview>;
  public programsCount: number = 0;
  public programsPageSize: number = 8;
  private _programsPage: number = 1;

  @Input() performance: boolean = true;
  public userId: string;
  public user: User;
  public isStudentView: boolean = false;
  public valuationPeding: Array<Valuations> = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _usersService: SettingsUsersService,
    private _router: Router,
    private _dialog: MatDialog,
    private _sectorService: SectorService,
    private _myCoursesService: MyCoursesService,
    private _authService: AuthService,
    private _eventsService: ContentEventsService,
    private _settingsValuationTestsService: SettingsValuationTestsService,
    private _programsService: ProgramsService

  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._checkMessages();
    this._checkNps();
    this.loadFilters();
    this._loadEvents();
    this.userId = this._authService.getLoggedUserId();
    const userRole = this._authService.getLoggedUserRole();
    this.isStudentView = userRole === 'Student';
    this._loadValuation();
    this._loadPrograms();
  }

  private _checkNps() {
    this._usersService.getUserNpsAvailability().subscribe(res => {
      if (res.data.active) {
        const dialogRef = this._dialog.open(NpsDialogComponent, {
          width: '1000px',
          data: res.data
        });
        dialogRef.afterClosed().subscribe((result: string) => {
          if (result != null) {
            this.notify(result);
          }
        });
      }
    });
  }

  private _checkMessages(): void {
    const expiredModule = localStorage.getItem('expiredModule');
    const expiredTrack = localStorage.getItem('expiredTrack');
    const concat = [expiredModule, expiredTrack];

    if (isEmpty(concat.filter(arg => !isNil(arg)))) return;

    const removeStorage = () => {
      localStorage.removeItem('expiredTrack');
      localStorage.removeItem('expiredModule');
    };

    setTimeout(() => {
      const dialogRef = this._dialog.open(SimpleDialogComponent, {
        hasBackdrop: true,
        closeOnNavigation: false,
        disableClose: true,
        data: {
          message: expiredModule || expiredTrack,
          positiveTextAction: 'Ok'
        } as SimpleDialog
      });
    }, 500);

    removeStorage();
  }

  public goToValuation(valuation: Valuations) {
    this._router.navigate([`/teste-de-avaliacao/${valuation.testId}`], { state: { accessByComponent: true } });
  }

  public openFilter() {
    this.canOpenFilter = !this.canOpenFilter;
  }

  public sort(value: ERequesrtSort) {
    const sortItem: IRequesrtSort = {} as IRequesrtSort;
    for (const item in this.EnumRequesrtSort) {
      if (!isNil(item)){
        sortItem[item] = false;
        document.getElementById(item).style.fontWeight = "normal";
      } 
    }
    sortItem[value] = true;
    document.getElementById(value).style.fontWeight = "Bold";

    this._myCoursesService.setSort(sortItem);

  }

  private loadFilters() {
    this._sectorService.GetFilters().subscribe(response => {
      this.filters = response.data;
      this.filters.sort((a, b) => a.sectorName.localeCompare(b.sectorName));
    });
  }

  public someComplete(selectedfilter: Filters): boolean {
    if (selectedfilter.segments == null) {
      return false;
    }
    return selectedfilter.segments.some(f => f.checked) && !selectedfilter.segments.every(f => f.checked);
  }

  public openFilterLevel(selectedfilter: Filters) {
    selectedfilter.opened = !selectedfilter.opened;
  }
  public setAll(selectedfilter: Filters, checked: boolean) {
    selectedfilter.checked = checked;
    selectedfilter.segments.forEach(segment => segment.checked = checked);

    this.updateFilter();
  }
  public setSector(selectedfilter: Filters, checked: boolean) {
    selectedfilter.checked = checked;
    if(!checked) selectedfilter.segments.forEach(segment => segment.checked = checked);
    this.updateFilter();
  }

  public updateFilter() {
    const cloneFilters = cloneDeep(this.filters);
    const selectedFilter = cloneFilters.filter(fil => fil.checked);
    selectedFilter.forEach(f => {
      f.segments = f.segments.filter(value => value.checked);
    });

    const requestFilter: IRequestFilter[] = selectedFilter.map(value => ({
      sectorId: value.sectorId,
      segmentId: value.segments.map(segment => segment.segmentId)
    }));

    this._myCoursesService.setFilters(requestFilter);
  }

  public updateSector(selectedfilter: Filters) {
    selectedfilter.checked = selectedfilter.segments.some(segment => segment.checked);
    this.updateFilter();
  }

  private _loadEvents(): void {
    this._eventsService.getHomeEvents()
      .subscribe((response) => {
        const events = (response && response.data.filter(data => data.recommended)) || [];
        this.events = [];
        events.forEach((event: EventPreview) => {
          const moduleProgress = {};
          if (event.moduleProgressInfo) {
            event.moduleProgressInfo.forEach((mP: ModuleProgress) =>
              moduleProgress[mP.moduleId] = mP
            );
          }

          event.schedules.forEach(schedule => {
            const isNotPastEvent = new Date(schedule.eventDate).getTime() > new Date().getTime();
            if (isNotPastEvent || (schedule.eventScheduleType.id == 2 && schedule.finishedAt == null) || this._authService.hasSelectedAccess(EAccess.InstructorEvent))
              this.events.push(new EventPreview((event as any), schedule, moduleProgress));
          });
        });
        this.events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      });
  }

  private _loadValuation(): void {
    this._settingsValuationTestsService.getPendingValuationsUser().subscribe((response) => {
      this.valuationPeding = response.data.map(valuation => new Valuations(valuation));
    });
  }

  private _loadPrograms(): void {
    this._programsService.getPagedProgramsFilteredList(this._programsPage, 8).subscribe((response) => {
      this.programs = response.data.programs;
      this.programsCount = response.data.itemsCount;
    });
  }

  public goToProgram(page) {
    this._programsPage = page;
    this._loadPrograms();
  }
}
