import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Istudents, ItrackModule } from '../models/trackStudentsSubordinates';

@Component({
  selector: 'app-cockpit-menu',
  templateUrl: './cockpit-menu.component.html',
  styleUrls: ['./cockpit-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitMenuComponent implements OnInit {

  private _data = new BehaviorSubject<Istudents[]>([]);

  @Input()
  set students(value) {
    this._data.next(value);
  }
  get students() {
    return this._data.getValue();
  }
  @Input() modules?: Array<ItrackModule>;
  @Input() showModules?: boolean = true;

  @Output() goToModule = new EventEmitter<string>();
  @Output() goToStudent = new EventEmitter<Istudents>();

  public filteredStudents: Array<Istudents>;
  public viewOptions = [
    { selected: false, title: 'ALUNO' }
  ];
  constructor() { }

  ngOnInit() {
    this._data
      .subscribe(students => {
      if (students.length > 0) {
        this.filteredStudents = this.students;
      }
    });
    if (this.showModules) {
      this.viewOptions.unshift({ selected: true, title: 'MÓDULO' });
    } else {
      this.viewOptions[0].selected = true;
    }
  }




  public selectViewOption(optTitle: string) {
    this.viewOptions.forEach(opt => { opt.selected = false; });
    this.viewOptions.find(x => x.title === optTitle).selected = true;
  }

  public isViewOption(title: string): boolean {
    return this.viewOptions.find(x => x.title === title) ?
      this.viewOptions.find(x => x.title === title).selected :
      false;
  }

  public searchStudents(event: string) {
    let user = this._data.getValue();
    const filteredUsers = [];
    if (event && event !== '') {
      for (const selectedUser of user) {
        if (selectedUser.name.toLowerCase().search(event.toLowerCase()) !== -1) {
          filteredUsers.push(selectedUser);
        }
      }
      user = filteredUsers;
    }
    this.filteredStudents = user;
  }
}
