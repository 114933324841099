<h3>FORUM DE DUVIDAS</h3>
<p>Tire suas dúvidas sobre o módulo e seus conteúdos com os demais estudantes</p>
<div class="content" >
  <div class="question">
    <p class="sub-title">Envie uma Dúvida</p>
    <p class="info">Evento: <span>{{newQuestion.eventName}}</span></p>
    <p class="info" *ngIf="newQuestion.position != null">Posição: <span>{{newQuestion.position}}</span></p>
    <p class="info" style="margin-right: 53px;"><input placeholder="Título" [(ngModel)]="newQuestion.title"/></p>
    <div id="htmlEditor" ></div>
    <p class="send-question-btn">
      <button class="btn-test" (click)="sendNewQuestion()" >
        Enviar pergunta
      </button>
    </p>
    
  </div>
  <div class="forum" >
    <p class="sub-title">Perguntas Deste Evento</p>
    <app-event-forum-question-card
        *ngFor="let question of forumQuestionsPreview"
        [question]="question"
        (manageLike)="manageLike($event)"
    ></app-event-forum-question-card>
    <ng-container *ngIf="forumQuestionsPreview.length == 0" >
      <p style="margin-bottom: 20px;">
        Ainda não há perguntas no Fórum
      </p>
    </ng-container>
    <button style="float: right" class="btn-test" (click)="goToForum()" >
      {{ forumQuestionsPreview.length > 0 ? 'Ver Todas Perguntas' : 'Ver Fórum'}}
    </button>
  </div>
</div>
