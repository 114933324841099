import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Module } from 'src/app/models/module.model';
import { Level } from 'src/app/models/shared/level.interface';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { SharedService } from 'src/app/shared/services/shared.service';

interface ExamResolverResponse { module: Module; levels: Level[]; }

@Injectable({
  providedIn: 'root'
})
export class ExamResolver implements Resolve<ExamResolverResponse> {

  constructor(private _modulesService: ContentModulesService, private _sharedService: SharedService) {

  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ExamResolverResponse> {
    const moduleId = route.paramMap.get('moduleId');
    const trackId = route.queryParams['trackId'];

    return forkJoin({
      module: this._modulesService.getModuleById(moduleId, trackId).pipe(map(response => response.data)),
      levels: this._sharedService.getLevels(true).pipe(map(response => response.data))
    })
      .pipe(
        map((response) => route.data = { ...route.data, module: new Module(response.module), levels: response.levels })
      );
  }
}
