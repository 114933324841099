<div class="filter">

  <app-autocomplete #Autocomplete *ngIf="environment.features.effortPerformance"
    [filter]="filters?.track"
    mdePopoverPositionY="above"
    (triggerSearch)="setupUpdateFilter(filters?.track,$event)"
    (change)="updateFilterOptions(filters?.track, $event)"
    (remove)="removeFilter(filters?.track, $event)">
  </app-autocomplete>
  <app-autocomplete *ngIf="filters?.track?.selectedOptions.length > 0"
    [filter]="filters?.trackModule"
    (triggerSearch)="setupUpdateFilter(filters?.trackModule,$event,true)"
    (change)="updateFilterOptions(filters?.trackModule, $event, true)"
    (remove)="removeFilter(filters?.trackModule, $event)">
  </app-autocomplete>

  <app-autocomplete #Autocomplete *ngIf="environment.features.effortTimeCalculation && filters?.track?.selectedOptions.length === 0"
    [filter]="filters?.module"
    mdePopoverPositionY="above"
    (triggerSearch)="setupUpdateFilterNoOptions(filters?.module,$event)"
    (change)="updateFilterNoOptions(filters?.module, $event)"
    (remove)="removeFilterNoOptions(filters?.module, $event)">
  </app-autocomplete>

  <app-user-search
    (userRemoved)="removeStudent($event)"
    (userSelected)="setStudent($event)">
  </app-user-search>

  <app-autocomplete
    [filter]="filters?.segment"
    (triggerSearch)="setupUpdateFilter(filters?.segment,$event)"
    (change)="updateFilterOptions(filters?.segment, $event)"
    (remove)="removeFilter(filters?.segment, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.businessGroup"
    (triggerSearch)="setupUpdateFilter(filters?.businessGroup,$event)"
    (change)="updateFilterOptions(filters?.businessGroup, $event)"
    (remove)="removeFilter(filters?.businessGroup, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.businessUnit"
    (triggerSearch)="setupUpdateFilter(filters?.businessUnit,$event)"
    (change)="updateFilterOptions(filters?.businessUnit, $event)"
    (remove)="removeFilter(filters?.businessUnit, $event)">
  </app-autocomplete>

  <mat-form-field>
    <input matInput
      placeholder="Data inicial"
      [ngxMatDatetimePicker]="pickerStart"
      (focus)="pickerStart.open();"
      [(ngModel)]="filter.date.start" />
    <mat-datepicker-toggle matPrefix [for]="pickerStart"></mat-datepicker-toggle>
    <mat-hint *ngIf="environment.features.effortPerformance">Válido somente para o relatório de esforço</mat-hint>
    <ngx-mat-datetime-picker #pickerStart
      [showSeconds]="true"
      [stepHour]="1"
      [enableMeridian]="false"
      [stepMinute]="1"
      [stepSecond]="1">
    </ngx-mat-datetime-picker>
  </mat-form-field>

  <mat-form-field>
    <input matInput
      placeholder="Data final"
      [ngxMatDatetimePicker]="pickerEnd"
      (focus)="pickerEnd.open()"
      [(ngModel)]="filter.date.end" />
    <mat-datepicker-toggle matPrefix [for]="pickerEnd"></mat-datepicker-toggle>
    <mat-hint *ngIf="environment.features.effortPerformance">Válido somente para o relatório de esforço</mat-hint>
    <ngx-mat-datetime-picker #pickerEnd
      [showSeconds]="true"
      [stepHour]="1"
      [enableMeridian]="false"
      [stepMinute]="1"
      [stepSecond]="1">
    </ngx-mat-datetime-picker>
  </mat-form-field>

</div>
