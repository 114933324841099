<h3>{{ content.title }}</h3>
<h4>{{ content.subjectTitle }}</h4>

<div class="content">
  <div class="info">
    <p class="label">
      Arquivo *
    </p>
    <div class="file-upload">
      <p>
        {{
          formGroup.get('fileName').value ?
            formGroup.get('fileName').value : ' Escolha o Arquivo'
        }}
      </p>
      <button class="btn-test primary" (click)="openFileUpload()">
        {{ formGroup.get('fileName').value ? 'Editar' : 'Upload' }}
      </button>
      <input class="input-file" type="file" id="inputFile" hidden (change)="setZipFile($event, formGroup)" />
    </div>

    <mat-form-field>
      <textarea matInput placeholder="Sobre (máx. MMM caracteres)" [(ngModel)]="content.excerpt" required></textarea>
    </mat-form-field>

    <p class="label">
      Referêcias Úteis
    </p>
    <div class="references">
      <input matInput placeholder="URL"
        *ngFor="let ref of content.referenceUrls; let index = index; trackBy: referencesTrackBy"
        [(ngModel)]="content.referenceUrls[index]" required />
    </div>
    <button class="btn-test" (click)="addReference()">
      + Adicionar Referência
    </button>
  </div>

  <div class="concepts">
    <p class="title">Instruções</p>
    <p>
      Para configurar o conteúdo basta fazer upload do conteúdo no campo acima na esquerda
      e configurar os conceitos colocando.
    </p>

    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Conceitos</th>
            <th>Página</th>
          </tr>
        </thead>
        <tbody>
          <tr [ngClass]="{concept: true,disabled: !formGroup.get('fileName').value}"
            *ngFor="let concept of content.concepts">
            <td>
              <div class="checkbox" [ngClass]="{ 'checked': concept.checked }" (click)="setConcept(concept)"></div>
              {{ concept.name }}
            </td>
            <td>
              <input matInput type="number" placeholder="página" *ngIf="concept.checked" required
                [(ngModel)]="concept['positions'][0]" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<div class="actions">
  <button class="btn-outline" (click)="dismiss()">
    Cancelar
  </button>
  <button class="btn-test" (click)="save()" [disabled]="isLoading || !formGroup.get('downloadLink').value">
    Salvar
  </button>
</div>
