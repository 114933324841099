import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Data } from './data.model';

@Component({
  selector: 'app-generic-card',
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss']
})
export class GenericCardComponent {

  @Input() data: Data;
  @Input() hasClone: boolean = false;
  @Input() hasEdit: boolean = true;
  @Input() hasDelete: boolean = false;
  @Input() showId: boolean = false;
  @Output() edit = new EventEmitter<Data>();
  @Output() delete = new EventEmitter<Data>();
  @Output() clone = new EventEmitter<Data>();
  constructor() { }


}
