<div class="inner-content">
  <div class="inner-content-header">
    <h2>GERENCIAR CONTATOS</h2>
    <div class="block">
      <button class="btn-test new-group-button" type="button" (click)="sendEmail()">
        CRIAR NOVO GRUPO
      </button>
    </div>
  </div>

  <table mat-table matSort [dataSource]="customEmails" (matSortChange)="sortData($event)" class="mat-elevation-z8">

    <ng-container matColumnDef="title">
      <th width="30%" mat-header-cell *matHeaderCellDef> Titulo </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th width="30%" mat-sort-header="createdAt" mat-header-cell *matHeaderCellDef> Data </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.createdAt | date : 'dd/MM/yyyy':'+0000' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="usersCount">
      <th width="30%" mat-header-cell *matHeaderCellDef> Total de Usuários </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.usersIds.length }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th width="10%" mat-header-cell *matHeaderCellDef> Detalhes </th>
      <td width="10%" mat-cell *matCellDef="let row">
        <div class="action">
          <span class="send" (click)="resendEmail(row.id)">
            <mat-icon>send</mat-icon>REENVIAR
          </span>
          <span class="edit" (click)="edit(row.id)">
            <mat-icon>border_color</mat-icon>EDITAR
          </span>
          <span class="delete" (click)="delete(row.id)">
            <mat-icon>delete</mat-icon>DELETAR
          </span>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="empty-data" *ngIf="!customEmailsCount">
    <p>Você ainda não tem nenhum grupo criado</p>
    <p>Crie um grupo no botão abaixo para enviar mensagens</p>
    <button class="btn-test new-group-button" type="button" (click)="sendEmail()">
      CRIAR NOVO GRUPO
    </button>
  </div>

  <app-pagination *ngIf="customEmailsCount" [setPagination]="{
      'itemsCount': customEmailsCount,
      'pageSize': pageSize
    }" (goToPage)="goToPage($event)"></app-pagination>
</div>
