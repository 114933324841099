import { Component, OnInit, ViewChild, SimpleChanges, OnChanges, DoCheck, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NotificationClass } from '../../shared/classes/notification';
import { EventApplication } from '../../models/event-application.model';
import { SettingsEventsService } from '../_services/events.service';
import { ApplicationStatus } from 'src/app/models/enums/application-status';
import { Subscription } from 'rxjs';
import { EventScheduleTypeEnum } from 'src/app/models/enums/event-schedule.enum';

@Component({
  selector: 'app-settings-events-applications',
  templateUrl: './events-applications.component.html',
  styleUrls: ['./events-applications.component.scss']
})
export class SettingsEventsApplicationsComponent extends NotificationClass implements OnInit {

  public pendingApplications: Array<EventApplication> = [];
  public approvedApplications: Array<EventApplication> = [];
  public deniedApplications: Array<EventApplication> = [];
  public eventInfo: any = {};
  public applications: Array<EventApplication> = [];
  public scheduleType: EventScheduleTypeEnum;
  public scheduleEnum = EventScheduleTypeEnum;

  private _eventId: string;
  private _scheduleId: string;

  constructor(
    protected _snackBar: MatSnackBar,
    private _eventsService: SettingsEventsService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    this._scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
    this._loadApplications(this._eventId, this._scheduleId);
  }

  public loadApplications() {
    return this._loadApplications(this._eventId, this._scheduleId);
  }

  private _loadApplications(eventId: string, scheduleId: string): Subscription {
    return this._eventsService.getEventsApplicationsByEventId(eventId, scheduleId).subscribe((response) => {
      const event = response.data.event;
      const schedule = event.schedules.find(x => x.id === scheduleId);
      this.scheduleType = schedule.eventScheduleType.id;
      this.eventInfo.eventTitle = event.title;
      this.eventInfo.eventDate = schedule.eventDate;
      this.eventInfo.scheduleStartDate = schedule.subscriptionStartDate;
      this.eventInfo.scheduleEndDate = schedule.subscriptionEndDate;
      this.eventInfo.percentage = (
        (new Date().getTime() - new Date(schedule.subscriptionStartDate).getTime()) /
        (new Date(schedule.subscriptionEndDate).getTime() - new Date(schedule.subscriptionStartDate).getTime())
      ) * 100;
      this.applications = response.data.applications;
      this._setApplicationsByStatus(this.applications);
    });
  }

  private _setApplicationsByStatus(applications: Array<EventApplication>): void {
    this.pendingApplications = applications.filter(a => a.applicationStatus === ApplicationStatus.Pending);
    this.approvedApplications = applications.filter(a => a.applicationStatus === ApplicationStatus.Approved);
    this.deniedApplications = applications.filter(a => a.applicationStatus === ApplicationStatus.Rejected);
  }

  public approveUser(userId: string) {
    const pendingApplications = this.pendingApplications.slice();
    const approvedApplications = this.approvedApplications.slice();
    const deniedApplications = this.deniedApplications.slice();
    let index = pendingApplications.findIndex(x => x.user.id === userId);
    if (index !== -1) {
      const removedItem = pendingApplications.splice(index, 1)[0];
      removedItem.applicationStatus = ApplicationStatus.Approved;
      this.pendingApplications = pendingApplications;
      approvedApplications.push(removedItem);
      this.approvedApplications = approvedApplications;
    } else {
      index = deniedApplications.findIndex(x => x.user.id === userId);
      if (index !== -1) {
        const removedItem = deniedApplications.splice(index, 1)[0];
        removedItem.applicationStatus = ApplicationStatus.Approved;
        this.deniedApplications = deniedApplications;
        approvedApplications.push(removedItem);
        this.approvedApplications = approvedApplications;
      }
    }
  }

  public denyUser(userId: string) {
    const pendingApplications = this.pendingApplications.slice();
    const approvedApplications = this.approvedApplications.slice();
    const deniedApplications = this.deniedApplications.slice();
    let index = pendingApplications.findIndex(x => x.user.id === userId);
    if (index !== -1) {
      const removedItem = pendingApplications.splice(index, 1)[0];
      removedItem.applicationStatus = ApplicationStatus.Rejected;
      this.pendingApplications = pendingApplications;
      deniedApplications.push(removedItem);
      this.deniedApplications = deniedApplications;
    } else {
      index = approvedApplications.findIndex(x => x.user.id === userId);
      if (index !== -1) {
        const removedItem = approvedApplications.splice(index, 1)[0];
        removedItem.applicationStatus = ApplicationStatus.Rejected;
        this.approvedApplications = approvedApplications;
        deniedApplications.push(removedItem);
        this.deniedApplications = deniedApplications;
      }
    }
  }


}
