import { Component, OnInit, Input } from '@angular/core';
import * as Chart from 'chart.js';
import { EffortProgress } from 'src/app/pages/track/track-overview/track-effort-progress/models/trackEffort.model';
import { values, sum, mapValues } from 'lodash';

@Component({
  selector: 'app-track-effort-progress',
  templateUrl: './track-effort-progress.component.html',
  styleUrls: ['./track-effort-progress.component.scss']
})
export class TrackEffortProgressComponent {

  public chart: Chart;

  private _dataset: EffortProgress;
  @Input()
  public get dataset(): EffortProgress { return this._dataset; }
  public set dataset(dataset: EffortProgress) {
    dataset = mapValues(dataset, prop => prop = (+prop.toFixed(2)));
    this._dataset = dataset;
    if (sum(values(dataset)) > 0) {
      this._initChart();
    }
  }

  private get _chartDataset() {
    return [
      this._createDatasetObject({
        dataSetValue: this.dataset.progressUntilDay,
        sulfix: 'Meu progresso',
        color: this._colors.progressUntilDay
      }),
      this._createDatasetObject({
        dataSetValue: this.dataset.expectedProgressUntilDay,
        sulfix: 'Progresso esperado',
        color: this._colors.expectedProgressUntilDay
      }),
      this._createDatasetObject({
        dataSetValue: this.dataset.averageStudentProgress,
        sulfix: 'Progresso da turma',
        color: this._colors.averageStudentProgress
      }),

    ];
  }

  private _colors: {
    expectedProgressUntilDay: string;
    progressUntilDay: string;
    averageStudentProgress: string;
  };

  constructor() {
    const style = getComputedStyle(document.body);
    this._colors = {
      expectedProgressUntilDay: style.getPropertyValue('--progress-bar-expected'),
      progressUntilDay: style.getPropertyValue('--progress-bar-completed'),
      averageStudentProgress: style.getPropertyValue('--progress-bar-average-class'),
    };
  }


  private _initChart(): void {
    this.chart = new Chart('TrackEffortProgress', {
      type: 'horizontalBar',
      options: {
        responsive: true,
        tooltips: { enabled: false },
        showLines: false,
        legend: {
          position: 'right',
          labels: {
            boxWidth: 30,
            fontSize: 14,
            fontFamily: 'Montserrat',
          }
        }
        , scales: {
          ticks: {
            display: false
          },
          xAxes: [{
            ticks: { beginAtZero: true, max: 100 },
            display: false, offset: true
          }],
          yAxes: [{
            ticks: { beginAtZero: true, max: 100 },
            display: false, offset: true
          }]
        },
      },
      data: {
        datasets: this._chartDataset
      }
    });

  }


  private _createDatasetObject({ sulfix, color, dataSetValue }) {
    return {
      label: `${dataSetValue.toFixed(2)}% ${sulfix}`,
      data: [dataSetValue],
      fill: true,
      backgroundColor: [color],
      hoverBackgroundColor: [color],
      maxBarThickness: 40,
      barThickness: 50,
    } as Chart.ChartDataSets;
  }

}
