import { BackendResponse, BackendService } from './backend-service';
import { Injectable } from '@angular/core';
import { BaseUrlService } from './url-service';
import { TokenStorageService } from 'src/app/shared/services/token-storage.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AuthenticationInfo {
    public access_token: string;
    public expires_in: number;
    public refresh_token: string;
    public remember: boolean;
}

@Injectable()
export class AuthService {
    constructor(private http: BackendService, private tokenService: TokenStorageService) {}

    private AuthCache: AuthenticationInfo = null;

    protected setAuthentication(
        info: AuthenticationInfo,
        remember: boolean = true
    ) {
        const key = "auth_data";
        const jsonInfo = JSON.stringify(info);

        if (remember) {
            localStorage.setItem(key, jsonInfo);
        }

        sessionStorage.setItem(key, jsonInfo);
        this.AuthCache = info;
    }

    public getAuthentication(): AuthenticationInfo {
        const key = "auth_data";
        /*if (this._verifyAuthCache( this.AuthCache ))
            return this.AuthCache;*/
        let jsonInfo = sessionStorage.getItem(key);
        if (!jsonInfo) jsonInfo = localStorage.getItem(key);
        this.AuthCache = jsonInfo ? JSON.parse(jsonInfo) : null;
        return this.AuthCache;
    }

    public isAuthenticated(): boolean {
        const info = this.getAuthentication();
        return info != null;
    }

    public logout() {}

    public async login() {
        var response = await this.http
            .post<AuthenticationInfo>("login", null)
            .toPromise();

        this.setAuthentication(response.data);
    }

    public refreshToken(): Observable<string> {
        const refresh_token = this.getAuthentication().refresh_token;

        return this.http
            .post<AuthenticationInfo>("refresh_token", { token: refresh_token })
            .pipe(
                map(resp => {
                    this.setAuthentication(resp.data);
                    return resp.data.refresh_token;
                })
            );
    }



    public getAuthorizationHeader(): string {
        return this.getAuthentication().access_token;
    }

    private _verifyAuthCache(auth: AuthenticationInfo) {
        return auth != null &&
               auth.access_token &&
               auth.access_token.trim() !== '';
    }
    public refreshsToken(refresh_token: string): Observable<BackendResponse<AuthenticationInfo>> {
		return this.http
        .post<AuthenticationInfo>('refresh_token', { token: refresh_token });
	}
}
