/* tslint:disable */
const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const mastPattern = /^(?:5[1-5][0-9]{14})$/;
const amexPattern = /^(?:3[47][0-9]{13})$/;
const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

export function validateCreditCardNumber(number) {
  number = number.slice(0, 16);

  const isVisa = visaPattern.test(number) === true;
  const isMast = mastPattern.test(number) === true;
  const isAmex = amexPattern.test(number) === true;
  const isDisc = discPattern.test(number) === true;

  return (isVisa || isMast || isAmex || isDisc);
}

export function validateExpirationDate(expirationDate) {
  expirationDate = expirationDate.slice(0, 7);

  const monthYear = expirationDate.split('/');
  const year = parseInt(monthYear[1]);
  const month = parseInt(monthYear[0]);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  if (year < currentYear)
    return false;
  else if (year == currentYear && month < currentMonth)
    return false;
  else if (month < 1 || month > 12)
    return false;
  else if (year < 1)
    return false;

  return true;
}

export function getCreditCardFlag(number) {
  number = number.slice(0, 16);

  if (visaPattern.test(number) === true)
    return 1;
  else if (mastPattern.test(number) === true)
    return 2;
  else if (amexPattern.test(number) === true)
    return 3;
  else if (discPattern.test(number) === true)
    return 4;

  return 0;
}
