import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { CockpitService } from 'src/app/pages/_services/cockpit.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  Ifilter,
  Istudents,
  ItrackModule,
  ItracksStudentsSubordinates,
  IstudentsRanking,
  ImetricsStudents,
  ItrackInfo,
  Eselect,
} from './../../models/trackStudentsSubordinates';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cockpit-track-class',
  templateUrl: './cockpit-track-class.component.html',
  styleUrls: ['./cockpit-track-class.component.scss'],
})
export class CockpitTrackClassComponent implements OnInit {
  public tracksStudentsSubordinates: Array<ItracksStudentsSubordinates>;
  public students: Array<Istudents> = [];
  public studentsRanking: Array<IstudentsRanking> = [];
  public trackModules: Array<ItrackModule> = [];
  public studentsMetrics: ImetricsStudents = {} as ImetricsStudents;
  private subscription: Subscription;
  constructor(public _cockpitService: CockpitService, private _router: Router) {}
  ngOnInit(): void {
    this._cockpitService.setSelectedStudents(Eselect.AllStudents);
    const selectedTrack = this._cockpitService.getSelectedTrack().getValue();
    if (selectedTrack) this._cockpitService.setTitle(selectedTrack.title);
  }

  public goToModule(moduleId: string) {
    this._router.navigate(['empenho-desempenho/module/' + this._cockpitService.getSelectedTrack().getValue().id + '/' + moduleId]);
  }
  public goToStudent(student: Istudents) {
    this._cockpitService.setSelectedStudents(student);
    const track = this._cockpitService.getSelectedTrack().getValue();
    this._router.navigate(['empenho-desempenho/students/track/' + track.id + '/' + student.id], {
      queryParams: { trackId: this._cockpitService.getSelectedTrack().getValue().id },
    });
  }
}
