<header>
  <h2 mat-dialog-title>ALUNOS</h2>

  <mat-form-field>
    <input
      type="text"
      matInput
      placeholder="Filtre por um nome de aluno"
      (keyup)="updateSearch($event.target.value)" />
  </mat-form-field>
</header>

<mat-dialog-content>
  <ngx-datatable
    #Table
    class="material fullscreen"
    [rows]="data"
    trackByProp="id"
    minWidth="20"
    [rowIdentity]="rowIdentity"
    [messages]="messages"
    [selectionType]="SelectionType.checkbox"
    [headerHeight]="50"
    [selectAllRowsOnPage]="false"
    [selected]="selectedRows"
    [loadingIndicator]="loader"
    [footerHeight]="50"
    [rowHeight]="rowHeight"
    [columnMode]="ColumnMode.force"
    [scrollbarV]="true">
    <ngx-datatable-column
      [minWidth]="50"
      [maxWidth]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="true"
      [checkboxable]="true">
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="50"
      [maxWidth]="50"
      name=""
      prop="imageUrl">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <img class="photo" src="{{value || imagePlaceholder}}" />
      </ng-template>
    </ngx-datatable-column>

    <ng-container *ngFor="let column of columns">
      <ngx-datatable-column
        *ngIf="existProp(column)"
        [prop]="column?.prop"
        [name]="column?.name">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
    </ng-container>

  </ngx-datatable>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button class="btn-outline" (click)="exportData()">Incluir os selecionados</button>
  <button class="btn-test" (click)="dismiss()">Fechar</button>
</mat-dialog-actions>
