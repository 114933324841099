import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { isValid, parse } from "date-fns";
import { isNil } from "lodash";
import { ProgramsService } from "src/app/pages/_services/programs.service";
import { ImportExcelFilesComponent } from "src/app/shared/components/import-excel-files/import-excel-files.component";
import { IErroList } from "src/app/shared/components/import-excel-files/Models/erro-list.interface";
import { ExcelService } from "src/app/shared/services/excel.service";
import { mandatoryCollumnEnum } from "./Enums/mandatory-collumn.enum";
import { ProgramImport } from "./Models/program-import.model";

@Component({
    selector: 'app-exemption-user-dialog',
    templateUrl: '../../../shared/components/import-excel-files/import-excel-files.component.html',
    styleUrls: ['../../../shared/components/import-excel-files/import-excel-files.component.scss']
})
export class DialogProgramsImportComponent extends ImportExcelFilesComponent {
    public showRadionButtons: boolean = false;
    public name = 'Programas';
    public pathTemplate: string = './../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_PROGRAMAS.xlsx';
    public downloadName: string = 'PLANILHA_MODELO_IMPORTAÇÃO_PROGRAMAS.xlsx';
    public subtitle = '';
    public get mandatoryCollumns(): string[] {
        return [
            mandatoryCollumnEnum.programName,
            mandatoryCollumnEnum.description,
            mandatoryCollumnEnum.startDate,
            mandatoryCollumnEnum.endDate,
            mandatoryCollumnEnum.team,
        ];
    }

    public loading: boolean = false;
    public erroList: any;
    public data: ProgramImport[] = [];
    constructor(_excelService: ExcelService,
        _snackBar: MatSnackBar,
        private _programsService: ProgramsService,
        private _dialogRef: MatDialogRef<DialogProgramsImportComponent>) {
        super(_excelService, _snackBar);
    }


    public validateJson(contentJson: any, headers: any): IErroList[] {
        let index = 0;
        const erroList: IErroList[] = [];
        const isValidDate = (strDate: string): boolean => {
            const date = this.parseDate(strDate);
            return isValid(date);
        };

        while (index < contentJson.length) {
            const excelContent = contentJson[index];

            if (isNil(excelContent[mandatoryCollumnEnum.programName])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.programName),
                    row: excelContent['row'],
                    description: 'Este campo não pode ser nulo'
                });
            }

            if (isNil(excelContent[mandatoryCollumnEnum.description])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.description),
                    row: excelContent['row'],
                    description: 'Este campo não pode ser nulo'
                });
            }
            if (isNil(excelContent[mandatoryCollumnEnum.startDate])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.startDate),
                    row: excelContent['row'],
                    description: 'Este campo não pode ser nulo'
                });
            } else if (!isValidDate(excelContent[mandatoryCollumnEnum.startDate])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.startDate),
                    row: excelContent['row'],
                    description: 'Insira um formata de data válido'
                });
            }
            if (isNil(excelContent[mandatoryCollumnEnum.endDate])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.endDate),
                    row: excelContent['row'],
                    description: 'Este campo não pode ser nulo'
                });
            } else if (!isValidDate(excelContent[mandatoryCollumnEnum.endDate])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.endDate),
                    row: excelContent['row'],
                    description: 'Insira um formata de data válido'
                });
            }

            index++;
        }
        return erroList;
    }
    public setValues(contentJson: any, headers: any): void {
        let index = 0;
        while (index < contentJson.length) {
            const excelContent = contentJson[index];
            this.data.push({
                title: excelContent[mandatoryCollumnEnum.programName],
                description: excelContent[mandatoryCollumnEnum.description],
                startDate: this.parseDate(excelContent[mandatoryCollumnEnum.startDate]),
                endDate: this.parseDate(excelContent[mandatoryCollumnEnum.endDate]),
                teams: !isNil(excelContent[mandatoryCollumnEnum.team])
                    ? (excelContent[mandatoryCollumnEnum.team] as string).split(";").map(value => value.trim())
                    : []
            });
            index++;
        }
    }
    public save(): void {
        this.loading = true;
        this._programsService.ImportProgram(this.data).subscribe(response => {
            this.loading = false;
            this._dialogRef.close(true);
        }, error => {
            this.loading = false;
            error.error.errors.forEach(err => {
                this.erroList.push({ description: err });
            });
        });
    }

    public dismiss() {
        this._dialogRef.close();
    }

    public parseDate(strDate: string): Date {
        const date = parse(strDate);

        return date;
    }
}