import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TrackPreview } from '../../../models/previews/track.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TrackDraft } from 'src/app/models/track-draft.model';

@Component({
  selector: 'app-settings-track-card',
  template: `
    <div class="track-card" >
      <img class="main-img" [src]="track.imageUrl" />
      
      <div class="draft-tag" [ngClass]="{ 'draft': isDraft }" >
        {{ isDraft ? 'RASCUNHO': 'PUBLICADO' }}
      </div>
      
      <div class="preview" >
        <div>
          <h3>
            {{ track.title }}<br>
            <small *ngIf="isAdmin" >
              Id: {{ track.id }}
            </small>
          </h3>
        </div>
        <p>{{ moduleCount }} MÓDULOS / {{ eventCount }} EVENTOS</p>
      </div>

      <div class="edit" >
        <img src="./assets/img/edit.png" (click)="editTrack.emit(track)" />
        <img src="./assets/img/trash.png" (click)="deleteTrack.emit(track)" />
        <img src="./assets/img/icon_trilha.png" (click)="cloneTrack.emit(track)" />
      </div>
    </div>
  `,
  styleUrls: ['./track-card.component.scss']
})
export class SettingsTrackCardComponent {

  private _track: TrackPreview | TrackDraft;
  @Input()
  public get track(): TrackPreview | TrackDraft {
    return this._track;
  }
  public set track(value: TrackPreview | TrackDraft) {
    if (value instanceof TrackDraft) {
      this.moduleCount = value?.modulesConfiguration?.length || 0;
      this.eventCount = value?.eventsConfiguration?.length || 0;
      this.isDraft = value.isDraft;
    } else {
      this.moduleCount = value.moduleCount;
      this.eventCount = value.eventCount;
    }

    this._track = value;
  }
  @Output() editTrack = new EventEmitter<TrackPreview | TrackDraft>();
  @Output() deleteTrack = new EventEmitter<TrackPreview | TrackDraft>();
  @Output() cloneTrack = new EventEmitter<TrackPreview | TrackDraft>();

  public isAdmin: boolean = false;
  public isDraft: boolean = false;
  public moduleCount: number;
  public eventCount: number;

  constructor(
    private _authService: AuthService
  ) {
    const user = this._authService.getLoggedUser();
    this.isAdmin = user && user.role && user.role === 'Admin';
  }

}
