import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as Chart from 'chart.js';
import { isEmpty, isNil } from 'lodash';
import { IeffortMetric } from 'src/app/pages/cockpit/models/studentMetrics';

@Component({
  selector: 'app-track-effort-progress-chart',
  templateUrl: './track-effort-progress-chart.component.html',
  styleUrls: ['./track-effort-progress-chart.component.scss']
})
export class TrackEffortProgressChartComponent {

  public chart: Chart;
  private _effortMetric: IeffortMetric;
  @Input()
  get effortMetric() {
    return this._effortMetric;
  }
  set effortMetric(effortMetric: IeffortMetric) {
    this._effortMetric = effortMetric;
    if (!isNil(this._effortMetric) && !isEmpty(this._effortMetric)) {
      this.chart = new Chart('cockpit-radar', {
        type: 'radar',
        data: {
          labels: [' ', ' ', ' '],
          datasets: [{
            data: [
              effortMetric.expectedProgressUntilDay / 10,
              null,
              effortMetric.expectedInteractionUntilDay / 10
            ],
            backgroundColor: 'rgba(255, 141, 158, 0.35)',
            borderColor: 'rgb(255, 141, 158)',
            pointBackgroundColor: 'rgb(255, 141, 158)',
          },
          {
            data: [
              effortMetric.studentProgressUntilDay / 10,
              effortMetric.studentParticipationUntilDay / 10,
              effortMetric.studentInteractionUntilDay
            ],
            backgroundColor: 'rgba(35, 188, 209, 0.35)',
            borderColor: 'rgb(35, 188, 209)',
            pointBackgroundColor: 'rgb(35, 188, 209)',
          },
          {
            data: [
              effortMetric.classProgressUntilDay / 10,
              effortMetric.classParticipationUntilDay / 10,
              effortMetric.classInteractionUntilDay / 10
            ],
            backgroundColor: 'rgba(255, 224, 141, 0.35)',
            borderColor: 'rgb(244, 214, 134)',
            pointBackgroundColor: 'rgb(255, 244, 141)',
          }
          ]
        },
        options: {
          responsive: true,
          legend: { display: false },
          scale: {
            ticks: {
              beginAtZero: true,
              max: 10,
              min: 0,
              stepSize: 1,
            },
            pointLabels: {
              fontSize: 26,
              fontColor: '#c4c4c4',
              fontStyle: 'bold'
            }
          }
        }
      });
    }
  }
  constructor() { }
}
