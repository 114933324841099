<header>
  <h2 mat-dialog-title>AVISO</h2>
</header>

<mat-dialog-content>
  <div class="mat-h4" [innerHtml]="message"></div>
</mat-dialog-content>

<mat-dialog-actions class="footer">
    <button class="btn-test" (click)="dismiss()">Estou ciente</button>
</mat-dialog-actions>
