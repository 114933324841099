import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationClass } from '../../shared/classes/notification';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BtgLoginComponent } from './btg-login/btg-login.component';
import { environment } from '../../../environments/environment';
import { ExternalService } from 'src/app/shared/services/external.service';
import { Credential } from 'src/app/models/login.model';
import Player from '@vimeo/player';
import { CookieService } from 'ngx-cookie-service';
import { WarningDialogComponent } from 'src/app/pages/login/warning-dialog/waning-dialog.component';
import { ForgotPassDialogComponent } from './forgot-pass-dialog/forgot-pass-dialog.component';
import { duration } from 'moment';
import { isNil } from 'lodash';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { CommunicationService } from 'src/app/settings/_services/communications.service';
import { Communication } from 'src/app/settings/comunication/Models/communication.model';
import * as sha from "sha.js";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends NotificationClass implements OnInit {

  public loading: boolean;
  public formGroup: FormGroup;
  public firstAccessFormGroup: FormGroup;
  public hasSSO: boolean;
  public hasSignUp: boolean;
  public hasLoginLinkedin: boolean;
  public params: any;
  public isFirstAccess: boolean = false;
  public showLoader: boolean = false;
  public player;
  public apiUrl: string;
  public linkedIn: string = 'https://www.linkedin.com/oauth/v2/authorization' +
    '?response_type=code&client_id=' + environment.linkedInId +
    '&redirect_uri=' + environment.linkedInRedirect +
    '&scope=r_liteprofile%20r_emailaddress';
  private videoUrl: string = 'https://player.vimeo.com/video/308296447';
  public communications: Communication[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _authService: AuthService,
    private _externalService: ExternalService,
    private _dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private _cookieService: CookieService,
    private _communicationService: CommunicationService
  ) {
    super(_snackBar);
    this.formGroup = new FormGroup({
      'username': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required])
    });
    this.hasSSO = environment.features.sso.feature;
    this.hasSignUp = environment.features.registration;
    this.hasLoginLinkedin = environment.features.loginLinkedIn;
    this.apiUrl = environment.apiUrl;
    this.firstAccessFormGroup = new FormGroup({
      'fullName': new FormControl('', [Validators.required]),
      'phone': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required]),
      'cpf': new FormControl('', [Validators.required])
    });

    this.route.queryParams.subscribe(params => {
      this.params = Object.assign({}, params);
    });
  }

  ngOnInit() {
    if (this._authService.hasToken())
      this._redirectUser();
    else if (this.params && this.params['ssoerror'])
      setTimeout(() => {
        this.notify(this.params['ssoerror']);
      });
    else if (this.params && this.params['ssosuccess']) {
      const tokenInfo = JSON.parse(this.params['token']);
      this._authService.ssoLogin(tokenInfo);
      if (tokenInfo.first_access && environment.features.firstLoginPersonalDataForm) {
        this.isFirstAccess = true;
        this.formGroup.get('username').setValue(tokenInfo.username);
        this._updateForm(tokenInfo);
        // this._setIntroductionVideo();
      } else {
        this._redirectUser();
      }
    } else {
      this.route.queryParams.subscribe(params => {
        const linkedInCode = params['code'];
        if (linkedInCode) {
          this.showLoader = true;
          this._externalService.linkedInLogin(linkedInCode).subscribe(res => {
            this._authService.ssoLogin(res.data.tokenInfo);
            this._redirectUser();
            this.showLoader = false;
          }, err => {
            this.notify(this.getErrorNotification(err));
          });
        }
      });
    }
    this.loadCommunications();
  }

  public openBtgDialog(): void {
    this._dialog.open(BtgLoginComponent, {
      width: '400px'
    });
  }

  public openDialog(): void {
    const dialogRef = this._dialog.open(ForgotPassDialogComponent, {
      width: '800px',
    });

    dialogRef.afterClosed().subscribe(async (credentials: any) => {
      if (credentials) {
        await this.forgotPass(credentials);
      }
    });
  }

  public async forgotPass(credentials: any) {
    if (credentials) {
      this.loading = true;
      this._authService.forgotPass(credentials).subscribe(() => {
        this.loading = false;
        const config = new MatSnackBarConfig();
        config.duration = 5000;
        config.verticalPosition = 'top';
        this.notify('Foi enviado um email ao usuário com uma nova senha de acesso. Verifique sua caixa de entrada ou spam do seu email.',
          'OK',
          config);
      }, () => {
        this.loading = false;
        this.notify('Usuário inválido');
      });
    } else {
      this.notify('Preencha o campo de usúario');
    }
  }

  public signUp() {
    this.router.navigate(['register']);
  }

  public async doLogin() {
    const credentials: Credential = this.formGroup.getRawValue();

    if (this.canForceLoginBySSO(credentials.username)) {
      this.loading = false;
      return this.notify('Para prosseguir com o processo de LOGIN você terá que LOGAR via SSO');
    }

    if(credentials.password==""){
      this.notify('Digite sua senha.');
      return;
    }
    this.loading = true;
    try {

      const result = await this._authService.login(credentials.username, sha('sha512').update(credentials.password + environment.salt).digest('hex'));

      if (result.success) {
        this.loading = false;
        if (result.data.tokenInfo.first_access) {
          this._handleFirstAccess(result);
        } else if (!result.data.tokenInfo.email_verified) {
          this.router.navigate(['confirmacao-email']);
        } else {
          this._redirectUser();
        }

      } else {
        const error = result && result.errors && result.errors.length > 0 ?
          result.errors[0] : 'Houve um erro ao se comunicar com o servidor. Por favor tente mais tarde.';
        this.loading = false;
        this.notify(error);
      }
    } catch (err) {
      this.notify('Houve um erro ao se comunicar com o servidor. Por favor tente mais tarde.');
      this.loading = false;
    }
  }

  private _handleFirstAccess(result): void {
    const role = this._authService.getLoggedUserRole();
    if (role === 'Recruiter' || role === 'BusinessManager') {
      this.router.navigate(['configuracoes/identificacao-empresa']);
    } else if (environment.features.firstLoginPersonalDataForm) {
      this.isFirstAccess = true;
      this._updateForm(result.data.user);
      // this._setIntroductionVideo();
    } else {
      this._redirectUser();
    }
  }

  public async saveAndContinue() {
    this.loading = true;
    const credentials = this.firstAccessFormGroup.getRawValue();
    credentials.username = this.formGroup.getRawValue().username;
    try {
      const result = await this._authService.firstAccess(credentials);
      if (result.success) {
        this.loading = false;
        if (!result.data.email_verified) {
          this.router.navigate(['confirmacao-email']);
        }
        this._redirectUser();
      } else {
        const error = result && result.errors && result.errors.length > 0 ?
          result.errors[0] : 'Houve um erro ao se comunicar com o servidor. Por favor tente mais tarde.';
        this.loading = false;
        this.notify(error);
      }
    } catch (err) {
      this.notify('Houve um erro ao se comunicar com o servidor. Por favor tente mais tarde.');
      this.loading = false;
    }
  }

  private _updateForm(user) {
    if (!user) return;
    this.firstAccessFormGroup.get('fullName').setValue(user.name);
    this.firstAccessFormGroup.get('phone').setValue(user.phone);
    this.firstAccessFormGroup.get('email').setValue(user.email);
    this.firstAccessFormGroup.get('cpf').setValue(user.cpf);
  }

  private _redirectUser(): void {
    this._showWarningMessage();
    const emailUrl = localStorage.getItem('emailUrl');
    if (emailUrl) {
      this.router.navigate([emailUrl]);
    } else {
      const role = this._authService.getLoggedUserRole();
      const redirectUrl = this._getRedirectUrl(role);
      this.router.navigate([redirectUrl]);
    }
  }

  private _getRedirectUrl(userRole: string): string {
    switch (userRole) {
      case 'Secretary':
        return 'configuracoes/usuarios';
      case 'Recruiter':
        return 'configuracoes/vagas-empresa';
      default:
        return this.route.snapshot.queryParams['returnUrl'] || 'home';
    }
  }

  private _showWarningMessage() {
    const setCookie = (prop, value) => {
      const expirationDate = new Date(2020, 7/* MÊS - 1 */, 24, 18);
      this._cookieService.set(prop, value, expirationDate, undefined, undefined, false, 'Lax');
    };

    setCookie('showLoginWarning', 'true');

    const hasViewed = this._cookieService.get('viewedTheWarning');

    if (hasViewed === 'true' || !this._cookieService.check('showLoginWarning')) return;
    const dialogRef = this._dialog.open(WarningDialogComponent, {
      width: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: true,
      closeOnNavigation: false,
      // tslint:disable-next-line: max-line-length
      data: `Realizaremos nossa manutenção programada no dia <strong>24 de agosto de 2020 a partir das 9h horário de Brasília</strong>.
      Sua duração se estenderá até as 18h:00 horário de Brasília.
      Durante esse período, você <strong>não</strong> terá acesso ao sistema.
      Para mais informações, acesse a <a href="atendimento">central de atendimento</a>.`
    });

    dialogRef.afterClosed().subscribe(viewedTheWarning => {
      setCookie('viewedTheWarning', viewedTheWarning);
    });
  }

  public canForceLoginBySSO(email: string): boolean {
    const ssoParameters = environment.features.sso;
    if (!this.hasSSO || !ssoParameters.forceLoginSso) return false;

    const emailDomain = email.substring(email.lastIndexOf('@') + 1).split('.')[0];
    if (isNil(emailDomain)) return false;

    return ssoParameters.domains.some(domainLoginSso => domainLoginSso === emailDomain);
  }
  public loadCommunications() {
    const startDate = new Date();

    startDate.setHours(0, 0, 0, 0);

    this._communicationService.getPagedCommunications(1, null, '', startDate, startDate).subscribe(response => {
      this.communications = response.data.communications;
      for (let index = 0; index < this.communications.length; index++) {
        const communication = this.communications[index];
        setTimeout(() => {
          this._configureEditor(communication.text, index);
        }, 100);
      }
    });
  }

  public removeCommunication(id: string) {
    this.communications = this.communications.filter(communication => communication.id !== id);
  }

  private _configureEditor(text: string, index: number): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const element = document.querySelector('#htmlEditor' + index) as HTMLElement;

    if (element === null) return;

    const editor = new Viewer({
      el: element,
    });

    editor.setMarkdown(text);
  }
}
