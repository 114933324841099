<div [ngClass]="{ 'list': viewType === 'list'}" class="track-card" [style.height]="height + 'px'"
  (click)="goToTrack($event)">
  <app-card-tag *ngIf="track.recommended && showRecommended && !track.instructor"
    [ngClass]="{ 'attending': track.recommended && showRecommended }"
    [text]="progress?.progress === 1 ? 'Finalizado' : progress?.exemptInAllModules ? 'Isento' : progress?.startedAnyModule ?  'Cursando' : 'Não iniciado' ">
  </app-card-tag>
  <img class="course-img" [src]="track.imageUrl" />

  <div class="content">
    <p class="title" *ngIf="showText">
      {{ track.title }}
    </p>
    <div class="modules" *ngIf="showModule || showEvents">
      <div class="all-track">
        <div class="showModule" *ngIf="showModule">
          <p>{{ track.moduleCount }}</p>
          <span> {{ track.moduleCount > 1 ? 'módulos' : 'módulo' }}&nbsp;</span>
        </div>
        <div class="showModule" *ngIf="showModule && showEvents">
          /
        </div>
        <div class="showEvents" *ngIf="showEvents">
          <p>&nbsp;{{ track.eventCount }} </p>
          <span> {{ track.eventCount > 1 ? 'eventos' : 'evento' }} </span>
        </div>
      </div>
    </div>

    <p class="excerpt" *ngIf="showContent">
      {{ getExcerptContent() }}
    </p>
  </div>

  <div class="buttons-and-price">
    <div class="button-store" *ngIf="showButtonKnowMore && hasFeatureKnowMore">
      <div></div>
      <button (click)="goToPageStoreUrl($event)">
        Saiba Mais
      </button>
    </div>

    <div class="card-footer">
      <div class="button-subscribe" *ngIf="!track?.recommended && showRecommended && !track.instructor">
        <button *ngIf="track.ecommerceProducts && track.ecommerceProducts[0]
            && track.ecommerceProducts[0].price && showPrice && showButtonSubscribe"
          (click)="goToPageEcommerceUrl($event)">
          Matricular
        </button>
      </div>
      <div class="button-subscribe-off" *ngIf="!track?.recommended && track?.ecommerceProducts && track?.ecommerceProducts[0]
            && track?.ecommerceProducts[0].disableFreeTrial && showButtonSubscribe">
        <p>GRÁTIS</p>
        <button (click)="goToPageEcommerceUrl($event)">
          Matricular
        </button>
      </div>
    </div>

    <div class="price">
      <p
        *ngIf="track.ecommerceProducts && track.ecommerceProducts[0] && track.ecommerceProducts[0].price && track.ecommerceProducts[0].price !== '0' && showPrice">
        {{ track.ecommerceProducts[0].price | currency:'BRL' }}
      </p>
    </div>
  </div>
</div>
