import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl } from '@angular/forms';
import { SharedService } from '../services/shared.service';
import { NotificationClass } from './notification';
import { ImageCropDialogComponent } from '../dialogs/image-crop/image-crop.dialog';
import { UploadResource } from '../../models/shared/upload-resource.interface';
import { UploadService } from 'src/app/shared/services/upload.service';

export class ImageUploadClass extends NotificationClass {

  constructor(
    protected _snackBar: MatSnackBar,
    protected _matDialog: MatDialog,
    protected _sharedService: SharedService,
    protected _uploadService: UploadService,

  ) {
    super(_snackBar);
  }

  public uploadImage(imageWidth: number, imageHeight: number, formControl: AbstractControl) {
    const dialogRef = this._matDialog.open(ImageCropDialogComponent, {
      width: window.innerWidth < 768 ? '100vw' : '50vw',
      data: {
        'canvasWidth': window.innerWidth < 768 ? 250 : 300,
        'canvasHeight': window.innerWidth < 768 ? 250 : 300,
        'croppedWidth': imageWidth,
        'croppedHeight': imageHeight
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.image) {
        this._uploadImage(
          result.image,
          formControl
        );
      }
    });
  }

  public removeImage(formControl: AbstractControl) {
    formControl.setValue('./assets/img/user-image-placeholder.png');
  }

  private _uploadImage(base64Image: string, formControl: AbstractControl) {
    this._uploadService.uploadFile(base64Image).subscribe(uploadedImageURL => formControl.setValue(uploadedImageURL)
      , () => {
        this.notify('Ocorreu um erro ao enviar a imagem, por favor tente novamente mais tarde');
      });
  }


}
