<div class="inner-content" >
  <h2>AVALIAÇÃO</h2>
  <p class="title" >
    {{ exam?.title }}
  </p>

  <p class="description" >
    {{ exam?.description }}
  </p>

  <div class="status" >
    <p>
      Nível Atual<br>
      <span *ngIf="levels">{{levels[userProgress?.level]}}</span>
    </p>
    <p>
      Aproveitamento<br>
      <span *ngIf="userProgress">{{userProgress.progress*100|number:'1.0-0'}} %</span>
    </p>
    <div *ngIf="userProgress">
      <p>Níveis Conquistados</p>
      <img [src]="getBadgesProgressImageSrc(userProgress.level)" />
    </div>
  </div>

  <div class="start-actions" >
    <button class="start" (click)="startExam()" >
      {{getContinueButtonText()}}
    </button>
    <button class="later" (click)="goBack()" >
      Deixar para Depois
    </button>
  </div>
</div>
