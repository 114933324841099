import { Component, Input, OnInit } from '@angular/core';
import { Module } from 'src/app/models/module.model';
import { Subject } from 'src/app/models/subject.model';
import { ActionInfo, ActionInfoPageEnum, ActionInfoTypeEnum } from 'src/app/shared/directives/save-action/action-info.interface';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-btn-exam',
  template: `
        <button class="btn-test"
        *ngIf="module && subject.hasQuestions"
        [ngClass]="{'btn-blocked': blocked}"
        (click)="module?.isAvailability && goToExam()"
        [attr.title]="disableMessage"
        [saveAction]="getActionInfo()"
        [disabled]="isDisabled" >
        {{ labelButtonTest }}
      </button>
  `,
  styleUrls: ['./btn-exam.component.scss']
})
export class BtnExamComponent extends NotificationClass implements OnInit {

  @Input() readonly subjectId: string;
  @Input() readonly levels = {};
  @Input() readonly reachedMaxLevel = false;
  private _hasFinishedRequirements = false;
  @Input()
  public get hasFinishedRequirements() {
    return this._hasFinishedRequirements;
  }
  public set hasFinishedRequirements(value: boolean) {
    this._hasFinishedRequirements = value;
    this.labelButtonTest = this._getLabelButtonText();
  }
  @Input() readonly isExemption: any;
  @Input() readonly blocked: any;

  public disableMessage: string;
  public hasFeaturePracticeQuestions = environment.features.practiceQuestions;

  public get isDisabled() {
    let maxLevel = this.reachedMaxLevel;
    if (maxLevel && this.hasFeaturePracticeQuestions && this.module.practiceQuestions) {
      maxLevel = false;
    }
    return !this.module.isEnrolled ||
      !this.hasFinishedRequirements ||
      maxLevel ||
      !this.module.isAvailability ||
      this.isExemption ||
      this.blocked;
  }

  private _module: Module;
  @Input()
  public get module(): Module { return this._module; }
  public set module(module: Module) {
    this._module = new Module(module);
    if (module) {
      this.disableMessage = !this.module.isEnrolled ? 'Necessário estar matriculado para testar seu conhecimento' :
        !module.isAvailability ? 'Prazo para avaliação encerrado ou indisponível' : null;
    }
  }

  public labelButtonTest: string;

  public get subject(): Subject {
    return this.module.subjects.find(subject => subject.id === this.subjectId);
  }

  private _trackId: string;
  private _hasLinkedTrack: boolean;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    protected snackbar: MatSnackBar
  ) {
    super(snackbar);
  }

  ngOnInit(): void {
    this._trackId = this._route.snapshot.queryParams['trackId'];
    this._hasLinkedTrack = !!this._trackId;
  }

  private _getLabelButtonText(): string {
    if (this.blocked) {
      return 'Bloqueado nesse Assunto';
    } else if (this.reachedMaxLevel) {
      if (this.hasFeaturePracticeQuestions && this.module.practiceQuestions) {
        return 'Praticar BdQ';
      } else {
        return 'Nível Máximo';
      }
    } else if (this.hasFinishedRequirements) {
      return 'Testar meu Conhecimento';
    } else if (!this.hasFinishedRequirements) {
      return 'Pré-requisitos Pendentes';
    }
  }

  public getActionInfo(): ActionInfo {
    return {
      page: ActionInfoPageEnum.Module,
      description: 'btn-testar-conhecimento',
      type: ActionInfoTypeEnum.Click,
      moduleId: this.module.id,
      subjectId: this.subject.id
    };
  }

  public goToExam(): void {
    const extras: NavigationExtras = this._hasLinkedTrack ? { queryParams: { trackId: this._trackId } } : {};
    this._router.navigate(['/avaliacao/' + this.module.id + '/' + this.subject.id], extras);
  }

}
