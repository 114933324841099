import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { MatCardModule } from '@angular/material/card';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import {
  GenerateValuationTestCheckOptionsModule
} from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.module';

import { ProgramsComponent } from './programs.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { ProgramInfoComponent } from 'src/app/settings/programs/program/steps/1_program-info/program-info.component';
import { ProgramTeamsComponent } from 'src/app/settings/programs/program/steps/2_program-teams/program-teams.component';
import { ProgramItemsComponent } from 'src/app/settings/programs/program/steps/3_program-items/program-items.component';
import { EcommerceComponent } from './program/steps/4_program-ecommerce/ecommerce.component';
import { ProgramComponent } from 'src/app/settings/programs/program/program.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgxMaskModule } from 'ngx-mask';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgUtilModule } from '@tg4/ng-util';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SelectModule } from '@academia/select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DebugModule } from '@academia/debug';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserModule } from '@angular/platform-browser';
import { CascateSelectModule } from 'src/app/shared/components/cascate-select/cascate-select.module';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { DialogProgramsImportComponent } from './Dialogs/dialog-programs-import';
import { ImportExcelFilesModule } from 'src/app/shared/components/import-excel-files/import-excel-files.module';
import { AutocompleteModule } from 'src/app/shared/components/autocomplete/autocomplete.module';

@NgModule({
  declarations: [
    ProgramComponent,
    ProgramInfoComponent,
    ProgramTeamsComponent,
    ProgramItemsComponent,
    ProgramsComponent,
    EcommerceComponent,
    DialogProgramsImportComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialComponentsModule,
    MatCardModule,
    ListSearchModule,
    PaginationModule,
    GenerateValuationTestCheckOptionsModule,
    PipesModule,
    MatTabsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    NgxMaskModule,
    CdkStepperModule,
    NgUtilModule,
    MatIconModule,
    MatExpansionModule,
    SelectModule,
    DragDropModule,
    DebugModule,
    ScrollingModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    DirectivesModule,
    ReactiveFormsModule,
    CascateSelectModule,
    NavigationBackModule,
    ImportExcelFilesModule,
    AutocompleteModule
  ]
})
export class ProgramsModule { }

