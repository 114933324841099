import { PipesModule } from './../../../shared/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NpsAnswerComponent } from './nps-answer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';


@NgModule({
  declarations: [NpsAnswerComponent],
  exports: [NpsAnswerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MarkdownToHtmlModule
  ]
})

export class NpsAnswerModule { }
