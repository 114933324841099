import { Component, Input, ViewChild } from '@angular/core';
import { Requirement } from '../../../settings/modules/new-module/models/new-requirement.model';
import { Level } from '../../../models/shared/level.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { SettingsModulesService } from 'src/app/settings/_services/modules.service';
import { TestTrack } from 'src/app/models/valuation-test.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { FilterTrackModuleComponent } from 'src/app/pages/report/filters/filter-track-module/filter-track-module.component';
import { FilterTrackModule } from 'src/app/pages/report/filters/filter-track-module/models/filter-track-module.model';
import { CategoryFilterSearchOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';

@Component({
  selector: 'app-report-program-execution',
  templateUrl: './report-program-execution.component.html',
  styleUrls: ['./report-program-execution.component.scss']
})
export class ReportProgramExecutionComponent extends NotificationClass {

  @Input() requirement: Requirement;
  @Input() levels: Array<Level>;
  @Input() last: boolean = false;
  @ViewChild('FilterComponent', { static: true }) public filterComponent: FilterTrackModuleComponent;

  public tracks: Array<TestTrack> = [];
  public get selectedTrack() {
    return this.filter ?
      this._getCategories(this.filter).filter(c => c.columnName === 'track.id')[0].contentNames
      : [''];
  }
  public get filter() { return this.filterComponent.filter; }

  constructor(
    private _excelService: ExcelService,
    private _reportsService: ReportsService,
    private _modulesService: SettingsModulesService,
    private _reportService: ReportsService,
    protected _snackBar: MatSnackBar,
  ) {
    super(_snackBar);
  }

  public checkTrackSelectedEffectiveness() {
    if (!this.filter || this.selectedTrack === null || this.selectedTrack.length === 0) {
      this.notify(`Selecione uma trilha para exportar os dados.`);
      return;
    } else {
      this.notify('A exportação pode levar alguns minutos');
    }

    this.exportEffectiveness();
  }

  public exportEffectiveness() {
    const userId = this.filter.student ? this.filter.student.id : undefined;

    this._reportService.getEffectivenessIndicators(userId, this._getCategories(this.filter)).subscribe(res => {
      console.log('res -> ', res);
      this._excelService.exportAsExcelFile(
        this._excelService.buildExportUsersEffectiveness(res),
        'Ranking-Efetividade_'
      );
    });
  }

  private _getCategories(filter: FilterTrackModule) {
    if (!filter) return;

    return filter.categories.map<CategoryFilterSearchOption>(category => ({
      columnName: category.columnName,
      contentNames: category.contentNames.map(c => c.id)
    }));

  }

  public exportGrades() {

    if (!this.filter || this.selectedTrack === null || this.selectedTrack.length === 0) {
      this.notify('Selecione uma trilha para exportar os dados.');
      return;
    } else {
      this.notify('A exportação pode levar alguns minutos');
    }


    this._reportsService.getTracksGrades(this._getCategories(this.filter)).subscribe(res => {
      console.log('getTracksGrades -> ', res.data);
      const exportArray: any[] = [];
      const moduleArray: any[] = [];
      const eventArray: any[] = [];
      const eventPresenceArray: any[] = [];
      for (let i = 0; i < res.data.length; i++) {
        exportArray.push([]);
        exportArray[i]['Alunos'] = res.data[i].name;
      }
      for (let i = 0; i < res.data.length; i++) {
        exportArray.push([]);
        exportArray[i]['CPF'] = res.data[i].cpf;
      }
      for (let i = 0; i < res.data.length; i++) {
        exportArray.push([]);
        exportArray[i]['Trilha'] = res.data[i].track;
      }
      for (let i = 0; i < res.data.length; i++) {
        exportArray.push([]);
        exportArray[i]['Total de Pontos'] = res.data[i].totalPoints;
      }
      for (let i = 0; i < res.data.length; i++) {
        exportArray.push([]);
        exportArray[i]['Nota Total'] = res.data[i].totalGrades;
      }
      for (let i = 0; i < res.data.length; i++) {
        exportArray.push([]);
        exportArray[i]['Ranking'] = res.data[i].ranking;
      }
      for (let i = 0; i < res.data.length; i++) {
        for (let j = 0; j < res.data[i].moduleGrade.length; j++) {
          if (!moduleArray.includes(res.data[i].moduleGrade[j].moduleName)) {
            moduleArray.push('Módulo: ' + res.data[i].moduleGrade[j].moduleName);
            moduleArray.push('Módulo-Peso: ' + res.data[i].moduleGrade[j].moduleName);
          }
        }
        for (let k = 0; k < res.data[i].eventGrade.length; k++) {
          if (!eventArray.includes(res.data[i].eventGrade[k].eventName)) {
            eventArray.push('Evento: ' + res.data[i].eventGrade[k].eventName);
            eventArray.push('Evento-Peso: ' + res.data[i].eventGrade[k].eventName);
          }
        }
        for (let l = 0; l < res.data[i].eventPresence.length; l++) {
          if (!eventPresenceArray.includes(res.data[i].eventPresence[l].eventName)) {
            eventPresenceArray.push(res.data[i].eventPresence[l].eventName);
          }
        }
      }
      for (let i = 0; i < res.data.length; i++) {
        for (let j = 0; j < moduleArray.length; j++) {
          exportArray[i][moduleArray[j]] = '';
        }
        for (let j = 0; j < res.data[i].moduleGrade.length; j++) {
          exportArray[i]['Módulo: ' + res.data[i].moduleGrade[j].moduleName] = res.data[i].moduleGrade[j].grade;
          exportArray[i]['Módulo-Peso: ' + res.data[i].moduleGrade[j].moduleName] = res.data[i].moduleGrade[j].weightGrade;
        }
      }
      for (let i = 0; i < res.data.length; i++) {
        for (let k = 0; k < eventArray.length; k++) {
          exportArray[i][eventArray[k]] = '';
        }
        for (let k = 0; k < res.data[i].eventGrade.length; k++) {
          exportArray[i]['Evento: ' + res.data[i].eventGrade[k].eventName] = res.data[i].eventGrade[k].finalGrade;
          exportArray[i]['Evento-Peso: ' + res.data[i].eventGrade[k].eventName] = res.data[i].eventGrade[k].weightGrade;
        }
      }
      for (let i = 0; i < res.data.length; i++) {
        for (let l = 0; l < eventPresenceArray.length; l++) {
          exportArray[i][eventPresenceArray[l]] = '';
        }
        for (let l = 0; l < res.data[i].eventPresence.length; l++) {
          exportArray[i][res.data[i].eventPresence[l].eventName] = res.data[i].eventPresence[l].userPresence;
        }
      }
      this._excelService.exportAsExcelFile(exportArray, 'Ranking-Notas');
    });
  }

  public getUserProgresses() {
    if (!this.filter || this.selectedTrack === null || this.selectedTrack.length === 0) {
      this.notify('Selecione uma trilha para exportar os dados.');
      return;
    }

    this._reportService.getUserProgresses(this._getCategories(this.filter)).subscribe(res => {
      res.data.forEach(trackReport => {
        this._excelService.exportAsExcelFile(
          this._excelService.buildExportUserProgressReport(trackReport),
          'RelatorioDeProgresso'
        );
      });
    });
  }

}
