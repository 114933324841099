<div class="content">
  <p class="title">METRICA DE DESEMPENHO</p>
  <app-cockpit-buttons type="understand" (clickButton)="dispatchDialog()"></app-cockpit-buttons>
  <app-cockpit-chart-performance [effortPerformance]="effortPerformance" [isTrack]="isTrack">
  </app-cockpit-chart-performance>
  <app-cockpit-details title="diagnóstica" class="diagnostic column-end">
    <ng-container first>
      <p class="items-title cyan">Aluno</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.student?.diagnostic?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.student?.diagnostic)}}%)
      </p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Turma</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.class?.averageDiagnostic?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.class?.averageDiagnostic)}}%)
      </p>
    </ng-container>
    <ng-container third>
      <p class="items-title dark-blue">Top10</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.top10?.averageDiagnostic?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.top10?.averageDiagnostic)}}%)
      </p>
    </ng-container>
  </app-cockpit-details>

  <app-cockpit-details title="prova" class="valuation column-end">
    <ng-container first>
      <p class="items-title cyan">Aluno</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.student?.valuation?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.student?.valuation)}}%)
      </p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Turma</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.class?.averageValuation?.toFixed(1)}}</strong>
        ({{(getAsPercent(effortPerformance?.class?.averageValuation))}}%)
      </p>
    </ng-container>
    <ng-container third>
      <p class="items-title dark-blue">Top10</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.top10?.averageValuation?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.top10?.averageValuation)}}%)
      </p>
    </ng-container>
  </app-cockpit-details>

  <app-cockpit-details *ngIf="isTrack" title="evento" class="events column-end">
    <ng-container first>
      <p class="items-title cyan">Aluno</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.student?.averageEventGrade?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.student?.averageEventGrade)}}%)
      </p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Turma</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.class?.averageEvent?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.class?.averageEvent)}}%)
      </p>
    </ng-container>
    <ng-container third>
      <p class="items-title dark-blue">Top10</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.top10?.averageEvent?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.top10?.averageEvent)}}%)
      </p>
    </ng-container>
  </app-cockpit-details>
  <app-cockpit-details *ngIf="!isTrack" title="BDQ" class="events column-end">
    <ng-container first>
      <p class="items-title cyan">Aluno</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.student?.bdqGrade?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.student?.bdqGrade)}}%)
      </p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Turma</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.class?.averageBdq?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.class?.averageBdq)}}%)
      </p>
    </ng-container>
    <ng-container third>
      <p class="items-title dark-blue">Top10</p>
      <p class="items-subtitle">
        <strong>{{effortPerformance?.top10?.averageBdq?.toFixed(1)}}</strong>
        ({{getAsPercent(effortPerformance?.top10?.averageBdq)}}%)
      </p>
    </ng-container>
  </app-cockpit-details>

  <div class="footer">
    <div class="footer-items font-grey">
      <p>Diagnóstica</p>
      <strong>{{effortPerformance?.student?.diagnostic?.toFixed(1)}}</strong>
    </div>
    <span class="material-icons">
      forward
    </span>
    <div class="content-green">
      <div class="footer-items bg-white font-grey">
        <p>Prova</p>
        <strong>{{effortPerformance?.student?.valuation?.toFixed(1)}}</strong>
      </div>
      <div class="footer-items">
        <p>Aprendizagem</p>
        <strong>{{effortPerformance?.student?.learning?.toFixed(1)}}</strong>
      </div>
      <div class="footer-items padding-right">
        <p>Acima da média</p>
        <strong>{{getLabel()}}</strong>
      </div>
    </div>
  </div>

  <ng-template #understandDialogTemplate>
    <app-understand-content title="Entenda as Métricas de Desempenho"
      text="métricas relacionadas a performance do aluno respondendo ao Banco de Questões e na participação em eventos (atividades com instrutores presenciais)"
      (close)="dialog.close()">
    </app-understand-content>
  </ng-template>
</div>
