import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Content, PdfReference } from '../../../models/content.model';
import { PdfMarker } from './marker.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ScormWrapperService } from 'src/app/pages/_services/scorm-wrapper.service';
import { EffortService } from 'src/app/shared/services/effort.service';

@Component({
  selector: 'app-content-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss']
})
export class HTMLContentComponent {

  @Input() set setContent(content: Content) {
    this.content = content;
  }
  @Output() saveHtmlFinishedAction: EventEmitter<string> = new EventEmitter();
  public content: Content;

  constructor() { }

  public openPopUp(url: string) {
    console.log(url);
    window.open(
      'https://' + url,
      'pagina',
      'width=350, height=255, top=100, left=110, scrollbars=no');

    this.saveHtmlFinishedAction.emit(this.content.id);
  }

}
