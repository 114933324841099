<mat-card *ngFor="let item of data">
    <div class="card-content">
        <strong>{{item.description}}</strong>
        <div class="levels">
            <div class="levels-items">
                <mat-form-field>
                    <input matInput  [(ngModel)]="item.easy" placeholder="Fácil" type="number"/>
                </mat-form-field>
            </div>
            <div class="levels-items">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.medium" placeholder="Médio" type="number" />
                </mat-form-field>
            </div>
            <div class="levels-items">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.hard" placeholder="Difícil" type="number" />
                </mat-form-field>
            </div>
        </div>
    </div>
    
</mat-card>

