<div class="content">
  <app-cockpit-title type="custom" title="RANKING">
    <div>
      <button class="btn-ranking primary">Padrão</button>
      <button (click)="openCustomDialogRanking()" class="btn-ranking outline">Personalizado</button>
    </div>
  </app-cockpit-title>
  <app-table-ranking [ranking]="ranking.slice(0,4)">
  </app-table-ranking>
  <div class="action">
    <button (click)="openDialogRanking()">VER MAIS</button>
  </div>

  <ng-template #templateFilters>
    <app-filter-ranking (customRanking)="handleCustomRankingChange($event)" [ranking]="ranking"></app-filter-ranking>
  </ng-template>

  <ng-template #templateTitle>
    <div class="title-content">
      <div class="title">
        <h5>MONTE SEU PRÓPRIO RANKING</h5>
        <app-cockpit-buttons type="understand"></app-cockpit-buttons>
      </div>
      <app-cockpit-buttons type="block" title="Exportar Relatório" [width]="243" [height]="46"
        (click)="exportRanking()"></app-cockpit-buttons>
      <app-cockpit-buttons type="block" title="Voltar" [width]="243" [height]="46" (click)="closeDialog()">
      </app-cockpit-buttons>
    </div>
  </ng-template>
  <ng-template #templateTitleTrack>
    <div class="title-content">
      <div class="title">
        <h5>RANKING DA {{replacement.track.singular | uppercase}}</h5>
      </div>
    </div>
  </ng-template>
</div>
