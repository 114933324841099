export interface ItracksStudentsSubordinates {
    id: string;
    title: string;
    students: Istudents[];
}

export interface Istudents {
    id: string;
    name: string;
    email: string;
}

export enum Eselect {
    AllStudents = -1
}

export interface Ifilter {
    selectedTrack: ItracksStudentsSubordinates;
    selectedStudent: Istudents[];
}

export interface ItrackModule {
    id: string;
    title: string;
    studentFinishedPercent: number;
    hasQuestions: boolean;
}

export interface IstudentsRanking {
    id: string;
    name: string;
    email: string;
    ranking: number;
    interactionUntilDay?: number;
    interactionUntilDayInHours: number;
    progressUntilDay: number;
    averageEventGrade: number;
    learning: number;
    grade: number;
    diagnostic: number;
    valuation: number;
    factor?: number;
    participation?: number;
}

export interface ImetricsStudents {
    effort: IMetric;
    participation: IMetric;
    engagement: IMetric;
    averageDiagnosticTest: number;
    averageTest: number;
    averageEvents: number;
    averageDiagnosticTestTop10: number;
    averageTestTop10: number;
    averageEventsTop10: number;
    learning: number;
    learningTop10: number;
    expectedProgressUntilDay: number;
    expectedInteractionUntilDay: number;
    expectedInteractionUntilDayInHours: number;
    averageStudentsInteraction: number;
    averageBdqGrade: number;
    averageBdqGradeTop10: number;
}

export interface ItrackInfo {
    id: string;
    title: string;
    students: IstudentsRanking[];
    totalstudents: number;
    moduleConfigurates: ItrackModule[];
    expectedProgressUntilDay: number;
    expectedInteractionUntilDay: number;
    averageDiagnosticTest: number;
    averageTest: number;
    averageEvents: number;
    averageDiagnosticTestTop10: number;
    averageTestTop10: number;
    averageEventsTop10: number;
    averageProgressUntilDay: number;
    learning: number;
    learningTop10: number;
    averageStudentsInteraction: number;
    averageBdqGrade: number;
    averageBdqGradeTop10: number;
}

export interface IMetric {
    expected?: number;
    real: number;
    expectedInHours?: number;
    realInHours?: number;
}
