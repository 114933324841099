<div class="inner-content">

  <div class="header">
    <a href="javascript:history.back()">
      <img src="./assets/img/arrow-back.png" />
      <span>Voltar</span>
    </a>
    <h2>{{ testTitle }}</h2>
    <div class="empty-block"> </div>
  </div>

  <p *ngIf="responses && responses.length === 0">
    Não há testes respondidos para recomendação.
  </p>


  <div class="wrapper">
    <div class="actions">
      <button class="btn btn-test" (click)="exportReport()" *ngIf="testType !== 3">
        Exportar relatório
      </button>
    </div>
    <table mat-table matSort class="mat-elevation-z8"
      *ngIf="responses && responses.length > 0"
      [dataSource]="responses">
      <ng-container matColumnDef="userName">
        <th width="8%" mat-header-cell *matHeaderCellDef> Nome Usuário </th>
        <td width="8%" mat-cell *matCellDef="let row">
          {{ row.userName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="registerId">
        <th width="8%" mat-header-cell *matHeaderCellDef> Matrícula Usuário </th>
        <td width="8%" mat-cell *matCellDef="let row">
          {{ row.userRegisterId || '--' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th width="8%" mat-header-cell *matHeaderCellDef> Respondido Em </th>
        <td width="8%" mat-cell *matCellDef="let row">
          {{ row.createdAt | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="grade">
        <th width="8%" mat-header-cell *matHeaderCellDef> Nota Final </th>
        <td width="8%" mat-cell *matCellDef="let row">
          {{ row.finalGrade ? row.finalGrade.toFixed(1) : 'Aguardando Nota' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="file" *ngIf="testType === 3" >
      <ng-container >
        <th width="8%" mat-header-cell *matHeaderCellDef> Arquivo </th>
        <td width="8%" mat-cell *matCellDef="let row">
          <button class="file-button" (click)="download(row.answers[0].curseWorkFile['downloadLink'], $event)">
            <span class="material-icons" id="file-button">
              get_app
            </span>
        </button>
        </td>
      </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="goToRecommendation(row, $event)"></tr>
    </table>

    <app-pagination *ngIf="responses && responses.length > 0"
      [setPagination]="{
      'itemsCount': itemsCount,
      'pageSize': 10
    }"
      (goToPage)="goToPage($event)"></app-pagination>
  </div>

</div>
