<div class="content">
    <div class="header">
        <h1>{{user?.name}}</h1>
        <div class="adress">
            <p>{{user?.address.street}} - {{user?.address.district}} </p>
            <p>{{user?.address.city + (user?.address.state ? ' - ' + user.address.state : '')}} - {{user?.address.zipCode}}</p>
            <p>{{user?.phone | asPhone }} {{(user?.phone2 ? ' / ' + user.phone2 : '/') | asPhone }} {{user?.email}}</p>
        </div>
    </div>
    <div class="sub-header">
          <p>Nome: {{user?.name}}</p>
          <p>Endereço: {{user?.address.street}}</p>  
          <p>Telefone/Email: {{user?.phone | asPhone }} {{(user?.phone2 ? ' / ' + user.phone2 : '/')}} {{user?.email}} </p>    
    </div>
    <div class="sub-title">{{user?.career?.desiredPosition}}</div>
    <div class="sub-title">Resumo das Qualificações</div>
    <div class="item-content">
       {{user?.info}}
    </div>
    <div class="sub-title">Formação Acadêmica</div>
    <div class="item-content" *ngFor="let education of user?.career?.colleges">
        {{education.name | uppercase}} – {{education.title | uppercase}} – {{formatDate(education.startDate)}} a {{formatDate(education.endDate)}} 
    </div>
    <div class="sub-title">Histórico Profissional</div>
    <ng-container *ngFor="let professionalExperience of user?.career?.professionalExperiences">
        <div class="header">
            <strong>{{professionalExperience.title | uppercase}}</strong>
            <strong>{{formatDate(professionalExperience.startDate) + (professionalExperience.endDate ? ' a ' + formatDate(professionalExperience.endDate) : ' até o momento')}}</strong>
        </div>
        <div class="item-content">
            {{professionalExperience.description}}
        </div>
    </ng-container>
    <div class="sub-title">Idiomas</div>
    <ng-container *ngFor="let idiom of user?.career?.languages">
    <div>
       {{idiom.languages +' - '+ idiom.level | uppercase}}
    </div>
    </ng-container>
    <div class="sub-title">Informações complementares</div>
    <div *ngFor="let reward of user?.career?.rewards">
        <div class="header">
            <strong>{{reward.name}}</strong>
            <strong>{{formatDate(reward.date)}}</strong>
        </div>
        <div class="item-content">
            <p style="font-size: 13px;">{{reward.link}}</p>
            {{reward.title}}
         </div>
    </div>
    <div *ngFor="let certificate of user?.career?.certificates">
        <div class="header">
            <strong>{{certificate.title}}</strong>
        </div>
        <div class="item-content">
            <p style="font-size: 13px;">{{certificate.link}}</p>
         </div>
    </div>
</div>
