import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService, AuthInterceptor as BaseAuthInterceptor } from '@tg4/http-infrastructure';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { ToolbarHeaderModule } from 'src/app/shared/components/toolbar-header/toolbar-header.module';
import { SettingsModule } from './settings/settings.module';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app.routing.module';
import { DateAdapter } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RecruitmentModule } from './recruitment/recruitment.module';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { PortugueseDateAdapter } from 'src/app/shared/classes/native-date-adapter';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SentryErrorHandler } from 'src/app/shared/handlers/sentry-error.handler';
import { LoaderInterceptor } from 'src/app/shared/interceptors/loader.interceptor';
import { PersonificationInterceptor } from 'src/app/shared/interceptors/personification.interceptor';
import { AccessInterceptor } from 'src/app/shared/interceptors/access.interceptor';
import { TokenStorageService } from 'src/app/shared/services/token-storage.service';
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    SettingsModule,
    RecruitmentModule,
    ToolbarHeaderModule,
    NgxMaskModule.forRoot(),
    MatSidenavModule,
    PipesModule
  ],
  providers: [
    AuthService,
    CookieService,
    TokenStorageService,
    { provide: HTTP_INTERCEPTORS, useClass: BaseAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PersonificationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccessInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true } ,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DateAdapter, useClass: PortugueseDateAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
