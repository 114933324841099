import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationClass } from '../../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentModulesService } from '../../_services/modules.service';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModuleTagDialogComponent } from './module-tag-dialog/module-tag-dialog.component';

@Component({
  selector: 'app-module-tag-curatorship',
  templateUrl: './module-tag-curatorship.component.html',
  styleUrls: ['./module-tag-curatorship.component.scss']
})
export class ModuleTagCuratorshipComponent extends NotificationClass implements OnInit {

  public page: number = 1;
  public pageSize: number = 20;
  public searchValue: string = '';
  public moduleId: string = '';
  public subjectId: string = '';
  public displayedColumns: string[] = [
    'module', 'subject', 'content', 'concept', 'positive', 'negative', 'status', 'approval', 'action'
  ];
  public moduleTags: any[] = [];
  public moduleTagsCount: number = 0;
  public inputSubscription: Subject<string> = new Subject();
  public modules: any[] = [];
  public selectedModule: any;
  public selectedSubject: any;
  private _sortDirection: boolean = false;
  private _sortActive: string = '';

  @Output() updateModuleCount: EventEmitter<any> = new EventEmitter();

  constructor(
    protected _snackBar: MatSnackBar,
    public moduleService: ContentModulesService,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.getFilteredUserTagValuations();
    this.getFilteredModule();
  }

  public sortData(sort: Sort) {
    if (sort.direction === 'asc') {
      this._sortActive = sort.active;
      this._sortDirection = true;
      this.getFilteredUserTagValuations();
    } else if (sort.direction === 'desc') {
      this._sortActive = sort.active;
      this._sortDirection = false;
      this.getFilteredUserTagValuations();
    } else {
      this._sortActive = '';
      this._sortDirection = false;
      this.getFilteredUserTagValuations();
    }
  }

  public getFilteredModule(searchValue = '') {
    this.moduleService.getPagedFilteredModulesList(1, 4, searchValue)
    .pipe(
      debounceTime(500)
    ).subscribe(res => {
      this.modules = res.data.modules;
    });
  }

  public getFilteredUserTagValuations() {
    this.moduleService.getFilteredUserTagValuations(
      this.page, this.pageSize, this.searchValue, this._sortActive,
      this._sortDirection, this.moduleId, this.subjectId
    )
    .pipe(
      debounceTime(500)
    ).subscribe(res => {
      this.moduleTags = res.data.tags;
      this.moduleTagsCount = res.data.itemsCount;
      console.log(this.moduleTags);
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  public getModuleTagEverageGrade(moduleTag: any): number {
    if (
      moduleTag &&
      moduleTag.moduleTagValuations &&
      moduleTag.moduleTagValuations.length > 0
    ) {
      let totalGrade = 0;
      for (let i = 0; i < moduleTag.moduleTagValuations.length; i++) {
        const moduleTagValuation = moduleTag.moduleTagValuations[i];
        let valuationGrade = moduleTagValuation.textValuation +
        moduleTagValuation.conceptsValuation +
        moduleTagValuation.answersValuation;
        valuationGrade = valuationGrade / 3;
        totalGrade += valuationGrade;
      }
      totalGrade = totalGrade / moduleTag.moduleTagValuations.length;
      return totalGrade;
    } else {
      return 0;
    }
  }

  public getModuleTagStatus(moduleTag: any): string {
    if (moduleTag && moduleTag.moduleTag && moduleTag.moduleTag.status) {
      if (moduleTag.moduleTag.status === 2) {
        return 'Aprovado';
      } else if (moduleTag.moduleTag.status === 3) {
        return 'Rejeitado';
      } else {
        return 'Pendente';
      }
    } else {
      return 'Pendente';
    }
  }

  public getModuleTagStatusColor(moduleTag: any): string {
    if (moduleTag && moduleTag.moduleTag && moduleTag.moduleTag.status) {
      if (moduleTag.moduleTag.status === 2) {
        return 'var(--success-color)';
      } else if (moduleTag.moduleTag.status === 3) {
        return 'var(--danger-color)';
      } else {
        return 'var(--warn-color)';
      }
    } else {
      return 'var(--warn-color)';
    }
  }

  public changeModuleTagStatus(moduleTag: any, status: number) {
    this.moduleService.updateModuleTagStatus(moduleTag.moduleTag.id, status).subscribe(() => {
      moduleTag.moduleTag.status = status;
      this.updateModuleCount.emit();
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  public openTagDialog(moduleTag: any) {
    const dialogRef = this._dialog.open(ModuleTagDialogComponent, {
      width: '600px',
      data: {
        moduleTag: moduleTag
      }
    });

    dialogRef.afterClosed().subscribe((update: any) => {
      if (update) {
        this.getFilteredUserTagValuations();
      }
    });
  }

  public triggerTagSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this.searchValue = searchValue;
      this.getFilteredUserTagValuations();
    } else {
      this.searchValue = '';
      this.getFilteredUserTagValuations();
    }
  }

  public handleSelectModuleFilterChange(selectedModule: any): void {
    this.moduleId = selectedModule.id;
    this.getFilteredUserTagValuations();
  }

  public onKey(searchTerm: string) {
    this.getFilteredModule(searchTerm);
  }

  public openedChange(event) {
    if (event) {
      const input = document.getElementById('input-search') as HTMLInputElement;
      input.value = '';
    } else {
      this.getFilteredModule();
    }
  }

  public handleSelectSubjectsFilterChange(selectedSubject: any) {
    this.subjectId = selectedSubject.id;
    this.getFilteredUserTagValuations();
  }
}
