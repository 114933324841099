import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventPreview } from '../../../models/previews/event.interface';
import * as moment from 'moment/moment';
import { ModuleProgress } from 'src/app/models/previews/module-progress.interface';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';

@Component({
  selector: 'app-events-card',
  templateUrl: './events-card.component.html',
  styleUrls: ['./events-card.component.scss']
})
export class EventsCardComponent {

  @Input() event: EventPreview;
  @Input() isLast: boolean = false;

  constructor(private _router: Router, private _navigationService: NavigationService) { }

  public getSubscriptionDueDays(): string {
    if (this.event.nextSchedule) {
      const dueDate = new Date(this.event.nextSchedule.subscriptionEndDate.toString());
      const today = new Date();
      if (dueDate > today) {
        const diff = differenceInDays(dueDate, today);
        if (diff > 0) {
          return diff + ` dia${diff > 1 ? 's' : ''}`;
        } else if (diff === 0 && differenceInHours(dueDate, today) > 0) {
          const diffInHours = differenceInHours(dueDate, today);
          return diffInHours + ` hora${diffInHours > 1 ? 's' : ''}`;
        } else {
          const diffInMinutes = differenceInMinutes(dueDate, today);
          return diffInMinutes + ` minuto${diffInMinutes > 1 ? 's' : ''}`;
        }

      } else
        return 'Inscr. Enc.';
    }
    return '--';
  }

  public getRemainingRequirements(): number {
    let completedRequirements: number = 0;
    this.event.requirements.forEach(req => {
      if (!req.optional) {
        const progress: ModuleProgress = this.event.moduleProgressInfo[req.moduleId];
        if (progress && req.level + 1 <= progress.level)
          completedRequirements++;
      }
    });
    return completedRequirements;
  }

  public goToEvent() {

    // this._navigationService.goToStudentEventContent({
    //   alwaysAvailable: this.event.
    // })

    // this._navigationService.goToEventOverViewContent({

    // }, {

    // })

    this._router.navigate(['evento/' + this.event.id + '/' + this.event.nextSchedule.id]);
  }

}
