import { TrackEvent } from './track-event.model';
import { TrackModule } from './track-module.model';
import { ModulePreview } from './previews/module.interface';
import { EcommerceProduct } from './ecommerce-product.model';
import { EngagementMethodEnum } from './enums/engagementMethod.enum';
import { Segment } from './segment.model';
import { Sector } from './sector.model';
import { ValuationsConfiguration } from './valuation-configuration';
import { ETrackItem } from './enums/track-item.enum';
import { cloneDeep, isNil } from 'lodash';
import { ECourseAvailability } from './enums/course-availability.enum';

export class TrackArea {
  id?: string;
  title: string;
}
export class TrackSubArea {
  id?: string;
  title: string;
}

export class Track {
  public id?: string;
  public title: string;
  public description: string;
  public storeUrl?: string;
  public ecommerceUrl?: string;
  public createInEcommerce?: boolean;
  public imageUrl: string;
  public area: TrackArea;
  public subArea: TrackSubArea;
  public conclusion: number;
  public modulesConfiguration: Array<TrackModule> = [];
  public modules?: Array<ModulePreview> = [];
  public valuationsConfiguration: ValuationsConfiguration = new ValuationsConfiguration();
  public valuations?: ValuationsConfiguration = new ValuationsConfiguration();
  public eventsConfiguration?: Array<TrackEvent> = [];
  public tags: Array<string>;
  public published: boolean;
  public videoUrl?: string;
  public videoDuration?: number;
  public duration?: string;
  public videoId?: string;
  public videoTitle?: string;
  public mandatoryCourseVideo: boolean;
  public courseVideoUrl?: string;
  public courseVideoDuration?: number;
  public courseVideoId?: string;
  public courseVideoTitle?: string;
  public calendarEvents?: Array<TrackEvent>;
  public ecommerceProducts?: Array<EcommerceProduct>;
  public requireUserCareer: boolean;
  public allowedPercentageWithoutCareerInfo?: number;
  public profileTestId?: string;
  public profileTestName?: string;
  public validFor?: number;
  public segment?: Segment;
  public sector?: Sector;
  public timeInDaysToCompleteTheTrack?: number;
  public engagementMethod?: EngagementMethodEnum;

  public trackAreaId: string;
  public trackSubAreaId: string;
  public requester?: string;
  public requesterImageUrl?: string;
  public availability?: ECourseAvailability = ECourseAvailability.available;


  constructor(track: Track = null) {
    if (track) {
      Object.keys(track).forEach(key => this[key] = track[key]);

      if (this.modulesConfiguration) {
        this.modulesConfiguration = this.modulesConfiguration.map((trackModule: any) => this._getConfiguration(trackModule, ETrackItem.Module));
      }

      if (this.eventsConfiguration) {
        this.eventsConfiguration = this.eventsConfiguration.map((trackEvent: any) => this._getConfiguration(trackEvent, ETrackItem.Event));
      }

      if (this.valuations) {
        this.valuations.modules = this.valuations.modules || [];
        this.valuations.tracks = this.valuations.tracks || [];

        this.valuations.modules = this.valuations.modules.map((valuation: any) => this._getConfiguration(valuation, ETrackItem.Valuation));
        this.valuations.tracks = this.valuations.tracks.map((valuation: any) => this._getConfiguration(valuation, ETrackItem.Valuation));
        this.valuationsConfiguration = this.valuations;
      }
    }

  }

  public setTrackInfo(trackInfo: Track) {
    this.title = trackInfo.title;
    this.description = trackInfo.description;
    this.imageUrl = trackInfo.imageUrl;
    this.tags = trackInfo.tags;
    this.published = trackInfo.published ? true : false;
    this.storeUrl = trackInfo.storeUrl;
    this.ecommerceUrl = trackInfo.ecommerceUrl;
    this.requireUserCareer = trackInfo.requireUserCareer ? true : false;
    this.allowedPercentageWithoutCareerInfo = this.requireUserCareer ?
      trackInfo.allowedPercentageWithoutCareerInfo : null;
    this.createInEcommerce = trackInfo.createInEcommerce;
    this.profileTestId = trackInfo.profileTestId;
    this.profileTestName = trackInfo.profileTestName;
    this.validFor = trackInfo.validFor;
    this.duration = String(trackInfo.duration);
    this.engagementMethod = trackInfo.engagementMethod;
    this.timeInDaysToCompleteTheTrack = trackInfo.timeInDaysToCompleteTheTrack;
    this.trackAreaId = trackInfo.trackAreaId;
    this.trackSubAreaId = trackInfo.trackSubAreaId;
    this.requester = trackInfo.requester ? trackInfo.requester : "";
    this.requesterImageUrl = trackInfo.requesterImageUrl;
    this.sector = trackInfo.sector;
    this.segment = trackInfo.segment;
    this.availability = trackInfo.availability;
  }

  public setVideoInfo(trackInfo: Track) {
    this.videoUrl = trackInfo.videoUrl;
    this.videoDuration = trackInfo.videoDuration;
    this.videoId = trackInfo.videoId;
    this.videoTitle = trackInfo.videoTitle;
    this.mandatoryCourseVideo = trackInfo.mandatoryCourseVideo;
    this.courseVideoUrl = trackInfo.courseVideoUrl;
    this.courseVideoDuration = trackInfo.courseVideoDuration;
    this.courseVideoId = trackInfo.courseVideoId;
    this.courseVideoTitle = trackInfo.courseVideoTitle;
  }

  public setValuationConfiguration(valuationConfiguration: ValuationsConfiguration) {
    this.valuations.modules = valuationConfiguration.modules.map((valuation: any) => this._getConfiguration(valuation, ETrackItem.Valuation));
    this.valuations.tracks = valuationConfiguration.tracks.map((valuation: any) => this._getConfiguration(valuation, ETrackItem.Valuation));
    this.valuationsConfiguration = this.valuations;
  }


  private _getConfiguration(config, type: ETrackItem) {
    const clone = cloneDeep(config);
    const hasInvalidDate = clone.alwaysAvailable === false && isNil(clone.openDate);

    clone.type = type;
    clone.valuationDate = hasInvalidDate ? null : clone.valuationDate;
    clone.cutOffDate = hasInvalidDate ? null : clone.cutOffDate;
    clone.alwaysAvailable = isNil(clone.alwaysAvailable) || hasInvalidDate || clone.alwaysAvailable;
    return clone;
  }
}
