import { Component, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Activation } from 'src/app/models/activation.model';

@Component({
  selector: 'app-nps-answer',
  templateUrl: './nps-answer.component.html',
  styleUrls: ['./nps-answer.component.scss']
})
export class NpsAnswerComponent {

  @Output() sliderValue = new EventEmitter<number>();

  @Input() data: Activation;
  @Input() textAlignLeft: boolean;

  public formGroup: FormGroup;
  private _valuating: boolean = false;

  constructor(public cdr: ChangeDetectorRef) {
    this.formGroup = new FormGroup({
      'answer': new FormControl(''),
    });
  }

  public setSliderValue(value: number) {
    this.formGroup.get('answer').setValue(+value);
    this.sliderValue.emit(value);
  }

  public setAnswer(value: string) {
    const valueInteger = parseInt(value, 10);

    this.formGroup.get('answer').setValue(isNaN(valueInteger) ? '' : valueInteger);
  }

  public resetAnswer() {
    const form = this.formGroup.get('answer');
    form.setValue('');
    form.updateValueAndValidity({ emitEvent: true });
  }
}
