import { Component, Input, ViewChild } from '@angular/core';
import { Requirement } from '../../../settings/modules/new-module/models/new-requirement.model';
import { Level } from '../../../models/shared/level.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { TestTrack } from 'src/app/models/valuation-test.interface';
import {
  FilterReportInformation
} from 'src/app/pages/report/filters/filter-report-information-registration/models/filter-report-information-registration.models';
import { Moment } from 'moment';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';
import {
  FilterReportInformationRegistrationComponent
} from './../filters/filter-report-information-registration/filter-report-information-registration.component';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { EReportType } from './Enums/reportsType';
import { CategoryFilterSearchOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { environment } from 'src/environments/environment';
import format from 'date-fns/format';
import { UtilService } from 'src/app/shared/services/util.service';
import { isNil } from 'lodash';
interface ReportParameters {
  page: string;
  pageSize: string;
  sort: string;
  sortAscending: string;
  categories: CategoryFilterSearchOption[];
  startDate: string;
  endDate: string;
  trackIds: string[];
  student: string;
  responsibles: string;
}

@Component({
  selector: 'app-report-information-registration',
  templateUrl: './report-information-registration.component.html',
  styleUrls: ['./report-information-registration.component.scss'],
})

export class ReportInformationRegistrationComponent extends NotificationClass {

  @Input() requirement: Requirement;
  @Input() levels: Array<Level>;
  @Input() last: boolean = false;

  @ViewChild('FilterComponent', { static: true })
  public filterComponent: FilterReportInformationRegistrationComponent;

  public replacement = environment.replecement;
  public tracks: Array<TestTrack> = [];
  public get filter() { return this.filterComponent.filter; }
  // public get selectedTracks() { return this.filterComponent.selectedTracks; }
  public getManageInfo: boolean;
  public DateInitial: Moment;
  public DateFinished: Moment;
  public reportsTypes = EReportType;
  constructor(
    private _excelService: ExcelService,
    private _tracksService: SettingsTracksService,
    private _reportService: ReportsService,
    protected _snackBar: MatSnackBar,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }

  public exportRegistrationUser() {
    this.loadTrackReportStudents();
  }

  public exportReport(reportType: EReportType) {
    this.notify('Dependendo da seleção do filtro, a visualização dos dados pode levar alguns minutos');
    this._loadDataReport(this.filter, reportType);
  }
  private _getFilterCategories() {
    return this.filter.categories.map(category => ({
      columnName: category.columnName,
      contentNames: category.contentNames.map(c => category.columnName.includes('.id') ? c.id : c.description)
    }) as CategoryFilterSearchOption);
  }

  private _loadDataReport(filter: FilterReportInformation, reportType: EReportType): void {
    if (!filter) return;
    const track = this.filter.categories.find(categorie => categorie.columnName === 'track.id');
    const parameters: ReportParameters = {
      page: null,
      pageSize: null,
      sort: null,
      sortAscending: null,
      categories: this._getFilterCategories(),
      startDate: filter.date.start,
      endDate: filter.date.end,
      trackIds: track.contentNames && track.contentNames.length > 0 ? track.contentNames.map(content => content.id) : undefined,
      responsibles: filter.responsible,
      student: filter.student
    };

    const getReport = (type: EReportType) => {
      const reports = {
        [EReportType.RegisteredTracks]: () => this.exportStudentsTrackReport(parameters),
        [EReportType.RegistrationUser]: () => this.exportRegistrationUser(),
        [EReportType.Status]: () => this.exportStatusReport(parameters),
        [EReportType.Tracks]: () => this.exportTracksReport(),
        [EReportType.Modules]: () => this.exportModulesReport(),
        [EReportType.synchronousExperience]: () => this.exportSynchronousExperienceReport(parameters),
        [EReportType.StudyPlans]: () => this.exportStudyPlanReport()
      };
      return reports[type]();
    };

    getReport(reportType);

  }

  public exportFinanceReport() {
    this._reportService.getFinanceReport().subscribe(res => {
      const extract = res.data;
      if (extract) {
        const excelModule = [];
        for (let idx = 0; idx < extract.length; idx++) {
          const movement = extract[idx];
          for (let i = 0; i < movement.payables.length; i++) {
            const payable = movement.payables[i];

            excelModule.push({
              'Id da Transação': movement.id,
              'Status da Transação': movement.status,
              'Data da Criação': movement.formated_date_created,
              'Data da Última Atualização': movement.formated_date_updated,
              'Valor Pago': Number(movement.paid_amount / 100),
              'Parcelas': movement.installments,
              'Método de Pagamento': movement.payment_method,
              'Estado': movement.address ? movement.address.state : '',
              'Produto': movement.product,
              'Número do Pedido': movement.metadata ? movement.metadata.order_number : '',
              'Número do Documento': movement.customer ? movement.customer.document_number : '',
              'Tipo do Documento': movement.customer ? movement.customer.document_type : '',
              'Nome': movement.customer ? movement.customer.name : '',
              'Email': movement.customer ? movement.customer.email : '',
              'Status do Recebível': payable.status,
              'Tipo do Recebível': payable.type,
              'Valor do Recebível': Number(payable.amount / 100),
              'Taxa': Number(payable.fee / 100),
              'Taxa de Antecipação': Number(payable.anticipation_fee / 100),
              'Parcela': payable.installment,
              'Dia do Pagamento': payable.formated_payment_date,
              'Dia da Competência': payable.formated_accrual_date,
              'Valor Líquido': Number((payable.amount - payable.fee) / 100)
            });

          }
        }
        this._excelService.exportAsExcelFile(excelModule, 'Relatório Financeiro');
      }
    }, () => {
      console.log('Erro ao exportar relatorio financeiro.');
    });
  }

  public exportStudentsTrackReport(parameters: ReportParameters) {
    this._tracksService.getTrackReportStudentsFiltered(parameters).subscribe(res => {
      let excelModule = [];
      const selectedTracks = this.filter.categories.find(categorie => categorie.columnName === 'track.id');
      if (selectedTracks.contentNames.length > 0) {
        for (let idx = 0; idx < res.data.length; idx++) {
          const user = res.data[idx];
          selectedTracks.contentNames.forEach(item => {
            user.tracksInfo.forEach(t => {
              if (t.id === item.id) {
                excelModule.push({
                  'Name': user.name,
                  'Email': user.email,
                  'Trilha_módulo': item.description,
                  'Área': t.area,
                  'Sub-Área': t.subArea,
                  'Data_Expiração': t.blocked ? 'BLOQUEADO' : !t.dueDate
                    ? '--- Sem Data ---' : format(new Date(t.dueDate), 'DD/MM/YYYY'),
                  'Matrícula': user.registrationId,
                  'Código Trilha': t.id
                });
              }
            });
          });
        }
        excelModule = this.sortByAlphabeticalOrderArray('Trilha_módulo', excelModule);
      } else {
        for (let idx = 0; idx < res.data.length; idx++) {
          const user = res.data[idx];
          user.tracksInfo.forEach(t => {
            excelModule.push({
              'Name': user.name,
              'Email': user.email,
              'Trilha_módulo': t.name,
              'Área': t.area,
              'Sub-Área': t.subArea,
              'Data_Expiração': t.blocked ? 'BLOQUEADO' : !t.dueDate ? '--- Sem Data ---' : format(new Date(t.dueDate), 'DD/MM/YYYY'),
              'Matrícula': user.registrationId,
              'Código Trilha': t.id

            });
          });
        }

        excelModule = this.sortByAlphabeticalOrderArray('Trilha_módulo', excelModule);
      }
      if (excelModule.length > 0) {
        this._excelService.exportAsExcelFile(excelModule, 'Relatório-Aluno-Trilha');
      } else {
        this.notify('Não foi encontrado usuários para esse filtro');
      }
    });
  }

  public sortByAlphabeticalOrderArray(field: string, array: any[]): any {
    return array.sort((a, b) => a[field] < b['Trilha_módulo'] ? -1 : a[field] > b[field] ? 1 : 0);
  }

  public loadTrackReportStudents() {
    this._tracksService.getTrackReportStudents().subscribe(res => {
      const users = res.data;
      if (users) {
        const excelModule = [];

        for (let idx = 0; idx < users.length; idx++) {
          const user = users[idx];

          excelModule.push({
            'Nome': user.name,
            'CPF': user.cpf,
            'DATA DE CRIAÇÃO': format(new Date(user.createdAt), 'DD/MM/YYYY'),
            'Companhia': user.company ? user.company.name : null,
            'Rua': user.address ? user.address.street : null,
            'Complemento': user.address ? user.address.addressDetails : null,
            'Estado': user.address ? user.address.state : null,
            'Cidade': user.address ? user.address.city : null,
            'Responsável': user.responsible,
            'Grupo': user.businessGroup ? user.businessGroup.name : null,
            'Unidade': user.businessUnit ? user.businessUnit.name : null,
            'Segmento': user.segment ? user.segment.name : null,
            'Id de registro': user.registrationId,
            'Nome de usuário': user.userName,
            'E-mail': user.email,
            'Telefone': user.phone,
            'Faculdade': user.currentCollege,
            'Ano Formatura': user.conclusionYear,
            'Bloqueado': user.isBlocked
          });
        }

        this._excelService.exportAsExcelFile(excelModule, 'Perfil-Cadastral-Usuários');
      }
    }, () => {
      console.log('Erro ao exportar relatorio de cadastro de usuários.');
    });
  }

  public exportStatusReport(parameters: ReportParameters) {
    this._reportService.getStatusReport(parameters).subscribe(response => {
      const excelData = [];
      for (let idx = 0; idx < response.data.length; idx++) {
        const row = response.data[idx];
        if (row) {
          excelData.push({
            'Aluno': row.name,
            'Email': row.email,
            'Trilha': row.trackTitle,
            'Área': row.trackAreaTitle,
            'Sub-Área': row.trackSubAreaTitle,
            'Módulo': row.module,
            'Status': row.status,
            'Razão de isenção': row.reason !== '' ? row.reason : '-',
            'Matrícula': row.registrationId
          });
        }
      }
      this._excelService.exportAsExcelFile(excelData, 'Status-Usuários-Módulos');
    });
  }
  public exportTracksReport() {
    this._reportService.getTracksReport().subscribe(response => {
      const excelData = [];
      const rows = response.data;
      for (let idx = 0; idx < rows.length; idx++) {
        const row = rows[idx];
        if (row) {
          excelData.push({
            'Id Trilha': row.id,
            'Nome Trilha': row.title,
            'Duracao': this._utilService.formatSecondsToHourMinute(row.duration),
            'Resumo': row.description,
            'Data de Criacao': format(new Date(row.createdAt), 'DD/MM/YYYY'),
            'Área': row.area,
            'Subárea': row.subArea,
            'Solicitante': row.requester,
            'Total de inscritos': row.totalEnroll,
            'Total de bloqueados': row.totalBlockeds,
            'Total concluidos': row.totalFinished,
            'Total não concluidos': row.totalNotFinished,
            'Segmento': row.segment,
            'Setor': row.sector,
            'Disponibilidade': row.availability
          });
        }
      }
      this._excelService.exportAsExcelFile(excelData, 'Relatório-Trilhas');
    });
  }

  public exportStudyPlanReport() {
    this._reportService.getStudyPlanReport().subscribe(response => {
      const excelData = [];
      const rows = response.data;
      for (let idx = 0; idx < rows.length; idx++) {
        const row = rows[idx];
        if (row) {
          excelData.push({
            'Nome': row.studentName,
            'Email': row.email,
            'Plano de Estudo': row.planName,
            'Tipo': row.type,
            //'Trilhas':row.tracks,
            //'Módulos':row.module,
            'Status':'',
            'Matrícula': row.registrationId,
            'Trilha_Módulo':row.moduleTrackName
          });
        }
      }
      this._excelService.exportAsExcelFile(excelData, 'Relatório-Planos-de-Estudo');
    });
  }

  public exportModulesReport() {
    this._reportService.getModulesReport().subscribe(response => {
      const excelData = [];
      const rows = response.data;
      for (let idx = 0; idx < rows.length; idx++) {
        const row = rows[idx];
        if (row) {
          excelData.push({
            'Id Módulo': row.moduleId,
            'Nome Módulo': row.module,
            'Duracao': this._utilService.formatSecondsToHourMinute(row.duration),
            'Resumo': row.excerpt,
            'Instrutor': row.instructor,
            'Total de Questões': row.totalQuestions,
            'Total de Assuntos': row.totalSubjects,
            'Total de Conteúdos': row.totalContents,
            'Trilhas Associadas': row.tracks,
            'Data de Criação': format(new Date(row.createdAt), 'DD/MM/YYYY'),
            'Disponibilidade': row.availability,
            'Status': row.isInactive ? "Inativo" : "Ativo"
          });
        }
      }
      this._excelService.exportAsExcelFile(excelData, 'Relatório-Módulos');
    });
  }



  public exportSynchronousExperienceReport(parameters: ReportParameters) {
    this._reportService.getSynchronousExperienceReport(parameters.startDate, parameters.endDate, false, parameters.sort, parameters.trackIds).subscribe(response => {
      const excelData = [];
      const rows = response.data;
      for (let idx = 0; idx < rows.length; idx++) {
        const row = rows[idx];
        if (row) {
          const report = {
            'Área comercial': row.commercialArea,
            'Nome do Evento': row.eventTitle,
            'Matrícula do participante': row.registrationId,
            'Nome do participante': row.name,            
            'E-mail do participante': row.email,
            'Data da experiência': format(new Date(row.date), 'DD/MM/YYYY'),
            'Horário': format(new Date(row.date), 'HH:mm:ss'),
            'Lista de presença': isNil(row.userPresence) ? 'Não Computado' : row.userPresence ? 'Presente' : 'Faltou',
            'Nota da Inscrição': row.gradeRegister == -1 ? "Sem nota" : row.gradeRegister,
            'Nota da Avaliação inicial': row.gradeDiagnostic == -1 ? "Sem nota" : row.gradeDiagnostic,
            'Nota da Avaliação final': row.gradeValuation == -1 ? "Sem nota" : row.gradeValuation,
            'NPS': row.gradeNPS == -1 ? "Sem nota" : row.gradeNPS,
            'NPS discursivo': row.gradeNPSdiscursive == "" ? "Sem nota" : row.gradeNPSdiscursive,           
            'Instrutor ': row.instructor,
            'Tipo de treinamento': row.trainingType,
            'Produto Abordado': row.productAddressed,
            'Classe de produto': row.productClass,
            'Tempo da Experiência': this._utilService.formatSecondsToHourMinute(row.experienceTime)
          };
          excelData.push(report);
        }
      }
      this._excelService.exportAsExcelFile(excelData, 'Relatório-Experiência-Sincrona');
    });
  }
}


