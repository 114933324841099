import { AbstractControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../services/shared.service';
import { NotificationClass } from './notification';
import * as PDFJS from 'pdfjs-dist/build/pdf';
import { UploadService } from '../services/upload.service';
import { environment } from 'src/environments/environment';
PDFJS.GlobalWorkerOptions.workerSrc = '/node_modules/pdfjs-dist/build/pdf.worker.js';

export class FileUploadClass extends NotificationClass {

  constructor(
    protected _snackBar: MatSnackBar,
    protected _sharedService: SharedService,
    protected _uploadService: UploadService
  ) {
    super(_snackBar);
  }

  public setDocumentFile(event, childFormGroup: FormGroup) {
    if (event.target && event.target.files && event.target.files.length > 0) {

      if (event.target.files[0].size > 20000000) {
        this.notify('O arquivo selecionado ultrapassa o tamanho limite de 20MB');
        return;
      }
      if (!this._checkMimeType(event)) {
        this.notify('Formato de arquivo não suportado');
        return;
      }

      this.uploadFile(
        event.target.files[0],
        childFormGroup.get('downloadLink'),
        childFormGroup.get('fileName'),
      );
    }
  }

  public setZipFile(event, childFormGroup: FormGroup, contentType: string = null) {
    if (event.target && event.target.files && event.target.files.length > 0) {

      if (event.target.files[0].size > 80000000) {
        this.notify('O arquivo selecionado ultrapassa o tamanho limite de 80MB');
        return;
      }
      if (!this._checkMimeType(event)) {
        this.notify('Formato de arquivo não suportado');
        return;
      }
      console.log('event.target.files[0] -> ', event.target.files[0]);
      this.uploadFile(
        event.target.files[0],
        childFormGroup.get('downloadLink'),
        childFormGroup.get('fileName'),
        contentType
      );
    }
  }

  protected uploadFile(
    file,
    valueControl: AbstractControl,
    nameControl: AbstractControl,
    contentType: string = null) {

    nameControl.setValue(file.name);
    const fileName = file.name;
    this._uploadService.uploadFile(file, fileName, contentType).subscribe((response) => {
      console.log('this._uploadService.uploadFile - response -> ', response);
      valueControl.setValue(response);
      valueControl.updateValueAndValidity({ emitEvent: false });
      nameControl.updateValueAndValidity({ emitEvent: false });
      this.notify('Arquivo enviado com sucesso!');
    }, () => {
      this.notify('Ocorreu um erro ao enviar o arquivo, por favor tente novamente mais tarde');
    });
  }

  private _checkMimeType(event) {
    const typesAccepted = environment.contentTypesAccepted;
    return typesAccepted.some(type => type === event.target.files[0].type);
  }
}
