import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/pages/_services/user.service';

@Pipe({ name: 'levelColor' })
export class LevelColor implements PipeTransform {

    constructor(private _userService: UserService) { }
    transform(level) {
        const color = this._userService.getLevelColor(level);
        return color;
    }

}
