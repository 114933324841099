<div class="inner-content">
  <div class="header">
    <!--div class="empty-block"></div-->
    <h2>AVALIAÇÕES, PESQUISAS E TRABALHOS NOS CURSOS</h2>
    <!--div class="actions" >
      <button class="btn-test" (click)="createNewTest()" >
          NOVA AVALIAÇÃO OU PESQUISA
      </button>
    </div-->
  </div>

  <p>
    Esta seção gerencia a criação de provas, avaliações de reação acadêmica
    e pesquisas diversas associadas a cursos, eventos {{replacement.track.singular | replacement:'ou' }}.
    Elas aparecem para os alunos no corpo do curso, evento {{replacement.track.singular | replacement:'ou' }} como mais
    uma tarefa.
  </p>

  <div class="center-btn">
    <button class="btn-test" (click)="createNewTest()">
      NOVA AVALIAÇÃO OU PESQUISA
    </button>
    <button class="btn-test" (click)="goToPageValuationGroup()">
      ACESSAR AVALIAÇÕES EM GRUPO
    </button>
  </div>

  <div class="content-tests">
    <div class="filters">
      <app-filters-valuation-tests (setValuationTestTermFilter)="setValuationTestTermFilter($event)"
        (setCreatedSince)="setCreatedSince($event)" (setModules)="setModules($event)" (setTracks)="setTracks($event)">
      </app-filters-valuation-tests>
    </div>
    <div class="tests">
      <app-settings-valuation-test-card *ngFor="let test of tests" (cloneTest)="manageTest($event)"
        (editTest)="manageTest($event)" (deleteTest)="deleteTest($event)" (viewTest)="viewTest($event)"
        (getAnswersExcel)="getAnswersExcel($event)" [test]="test"></app-settings-valuation-test-card>
    </div>
    <app-pagination class="pagination" *ngIf="tests && tests.length > 0" [setPagination]=" {
        'itemsCount':_itemsCount,
        'pageSize': 10
      }" (goToPage)="goToPage($event)"></app-pagination>
  </div>
  <p class="emptyState" *ngIf="tests && tests.length === 0">
    Ainda não há nenhum BDQ criado.
  </p>
</div>
