<div class="choice-box">
  <p>Possui Experiência Profissional?</p>
  <div style="display: flex;">
    <div class="answer" (click)="selectProfessionalExperience(true)" >
      <div class="checkbox"
        [ngClass]="{ 'selected': formGroup.get('professionalExperience').value }"
      ></div>
      <p>Sim</p>
    </div>
    <div class="answer" (click)="selectProfessionalExperience(false)" >
      <div class="checkbox"
        [ngClass]="{ 'selected': !formGroup.get('professionalExperience').value }"
      ></div>
      <p>Não</p>
    </div>
  </div>
</div>
<div class="prof-experience" [formGroup]="formGroup" *ngIf="formGroup.get('professionalExperience').value">
  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('professionalExperiences')['controls']; let i = index;"
    formArrayName="professionalExperiences"
  >
    <ng-container [formGroupName]="i" >
      <div class="card-title companyName" >
        <p>Empresa {{i + 1}}</p>
        <div style="display: flex;">
          <mat-form-field
            class="whiteBorder noBorder"
            floatLabel="never">
            <input matInput formControlName="title" required/>
            <mat-placeholder class="placeholder">Nome da Empresa</mat-placeholder>
          </mat-form-field>
          <button class="btn-test remove-btn"
            type="button"
            (click)="removeForm('professionalExperiences', i)">
            Remover
          </button>
        </div>
      </div>
      <div class="card-info role" >
        <p>Cargo</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="role"
          placeholder="Seu cargo na Empresa" />
        </mat-form-field>
      </div>
      <div class="card-info description" >
        <p>Descrição</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="description"
          placeholder="Quais eram suas funções e atividades na empresa" required/>
        </mat-form-field>
      </div>
      <div class="two-columns">
        <div class="card-info startDate" >
          <p>Data de Início</p>
          <mat-form-field
            class="noBorder"
            floatLabel="never">
            <input matInput
              [matDatepicker]="startDate_i"
              placeholder="Dia que começou a trabalhar na empresa"
              formControlName="startDate"
              required
            />
            <mat-datepicker-toggle
              matSuffix [for]="startDate_i"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #startDate_i
            ></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="card-info endDate" >
          <p>Data de Saída </p>
          <mat-form-field
            class="noBorder"
            floatLabel="never">
            <input matInput
              [matDatepicker]="endDate_i"
              placeholder="Último dia trabalhando na empresa"
              formControlName="endDate"
              required
            />
            <mat-datepicker-toggle
              matSuffix [for]="endDate_i"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #endDate_i
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <p class="obs" >
        * caso esteja trabalhando nesta empresa atualmente, deixe o campo Data de Saída em branco.
      </p>
    </ng-container>
  </div>
</div>

<button class="btn-test add-button"
  type="button"
  [disabled]="!formGroup.get('professionalExperience').value"
  (click)="addExperience.emit()" >
  + Adicionar Empresa
</button>