import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersItemsBlockedComponent } from './users-items-blocked/users-items-blocked.component';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { UserSubjectBlockedComponent } from './user-subject-blocked.component';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';



@NgModule({
    declarations: [UsersItemsBlockedComponent, UserSubjectBlockedComponent],
    imports: [
        CommonModule,
        PaginationModule,
        MaterialComponentsModule,
        NavigationBackModule
    ]
})
export class UserSubjectBlockedModule { }
