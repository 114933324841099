<div class="header" *ngIf="currentQuestion" [ngClass]="{ 'offset': showMenu }">
  <div class="header-content">
    <a href="javascript:history.back()">
      <img class="back" src="./assets/img/arrow-back-white.png" />
    </a>

    <p>{{subject?.title}}</p>
  </div>
</div>

<div id="Exam" class="inner-content">

  <div class="warning-wrapper" *ngIf="cutOffDateWarningText">
    <mat-icon>report_problem</mat-icon>
    <div class="title">{{cutOffDateWarningText}}</div>
  </div>

  <app-exam-start
    *ngIf="!currentQuestion"
    [exam]="subject"
    [userProgress]="userProgress"
    [levels]="levelDict"
    [isPracticing]="isPracticing"
    (goToNextQuestion)="startExam()">
  </app-exam-start>

  <div class="content" *ngIf="currentQuestion" [ngClass]="{ 'reviewing': reviewingConcept }">
    <app-exam-question #questions [style.width]="questionWidth + '%'" [subject]="subject" [question]="currentQuestion"
      [levels]="levelDict" [evalAnswer]="answerResult" [reviewingConcept]="reviewingConcept" [showComment]="showComment"
      [hasNewLearningTechniques]="module.hasNewLearningTechniques" [isMock]="isMock"
      (confirmAnswer)="confirmAnswer($event)" (goToNextQuestion)="goToNextQuestion()" (openReview)="openReview($event)"
      [isPracticing]="isPracticing"
      (skippedQuestion)="skippedQuestion()">
    </app-exam-question>

    <app-exam-review *ngIf="reviewingConcept" [style.width]="reviewWidth + '%'" [contents]="conceptContents"
      [concept]="reviewingConcept" (closeReview)="closeReview()" (resizeWindows)="resizeWindows($event)"
      (setFinalOffset)="setFinalOffset($event)">
    </app-exam-review>
  </div>
</div>
<app-exam-footer
  *ngIf="currentQuestion"
  [progress]="userProgress"
  [levels]="levelDict"
  [hasAnswered]="canMoveToNextQuestion"
  [hasMoreQuestions]="userProgress?.level < 3"
  [isPracticing]="isPracticing"
  (goToNextQuestion)="goToNextQuestion()"
  (backToModule)="backToModule()"
  (navigateQuestion)="navigateQuestion($event)">
</app-exam-footer>

<app-exam-finish
  *ngIf="examFinished"
  [levels]="levelDict"
  [achievedLevel]="userProgress?.level-1"
  [isPracticing]="isPracticing"
  (continue)="startExam()"
  (finish)="finish()">
</app-exam-finish>
