import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-understand-content',
  templateUrl: './understand-content.component.html',
  styleUrls: ['./understand-content.component.scss']
})
export class UnderstandContentComponent {

  @Input() title: string;
  @Input() text: string;
  @Output() close = new EventEmitter();

  constructor() { }

}
