import { Component, ViewChild, Input } from '@angular/core';
import { DatatableComponent, ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { StudentGrade } from 'src/app/shared/components/student-grade/models/student-grade.model';
import { isEmpty, orderBy } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-grade',
  templateUrl: './student-grade.component.html',
  styleUrls: ['./student-grade.component.scss']
})
export class StudentGradeComponent {

  public isLoading: boolean;

  private _students: StudentGrade[] = [];
  private _businessGroup: string;
  private _businessUnit: string;
  private _segment: string;
  @Input()
  public get students(): StudentGrade[] { return this._students; }
  public set students(students: StudentGrade[]) {
    if (students) {
      this._students = orderBy(students, student => student.grade, 'desc');
      this.messages.emptyMessage = isEmpty(students) || students.length === 0 ? 'Não há dados para exibir' : '';
    }

  }

  @Input() public showRanking: boolean = false;

  @ViewChild('Table', { static: true }) private _table: DatatableComponent;

  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public imagePlaceholder = './assets/img/user-image-placeholder.png';
  // tslint:disable-next-line: max-line-length
  public title: string;
  public messages = {
    emptyMessage: 'Buscando dados...', totalMessage: 'Total', selectedMessag: 'selecionado',
  };

  public getRowHeight = (row) => row.height = 40;
  public rowIdentity = (row) => row.id;

  constructor(private _router: Router) { }


  public handleActivatedRowEvent(event) {
    const student: StudentGrade = event.row;
    const { businessGroup, businessUnit, segment } = student;
    const getFormattedName = (name: string) => `${name.charAt(0).toUpperCase()}${name.slice(1, name.length).toLowerCase()}`;

    this._businessGroup = `${businessGroup ? 'Businnes group: ' + getFormattedName(businessGroup) : ''}`;
    this._businessUnit = `${businessUnit ? 'Businnes unit: ' + getFormattedName(businessUnit) : ''}`;
    this._segment = `${segment ? 'Segment: ' + getFormattedName(segment) : ''}`;

    if (event.type === 'click')
      this._router.navigate([`configuracoes/detalhes-usuario/${(student).id}`]);

    const departments = `\n${this._businessGroup}\n${this._businessUnit}\n${this._segment}`;

    this.title = `Clique para acessar mais detalhes.`;
    if (departments.trim()) this.title += departments;
  }


}
