<div class="step" >
  <p class="subtitle" >
    Adicione módulos e eventos à Trilha e clique nas posições para ordená-los.
  </p><br>

  <h2 style="margin-bottom: -10px" >
    Módulos
  </h2>
  <div class="module-form" >
    <img class="search-icon" src="./assets/img/search.png" />
    <span class="label-information">*Módulos em rascunho não serão listados</span>
    <div class="search" >
      <input matInput type="text" placeholder="Nome do módulo" id="moduleInput"
        (keyup)="updateModuleSearch($event.target.value)" required
      />

      <div class="search-results"
        *ngIf="searchModuleResults && searchModuleResults.length > 0"
      >
      <div *ngFor="let module of searchModuleResults" (click)="setModule(module)">
        <p> {{ module.title }}</p>
      </div>
      </div>
    </div>

    <mat-form-field>
      <mat-select placeholder="Nível" required [(ngModel)]="newModule.level" >
        <mat-option [value]="level.id" *ngFor="let level of levels" >
          {{ level.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="btn-test" type="button" (click)="addModule()" >
      Adicionar
    </button>
  </div><br>

  <h2>Eventos</h2>
  <div class="module-form" >
    <img class="search-icon event" src="./assets/img/search.png" />
    <span class="label-information">*Eventos em rascunho não serão listados</span>
    <!-- <i class="event icon-lupa"></i> -->
    <div class="search event" >
      <input matInput type="text" placeholder="Nome do evento" id="eventInput"
        (keyup)="updateEventSearch($event.target.value)" required
      />

      <div class="search-results"
        *ngIf="searchEventResults && searchEventResults.length > 0"
      >
      <div 
      *ngFor="let event of searchEventResults" 
      (click)="setEvent(event)"
      [ngClass]="{'disabled': event.nextSchedule === null }">
        <p>{{ event.title }} </p>
        <span *ngIf="event.nextSchedule === null">Este evento ja aconteceu</span>
      </div>
      </div>
    </div>
    <button class="btn-test" type="button" style="margin-bottom: 15px"
      (click)="addEvent()" >
      Adicionar
    </button>
  </div>
  <br>

  <div class="modules" >
    <app-track-module-card
      *ngFor="let item of trackModulesEvents"
      [trackModule]="item"
      [modulesLength]="trackModulesEvents.length"
      [levels]="levels"
      (updateItemsOrder)="updateTrackItemsOrder($event)"
      (removeItem)="removeItemFromTrack($event)"
    ></app-track-module-card>
  </div>
</div>
