import { Component, Input, Output, EventEmitter, TemplateRef, ContentChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WrongItem } from 'src/app/shared/components/most-wrong-item/models/wrong-item.model';
// tslint:disable-next-line: max-line-length
import { WrongItemDialogComponent } from 'src/app/shared/components/most-wrong-item/components/wrong-item-dialog/wrong-item-dialog.component';

@Component({
  selector: 'app-most-wrong-item',
  templateUrl: './most-wrong-item.component.html',
  styleUrls: ['./most-wrong-item.component.scss']
})
export class MostWrongItemComponent {

  @Input() readonly itens: Array<WrongItem>;
  @Input() readonly title: string = 'ITENS COM MAIS ERROS';
  @Input() readonly showAll: boolean = true;
  @Input() readonly emptyStateEntity: string = 'item';
  @Input() readonly viewElements: number = 8;
  @Input() itemTemplate: TemplateRef<HTMLElement>;
  @Output() readonly click = new EventEmitter<WrongItem>();

  constructor(
    private _dialog: MatDialog
  ) {
  }


  public getChoppedItens(): Array<WrongItem> {
    return this.itens ? this.itens.slice(0, this.viewElements) : [];
  }

  public viewAllItens(): void {
    this._dialog.open(WrongItemDialogComponent, {
      width: '600px',
      data: this.itens
    });
  }

}
