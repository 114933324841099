import { Component, ElementRef, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Activation } from 'src/app/models/activation.model';
import { ActivationTypeEnum } from 'src/app/models/enums/activation-status.enum';
import { Answer } from 'src/app/models/question.model';
import { Subject } from 'src/app/models/subject.model';
import { NpsAnswerComponent } from 'src/app/settings/nps/nps-answer/nps-answer.component';
import { TypeTargetEffort } from 'src/app/shared/enumerations/effort';
import { EffortService } from 'src/app/shared/services/effort.service';

@Component({
  selector: 'app-exam-nps',
  templateUrl: './exam-nps.component.html',
  styleUrls: ['./exam-nps.component.scss']
})
export class ExamNpsComponent implements OnInit, OnDestroy {

  @ViewChild('questionNPS') npsAnswerComponent: NpsAnswerComponent;


  @Input() readonly subject?: Subject;
  @Input() readonly question: any;
  @Input() readonly title?: string;
  @Input() readonly questionNumber: number;
  @Input() readonly showButton?: boolean = false;
  @Input() readonly last: boolean;
  @Input() readonly isQuestionBdq?: boolean = true;
  @Input() readonly levels?: any;
  @Input() set evalAnswer(answer: Answer | string | number) {
    this.answered = answer ? true : false;
    this.selectedAnswer = answer;
  }
  @Output() finished = new EventEmitter();
  @Output() confirmAnswer = new EventEmitter();
  @Output() goToNextQuestion = new EventEmitter();
  @Output() openReview = new EventEmitter<any>();

  public get getSelectedAnswer(): any { return this.selectedAnswer; }
  public selectedAnswer: Answer | string | number;
  public answered: boolean = false;
  public selectedConcept: any;
  public id: string;
  constructor(
    private _effortService: EffortService,
    private _activatedRoute: ActivatedRoute,
    el: ElementRef) {
    _effortService.listenEvent(el.nativeElement, 'mousemove');
  }

  ngOnInit() {
    if (this.question != null && this.question.answers != null && this.question.answers.length > 0) {
      this.question.answers = this.shuffleArray(this.question.answers);
    }
    this.id = this._activatedRoute.snapshot.paramMap.get('moduleId');
    this._effortService.startCountInteraction(this.id, TypeTargetEffort.module);
  }

  ngOnDestroy(): void {
    this._effortService.finishInteraction();
  }
  private shuffleArray(array: any[]): any[] {
    let currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  public setAnswer(answer: any): void {
    this.selectedAnswer = answer;
  }

  public setAnswerDraftNps(answer: string) {
    this.setAnswer(answer);
    this.npsAnswerComponent.setAnswer(answer);
  }

  public confirm(): void {
    if (this.answered) {
      this.goToNextQuestion.emit();
      this.selectedAnswer = null;
    } else {
      this.confirmAnswer.emit(this.selectedAnswer.toString());
    }
  }
  public finish(): void {
    this.finished.emit();
  }
  public resetAnswer(): void {
    this.answered = false;
    this.selectedAnswer = null;
    this.npsAnswerComponent.resetAnswer();
  }

  public questionToActivation(question: any): Activation {

    return {
      id: question.id,
      type: ActivationTypeEnum.Nps,
      active: true,
      title: '',
      text: question.title
    } as Activation;
  }
}
