<div class="main">
    <app-cockpit-ranking-card 
    [ranking]="ranking"
    class="ranking"></app-cockpit-ranking-card>
    <app-effort-progress-students
      [effortMetric]="effortMetric" 
      class="effort-progress">
    </app-effort-progress-students>
    <app-effort-performance-students
      [effortPerformance]="effortPerformance"
      [isTrack]="false"
    >
    </app-effort-performance-students>
    <app-cockpit-warning class="warning"></app-cockpit-warning>
  </div>
