import { Component, Input } from '@angular/core';
import { isNil } from 'lodash';
import { UserProgress } from 'src/app/models/subject.model';
import { TrackPreviewProgress } from 'src/app/models/track-preview-progress.model';
import { ECardType } from '../Enum/card.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() type: ECardType;
  @Input() isEnrolled: boolean = true;
  private _progress: UserProgress &
  { progress: number, points: number, isStarted: boolean, isExempt: boolean } | TrackPreviewProgress;
  @Input()
  public get progress(): UserProgress &
  { progress: number, points: number, isStarted: boolean, isExempt: boolean } | TrackPreviewProgress {
    return this._progress;
  }
  public set progress(value: UserProgress &
  { progress: number, points: number, isStarted: boolean, isExempt: boolean } | TrackPreviewProgress) {
    if (!isNil(value) && this.type === ECardType.module) {
      value = value as UserProgress & { progress: number, points: number, isStarted: boolean, isExempt: boolean };
      this.statusLabel = value.isExempt ? 'Isento' :
        value.level === 4 ? 'Finalizado' :
          value.isStarted ? 'Cursando' : 'Inscrito';
    } else if (!isNil(value) && this.type === ECardType.track) {
      value = value as TrackPreviewProgress;
      this.statusLabel = value.progress === 1 ? 'Finalizado' :
        value.exemptInAllModules ? 'Isento' :
          value.startedAnyModule ? 'Cursando' : 'Inscrito';
    }
    this._progress = value;
  }
  public cardTypeEnum = ECardType;
  public statusLabel: string;
  constructor() { }

}
