import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExemptionReasonService {

    constructor(private _httpService: BackendService) { }

    public GetExemptionReasonsByUsersIds(usersIds: string[]): Observable<any> {
        return this._httpService.post('GetExemptionReasonsByUsersIds', {
            userIds: usersIds
        });
    }

}
