import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormArray } from '@angular/forms';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-complementary-experience',
  templateUrl: './complementary-experience.component.html',
  styleUrls: ['../manage-user-career.component.scss']
})
export class CareerComplementaryExperienceComponent extends NotificationClass {

  @Input() formGroup: FormGroup;
  @Output() addAbility = new EventEmitter();
  @Output() setValidator = new EventEmitter();

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  public cleanLevel(index: number): void {
    const formArray = this.formGroup.get('fixedAbilities') as FormArray;
    const formControl = formArray.controls[index];

    if (!formControl.get('hasLevel').value)
      formControl.get('level').setValue('');
  }

  public removeForm(key: string, index: number) {
    const formArray = this.formGroup.get(key) as FormArray;
    formArray.removeAt(index);
  }

  public validField(): void {
    const career = this.formGroup.getRawValue();

    let checkComplementaryExpField = true;
    if (career.fixedAbilities) {
      if (career.fixedAbilities[0].hasLevel === null) {
        this.notify('O campo "VBA" é obrigatório');
        checkComplementaryExpField = false;
      } else if (career.fixedAbilities[1].hasLevel === null) {
        this.notify('O campo "Excel" é obrigatório');
        checkComplementaryExpField = false;
      } else {
        career.abilities.forEach(x => {
          if (x.level.length === 0 || x.name.length === 0) {
            this.notify('Não podem haver nenhum campo em branco em "Formação Complementar"');
            checkComplementaryExpField = false;
          }
        });
      }
      const fixedAbilitiesFiltered = career.fixedAbilities
        .filter(fixedAbilities => fixedAbilities.hasLevel === true && fixedAbilities.level.length === 0)
        .map(fixedAbilities => fixedAbilities.name)
        .join();
      if (fixedAbilitiesFiltered.length > 0) {
        this.notify('Prencha o nível dos campos ' + fixedAbilitiesFiltered);
        checkComplementaryExpField = false;
      }
    } if (career.fixedAbilities.length === 0) {
      this.notify('Os campos "VBA" e "Excel" são obrigatórios');
      checkComplementaryExpField = false;
    }
    this.setValidator.emit({key: 'complementaryExperience', value: checkComplementaryExpField});
  }
}
