<div class="exam-review">

  <div class="drag-bar"
    (mousedown)="startDraggingBar($event)"
    (window:mouseup)="endDraggingBar()"
    (window:mousemove)="draggingBar($event)"
  ></div>

  <div class="back" (click)="goBack()" >
    <img src="./assets/img/arrow-back-white.png" />
  </div>

  <div class="list-container" [hidden]="selectedContent" >
    <app-content-list
      [concept]="concept"
      [contents]="contents"
      (selectContent)="viewContent($event)"
      (finishReview)="finishReview()"
    ></app-content-list>
  </div>

  <ng-container *ngIf="selectedContent" [ngSwitch]="selectedContent.type" >
    <app-content-video
      *ngSwitchCase="contentTypeEnum.Video"
      [resumedView]="true"
      [preventPointerEvents]="isDragging"
      [setContent]="selectedContent"
      [setPosition]="conceptPosition"
    ></app-content-video>

    <app-content-pdf
      *ngSwitchCase="contentTypeEnum.Pdf"
      #pdfContent
      [resumedView]="true"
      [setContent]="selectedContent"
    ></app-content-pdf>

    <app-content-text
      *ngSwitchDefault
      [resumedView]="true"
      [content]="selectedContent"
    ></app-content-text>

    <app-content-description
      [resumedView]="true"
      [content]="selectedContent"
      (goToPosition)="goToPosition($event)"
      (goToAnchor)="goToAnchor($event)"
    ></app-content-description>
  </ng-container>
</div>
