import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export function durationValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const value = control.value;

        if (isNil(value)) {
            return null;
        }

        return value <= 0 ? { durationIsInvalid: true } : null;
    };

}
