import { EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isNil } from 'lodash';
import { filter } from 'rxjs/operators';
import { ExpandedListDialogComponent } from 'src/app/shared/components/collapsed-list/expanded-list-dialog/expanded-list-dialog.component';
import { Item } from 'src/app/shared/components/collapsed-list/item/models/item.model';

@Component({
  selector: 'app-collapsed-list',
  templateUrl: './collapsed-list.component.html',
  styleUrls: ['./collapsed-list.component.scss']
})
export class CollapsedListComponent {

  private _itens: Array<Item> = [];
  @Input()
  public get itens(): Array<Item> { return this._itens; }
  public set itens(itens: Array<Item>) {
    if (itens && this._dialogRef) {
      this._dialogRef.componentInstance.data.itens = itens;
    } else if (itens) {
      this._itens = itens;
    }
  }

  @Input() readonly title: string = 'ITENS';
  @Input() readonly showAll: boolean = true;
  @Input() readonly emptyStateEntity: string = 'item';
  @Input() readonly viewElements: number = 3;
  @Input() itemTemplate: TemplateRef<HTMLElement>;
  @Input() filterTemplate: TemplateRef<HTMLElement>;
  @Output() readonly click = new EventEmitter<Item>();

  private _dialogRef: MatDialogRef<ExpandedListDialogComponent>;

  constructor(private _dialog: MatDialog, private _elementRef: ElementRef) {

  }

  public getChoppedItens(): Array<Item> {
    return this.itens ? this.itens.slice(0, this.viewElements) : [];
  }

  public viewAllItens(): void {
    this._dialogRef = this._dialog
      .open(ExpandedListDialogComponent, {
        width: '600px',
        data: {
          title: this.title,
          itens: this.itens,
          filterTemplate: this.filterTemplate,
          itemTemplate: this.itemTemplate
        }
      });

    this._dialogRef.afterClosed()
      .pipe(filter(itens => !isNil(itens)))
      .subscribe(itens => this._itens = itens);
  }

}
