<div class="module-card">
  <img *ngIf="data && data.imageUrl" class="main-img" [src]="data.imageUrl"
    onError="this.src='./assets/img/420x210-placeholder.png'" />
  <ng-container *ngIf="data && data.isDraft">
    <div class="draft-tag" [ngClass]="{ 'draft': data.isDraft }">
      {{ module.isDraft ? 'RASCUNHO': 'PUBLICADO' }}
    </div>
  </ng-container>

  <div class="preview">
    <div>
      <h3>
        {{data?.title}}
        <ng-content select="[afterTitle]">
        </ng-content>
      </h3>
      <p>
        <small *ngIf="showId">
          Id: {{ data?.id }}
        </small>
      </p>
    </div>
    <div>
      <ng-content select="[first]">
      </ng-content>
    </div>
  </div>

  <div class="edit">
    <img *ngIf="hasEdit" src="./assets/img/edit.png" (click)="edit.emit(data)" />
    <img *ngIf="hasClone" src="./assets/img/icon_trilha.png" (click)="clone.emit(data)" />
    <img *ngIf="hasDelete" src="./assets/img/trash.png" (click)="delete.emit(data)" />
  </div>
</div>
