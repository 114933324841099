<div class="filters">
  <p class="header">
    FILTROS POR CATEGORIA
  </p>

  <!-- <div class="chips" >
      <ng-container *ngFor="let filter of filters" >
        <p *ngFor="let option of filter.selectedOptions" >
          {{ filter.name }}: {{ option.description }}
          <span (click)="removeFilter(filter, option)" >X</span>
        </p>
      </ng-container>
    </div> -->

  <mat-accordion>

    <mat-expansion-panel hideToggle="true" disabled="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat-title">
            FILTROS DE BUSCA
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            BUSCAR POR NOME
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-list-search [noPadding]="true" (triggerSearch)="triggerUserSearch($event)"></app-list-search>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showCreatedSince">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            Data de Criação
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <input matInput [(ngModel)]="createdSince" [matDatepickerFilter]="maxDateFilter" [matDatepicker]="sincePicker"
          (ngModelChange)="setCreatedSince.emit(createdSince)" placeholder="Desde de..." />
        <mat-datepicker-toggle matSuffix [for]="sincePicker"></mat-datepicker-toggle>
        <mat-datepicker #sincePicker></mat-datepicker>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showTracks">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            Trilhas Associadas
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="options">
        <app-list-search placeholder="filtrar resultados" [noPadding]="true"
          (triggerSearch)="triggerTrackSearch($event)"></app-list-search>
        <ng-container *ngIf="tracks">
          <mat-checkbox *ngFor="let track of tracks" [(ngModel)]="track.checked"
            (ngModelChange)="changeFilterTracks($event)">
            <ng-container>{{track.title}}</ng-container>
          </mat-checkbox>
        </ng-container>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="showCreatedSince">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            Módulos Associados
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="options">
        <app-list-search placeholder="filtrar resultados" [noPadding]="true"
          (triggerSearch)="triggerModuleSearch($event)"></app-list-search>
        <ng-container>
          <mat-checkbox *ngFor="let module of modules" [(ngModel)]="module.checked"
            (ngModelChange)="changeFilterModules($event)">
            <ng-container>{{module.title}}</ng-container>
          </mat-checkbox>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
