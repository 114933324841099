import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationStatus } from 'src/app/models/enums/application-status';
import { EventApplication } from '../../../../models/event-application.model';

@Component({
  selector: 'app-answers-dialog',
  template: `
    <div class="answers-dialog" >
      <img class="close"
        src="./assets/img/close-colored.png"
        (click)="dismiss()"
      />
      <h2>PERGUNTAS DE PREPARAÇÃO</h2>

      <div class="user-info" >
        <div class="info" >
          <img [src]="eventApplication?.user?.imageUrl" />
          <p>
            {{ eventApplication?.user?.name }}<br>
            <small>{{ eventApplication?.user?.rank ? eventApplication?.user?.rank.name : '-'  }}</small>
          </p>
        </div>
        <p class="status" >{{subscriptionStatus[eventApplication.applicationStatus]}}</p>
      </div>

      <div class="scrollable" >
        <div class="question-container"
          *ngFor="let answer of eventApplication?.prepQuizAnswersList; let index = index" >
          <p class="number" >
            {{ index + 1 }}.
          </p>
          <div class="answer-container" >
            <p class="question" >
              {{ eventApplication?.prepQuiz?.questions[index] }}
            </p>
            <p class="answer" >
              <a href="{{answer.answer}}" *ngIf="answer.fileAsAnswer" download>
                <img src="./assets/img/download_azul.svg" *ngIf="answer.fileAsAnswer" >
              </a>
              {{ answer.answer }}
            </p>
          </div>
        </div>
      </div>
    </div>`,
  styleUrls: ['./answers.dialog.scss']
})
export class AnswersDialogComponent {

  public subscriptionStatus = {
    [ApplicationStatus.Pending]: 'APLICAÇÃO EM ANÁLISE',
    [ApplicationStatus.Rejected]: 'APLICAÇÃO REJEITADA',
    [ApplicationStatus.Approved]: 'APLICAÇÃO APROVADA'
  };

  constructor(
    public dialogRef: MatDialogRef<AnswersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public eventApplication: EventApplication
  ) {
  }



  public dismiss(): void {
    this.dialogRef.close();
  }

}
