<div class="inner-content" >
  <h2>ENTURMAÇÃO E MATRÍCULA</h2>

  <p *ngIf="responses && responses.length === 0" >
    Não há testes respondidos para recomendação.
  </p>

  <table mat-table matSort class="mat-elevation-z8"
    *ngIf="responses && responses.length > 0"
    [dataSource]="responses"
  >
    <ng-container matColumnDef="userName">
      <th width="8%" mat-header-cell *matHeaderCellDef> Nome Usuário </th>
      <td width="8%" mat-cell *matCellDef="let row">
        {{ row.userName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="registerId">
      <th width="8%" mat-header-cell *matHeaderCellDef> Matrícula Usuário </th>
      <td width="8%" mat-cell *matCellDef="let row">
        {{ row.userRegisterId || '--' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="testTitle">
      <th width="8%" mat-header-cell *matHeaderCellDef> Teste </th>
      <td width="8%" mat-cell *matCellDef="let row">
        {{ row.testTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th width="8%" mat-header-cell *matHeaderCellDef> Respondido Em </th>
      <td width="8%" mat-cell *matCellDef="let row">
        {{ row.createdAt | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="grade">
      <th width="8%" mat-header-cell *matHeaderCellDef> Nota Final </th>
      <td width="8%" mat-cell *matCellDef="let row">
        {{ row.finalGrade ? row.finalGrade.toFixed(1) : 'Aguardando Nota' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
     (click)="goToRecommendation(row)"
    ></tr>
  </table>

  <app-pagination *ngIf="responses && responses.length > 0"
    [setPagination]="{
      'itemsCount': itemsCount,
      'pageSize': 10
    }"
    (goToPage)="goToPage($event)"
  ></app-pagination>
</div>
