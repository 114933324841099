import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import {  map, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsModulesService } from 'src/app/settings/_services/modules.service';

@Injectable({ providedIn: 'root' })
export class AccessModuleGuard extends NotificationClass implements CanActivate {
  constructor(
    private _moduleService: SettingsModulesService,
    protected snackBar: MatSnackBar) {
    super(snackBar);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const trackId = route.queryParams['trackId'];
    const moduleId = route.paramMap.get('moduleId');

    return this._moduleService
      .getAccessAvailability(moduleId, trackId)
      .pipe(
        tap(response => {
          if (!response.data.isAvailability) {
            this.notify(`O acesso a esse módulo não está disponível: ${response.data.reason}`);
            return EMPTY;
          }
        }),
        map(response => response.data.isAvailability),
      );
  }

}

