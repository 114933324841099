import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserPreviewInterceptor } from '../interfaces/user-preview-interceptor.interface';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class PersonificationInterceptor implements HttpInterceptor {

  constructor(private _authService: AuthService) { }

  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<HttpEvent<any>> {


    const userRoleToSeeHow = this._authService.getUserRoleToSeeHow();
    if (userRoleToSeeHow) {
      request = request.clone({
        setHeaders: {
          'UserRoleToSeeHow': userRoleToSeeHow
        }
      });
    }

    const impersonatedUser: UserPreviewInterceptor | null = this._authService.getImpersonatedUser();
    if (impersonatedUser && impersonatedUser.userId) {
      request = request.clone({
        setHeaders: {
          'UserIdToImpersonate': impersonatedUser.userId,
          'UserRoleToImpersonate': impersonatedUser.userRole
        }
      });
    }

    return next.handle(request);
  }


}
