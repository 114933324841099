import { Component, OnInit } from '@angular/core';
import { NotificationClass } from '../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Module, ModuleQuestion, ModuleQuestionValuation } from '../../models/module.model';
import { ContentModulesService } from '../_services/modules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'src/app/models/subject.model';
import { MatDialog } from '@angular/material/dialog';
import { QuestionValuationFinishDialogComponent } from './question-valuation-finish-dialog/question-valuation-finish-dialog.component';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';

@Component({
  selector: 'app-module-question-valuation',
  templateUrl: './module-question-valuation.component.html',
  styleUrls: ['./module-question-valuation.component.scss']
})
export class ModuleQuestionValuationComponent extends NotificationClass implements OnInit {

  public module: Module;
  public subject: Subject;
  public moduleQuestion: ModuleQuestion;
  public moduleValuationQuestion: ModuleQuestion;
  public step: number = 0;

  constructor(
    protected _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    public moduleService: ContentModulesService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    const moduleId = this._route.snapshot.params['moduleId'];
    const subjectId = this._route.snapshot.params['subjectId'];
    this.moduleService.getUserQuestionValuation(moduleId, subjectId).subscribe(res => {
      this.module = res.data.module;
      if (this.module) {
        const subject = this.module.subjects.find(x => x.id === subjectId);
        if (subject) {
          this.subject = subject;
        }
      }
      if (res.data.moduleQuestion) {
        this.moduleQuestion = res.data.moduleQuestion;
      }
      if (res.data.moduleValuationQuestion) {
        this.moduleValuationQuestion = res.data.moduleValuationQuestion;
      }
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  goBack() {
    if (this.step > 0) {
      this.step--;
    } else {
      this._router.navigate(['/modulo/' + this.module.id]);
    }
  }

  next(obj: any) {
    if (this.step === 0) {
      this.step++;
    } else if (this.step === 1) {
      this.moduleService.addModuleUserQuestion(this.module.id, this.subject.id, obj).subscribe(res => {
        this.moduleQuestion = res.data;
        document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
        if (this.moduleValuationQuestion !== undefined && this.moduleValuationQuestion !== null) {
          this.step++;
        } else {
          const dialogRef = this._dialog.open(SimpleDialogComponent, {
            hasBackdrop: true,
            closeOnNavigation: false,
            disableClose: true,
            data: {
              message: 'Ainda não existem perguntas criadas por outros alunos para serem avaliadas. Volte aqui mais tarde',
              positiveTextAction: 'Ok'
            } as SimpleDialog
          });
          dialogRef.afterClosed().subscribe(() => {
            this._router.navigate(['/modulo/' + this.module.id]);
          });
        }
      });
    } else if (this.step === 2) {
      this.moduleService.addModuleUserQuestionValuation(this.moduleValuationQuestion.id, obj).subscribe(res => {
        const dialogRef = this._dialog.open(QuestionValuationFinishDialogComponent, {
          maxWidth: '100%',
          width: '100%',
          maxHeight: '100%',
          height: '100%',
          panelClass: 'custom-dialog'
        });
        dialogRef.afterClosed().subscribe(() => {
          this._router.navigate(['/modulo/' + this.module.id]);
        });
      });
    }
  }
}
