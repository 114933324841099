<header *ngIf="data?.title || data?.filterTemplate">
  <h2 mat-dialog-title>{{data?.title}}</h2>
  <div class="filter-wrapper">
    <ng-container #container [ngTemplateOutlet]="data?.filterTemplate" [ngTemplateOutletContext]="{ $implicit: data?.itens }"></ng-container>
  </div>
</header>

<mat-dialog-content>
  <ul *ngIf="data?.itens.length else EmptyList" class="list">
    <li class="item" *ngFor="let item of data?.itens; let index = index">
      <app-item *ngIf="!data.itemTemplate; else templateWrapper" [item]="item"></app-item>
      <ng-template #templateWrapper>
        <ng-container [ngTemplateOutlet]="data.itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </ng-template>
    </li>
  </ul>
  <ng-template #EmptyList>
    <span class="empty-list">Não há itens para exibir.</span>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button class="btn-test" (click)="dismiss()">Fechar</button>
</mat-dialog-actions>
