<div class="inner-content">
  <header>
    <h1>GERENCIAR TURMAS</h1>
    <div class="actions">
      <a class="btn-outline" href="./../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_TURMAS.xlsx"
        download="PLANILHA_MODELO_IMPORTAÇÃO_TURMAS.xlsx">Baixar template</a>
      <button class="btn-test primary-color" (click)="openImportDialogTeam()">Importar Turmas</button>
      <button class="btn-test width-larger" (click)="goToTeam()">+Adicionar Turmas</button>
    </div>
  </header>
  <div class="main">
    <div class="filters">
      <app-filters (setUserTermFilter)="searchName($event)" (setCreatedSince)="createdSince($event)"
        (setProgramsFilter)="programsFilter($event)"></app-filters>
    </div>
    <div class="table">
      <div class="info-table">
        <div>
          <div class="filter-actions-new">
            <div class="down">
              <span>
                <b>{{teamCount}}</b> Turmas Encontrados
              </span>
            </div>
          </div>
        </div>
        <table mat-table matSort [dataSource]="teams" class="mat-elevation-z8">

          <ng-container matColumnDef="id">
            <th width="10%" mat-header-cell *matHeaderCellDef>Id</th>
            <td width="10%" mat-cell *matCellDef="let row" style="font-size: 12px;">
              {{row.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th width="20%" mat-header-cell *matHeaderCellDef>Nome da Turma</th>
            <td width="20%" mat-cell *matCellDef="let row">
              {{row.name | uppercase}}
            </td>
          </ng-container>

          <ng-container matColumnDef="programName">
            <th width="20%" mat-sort-header="name" mat-header-cell *matHeaderCellDef>Programa(s)</th>
            <td width="20%" mat-cell *matCellDef="let row">
              {{row.programs && row.programs.length > 0 ? row.programs[0].title  : '' }}
              <ng-container *ngIf="row?.programs.length > 1">( +{{row.programs.length - 1}}
                {{row?.programs.length === 2 ? 'outra' : 'outras'}})</ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="createDate">
            <th width="15%" mat-header-cell *matHeaderCellDef>Data de criação</th>
            <td width="15%" class="padding" mat-cell *matCellDef="let row">
              {{row.createdAt | date: 'dd/MM/yyyy'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="studentNumber">
            <th width="5%" mat-header-cell *matHeaderCellDef>Nº de Alunos</th>
            <td width="5%" mat-cell *matCellDef="let row" (click)="viewUserDetails(row)">
              {{row.countStudents}}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th width="5%" mat-header-cell *matHeaderCellDef></th>
            <td width="5%" mat-cell *matCellDef="let row">
              <div class="actions-table">
                <img class="icon" src="./assets/img/edit.png" (click)="goToTeam(row.id)" title="Editar turmas">
                <img class="icon" src="./assets/img/trash.png" (click)="deleteTeam(row)" title="Excluir turmas">
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <app-pagination *ngIf="teamCount" [setPagination]="{
        'itemsCount': teamCount,
        'pageSize': pageSize
      }" (goToPage)="goToPage($event)"></app-pagination>
    </div>
  </div>
