<div class="content">
  <div class="side-content">
    <h3>Filtros</h3>
    <app-filter-report-information-registration #FilterComponent></app-filter-report-information-registration>
  </div>
  <div class="main-content">
    <div class="table">
      <div class="item">
        <div class="files">
          <span>Perfil Cadastral de Usuários</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.RegistrationUser)">
            EXPORTAR
          </button>
        </div>
      </div>

      <div class="item">
        <div class="files">
          <span>Usuários Inscritos nas Trilhas</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.RegisteredTracks)">
            EXPORTAR
          </button>
        </div>
      </div>



      <div class="item">
        <div class="files">
          <span>Relatório de Trilhas</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.Tracks)">
            EXPORTAR
          </button>
        </div>
      </div>

      <div class="item">
        <div class="files">
          <span>Status</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.Status)">
            EXPORTAR
          </button>
        </div>
      </div>

      <div class="item">
        <div class="files">
          <span>Relatório de Módulos</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.Modules)">
            EXPORTAR
          </button>
        </div>
      </div>

      <div class="item">
        <div class="files">
          <span>Relatório de Experiência Síncrona</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.synchronousExperience)">
            EXPORTAR
          </button>
        </div>
      </div>
      <div class="item">
        <div class="files">
          <span>Relatório de Planos de Estudo</span>
        </div>
        <div class="buttonExcel">
          <button class="btn-test btn-table" (click)="exportReport(reportsTypes.StudyPlans)">
            EXPORTAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
