import { VideoReference, TextReference, ConceptReference } from './../../../../../models/content.model';
import { Component, Input, Output, EventEmitter, OnInit, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Content, ContentExcel } from '../../../../../models/content.model';
import { Module } from '../../../../../models/module.model';
import { VideoConfigDialogComponent } from './content-config/video-config/video-config.dialog';
import { PdfConfigDialogComponent } from './content-config/pdf-config/pdf-config.dialog';
import { TextConfigDialogComponent } from './content-config/text-config/text-config.dialog';
import { Subject } from '../../../../../models/subject.model';
import { ContentTypeEnum } from '../../../../../models/enums/content-type.enum';
import { UtilService } from '../../../../../shared/services/util.service';
import { ZipConfigDialogComponent } from './content-config/zip-config/zip-config.dialog';
import Player from '@vimeo/player';
import { cloneDeep, isNil, isNumber, isString } from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ScormConfigComponent } from './content-config/scorm-config/scorm-config.component';
import { LinkDialogComponent } from './content-config/link-dialog/link-dialog.component';
import { UploadContentsDatabaseDialogComponent } from './upload-subjects/upload-contents.dialog';
import { concatMap, takeUntil } from 'rxjs/operators';
import { SettingsModulesDraftsService } from 'src/app/settings/_services/modules-drafts.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { Observable, Subject as SubjectRxjs } from 'rxjs';
import { XlsxConfigComponent } from './content-config/xlsx-config/xlsx-config.component';

@Component({
  selector: 'app-new-module-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['../new-module-steps.scss', './contents.component.scss']
})
export class NewModuleContentsComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() module$: Observable<Module>;
  @Input() createNewModuleDraft: Function;
  public module: Module;
  public newModule: Module;


  @Output() addContents = new EventEmitter<Array<Content>>();
  @Output() updateImportSubjects = new EventEmitter<Array<Subject>>();

  public contentTypeEnum = ContentTypeEnum;
  private _unsubscribeAll = new SubjectRxjs<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _utilService: UtilService,
    private _renderer: Renderer2,
    private _element: ElementRef,
    private _draftsService: SettingsModulesDraftsService,
    private _excelService: ExcelService,
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        if (moduleItems && moduleItems.subjects) {
          moduleItems.subjects.forEach(subject => {
            if (subject.contents) {
              subject.contents.forEach((content) => {
                content.subjectId = subject.id;
                content.formatedDuration = isString(content.duration) ?
                  content.duration :
                  this._utilService.formatDurationToHour(content.duration);
                content.concepts.forEach(conc => conc.checked = true);
              });
            }
          });
        }
        this.module = cloneDeep(moduleItems);
        this.newModule = cloneDeep(this.module);
      });
    }
  }

  public droppedContent(subject: Subject, evt: CdkDragDrop<Subject[]>) {
    moveItemInArray(subject.contents, evt.previousIndex, evt.currentIndex);
  }

  public addContent(subject: Subject): void {
    const concepts = subject.concepts ? subject.concepts : [];
    const content = new Content(
      subject.id,
      concepts.map((concept: string) => ({ 'name': concept }))
    );
    subject.contents = subject.contents ? subject.contents : [];
    subject.contents.push(content);
  }

  public removeContent(subject: Subject, index: number): void {
    subject.contents.splice(index, 1);
  }

  public openConfigDialog(content: Content, subject: Subject) {
    this.newModule = cloneDeep(this.module);
    content.subjectTitle = subject.title;
    content = this._adjustContent(content, subject);
    const dialog = this._getConfigDialog(content) as any;
    const dialogRef = this._dialog.open(dialog, {
      width: '1000px',
      maxHeight: '90vh',
      data: content,
      panelClass: 'content-dialog'
    });

    dialogRef.afterClosed().subscribe(async (result: Content) => {
      if (result) {
        delete result.subjectTitle;
        if (result.type === ContentTypeEnum.Video) {
          this.notify('Obtendo a duração do vídeo...');

          const videoContainer = this._createVideoContainer();
          const player = new Player('videoContent', { url: result.value });
          const duration = await player.getDuration();
          result.formatedDuration = this._utilService.formatDurationToHour(duration);
          this.notify('Sucesso ao obter a duração do vídeo');
          this._removeVideoContainer(videoContainer);
        }
        content = result;
        this.newModule = cloneDeep(this.module);
        
      }
      else {
        this.module = cloneDeep(this.newModule);
      }
    });
  }

  public nextStep(): void {
    const validationError = this._checkSubjectsInfo();
    if (validationError === null) {
      const contents: Array<Content> = [];
      if (!this.module.subjects) return;
      this.module.subjects.forEach(sub => {
        // eslint-disable-next-line prefer-spread
        contents.push.apply(contents, sub.contents);
      });
      this.addContents.emit(
        this._adjustDuration(contents)
      );

    } else this.notify(validationError);
  }

  public setDuration(content, value): void {
    content.duration = this._utilService.getDurationFromFormattedHour(value);
  }

  private _createVideoContainer(): HTMLDivElement {
    const container = this._renderer.createElement('div');
    this._renderer.setProperty(container, 'id', 'videoContent');
    this._renderer.setStyle(container, 'display', 'none');
    this._renderer.appendChild(this._element.nativeElement, container);
    return container;
  }

  private _removeVideoContainer(videoContainer: HTMLDivElement) {
    this._renderer.removeChild(this._element.nativeElement, videoContainer);
  }

  private _getConfigDialog(content: Content) {
    switch (content.type) {
      case ContentTypeEnum.Video:
        return VideoConfigDialogComponent;
      case ContentTypeEnum.Pdf:
        return PdfConfigDialogComponent;
      case ContentTypeEnum.Text:
        return TextConfigDialogComponent;
      case ContentTypeEnum.Zip:
        return ZipConfigDialogComponent;
      case ContentTypeEnum.Scorm:
        return ScormConfigComponent;
      case ContentTypeEnum.Link:
        return LinkDialogComponent;
      case ContentTypeEnum.XLSX:
        return XlsxConfigComponent;
      default:
        return TextConfigDialogComponent;
    }
  }

  private _adjustDuration(contents: Array<Content>): Array<Content> {
    contents.forEach((content) => {
      if (content.type === ContentTypeEnum.Video) {
        if (isString(content.duration)) this.setDuration(content, content.duration);
      }
    });
    return contents;
  }

  private _checkSubjectsInfo(): string {
    if (!this.module.subjects || this.module.subjects.length === 0) return null;

    let validationError = null;
    this.module.subjects.forEach(subj => {
      if (subj.contents && subj.contents.length > 0) {
        subj.contents.forEach((cont, index: number) => {

          if (!cont ||
            !cont.title ||
            (cont.type == null) ||
            isNil(cont.duration) && cont.type === ContentTypeEnum.Video ||
            (!cont.value && cont.type !== ContentTypeEnum.Zip)) {
            validationError = 'O Conteúdo \'' + (cont.title ? cont.title : (index + 1)) +
              '\' do Assunto \'' + subj.title +
              '\' possui campos ausentes de preenchimento obrigatório';
          } else if (cont.concepts && cont.concepts.length > 0) {
            const hasError = cont.concepts.some((conc: any) =>
              conc.positions && conc.positions.some(pos => !pos)
            );
            if (hasError) {
              validationError = 'O Conteúdo \'' + (cont.title ? cont.title : (index + 1)) +
                '\' do Assunto \'' + subj.title +
                '\' possui informações em \'Conceitos\' ausentes de preenchimento obrigatório';
            }
          }
        });
      }
    });

    return validationError;
  }

  private _adjustContent(content: Content, subject: Subject) {
    content.subjectId = subject.id;

    if (content.type === ContentTypeEnum.Video) {
      if (isNumber(content.duration)) content.duration = this._utilService.formatDurationToHour(content.duration) as any;
      if (isString(content.duration)) content.duration = this._utilService.getDurationFromFormattedHour(content.duration);
    }

    if (subject.concepts) {
      content.concepts = subject.concepts.map((concept: string): any => {
        const existingConcept = this._retrieveExistingConcept(content.concepts, concept);
        const videoPositions = existingConcept ? (existingConcept as VideoReference).positions : [];
        const textAnchors = existingConcept ? (existingConcept as TextReference).anchors : [];
        const checkedConcepts = (videoPositions || textAnchors);

        return {
          name: concept,
          checked: checkedConcepts,
          positions: existingConcept ? videoPositions : null,
          anchors: existingConcept ? textAnchors : null
        };
      });
    }

    switch (content.type) {
      case ContentTypeEnum.Video:
        content = this._adjustVideoContent(content);
        break;
      case ContentTypeEnum.Pdf:
        content = this._adjustPDFContent(content);
        break;
      case ContentTypeEnum.Zip:
        content = this._adjustZipContent(content);
        break;
      case ContentTypeEnum.Text:
      default:
        content = this._adjustTextContent(content);
        break;
    }

    return content;
  }

  private _retrieveExistingConcept(concepts: ConceptReference[], name: string): ConceptReference | {} {
    return concepts ? concepts.find(concept => concept.name === name) : {};
  }

  private _adjustVideoContent(content) {
    content.concepts.forEach(conc => {
      if (conc.positions && conc.positions.length > 0) {
        conc.checked = true;
        conc.positions = conc.positions.map((pos) =>
          typeof pos === 'string' ? pos :
            this._utilService.formatDurationToHour(pos)
        );
      }
    });
    return content;
  }

  private _adjustPDFContent(content) {
    content.concepts.forEach(conc => {
      conc.checked = conc.positions && conc.positions.length > 0;
    });
    return content;
  }

  private _adjustZipContent(content) {
    content.concepts.forEach(conc => {
      conc.checked = conc.positions && conc.positions.length > 0;
    });
    return content;
  }

  private _adjustTextContent(content) {
    content.concepts.forEach(conc => {
      conc.checked = conc.anchors && conc.anchors.length > 0;
    });
    return content;
  }

  public openUploadDialog(module: Module) {
    const dialogRef = this._dialog.open(UploadContentsDatabaseDialogComponent, {
      width: '1000px',
      maxHeight: '90vh',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        'module': module
      }
    });

    dialogRef.afterClosed().subscribe((data) => {

      const contents = this._getContents(data);

      if (!this.module.isDraft && !isNil(data) && contents.length > 0) {
        this.createNewModuleDraft(this.module).then(() => {
          this._importDraftContents(data);
        });
      } else {
        this._importDraftContents(data);
      }
    });
  }

  private _getContents(data) {
    let contentsData = [];
    if (!isNil(data)) {
      contentsData = data.subjects.map((sub) => ({ ...sub.contents }));
    }

    return contentsData;
  }

  private _importDraftContents(data) {

    const contents = this._getContents(data);

    if (!isNil(data) && !isNil(data.subjects) && contents.length > 0) {
      this._draftsService.importDraftContents(data.subjects, this.module.id, data.addContents)
        .pipe(concatMap(() => this._draftsService.getDraftById(this.module.id)))
        .subscribe(response => {
          this.updateImportSubjects.emit(response.data.subjects);
          this.notify('Modificacões Salvas em Rascunho');
        }, (err) => this.notify(this.getErrorNotification(err), 'Fechar', { duration: 9000000000000 }));
    }
  }

  public exportContents() {
    const excelContents: ContentExcel[] = [];
    for (let i = 0; i < this.module.subjects.length; i++) {
      const subject = this.module.subjects[i];
      if (subject.contents === null || subject.contents.length === 0) {
        continue;
      } else {
        for (let j = 0; j < subject.contents.length; j++) {
          const content = subject.contents[j];
          const contentName = content.title;
          let contentType = '';
          let contentValue = '';
          if (content.type === ContentTypeEnum.Video) {
            contentType = 'video';
            contentValue = content.value;
          } else if (content.type === ContentTypeEnum.Text) {
            contentType = 'texto';
            contentValue = content.value;
          } else if (content.type === ContentTypeEnum.Pdf) {
            contentType = 'pdf';
          } else if (content.type === ContentTypeEnum.Zip) {
            contentType = 'zip';
          } else if (content.type === ContentTypeEnum.Scorm) {
            contentType = 'scorm';
          } else if (content.type === ContentTypeEnum.Link) {
            contentType = 'link';
            contentValue = content.value;
          }
          const contentExcerpt = content.excerpt;
          const contentUrls = content.referenceUrls.join(';');

          const newExcelContentLine = new ContentExcel();
          newExcelContentLine.Nome_do_Assunto = subject.title;
          newExcelContentLine.Nome_do_Conteudo = contentName;
          newExcelContentLine.Tipo_do_Conteudo = contentType;
          newExcelContentLine.Valor_do_Conteudo = contentValue;
          newExcelContentLine.Descricao_do_Conteudo = contentExcerpt;
          newExcelContentLine.Urls_de_Referencia = contentUrls;
          excelContents.push(newExcelContentLine);
        }
      }
    }
    this._excelService.exportAsExcelFile(excelContents, 'Conteúdos - ' + this.module.title);
  }
}
