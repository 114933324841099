<ng-container *ngIf="module && subject">
    <div class="header inner-content">
        <h3>CURADORIA</h3>
        <mat-tab-group [selectedIndex]="step" (selectedTabChange)="onTabSelected($event)" animationDuration="0ms">
            <mat-tab
                label="CONFIGURAÇÃO">
            </mat-tab>
            <mat-tab
                label="AVALIAÇÕES">
            </mat-tab>
        </mat-tab-group>
    </div>
    <ng-container *ngIf="step === 0">
        <app-module-question
            [subject]="subject"
            [moduleQuestion]="moduleQuestion"
            (next)="next($event)"
            (goBack)="goBack()"
        ></app-module-question>
    </ng-container>
    <ng-container *ngIf="step === 1">
        <app-module-valuation
            [subject]="subject"
            [moduleQuestion]="moduleQuestion"
            [moduleQuestionValuations]="moduleQuestionValuations"
            (next)="next($event)"
            (goBack)="goBack()"
        ></app-module-valuation>
    </ng-container>
</ng-container>