import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Content } from '../../../../models/content.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { Level } from 'src/app/models/shared/level.interface';
import { ActivatedRoute } from '@angular/router';
import { ActionInfoTypeEnum, ActionInfo, ActionInfoPageEnum } from 'src/app/shared/directives/save-action/action-info.interface';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { environment } from 'src/environments/environment';
import { isNil } from 'lodash';

@Component({
  selector: 'app-content-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class ContentMenuComponent implements OnInit {

  // Features
  public hasFeatureLevels = environment.features.levels;

  // Components Props
  @Input() readonly contents: Array<Content> = [];
  private _menuContents: any;
  @Input()
  public get menuContents(): any {
    return this._menuContents;
  }
  public set menuContents(value: any) {
    if (!isNil(value) && isNil(this._menuContents)) {
      this._menuContents = value;
      if (this.menuContents.modules) {
        for (let index = 0; index < this.menuContents.modules.length; index++) {
          const element = this.menuContents.modules[index];
          if (element.id === this.moduleId) {
            element.selected = true;

            for (let sIndex = 0; sIndex < this.menuContents.modules[index].subjects.length; sIndex++) {
              const sub = this.menuContents.modules[index].subjects[sIndex];
              if (sub.id === this.subjectId) {
                sub.selected = true;
              } else {
                sub.selected = false;
              }
            }
          } else {
            element.selected = false;
          }
        }
      } else {
        for (let sIndex = 0; sIndex < this.menuContents.subjects.length; sIndex++) {
          const sub = this.menuContents.subjects[sIndex];
          if (sub.id === this.subjectId) {
            sub.selected = true;
          } else {
            sub.selected = false;
          }
        }
      }
    }
  }
  @Input() readonly currentIndex: number = 0;
  @Input() readonly subjectProgress;
  @Input() readonly levels: Array<Level> = [];
  @Input() readonly hasQuestionsSubject: boolean = false;

  @Output() toggleMenu = new EventEmitter();
  @Output() goToContent = new EventEmitter<any>();
  @Output() toggleItemClick = new EventEmitter();

  public moduleId: string = '';
  public subjectId: string = '';
  public readonly isNullOrUndefined = (value) => isNil(value);

  constructor(
    public utilService: UtilService,
    private _analyticsService: AnalyticsService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.moduleId = this._activatedRoute.snapshot.paramMap.get('moduleId');
    this.subjectId = this._activatedRoute.snapshot.paramMap.get('subjectId');
  }

  public closeMenu() {
    this.toggleMenu.emit();
  }

  public getLevelDescription(levelId: number): string {
    if (!this.levels || this.levels.length === 0 || this._hasNoProgress())
      return 'Não Iniciado';

    const level = this.levels.find(lev => lev.id === levelId);
    return level ? level.description : '';
  }

  public getBadgesProgressImageSrc(levelId: number): string {
    if (this._hasNoProgress())
      return './assets/img/empty-badge.png';

    switch (levelId) {
      case 1:
        return './assets/img/pencil-icon-shadow.png';
      case 2:
        return './assets/img/glasses-icon-shadow.png';
      case 3:
        return './assets/img/brain-icon-shadow.png';
      case 4:
        return './assets/img/brain-dark-icon-shadow.png';
      case 0:
      default:
        return './assets/img/empty-badge.png';
    }
  }

  private _hasNoProgress(): boolean {
    return !this.subjectProgress || (
      this.subjectProgress.level === 0 &&
      this.subjectProgress.progress === 0
    );
  }

  public finishContent(moduleId: string, subjectId: string, content: any): void {
    event.stopPropagation();
    if (content.watched) {
      const actionInfo = this._getActionInfo('content-video', moduleId, subjectId, content.id, ActionInfoTypeEnum.Finish);
      this._analyticsService.saveAction(actionInfo).subscribe(
        () => { },
        (error) => { console.error(error); });
    } else {
      const actionInfo = this._getActionInfo('content-video', moduleId, subjectId, content.id, ActionInfoTypeEnum.Finish);
      this._analyticsService.removeAction(actionInfo).subscribe(
        () => { },
        (error) => { console.error(error); });
    }
  }

  private _getActionInfo(description: string, moduleId: string, subjectId: string,
    contentId: string, type: ActionInfoTypeEnum): ActionInfo {
    return {
      'page': ActionInfoPageEnum.Content,
      'description': description,
      'type': type,
      'moduleId': moduleId,
      'subjectId': subjectId,
      'contentId': contentId
    };
  }

  public toggleSelection(event: Event, content: any): void {
    event.stopPropagation();
    content.selected = !content.selected;
  }

  public goToNewContent(event: Event, moduleId: string, subjectId: string, index: number): void {
    event.stopPropagation();
    const data = {
      'moduleId': moduleId,
      'subjectId': subjectId,
      'index': index
    };

    this.goToContent.emit(data);
  }

}
