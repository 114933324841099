import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudyPlanComponent } from './study-plan.component';
import { NewIndividualStudyPlanComponent } from './new-individual-study-plan/new-individual-study-plan.component';
import { NewGroupStudyPlanComponent } from './new-group-study-plan/new-group-study-plan.component';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { StudyPlanHeaderComponent } from './study-plan-header/study-plan-header.component';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { PagedListComponent } from './component/paged-list/paged-list.component';
import { ConnectDialogComponent } from './dialogs/connect-dialog/connect-dialog.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DragingListModule } from './component/draging-list/draging-list.module';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { EmptyDataModule } from 'src/app/shared/components/empty-data/empty-data.module';
import { SettingsHeaderModule } from 'src/app/shared/components/settings-header/settings-header.module';
import { GenericCardModule } from 'src/app/shared/components/generic-card/generic-card.module';
import { MatIconModule } from '@angular/material/icon';
import { CreateDialogComponent } from './dialogs/create-dialog/create-dialog.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ImportDialogComponent } from './dialogs/import-dialog/import-dialog.component';
import { ImportExcelFilesModule } from 'src/app/shared/components/import-excel-files/import-excel-files.module';
import { UserService } from 'src/app/pages/_services/user.service';


@NgModule({
  declarations: [
    StudyPlanComponent,
    NewIndividualStudyPlanComponent,
    NewGroupStudyPlanComponent,
    StudyPlanHeaderComponent,
    PagedListComponent,
    ConnectDialogComponent,
    CreateDialogComponent,
    ImportDialogComponent
  ],
  imports: [
    CommonModule,
    NavigationBackModule,
    MaterialComponentsModule,
    PipesModule,
    DragingListModule,
    PaginationModule,
    EmptyDataModule,
    SettingsHeaderModule,
    GenericCardModule,
    MatIconModule,
    DirectivesModule,
    ImportExcelFilesModule,
  ],
  providers: [
    UserService
  ]
})
export class StudyPlanModule { }
