import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cockpit-warning',
  templateUrl: './cockpit-warning.component.html',
  styleUrls: ['./cockpit-warning.component.scss']
})
export class CockpitWarningComponent implements OnInit {
  public primary_color: string;

  constructor() { }

  ngOnInit() {
    const style = getComputedStyle(document.body);
    this.primary_color = style.getPropertyValue('--primary-color');
  }

}
