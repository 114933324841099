import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../../shared/services/util.service';
import { ForumQuestion } from 'src/app/models/forum.model';
import { Module } from 'src/app/models/module.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-module-subject',
  templateUrl: './module-subject.component.html',
  styleUrls: ['./module-subject.component.scss']
})
export class ModuleSubjectComponent implements OnInit {

  // Features
  public hasFeatureLevels = environment.features.levels;

  // Components Props
  @Input() readonly subjectProgress: any;
  @Input() readonly subjectId: string;
  @Input() readonly module: Module;
  @Input() readonly isAvailability: boolean;
  @Input() readonly levels = {};
  @Input() readonly hasFinishedRequirements: boolean = true;
  @Input() readonly userProgress: any;
  @Output() expanedPanel = new EventEmitter<boolean>();

  public expanded: boolean = false;
  public questionCreationValuationStatus: boolean = false;
  public contents = [{}, {}, {}];
  public get subject() {
    return this.module.subjects.find(subject => subject.id === this.subjectId);
  }

  private _trackId: string;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this._trackId = this._route.snapshot.queryParams['trackId'];
    this.subject.duration = this.subject.contents.reduce((sum, c) => sum + c.duration, 0);
    if (
      this.module &&
      this.module.questionCreationValuation &&
      this.module.moduleQuestions &&
      this.module.moduleQuestions.length > 0 &&
      this.module.moduleQuestions.some(x =>
        x.moduleQuestion.subjectId === this.subject.id
      )
    ) {
      const moduleQuestion = this.module.moduleQuestions.find(x =>
        x.moduleQuestion.subjectId === this.subject.id
      );
      if (
        moduleQuestion.moduleQuestionValuation
      ) {
        this.questionCreationValuationStatus = true;
      }
    }
  }

  public reachedMaxLevel(): boolean {
    return this.levels && this.levels[this.subjectProgress.level] === undefined;
  }

  public goToContent(index: number): void {
    if (this.subject && this.subject.contents && this.subject.contents.length > 0) {
      localStorage.setItem('contents-hasQuestions', JSON.stringify(this.subject.hasQuestions));
      localStorage.setItem('subjectProgress', JSON.stringify(this.subjectProgress));
      localStorage.setItem('hasFinishedRequirements', this.hasFinishedRequirements.toString());
      localStorage.setItem('isExemption', JSON.stringify(this.userProgress.isExemption));
      const localForumQuestion: ForumQuestion = JSON.parse(localStorage.getItem('forumQuestionDialog'));
      if (localForumQuestion) {
        localForumQuestion.subjectId = this.subject.id;
        localForumQuestion.subjectName = this.subject.title;
        localForumQuestion.contentId = this.subject.contents[0].id;
        localForumQuestion.contentName = this.subject.contents[0].title;
        localStorage.setItem('forumQuestionDialog', JSON.stringify(localForumQuestion));
      }
      const hasLinkedTrack = !!this._trackId;
      const extras: NavigationExtras = hasLinkedTrack ? { queryParams: { trackId: this._trackId } } : {};
      this._router.navigate(['/modulo/' + this.module.id + '/' + this.subject.id + `/${index}`], extras);
    }
  }

  public onExpandHeader(event) {
    this.expanded = !this.expanded;
    this.expanedPanel.emit(this.expanded);
  }

  public goToCreateValuateQuestion() {
    this._router.navigate(['/modulo/' + this.module.id + '/assunto/' + this.subject.id + '/criar-avaliar-questao']);
  }
}
