<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      Voltar
    </a>
    <h2>GERENCIAR INSCRIÇÕES</h2>
  </nav>

  <app-settings-applications-header [applications]="applications" [eventTitle]="eventInfo.eventTitle"
    [eventDate]="eventInfo.eventDate" [scheduleStartDate]="eventInfo.scheduleStartDate"
    [scheduleEndDate]="eventInfo.scheduleEndDate" [percentage]="eventInfo.percentage" (usersAdded)="loadApplications()">
  </app-settings-applications-header>

  <app-settings-applications-table [applications]="pendingApplications" [scheduleType]="scheduleType"
    (changedEventApplication)="loadApplications()" (approveApplication)="approveUser($event)"
    (denyApplication)="denyUser($event)"></app-settings-applications-table>

  <app-settings-applications-table header="INSCRITOS"
    [dateLabel]="scheduleType === scheduleEnum.SynchronousExperience ? 'Data de importação' : 'Data de Inscrição'"
    [applications]="approvedApplications" [scheduleType]="scheduleType" (changedEventApplication)="loadApplications()"
    (approveApplication)="approveUser($event)" (denyApplication)="denyUser($event)"></app-settings-applications-table>

  <app-settings-applications-table header="RECUSADOS" dateLabel="Recusado Em" [scheduleType]="scheduleType"
    [applications]="deniedApplications" (changedEventApplication)="loadApplications()"
    (approveApplication)="approveUser($event)" (denyApplication)="denyUser($event)"></app-settings-applications-table>
</div>
