<div class="inner-content">
    <div>
      <h4>FORMAÇÃO ACADEMICA</h4>
      <div class="graduation">
        <span>Escolaridade: &nbsp; <b>{{job?.employment?.preRequirements?.education}}</b></span>
        <span>Data da formatura: &nbsp; <b>{{job?.employment?.preRequirements?.dateConclusion}}</b> </span>
      </div>
      <div class="curse">
      <span>Curso: &nbsp; <b>{{job?.employment?.preRequirements?.curseName}}</b></span>
      <span>CR: &nbsp; <b>Acima de {{job?.employment?.preRequirements?.crAcumulation}}</b></span>
      </div>
    </div>

    <div>
      <h4>EXPERIÊNCIA PROFISSIONAL</h4>
      <span><b>{{job?.employment?.preRequirements?.minTime}} anos</b></span>
    </div>

    <div class="benefics">
      <h4 class="exp">COMPETÊNCIAS</h4>
      <div class="cards-slider" >
        <div class="slider-grid">
          <ng-container *ngFor="let skill of job.employment.preRequirements.complementaryInfo">
            <div class="progress-card">
                <p class="title">{{skill.name ? skill.name : '-'}}</p>
                <p style="color: rgba(0, 0, 0, 0.42);">{{skill.level ? skill.level : '-'}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div>
      <h4>CERTIFICAÇÃO</h4>
      <span><b>{{job?.employment?.preRequirements?.certification}}</b></span>
    </div>

    <div class="benefics">
      <h4 class="exp">IDIOMAS</h4>
      <div class="cards-slider" >
        <div class="slider-grid">
          <ng-container *ngFor="let language of job.employment.preRequirements.languageInfo">
            <div class="progress-card">
                <p class="title">{{language.language ? language.language : '-'}}</p>
                <p style="color: rgba(0, 0, 0, 0.42);">{{language.level ? language.level : '-'}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
</div>