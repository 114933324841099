import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgUtilModule } from '@tg4/ng-util';
import { MaterialComponentsModule } from '../../shared/material.module';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';

import { ValuationTestsComponent } from './valuation-tests.component';
import { ManageValuationQuestionDialogComponent } from './manage-valuation-test/manage-valuation-question/manage-valuation-question.dialog';
import { SettingsManageValuationTestComponent } from './manage-valuation-test/manage-valuation-test.component';
import { SettingsValuationTestCardComponent } from './valuation-test-card/valuation-test-card.component';
import { SettingsModuleCardValuationTestComponent } from './manage-valuation-test/module-card-valuation-test/module-card-select.component';
import { SettingsTrackCardValuationTestComponent } from './manage-valuation-test/track-card-valuation-test/track-card-select.component';
import { SettingsManageValuationTestReleaseComponent } from './manage-valuation-test-release/manage-valuation-test-release.component';
// tslint:disable-next-line: max-line-length
import { ValuationTestReleaseToggleComponent } from './manage-valuation-test-release/valuation-test-release-toggle/valuation-test-release-toggle.component';
import { SettingsModuleCardSelectComponent } from '../manage-team/recommend-track/module-card-select/module-card-select.component';
import {
    UploadValuationTestDialogComponent
} from './manage-valuation-test/upload-valuation-test/upload-valuation-test-dialog/upload-valuation-test.dialog';
import { CourseworkDialogComponent } from './manage-valuation-test/coursework-dialog/coursework.dialog.component';

import { SettingsValuationTestsService } from '../_services/valuation-tests.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { SharedService } from 'src/app/shared/services/shared.service';
// tslint:disable-next-line: max-line-length
import { GenereteValuationTestQuestionsDialogComponent } from './manage-valuation-test/generete-valuation-test-questions-dialog/generete-valuation-test-questions-dialog.component';
import { AutocompleteModule } from 'src/app/shared/components/autocomplete/autocomplete.module';
import { MatCardModule } from '@angular/material/card';
// tslint:disable-next-line: max-line-length
import { GenerateValuationTestAccordionComponent } from './manage-valuation-test/generete-valuation-test-questions-dialog/generate-valuation-test-accordion/generate-valuation-test-accordion.component';
import { FiltersValuationTestsComponent } from './manage-valuation-test/filters-valuation-tests/filters-valuation-tests.component';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
// tslint:disable-next-line: max-line-length
import { GenerateValuationTestCheckOptionsModule } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SettingsManageUserRegisterModule } from '../manage-user-register/manage-user-register.module';
@NgModule({
    declarations: [
        ValuationTestsComponent,
        SettingsManageValuationTestComponent,
        SettingsManageValuationTestReleaseComponent,
        ValuationTestReleaseToggleComponent,
        SettingsValuationTestCardComponent,
        ManageValuationQuestionDialogComponent,
        SettingsModuleCardValuationTestComponent,
        SettingsTrackCardValuationTestComponent,
        SettingsValuationTestCardComponent,
        SettingsModuleCardValuationTestComponent,
        UploadValuationTestDialogComponent,
        CourseworkDialogComponent,
        GenereteValuationTestQuestionsDialogComponent,
        GenerateValuationTestAccordionComponent,
        FiltersValuationTestsComponent,
    ],
    imports: [
        BrowserModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ListSearchModule,
        NgUtilModule,
        AutocompleteModule,
        MatCardModule,
        PaginationModule,
        GenerateValuationTestCheckOptionsModule,
        PipesModule,
        SettingsManageUserRegisterModule
    ],
    providers: [
        SettingsValuationTestsService,
        UploadService,
        SharedService
    ],
    exports: [
        FiltersValuationTestsComponent
    ]
})
export class SettingsValuationTestsModule { }
