import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged } from 'rxjs/operators';
import { EStudyPlanItem } from 'src/app/models/enums/study-plan-item.enum';
import { Module } from 'src/app/models/module.model';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { Sector } from 'src/app/models/sector.model';
import { StudyPlanService } from 'src/app/settings/_services/study-plan.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IRequestFilter } from '../Interface/request-filter';
import { ERequesrtSort, IRequesrtSort } from '../Interface/request-sort';
import { SectorService } from 'src/app/settings/_services/sector.service';
import { MyCoursesService } from '../services/my-courses.service';
import { Course } from 'src/app/models/shared/course.model';
import { element } from 'protractor';
import { cloneDeep, indexOf } from 'lodash';
import { init } from 'logrocket';
import { forkJoin } from 'rxjs';
import { ItemComponent } from 'src/app/shared/components/collapsed-list/item/item.component';

@Component({
  selector: 'app-all-courses',
  templateUrl: './all-courses.component.html',
  styleUrls: ['./all-courses.component.scss']
})
export class AllCoursesComponent implements OnInit, OnDestroy {
  public readonly pageSize: number = 8;
  public allCoursesAlphabetic: Course[] = [];
  public allCoursesDueDate: Course[] = [];
  public allCoursesProgress: Course[] = [];
  public actualSort = this.allCoursesAlphabetic;
  public courses: Course[] = [];
  public sectors: Sector[] =[];
  public sectorFilters: IRequestFilter[];
  public sort: IRequesrtSort = {
    alphabetic: true,
    dueDate: false,
    progress: false
  };

  private subscriptionArray: Subscription[] = [];
  

  constructor(private _studyPlanService: StudyPlanService,
    private _authService: AuthService,
    private _myCoursesService: MyCoursesService,
    private _sectorService: SectorService) { }
  ngOnInit() {
    this.loadSector();
    this.setSubscriptionFilters();
    this.setSubscriptionSort();
  }
  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }
  public loadSector() {
    this._sectorService.GetAllSectors().subscribe(response => {
      this.sectors = response.data.map(value => ({
        id: value.id, name: value.name
      }));
      this.sectors.sort((a, b) => a.name.localeCompare(b.name));
      this.sectors.push({
        id: "000000000000000000000000", name: "Outros",
        segmentsCount: 0
      });
      this.loadCourses(this.allCoursesAlphabetic);
    });
  }
  public loadCourses(allCoursesArray){
    const Observables=[];
    this.sectors.forEach(sector =>{
      Observables.push(this._studyPlanService.GetAllCourseFiltered(1, this.pageSize, sector.id,
      null, this.sort, false));
    });
    forkJoin(Observables).subscribe(response =>{
      response.forEach((el: any) => {
        
        if(el.data.itemsCount>0){
          const sectorPreview = new Course();
          if(el.data.courses[0].sector){
            sectorPreview.id = el.data.courses[0].sector.id;
            sectorPreview.title = el.data.courses[0].sector.name;
          } 
          else {sectorPreview.id = "000000000000000000000000";
          sectorPreview.title = 'Outros';
        }
          sectorPreview.count = el.data.itemsCount;
          sectorPreview.page = el.data.page;
          sectorPreview.programs = this._studyPlanService.ParseStudyPlanItem(el.data.courses);
          allCoursesArray.push(sectorPreview);
        }
      });
      this.sortArray(allCoursesArray);
      this.courses = cloneDeep(allCoursesArray);
      this.actualSort = cloneDeep(allCoursesArray);
      this.updateFilter();
    });
  }


  public LoadSectorPrograms(page: number, sector: Sector){
    const course = this.courses.find(elem => elem.id == sector.id);
    course.page = page;
    this._studyPlanService.GetAllCourseFiltered(page, this.pageSize, sector.id,
      this.sectorFilters, this.sort, false).subscribe(response => {
        course.programs = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
        course.count = response.data.itemsCount;        
      });
  }
  public CreateSectorPrograms(page: number, sector: Sector){
    const sectorPreview = new Course();
    sectorPreview.page=page;
    sectorPreview.title=sector.name;
    sectorPreview.id= sector.id;
    this._studyPlanService.GetAllCourseFiltered(page, this.pageSize, sector.id,
      this.sectorFilters, this.sort, false).subscribe(response => {
        sectorPreview.programs = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
        sectorPreview.count = response.data.itemsCount; 
        this.courses.push(sectorPreview);       
      });
  }

  public sortArray(allCoursesArray){
    allCoursesArray.sort((a, b) => a.title.localeCompare(b.title));
    const investmentAdvice = allCoursesArray.find(el => el.id == '632df2133f09d931cb23e7f0');
    const financialMarket = allCoursesArray.find(el => el.id == '632df2363f09d931cb23e7f6');
    const Others = allCoursesArray.find(el => el.id == '000000000000000000000000');
    if (financialMarket) {
      allCoursesArray.splice(allCoursesArray.indexOf(financialMarket),1);
      allCoursesArray.splice(0,0,financialMarket);
    }
    if (investmentAdvice) {
      allCoursesArray.splice(allCoursesArray.indexOf(investmentAdvice),1);
      allCoursesArray.splice(0,0,investmentAdvice);}
    allCoursesArray.splice(allCoursesArray.indexOf(Others),1);
    allCoursesArray.splice(allCoursesArray.length-1,0,Others);

  }

  public goToPage(data: { page: number, title: string}) {
    const sector = this.sectors.find(sector => sector.name == data.title);
    this.LoadSectorPrograms(data.page,sector);
  }

  public setSubscriptionFilters() {
    this.subscriptionArray.push(this._myCoursesService.getFilter().pipe(distinctUntilChanged()).subscribe(value => {
      this.sectorFilters = value;
      this.updateFilter();
    }));
  }

  public setSubscriptionSort() {
    this.subscriptionArray.push(this._myCoursesService.getSort().pipe(distinctUntilChanged()).subscribe(value => {
      this.sort = value;
      if(this.sort.dueDate && this.allCoursesDueDate.length==0) 
      { 
        this.loadCourses(this.allCoursesDueDate); 
        return;
      }
      if(this.sort.progress && this.allCoursesProgress.length==0) 
      {
        this.loadCourses(this.allCoursesProgress); 
        return;
      }
      if(this.sort.dueDate) this.actualSort = cloneDeep(this.allCoursesDueDate);
      if(this.sort.alphabetic) this.actualSort = cloneDeep(this.allCoursesAlphabetic);
      if(this.sort.progress) this.actualSort = cloneDeep(this.allCoursesProgress);
      this.updateFilter();
    }));
}


  public updateFilter(){
    this.courses = [];
    if(this.sectorFilters && this.sectorFilters.length>0){
      this.courses = [];
      this.sectorFilters.forEach(sector => {
        if (sector.segmentId.length==0) this.courses.push(this.actualSort.find(el => sector.sectorId == el.id));
        else {
          const actualSector = this.sectors.find(sct => sct.id == sector.sectorId);
          this.CreateSectorPrograms(1, actualSector);
          }          
        });
    }
    else{
      this.courses = cloneDeep(this.actualSort);
    }    
  }

}
