import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { SharedService } from '../../../../../shared/services/shared.service';
import * as XLSX from 'xlsx';
import { Headears, mandatoryCollumnEnum, correctAnswer} from '../model-upload/model-upload';
import { ValuationTestQuestion, ValuationTestQuestionTypeEnum, ValuationTestQuestionOption } from 'src/app/models/valuation-test.interface';
import { isNil, groupBy } from 'lodash';

@Component({
  selector: 'app-upload-valuation-test.dialog',
  templateUrl: './upload-valuation-test.dialog.html',
  styleUrls: ['./upload-valuation-test.dialog.scss']
})
export class UploadValuationTestDialogComponent extends NotificationClass {

  public addQuestions = true;

  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadValuationTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _sharedService: SharedService
  ) {
    super(_snackBar);
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  public openFileUpload(): void {
    const input = document.getElementById('qdbInputFile') as HTMLInputElement;
    input.value = '';
    input.click();
  }

  public setDocumentFile(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
        this.notify('Tipo de arquivo inválido. Apenas \'xls\', \'xlsx\' e \'csv\' são permitidos.');
        return;
      }

      this._readExcel(file);
    }
  }

  private _readExcel(file) {
    const callback = this.setQuestions.bind(this);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let binary = '';
      const bytes = new Uint8Array(this.result as any);

      for (let i = 0; i < bytes.byteLength; i++)
        binary += String.fromCharCode(bytes[i]);

      callback(binary);
    };
    reader.readAsArrayBuffer(file);
  }

  private _getExcelContentAsJson(excelFile: any): Array<any> {
    const mandatoryCollumns = [
      mandatoryCollumnEnum.id,
      mandatoryCollumnEnum.typeQuestion,
      mandatoryCollumnEnum.text,
      mandatoryCollumnEnum.answareOrWeight];

    const workbook = XLSX.read(excelFile, { type: 'binary' });
    const wsname: string = workbook.SheetNames[0];
    const ws: XLSX.WorkSheet = workbook.Sheets[wsname];
    const headers = this.getHeaderValues(ws, mandatoryCollumns);

    if (headers.length !== mandatoryCollumns.length) {
      this.notify('Campos obrigatórios não foram encontrado');
      this.dismiss();
      return [];
    }

    return this.getCollumnsValue(ws, headers).filter(value => !this.isEmpty(value));
  }

  public isEmpty(obj): boolean {
    return Object.keys(obj).length === 0;
  }
  private getCollumnsValue(sheet: XLSX.WorkSheet, headers: Headears[]) {
    const values = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let rows = range.s.r; /* start in the first row */
    let hdr = {};
    for (rows = range.s.r; rows <= range.e.r; ++rows) {
      hdr = {};
      for (let i = 0; i < headers.length; ++i) {
        const cell = sheet[XLSX.utils.encode_cell({ c: headers[i]['collumn'], r: rows })];

        if (cell && cell.t) {
          hdr[headers[i]['headear']] = XLSX.utils.format_cell(cell);
        }
      }
      values.push(hdr);
    }
    values.shift();

    return values;
  }

  private getHeaderValues(sheet: XLSX.WorkSheet, mandatoryCollumns: string[]): Headears[] {
    const headers = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let collumns = range.s.c; /* start in the first collumn */
    const row = range.s.r; /* start in the first row */

    for (collumns = range.s.c; collumns <= range.e.c; ++collumns) {
      const cell = sheet[XLSX.utils.encode_cell({ c: collumns, r: row })];
      let hdr = 'UNKNOWN' + collumns;
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        if (mandatoryCollumns.filter(collumnName => this.normalizeString(hdr) === this.normalizeString(collumnName)).length > 0) {
          headers.push({
            headear: this.normalizeString(hdr),
            collumn: collumns
          });
        }
      }
    }
    return headers;
  }

  public normalizeString(text: string): string {
    return text.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
  }

  public setQuestions(file): void {
    const questions = this._getExcelContentAsJson(file);

    if (questions.length === 0) return;
    let index = 0;
    const questionsPush: ValuationTestQuestion[] = [];

    if (questions.filter(quest => quest[mandatoryCollumnEnum.id] === '').length > 0) {
      this.notify('Todas as questões precisam ter um id associado');
      this.dismiss();
      return;
    }

    while (index < questions.length) {
      const _id = questions[index][mandatoryCollumnEnum.id];
      const question = new ValuationTestQuestion();
      const questionFiltered = questions.filter(quest => quest[mandatoryCollumnEnum.id] === _id);

      const type = questionFiltered[0][mandatoryCollumnEnum.typeQuestion].replace(' ', '').toLowerCase();

      const erroList = [];

      if (this.normalizeString(type) === 'multiplaescolha') {
        question.type = ValuationTestQuestionTypeEnum.MultipleChoice;
        question.options = [];
        for (let i = 2; i < questionFiltered.length; i++) {
          const options = new ValuationTestQuestionOption();
          options.text = questionFiltered[i][mandatoryCollumnEnum.text];
          if (!isNil(questionFiltered[i][mandatoryCollumnEnum.answareOrWeight])) {
            options.correct = (questionFiltered[i][mandatoryCollumnEnum.answareOrWeight].replace(' ', '').toLowerCase() === correctAnswer);
          } else {
            options.correct = false;
          }
          question.options.push(options);
        }

        if (!question.options.some(opt => opt.correct === true)) {
          erroList.push('As questões precisam ter uma alternativa correta');
        }

        if (question.options.filter(option => !isNil(option.text)).length < 2) {
          erroList.push('É preciso ter duas alternativas pelomenos');
        }

      } else if (this.normalizeString(type) === 'discursiva') {
        question.type = ValuationTestQuestionTypeEnum.Discursive;
      } else {
        erroList.push(questionFiltered[0].typeQuestion + ' é um tipo de questão invalidá');
      }

      if (isNil(questionFiltered[1][mandatoryCollumnEnum.answareOrWeight])) {
        erroList.push('O campo peso é obrigatório');
      } else if (typeof (Number(questionFiltered[1][mandatoryCollumnEnum.answareOrWeight])) !== 'number') {
        erroList.push('O peso da questão precisa ser um número');
      } else {
        question.percentage = parseFloat(questionFiltered[1][mandatoryCollumnEnum.answareOrWeight]);
      }
      if (questionFiltered[1][mandatoryCollumnEnum.text] === null) {
        erroList.push('O enunciado da questão é obrigatório');
      } else {
        question.title = questionFiltered[1][mandatoryCollumnEnum.text];
      }

      if (erroList.length > 0) {
        this.notify(erroList.join(',\n'), 'OK', { duration: 5000 });
        this.dismiss();
        return;
      }
      question.testId = this.data.testId !== null ? this.data.testId : null;
      question.testTitle = this.data.title !== null ? this.data.testId : null;
      question.newQuestion = false;

      questionsPush.push(question);
      index += questionFiltered.length;

    }
    this.dialogRef.close({ questions: questionsPush, addQuestions: this.addQuestions });
  }

}
