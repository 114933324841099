import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropDialogComponent } from '../../../../../shared/dialogs/image-crop/image-crop.dialog';
import { Event } from '../../../../../models/event.model';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { User } from 'src/app/settings/users/user-models/user';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { TutorInfo } from 'src/app/models/previews/tutor-info.interface';
import { environment } from 'src/environments/environment';
import { UploadService } from 'src/app/shared/services/upload.service';
import { Observable, Subject } from 'rxjs';
import { cloneDeep, isNil } from 'lodash';

@Component({
  selector: 'app-new-event-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['../new-event-steps.scss', './event-info.component.scss']
})
export class NewEventEventInfoComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() public event$: Observable<Event>;
  public event: Event;
  @Output() setEventInfo = new EventEmitter();

  public formGroup: FormGroup;
  public users: Array<User> = [];
  public userName: string = '';
  private _selectedUser: any;
  public tutors: Array<TutorInfo> = [];
  public selectedTutors: Array<TutorInfo> = [];
  public hasEcommerceIntegration: boolean = environment.ecommerceIntegration;
  private _unsubscribeAll = new Subject<any>();


  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _usersService: SettingsUsersService,
    private _uploadService: UploadService,

  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(eventItems => {
        this.event = cloneDeep(eventItems);
        if (this.formGroup) {
          this.updateComponent(eventItems);
        } else {
          this.formGroup = this._createFormGroup(eventItems);
        }

        if (eventItems.tutors) {
          this.selectedTutors = eventItems.tutors;
        }
      });
    }
  }

  public updateComponent(event: Event) {

    this.userName = event.instructor ? event.instructor : '';
    this._selectedUser = event.instructorId ? { id: event.instructorId } : null;

    this.formGroup.patchValue({
      'title': event ? event.title : '',

      'storeUrl': event ? event.storeUrl : '',

      'createInEcommerce': event ? event.createInEcommerce : false,

      'forceProblemStatement': event ? event.forceProblemStatement : false,

      'ecommerceId': event && event.ecommerceId ? event.ecommerceId : null,

      'excerpt': event ? event.excerpt : '',

      'instructorMiniBio': event ? event.instructorMiniBio : '',

      'imageUrl': event && event.imageUrl ? event.imageUrl : './assets/img/240x240-placeholder.png',

      'instructorImageUrl': event && event.instructorImageUrl ? event.instructorImageUrl : './assets/img/240x240-placeholder.png',

      'tags': event && event.tags ? event.tags.map(tag => (tag)) : []
    });
  }

  public uploadImage(imageWidth: number, imageHeight: number, controlName: string) {
    const dialogRef = this._dialog.open(ImageCropDialogComponent, {
      width: window.innerWidth < 768 ? '100vw' : '50vw',
      data: {
        'croppedWidth': imageWidth,
        'croppedHeight': imageHeight
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.image) {
        this._uploadImage(
          result.image,
          this.formGroup.get(controlName),
          result.file.name,
        );
      }
    });
  }

  public addTag(event): void {
    const tags = event.target.value;
    if (tags.length !== 0) {
      const tagsArr = tags.split(',');
      tagsArr.forEach(tag => {
        if (tag.trim() !== '') {
          if (tag.length > 30) {
            this.notify('As TAGs devem ter no máximo 30 caracteres');
          } else {
            const tagsArray = this.formGroup.get('tags') as FormArray;
            tagsArray.push(
              new FormControl(tag)
            );
            event.target.value = '';
          }
        }
      });
    }
  }

  public removeTag(index: number): void {
    const tagsArray = this.formGroup.get('tags') as FormArray;
    tagsArray.removeAt(index);
  }

  public nextStep(): void {
    if (this.formGroup.valid) {
        const eventInfo = this.formGroup.getRawValue();
        if (this.selectedTutors.length > 0)
          eventInfo.tutorsIds = this.selectedTutors.map(x => x.id);
        this.setEventInfo.emit(cloneDeep(eventInfo));
    } else {
      this.notify('Por favor, preencha todos os campos obrigatórios');
    }
  }

  public setEcommerceId(id?: number): void {
    this.formGroup.get('ecommerceId').setValue(id);
  }

  private _createFormGroup(event: Event): FormGroup {
    this.userName = event.instructor ? event.instructor : '';
    this._selectedUser = event.instructorId ? { id: event.instructorId } : null;
    return new FormGroup({
      'title': new FormControl(
        event ? event.title : '', [Validators.required]
      ),
      'storeUrl': new FormControl(
        event ? event.storeUrl : ''
      ),
      'createInEcommerce': new FormControl(
        event ? event.createInEcommerce : false
      ),
      'forceProblemStatement': new FormControl(
        event ? event.forceProblemStatement : false
      ),
      'ecommerceId': new FormControl(
        event && event.ecommerceId ? event.ecommerceId : null
      ),
      'excerpt': new FormControl(
        event ? event.excerpt : '', [Validators.required]
      ),
      'instructorMiniBio': new FormControl(
        event ? event.instructorMiniBio : ''
      ),
      'imageUrl': new FormControl(
        event && event.imageUrl ? event.imageUrl : './assets/img/240x240-placeholder.png', [Validators.required]
      ),
      'instructorImageUrl': new FormControl(
        event && event.instructorImageUrl ? event.instructorImageUrl : './assets/img/240x240-placeholder.png', [Validators.required]
      ),
      'tags': new FormArray(
        event && event.tags ? event.tags.map(tag => new FormControl(tag)) : []
      )
    });
  }

  private _uploadImage(base64Image: string, formControl: AbstractControl, fileName: string) {
    this._uploadService.uploadFile(base64Image, fileName).subscribe(uploadedImageURL => formControl.setValue(uploadedImageURL)
      , () => {
        this.notify('Ocorreu um erro ao enviar a imagem, por favor tente novamente mais tarde');
      });
  }

  public triggerUserSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this._loadUsers(searchValue);

      this._selectedUser = { 'name': searchValue };
      this.userName = searchValue;
    }
  }

  private _loadUsers(searchValue: string = ''): void {
    this._usersService.getProfessors(searchValue).subscribe(response => {
      this.users = response.data;
    });
  }

  public addUser(user: User) {
    this.event.instructorId = user.id;
    if (user.imageUrl)
      this.formGroup.get('instructorImageUrl').setValue(user.imageUrl);

    this._selectedUser = user;
    this.userName = user.name;
    this.users = [];
  }

  private _loadTutors(searchValue: string = ''): void {
    this._usersService.getProfessors(searchValue).subscribe(response => {
      this.tutors = response.data;
    });
  }

  public triggerTutorSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '')
      this._loadTutors(searchValue);
  }

  public resetTutorSearch(): void {
    this.tutors = [];
  }

  public addTutor(user: TutorInfo) {
    const tutorExists = this.selectedTutors.find(u => u.id === user.id);
    if (!tutorExists)
      this.selectedTutors.push(user);
    this.resetTutorSearch();
  }

  public removeSelectedTutor(user: TutorInfo) {
    const index = this.selectedTutors.findIndex(x => x.id === user.id);
    this.selectedTutors.splice(index, 1);
  }
}
