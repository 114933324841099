import { Component, EventEmitter, Output } from '@angular/core';
import { Program } from '../../programs/Models/program.model';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {

  @Output() setCreatedSince: EventEmitter<Date> = new EventEmitter();
  @Output() setUserTermFilter: EventEmitter<string> = new EventEmitter();
  @Output() setProgramsFilter: EventEmitter<string[]> = new EventEmitter();
  public createdSince: Date;
  constructor() { }



  public triggerSearch(searchValue: string) {
    this.setUserTermFilter.emit(searchValue);
  }

  public maxDateFilter(currentDate: Date): boolean {
    const today = new Date();
    return currentDate <= today;
  }

  public handleSelectionItemChange(event: Program[]) {
    this.setProgramsFilter.emit(event.map(value => value.id));
  }
}
