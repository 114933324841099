<mat-dialog-content >
  <div class="wrapper">
    <div id="htmlEditor"></div>
    <mat-radio-group>
      <mat-radio-button  name="answer-description" [value]="answer?.correctAnswer" [checked]="answer?.correctAnswer"
        *ngFor="let answer of answers; trackBy:trackById">
        <div class="description-wrapper">
          <div class="description"> {{answer?.description}} </div>
          <div class="percentage">({{answer?.totalTimesSelected}} / {{(answer?.totalTimesSelected / totalAnswered * 100).toFixed(2)}}%)</div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button class="btn-test" (click)="dismiss()">Fechar</button>
</mat-dialog-actions>
