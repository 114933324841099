<header>
  <h2 mat-dialog-title>ALUNOS</h2>

  <mat-form-field>
    <input type="text" matInput placeholder="Filtre por um nome de aluno" (keyup)="updateSearch($event.target.value)" />
  </mat-form-field>
</header>

<mat-dialog-content>
  <ngx-datatable #Table class="material fullscreen" [rows]="data" trackByProp="id" minWidth="20"
    [rowIdentity]="rowIdentity" [messages]="messages" [selectionType]="SelectionType.checkbox" [headerHeight]="50"
    [selectAllRowsOnPage]="false" [selected]="selectedRows" [loadingIndicator]="loader" [footerHeight]="50"
    [rowHeight]="rowHeight" [columnMode]="ColumnMode.force" [scrollbarV]="true">

    <!-- Row Detail Template -->
    <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow (toggle)="onDetailToggle($event)">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div class="details">

          <div class="detail">
            <label>Businnes group</label>
            <span>{{row.businessGroup || '-'}}</span>
          </div>
          <div class="detail">
            <label>Businnes unit</label>
            <span>{{row.businessUnit || '-'}}</span>
          </div>
          <div class="detail">
            <label>Segment</label>
            <span>{{row.segment || '-'}}</span>
          </div>

          <div class="detail">
            <label>Nota do BQD </label>
            <span>{{row.bdqGrade || '-'}}</span>
          </div>
          <div class="detail">
            <label>Nota da avaliação diagnóstica</label>
            <span>{{row.beforeModuleGrade || '-'}}</span>
          </div>
          <div class="detail">
            <label>Nota da avaliação final </label>
            <span>{{row.afterModuleGrade || '-'}}</span>
          </div>

          <div class="detail">
            <label>Questões BDQ</label>
            <span>existentes {{row.totalModuleQuestion || '-'}} | {{row.totalQuestionAnswered || '0'}}
              respondidas</span>
          </div>

          <div class="detail">
            <label>Percentual das respostas</label>
            <span>corretas {{row.percentageOfCorrectAnswers}}% | {{row.percentageOfWrongAnswers}}% incorretas</span>
          </div>

          <div class="detail">
            <label>Questões nível-1</label>
            <span>corretas {{row.answerBeginnerReport.answer.correct}} | {{row.answerBeginnerReport.answer.wrong}}
              incorretas</span>
          </div>
          <div class="detail">
            <label>Questões nível-2</label>
            <span>corretas {{row.answerIntermediateReport.answer.correct}} |
              {{row.answerIntermediateReport.answer.wrong}} incorretas</span>
          </div>
          <div class="detail">
            <label>Questões nível-3</label>
            <span>corretas {{row.answerAdvancedReport.answer.correct}} | {{row.answerAdvancedReport.answer.wrong}}
              incorretas</span>
          </div>
          <div class="detail">
            <label>Conteúdos</label>
            <span>existentes {{row.totalModuleContent || '-'}} | {{row.totalContentCompleted || '0'}}
              visualizados</span>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <!-- Column Templates -->

    <ngx-datatable-column [width]="45" [resizeable]="false" [sortable]="false" [draggable]="false"
      [canAutoResize]="false">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <a href="javascript:void(0)" class="detail-button" [class.datatable-icon-right]="!expanded"
          [class.datatable-icon-down]="expanded" title="Ver detalhes" (click)="toggleExpandRow(row)">
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="35 " [frozenLeft]="true" [sortable]="false" [canAutoResize]="false"
      [draggable]="false" [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>

    <ngx-datatable-column *ngFor="let column of normalColumns" [prop]="column.prop" [name]="column.name"
      [width]="column.width? column.width : 100" [frozenLeft]="column.frozenLeft">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ column?.prop.toString() === 'level'? this.getLevelName(row) : value }}
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</mat-dialog-content>

<mat-dialog-actions class="footer">
  <button class="btn-outline"
    (click)="exportData()">{{selectedRows?.length? 'Exportar selecionados' : 'Exportar'}}</button>
  <button class="btn-test" (click)="dismiss()">Fechar</button>
</mat-dialog-actions>
