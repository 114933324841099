import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../../shared/classes/notification';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Module } from 'src/app/models/module.model';
import { SettingsModulesDraftsService } from 'src/app/settings/_services/modules-drafts.service';
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { mandatoryCollumnEnum } from './model/mandatoryCollums.interface';
import { isEmpty, isNil } from 'lodash';
import { Answer, ConceptAnswer, Question } from 'src/app/models/question.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-qbd-dialog',
  templateUrl: './upload-qdb.dialog.html',
  styleUrls: ['./upload-qdb.dialog.scss'],
})
export class UploadQuestionDatabaseDialogComponent extends NotificationClass {
  public module: Module;
  public addQuestions = true;
  public showCloseButton = true;
  public questions: Question[] = [];
  public loading: boolean = false;
  public erroList;
  private readonly _rightAnsware = '2';
  private readonly _enunciated = 'enunciado';
  public readonly collums = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  public hasComment: boolean = environment.features.questionComment;
  constructor(
    protected _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<UploadQuestionDatabaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { module: Module },
    private _draftsService: SettingsModulesDraftsService,
    public _sharedService: SharedService,
    private _router: Router,
    private _excelService: ExcelService
  ) {
    super(_snackBar);
    this.module = this.data.module;
  }
  public normalizeString = (value) => (isNil(value) ? null : this._excelService.normalizeString(value));

  public dismiss(): void {
    this._dialogRef.close();
  }

  public save(): void {
    this._dialogRef.close({
      questions: this.questions,
      addQuestions: this.addQuestions,
    });
  }
  public openFileUpload(): void {
    const input = document.getElementById('qdbInputFile') as HTMLInputElement;
    input.value = '';
    input.click();
  }

  public setDocumentFile(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
        this.notify('Tipo de arquivo inválido. Apenas "xls", "xlsx" e "csv" são permitidos.');
        return;
      }

      this.loading = true;
      this._readExcel(file);
    }
  }

  private _readExcel(file) {
    const callback = this._importQuestion.bind(this);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let binary = '';
      const bytes = new Uint8Array(this.result as any);

      for (let i = 0; i < bytes.byteLength; i++) binary += String.fromCharCode(bytes[i]);

      callback(binary);
    };
    reader.readAsArrayBuffer(file);
  }

  private _importQuestion(file: string) {
    const mandatoryCollumns = [
      mandatoryCollumnEnum.id,
      mandatoryCollumnEnum.type,
      mandatoryCollumnEnum.text,
      mandatoryCollumnEnum.answareOrWeight,
      mandatoryCollumnEnum.concepts,
      mandatoryCollumnEnum.subjectId,
      mandatoryCollumnEnum.time
    ];

    if (this.hasComment) {
      mandatoryCollumns.push(mandatoryCollumnEnum.comment);
    }

    this._excelService._getExcelContentAsJson(file, mandatoryCollumns).subscribe(
      (response) => {
        this._setQuestions(response.contentJson, response.headers);
      },
      (error) => {
        this.erroList = [{ description: error }];
        this.loading = false;
      }
    );
  }

  private _setQuestions(questions, headers) {
    this.erroList = [];
    const getCollum = (header: string) => {
      const collumn = headers.find((h) => h.headear === header).collumn;
      return isNil(collumn) ? collumn : this.collums[collumn];
    };

    const nullOrEmptyValidation = (question) => {
      Object.keys(question).forEach((key) => {
        /* O campo que corresponde ao mandatoryCollumnEnum.concepts precisa de uma validação especial
        que é feita na hora da associação dos conceitos
        */
        if (
          (key !== mandatoryCollumnEnum.concepts && isNil(question[key]) )
        )
          this.erroList.push({
            collum: getCollum(key),
            row: question['row'],
            description: 'Este campo não pode ser nulo',
          });
      });
    };

    const splitConcepts = (data) => {
      if (isNil(data)) return [];
      return data.split(',').map((c) => c.trim());
    };

    const getLevels = (quest: string) => {
      const key = this.normalizeString(quest[mandatoryCollumnEnum.answareOrWeight]);
      switch (key) {
        case '1':
          return 0;
          break;
        case '2':
          return 1;
          break;
        case '3':
          return 2;
        case 'facil':
          return 0;
          break;
        case 'medio':
          return 1;
          break;
        case 'dificil':
          return 2;
          break;
        default:
          this.erroList.push({
            collum: getCollum(mandatoryCollumnEnum.answareOrWeight),
            row: quest['row'],
            description: `${key} não é um nível válido`,
          });
          return -1;
          break;
      }
    };

    const isRepeatedId = (q: any) => {
      const isRepeated = q.filter((quest) => this.normalizeString(quest[mandatoryCollumnEnum.type]) === this._enunciated);

      return isRepeated.length > 1;
    };

    let index = 0;
    while (index < questions.length) {
      const _id = questions[index][mandatoryCollumnEnum.id];
      if (isNil(_id)) {
        index++;
        continue;
      }
      const questionFiltered = questions.filter((quest) => quest[mandatoryCollumnEnum.id] === _id);
      if (isRepeatedId(questionFiltered)) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.id),
          description: `Cada questão deve possuir o indentificado único. O ID ${_id} está repetido `,
        });
        index = index + questionFiltered.length;
        continue;
      }

      const rightAnsware = questionFiltered.find((quest) => (
          !isNil(quest[mandatoryCollumnEnum.answareOrWeight]) &&
          this.normalizeString(quest[mandatoryCollumnEnum.answareOrWeight]) === this._rightAnsware &&
          this.normalizeString(quest[mandatoryCollumnEnum.type]) !== this._enunciated
        ));
      let concept = [];
      let subjectId, level;
      let time = 0;

      if (isNil(rightAnsware)) {
        this.erroList.push({
          description: `A questão com ID de Nº ${_id} não possui uma resposta certa`,
        });
      } else if (isNil(rightAnsware[mandatoryCollumnEnum.concepts])) {
        this.erroList.push({
          collum: getCollum(mandatoryCollumnEnum.concepts),
          row: rightAnsware['row'],
          description: 'Essa alternativa necessita ter conceitos',
        });
      } else {
        concept = splitConcepts(rightAnsware[mandatoryCollumnEnum.concepts]);
      }

      let text = null;
      const awnsware = [];
      questionFiltered.forEach((quest) => {
        nullOrEmptyValidation(quest);
        if (this.normalizeString(quest[mandatoryCollumnEnum.type]) === this._enunciated) {
          text = quest[mandatoryCollumnEnum.text];
          level = getLevels(quest);
        } else if (quest[mandatoryCollumnEnum.type].match(/\s*r+\d+\s*/gi)) {
          const answareConcepts = concept.map((c) => new ConceptAnswer(c));
          const rightConcepts = splitConcepts(quest[mandatoryCollumnEnum.concepts]);
          const resposta = new Answer(undefined, concept);
          resposta.description = quest[mandatoryCollumnEnum.text];
          if (
            !isNil(quest[mandatoryCollumnEnum.answareOrWeight]) &&
            this.normalizeString(quest[mandatoryCollumnEnum.answareOrWeight]) === this._rightAnsware
          ) {
            resposta.points = 2;
            resposta.isRight = true;
          } else if (
            isNaN(Number(quest[mandatoryCollumnEnum.answareOrWeight])) ||
            Number(quest[mandatoryCollumnEnum.answareOrWeight]) < -1 ||
            Number(quest[mandatoryCollumnEnum.answareOrWeight] > 2)
          ) {
            this.erroList.push({
              collum: getCollum(mandatoryCollumnEnum.answareOrWeight),
              row: quest['row'],
              description: `${quest[mandatoryCollumnEnum.answareOrWeight]} não é uma pontuação válida`,
            });
          } else {
            resposta.points = Number(quest[mandatoryCollumnEnum.answareOrWeight]);
            resposta.isRight = false;
          }

          resposta.concepts = answareConcepts.map((concepts) => {
            concepts.isRight = rightConcepts.some((rightconcept) => rightconcept === concepts.concept);
            return concepts;
          });

          const wrong = resposta.concepts.filter((c) => !c.isRight).length;
          if (wrong === concept.length && concept.length > 0 && resposta.points !== -1) {
            this.erroList.push({
              description: 'Uma alternativa com todos conceitos errado deve pontuar com -1',
              row: quest.row,
            });
          } else if (wrong > 0 && (resposta.points < 0 || resposta.points > 1) && wrong < concept.length) {
            this.erroList.push({
              description: 'Uma alternativa parcialmente correta não pode pontuar com -1',
              row: quest.row,
            });
          }
          awnsware.push(resposta);
        }
      });

      const rowSubject = questionFiltered.find((quest) => !isNil(quest[mandatoryCollumnEnum.subjectId]));
      const rowTime = questionFiltered.find((quest) => !isNil(quest[mandatoryCollumnEnum.subjectId]));

      if (!isNil(rowSubject)) subjectId = rowSubject[mandatoryCollumnEnum.subjectId];
      if (!isNil(rowTime)) {
        if (isNaN(Number(rowTime[mandatoryCollumnEnum.time]))) {
          this.erroList.push({
            collum: getCollum(mandatoryCollumnEnum.time),
            description: `${rowTime[mandatoryCollumnEnum.time]} não é um tempo válido`,
          });
        } else {
          time = Number(rowTime[mandatoryCollumnEnum.time]);
        }
      }

      if (!awnsware || awnsware.length < 2) {
        this.erroList.push({
          description: 'Defina pelo menos duas alternativas corretas',
        });
      }

      const countRight = awnsware.filter((a) => a.concepts.length > 0 && a.concepts.every((c) => c.isRight));
      if (countRight.length > 1)
        this.erroList.push({
          description: `Questão de Nº ${_id} apenas uma alternativa pode ter todos os conceitos corretos`,
        });

      const moduleHasSubjectId = this.module.subjects.filter((subject) => subject.id === subjectId).length;
      if (moduleHasSubjectId === 0)
        this.erroList.push({
          description: `O ID ${subjectId} não corresponde a nenhum assunto no módulo`,
        });

      const question = new Question(subjectId, concept);
      question.answers = awnsware;
      question.duration = time * 60;
      question.level = level;
      question.moduleId = this.module.id;
      question.text = text;
      if (this.hasComment) {
        question.comment = questions[index][mandatoryCollumnEnum.comment];
      }

      this.questions.push(question);
      index = index + questionFiltered.length;
    }

    this.loading = false;
  }
}
