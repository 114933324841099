<div class="concepts">
  <p class="concept-title">COMUNICADO</p>
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="concept-box">
        <mat-form-field>
          <input matInput placeholder="Título" formControlName="title" required />
        </mat-form-field>
        <!-- <mat-form-field>
          <textarea matInput placeholder="Texto" formControlName="text" required></textarea>
        </mat-form-field> -->
        <label>Conteúdo da comunicação:</label>
        <div id="htmlEditor"></div>
        <mat-form-field class="noBorder" floatLabel="never">
          <input matInput formControlName="startDate" [matDatepicker]="startDate" placeholder="Data de inicio"
            required />
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="noBorder" floatLabel="never">
          <input matInput formControlName="endDate" [matDatepicker]="endDate" placeholder="Data de término" required />
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </ng-container>
</div>
<div class="footer">
  <p class="dismiss" (click)="save()">
    salvar
  </p>
  <p class="dismiss bar" (click)="dismiss()">
    fechar
  </p>
</div>
