<div class="inner-content">
  <h2>CATÁLOGO DE CURSOS</h2>
  <!--
  <app-e-commerce-search
    *ngIf="hasContent()"
  ></app-e-commerce-search> -->

  <!-- <ng-container *ngIf="tracks && tracks.length > 0" >
    <div class="path" >
      <p>Recomendados Para Você</p>
    </div>

    <div class="suggested" >
      <app-track-card
        *ngFor="let track of suggestedTracks"
        [track]="track"
        [progress]="trackProgress[track.id]"
        [showRecommended]="true"
        [showText]="true"
        [showModule]="true"
        [showEvents]="true"
        [hours]="true"
        [showContent]="true"
        [showButtonKnowMore]="true"
        [showPrice]="true"
        [showButtonSubscribe]="true"
        [redirectWhenRecommended]="false"
      ></app-track-card>
    </div>
  </ng-container> -->

  <ng-container *ngIf="tracks && tracks.length > 0">
    <div class="path">
      <p>{{replacement.tracks.plural}} Disponíveis</p>
      <span (click)="changeViewTypeTracks()"><i class="icon visao-icon icon-visao_lista"></i></span>
    </div>
    <div class="sub-path">
      <p>{{replacement.tracks.plural}} de cursos e eventos para uma formação profissional completa em diferentes áreas.
      </p>
    </div>

    <div class="suggested" [ngClass]="{ 'list': viewTypeTracks === 'list' }">
      <app-track-card *ngFor="let track of tracks" [track]="track" [progress]="trackProgress[track.id]"
        [viewType]="viewTypeTracks" [showRecommended]="true" [showText]="true" [showModule]="true" [showEvents]="true"
        [hours]="true" [showContent]="true" [showButtonKnowMore]="true" [showPrice]="true" [showButtonSubscribe]="false"
        [redirectWhenRecommended]="false"></app-track-card>
    </div>
  </ng-container>

  <ng-container *ngIf="allModules && allModules.length > 0">
    <div class="all-modules">
      <p>Cursos Online Disponíveis</p>
      <span (click)="changeViewTypeModules()"><i class="icon visao-icon icon-visao_lista"></i></span>
    </div>
    <div class="sub-path">
      <p>Cursos online com conteúdos de diversos domínios do conhecimento, preparados por especialistas de cada área e
        acompanhados por tutores acadêmicos.</p>
    </div>

    <div class="suggested" [ngClass]="{ 'list': viewTypeModules === 'list' }">
      <app-module-card *ngFor="let module of allModules" [module]="module" [viewType]="viewTypeModules"
        [progress]="moduleProgress[module.id]" [levels]="levelDict" [showRecommended]="true" [showText]="true"
        [showInstructor]="true" [showContent]="true" [hours]="true" [showButtonKnowMore]="true" [showPrice]="true"
        [showButtonSubscribe]="false" [redirectWhenRecommended]="false"></app-module-card>
    </div>
  </ng-container>
  <!--
  <ng-container *ngIf="tracks && tracks.length > 0" >
    <div class="path" >
      <p>Eventos Disponíveis</p>
    </div>
    <div class="sub-path">
      <p>Eventos síncronos, presenciais ou remotos, intermediados por instrutores acadêmicos.</p>
    </div>
  </ng-container> -->


</div>
