<div class="inner-content">

  <app-header [subTitle]="subTitle" [title]="title"></app-header>

  <div class="content">
    <app-module-tracks-table *ngIf="items?.trackItems?.length " [tracks]="items"></app-module-tracks-table>
    <app-events-average-student-grades [data]="items?.eventsConfiguration" *ngIf="items?.eventsConfiguration?.length">
    </app-events-average-student-grades>
  </div>
</div>
