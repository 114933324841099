<div class="inner-content">
  <h2>TRILHAS</h2>

  <div class="buttonsAreaAndSubArea">
    <button class="btn-outline btn" (click)="gotoTrackAreas()">
      ÁREAS
    </button>

    <button class="btn-outline btn" (click)="gotoTrackSubAreas()">
      SUB-ÁREAS
    </button>
  </div>

  <div class="header" >
    <div class="search" >
      <i class="logo icon-lupa search-icon"></i>
      <input type="text" placeholder="Digite o nome da trilha ou TAG" (keyup)="updateSearch($event.target.value)" />
    </div>
    <div class="actions">
      <button class="btn-test" (click)="createNewTrack()">
        NOVA TRILHA
      </button>
    </div>
  </div>

  <div class="tracks">
    <app-settings-track-card *ngFor="let track of tracks" [track]="track" (editTrack)="editTrack($event)"
      (deleteTrack)="deleteTrack($event)" (cloneTrack)="clone($event)"></app-settings-track-card>
  </div>

  <app-pagination *ngIf="tracksCount" [setPagination]="{
      'itemsCount': tracksCount,
      'pageSize': 20
    }" (goToPage)="goToPage($event)"></app-pagination>
</div>
