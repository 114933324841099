import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { IstudentsRanking } from '../models/trackStudentsSubordinates';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogRankingComponent } from './dialog-ranking/dialog-ranking.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { CockpitService } from '../../_services/cockpit.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cockpit-ranking',
  templateUrl: './cockpit-ranking.component.html',
  styleUrls: ['./cockpit-ranking.component.scss']
})
export class CockpitRankingComponent {
  @ViewChild('templateFilters', { static: true })
  templateFilters: TemplateRef<any>;

  @ViewChild('templateTitle', { static: true })
  templateTitle: TemplateRef<any>;

  @ViewChild('templateTitleTrack', { static: true })
  templateTitleTrack: TemplateRef<any>;

  @Input() ranking: Array<IstudentsRanking>;

  public rankingSubject = new BehaviorSubject<Array<IstudentsRanking>>([]);
  public replacement = environment.replecement;
  constructor(private _dialog: MatDialog,
    private _cockpitService: CockpitService,
    private _excelService: ExcelService
  ) { }



  public openDialogRanking(): void {
    // Atualizando o observable para que o dialog detect a mudança
    setTimeout(() => {
      this.rankingSubject.next(this.ranking);
    }, 0);

    this._dialog.open(DialogRankingComponent, {
      width: '1200px',
      maxHeight: '90vh',
      height: '673px',
      data: {
        ranking: this.rankingSubject,
        titleTemplate: this.templateTitleTrack,
        showFactor: false
      }
    });
  }

  public openCustomDialogRanking(): void {
    this._dialog.open(DialogRankingComponent, {
      width: '1200px',
      maxHeight: '90vh',
      height: '673px',
      data: {
        ranking: this.rankingSubject,
        filterTemplate: this.templateFilters,
        titleTemplate: this.templateTitle,
        showFactor: true
      }
    });
  }

  public closeDialog() {
    this._dialog.closeAll();
  }

  handleCustomRankingChange($event: Array<IstudentsRanking>) {
    this.rankingSubject.next($event);
  }

  public exportRanking() {
    const studentsRaking = this.rankingSubject.getValue();
    const rankingExcel = [];
    const title = this._cockpitService.getTitle().getValue();
    studentsRaking.forEach((stdRanking, i) => {
      const student = {
        'Trilha/Módulo': title,
        'Nome': stdRanking.name,
        'Email': stdRanking.email,
        'Ranking': i + 1,
        'Prova': stdRanking.valuation,
        'Esforço': stdRanking.interactionUntilDay,
        'Engajamento': stdRanking.progressUntilDay,
        'Participação': stdRanking.participation,
        'Aprendizagem': stdRanking.learning,
        'Evento': stdRanking.averageEventGrade
      };
      rankingExcel.push(student);
    });

    this._excelService.exportAsExcelFile(rankingExcel, `Ranking_personalizado_${title}`);
  }
}
