<div class="opa-background">
    <div class="card">
        <div class="top">
            <img src="./assets/img/right-answer-colored.png"/>
            <p>Atividade concluída com sucesso!</p>
        </div>
        <div class="center">
            <p>
                Sua contribuição será avaliada pelo instrutor e 
                contará na sua nota final deste módulo
            </p>
        </div>
        <div class="bottom">
            <button class="btn-test" (click)="dialogRef.close()" >
                Ok
            </button>
        </div>
    </div>
</div>