import { Component, Input, OnInit, Output, EventEmitter, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Content, TextReference } from '../../../models/content.model';
import { TextMarker } from './marker.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { EffortService } from 'src/app/shared/services/effort.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-content-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextContentComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() readonly resumedView?: boolean = false;
  private _content: Content;
  @Input()
  public get content(): Content {
    return this._content;
  }
  public set content(value: Content) {
    this._content = value;
    this._finishedText = this.content.watched || false;
    setTimeout(() => {
      this._scrollChange$.next(this._htmlContent.clientHeight);
    }, 500);

  }
  @Output() saveTextFinishedAction: EventEmitter<string> = new EventEmitter();

  public markers: Array<TextMarker> = [];
  private _scrollChange$ = new Subject<number>();
  private _finishedText: boolean = false;
  private get _htmlContent() {
    return (this._host.nativeElement as HTMLElement).querySelector('.htmlContent');
  }


  listenerFn: () => void;
  constructor(
    private _sharedService: SharedService,
    private _effortService: EffortService,
    private _host: ElementRef,
    el: ElementRef) {
    this.listenerFn = _effortService.listenEvent(el.nativeElement, 'mousemove');

    this._sharedService.forumQuestion.subscribe(() => {
      this._sharedService.forumQuestionResponse.next('-');
    });
  }
  ngOnDestroy(): void {
    this._effortService.finishInteraction();
    if (this.listenerFn) this.listenerFn();
  }

  ngOnInit() {
    if (this.content && this.content.concepts)
      this.content.concepts.forEach((concept: TextReference) => {
        if (concept.anchors) {
          // eslint-disable-next-line prefer-spread
          this.markers.push.apply(
            this.markers,
            concept.anchors.map((anchor: string) => new TextMarker(concept.name, anchor))
          );
        }
      });
  }

  ngAfterViewInit(): void {
    this._scrollChange$.subscribe(value => {
      this.hasFinished(value);
    });
  }

  public hasFinished(scrolled): void {
    if (this.content && this._htmlContent.scrollHeight === scrolled && !this._finishedText) {
      this._finishedText = true;
      this.saveTextFinishedAction.emit(this.content.id);
    }
  }

  public goToAnchor(anchor: string): void {
    const anchorElement: any = document.querySelector('a[href=\'' + anchor + '\']');
    if (anchorElement != null)
      this._htmlContent.scrollTop = anchorElement.offsetTop - 50;
  }

  public onScroll(event) {
    if (!this._finishedText) {
      const scrolled = Math.ceil(
        event.target.offsetHeight +
        event.target.scrollTop
      );

      this._scrollChange$.next(scrolled);
      // if (scrolled >= event.target.scrollHeight) {
      //   this._finishedText = true;
      //   this.saveTextFinishedAction.emit(this.content.id);
      // }
    }
  }

}
