<div class="module" (click)="goToEvent()" [style.backgroundImage]="'url('+ event.imageUrl +')'" [ngClass]="{ 'last': isLast }">

  <p class="subscription">
    <small>Inscrições Terminam em</small><br>
    <span>{{ getSubscriptionDueDays() }}</span>
  </p>

  <p class="title">
    {{ event.title }}<br>
    <small>{{ event.instructor }}</small>
  </p>

  <div class="date">
    <p class="course-date">
      <small>Data do curso</small><br>
      {{ event.nextSchedule ? (event.nextSchedule.eventDate | date : 'dd/MM/yyyy') : '--' }}
    </p>
    <p *ngIf="event.requirements.length > 0">
      <span [ngClass]="{ 'completed': getRemainingRequirements() === event.requirements.length }">
        {{ getRemainingRequirements() }} de {{ event.requirements.length }}
      </span> requisitos
    </p>
  </div>
  <div class="bg-shadow"></div>
</div>
