<div class="participation">
  <p class="title">
    PARTICIPAÇÃO EM EVENTOS
  </p>
  <p class="no-event" *ngIf="events && events.length === 0">
    Não há dados para exibir.
  </p>
  <table *ngIf="events && events.length > 0">
    <thead>
      <tr>
        <th>Prova/Evento</th>
        <th width="1px">Data de realização</th>
        <th width="195px">Status</th>
        <th width="1px">Aproveitamento</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let event of events">
        <td> {{ event.eventName }} </td>
        <td> {{ !event.date ? '--' : event.date | date : 'dd/MM/yyyy'}} </td>
        <td>
          <p [ngClass]="{ 'finished': event.presence === true, 'no-show': event.presence === false }">
            {{ getEventStatus(event) }}
          </p>
        </td>
        <td class="align-right"> {{ event.presence && event.finalGrade?.toFixed(2) || '--' }} </td>
      </tr>
    </tbody>
  </table>
</div>
dd/MM/yyyy