<div class="main">
  <div class="header">
    <h3>Vincular ao plano de estudo</h3>
  </div>
  <div class="search">
    <div class="input-container">
      <i class="icon icon-lupa lupa-home"></i>
      <input id="SearchDisplay" type="text" [placeholder]="inputPlacedolher" (keyup)="doSearch($event.target.value)"
        (keydown)="$event.stopPropagation()" />
    </div>
  </div>
  <div class="content">
    <ul *ngIf="results.length > 0">
      <li class="content-wrapper" *ngFor="let item of results">
        <div class="content-sub-wrapper">
          <div>{{(item.title || item.name) | uppercase}}</div>
          <div *ngIf="item.type" class="type">
            {{item.type == EnumStudyPlanItem.module ? 'MÓDULO' : 'TRILHA'}}</div>
          <div *ngIf="item.email" class="type user-type">{{item.email | uppercase}}</div>
        </div>
        <button class="btn-test" [disabled]="item.id | contains:data.notIn" (click)="selectItem(item)"> Vincular
        </button>
      </li>
    </ul>
  </div>
</div>
