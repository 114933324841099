<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <div class="header">
    <h3>Setores</h3>
    <button class="btn-test" (click)="goTo()">
      NOVO SETOR
    </button>
  </div>
  <div class="main">
    <div class="content-wrapper" *ngFor="let sector of sectors">
      <div class="content-title">
        <p class="title">{{sector.name}}</p>
        <p class="subTitle"><strong>{{sector.segmentsCount}}</strong> segmentos associados</p>
      </div>
      <div class="action">
        <img class="icon" src="./assets/img/edit.png" (click)="goTo(sector)" title="Editar turmas">
        <img class="icon" src="./assets/img/trash.png" (click)="delete(sector)" title="Excluir turmas">
      </div>
    </div>
  </div>
  <app-pagination [hidden]="itemsCount === 0" [setPagination]="{
    'itemsCount': itemsCount,
    'pageSize': pageSize
  }" (goToPage)="goToPage($event)"></app-pagination>
</div>
