import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TrackPreview } from '../../../models/previews/track.interface';
import { UserProgress } from 'src/app/models/subject.model';
import { UserService } from 'src/app/pages/_services/user.service';
import { UtilService } from '../../services/util.service';
import { AuthService } from '../../services/auth.service';
import { Recommendations } from 'src/app/settings/users/user-models/user-track';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../classes/notification';
import { TrackHeightChangeEnum } from 'src/app/models/enums/track-height-change.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ERole } from 'src/app/models/enums/role.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-track-card',
  templateUrl: './track-card.component.html',
  styleUrls: ['./track-card.component.scss']
})
export class TrackCardComponent extends NotificationClass implements OnChanges {

  @Input() track: TrackPreview;
  @Input() progress: {
    level: number
    modulesCompleted: []
    progress: number
    startedAnyModule: boolean
    exemptInAllModules: boolean
    trackId: string
  };
  @Input() viewType?: string = 'cards';
  @Input() showModule: boolean = false;
  @Input() showEvents: boolean = false;
  @Input() showRecommended: boolean = false;
  @Input() showText: boolean = false;
  @Input() showContent: boolean = false;
  @Input() hours: boolean = false;
  @Input() showPrice: boolean = false;
  @Input() showButtonSubscribe: boolean = false;
  @Input() redirectWhenRecommended: boolean = true;
  private _showButtonKnowMore: boolean;

  public hasFeatureKnowMore = environment.features.knowMore;

  @Input()
  public get showButtonKnowMore(): boolean {
    const hasValidParameters = !(this.track.ecommerceProducts === null ||
      !this.track.ecommerceProducts.length ||
      this.track.ecommerceProducts[0].linkProduct === null);

    return this._showButtonKnowMore && hasValidParameters;
  }
  public set showButtonKnowMore(showButtonKnowMore: boolean) { this._showButtonKnowMore = showButtonKnowMore; }

  public height: number = 170;

  constructor(
    private _router: Router,
    public userService: UserService,
    private _usersService: SettingsUsersService,
    protected _snackBar: MatSnackBar,
    private _utilService: UtilService,
    private _authService: AuthService
  ) {
    super(_snackBar);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.viewType === 'list') {
      this.height = 150;
    } else {
      this.height = 170;
      this.changeHeight();
    }
  }

  public getDuration(): string {
    return this._utilService.formatSecondsToHour(this.track.duration);
  }

  public goToTrack(event) {
    const isSeenHowManager = this._authService.isSeenHowManager();


    const createResponsibleTrackNavigation = track => {
      localStorage.setItem(LocalStorageService.key.trackResponsible, JSON.stringify(track));
      this._router.navigate(['configuracoes/trilha-de-curso/' + track.id]);
    };

    event.stopPropagation();
    if (!this.redirectWhenRecommended && this.track.recommended) {
      return false;
    }

    if (this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources)) {
      this._router.navigate(['configuracoes/trilha-de-curso/' + this.track.id]);
    } else if (isSeenHowManager) {
      createResponsibleTrackNavigation(this.track);
    } else if (this.track.subordinate || this.track.instructor) {
      createResponsibleTrackNavigation(this.track);
    } else {
      this._router.navigate(['trilha-de-curso/' + this.track.id]);
    }

  }

  public goToPageEcommerceUrl(event) {

    event.stopPropagation();

    if (this.track.ecommerceProducts === null) {
      this.notify('As matrículas não foram aberta');
    }
    if (this.track.ecommerceProducts[0].disableFreeTrial) {
      const recommendations: Array<Recommendations> = this._setRecommendations();
      this._usersService.updateUserRecommendations(
        recommendations
      ).subscribe(() => {
        this.track.recommended = true;
        this.notify('Inscrição feita com sucesso');
      }, () => this.notify('Ocorreu um erro ao tentar se matricular'));
    } else {
      window.open(this.track.ecommerceProducts[0].linkEcommerce, '_blank');
    }
  }

  private _setRecommendations(): Array<Recommendations> {
    const recommendations: Array<Recommendations> = [];
    const user = JSON.parse(localStorage.getItem(LocalStorageService.key.authData));
    const recommendation: Recommendations = {
      userId: user.user_id,
      tracks: [
        {
          id: this.track.id, name: this.track.title, level: 0, percentage: 0
        }],
      modules: [],
      events: []
    };
    recommendations.push(recommendation);

    return recommendations;
  }

  public goToPageStoreUrl(event) {

    event.stopPropagation();
    window.open(this.track.ecommerceProducts[0].linkProduct, '_blank');

  }

  public getExcerptContent(): string {
    if (!this.track) return '';

    if (this.track) {
      if (this.track.description.length > 130) {
        return this.track.description.substring(0, 130) + '...';
      } else {
        return this.track.description;
      }
    }
  }

  public changeHeight() {
    if (this.showText) {
      this.height += TrackHeightChangeEnum.showText;
    }
    if (this.showModule) {
      this.height += TrackHeightChangeEnum.showModule;
    }
    if (this.showEvents) {
      this.height += TrackHeightChangeEnum.showEvents;
    }
    if (this.showContent) {
      this.height += TrackHeightChangeEnum.showContent;
    }
    if (this.hours) {
      this.height += TrackHeightChangeEnum.hours;
    }
    if (this.showPrice) {
      this.height += TrackHeightChangeEnum.showPrice;
    }
    if (this.showButtonSubscribe) {
      this.height += TrackHeightChangeEnum.showButtonSubscribe;
    }
    this.height += TrackHeightChangeEnum.showButtonKnowMore;
  }
}
