import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Module } from '../../../../../models/module.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropDialogComponent } from '../../../../../shared/dialogs/image-crop/image-crop.dialog';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { UtilService } from '../../../../../shared/services/util.service';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { User } from 'src/app/settings/users/user-models/user';
import { TutorInfo } from 'src/app/models/previews/tutor-info.interface';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { isNil, uniqBy, isEmpty, cloneDeep } from 'lodash';
import { UploadService } from 'src/app/shared/services/upload.service';
import { takeUntil } from 'rxjs/operators';
import { CascateSelectComponent } from 'src/app/shared/components/cascate-select/cascate-select.component';
import { ECourseAvailability } from 'src/app/models/enums/course-availability.enum';
import { track } from 'logrocket';

@Component({
  selector: 'app-new-module-module-info',
  templateUrl: './module-info.component.html',
  styleUrls: ['../new-module-steps.scss', './module-info.component.scss']
})
export class NewModuleModuleInfoComponent extends NotificationClass implements OnInit, OnDestroy {

  @ViewChild('cascateSelect') cascateSelect: CascateSelectComponent;

  @Input() public module$: Observable<Module>;
  public module: Module;

  @Input() readonly showCertification: boolean = false;
  @Output() setModuleInfo = new EventEmitter();

  public formGroup: FormGroup;
  public instructors: Array<User> = [];
  public tutors: Array<TutorInfo> = [];
  public selectedTutors: Array<TutorInfo> = [];
  public selectedInstructors = [];
  public hasEcommerceIntegration: boolean = environment.ecommerceIntegration;
  private _unsubscribeAll = new Subject<any>();
  public practiceQuestionsEnabled = environment.features.practiceQuestions;
  public courseAvailabilityEnum = ECourseAvailability;
  private createInstructor = instructor => ({
    id: instructor.id, name: instructor.name
  });


  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _utilService: UtilService,
    private _usersService: SettingsUsersService,
    private _uploadService: UploadService
  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = cloneDeep(moduleItems);
        if (this.formGroup && moduleItems) {
          this.updateComponent(moduleItems);
        } else {
          this.formGroup = this._createFormGroup(moduleItems);
        }

        if (moduleItems.tutors) {
          this.selectedTutors = moduleItems.tutors;
        }

        const extraInstructors = (moduleItems.extraInstructors ? moduleItems.extraInstructors : []);

        if (moduleItems.instructor)
          this.selectedInstructors = [
            { id: moduleItems.instructorId, name: moduleItems.instructor }
          ];

        if (!isEmpty(extraInstructors))
          this.selectedInstructors.push(...extraInstructors.map(this.createInstructor));
      });
    }
  }

  public updateComponent(module: Module) {
    this.formGroup.patchValue(
      {
        'title': module ? module.title : '',
        'storeUrl': module ? module.storeUrl : '',
        'createInEcommerce': module && !isNil(module.createInEcommerce) ? module.createInEcommerce : false,
        'published': module ? module.published : true,
        'excerpt': module ? module.excerpt : '',
        'instructorMiniBio': module ? module.instructorMiniBio : '',
        'imageUrl': module && module.imageUrl ? module.imageUrl : './assets/img/420x210-placeholder.png',
        'instructorImageUrl': module && module.instructorImageUrl ? module.instructorImageUrl : './assets/img/240x240-placeholder.png',
        'tags': module && module.tags ? module.tags.map(tag => (tag)) : [],
        'validFor': module && module.validFor ? module.validFor : null,
        'duration': module && !isNil(module.duration) ? this._utilService.SecondToHours(module.duration) : '',
        'ecommerceProducts': module && !isNil(module.ecommerceProducts) ? module.ecommerceProducts : [],
        'questionCreationValuation': module && module.questionCreationValuation ? module.questionCreationValuation : false,
        'availability': module && module.availability ? module.availability : ECourseAvailability.available
      }
    );
  }

  public uploadImage(imageWidth: number, imageHeight: number, controlName: string) {
    const dialogRef = this._dialog.open(ImageCropDialogComponent, {
      width: window.innerWidth < 768 ? '100vw' : '50vw',
      data: {
        'croppedWidth': imageWidth,
        'croppedHeight': imageHeight
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.image) {
        this._uploadImage(
          result.image,
          this.formGroup.get(controlName),
          result.file.name
        );
      }
    });
  }

  public addTag(event): void {
    const tags = event.target.value;
    if (tags.length !== 0) {
      const tagsArr = tags.split(',');
      tagsArr.forEach(tag => {
        if (tag.trim() !== '') {
          if (tag.length > 30) {
            this.notify('As TAGs devem ter no máximo 30 caracteres');
          } else {
            const tagsArray = this.formGroup.get('tags') as FormArray;
            tagsArray.push(
              new FormControl(tag)
            );
            event.target.value = '';
          }
        }
      });
    }
  }


  public removeTag(index: number): void {
    const tagsArray = this.formGroup.get('tags') as FormArray;
    tagsArray.removeAt(index);
  }

  public nextStep() {
    this.save().subscribe((moduleDraft: Module) => {
      if (!isNil(moduleDraft)) {
        moduleDraft.duration = this._utilService.HourtoSecond(String(moduleDraft.duration));
        this.setModuleInfo.emit(moduleDraft);
      }
    });
  }

  public save() {

    return new Observable<{}>(observer => {
      if (this.formGroup.valid) {
        const instructorCount = this.selectedInstructors.length;
        if (instructorCount > 0) {
          const instructor = this.selectedInstructors[0];
          const hasExtraInstructor = instructorCount > 1;
          const hasDuplicated = uniqBy(
            this.selectedInstructors,
            instructorToCompare => instructorToCompare.id
          ).length !== instructorCount;

          if (hasDuplicated) {
            this.notify('Remova os instrutores duplicados');
            return;
          }

          const moduleInfo = this.formGroup.getRawValue();
          moduleInfo.instructorId = instructor.id;
          moduleInfo.instructor = instructor.name;
          const selectedCascate = this.cascateSelect.GetSelectedItems;
          moduleInfo.sector = selectedCascate.sector;
          moduleInfo.segment = selectedCascate.segment;
          if (this.selectedTutors.length > 0) {
            moduleInfo.tutorsIds = this.selectedTutors.map(x => x.id);
          } else {
            moduleInfo.tutorsIds = [];
          }
          if (hasExtraInstructor) {
            const extraInstructors = this.selectedInstructors
              .slice(1, instructorCount);

            moduleInfo.extraInstructorIds = extraInstructors.map(x => x.id);
            moduleInfo.extraInstructors = extraInstructors.map(this.createInstructor);
          } else {
            moduleInfo.extraInstructorIds = [];
            moduleInfo.extraInstructors = [];
          }


          const draft = { id: this.module.id, moduleId: this.module.id, ...moduleInfo };

          localStorage.setItem(LocalStorageService.key.editingModule, JSON.stringify(draft));

          observer.next(draft);
        } else {
          this.notify('Por favor, selecione um instrutor para o evento');
        }
      } else {
        this.formGroup = this._utilService.markFormControlsAsTouch(this.formGroup);
        this.notify('Por favor, preencha todos os campos obrigatórios');
      }

      observer.complete();
    });

  }

  private _createFormGroup(module: Module): FormGroup {

    return new FormGroup({
      'title': new FormControl(
        module ? module.title : '', [Validators.required]
      ),
      'storeUrl': new FormControl(
        module ? module.storeUrl : ''
      ),
      'createInEcommerce': new FormControl(
        module && !isNil(module.createInEcommerce) ? module.createInEcommerce : false
      ),
      'published': new FormControl(
        module ? module.published : true, [Validators.required]
      ),
      'excerpt': new FormControl(
        module ? module.excerpt : '', [Validators.required]
      ),
      'instructorMiniBio': new FormControl(
        module ? module.instructorMiniBio : ''
      ),
      'imageUrl': new FormControl(
        module && module.imageUrl ? module.imageUrl : './assets/img/420x210-placeholder.png',
        [Validators.required]
      ),
      'instructorImageUrl': new FormControl(
        module && module.instructorImageUrl ? module.instructorImageUrl : './assets/img/240x240-placeholder.png',
        [Validators.required]
      ),
      'tags': new FormArray(
        module && module.tags ? module.tags.map(tag => new FormControl(tag)) : []
      ),
      'validFor': new FormControl(
        module && module.validFor ? module.validFor : null
      ),
      'duration': new FormControl(
        module && !isNil(module.duration) ? this._utilService.SecondToHours(module.duration) : ''
      ),
      'questionCreationValuation': new FormControl(
        module && module.questionCreationValuation ? module.questionCreationValuation : false, [Validators.required]
      ),
      'practiceQuestions': new FormControl(
        module && module.practiceQuestions ? module.practiceQuestions : false, [Validators.required]
      ),
      'ecommerceProducts': new FormControl(
        module && !isNil(module.ecommerceProducts) ? module.ecommerceProducts : []
      ),
      'availability': new FormControl(module && module.availability ? module.availability : ECourseAvailability.available)
    });
  }

  private _uploadImage(base64Image: string, formControl: AbstractControl, fileName: string) {
    this._uploadService.uploadFile(base64Image, fileName).subscribe(uploadedImageURL => formControl.setValue(uploadedImageURL)
      , () => {
        this.notify('Ocorreu um erro ao enviar a imagem, por favor tente novamente mais tarde');
      });
  }

  public triggerUserSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this._loadInstructors(searchValue);

    }
  }

  private _loadInstructors(searchValue: string = ''): void {
    this._usersService.getProfessors(searchValue).subscribe(response => {
      this.instructors = response.data;
    });
  }

  public resetInstructorSearch() {
    this.instructors = [];
  }

  public addInstructor(instructor: User) {
    this.module.instructorId = instructor.id;
    if (instructor.imageUrl)
      this.formGroup.get('instructorImageUrl').setValue(instructor.imageUrl);

    this.selectedInstructors.push(instructor);
    this.resetInstructorSearch();
  }

  public setEcommerceId(id?: number): void {
    if (this.formGroup.get('ecommerceId')) {
      this.formGroup.get('ecommerceId').setValue(id);
    }
  }

  private _loadTutors(searchValue: string = ''): void {
    this._usersService.getProfessors(searchValue).subscribe(response => {
      this.tutors = response.data;
    });
  }

  private _removeSelectedUser(list, user: { id }) {
    const index = list.findIndex(x => x.id === user.id);
    list.splice(index, 1);
  }

  public triggerTutorSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '')
      this._loadTutors(searchValue);
    else
      this.resetTutorSearch();
  }

  public resetTutorSearch(): void {
    this.tutors = [];
  }

  public addTutor(user: TutorInfo) {
    const tutorExists = this.selectedTutors.find(u => u.id === user.id);
    if (!tutorExists)
      this.selectedTutors.push(user);
    this.resetTutorSearch();
  }

  public removeSelectedTutor(user: TutorInfo) {
    this._removeSelectedUser(this.selectedTutors, user);
  }

  public removeSelectedInstructor(user) {
    this._removeSelectedUser(this.selectedInstructors, user);
  }

}
