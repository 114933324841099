import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivateModule } from './models/activate-module.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activate-module-dialog',
  template: `
    <div class="activate-module">
      <div *ngIf="data.isInactive">
        <h4>
          ATIVAR MÓDULO
        </h4>
        <p>
          Você deseja realmente realizar esta ação? Ao ativar este módulo, ele não será adicionado novamente na trilha em que ele estava associado”.
        </p>
        <p>
          Por favor, confirme a sua escolha clicando no botão “<b>Ativar Módulo</b>” abaixo. Se você não deseja continuar, basta clicar no botão “<b>Cancelar</b>”.
        </p>
      </div>
      <div *ngIf="!data.isInactive">
        <h4>
          DESATIVAR MÓDULO
        </h4>
        <p *ngIf="data.tracks.length>0">
        Você tem certeza de que deseja realizar essa ação? Ao desativar esse módulo, ele será removido das trilhas:  
        </p>
        <p *ngIf="!data.tracks.length>0">
        Você tem certeza de que deseja realizar essa ação?  
        </p>
        <div class="track-list" *ngFor="let track of data.tracks">
          <p (click)="goToTrack(track)"> {{track.title}}. </p>
        </div>
        <p>
        Por favor, confirme sua escolha clicando no botão '<b>Desativar Módulo</b>' abaixo. Se você não deseja continuar, basta clicar em '<b>Cancelar</b>'
        </p>
      </div>
      <div class="buttons">
          <button class="btn-test" id="cancel" (click)="dismiss(false)">Cancelar</button>
          <button *ngIf="data.isInactive" class="btn-test" (click)="dismiss(true)">Ativar Módulo</button>
          <button *ngIf="!data.isInactive" class="btn-test" (click)="dismiss(true)">Desativar Módulo</button>
        </div>
    </div>
    `,
  styleUrls: ['./activate-module.dialog.scss']
})
export class ActivateModuleDialogComponent {

  private _urlStateGuard = undefined;

  constructor(
    public dialogRef: MatDialogRef<ActivateModuleDialogComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ActivateModule,
  ) { }

  public dismiss(state: boolean): void {
    this.dialogRef.close(state);
  }
  public goToTrack(track: any): void {
    this._setUrlStateGuard();
    this.dialogRef.close(false);
    this._router.navigate(['/configuracoes/trilha/' + track.id], this._urlStateGuard);
  }

  private _setUrlStateGuard(): void {
    this._urlStateGuard = { state: { accessByComponent: true } };
  }

}
