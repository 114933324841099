<div class="main">
  <app-my-course-sectors title="Minha Jornada Individual" type="myjourneyindividual" [coursers]="coursesIndividual"
    [itemsCount]="coursesIndividualCount" [pageSize]="pageSize" (goToPage)="goToPage($event)">
  </app-my-course-sectors>

  <app-my-course-sectors title="Minha Jornada" type="myjourney" [coursers]="courses" [itemsCount]="coursesCount"
    [pageSize]="pageSize" (goToPage)="goToPage($event)">
  </app-my-course-sectors>

  <app-my-course-sectors title="TREINAMENTOS INSTITUCIONAIS" type="institutionalTraining"
    [coursers]="institutionalTraining" [itemsCount]="institutionalTrainingCount" [pageSize]="pageSize"
    (goToPage)="goToPage($event)">
  </app-my-course-sectors>

  <app-my-course-sectors title="TREINAMENTOS OBRIGATÓRIOS" type="mandatoryTraining" [coursers]="mandatoryTraining"
    [itemsCount]="mandatoryTrainingCount" [pageSize]="pageSize" (goToPage)="goToPage($event)">
  </app-my-course-sectors>
</div>
