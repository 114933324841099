<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      voltar
    </a>
    <h2>{{ test?.title }}</h2>
    <div class="empty-block"></div>
  </nav>

  <div class="test-info">
    <ng-container *ngIf="test">
      <div class="title-box">
        <mat-form-field>
          <input matInput placeholder="Título" [(ngModel)]="test.title" required />
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Tipo" [(ngModel)]="test.type" required>
            <mat-option [value]="1">Pesquisa</mat-option>
            <mat-option [value]="2">Avaliação</mat-option>
            <mat-option [value]="3">Trabalho</mat-option>
            <mat-option [value]="4">Simulado</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="questions">
      <div class="header">
        <h3>Perguntas</h3>
        <button *ngIf="test.type === testType.Free && test.generated !== testTypeGenerated.Manually"
          class="btn-test primary" (click)="opendDialogGenerateValuationTest()">
          GERAR AVALIAÇÃO
        </button>
        <a class="link-button" href="./../../../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_QUESTÕES.xlsx"
          download="Planilha_Modelo_de_Importação_de_Questões.xlsx">
          BAIXAR MODELO
        </a>
        <button *ngIf="test.generated !== testTypeGenerated.Automatically" (click)="openDialogImportQuestion()"
          class="btn-test primary">
          IMPORTAR EXCEL
        </button>

        <button *ngIf="test.generated !== testTypeGenerated.Automatically" class="btn-test"
          (click)="(test.type !== testType.Coursework) ? openQuestionDialog() : openCourseworkDialog() ">
          {{(test.type !== testType.Coursework) ? 'NOVA PERGUNTA' : 'NOVO TRABALHO' }}
        </button>
      </div>
      <div class="question" *ngFor="let question of test?.testQuestions; let index = index">
        <div class="content">
          <div class="info" [ngSwitch]="question.type">
            <p class="number" *ngSwitchCase='3'>
              TRABALHO
            </p>
            <p class="number" *ngSwitchCase='4'>
              QUESTÃO
              {{index + 1}}
              (NPS)
            </p>
            <p class="number" *ngSwitchDefault>
              QUESTÃO {{ index + 1 }} (peso {{+question.percentage?.toFixed(2)}})
            </p>
          </div>
          <p class="question">
            {{ question.title }}
          </p>
        </div>
        <div class="edit">
          <img *ngIf="test.generated !== testTypeGenerated.Automatically" src="./assets/img/edit.png"
            (click)="(test.type !== testType.Coursework) ? openQuestionDialog(question) : openCourseworkDialog(question)" />

          <img *ngIf="test.generated !== testTypeGenerated.Automatically" src="./assets/img/trash.png"
            (click)="confirmRemoveQuestion(index)" />

          <img *ngIf="test.generated === testTypeGenerated.Automatically" src="./assets/img/view.png"
            (click)="openQuestionDialog(question)" />
        </div>
      </div>
    </div>

    <ng-container *ngIf="test.type !== testType.Mock">
      <h3>Módulos</h3>
      <p class="sub">
        {{getText(test.generated, 'module')}}
      </p>
      <ng-container *ngIf="test.generated !== testTypeGenerated.Automatically">
        <app-list-search *ngIf="test.generated !== testTypeGenerated.Automatically"
          placeholder="Pesquisar por nome ou conceito" (triggerSearch)="triggerModuleSearch($event)"></app-list-search>
        <div class="chips">
          <p *ngFor="let module of selectedModules">
            {{ module.title }}
            <span (click)="removeSelectedModule(module.id)">X</span>
          </p>
        </div>
        <div class="tracks">
          <app-settings-module-card-valuation-test *ngFor="let module of modules" [module]="module"
            [isDisabled]="test.generated === testTypeGenerated.Automatically" (updateCollection)="updateModules()">
          </app-settings-module-card-valuation-test>
        </div>
      </ng-container>
      <ng-container *ngIf="test.generated === testTypeGenerated.Automatically">
        <app-generate-valuation-test-check-options #checkedModuleComponent (search)="triggerModuleSearch($event)"
          (selectedData)="handleModuleSelected($event)" [dataSource]="{data: modules}" firstTitle="Módulos Encontrados"
          secondTitle="Módulos Adicionados">
        </app-generate-valuation-test-check-options>
      </ng-container>

      <h3>Trilhas</h3>
      <p class="sub">
        {{getText(test.generated, 'track')}}
      </p>
      <ng-container *ngIf="test.generated !== testTypeGenerated.Automatically">
        <app-list-search placeholder="Pesquisar por nome ou conceito" (triggerSearch)="triggerTrackSearch($event)">
        </app-list-search>
        <div class="chips">
          <p *ngFor="let track of ( isTargetModule ? selectedModules[0].tracks : selectedTracks)">
            {{ track.title }}
            <span
              (click)="isTargetModule ? removeSelectedModuleTracksDisplayed(track.id) : removeSelectedTrack(track.id)">X</span>
          </p>
        </div>
        <div class="tracks">
          <app-settings-track-card-valuation-test *ngFor="let track of tracks" [track]="track"
            [isDisabled]="test.generated === testTypeGenerated.Automatically" (updateCollection)="updateTracks()">
          </app-settings-track-card-valuation-test>
        </div>
      </ng-container>

      <ng-container *ngIf="test.generated === testTypeGenerated.Automatically">
        <app-generate-valuation-test-check-options #checkedComponent
          [dataSource]="isTargetModule ? { data: this.tracksContainsModuleById, key: this.key } : { data: this.tracks }"
          (selectedData)="handleTrackSelected($event)" firstTitle="Trilhas Encontradas"
          secondTitle="Trilhas Adicionadas">
        </app-generate-valuation-test-check-options>
      </ng-container>


      <h4>EVENTOS</h4>
      <p class="sub">
        Escolha uma ou mais eventos para recomendar aos usuários selecionados acima
      </p>
      <ng-container>
        <app-list-search placeholder="Pesquisar por nome ou conceito" (triggerSearch)="triggerEventSearch($event)">
        </app-list-search>
        <div class="chips">
          <p *ngFor="let event of selectedEvents">
            {{ event.title }} - {{ event.eventDate | date : 'dd/MM/yyyy' }}
            <span (click)="removeSelectedEvent(event)">X</span>
          </p>
        </div>
        <div class="tracks">
          <app-settings-event-card-select *ngFor="let event of events" [setEvent]="event"
            (updateCollection)="updateEvents($event)"></app-settings-event-card-select>
        </div>

      </ng-container>
      <ng-container>
        <h3>Configurações</h3>
        <p class="sub">
          Configure a ordem de aparições
        </p>
        <p *ngIf="!checkTest(test)">Você ainda não concluiu as etapas anteriores</p>
        <app-settings-manage-valuation-test-release #ManageValuationTestRelease *ngIf="checkTest(test)" [test]="test">
        </app-settings-manage-valuation-test-release>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="test.type === testType.Mock">

      <h3>Módulos</h3>
      <p class="sub">
        {{getText(test.generated, 'module')}}
      </p>
      <app-generate-valuation-test-check-options #checkedModuleComponent (search)="triggerModuleSearch($event)"
        (selectedData)="handleModuleSelected($event)" [dataSource]="{data: modules}" [fixedSelected]="true"
        [addSelectedItem]="moduleToAdd" firstTitle="Módulos Encontrados" secondTitle="Módulos Adicionados"
        searchPlaceholder="Pesquise por um módulo">
      </app-generate-valuation-test-check-options>

      <h3>Assuntos</h3>
      <p class="sub">
        {{getText(test.generated, 'subject')}}
      </p>
      <app-generate-valuation-test-check-options #checkedModuleComponent (selectedData)="handleSubjectSelected($event)"
        [dataSource]="{data: subjects}" [fixedSelected]="true" [disableSearch]="true" [addSelectedItem]="subjectToAdd"
        [removeSelectedId]="subjectIdToRemove" firstTitle="Assuntos Encontrados" secondTitle="Assuntos Adicionados">
      </app-generate-valuation-test-check-options>

    </ng-container>

    <div class="actions">
      <button class="btn-test" (click)="save()"> Salvar </button>
    </div>
  </div>
</div>
