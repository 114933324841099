<h1>
  <a (click)="goBack()"
    style="cursor: pointer;" >
    voltar
  </a>
  <!-- MINHA CARREIRA -->
  PERFIL PROFISSIONAL
</h1>
<div class="inner-content" *ngIf="formGroup" >
  <form [formGroup]="formGroup" >

    <mat-tab-group #tabGroup (selectedTabChange)="nextStep($event)">
      <mat-tab label="Histórico Acadêmico">
        <app-academic-education
          #academicEducations
          [formGroup]="formGroup"
          (addEducation)="addCollege()"
          (setValidator)="setValidator($event)"
        ></app-academic-education>
      </mat-tab>
      <mat-tab label="Experiência Profissional">
        <app-professional-experience
          #professionalExperiences
          [formGroup]="formGroup"
          (addExperience)="addProfessionalExperience()"
          (setValidator)="setValidator($event)"
        ></app-professional-experience>
      </mat-tab>
      <mat-tab label="Formação Complementar">
        <app-complementary-experience
          #complementaryInfos
          [formGroup]="formGroup"
          (addAbility)="addAbility()"
          (setValidator)="setValidator($event)"        
        ></app-complementary-experience>
      </mat-tab>
      <mat-tab label="Informações Complementares">
        <app-complementary-info
          #complementaryExperiences
          [formGroup]="formGroup"
          [travelAvailability]="travelAvailability"
          [movingAvailability]="movingAvailability"
          (addReward)="addReward()"
          (addCertificate)="addCertificate()"
          (addLanguage)="addLanguage()"
          (addSkill)="addSkill()"
          (selectTravelAvailability)="selectTravelAvailability($event)"
          (selectMovingAvailability)="selectMovingAvailability($event)"
          (setValidator)="setValidator($event)"
        ></app-complementary-info>
      </mat-tab>
      <mat-tab label="Objetivos Profissionais">
        <app-professional-objectives
          #professionalsObjectives
          [formGroup]="formGroup"
          (setValidator)="setValidator($event)"
        ></app-professional-objectives>
      </mat-tab>
    </mat-tab-group>
  </form>

</div>

<div class="footer" >
  <button 
    class="previous" 
    type="button" 
    mat-button 
    [hidden]="previousTab === 0"
    (click)="nextStep(previousTab - 1)">Voltar
  </button>
    <button class="save"
      [hidden]="previousTab !== 4"
      type="button"
      (click)="saveCareer()" >
      Salvar Alterações
    </button>
    <button 
      class="next" 
      type="button" 
      mat-button 
      [hidden]="previousTab === 4"
      (click)="nextStep(previousTab + 1)">
      continuar
    </button>
</div>
