<div class="header">
  <app-navigation-back></app-navigation-back>

  <ng-container>
    <div class="title-wrapper">
      <span *ngIf="subTitle" class="subtitle">{{subTitle}}</span>
      <span *ngIf="title" class="title">{{title}}</span>
    </div>
  </ng-container>
</div>
