import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { Sector } from 'src/app/models/sector.model';
import { Segment } from 'src/app/models/segment.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { SectorService } from '../../_services/sector.service';
import { SegmentService } from '../../_services/segments.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-segment',
  templateUrl: './new-segment.component.html',
  styleUrls: ['./new-segment.component.scss']
})
export class NewSegmentComponent extends NotificationClass implements OnInit {

  public segment: Segment = new Segment();
  public segmentId: string;
  public sectors: Sector[];
  public sector: Sector = new Sector();

  constructor(
    private _segmentsService: SegmentService,
    private _sectorService: SectorService,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.segmentId = this._activatedRoute.snapshot.params['segmentId'];
    if (this.segmentId)
      this.loadSegment();

    this.loadSector();
  }

  public loadSegment() {
    this._segmentsService.GetSegmentById(this.segmentId).subscribe(response => {
      this.segment = response.data;
      this.sector = this.segment.sector;
    });
  }

  public loadSector() {
    this._sectorService.GetAllSectors().subscribe(response => {
      this.sectors = response.data;
    });
  }

  public save() {
    if (isNil(this.segmentId)) {
      this._segmentsService.addSegment(this.segment.name, this.sector.id).subscribe(response => {
        this.segmentId = response.data.id;
        this.segment.id = this.segmentId;
        this._location.replaceState(`configuracoes/segmento/${this.segment.id}`);
        this.notify("Segmento salvo com sucesso");
      });
    } else {
      this._segmentsService.updateSegment(this.segment.name, this.sector.id, this.segment.id).subscribe(_ => {
        this.notify("Segmento salvo com sucesso");
      });
    }
  }

  compareFn(t1: any, t2: any): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }
}
