import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Editor from '@toast-ui/editor';
import { Content, TextReference } from '../../../../../../../models/content.model';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'app-text-config-dialog',
  templateUrl: './text-config.dialog.html',
  styleUrls: ['../content-config.scss', './text-config.dialog.scss']
})
export class TextConfigDialogComponent implements OnInit {

  public editor: Editor;

  constructor(
    public dialogRef: MatDialogRef<TextConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public content: Content,
    private _uploadService: UploadService
  ) { }

  ngOnInit() {
    this.editor = new Editor({
      el: document.querySelector('#htmlEditor'),
      initialEditType: 'markdown',
      previewStyle: 'vertical',
      height: '200px',
      hooks: {
        addImageBlobHook: (file: File, callback) => {
          this._uploadService.uploadFile(file).subscribe(uploadedImageURL => {
            callback(uploadedImageURL, file.name);
          });
        }
      }
    });

    this.editor.setMarkdown(
      this.content.value
    );
  }

  public referencesTrackBy(index: number, obj: any) {
    return index;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.content.value = this.editor.getMarkdown();
    this.dialogRef.close(this.content);
  }

  public addReference(): void {
    this.content.referenceUrls ?
      this.content.referenceUrls.push('') :
      this.content.referenceUrls = [''];
  }

  public setConcept(concept: TextReference) {
    concept.checked = !concept.checked;
    if (concept.checked)
      concept.anchors = ['#'];
  }

}
