<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <div class="header">
    <h3>Setores</h3>
  </div>
  <div class="main">
    <mat-form-field class="form" floatLabel="always">
      <mat-label class="mat-label">Nome do setor</mat-label>
      <input matInput class="input-search" [(ngModel)]="sector.name" placeholder="Digite o nome do setor">
    </mat-form-field>
    <div class="title">
      <h3>Associar Segmentos</h3>
      <label>Segmentos que podem ser associados ao setor</label>
    </div>
    <div>
      <app-generate-valuation-test-check-options #checkedComponent [disableSearch]="true"
        searchPlaceholder="Pesquise um segmento..." (selectedData)="handleSegmenteSelected($event)"
        [dataSource]="{data: segments}" firstTitle="Segmentos Encontrados" secondTitle="Segmentos Adicionados">
      </app-generate-valuation-test-check-options>
    </div>
    <button class="btn-test" (click)="save()">
      Salvar Alterações
    </button>
  </div>
</div>
