<div *ngIf="coursers.length > 0">
  <div class="path">
    <h4>{{ title }}</h4>
  </div>
  <div class="suggested">
    <app-course-card *ngFor="let course of coursers" [course]="course"
      [progress]="{ progress: course?.progress, points: course?.points, isStarted: course?.isStarted, isExempt: course?.isExempt }">
    </app-course-card>
  </div>
  <app-pagination [setPagination]="{
          'itemsCount': itemsCount,
          'pageSize': pageSize
        }" (goToPage)="goToPage.emit({page: $event, type: type, title: title})"></app-pagination>
</div>
