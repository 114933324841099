<div class="inner-content">
  <app-exam-header [subTitle]="subject?.title" [levels]="levels" [showButton]="showButton" (finished)="finish()">
  </app-exam-header>

  <div class="content notranslate" translate="no">
    <div [ngClass]="{'answers': true,'no-border': !isQuestionBdq }">
      <p class="number">
        {{title ? title : 'Questão'}}
      </p>

      <app-nps-answer #questionNPS [data]="questionToActivation(question)" (sliderValue)="setAnswer($event)"
        [textAlignLeft]="true">
      </app-nps-answer>

      <br>
      <button class="btn-test" (click)="confirm()"
        [disabled]="selectedAnswer === null || selectedAnswer === undefined || selectedAnswer === ''"
        [ngClass]="{ 'answered': answered }">
        {{ answered ? last ? 'Finalizar'
        : 'Próxima pregunta'
        : 'Confirmar Resposta' }}
      </button>
    </div>
  </div>
</div>
