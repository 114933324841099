import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  CategoryFilter, Parent,
} from 'src/app/shared/components/autocomplete/models/autocomplete.model';

import { CategoryFilterEnum } from 'src/app/models/enums/category-filter.enum';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { Moment } from 'moment';
import { AnswerReportFilter } from 'src/app/pages/report/report-student-dashboard/models/report-student-dashboard.component.model';
import { forkJoin } from 'rxjs';
import { ReportFilter } from 'src/app/pages/report/filters/shared/report-filter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-filter-report-student-dashboard',
  templateUrl: './filter-report-student-dashboard.component.html',
  styleUrls: ['./filter-report-student-dashboard.component.scss']
})
export class FilterReportStudentDashboardComponent extends ReportFilter implements OnInit {

  public filters = {
    student: null,
    modules: null,
    subjects: null,
    track: null,
    segment: null,
    businessGroup: null,
    businessUnit: null,
  };

  public get filter(): AnswerReportFilter {
    this.updateFilters(this.categoryFilters);

    return {
      categories: this.categoryFilters.map(filter => ({
        columnName: filter.filterColumn,
        contentNames: filter.selectedOptions
      })),
      student: this.filters.student
    };
  }

  constructor(
    protected _reportSertivce: ReportsService,
    protected _snackBar: MatSnackBar,
    private _authService: AuthService
  ) {
    super(_reportSertivce, _snackBar);
  }

  ngOnInit() {
    this._setCategory();
  }

  private _setCategory() {

    forkJoin({
      segment: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Segments }),
      businessGroup: this._reportSertivce.getCategory({ category: CategoryFilterEnum.BusinessGroups }),
      businessUnit: this._reportSertivce.getCategory({ category: CategoryFilterEnum.BusinessUnits }),
      track: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Tracks })
    }).subscribe(response => {

      this._setFilter('Trilha', CategoryFilterEnum.Tracks, 'track.id',
        response.track.data.itemsCount, response.track.data.items, 'title', true, true);

      this._setFilter('Módulos', CategoryFilterEnum.Modules, 'module.id',
        0, [], 'title', true, false, { category: CategoryFilterEnum.Tracks, key: null });

      this._setFilter('Assuntos', CategoryFilterEnum.Subjects, 'subject.id',
        0, [], 'title', true, false, { category: CategoryFilterEnum.Modules, key: null }, []);

      this._setFilter('Segment', CategoryFilterEnum.Segments, 'segment.name',
        response.segment.data.itemsCount, response.segment.data.items, 'name', false);

      this._setFilter('Business Group', CategoryFilterEnum.BusinessGroups, 'businessGroup.name',
        response.businessGroup.data.itemsCount, response.businessGroup.data.items, 'name', false);

      this._setFilter('Business Unit', CategoryFilterEnum.BusinessUnits, 'businessUnit.name',
        response.businessUnit.data.itemsCount, response.businessUnit.data.items, 'name', false);

      this.filters.track = this.categoryFilters[0];
      this.filters.modules = this.categoryFilters[1];
      this.filters.subjects = this.categoryFilters[2];
      this.filters.segment = this.categoryFilters[3];
      this.filters.businessGroup = this.categoryFilters[4];
      this.filters.businessUnit = this.categoryFilters[5];
    });

  }

  private _setFilter(
    filterName: string,
    category: number,
    filterColumn: string,
    itemsCount: number,
    collection: any[],
    prop: string = 'name',
    demandsId: boolean = false,
    isSingleOption: boolean = false,
    parent?: Parent,
    parents?: Parent[],
  ) {

    const newFilter: CategoryFilter = {
      name: filterName,
      category: category,
      filterColumn: filterColumn,
      page: 1,
      itemsCount: itemsCount,
      maxLength: collection.length,
      options: [],
      selectedOptions: [],
      isAlternate: prop === 'title',
      demandsId: demandsId,
      isSingleOption,
      parent,
      parents
    };


    for (let index = 0; index < collection.length; index++) {
      newFilter.options.push({
        id: collection[index].id,
        description: collection[index][prop],
        checked: false
      });
    }

    this.categoryFilters.push(newFilter);
    this.selectedFilters.push({ columnName: filterColumn, contentNames: [] });
  }

  public removeStudent(event) {
    this.filters.student = undefined;
  }
  public setStudent(student: UserPreview) {
    this.filters.student = student;
  }
}
