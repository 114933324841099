<ngx-datatable
  #Table
  [title]="title"
  class="material fullscreen"
  trackByProp="id"
  (activate)="handleActivatedRowEvent($event)"
  [rows]="students"
  [rowIdentity]="rowIdentity"
  [messages]="messages"
  [rowHeight]="getRowHeight"
  [footerHeight]="50"
  [headerHeight]="50"
  [columnMode]="ColumnMode.force"
  [scrollbarV]="true">

  <ngx-datatable-column
    [minWidth]="50"
    [maxWidth]="50"
    name=""
    prop="imageUrl">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <img class="photo" src="{{value || imagePlaceholder}}" />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="name" name="Nome">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [minWidth]="105" prop="userName" name="Nome de usuário">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column cellClass="number" [maxWidth]="80" prop="grade" name="Nota">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <span class="number">{{value | number: '1.2-2' }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="showRanking" cellClass="number" [maxWidth]="90" prop="ranking" name="Ranking">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <span class="number">{{value ? value+' º' : '-'}}</span>
    </ng-template>
  </ngx-datatable-column>


</ngx-datatable>
