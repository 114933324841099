import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DialogFactoryService } from 'src/app/shared/services/dialog/dialog-factory.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { DialogData } from 'src/app/shared/services/dialog/models/dialog-data.model';

@Component({
  selector: 'app-cockpit-title',
  templateUrl: './cockpit-title.component.html',
  styleUrls: ['./cockpit-title.component.scss']
})
export class CockpitTitleComponent {

  @Input() type: string;
  @Input() titleText: string;
  @Input() legend?: string;
  @Input() templateName?: string;

  public dialog: DialogService;
  @ViewChild('understandDialogEffortTemplate', { static: true })
  understandDialogEffortTemplate: TemplateRef<any>;
  @ViewChild('understandDialogPerformanceTemplate', { static: true })
  understandDialogPerformanceTemplate: TemplateRef<any>;
  constructor(private dialogFactoryService: DialogFactoryService) { }


  public dispatchDialog(title: string, templateName: string) {
    this.openDialog({
      headerText: title,
      template: templateName === 'effort' ? this.understandDialogEffortTemplate : this.understandDialogPerformanceTemplate,
    });
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: 720,
      height: 230,
      disableClose: false,
    });
  }
}
