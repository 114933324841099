import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ModuleSidebarModule } from '../../shared/components/module-sidebar/module-sidebar.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { SettingsTracksModule } from 'src/app/settings/tracks/tracks.module';
import { MatIconModule } from '@angular/material/icon';
import { ModuleQuestionValuationComponent } from './module-question-valuation.component';
import { QuestionValuationExplanationComponent } from './question-valuation-explanation/question-valuation-explanation.component';
import { ModuleNewQuestionComponent } from './module-new-question/module-new-question.component';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUtilModule } from '@tg4/ng-util';
import { NewModuleModule } from 'src/app/settings/modules/new-module/new-module.module';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { ModuleNewValuationComponent } from './module-new-valuation/module-new-valuation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuestionValuationFinishDialogComponent } from './question-valuation-finish-dialog/question-valuation-finish-dialog.component';

@NgModule({
    declarations: [
        ModuleQuestionValuationComponent,
        QuestionValuationExplanationComponent,
        ModuleNewQuestionComponent,
        ModuleNewValuationComponent,
        QuestionValuationFinishDialogComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        RouterModule,
        ModuleSidebarModule,
        PipesModule,
        DirectivesModule,
        SettingsTracksModule,
        FormsModule,
        ReactiveFormsModule,
        NgUtilModule,
        NewModuleModule,
        PaginationModule,
        MatIconModule,
        ListSearchModule,
        MaterialComponentsModule
    ],
    exports: []
})
export class ModuleQuestionValuationModule { }
