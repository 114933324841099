<h2>INDICAR TESTE DE PERFIL</h2>

<div class="inner-content" >

  <h4>USUÁRIOS</h4>
  <app-list-search
    placeholder="Digite o nome de um usuário para adicioná-lo"
    (triggerSearch)="triggerUserSearch($event)"
  ></app-list-search>
  <ul class="user-list"
    *ngIf="users && users.length > 0"
    (clickOutside)="resetUserSearch()" >
    <li *ngFor="let user of users"
      (click)="addUser(user)" >
      <img class="logo" [src]="user.imageUrl ? user.imageUrl : './assets/img/user-image-placeholder.png'" />
      <span>{{ user.name }}</span>
    </li>
  </ul>
  <div class="chips"
    *ngIf="selectedUsers && selectedUsers.length > 0"
    [ngClass]="{ 'hide': !showAllSelectedUsers }" >
    <p *ngFor="let user of selectedUsers" >
      <img class="logo" [src]="user.imageUrl ? user.imageUrl : './assets/img/user-image-placeholder.png'" />
      {{ user.name }}
      <span (click)="removeSelectedUser(user)">X</span>
    </p>
  </div>
  <p class="view-all"
    *ngIf="selectedUsers && selectedUsers.length > 4"
    (click)="showAllSelectedUsers = !showAllSelectedUsers"
  >
    {{ showAllSelectedUsers ? 'ocultar' : 'ver todos' }} 
  </p>

  <h4>TESTES</h4>
  <div class="tests" >
    <app-settings-test-card
      *ngFor="let test of tests"
      [test]="test"
      (selectTest)="selectTest($event)"
    ></app-settings-test-card>
  </div>

  <div class="footer" >
    <button class="btn-test" (click)="sendRecommendations()" >
      Indicar Teste
    </button>
  </div>
</div>
  