import { Component, OnInit } from '@angular/core';
import { Track } from '../../models/track.model';
import { EventPreview } from '../../models/previews/event.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentTracksService } from '../_services/tracks.service';
import { NotificationClass } from '../../shared/classes/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TrackEvent } from 'src/app/models/track-event.model';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent extends NotificationClass implements OnInit {

  public replacement = environment.replecement;
  public track: Track;
  private _trackId: string;
  private programId: string;
  public events: Array<EventPreview> = [];
  levelDict: any = {};
  moduleProgress: any = {};
  trackProgress: any = {};

  constructor(
    protected _snackBar: MatSnackBar,
    private _tracksService: ContentTracksService,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _sharedService: SharedService,
    private _router: Router
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._trackId = this._activatedRoute.snapshot.paramMap.get('trackId');
    this._activatedRoute.queryParams
      .pipe(filter((params) => params.programId))
      .subscribe((params) => (this.programId = params.programId));

    this._tracksService.getTrackById(
      this._trackId, true, this.programId
    ).subscribe((response) => {
      this.track = response.data;

    });
    this._loadProgress();
    this._loadLevels();
  }

  public getCalendarEvents(): Array<TrackEvent> {
    const trackEvents: Array<TrackEvent> = [];

    if (this.track.eventsConfiguration)
      // eslint-disable-next-line prefer-spread
      trackEvents.push.apply(trackEvents, this.track.eventsConfiguration);

    if (this.track.calendarEvents)
      // eslint-disable-next-line prefer-spread
      trackEvents.push.apply(trackEvents, this.track.calendarEvents);

    return trackEvents;
  }

  public getAttendedEventsCount(): number {
    return this.track.eventsConfiguration.reduce((sum: number, ev) => ev.hasTakenPart ? sum + 1 : sum, 0);
  }

  public goToEvent(event) {
    this._router.navigate(['evento/' + event.eventId + '/' + event.eventScheduleId]);
  }

  private _loadLevels(): void {
    this._sharedService.getLevels(true).subscribe((response) => {
      this.levelDict = {};
      response.data.forEach(level => {
        this.levelDict[level.id] = level.description;
      });
    });
  }

  private _loadProgress(): any {
    this._userService.getUserModulesProgress().subscribe((response) => {
      this.moduleProgress = {};
      response.data.forEach(x => {
        this.moduleProgress[x.moduleId] = x;
      });

    });

    this._userService.getUserTrackProgress(this._trackId).subscribe((response) => {
      this.trackProgress = response.data;
    });
  }
}
