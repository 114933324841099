import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserCategoryFilterSearchOption } from 'src/app/settings/manage-team/filters/filters.interface';
import { IFilter } from './interface/filter.interface';

@Injectable({
    providedIn: 'root'
})
export class TeamsFiltersService {

    private _filter: IFilter = {} as IFilter;
    private _filters$: BehaviorSubject<IFilter> = new BehaviorSubject<IFilter>({} as IFilter);

    constructor() { }

    public setSearchTerm(value: string) {
        this._filter.searchTerm = value;
        this._filters$.next(this._filter);
    }

    public setCreatedSince(value: Date) {
        this._filter.createdSince = value;
        this._filters$.next(this._filter);
    }

    public setUserCategoryFilterSearchOption(value: Array<UserCategoryFilterSearchOption>) {
        this._filter.categoryFilters = value;
        this._filters$.next(this._filter);
    }

    public getFilters(): BehaviorSubject<IFilter> {
        return this._filters$;
    }
}
