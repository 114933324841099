<div class="inner-content" >
  <nav>
    <a href="javascript:history.back()" >
      voltar
    </a>
    <h2>{{ test?.title }}</h2>
    <a></a>
  </nav>

  <div class="test-info" >
    <ng-container *ngIf="test" >
      <mat-form-field>
        <input matInput placeholder="Título"
          [(ngModel)]="test.title" required />
      </mat-form-field>
      <br>
      <mat-slide-toggle
        [checked]="test.isDefault"
        (change)="test.isDefault = $event.checked"
      >
        Teste Padrão?*
      </mat-slide-toggle>
    </ng-container>
  
    <p class="default-test" >
      *O teste marcado como padrão será apresentado aos usuários que não tiverem nenhum conteúdo disponível,
      caso não haja outro teste recomendado para aquele usuário.
    </p>

    <div class="questions" >
      <div class="header" >
        <h3>Perguntas</h3>
        <button class="btn-test primary" (click)="openFileUpload()" >
          IMPORTAR EXCEL
        </button>
        <input class="input-file" type="file" id="inputFile" hidden
          (change)="setDocumentFile($event)"
        />

        <button class="btn-test" 
          (click)="openQuestionDialog()" >
          NOVA PERGUNTA
        </button>
      </div>
      <div class="question" *ngFor="let question of test?.testQuestions; let index = index" >
        <div class="content" >
          <div class="info" >
            <p class="number" >
              QUESTÃO {{ index + 1 }} (peso {{question.percentage}})
            </p>
          </div>
          <p class="question" >
            {{ question.title }}
          </p>
        </div>
        <div class="edit" >
          <img src="./assets/img/edit.png"
            (click)="openQuestionDialog(question)"
          />
          <img src="./assets/img/trash.png"
            (click)="confirmRemoveQuestion(index)"
          />
        </div>
      </div>
    </div>
  
    <div class="actions" >
      <button class="btn-test" (click)="save()" >
        Salvar
      </button>
    </div>
  </div>
</div>
