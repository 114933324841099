import { Component, EventEmitter, Output, Input, OnInit, OnDestroy } from '@angular/core';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject as SubjectModel, SubjectExcel, UserProgress } from '../../../../../models/subject.model';
import { Module } from '../../../../../models/module.model';
import { Level } from '../../../../../models/shared/level.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep, isNil } from 'lodash';
import { SettingsModulesDraftsService } from 'src/app/settings/_services/modules-drafts.service';
import { UploadSubjectsDatabaseDialogComponent } from './upload-subjects/upload-subjects.dialog';
import { concatMap, takeUntil } from 'rxjs/operators';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { Observable, Subject } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-new-module-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['../new-module-steps.scss', './subjects.component.scss']
})
export class NewModuleSubjectsComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() module$: Observable<Module>;
  public module: Module;
  @Input() set setLevels(levels: Array<Level>) {
    this.levels = levels;
    this.setUserProgess();
  }

  @Input() createNewModuleDraft: Function;
  @Output() addSubjects = new EventEmitter<Array<SubjectModel>>();
  @Output() updateImportSubjects = new EventEmitter<Array<SubjectModel>>();

  public levels: Array<Level>;
  public newSubject: SubjectModel;
  private _unsubscribeAll = new Subject<any>();

  constructor(protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _draftsService: SettingsModulesDraftsService,
    private _excelService: ExcelService,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = cloneDeep(moduleItems);
        if (moduleItems && moduleItems.subjects) {
          moduleItems.subjects.forEach(sub => {
            delete sub.hasQuestions;
          });
        }
        this.module = cloneDeep(moduleItems);
        this.setUserProgess();
      });
    }
  }

  public getLevelDescription(levelId: number): string {
    if (!this.levels) return '';
    const level = this.levels.find(lev => lev.id === levelId);
    return level ? level.description : '';
  }

  public setProgressPercentage(event, progress: UserProgress) {
    if (event.target.valueAsNumber === 0 || !event) {
      this.notify('Os valores devem ser diferentes de 0');
      event.target.valueAsNumber = 70;
    }
    progress.percentage = event.target.valueAsNumber / 100;
  }

  public addSubject(): void {
    if (!this.module.subjects)
      this.module.subjects = [];

    this.module.subjects.push(
      new SubjectModel(this.levels)
    );
  }

  public removeSubject(index: number): void {
    const subjectId = this.module.subjects[index].id;
    if (this.module.tests &&
      this.module.tests.length > 0 &&
      this.module.tests.some(x => x.testSubjectIds.some(y => y === subjectId))
    ) {
      const dialogVerification = this._dialog.open(SimpleDialogComponent, {
        hasBackdrop: true,
        closeOnNavigation: false,
        disableClose: true,
        data: {
          message: 'Este assunto possui um simulado associado a ele.\nDeseja continuar?',
          positiveTextAction: 'Sim',
          negativeTextAction: 'Não'
        } as SimpleDialog
      });

      dialogVerification.afterClosed().subscribe(isAccepted => {
        if (isAccepted) this.module.subjects.splice(index, 1);
      });
    } else {
      this.module.subjects.splice(index, 1);
    }
  }

  public updateConcepts(concepts: Array<string>, subject: SubjectModel) {
    subject.concepts = concepts;
  }

  public nextStep(): void {
    if (this._checkSubjectsInfo(this.module.subjects)) {
      if (!this.module.subjects) return;
      this.module.subjects.forEach(sub => {
        if (sub.contents) {
          sub.contents.forEach(cont => {
            cont.subjectId = sub.id;
          });
        }
      });
      this.addSubjects.emit(this.module.subjects);
    } else {
      this.notify('Por favor, preencha todos os campos obrigatórios');
    }
  }

  public setUserProgess() {
    if (this.module && this.module.subjects && this.levels) {
      this.module.subjects.forEach(subj => {
        subj.userProgresses = subj.userProgresses || [];

        this.levels.forEach(level => {
          if (!subj.userProgresses.some(up => up.level === level.id)) {
            subj.userProgresses.push(
              new UserProgress(level)
            );
          }
        });
      });
    }
  }

  public droppedSubject(evt: CdkDragDrop<SubjectModel[]>) {
    moveItemInArray(this.module.subjects, evt.previousIndex, evt.currentIndex);
  }

  private _checkSubjectsInfo(subjects?: Array<SubjectModel>): boolean {
    if (!subjects) return true;
    return !subjects.some(subj => !subj || !subj.title || !subj.excerpt);
  }

  public openUploadDialog(module: Module) {
    const dialogRef = this._dialog.open(UploadSubjectsDatabaseDialogComponent, {
      width: '1000px',
      maxHeight: '90vh',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        'module': module,
        'levels': this.levels
      }
    });

    dialogRef.afterClosed().subscribe((data) => {

      if (!isNil(data) && !isNil(data.subjects)) {
        if (module.isDraft) {
          this.importDraft(data);
        } else {
          this.createNewModuleDraft(this.module).then(() => {
            this.importDraft(data);
          });
        }
      }
    });
  }

  public importDraft(data: any) {
    this._draftsService.importDraftSubject(data.subjects, this.module.id, data.addSubjects)
      .pipe(concatMap(() => this._draftsService.getDraftById(this.module.id)))
      .subscribe(response => {
        this.updateImportSubjects.emit(response.data.subjects);
      }, (err) => this.notify(this.getErrorNotification(err), 'Fechar', { duration: 9000000000000 }));
  }


  public exportSubjects() {
    const excelSubjects: SubjectExcel[] = [];
    for (let i = 0; i < this.module.subjects.length; i++) {
      const subject = this.module.subjects[i];
      const newExcelLine = new SubjectExcel();
      newExcelLine.Nome_do_Assunto = subject.title;
      newExcelLine.Descricao_do_Assunto = subject.excerpt;
      const percentuais = [];
      subject.userProgresses.forEach(progress => {
        percentuais.push((progress.percentage * 100).toString());
      });
      newExcelLine.Percentuais_do_Assunto = percentuais.join(';');
      newExcelLine.Conceitos_do_Assunto = subject.concepts.join(';');
      excelSubjects.push(newExcelLine);
    }
    this._excelService.exportAsExcelFile(excelSubjects, 'Assuntos - ' + this.module.title);
  }
}
