import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { NotificationClass } from '../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Module } from '../../models/module.model';
import { UtilService } from '../../shared/services/util.service';
import { SharedService } from '../../shared/services/shared.service';
import { Level } from '../../models/shared/level.interface';
import { Level as EnumLevel } from 'src/app/models/enums/level.enum';
import { UserService } from '../_services/user.service';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import Player from '@vimeo/player';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import {
  ActionInfoPageEnum,
  ActionInfoTypeEnum,
} from 'src/app/shared/directives/save-action/action-info.interface';
import { ForumQuestion } from 'src/app/models/forum.model';
import { RequirementProgress } from 'src/app/settings/modules/new-module/models/new-requirement.model';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import {
  ValuationTestTypeEnum,
  ValuationTestModuleTypeEnum,
} from 'src/app/models/enums/valuation-test-type-enum';
import { ValuationTest } from 'src/app/models/valuation-test.interface';
import { environment } from 'src/environments/environment';
import { EffortService } from 'src/app/shared/services/effort.service';
import { TypeTargetEffort } from 'src/app/shared/enumerations/effort';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuestionsAndAnswersDialogComponent } from 'src/app/shared/dialogs/questions-and-answers/questions-and-answers.dialog';
import { MatDialog } from '@angular/material/dialog';
import { ERole } from 'src/app/models/enums/role.enum';
import { isNil } from 'lodash';
import { addDays, differenceInSeconds } from 'date-fns';
import { ModuleOpinionDialogComponent } from './module-opinion-dialog/module-opinion-dialog.component';
import { EFavoriteEnum } from 'src/app/models/enums/favorite.enum';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss'],
})
export class ModuleComponent extends NotificationClass implements OnInit, OnDestroy {

  // Features
  public hasFeatureCertificate = environment.features.certificate;
  public canGenerateCertificates = environment.features.canGenerateCertificate;
  public hasFeatureKnowMore = environment.features.knowMore;

  // Component props
  public module: Module;
  public forumQuestionsPreview: ForumQuestion[] = [];
  public trackModule: string = '';
  public levels: Array<Level> = [];
  public userProgress: any;
  public userModuleInfo: any;
  public levelDict: any;
  public player;
  public moduleTestsResearch: any[] = [];
  public moduleTestsBefore: any[] = [];
  public moduleTestsAfter: any[] = [];
  public moduleCourseWork: any[] = [];
  public moduleTestsResearchExpanded: boolean = false;
  public moduleTestsBeforeExpanded: boolean = false;
  public moduleTestsAfterExpanded: boolean = false;
  public moduleCourseWorkExpanded: boolean = false;
  public lockModule: boolean = false;
  public moduleTests: any[] = [];
  public moduleTestsExpanded: boolean = false;
  public isAvailability: boolean = false;
  public hasTestAvailable: boolean;
  public ValuationTestModuleTypeEnum = ValuationTestModuleTypeEnum;
  public isAdmin: boolean;
  public LoggedUserisAdmin: boolean;
  public moduleTestsBeforeGrade: number = null;
  public moduleTestesAfterGrade: number = null;
  public moduleCourseWorkGrade: number = null;
  public disableTestMessage = 'Necessário estar matriculado para realizar essa ação';
  public ValuationTestTypeEnum = ValuationTestTypeEnum;
  public ValuationTestTypeTitle = {
    [ValuationTestTypeEnum.Coursework]: 'TRABALHO',
    [ValuationTestTypeEnum.Free]: 'AVALIAÇÃO',
    [ValuationTestTypeEnum.Percentile]: 'PESQUISAS',
  };
  public hasFinishedRequirements: boolean;

  public moduleMocksBeforeExpanded: boolean = false;
  public moduleMocks: any[] = [];
  public moduleSubjectMocks: any[] = [];
  public favoriteEnum = EFavoriteEnum;

  private _urlStateGuard = undefined;
  private _watchedVideo: boolean;


  listenerFn: () => void;
  constructor(
    protected _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _utilService: UtilService,
    private _sharedService: SharedService,
    private _userService: UserService,
    private _analyticsService: AnalyticsService,
    private _reportService: ReportsService,
    private _testsService: SettingsValuationTestsService,
    private _router: Router,
    private _effortService: EffortService,
    private _authService: AuthService,
    private _dialogService: MatDialog,
    private _valuationTestService: SettingsValuationTestsService,
    el: ElementRef
  ) {
    super(_snackBar);
    this.isAdmin = this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources);
    this.listenerFn = _effortService.listenEvent(el.nativeElement, 'mousemove');
    this.LoggedUserisAdmin = this._authService.LoggedUserisAdmin();
  }

  ngOnInit() {
    this._activatedRoute.data.subscribe((data: { module: Module }) => {
      this._activatedRoute.queryParams
        .pipe(filter((params) => params.trackId))
        .subscribe((params) => (this.trackModule = params.trackId));

      this.module = new Module(data.module);
      this.isAvailability = this.module.isAvailability;
      this.hasTestAvailable = this.isAvailability && this.checkRequirements();
      this.hasFinishedRequirements = this.checkRequirements();

      this._loadUserSubjectProgress(this.module)
        .then(userProgress => {
          this.module = this._setRequirementsProgress(this.module, userProgress.requirementsProgress);
          this._loadUserModuleInfo(this.module.id);
          this._loadModuleTests(this.module.id, this.trackModule);
        });

      localStorage.setItem(
        'forumQuestionDialog',
        JSON.stringify({
          moduleId: this.module.id,
          moduleName: this.module.title,
          subjectId: '',
          subjectName: '-',
          contentId: '',
          contentName: '-',
          position: '-',
        })
      );
      this._setIntroductionVideo();
      this._loadLevels();
      this.loadModuleForumPreview(this.module.id);

      window.scroll(0, 0);
    });

    this._effortService.startCountInteraction(
      this.module.id,
      TypeTargetEffort.module
    );
  }

  ngOnDestroy(): void {
    this._effortService.finishInteraction();
    if (this.listenerFn) this.listenerFn();
  }

  public getVideoDurationFormatted(): string {
    return this._utilService.formatSecondsToMinutes(this.module.videoDuration);
  }

  public getProgressForSubject(subjectId: string) {
    return this.userProgress &&
      this.userProgress.subjectsProgressDict[subjectId]
      ? this.userProgress.subjectsProgressDict[subjectId]
      : { level: 0, progress: 0 };
  }

  public checkRequirements(): boolean {
    if (!this.module) return false;
    if (
      this.module &&
      (!this.module.requirements || this.module.requirements.length === 0)
    )
      return true;

    return this.module.requirements.every((req) => req.optional || (req.progress && req.progress.level > req.level));
  }

  private _loadLevels(): void {
    this._sharedService.getLevels(true).subscribe((response) => {
      this.levels = response.data;
      this.levelDict = {};
      this.levels.forEach((level) => {
        this.levelDict[level.id] = level.description;
      });
    });
  }

  private _loadUserSubjectProgress(module: Module): Promise<any> {
    return this._userService.getUserModuleProgress(module.id)
      .toPromise()
      .then((response) => {
        this.userProgress = response.data;
        this.userProgress.subjectsProgressDict = {};
        this.userProgress.subjectsProgress.forEach(sbp => {
          this.userProgress.subjectsProgressDict[sbp.subjectId] = sbp;
        });
        return this.userProgress;
      });
  }

  private _loadUserModuleInfo(moduleId: string): void {
    this._userService.getBasicProgressInfo(moduleId).subscribe((response) => {
      console.log('_loadUserModuleInfo -> ', response.data);
      this.userModuleInfo = response.data;
      this.userModuleInfo.modulesInfo.forEach((element) => {
        if (
          element.validFor &&
          element.validFor > 0 &&
          element.id === moduleId
        ) {
          if (new Date(element.dueDate).getTime() < new Date().getTime()) {
            localStorage.setItem(
              'expiredModule',
              'Seu acesso ao módulo expirou'
            );
            this._router.navigate(['home']);
          }
        }
      });
    });
  }

  public loadModuleForumPreview(moduleId: string): void {
    this._userService
      .getModuleForumPreview(moduleId, 2)
      .subscribe((response) => {
        this.forumQuestionsPreview = response.data;
      });
  }

  private _setIntroductionVideo(): void {
    if (this.module.videoUrl && this.module.videoUrl !== '') {
      if (document.getElementById('videoContent')) {
        const w = window.innerWidth;
        if (w <= 414) {
          const options = {
            url: this.module.videoUrl,
            height: 181
          };
          this.player = new Player('videoContent', options);
          this._handleVideoLoaded(this.player);
          this._handleVideoPlayed(this.player);
        } else {
          const option = {
            url: this.module.videoUrl,
            height: 470
          };
          this.player = new Player('videoContent', option);
          this._handleVideoLoaded(this.player);
          this._handleVideoPlayed(this.player);
        }
      }
    } else {
      const videoEl = document.getElementById('videoContent');
      if (videoEl) videoEl.innerHTML = '';
    }
  }

  private _handleVideoLoaded(player): void {
    player.on('loaded', () => {
      const frame = document.querySelector('iframe');
      if (frame) {
        frame.style.width = '100%';
      }
      const divFrame = document.getElementById('videoContent');
      divFrame.style.visibility = 'initial';
    });
  }

  private _handleVideoPlayed(player) {
    player.on('play', () => {
      if (!this._watchedVideo) {
        this._watchedVideo = true;
        this._analyticsService
          .saveAction({
            page: ActionInfoPageEnum.Module,
            description: 'introduction-video',
            type: ActionInfoTypeEnum.VideoPlay,
            moduleId: this.module.id,
          })
          .subscribe(
            () => { },
            (error) => {
              console.error(error);
            }
          );
      }
    });
  }

  private _loadModuleTests(moduleId: string, trackId: string) {
    this._testsService.getModuleValuationTests(moduleId, trackId).subscribe(res => {
      const valuations = res.data;
      valuations.forEach(valuation => this._updateValuationProperties(valuation));

      if (valuations.some(x => x.type === ValuationTestTypeEnum.Percentile)) {
        this.moduleTestsResearch = valuations.filter(x => x.type === ValuationTestTypeEnum.Percentile);
        this.moduleTestsResearch.forEach(valuation => {
          valuation.isDisabled = this.disablePercentButtonLogic(valuation, this.getModuleTestPercent(valuation), ValuationTestTypeEnum.Percentile);
          valuation.statusText = this.disablePercentButtonLogicText(valuation, this.getModuleTestPercent(valuation), ValuationTestTypeEnum.Percentile);
        });
      }

      if (valuations.some(module => module.type === ValuationTestModuleTypeEnum.Coursework)) {
        this.moduleCourseWork = valuations.filter(module => module.type === ValuationTestModuleTypeEnum.Coursework);
        this.setValuationConfiguration(this.moduleCourseWork, 1, ValuationTestTypeEnum.Coursework);
      }

      if (valuations.some(x => x.type === ValuationTestTypeEnum.Free)) {
        const moduleTestsFree = valuations.filter(x => x.type === ValuationTestTypeEnum.Free);
        if (moduleTestsFree.some(x => x.testModules.some(y => y.type === ValuationTestModuleTypeEnum.BeforeModule))) {
          this.moduleTestsBefore = moduleTestsFree.filter(x =>
            x.testModules.some(y => y.type === ValuationTestModuleTypeEnum.BeforeModule)
          );
          this.lockModule =
            this._authService.hasRole(ERole.Student) && this.module.isEnrolled &&
            this.moduleTestsBefore.some(x => x.answered === false);

          this.setValuationConfiguration(this.moduleTestsBefore, 0, ValuationTestTypeEnum.Free);
        }

        if (moduleTestsFree.some(x => x.testModules.some(y => y.type === ValuationTestModuleTypeEnum.AfterModule))) {
          this.moduleTestsAfter = moduleTestsFree.filter(x =>
            x.testModules.some(y => y.type === ValuationTestModuleTypeEnum.AfterModule)
          );
          this.setValuationConfiguration(this.moduleTestsAfter, 1, ValuationTestTypeEnum.Free);
        }


        if (moduleTestsFree.some((x) => x.testModules.some((y) => y.type === ValuationTestModuleTypeEnum.AfterModule))) {
          this.moduleTestsAfter = moduleTestsFree.filter((x) =>
            x.testModules.some(
              (y) => y.type === ValuationTestModuleTypeEnum.AfterModule
            )
          );
        }
      }

      if (res.data.some(x => x.type === ValuationTestTypeEnum.Mock)) {
        const moduleMocks: any[] = res.data.filter(x => x.type === ValuationTestTypeEnum.Mock);
        moduleMocks.forEach(mock => {
          this.moduleMocks.push(mock);
          mock.testSubjects.forEach(mockTestSubject => {
            if (mockTestSubject.module.id === moduleId) {
              const moduleSubjectMockIndex = this.moduleSubjectMocks.findIndex(x => x.subjectId === mockTestSubject.id);
              if (moduleSubjectMockIndex === -1) {
                this.moduleSubjectMocks.push({
                  subjectId: mockTestSubject.id,
                  expanded: false,
                  tests: [mock]
                });
              } else {
                this.moduleSubjectMocks[moduleSubjectMockIndex].tests.push(mock);
              }
              const mockIndex = this.moduleMocks.findIndex(x => x.id === mock.id);
              if (mockIndex !== -1) {
                this.moduleMocks.splice(mockIndex, 1);
              }
            }
          });
        });
      }

    });
  }

  public setValuationConfiguration(arr: any[], percent: number, type: ValuationTestTypeEnum) {
    arr.forEach(valuation => {
      valuation.isDisabled = this.disablePercentButtonLogic(valuation, percent, type);
      valuation.statusText = this.disablePercentButtonLogicText(valuation, percent, type);
    });
  }

  public getModuleTestPercent(test: ValuationTest): number {
    return test.testModules.find((x) => x.id === this.module.id).percent;
  }

  public disablePercentButtonLogic(tests: ValuationTest, percent: number, type: ValuationTestTypeEnum): boolean {
    const answered: boolean = tests.answered;

    if (answered || (!tests.isAvailable && (type === ValuationTestTypeEnum.Percentile || type === ValuationTestTypeEnum.Coursework))) {
      return true;
    }
    if (percent > 1) {
      percent = percent / 100;
    }
    if (this.userProgress == null) {
      return true;
    } else {
      const progress = (this.userProgress.level + this.userProgress.progress) * 0.25;
      if (progress >= percent) {
        return false;
      } else {
        return true;
      }
    }
  }

  public getLogicDisableButtonCertificate(moduleTestsResearch: any[]): boolean {
    if (moduleTestsResearch.length === 0 || this.userProgress && this.userProgress.isExemption) {
      return false;
    } else if (moduleTestsResearch.length > 0) {
      const answered = moduleTestsResearch.filter((test) => !test.answered);
      return answered.length > 0;
    }
  }

  public disablePercentButtonLogicText(test: ValuationTest, percent: number, type: ValuationTestTypeEnum): string {

    if (test.answered) {
      if (test.type === ValuationTestTypeEnum.Coursework) {
        return 'Trabalho Respondido';
      } else {
        return 'Teste respondido';
      }
    }

    if (!test.isAvailable && type === ValuationTestTypeEnum.Percentile) {
      return `Avaliação fora do prazo`;
    }

    if (!test.isAvailable && type === ValuationTestTypeEnum.Coursework) {
      return `Trabalho fora do prazo`;
    }

    if (!test.isAvailable && test.valuationDate && new Date() > new Date(test.valuationDate)) {
      return `Avaliação fora do prazo`;
    }

    if (!this.checkRequirements()) {
      return 'Sem requisitos minimos';
    }

    if (percent > 1) {
      percent = percent / 100;
    }

    if (this.userProgress == null) {
      return 'Progresso necessário ' + (percent * 100).toString() + '%';
    } else {
      const progress = (this.userProgress.level + this.userProgress.progress) * 0.25;

      if (progress >= percent) {
        this._setUrlStateGuard();

        if (test.type === ValuationTestTypeEnum.Coursework) {
          return 'Visualizar trabalho';
        } else {
          return 'Fazer o teste';
        }

      } else {
        return 'Progresso necessário ' + (percent * 100).toString() + '%';
      }
    }
  }

  public goToTest(test: ValuationTest): void {

    if (test.type !== ValuationTestTypeEnum.Coursework) {
      this._setUrlStateGuard();
      this._router.navigate(['/teste-de-avaliacao/' + test.id], this._urlStateGuard);
    } else {
      this._router.navigate(['configuracoes/trabalho-final/' + test.id]);
    }
  }

  public disableGrade(): boolean {
    if (this.userProgress == null) {
      return true;
    }

    if (this.userProgress.level !== 4) {
      return true;
    }

    if (
      this.moduleTestsBefore.some((x) => x.answered === false) ||
      this.moduleTestsAfter.some((x) => x.answered === false) ||
      this.moduleTestsResearch.some((x) => x.answered === false)
    ) {
      return true;
    }

    return false;
  }

  private _setUrlStateGuard(): void {
    this._urlStateGuard = { state: { accessByComponent: true } };
  }

  private _setRequirementsProgress(
    module: Module,
    requirementsProgress: Array<RequirementProgress>
  ): Module {
    if (module.requirements) {
      module.requirements.forEach((req) => {
        const reqProgress = requirementsProgress.find(
          (p) => p.moduleId === req.moduleId
        );
        if (reqProgress) {
          req.progress = {
            moduleId: reqProgress.moduleId,
            level: reqProgress.level,
            progress: reqProgress.progress,
          };
        }
      });
    }

    return module;
  }

  public calculateGrade(moduleTest) {
    const grade = this._setGrade(moduleTest.answers);
    if (grade === null) {
      return '-';
    } else {
      return grade.toFixed(2).replace('.', ',');
    }
  }

  private _setGrade(answers: Array<{ grade: number }>) {
    if (answers.every(a => a.grade != null)) {
      return answers.reduce((sum, a) => sum + a.grade, 0) / 10;
    } else {
      return null;
    }
  }
  public goToPageEcommerceUrl() {
    window.open(this.module.ecommerceUrl, '_blank');
  }

  public goToPageStoreUrl() {
    window.open(this.module.storeUrl, '_blank');
  }

  public canSeeAnswers(): boolean {
    const now = new Date();

    if (this.module &&
      this.module.moduleConfiguration &&
      this.module.moduleConfiguration.valuationDate &&
      this.module.moduleConfiguration.cutOffDate &&
      new Date(this.module.moduleConfiguration.valuationDate) < now) {
      return true;
    }

    if (this.module &&
      this.module.moduleConfiguration &&
      !this.module.moduleConfiguration.valuationDate &&
      this.module.moduleConfiguration.cutOffDate &&
      new Date(this.module.moduleConfiguration.cutOffDate) < now) {
      return true;
    }

    if (this.module &&
      this.module.moduleConfiguration &&
      isNil(this.module.moduleConfiguration.valuationDate &&
        this.module.moduleConfiguration.cutOffDate &&
        this.userProgress === 100)) {
      return true;
    }
    return false;
  }

  public getCertificate(): void {
    this._userService
      .GenerateCertificatePdf(this.module.id, 'module')
      .subscribe((response) => {
        this.notify('Certificado emitido com sucesso');
      });
  }

  public canGenerateCertificate(): boolean {
    return (
      (this.userProgress && this.userProgress.progress === 1) ||
      ((this.userProgress &&
        this.userProgress.level === EnumLevel.NIVEL_4 || this.userProgress && this.userProgress.isExemption) &&
        this.module.hasCertificate &&
        this.hasFeatureCertificate)
    );
  }

  public viewQuestionsAndAnswersDialog(test: any) {
    this._valuationTestService.getValuationTestById(test.id)
      .pipe(take(1))
      .subscribe(valuationTest => {
        this._dialogService.open(QuestionsAndAnswersDialogComponent, {
          width: '1000px',
          height: '90%',
          data: { test: test, valuationTest: valuationTest.data }
        });
      });
  }

  private _updateValuationProperties(test: ValuationTest): void {
    const testModule = test.testModules.find(valuationModule => valuationModule.id === this.module.id);
    const now = new Date();
    test.hasStarted = true;
    test.isAvailable = this.module.isAvailability && this.hasFinishedRequirements;

    if (!isNil(this.module.moduleConfiguration)) {
      const moduleAlreadyStarted = differenceInSeconds(new Date(this.module.moduleConfiguration.openDate), now) <= 0;
      if (!moduleAlreadyStarted) return;
    }

    const valuationConfiguration = this.module.valuationsConfiguration ?
      this.module.valuationsConfiguration.modules
        .find(valuationTest => valuationTest.testId === test.id && valuationTest.itemId === testModule.id)
      : null;

    if (isNil(valuationConfiguration)) return;
    test.hasStarted = false;
    test.isAvailable = false;

    const endDate = valuationConfiguration.valuationDate;
    const openDate = valuationConfiguration.openDate;

    let _isAvailability = false;

    const isAlwaysAvailable: boolean = valuationConfiguration && (
      valuationConfiguration.alwaysAvailable ||
      isNil(valuationConfiguration.alwaysAvailable) ||
      (valuationConfiguration.alwaysAvailable === false && isNil(openDate))
    );

    const hasValuationDate = !!valuationConfiguration.valuationDate;

    const valuationAlreadyStarted = differenceInSeconds(openDate, now) <= 0;
    const isOnDeadLine = hasValuationDate && differenceInSeconds(now, valuationConfiguration.valuationDate) < 0;

    if (valuationAlreadyStarted) {
      _isAvailability = isNil(endDate) ? true : isOnDeadLine && this.hasFinishedRequirements;
    }

    test.canSeeAnswers = this._canSeeAnswers(valuationConfiguration);
    test.hasStarted = valuationAlreadyStarted;
    test.isAvailable = isAlwaysAvailable || _isAvailability;
  }

  private _canSeeAnswers({ cutOffDate, valuationDate, alwaysAvailable }): boolean {

    const now = new Date();

    const hasFinishedModule = (this.userProgress) && (this.userProgress.progress === 100 ||
      (this.levels && (this.userProgress.level === this.levels.length)));

    const endDate = cutOffDate || valuationDate;
    const differenceBetweenDates = differenceInSeconds(addDays(endDate, 1), now);
    const hasPassedEndDate = differenceBetweenDates < 0;

    return !alwaysAvailable && hasPassedEndDate && hasFinishedModule;
  }

  public getSubjectMocks(subjectId: string) {
    const index = this.moduleSubjectMocks.findIndex(x => x.subjectId === subjectId);
    if (index === -1) {
      return null;
    } else {
      return this.moduleSubjectMocks[index];
    }
  }

  public openOpinionDialog() {
    const dialogRef = this._dialogService.open(ModuleOpinionDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'custom-dialog',
      data: { moduleId: this.module.id }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.module.opinion = true;
      }
    });
  }
}
