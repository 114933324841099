import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import {
  StudentReport,
  AnswerReport,
  AnswerLevelReport,
  AnswerReportFilter
} from 'src/app/pages/report/report-student-dashboard/models/report-student-dashboard.component.model';

import {
  DialogReportStudentDashboardComponent
} from 'src/app/pages/report/dialogs/dialog-report-student-dashboard/dialog-report-student-dashboard.component';
import {
  FilterReportStudentDashboardComponent
} from 'src/app/pages/report/filters/filter-report-student-dashboard/filter-report-student-dashboard.component';
import { find, isNil, isEmpty } from 'lodash';
import { format } from 'date-fns';
import { CategoryFilterSearchOption } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { Level } from 'src/app/shared/classes/level';
import { DialogReportStudentBySubjectDashboardComponent } from '../dialogs/dialog-report-student-by-subject-dashboard/dialog-report-student-by-subject-dashboard.component';

@Component({
  selector: 'app-report-student-dashboard',
  templateUrl: './report-student-dashboard.component.html',
  styleUrls: ['./report-student-dashboard.component.scss']
})
export class ReportStudentDashboardComponent extends NotificationClass {

  @ViewChild('FilterComponent', { static: true })
  public filterComponent: FilterReportStudentDashboardComponent;
  public readonly pageSize: number = 20;
  public get filter() { return this.filterComponent.filter; }
  public usersCount: number = 0;
  public showAllSelectedUsers: boolean = false;
  public hasProfileTest: boolean = environment.features.profileTest;
  public isBTG: boolean = true;
  public user: LoginResponse;

  private _filterDescription: {
    track: string,
    modules: string,
    subjects?: string
  };

  constructor(
    protected _snackBar: MatSnackBar,
    private _reportService: ReportsService,
    private _excelService: ExcelService,
    private _dialog: MatDialog,
    _authService: AuthService
  ) {
    super(_snackBar);
    _authService.isLoggedIn().subscribe((x) => {
      this.user = _authService.getLoggedUser();
    });
  }

  public openDialog() {
    const callback = rows => {
      const dialogRef = this._dialog.open(DialogReportStudentDashboardComponent, {
        maxWidth: '100vw',
        width: '100vw',
        minHeight: '100vh',
        height: '100vh',
        hasBackdrop: false,
        data: rows
      });
      dialogRef.afterClosed().subscribe((rowsFromDialog) => {
        if (rowsFromDialog) {
          this._createReport(rowsFromDialog);
        }
      });
    };

    this.notify('Dependendo da seleção do filtro, a visualização dos dados pode levar alguns minutos');

    this._loadTableReport(this.filter, callback);
  }

  public openBySubjectDialog() {
    const callback = rows => {
      const dialogRef = this._dialog.open(DialogReportStudentBySubjectDashboardComponent, {
        maxWidth: '100vw',
        width: '100vw',
        minHeight: '100vh',
        height: '100vh',
        hasBackdrop: false,
        data: rows
      });
      dialogRef.afterClosed().subscribe((rowsFromDialog) => {
        if (rowsFromDialog) {
          this._createReportBySubject(rowsFromDialog);
        }
      });
    };

    this.notify('Dependendo da seleção do filtro, a visualização dos dados pode levar alguns minutos');

    this._loadTableReportBySubject(this.filter, callback);
  }

  public exportReport() {
    this._loadTableReport(this.filter, (rowsFromServer: StudentReport[]) => this._createReport(rowsFromServer));
  }

  private _createReport(rows: StudentReport[]) {

    const getLevelName = (row: StudentReport): string => {
      if (row.exemption) {
        return '--';
      } else {
        return Level.getLevelName(row.level);
      }
    };

    const getAnswers = (answersLevel: AnswerLevelReport) => answersLevel ? answersLevel.answer : new AnswerReport();

    const reportExcel =
      rows.map(row => ({
        'Nome': row.userName,
        'Businnes group': row.businessGroup,
        'Businnes unit': row.businessUnit,
        'Segment': row.segment,
        'Trilha': row.trackTitle,
        'Área': row.trackAreaTitle,
        'Sub-Área': row.trackSubAreaTitle,
        'Módulo': row.moduleName,
        'Iniciou módulo': row.startedModule,
        'Nível atual': getLevelName(row),
        'Data início uso BDQ': row.bdqStartDate,
        'Data fim uso BDQ': row.bdqEndDate,
        'Data de corte': row.cutOffDate,
        'Total de questões BDQ': row.totalModuleQuestion,
        'Total de questões respondidas': row.totalQuestionAnswered,
        '% de acertos': row.percentageOfCorrectAnswers,
        '% de erros': row.percentageOfWrongAnswers,
        'Questões nível-1 corretas': getAnswers(row.answerBeginnerReport).correct,
        'Questões nível-1 incorretas': getAnswers(row.answerBeginnerReport).wrong,
        'Questões nível-2 corretas': getAnswers(row.answerIntermediateReport).correct,
        'Questões nível-2 incorretas': getAnswers(row.answerIntermediateReport).wrong,
        'Questões nível-3/nível-4 corretas': getAnswers(row.answerAdvancedReport).correct,
        'Questões nível-3/nível-4 incorretas': getAnswers(row.answerAdvancedReport).wrong,
        'Nota como prova': row.gradeAsProof,
        'Nota do BDQ': row.bdqGrade,
        'Nota da avaliação diagnóstica': row.beforeModuleGrade,
        'Nota da avaliação final': row.afterModuleGrade,
        'Média do módulo': row.grade,
        'Matrícula': row.registrationId,
        'Total de conteúdos': row.totalModuleContent || 0,
        'Total de conteúdos visualizados': row.totalContentCompleted || 0,
        'Código do Módulo': row.moduleId,
        'Código da Trilha:': row.trackId
      }));

    const header = [
      ['Trilha:', this._filterDescription.track]
      , ['Módulo:', this._filterDescription.modules]
      , ['Observação', 'Todos os horários estão em UTC(-3), horário de Brasília']
      , []
    ];

    this._excelService.exportAsExcelFile(reportExcel, 'Dashboard dos alunos_', header);

  }

  private _createReportBySubject(rows: StudentReport[]) {

    const getLevelName = (row: StudentReport): string => {
      if (row.exemption) {
        return '--';
      } else {
        return Level.getLevelName(row.level);
      }
    };

    const getAnswers = (answersLevel: AnswerLevelReport) => answersLevel ? answersLevel.answer : new AnswerReport();

    const reportExcel =
      rows.map(row => ({
        'Nome': row.userName,
        'Trilha': row.trackTitle,
        'Área': row.trackAreaTitle,
        'Sub-Área': row.trackSubAreaTitle,
        'Módulo': row.moduleName,
        'Assunto': row.subjectName,        
        'Terminou Assunto': row.finishedSubject,
        'Nível atual': getLevelName(row),
        'Data início uso BDQ': row.bdqStartDate,
        'Data fim uso BDQ': row.bdqEndDate,
        'Total de questões BDQ': row.totalSubjectQuestion,
        'Total de questões respondidas': row.totalSubjectQuestionAnswered,
        '% de acertos': row.percentageOfCorrectAnswers,
        '% de erros': row.percentageOfWrongAnswers,
        'Questões nível-1 corretas': getAnswers(row.answerBeginnerReport).correct,
        'Questões nível-1 incorretas': getAnswers(row.answerBeginnerReport).wrong,
        'Questões nível-2 corretas': getAnswers(row.answerIntermediateReport).correct,
        'Questões nível-2 incorretas': getAnswers(row.answerIntermediateReport).wrong,
        'Questões nível-3/nível-4 corretas': getAnswers(row.answerAdvancedReport).correct,
        'Questões nível-3/nível-4 incorretas': getAnswers(row.answerAdvancedReport).wrong,
        'Matrícula': row.registrationId,
        'Total de conteúdos': row.totalSubjectContent || 0,
        'Total de conteúdos visualizados': row.totalContentCompleted || 0
      }));

    const header = [
      ['Módulo:', this._filterDescription.modules],
      ['Assuntos:', this._filterDescription.subjects],
      ['Observação', 'Todos os horários estão em UTC(-3), horário de Brasília'],
      []
    ];

    this._excelService.exportAsExcelFile(reportExcel, 'Dashboard dos alunos_', header);

  }

  private _findDescriptionByColumnName(columnName: string) {
    const hasCategories = !isNil(this.filter.categories) || !isEmpty(this.filter.categories);
    const filterCategory = find(this.filter.categories, category => category.columnName === columnName);
    const hasContentNames = filterCategory.contentNames;

    return hasCategories && !isEmpty(hasContentNames) ?
      filterCategory.contentNames
        .map(c => c.description)
        .reduce((acc, next) => acc.concat(`, ${next}`))
      : ' - ';
  }

  private _loadTableReport(filter: AnswerReportFilter, callback: Function): void {
    if (!filter) return;

    this._filterDescription = {
      track: this._findDescriptionByColumnName('track.id'),
      modules: this._findDescriptionByColumnName('module.id')
    };

    const parameters = {
      page: null,
      pageSize: null,
      sort: null,
      sortAscending: null,
      categories: this._getFilterCategories(),
      userId: filter.student ? filter.student.id : undefined
    };

    this._reportService
      .getStudentDashboard(parameters)
      .subscribe(response => {
        if (response && response.data) {
          response.data.forEach(row => {
            row.bdqStartDate = !row.bdqStartDate ? '--' : format(row.bdqStartDate, 'DD/MM/YYYY HH:mm:ss');
            row.bdqEndDate = !row.bdqEndDate ? '--' : format(row.bdqEndDate, 'DD/MM/YYYY HH:mm:ss');
          });
        }
        return callback(response.data);
      });
  }


  private _getFilterCategories() {
    return this.filter.categories.map(category => ({
      columnName: category.columnName,
      contentNames: category.contentNames.map(c => category.columnName.includes('.id') ? c.id : c.description)
    }) as CategoryFilterSearchOption);
  }

  public exportReportBySubject() {
    this._loadTableReportBySubject(this.filter, (rowsFromServer: StudentReport[]) => this._createReportBySubject(rowsFromServer));
  }

  private _loadTableReportBySubject(filter: AnswerReportFilter, callback: Function): void {
    if (!filter) return;

    this._filterDescription = {
      track: this._findDescriptionByColumnName('track.id'),
      modules: this._findDescriptionByColumnName('module.id'),
      subjects: this._findDescriptionByColumnName('subject.id')
    };

    const parameters = {
      page: null,
      pageSize: null,
      sort: null,
      sortAscending: null,
      categories: this._getFilterCategories(),
      userId: filter.student ? filter.student.id : undefined
    };

    this._reportService
      .getStudentDashboardBySubject(parameters)
      .subscribe(response => {
        if (response && response.data) {
          response.data.forEach(row => {
            row.bdqStartDate = !row.bdqStartDate ? '--' : format(row.bdqStartDate, 'DD/MM/YYYY HH:mm:ss');
            row.bdqEndDate = !row.bdqEndDate ? '--' : format(row.bdqEndDate, 'DD/MM/YYYY HH:mm:ss');
          });
        }
        return callback(response.data);
      });
  }

}
