<div class="module-subject">
  <div class="header" (click)="onExpandHeader($event)">
    <div class="header-content">
      <p class="flex-start">{{ subject.title }}</p>
      <small class="flex-start"
        *ngIf="subject && subject.duration && subject.duration !== 0">{{ subject.duration | secondToMinute }}
        minuto(s)</small>
    </div>
    <div class="header-content">
      <div *ngIf="subject.hasQuestions && hasFeatureLevels">
        <mat-icon [svgIcon]="(subjectProgress?.level || 0) | completedLevelImage"></mat-icon>

        <span [ngStyle]="{'color': (subjectProgress?.level || 0) | levelColor}"
          *ngIf=" (levels && !(subjectProgress?.progress==='1' ))">{{levels[subjectProgress?.level]}}</span>

        <small [ngStyle]="{'color': (subjectProgress?.level || 0) | levelColor}"
          *ngIf=" !(subjectProgress?.progress==='1' ) && (subjectProgress.level !==4)">
          &nbsp;{{subjectProgress?.progress*100|number:'1.0-0'}}%
        </small>
        <span *ngIf="subjectProgress.level === 4"
          [ngStyle]="{'color': (subjectProgress?.level || 0) | levelColor}">Nível-4</span>
      </div>
      <div>
        <mat-icon class="content-icon"
          [ngClass]="{'finished-content': (subjectProgress?.contentsCompletedCount || 0) === subject.contents.length}">
          check_circle</mat-icon>
        <span>{{subjectProgress?.contentsCompletedCount || 0}} / {{subject.contents.length}} Conteúdos
          finalizados</span>
      </div>
    </div>
  </div>
  <div class="content" *ngIf="expanded">
    <div class="level" *ngIf="subject.hasQuestions && module.hasQuestions">
      <p *ngIf="levels">
        <ng-container *ngIf="!reachedMaxLevel() && hasFeatureLevels">
          <small>Nível necessário</small><br>
          {{levels[subjectProgress?.level]}} -
          {{subject.userProgresses[subjectProgress?.level]?.percentage*100|number:'1.0-0'}}%
        </ng-container>
      </p>
      <app-btn-exam [module]="module" [isExemption]="userProgress?.isExemption" [subjectId]="subjectId"
        [blocked]="subjectProgress.bloacked" [reachedMaxLevel]="reachedMaxLevel()"
        [hasFinishedRequirements]="hasFinishedRequirements" [levels]="levels">
      </app-btn-exam>
    </div>

    <app-subject-content (click)="goToContent(index)" *ngFor="let content of subject.contents; let index = index"
      [moduleId]="module.id" [subject]="subject" [index]="index" [subjectProgress]="subjectProgress"
      [hasFinishedRequirements]="hasFinishedRequirements">
    </app-subject-content>

    <ng-container *ngIf="module?.questionCreationValuation">
      <div class="subject-content">
        <div style="width: 100%">
          <div class="header">
            <p class="title">
              Criar / Avaliar Questão <span>*obrigatório</span>
            </p>
          </div>
          <p class="description">
            Avalie uma questão criada por outro Alumni e crie sua própria pergunta
            para poder concluir este assunto.
          </p>
        </div>
        <button class="btn-test" (click)="goToCreateValuateQuestion()" *ngIf="!questionCreationValuationStatus">
          Criar/Avaliar
        </button>
        <img class="valuation-icon" [src]="'./assets/img/status-success.png'" *ngIf="questionCreationValuationStatus" />
      </div>
    </ng-container>
  </div>
</div>
<div class="expand" (click)="expanded = !expanded" [ngClass]="{ 'expanded' : expanded }">
  <span *ngIf=!expanded><i class="icon icon-seta_bx seta"></i></span>
  <span *ngIf=expanded><i class="icon icon-seta_cima seta"></i></span>
</div>
