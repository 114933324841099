import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Segment } from 'src/app/models/segment.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { SegmentService } from '../_services/segments.service';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit {

  public page: number = 1;
  public readonly pageSize: number = 8;
  public itemsCount: number = 0;
  public segments: Segment[] = [];
  constructor(private _router: Router,
    private _segmentService: SegmentService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadSegment();
  }

  public loadSegment() {
    this._segmentService.GetAllSegmentsPaged(this.page, this.pageSize).subscribe(response => {
      this.segments = response.data.segments;
      this.itemsCount = response.data.itemsCount;
    });
  }

  public goToPage(page: number) {
    this.page = page;
    this.loadSegment();
  }

  public goTo(segment: Segment = null) {
    const url = 'configuracoes/segmento' + (segment === null ? '' : '/' + segment.id);
    this._router.navigate([url]);
  }

  public delete(segment: Segment) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: { message: 'Tem certeza que excluir este segmento ?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._segmentService.deleteSegment(segment.id).subscribe(value => {
          this.loadSegment();
        });
      }

    });
  }
}
