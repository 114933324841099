import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CustomControlMessagesComponent } from './control-messages.component';

@NgModule({
  declarations: [CustomControlMessagesComponent],
  imports: [BrowserModule],
  exports: [CustomControlMessagesComponent]
})
export class ValidationModule { }
