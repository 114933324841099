import { Injectable } from '@angular/core';
import { BackendResponse, BackendService } from '@tg4/http-infrastructure';
import { DownloadService } from 'src/app/shared/services/download.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ERole } from 'src/app/models/enums/role.enum';
import { isNil } from 'lodash';

@Injectable()
export class UserService {

  constructor(
    private _httpService: BackendService,
    private _DownloadService: DownloadService) { }

  public getUserSubjectProgress(moduleId, subjectId): Observable<any> {
    return this._httpService.get('getsubjectprogress', [], [
      { name: 'moduleId', value: moduleId },
      { name: 'subjectId', value: subjectId }]);
  }

  public getUserModuleProgress(moduleId): Observable<any> {
    return this._httpService.get('getmoduleprogress', [], [
      { name: 'moduleId', value: moduleId }]);
  }

  public getUserCareerPdf(userId): Observable<any> {
    const token: any = JSON.parse(localStorage.getItem(LocalStorageService.key.authData)).access_token;
    return this._DownloadService._downloadFile('getUserCareerPdf', {
      'userId': userId,
      'token': token
    });
  }
  public GenerateCertificatePdf(trackId: string, where: string): Observable<any> {
    const impersonatedUser = JSON.parse(localStorage.getItem('ImpersonatedUser'));
    const auth_data = localStorage.getItem('auth_data');
    const userId = !isNil(impersonatedUser) && !isNil(impersonatedUser.userId)
      ? impersonatedUser.userId
      : JSON.parse(auth_data).user_id;
    return this._DownloadService._downloadFile('getCertificatePdf', {
      'id': trackId,
      'token': auth_data,
      'where': where,
      'userId': userId
    });
  }

  public getModuleForumPreview(moduleId: string, pageSize: number): Observable<any> {
    return this._httpService.post('getForumPreviewByModule', {
      'moduleId': moduleId,
      'pageSize': pageSize
    });
  }

  public getEventForumPreview(eventScheduleId: string, pageSize: number): Observable<any> {
    return this._httpService.post('getEventForumPreviewByEventSchedule', {
      'eventScheduleId': eventScheduleId,
      'pageSize': pageSize
    });
  }

  public getUserModulesProgress(): Observable<any> {
    return this._httpService.get('getmodulesprogress');
  }

  public getUserTracksProgress(): Observable<any> {
    return this._httpService.get('gettracksprogress');
  }

  public getUserTrackProgress(trackId: string): Observable<any> {
    return this._httpService.get('getTrackProgress', [], [
      { name: 'trackId', value: trackId }]);
  }

  public getContactAreas(): Observable<any> {
    return this._httpService.get('getContactAreas');
  }

  public sendMessage(areaId: string, title: string, text: string, fileUrl: string): Observable<any> {
    return this._httpService.post('sendMessage', {
      'areaId': areaId,
      'title': title,
      'text': text,
      'fileUrl': fileUrl
    });
  }

  public blockUserMaterial(userId: string, trackId: string, moduleId: string, eventScheduleId: string): Observable<any> {
    return this._httpService.post('blockUserMaterial', {
      'userId': userId,
      'trackId': trackId,
      'moduleId': moduleId,
      'eventScheduleId': eventScheduleId
    });
  }

  public seeHow(userRoleToChange: ERole): Observable<any> {
    return this._httpService.post('seeHow', {
      'userRoleToChange': userRoleToChange
    });
  }

  public getBasicProgressInfo(entityId: string) {
    return this._httpService.get('getBasicProgressInfo', [], [
      { name: 'entityId', value: entityId }]);
  }

  public GetCertificatePdf(trackId: string, token: string): Observable<any> {
    return this._httpService.httpRaw.post('getCertificatePdf', {
      'trackId': trackId,
      'token': token,
    });
  }

  public FavoriteCourse(id: string, type: number, favorite: boolean): Observable<any> {
    return this._httpService.post('favorite', {
      id,
      type,
      favorite
    });
  }

  public getLevelColor(level: number) {
    switch (level) {
      case 0:
        return '#04b577';
      case 1:
        return '#0369DB';
      case 2:
        return '#6F5EED';
      case 3: case 4:
        return '#3C3C3C';
      default:
        const style = getComputedStyle(document.body);
        const finished_progress_color = style.getPropertyValue('--finished-progress-color');
        return finished_progress_color;
    }
  }

  public getLevelClass(level: number) {
    switch (level) {
      case 1:
        return '#0369DB';
      case 2:
        return '#6F5EED';
      case 3:
        return '#3C3C3C';
      case 0:
      default:
        const style = getComputedStyle(document.body);
        const finished_progress_color = style.getPropertyValue('--finished-progress-color');
        return finished_progress_color;
    }
  }

  public getLevelGreyImage(level: number): string {
    switch (level) {
      case 1:
        return './assets/img/glasses-icon-gray.png';
      case 2:
      case 3:
        return './assets/img/brain-icon-gray.png';
      case 0:
      default:
        return './assets/img/pencil-icon-gray.png';
    }
  }
  public getLevelIcon(level: number): string {
    switch (level) {
      case 1:
        return 'glasses-icon';
      case 2:
        return 'brain-icon';
      case 3: case 4:
        return 'brain-dark-icon-shadow';
      case 0:
      default:
        return 'pencil-icon';
    }
  }
  public getCompletedLevelImage(level: number) {
    switch (level) {
      case 1:
        return './assets/img/pencil-icon.png';
      case 2:
        return './assets/img/glasses-icon.png';
      case 3:
        return './assets/img/brain-icon.png';
      case 4:
        return './assets/img/brain-dark-icon-shadow.png';
      case 0:
      default:
        return './assets/img/empty-badge.png';
    }
  }

}
