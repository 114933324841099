import { UtilService } from 'src/app/shared/services/util.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Module } from '../../../../../models/module.model';
import { SupportMaterial, SupportMaterialType } from '../../../../../models/support-material.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../../../../shared/services/shared.service';
import { FileUploadClass } from '../../../../../shared/classes/file-upload';
import { UploadService } from '../../../../../shared/services/upload.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-new-module-support-materials',
  templateUrl: './support-materials.component.html',
  styleUrls: ['../new-module-steps.scss', './support-materials.component.scss']
})
export class NewModuleSupportMaterialsComponent extends FileUploadClass implements OnInit, OnDestroy {

  @Input() module$: Observable<Module>;
  public module: Module;
  @Output() addSupportMaterials = new EventEmitter<Array<SupportMaterial>>();

  public formGroup: FormGroup;
  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    protected _sharedService: SharedService,
    protected _uploadService: UploadService,
    private _utilService: UtilService
  ) {
    super(_snackBar, _sharedService, _uploadService);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = cloneDeep(moduleItems);
        if (this.formGroup && moduleItems.supportMaterials) {
          this.updateComponent(moduleItems);
        } else {
          this.formGroup = this._createFormGroup(moduleItems);
        }
      });
    }
  }

  public updateComponent(module: Module) {

    let moduleMaterial = this.formGroup.get('materials') as FormArray;

    moduleMaterial ? moduleMaterial.clear() : moduleMaterial = new FormArray([]);

    module.supportMaterials.forEach((mat) => {
      moduleMaterial.push(this._createMaterialForm(mat));
    });
  }

  public addMaterial(): void {
    const materials = this.formGroup.get('materials') as FormArray;
    materials.push(
      this._createMaterialForm()
    );
  }

  public removeMaterial(index: number): void {
    const materials = this.formGroup.get('materials') as FormArray;
    materials.removeAt(index);
  }

  public openFileUpload(index: number): void {
    (document.getElementById('inputFile' + index) ).click();
  }

  public nextStep(): void {
    if (this.formGroup.valid) {
      const formArray = this.formGroup.getRawValue();
      this.addSupportMaterials.emit(formArray.materials);
    } else
      this.notify('Por favor, preencha todos os campos obrigatórios');
  }

  private _createFormGroup(module: Module): FormGroup {
    return new FormGroup({
      'materials': this._setMaterialsFormArray(module)
    });
  }

  private _setMaterialsFormArray(module: Module): FormArray {
    if (module && module.supportMaterials) {
      return new FormArray(
        module.supportMaterials.map((mat) => this._createMaterialForm(mat))
      );
    } else {
      return new FormArray([
        this._createMaterialForm()
      ]);
    }
  }

  private _createMaterialForm(material: SupportMaterial = null): FormGroup {
    return new FormGroup({
      'id': new FormControl(material ? material.id : ''),
      'title': new FormControl(material ? material.title : '', [Validators.required]),
      'fileName': new FormControl(material ? material.title : ''),
      'imageUrl': new FormControl(material ? material.imageUrl : null),
      'downloadLink': new FormControl(material ? material.downloadLink : '', [Validators.required]),
      'description': new FormControl(material ? material.description : '', [Validators.required]),
      'type': new FormControl(material && material.type ? material.type : SupportMaterialType.File, [Validators.required]
      )
    });
  }

}
