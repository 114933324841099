import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'excerpt' })
export class ExcerptPipe implements PipeTransform {
    transform(value: string): any {
        if (value && value.length > 130) {
            return value.substring(0, 130) + '...';
        } else {
            return value;
        }
    }
}
