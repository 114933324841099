<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <div class="header">
    <h3>Segmentos</h3>
  </div>
  <div class="main">
    <mat-form-field class="form" floatLabel="always">
      <mat-label class="mat-label">Nome do segmento</mat-label>
      <input matInput class="input-search" [(ngModel)]="segment.name" placeholder="Digite o nome do segmento">
    </mat-form-field>
    <div class="title">
      <h3>Associar Setor</h3>
      <label>Setores que podem ser associados ao segmento</label>
    </div>
    <mat-form-field appearance="fill">
      <mat-select placeholder="Selecione um setor..." [compareWith]="compareFn" [(ngModel)]="sector">
        <mat-option *ngFor="let sector of sectors" [value]="sector">{{sector.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="btn-test" (click)="save()">
      Salvar Alterações
    </button>
  </div>
</div>
