<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <h2 class="title">CADASTRO DE SUB-ÁREAS</h2>

  <div class="headerAdd">
    <input class="inputTitle" [(ngModel)]="trackSubAreaTitle">

    <button class="btn-outline btn" appDebounceClick (debounceClick)="add()">
      ADICIONAR
    </button>
  </div>

  <table align="center" mat-table [dataSource]="trackSubAreas" class="mat-elevation-z8 tableList">

    <ng-container matColumnDef="title">
      <th width="80%" mat-header-cell *matHeaderCellDef> Nome </th>
      <td width="80%" mat-cell *matCellDef="let row">
        {{ row.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th width="20%" mat-header-cell *matHeaderCellDef> </th>
      <td width="20%" mat-cell *matCellDef="let row">

        <button class="btn-outline btn" (click)="removeConfirm(row.id)">
          excluir
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <app-pagination *ngIf="trackSubAreasCount" [hidden]="trackSubAreasCount === 0" [setPagination]="{
        'itemsCount': trackSubAreasCount,
        'pageSize': 10
      }" (goToPage)="goToPage($event)"></app-pagination>
</div>
