import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { Event } from 'src/app/models/event.model';
import { Module } from 'src/app/models/module.model';
import { Valuations } from 'src/app/models/my-course-valuation.model';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { ModuleProgress } from 'src/app/models/previews/module-progress.interface';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { ProfileTestResponse } from 'src/app/models/profile-test.interface';
import { Suggestion } from 'src/app/models/suggestions.model';
import { User } from 'src/app/models/user.model';
import { ProgramPreview } from 'src/app/settings/programs/Models/programPreview.model';
import { SettingsProfileTestsService } from 'src/app/settings/_services/profile-tests.service';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { ContentEventsService } from '../../_services/events.service';
import { ContentModulesService } from '../../_services/modules.service';
import { ProgramsService } from '../../_services/programs.service';
import { ContentTracksService } from '../../_services/tracks.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-management-student-courses',
  templateUrl: './management-student-courses.component.html',
  styleUrls: ['./management-student-courses.component.scss']
})
export class ManagementStudentCoursesComponent implements OnInit {

  public replacement = environment.replecement;
  public hasHomeV2 = environment.features.hasHomeV2;

  public modules: Array<ModulePreview>;
  public suggestions: Array<Suggestion>;
  public test: ProfileTestResponse;
  public defaultTest: ProfileTestResponse;

  public tracks: Array<TrackPreview>;
  public tracksCount: number = 0;
  private _tracksPage: number = 1;
  private _valuationPage: number = 1;

  public allModules: Array<ModulePreview>;
  public modulesCount: number = 0;
  private _modulesPage: number = 1;

  public translatedValue: number = 0;
  public scrollLimit: number = 1;
  public viewTypeModules: string = 'cards';
  public viewTypeTracks: string = 'cards';
  public moduleProgress: any = {};
  public trackProgress: any = {};
  public levels: any;
  public levelDict: any;
  public teste: string;
  public valuationPeding: Array<Valuations> = [];

  public userId: string;
  public user: User;
  public isManagerView: boolean = false;

  constructor(
    private _settingsValuationTestsService: SettingsValuationTestsService,
    private _profileTestsService: SettingsProfileTestsService,
    private _modulesService: ContentModulesService,
    private _tracksService: ContentTracksService,
    private _userService: UserService,
    private _sharedService: SharedService,
    private _authService: AuthService,
    private _router: Router,

  ) { }

  ngOnInit() {
    this._loadLevels();
    this._loadModules();
    this._loadTracks();
    this._loadProgress();
    this._loadSuggestions();
    this.userId = this._authService.getLoggedUserId();
    this.isManagerView = this._authService.isSeenHowManager();
    this._loadValuation();
  }

  public hasContent(): boolean {
    return (this.allModules && this.allModules.length > 0) ||
      (this.tracks && this.tracks.length > 0);
  }

  public goToStore(suggestion: Suggestion): void {
    if (suggestion.storeUrl && suggestion.storeUrl.trim() !== '') {
      const hasProtocol = suggestion.storeUrl.split('http').length > 1;
      window.location.href = hasProtocol ? suggestion.storeUrl : '//' + suggestion.storeUrl;
    }
  }

  private _loadProgress(): any {
    this._userService.getUserModulesProgress().subscribe((response) => {
      this.moduleProgress = {};
      response.data.forEach(x => {
        this.moduleProgress[x.moduleId] = x;
      });
    });

    this._userService.getUserTracksProgress().subscribe((response) => {
      this.trackProgress = {};
      response.data.forEach(x => {
        this.trackProgress[x.trackId] = x;
      });
    });
  }

  private _loadLevels(): void {
    this._sharedService.getLevels(true).subscribe((response) => {
      this.levels = response.data;
      this.levelDict = {};
      this.levels.forEach(level => {
        this.levelDict[level.id] = level.description;
      });
    });
  }

  public changeViewTypeModules(): void {
    this.viewTypeModules = this.viewTypeModules === 'cards' ? 'list' : 'cards';
  }

  public changeViewTypeTracks(): void {
    this.viewTypeTracks = this.viewTypeTracks === 'cards' ? 'list' : 'cards';
  }

  public goToPage(page: number) {
    if (page !== this._modulesPage) {
      this._modulesPage = page;
      this._loadModules();
    }
  }

  public goToPageTrack(page: number) {
    if (page !== this._tracksPage) {
      this._tracksPage = page;
      this._loadTracks();
    }
  }

  private _loadModules(sort = null): void {
    this._modulesService.getPagedFilteredMyCoursesModulesList(
      this._modulesPage, 8, null, null, true, sort
    ).subscribe((response) => {
      this.allModules = isEmpty(response.data.modules) ? [] :
        response.data.modules.map(module => new Module(module))
        ;
      this.modulesCount = response.data.itemsCount;
    });
  }

  private _loadTracks(): void {
    this._tracksService.getPagedFilteredTracksList(this._tracksPage, 8).subscribe((response) => {
      this.tracks = response.data.tracks;
      this.tracksCount = response.data.itemsCount;
    });
  }

  private _loadValuation(): void {
    this._settingsValuationTestsService.getPendingValuationsUser().subscribe((response) => {
      this.valuationPeding = response.data;
    });
  }

  public slideRight(): void {
    const modulesElement: HTMLElement = document.querySelector('.modules');
    const width = screen.width >= 1200 ? 1200 : (screen.width - 30);
    this.scrollLimit = -modulesElement.scrollWidth - width;

    if (-this.translatedValue > this.scrollLimit) {
      this.translatedValue = this.translatedValue - 100;
      const elements: Array<HTMLElement> = Array.from(document.querySelectorAll('.module'));

      let offset = 0;
      if (-this.translatedValue < this.scrollLimit)
        offset = -this.translatedValue - this.scrollLimit;

      elements.forEach((el: HTMLElement) => {
        el.style.left = this.translatedValue + offset + 'px';
      });
    }
  }

  public slideLeft(): void {
    if (this.translatedValue <= -100) {
      this.translatedValue = this.translatedValue + 100;
      const elements: Array<HTMLElement> = Array.from(document.querySelectorAll('.module'));

      elements.forEach((el: HTMLElement) => {
        el.style.left = this.translatedValue + 'px';
      });
    }
  }



  public hasSuggestions(): boolean {
    return this.suggestions && this.suggestions.length > 0;
  }

  public hasNoContent(): boolean {
    if (!this.allModules || !this.tracks)
      return false;
    return this.allModules.length === 0 &&
      this.tracks.length === 0;
  }
  public hasTest(): boolean {
    return !this.hasSuggestions() && (
      this.hasDefaultTest() ||
      this.hasSuggestedTest()
    );
  }

  public hasDefaultTest(): boolean {
    return this.hasNoContent() &&
      this.defaultTest &&
      this.defaultTest.testId != null;
  }

  public hasSuggestedTest(): boolean {
    return this.test &&
      this.test.testId != null && (
        !this.test.answers ||
        this.test.answers.length === 0
      );
  }

  private _loadSuggestions(): void {
    this._profileTestsService.getSuggestedProducts().subscribe((response) => {
      this.suggestions = [
        ...response.data.modules,
        ...response.data.events,
        ...response.data.tracks
      ];
      this.test = response.data.test;
      this.defaultTest = response.data.defaultTest;

    });
  }

  public goToProfileTest(): void {
    if (this.hasSuggestedTest())
      this._router.navigate(['teste-de-perfil/' + this.test.testId]);
    else if (this.hasDefaultTest())
      this._router.navigate(['teste-de-perfil/' + this.defaultTest.testId]);
  }
}
