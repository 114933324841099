import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventApplication } from '../../../models/event-application.model';
import { UserPreview } from '../../../models/previews/user.interface';
import { AnswersDialogComponent } from './answers-dialog/answers.dialog';
import { SettingsEventsService } from '../../_services/events.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import {
  EventsChangeDialogComponent
} from 'src/app/settings/events-applications-grades/events-chage-date-dialog/events-change-dialog.component';
import { forkJoin } from 'rxjs';
import { EventScheduleTypeEnum } from 'src/app/models/enums/event-schedule.enum';

@Component({
  selector: 'app-settings-applications-table',
  templateUrl: './applications-table.component.html',
  styleUrls: ['./applications-table.component.scss']
})
export class SettingsApplicationsTableComponent extends NotificationClass implements OnInit {

  @Input() header: string = 'APLICAÇÕES PENDENTES';
  @Input() dateLabel: string = 'Solicitado Em';
  private _scheduleType: EventScheduleTypeEnum;
  @Input()
  public get scheduleType(): EventScheduleTypeEnum {
    return this._scheduleType;
  }
  public set scheduleType(value: EventScheduleTypeEnum) {
    this._scheduleType = value;
    if (this.scheduleType === EventScheduleTypeEnum.DefaultScheduleType)
      this.displayedColumns = [
        'logo', 'name', 'email', 'category', 'requestDate', 'requirements', 'answers', 'profile', 'actions'
      ];
  }
  @Input() applications: Array<EventApplication> = [];
  @Output() approveApplication = new EventEmitter<string>();
  @Output() denyApplication = new EventEmitter<string>();
  @Output() changedEventApplication = new EventEmitter();

  public displayedColumns: string[] = [
    'logo', 'name', 'email', 'requestDate', 'profile', 'actions'
  ];
  public scheduleEnum = EventScheduleTypeEnum;
  public showSearchInput: boolean = false;
  public searchValue: string;
  public startApplications: Array<EventApplication> = [];

  private _eventId: string;
  private _scheduleId: string;
  private _firstLoad: boolean = true;

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: MatDialog,
    private _eventsService: SettingsEventsService,
    private _activatedRoute: ActivatedRoute
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    this._scheduleId = this._activatedRoute.snapshot.paramMap.get('scheduleId');
  }

  public startSearch() {
    if (this._firstLoad) {
      this.startApplications = this.applications.slice();
      this._firstLoad = false;
    }
    this.showSearchInput = !this.showSearchInput;
  }

  public filterApplications() {
    this.applications = this.startApplications.filter(ap => ap.user.name.toLocaleLowerCase().includes(
      this.searchValue.toLowerCase()
    ));
  }

  public viewAnswers(application: EventApplication): void {
    this._dialog.open(AnswersDialogComponent, {
      width: '1000px',
      data: application
    });
  }

  public viewUserProfile(user: UserPreview): void {
    this._router.navigate(['/configuracoes/detalhes-usuario/' + user.id]);
  }

  public resolveApplication(application: EventApplication, approved: boolean): void {
    this._eventsService.updateEventUserApplicationStatus(this._eventId, this._scheduleId,
      application.user.id, approved ? 1 : 2).subscribe(() => {
        approved ? this.approveApplication.emit(application.user.id) : this.denyApplication.emit(application.user.id);
      });
  }

  public getCheckImgSrc(check: boolean): string {
    return check ? './assets/img/approved.png' : './assets/img/denied.png';
  }


  public changeEventDateDialog(userId: string) {


    forkJoin({
      eventsInfo: this._eventsService.getEventSchedulesByEventId(this._eventId),
      event: this._eventsService.getEventsApplicationsByEventId(this._eventId, this._scheduleId)
    })
      .subscribe((response) => {
        const { eventsInfo, event } = response;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
          allSchedules: eventsInfo.data,
          event: event.data.event,
          scheduleId: this._scheduleId
        };

        const dialogRef = this._dialog.open(EventsChangeDialogComponent, {
          width: '1000px',
          data: dialogConfig
        });

        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this._eventsService.changeUserEventApplicationSchedule(userId, this._eventId,
              this._scheduleId, result)
              .subscribe(() => this.changedEventApplication.emit());
          }
        });
      });
  }

}
