import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../shared/classes/notification';
import { TrackPreview } from '../../../models/previews/track.interface';
import { SettingsTracksService } from '../../_services/tracks.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActionTypeEnum, AutomaticSync, Rule, RuleItemTypeEnum, Target, TargetItemTypeEnum } from 'src/app/models/automatic-sync.model';
import { AutomaticSyncService } from '../../_services/automatic-sync.service';
@Component({
  selector: 'app-manage-automatic-sync',
  templateUrl: './manage-automatic-sync.component.html',
  styleUrls: ['./manage-automatic-sync.component.scss']
})
export class ManageAutomaticSyncComponent extends NotificationClass implements OnInit {

  public ruleItemTypeEnum = RuleItemTypeEnum;
  public actionTypeEnum = ActionTypeEnum;
  public targetItemTypeEnum = TargetItemTypeEnum;
  public automaticSync: AutomaticSync;
  public isNewAutomaticSync: boolean = true;
  public selectedType: RuleItemTypeEnum = RuleItemTypeEnum.Jobs;
  public relationalItems: any[] = [];

  public tracks: TrackPreview[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _automaticSyncService: AutomaticSyncService,
    private _tracksService: SettingsTracksService,
    private _router: Router,
    private _authService: AuthService,
    private _dialogService: MatDialog,
    private _cd: ChangeDetectorRef
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadTracks();
    const automaticSyncStr = localStorage.getItem('editingAutomaticSync');
    if (automaticSyncStr && automaticSyncStr.trim() !== '') {
      this.isNewAutomaticSync = false;
      this.automaticSync = JSON.parse(automaticSyncStr);
    } else {
      this.automaticSync = {
        title: '',
        rules: [],
        targets: []
      };
    }
  }

  public triggerRelationalItemSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this._loadRelationalItems(searchValue);
    }
  }

  public getRulesByActionType(action: ActionTypeEnum) {
    const rules = [];
    for (let index = 0; index < this.automaticSync.rules.length; index++) {
      const rule = this.automaticSync.rules[index];
      if (rule.actionType === action) {
        rules.push(rule);
      }
    }
    return rules;
  }

  public addRule(item: any, action: ActionTypeEnum) {
    const ruleExists = this.automaticSync.rules.find(x => x.id === item.id && x.actionType === action);
    if (!ruleExists) {
      this.automaticSync.rules.push({
        ruleItemType: this.selectedType,
        id: item.id,
        name: item.name,
        actionType: action
      });
    }
    this.resetRelationalItemSearch();
  }

  public removeRule(rule: Rule) {
    const index = this.automaticSync.rules.findIndex(x => x.id === rule.id && x.actionType === rule.actionType);
    this.automaticSync.rules.splice(index, 1);
  }

  public resetRelationalItemSearch(): void {
    this.relationalItems = [];
  }

  public triggerTrackSearch(searchValue: string) {
    this._loadTracks(searchValue);
  }

  public getTargetsByType(type: TargetItemTypeEnum) {
    const targets = [];
    for (let index = 0; index < this.automaticSync.targets.length; index++) {
      const target = this.automaticSync.targets[index];
      if (target.targetItemType === type) {
        targets.push(target);
      }
    }
    return targets;
  }

  public removeSelectedTarget(id: string) {
    const selectedTargetIndex = this.automaticSync.targets.findIndex(x => x.id === id);
    if (selectedTargetIndex !== -1) {
      this.automaticSync.targets.splice(selectedTargetIndex, 1);
    }

    const trackIndex = this.tracks.findIndex(x => x.id === id);
    if (trackIndex !== -1) {
      this.tracks[trackIndex].checked = false;
    }
  }

  public updateTracks(): void {
    const prevSelected = this.automaticSync.targets.filter(x =>
      !this.tracks.find(t => t.id === x.id)
    );

    const selectedTracks = this.tracks.filter(track =>
      track.checked
    );

    const selectedTargets: Target[] = [];
    for (let index = 0; index < selectedTracks.length; index++) {
      const selectedTrack = selectedTracks[index];
      selectedTargets.push({
        targetItemType: TargetItemTypeEnum.Track,
        id: selectedTrack.id,
        name: selectedTrack.title
      });
    }

    this.automaticSync.targets = [...prevSelected, ...selectedTargets];
  }

  public saveAutomaticSync(): void {
    if (!this.automaticSync.title) {
      this.notify('É necessario escolher um nome');
      return;
    }
    if (this.automaticSync.rules.length === 0) {
      this.notify('É necessario definir pelo menos uma regra');
      return;
    }
    if (this.automaticSync.targets.length === 0) {
      this.notify('É necessario selecionar pelo menos um alvo');
      return;
    }
    this._automaticSyncService.updateAutomaticSync(
      this.automaticSync
    ).subscribe(() => {
      this._router.navigate(['/configuracoes/associacao-automatica']);
    }, () => {
      this.notify('Ocorreu um erro ao atualizar a associação automatica, por favor tente novamente mais tarde');
    });
  }

  private _loadTracks(searchValue: string = ''): void {
    const loggedUser = this._authService.getLoggedUser();
    const published = !loggedUser || loggedUser.role === 'Student' ?
      true : null;

    this._tracksService.getPagedFilteredTracksList(
      1, 4, searchValue, published
    ).subscribe(response => {
      response.data.tracks.forEach(track => {
        track.checked = this.automaticSync.targets.find(t => t.id === track.id) && true;
      });
      this.tracks = response.data.tracks;
    });
  }

  private _loadRelationalItems(searchValue: string = ''): void {
    this._automaticSyncService.getPagedFilteredRelationalItens(1, 4, searchValue, this.selectedType)
      .subscribe(response => this.relationalItems = response.data.relationalItems);
  }

}
