import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Track } from '../../../../../models/track.model';
import { ValuationsConfiguration } from 'src/app/models/valuation-configuration';
import { TrackModule } from '../../../../../models/track-module.model';
import { TrackEvent } from '../../../../../models/track-event.model';
import { cloneDeep, intersectionBy, isNil } from 'lodash';
import { differenceInSeconds, isValid } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ETrackItem } from 'src/app/models/enums/track-item.enum';


type ModuleEvent = TrackEvent & TrackModule;
@Component({
  selector: 'app-new-track-modules-dates',
  templateUrl: './modules-dates.component.html',
  styleUrls: ['../new-track-steps.scss', './modules-dates.component.scss']
})
export class NewTrackModulesEventsDatesComponent extends NotificationClass implements OnInit, OnDestroy {

  public readonly displayedColumns: string[] = [
    'modality', 'alwaysAvailable', 'openDate', 'cutOffDate', 'valuationDate'
  ];

  @Input() public track: Observable<Track>;

  @Output() addModulesEventsDate = new EventEmitter<{ modules, events, valuations }>();
  @Output() error = new EventEmitter<void>();

  public modules: Array<TrackModule> = [];
  public events: Array<TrackEvent> = [];
  public valuations: ValuationsConfiguration;
  public trackModulesEvents: Array<TrackModule | TrackEvent> = [];
  public ETrackItem = ETrackItem;
  public trackItemType = {};

  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (this.track) {
      this.track.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(track => {
        this.trackItemType = {
          [ETrackItem.Event]: 'Evento Presencial',
          [ETrackItem.Module]: 'Módulo Online',
          [ETrackItem.Valuation]: 'Avaliação',
        };

        this.modules = cloneDeep(track.modulesConfiguration);
        this.events = cloneDeep(track.eventsConfiguration);
        this.valuations = cloneDeep(track.valuations);

        this.trackModulesEvents = this.getTrackCards();

      });
    }
  }

  public getTrackCards(): Array<TrackModule | TrackEvent> {
    let modulesEvents = [... this.modules, ...this.events];
    modulesEvents = modulesEvents.sort((a, b) => a.order - b.order);
    modulesEvents = cloneDeep(modulesEvents);

    if (!isNil(this.valuations)) {
      modulesEvents.push(...this.valuations.modules, ...this.valuations.tracks);
    }

    return modulesEvents;
  }

  public nextStep(): void {
    const filteredItens: Array<any> = this.trackModulesEvents.filter(x => !x.alwaysAvailable);
    const invalidDatesErrors = this._checkDates(filteredItens) || [];

    const getByType = (items: Array<any>, type: ETrackItem) => items
      .filter(item => item.type === type);

    const getModules = items => getByType(items, ETrackItem.Module);
    const getEvents = items => getByType(items, ETrackItem.Event);
    const getValuations = items => getByType(items, ETrackItem.Valuation);

    if (!invalidDatesErrors.length) {
      this.modules = getModules(this.trackModulesEvents);
      this.events = getEvents(this.trackModulesEvents);
      const allValuations = getValuations(this.trackModulesEvents);

      this.valuations = {
        modules: this.valuations ? intersectionBy(allValuations, this.valuations.modules, 'testId') : [],
        tracks: this.valuations ? intersectionBy(allValuations, this.valuations.tracks, 'testId') : [],
      };

      this.addModulesEventsDate.emit({ modules: this.modules, events: this.events, valuations: this.valuations });
    } else {
      this.notify(invalidDatesErrors.join('\n'), 'OK', { duration: 999999999 });
      this.error.emit();
    }
  }

  public toggleModuleAvailability(row: ModuleEvent) {
    row.alwaysAvailable = !row.alwaysAvailable;
    if (row.alwaysAvailable) {
      row.openDate = null;
      row.cutOffDate = null;
      row.valuationDate = null;
    }
  }

  private _checkDates(trackModulesEvents: Array<ModuleEvent>) {
    const errors = [];
    for (let i = 0; i < trackModulesEvents.length; i++) {
      const item = trackModulesEvents[i] as any;
      const start = new Date(item.openDate);
      const end = new Date(item.cutOffDate || item.valuationDate);

      const itemTypeText = {
        [ETrackItem.Module]: `O módulo ${item.title}`,
        [ETrackItem.Valuation]: `A avaliação ${item.title}`,
        [ETrackItem.Event]: `O evento ${item.title}`
      };

      const errorMessage = `${itemTypeText[item.type]} contém uma configuração de data inválida.`;

      if (!this._checkDate(start, end) || !this._checkDate(start, end) || !isValid(start)) {
        errors.push(errorMessage);
      } else if (item.cutOffDate && item.valuationDate && differenceInSeconds(item.cutOffDate, item.valuationDate) > 0) {
        errors.push(`${errorMessage}\nA data de corte não pode ser maior que a data de término.\n`);
      }
    }
    return errors;
  }

  private _checkDate(fromDate: Date, toDate: Date): boolean {
    if (fromDate && toDate) {
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      if (fromDate > toDate) {
        return false;
      } else {
        return true;
      }
    } else if (fromDate) {
      return true;
    } else {
      return false;
    }
  }
}
