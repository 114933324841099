<div class="inner-content">
  <div class="main">
    <app-effort-metric class="effort-metric" [metrics]="_cockpitService.getStudentsMetrics().getValue()">
    </app-effort-metric>
    <app-performance-metric class="performance-metric" [metrics]="_cockpitService.getStudentsMetrics().getValue()">
    </app-performance-metric>
    <app-cockpit-ranking [ranking]="_cockpitService.getStudentsRankings()" class="ranking">
    </app-cockpit-ranking>
    <app-cockpit-menu [students]="_cockpitService.getStudents().getValue()"
      [modules]="_cockpitService.getTrackModules()" (goToModule)="goToModule($event)"
      (goToStudent)="goToStudent($event)" class="cockpit-menu">
    </app-cockpit-menu>
  </div>
</div>
