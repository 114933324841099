/* tslint:disable */
export function retirarNumeros(value) {
  return value.replace(/[^a-zA-ZáéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ\s]/g, '');
}

export function retirarNumerosMenosTraco(value) {
  return value.replace(/[^a-zA-ZáéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕ\-Ç\s]/g, '');
}

export function retirarCaracterEspecialMenosTraco(value) {
  return value.replace(/[^a-zA-Z0-9áéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕ\-Ç\s]/g, '');
}

export function retirarCaracterEspecial(value) {
  return value.replace(/[^a-zA-Z0-9áéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ\s]/g, '');
}

export function retirarLetras(value) {
  return value.replace(/[^0-9/()-.\s]/g, '');
}

export function transformarLetrasAcentuadas(value) {
  const map = { 'â': 'a', 'Â': 'A', 'à': 'a', 'À': 'A', 'á': 'a', 'Á': 'A', 'ã': 'a', 'Ã': 'A', 'ê': 'e', 'Ê': 'E', 'è': 'e', 'È': 'E', 'é': 'e', 'É': 'E', 'î': 'i', 'Î': 'I', 'ì': 'i', 'Ì': 'I', 'í': 'i', 'Í': 'I', 'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O', 'ò': 'o', 'Ò': 'O', 'ó': 'o', 'Ó': 'O', 'ü': 'u', 'Ü': 'U', 'û': 'u', 'Û': 'U', 'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U', 'ç': 'c', 'Ç': 'C' };
  const text = value.toString().replace(/[\W\[\] ]/g, function (a) {
      return map[a] || a;
  });
  return text;
}

