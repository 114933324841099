import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBackComponent } from 'src/app/shared/components/navigation-back/navigation-back.component';


@NgModule({
  declarations: [NavigationBackComponent],
  exports: [NavigationBackComponent],
  imports: [
    CommonModule
  ]
})
export class NavigationBackModule { }
