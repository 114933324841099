import { Component } from '@angular/core';
import { NotificationClass } from '../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent extends NotificationClass {

  public users: Array<UserPreview> = [];
  public selectedTrackId: string = '';
  public readonly pageSize: number = 20;
  public selectedUsers: Array<UserPreview> = [];
  public usersCount: number = 0;
  public getManageInfo: boolean;
  public displayedContent: boolean = true;

  public tracks: Array<TrackPreview> = [];
  public tracksCount: number;
  public selectedTracks: Array<TrackPreview> = [];

  public modules: Array<ModulePreview> = [];
  public modulesCount: number;
  public selectedModules: Array<ModulePreview> = [];

  public events: Array<EventPreview> = [];
  public eventsCount: number;
  public selectedEvents: Array<EventPreview> = [];
  public userId: Array<string> = ['5c0eabafab1c6871c5905f87'];

  public viewOptions = [
    { selected: true, title: 'INFORMAÇÕES CADASTRAIS', tag: 'INFO' },
    { selected: false, title: 'EXECUÇÃO DOS PROGRAMAS', tag: 'PROGRAMAS' },
    { selected: false, title: 'OBJETOS DE APRENDIZAGEM E AVALIAÇÃO', tag: 'APRENDIZAGEM' },
    { selected: false, title: 'PERFIL PROFISSIONAL', tag: 'CARREIRA' },
    { selected: false, title: 'PESQUISAS', tag: 'PESQUISA' }
  ];


  constructor(
    protected _snackBar: MatSnackBar,

  ) {
    super(_snackBar);
  }



  public selectViewOption(optTitle: string) {
    this.viewOptions.forEach(opt => { opt.selected = false; });
    this.viewOptions.find(x => x.title === optTitle).selected = true;
  }

  public isViewOption(tag: string): boolean {
    return this.viewOptions.find(x => x.tag === tag).selected;
  }


}
