import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from '../services/util.service';

@Pipe({ name: 'secondToMinute' })
export class SecondToMinute implements PipeTransform {

    constructor(private _utilService: UtilService) { }

    transform(seconds?: number) {
        return this._utilService.formatSecondsToMinutes(seconds);
    }

}
