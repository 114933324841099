<div class="inner-content" >
  <nav>
    <a href="javascript:;" (click)="back()" >
      <img src="./assets/img/arrow-back.png" />
      voltar para minhas vagas
    </a>
    <h2>{{ job?.title | uppercase }}</h2>
    <button class="btn-test primary" (click)="searchJobApplicants()">
      Buscar Candidatos
    </button>
  </nav>

  <div class="header">
    <div class="logo-container">
      <!--img [src]="job?.logoUrl ? job.logoUrl : './assets/img/logo-proseek.png'" /-->
      <img src="./assets/img/logo-proseek.png"/>      
      <div class="dropdown-container">
        <p><span>Status: </span></p>
        <mat-select [(ngModel)]="jobStatus" (selectionChange)="changeJobStatus()">
          <mat-option [value]="1">
            Vaga Aberta
          </mat-option>
          <mat-option [value]="2">
            Vaga Fechada
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="info-container">
      <p>Nome Vaga: <b>{{ job?.title }}</b></p>
      <p>Prazo de Conclusão: <b>{{ job?.dueTo | date: 'dd/MM/yyyy'}}</b></p>
      <p>Prioridade: <b class="danger">{{ getPriorityStatus() }}</b></p>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab class="labelColor" label="CANDIDATOS CONFIRMADOS">
      <div class="emptyUsers" *ngIf="acceptedUsers.length === 0">
        <p>Nenhum candidato pendente ainda foi aceito</p>
      </div>
      <app-job-confirmed
        *ngIf="acceptedUsers.length  > 0"
        [applications]="acceptedUsers"
        [jobPositionId] = "jobPositionId"
        (removeUser)="removeUser($event)">
    </app-job-confirmed>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span [matBadge]="pendingUsers.length" 
          matBadgeOverlap="false"
          matBadgePosition="above after"
          >CANDIDATOS PEDENTES
        </span>
      </ng-template>
      <div class="emptyUsers" *ngIf="pendingUsers.length === 0">
        <p>Nenhum candidato foi associado a está vaga</p>
      </div>
      <app-job-pendents
        *ngIf="pendingUsers.length > 0"
        [applications]="pendingUsers"
        (approveUserJob)="approveUserJob($event)"
        (removeUser)="removeUser($event)">
      </app-job-pendents>  
    </mat-tab>
  </mat-tab-group>
</div>
  