<div class="inner-content">
  <app-exam-header [showButton]="showButton" [isMock]="isMock" (finished)="finish()"></app-exam-header>
  <div class="content notranslate" translate="no">
    <div class="answers">
      <p class="number">
        {{title ? title : 'Questão'}}
      </p>
      <p class="statement notranslate" translate="no" [innerHtml]="question.text || question.title | MarkdownToHtml">
      </p>
      <div class="text-box" [formGroup]="formGroup">
        <textarea class="text-area" formControlName="answer" placeholder="Escreva aqui sua Resposta"></textarea>
      </div>
      <br>
      <button class="btn-test" appDebounceClick (debounceClick)="confirm()"
        [disabled]="formGroup.get('answer').value === ''"
        [ngClass]="{ 'answered': formGroup.get('answer').value === '' }">
        {{ answered && !hasChange() ? last ? 'Finalizar' : 'Próxima Pergunta' : 'Confirmar Resposta' }}
      </button>
      <ng-container *ngIf="hasComment && isMock && showComment && question && question.comment">
        <div class="inner-content-padding">
          <button class="btn-test primary" (click)="seeComment()">
            Ver comentário
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
