<div class="header">
  <div class="title">
    <h3>Usuários Selecionados</h3>
    <p>
      Nesta área você está visualizando todos os usuários que foram vinculados a esse grupo email
    </p>
  </div>
</div>
<div>
  <table mat-table matSort [dataSource]="users" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th width="12%" mat-header-cell *matHeaderCellDef> Nome do Aluno </th>
      <td width="12%" mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th width="10%" mat-header-cell *matHeaderCellDef> Nome de Usuário </th>
      <td width="10%" class="padding" mat-cell *matCellDef="let row">
        {{ row.userName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th width="10%" mat-header-cell *matHeaderCellDef> Email </th>
      <td width="10%" class="padding" mat-cell *matCellDef="let row">
        {{ row.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="registerId">
      <th width="10%" mat-header-cell *matHeaderCellDef> Matrícula </th>
      <td width="10%" mat-cell *matCellDef="let row">
        {{ row.registrationId }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <app-pagination *ngIf="itemsCount" [setPagination]="{
    'itemsCount': itemsCount,
    'pageSize': pageSize
  }" (goToPage)="goToPage($event)"></app-pagination>
</div>
<div>
  <button class="btn-test dialog-button" type="button" (click)="dialogRef.close()">
    OK
  </button>
</div>
