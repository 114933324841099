import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserPreviewInterceptor } from '../interfaces/user-preview-interceptor.interface';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private _requestsCount: number = 0;

  constructor(private _sharedService: SharedService, private router: Router,
    private _authService: AuthService) { }

  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this._sharedService.setLoaderValue(true);
    this._requestsCount++;

    const userRoleToSeeHow = this._authService.getUserRoleToSeeHow();
    if (userRoleToSeeHow) {
      request = request.clone({
        setHeaders: {
          'UserRoleToSeeHow': userRoleToSeeHow
        }
      });
    }

    const impersonatedUser: UserPreviewInterceptor | null = this._authService.getImpersonatedUser();
    if (impersonatedUser && impersonatedUser.userId) {
      request = request.clone({
        setHeaders: {
          'UserIdToImpersonate': impersonatedUser.userId,
          'UserRoleToImpersonate': impersonatedUser.userRole
        }
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (this._requestsCount > 0)
          this._requestsCount--;
        if (this._requestsCount === 0)
          this._sharedService.setLoaderValue(false);
        return request;
      }))
      .pipe(
        catchError((error, caught) => {
          this._requestsCount--;
          this._sharedService.setLoaderValue(false);
          return throwError(error);
        }) as any);
  }


}
