<div class="content">
  <header class="header-content">
    <img src="./assets/img/exam-dialog-blocked.svg" />
    <p>Você foi bloqueado de responder questões nesse assunto</p>
  </header>
  <div>
    <p class="info">
      Infelizmente você esgotou todas as suas tentativas de responder as perguntas desse nivél. Por esse motivo você não
      poderá mais progredir nesse assunto.
    </p>
  </div>

  <button class="btn-test exam-blocked" (click)="dialogRef.close()"> Voltar para o módulo</button>
</div>
