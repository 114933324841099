<div class="header">
  <h3 class="inner-content">
    {{ content?.title }}
    <span *ngIf="content && content.duration && content.duration !== 0">
      {{ getFormattedDuration() }} minutos
      <img src="./assets/img/arrow-back.png" (click)="toggleShowContent()" [ngClass]="{ 'closed': !showContent }" />
    </span>
  </h3>
</div>
<div id="ContentDescription" class="content" [ngClass]="{ 'resumed': resumedView }" *ngIf="showContent">
  <div class="options">
    <p>
      <ng-container *ngFor="let opt of viewOptions">
        <span [ngClass]="{ 'active': opt.selected }" (click)="selectViewOption(opt.title)">
          {{opt.title}}
        </span>
      </ng-container>
    </p>
  </div>
  <ng-container *ngIf="isViewOption('INFORMAÇÕES')">
    <div class="description inner-content">
      <div class="about" *ngIf="!resumedView">
        <span>Sobre</span>
        <p>
          {{ content?.excerpt }}
        </p>
        <br>
        <ng-container *ngIf="getReferenceUrls().length > 0">
          <span>Referências Úteis</span>
          <ul>
            <li *ngFor="let reference of getReferenceUrls()">
              <a [href]="reference" target="_blank">
                {{ reference }}
              </a>
            </li>
          </ul>
        </ng-container>
      </div>
      <div class="concepts" *ngIf="canCreateTags || getMarkedConcepts().length > 0">
        <div *ngIf="canCreateTags" class="tag-help">
          <p class="title">Sugira tags para otimizar o material de estudo.</p>
          <p class="subtitle">Os conceitos servem para marcar os conceitos presentes em um trecho do material</p>
          <p class="help-icon" (click)="helpCreateTag.emit()">
            <span class="material-icons icon">
              error_outline
            </span>
            <span class="icon-text">Ajuda</span>
          </p>
        </div>
        <div *ngIf="getMarkedConcepts().length > 0" class="concepts-info">
          <span>Conceitos Abordados Neste Material</span>
          <ul>
            <li class="concept" *ngFor="let concept of getMarkedConcepts()">
              <div>{{ concept.name }}</div>
              <ng-container *ngFor="let position of concept['positions']">
                <div class="time" *ngIf="position > 0" (click)="goToContentPosition(position, concept)">
                  {{ getConceptTag(position) }}
                </div>
              </ng-container>
              <div class="time" *ngFor="let position of concept['anchors']" [ngClass]="{'disabled': position === '#'}"
                (click)="goToContentPosition(position, concept)">
                {{ getConceptTag(position) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isViewOption('ANOTAÇÕES')">
    <div class="ContentNote">
      <textarea class="textarea-note" placeholder="Anotações (max 4000 caracteres)" maxlength="4000"
        (keyup)="updateNote($event.target.value)" [value]="contentNote"></textarea>
    </div>
  </ng-container>
</div>
