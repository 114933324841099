import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Content } from '../../models/content.model';
import { ContentTypeEnum } from '../../models/enums/content-type.enum';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PDFContentComponent } from './pdf/pdf.component';
import { ContentDescriptionComponent } from './common/description/description.component';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ActionInfoPageEnum, ActionInfoTypeEnum, ActionInfo } from 'src/app/shared/directives/save-action/action-info.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ForumQuestionDialogComponent } from 'src/app/shared/dialogs/forum-question/forum-question.dialog';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Level } from 'src/app/models/shared/level.interface';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { Module } from 'src/app/models/module.model';
import { ModuleContent } from 'src/app/models/moduleContent.model';
import { ContentResolverResponse } from 'src/app/pages/content/resolver/content.resolver';
import { EffortService } from 'src/app/shared/services/effort.service';
import { TypeTargetEffort } from 'src/app/shared/enumerations/effort';
import { concatMap, filter, take } from 'rxjs/operators';
import { chain, isNil } from 'lodash';
import { EMPTY, forkJoin, of } from 'rxjs';

import { ContentModulesService } from '../_services/modules.service';
import { BackendResponse } from '@tg4/http-infrastructure';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent extends NotificationClass implements OnInit, OnDestroy {

  @ViewChild('pdfContent') pdfContent: PDFContentComponent;
  @ViewChild('contentDescription') contentDescription: ContentDescriptionComponent;

  public contents: Array<Content>;
  public allContents: any;
  public index = 0;
  public contentTypeEnum = ContentTypeEnum;
  public showMenu = true;
  public conceptPosition = 0;
  public subjectId = '';
  public subjectProgress: any;
  public levels: Array<Level> = [];
  public hasQuestions = false;
  public hasFinishedRequirements = false;
  public userContentNote = '';
  public module: ModuleContent;

  private get _actionParameters() {
    return {
      moduleId: this.module.id,
      contentId: this.contents[this.index].id,
      subjectId: this.subjectId
    };
  }

  private _trackId: string;
  private _hasLinkedTrack: boolean;

  constructor(
    protected _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private _dialog: MatDialog,
    private _sharedService: SharedService,
    private _settingsUsersService: SettingsUsersService,
    private _effortService: EffortService,
    private _route: ActivatedRoute,
    private _moduleService: ContentModulesService
  ) {
    super(_snackBar);
  }


  ngOnInit() {

    this._activatedRoute.data.subscribe(async (data: { contentArgs: ContentResolverResponse, module: Module }) => {
      this.module = new ModuleContent(data.module);
      this.hasQuestions = data.module.hasQuestions;

      if (isNil(this.allContents)) {
        const response = await this._moduleService.getAllContent(this.module.id).toPromise();
        if (response.success) this.allContents = response.data;
      }

      this.levels = data.contentArgs.levels;
      this.index = parseInt(this._activatedRoute.snapshot.paramMap.get('contentIndex'), 10);
      this.subjectId = this._activatedRoute.snapshot.paramMap.get('subjectId');

      this.contents = this.module.subjects
        .find(subject => subject.id === this.subjectId)
        .contents
        .map(content => ({
          ...content
          , watched: chain(this.allContents.subjects)
            .flatMap(subject => subject.contents)
            .find(_content => _content.id === content.id)
            .value()
            .watched
        }));

      this._saveAccessAction(this.module.id, this.subjectId, this.contents[this.index]);

      const progressStr = localStorage.getItem('subjectProgress');
      if (progressStr && progressStr.trim() !== '')
        this.subjectProgress = JSON.parse(progressStr);

      const finishedReqStr = localStorage.getItem('hasFinishedRequirements');
      if (finishedReqStr && finishedReqStr.trim() !== '')
        this.hasFinishedRequirements = JSON.parse(finishedReqStr);

      this.loadUserContentNote();

    });

    this._effortService.startCountInteraction(this.module.id, TypeTargetEffort.module);

  }

  ngOnDestroy() {
    localStorage.removeItem('contents-hasQuestions');
    this._effortService.finishInteraction();
  }

  public goBackToModule(): void {
    history.back();
  }

  public toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  public goToPrevious(): void {
    this.index--;
    window.scroll({ top: 0, behavior: 'smooth' });
    this.contentDescription.showContent = false;
    setTimeout(() => { if (this.contentDescription) this.contentDescription.setDescriptionContentHeight(); });
    this._saveAccessAction(this.module.id, this.subjectId, this.contents[this.index]);
  }

  public goToNext(): void {
    if (this.index === this.contents.length - 1) {
      this.goBackToModule();
    } else {
      this.index++;
      this.contentDescription.showContent = false;
      window.scroll({ top: 0, behavior: 'smooth' });
      setTimeout(() => { if (this.contentDescription) this.contentDescription.setDescriptionContentHeight(); });
      this._saveAccessAction(this.module.id, this.subjectId, this.contents[this.index]);
    }
  }

  public goToContent(data: { moduleId: string, subjectId: string, index: number }): void {
    this._trackId = this._route.snapshot.queryParams['trackId'];
    this._hasLinkedTrack = !!this._trackId;
    this.module.id = data.moduleId;
    this.subjectId = data.subjectId;

    const extras: NavigationExtras = this._hasLinkedTrack
      ? { replaceUrl: true, queryParams: { trackId: this._trackId } }
      : { replaceUrl: true };

    this._router.navigate(['/modulo/', data.moduleId, data.subjectId, this.index], extras).then(() => this.updateContent(data));

  }

  public updateContent(data: { moduleId: string, subjectId: string, index: number }) {
    if (this.allContents.modules) {
      for (let index = 0; index < this.allContents.modules.length; index++) {
        const element = this.allContents.modules[index];
        if (element.id === this.module.id) {
          element.selected = true;

          for (let sIndex = 0; sIndex < this.allContents.modules[index].subjects.length; sIndex++) {
            const sub = this.allContents.modules[index].subjects[sIndex];
            if (sub.id === this.subjectId) {
              sub.selected = true;
            } else {
              sub.selected = false;
            }
          }
        } else {
          element.selected = false;
        }
      }

      const currentModule = this.allContents.modules.find(x => x.id === data.moduleId);
      const currentSubject = currentModule.subjects.find(x => x.id === data.subjectId);
      this.contents = currentSubject.contents;
    } else {
      for (let sIndex = 0; sIndex < this.allContents.subjects.length; sIndex++) {
        const sub = this.allContents.subjects[sIndex];
        if (sub.id === this.subjectId) {
          sub.selected = true;
          const currentSubject = sub;
          this.contents = currentSubject.contents;
        } else {
          sub.selected = false;
        }
      }
    }

    this.index = data.index;
    if (this.contentDescription) this.contentDescription.showContent = false;
    setTimeout(() => { if (this.contentDescription) this.contentDescription.setDescriptionContentHeight(); });
    this._saveAccessAction(data.moduleId, data.subjectId, this.contents[this.index]);
  }

  public goToPosition(position: number): void {
    if (this.pdfContent) {
      this.pdfContent.goToPage(position);
    }
    this.conceptPosition = position;
  }

  public goToAnchor(anchor: string): void {
    const anchorElement: any = document.querySelector('a[href=\'' + anchor + '\']');
    if (anchorElement !== null)
      document.querySelector('.htmlContent').scrollTop = anchorElement.offsetTop - 50;
  }

  public saveVideoPlayedAction(contentId: string): void {
    const actionInfo = this._getActionInfo('content-video', this.module.id, this.subjectId, contentId, ActionInfoTypeEnum.VideoPlay);
    this._analyticsService.saveAction(actionInfo).subscribe();
  }

  public saveZipFinishedAction(contentId: string): void {
    this._saveFinishedContent('content-zip', contentId);
  }

  public saveLinkFinishedAction(contentId: string): void {
    this._saveFinishedContent('content-link', contentId);
  }

  public savePdfFinishedAction(contentId: string): void {
    this._saveFinishedContent('content-pdf', contentId);
  }

  public saveTextFinishedAction(contentId: string): void {
    this._saveFinishedContent('content-text', contentId);
  }

  public saveVideoFinishedAction(contentId: string): void {
    this._saveFinishedContent('content-video', contentId);
  }
  public saveDownloadFinishedAction(contentId: string): void {
    this._saveFinishedContent('content-download', contentId);
  }
  public saveConceptViewAction(concept: string): void {
    const { moduleId, subjectId, contentId } = this._actionParameters;
    const actionInfo = this._getActionInfo('content-view', moduleId, subjectId, contentId, ActionInfoTypeEnum.Click);
    actionInfo.concept = concept;
    this._analyticsService.saveAction(actionInfo).subscribe();
  }

  public reachedMaxLevel(): boolean {
    return this.levels && this.subjectProgress && this.levels[this.subjectProgress.level] === undefined;
  }

  private _saveFinishedContent(description: string, contentId: string, callback?) {
    this.subjectId = this._activatedRoute.snapshot.paramMap.get('subjectId');
    const actionInfo = this._getActionInfo(description, this.module.id, this.subjectId, contentId, ActionInfoTypeEnum.Finish);
    const content = this.allContents
      .subjects.find(subject => subject.id === this.subjectId)
      .contents.find(_content => _content.id === contentId);

    const saveAction$ = content.watched ? EMPTY : this._analyticsService.saveAction(actionInfo)
      .pipe(
        concatMap((response: BackendResponse<any>) => {
          if (callback) return callback(response);
          return of(response);
        })
      );

    const manageSubjectProgress$ = content.watched ? EMPTY
      : this._settingsUsersService.manageSubjectProgress({
        contentId,
        moduleId: this.module.id,
        subjectId: this.subjectId
      });

    return forkJoin({
      manageSubjectProgress: manageSubjectProgress$,
      saveAction: saveAction$
    })
      .subscribe((response) => content.watched = !!response.manageSubjectProgress.data);
  }

  private _saveAccessAction(moduleId: string, subjectId: string, content: Content): void {
    if (content && !content.accessed) {
      content.accessed = true;

      const actionInfo = this._getActionInfo('content-access', moduleId, subjectId, content.id, ActionInfoTypeEnum.Access);
      this._analyticsService.saveAction(actionInfo).subscribe(() => {
        const waitingAction = this._getActionInfo('content-leave', moduleId, subjectId, content.id, ActionInfoTypeEnum.CloseTab);
        this._analyticsService.setWaitingAction(waitingAction);
      });
    }
  }


  private _getActionInfo(description: string, moduleId: string, subjectId: string,
    contentId: string, type: ActionInfoTypeEnum): ActionInfo {
    return {
      'page': ActionInfoPageEnum.Content,
      'description': description,
      'type': type,
      'moduleId': moduleId,
      'subjectId': subjectId,
      'contentId': contentId
    };
  }

  public openForumQuestionModal() {
    this._sharedService.forumQuestionResponse.pipe(take(1)).subscribe(data => {
      const localForumQuestion = JSON.parse(localStorage.getItem('forumQuestionDialog'));
      if (localForumQuestion) localForumQuestion.position = data;
      localStorage.setItem('forumQuestionDialog', JSON.stringify(localForumQuestion));
      this._dialog.open(ForumQuestionDialogComponent, {
        width: '1000px'
      });
    });
    this._sharedService.forumQuestion.next();
  }

  public loadUserContentNote() {
    if (this.contents && this.contents.length > 0 && this.contents[this.index]) {
      const contentId = this.contents[this.index].id;
      this._settingsUsersService.getUserContentNote(this.module.id, this.subjectId, contentId).subscribe(res => {
        this.userContentNote = res.data.note;
      });
    }
  }

  public updateUserContentNote(note: string) {
    const { moduleId, subjectId, contentId } = this._actionParameters;
    this._settingsUsersService.updateUserContentNote(moduleId, subjectId, contentId, note).subscribe(() => {
      this.userContentNote = note;
      this.notify('pages/content/content.component.ts.anotacaoSalva');
    }, err => {
      this.notify(this.getErrorNotification(err));
    });
  }

}
