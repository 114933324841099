<div class="inner-content">
    <div class="card-title">
        <div>
          <h3>Validar Questão</h3>
        </div>
        <div class="bar-container">
          <div class="bar fill"></div>
          <div class="bar fill"></div>
          <p>passo 02 de 02</p>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Enunciado da Questão</h4>
        <div class="gray-card">
            <p>{{moduleQuestion.text}}</p>
        </div>
        <p class="explanation">
            Como avalia o enunciado em relação á pertinência ao assunto 
            {{subject.title}}, uso formal da língua, objetividade, simplicidade 
            e clareza.
        </p>
        <div class="slider-container">
            <p class="slider-value">{{valuation.textValuation}} - {{getValuationName(valuation.textValuation)}}</p>
            <mat-slider class="slider" color="primary" 
                [value]="valuation.textValuation"
                [min]="0"
                [max]="10"
                (input)="changeTextValuation($event.value)"
            ></mat-slider>
            <div class="slider-levels">
                <p class="slider-level">0 - Péssimo</p>
                <p class="slider-level">10 - Excelente</p>
            </div>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Alternativas de Respostas</h4>
        <div class="gray-card">
            <div class="answers-valuation-container">
                <div class="answers-valuation-grid">
                    <p><b>100% Correta</b></p>
                    <p>{{moduleQuestion.answers[0].text}}</p>
                </div>
                <div class="answers-valuation-grid">
                    <p><b>Parcialmente Correta</b></p>
                    <p>{{moduleQuestion.answers[1].text}}</p>
                </div>
                <div class="answers-valuation-grid">
                    <p><b>Errada</b></p>
                    <p>{{moduleQuestion.answers[2].text}}</p>
                </div>
                <div class="answers-valuation-grid">
                    <p><b>Absurda</b></p>
                    <p>{{moduleQuestion.answers[3].text}}</p>
                </div>
            </div>
        </div>
        <p class="explanation">
            Como avalia as alternativas em realação á pertinência ao enunciado 
            da questão e ás categorias associadas (100% correta, parcialmente 
            correta, errada e absurda).
        </p>
        <div class="slider-container">
            <p class="slider-value">{{valuation.answersValuation}} - {{getValuationName(valuation.answersValuation)}}</p>
            <mat-slider class="slider" color="primary" 
                [value]="valuation.answersValuation"
                [min]="0"
                [max]="10"
                (input)="changeAnswersValuation($event.value)"
            ></mat-slider>
            <div class="slider-levels">
                <p class="slider-level">0 - Péssimo</p>
                <p class="slider-level">10 - Excelente</p>
            </div>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Conceitos abordados</h4>
        <div class="gray-card">
            <div class="concepts-valuation-container">
                <div class="concepts-valuation-grid">
                    <p></p>
                    <p class="answer-title">{{moduleQuestion.answers[0].text}}</p>
                    <p class="answer-title">{{moduleQuestion.answers[1].text}}</p>
                    <p class="answer-title">{{moduleQuestion.answers[2].text}}</p>
                    <p class="answer-title">{{moduleQuestion.answers[3].text}}</p>
                </div>
                <div class="concepts-valuation-grid">
                    <p>Conceitos</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
                <ng-container *ngFor="let concept of getSubjectConcepts();">
                    <div class="concepts-valuation-grid">
                        <p class="answer-box first">{{concept}}</p>
                        <p class="answer-box"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[0], concept)"/></p>
                        <p class="answer-box"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[1], concept)"/></p>
                        <p class="answer-box"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[2], concept)"/></p>
                        <p class="answer-box last"><img [src]="getAnswerConceptStatus(moduleQuestion.answers[3], concept)"/></p>
                    </div>
                </ng-container>
            </div>
        </div>
        <p class="explanation">
            Como avalia os conceitos em realação á pertinência ao enunciado 
            e a configuração (se certo ou errado) e correlação ás alternativas.
        </p>
        <div class="slider-container">
            <p class="slider-value">{{valuation.conceptsValuation}} - {{getValuationName(valuation.conceptsValuation)}}</p>
            <mat-slider class="slider" color="primary" 
                [value]="valuation.conceptsValuation"
                [min]="0"
                [max]="10"
                (input)="changeConceptsValuation($event.value)"
            ></mat-slider>
            <div class="slider-levels">
                <p class="slider-level">0 - Péssimo</p>
                <p class="slider-level">10 - Excelente</p>
            </div>
        </div>
    </div>

    <div class="card">
        <h4 class="sub-title">Conclusão</h4>
        <div class="radio-group">
            <mat-radio-group [(ngModel)]="valuation.conclusion">
                <mat-radio-button [value]="true">
                    <p>Aprovar</p>
                </mat-radio-button>
                <mat-radio-button class="last" [value]="false">
                    <p>Reprovar</p>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <textarea class="conclusion" matInput placeholder="Justifique..." 
            [(ngModel)]="valuation.conclusionText" required>
        </textarea>
    </div>

    <div class="card-footer">
        <button class="btn-outline" (click)="goBack.emit()">
          Cancelar
        </button>
        <button class="btn-test" (click)="save()">
          Salvar e finalizar
        </button>
      </div>
</div>