<div class="inner-content">
  <header class="header">
    <app-navigation-back></app-navigation-back>
    <h3>{{ userName }}</h3>
  </header>

  <app-study-plan-header [defaultValue]="studyPlan.name" (planStudyNameEmiiter)="setStudyPlanName($event)"
    (buttonClick)="save()">
  </app-study-plan-header>
  <div class="main">
    <div class="content-wrapper" *ngIf="studyPlansTeam && studyPlansTeam.length > 0">
      <ng-container *ngFor="let studyPlan of studyPlansTeam">
        <h3>{{studyPlan.name}}</h3>
        <div class="content">
          <app-draging-list [dataSource]="studyPlan.items" [disableDrag]="true" (remove)="remove($event)">
          </app-draging-list>
        </div>

      </ng-container>
      <app-pagination *ngIf="studyPlansTeam && studyPlansTeam.length > 0" [hidden]="itemsCount === 0" [setPagination]="{
        'itemsCount': itemsCount,
        'pageSize': pageSize
      }" (goToPage)="goToPage($event)"></app-pagination>
    </div>
    <div class="content-wrapper">
      <div class="content-header">
        <h3>ESTUDOS INDIVIDUALIZADO (PERSONALIZADO)</h3>
        <button class="btn-test" (click)="openDialog(EnumConnect.course)">Vincular Módulo/Trilha</button>
      </div>

      <div class="content">
        <app-draging-list [dataSource]="studyPlansItems" (remove)="remove($event)"></app-draging-list>
      </div>
    </div>
  </div>
</div>
