import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
declare let InbentaChatbotSDK: any;

@Injectable({
    providedIn: 'root'
})
export class ChatBotService {
    constructor(private _httpService: BackendService) {
    }

    public init(inbenta_token, inbenta_key) {

        const InbentaAuth = InbentaChatbotSDK.createFromAccessToken(inbenta_token, inbenta_key);
        const InbentaConfiguration = {
            lang: 'pt',
            answers: {
                answerAttributes: ['ANSWER_TEXT'],
                sideBubbleAttributes: ['SIDEBUBBLE_TEXT']
            },
            usertype: 0,
            environment: environment.production && !environment.apiUrl.includes('staging') ? 'production' : 'development',
            launcher: {
                title: ''
            },
            labels: {
                pt: {
                    'yes': 'Sim',
                    'no': 'Não',
                    'generic-error-message': 'Houve um erro',
                    'enter-question': 'Olá, esse é um teste de desenvolvimento',
                    'interface-title': 'Modal Mais',
                    'guest-name': 'Você',
                    'help-question': 'Olá !, sou o Agente de Suporte da Inbenta v0.1.0, o que posso fazer por você?',
                    'thanks': 'Obrigado',
                    'rate-content': 'Eu ajudei ?',
                    'form-message': 'Por favor me diga como posso melhorar ?',
                    'submit': 'Enviar'
                }
            }
        };

        InbentaChatbotSDK.build(InbentaAuth, InbentaConfiguration);
    }

    public inbentaLogin(): Observable<any> {
        return this._httpService.get('InbentaLogin', [], []);
    }
}
