<div class="chart-wapper">
    <canvas width="100%" height="100%" id="cockpit-radar"></canvas>
    <div class="labels top">
        <h1>Engajamento</h1>
        <span 
            class="material-icons">
            help_outline
        </span> 
    </div>
    <div class="labels left">
        <h1>Esforço</h1>
        <span 
            class="material-icons">
            help_outline
        </span> 
    </div>
    <div class="labels right">
        <h1>Participação</h1>
        <span 
            class="material-icons">
            help_outline
        </span> 
    </div>
</div>

