<div [formGroup]="formGroup" > 
  <!--PRÊMIOS E RECONHECIMENTOS-->
  <h2>PRÊMIOS E RECONHECIMENTOS</h2>
  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('rewards')['controls']; let i = index;"
    formArrayName="rewards"
  >
    <ng-container [formGroupName]="i" >
      <div class="card-title rewardTitle" >
        <p>Descrição Prêmio/Reconhecimento</p>
        <div style="display: flex;">
          <mat-form-field
            class="whiteBorder noBorder"
            floatLabel="never">
            <input matInput formControlName="title"/>
            <mat-placeholder class="placeholder">Nome e colocação alcançada</mat-placeholder>
          </mat-form-field>
          <button class="btn-test remove-btn"
            type="button"
            (click)="removeForm('rewards', i)">
            Remover
          </button>
        </div>
      </div>
      <div class="card-info rewardName" >
        <p>Instituição Responsável</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="name"
          placeholder="Nome da instituição"/>
        </mat-form-field>
      </div>
      <div class="card-info rewardLink" >
        <p>Link</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="link"
          placeholder="Link de referência"/>
        </mat-form-field>
      </div>
      <div class="card-info rewardDate" >
        <p>Data</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput
            [matDatepicker]="date_i"
            placeholder="Dia que começou no local"
            formControlName="date"
          />
          <mat-datepicker-toggle
            matSuffix [for]="date_i"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #date_i
          ></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <button class="btn-test add-button rewardButton"
    type="button"
    (click)="addReward.emit()" >
    + Adicionar Prêmios
  </button>
  <!--FIM PRÊMIOS E RECONHECIMENTOS-->
  
  <!--CERTIFICADOS-->
  <h2>CERTIFICADOS</h2>
  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('certificates')['controls']; let i = index;"
    formArrayName="certificates"
  >
    <ng-container [formGroupName]="i" >
      <div class="card-title certificateTitle" >
        <p>Certificado</p>
        <div style="display: flex;">
          <mat-form-field
            class="whiteBorder noBorder"
            floatLabel="never">
            <input matInput formControlName="title"/>
            <mat-placeholder class="placeholder">Nome do certificado</mat-placeholder>
          </mat-form-field>
          <button class="btn-test remove-btn"
            type="button"
            (click)="removeForm('certificates', i)">
            Remover
          </button>
        </div>
      </div>
      <div class="card-info certificateLink" >
        <p>Link</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="link" 
          placeholder="Link de referência"/>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <button class="btn-test add-button certificateButton"
    type="button"
    (click)="addCertificate.emit()" >
    + Adicionar Certificado
  </button>
  <!--FIM CERTIFICADOS-->
  
  <!--IDIOMAS-->
  <h2>IDIOMAS</h2>

  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('fixedLanguages')['controls']; let i = index;"
    formArrayName="fixedLanguages"
  >
    <div class="two-columns" [formGroupName]="i" >
      <div class="card-info" >
        <p>Idioma</p>
        <mat-form-field class="noBorder" floatLabel="never" >
          <mat-select formControlName="languages" (selectionChange)="cleanLevel(i)" >
            <mat-option value="ingles">Inglês</mat-option>
            <mat-option value="espanhol">Espanhol</mat-option>
            <mat-option value="frances">Francês</mat-option>
            <mat-option value="alemao">Alemão</mat-option>
            <mat-option value="outro">Outro</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="noBorder languageName"
          floatLabel="never"
          *ngIf="formGroup.get('fixedLanguages')['controls'][i]?.get('languages')?.value === 'outro'">
          <input matInput placeholder="Adicione outro idioma" formControlName="names"/>
        </mat-form-field>
      </div>
      <div class="card-info level" >
        <p>Nível</p>
        <div style="display: flex;">
          <mat-form-field class="noBorder" floatLabel="never" >
            <mat-select formControlName="level" 
              [disabled]="!formGroup.get('fixedLanguages')['controls'][i]?.get('languages')?.value" >
              <mat-option [value]="'Básico'">Básico</mat-option>
              <mat-option [value]="'Intermediário'">Intermediário</mat-option>
              <mat-option [value]="'Avançado'">Avançado</mat-option>
              <mat-option [value]="'Avançado'">Fluente</mat-option>
            </mat-select>
          </mat-form-field>
          <button class="btn-test remove-btn"
            type="button"
            (click)="removeForm('fixedLanguages', i)">
            Remover
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="btn-test add-button languageButton"
    type="button"
    (click)="addLanguage.emit()" >
    + Adicionar Outros Idiomas
  </button>
  <!--FIM IDIOMAS-->
  
  <!--COMPETÊNCIAS-->
  <h2>COMPETÊNCIAS</h2>
  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('skills')['controls']; let i = index;"
    formArrayName="skills"
  >
    <ng-container [formGroupName]="i" >
      <div class="card-title skillName" >
        <p>Competência</p>
        <div style="display: flex;">
          <mat-form-field
            class="whiteBorder noBorder"
            floatLabel="never">
            <input matInput formControlName="name"/>
            <mat-placeholder class="placeholder">Nome da competência</mat-placeholder>
          </mat-form-field>
          <button class="btn-test remove-btn"
            type="button"
            (click)="removeForm('skills', i)">
            Remover
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <button class="btn-test add-button skillButton"
    type="button"
    (click)="addSkill.emit()" >
    + Adicionar Competência
  </button>
  <!--FIM COMPETÊNCIAS-->
  
  <div class="choice-box" style="margin-top: 20px;">
    <p>Disponibilidade para Viagens?</p>
    <div style="display: flex;">
      <div class="answer" (click)="selectTravelAvailability.emit(true)" >
        <div class="checkbox"
          [ngClass]="{ 'selected': travelAvailability }"
        ></div>
        <p>Sim</p>
      </div>
      <div class="answer" (click)="selectTravelAvailability.emit(false)" >
        <div class="checkbox"
          [ngClass]="{ 'selected': !travelAvailability }"
        ></div>
        <p>Não</p>
      </div>
    </div>
  </div><div class="choice-box" style="margin-top: 20px;">
      <p>Disponibilidade para Mudança?</p>
      <div style="display: flex;">
        <div class="answer" (click)="selectMovingAvailability.emit(true)" >
          <div class="checkbox"
            [ngClass]="{ 'selected': movingAvailability }"
          ></div>
          <p>Sim</p>
        </div>
        <div class="answer" (click)="selectMovingAvailability.emit(false)" >
          <div class="checkbox"
            [ngClass]="{ 'selected': !movingAvailability }"
          ></div>
          <p>Não</p>
        </div>
      </div>
    </div>
</div>