import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ModuleContent } from 'src/app/models/moduleContent.model';
import { Level } from 'src/app/models/shared/level.interface';
import { map } from 'rxjs/operators';

export interface ContentResolverResponse { allContents: ModuleContent; levels: Level[]; }

@Injectable({ providedIn: 'root' })
export class ContentResolver implements Resolve<Observable<ContentResolverResponse>> {

  constructor(private _moduleService: ContentModulesService, private _sharedService: SharedService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ContentResolverResponse> {
    const moduleId = route.paramMap.get('moduleId');
    return forkJoin({
      allContents: this._moduleService.getAllContent(moduleId).pipe(map(response => response.data)),
      levels: this._sharedService.getLevels(true).pipe(map(response => response.data)),
    });
  }
}
