import { ValuationTestQuestion } from 'src/app/models/valuation-test.interface';

export enum mandatoryCollumnEnum {
    id = 'idquestao',
    typeQuestion = 'tipo',
    text = 'questaoresposta',
    answareOrWeight = 'nivelvalorresposta'
  }

export interface ValuationTestUploadResponse {
    addQuestions?: boolean;
    questions?: ValuationTestQuestion[];
}

export interface Headears {
    headear: string;
    collumn: number;
}

export const correctAnswer = 'respostacerta';
