<div class="filters">

  <app-autocomplete
    [filter]="filters?.track"
    (triggerSearch)="setupUpdateFilter(filters?.track,$event)"
    (change)="updateFilterOptions(filters?.track, $event)"
    (remove)="removeFilter(filters?.track, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.modules"
    (triggerSearch)="setupUpdateFilter(filters?.modules,$event, true)"
    (change)="updateFilterOptions(filters?.modules, $event)"
    (remove)="removeFilter(filters?.modules, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.subjects"
    (triggerSearch)="setupUpdateFilter(filters?.subjects,$event, true)"
    (change)="updateFilterOptions(filters?.subjects, $event)"
    (remove)="removeFilter(filters?.subjects, $event)">
  </app-autocomplete>

  <app-user-search
    (userRemoved)="removeStudent($event)"
    (userSelected)="setStudent($event)">
  </app-user-search>

  <app-autocomplete
    [filter]="filters?.businessGroup"
    (triggerSearch)="setupUpdateFilter(filters?.businessGroup,$event)"
    (change)="updateFilterOptions(filters?.businessGroup, $event)"
    (remove)="removeFilter(filters?.businessGroup, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.businessUnit"
    (triggerSearch)="setupUpdateFilter(filters?.businessUnit,$event)"
    (change)="updateFilterOptions(filters?.businessUnit, $event)"
    (remove)="removeFilter(filters?.businessUnit, $event)">
  </app-autocomplete>

  <app-autocomplete
    [filter]="filters?.segment"
    (triggerSearch)="setupUpdateFilter(filters?.segment,$event)"
    (change)="updateFilterOptions(filters?.segment, $event)"
    (remove)="removeFilter(filters?.segment, $event)">
  </app-autocomplete>
</div>
