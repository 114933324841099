<header>
  <h2>Material de Suporte</h2>
</header>

<app-support-material
  *ngFor="let material of entity?.supportMaterials"
  [material]="material"
  [moduleId]="entity?.moduleId"
  [eventId]="entity?.eventId">
</app-support-material>

<div class="footer">
  <button class="btn-test primary" (click)="dismiss()">Fechar</button>
</div>
