import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { SupportMaterial, SupportMaterialType } from '../../../../../models/support-material.interface';
import { Event } from '../../../../../models/event.model';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from 'src/app/shared/services/upload.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-new-event-support-materials',
  templateUrl: './support-materials.component.html',
  styleUrls: ['../new-event-steps.scss', './support-materials.component.scss']
})
export class NewEventSupportMaterialsComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() event$: Observable<Event>;
  public event: Event;
  @Output() addEventSupportMaterials = new EventEmitter<Array<SupportMaterial>>();

  public formGroup: FormGroup;
  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    private _uploadService: UploadService
  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((eventItems) => {
        this.event = cloneDeep(eventItems);
        this.formGroup = this._createFormGroup(eventItems);
      });
    }
  }

  public addMaterial(): void {
    const materials = this.formGroup.get('materials') as FormArray;
    materials.push(
      this._createMaterialForm()
    );
  }

  public removeMaterial(index: number): void {
    const materials = this.formGroup.get('materials') as FormArray;
    materials.removeAt(index);
  }

  public openFileUpload(index: number): void {
    (document.getElementById('inputFile' + index) ).click();
  }

  public setDocumentFile(event, childFormGroup: FormGroup) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this._sendPdfToServer(
        event.target.files[0],
        childFormGroup.get('downloadLink'),
        childFormGroup.get('fileName')
      );
    }
  }

  public nextStep(): void {
    if (this.formGroup.valid) {
      const formArray = this.formGroup.getRawValue();
      this.addEventSupportMaterials.emit(formArray.materials);
    } else
      this.notify('Por favor, preencha todos os campos obrigatórios');
  }

  private _createFormGroup(event: Event): FormGroup {
    return new FormGroup({
      'materials': this._setMaterialsFormArray(event)
    });
  }

  private _setMaterialsFormArray(event: Event): FormArray {
    if (event && event.supportMaterials) {
      return new FormArray(
        event.supportMaterials.map((mat) => this._createMaterialForm(mat))
      );
    } else {
      return new FormArray([
        this._createMaterialForm()
      ]);
    }
  }

  private _createMaterialForm(material: SupportMaterial = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(material ? material.title : '', [Validators.required]),
      'downloadLink': new FormControl(material ? material.downloadLink : '', [Validators.required]),
      'fileName': new FormControl(material ? material.title : ''),
      'id': new FormControl(material && material.id ? material.id : ''),
      'imageUrl': new FormControl (material && material.imageUrl ? material.imageUrl : null),
      'description': new FormControl(material ? material.description : '', [Validators.required]),
      'type': new FormControl(material && material.type ? material.type : SupportMaterialType.File, [Validators.required],
      )
    });
  }


  private _sendPdfToServer(file: File,  valueControl: AbstractControl, nameControl: AbstractControl) {

    this._uploadService.uploadFile(file).subscribe((uploadedFileUrl) => {
      valueControl.setValue(uploadedFileUrl);
      nameControl.setValue(file.name);
    }, () => {
      this.notify('Ocorreu um erro ao enviar o arquivo, por favor tente novamente mais tarde');
    });
  }
}
