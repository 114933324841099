<div class="inner-content">
  <header>
    <app-navigation-back></app-navigation-back>
    <h1>CONFIGURAÇÃO DO PROGRAMA</h1>
    <div class="actions">
      <button class="btn-test width-larger" [disabled]="form?.invalid" appDebounceClick (debounceClick)="saveContent()" [debounceTime]="700">
        {{ !programId? 'CADASTRAR' : 'SALVAR ALTERAÇÕES'}}
      </button>
    </div>
  </header>

  <mat-horizontal-stepper class="mat-elevation-z2" #stepper>

    <mat-step id="programInfo" [stepControl]="form?.get('info')" labelPosition="bottom"
      errorMessage="Essa etapa contem erros.">
      <ng-template matStepLabel>Informações</ng-template>
      <app-settings-program-info #programInfo></app-settings-program-info>
    </mat-step>

    <mat-step id="programTeam" [stepControl]="form?.get('team')" labelPosition="bottom"
      errorMessage="Essa etapa contem erros.">
      <ng-template matStepLabel>Turmas</ng-template>
      <app-settings-program-teams #programTeam></app-settings-program-teams>
    </mat-step>

    <mat-step id="programItems" [stepControl]="form?.get('items')" labelPosition="bottom" errorMessage="Essa etapa contem erros.">
      <ng-template matStepLabel>Itens</ng-template>
      <app-settings-program-items #programItems></app-settings-program-items>
    </mat-step>

    <mat-step id="programEcommerce" [stepControl]="form?.get('ecommerce')" labelPosition="bottom"
      errorMessage="Essa etapa contem erros.">
      <ng-template matStepLabel>E-commerce</ng-template>
      <app-ecommerce #programEcommerce></app-ecommerce>
    </mat-step>

  </mat-horizontal-stepper>

  <div class="footer">
    <div class="buttom-wrapper">
      <button [disabled]="stepper.selectedIndex === 0" class="btn-test previous" (click)="stepper.previous()" type="button" mat-button>
        ANTERIOR
      </button>
      <button [disabled]="stepper.selectedIndex === (stepsCount - 1)" class="btn-test next" type="button" (click)="stepper.next()" mat-button>
        PRÓXIMO
      </button>
    </div>
  </div>
</div>
