import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { ActivatedRoute } from '@angular/router';
import {UtilService} from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {

  public user: User;
  public userId: string;
  constructor(
    private _usersService: SettingsUsersService,
    private _activatedRoute: ActivatedRoute,
    private _utilService: UtilService,
  ) { }

  ngOnInit() {
    this.userId = this._activatedRoute.snapshot.paramMap.get('userId');
    this._loadUser( this.userId );
  }


  private _loadUser(userId: string): void {
    this._usersService.getUserProfile(
      userId
    ).subscribe((response) => {
      this.user = response.data;
      this._loadUserCareer( userId );

    }, (error) => {});
  }

  private _loadUserCareer(userId: string): void {
    this._usersService.getUserCareer(
      userId
    ).subscribe((response) => {
      this.user.career = response.data;
    }, () => { });
  }

  public formatDate(date: string): string {
    return this._utilService.formatDateToDDMMYYYY(new Date(date));
  }
}
