import { Component, OnInit, Input } from '@angular/core';
import {
  CategoryFilter,
  CategoryFilterSearch,
  CategoryFilterOption,
  CategoryFilterSearchOption,
  Parent,
} from 'src/app/shared/components/autocomplete/models/autocomplete.model';

import { environment } from 'src/environments/environment';
import { CategoryFilterEnum } from 'src/app/models/enums/category-filter.enum';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { forkJoin } from 'rxjs';
import { FilterTrackModule } from 'src/app/pages/report/filters/filter-track-module/models/filter-track-module.model';
import { ReportFilter } from 'src/app/pages/report/filters/shared/report-filter';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-filter-track-module',
  templateUrl: './filter-track-module.component.html',
  styleUrls: ['./filter-track-module.component.scss']
})
export class FilterTrackModuleComponent extends ReportFilter implements OnInit {

  public filters = {
    student: null,
    track: null
    , modules: null
  };
  public isBTG: boolean;
  public categoryFilters: Array<CategoryFilter> = [];
  public get filter(): FilterTrackModule {
    this.updateFilters(this.categoryFilters);

    return {
      student: this.filters.student,
      categories: this.categoryFilters.map(filter => ({
        columnName: filter.filterColumn,
        contentNames: filter.selectedOptions
      }))
    };
  }
  private _selectedFilters: Array<CategoryFilterSearchOption> = [];

  constructor(protected _reportSertivce: ReportsService, protected _snackBar: MatSnackBar) {
    super(_reportSertivce, _snackBar);
  }

  ngOnInit() {
    this.isBTG = environment.logo === 'btg' ? true : false;
    this._setCategory();
  }


  private _setFilter(
    filterName: string,
    category: number,
    filterColumn: string,
    itemsCount: number,
    collection: any[],
    prop: string = 'name',
    demandsId: boolean = false,
    isSingleOption: boolean = false,
    parent?: Parent,
  ) {

    const newFilter: CategoryFilter = {
      name: filterName,
      category: category,
      filterColumn: filterColumn,
      page: 1,
      itemsCount: itemsCount,
      maxLength: collection.length,
      options: [],
      selectedOptions: [],
      isAlternate: prop === 'title',
      demandsId: demandsId,
      isSingleOption,
      parent,
    };

    for (let index = 0; index < collection.length; index++) {
      newFilter.options.push({
        id: collection[index].id,
        description: collection[index][prop],
        checked: false
      });
    }

    this.categoryFilters.push(newFilter);
    this._selectedFilters.push({ columnName: filterColumn, contentNames: [] });
  }


  private _setCategory() {

    forkJoin({
      track: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Tracks }),
      modules: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Modules }),
    }).subscribe(response => {
      this._setFilter('Trilha', CategoryFilterEnum.Tracks, 'track.id',
        response.track.data.itemsCount, response.track.data.items, 'title', true, true);

      this._setFilter('Módulos', CategoryFilterEnum.Modules, 'module.id',
        0, [], 'title', true, false, { category: CategoryFilterEnum.Tracks, key: null });

      this.filters.track = this.categoryFilters[0];
      this.filters.modules = this.categoryFilters[1];

    });

  }


  public removeStudent(event) {
    this.filters.student = undefined;
  }
  public setStudent(student: UserPreview) {
    this.filters.student = student;
  }

}
