import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { TrackEvent } from 'src/app/pages/instructor/components/module-tracks-table/models/module-track.model';

@Component({
  selector: 'app-events-average-student-grades',
  templateUrl: './events-average-student-grades.component.html',
  styleUrls: ['./events-average-student-grades.component.scss']
})
export class EventAverageStudentGradesComponent {

  private _data: TrackEvent[];
  @Input()
  public get data(): TrackEvent[] { return this._data; }
  public set data(data: TrackEvent[]) {
    this._data = data;
  }

  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public messages = {
    emptyMessage: 'Buscando dados...', totalMessage: 'Total', selectedMessag: 'selecionado',
  };

  public rowIdentity = (row) => row.id;
  public rowHeight = (row) => row.height = 45;

  constructor(private _router: Router) { }



  public goToEventDetail(event) {
    const row = (event.row as TrackEvent);
    if (event.type === 'click')
      this._router.navigate([`instrutor/evento/${row.eventId}/${row.scheduleId}`]);
  }

}
