import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-professional-objectives',
  templateUrl: './professional-objectives.component.html',
  styleUrls: ['../manage-user-career.component.scss']
})
export class ProfessionalObjectivesComponent extends NotificationClass {

  @Input() formGroup: FormGroup;
  @Output() setValidator = new EventEmitter();
  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }


  public validField() {
    const career = this.formGroup.getRawValue();
    let checkProfessionalObjectivesField = true;
    if (!career.longDateObjectives || !career.shortDateObjectives) {
        this.notify('O campo Curto e Longo Prazo é obrigatório');
        checkProfessionalObjectivesField = false;
    }
    this.setValidator.emit({key: 'professionalObjectives', value: checkProfessionalObjectivesField });
  }
}
