import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { DialogFactoryService } from 'src/app/shared/services/dialog/dialog-factory.service';
import { IeffortPerformance } from '../../../models/studentMetrics';
import { DialogData } from 'src/app/shared/services/dialog/models/dialog-data.model';
import { isNil } from 'lodash';

@Component({
  selector: 'app-effort-performance-students',
  templateUrl: './effort-performance-students.component.html',
  styleUrls: ['./effort-performance-students.component.scss'],
})
export class EffortPerformanceStudentsComponent {
  @Input() effortPerformance: IeffortPerformance;
  @Input() title: string;
  @Input() isTrack: boolean = true;

  public dialog: DialogService;
  @ViewChild('understandDialogTemplate', { static: true })
  understandDialogTemplate: TemplateRef<any>;

  constructor(private dialogFactoryService: DialogFactoryService) { }


  public dispatchDialog() {
    this.openDialog({
      headerText: 'Métricas de desEMPENHO',
      template: this.understandDialogTemplate,
    });
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: 720,
      height: 260,
      disableClose: false,
    });
  }

  public getLabel(): string {
    if (
      this.effortPerformance.student &&
      this.effortPerformance.class &&
      this.effortPerformance.student.learning >
      this.effortPerformance.class.learning
    ) {
      return 'Sim';
    } else {
      return 'Não';
    }
  }

  public getAsPercent(percent: number): string {
    if (isNil(percent)) return;
    return (percent * 10).toFixed(2);
  }
}
