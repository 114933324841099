<ng-container>
  <h2>{{title}}</h2>

  <div class="header">
    <div class="search">
      <i class="logo icon-lupa search-icon"></i>
      <input type="text" [placeholder]="serachInputPlaceholder" (keyup)="updateSearch($event.target.value)" />
    </div>
    <div class="actions">
      <button class="btn-test" (click)="buttonClick.emit()">
        {{defaultButtonName}}
      </button>
      <ng-content #actions></ng-content>
    </div>
  </div>
</ng-container>
