import { Component, ViewChild } from '@angular/core';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { LogsService } from '../_services/logs.service';
import { AuditLog } from 'src/app/models/audit-log.interface';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { isArray } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { LogsDialogComponent } from 'src/app/settings/logs/logs-dialog/logs-dialog.component';
import { ELogActivity as EEntityType } from 'src/app/settings/logs/enums/log-activity.enum';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent extends NotificationClass {

  @ViewChild('Picker', { static: true }) private _picker: DateRangePickerComponent;

  public readonly pageSize: number = 19;
  public readonly displayedColumns: string[] = [
    'name', 'date', 'logAction', 'action'
  ];
  public logs: AuditLog[] = [];
  public logsCount: number = 0;
  public isLoading: boolean;
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public difference: string;
  public messages = {
    emptyMessage: 'Buscando dados...', totalMessage: 'Total', selectedMessag: 'selecionado',
  };
  public filter: {
    page: number
    , sortValue: string
    , sortDirection: boolean
    , sortAscending: boolean
    , fromDate: Date
    , toDate: Date
    , entityType: any
  };
  public entityType: EEntityType;
  public entityList = [
    { label: 'Módulo', value: EEntityType.Module }
    , { label: 'Trilha', value: EEntityType.Track }
    , { label: 'Evento', value: EEntityType.Event }
    , { label: 'Usuário', value: EEntityType.User }
    , { label: 'Teste/Avaliação', value: EEntityType.Valuation }
  ];

  private _sortDirection: boolean = false;
  private _fromDate: Date = null;
  private _toDate: Date = null;

  public rowIdentity = (row) => row.id;
  constructor(
    protected _snackBar: MatSnackBar,
    private _logsService: LogsService,
    private _dialogService: MatDialog
  ) {
    super(_snackBar);
  }



  private _loadProcesses({ page, sortValue, sortAscending = false, fromDate = null, toDate = null, entityType }): void {
    this._logsService.getPagedLogs(
      page, this.pageSize, sortValue, sortAscending, entityType, fromDate, toDate
    ).subscribe((response) => {
      this.logs = response.data.auditLogItems;
      this.logsCount = response.data.itemsCount;
      if (!this.logs.length) {
        this.messages.emptyMessage = 'Não há dados para exibir.';
      }
    });
  }

  public filterByDateRange(dates: Array<Date>) {
    this._fromDate = dates[0];
    this._toDate = dates[1];
  }

  public applyFilter(pageNumber?: number) {
    this.filter = {
      page: pageNumber ? pageNumber : 1,
      sortAscending: false,
      sortValue: 'date',
      sortDirection: this._sortDirection,
      fromDate: this._fromDate,
      toDate: this._toDate,
      entityType: this.entityType,
    };

    this._loadProcesses(this.filter);
  }

  public goToPage(pageInfo: any) {
    this.applyFilter(pageInfo.offset + 1);
  }

  public onRowClick(event: any) {
    if (!event) return;

    const hasClickedInActionProp =
      event.hasOwnProperty('column') && event.column.name === 'Ação' && event.type === 'click';

    if (hasClickedInActionProp) {
      let { newValues, oldValues } = event.row;
      oldValues = oldValues ? oldValues : {};
      if (isArray(newValues) || isArray(oldValues)) {
        newValues = { lista: newValues };
        oldValues = { lista: oldValues ? oldValues : [] };
      }
      const dialogRef = this._dialogService.open(LogsDialogComponent, {
        maxWidth: '100vw',
        width: '100vw',
        minHeight: '100vh',
        height: '100vh',
        hasBackdrop: false,
        data: { newValues, oldValues }
      });

    }

  }



}
