<div class="inner-content" >
  <div class="header">
    <div>
      <a href="javascript:history.back()">
        <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
        Voltar
      </a>
    </div>
    <h2>RESULTADO DE PESQUISAS</h2>
    <div class="button-header">
      <button class="btn-test" (click)="getAnswersExcel()" >
        Exportar avaliações
      </button>
    </div>
  </div>

  <table mat-table matSort [dataSource]="processes" (matSortChange)="sortData($event)" class="mat-elevation-z8">

    <ng-container matColumnDef="image">
      <th width="10%" mat-header-cell *matHeaderCellDef></th>
      <td width="10%" mat-cell *matCellDef="let row">
        <img class="user-img"
          [src]="row.imageUrl ? row.imageUrl : './assets/img/user-image-placeholder.png'"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th width="30%" mat-sort-header="name" mat-header-cell *matHeaderCellDef> Nome </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.name ? row.name : '-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th width="30%" mat-sort-header="email" mat-header-cell *matHeaderCellDef> Email </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.email ? row.email : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="grade">
      <th width="10%" mat-sort-header="grade" mat-header-cell *matHeaderCellDef> Nota </th>
      <td width="10%" mat-cell *matCellDef="let row">
        {{ row.grade ? row.grade : '-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th width="10%" mat-sort-header="createdAt" mat-header-cell *matHeaderCellDef> Data </th>
      <td width="10%" mat-cell *matCellDef="let row">
        {{ row.createdAt | date : 'dd/MM/yyyy':'+0000' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="objects">
      <th width="10%" mat-header-cell *matHeaderCellDef> Objetos Associados </th>
      <td width="10%" mat-cell *matCellDef="let row">
        <img class="icon"
          src="./assets/img/view.png"
          (click)="viewDetails(row)"
        />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>

  <app-pagination *ngIf="processesCount"
    [setPagination]="{
      'itemsCount': processesCount,
      'pageSize': pageSize
    }"
    (goToPage)="goToPage($event)"
  ></app-pagination>
</div>
  