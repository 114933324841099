import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationClass } from '../../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentModulesService } from '../../_services/modules.service';
import { MatDialog } from '@angular/material/dialog';
import { ModuleQuestionDialogComponent } from './module-question-dialog/module-question-dialog.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModuleQuestionApprovalDialogComponent } from './module-question-approval-dialog/module-question-approval-dialog.component';

@Component({
  selector: 'app-module-question-curatorship',
  templateUrl: './module-question-curatorship.component.html',
  styleUrls: ['./module-question-curatorship.component.scss']
})
export class ModuleQuestionCuratorshipComponent extends NotificationClass implements OnInit {

  public page: number = 1;
  public pageSize: number = 20;
  public searchValue: string = '';
  public moduleId: string = '';
  public subjectId: string = '';
  public displayedColumns: string[] = [
    'module', 'subject', 'question', 'everageGrade', 'positive', 'negative', 'status', 'approval', 'action'
  ];
  public moduleQuestions: any[] = [];
  public moduleQuestionsCount: number = 0;
  public inputSubscription: Subject<string> = new Subject();
  public modules: any[] = [];
  public selectedModule: any;
  public selectedSubject: any;

  @Output() updateModuleCount: EventEmitter<any> = new EventEmitter();

  constructor(
    protected _snackBar: MatSnackBar,
    public moduleService: ContentModulesService,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.getFilteredUserQuestionValuations();
    this.getFilteredModule();
  }

  getFilteredModule(searchValue = '') {
    this.moduleService.getPagedFilteredModulesList(1, 4, searchValue)
    .pipe(
      debounceTime(500)
    ).subscribe(res => {
      this.modules = res.data.modules;
    });
  }

  getFilteredUserQuestionValuations() {
    this.moduleService.getFilteredUserQuestionValuations(
      this.page, this.pageSize, this.searchValue, this.moduleId, this.subjectId
    )
    .pipe(
      debounceTime(500)
    ).subscribe(res => {
      this.moduleQuestions = res.data.questions;
      this.moduleQuestionsCount = res.data.itemsCount;
    }, (error) => {
      this.notify(
        this.getErrorNotification(error)
      );
    });
  }

  getModuleQuestionEverageGrade(moduleQuestion: any): number {
    if (
      moduleQuestion &&
      moduleQuestion.moduleQuestionValuations &&
      moduleQuestion.moduleQuestionValuations.length > 0
    ) {
      let totalGrade = 0;
      for (let i = 0; i < moduleQuestion.moduleQuestionValuations.length; i++) {
        const moduleQuestionValuation = moduleQuestion.moduleQuestionValuations[i];
        let valuationGrade = moduleQuestionValuation.textValuation +
        moduleQuestionValuation.conceptsValuation +
        moduleQuestionValuation.answersValuation;
        valuationGrade = valuationGrade / 3;
        totalGrade += valuationGrade;
      }
      totalGrade = totalGrade / moduleQuestion.moduleQuestionValuations.length;
      return totalGrade;
    } else {
      return 0;
    }
  }

  getModuleQuestionStatus(moduleQuestion: any): string {
    if (moduleQuestion && moduleQuestion.moduleQuestion && moduleQuestion.moduleQuestion.status) {
      if (moduleQuestion.moduleQuestion.status === 2) {
        return 'Aprovado';
      } else if (moduleQuestion.moduleQuestion.status === 3) {
        return 'Rejeitado';
      } else {
        return 'Pendente';
      }
    } else {
      return 'Pendente';
    }
  }

  getModuleTagStatusColor(moduleQuestion: any): string {
    if (moduleQuestion && moduleQuestion.moduleQuestion && moduleQuestion.moduleQuestion.status) {
      if (moduleQuestion.moduleQuestion.status === 2) {
        return 'var(--success-color)';
      } else if (moduleQuestion.moduleQuestion.status === 3) {
        return 'var(--danger-color)';
      } else {
        return 'var(--warn-color)';
      }
    } else {
      return 'var(--warn-color)';
    }
  }

  changeModuleQuestionStatus(moduleQuestion: any, status: number) {
    if (status === 2) {
      const dialogRef = this._dialog.open(ModuleQuestionApprovalDialogComponent, {
        maxWidth: '100%',
        width: '100%',
        maxHeight: '100%',
        height: '100%',
        panelClass: 'custom-dialog',
        data: {
          moduleQuestion: moduleQuestion.moduleQuestion
        }
      });

      dialogRef.afterClosed().subscribe(level => {
        if (level > -1) {
          this.moduleService.updateModuleQuestionStatus(moduleQuestion.moduleQuestion.id, status, level).subscribe(() => {
            moduleQuestion.moduleQuestion.status = status;
            this.updateModuleCount.emit();
          }, (error) => {
            this.notify(
              this.getErrorNotification(error)
            );
          });
        }
      });
    } else {
      this.moduleService.updateModuleQuestionStatus(moduleQuestion.moduleQuestion.id, status).subscribe(() => {
        moduleQuestion.moduleQuestion.status = status;
        this.updateModuleCount.emit();
      }, (error) => {
        this.notify(
          this.getErrorNotification(error)
        );
      });
    }
  }

  public moduleQuestionDialog(moduleQuestion: any) {
    this._dialog.open(ModuleQuestionDialogComponent, {
      maxWidth: '100%',
      width: '100%',
      maxHeight: '100%',
      height: '100%',
      data: {
        moduleQuestion: moduleQuestion.moduleQuestion,
        module: moduleQuestion.module,
        subject: moduleQuestion.subject,
        moduleQuestionValuations: moduleQuestion.moduleQuestionValuations
      }
    });
  }

  public triggerQuestionSearch(searchValue: string) {
    if (searchValue && searchValue.trim() !== '') {
      this.searchValue = searchValue;
      this.getFilteredUserQuestionValuations();
    }
  }

  public handleSelectModuleFilterChange(selectedModule: any): void {
    this.moduleId = selectedModule.id;
    this.getFilteredUserQuestionValuations();
  }

  public onKey(searchTerm: string) {
    this.getFilteredModule(searchTerm);
  }

  public openedChange(event) {
    if (event) {
      const input = document.getElementById('input-search') as HTMLInputElement;
      input.value = '';
    } else {
      this.getFilteredModule();
    }
  }

  public handleSelectSubjectsFilterChange(selectedSubject: any) {
    this.subjectId = selectedSubject.id;
    this.getFilteredUserQuestionValuations();
  }
}
