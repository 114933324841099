import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { TrackOverview, LateStudent } from 'src/app/models/track-overview.interface';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ERole } from 'src/app/models/enums/role.enum';

@Component({
  selector: 'app-track-overview-students',
  templateUrl: './track-students.component.html',
  styleUrls: ['./track-students.component.scss']
})
export class TrackOverviewStudentsComponent extends NotificationClass implements OnInit {

  @Input() readonly track: TrackOverview;
  @Input() readonly itemsCount: number = 0;
  @Output() goToPage: EventEmitter<number> = new EventEmitter();
  @Output() searchStudent: EventEmitter<string> = new EventEmitter();

  public isAdmin: boolean = false;
  private _searchStudentSubject: Subject<string> = new Subject();

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _tracksService: SettingsTracksService,
    private _authService: AuthService,
    private _excelService: ExcelService
  ) {
    super(_snackBar);
    this.isAdmin = this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources);
  }


  ngOnInit(): void {
    this._searchStudentSubject
      .pipe(
        debounceTime(700),
        distinctUntilChanged()
      ).subscribe(name => this.searchStudent.emit(name));
  }


  public getLateStudents(track: TrackOverview): number {
    const lateStudents: Array<LateStudent> = [];

    if (track.lateStudents) {
      track.lateStudents.forEach(student => {
        if (!lateStudents.some(s => student.studentId === s.studentId))
          lateStudents.push(student);
      });
    }

    return lateStudents.length;
  }

  public setStudentName(studentName: string) {
    this._searchStudentSubject.next(studentName);
  }

  public checkStudentLate(track: TrackOverview, studentId: string): number | null {
    if (track.lateStudents) {
      const student = track.lateStudents.find(x => x.studentId === studentId);
      if (student && student.lateEvents && student.lateEvents.length > 0)
        return student.progress / student.lateEvents.length;

      return null;
    }
    return null;
  }

  public viewStudentOverview(student: User) {
    this._router.navigate(['configuracoes/trilha-de-curso/' + this.track.id + '/' + student.id]);
  }

  public canExport(): boolean {
    const trackPreview = localStorage.getItem(LocalStorageService.key.trackResponsible);
    if (trackPreview) {
      const track: TrackPreview = JSON.parse(trackPreview);
      if (track) return !track.subordinate;
    }
    return true;
  }

  public exportStudents() {
    this._tracksService.getTrackOverviewStudents({ trackId: this.track.id, getBinaryResponse: true }).subscribe();
  }

}
