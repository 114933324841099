import { ExamNpsComponent } from './../exam/exam-nps/exam-nps.component';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NotificationClass } from '../../shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ValuationTest,
  ValuationTestQuestion,
  ValuationTestQuestionOption,
  ValuationTestQuestionTypeEnum,
} from 'src/app/models/valuation-test.interface';
import { ExamQuestionComponent } from '../exam/exam-question/exam-question.component';
import { ExamQuestionDiscursiveComponent } from '../exam/exam-question/exam-question-discursive/exam-question-discursive.component';
import { isEmpty, isNil, shuffle } from 'lodash';
import { ValuationTestTypeEnum } from 'src/app/models/enums/valuation-test-type-enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-valuation-test',
  templateUrl: './valuation-test.component.html',
  styleUrls: ['./valuation-test.component.scss'],
})
export class ValuationTestComponent extends NotificationClass implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('questions') questionsComponent: ExamQuestionComponent;
  @ViewChild('questionsDiscursive') questionsDiscursiveComponent: ExamQuestionDiscursiveComponent;
  @ViewChild('questionNPS') examNpsComponent: ExamNpsComponent;

  public test: ValuationTest;
  public testId: string;
  public currentQuestion: ValuationTestQuestion;
  public currentQuenstionIndex: number = 0;
  public totalQuestions: number = 0;
  public canMoveToNextQuestion: boolean = false;
  public currentAnswareSelect: string = '';
  public answerResult: any = null;
  public last: boolean = false;
  public valuationTestQuestionType = ValuationTestQuestionTypeEnum;
  public get getAnswareSelect(): any {
    if (this.questionsComponent) return this.questionsComponent.getSelectedAnswer;
  }
  public get getValueAnswerDiscursive(): any {
    if (this.questionsDiscursiveComponent) return this.questionsDiscursiveComponent.getValueAnswer;
  }
  public hasComment: boolean = environment.features.questionComment;
  public showComment: boolean = false;
  public isMock: boolean = false;

  public get getValueAnswerNps(): any {
    if (this.examNpsComponent) return this.examNpsComponent.getSelectedAnswer;
  }

  constructor(
    protected _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _testService: SettingsValuationTestsService,
    private cdr: ChangeDetectorRef
  ) {
    super(_snackBar);
    const accessByComponent = history && history.state && history.state.accessByComponent;
    if (!accessByComponent) this.notify('Não é possível acessar essa área através desse método')
      .afterOpened()
      .subscribe(() => history.back());

  }
  ngOnInit() {
    this.testId = this._activatedRoute.snapshot.paramMap.get('testId');
    this._loadTest(this.testId);
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  public checkFullyAnswered(): boolean {
    return this.test.testQuestions.every((tQ) => tQ.answer && tQ.answer.trim() !== '');
  }

  public sendResponse(): void {
    if (this.test && this.test.type !== ValuationTestTypeEnum.Mock) {
      const isFullyAnswered = this.checkFullyAnswered();

      if (!isFullyAnswered && this.test.type !== ValuationTestTypeEnum.Percentile) {
        this.notify('Por favor, responda todas as perguntas para continuar');
        return;
      }

      this._testService.saveValuationTestResponse(this.test).subscribe(
        () => {
          this.notify('Resposta salva com sucesso!');
          localStorage.removeItem(this.testId + 'valuation_teste');
          window.history.back();
        },
        (error) => {
          this.notify(this.getErrorNotification(error));
          this._router.navigate(['home']);
        }
      );
    } else {
      localStorage.removeItem(this.testId + 'valuation_teste');
      window.history.back();
    }
  }

  private _loadTest(testId: string) {
    this._testService.getValuationUser(testId).subscribe(
      async (response) => {
        this.test = response.data;
        this.test.testQuestions = this.test.testQuestions.filter((quest) => !quest.canceled);
        this.totalQuestions = this.test.testQuestions.length;
        if (this.test && this.test.type !== ValuationTestTypeEnum.Mock) {
          this.test.testQuestions.forEach((question) => {
            if (question.type === ValuationTestQuestionTypeEnum.MultipleChoice) question.options = shuffle(question.options);
          });
          await this._loadResponses(testId);
        } else {
          this.isMock = true;
          this.setCurrentQuestion(this.currentQuenstionIndex);
        }
      },
      (error) => {
        this.notify(this.getErrorNotification(error));
        this._router.navigate(['home']);
      }
    );
  }

  private async _loadResponses(testId: string) {
    this._testService
      .getDraftValuationTestResponses(testId)
      .toPromise()
      .then((response) => {
        if (response.data.length > 0) {
          let testChange = false;
          response.data.forEach((answer: any) => {
            const questIndex = this.test.testQuestions.findIndex((question) => question.id === answer.id);
            if (isNil(questIndex)) testChange = true;
          });
          if (!testChange) {
            response.data.forEach((answer: any, index: number) => {
              const questIndex = this.test.testQuestions.findIndex((question) => question.id === answer.id);
              if (questIndex !== -1) this.test.testQuestions[questIndex].answer = answer.answer;
            });
          }
        }
        this.setCurrentQuestion(this.currentQuenstionIndex);
        this.setSelectedAnswer(this.currentQuestion);
      });
  }
  public _parseStorageTest(storageTest: string) {
    this.test = JSON.parse(storageTest);
    this.totalQuestions = this.test.testQuestions.length;

    this.setCurrentQuestion(this.currentQuenstionIndex);
    this.setSelectedAnswer(this.currentQuestion);
  }

  public goToNextQuestion(): void {
    if (this.currentQuenstionIndex + 1 >= this.totalQuestions) return;
    this.currentQuenstionIndex++;
    this.setCurrentQuestion(this.currentQuenstionIndex);
    this.setSelectedAnswer(this.test.testQuestions[this.currentQuenstionIndex]);
  }

  public _canMoveToNextQuestion(): boolean {
    if (this.test && isEmpty(this.test.testQuestions)) {
      this.notify('Esta avaliação não possui nenhuma questão');
      history.back();
      throw new Error(`O teste ${this.test.id} não possui nenhuma questão`);
    }

    if (this.test && this.test.type === ValuationTestTypeEnum.Mock) {
      return true;
    }

    return (
      this.test &&
      !isNil(this.currentQuestion.answer) &&
      (this.currentQuestion.answer === (this.getAnswareSelect && this.getAnswareSelect.text) ||
        this.currentQuestion.answer === this.getValueAnswerDiscursive ||
        this.currentQuestion.answer === this.getValueAnswerNps)
    );
  }

  public countQuestionAnswered(): number {
    return this.test ?
      this.test.testQuestions.filter((quest) => !isNil(quest.answer)).length / this.totalQuestions
      : 0;
  }

  public goToBackQuestion(): void {
    if (this.currentQuenstionIndex - 1 < 0) return;
    this.currentQuenstionIndex--;
    this.setCurrentQuestion(this.currentQuenstionIndex);
    this.setSelectedAnswer(this.currentQuestion);
  }

  public setSelectedAnswer(testQuestion: ValuationTestQuestion): void {
    if (this.answerResult) this.answerResult = null;
    this.showComment = this.hasComment && !isNil(testQuestion.comment);
    switch (testQuestion.type) {
      case ValuationTestQuestionTypeEnum.MultipleChoice:
        const optionSelected = testQuestion.options.find((option) => option.text === testQuestion.answer);
        this._setSelectedAnswers(optionSelected, ValuationTestQuestionTypeEnum.MultipleChoice);
        break;
      case ValuationTestQuestionTypeEnum.Discursive:
        this._setSelectedAnswers(testQuestion.answer, ValuationTestQuestionTypeEnum.Discursive);
        break;
      case ValuationTestQuestionTypeEnum.NPS:
        this._setSelectedAnswers(testQuestion.answer, ValuationTestQuestionTypeEnum.NPS);
        break;
      default:
        break;
    }
  }

  public backToModule(): void {
    history.back();
  }

  public getTitle(): string {
    return `Questão (${this.currentQuenstionIndex + 1}/${this.totalQuestions})`;
  }

  public setCurrentQuestion(currentIndex: number): void {
    this.currentQuestion = this.test.testQuestions[currentIndex];
  }

  public confirmAnswer(answer: ValuationTestQuestionOption | string): void {
    this.currentQuestion.answer = (answer as ValuationTestQuestionOption).text || (answer as string);
    if (this.test && this.test.type !== ValuationTestTypeEnum.Mock) {
      const isFinished = this.currentQuenstionIndex + 1 >= this.totalQuestions;
      if (!isFinished) {
        this.currentQuenstionIndex++;
        this.setCurrentQuestion(this.currentQuenstionIndex);
        this._configNextAnswer();
      }
      this._testService.saveDraftValuationTestResponse(this.test).subscribe();
    } else {
      this._setMockAnswers(answer);
    }
    if (this.hasComment && this.currentQuestion.comment) {
      this.setSelectedAnswer(this.currentQuestion);
    }

  }

  private _configNextAnswer() {
    if (isNil(this.currentQuestion.answer)) {
      if (this.questionsComponent) this.questionsComponent.resetAnswer();
      if (this.questionsDiscursiveComponent) this.questionsDiscursiveComponent.resetAnswer();
      if (this.examNpsComponent) this.examNpsComponent.resetAnswer();
    } else {
      this.setSelectedAnswer(this.currentQuestion);
    }
  }

  private _setMockAnswers(answer: string | ValuationTestQuestionOption = null) {
    if (this.currentQuestion.type === ValuationTestQuestionTypeEnum.MultipleChoice) this.answerResult = answer;
    this.last = this.currentQuenstionIndex + 1 === this.totalQuestions;
  }

  private _setSelectedAnswers(answer: string | ValuationTestQuestionOption = null, type: ValuationTestQuestionTypeEnum) {
    setTimeout(() => {
      answer ?
        this.setAnswersByType(answer, type)
        : this.setAnswersByType('', type);
    }, 10);
  }


  private setAnswersByType(answer: string | ValuationTestQuestionOption, type: ValuationTestQuestionTypeEnum) {
    switch (type) {
      case ValuationTestQuestionTypeEnum.MultipleChoice:
        this.questionsComponent.setAnswerinit(answer);
        this._setMockAnswers(answer);
        break;
      case ValuationTestQuestionTypeEnum.Discursive:
        this.questionsDiscursiveComponent.setAnswer(answer as string);
        this._setMockAnswers();
        break;
      case ValuationTestQuestionTypeEnum.NPS:
        this.examNpsComponent.setAnswerDraftNps(answer as string);
        break;
      default:
        break;
    }
  }
}
