<div class="forgot-pass" >
    <h3>Preencha o campo de usuário ou email e clique enviar</h3>
    <form class="form" [formGroup]="formGroup">
        <mat-form-field class="login-input">
        <input matInput placeholder="Usuário ou Email" formControlName="credential" required />
        </mat-form-field>
    </form>
  </div>
  <p style="padding-bottom: 20px;" (click)="save()">ENVIAR</p>
  <p (click)="dismiss()" >Cancelar</p>
