import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Module, ModuleQuestion, ModuleQuestionValuation } from 'src/app/models/module.model';
import { Subject } from 'src/app/models/subject.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ContentModulesService } from '../../_services/modules.service';

@Component({
  selector: 'app-question-valuation-finish-dialog',
  templateUrl: './question-valuation-finish-dialog.component.html',
  styleUrls: ['./question-valuation-finish-dialog.component.scss']
})
export class QuestionValuationFinishDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<QuestionValuationFinishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
