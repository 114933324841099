import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsTracksService } from '../../_services/tracks.service';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { TrackOverview } from 'src/app/models/track-overview.interface';
import { UtilService } from 'src/app/shared/services/util.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Level } from 'src/app/models/shared/level.interface';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { TimelineElement } from 'src/app/shared/components/horizontal-timeline/timeline-element';
import { flatMap, uniqBy, orderBy, compact, isEmpty, remove } from 'lodash';
import { SortDirection } from '@swimlane/ngx-datatable';
import { Subject, Subscription } from 'rxjs';
import { ERole } from 'src/app/models/enums/role.enum';
import { Item } from 'src/app/shared/components/collapsed-list/item/models/item.model';
import { debounceTime, filter, pairwise, take } from 'rxjs/operators';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { TrackRankingDialogComponent } from './components/track-ranking-dialog/track-ranking-dialog.component';
import { IstudentsRanking } from 'src/app/pages/cockpit/models/trackStudentsSubordinates';
import { ContentTracksService } from 'src/app/pages/_services/tracks.service';
import { environment } from 'src/environments/environment';

enum EReport {
  ReasonEvasion = 1
}

@Component({
  selector: 'app-settings-track-overview',
  templateUrl: './track-overview.component.html',
  styleUrls: ['./track-overview.component.scss']
})
export class SettingsTrackOverviewComponent extends NotificationClass implements OnInit, OnDestroy {

  public EReport = EReport;
  public track: TrackOverview;
  public trackStudents: { students, lateStudents, id };
  public levels: Array<Level> = [];
  public trackParticipation: any[] = [];
  public isBusinessManager: boolean = false;
  public content: string = '';
  public timeline: TimelineElement[] = [];
  public totalDays: number = 0;
  public currentSortDir = SortDirection.asc;
  public expectedProgress: number = 0;
  public userProgress = undefined;
  public users: string[];
  public segments: string[];
  public isStudent: boolean = false;
  public isAdmin: boolean = false;
  public isManager = false;
  public tabLabel = {
    OPERATIONAL: 'OPERACIONAL', MANAGEMENT: 'GERENCIAL', STUDENT: 'ALUNO', MANAGER: 'GESTOR'
  };
  public selectedTab: number = 0;
  public canSeeManerialTab = this._authService.hasRole(ERole.Admin) ||
    this._authService.hasRole(ERole.HumanResources) || this._authService.isSeenHowManager();

  public ranking: IstudentsRanking[] = [];

  public hasFeatureCockpitMetrics = environment.features.cockpitMetrics;

  private _userSearchSubject: Subject<string> = new Subject();
  private _trackEventInfo: TrackOverview;
  private _trackId: string;
  private _currentPage: number = 1;
  private _searchTerm: string = '';
  private _role = this._authService.getLoggedUserRole();
  private _tabLabel: string = '';
  private _loggedUser: LoginResponse;

  @ViewChildren(MatTab) private _tabs: QueryList<MatTab>;

  constructor(
    protected _snackBar: MatSnackBar,
    private _tracksService: SettingsTracksService,
    private _contentTracksService: ContentTracksService,
    private _activatedRoute: ActivatedRoute,
    private _utilService: UtilService,
    private _sharedService: SharedService,
    private _authService: AuthService,
    private _changeDetector: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
    this._loggedUser = this._authService.getLoggedUser();
    this.isStudent = this._role === ERole.Student || this._role === ERole.Admin;
    this.isManager = this._authService.isSeenHowManager();
    this.isAdmin = this._role === ERole.Admin || this._role === ERole.HumanResources;
  }

  ngOnInit() {
    this._trackId = this._activatedRoute.snapshot.paramMap.get('trackId');

    this._loadLevels();
    this._loadTrack(this._trackId).add(() => {
      const tabLabel = sessionStorage.getItem(SessionStorageService.key.trackOverviewTab);
      this._changeDetector.detectChanges();
      if (tabLabel) this._setTab(tabLabel);
    });
    this._loadMetrics(this._trackId);
    const isAdmin = this._authService.getLoggedUserRole() === ERole.Admin;
    this.hasFeatureCockpitMetrics = isAdmin ? isAdmin : this.hasFeatureCockpitMetrics;
  }

  ngOnDestroy(): void {
    if (this._userSearchSubject)
      this._userSearchSubject.unsubscribe();
  }

  public onTabSelected($event: MatTabChangeEvent) {
    if (!this._trackId || !$event) return;
    this._tabLabel = $event.tab.textLabel.toUpperCase();

    if (this.tabLabel.MANAGEMENT.toUpperCase().includes(this._tabLabel)) {
      this._loadUserProgress(this._trackId);
    } else if (this.tabLabel.MANAGER.toUpperCase().includes(this._tabLabel)) {
      this._loadUserProgress(this._trackId);
    }
  }

  public getFormattedHour(): string {
    if (!this.track || !this.track.duration) return '--';
    return this._utilService.formatSecondsToHourMinute(this.track.duration);
  }

  public goToPage(page: number) {
    if (page !== this._currentPage) {
      this._currentPage = page;
      this._loadTrack(this._trackId);
    }
  }

  public searchStudent(name: string) {
    this._searchTerm = name;
    this._loadTrack(this._trackId);
  }

  public getRadarLabels(): Array<number> {
    if (this.track)
      return this.track.modulesConfiguration.filter(module => module.hasQuestions === true).map((m, index) => index + 1);
  }

  public getRadarTitleCallback(tooltipItem, data): string {
    const moduleIndex = data.labels[tooltipItem[0].index] - 1;
    return this.track.modulesConfiguration.filter(module => module.hasQuestions === true).find(
      (m, index) => index === moduleIndex
    ).title;
  }

  public getGantProgressTitleCallback(tooltipItem, data): string {
    return tooltipItem[0].yLabel;
  }

  public getRadarDataset() {
    const modules = this.track ? this.track.modulesConfiguration.filter(module => module.hasQuestions === true) : [];
    return [{
      label: 'OBJETIVO',
      data: modules.map(m => (m.level + 1)),
      backgroundColor: 'rgba(255, 67, 118, 0.35)',
      borderColor: 'transparent',
      pointBackgroundColor: 'rgba(255, 67, 118, 0.35)',
      pointRadius: 8
    }, {
      label: 'TURMA',
      data: modules.map(m => m.classLevel),
      backgroundColor: 'rgba(36, 188, 209, 0.35)',
      borderColor: 'rgb(36, 188, 209)',
      pointBackgroundColor: 'rgb(36, 188, 209)',
      pointRadius: 15
    }];
  }

  public getRadarTooltipCallback(tooltipItem, data): string {
    const level = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
    return 'Level ' + level.toFixed(1) + this.getLevelDescription(
      Math.round(level - 1)
    );
  }

  public getGantProgressTooltipCallback(tooltipItem, data): boolean {
    const startDate = new Date(this.track.modulesConfiguration[tooltipItem.index].openDate);
    const endDate = new Date(this.track.modulesConfiguration[tooltipItem.index].valuationDate);
    data = 'Início: ' + this.formatDate(startDate) + '\n' + ' Fim: ' + this.formatDate(endDate);

    return data;
  }

  public getLevelDescription(level: number): string {
    if (level < 0 || !this.levels) return '';

    const selectedLevel = this.levels.find(l => l.id === level);
    return ' (' + selectedLevel.description + ')';
  }

  private _setTab(tabLabel: string) {
    const selectedtab = this._tabs.find((tab) =>
      tab.textLabel.toUpperCase().includes(tabLabel.toUpperCase())
    );
    this.selectedTab = selectedtab.position;
    sessionStorage.setItem(SessionStorageService.key.trackOverviewTab, '');
  }

  private _buildTrackParticipation() {

    const students = flatMap(flatMap(this.track.modulesConfiguration).map(t => t.students));
    const groupStudent = compact(students).length ? uniqBy(students, s => s.userId) : [];

    this.trackParticipation = groupStudent.map((student: any) => ({
      grade: student.trackGrade,
      imageUrl: student.imageUrl,
      id: student.userId,
      userName: student.userName,
      name: student.name,
      ranking: student.ranking,
      businessGroup: student.businessGroup ? student.businessGroup : '',
      businessUnit: student.businessUnit ? student.businessUnit : '',
      segment: student.segment ? student.segment : '',
    }));
    this.trackParticipation = orderBy(this.trackParticipation, x => x.ranking, this.currentSortDir);

  }

  private _loadTrack(trackId: string): Subscription {
    return this._tracksService.getTrackOverview(
      trackId, this._currentPage, this._searchTerm
    ).subscribe((response) => {
      this.track = response.data;
      this.trackStudents = {
        id: this.track.id,
        students: [...this.track.students || []],
        lateStudents: [...this.track.lateStudents || []],
      };

      remove(this.track.students, student => student.isBlocked || (student.tracksInfo as any[]).some(trackInfo => trackInfo.blocked));

      if (this._trackEventInfo) {
        this.track.eventsConfiguration = this._trackEventInfo.eventsConfiguration;
        this._buildTrackParticipation();
      }
      this._loadTrackOverviewEventInfo(this._trackId);
      this.getGanttProgressDataset();
    });
  }

  private _loadTrackOverviewEventInfo(trackId: string): void {
    this._tracksService.getTrackOverviewEventInfo(
      { trackId }
    ).subscribe((response) => {
      this._trackEventInfo = response.data;
      if (this.track) {
        this.track.eventsConfiguration = response.data.eventsConfiguration;
        this.track.lateStudents = response.data.lateStudents;
        this._buildTrackParticipation();
      }

    });
  }

  private _loadLevels(): void {
    this._sharedService.getLevels(true).subscribe((response) => {
      this.levels = response.data;
    });
  }

  public goToPageEcommerceUrl() {
    window.open(this.track.ecommerceUrl, '_blank');
  }

  public goToPageStoreUrl() {
    window.open(this.track.storeUrl, '_blank');
  }

  public getGanttProgressDataset() {
    if (this.track) {

      const startDate = new Date(Math.min.apply(null, this.track.modulesConfiguration
        .filter(m => m.openDate)
        .map(m => new Date(m.openDate)))
      );

      const endDate = new Date(Math.max.apply(null, this.track.modulesConfiguration
        .filter(m => m.valuationDate ? m.valuationDate : m.cutOffDate)
        .map(m => new Date(m.valuationDate || m.cutOffDate))));

      this.totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

      const today = new Date();

      this.expectedProgress = +((
        this.track.modulesConfiguration
          .filter(m => m.openDate)
          .filter(m => {
            const cutOffDate = (new Date(m.cutOffDate) || new Date(m.valuationDate));
            return cutOffDate && today.getTime() >= cutOffDate.getTime();
          }).length / this.track.modulesConfiguration.length)
        * 100).toFixed(2);

      const progressOffsetDataset = {
        data: [],
        backgroundColor: 'transparent',
        label: 'Tempo Livre'
      };

      const progressCompletedDataset = {
        data: [],
        backgroundColor: '#5AFF59',
        label: 'Módulo Finalizado'
      };

      const progressCurrentFallOffDataset = {
        data: [],
        backgroundColor: '#EBE759',
        label: 'Duração Restante'
      };

      const progressRemainingDataset = {
        data: [],
        backgroundColor: '#59ACFF',
        label: 'Módulo Não Iniciado'
      };


      for (let i = 0; i < this.track.modulesConfiguration.length; i++) {
        const module = this.track.modulesConfiguration[i];
        const currentModuleStartDate = new Date(module.openDate);
        const currentModuleEndDate = new Date(module.valuationDate || module.cutOffDate);
        const currentModuleOffsetDate = (currentModuleStartDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
        let currentModuleCompletedProgress = 0;
        let currentModuleFallOffProgress = 0;
        let currentModuleRemainingProgress = 0;

        const hasIgnoredTuple =
          isNaN(currentModuleStartDate.getTime()) ||
          isNaN(currentModuleEndDate.getTime()) ||
          currentModuleOffsetDate < 0;


        if (currentModuleStartDate < today && currentModuleEndDate < today) {
          currentModuleCompletedProgress = (currentModuleEndDate.getTime() - currentModuleStartDate.getTime()) / (1000 * 3600 * 24);
        } else {
          if (currentModuleStartDate < today && currentModuleEndDate > today) {
            currentModuleCompletedProgress = (today.getTime() - currentModuleStartDate.getTime()) / (1000 * 3600 * 24);
            currentModuleFallOffProgress = (currentModuleEndDate.getTime() - today.getTime()) / (1000 * 3600 * 24);
          }
        }

        if (currentModuleStartDate > today && currentModuleEndDate > today) {
          currentModuleRemainingProgress = (currentModuleEndDate.getTime() - currentModuleStartDate.getTime()) / (1000 * 3600 * 24);
        }

        progressOffsetDataset.data[i] =
          hasIgnoredTuple ? null : parseFloat((currentModuleOffsetDate * 100 / this.totalDays).toFixed(2));
        progressCompletedDataset.data[i] =
          hasIgnoredTuple ? null : parseFloat((currentModuleCompletedProgress * 100 / this.totalDays).toFixed(2));
        progressCurrentFallOffDataset.data[i] =
          hasIgnoredTuple ? null : parseFloat((currentModuleFallOffProgress * 100 / this.totalDays).toFixed(2));
        progressRemainingDataset.data[i] =
          hasIgnoredTuple ? null : parseFloat((currentModuleRemainingProgress * 100 / this.totalDays).toFixed(2));
      }

      const dataset = [progressOffsetDataset, progressCompletedDataset, progressCurrentFallOffDataset, progressRemainingDataset];
      return dataset;
    }
  }

  public getGanttProgressLabels(): Array<string> {
    if (this.track)
      return this.track.modulesConfiguration.map((m, index) => m.title);
  }

  private formatDate(date: Date): string {
    const mm = date.getMonth() + 1;
    const dd = date.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join('/');
  }

  private _loadUserProgress(trackId: string) {
    if (!isEmpty(this.userProgress)) return;
    this._tracksService
      .getUsersProgress(trackId)
      .subscribe((response) => {
        this.userProgress = response.data;
        this._changeDetector.detectChanges();
      });
  }

  private _loadMetrics(trackId: string) {
    this._contentTracksService.getOverviewTrackMetrics(trackId).subscribe((resp) => {
      this._loadStudentsRanking(resp.data);
    });
  }

  private _loadStudentsRanking(students: Array<any>): void {
    this.ranking = [];
    students.forEach((stdranking) => {
      const ranking: IstudentsRanking = {
        id: stdranking.id,
        name: stdranking.name,
        email: stdranking.email,
        grade: stdranking.grade,
        ranking: stdranking.ranking,
        learning: stdranking.learning,
        averageEventGrade: stdranking.averageEventGrade,
        interactionUntilDay: stdranking.interactionUntilDay,
        interactionUntilDayInHours: stdranking.interactionUntilDayInHours || 0,
        progressUntilDay: stdranking.progressUntilDay,
        diagnostic: stdranking.diagnostic || 0,
        valuation: stdranking.valuation || 0,
        participation: stdranking.participation || 0
      };

      this.ranking.push(ranking);
    });
    this.ranking = orderBy(this.ranking, ['grade'], ['desc']);
  }

  public openTrackRankingDialog(): void {
    this._dialog.open(TrackRankingDialogComponent, {
       width: '1200px',
       maxHeight: '90vh',
       height: '673px',
       data: {
         ranking: this.ranking,
         showFactor: true
       }
     });
   }

}
