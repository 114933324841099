import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { differenceInSeconds } from 'date-fns';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { ERole } from 'src/app/models/enums/role.enum';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { TrackEvent } from 'src/app/models/track-event.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends NotificationClass {
  private _hasLinkedTrack: any;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    protected snackBar: MatSnackBar,
  ) {
    super(snackBar);

  }

  public goToStudentEventContent(
    item: {
      isEvent: boolean, alwaysAvailable: boolean, openDate: string, blocked: boolean, eventId: string, eventScheduleId: string,
      valuationDate: string, programId: string
    }
  ): void {
    if (!item.isEvent && item.blocked) {
      this.notify('É necessário se aplicar ao case antes de visualizar o curso.');
      return;
    }

    if (this._checkModuleEventAvailability(item)) {
      this._router.navigate(['evento/' + item.eventId + '/' + item.eventScheduleId]);
    }
  }

  private _checkModuleEventAvailability(
    item: { isEvent: boolean, alwaysAvailable: boolean, openDate: string, programId: string, valuationDate: string }): boolean {
    if (item.alwaysAvailable === null ||
      item.alwaysAvailable === undefined ||
      item.alwaysAvailable ||
      (item.alwaysAvailable === false && (item.openDate === null || item.openDate === undefined))
    ) {
      return true;
    }

    const diffOpen = differenceInSeconds(new Date(item.openDate), new Date());
    if (!item.isEvent && item.valuationDate !== null) {
      if (new Date() > new Date(item.valuationDate)) {
        this.notify('O acesso a esse módulo não está disponível: O módulo está finalizado');
        return false;
      }
    }

    if (diffOpen <= 0)
      return true;

    item.isEvent ? this.notify('Este evento ainda não começou') : this.notify('Este módulo ainda não começou');
    return false;
  }

  public goToStudentModuleContent(
    item: {
      isEvent: boolean,
      alwaysAvailable: boolean,
      blocked: boolean,
      openDate: string,
      trackId: string,
      programId: string,
      moduleId: string,
      valuationDate: string
    }
  ): void {

    if (!item.isEvent && item.blocked) {
      this.notify('É necessário se aplicar ao case antes de visualizar o curso.');
      return;
    }

    const queryParams = {};
    if (!!item.trackId) Object.assign(queryParams, { trackId: item.trackId });
    if (!!item.programId) Object.assign(queryParams, { programId: item.programId });

    const extras: NavigationExtras = { queryParams: queryParams };

    if (this._checkModuleEventAvailability(item)) {
      this._router.navigate(['modulo/' + item.moduleId], extras);
    }
  }

  public goToModuleOverViewContent(
    item: {
      isEvent: boolean, alwaysAvailable: boolean, openDate: string, blocked: boolean, moduleId: string, programId: string, trackId: string,
      valuationDate: string
    },
  ): void {

    if (this._checkModuleEventAvailability(item)) {
      this._router.navigate([
        'configuracoes/trilha-de-curso/' + item.trackId + '/modulo/' + item.moduleId
      ]);
    }
  }

  public goToEventOverViewContent(
    item: {
      isEvent: boolean,
      alwaysAvailable: boolean, openDate: string, blocked: boolean, eventId: string, programId: string, valuationDate: string
    },
    eventConfiguration?: TrackEvent): void {

    const trackPreview =
      localStorage.getItem(LocalStorageService.key.trackResponsible) !== null ||
      this._authService.hasRole(ERole.Secretary);

    if (this._checkModuleEventAvailability(item) && (!trackPreview || this._authService.hasSelectedAccess(EAccess.InstructorEvent))) {
      localStorage.setItem('fromTrack', JSON.stringify({ eventConfiguration }));
      this._router.navigate(['configuracoes/gerenciar-eventos/' + item.eventId]);
    }
  }

  public goToDashboardInstructor(item: { isEvent: boolean, moduleId: string, eventId: string }) {
    const url = item.isEvent ? 'evento' : 'modulo';
    const id = item.isEvent ? item.eventId : item.moduleId;
    this._router.navigate([`instrutor/${url}/${id}`]);
  }

}
