<div class="inner-content">
  <div class="header">
    <h2>COMUNICADOS</h2>

    <div class="search">
      <i class="logo icon-lupa search-icon"></i>
      <input type="text" placeholder="Digite o titulo do comunicado" (keyup)="updateSearch($event.target.value)" />
    </div>
  </div>
  <ng-container *ngIf="communications && communications.length > 0">
    <div class="tests">
      <ng-container *ngFor="let communication of communications">
        <app-generic-card [data]="{id:communication.id, title:communication.title}" [hasDelete]="true"
          (delete)="DeleteCommunications($event)" (edit)="openDialogComunication($event)">
          <ng-container first>
            <p class="legend-card">de {{communication?.startDate | date: 'dd/MM/yyyy'}} até
              {{communication?.endDate | date: 'dd/MM/yyyy'}}</p>
          </ng-container>
        </app-generic-card>
      </ng-container>
    </div>
  </ng-container>

  <p *ngIf="communications && communications.length === 0">
    Ainda não há nenhum comunicado criado.
  </p>

  <div class="center-btn">
    <button class="btn-test" (click)="openDialogComunication()">
      NOVO COMUNICADO
    </button>
  </div>

  <app-pagination *ngIf="communicationCount" [setPagination]="{
    'itemsCount': communicationCount,
    'pageSize': pageSize
  }" (goToPage)="goToPage($event)"></app-pagination>
</div>
