import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsHeaderComponent } from './settings-header.component';



@NgModule({
  declarations: [SettingsHeaderComponent],
  imports: [
    CommonModule
  ],
  exports: [SettingsHeaderComponent]
})
export class SettingsHeaderModule { }
