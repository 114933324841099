import { Pipe, PipeTransform } from '@angular/core';
import { Module } from 'src/app/models/module.model';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';

@Pipe({ name: 'hasRequirement' })
export class RequirementPipe implements PipeTransform {
    transform(course: Module | TrackPreview | EventPreview): any {

        if (course instanceof EventPreview) return false;
        return course && course.requirement && course.requirement.length > 0;
    }
}
