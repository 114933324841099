<div class="inner-content" >
  <nav>
    <a href="javascript:history.back()" >
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      Voltar
    </a>
    <h2>AVALIAÇÃO DE REAÇÃO</h2>
  </nav>
  <div class="header" >
    <p class="title">{{eventName}}</p>
    <p class="date">Ocorrido em {{eventDate  | date : 'dd/MM/yyyy'}}</p>
    <p class="description">Para que possamos sempre aperfeiçoar nossos eventos,
      nos conte como foi sua experiência na aula presencial em que participou, avaliando os tópicos abaixo:</p>
  </div>

  <div class="content" *ngIf="_event">
    <ng-container *ngFor="let valuation of eventValuations">
      <p class="title">{{valuation.title}}</p>
      <div class="content-box">
        <div class="radio-options">
          <span class="tier">Ruim</span>
          <label class="container" *ngFor="let value of valuation.values">
            <input type="radio" name="{{valuation.title}}" (click)="setValue(valuation, value)">
            <span class="checkmark"></span>
          </label>
          <span class="tier">Excelente</span>
        </div>
      </div>
      <p class='title-description'>{{getValue(valuation)?.title}}</p>
      <p class='description'>{{getValue(valuation)?.description}}</p>
    </ng-container>

    <p class="title">Em relação às suas expectativas, o evento esteve em geral:</p>
    
    <div class="content-box">
      <div class="radio-options">
        <span class="tier">Abaixo</span>
        <label class="container" >
          <input type="radio" name="sliderValue" (click)="setSliderValue(0)" />
          <span class="checkmark"></span>
        </label>
        <label class="container" >
          <input type="radio" name="sliderValue" (click)="setSliderValue(1)" />
          <span class="checkmark"></span>
        </label>
        <label class="container" >
          <input type="radio" name="sliderValue" (click)="setSliderValue(2)" />
          <span class="checkmark"></span>
        </label>
        <span class="tier">Acima</span>
      </div>
    </div>

    <p class="title">Se você pudesse mudar algo no treinamento, qual seria?
      Conte-nos se tiver sugestões de melhorias para este e/ou próximos módulos.</p>
    <div class="text-box">
      <textarea class="text-area" placeholder="Escreva aqui sua Resposta" (change)="setSugestion($event)"></textarea>
    </div>
    <button class="btn-test" style="float: right; width: 240px;" (click)="sendValuation()">
      Enviar
    </button>
    <br>
    <br>
    <br>
    <br>
  </div>
</div>
