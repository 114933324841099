<!-- <h2>RESPONSÁVEL NAS TRILHAS</h2>
<br>
<br> -->
<h2>MEU DESEMPENHO</h2>
<h4>MINHAS TRILHAS</h4>
<app-cards-slider *ngIf="tracks?.length > 0" [cardsContent]="tracksCards"></app-cards-slider>
<h3 *ngIf="!tracks?.length"> Nenhuma trilha iniciada</h3>
<br>

<h4>MEUS CURSOS</h4>
<app-cards-slider *ngIf="modules?.length > 0"
  [cardsContent]="modulesCards"></app-cards-slider>
<h3 *ngIf="!modules?.length"> Nenhum módulo iniciado</h3>
<br>

<h4>MEUS EVENTOS</h4>
<app-cards-slider *ngIf="events?.length > 0" [cardsContent]="eventsCards"></app-cards-slider>
<h3 *ngIf="!events?.length"> Nenhum evento comparecido</h3>
<br>


<ng-template #tracksCards >
  <ng-container *ngFor="let track of tracks">
    <app-user-progress-card
      [progressItem]="track"
      [isTrack]="true"
    ></app-user-progress-card>
    </ng-container>
</ng-template>

<ng-template #modulesCards >
  <ng-container *ngFor="let module of modules">
    <app-user-progress-card
      [progressItem]="module"
      [isModule]="true"
    ></app-user-progress-card>
    </ng-container>
</ng-template>

<ng-template #eventsCards >
  <ng-container *ngFor="let event of events">
    <app-user-progress-card
      [progressItem]="event"
      [showProgress]="false"
      [isEvent]="true"
    ></app-user-progress-card>
    </ng-container>
</ng-template>

