<ng-container *ngIf="module && subject">
    <div class="header">
        <div class="inner-content">
        <a href="javascript:;" (click)="goBack()">
            <img class="back"
            src="./assets/img/arrow-back-white.png" />
        </a>
    
        <p>{{subject.title}}</p>
        </div>
    </div>
    <ng-container *ngIf="step === 0">
        <app-question-valuation-explanation
        [subject]="subject"
        (next)="next($event)"
        (goBack)="goBack()"
        ></app-question-valuation-explanation>
    </ng-container>
    <ng-container *ngIf="step === 1">
        <app-module-new-question
        [subject]="subject"
        [moduleQuestion]="moduleQuestion"
        (next)="next($event)"
        (goBack)="goBack()"
        ></app-module-new-question>
    </ng-container>
    <ng-container *ngIf="step === 2">
        <app-module-new-valuation
        [subject]="subject"
        [moduleQuestion]="moduleValuationQuestion"
        (next)="next($event)"
        (goBack)="goBack()"
        ></app-module-new-valuation>
    </ng-container>
</ng-container>