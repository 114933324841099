<div class="main">
  <app-cockpit-ranking-card 
  [ranking]="studentTrack?.ranking"
  class="ranking"></app-cockpit-ranking-card>
  <app-effort-progress-students 
    [effortMetric]="effortMetric"
    class="effort-progress">
  </app-effort-progress-students>
  <app-effort-performance-students
    [effortPerformance]="effortPerformance"
  ></app-effort-performance-students>
  <app-cockpit-warning class="warning"></app-cockpit-warning>
  <app-cockpit-modules-progress
    [modules]="studentTrack.moduleConfigurations"
    (goToModule)="goToModule($event)"
  ></app-cockpit-modules-progress>
</div>
