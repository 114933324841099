<div class="filters">
  <mat-accordion>

    <mat-expansion-panel hideToggle="true" disabled="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat-title" style="font-size: 14px;">
            FILTROS POR CATEGORIA
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            BUSCAR POR NOME
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-list-search [noPadding]="true" (triggerSearch)="triggerSearch($event)"></app-list-search>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            Data de Criação
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <input matInput [(ngModel)]="createdSince" [matDatepickerFilter]="maxDateFilter" [matDatepicker]="sincePicker"
          (ngModelChange)="setCreatedSince.emit(createdSince)" placeholder="Desde de..." />
        <mat-datepicker-toggle matSuffix [for]="sincePicker"></mat-datepicker-toggle>
        <mat-datepicker #sincePicker></mat-datepicker>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="cat">
            Programas Associados
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="options">
        <app-programs-select (selectionChange)="handleSelectionItemChange($event)" [multiple]="true">
        </app-programs-select>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
