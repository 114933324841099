import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImetricsStudents } from '../../models/trackStudentsSubordinates';

@Component({
  selector: 'app-effort-metric',
  templateUrl: './effort-metric.component.html',
  styleUrls: ['./effort-metric.component.scss']
})
export class EffortMetricComponent {

  @Input() metrics: ImetricsStudents;
  constructor() { }
  public replacement = environment.replecement;


}
