<div class="inner-content" >
  <nav>
    <a href="javascript:history.back()" >
      <img src="./assets/img/arrow-back.png" />
      Voltar
    </a>
    <h2>RECOMENDAR PRODUTOS</h2>
  </nav>

  <app-suggestion-area-toggle
    [title]="'Produtos Associados'"
    [isClosed]="!displayedContent.top"
    (toggle)="displayedContent.top = !displayedContent.top"
  ></app-suggestion-area-toggle>

  <div class="top" *ngIf="displayedContent.top" >
    <ng-container *ngIf="response" >
      <div>
        <h3>Módulos</h3>
        <ul>
          <li *ngFor="let module of response.modulesInfo" >
            {{ module.name }}
          </li>
          <li *ngIf="!response.modulesInfo || response.modulesInfo.length === 0" >
            Este usuário não possui módulos associados.
          </li>
        </ul>
  
        <h3>Eventos</h3>
        <ul>
          <li *ngFor="let event of response.eventsInfo" >
            {{ event.name }}
          </li>
          <li *ngIf="!response.eventsInfo || response.eventsInfo.length === 0" >
            Este usuário não possui eventos associados.
          </li>
        </ul>
      </div>
      <div>
        <h3>Trilhas</h3>
        <ul>
          <li *ngFor="let track of response.tracksInfo" >
            {{ track.name }}
          </li>
          <li *ngIf="!response.tracksInfo || response.tracksInfo.length === 0" >
            Este usuário não possui eventos associados.
          </li>
        </ul>
      </div>
    </ng-container>
  </div>

  <app-suggestion-area-toggle
    [title]="'Respostas ' + (response?.finalGrade ? ' - Nota Final: ' + response.finalGrade.toFixed(1) : '')"
    [isClosed]="!displayedContent.middle"
    (toggle)="displayedContent.middle = !displayedContent.middle"
  ></app-suggestion-area-toggle>

  <div class="middle" *ngIf="displayedContent.middle" >
    <div class="actions" *ngIf="response" >
      Aluno: {{ response.userName }} &nbsp;|&nbsp; Matrícula: {{ response.userRegisterId || '--' }}
      <div>
        <button class="btn-test"
          (click)="exportAnswers()" >
          Exportar Respostas
        </button>
        <button class="btn-test primary"
          *ngIf="!response.finalGrade"
          (click)="displayedContent.gradesInput = true" >
          Dar Notas
        </button>
      </div>
    </div>
    <div class="question" *ngFor="let item of response?.answers" >
      <p>{{ item.question }} ({{ item.grade != null ? item.grade + '/' + item.percentage : 'valor: ' + item.percentage }})</p>
      <p>{{ item.answer }}</p>
      <mat-form-field *ngIf="displayedContent.gradesInput && !item.gradeIsSet" >
        <input matInput type="number" min="1"
          placeholder="Nota da Questão"
          [(ngModel)]="item.grade" required
        />
      </mat-form-field>
    </div>
    <div class="grade-actions" *ngIf="displayedContent.gradesInput" >
      <button class="btn-test"
        (click)="displayedContent.gradesInput = false" >
        Cancelar
      </button>
      <button class="btn-test primary"
        *ngIf="!response.finalGrade"
        (click)="gradeProfileTestAnswers()" >
        Salvar
      </button>
    </div>
  </div>

  <app-suggestion-area-toggle
    [title]="'Recomendações'"
    [isClosed]="!displayedContent.bottom"
    (toggle)="displayedContent.bottom = !displayedContent.bottom"
  ></app-suggestion-area-toggle>

  <div class="bottom" [hidden]="!displayedContent.bottom" >
    <h3>Módulos ({{ modulesCount }})</h3>
    <app-list-search
      placeholder="Pesquisar por nome ou conceito"
      [noPadding]="true"
      (triggerSearch)="triggerModuleSearch($event)"
    ></app-list-search>
    <div class="chips" >
      <p *ngFor="let module of selectedModules" >
        {{ module.title }}
        <span (click)="removeSelectedModule(module.id)" >X</span>
      </p>
    </div>
    <div class="tracks" >
      <app-suggestion-module-select
        *ngFor="let module of modules"
        [module]="module"
        (updateCollection)="updateModules()"
      ></app-suggestion-module-select>
    </div>
    <br>
    <h3>Eventos ({{ eventsCount }})</h3>
    <app-list-search
      placeholder="Pesquisar por nome ou conceito"
      [noPadding]="true"
      (triggerSearch)="triggerEventSearch($event)"
    ></app-list-search>
    <div class="chips" >
      <p *ngFor="let event of selectedEvents" >
        {{ event.title }}
        <span (click)="removeSelectedEvent(event.id)" >X</span>
      </p>
    </div>
    <div class="tracks" >
      <app-suggestion-event-select
        *ngFor="let event of events"
        [setEvent]="event"
        (updateCollection)="updateEvents()"
      ></app-suggestion-event-select>
    </div>
    <br>
    <h3>Trilhas ({{ tracksCount }})</h3>
    <app-list-search
      placeholder="Pesquisar por nome ou conceito"
      [noPadding]="true"
      (triggerSearch)="triggerTrackSearch($event)"
    ></app-list-search>
    <div class="chips" >
      <p *ngFor="let track of selectedTracks" >
        {{ track.title }}
        <span (click)="removeSelectedTrack(track.id)" >X</span>
      </p>
    </div>
    <div class="tracks" >
      <app-suggestion-track-select
        *ngFor="let track of tracks"
        [track]="track"
        (updateCollection)="updateTracks()"
      ></app-suggestion-track-select>
    </div>

    <div class="action" >
      <button class="btn-test" (click)="filterSugestions()" >
        Recomendar Produtos
      </button>
    </div>
  </div>
</div>
