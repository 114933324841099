<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      voltar
    </a>
  </nav>

  <div class="header" *ngIf="fromTrack">
    <div class="level">
      <p class="hdesc">
        Trilha de Curso <br>
        <span>{{track.title}}</span>
      </p>
      <p class="hdesc-info">
        <span>
          Turma <br>
          {{track.studentsCount}} alunos
        </span>
        <span>
          Duração Total <br>
          {{ getFormattedHour() }} h
        </span>
      </p>
    </div>
  </div>

  <div class="upper">
    <div class="details">
      <h3>
        {{ event?.title }}<br>
        <mat-select [(ngModel)]="scheduleId" (selectionChange)="changeScheduleId()">
          <mat-option *ngFor="let schedule of schedules" [value]="schedule.id">
            {{ schedule.eventDate | date : 'dd/MM/yyyy' }}
          </mat-option>
        </mat-select>
      </h3>
      <div *ngIf="!noActions">
        <ng-container *ngIf="canImportGrades()">
          <button class="btn-test" (click)="generateStudentList()"
            *ngIf="schedule?.eventScheduleType !== scheduleEnum.SynchronousExperience">
            Gerar Pauta
          </button>
          <a class="link-button" *ngIf ="schedule?.eventScheduleType !== scheduleEnum.SynchronousExperience" href="./../../../assets/download/PLANILHA_DE_IMPORTAÇÃO_DE_NOTAS_EM_EVENTOS.xlsx"
            download="PLANILHA_DE_IMPORTAÇÃO_DE_NOTAS_EM_EVENTOS.xlsx">
            BAIXAR MODELO
          </a>
          <a class="link-button" *ngIf ="schedule?.eventScheduleType === scheduleEnum.SynchronousExperience" href="./../../../assets/download/PLANILHA_DE_IMPORTAÇÃO_DE_NOTAS_EM_EVENTOS_EXPASSINC.xlsx"
            download="PLANILHA_DE_IMPORTAÇÃO_DE_NOTAS_EM_EVENTOS.xlsx">
            BAIXAR MODELO
          </a>
          <button class="btn-test" (click)="file.click()">
            Importar Notas
          </button>
          <button class="btn-test" (click)="openDialogImportPresence()">
            Importar Presença
          </button>
          <input class="input-file" type="file" accept=".csv,.xlsx" hidden onclick="this.value = null"
            (change)="setDocumentFile($event.target.files)" #file />
        </ng-container>
        <button class="btn-test" (click)="sendReactionValuation()" [disabled]="disableSendEmails()"
          *ngIf="schedule?.eventScheduleType !== scheduleEnum.SynchronousExperience">
          Enviar Avaliação de Reação
        </button>
        <button class="btn-test" [disabled]="canFinishClass()" (click)="finishClass()">
          Salvar avaliação e encerrar aula
        </button>
      </div>
    </div>
    <div class="pending">
      <p>PRESENÇA PENDENTE ({{pendingPresence}})</p>
      <button class="btn-test" (click)="exportAsExcel()"
        *ngIf="schedule?.eventScheduleType !== scheduleEnum.SynchronousExperience">
        Exportar para excel
      </button>
    </div>
  </div>

  <div class="responsive-table">
    <table #Table mat-table multiTemplateDataRows [dataSource]="users" (matSortChange)="sortData($event)"
      class="mat-elevation-z8">

      <ng-container matColumnDef="logo">
        <th class="table-img" width="5%" mat-header-cell *matHeaderCellDef> </th>
        <td class="table-img" width="5%" mat-cell *matCellDef="let row">
          <img class="logo"
            [src]="row.user?.imageUrl ? row.user.imageUrl : './assets/img/user-image-placeholder.png'" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th width="25%" mat-header-cell *matHeaderCellDef> Nome do Usuário </th>
        <td width="25%" mat-cell *matCellDef="let row">
          <ng-container *ngIf="noActions">
            <a href="javascript:;">
              {{ row.user.name }}
            </a>
          </ng-container>
          <ng-container *ngIf="!noActions">
            <a [routerLink]="'/configuracoes/detalhes-usuario/' + row.user.id">
              {{ row.user.name }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="requirements">
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Pré-requisitos </th>
        <td class="table-img" width="10%" mat-cell *matCellDef="let row">
          <img [src]="getCheckImgSrc(row.eventRequirement)" />
        </td>
      </ng-container>

      <ng-container matColumnDef="questions">
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Perguntas de Preparação </th>
        <td class="table-img" width="10%" mat-cell *matCellDef="let row">
          <img *ngIf="row.prepQuizAnswers && row.prepQuiz && row.prepQuiz.questions"
            [src]="getCheckImgSrc(row.prepQuizAnswers.length === row.prepQuiz.questions.length)" />
        </td>
      </ng-container>


      <ng-container matColumnDef="rated">
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Aluno avaliou a aula? </th>
        <td class="table-img" width="10%" mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.filledEventReaction">
            <img [src]="'./assets/img/approved.png'" />
          </ng-container>
          <ng-container *ngIf="!row.filledEventReaction">
            <ng-container *ngIf="noActions">
              <img [src]="'./assets/img/denied.png'" />
            </ng-container>
            <ng-container *ngIf="!noActions">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;"
                (click)="sendReactionValuation(row.user.id)" [src]="'./assets/img/email-reaction.png'" />
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="register"> 
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Inscrição </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
            <p class="finishedGrade" *ngIf="row.userPresence === true">
              {{(row.registerGrade) | number : '1.1-1'}}
          </td>
              
      </ng-container>


      <ng-container matColumnDef="diagnostic"> 
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Diagnostico </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
            <p class="finishedGrade" *ngIf="row.userPresence === true">
              {{(row.diagnosticGrade) | number : '1.1-1'}}
          </td>
              
      </ng-container>

      <ng-container matColumnDef="valuation"> 
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Avaliação </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
           <p class="finishedGrade" *ngIf="row.userPresence === true">
              {{(row.valuationGrade) | number : '1.1-1'}}
           </p>
          </td>
              
      </ng-container>

      <ng-container matColumnDef="nps"> 
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> NPS </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
            <p class="finishedGrade" *ngIf="row.userPresence === true">
              {{(row.npsGrade) | number : '1.1-1'}}
          </td>
              
      </ng-container>
      <ng-container matColumnDef="edit">
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Editar notas </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
            <ng-container *ngIf="(!isFinished && row.userPresence && tests.length-testsNps.length>0)">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeGrade(row)"
                [src]="'./assets/img/edit-enabled.png'" />
            </ng-container>
            <ng-container *ngIf="isFinished || !row.userPresence || tests.length-testsNps.length==0">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;"
                [src]="'./assets/img/edit-disabled.png'" />
            </ng-container>
      </ng-container>

      <ng-container *ngIf="!isFinished && !noActions">
        <ng-container matColumnDef="presence">
          <th class="table-img" width="15%" mat-header-cell *matHeaderCellDef> Presença </th>
          <td class="table-img" width="15%" mat-cell *matCellDef="let row">
            <img class="grade border-right" [src]="getPresenceImgSrc(row, false)"
              (click)="manageUserPresence(row, false)" />
            <img class="grade" [src]="getPresenceImgSrc(row, true)" (click)="manageUserPresence(row, true)" />
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isFinished || noActions">
        <ng-container matColumnDef="presence">
          <th class="table-img" width="15%" mat-header-cell *matHeaderCellDef> Presença </th>
          <td class="table-img" width="15%" mat-cell *matCellDef="let row">
            <img class="grade" [src]="getCheckImgSrc(row.userPresence)" />
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="event-date">
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Mudar Dia/Horário </th>
        <td class="table-img" width="10%" mat-cell *matCellDef="let row">
          <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeEventDateDialog(row.user.id)"
            [src]="'./assets/img/edit-icon.png'" />
        </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Participação Transcrita </th>
        <td class="table-img" width="10%" mat-cell *matCellDef="let row">
          <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="openParticipation(row)"
            [src]="'./assets/img/search.png'" />
        </td>
      </ng-container>

      <ng-container *ngIf="!isFinished && !noActions">
        <ng-container matColumnDef="grade">
          <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Aproveitamento </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.grading">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeGrade(row)"
                [src]="'./assets/img/edit-enabled.png'" />
            </ng-container>
            <ng-container *ngIf="!row.grading && row.userPresence === false">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;"
                [src]="'./assets/img/edit-disabled.png'" />
            </ng-container>
            <ng-container *ngIf="!customGrades">
              <ng-container
                *ngIf="!row.grading && row.userPresence === true && row.organicGrade === null && row.inorganicGrade === null">
                <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeGrade(row)"
                  [src]="'./assets/img/edit-icon.png'" />
              </ng-container>
              <ng-container
                *ngIf="!row.grading && row.userPresence === true && row.organicGrade !== null && row.inorganicGrade !== null">
                <p class="finishedGrade green-circle" (click)="changeGrade(row)">
                  {{ (row.organicGrade + row.inorganicGrade) / 2 | number : '1.1-1' }}
                </p>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="customGrades">
              <ng-container *ngIf="!row.grading && row.userPresence === true && hasEmptyCustomGrade(row)">
                <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeGrade(row)"
                  [src]="'./assets/img/edit-icon.png'" />
              </ng-container>
              <ng-container *ngIf="!row.grading && row.userPresence === true && !hasEmptyCustomGrade(row)">
                <p class="finishedGrade green-circle" (click)="changeGrade(row)">
                  {{ sumGrades(row) | number : '1.1-1'}}
                </p>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isFinished || noActions">
        <ng-container *ngIf="!customGrades">
          <ng-container matColumnDef="grade">
            <th width="10%" mat-header-cell *matHeaderCellDef> Aproveitamento </th>
            <td width="10%" mat-cell *matCellDef="let row">
              <p class="finishedGrade">{{(row.organicGrade + row.inorganicGrade) / 2 | number : '1.1-1'}}</p>
            </td>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="customGrades">
          <ng-container matColumnDef="grade">
            <th width="20%" mat-header-cell *matHeaderCellDef> Aproveitamento </th>
            <td width="20%" mat-cell *matCellDef="let row">
              <p class="finishedGrade">{{ sumGrades(row) | number : '1.1-1'}}</p>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="expandedDetail" *ngIf="schedule?.eventScheduleType === scheduleEnum.SynchronousExperience">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="rating">
            <div class="grade" *ngIf="testsRegister.length>0">
              <p>INSCRIÇÃO</p>
              <div>
                <input matInput type="number" [(ngModel)]="row.registerGrade" />
              </div>
            </div>
            <div class="grade" *ngIf="testsValuation.length>0">
              <p>AVALIAÇÃO</p>
              <div>
                <input matInput type="number" [(ngModel)]="row.valuationGrade" />
              </div>
            </div>
            <div class="grade" *ngIf="testsDiagnostic.length>0">
              <p>DIAGNÓSTICO</p>
              <div>
                <input matInput type="number" [(ngModel)]="row.diagnosticGrade" />
              </div>
            </div>
            <div class="grade" *ngIf="testsDiagnostic.length>0">
              <p>NPS</p>
              <div>
                <input matInput type="number" [(ngModel)]="row.npsGrade" />
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail" *ngIf="!customGrades && schedule?.eventScheduleType !== scheduleEnum.SynchronousExperience">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="rating">
            <div class="grade">
              <p>CONTRIBUIÇÃO ORGÂNICA</p>
              <div>
                <input matInput type="number" [(ngModel)]="row.organicGrade" />
              </div>
            </div>
            <div class="grade">
              <p>CONTRIBUIÇÃO INORGÂNICA</p>
              <div>
                <input matInput type="number" [(ngModel)]="row.inorganicGrade" />
              </div>
            </div>
            <div class="final-grade">
              <p>NOTA FINAL</p>
              <p *ngIf="row.organicGrade !== null && row.inorganicGrade !== null">
                {{ (row.organicGrade + row.inorganicGrade) / 2 | number : '1.1-1'}}
              </p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail" *ngIf="customGrades && schedule?.eventScheduleType !== scheduleEnum.SynchronousExperience">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="rating">
            <div class="grade" *ngFor="let customEventGradeValue of row.customEventGradeValues">
              <p>{{ customEventGradeValue.key }}</p>
              <div>
                <input matInput type="number" [(ngModel)]="customEventGradeValue.grade" />
              </div>
            </div>
            <div class="final-grade">
              <p>NOTA FINAL</p>
              <p *ngIf="!hasEmptyCustomGrade(row)">
                {{ sumGrades(row) | number : '1.1-1'}}
              </p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="!isFinished && !noActions">
        <ng-container matColumnDef="forumGrade">
          <th class="table-img" width="10%" mat-header-cell *matHeaderCellDef> Nota Fórum </th>
          <td class="table-img" width="10%" mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.forumGrading">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeForumGrade(row)"
                [src]="'./assets/img/edit-enabled.png'" />
            </ng-container>
            <ng-container *ngIf="!row.forumGrading && row.userPresence === false">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;"
                [src]="'./assets/img/edit-disabled.png'" />
            </ng-container>
            <ng-container *ngIf="!row.forumGrading && row.userPresence === true && row.forumGrade === null">
              <img style="cursor: pointer; max-width: 32px; max-height: 32px;" (click)="changeForumGrade(row)"
                [src]="'./assets/img/edit-icon.png'" />
            </ng-container>
            <ng-container *ngIf="!row.forumGrading && row.userPresence === true && row.forumGrade !== null">
              <p class="finishedGrade green-circle" (click)="changeForumGrade(row)">
                {{row.forumGrade}}
              </p>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isFinished || noActions">
        <ng-container matColumnDef="forumGrade">
          <th width="10%" mat-header-cell *matHeaderCellDef> Nota Fórum </th>
          <td width="10%" mat-cell *matCellDef="let row">
            <p class="finishedGrade">{{row.forumGrade}}</p>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="expandedForumDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="rating">
            <div class="grade">
              <p>
                FÓRUM
                <span style="cursor: pointer; margin-left: 20px; position: absolute;"
                  (click)="viewUserForumParticipation(row.userId)">
                  <img style="max-height: 13px;" [src]="'./assets/img/search-white.png'" />
                  Ver participação
                </span>
              </p>
              <div>
                <input matInput type="number" [(ngModel)]="row.forumGrade" />
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [hidden]="!row.grading" class="example-detail-row">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedForumDetail']" [hidden]="!row.forumGrading"
        class="example-detail-row"></tr>
    </table>
  </div>

</div>
