import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUtilModule } from '@tg4/ng-util';
import { RouterModule } from '@angular/router';
import { NewModuleModule } from 'src/app/settings/modules/new-module/new-module.module';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { SettingsTracksService } from '../_services/tracks.service';
import { SettingsTracksComponent } from './tracks.component';
import { MaterialComponentsModule } from '../../shared/material.module';
import { SettingsTrackCardComponent } from './track-card/track-card.component';
import { NewTrackModule } from './new-track/new-track.module';
import { DeleteTrackDialogComponent } from './delete-track/delete-track.dialog';
import { SettingsTrackOverviewComponent } from './track-overview/track-overview.component';
import { TrackPathModule } from 'src/app/pages/track/track-overview/track-path/track-path.module';
import { ProgressBarModule } from 'src/app/shared/components/layout/progress-bar/progress-bar.module';
import { TrackStudentOverviewComponent } from './track-overview/student-overview/student-overview.component';
import { TrackOverviewStudentsComponent } from './track-overview/components/track-students/track-students.component';
import { TrackOverviewWrongConceptsComponent } from './track-overview/components/wrong-concepts/wrong-concepts.component';
import { TrackOverviewPerformanceRadarComponent } from './track-overview/components/performance-radar/performance-radar.component';
import { TrackOverviewEventsParticipationComponent } from './track-overview/components/events-participation/events-participation.component';
import { TrackOverviewWrongConceptsDialogComponent } from './track-overview/components/wrong-concepts-dialog/wrong-concepts.dialog';
import { TrackModuleOverviewComponent } from './track-overview/module-overview/module-overview.component';
import { TrackOverviewContentViewsComponent } from './track-overview/components/content-views/content-views.component';
import { TrackOverviewBadgesComponent } from './track-overview/components/badges/badges.component';
import { TrackOverviewStudentsProgressComponent } from './track-overview/components/students-progress/students-progress.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TrackOverviewTopBottomPerformesComponent } from './track-overview/components/top-bottom-performes/top-bottom-performes.component';
// tslint:disable-next-line:max-line-length
import { TrackOverviewTopBottomPerformesDialogComponent } from './track-overview/components/top-bottom-performes-dialog/top-bottom-performes.dialog';
import { TrackOverviewModule } from 'src/app/pages/track/track-overview/track-overview.module';
import { TrackOverviewBadgesDialogComponent } from './track-overview/components/badges-dialog/badges.dialog';
import { TrackOverviewTrackParticipationComponent } from './track-overview/components/track-participation/track-participation.component';
import { HorizontalTimelineComponent } from 'src/app/shared/components/horizontal-timeline/horizontal-timeline.component';
import { TrackModulesProgressGanttComponent } from './track-overview/components/track-modules-progress/track-modules-progress.component';
import { TrackStudentReportCardComponent } from './track-overview/student-report-card/student-report-card.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StudentGradeModule } from 'src/app/shared/components/student-grade/student-grade.module';
import { MatCardModule } from '@angular/material/card';
import { TrackDescriptionComponent } from 'src/app/settings/tracks/track-overview/components/track-description/track-description.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CollapsedListModule } from 'src/app/shared/components/collapsed-list/collapsed-list.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { StudentAvatarModule } from 'src/app/shared/components/student-avatar/student-avatar.module';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { TrackRankingDialogComponent } from './track-overview/components/track-ranking-dialog/track-ranking-dialog.component';
import { ListSearchModule } from 'src/app/shared/components/list-search/list-search.module';
import { MdePopoverModule } from '@material-extended/mde';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { SettingsTrackAreasComponent } from './track-areas/track-areas.component';
import { SettingsTrackSubAreasComponent } from './track-subareas/track-subareas.component';

@NgModule({
    declarations: [
        SettingsTracksComponent,
        SettingsTrackCardComponent,
        DeleteTrackDialogComponent,
        SettingsTrackOverviewComponent,
        TrackOverviewStudentsComponent,
        TrackStudentOverviewComponent,
        TrackOverviewPerformanceRadarComponent,
        TrackOverviewWrongConceptsComponent,
        TrackOverviewEventsParticipationComponent,
        TrackOverviewWrongConceptsDialogComponent,
        TrackOverviewTopBottomPerformesDialogComponent,
        TrackOverviewBadgesDialogComponent,
        TrackOverviewContentViewsComponent,
        TrackModuleOverviewComponent,
        TrackOverviewBadgesComponent,
        TrackOverviewStudentsProgressComponent,
        TrackOverviewTopBottomPerformesComponent,
        TrackOverviewTrackParticipationComponent,
        HorizontalTimelineComponent,
        TrackModulesProgressGanttComponent,
        TrackStudentReportCardComponent,
        TrackDescriptionComponent,
        TrackRankingDialogComponent,
        SettingsTrackAreasComponent,
        SettingsTrackSubAreasComponent
    ],
    imports: [
        BrowserModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgUtilModule,
        NewModuleModule,
        RouterModule,
        PaginationModule,
        NewTrackModule,
        TrackPathModule,
        ProgressBarModule,
        PipesModule,
        TrackOverviewModule,
        ScrollingModule,
        StudentGradeModule,
        MatCardModule,
        MatIconModule,
        StudentAvatarModule,
        NavigationBackModule,
        MatSelectModule,
        CollapsedListModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        ListSearchModule,
        MdePopoverModule,
        DirectivesModule,
    ],
    providers: [
        SettingsTracksService
    ],
    exports: [
        TrackOverviewBadgesComponent,
        TrackOverviewPerformanceRadarComponent,
        TrackOverviewContentViewsComponent,
        TrackOverviewWrongConceptsComponent
    ]
})
export class SettingsTracksModule { }
