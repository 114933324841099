<div class="students">
  <div class="search">
    <i class="search-icon icon-lupa"></i>
    <input type="text" placeholder="Procure pelo nome" (keyup)="search.emit($event.target.value)" />
  </div>
  <div class="no-students" *ngIf="users && users.length === 0">
    <app-empty-data></app-empty-data>
  </div>
  <ul *ngIf="users && users.length > 0">
    <li *ngFor="let user of users">
      <div class="student-info-wrapper">
        <img class="photo" [src]="user.imageUrl || './assets/img/user-image-placeholder.png'" />
        <p class="item-title"> {{user.title}} </p>
        <button class="btn-test primary" (click)="removeUser.emit(user.id)">Remover</button>
      </div>
    </li>
  </ul>

  <app-pagination *ngIf="users.length > 0" [hidden]="itemsCount === 0" [setPagination]="{
            'itemsCount': itensCount,
            'pageSize': pageSize
          }" (goToPage)="goToPage.emit($event)"></app-pagination>
</div>
