export enum CategoryFilterEnum {
  Segments = 1,
  BusinessGroups = 2,
  BusinessUnits = 3,
  Users = 4,
  Tracks = 5,
  Modules = 6,
  Subjects = 7
}


