<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <h2>{{name}} - {{itensCount}} ASSUNTO(S) BLOQUEADO(S)</h2>

  <div class="header">
    <div class="search">
      <i class="logo icon-lupa search-icon"></i>
      <input type="text" placeholder="Digite o nome do módulo" (keyup)="updateSearch($event.target.value)" />
    </div>
  </div>

  <table mat-table matSort [dataSource]="usersSubjectBlocked" class="mat-elevation-z8 table">

    <ng-container matColumnDef="module">
      <th width="30%" mat-header-cell *matHeaderCellDef> Módulos </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{row.moduleName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th width="30%" mat-header-cell *matHeaderCellDef> Assuntos </th>
      <td width="30%" mat-cell *matCellDef="let row">
        {{ row.subjectName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th width="20%" mat-header-cell *matHeaderCellDef> Ações </th>
      <td width="20%" mat-cell *matCellDef="let row">
        <button class="btn-test" (click)="unlockUserSubject(row.moduleId,userId,row.subjectId)"> Desbloquear </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <app-pagination *ngIf="itensCount" [setPagination]="{
          'itemsCount': itensCount,
          'pageSize': pageSize
        }" (goToPage)="goToPage($event)"></app-pagination>
</div>
