<div class="step" >
  <h2>Ecommerce</h2>
  <form [formGroup]="formGroup" >
    <div class="product"
      *ngFor="let childFormGroup of formGroup.get('ecommerceProducts')['controls']; let i = index"
      formArrayName="ecommerceProducts" >
      <ng-container [formGroupName]="i" >

        <img class="trash"
          src="./assets/img/trash.png"
          (click)="removeEcommerceProduct(i)"
        />
        <div class="two-columns" >
          <div class="validation-field" >
            <mat-form-field>
              <input matInput type="number"
                placeholder="Id do Ecommerce"
                formControlName="ecommerceId" />
            </mat-form-field>
          </div>
          <div class="validation-field" >
            <mat-form-field>
              <input matInput type="number"
                placeholder="Número de Usuários a Cadastrar por Compra"
                formControlName="usersAmount"  />
            </mat-form-field>
          </div>
        </div>
        <div class="two-columns" >
          <div class="validation-field" >
            <mat-form-field>
              <mat-select placeholder="Free Trial?"
              formControlName="disableFreeTrial" (selectionChange)="cleanLevel(i)" required >
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
            </mat-form-field>
          </div>
          <div class="validation-field" >
            <mat-form-field>
              <input matInput placeholder="Preço" formControlName="price"
              currencyMask [options]="{ align : 'left', prefix:'', thousands: '.', decimal: ',' }"
              required>
              <span matPrefix>R$&nbsp;</span>
            </mat-form-field>
          </div>
        </div>
        <div class="two-columns" >
          <div class="validation-field" >
            <mat-form-field>
              <mat-select placeholder="Exibir o E-commerce?"
              formControlName="disableEcommerce" required >
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
            </mat-form-field>
          </div>
          <div class="validation-field" >
            <mat-form-field>
              <input matInput type="text"
                placeholder="Link para check out"
                formControlName="linkEcommerce" >
            </mat-form-field>
          </div>
        </div>
        <mat-form-field style="width: 100%">
          <input matInput placeholder="Link para detalhes da trilha"
          formControlName="linkProduct" >
        </mat-form-field>
        <div class="two-columns" >
          <div class="validation-field" >
            <mat-form-field>
              <mat-select placeholder="Assunto"
                formControlName="subject">
                <mat-option [value]="'finance'">Finanças</mat-option>
                <mat-option [value]="'business'">Negócio</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="validation-field" >
            <mat-form-field>
              <input matInput
              placeholder="Carga horária (hh:mm:ss)"
              formControlName="hours"
              mask="00:00:00" />
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>
    <button class="btn-test add-date" (click)="addEcommerceId()" >
      + Adicionar Id Ecommerce
    </button>
  </form>
</div>
