<div class="header">
  <app-navigation-back></app-navigation-back>
  <h2>{{group.title}}</h2>
</div>
<div class="inner-content">
  <mat-form-field>
    <input matInput type="text" placeholder="Titulo" [(ngModel)]="group.title" />
  </mat-form-field>
  <div>
    <app-generate-valuation-test-check-options #checkedComponent [dataSource]="{data: valuations}"
      (selectedData)="handleSelectedData($event)" firstTitle="Avaliações Encontrados"
      secondTitle="Avaliações Adicionados" (search)="search($event)">
    </app-generate-valuation-test-check-options>
  </div>
  <div class="center-btn">
    <button class="btn-test" appDebounceClick (debounceClick)="save()">
      Salvar
    </button>
  </div>
</div>
