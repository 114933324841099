import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isEmpty, isNil } from 'lodash';
import { CockpitService } from 'src/app/pages/_services/cockpit.service';
import { ContentModulesService } from 'src/app/pages/_services/modules.service';
import {
  IeffortMetric,
  IeffortPerformance,
  IstudentProgress,
} from '../../models/studentMetrics';
import { ItracksStudentsSubordinates } from '../../models/trackStudentsSubordinates';

@Component({
  selector: 'app-cockpit-student-module',
  templateUrl: './cockpit-student-module.component.html',
  styleUrls: ['./cockpit-student-module.component.scss'],
})
export class CockpitStudentModuleComponent implements OnInit {
  public moduleId: string;
  public studentId: string;
  public studentTrack: IstudentProgress = {} as IstudentProgress;
  public effortPerformance: IeffortPerformance = {} as IeffortPerformance;
  public effortMetric: IeffortMetric = {} as IeffortMetric;
  public ranking: number;
  constructor(
    public _activatedRoute: ActivatedRoute,
    public _moduleService: ContentModulesService,
    public _cockpitService: CockpitService
  ) {}

  ngOnInit() {
    this.moduleId = this._activatedRoute.snapshot.params['moduleId'];
    this.studentId = this._activatedRoute.snapshot.params['studentId'];
    this._cockpitService.getSelectedTrack().subscribe(selectedTrack => {
      if (!isEmpty(selectedTrack)) {
        const currentStudent = selectedTrack.students.find(student => student.id === this.studentId);
        this._cockpitService.setSelectedStudents(currentStudent);
        this.loadStudent(this.moduleId, this.studentId, selectedTrack);
      }
    });
  }

  public loadStudent(moduleId, studentId, selectedTrack: ItracksStudentsSubordinates) {
    this._moduleService
      .getCockpitModuleMetricsStudentCurrent(moduleId, studentId)
      .subscribe((response) => {
        this.mountStudentMetrics(response.data, selectedTrack);
      });
  }

  public async mountStudentMetrics(studentMetrics: any, selectedTrack: ItracksStudentsSubordinates ) {
    let classMetrics = null;
    let allStudents = null;

    await this._moduleService
    .getCockpitModuleMetrics(
      this.moduleId,
      [this.studentId],
      selectedTrack.id
    )
    .toPromise()
    .then((response) => {
      classMetrics = this._cockpitService.setStudentsModuleMetrics(
        response.data
      );
      this._cockpitService.setTitle(response.data.title);
      allStudents = response.data.students;
    });

    const student = allStudents.find((s) => s.id === this.studentId);

    this.ranking = !isNil(student) ? student.ranking : 0;

    const top10 = {
      averageDiagnostic: classMetrics.averageDiagnosticTestTop10 || 0,
      averageEvent: classMetrics.averageEventsTop10 || 0,
      averageValuation: classMetrics.averageTestTop10 || 0,
      averageBdq: classMetrics.averageBdqGradeTop10 || 0,
    };
    const students = {
      averageDiagnostic: classMetrics.averageDiagnosticTest || 0,
      averageEvent: classMetrics.averageEventsTop10 || 0,
      averageValuation: classMetrics.averageTest || 0,
      averageBdq: classMetrics.averageBdqGrade || 0,
    };

    this.effortPerformance = {
      top10: top10,
      class: students,
      student: studentMetrics,
    };

    this.effortMetric = {
      studentProgressUntilDay: !isNil(student) ? student.progressUntilDay * 10 || 0 : 0,
      studentInteractionUntilDay: !isNil(student) ? student.interactionUntilDay || 0 : 0,
      studentInteractionUntilDayInHours: !isNil(student) ? student.interactionUntilDayInHours || 0 : 0,
      studentParticipationUntilDay: studentMetrics.participationUntilDay,
      classProgressUntilDay: (allStudents.reduce((total, currentValue) => total +
        currentValue.progressUntilDay, 0) / (allStudents.length + 1)) * 10 || 0,
      classInteractionUntilDay: classMetrics.effort.real || 0,
      classInteractionUntilDayInHours: classMetrics.effort.realInHours || 0,
      classParticipationUntilDay: classMetrics.participation.real || 0,
      expectedProgressUntilDay: classMetrics.engagement.expected || 0,
      expectedInteractionUntilDay: classMetrics.effort.expected || 0,
      expectedInteractionUntilDayInHours: classMetrics.effort.expectedInHours || 0,
    };
  }
}
