<div class="content">
    <div>
        <h1  class="title">Módulos</h1>
    </div>
    <hr/>
    <ng-container *ngFor="let module of modules">
        <app-cockpit-menu-card
            [title]="module.title"
            [progress]="module.percentage"
            subtitle="Completo"
            [showBoxShadow]="false"
            (click)="goToModule.emit(module)"
        >
        </app-cockpit-menu-card>
        <hr/>
    </ng-container>
</div>
