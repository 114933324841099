import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({ providedIn: 'root' })
export class LayoutService {
  constructor(
    private _iconRegistry: MatIconRegistry, private _sanitizer: DomSanitizer
  ) {

  }

  public setSvgIcons() {
    const getTrustResource = (iconName: string) => this._sanitizer.bypassSecurityTrustResourceUrl(`./../../../assets/img/${iconName}.svg`);
    const addSvgIcon = (iconName: string) => this._iconRegistry.addSvgIcon(iconName, getTrustResource(iconName));

    addSvgIcon('empty-badge');
    addSvgIcon('glasses-icon');
    addSvgIcon('brain-dark-icon-shadow');
    addSvgIcon('pencil-icon');
    addSvgIcon('brain-icon');
  }
}
