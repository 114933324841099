import { Component, OnInit } from '@angular/core';
import { ReportFilter } from 'src/app/pages/report/filters/shared/report-filter';
import { ReportsService } from 'src/app/settings/_services/reports.service';
import {
  FilterReportStudentMetric
} from 'src/app/pages/report/filters/filter-report-student-metric/models/filter-report-student-metric';
import { CategoryFilterEnum } from 'src/app/models/enums/category-filter.enum';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CategoryFilter, Parent } from 'src/app/shared/components/autocomplete/models/autocomplete.model';
import { UserPreview } from 'src/app/models/previews/user.interface';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { remove, uniqBy } from 'lodash';

@Component({
  selector: 'app-filter-report-student-metric',
  templateUrl: './filter-report-student-metric.component.html',
  styleUrls: ['./filter-report-student-metric.component.scss']
})
export class FilterReportStudentMetricComponent extends ReportFilter implements OnInit {

  public environment = environment;

  public filters = {
    student: null,
    date: { start: null, end: null },
    track: null as CategoryFilter,
    trackAreaTitle: '' as string,
    trackSubAreaTitle: '' as string,
    trackModule: null as CategoryFilter,
    module: null as CategoryFilter,
    segment: null,
    businessGroup: null,
    businessUnit: null,
  };

  public get filter(): FilterReportStudentMetric {
    this.updateFilters(this.categoryFilters);

    const filters = {
      categories: this.categoryFilters.map(filter => ({
        columnName: filter.filterColumn,
        contentNames: filter.selectedOptions
      })),
      date: this.filters.date,
      student: this.filters.student
    };

    const index = filters.categories.findIndex(categorie => categorie.columnName === 'module.id'
      && categorie.contentNames.length === 0);
    filters.categories.splice(index, 1);

    return filters;
  }

  constructor(protected _reportSertivce: ReportsService, protected _snackBar: MatSnackBar) {
    super(_reportSertivce, _snackBar);
  }

  ngOnInit() {
    this._setCategory();
  }

  private _setCategory() {

    forkJoin({
      segment: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Segments }),
      businessGroup: this._reportSertivce.getCategory({ category: CategoryFilterEnum.BusinessGroups }),
      businessUnit: this._reportSertivce.getCategory({ category: CategoryFilterEnum.BusinessUnits }),
      track: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Tracks }),
      module: this._reportSertivce.getCategory({ category: CategoryFilterEnum.Modules }),
    }).subscribe(response => {

      this._setFilter('Segment', CategoryFilterEnum.Segments, 'segment.name',
        response.segment.data.itemsCount, response.segment.data.items, 'name', false);

      this._setFilter('Business Group', CategoryFilterEnum.BusinessGroups, 'businessGroup.name',
        response.businessGroup.data.itemsCount, response.businessGroup.data.items, 'name', false);

      this._setFilter('Business Unit', CategoryFilterEnum.BusinessUnits, 'businessUnit.name',
        response.businessUnit.data.itemsCount, response.businessUnit.data.items, 'name', false);

      this._setFilter('Trilha', CategoryFilterEnum.Tracks, 'track.id',
        response.track.data.itemsCount, response.track.data.items, 'title', true, true);

      this._setFilter('Módulos', CategoryFilterEnum.Modules, 'module.id',
        response.module.data.itemsCount, response.module.data.items, 'title', true, true);
      this._setFilter('Módulos', CategoryFilterEnum.Modules, 'module.id',
        0, [], 'title', true, false, { category: CategoryFilterEnum.Tracks, key: null });


      this.filters.segment = this.categoryFilters[0];
      this.filters.businessGroup = this.categoryFilters[1];
      this.filters.businessUnit = this.categoryFilters[2];
      this.filters.track = this.categoryFilters[3];
      this.filters.trackModule = this.categoryFilters[5];
      this.filters.module = this.categoryFilters[4];

    });


  }

  private _setFilter(
    filterName: string,
    category: number,
    filterColumn: string,
    itemsCount: number,
    collection: any[],
    prop: string = 'name',
    demandsId: boolean = false,
    isSingleOption: boolean = false,
    parent?: Parent,
  ) {

    const newFilter: CategoryFilter = {
      name: filterName,
      category: category,
      filterColumn: filterColumn,
      page: 1,
      itemsCount: itemsCount,
      maxLength: collection.length,
      options: [],
      selectedOptions: [],
      isAlternate: prop === 'title',
      demandsId: demandsId,
      isSingleOption,
      parent,
    };

    for (let index = 0; index < collection.length; index++) {
      newFilter.options.push({
        id: collection[index].id,
        description: collection[index][prop],
        checked: false
      });
    }

    this.categoryFilters.push(newFilter);
    this.selectedFilters.push({ columnName: filterColumn, contentNames: [] });
  }

  public removeStudent(event) {
    this.filters.student = undefined;
  }
  public setStudent(student: UserPreview) {
    this.filters.student = student;
  }

}
