import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { ModuleComponent } from './module.component';
import { BrowserModule } from '@angular/platform-browser';
import { ProgressBarModule } from '../../shared/components/layout/progress-bar/progress-bar.module';
import { ModuleSubjectComponent } from './module-subject/module-subject.component';
import { SubjectContentComponent } from './module-subject/subject-content/subject-content.component';
import { RouterModule } from '@angular/router';
import { ModuleHeaderComponent } from './module-header/module-header.component';
import { ModuleSidebarModule } from '../../shared/components/module-sidebar/module-sidebar.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { RequiredModuleComponent } from './required-module/required-module.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ModuleInfoComponent } from './module-info/module-info.component';
import { SettingsTracksModule } from 'src/app/settings/tracks/tracks.module';
import { BtnExamComponent } from './btn-exam/btn-exam.component';
import { MatIconModule } from '@angular/material/icon';
import { SupportMaterialComponent } from './module-header/material-dialog/support-material/support-material.component';
import { MaterialDialogComponent } from './module-header/material-dialog/material-dialog.component';
import { ModuleOpinionDialogComponent } from './module-opinion-dialog/module-opinion-dialog.component';
import { FormsModule } from '@angular/forms';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { MatCardModule } from '@angular/material/card';
import { FavoriteButtonModule } from 'src/app/shared/components/favorite-button/favorite-button.module';

@NgModule({
    declarations: [
        ModuleComponent,
        ModuleHeaderComponent,
        ModuleSubjectComponent,
        SubjectContentComponent,
        RequiredModuleComponent,
        ModuleInfoComponent,
        BtnExamComponent,
        MaterialDialogComponent,
        SupportMaterialComponent,
        ModuleOpinionDialogComponent
    ],
    imports: [
        BrowserModule,
        ProgressBarModule,
        RouterModule,
        ModuleSidebarModule,
        PipesModule,
        DirectivesModule,
        SettingsTracksModule,
        MatIconModule,
        MatCardModule,
        MatTooltipModule,
        FormsModule,
        MaterialComponentsModule,
        MatCardModule,
        FavoriteButtonModule
    ],
    exports: [
        BtnExamComponent
    ]
})
export class ModuleModule { }
