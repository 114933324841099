import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { WrongItemComponent } from './components/wrong-item/wrong-item.component';
import { MostWrongItemComponent } from 'src/app/shared/components/most-wrong-item/most-wrong-item.component';
// tslint:disable-next-line: max-line-length
import { WrongItemDialogComponent } from 'src/app/shared/components/most-wrong-item/components/wrong-item-dialog/wrong-item-dialog.component';

@NgModule({
    declarations: [MostWrongItemComponent, WrongItemDialogComponent, WrongItemComponent, WrongItemDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
    ],
    exports: [MostWrongItemComponent]
})
export class MostWrongItemModule { }
