<div class="content" [ngStyle]="{'border-color': getBorderColor()}">
  <div class="logo margin-bottom-50px">
    <img src="{{certificate.logoUrl ? (certificate.logoUrl | addHttps) : defaultLogoUrl}}" alt="logo empresa" />
  </div>
  <div class="title margin-bottom-32px">
    <p>NOME DO MÓDULO/TRILHA</p>
  </div>
  <div class="text">
    <p>A {{certificate.businessName ? certificate.businessName : 'PROSEEK' | uppercase }} confere o presente
      certificado, referente à conclusão de módulo/trilha NOME DO PROGRAMA, com
      carga horária com cerca de NúMERO DE HORAS a NOME DO ALUNO.
    </p>
    <p>Treinamento concluído em DD/MM/YYYY</p>
  </div>
  <div class="date margin-bottom-50px">
    <p>{{certificate.address ? certificate.address.city : 'RIO DE JANEIRO' | uppercase}}, data de mês de ano</p>
  </div>
  <div class="signatures">
    <div class="content-siginature">
      <div id="canvas" class="signature">
        <img src="{{certificate.signature}}" />
      </div>
      <p class="font-bold padding-signature">{{certificate?.name | uppercase}}</p>
    </div>
    <div class="content-siginature">
      <p class="font-bold padding-signature">NOME DO ALUNO</p>
    </div>
  </div>
</div>

<div class="content-buttons">
  <button class="btn-test" type="button" (click)="dismiss(true)">
    Aceitar
  </button>
  <button class="btn-test" type="button" (click)="dismiss(false)">
    Recusar
  </button>
</div>
