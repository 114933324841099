import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SettingsValuationTestsService } from 'src/app/settings/_services/valuation-tests.service';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Injectable({
  providedIn: 'root'
})
export class ValuationTestGuard extends NotificationClass implements CanActivate {

  constructor(
    private _settingsValuationTestsService: SettingsValuationTestsService,
    private _router: Router,
    protected snackBar: MatSnackBar,
  ) {
    super(snackBar);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const testId = route.params['testId'];

    const preventAcess = (message: string) => {
      this.notify(message)
        .afterDismissed()
        .subscribe(() => EMPTY);

      return false;
    };

    return this._settingsValuationTestsService.checkTestAnswered(testId)
      .pipe(
        tap(response => {
          if (response.data.answered) return preventAcess('Avaliação já respondida');
        }),
        map(response => !response.data.answered)
      );
  }
}
