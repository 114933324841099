import { Component, OnInit, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileTestResponse, ProfileTestAnswer, ProfileTestQuestion, ProfileTestTypeEnum } from 'src/app/models/profile-test.interface';
import { SettingsValuationTestsService } from '../_services/valuation-tests.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { format } from 'date-fns';
import { isNil, isEmpty } from 'lodash';
import { ValuationTestResponse } from 'src/app/models/valuation-test.interface';

@Component({
  selector: 'app-settings-valuation-test',
  templateUrl: './valuation-test.component.html',
  styleUrls: ['./valuation-test.component.scss'],
})
export class SettingsValuationTestComponent extends NotificationClass implements OnInit {
  public displayedColumns: string[] = ['userName', 'registerId', 'createdAt', 'grade'];
  private FileDownloadButtonIdName: string = 'file-button';
  public responses: Array<ProfileTestResponse>;
  public itemsCount: number = 0;

  private _currentPage: number = 1;
  private _testId: string = null;
  public testTitle: string = '';
  public testType: ProfileTestTypeEnum;
  private loggedUser: LoginResponse;
  public questionsOptions: ProfileTestQuestion[];

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _valuationTestService: SettingsValuationTestsService,
    private _activatedRoute: ActivatedRoute,
    private _excelService: ExcelService,
    private _authService: AuthService
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this.loggedUser = this._authService.getLoggedUser();

    this._testId = this._activatedRoute.snapshot.paramMap.get('testId');
    this._loadResponses(this._currentPage);
  }

  public goToRecommendation(response: ProfileTestResponse, event) {
    if (event.target.id === this.FileDownloadButtonIdName) return;
    this._router.navigate(['configuracoes/testes-de-avaliacao/repostas/' + this._testId + '/' + response.id]);
  }

  public goToPage(page: number): void {
    if (page !== this._currentPage) {
      this._currentPage = page;
      this._loadResponses(this._currentPage);
    }
  }

  public exportReport() {
    this.notify('Depdendendo da quantidade de dados, a exportação pode levar alguns minutos..');

    this._valuationTestService
      .getValuationTestResponses(this._testId, null, null)
      .subscribe((response: { data: { responses: ValuationTestResponse[]; testTitle: string } }) => {
        const rows = this._getUserResponses(response.data.responses);
        if (isNil(response) || isEmpty(response)) {
          this.notify('Não há dados para exportar');
          return;
        }

        const testTitle = response.data.testTitle;
        const reportExcel = [];
        const hasModuleTitle = rows.some(x => x.answers.some(y => !isNil(y.moduleTitle)));
        const hasSubjetcTitle = rows.some(x => x.answers.some(y => !isNil(y.subjectTitle)));

        rows.forEach((row) => {
          row.answers.forEach((userAnswer) => {
            const dbQuestion = this.questionsOptions.find((questionToFind) => questionToFind.id === userAnswer.questionId);
            const answerIndex = isEmpty(dbQuestion.options)
              ? -1
              : dbQuestion.options.findIndex((option) => option.text === userAnswer.answer);

            const createRowOption = (answer?, isCorrect?, index?) => {
              const rowOption = {
                'Aluno': row.userName,
                'Matrícula': row.userRegisterId,
                'Respondido em': format(row.createdAt, 'DD/MM/YYYY HH:mm:ss'),
                'Nota final': row.finalGrade,
                'Pergunta': userAnswer.question,
                'Resposta': answer,
                'Resposta certa': answerIndex !== -1 && isCorrect ? 'X' : '',
                'Resposta aluno': dbQuestion.type === ProfileTestTypeEnum.NPS ?
                  userAnswer.answer
                  : answerIndex !== -1 && answerIndex === index ? 'X' : '',
                'Tipo de questão': this.GetTestType(dbQuestion.type),
                'Nota da questão': userAnswer.grade / 10,
              };
              if (hasModuleTitle) {
                rowOption['Módulo'] = '';
                if (!isNil(userAnswer.moduleTitle)) {
                  rowOption['Módulo'] = userAnswer.moduleTitle;
                  rowOption['Código do Módulo'] = userAnswer.moduleId;
                }
              }
              if (hasSubjetcTitle) {
                rowOption['Assunto'] = '';
                if (!isNil(userAnswer.subjectTitle)) {
                  rowOption['Assunto'] = userAnswer.subjectTitle;
                  rowOption['Código do Assunto'] = userAnswer.subjectId;
                }
              }
              return rowOption;
            };

            answerIndex === -1
              ? reportExcel.push(createRowOption())
              : dbQuestion.options.forEach((option, index) => reportExcel.push(createRowOption(option.text, option.correct, index)));
          });
        });

        const header = [
          ['Titulo:', testTitle],
          ['Data:', format(new Date(), 'DD/MM/YYYY HH:mm:ss')],
          ['Gerado por:', `${this.loggedUser.name} (${this.loggedUser.username})`],
          [],
        ];

        this._excelService.exportAsExcelFile(reportExcel, `Relatório_${testTitle}_`, header);
      });
  }

  private _loadResponses(page: number): void {
    this._valuationTestService.getValuationTestResponses(this._testId, page).subscribe((response) => {
      this.responses = this._getUserResponses(response.data.responses);
      this.questionsOptions = response.data.questionOptions;
      this.testType = this.questionsOptions[0].type;
      if (this.testType === ProfileTestTypeEnum.Coursework) {
        this.displayedColumns = ['userName', 'registerId', 'createdAt', 'grade', 'file'];
      }
      this.itemsCount = response.data.itemsCount;
      this.testTitle = response.data.testTitle;
    });
  }

  public download(url): void {
    this._valuationTestService.downloadFileFromServe(url).subscribe();
  }

  public GetTestType(type: ProfileTestTypeEnum): string {
    switch (type) {
      case ProfileTestTypeEnum.MultipleChoice:
        return 'Múltipa escolha';
      case ProfileTestTypeEnum.Discursive:
        return 'Discursiva';
      case ProfileTestTypeEnum.NPS:
        return 'NPS';
      default:
        break;
    }

  }

  private _getUserResponses(responses: Array<ProfileTestResponse>): Array<ProfileTestResponse> {
    responses.forEach((response) => {
      response.finalGrade = this._setGrade(response.answers);
    });
    return responses;
  }

  private _setGrade(answers: Array<ProfileTestAnswer>) {
    if (answers.every((anser) => anser.grade != null)) {
      return answers.reduce((sum, anser) => sum + anser.grade, 0) / 10;
    }
  }
}
