<div id="Exam" class="inner-content">
  <ng-container *ngIf="currentQuestion">
    <div class="content" [ngSwitch]="currentQuestion.type" [ngClass]="{ 'reviewing': reviewingConcept }">
      <app-exam-question *ngSwitchCase="valuationTestQuestionType.MultipleChoice" #questions
        [style.width]="questionWidth + '%'" [title]="getTitle()" [isQuestionBdq]="false" [question]="currentQuestion"
        [evalAnswer]="answerResult" [showButton]="countQuestionAnswered() === 1" [reviewingConcept]="reviewingConcept"
        (confirmAnswer)="confirmAnswer($event)" (goToNextQuestion)="goToNextQuestion()"
        [last]="countQuestionAnswered() === 1 && currentQuenstionIndex + 1 === this.test.testQuestions.length"
        (finished)="sendResponse()">
      </app-exam-question>
      <app-exam-question-discursive *ngSwitchCase="valuationTestQuestionType.Discursive" #questionsDiscursive
        [question]="currentQuestion" [showButton]="countQuestionAnswered() === 1" (finished)="sendResponse()"
        [title]="getTitle()" (confirmAnswer)="confirmAnswer($event)" (goToNextQuestion)="goToNextQuestion()"
        [last]="countQuestionAnswered() === 1 && currentQuenstionIndex + 1 === this.test.testQuestions.length">
      </app-exam-question-discursive>

      <app-exam-nps *ngSwitchCase="valuationTestQuestionType.NPS" #questionNPS [style.width]="questionWidth + '%'"
        [title]="getTitle()" [isQuestionBdq]="false" [question]="currentQuestion" [evalAnswer]="answerResult"
        [showButton]="countQuestionAnswered() === 1" (confirmAnswer)="confirmAnswer($event)"
        (goToNextQuestion)="goToNextQuestion()" (finished)="sendResponse()"></app-exam-nps>
    </div>
  </ng-container>
</div>

<app-exam-footer *ngIf="currentQuestion" [progress]="countQuestionAnswered()" [hasAnswered]="_canMoveToNextQuestion()"
  [hasMoreQuestions]="!(currentQuenstionIndex + 1 >= totalQuestions)" [showBackButton]="true" [isQuestionBdq]="false"
  [isNotInitialQuestion]="currentQuenstionIndex !== 0" (goToNextQuestion)="goToNextQuestion()"
  (goToBackQuestion)="goToBackQuestion()" (backToModule)="backToModule()"></app-exam-footer>
