import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ContentMenuComponent } from './common/menu/menu.component';
import { ContentComponent } from './content.component';
import { ProgressBarModule } from '../../shared/components/layout/progress-bar/progress-bar.module';
import { VideoContentComponent } from './video/video.component';
import { ContentFooterComponent } from './common/footer/footer.component';
import { ContentDescriptionComponent } from './common/description/description.component';
import { TextContentComponent } from './text/text.component';
import { PDFContentComponent } from './pdf/pdf.component';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { ForumQuestionDialogModule } from 'src/app/shared/dialogs/forum-question/forum-question.module';
import { EventForumQuestionDialogModule } from 'src/app/shared/dialogs/event-forum-question/event-forum-question.module';
import { MaterialComponentsModule } from '../../shared/material.module';
import { FormsModule } from '@angular/forms';
import { HTMLContentComponent } from './html/html.component';
import { ModuleModule } from 'src/app/pages/module/module.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ScormComponent } from './scorm/scorm.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VideoTagDialogComponent } from './video/video-tag-dialog/video-tag-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { PdfTagDialogComponent } from './pdf/pdf-tag-dialog/pdf-tag-dialog.component';
import { CustomTooltipModule } from 'src/app/shared/components/custom-tooltip/custom-tooltip.module';
import { DownloadComponent } from './download/download.component';

@NgModule({
    declarations: [
        ContentComponent,
        ContentMenuComponent,
        VideoContentComponent,
        TextContentComponent,
        PDFContentComponent,
        ContentDescriptionComponent,
        ContentFooterComponent,
        HTMLContentComponent,
        ScormComponent,
        VideoTagDialogComponent,
        PdfTagDialogComponent,
        DownloadComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        ProgressBarModule,
        PipesModule,
        MarkdownToHtmlModule,
        ForumQuestionDialogModule,
        EventForumQuestionDialogModule,
        FormsModule,
        MaterialComponentsModule,
        ModuleModule,
        CustomTooltipModule,
        NgxMaskModule,
        MatIconModule,
        PdfJsViewerModule,
    ],
    exports: [
        VideoContentComponent,
        TextContentComponent,
        PDFContentComponent,
        ContentDescriptionComponent,
        ContentFooterComponent,
        HTMLContentComponent,
        ScormComponent
    ]
})
export class ContentModule { }
