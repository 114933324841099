<div class="step" >
  <div class="title-box">
    
    <h2>Datas Relevantes</h2>
    <button class="btn-test" style="margin-left: 20px;" (click)="file.click()">
      Importar eventos
    </button>
    <input class="input-file" type="file" accept=".csv" hidden
      (change)="setDocumentFile($event.target.files)" #file/>
  </div>
  <form [formGroup]="formGroup" >
    <div class="calendar-event" 
      *ngFor="let childFormGroup of formGroup.get('calendarEvents')['controls']; let i = index"
      formArrayName="calendarEvents" >
      <ng-container [formGroupName]="i" >
        <img class="trash"
          src="./assets/img/trash.png"
          (click)="removeCalendarEvent(i)"
        />
        <div class="two-columns" >
          <div class="validation-field" >
            <mat-form-field>
              <input matInput placeholder="Título"
                formControlName="title" required />
            </mat-form-field>
            <control-messages [control]="childFormGroup.get('title')"
            ></control-messages>
          </div>
          <div class="validation-field" >
            <mat-form-field>
              <input matInput [matDatepicker]="startPicker"
              formControlName="eventDate" placeholder="Data de Início">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <control-messages
              [control]="childFormGroup.get('eventDate')"
            ></control-messages>
          </div>
        </div>
        <div class="two-columns" >
          <div class="validation-field" >
            <mat-form-field>
              <input matInput placeholder="Hora (hh:mm)"
              mask="00:00" formControlName="startHour" />
            </mat-form-field>
            <control-messages
              [control]="childFormGroup.get('startHour')"
            ></control-messages>
          </div>
          <div class="validation-field" >
            <mat-form-field>
              <input matInput placeholder="Duração Total (hh:mm:ss)"
                mask="00:00:00" formControlName="duration"  />
            </mat-form-field>
            <control-messages
              [control]="childFormGroup.get('duration')"
            ></control-messages>
          </div>
        </div>
        <p class="obs" >
          obs: caso a Hora e a Duração Total não sejam preenchidas, a data será considerada no calendário como evento de dia inteiro.
        </p>
      </ng-container>
    </div>

    <button class="btn-test add-date" (click)="addCalendarEvent()" >
      + Adicionar Data
    </button>
  </form>
</div>
