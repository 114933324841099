import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from 'src/app/shared/material.module';

import { CertificateEditDialogComponent } from './certificate-edit-dialog/certificate-edit.dialog';
import { CertificatePreviewComponent } from './certificate-preview/certificate-preview.component';
import { CertificateEditComponent } from './certificate-edit.component';

import { SettingsUsersService } from '../_services/users.service';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';


@NgModule({
    imports: [
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        RouterModule,
        PipesModule
    ],
    providers: [
        SettingsUsersService
    ],
    declarations: [
        CertificateEditComponent,
        CertificateEditDialogComponent,
        CertificatePreviewComponent
    ],
    exports: [CertificateEditComponent]
})

export class CertificateEditModule { }
