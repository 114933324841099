import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  public static readonly key = {
    authData: 'auth_data',
    seeHowRole: 'SeeHowRole',
    userToSeeHowManager: 'UserToSeeHowManager',
    selectedAccess: 'SelectedAccess',
    originalRole: 'OriginalRole',
    impersonatedUser: 'ImpersonatedUser',
    loggedUser: 'loggedUser',
    logoUrl: 'logo-url',
    colorPalette: 'color-palette',
    trackResponsible: 'track-responsible',
    userModuleProgress: 'user-module-progress',
    questionModuleReload: 'questionModuleReload',
    infoIsCompleted: 'info_is_completed',
    editingModule: 'editingModule'
  };


  constructor() { }

}
