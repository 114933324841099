<ngx-datatable #Table class="material fullscreen" [rows]="data" trackByProp="id" [rowIdentity]="rowIdentity"
  [messages]="messages" [headerHeight]="78" [footerHeight]="50" [rowHeight]="'auto'" [virtualization]="false"
  [columnMode]="ColumnMode.standard" [scrollbarH]="false" [scrollbarV]="true"
  title="Para acessar os detalhes, selecione {{replacement.track.singular | replacement:'uma' }}"
  (activate)="goToTrackDetail($event)">

  <ngx-datatable-column prop="title" [width]="412" name="{{replacement.track.singular}}">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="studentsCount" [width]="99" name="Total de alunos">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="trackOverviewBadges.level" [width]="60" name="aaaaa">
    <ng-template let-column="column" ngx-datatable-header-template>
      <img src="./assets/img/empty-badge.png" />
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.trackOverviewBadges[0]?.count + row?.trackOverviewBadges[1]?.count}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="trackOverviewBadges.level" [width]="60" name="">
    <ng-template let-value="value" let-column="column" ngx-datatable-header-template>
      <img src="./assets/img/pencil-icon-shadow.png" />
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.trackOverviewBadges[2]?.count}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="trackOverviewBadges.level" [width]="60" name="">
    <ng-template let-column="column" ngx-datatable-header-template>
      <img src="./assets/img/glasses-icon-shadow.png" />
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.trackOverviewBadges[3]?.count}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="trackOverviewBadges.level" [width]="60" name="">
    <ng-template let-column="column" ngx-datatable-header-template>
      <img src="./assets/img/brain-icon-shadow.png" />
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.trackOverviewBadges[4]?.count}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="trackOverviewBadges.level" [width]="60" name="">
    <ng-template let-column="column" ngx-datatable-header-template>
      <img src="./assets/img/brain-dark-icon-shadow.png" />
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row?.trackOverviewBadges[5]?.count}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="title" [width]="118" prop="overallAverage" name="MED. GERAL BDQ">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="MED. GERAL PRÉ MÓDULO" prop="overallAveragePreModule" [width]="131">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="MED. GERAL PÓS MÓDULO" prop="overallAveragePostModule" [width]="133">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>
