import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash';
import { ImetricsStudents, IMetric } from '../../../models/trackStudentsSubordinates';

@Component({
  selector: 'app-effort-progress',
  templateUrl: './effort-progress.component.html',
  styleUrls: ['./effort-progress.component.scss']
})
export class EffortProgressComponent implements OnInit {

  @Input() metrics: IMetric;
  @Input() text: string;
  public primary_color: string;
  public readonly isNullOrUndefined = (value) => isNil(value);
  constructor() { }

  ngOnInit() {
    const style = getComputedStyle(document.body);
    this.primary_color = style.getPropertyValue('--primary-color');
  }

}
