<mat-form-field *ngIf="filter">
  <i class="icon icon-lupa icon-search"></i>
  <mat-label>{{filter?.name}}</mat-label>
  <!-- <input matInput type="text"
    #AutocompleteInput
    (keyup)="updateSearch($event.target.value)"
    [matAutocomplete]="auto"
    [disabled]="filter.parent && filter.parent.key === null"
    [placeholder]="filter?.placeholder" />-->
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  <mat-chip-list #chipList [multiple]="filter.isSingleOption">
    <mat-chip
      *ngFor="let option of filter?.selectedOptions"
      selectable="true"
      removable="true"
      [disabled]="filter.parent && filter.parent.key === null"
      (removed)="removeOption(option)">
      {{option.description}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #AutocompleteInput
      [hidden]="filter?.isSingleOption && filter?.selectedOptions.length === 1"
      [matAutocomplete]="auto"
      [disabled]="filter.parent && filter.parent.key === null"
      (keyup)="updateSearch($event.target.value)"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      matChipInputAddOnBlur="false" />
  </mat-chip-list>
  <mat-autocomplete #AutoComplete panelWidth="320" #auto="matAutocomplete" autoActiveFirstOption>
    <mat-option *ngFor="let option of filter?.options "
      (onSelectionChange)="optionSelectionChange(option, $event)"
      [matTooltip]="option?.tooltip"
      matTooltipShowDelay="1000">

      <span *ngIf="filter?.isSingleOption">{{option?.description}}</span>
      <mat-checkbox *ngIf="!filter?.isSingleOption" [(ngModel)]="option.checked"> {{option?.description}} </mat-checkbox>

    </mat-option>
  </mat-autocomplete>
</mat-form-field>
