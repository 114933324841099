<ngx-datatable
  #Table
  class="material fullscreen"
  [rows]="data"
  trackByProp="id"
  [rowIdentity]="rowIdentity"
  [messages]="messages"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [virtualization]="false"
  [columnMode]="ColumnMode.standard"
  [scrollbarH]="false"
  [scrollbarV]="true"
  title="Para acessar os detalhes, selecione um evento"
  (activate)="goToEventDetail($event)">

  <ngx-datatable-column prop="title" [width]="611" name="Nome">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="eventDate" [width]="190" name="Data do evento">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <span class="date">{{ value  }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="studentsCount" [width]="190" name="Total de alunos">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>


  <ngx-datatable-column prop="overallAverage" [width]="190" name="Média dos alunos">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>
