<div class="header">
  <h3>Isenção de alunos em módulos</h3>
  <p>
    Para isentar expanda os cards dos alunos selecione o módulo e escreva o motivo da isenção e clique em adicionar.
    Após repetir esse passo para todos os aluno clique em salvar para consolidar suas alterações
  </p>
</div>
<mat-accordion *ngIf="usersExemptions && usersExemptions.length > 0">
  <mat-expansion-panel *ngFor="let item of usersExemptions; let index = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="cat">
          {{item.name}}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="module-form">
      <img class="search-icon" src="./assets/img/search.png" />
      <div class="search">
        <input matInput type="text" placeholder="Nome do módulo" id="moduleInput_{{index}}"
          (keyup)="updateModuleSearch($event.target.value, index)" required />

        <div class="search-results" *ngIf="searchModuleResults[index] && searchModuleResults[index].length > 0">
          <div *ngFor="let module of searchModuleResults[index]" (click)="setModule(module, index)">
            <p> {{ module.title }}</p>
          </div>
        </div>
      </div>
      <mat-form-field class="login-input">
        <input matInput placeholder="Escreva o motivo da isenção" [(ngModel)]="selectedExemptions[index].reason" />
      </mat-form-field>
      <button class="btn-test" type="button" (click)="addExemption(item.userId, index)">
        Adicionar
      </button>
    </div>
    <ng-container *ngIf="item && item.exemptions.length > 0">
      <table mat-table [dataSource]="item.exemptions" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Módulo</th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef> Razão de isenção </th>
          <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Ações </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <img src="./../../../../assets/img/trash.png" style="cursor: pointer;" alt="icone de exclusão de isenção"
              (click)="removeExemption(item.userId, i)"> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          [class.hidden]="row.canExemption === false "></tr>
      </table>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
<div class="actions">
  <button class="btn-outline" (click)="dismiss()">
    fechar
  </button>
  <button class="btn-test" (click)="save()">
    Salvar
  </button>
</div>
