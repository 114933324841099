import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sector } from 'src/app/models/sector.model';
import { Segment } from 'src/app/models/segment.model';
import { Program } from 'src/app/settings/programs/Models/program.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { CascateSelectComponent } from 'src/app/shared/components/cascate-select/cascate-select.component';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-settings-program-info',
  templateUrl: './program-info.component.html',
  styleUrls: ['./program-info.component.scss']
})
export class ProgramInfoComponent extends NotificationClass {

  private _form: FormGroup = this._createForm();

  public get form(): FormGroup { return this._form; }
  public set form(value: FormGroup) {
    this._form = value;
    this.formChange.emit(this.form);
  }
  @ViewChild('cascateSelect', { static: false }) cascateSelect: CascateSelectComponent;

  @Output() formChange = new EventEmitter<FormGroup>();

  private _value: Partial<Program>;
  @Input()
  public get value(): Partial<Program> { return this._value; }
  public set value(value: Partial<Program>) {
    this._value = value;
    this.form = this._createForm(this._value);
  }

  public selectedSector: Sector;
  public selectedSegment: Segment;

  constructor(
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    super(_snackBar);
  }



  private _createForm(values: Partial<Program> = {} as Partial<Program>) {
    const { title, description, startDate, endDate, countTeams, sector, segment } = values;

    if (sector) this.selectedSector = sector;
    if (sector) this.selectedSegment = segment;

    return new FormGroup({
      title: new FormControl(title || '', [Validators.required, Validators.minLength(3)]),
      description: new FormControl(description || '', [Validators.required, Validators.minLength(3), Validators.maxLength(4000)]),
      startDate: new FormControl(new Date(startDate) || '', [Validators.required]),
      endDate: new FormControl(new Date(endDate) || '', [Validators.required]),
      sectorId: new FormControl((sector && sector.id) || ''),
      segmentId: new FormControl((segment && segment.id) || '')
    });
  }

  public handleSelectSectorAndSegment(type) {
    const selectedCascate = this.cascateSelect.GetSelectedItems;
    if (type=="sector") {
      this.selectedSector = selectedCascate.sector;
      this.selectedSegment = {
        id:'',
        name:'',
        sectorId:this.selectedSector.id,
        sector:this.selectedSector
      };
    }
    else this.selectedSegment = selectedCascate.segment;
    const sectorControl = this.form.get('sectorId');
    const segmentControl = this.form.get('segmentId');
    if (this.selectedSector) sectorControl.patchValue(this.selectedSector.id);  
    if (this.selectedSegment) segmentControl.patchValue(this.selectedSegment.id);
    sectorControl.updateValueAndValidity({ emitEvent: true, onlySelf: true });
    segmentControl.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

}
