<div class="step">
  <div class="title-wrapper">
    <span class="title">Eccomerce</span>
    <span class="subtitle">Informe os dados deste programa no e-commerce</span>
  </div>
  <div class="wrapper">
    <form [formGroup]="form" autocomplete="off">
      <div class="product" formArrayName="ecommerce"
        *ngFor="let ecommerceControl of form.get('ecommerce')?.controls; let index = index">
        <ng-container [formGroupName]="index">
          <div class="two-columns">
            <div class="validation-field">
              <mat-form-field>
                <input matInput type="number" placeholder="Id do Ecommerce" formControlName="ecommerceId" />
              </mat-form-field>
            </div>
            <div class="content-button">
              <button>
                <img class="trash" src="./assets/img/trash.png" (click)="removeEcommerceProduct(i)" />
                <span>Excluir</span>
              </button>
            </div>

          </div>
          <div class="two-columns">
            <div class="validation-field">
              <mat-form-field>
                <mat-select placeholder="Free Trial?" formControlName="disableFreeTrial">
                  <mat-option [value]="true">Sim</mat-option>
                  <mat-option [value]="false">Não</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput type="number" placeholder="Número de Usuários a Cadastrar por Compra"
                  formControlName="usersToRegisterPerPurchase" />
              </mat-form-field>
            </div>
          </div>
          <div class="two-columns">
            <div class="validation-field">
              <mat-form-field>
                <mat-select placeholder="Exibir o E-commerce?" formControlName="disableEcommerce">
                  <mat-option [value]="true">Sim</mat-option>
                  <mat-option [value]="false">Não</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput placeholder="Preço" type="number" step="0.01" formControlName="price">
              </mat-form-field>
            </div>
          </div>
          <div class="validation-field">
            <mat-form-field>
              <input matInput type="text" placeholder="Link para check out" formControlName="linkEcommerce">
            </mat-form-field>
          </div>
          <mat-form-field style="width: 100%">
            <input matInput placeholder="Link para detalhes da trilha" formControlName="linkProduct">
          </mat-form-field>
          <div class="two-columns">
            <div class="validation-field">
              <mat-form-field>
                <mat-select placeholder="Assunto" formControlName="subject">
                  <mat-option [value]="'finance'">Finanças</mat-option>
                  <mat-option [value]="'business'">Negócio</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="validation-field">
              <mat-form-field>
                <input matInput placeholder="Carga horária (hh:mm:ss)" mask="00:00:00" formControlName="workload" />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <button class="btn-test add-date" (click)="addEcommerceId()">
    + Adicionar Id Ecommerce
  </button>
</div>
