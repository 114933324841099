<div id="SearchResults" class="inner-content">

  <div class="sidebar">
    <p>VISUALIZAÇÃO</p>

    <div class="filters">
      <img *ngIf="viewType === 'cards'" (click)="changeView('list')" src="./assets/img/grid-icon.png" />
      <img *ngIf="viewType === 'list'" (click)="changeView('cards')" src="./assets/img/grid-icon-inactive.png" />
      <img *ngIf="viewType === 'list'" (click)="changeView('cards')" src="./assets/img/list-icon.png" />
      <img *ngIf="viewType === 'cards'" (click)="changeView('list')" src="./assets/img/list-icon-inactive.png" />
    </div>

    <p>Tags</p>
    <ul>
      <li *ngFor="let tag of tags">
        <div class="checkbox" [ngClass]="{ 'checked': tag.checked }" (click)="updateFilter(tag)"></div>
        {{ tag.tag }}
      </li>
    </ul>
  </div>

  <div class="content">
    <h3>{{replacement.module.singular}} <span>({{ modulesCount }} resultados)</span></h3>
    <div class="results" [ngClass]="{ 'list': viewType === 'list' }">
      <app-module-card *ngFor="let module of modules" [module]="module" [viewType]="viewType"
        [progress]="moduleProgress[module.id]" [levels]="levelDict" [showRecommended]="true" [showText]="true"
        [showInstructor]="true" [showContent]="true" [hours]="true" [showButtonKnowMore]="true" [showPrice]="true"
        [showButtonSubscribe]="false" [redirectWhenRecommended]="true"></app-module-card>
    </div>
    <app-pagination *ngIf="modulesCount" [setPagination]="{
        'itemsCount': modulesCount,
        'pageSize': 6
      }" (goToPage)="goToModulePage($event)"></app-pagination>

    <br><br>
    <h3>{{replacement.track.singular}} <span>({{ tracksCount }} resultados)</span></h3>
    <div class="results" [ngClass]="{ 'list': viewType === 'list' }">
      <app-track-card *ngFor="let track of tracks" [track]="track" [showRecommended]="true" [showText]="true"
        [viewType]="viewType" [showModule]="true" [showEvents]="true" [hours]="true" [showContent]="true"
        [showButtonKnowMore]="true" [showPrice]="true" [showButtonSubscribe]="false" [redirectWhenRecommended]="false">
      </app-track-card>
    </div>
    <app-pagination *ngIf="tracksCount" [setPagination]="{
        'itemsCount': tracksCount,
        'pageSize': 6
      }" (goToPage)="goToTrackPage($event)"></app-pagination>
  </div>

</div>
