<div class="options">
    <p>
      <ng-container *ngFor="let opt of viewOptions">
        <span
          [ngClass]="{ 'active': opt.selected }"
          (click)="selectViewOption(opt.title)">
          {{opt.title}}
        </span>
      </ng-container>
    </p>
</div>

<ng-container *ngIf="isViewOption('MÓDULO')">
    <cdk-virtual-scroll-viewport itemSize="10" class="contenat-card">
      <ng-container *cdkVirtualFor="let module of modules">
        <app-cockpit-menu-card
          [title]="module.title"
          [progress]="module.studentFinishedPercent"
          (click)="goToModule.emit(module.id)"
        ></app-cockpit-menu-card>
      </ng-container>
    </cdk-virtual-scroll-viewport>
</ng-container>
<ng-container *ngIf="isViewOption('ALUNO')">
  <app-list-search
    placeholder="Pesquisar Aluno"
    [noPadding]="true"
    (triggerSearch)="searchStudents($event)"
  ></app-list-search>
    <cdk-virtual-scroll-viewport itemSize="10" class="contenat-card">
    <ng-container *cdkVirtualFor="let student of filteredStudents">
      <app-cockpit-menu-card
        [title]="student.name"
        (click)="goToStudent.emit(student)"
      ></app-cockpit-menu-card>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-container>

