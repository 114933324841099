<div class="opa-background">
    <div class="card">
        <div class="top">
            <img src="./assets/img/right-answer-colored.png"/>
            <p>Questão Aprovada</p>
        </div>
        <div class="center">
            <p>
                Por favor, avalie o nível de dificuldade 
                da questão aprovada: 
            </p>
            <div class="radio-group">
                <mat-radio-group [(ngModel)]="level">
                    <mat-radio-button [value]="0">
                        <p>Facil</p>
                    </mat-radio-button>
                    <mat-radio-button class="last" [value]="1">
                        <p>Médio</p>
                    </mat-radio-button>
                    <mat-radio-button class="last" [value]="2">
                        <p>Dificil</p>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="question">
                <p class="question-title">Questão</p>
                <p>{{data.moduleQuestion.text}}</p>
                <mat-radio-group>
                    <mat-radio-button disabled *ngFor="let answer of data.moduleQuestion.answers">
                        <p>{{answer.text}}</p>
                    </mat-radio-button>
                </mat-radio-group>
                <!--p *ngFor="let answer of data.moduleQuestion.answers">
                    {{answer.text}}
                </p-->
            </div>
        </div>
        <div class="bottom">
            <button class="btn-test" (click)="dialogRef.close(level)" >
                Ok
            </button>
            <button class="btn-test" (click)="dialogRef.close()" >
                Cancelar
            </button>
        </div>
    </div>
</div>