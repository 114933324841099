import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cockpit-buttons',
  templateUrl: './cockpit-buttons.component.html',
  styleUrls: ['./cockpit-buttons.component.scss']
})
export class CockpitButtonsComponent implements OnInit {

  @Input() type: string;
  @Input() title?: string;
  @Input() width?: number;
  @Input() height?: number;
  @Output() clickButton = new EventEmitter();
  public primary_color: string;
  constructor() { }

  ngOnInit() {
    const style = getComputedStyle(document.body);
    this.primary_color = style.getPropertyValue('--primary-color');
  }
}
