import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { differenceInSeconds, isValid } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { Event, ValuationConfiguration } from 'src/app/models/event.model';
import { NotificationClass } from 'src/app/shared/classes/notification';

@Component({
  selector: 'app-relevant-date',
  templateUrl: './relevant-date.component.html',
  styleUrls: ['./relevant-date.component.scss']
})
export class RelevantDateComponent extends NotificationClass implements OnInit, OnDestroy {

  public readonly displayedColumns: string[] = [
    'modality', 'alwaysAvailable', 'openDate', 'valuationDate'
  ];

  @Input() event$: Observable<Event>;

  @Output() addValuationDate = new EventEmitter<ValuationConfiguration[]>();
  @Output() error = new EventEmitter<void>();

  public event: Event;
  public trackItemType = {};

  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(eventItems => {
        this.event = cloneDeep(eventItems);
      });
    }
  }



  public nextStep(): void {
    const invalidDatesErrors = this._checkDates(this.event.valuationConfiguration) || [];

    if (!invalidDatesErrors.length) {
      this.addValuationDate.emit(this.event.valuationConfiguration);
    }
  }

  public toggleModuleAvailability(row: any) {
    row.alwaysAvailable = !row.alwaysAvailable;
    if (row.alwaysAvailable) {
      row.openDate = null;
      row.cutOffDate = null;
      row.valuationDate = null;
    }
  }

  private _checkDates(valuation: Array<any>) {
    const errors = [];
    for (let i = 0; i < valuation.length; i++) {
      const item = valuation[i];
      const start = new Date(item.openDate);
      const end = new Date(item.cutOffDate || item.valuationDate);



      const errorMessage = `contém uma configuração de data inválida.`;

      if (!this._checkDate(start, end) || !this._checkDate(start, end) || !isValid(start)) {
        errors.push(errorMessage);
      } else if (item.cutOffDate && item.valuationDate && differenceInSeconds(item.cutOffDate, item.valuationDate) > 0) {
        errors.push(`${errorMessage}\nA data de corte não pode ser maior que a data de término.\n`);
      }
    }
    return errors;
  }

  private _checkDate(fromDate: Date, toDate: Date): boolean {
    if (fromDate && toDate) {
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      if (fromDate > toDate) {
        return false;
      } else {
        return true;
      }
    } else if (fromDate) {
      return true;
    } else {
      return false;
    }
  }
}
