import { Component, OnInit } from '@angular/core';
import { ContentModulesService } from '../../pages/_services/modules.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification';
import { Module } from '../../models/module.model';
import { ModulePreview } from '../../models/previews/module.interface';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ModuleExcel } from 'src/app/models/module-excel.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DeleteModuleDialogComponent } from './delete-module/delete-module.dialog';
import { CloneModuleDialogComponent } from './clone-module/clone-module.dialog';
import { SettingsModulesDraftsService } from '../_services/modules-drafts.service';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { SettingsModulesService } from 'src/app/settings/_services/modules.service';
import { ActivateModuleDialogComponent } from './activate-module/activate-module.dialog';
import { ActivateModule } from './activate-module/models/activate-module.model';

@Component({
  selector: 'app-settings-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class SettingsModulesComponent extends NotificationClass implements OnInit {

  public modules: Array<Module> = [];
  public modulesCount: number = 0;
  private _modulesPage: number = 1;
  public inactiveModules: Array<Module> = [];
  public inactiveModulesCount: number = 0;
  private _inactiveModulesPage: number = 1;
  private _searchSubject: Subject<string> = new Subject();
  public user: any;

  constructor(
    protected _snackBar: MatSnackBar,
    private _modulesService: ContentModulesService,
    private _modulesSettingsService: SettingsModulesService,
    private _modulesDraftsService: SettingsModulesDraftsService,
    private _excelService: ExcelService,
    private _authService: AuthService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }

  ngOnInit() {
    this._loadModules(this._modulesPage);
    this._loadInactiveModules(this._inactiveModulesPage);
    this._setSearchSubscription();
    this.user = this._authService.getLoggedUser();
  }

  public goToPage(page: number) {
    if (page !== this._modulesPage) {
      this._modulesPage = page;
      this._loadModules(this._modulesPage);
    }
  }

  public goToPageInactiveModules(page: number) {
    if (page !== this._inactiveModulesPage) {
      this._inactiveModulesPage = page;
      this._loadInactiveModules(this._inactiveModulesPage);
    }
  }

  public exportModules() {
    const excelModule: ModuleExcel[] = [];
    for (let moduleIndex = 0; moduleIndex < this.modules.length; moduleIndex++) {
      const module = this.modules[moduleIndex];
      if (module.subjects === null || module.subjects.length === 0) {
        excelModule.push({ moduleId: module.id, moduleName: module.title });
      } else {
        for (let subjectIndex = 0; subjectIndex < module.subjects.length; subjectIndex++) {
          const subject = module.subjects[subjectIndex];
          excelModule.push({ moduleId: module.id, moduleName: module.title, subjectId: subject.id, subjectName: subject.title });
        }
      }
    }
    this._excelService.exportAsExcelFile(excelModule, 'Modulos');
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  public createNewModule(): void {
    this._router.navigate(['/configuracoes/modulo']);
  }

  public editModule(module: ModulePreview) {
    this._router.navigate([this._goToEditModule(module)]);
  }

  public cloneModule(module: ModulePreview) {


    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      hasBackdrop: true,
      closeOnNavigation: false,
      disableClose: true,
      data: {
        message: 'Tem certeza que deseja duplicar este módulo ?',
        positiveTextAction: 'Sim',
        negativeTextAction: 'Cancelar'
      } as SimpleDialog
    });

    dialogRef.afterClosed().subscribe(canDuplicate => {
      if (canDuplicate) {

        this._modulesSettingsService.cloneModule({
          isDraft: module.isDraft,
          moduleId: module.id
        }).subscribe((newModuleResponse) => {
          newModuleResponse.data.isDraft = true;

          this.modules.push(newModuleResponse.data);
          this._loadModules(this._modulesPage);

          this.notify('Módulo duplicado com sucesso.');
        });

      }
    });

  }

  private _goToEditModule(module: ModulePreview) {
    const prefix = 'configuracoes/modulo';
    return module.isDraft ? `${prefix}/${module.id}/rascunho` : `${prefix}/${module.id}`;
  }

  public deleteModule(module: ModulePreview): void {
    const dialogRef = this._dialog.open(DeleteModuleDialogComponent, {
      width: '1000px',
      data: module.hasUserProgess
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const moduleId = module.isDraft ? module.moduleId : module.id;
        const draftId = module.isDraft ? module.id : '';
        this._modulesService.deleteModuleById(moduleId, draftId).subscribe(() => {
          this.notify('Módulo deletado com sucesso');
          this._loadModules(this._modulesPage);
        });
      }
    });
  }

  public changeModuleActivity(module: ModulePreview): void {
    this._modulesService.getTracksById(module.isDraft ? module.moduleId : module.id).subscribe((tracks) => {
      const dialogRef = this._dialog.open(ActivateModuleDialogComponent, {
        width: '647px',
        data: {
          isInactive: module.isInactive,
          tracks: tracks.data
        } as ActivateModule
  
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const moduleId = module.isDraft ? module.moduleId : module.id;
          const draftId = module.isDraft ? module.id : '';
          this._modulesService.updateModuleActivity(moduleId, draftId).subscribe(() => {
            if(module.isInactive) this.notify(`O módulo ${module.title} foi ativado`);
            else this.notify(`O módulo ${module.title} foi desativado`);
            this._loadAllModules();
          });
        }
      });
    });
  }

  private _loadModules(page: number, searchValue: string = ''): void {
    this._modulesDraftsService.getPagedModulesAndDrafts(
      page, 20, searchValue, false
    ).subscribe((response) => {
      this.modules = response.data.modules;
      this.modulesCount = response.data.itemsCount;

    });
  }
  private _loadInactiveModules(page: number, searchValue: string = ''): void {
    this._modulesDraftsService.getPagedModulesAndDrafts(
      page, 20, searchValue, true
    ).subscribe((response) => {
      this.inactiveModules = response.data.modules;
      this.inactiveModulesCount = response.data.itemsCount;

    });
  }

  private _setSearchSubscription(): void {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this._loadModules(this._modulesPage, searchValue);
    });
  }
  private _loadAllModules(){
    this._loadModules(this._modulesPage);
    this._loadInactiveModules(this._inactiveModulesPage);
  }

}
