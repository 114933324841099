<div class="inner-content" >
  <div class="header" >
    <h2>PESQUISAS NA BASE DE USUÁRIOS</h2>
  </div>
  <p class="description">Esta seção gerencia a criação de pesquisas diversas direcionadas para grupos da base de usuários. Ela não está associada
    a qualquer curso, evento ou trilha. Elas aparecem para os alunos na seção MEUS CURSOS como um novo curso.</p>
  <p class="description">A pesquisa para cálculo do indicador NPS é uma especial pesquisa na base. Ela aparece para os alunos como um pop up
      ao completarem um percentual pre estabelecido das suas atividades na plataforma.</p>

  <ng-container *ngIf="!isNps">
    <!--div class="path" >
      <h4>TESTES DE PERFIL</h4>
      <div class="actions" >
        <button class="btn-test" (click)="createNewTest()" >
          NOVA PESQUISA
        </button>
      </div>
    </div-->
    <div class="tests" >
      <app-settings-test-card
        *ngFor="let test of tests"
        (editTest)="manageTest($event)"
        (deleteTest)="deleteTest($event)"
        (getAnswersExcel)="getAnswersExcel($event)"
        [test]="test"
      ></app-settings-test-card>
    </div>

    <p *ngIf="tests && tests.length === 0" >
      Ainda não há nenhum Teste de Perfil criado.
    </p>

    <div class="center-btn" >
      <button class="btn-test" (click)="createNewTest()" >
        NOVA PESQUISA
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="isNps">
    <!--div class="path" >
      <h4>PESQUISAS DE SATISFAÇÃO</h4>
      <div class="actions" >
        <button class="btn-test" (click)="createNewResearch()" >
          NOVA PESQUISA NPS
        </button>
      </div>
    </div-->

    <div class="tests" >
      <app-settings-research-card
        *ngFor="let research of researchs"
        (viewResearch)="viewResearch($event)"
        (editResearch)="manageResearch($event)"
        (deleteResearch)="deleteResearch($event)"
        [research]="research"
      ></app-settings-research-card>
    </div>

    <p *ngIf="tests && tests.length === 0" >
      Ainda não há nenhuma pesquisa de satisfação criada.
    </p>

    <div class="center-btn" >
      <button class="btn-test" (click)="createNewResearch()" >
        NOVA PESQUISA NPS
      </button>
    </div>
  </ng-container>
</div>
