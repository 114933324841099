import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { ImportExcelFilesComponent } from 'src/app/shared/components/import-excel-files/import-excel-files.component';
import { IErroList } from 'src/app/shared/components/import-excel-files/Models/erro-list.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { SettingsEventsService } from '../../_services/events.service';
import { mandatoryCollumnEnum } from './Enum/mandatory-collumn.enum';
import { IImportUserPresence } from './Model/import-user-presence';

@Component({
    selector: 'app-import-presence',
    templateUrl: '../../../shared/components/import-excel-files/import-excel-files.component.html',
    styleUrls: ['../../../shared/components/import-excel-files/import-excel-files.component.scss']
})
export class ImportPresenceComponent extends ImportExcelFilesComponent {


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { eventId: string, scheduleId: string },
        _excelService: ExcelService,
        _snackBar: MatSnackBar,
        private _dialogRef: MatDialogRef<ImportPresenceComponent>,
        private _settingsEventsService: SettingsEventsService) {
        super(_excelService, _snackBar);
        this.startRow = 7;
        this.showDownloadTemplateButton = false;
    }

    public loading: boolean = false;
    public erroList: IErroList[];
    public name: string = 'Presença';
    public subtitle: string;
    public showRadionButtons: boolean = false;

    public userPresenceImport: IImportUserPresence[] = [];
    public pathTemplate: string;
    public downloadName: string;
    showButtonDownloadTemplate = false;

    public get mandatoryCollumns(): string[] {
        return [
            mandatoryCollumnEnum.email,
            mandatoryCollumnEnum.participantId,
        ];
    }

    public validateJson(contentJson: any, headers: any): IErroList[] {
        let index = 0;
        const erroList: IErroList[] = [];
        while (index < contentJson.length) {
            const excelContent = contentJson[index];

            if (isNil(excelContent[mandatoryCollumnEnum.email])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.email),
                    row: excelContent['row'],
                    description: 'Este campo não pode ser nulo'
                });
            }

            if (isNil(excelContent[mandatoryCollumnEnum.participantId])) {
                erroList.push({
                    collumn: this.getCollum(mandatoryCollumnEnum.participantId),
                    row: excelContent['row'],
                    description: 'Este campo não pode ser nulo'
                });
            }


            index++;
        }
        return erroList;
    }
    public setValues(contentJson: any, headers: any): void {
        let index = 0;

        while (index < contentJson.length) {
            const excelContent = contentJson[index];
            this.userPresenceImport.push({
                email: excelContent[mandatoryCollumnEnum.email],
                participationId: excelContent[mandatoryCollumnEnum.participantId],
            } as IImportUserPresence);

            index++;
        }
    }
    public save(): void {
        this._settingsEventsService.importUserPresence(this.userPresenceImport, this.data.eventId, this.data.scheduleId)
            .subscribe(_ => this._dialogRef.close(true));
    }

    public dismiss(): void {
        this._dialogRef.close();
    }

}
