<div class="container">
  <div class="loading" *ngIf="loading">
    <mat-progress-spinner [diameter]="50" mode="indeterminate"></mat-progress-spinner>
    <div class="text-loading">
      <div class="loading__letter">L</div>
      <div class="loading__letter">o</div>
      <div class="loading__letter">a</div>
      <div class="loading__letter">d</div>
      <div class="loading__letter">i</div>
      <div class="loading__letter">n</div>
      <div class="loading__letter">g</div>
      <div class="loading__letter">.</div>
      <div class="loading__letter">.</div>
      <div class="loading__letter">.</div>
    </div>
  </div>
  <div class="header">
    <h3>Upload de Banco de Questões</h3>
    <p>
      Ao importar uma nova planilha você tem a opção de substituir o banco de questões
      para este assunto ou adicionar novas perguntas. Ao substituir você estará apagando
      todo o histórico de respostas (tempo de resposta, aferição de respostas, etc.). Ao
      apagar o histórioco você nào estará afetando os alunos que já obtiveram suas conquistas.
    </p>
  </div>
  <form>
    <div class="content">

      <div class="question">
        <div class="content-ckGroup">
          <div class="ckGroup">
            <div class="checkbox" [ngClass]="{ 'checked': addQuestions }" (click)="addQuestions = true;"></div>
            <span>Adicionar Questões</span>
          </div>
          <div class="ckGroup">
            <div class="checkbox" [ngClass]="{ 'checked': !addQuestions }" (click)="addQuestions = false;"></div>
            <span>Substiuir BDQ para o Assunto</span>
          </div>
        </div>
        <div class="upload">
          <p class="file-name">
            Escolher Arquivo *
          </p>
          <button type="button" (click)="openFileUpload()">
            Upload
          </button>
          <input class="input-file" type="file" id="qdbInputFile" hidden
          (change)="setDocumentFile($event)" />
        </div>
      </div>
      <div class="display-erro" *ngIf="!loading && erroList?.length > 0">
        <div class="error-headear">
          <span class="material-icons">
            error
          </span>
          <p><strong>{{erroList?.length}}</strong> ERRO(S) ENCONTRADO(S)</p>
        </div>
        <div class="content">
          <div class="error-container" *ngFor="let error of erroList">
            <div class="error-content">
              <p *ngIf="error.collum">Coluna {{error.collum}},&nbsp;</p> <p *ngIf="error.row"> Linha {{error.row}}</p>
            </div>
            <div>{{ error?.description }}</div>
          </div>
        </div>
      </div>
      <div class="success" *ngIf="!loading && erroList?.length === 0">
        <span class="material-icons">
          check_circle
          </span>
        <p><strong>Sucesso, clique em salvar para concluir a importação</strong></p>
      </div>
      <div class="actions">
        <button class="btn-outline" *ngIf="showCloseButton" (click)="dismiss()">
          fechar
        </button>
        <button
          class="btn-test"
          (click)="save()"
          [disabled]="loading || erroList?.length > 0"
          >
          Salvar
        </button>
      </div>
    </div>
  </form>
</div>

