<div class="support-material" >
  <div>
    <h4>{{ material.title }}</h4>
    <p>{{ material.description }}</p>
  </div>
  <a [href]="linkToDownload" target="_blank"
     [saveAction]="getActionInfo('btn-download-support-material')" >
    <img src="./assets/img/download-icon.png" />
  </a>
</div>
