<div class="inner-content">
  <h2>RELATÓRIOS ANALÍTICOS</h2>
  <mat-tab-group>
    <mat-tab label="DASHBOARD">
      <ng-template matTabContent>
        <p class='title-path'>ALUNOS</p>
        <app-report-student-dashboard> </app-report-student-dashboard>
      </ng-template>
    </mat-tab>

    <mat-tab label="INFORMAÇÕES CADASTRAIS">
      <ng-template matTabContent>
        <p class='title-path'>RELATÓRIOS ENCONTRADOS</p>
        <app-report-information-registration>
        </app-report-information-registration>
      </ng-template>
    </mat-tab>

    <mat-tab  label="MÉTRICAS DO ALUNO">
      <ng-template matTabContent>
        <p class='title-path'>RELATÓRIOS ENCONTRADOS</p>
        <app-report-student-metric> </app-report-student-metric>
      </ng-template>
    </mat-tab>

    <mat-tab label="EXECUÇÃO DOS PROGRAMAS">
      <ng-template matTabContent>
        <p class='title-path'>RELATÓRIOS ENCONTRADOS</p>
        <app-report-program-execution> </app-report-program-execution>
      </ng-template>
    </mat-tab>

    <mat-tab label="OBJETOS DE APRENDIZAGEM E AVALIAÇÃO">
      <ng-template matTabContent>
        <p class='title-path'>RELATÓRIOS ENCONTRADOS</p>
        <app-report-learning-assessment-objects> </app-report-learning-assessment-objects>
      </ng-template>
    </mat-tab>

    <mat-tab label="PERFIL PROFISSIONAL">
      <ng-template matTabContent>
        <p class='title-path'>RELATÓRIOS ENCONTRADOS</p>
        <app-report-professional-profile> </app-report-professional-profile>
      </ng-template>
    </mat-tab>

    <mat-tab label="PESQUISAS">
      <ng-template matTabContent>
        <p class='title-path'>RELATÓRIOS ENCONTRADOS</p>
        <app-report-research> </app-report-research>
      </ng-template>
    </mat-tab>


  </mat-tab-group>
</div>
