<div class="inner-content">

  <header>
    <app-navigation-back></app-navigation-back>
    <div>
      <h1>CONFIGURAÇÃO TURMA</h1>
    </div>
  </header>

  <main class="main">
    <div class="header">
      <mat-form-field class="login-input">
        <input type="text" [(ngModel)]="team.name" matInput placeholder="NOME DA TURMA" required />
      </mat-form-field>
      <app-programs-select (selectionChange)="handleSelectionItemChange($event)" [value]="selectedPrograms"
        [multiple]="true">
      </app-programs-select>
    </div>

    <div class="content">
      <app-filters-team></app-filters-team>
      <app-generate-valuation-test-check-options #checkedComponent firstTitle="Usuários Encontrados"
        secondTitle="Usuários adicionados na turma" [disableSearch]="true" [dataSource]="{data: users.cachedFacts}"
        (selectedData)="handleSelectedUsers($event)">
      </app-generate-valuation-test-check-options>
    </div>
  </main>
  <div class="actions">
    <button class="btn-outline danger" (click)="cancel()">Cancelar</button>
    <button class="btn-test primary-color" (click)="openDialogImport()">
      <mat-icon aria-hidden="false" aria-label="Example home icon">save_alt</mat-icon>
      Importar usuários
    </button>
    <button class="btn-test width-larger" appDebounceClick (debounceClick)="save()" [debounceTime]="700">Salvar</button>
  </div>
</div>
