<div id="Module">
  <ng-template #EmptyValuationTemplate let-test>
    <ng-container *ngIf="!test.answered">
      <div class="empty-valuation-wrapper" *ngIf="!test.isAvailable && test.hasStarted">
        <span>{{test.title}}</span>
        <b>{{test.statusText}}</b>
      </div>
      <div class="empty-valuation-wrapper" *ngIf="!test.hasStarted">
        <span>{{test.title}}</span>
        <b>Aguardando início</b>
      </div>
    </ng-container>
  </ng-template>

  <div class="inner-content">
    <nav>
      <a href="/trilha-de-curso/{{this.trackModule}}">
        <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
        Voltar
      </a>
      <h2>{{ module?.title }}</h2>
      <div class="buttons">
        <app-favorite-button [id]="module.id" [isFavorite]="module.isFavorite" [type]="favoriteEnum.module"
          *ngIf="trackModule === ''">
        </app-favorite-button>
        <!-- <button class="btn-test" *ngIf="hasFeatureKnowMore" (click)="goToPageStoreUrl()">Saiba Mais</button> -->
        <button class="btn-test" *ngIf="canGenerateCertificate() && canGenerateCertificates"
          [disabled]="getLogicDisableButtonCertificate(moduleTestsResearch)" (click)="getCertificate()">Certificado
        </button>
        <button class="btn-test" *ngIf="module?.ecommerceUrl" (click)="goToPageEcommerceUrl()">Cadastrar
        </button>
      </div>
    </nav>

    <app-module-header [module]="module" [moduleProgress]="userProgress" [levels]="levelDict" [levelList]="levels"
      [disableGrade]="disableGrade()"></app-module-header>

    <div class="content">
      <div class="main">
        <div class="tests" *ngIf="userProgress?.isExemption">
          <div class="header module-valuation">
            <div>
              <p class="second">
                Você está isento desse módulo
              </p>
            </div>
          </div>
        </div>

        <div class="tests" *ngIf="module && (module.opinion === null || module.opinion === false)">
          <div class="header module-valuation">
            <div>
              <p class="first">
                Adoraríamos saber sua opinião
              </p>
              <p class="second">
                Como você avalia sua experiencia nesse módulo?
              </p>
            </div>
            <button class="btn-test" (click)="openOpinionDialog()">
              AVALIAR
            </button>
          </div>
        </div>

        <div id="videoContent" style="width: 100%; visibility: hidden;"></div>

        <ng-container *ngIf="module && module.videoUrl && module.videoUrl !== ''">
          <p class="title">
            <span>Introdução ao módulo</span>
            <small>{{ getVideoDurationFormatted() }} minuto(s)</small>
          </p>
        </ng-container>

        <div class="tests" *ngIf="moduleTestsBefore.length > 0">
          <div class="header">
            <p>
              Avaliação preliminar diagnóstica
            </p>
          </div>
          <div class="test-content" *ngIf="moduleTestsBeforeExpanded">
            <div class="level" *ngFor="let test of moduleTestsBefore">
              <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">

                <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                  <p *ngIf="levels">
                    {{test.title}}
                  </p>
                  <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                    class="btn-test test-disabled-button" *ngIf="test.answered"
                    (click)="viewQuestionsAndAnswersDialog(test)" [matTooltipDisabled]="canSeeAnswers()">
                    {{!canSeeAnswers() ? 'Teste Respondido' : 'Ver Respostas'}}
                  </button>
                  <button class="btn-test" *ngIf="!test.answered" (click)="goToTest(test)"
                    [attr.title]="!module.isEnrolled ? disableTestMessage : null"
                    [disabled]="!module.isEnrolled || test.answered || test.isDisabled">
                    Fazer o teste
                  </button>
                </div>
                <p class="grade" *ngIf="test.answered">
                  <span>Nota </span>
                  <span>{{calculateGrade(test)}}</span>
                </p>
              </ng-container>
              <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
              </ng-container>
            </div>
          </div>
          <div class="expand" (click)="moduleTestsBeforeExpanded = !moduleTestsBeforeExpanded"
            [ngClass]="{ 'moduleTestsBeforeExpanded' : moduleTestsBeforeExpanded }">
            <span *ngIf=!moduleTestsBeforeExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=moduleTestsBeforeExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>

        <div class="tests" *ngIf="moduleMocks.length > 0">
          <div class="header">
            <p>
              Simulados {{module.title}}
            </p>
          </div>
          <div class="test-content" *ngIf="moduleMocksBeforeExpanded">
            <div class="level" *ngFor="let test of moduleMocks">
              <div class="test-name-and-button" [style.width]="'100%'">
                <p>
                  {{test.title}}
                </p>
                <button class="btn-test" (click)="goToTest(test)">
                  Fazer o teste
                </button>
              </div>
            </div>
          </div>
          <div class="expand" (click)="moduleMocksBeforeExpanded = !moduleMocksBeforeExpanded"
            [ngClass]="{ 'moduleMocksBeforeExpanded' : moduleMocksBeforeExpanded }">
            <span *ngIf=!moduleMocksBeforeExpanded><i class="icon icon-seta_bx seta"></i></span>
            <span *ngIf=moduleMocksBeforeExpanded><i class="icon icon-seta_cima seta"></i></span>
          </div>
        </div>
        <ng-container *ngIf="!lockModule || userProgress?.isExemption">
          <div class="subjects">
            <ng-container *ngFor="let subject of module?.subjects">
              <app-module-subject [module]="module" [subjectProgress]="getProgressForSubject(subject.id)"
                [subjectId]="subject.id" [userProgress]="userProgress" [isAvailability]="isAvailability"
                [levels]="levelDict" [hasFinishedRequirements]="checkRequirements()">
              </app-module-subject>
              <div class="tests" *ngIf="getSubjectMocks(subject.id)">
                <div class="header">
                  <p>
                    Simulados {{subject.title}}
                  </p>
                </div>
                <div class="test-content" *ngIf="getSubjectMocks(subject.id).expanded">
                  <div class="level" *ngFor="let test of getSubjectMocks(subject.id).tests">
                    <div class="test-name-and-button" [style.width]="'100%'">
                      <p>
                        {{test.title}}
                      </p>
                      <button class="btn-test" (click)="goToTest(test)">
                        Fazer o teste
                      </button>
                    </div>
                  </div>
                </div>
                <div class="expand"
                  (click)="getSubjectMocks(subject.id).expanded = !getSubjectMocks(subject.id).expanded"
                  [ngClass]="{ 'moduleTestsBeforeExpanded' : getSubjectMocks(subject.id).expanded }">
                  <span *ngIf="!getSubjectMocks(subject.id).expanded"><i class="icon icon-seta_bx seta"></i></span>
                  <span *ngIf="getSubjectMocks(subject.id).expanded"><i class="icon icon-seta_cima seta"></i></span>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="tests" *ngIf="moduleTestsAfter.length > 0">
            <div class="header">
              <p>
                Avaliação final somativa
              </p>
            </div>
            <div class="test-content" *ngIf="moduleTestsAfterExpanded">
              <div class="level" *ngFor="let test of moduleTestsAfter">

                <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">
                  <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                    <p *ngIf="levels">
                      {{test.title}}
                    </p>
                    <button matTooltip="Após finalização do módulo, as respostas estarão disponíveis aqui"
                      class="btn-test test-disabled-button" *ngIf="test.answered"
                      (click)="viewQuestionsAndAnswersDialog(test)" [matTooltipDisabled]="canSeeAnswers()">
                      {{!canSeeAnswers() ? 'Teste Respondido' : 'Ver Respostas'}}
                    </button>
                    <button class="btn-test" *ngIf="!test.answered" (click)="hasTestAvailable && goToTest(test)"
                      [attr.title]="!module.isEnrolled ? disableTestMessage : null"
                      [disabled]="!module.isEnrolled || disablePercentButtonLogic(test, 1,  1) || userProgress?.isExemption || test.isDisabled">
                      {{
                        disablePercentButtonLogicText(test.answered, 1, test, 1)
                      }}
                    </button>
                  </div>
                  <p class="grade" *ngIf="test.answered">
                    <span>Nota
                    </span>
                    <span>{{calculateGrade(test)}}</span>
                  </p>
                </ng-container>
                <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
                </ng-container>
              </div>
            </div>
            <div class="expand" (click)="moduleTestsAfterExpanded = !moduleTestsAfterExpanded"
              [ngClass]="{ 'moduleTestsAfterExpanded' : moduleTestsAfterExpanded }">
              <span *ngIf=!moduleTestsAfterExpanded><i class="icon icon-seta_bx seta"></i></span>
              <span *ngIf=moduleTestsAfterExpanded><i class="icon icon-seta_cima seta"></i></span>
            </div>
          </div>



          <div class="tests" *ngIf="moduleTestsResearch.length > 0">
            <div class="header">
              <p>
                Pesquisas
              </p>
            </div>
            <div class="test-content" *ngIf="moduleTestsResearchExpanded">
              <div class="level" *ngFor="let test of moduleTestsResearch">
                <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">
                  <p *ngIf="levels">
                    {{test.title}}
                  </p>
                  <button class="btn-test" (click)="goToTest(test)"
                    [attr.title]="!module.isEnrolled ? disableTestMessage : null"
                    [disabled]="!module.isEnrolled || disablePercentButtonLogic(test, getModuleTestPercent(test),  2)">
                    {{
                    disablePercentButtonLogicText(test.answered, getModuleTestPercent(test), test, 2)
                    }}
                  </button>
                </ng-container>
                <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
                </ng-container>
              </div>
            </div>
            <div class="expandResearch" (click)="moduleTestsResearchExpanded = !moduleTestsResearchExpanded"
              [ngClass]="{ 'moduleTestsResearchExpanded' : moduleTestsResearchExpanded }">
              <span *ngIf=!moduleTestsResearchExpanded><i class="icon icon-seta_bx seta"></i></span>
              <span *ngIf=moduleTestsResearchExpanded><i class="icon icon-seta_cima seta"></i></span>
            </div>
          </div>

          <div class="tests" *ngIf="moduleCourseWork.length > 0">
            <div class="header">
              <p>
                Trabalho Final
              </p>
            </div>
            <div class="test-content" *ngIf="moduleCourseWorkExpanded">
              <div class="level" *ngFor="let test of moduleCourseWork">
                <ng-container *ngIf="test.answered || test.isAvailable && test.hasStarted;else EmptyValuation">
                  <div class="test-name-and-button" [style.width]="test.answered ? '85%' : '100%'">
                    <p *ngIf="levels">
                      {{test.title}}
                    </p>
                    <button class="btn-test" (click)="goToTest(test)"
                      [attr.title]="!module.isEnrolled ? disableTestMessage : null"
                      [disabled]="!module.isEnrolled || disablePercentButtonLogic(test, 1, 3)">
                      {{
                      disablePercentButtonLogicText(test.answered, 1, test,3 )
                      }}
                    </button>
                  </div>
                  <p class="grade" *ngIf="test.answered">
                    <span>Nota
                    </span>
                    <span>{{calculateGrade(test)}}</span>
                  </p>
                </ng-container>
                <ng-container #EmptyValuation *ngTemplateOutlet="EmptyValuationTemplate; context: {$implicit:test}">
                </ng-container>
              </div>
            </div>
            <div class="expand" (click)="moduleCourseWorkExpanded = !moduleCourseWorkExpanded"
              [ngClass]="{ 'moduleCourseWorkExpanded' : moduleCourseWorkExpanded }">
              <span *ngIf=!moduleCourseWorkExpanded><i class="icon icon-seta_bx seta"></i></span>
              <span *ngIf=moduleCourseWorkExpanded><i class="icon icon-seta_cima seta"></i></span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="module.isEnrolled && module?.requirements && module?.requirements.length > 0">
          <h3>PRÉ-REQUISITOS DESTE MÓDULO</h3>
          <app-required-module *ngFor="let requirement of module.requirements; let last = last"
            [requirement]="requirement" [levels]="levels" [last]="last"></app-required-module>
        </ng-container>
      </div>

      <app-module-sidebar [module]="module" [moduleProgress]="userProgress" [levelList]="levels"
        [forumQuestionsPreview]="forumQuestionsPreview" (reloadForumQuestionsPreview)="loadModuleForumPreview($event)">
      </app-module-sidebar>
    </div>
    <br>
  </div>
