import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { isNil } from 'lodash';
import { Sector } from 'src/app/models/sector.model';
import { Segment } from 'src/app/models/segment.model';
import { SectorService } from 'src/app/settings/_services/sector.service';
import { SegmentService } from 'src/app/settings/_services/segments.service';
import { Cascate } from 'src/app/shared/components/cascate-select/models/cascate.model';

@Component({
  selector: 'app-cascate-select',
  templateUrl: './cascate-select.component.html',
  styleUrls: ['./cascate-select.component.scss']
})
export class CascateSelectComponent implements OnInit {

  public sectors: Sector[] = [];
  public segments: Segment[] = [];

  private _selectedSector: Sector;
  @Input()
  public get selectedSector(): Sector {
    return this._selectedSector;
  }
  public set selectedSector(value: Sector) {
    if (!isNil(value)) {
      this.handleSelectSector(value);
    }
  }
  @Input() selectedSegment: Segment;

  public get GetSelectedItems() {
    return { sector: this.selectedSector, segment: this.selectedSegment };
  }

  @Output() valueChange = new EventEmitter<Cascate>();

  constructor(private _sectorService: SectorService,
    private _segmentService: SegmentService
  ) { }

  ngOnInit() {
    this.loadSector();
  }

  public emitChangeValues(value) {
    this.valueChange.emit(value);
  }

  public loadSector() {
    this._sectorService.GetAllSectors().subscribe(response => {
      this.sectors = response.data.map(value => ({
        id: value.id, name: value.name
      }));
    });
  }

  public loadSegmentBySectorId(sectorId: string) {
    this._segmentService.GetSegmentBySectorId(sectorId).subscribe(response => {
      this.segments = response.data;
    });
  }

  public handleSelectSector(sector: Sector) {
    this._selectedSector = sector;
    this.loadSegmentBySectorId(sector.id);
  }

  compareFn(t1: any, t2: any): boolean {
    return t1 && t2 ? t1.id === t2.id : t1.name === t2.name;
  }

  public selectionChange(evt: MatSelectChange) {
    this.valueChange.emit({
      sector: this.selectedSector,
      segment: this.selectedSegment,
    });
  }
}
