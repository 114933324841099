export class ERole {
  static Student = 'Student';
  static Admin = 'Admin';
  static HumanResources = 'HumanResources';
  static Secretary = 'Secretary';
  static Recruiter = 'Recruiter';
  static BusinessManager = 'BusinessManager';
  static Author = 'Author';
  static Sales = 'Sales';
}
