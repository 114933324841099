import { Component, OnInit, Inject } from '@angular/core';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// tslint:disable-next-line: max-line-length
import { ViewQuestionTextDialog } from 'src/app/pages/instructor/components/view-question-text-dialog/models/view-question-text-dialog.model';
import { Answer } from 'src/app/pages/instructor/components/view-question-text-dialog/models/answer.model';
import { sum } from 'lodash';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'app-view-question-text-dialog',
  templateUrl: './view-question-text-dialog.component.html',
  styleUrls: ['./view-question-text-dialog.component.scss']
})
export class ViewQuestionTextDialogComponent implements OnInit {

  public editor: Viewer;
  public answers: Answer[] = [];
  public totalAnswered = 0;
  public trackById = (answer: Answer) => answer.id;

  constructor(
    public dialogRef: MatDialogRef<ViewQuestionTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewQuestionTextDialog,
    private _uploadService: UploadService
  ) { }

  ngOnInit() {
    this.answers = this.data.answers;
    this.totalAnswered = sum(this.answers.map(answer => answer.totalTimesSelected));
    this._configureEditor(this.data.questionText);
  }

  public dismiss() {
    this.dialogRef.close();
  }

  private _configureEditor(text: string): void {
    this.editor = new Viewer({
      el: document.querySelector('#htmlEditor'),
    });

    this.editor.setMarkdown(text);
  }

}
