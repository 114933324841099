import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructorComponent } from './instructor.component';
import { ModuleTracksTableComponent } from './components/module-tracks-table/module-tracks-table.component';
import { ModuleTrackDetailComponent } from './components/module-track-detail/module-track-detail.component';
import { SettingsTracksModule } from 'src/app/settings/tracks/tracks.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StudentGradeModule } from 'src/app/shared/components/student-grade/student-grade.module';
import { MostWrongItemModule } from 'src/app/shared/components/most-wrong-item/most-wrong-item.module';
import { ViewQuestionTextDialogComponent } from './components/view-question-text-dialog/view-question-text-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { SettingsEventsModule } from 'src/app/settings/events/events.module';
import { HeaderModule } from 'src/app/shared/components/header/header.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
    declarations: [
        InstructorComponent,
        ModuleTracksTableComponent,
        ModuleTrackDetailComponent,
        ViewQuestionTextDialogComponent
    ],
    imports: [
        CommonModule,
        SettingsTracksModule,
        SettingsEventsModule,
        NgxDatatableModule,
        StudentGradeModule,
        MostWrongItemModule,
        MatDialogModule,
        MatIconModule,
        MatRadioModule,
        HeaderModule,
        PipesModule
    ]
})
export class InstructorModule { }
