import { EStudyPlanItem } from './enums/study-plan-item.enum';

export class StudyPlanDependencies {
    public id: string;
    public title?: string;
    public type: EStudyPlanItem;
    public completeRequirement?: boolean;

    constructor(studyPlanDependencies: StudyPlanDependencies = null) {
        if (studyPlanDependencies) Object.keys(studyPlanDependencies).forEach(key => this[key] = studyPlanDependencies[key]);
    }
}
