<div [formGroup]="formGroup" >
  <div class="info-box"
    *ngFor="let childFormGroup of formGroup.get('colleges')['controls']; let i = index;"
    formArrayName="colleges"
  >
    <ng-container [formGroupName]="i" >
      <div class="card-title" >
        <p>Instituição de Ensino </p>
        <div style="display: flex;">
          <div style="width: 100%;">
            <app-list-search
              placeholder="Nome da Instituição *"
              [inputValue]="childFormGroup.get('title')?.value"
              [noPadding]="true"
              [white]="true"
              (triggerSearch)="triggerCollegeSearch($event, childFormGroup, i)"
            ></app-list-search>
            <ul class="user-list"
              *ngIf="institutes[i] && institutes[i].length > 0"
              (clickOutside)="resetCollegeSearch(i)"
              required >
              <li *ngFor="let institute of institutes[i]"
                (click)="addCollegeIntoForm(institute, childFormGroup, i)" >
                <span>{{ institute.name }}</span>
              </li>
            </ul>
          </div>
          <button class="btn-test remove-btn"
            style="margin: auto 10px auto 0px;"
            type="button"
            (click)="removeForm('colleges', i)">
            Remover
          </button>
        </div>
      </div>
      <div class="card-info campus" >
        <p>Campus/Bairro</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="campus" 
          placeholder="Nome do Campus/Bairro"/>
        </mat-form-field>
      </div>
      <div class="two-columns">
        <div class="card-info name" >
          <p>Curso </p>
          <mat-form-field
            class="noBorder"
            floatLabel="never">
            <input matInput formControlName="name" required
            placeholder="Nome do Curso"/>
          </mat-form-field>
        </div>
        <div class="card-info academicDegree" >
          <p>Grau do curso </p>
          <mat-form-field 
            class="noBorder"
            floatLabel="never">
            <mat-select class="degree" formControlName="academicDegree" required placeholder="Grau" >
              <mat-option [value]="'Graduação'">Graduação</mat-option>
              <mat-option [value]="'Pós-Graduação'">Pós-Graduação</mat-option>
              <mat-option [value]="'Mestrado'">Mestrado</mat-option>
              <mat-option [value]="'Doutorado'">Doutorado</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="two-columns">
        <div class="card-info status" >
          <p>Situação </p>
          <mat-form-field 
            class="noBorder"
            floatLabel="never">
            <mat-select formControlName="status" required placeholder="Cursando" 
              (selectionChange)="changePeriodDisabled(childFormGroup, $event.value)">
              <mat-option [value]="'Cursando'">Cursando</mat-option>
              <mat-option [value]="'Trancado'">Trancado</mat-option>
              <mat-option [value]="'Completo'">Completo</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="card-info completePeriod" >
          <p>Período do ano de conclusão</p>
          <mat-form-field
            class="noBorder"
            floatLabel="never">
            <mat-select formControlName="completePeriod" placeholder="Semestre" >
              <mat-option [value]="1">1º Semestre</mat-option>
              <mat-option [value]="2">2º Semestre</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="two-columns">
        <div class="card-info startDate" >
          <p>Data de Entrada</p>
          <mat-form-field class="noBorder" floatLabel="never" >
            <input matInput
              placeholder="MM/YYYY"
              mask="00/0000"
              formControlName="startDate"
              required
            />
          </mat-form-field>
        </div>
        <div class="card-info endDate" >
          <p>Data de Saída/Previsão de Saída </p>
          <mat-form-field class="noBorder" floatLabel="never" >
            <input matInput
              placeholder="MM/YYYY"
              mask="00/0000"
              formControlName="endDate"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="card-info cr" >
        <p>CR Acumulado</p>
        <mat-form-field
          class="noBorder"
          floatLabel="never">
          <input matInput formControlName="cr"
          placeholder="Coeficiente de Rendimento"/>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>

<button class="btn-test add-button"
  type="button"
  (click)="addEducation.emit()" >
  + Adicionar Instituição de Ensino
</button>
