<div class="inner-content">
  <app-settings-header title="Plano de Estudos" defaultButtonName="Novo Plano de Estudos"
    serachInputPlaceholder="Digite o Nome do Plano de estudos" (searchValue)="search($event)"
    (buttonClick)="createStudyPlan()"></app-settings-header>

  <div class="main">
    <ng-container *ngIf="studyPlan">
      <app-generic-card *ngFor="let item of studyPlan" class="" [showId]="true" [hasDelete]="true"
        [data]="{id: item.id, title: item.name}" (edit)="edit(item)" (delete)="delete(item)">
        <ng-container afterTitle>
          <div class="tag">
            <div class="content">
              <p>{{item.type === EnumStudyPlan.individual ? 'individual' : 'Team'}}</p>
            </div>
          </div>

        </ng-container>
        <ng-container first>
          <div>
            <p class="p-generic-card">
              <strong>{{item.modulesCount}}</strong>
              módulo
            </p>
            <p class="p-generic-card">
              <strong>{{item.tracksCount}}</strong>
              trilha
            </p>
          </div>
        </ng-container>
      </app-generic-card>
    </ng-container>
  </div>
  <app-pagination [hidden]="itemsCount === 0" [setPagination]="{
    'itemsCount': itemsCount,
    'pageSize': pageSize
  }" (goToPage)="goToPage($event)"></app-pagination>
</div>
