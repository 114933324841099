<div class="content">
    <h5>Filtre Métricas</h5>
    <div class="items">
        <p><strong>Métrica</strong></p>
        <p><strong>Peso</strong></p>
    </div>
    <div class="items">
        <p>Prova</p>
        <div class="form-content">
            <mat-form-field class="login-input">
                <input 
                    type="number"
                    matInput 
                    (keyup)="handleFiltersChange($event.target.value, 'valuation')"
                    [value]="filters.valuation" 
                    required />
            </mat-form-field>
        </div>
    </div>
    <div class="items">
        <p>Esforço</p>
        <div class="form-content">
            <mat-form-field class="login-input">
                <input 
                    type="number"
                    matInput 
                    (keyup)="handleFiltersChange($event.target.value, 'effort')"
                    [value]="filters.effort"  required />
            </mat-form-field>
        </div>
    </div>
    <div class="items">
        <p>Engajamento</p>
        <div class="form-content">
            <mat-form-field class="login-input">
                <input 
                    type="number"
                    matInput 
                    (keyup)="handleFiltersChange($event.target.value, 'engagement')"
                    [value]="filters.engagement" required />
            </mat-form-field>
        </div>
    </div>
    <div class="items">
        <p>Aprendizagem</p>
        <div class="form-content">
            <mat-form-field class="login-input">
                <input 
                    type="number"
                    matInput 
                    (keyup)="handleFiltersChange($event.target.value, 'learning')"
                    [value]="filters.learning" required />
            </mat-form-field>
        </div>
    </div>
    <div class="items">
        <p>Evento</p>
        <div class="form-content">
            <mat-form-field class="login-input">
                <input 
                    type="number"
                    matInput 
                    (keyup)="handleFiltersChange($event.target.value, 'event')"
                    [value]="filters.event" required />
            </mat-form-field>
        </div>
    </div>

    <div class="items">
        <p>Participação</p>
        <div class="form-content">
            <mat-form-field class="login-input">
                <input 
                    type="number"
                    matInput 
                    (keyup)="handleFiltersChange($event.target.value, 'participation')"
                    [value]="filters.participation" required />
            </mat-form-field>
        </div>
    </div>

    <div class="filter-students">
        <h4>Filtre Aluno</h4>
        <app-list-search 
        [noPadding]="true"
        placeholder="Filtrar por nome..."
        (triggerSearch)="handleFiltersStudentChange($event)"
        ></app-list-search>
    </div>
</div>
