import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash';
import { ERole } from 'src/app/models/enums/role.enum';
import { Module } from 'src/app/models/module.model';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { UserProgress } from 'src/app/models/subject.model';
import { TrackPreviewProgress } from 'src/app/models/track-preview-progress.model';
import { AuthService } from '../../services/auth.service';
import { ECardType } from './Enum/card.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { EventPreview } from 'src/app/models/previews/event.interface';
import { Valuations } from 'src/app/models/my-course-valuation.model';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent {

  public cardType: ECardType;
  public readonly cardTypeEnum = ECardType;
  public isEnrolled;
  public isValuation;
  private _course: Module | TrackPreview | EventPreview | Valuations;
  @Input() hasStudyPlain: boolean = false;
  @Input()
  public get course(): Module | TrackPreview | EventPreview | Valuations {
    return this._course;
  }
  public set course(value: Module | TrackPreview | EventPreview | Valuations) {
    this.cardType = this.getCourseType(value);
    this._course = value;

    this.isValuation = this.course instanceof Valuations;
    this.isEnrolled = !(this.course instanceof Valuations) && this.course.isEnrolled;
  }
  private _progress?: UserProgress | TrackPreviewProgress;
  @Input()
  public get progress(): UserProgress | TrackPreviewProgress {
    if (this.course instanceof Module && !isNil(this._progress)) {
      this._progress['isStarted'] = (this._course as Module).isStarted;
      this._progress['isExempt'] = (this._course as Module).isExempt;
    }
    return this._progress;
  }
  public set progress(value: UserProgress | TrackPreviewProgress) {
    this._progress = value;
  }
  constructor(
    private _authService: AuthService,
    private _router: Router) { }


  public navigateToPage(event) {
    const role = this._authService.getLoggedUserRole();
    let uncompletedRequirements: any[] = [];
    if (!(this.course instanceof EventPreview) && !(this.course instanceof Valuations) && !(this.course instanceof Module) && this.course.requirement){
      uncompletedRequirements = this.course.requirement.filter(x => x.completeRequirement==false);
    }
    if (this.course instanceof EventPreview) {
      this.goToEvent();
    } else {

      if (!(this.course instanceof Valuations) && (this.course.requirement && uncompletedRequirements.length > 0 || !this.course.isEnrolled)) return;

      if (this.course instanceof Module) {
        this.goToModule(event);
      } else if (this.course instanceof Valuations) {
        this.goToValuation(event);
      } else {
        this.goToTrack(event);
      }
    }
  }

  public goToValuation(event) {
    this._router.navigate(['/teste-de-avaliacao/' + (this.course as Valuations).testId], { state: { accessByComponent: true } });
  }

  public goToTrack(event) {
    const isSeenHowManager = this._authService.isSeenHowManager();


    const createResponsibleTrackNavigation = track => {
      localStorage.setItem(LocalStorageService.key.trackResponsible, JSON.stringify(track));
      this._router.navigate(['configuracoes/trilha-de-curso/' + track.id]);
    };

    event.stopPropagation();


    if (this._authService.hasRole(ERole.Admin) || this._authService.hasRole(ERole.HumanResources)) {
      this._router.navigate(['configuracoes/trilha-de-curso/' + (this.course as TrackPreview).id]);
    } else if (isSeenHowManager) {
      createResponsibleTrackNavigation(this.course);
    } else if ((this.course as TrackPreview).subordinate || (this.course as TrackPreview).instructor) {
      createResponsibleTrackNavigation(this.course);
    } else {
      this._router.navigate(['trilha-de-curso/' + (this.course as TrackPreview).id]);
    }

  }

  public goToModule(event) {
    event.stopPropagation();
    this._router.navigate(['modulo/' + (this.course as TrackPreview).id]);
  }

  public goToEvent() {
    const event = this.course as EventPreview;
    this._router.navigate(['evento/' + event.id + '/' + event.nextSchedule.id]);
  }
  public isBlocked(){
    return (!(this.course instanceof EventPreview) && !(this.course instanceof Valuations) && !(this.course instanceof Module) && this.course.requirement && this.course.requirement.some(x => x.completeRequirement==false));
  }

  public getCourseType(value: Module | TrackPreview | EventPreview | Valuations) {
    if (value instanceof Module) {
      return ECardType.module;
    } else if (value instanceof EventPreview) {
      return ECardType.event;
    } else if (value instanceof Valuations) {
      return ECardType.valuation;
    } else {
      return ECardType.track;
    }

  }
}
