import { DataSource as DataSourceCdk } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clone, isEmpty, isNil, remove } from 'lodash';
import { UserDataSource } from 'src/app/settings/teams/team/DataSource/user.dataSource';
import { Data } from './model/data.model';


interface DataSource {
  data: Partial<Data>[];
  key?: string;
}
@Component({
  selector: 'app-generate-valuation-test-check-options',
  templateUrl: './generate-valuation-test-check-options.component.html',
  styleUrls: ['./generate-valuation-test-check-options.component.scss']
})
export class GenerateValuationTestCheckOptionsComponent {

  @Input() firstTitle: string;
  @Input() secondTitle: string;
  @Input() searchPlaceholder: string = 'Pesquise uma trilha encontrada';
  @Input() fixedSelected = false;
  @Input() disableSearch = false;
  @Input() itemsCount = 0;

  public isTree: boolean = false;
  public key: string;
  public hasInfiniteScroll: boolean = false;
  public dataSize: number;
  private _data;
  set data(value: Partial<Data>[] | UserDataSource) {
    this._setData(value);
  }
  get data() {
    return this._data;
  }

  private _dataSource: DataSource;
  @Input()
  public get dataSource(): DataSource {
    return this._dataSource;
  }
  public set dataSource(value: DataSource) {
    if (!isEmpty(value)) {
      this.data = value.data;
      this.isTree = !!value.key;
      this.key = value.key;
    }
    this._dataSource = value;
  }

  @Input() set removeSelectedId(id: string) {
    if (!isEmpty(id) && this.dataSelected.length !== 0) {
      const index = this.dataSelected.findIndex(x => x.id === id);
      if (index !== -1) {
        this.dataSelected.splice(index, 1);
      }
    }
  }

  @Input() set addSelectedItem(value: any) {
    if (!isEmpty(value)) {
      const index = this.dataSelected.findIndex(x => x.id === value.id);
      if (index === -1) {
        this.dataSelected.push(value);
      }
    }
  }

  @Output() selectedData = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  public dataSelected: any[] = [];
  public isNil = value => isNil(value);
  public get getTrackThatModuleWillBeDisplayed() { return this.dataSelected; }

  constructor(
    private _cdr: ChangeDetectorRef) { }


  public selectTrack(data: any) {
    this.dataSelected = [...this.dataSelected, data];

    if (!this.hasInfiniteScroll) {
      const index = (this.data as Data[]).findIndex(t => t.id === data.id);
      (this.data as Data[]).splice(index, 1);
    }
    this.emitDataSelected();
  }

  public selectTreeData(item: any, data: any, e) {
    e.preventDefault();

    const value = (this.hasInfiniteScroll ? (this.data as UserDataSource).cachedFacts : this.data) as Data[];

    if (this.dataSelected.some(selected => selected.id === data.id)) {
      this.dataSelected.find(t => t.id === data.id)[this.key].push(item);
      const index = data[this.key].findIndex(t => t.id === item.id);
      data[this.key].splice(index, 1);
    } else {
      const selected = clone(data);
      selected[this.key] = selected[this.key].filter(t => t.id === item.id);
      this.dataSelected.push(selected);
      const index = data[this.key].findIndex(t => t.id === item.id);
      data[this.key].splice(index, 1);
    }

    if (data[this.key].length === 0) {
      remove(value, dataSelected => dataSelected.id === data.id);
    }
  }
  public deselectData(data: any) {
    if (!this.hasInfiniteScroll) {
      data['checked'] = false;
      (this.data as Data[]).push(data);
    }
    const index = this.dataSelected.findIndex(t => t.id === data.id);
    this.dataSelected.splice(index, 1);
    this.dataSelected = [...this.dataSelected];
    this.emitDataSelected();
  }
  public deselectTreeData(item: any, data: any) {
    const value = (this.hasInfiniteScroll ? (this.data as UserDataSource).cachedFacts : this.data) as Data[];
    if (value.some(t => t.id === data.id)) {
      value.find(t => t.id === data.id)[this.key].push(item);
    } else {
      const selected = clone(data);
      selected[this.key] = selected[this.key].filter(t => t.id === item.id);
      value.push(selected);
    }
    const index = data[this.key].findIndex(t => t.id === item.id);
    data[this.key].splice(index, 1);
    if (data[this.key].length === 0) {
      const i = this.dataSelected.findIndex(t => t.id === data.id);
      this.dataSelected.splice(i, 1);
    }
  }
  public getTrackChecked(): number {
    const value = (this.hasInfiniteScroll ? (this.data as UserDataSource).cachedFacts : this.data) as Data[];
    if (value) {
      return value.filter(d => d.checked).length;
    } else {
      return 0;
    }
  }

  public selectdataChecked() {
    const value = (this.hasInfiniteScroll ? (this.data as UserDataSource).cachedFacts : this.data) as Data[];
    const dataChecked = value.filter(data => data.checked);

    if (!this.hasInfiniteScroll)
      this.data = value.filter(data => !data.checked);

    dataChecked.forEach(data => {
      data.checked = false;
    });
    this.dataSelected = [...this.dataSelected, ...dataChecked];
    this.emitDataSelected();
  }

  public selectAlldata() {
    const value = (this.hasInfiniteScroll ? (this.data as UserDataSource).cachedFacts : this.data) as Data[];
    value.forEach(data => {
      const index = this.dataSelected.findIndex(selected => selected.id === data.id);
      if (index === -1) data.checked = true;
    });
    this.emitDataSelected();
  }

  public deSelectAlldata() {
    const value = (this.hasInfiniteScroll ? (this.data as UserDataSource).cachedFacts : this.data) as Data[];
    value.forEach(data => data.checked = false);
    this.emitDataSelected();
  }

  public emitDataSelected() {
    this.selectedData.emit(this.dataSelected);
  }

  private _cloneSelectedData(dataSource: Partial<Data>[]): Partial<Data>[] {
    return dataSource.map(value => {
      if (value[this.key].some(data => data.checked)) {
        const valueClone = clone(value);
        valueClone[this.key] = valueClone[this.key].filter(data => data.checked);
        value[this.key] = value[this.key].filter(data => !data.checked);
        return valueClone;
      } else {
        return {} as Data;
      }
    });
  }

  private _setSelectedData(dataSelected: Partial<Data>[], dataValue: any): void {
    dataSelected.forEach(data => {
      const index = dataValue.findIndex(x => x.id === data.id);
      if (index !== -1) {
        dataValue.splice(index, 1);
      }
    });
  }

  private _setData(value: Partial<Data>[] | UserDataSource) {
    if (!isEmpty(value) || this.fixedSelected) {
      this._data = value;
      this.dataSize = this._data instanceof UserDataSource ? 0 : this._data.length;
      let arrValue = [];

      if (value instanceof UserDataSource) {
        arrValue = value.cachedFacts;
        this.hasInfiniteScroll = true;
      } else {
        arrValue = value;
      }

      if (!this.fixedSelected) {
        if (!this.isTree) {
          this.dataSelected = this.dataSelected.concat(arrValue.filter(data => data.checked));
          this._data = arrValue.filter(data => !data.checked && !this.dataSelected.some((elem) => elem.id == data.id));
        } else {
          this.dataSelected = this._cloneSelectedData(arrValue);
          this._data = arrValue.filter(module => module[this.key].length > 0);
          this.dataSelected = this.dataSelected.filter(module => !isEmpty(module));
        }
      } else {
        this._setSelectedData(this.dataSelected, arrValue);
      }
      if (!(value instanceof UserDataSource))
        this._data = this._data.filter(module => !module.checked);
    }
  }
}
