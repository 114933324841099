import { UtilService } from 'src/app/shared/services/util.service';
import { Component, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Event, ValuationConfiguration } from '../../../models/event.model';
import { Requirement } from '../../modules/new-module/models/new-requirement.model';
import { NewEventEventInfoComponent } from './steps/1_event-info/event-info.component';
import { CreatedEventDialogComponent } from './steps/7_created-event/created-event.dialog';
import { NewEventDateComponent } from './steps/2_date/date.component';
import { NewEventVideoComponent } from './steps/3_video/video.component';
import { NewEventSupportMaterialsComponent } from './steps/4_support-materials/support-materials.component';
import { NewEventRequirementsComponent } from './steps/5_requirements/requirements.component';
import { NewEventQuestionsComponent } from './steps/6_questions/questions.component';
import { SupportMaterial } from '../../../models/support-material.interface';
import { NotificationClass } from '../../../shared/classes/notification';
import { SettingsEventsService } from '../../_services/events.service';
import { EventSchedule } from '../../../models/event-schedule.model';
import { Level } from '../../../models/shared/level.interface';
import { SharedService } from '../../../shared/services/shared.service';
import { SettingsEventsDraftsService } from '../../_services/events-drafts.service';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { cloneDeep, has, isEqual, isNil, pickBy, forEach, isArray, merge } from 'lodash';
import { catchError } from 'rxjs/operators';
import { of, throwError, BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { RelevantDateComponent } from './steps/8_relevant-date/relevant-date/relevant-date.component';

@Component({
  selector: 'app-settings-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss']
})
export class SettingsNewEventComponent extends NotificationClass implements AfterViewInit, OnDestroy {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('eventInfo') eventInfo: NewEventEventInfoComponent;
  @ViewChild('eventDate') eventDate: NewEventDateComponent;
  @ViewChild('eventVideo') eventVideo: NewEventVideoComponent;
  @ViewChild('eventMaterials') eventMaterials: NewEventSupportMaterialsComponent;
  @ViewChild('eventRequirements') eventRequirements: NewEventRequirementsComponent;
  @ViewChild('eventQuestions') eventQuestions: NewEventQuestionsComponent;
  @ViewChild('relevantDate') relevantDate: RelevantDateComponent;

  public newEvent$: BehaviorSubject<Event> = new BehaviorSubject<Event>(new Event());
  private _newEvent: Event;
  public get newEvent(): Event { return this._newEvent; }
  public set newEvent(newEvent: Event) { this._newEvent = newEvent; }

  public levels: Array<Level> = [];
  public stepIndex: number = 0;
  public loading: boolean = false;
  public allowEditing: boolean = false;
  public showDraftOptions: boolean = false;
  private _shouldFinish: boolean = true;
  public isDraft = this._router.url.includes('rascunho');
  private _finalized: boolean;

  constructor(
    protected _snackBar: MatSnackBar,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _eventsService: SettingsEventsService,
    private _draftsService: SettingsEventsDraftsService,
    private _sharedService: SharedService,
    private _location: Location,
    public _cdr: ChangeDetectorRef,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }


  ngAfterViewInit(): void {
    this._loadLevels();
    const eventId = this._activatedRoute.snapshot.paramMap.get('eventId');
    const setEvent = (evt: Event) => {
      this.newEvent = new Event(evt);
      this.newEvent$.next(this.newEvent);
      this.allowEditing = true;
      const indexStr = localStorage.getItem('editingEventInitialIndex');
      if (indexStr) {
        const index = parseInt(indexStr, 10);
        this.stepIndex = index;
        setTimeout(() => this.stepper.selectedIndex = index, 0);
      }
    };

    if (isNil(eventId)) {
      setTimeout(() => this.newEvent = new Event(), 0);
      return;
    }

    this.isDraft ?
      this._draftsService.getEventDraftById(eventId).subscribe(response => setEvent(response.data))
      : this._eventsService.getEventById(eventId).subscribe(response => setEvent(response.data));
  }

  public saveStep(shouldContinue: boolean, foward: boolean) {
    this._finalized = !shouldContinue && !foward;
    if (shouldContinue && foward) {
      this.stepper.next();
    } else if (shouldContinue && !foward) {
      this.stepper.previous();
    } else {
      this.nextStep();
    }
  }

  public saveContent(): void {
    this._shouldFinish = this.stepIndex === 5;
    this.nextStep();
  }

  public nextStep(offset: number = 0) {
    switch (this.stepper.selectedIndex + offset) {
      case 0:
        this.eventInfo.nextStep(); break;
      case 1:
        this.eventDate.nextStep(); break;
      case 2:
        this.eventVideo.nextStep(); break;
      case 3:
        this.eventMaterials.nextStep(); break;
      case 4:
        this.eventRequirements.nextStep(); break;
      case 5:
        this.eventQuestions.nextStep(); break;
      case 6:
        this.relevantDate.nextStep(); break;
      default:
        break;
    }
  }

  public previousStep() {
    this.stepIndex--;
    this.stepper.previous();
  }

  public stepChanged(event, shouldFinish: boolean = true) {
    setTimeout(() => {
      if (event.previouslySelectedIndex < event.selectedIndex) {
        this._shouldFinish = shouldFinish;
        this.nextStep(-1);
      }
      if (this.stepIndex !== event.selectedIndex)
        this.stepIndex = event.selectedIndex;
    });
  }

  public setEventInfo(eventInfo: Event) {

    const currentEvent = cloneDeep(this.newEvent);

    this.newEvent.setEventInfo(eventInfo);

    if (!isNil(currentEvent.tutorsIds) && !isNil(this.newEvent.tutorsIds)) {
      currentEvent.tutorsIds = currentEvent.tutorsIds.sort();
      this.newEvent.tutorsIds = this.newEvent.tutorsIds.sort();
    }

    const areEventsSame = this._utilService.equalsObj(pickBy(currentEvent), pickBy(this.newEvent));

    if (areEventsSame) {
      return;
    }

    this.confirmChanges(
      currentEvent,
      this.newEvent,
      this.newEvent.id ? this._updateDraft.bind(this) : this._createEvent.bind(this)
    );
  }

  private _createEvent(eventInfo: Event) {
    this.loading = true;
    this._eventsService.manageEvent(eventInfo).subscribe((response) => {
      this.newEvent.id = response.data.id;
      this.loading = false;
      this.newEvent.ecommerceId = response.data.ecommerceId;
      this.allowEditing = true;
      this._location.replaceState(`configuracoes/evento/${this.newEvent.id}`);
      this._createDraft(this.newEvent);
    });
  }

  private _removeUnusableFieldsForComparison(originalEvent: Event): Event {

    const cloneEvent = cloneDeep(originalEvent);

    if (!cloneEvent.schedules) {
      return;
    }

    cloneEvent.schedules.forEach(sch => {
      delete sch.approvedUsersTotal;
      delete sch.finishedAt;
      delete sch.finishedBy;
      delete sch.rejectedUsersTotal;
      delete sch.usersTotal;
      delete sch.startHour;
      delete sch.eventId;

      if (sch.location) {
        delete sch.location['countryId'];
        delete sch.location['name'];
        delete sch.location['createdAt'];
        delete sch.location['createdBy'];
        delete sch.location['deletedAt'];
        delete sch.location['deletedBy'];
        delete sch.location['updatedAt'];
        delete sch.location['updatedBy'];
      } else {
        sch.location = null;
      }
    });

    if (cloneEvent.supportMaterials) {
      cloneEvent.supportMaterials.forEach((mat) => {
        delete mat.fileName;
      });
    }

    return cloneEvent;
  }


  public setEventDates(schedules: Array<EventSchedule>) {

    const currentEvent = cloneDeep(this.newEvent);
    const updateEvent = cloneDeep(this.newEvent);

    updateEvent.schedules = schedules;

    // Para efeito de comparação, criei esta função pra excluir os campos inutilizaveis pra comparação
    const currentEventComparison = this._removeUnusableFieldsForComparison(currentEvent);
    const updateEventComparison = this._removeUnusableFieldsForComparison(updateEvent);

    const areEventsSame = this._utilService.equalsObj(currentEventComparison, updateEventComparison);

    if (areEventsSame) {
      return;
    }

    const oldEvent = cloneDeep(this.newEvent);
    this.newEvent.schedules = schedules;

    this.confirmChanges(
      oldEvent,
      this.newEvent,
      this.newEvent.id ? this.updateSchedules.bind(this) : this._createEvent.bind(this)
    );
  }

  public setEventVideo(eventVideo: Event) {

    if (eventVideo.videoUrl.match('/null')) {
      return;
    }

    const currentEvent = cloneDeep(this.newEvent);
    this.newEvent.setVideoInfo(eventVideo);

    const areEventsSame = this._utilService.equalsObj(currentEvent, this.newEvent);

    if (areEventsSame) {
      return;
    }

    this.confirmChanges(
      currentEvent,
      this.newEvent,
      this.newEvent.id ? this._updateDraft.bind(this) : this._createEvent.bind(this)
    );
  }

  public addEventSupportMaterials(materials: Array<SupportMaterial>) {

    const currentEvent = cloneDeep(this.newEvent);
    const updateEvent = cloneDeep(this.newEvent);

    updateEvent.supportMaterials = materials;

    // Para efeito de comparação, criei esta função pra excluir os campos inutilizaveis pra comparação
    const currentEventComparison = this._removeUnusableFieldsForComparison(currentEvent);
    const updateEventComparison = this._removeUnusableFieldsForComparison(updateEvent);

    const areEventsSame = this._utilService.equalsObj(currentEventComparison, updateEventComparison);

    if (areEventsSame) {
      return;
    }

    const oldEvent = cloneDeep(this.newEvent);
    this.newEvent.supportMaterials = materials;

    this.confirmChanges(
      oldEvent,
      this.newEvent,
      this.newEvent.id ? this._updateSupportMaterials.bind(this) : this._createEvent.bind(this)
    );
  }

  public setRequirements(requirements: Array<Array<Requirement>>) {

    const currentEvent = cloneDeep(this.newEvent);

    currentEvent.requiredModules = this.newEvent.requirements.filter((req) => !req.optional);
    currentEvent.optionalModules = this.newEvent.requirements.filter((req) => req.optional);

    this.newEvent.requiredModules = requirements[0];
    this.newEvent.optionalModules = requirements[1];

    const areRequiredModulesSame =
      currentEvent.requiredModules.length === this.newEvent.requiredModules.length
      && this._utilService.equalsObj(currentEvent.requiredModules, this.newEvent.requiredModules);

    const areOptionalModulesSame =
      currentEvent.optionalModules.length === this.newEvent.optionalModules.length
      && this._utilService.equalsObj(currentEvent.optionalModules, this.newEvent.optionalModules);

    if (areRequiredModulesSame && areOptionalModulesSame) {
      return;
    }

    const requirmentsList = [...requirements[0], ...requirements[1]];
    requirmentsList.forEach((req) => { delete req.module; delete req.editing; });

    this.newEvent.requirements = requirmentsList;

    this.confirmChanges(
      currentEvent,
      this.newEvent,
      this.newEvent.id ? this._updateRequirements.bind(this) : this._createEvent.bind(this)
    );
  }

  public addEventQuestions(questions) {

    const currentEvent = cloneDeep(this.newEvent);
    this.newEvent.prepQuizQuestionList = questions;

    const areEventsSame = this._utilService.equalsObj(currentEvent, this.newEvent);



    this.confirmChanges(
      currentEvent,
      this.newEvent,
      this.newEvent.id ? this._updateDraft.bind(this) : this._createEvent.bind(this)
    );
  }

  public addValuationDate(valuations: ValuationConfiguration[]) {
    const currentEvent = cloneDeep(this.newEvent);
    this.newEvent.valuationConfiguration = valuations;
    const areEventsSame = this._utilService.equalsObj(currentEvent.valuationConfiguration, this.newEvent.valuationConfiguration);

    if (areEventsSame) {
      if (this._finalized) {
        this._dialog.open(CreatedEventDialogComponent);
        this._router.navigate(['configuracoes/eventos']);
      }
      return;
    }

    this.confirmChanges(
      currentEvent,
      this.newEvent,
      this.newEvent.id ? this._updateDraft.bind(this) : this._createEvent.bind(this)
    );
  }
  public publishDraftChanges(): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja publicar as alterações? O evento será substituído pela versão em rascunho.' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._draftsService.publishEventDraft(
          this.newEvent.id
        ).subscribe(() => {
          this.notify('Evento publicado com sucesso!');
          this._router.navigate(['configuracoes/eventos']);

        });
      }
    });
  }

  public rejectDraftChanges(): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '530px',
      data: { message: 'Tem certeza que deseja rejeitar as alterações em rascunho? Todas as alterações serão perdidas.' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.newEvent && !this.newEvent.id)
          return this._router.navigate(['configuracoes/eventos']);

        this._draftsService.rejectEventDraft(this.newEvent.id)
          .pipe(catchError(error =>
            isEqual(this.newEvent, this._newEvent) ? of(this.newEvent) : throwError(error)
          ))
          .subscribe(() => {
            this.notify('Alterações rejeitadas com sucesso!');
            this._router.navigate(['configuracoes/eventos']);
          });
      }
    });
  }

  private _createDraft(event: Event) {
    this.loading = true;
    this._draftsService.addNewEventDraft(event).subscribe((response) => {
      this.newEvent.id = response.data.id;
      this.loading = false;
      this.isDraft = true;
      this.allowEditing = true;
      this.newEvent.ecommerceId = response.data.ecommerceId;
      this._location.replaceState(`configuracoes/evento/${this.newEvent.id}/rascunho`);
      if (this._finalized && this.stepIndex === 5) {
        this._dialog.open(CreatedEventDialogComponent);
        this._router.navigate(['configuracoes/eventos']);
        this.notify('Modificacões Salvas em Rascunho');
      }
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  private async _createDraftPromise(event: Event): Promise<string> {
    this.loading = true;
    const response = await this._draftsService.addNewEventDraft(event).toPromise();
    this.newEvent.id = response.data.id;
    this.loading = false;
    this.isDraft = true;
    this.allowEditing = true;
    this.newEvent.ecommerceId = response.data.ecommerceId;

    return response.data.id;
  }

  private _updateDraft(event: Event, finished: boolean = false) {
    this.loading = true;
    this._draftsService.updateEventDraft(event).subscribe((response) => {
      this.newEvent.id = response.data.id;
      this.newEvent.ecommerceId = response.data.ecommerceId;
      this.eventInfo.setEcommerceId(this.newEvent.ecommerceId);

      if (this._finalized && this.stepIndex === 5) {
        this._dialog.open(CreatedEventDialogComponent);
        this._router.navigate(['configuracoes/eventos']);

      } else {
        this.newEvent.id = response.data.id;
      }
      this._shouldFinish = true;
      this.loading = false;
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  public async childUpdateSchedule(schedule: Array<EventSchedule>) {
    const index = this.newEvent.schedules.findIndex(sch => sch.id === schedule[0].id);

    if (index !== -1 && !isNil(schedule[0].id) && schedule[0].id !== '') {
      const currentSchedule = this.newEvent.schedules[index];

      this.newEvent.schedules[index] = merge(currentSchedule, schedule[0]);

    } else {
      this.newEvent.schedules.push(schedule[0]);
    }

    await this.updateSchedules(this.newEvent.schedules);
  }

  public async updateSchedules(eventOrSchedule: Event | Array<EventSchedule>) {
    const schedules = eventOrSchedule instanceof Event ? eventOrSchedule.schedules : eventOrSchedule;

    if (schedules) {
      for (let index = 0; index < schedules.length; index++) {
        if (isNil(schedules[index].eventId)) {
          schedules[index].eventId = this.newEvent.id;
        }
        const response = await this._draftsService.manageEventDraftSchedule(schedules[index]).toPromise();
        schedules[index].id = response.data.id;
      }
      this.newEvent.schedules = cloneDeep(schedules);
      this.nextState(this.newEvent);
      this.isDraft = true;
    }
    this.notify('Modificacões Salvas em Rascunho');
  }

  public async deleteSchedule(schedule: EventSchedule) {
    let eventId = schedule.eventId;
    if (!this.isDraft) {
      this.newEvent.schedules = this.newEvent.schedules.filter(sch => sch.id !== schedule.id);
      eventId = await this._createDraftPromise(this.newEvent);
      this.newEvent.id = eventId;
      this.nextState(this.newEvent);
      return;
    }

    await this._draftsService.deleteSchedule({ eventId: eventId, scheduleId: schedule.id }).toPromise();

    this.newEvent.schedules = this.newEvent.schedules.filter(sch => sch.id !== schedule.id);

    this.nextState(this.newEvent);
  }

  private _updateSupportMaterials(event: Event) {
    this.loading = true;
    this._draftsService.manageEventDraftSupportMaterials(event.id, event.supportMaterials).subscribe(() => {
      this._shouldFinish ?
        this._updateFooter() :
        this._shouldFinish = true;
      this.loading = false;
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  private _updateRequirements(event: Event) {
    this.loading = true;
    this._draftsService.manageEventDraftRequirements(event.id, event.requirements).subscribe(() => {
      this._shouldFinish ?
        this._updateFooter() :
        this._shouldFinish = true;
      this.loading = false;
      this.notify('Modificacões Salvas em Rascunho');
    });
  }

  private _updateFooter() {
    this.stepIndex++;
    this.stepper.next();
  }

  private _loadLevels(): void {
    this._sharedService.getLevels().subscribe((response) => {
      this.levels = response.data;
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('editingEventInitialIndex');
  }

  public confirmChanges(event: Event, eventUpdate: Event, callBack: Function = null): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Deseja salvar alterações?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.isDraft) {
          callBack(eventUpdate);
        } else {
          if (eventUpdate.id) {
            this._createDraft(eventUpdate);
            this.nextState(eventUpdate);
          } else {
            this.nextState(eventUpdate);
            callBack(eventUpdate);
          }
        }
      } else {
        this.nextState(event);
      }
    });
  }

  public nextState(event: Event) {
    this.newEvent$.next(event);
    this._newEvent = this.newEvent$.getValue();
    this._cdr.detectChanges();
  }
}
