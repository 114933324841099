import { Component, Output, EventEmitter, OnInit, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { environment } from 'src/environments/environment';
import { UserNotification } from 'src/app/models/notification.interface';
import { Router, NavigationStart } from '@angular/router';
import { UserService } from 'src/app/pages/_services/user.service';
import { filter } from 'rxjs/operators';
import { ERole } from 'src/app/models/enums/role.enum';
import { EAccess } from 'src/app/models/enums/acccess.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-toolbar-header',
  templateUrl: './toolbar-header.component.html',
  styleUrls: ['./toolbar-header.component.scss']
})
export class ToolbarHeaderComponent implements OnInit, OnDestroy {

  @Output()
  public OpenMenu: EventEmitter<any> = new EventEmitter();

  public isLoggedIn: boolean;
  public isImpersonating: boolean = false;
  public user: any;
  public logo = environment.logo;

  public showLoader: boolean = false;
  public toggleNotifications: boolean = false;
  public toggleSeeHow: boolean = false;
  public toggleSeeHowClick: boolean = false;
  public notifications: Array<UserNotification> = [];
  public types = [];

  private get _isSeeHow() {
    return this._authService.getUserRoleToSeeHow() ||
      this._authService.isSeenHowManager() ||
      this._authService.hasSelectedAccess(EAccess.InstructorModule) ||
      this._authService.hasSelectedAccess(EAccess.InstructorEvent);
  }

  private _types = [
    { name: 'Aluno', action: () => this._seeHow(ERole.Student, true), visible: () => this._canSeeHow() },
    { name: 'Secretaria', action: () => this._seeHow(ERole.Secretary, true), visible: () => this._canSeeHow() },
    { name: 'RH', action: () => this._seeHow(ERole.HumanResources, true), visible: () => this._canSeeHow() },
    { name: 'Autor', action: () => this._seeHow(ERole.Author, true), visible: () => this._canSeeHow() },
    { name: 'Gestor', action: () => this._seeHowManager(EAccess.LineManager), visible: () => this._canSeeHowManager(EAccess.LineManager) },
    { name: 'Vendas', action: () => this._seeHowSales(EAccess.Sales), visible: () => this._canSeeHowSales(EAccess.Sales) },
    {
      name: 'Instrutor de módulo', action: () => this._seeHowInstrutorOrTutor(EAccess.InstructorModule),
      visible: () => this._canSeeHowInstrutorOrTutor(EAccess.InstructorModule)
    },
    {
      name: 'Instrutor de evento', action: () => this._seeHowInstrutorOrTutor(EAccess.InstructorEvent),
      visible: () => this._canSeeHowInstrutorOrTutor(EAccess.InstructorEvent)
    },
  ];

  private _loaderSubscription: Subscription;

  @ViewChild('seeHowDiv') seeHowDiv: ElementRef;
  constructor(
    private _authService: AuthService,
    private _sharedService: SharedService,
    private _userService: UserService,
    private _router: Router
  ) {

    _authService.isLoggedIn().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.user = _authService.getLoggedUser();

      this.logo = './assets/img/logo-' + environment.logo;

      if (this.isLoggedIn) {
        this._loadNotifications();

        this.logo = localStorage.getItem(LocalStorageService.key.logoUrl)
          ? localStorage.getItem(LocalStorageService.key.logoUrl)
          : './assets/img/logo-' + environment.logo;
      }

      if (this._authService.getImpersonatedUser()) {
        this.isImpersonating = true;
      }

      this.types = this._types.filter(type => type.visible());
    });
  }

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    if (this.toggleSeeHow) {
      const clickedInside = this.seeHowDiv && this.seeHowDiv.nativeElement.contains(targetElement);
      if (!clickedInside) {
        if (this.toggleSeeHowClick) {
          this.toggleSeeHowClick = false;
        } else {
          this.toggleSeeHow = false;
        }
      }
    }
  }

  ngOnInit() {
    this._loaderSubscription = this._sharedService.getLoaderSubject().subscribe(
      (value: boolean) => this.showLoader = value
    );

    this._router.events
      .pipe(
        filter(_ => this._authService.isLoggedIn({ getRawValue: true })),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(event => {
        this._loadNotifications();
      });

  }

  ngOnDestroy() {
    this._loaderSubscription.unsubscribe();
  }

  public open() {
    this.OpenMenu.emit();
  }

  public logout() {
    this._router.navigate([''])
      .then(() => {
        this._authService.logout();
        this.logo = './assets/img/logo-' + environment.logo;
        this.isImpersonating = false;
      });
  }

  public impersonateLogout() {
    if (this._router.url === '/home') {      
      this._authService.clearImpersonationInfo();
      location.reload();
    } else {
      this._router.navigate(['home']).then(() => {        
        this._authService.clearImpersonationInfo();
        location.reload();
      });
    }
  }

  public getSeeHowImgSrc(): string {
    return this._getImageToSeeHow(this._isSeeHow);
  }

  public getNotificationImgSrc(): string {
    return this.hasNotification() ?
      './assets/img/notification-empty.png' :
      './assets/img/notification.png';
  }

  public hasNotification(): boolean {
    return this.notifications && this.notifications.every(n => n.read);
  }

  public goToNotification(notification: UserNotification): void {
    if (!notification.read)
      this._setNotificationRead(notification);

    this._router.navigate([notification.redirectPath]);
    this.toggleNotifications = false;
  }

  public goTouserInfo() {
    const role = this._authService.getLoggedUserRole();
    if (role === ERole.Recruiter || role === ERole.BusinessManager) {
      this._router.navigate(['/configuracoes/identificacao-empresa']);
    } else {
      this._router.navigate(['/configuracoes/detalhes-usuario/' + this.user.user_id]);
    }
  }

  public toggleSeeHowAction() {
    this.toggleNotifications = false;
    if (this._isSeeHow) {
      this._authService.restoreOriginalAccessAndRoles();
      this._reloadToHome();
    } else {
      this.toggleSeeHow = !this.toggleSeeHow;
      this.toggleSeeHowClick = true;
    }
  }

  private _loadNotifications(): void {
    this._sharedService.getNotifications().subscribe((response) => this.notifications = response.data);
  }

  private _setNotificationRead(notification: UserNotification): void {
    notification.read = true;
    this._sharedService.manageNotification(
      notification.id, true
    ).subscribe();
  }

  private _reloadToHome() {
    if (this._router.url === '/home') {
      location.reload();
    } else {
      this._router.navigate(['home']).then(() => {
        location.reload();
      });
    }
  }

  private _getImageToSeeHow(condition) {
    return condition ?
      './assets/img/shared-doc.png'
      : './assets/img/shared-content.png';
  }

  private _canSeeHowManager(access: EAccess.LineManager | EAccess.TrackManager): boolean {
    return this._hasAccess(access) || this._authService.isSeenHowManager();
  }

  private _canSeeHowInstrutorOrTutor(access: EAccess.InstructorEvent | EAccess.InstructorModule): boolean {
    return this._hasAccess(access) || this._authService.hasSelectedAccess(access);
  }

  private _hasAccess(access: EAccess): boolean {
    return this._authService.hasAccess(access);
  }

  private _seeHowManager(access: EAccess.LineManager | EAccess.TrackManager) {
    const condition = this._authService.isSeenHowManager();
    this._toogleAccessPermission(condition, access);
  }

  private _seeHowInstrutorOrTutor(access: EAccess) {
    const condition = this._authService.hasSelectedAccess(access);
    this._toogleAccessPermission(condition, access);
  }

  private _toogleAccessPermission(conditionToRestoreOriginalAccessAndRoles: boolean, access: EAccess) {
    if (conditionToRestoreOriginalAccessAndRoles) {
      this._authService.restoreOriginalAccessAndRoles();
    } else {
      this._authService.setAccess(access);
      this._reloadToHome();
    }
  }

  private _canSeeHowSales(salesType: EAccess.Sales): boolean {
    return this.user && (
      this.user.role === ERole.Student &&
      this.user.adminAccesses &&
      (this.user.adminAccesses instanceof Array) && this.user.adminAccesses.some(access => access === salesType)) ||
      this._authService.isSeenHowSales();
  }

  private _seeHowSales(role: EAccess.Sales) {
    if (this._authService.isSeenHowSales()) {
      this._authService.restoreOriginalAccessAndRoles();
    } else {
      this._authService.setAccess(role);
      this._reloadToHome();
    }
  }

  private _canSeeHow(role: ERole = this._authService.getLoggedUserRole()): boolean {
    if (!this.user) return false;
    const originalRole = (this._authService.getOriginalRole() || this.user.role);
    return originalRole === ERole.Admin || originalRole !== role;
  }

  private _seeHow(role: ERole, needReload = false): void {

    const seeHow$ = (callback) => this._userService.seeHow(role)
      .subscribe(() => {
        callback();
        if (needReload) this._reloadToHome();
      }, () => this._authService.restoreOriginalAccessAndRoles());

    this._hasSeeHowApplied() ?
      seeHow$(() => this._authService.restoreOriginalAccessAndRoles())
      : seeHow$(() => this._authService.setUserRoleToSeeHow(role));

  }

  private _hasSeeHowApplied(): boolean {
    return !!this._authService.getUserRoleToSeeHow();
  }

  public navigateTo() {
    if (environment.features.navigateExternatUrl && this._router.url === '/' && environment.externatUrl !== '') {
      window.open(environment.externatUrl);
    } else {
      this._router.navigate(['/']);
    }

  }

}
