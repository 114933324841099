import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable, of } from 'rxjs';
import { AutomaticSync, RuleItemTypeEnum } from 'src/app/models/automatic-sync.model';

@Injectable()
export class AutomaticSyncService {
  constructor(private _httpService: BackendService) { }

  public getAutomaticSyncs(page: number, pageSize: number): Observable<any> {
    return this._httpService.get('getAutomaticSyncs', [], [
      { 'name': 'page', 'value': page.toString() },
      { 'name': 'pageSize', 'value': pageSize.toString() }
    ]);
  }

  public getPagedFilteredRelationalItens(
    page?: number,
    pageSize?: number,
    searchValue?: string,
    type?: RuleItemTypeEnum
  ): Observable<any> {
    return this._httpService.post('getPagedRelationalItens', {
      page: page,
      pageSize: pageSize,
      filters: {
        name: searchValue,
        type: type
      }
    });
  }

  public updateAutomaticSync(automaticSync: AutomaticSync): Observable<any> {
    return this._httpService.post('updateAutomaticSync', {
      'automaticSync': automaticSync
    });
  }

  public deleteAutomaticSync(automaticSyncId: string): Observable<any> {
    return this._httpService.delete('deleteAutomaticSync', [
      { 'name': 'id', 'value': automaticSyncId }
    ]);
  }

  public automaticSyncs(): Observable<any> {
    return this._httpService.get('automaticSyncs', [], []);
  }
}
