import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy, ElementRef, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableColumn, SelectionType, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { Student } from 'src/app/shared/dialogs/users/models/student.model';
import { keys, isNil, flatMap, uniq } from 'lodash';

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss']
})
export class UsersDialogComponent extends NotificationClass implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('Table', { static: true }) private _table: DatatableComponent;

  public imagePlaceholder = './assets/img/user-image-placeholder.png';
  public loader = true;
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
  public messages = {
    emptyMessage: 'Não há dados para exibir', totalMessage: 'Total', selectedMessag: 'selecionado',
  };
  public columns: TableColumn[] = [
    , , { prop: 'name', name: 'Nome' }
    , { prop: 'userName', name: 'Nome de usuário' }
    , { prop: 'email', name: 'Email' }
    , { prop: 'businessGroup', name: 'BusinessGroup' }
    , { prop: 'businessUnit', name: 'BusinessUnit' }
    , { prop: 'segment', name: 'Segment' }
  ];
  public selectedRows: [] = [];

  private _temp = [];
  private _searchSubject: Subject<string> = new Subject();
  public rowIdentity = (row) => row.id;
  public rowHeight = (row) => row.height = 45;
  public existProp = (column: TableColumn) => isNil(column) ? false :
    uniq(flatMap(this.data.map(keys))).some(prop => prop === column.prop);

  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<Student>,
  ) {
    super(_snackBar);
    this._temp = [...data || []];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._table.recalculate();
      this.loader = false;
    }, 1000);
  }

  ngOnInit() {
    this._setSearchSubscription();
  }

  ngOnDestroy(): void {
    if (this._searchSubject)
      this._searchSubject.unsubscribe();
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue.toLowerCase());
  }

  private _setSearchSubscription() {
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this._updateFilter(searchValue);
    });
  }

  public dismiss() {
    this.dialogRef.close();
  }

  public exportData() {
    this.dialogRef.close(this.selectedRows.length > 0 ? this.selectedRows : this.data);
  }

  private _updateFilter(name: string) {
    const temp = this._temp.filter((row: any) => row.name.toLowerCase().indexOf(name) !== -1 || !name) || [];
    this.data = temp as any;
    this._table.offset = 0;
  }

}
