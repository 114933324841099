import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { isNil } from 'lodash';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const access = localStorage.getItem(LocalStorageService.key.selectedAccess);

    if (!isNil(access)) {
      request = request.clone({
        setHeaders: { 'SelectedAccess': access }
      });
    }

    return next.handle(request);
  }


}
