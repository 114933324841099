<div class="main" [ngStyle]="{'height': !isValuation ? '300px' : '220px'}">
  <div class="blocked" *ngIf="isBlocked()">
    <img class="lock" src="./../../../../assets/img/Icon ionic-ios-lock.svg">
    <div class="blocked-content">
      <p>Desbloqueie {{cardType === cardTypeEnum.module ? 'esse módulo' : 'essa trilha' }} ao completar</p>
      <p *ngFor="let requirement of course.requirement">"{{requirement.title}}"</p>
    </div>
  </div>
  <div class="content-wrapper" (click)="navigateToPage($event)">
    <div class="header">
      <app-header [type]="cardType" [progress]="progress" [isEnrolled]="isEnrolled"></app-header>
    </div>
    <div class="title">
      {{course.title}}
    </div>
    <div class="info">
      <app-info [course]="course"></app-info>
    </div>
    <ng-container [ngSwitch]="cardType">
      <div *ngSwitchCase="cardTypeEnum.valuation">
        <div class="item-link">
          <div class="label">Ir para avaliação</div>
          <div class="arrow">
            <mat-icon>navigate_next</mat-icon>
          </div>
        </div>
      </div>
      <div class="progress-content" *ngSwitchDefault>
        <div>
          <app-progress-bar [currentProgress]="progress?.progress * 100" [height]="25"
            *ngIf="cardType !== cardTypeEnum.event"></app-progress-bar>
        </div>
        <span>{{(progress?.progress * 100 | number : '1.0-0') || 0}}%</span>
      </div>
    </ng-container>
  </div>
</div>
