<div class="inner-content">

  <h2>MEUS CURSOS E AVALIAÇÕES</h2>
  <app-my-courses-search></app-my-courses-search>

  <div class="flex filter" (click)="openFilter()" *ngIf="hasHomeV2 && isStudentView">
    <b>Filtros</b>
    <mat-icon aria-hidden="false" aria-label="Example home icon">tune</mat-icon>
  </div>
  <div class="content-filter" *ngIf="canOpenFilter">
    <div>
      <div class="title">
        <p>Tipo</p>
      </div>
      <div class="section-filters">
        <section class="example-section" *ngFor="let filter of filters">
          <span class="sector-content">
            <mat-checkbox class="example-margin" [checked]="filter.checked" [indeterminate]="someComplete(filter)"
              (change)="setSector(filter, $event.checked)">
              {{filter.sectorName}}
            </mat-checkbox>
            <mat-icon [ngClass]="{'opened': filter.opened}" (click)="openFilterLevel(filter)">arrow_drop_down</mat-icon>
          </span>
          <span class="example-list-section" *ngIf="filter.opened">
            <ul class="sub-filter">
              <li *ngFor="let segment of filter.segments">
                <mat-checkbox [(ngModel)]="segment.checked" (ngModelChange)="updateSector(filter)">
                  {{segment.segmentName}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
      </div>
    </div>
    <div>
      <div class="title">
        <p>Ordenação</p>
      </div>
      <div class="sort-selection">
        <p id = "alphabetic" (click)="sort(EnumRequesrtSort.alphabetic)">Ordem alfabética</p>
        <p id = "dueDate" (click)="sort(EnumRequesrtSort.dueDate)">Data de vencimento</p>
        <p id = "progress" (click)="sort(EnumRequesrtSort.progress)">Mais próximos de completar</p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="valuationPeding && valuationPeding.length > 0">

    <ng-container *ngIf="hasHomeV2">
      <app-my-course-sectors title="AVALIAÇÕES PENDENTES" [coursers]="valuationPeding" [itemsCount]="1" [pageSize]="1"
        (goToPage)="goToPage($event)">
      </app-my-course-sectors>
    </ng-container>

    <ng-container *ngIf="!hasHomeV2">
      <div class="path">
        <h4 *ngIf="!isManagerView">AVALIAÇÕES PENDENTES</h4>
        <h4 *ngIf="isManagerView">{{ replacement.track.plural | uppercase }}</h4>
      </div>

      <div class="suggested ">
        <div class="valuations-item" (click)="goToValuation(valuations)" *ngFor="let valuations of valuationPeding ">
          <p>{{valuations.title}}</p>
        </div>
      </div>
    </ng-container>

  </ng-container>

  <app-management-student-courses *ngIf="!isStudentView || !hasHomeV2"></app-management-student-courses>

  <mat-tab-group mat-stretch-tabs mat-align-tabs="center" *ngIf="isStudentView && hasHomeV2">
    <mat-tab label="PLANO DE ESTUDOS">
      <ng-template matTabContent>
        <app-study-plan></app-study-plan>
      </ng-template>
    </mat-tab>
    <mat-tab label="MINHA VISÃO">
      <ng-template matTabContent>
        <app-my-vision></app-my-vision>
      </ng-template>
    </mat-tab>
    <mat-tab label="TODOS OS CURSOS">
      <ng-template matTabContent>
        <app-all-courses></app-all-courses>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="programs && programs.length > 0 && hasHomeV2">
    <div class="path">
      <h4 *ngIf="!isManagerView">{{ (replacement.program.plural | replacement:'meus') | uppercase }}</h4>
      <h4 *ngIf="isManagerView">{{ replacement.program.plural | uppercase }} </h4>
      <div class="all-modules">
        <span (click)="changeViewTypePrograms()"><i class="icon visao-icon icon-visao_lista"></i></span>
      </div>
    </div>
    <div class="suggested" [ngClass]="{ 'list': viewTypePrograms === 'list' }">
      <app-program-card *ngFor="let program of programs" [program]="program">
      </app-program-card>
    </div>

    <app-pagination *ngIf="programsCount" [setPagination]="{ 'itemsCount': programsCount, 'pageSize': 8 }"
      (goToPage)="goToPageProgram($event)">
    </app-pagination>
  </ng-container>


  <ng-container *ngIf="events && events.length > 0">
    <app-my-course-sectors title="Eventos" type="" [coursers]="events" [itemsCount]="events.length"
      [pageSize]="events.length">
    </app-my-course-sectors>
  </ng-container>


</div>
