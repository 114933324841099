<div class="inner-content">
    <div class="filter-container">
        <app-list-search
            placeholder="Pesquise..."
            (triggerSearch)="triggerTagSearch($event)"
        ></app-list-search>
        <div class="filters">
            <div class="form-content">
                <label>Modulo</label>
                <!-- Encontrar uma outra solução para o placeholder -->
                <mat-form-field>
                    <mat-select
                        panelClass="cockpit-mat-panel"
                        [(value)]="selectedModule"
                        [placeholder]="selectedModule?.title"
                        (selectionChange)="handleSelectModuleFilterChange($event.value)"
                        (openedChange)="openedChange($event)"
                     >
                        <input class="input-search" 
                            id="input-search"
                            placeholder ="Pesquise...." 
                            (keyup)="onKey($event.target.value)" 
                            (keydown)="$event.stopPropagation()"
                        > 
                        <mat-option 
                            style="color: black; width: 600px !important;" 
                            [attr.title]="'Todos'"
                            [value]="''"
                        >
                            Todos
                        </mat-option>
                        <mat-option 
                            *ngFor="let module of modules"
                            style="color: black; width: 600px !important;" 
                            [attr.title]="module.title"
                            [value]="module"
                        >
                            {{module.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-content">
                <label>Assunto</label>
                <mat-form-field>
                    <mat-select 
                    [(ngModel)]="selectedSubject" 
                    (selectionChange)="handleSelectSubjectsFilterChange($event.value)" 
                    [value]="selectedSubject" >
                        <ng-container *ngIf="selectedModule" >
                            <mat-option [value]="-1">Todos</mat-option>
                            <mat-option 
                                *ngFor="let subject of selectedModule.subjects" 
                                style="color: black" 
                                [value]="subject"
                            >
                                {{subject.title}}
                            </mat-option>
                        </ng-container>  
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <!--div class="custom-table">
        <div class="custom-table-header">
            <p class="out-table-th first" style="width: 30%">Modulo</p>
            <p class="out-table-th first" style="width: 30%">Assunto</p>
            <p class="out-table-th first" style="width: 30%">Conteudo</p>
            <p class="out-table-th last" style="width: 10%"></p>
        </div>
        <div class="custom-table-body">
            <ng-container *ngFor="let row of moduleTags">
                <div class="custom-table-body-row">
                    <p class="out-table-td first" style="width:30%">
                        {{row.module.title}}
                    </p>
                    <p class="out-table-td first" style="width:30%">
                        {{row.subject.title}}
                    </p>
                    <p class="out-table-td first" style="width:30%">
                        {{row.content.title}}
                    </p>
                    <p class="out-table-td last" style="width:10%">
                        <span *ngIf="row.expanded === undefinded || row.expanded === null || row.expanded === false" class="icon-custom icon-custom-expand not-expanded" (click)="row.expanded = true;"></span>
                        <span *ngIf="row.expanded === true" class="icon-custom icon-custom-expand" (click)="row.expanded = false;"></span>
                    </p>
                </div>
                <ng-container *ngIf="row.expanded === true">
                    <div class="inner-table">
                        <div class="inner-table-header">
                            <p class="inner-table-th first" style="width: 20%">Modulo</p>
                            <p class="inner-table-th" style="width: 20%">Assunto</p>
                            <p class="inner-table-th" style="width: 20%">Conteudo</p>
                            <p class="inner-table-th" style="width: 5%">
                                <img
                                    class="icon"
                                    src="./assets/img/like.png"
                                    title="Avaliações positivas"
                                />
                            </p>
                            <p class="inner-table-th" style="width: 5%">
                                <img
                                    class="icon"
                                    style="transform: rotate(180deg);"
                                    src="./assets/img/like.png"
                                    title="Avaliações negativas"
                                />
                            </p>
                            <p class="inner-table-th" style="width: 10%">Status</p>
                            <p class="inner-table-th" style="width: 10%">Aprovação</p>
                            <p class="inner-table-th last" style="width: 5%"></p>
                        </div>
                        <div class="inner-table-body">
                            <ng-container *ngFor="let row2 of moduleTags">
                                <div class="inner-table-row">
                                    <p class="inner-table-td first" style="width: 20%">
                                        {{row2.module.title}}
                                    </p>
                                    <p class="inner-table-td" style="width: 20%">
                                        {{row2.subject.title}}
                                    </p>
                                    <p class="inner-table-td" style="width: 20%">
                                        {{row2.content.title}}
                                    </p>
                                    <p class="inner-table-td" style="width: 5%">
                                        {{getModuleTagPositive(row2)}}
                                    </p>
                                    <p class="inner-table-td" style="width: 5%">
                                        {{getModuleTagNegative(row2)}}
                                    </p>
                                    <p class="inner-table-td" style="width: 10%">
                                        {{getModuleTagStatus(row2)}}
                                    </p>
                                    <p class="inner-table-td" style="width: 10%">
                                        <span class="approval-container">
                                            <img
                                                class="icon left-icon icon-click"
                                                src="./assets/img/right-answer-colored.png"
                                                title="Aprovar"
                                                (click)="changeModuleTagStatus(row2, 2)"
                                            />
                                            <img
                                                class="icon icon-click"
                                                src="./assets/img/wrong-answer-colored.png"
                                                title="Reprovar"
                                                (click)="changeModuleTagStatus(row2, 3)"
                                            />
                                        </span>
                                    </p>
                                    <p class="inner-table-td" style="width: 5%">
                                        <img
                                            class="icon icon-click"
                                            src="./assets/img/view.png"
                                            title="Aprovar"
                                            (click)="moduleTagDialog(row2)"
                                        />
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div-->
    
    <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="moduleTags" class="mat-elevation-z8 table-top">

        <ng-container matColumnDef="module">
            <th width="20%" mat-header-cell *matHeaderCellDef>Modulo</th>
            <td width="20%" mat-cell *matCellDef="let row">
                {{row.module ? row.module.title : '--'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="subject">
            <th width="15%" mat-header-cell *matHeaderCellDef>Assunto</th>
            <td width="15%" mat-cell *matCellDef="let row">
                {{row.subject ? row.subject.title : '--'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="content">
            <th width="15%" mat-header-cell *matHeaderCellDef>Conteudo</th>
            <td width="15%" mat-cell *matCellDef="let row">
                {{row.content ? row.content.title : '--'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="concept">
            <th width="10%" mat-header-cell *matHeaderCellDef>Conceito</th>
            <td width="10%" mat-cell *matCellDef="let row">
                {{row.moduleTag.concept.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="positive">
            <th width="5%" mat-sort-header="positive" mat-header-cell *matHeaderCellDef>
                <img
                    class="icon"
                    src="./assets/img/like.png"
                    title="Avaliações positivas"
                />
            </th>
            <td width="5%" mat-cell *matCellDef="let row">
                {{row.moduleTag.likes}}
            </td>
        </ng-container>

        <ng-container matColumnDef="negative">
            <th width="5%" mat-sort-header="negative" mat-header-cell *matHeaderCellDef>
                <img
                    class="icon"
                    style="transform: rotate(180deg);"
                    src="./assets/img/like.png"
                    title="Avaliações negativas"
                />
            </th>
            <td width="5%" mat-cell *matCellDef="let row">
                {{row.moduleTag.dislikes}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th width="10%" mat-header-cell *matHeaderCellDef>Status</th>
            <td width="10%" mat-cell *matCellDef="let row">
                <p class="status" [ngStyle]="{'color': getModuleTagStatusColor(row)}">
                    {{getModuleTagStatus(row)}}
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="approval">
            <th width="10%" mat-header-cell *matHeaderCellDef>Aprovação</th>
            <td width="10%" mat-cell *matCellDef="let row">
                <div class="approval-container">
                    <img
                        class="icon left-icon icon-click"
                        src="./assets/img/right-answer-colored.png"
                        title="Aprovar"
                        (click)="changeModuleTagStatus(row, 2)"
                    />
                    <img
                        class="icon icon-click"
                        src="./assets/img/wrong-answer-colored.png"
                        title="Reprovar"
                        (click)="changeModuleTagStatus(row, 3)"
                    />
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th width="5%" mat-header-cell *matHeaderCellDef></th>
            <td width="5%" mat-cell *matCellDef="let row">
                <img
                    class="icon icon-click"
                    src="./assets/img/view.png"
                    title="Aprovar"
                    (click)="openTagDialog(row)"
                />
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <app-pagination *ngIf="moduleTagsCount"
        [setPagination]="{
            'itemsCount': moduleTagsCount,
            'pageSize': pageSize
        }"
        (goToPage)="goToPage($event)">
    </app-pagination>
</div>