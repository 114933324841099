import { Pipe, PipeTransform } from '@angular/core';
import { QuestionAndAnswer, ValuationTestQuestionType } from '../models/question';

@Pipe({ name: 'questionGrade' })
export class QuestionGradePipe implements PipeTransform {

  transform(question: QuestionAndAnswer) {
    if (
        question.type === ValuationTestQuestionType.Discursive &&
        !question.grade
      ) {
        return 'Aguardando nota ';
      } else {
        return (
          (question.grade / 10).toFixed(2).replace('.', ',') +
          ' / ' +
          (question.percentage / 10).toFixed(2).replace('.', ',')
        );
      }
  }

}
