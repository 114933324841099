<div class="content">
  <p class="title">METRICA DE EMPENHO</p>

  <app-track-effort-progress-chart [effortMetric]="effortMetric" class="chart"></app-track-effort-progress-chart>

  <app-track-effort-progress-details title="engajamento" class="diagnostic">
    <ng-container first>
      <p class="items-title cyan">Meu Progresso</p>
      <p class="items-subtitle">{{effortMetric?.studentProgressUntilDay?.toFixed(1)}}%</p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Progresso Turma</p>
      <p class="items-subtitle">{{effortMetric?.classProgressUntilDay?.toFixed(1)}}%</p>
    </ng-container>
    <ng-container third>
      <p class="items-title dark-blue">Progresso Esperado</p>
      <p class="items-subtitle">{{effortMetric?.expectedProgressUntilDay?.toFixed(1)}}%</p>
    </ng-container>
  </app-track-effort-progress-details>

  <app-track-effort-progress-details title="participação" class="events">
      <ng-container first>
          <p class="items-title cyan">Minha Part.</p>
          <p class="items-subtitle">{{effortMetric?.studentParticipationUntilDay?.toFixed(1)}}%</p>
      </ng-container>
      <ng-container second>
          <p class="items-title yellow">Part. Turma</p>
          <p class="items-subtitle">{{effortMetric?.classParticipationUntilDay?.toFixed(1)}}%</p>
      </ng-container>
  </app-track-effort-progress-details>

  <app-track-effort-progress-details title="esforço" class="valuation">
    <ng-container first>
      <p class="items-title cyan">Meu Esforço.</p>
      <p class="items-subtitle">{{effortMetric?.studentInteractionUntilDayInHours?.toFixed(1)}}h</p>
    </ng-container>
    <ng-container second>
      <p class="items-title yellow">Esforço Turma</p>
      <p class="items-subtitle">{{effortMetric?.classInteractionUntilDayInHours?.toFixed(1)}}h</p>
    </ng-container>
    <ng-container third>
      <p class="items-title red">Progresso Esperado</p>
      <p class="items-subtitle">{{effortMetric?.expectedInteractionUntilDayInHours?.toFixed(1)}}h</p>
    </ng-container>
  </app-track-effort-progress-details>
</div>
