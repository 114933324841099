import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import { IUserSubjectBlocked } from 'src/app/models/user-subject-blocked.interface';
import { SettingsUsersService } from '../_services/users.service';

@Component({
  selector: 'app-user-subject-blocked',
  templateUrl: './user-subject-blocked.component.html',
  styleUrls: ['./user-subject-blocked.component.scss']
})
export class UserSubjectBlockedComponent implements OnInit {
  public readonly pageSize: number = 10;
  public page: number = 1;
  public readonly displayedColumns = ['user', 'email', 'subjectBlockedCount', 'actions'];
  public itensCount: number = 0;
  public usersSubjectBlocked: Array<IUserSubjectBlocked> = [];
  private _searchUser: Subject<string> = new Subject();
  constructor(private _usersService: SettingsUsersService,
    private _router: Router) { }

  ngOnInit() {
    this._loadUsersSubjectBlocked();
    this._setSearchSubscription();
  }

  private _loadUsersSubjectBlocked(searchValue: string = '') {
    this._usersService.getPagedUsersSubjectBlocked(
      { page: this.page, blocked: true, pageSize: this.pageSize, searchValue: searchValue }).subscribe(result => {
        this.usersSubjectBlocked = result.data.pagedItem;
        this.itensCount = result.data.itemsCount;
      });
  }

  public goToPage(page: number) {
    if (page !== this.page) {
      this.page = page;
      this._loadUsersSubjectBlocked();
    }
  }
  public goToUser(userId: string) {
    this._router.navigate(['configuracoes/banco-de-questão-bloqueado/' + userId]);
  }

  public updateSearch(searchTextValue: string) {
    this._searchUser.next(searchTextValue);
  }

  private _setSearchSubscription() {
    this._searchUser.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.page = 1;
      this._loadUsersSubjectBlocked(searchValue);
    });
  }
}
