import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WrongItem } from 'src/app/shared/components/most-wrong-item/models/wrong-item.model';

@Component({
  selector: 'app-wrong-item-dialog',
  templateUrl: './wrong-item-dialog.component.html',
  styleUrls: ['./wrong-item-dialog.component.scss']
})
export class WrongItemDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<WrongItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public itens: Array<WrongItem>
  ) { }

  public dismiss(): void {
    this.dialogRef.close();
  }
}
