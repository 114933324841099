

<h3>NOVO CONCEITO</h3>
<ng-container *ngIf="data.moduleTag.content.type === 0">
  <div class="inputs">
    <mat-form-field>
      <input matInput
        placeholder="Nome do conceito"
        type="text" 
        [(ngModel)]="conceptName" 
        required 
      />
    </mat-form-field>
    
    <mat-form-field>
      <input matInput
        placeholder="Breve Descrição"
        type="text"
        [(ngModel)]="conceptDescription" 
        required 
      />
    </mat-form-field>
  
    <div id="dialogVideoContent"></div>
    
    <mat-form-field>
      <input matInput
        placeholder="00:00:00"
        mask="00:00:00"
        [(ngModel)]="videoPosition"
        required
      />
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="data.moduleTag.content.type === 2">
  <div class="inputs">
    <mat-form-field>
      <input matInput
        placeholder="Nome do conceito"
        type="text" 
        [(ngModel)]="conceptName" 
        required 
      />
    </mat-form-field>
    
    <mat-form-field>
      <input matInput
        placeholder="Breve Descrição"
        type="text"
        [(ngModel)]="conceptDescription" 
        required 
      />
    </mat-form-field>
  
    <mat-form-field>
      <input matInput
        placeholder="Página"
        [(ngModel)]="page"
        required
      />
    </mat-form-field>
  </div>
</ng-container>

<div class="card-footer">
  <button class="btn-outline" (click)="dialogRef.close()">
    Sair
  </button>
  <button class="btn-test" (click)="save()">
    Atualizar
  </button>
</div>
