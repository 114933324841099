import { forEach, isNil } from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { UtilService } from '../../../../../shared/services/util.service';
import { Track } from '../../../../../models/track.model';
import { TrackEvent } from 'src/app/models/track-event.model';
import * as moment from 'moment/moment';
import { SettingsTracksService } from 'src/app/settings/_services/tracks.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-track-relevant-dates',
  templateUrl: './relevant-dates.component.html',
  styleUrls: ['../new-track-steps.scss', './relevant-dates.component.scss']
})
export class NewTrackRelevantDatesComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() track$: Observable<Track>;
  public track: Track;
  @Output() manageRelevantDates = new EventEmitter<Track>();

  public formGroup: FormGroup;
  public oldformGroup: FormGroup;
  private _concatEvents = true;

  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    private _tracksService: SettingsTracksService,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.track$) {
      this.track$.pipe(
      ).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(trackItems => {
        this.track = trackItems;
        if (this.formGroup && trackItems.calendarEvents) {
          this.updateComponent(trackItems.calendarEvents);
        } else {
          this.formGroup = this._createFormGroup();
          if (trackItems && trackItems.calendarEvents) {
            this._fillFormEvents(trackItems.calendarEvents);
          }
        }
      });
    }
  }

  public updateComponent(calendarEvents: TrackEvent[]) {
    let calEvents = this.formGroup.get('calendarEvents') as FormArray;

    calEvents ? calEvents.clear() : calEvents = new FormArray([]);

    calendarEvents.forEach((event) => {
      calEvents.push(this._createCalendarEventForm(event));
    });
  }

  public nextStep(): void {
    if (this.formGroup.valid) {
      const relevantDates = this.formGroup.getRawValue();

      relevantDates.calendarEvents.forEach(calEv =>
        calEv = this._adjustEventTime(calEv)
      );

      this.manageRelevantDates.emit(relevantDates.calendarEvents);

    } else {
      this.formGroup = this._utilService.markFormControlsAsTouch(this.formGroup);
      this.notify('Por favor, preencha todos os campos obrigatórios');
    }
  }

  public addCalendarEvent(): void {
    const calEvents = this.formGroup.get('calendarEvents') as FormArray;
    calEvents.push(
      this._createCalendarEventForm()
    );
  }

  public removeCalendarEvent(index: number): void {
    const calEvents = this.formGroup.get('calendarEvents') as FormArray;
    calEvents.removeAt(index);
  }

  private _adjustEventTime(calEv: TrackEvent): TrackEvent {
    calEv.eventDate = new Date(calEv.eventDate);

    if (calEv.duration) {
      if ((calEv.duration as string).length === 4)
        calEv.duration = calEv.duration + '00';

      calEv.duration = this._utilService.getDurationFromFormattedHour(
        calEv.duration.toString()
      );
    }

    if (calEv.startHour && calEv.startHour.trim() !== '') {
      calEv.startHour = calEv.startHour.split(':').join('');
      const startHours = calEv.startHour.substring(0, 2);
      calEv.eventDate.setHours(
        parseInt(startHours, 10)
      );

      const startMinutes = calEv.startHour.substring(2, 4);
      calEv.eventDate.setMinutes(
        parseInt(startMinutes, 10)
      );
    }

    delete calEv.startHour;

    return calEv;
  }

  private _createFormGroup(): FormGroup {
    return new FormGroup({
      'calendarEvents': new FormArray([])
    });
  }

  private _createCalendarEventForm(calEvent: TrackEvent = null): FormGroup {
    return new FormGroup({
      'title': new FormControl(calEvent ? calEvent.title : '', [Validators.required]),
      'eventDate': new FormControl(calEvent ? calEvent.eventDate : '', [Validators.required]),
      'startHour': new FormControl(calEvent ? moment(calEvent.eventDate).format('HH:mm') : ''),
      'duration': new FormControl(calEvent && calEvent.duration ?
        this._utilService.formatDurationToHour(calEvent.duration as number) : null
      )
    });
  }

  private _fillFormEvents(calendarEvents: TrackEvent[]): void {
    const calEvents = this.formGroup.get('calendarEvents') as FormArray;
    this._concatEvents ?
      calendarEvents.forEach(calEv => {
        calEvents.push(
          this._createCalendarEventForm(calEv)
        );
      }) : calEvents.setValue(calendarEvents);
  }

  public setDocumentFile(files: FileList) {
    const file = files.item(0);
    const reader = new FileReader();
    const callback = this._sendToServer.bind(this);

    reader.onloadend = function (e) {
      try {
        callback(
          this.result as string
        );
      } catch (error) { console.log(error); }
    };
    reader.readAsDataURL(file);

  }

  private _sendToServer(result: string) {

    this.notify(
      'Dependendo do tamanho da planilha o processo de importação pode demorar um pouco. Assim que completar ele aparecerá na lista abaixo'
      );

    this._tracksService.addCalendarEventsFromFile(this.track.id, result)
      .subscribe((response) => {
        const calendarEvents = response.data;
        this._fillFormEvents(calendarEvents);
        this.notify('Arquivo enviado com sucesso!');
        this.track.calendarEvents = this._concatEvents ? [...this.track.calendarEvents, ...calendarEvents] : calendarEvents;
      }, (response: HttpErrorResponse) => {
        const error = response.error;
        this.notify(
          error.errors && error.errors.length ?
            error.errors.join() : 'Ocorreu um erro ao enviar o arquivo, por favor tente novamente mais tarde');
      });
  }
}
