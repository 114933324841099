import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';
import { Team } from 'src/app/settings/teams/Models/team.model';
import { PagedTeamOptionsResponse } from 'src/app/settings/teams/services/viewModels/paged-team-options.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  constructor(private _httpService: BackendService) { }

  public getPagedTeams(
    page: number = 1, pageSize: number = 10, searchTerm: string = '', createdAt: Date = null, programsId: string[] = null
  ): Observable<any> {
    return this._httpService.get('getAllTeams', [], [
      { 'name': 'page', 'value': page.toString() },
      { 'name': 'pageSize', 'value': pageSize.toString() },
      { 'name': 'name', 'value': searchTerm },
      { 'name': 'createdAt', 'value': createdAt ? createdAt.toISOString() : null },
      { 'name': 'programsId', 'value': programsId ? programsId.join(',') : '' }
    ]);
  }
  public getTeamById(teamId: string) {
    return this._httpService.get<Team>('getTeamById', [], [{
      'name': 'teamId', 'value': teamId
    }]);
  }
  public getStudentsTeamByIds(teamsIds: string[]) {
    return this._httpService.post('GetStudents', {
      teamsIds
    });
  }

  public getTeamOptions(args: { teamTitle?: string, programId?: string, page: number, pageSize: number }) {
    return this._httpService.post<PagedTeamOptionsResponse>('getTeamOptions', args);
  }

  public createTeams(
    name: string, programId: string[], companyId: string, students: Array<String>
  ): Observable<any> {
    return this._httpService.post('CreateTeam', {
      'name': name,
      'programsId': programId,
      'companyId': companyId,
      'students': students
    });
  }

  public updateTeam(
    name: string, programId: string[], companyId: string, students: Array<String>, id: string
  ): Observable<any> {
    return this._httpService.put('UpdateTeam', {
      'name': name,
      'programsId': programId,
      'companyId': companyId,
      'students': students,
      'id': id
    });
  }

  public deleteTeam(teamId: string): Observable<any> {
    return this._httpService.delete('DeleteTeam', [
      { 'name': 'teamId', 'value': teamId }
    ]);
  }

  public ImportTeam(teamItem: any): Observable<any> {
    return this._httpService.post('ImportTeam', {
      teamItem
    });
  }
}
