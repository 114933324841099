import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-track-mandatory-video',
  template: `
    <div class="mandatory" >
      <div>
        <p class="mandatory-title"
          [ngStyle]="getTitleColors()" >
          VÍDEO INTRODUTÓRIO OBRIGATÓRIO
        </p>
        <p class="mandatory-subtitle" >
          {{ seen ? 'Conteúdo da trilha desbloqueado' :
          'Veja o vídeo para desbloquear o conteúdo da trilha'}}
        </p>
      </div>
      <button class="btn-test mandatory-btn"
        [ngStyle]="getBtnColors()"
        (click)="watch()" >
        Ver
      </button>
    </div>
    <div class="locked-track" *ngIf="!seen">
      <p>{{replecement.track.singular | uppercase}}</p>
      <img src="./assets/img/lock-icon.png" />
    </div>`,
  styleUrls: ['./track-mandatory-video.component.scss']
})
export class TrackMandatoryVideoComponent implements OnInit {

  @Input() readonly seen: boolean = false;
  @Output() watchVideo = new EventEmitter();
  private _finished_progress_color: string;
  public replacement = environment.replecement;

  constructor() { }
  ngOnInit(): void {
    const style = getComputedStyle(document.body);
    this._finished_progress_color = style.getPropertyValue('--finished-progress-color');
  }

  public watch() {
    this.watchVideo.emit(true);
  }

  public getTitleColors() {
    if (this.seen) {
      return {
        'color': this._finished_progress_color
      };
    } else {
      return {
        'color': '#ff4376'
      };
    }
  }

  public getBtnColors() {
    if (this.seen) {
      return {
        'background-color': this._finished_progress_color
      };
    } else {
      return {
        'background-color': '#ff4376'
      };
    }
  }
}
