import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';

@Injectable()
export class LogsService {

  constructor(private _httpService: BackendService) { }

  public getAllLogs(): Observable<any> {
    return this._httpService.get('getAllLogs');
  }

  public getPagedLogs(
    page: number = 1,
    pageSize: number = 10,
    sort: string = '',
    sortAscending: boolean = false,
    entityType: string,
    fromDate: Date = null,
    toDate: Date = null): Observable<any> {
    return this._httpService.post('getPagedAuditLogs', {
      page: page,
      pageSize: pageSize,
      filters: {
        entityType: entityType,
        startDate: fromDate,
        endDate: toDate,
        sortBy: sort,
        isSortAscending: sortAscending
      }
    });
  }

  public getPagedErrorLogsEcommerceIntegration(
    page: number = 1,
    pageSize: number = 10,
    sort: string = '',
    sortAscending: boolean = false,
    fromDate: Date = null,
    toDate: Date = null): Observable<any> {
    return this._httpService.post('getErrorEcommerceIntegration', {
      page: page,
      pageSize: pageSize,
      filters: {
        startDate: fromDate,
        endDate: toDate,
        sortBy: sort,
        isSortAscending: sortAscending
      }
    });
  }

  public getAllUpdatedQuestionsDraft(
    moduleId: string,
    entityType: string,
    startDate: Date = null,
    endDate: Date = null,
    page: number = 1,
    pageSize: number = 10,
    sort: string = '',
    sortAscending: boolean = false): Observable<any> {
    return this._httpService.post('getAllUpdatedQuestionsDraft', {
      page,
      pageSize,
      filters: {
        moduleId,
        entityType,
        startDate,
        endDate,
        sortBy: sort,
        isSortAscending: sortAscending
      }
    });
  }
}
