<div class="answer" (click)="select()" >
  <div class="checkbox"
    [ngClass]="{
      'selected': selected,
      'right': (correctAnswerId && correctAnswerId === answer.id) || (selected && confirmed && answer.isRight) || (isMock && confirmed && answer.isRight) ,
      'wrong': (correctAnswerId && correctAnswerId !== answer.id) || (selected && confirmed && !answer.isRight) ||(isMock && confirmed && !answer.isRight) 
    }"
  >
    <img *ngIf="(correctAnswerId && correctAnswerId !== answer.id) || (selected && confirmed && !answer.isRight) || (isMock && confirmed && !answer.isRight) "
      src="./assets/img/wrong-answer.png"
    />
    <img *ngIf="(correctAnswerId && correctAnswerId === answer.id) || (selected && confirmed && answer.isRight) || (isMock && confirmed && answer.isRight) "
      src="./assets/img/right-answer.png"
    />
  </div>
  <p class="notranslate" translate="no">{{ answer?.description || answer?.text }}</p>
</div>
