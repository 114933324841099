<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <div class="header">
    <h3>Segmento</h3>
    <button class="btn-test" (click)="goTo()">
      NOVO SEGMENTO
    </button>
  </div>
  <div class="main">
    <div class="content-wrapper" *ngFor="let segment of segments">
      <div class="content-title">
        <p class="title">{{segment.name}}</p>
        <p class="subTitle">Associado em <strong>{{segment.sector ? 1 : 0}}</strong> setor </p>
      </div>
      <div class="action">
        <img class="icon" src="./assets/img/edit.png" (click)="goTo(segment)" title="Editar segmento">
        <img class="icon" src="./assets/img/trash.png" (click)="delete(segment)" title="Excluir segmento">
      </div>
    </div>
  </div>
  <app-pagination [hidden]="itemsCount === 0" [setPagination]="{
      'itemsCount': itemsCount,
      'pageSize': pageSize
    }" (goToPage)="goToPage($event)"></app-pagination>
</div>
