import { TemplateRef } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { IeffortMetric } from 'src/app/pages/cockpit/models/studentMetrics';
import { DialogFactoryService } from 'src/app/shared/services/dialog/dialog-factory.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { DialogData } from 'src/app/shared/services/dialog/models/dialog-data.model';

@Component({
  selector: 'app-effort-progress-students',
  templateUrl: './effort-progress-students.component.html',
  styleUrls: ['./effort-progress-students.component.scss']
})
export class EffortProgressStudentsComponent {

  @Input() effortMetric: IeffortMetric;

  public dialog: DialogService;
  @ViewChild('understandDialogTemplate', { static: true })
  understandDialogTemplate: TemplateRef<any>;

  constructor(private dialogFactoryService: DialogFactoryService) { }



  public dispatchDialog() {
    this.openDialog({
      headerText: 'Métricas de EMPENHO',
      template: this.understandDialogTemplate
    });
  }

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, { width: 720, height: 260, disableClose: false });
  }
}
