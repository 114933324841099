import { ValuationTestTypeEnum, ValuationTestModuleTypeEnum, ValuationTestTypeGeneredEnum } from './enums/valuation-test-type-enum';
import { SupportMaterial } from 'src/app/models/support-material.interface';
import { Type } from 'src/app/settings/valuation-tests/manage-valuation-test/generete-valuation-test-questions-dialog/model/generate-valuation-test';

export class ValuationTest {
  public id?: string;
  public type: ValuationTestTypeEnum;
  public generated: ValuationTestTypeGeneredEnum;
  public generatedBy?: Type;
  public title: string;
  public moduleIds?: string[];
  public trackIds?: string[];
  public subjectIds?: string[];
  public testModules: TestModule[];
  public testTracks: TestTrack[];
  public testSubjects?: TestSubject[];
  public testSchedules?: TestSchedules[];
  public questions?: string[];
  public testQuestions?: ValuationTestQuestion[];
  public hasAnswers?: boolean;
  public answered?: boolean;
  public isAvailable?: boolean;
  public hasStarted?: boolean;
  public comment?: string;
  public openDate?: Date;
  public valuationDate?: Date;
  public alwaysAvailable?: boolean;

  public checked?: boolean;
  public canSeeAnswers?: boolean;
  public otherSubjects?: TestSubject[];

  constructor() {
    this.testQuestions = [];
    this.testModules = [];
  }
}

export class ValuationTestQuestion {
  public id?: string;
  public testId: string;
  public title: string;
  public percentage: number;
  public testGenerated: ValuationTestTypeGeneredEnum;
  public type: ValuationTestQuestionTypeEnum;
  public options: ValuationTestQuestionOption[];
  public testTitle?: string;
  public answer?: string;
  public hasChange?: boolean;
  public supportMaterials?: SupportMaterial[];
  public comments?: string;
  public curseWorkFile?: ValuationTestcurseWorkFileResponse;
  public canceled: boolean;
  public comment?: string;

  public newQuestion?: boolean;
  public notEditable?: boolean;
  public isMock?: boolean;

  public moduleId?: string;
  public subjectId?: string;
  public moduleTitle?: string;
  public subjectTitle?: string;

  constructor() {
    this.newQuestion = true;
    this.canceled = false;
  }
}
export class ValuationTestcurseWorkFileResponse {
  public fileName: string;
  public downloadLink: string;
}
export class ValuationTestQuestionOption {
  public text: string;
  public correct: boolean;
}

export interface ValuationTestResponse {
  id: string;
  createdAt: Date;
  createdBy?: string;
  title?: string;
  userName: string;
  userRegisterId?: string;
  testId?: string;
  testTitle?: string;
  answers: ValuationTestAnswer[];
  recommended?: boolean;
  eventsInfo?;
  tracksInfo?;
  modulesInfo?;

  finalGrade?: number;
}

export interface ValuationTestAnswer {
  questionId: string;
  question?: string;
  answer: string;
  grade?: number;
  percentage?: number;
  moduleTitle?: string;
  subjectTitle?: string;
  moduleId?: string;
  subjectId?: string;
  gradeIsSet?: boolean;
}

export enum ValuationTestQuestionTypeEnum {
  MultipleChoice = 1,
  Discursive = 2,
  Coursework = 3,
  NPS = 4
}

export interface ValuationTestExcel {
  aluno: string;
  matricula: string;
  questao: string;
  answer: string;
  data: Date;
  moduleTitle?: string;
  subjectTitle?: string;
}

export interface TestModule {
  id: string;
  title: string;
  checked?: boolean;
  toggled?: boolean;
  percent?: number;
  type?: ValuationTestModuleTypeEnum;
  deliveryDate?: Date;
  tracks?: TestTrack[];
  subjects?: TestSubject[];
}

export interface TestTrack {
  id: string;
  title: string;
  checked?: boolean;
  toggled?: boolean;
  percent?: number;
  order?: number;
  deliveryDate?: Date;
  modulesConfiguration?: any;
  eventConfiguration?: any;
}

export interface TestSubject {
  id: string;
  title: string;
  checked?: boolean;
  toggled?: boolean;
  percent?: number;
  deliveryDate?: Date;
  module?: TestModule;
}

export interface TestSchedules {
  id: string;
  eventId: string;
  title: string;
  eventDate: Date;
  checked?: boolean;
  toggled?: boolean;
  percent?: number;
  type?: ValuationTestModuleTypeEnum;
}
