<nav>
  <a href="javascript:history.back()">
    <i class="logo icon-seta_direita" style="font-size: 20px;"></i>
    voltar
  </a>
</nav>
<div class="header">
  <div class="info">
    <h2 class="track-name">{{title}}</h2>
    <h1 class="year-class">{{selectedStudent === AllStudents ? 'TURMA 2021' : selectedStudent?.name }}</h1>
  </div>
  <div class="filters">
    <div class="form-content">
      <label>{{replacement.track.singular}}</label>
      <!-- Encontrar uma outra solução para o placeholder -->
      <mat-form-field>
        <mat-select panelClass="cockpit-mat-panel" [(value)]="selectedTrack" [placeholder]="selectedTrack?.title"
          (selectionChange)="handleSelectTrackFilterChange($event.value)" (openedChange)="openedChange($event)">
          <input class="input-search" id="input-search" placeholder="Pesquise...." (keyup)="onKey($event.target.value)"
            (keydown)="$event.stopPropagation()">
          <mat-option *ngFor="let track of tracks" style="color: black; width: 600px !important;"
            [attr.title]="track.title" [value]="track"> {{track.title}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-content">
      <label>Aluno</label>
      <mat-form-field>
        <mat-select [(ngModel)]="selectedStudent" (selectionChange)="handleSelectStudentsFilterChange(selectedStudent)"
          [value]="selectedStudent">
          <ng-container *ngIf="selectedTrack">
            <mat-option [value]="-1"> Todos </mat-option>
            <mat-option *ngFor="let student of selectedTrack.students" style="color: black" [value]="student">
              {{student.name}} </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
