import { Pipe, PipeTransform } from '@angular/core';
import { QuestionAndAnswer, ValuationTestQuestionType } from '../models/question';

@Pipe({ name: 'questionGradeClass' })
export class QuestionGradeClassPipe implements PipeTransform {

  transform(question: QuestionAndAnswer) {
    if (question.canceled) return 'canceled';
    if (
        question.type === ValuationTestQuestionType.Discursive &&
        !question.grade
      )
        return 'warn';
      if (question.grade < question.percentage) return 'danger';
      else return 'success';

    }
}
