<h1>
  <a href="javascript:;"
  (click)="backToUserManager()" >
    cancelar
  </a>
  ALTERAR SENHA
</h1>
<div class="inner-content" >
  <div class="user-info" >
    <form [formGroup]="formGroup" >
      <mat-form-field *ngIf="!adminChange">
        <input matInput placeholder="Senha atual" type="password"
          formControlName="currentPassword"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Nova senha" type="password"
          formControlName="newPassword"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Confirmação nova senha" type="password"
          formControlName="newPasswordConfirmation"/>
      </mat-form-field>
    </form>
  </div>
</div>

<div class="footer" >
  <button class="btn-test" type="button" (click)="save()" >
    Alterar senha
  </button>
</div>
