import { Component, Inject, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl } from '@angular/forms';
import { Content, PdfReference } from '../../../../../../../models/content.model';
import { FileUploadClass } from '../../../../../../../shared/classes/file-upload';
import { SharedService } from '../../../../../../../shared/services/shared.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { isNil } from 'lodash';
import * as pdfJS from 'pdfjs-dist';
import { distinctUntilChanged } from 'rxjs/operators';
pdfJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJS.version}/pdf.worker.js`;

@Component({
  selector: 'app-pdf-config-dialog',
  templateUrl: './pdf-config.dialog.html',
  styleUrls: ['../content-config.scss', './pdf-config.dialog.scss']
})

export class PdfConfigDialogComponent extends FileUploadClass implements OnInit, AfterViewInit, OnDestroy {

  public formGroup: FormGroup;
  downloadLink: any;
  public subscription: Subscription;
  public isLoading: boolean;
  constructor(
    protected _snackbar: MatSnackBar,
    protected _sharedService: SharedService,
    protected _uploadService: UploadService,
    public dialogRef: MatDialogRef<PdfConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public content: Content
  ) {
    super(_snackbar, _sharedService, _uploadService);
  }
  ngOnInit() {
    const getFileName = content => isNil(content) ?
      ''
      : content.value.split('/module/')[1] || content.value.split('/').pop() || '';

    this.downloadLink = this.content ? this.content.value : '';

    this.formGroup = new FormGroup({
      'downloadLink': new FormControl(
        this.downloadLink
      ),
      'fileName': new FormControl(
        this.content && this.content.value ? getFileName(this.content) : ''
      ),
      'numPages': new FormControl(
        this.content && this.content.numPages ? this.content.numPages : 1
      )
    });
  }

  ngAfterViewInit(): void {
    this.subscription = this.formGroup.get('fileName')
      .valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(async r => {
        await this.setNumberPage(this.formGroup);
      });

    this._sharedService.getLoaderSubject().subscribe(value => {
      this.isLoading = value;
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public referencesTrackBy(index: number, obj: any) {
    return index;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  public async save(): Promise<void> {
    await this.setNumberPage(this.formGroup);

    const hasPositionsExtendingThePage = this.content.concepts.some(concept => {
      if (!isNil(concept.checked)) {
        return concept['positions'][0] > this.formGroup.get('numPages').value;
      }
    });

    if (hasPositionsExtendingThePage) {
      this.notify('A posição do conceito não pode exceder o número de páginas do pdf ');
      return;
    }

    const formValues = this.formGroup.getRawValue();
    this.content.value = formValues.downloadLink;
    this.content.numPages = formValues.numPages || 1;
    this.dialogRef.close(this.content);
  }

  public setNumberPage(formGroup: FormGroup): Promise<void> {
    const input = document.getElementById('inputFile') as HTMLInputElement;

    return new Promise(async (resolve, reject) => {
      try {
        if (input.files.length === 0) return resolve();

        const file = input.files[0];
        const fileReader = new FileReader();

        fileReader.onload = async function () {

          const typedarray = new Uint8Array(fileReader.result as any);
          const pdf = await pdfJS.getDocument(typedarray).promise;
          formGroup.get('numPages').setValue(pdf.numPages);
          return resolve();

        };
        fileReader.readAsArrayBuffer(file);


      } catch (error) {
        formGroup.get('numPages').setValue(1);
        reject(error);
      }
    });
  }

  public showError(concept): boolean {
    if (!isNil(concept.checked)) {
      return concept['positions'][0] > Number(this.formGroup.get('numPages').value);
    } else {
      return false;
    }
  }

  public addReference(): void {
    this.content.referenceUrls ?
      this.content.referenceUrls.push('') :
      this.content.referenceUrls = [''];
  }

  public setConcept(concept: PdfReference) {
    if (this.formGroup.get('fileName').value) {
      concept.checked = !concept.checked;
      if (concept.checked)
        concept.positions = [1];
    }
  }

  public openFileUpload(): void {
    (document.getElementById('inputFile') ).click();
  }

}
