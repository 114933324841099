import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService, LoginResponse } from 'src/app/shared/services/auth.service';
import { format } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { Groups } from 'src/app/models/groups.model';
import { ValuationTestResponse } from 'src/app/models/valuation-test.interface';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { GroupsService } from '../_services/groups.service';
import { SettingsValuationTestsService } from '../_services/valuation-tests.service';
import { ProfileTestTypeEnum } from 'src/app/models/profile-test.interface';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SimpleDialog } from 'src/app/shared/components/simple-dialog/models/simple-dialog.model';
import { ExportTemplate } from './enum/export-templates.enum';
@Component({
  selector: 'app-valuation-tests-group',
  templateUrl: './valuation-tests-group.component.html',
  styleUrls: ['./valuation-tests-group.component.scss']
})
export class ValuationTestsGroupComponent extends NotificationClass implements OnInit {

  @ViewChildren("templateChooseTemplateExportDialog") templateChooseTemplateExportDialog: QueryList<TemplateRef<HTMLElement>>;

  private _searchValue: string;
  private page: number = 1;
  public pageSize: number = 4;

  public groups: Array<Groups> = [];
  public itemsCount: number = 0;

  public loggedUser: LoginResponse;
  public readonly exportTemplates = ExportTemplate;


  constructor(private _groupsService: GroupsService,
    private _router: Router,
    private _dialog: MatDialog,
    private _testsService: SettingsValuationTestsService,
    private _excelService: ExcelService,
    private _authService: AuthService,
    _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  ngOnInit(): void {

    this.loggedUser = this._authService.getLoggedUser();
    this._loadGroupTests();
  }

  public setValuationTestTermFilter($event: string) {
    this._searchValue = $event;
    this._loadGroupTests();
  }

  private _loadGroupTests() {
    this._groupsService.getAll(this.page, this.pageSize, this._searchValue).subscribe(response => {
      this.groups = response.data.pagedItem;
      this.itemsCount = response.data.itemsCount;
    });
  }

  public DeleteGroup(id: string) {

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja remover este grupo?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._groupsService.DeleteGroups(id).subscribe(response => {
          this._loadGroupTests();
        });
      }
    });

  }

  public goToValuationGroup(id: string = null) {
    const url = `configuracoes/grupo-avaliação${isNil(id) ? '' : '/' + id}`;
    this._router.navigate([url]);
  }

  public goToPage(page: number) {
    this.page = page;
    this._loadGroupTests();
  }

  public exportAnswersExcel(group: Groups, exportTemplate: ExportTemplate): void {
    this.notify('Depdendendo da quantidade de dados, a exportação pode levar alguns minutos..');
    this._testsService.GetAllValuationTestResponsesByGroup(
      group.id
    ).subscribe((response) => {

      const header = [
        ['Grupo:', group.title],
        ['Data:', format(new Date(), 'DD/MM/YYYY HH:mm:ss')],
        ['Gerado por:', `${this.loggedUser.name} (${this.loggedUser.username})`],
        [],
      ];


      this._excelService.exportAsExcelFile(
        exportTemplate === ExportTemplate.discursive_nps
          ? this._prepareAnswersForExportTemplateDiscursiveNps(response.data)
          : this._prepareAnswersForExportMultipleChoice(response.data),
        "export-grupo-avaliações",
        header
      );

    });
  }

  private _prepareAnswersForExportMultipleChoice(responses: any) {
    const answers = [];

    responses.userAnswers.forEach(response => {

      const userFinalGrade: number | string = (response.answers.every((answers) => answers.grade != null))
        ? (response.answers.reduce((sum, answers) => sum + answers.grade, 0) / 10)
        : 'Aguardando Nota';

      response.answers.forEach(userAnswer => {
        const dbQuestion = responses.questionOptions.find((questionToFind) => (
          questionToFind.id === userAnswer.questionId && questionToFind.testId === response.testId
        ));

        const answerIndex = isEmpty(dbQuestion.options)
          ? -1
          : dbQuestion.options.findIndex((option) => option.text === userAnswer.answer);

        const createRowOption = (answer?, isCorrect?, index?) => {
          const answerItem = {
            'Avaliação': response.title,
            'Aluno': response.userName,
            'Matrícula': response.userRegisterId,
            'Respondido em': format(response.createdAt, 'DD/MM/YYYY'),
            'Nota Final': userFinalGrade,
            'Pergunta': userAnswer.question,
            'Resposta': answer,
            'Resposta certa': answerIndex !== -1 && isCorrect ? 'X' : '',
            'Resposta aluno': dbQuestion.type === ProfileTestTypeEnum.NPS ?
              userAnswer.answer
              : answerIndex !== -1 && answerIndex === index ? 'X' : '',
            'Tipo de questão': this.GetTestType(dbQuestion.type),
            'Nota da questão': userAnswer.grade / 10,

          };
          if (!isNil(userAnswer.moduleTitle)) {
            answerItem['Módulo'] = userAnswer.moduleTitle;
          }
          if (!isNil(userAnswer.subjectTitle)) {
            answerItem['Assunto'] = userAnswer.subjectTitle;
          }

          return answerItem;
        };

        answerIndex === -1
          ? answers.push(createRowOption())
          : dbQuestion.options.forEach((option, index) => answers.push(createRowOption(option.text, option.correct, index)));
      });

    });

    return answers;
  }

  private _prepareAnswersForExportTemplateDiscursiveNps(responses: any) {
    const answers: Array<any> = [];

    responses.userAnswers.forEach(response => {
      response.answers.forEach(userAnswer => {
        const answer = {
          'Avaliação': response.title,
          'Aluno': response.userName,
          'Matrícula': response.userRegisterId,
          'Respondido em': format(response.createdAt, 'DD/MM/YYYY'),
          'Questão': userAnswer.question,
          'Answer': userAnswer.answer,
        };
        if (!isNil(userAnswer.moduleTitle)) {
          answer['Módulo'] = userAnswer.moduleTitle;
        }
        if (!isNil(userAnswer.subjectTitle)) {
          answer['Assunto'] = userAnswer.subjectTitle;
        }
        answers.push(answer);
      });
    });

    return answers;
  }

  public GetTestType(type: ProfileTestTypeEnum): string {
    switch (type) {
      case ProfileTestTypeEnum.MultipleChoice:
        return 'Múltipa escolha';
      case ProfileTestTypeEnum.Discursive:
        return 'Discursiva';
      case ProfileTestTypeEnum.NPS:
        return 'NPS';
      default:
        break;
    }
  }

  public chooseTemplateExportDialog(index: number) {
    const dialogRef = this._dialog.open(SimpleDialogComponent, {
      panelClass: "custom-dialog-container",
      data: {
        template: this.templateChooseTemplateExportDialog.get(index)

      } as SimpleDialog
    });
  }
}
