import { EStudyPlan } from './enums/study-plan.enum';
import { StudyPlanItem } from './study-plan-items.model';
import { StudyPlanRequeriments } from './study-plan-requeriments.models';

export class StudyPlan {
    public id: string = '';
    public name: string = '';
    public description: string = '';
    public type: EStudyPlan = EStudyPlan.team;
    public users: Array<string>;
    public img: string = '';
    public items: StudyPlanItem[] = new Array<StudyPlanItem>();
    public mandatoryTraining: StudyPlanItem[] = new Array<StudyPlanItem>();
    public institutionalTraining: StudyPlanItem[] = new Array<StudyPlanItem>();
    public requirements: StudyPlanRequeriments[] = new Array<StudyPlanRequeriments>();

    public modulesCount?: number;
    public trackCount?: number;

    constructor(studyPlan: StudyPlan = null) {
        if (studyPlan) Object.keys(studyPlan).forEach(key => this[key] = studyPlan[key]);
    }
}
