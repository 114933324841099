<div class="title-content">
    <div class="title">
        <h5>MONTE SEU PRÓPRIO RANKING</h5>
    </div>
    <!--button class="btn-test"
        [ngStyle]="{'width.px': 243, 'height.px':46}"
        (click)="exportRanking()">
        Exportar Relatório
    </button-->
    <button class="btn-test"
        [ngStyle]="{'width.px': 243, 'height.px':46}"
        (click)="closeDialog()">
        Voltar
    </button>
</div>
<div class="content">
    <div class="content-filter">
        <h5>Filtre Métricas</h5>
        <div class="items">
            <p><strong>Métrica</strong></p>
            <p><strong>Peso</strong></p>
        </div>
        <div class="items">
            <p>Prova</p>
            <div class="form-content">
                <mat-form-field class="login-input">
                    <input 
                        type="number"
                        matInput 
                        (keyup)="handleFiltersChange($event.target.value, 'valuation')"
                        [value]="filters.valuation" 
                        required />
                </mat-form-field>
            </div>
        </div>
        <div class="items">
            <p>Esforço</p>
            <div class="form-content">
                <mat-form-field class="login-input">
                    <input 
                        type="number"
                        matInput 
                        (keyup)="handleFiltersChange($event.target.value, 'effort')"
                        [value]="filters.effort"  required />
                </mat-form-field>
            </div>
        </div>
        <div class="items">
            <p>Engajamento</p>
            <div class="form-content">
                <mat-form-field class="login-input">
                    <input 
                        type="number"
                        matInput 
                        (keyup)="handleFiltersChange($event.target.value, 'engagement')"
                        [value]="filters.engagement" required />
                </mat-form-field>
            </div>
        </div>
        <div class="items">
            <p>Aprendizagem</p>
            <div class="form-content">
                <mat-form-field class="login-input">
                    <input 
                        type="number"
                        matInput 
                        (keyup)="handleFiltersChange($event.target.value, 'learning')"
                        [value]="filters.learning" required />
                </mat-form-field>
            </div>
        </div>
        <div class="items">
            <p>Evento</p>
            <div class="form-content">
                <mat-form-field class="login-input">
                    <input 
                        type="number"
                        matInput 
                        (keyup)="handleFiltersChange($event.target.value, 'event')"
                        [value]="filters.event" required />
                </mat-form-field>
            </div>
        </div>
    
        <div class="items">
            <p>Participação</p>
            <div class="form-content">
                <mat-form-field class="login-input">
                    <input 
                        type="number"
                        matInput 
                        (keyup)="handleFiltersChange($event.target.value, 'participation')"
                        [value]="filters.participation" required />
                </mat-form-field>
            </div>
        </div>
    
        <div class="filter-students">
            <h4>Filtre Aluno</h4>
            <app-list-search 
                [noPadding]="true"
                placeholder="Filtrar por nome..."
                (triggerSearch)="handleFiltersStudentChange($event)"
            ></app-list-search>
        </div>
    </div>
    <div class="content-table">
        <table>
            <tr>
                <th class="center">Posição</th>
                <th class="left">Nome do Aluno</th>
                <th class="right">Média Nota</th>
            </tr>
            <ng-container *ngFor="let student of customRanking; let i = index">
            <tr>
                <td class="center">
                #{{i + 1}}
                </td>
                <td class="left">{{student?.name}}</td>
                <td class="right">{{showFactor ? student.factor?.toFixed(2) : student.grade?.toFixed(2)}}</td>
            </tr>
            </ng-container>
        </table>
    </div>
</div>



