<div class="content"[ngClass]="{'box-shadow': showBoxShadow}">
    <p [attr.title]="title">{{title}}</p>
    <div class="progress">
        <div *ngIf="canShow(progress)" class="progress-content">
            <app-progress-bar
                [currentProgress]="progress"
                [height]="11"
                [roundedBorder]="true"
                [color]="primary_color"
            ></app-progress-bar>
            <label><strong>{{progress}}%</strong> {{canShow(subtitle) ? subtitle : 'dos alunos completaram'}}</label>
        </div>
        <span class="material-icons">
            navigate_next
        </span>
    </div>
</div>