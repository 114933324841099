<div class="students">
  <p class="title" *ngIf="track && itemsCount > 0">
    <span class="title-total"> Total: {{itemsCount}}</span>
    <span class="title-late"> Atrasados: {{getLateStudents(track)}}</span>
  </p>
  <p class="title space">
    ALUNOS
    <button [disabled]="!this.track?.id" *ngIf="canExport()" class="btn-test" (click)="exportStudents()"> Exportar Alunos </button>
  </p>
  <div class="search">
    <i class="search-icon icon-lupa"></i>
    <input type="text"
      placeholder="Procure pelo nome"
      (keyup)="setStudentName($event.target.value)" />
  </div>
  <p class="no-students" *ngIf="track && itemsCount === 0">
    Ainda não há alunos inscritos nesta trilha.
  </p>
  <ul *ngIf="track && itemsCount > 0">
    <li *ngFor="let student of track?.students; let index = index" [class.blocked]="student.isBlocked"
      (click)="!student.isBlocked && viewStudentOverview(student)">
      <div class="student-info-wrapper">
        <img class="photo" [src]="student.imageUrl || './assets/img/user-image-placeholder.png'" />
        <img class="icon" [src]="student.isBlocked ? './assets/img/lock-user-red.png' : './assets/img/unlock-user-green.png'" />
        <p class="item-title"> {{ student.name }} </p>
      </div>

      <div class="late">
        <ng-container *ngIf="checkStudentLate(track, student.id) !== null">
          <div class="warning-wrapper">
            <div class="description">
              atrasado
              <img class="warn" src="./assets/img/status-warning.png" />
            </div>
            <p>empenho: {{ checkStudentLate(track, student.id) * 100 }}%</p>
          </div>
        </ng-container>
      </div>

      <i class="icon-seta_esquerda"></i>
    </li>
  </ul>

  <app-pagination
    [hidden]="itemsCount === 0"
    [setPagination]="{
          'itemsCount': itemsCount,
          'pageSize': 10
        }"
    (goToPage)="goToPage.emit($event)"></app-pagination>
</div>
