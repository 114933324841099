<div class="step">
  <header>
    <div class="title-wrapper">
      <span class="title">Turma(s)</span>
      <span class="subtitle">Adicione as turmas pesquisando por nome.</span>
    </div>
  </header>
  <div class="wrapper">
    <div class="teams-wrapper">
      <div class="search">
        <acd-select [data]="teamsOptions" placeholder="Pesquise por uma turma "
          filterPlaceholder="Pesquise por  uma turma " [disabledSelectedItems]="true" [isLoading]="isLoading"
          [multiple]="true" [displayMember]="'title'" [showFilter]="true"
          (selectionChange)="handleSelectionChange($event)" (openedChange)="handleSelectOpenedChange($event)"
          (filterChange)="handleSearchItem($event)">
        </acd-select>
      </div>
      <ul class="selected-teams" [style.margin-top.px]="selectedTeam? 0:-20">
        <ng-container *ngIf="selectedTeams?.length;else EmptyTeamSelection">
          <li (click)="selectAllTeam()">
            <div class="title">
              <span class="description">Todas as turmas</span>
              <span class="amount">{{allTeamsCount}}</span>
            </div>
            <button mat-icon class="btn-accordion arrow" title="Ver todas as turmas">
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </li>
          <li (click)="selectTeam(team)" *ngFor="let team of selectedTeams;let index=index">
            <div class="title">
              <span class="description">{{team.title}}</span>
              <span class="amount">{{team.studentsCount}}</span>
            </div>
            <button mat-icon class="btn-accordion trash" title="Excluir item" (click)="removeSelectedTeam(index)">
              <mat-icon>delete</mat-icon>
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
    <span class=" divider"></span>
    <div class="student-wrapper">
      <div class="title small-title" *ngIf="selectedTeam?.students?.length">
        <span class="description">{{selectedTeam && selectedTeam.name ? selectedTeam.name : 'Todas as Turmas'}}</span>
        <div>
          <span class="amount">{{selectedTeam?.students?.length}}</span>
          <span class="description">Alunos matriculados</span>
        </div>
      </div>
      <app-list-search *ngIf="selectedTeam?.students?.length" placeholder="Pesquise um aluno" [noPadding]="true"
        (triggerSearch)="search($event)">
      </app-list-search>
      <ul class="students">
        <ng-container *ngIf="selectedTeam;else EmptyStudentSelection">
          <li *ngFor="let student of selectedTeam?.students">
            {{student.name}}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

</div>
<ng-template #EmptyStudentSelection>
  <li class="empty-list">Não há alunos para exibir</li>
</ng-template>
<ng-template #EmptyTeamSelection>
  <li class="empty-list">Não há turma para exibir</li>
</ng-template>
