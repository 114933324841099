<h3>SUA INSCRIÇÃO ESTÁ QUASE COMPLETA!</h3>
<p class="subtitle">
  Consulte o material de apoio e responda as perguntas a seguir para completar sua inscrição.
</p>

<div class="form">
  <div *ngFor="let question of questions; let index = index">
    <div class="question" *ngIf="!question.question.fileAsAnswer">
      <p class="index">{{ index + 1 }}.</p>
      <div class="answer">
        <p>{{ question.question.question }}</p>
        <mat-form-field>
          <textarea matInput (change)="changeAnswer($event, question)" [value]="question.answer" placeholder="Escreva aqui sua resposta"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="question" *ngIf="question.question.fileAsAnswer">
      <p class="index">{{ index + 1 }}.</p>
      <div class="answer">
        <p>{{ question.question.question }}</p>
        <div class="upload">
          <p class="file-name" *ngIf="!question.answer">
            Escolher Arquivo *
          </p>
          <a class="file-name" [href]="question.answer" *ngIf="question.answer">
            {{question.fileName || question.answer}}
          </a>
          <button type="button" (click)="openFileUpload(index, question)">{{ question.answer ? 'Editar' : 'Upload' }}</button>
          <input class="input-file" type="file" id="inputEventFile{{index}}" hidden
            (change)="setDocumentFile($event, childFormGroup)" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="actions">
  <button class="outline" (click)="dismiss(null)">
    Cancelar
  </button>
  <button class="btn-test" (click)="dismiss(questions)">
    Salvar e Enviar Inscrição
  </button>
</div>
