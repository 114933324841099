<div class="content" >
  <h1>
    Ocorreu um erro na aplicação...
  </h1>
  <p class="route" *ngIf="route && route !== ''" >
    Erro na rota: {{ route }}
  </p>
  <p>
    Informações sobre o ocorrido foram salvas e estamos trabalhando para resolver o problema.
  </p>
  <p>
    Solicitamos que você <a (click)="goToSupport()" >contate o suporte.</a><br>
    <a (click)="goBackHome()" >Clique aqui para voltar para Home.</a>
  </p>
</div>