import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { Requirement } from '../../../../modules/new-module/models/new-requirement.model';
import { Event } from '../../../../../models/event.model';
import { Level } from '../../../../../models/shared/level.interface';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-new-event-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['../new-event-steps.scss']
})
export class NewEventRequirementsComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() readonly event$: Observable<Event>;
  public event: Event;
  @Input() readonly levels: Array<Level>;
  @Output() setRequirements = new EventEmitter<Array<Array<Requirement>>>();

  public requirements: Array<Array<Requirement>> = [ [], [] ];

  public requiredModules: Array<Requirement> = [];
  public newModule = new Requirement('', 0);
  public editing: boolean = false;

  public optionalModules: Array<Requirement> = [];
  public newOptionalModule = new Requirement('', 0);
  public editingOptional: boolean = false;

  private _unsubscribeAll = new Subject<any>();

  constructor(protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((eventItems) => {
        this.event = cloneDeep(eventItems);
        if (eventItems && eventItems.requirements) {
          this.requirements = [
            eventItems.requirements.filter(r => !r.optional),
            eventItems.requirements.filter(r => r.optional)
          ];
        }
      });
    }
  }

  public updateRequirements(requirements: Array<Array<Requirement>>): void {
    this.requirements = cloneDeep(requirements);
  }

  public nextStep(): void {
    this.setRequirements.emit( this.requirements );
  }
}
