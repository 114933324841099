import { Injectable } from '@angular/core';
import { BackendService } from '@tg4/http-infrastructure';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommunicationService {
    constructor(private _httpService: BackendService) { }

    public getPagedCommunications(
        page: number = 1, pageSize: number = 10, searchTerm: string = '', startDate: Date = null, endDate: Date = null
    ): Observable<any> {
        return this._httpService.post('getAllCommunication', {
            'page': page,
            'pageSize': pageSize,
            'filters': {
                'term': searchTerm,
                'startDate': startDate,
                'endDate': endDate,
            }
        });
    }
    public getCommunicationById(communicationId: string): Observable<any> {
        return this._httpService.get('getCommunicationById', [], [{
            'name': 'id', 'value': communicationId
        }]);
    }
    public createCommunications(
        title: string, text: string, startDate: Date, endDate: Date
    ): Observable<any> {
        return this._httpService.post('CreateCommunication', {
            title,
            text,
            startDate,
            endDate
        });
    }

    public updateCommunications(
        id: string, title: string, text: string, startDate: Date, endDate: Date
    ): Observable<any> {
        return this._httpService.post('UpdateCommunication', {
            id,
            title,
            text,
            startDate,
            endDate
        });
    }
    public deleteCommunications(id: string): Observable<any> {
        return this._httpService.post('DeleteCommunication', {
            id
        });
    }
}
