import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsedListComponent } from './collapsed-list.component';
import { ExpandedListDialogComponent } from './expanded-list-dialog/expanded-list-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ItemComponent } from './item/item.component';

@NgModule({
    declarations: [CollapsedListComponent, ExpandedListDialogComponent, ItemComponent],
    exports: [CollapsedListComponent],
    imports: [
        CommonModule,
        MatDialogModule,
    ]
})
export class CollapsedListModule { }
