import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { TrackCardModule } from '../../shared/components/track-card/track-card.module';
import { RouterModule } from '@angular/router';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { ContentModulesService } from '../_services/modules.service';
import { BaseUrlService, BackendService } from '@tg4/http-infrastructure';
import { UrlService } from '../../shared/services/url.service';
import { ModuleCardModule } from '../../shared/components/module-card/module-card.module';
import { ContentEventsService } from '../_services/events.service';
import { ContentTracksService } from '../_services/tracks.service';
import { UserService } from '../_services/user.service';
import { SettingsUserDetailsModule } from 'src/app/settings/users/user-details/user-details.module';
import { NPSDialogModule } from 'src/app/shared/dialogs/nps/nps.dialog.module';
import { MyCoursesComponent } from './my-courses.component';
import { MyCoursesSearchComponent } from './search/search.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ProgramsService } from '../_services/programs.service';
import { ProgramCardModule } from 'src/app/shared/components/program-card/program-card.module';
import { CourseCardModule } from 'src/app/shared/components/course-card/course-card.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StudyPlanComponent } from './study-plan/study-plan.component';
import { AllCoursesComponent } from './all-courses/all-courses.component';
import { MyVisionComponent } from './my-vision/my-vision.component';
import { ManagementStudentCoursesComponent } from './management-student-courses/management-student-courses.component';
import { MyCourseSectorsComponent } from './my-course-sectors/my-course-sectors.component';

@NgModule({
  declarations: [
    MyCoursesComponent,
    MyCoursesSearchComponent,
    StudyPlanComponent,
    AllCoursesComponent,
    MyVisionComponent,
    ManagementStudentCoursesComponent,
    MyCourseSectorsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ModuleCardModule,
    TrackCardModule,
    ProgramCardModule,
    RouterModule,
    PaginationModule,
    HttpClientModule,
    SettingsUserDetailsModule,
    PipesModule,
    CourseCardModule,
    MatTabsModule,
    MatIconModule,
    MatCheckboxModule
  ],
  providers: [
    ContentModulesService,
    ContentEventsService,
    ContentTracksService,
    ProgramsService,
    UserService,
    BackendService,
    { provide: BaseUrlService, useClass: UrlService }
  ]
})
export class MyCoursesModule { }
