import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNumber, isNaN, clone, sum, values, orderBy } from 'lodash';
import { interval, pipe, Subject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { IstudentsRanking } from '../../models/trackStudentsSubordinates';
import { Filters } from './models/filters';

@Component({
  selector: 'app-filter-ranking',
  templateUrl: './filter-ranking.component.html',
  styleUrls: ['./filter-ranking.component.scss'],
})
export class FilterRankingComponent implements OnInit {
  @Input() ranking: Array<IstudentsRanking>;
  @Output() customRanking = new EventEmitter<Array<IstudentsRanking>>();
  public filters: Filters = new Filters();
  public inputChange: Subject<any> = new Subject();
  public cloneRanking: Array<IstudentsRanking>;
  constructor() {}

  ngOnInit() {
    this.cloneRanking = clone(this.ranking);
    this.orderRanking();

    this.inputChange.pipe(debounceTime(650)).subscribe((data) => {
      const total =
        this.filters.effort +
        this.filters.engagement +
        this.filters.valuation +
        this.filters.event +
        this.filters.learning +
        this.filters.participation -
        this.filters[data.target];
      if (total + data.value > 100) {
        this.filters[data.target] = 100 - total;
      } else {
        this.filters[data.target] = data.value;
      }
      this.orderRanking();
    });
  }

  public handleFiltersChange(event: number, target: string): void {
    this.inputChange.next({ value: Number(event), target: target });
  }
  handleFiltersStudentChange(value: string) {
    this.cloneRanking = this.ranking.filter((student) => student.name.toLowerCase().search(value.toLowerCase()) !== -1);
    this.customRanking.emit(orderBy(this.cloneRanking, ['factor'], ['desc']));
  }

  public orderRanking() {
    this.cloneRanking.forEach((ranking) => {
      ranking.factor = (
        (this.filters.effort * ranking.interactionUntilDay) +
        (this.filters.engagement * (ranking.progressUntilDay / 10)) +
        (this.filters.valuation * ranking.valuation) +
        (this.filters.event * ranking.averageEventGrade) +
        (this.filters.learning * ranking.learning) +
        (this.filters.participation * ranking.participation)
        /
        sum(values(this.filters))
      );
    });

    this.customRanking.emit(
      orderBy(
        this.cloneRanking,
        ['factor', 'valuation', 'averageEventGrade', 'learning', 'progressUntilDay', 'interactionUntilDay', 'participation'],
        ['desc']
      )
    );
  }
}
