import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { Groups } from 'src/app/models/groups.model';
import { ValuationTest } from 'src/app/models/valuation-test.interface';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { GenerateValuationTestCheckOptionsComponent } from 'src/app/shared/components/generate-valuation-test-check-options/generate-valuation-test-check-options.component';

import { Data } from 'src/app/shared/components/generate-valuation-test-check-options/model/data.model';
import { GroupsService } from '../../_services/groups.service';
import { SettingsValuationTestsService } from '../../_services/valuation-tests.service';

@Component({
  selector: 'app-new-valuation-test-group',
  templateUrl: './new-valuation-test-group.component.html',
  styleUrls: ['./new-valuation-test-group.component.scss']
})
export class NewValuationTestGroupComponent extends NotificationClass implements OnInit {

  @ViewChild('checkedComponent')
  checkedComponent: GenerateValuationTestCheckOptionsComponent;

  public valuations: Data[] = [];
  public filteredValuations: Data[] = [];
  public group: Groups = new Groups();

  public isNewGroup: boolean;


  constructor(private _settingsValuationTestsService: SettingsValuationTestsService,
    private _activatedRoute: ActivatedRoute,
    private _groupsService: GroupsService,
    _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  ngOnInit(): void {

    const groupId = this._activatedRoute.snapshot.params['groupId'];
    this.isNewGroup = isNil(groupId);

    this.loadFunctions(groupId);

  }

  public loadFunctions(groupId: string) {

    forkJoin({
      valuations: this._settingsValuationTestsService.GetAllPreview(),
      groups: this.isNewGroup ? of({ data: new Groups() }) : this._groupsService.GetById(groupId),
    }).subscribe(response => {
      this.group = response.groups.data;
      this.valuations = this.sortAlphabetically(response.valuations.data.map(test => (
        {
          id: test.id,
          title: test.title,
          checked: this.group.group.some(groupItem => groupItem.id == test.id)
        } as Data
      )));

    });

  }

  public sortAlphabetically(data: any[]) {
    return data;
  }

  public handleSelectedData(data: any[]) {
    this.group.group = data;
  }

  public save() {
    if (this.isValidateGroup(this.group)) {
      if (this.isNewGroup) {
        this._groupsService.createGroup(this.group).subscribe(response => {
          this.group.id = response.data.id;
          this.isNewGroup = false;
          this.notify('Grupo salvo com sucesso');
        });
      } else {
        this._groupsService.updateGroup(this.group).subscribe(response => {
          this.group.id = response.data.id;
          this.notify('Grupo salvo com sucesso');
        });
      }
    }
  }


  public isValidateGroup(group: Groups): boolean {
    if (isNil(group.title) && group.title === '') {
      this.notify('O titulo não pode ser nulo');
      return false;
    }

    if (group.group.length === 0) {
      this.notify('Selecione ao menos uma avaliação para o grupo');
      return false;
    }

    return true;
  }

  public search(searchTerm: string) {

    // ##TODO refatorar esse método para utilizar a melhorias implementadas no componente app-generate-valuation-test-check-options implementadas no plano de estudos

    if (searchTerm == '') {
      this.checkedComponent.data = this.valuations;
    } else {
      this.checkedComponent.data = this.valuations.filter(valuation => valuation.title.includes(searchTerm));
    }

    this.checkedComponent.dataSelected = this.sortAlphabetically(this.group.group.map(g => (
      {
        id: g.id,
        title: g.title,
        checked: true
      } as Data
    )));

  }
}
