import { Component, Input } from '@angular/core';
import { Data } from '@angular/router';

@Component({
  selector: 'app-generate-valuation-test-accordion',
  templateUrl: './generate-valuation-test-accordion.component.html',
  styleUrls: ['./generate-valuation-test-accordion.component.scss']
})
export class GenerateValuationTestAccordionComponent {

  @Input() data: Data[];
  public get getData() { return this.data; }
  constructor() { }



}
