<div class="inner-content">
    <div class="filter-container">
        <app-list-search
            placeholder="Pesquise..."
            (triggerSearch)="triggerQuestionSearch($event)"
        ></app-list-search>
        <div class="filters">
            <div class="form-content">
                <label>Modulo</label>
                <!-- Encontrar uma outra solução para o placeholder -->
                <mat-form-field>
                    <mat-select
                        panelClass="cockpit-mat-panel"
                        [(value)]="selectedModule"
                        [placeholder]="selectedModule?.title"
                        (selectionChange)="handleSelectModuleFilterChange($event.value)"
                        (openedChange)="openedChange($event)"
                     >
                        <input class="input-search" 
                            id="input-search"
                            placeholder ="Pesquise...." 
                            (keyup)="onKey($event.target.value)" 
                            (keydown)="$event.stopPropagation()"
                        > 
                        <mat-option 
                            style="color: black; width: 600px !important;" 
                            [attr.title]="'Todos'"
                            [value]="''"
                        >
                            Todos
                        </mat-option>
                        <mat-option 
                            *ngFor="let module of modules"
                            style="color: black; width: 600px !important;" 
                            [attr.title]="module.title"
                            [value]="module"
                        >
                            {{module.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-content">
                <label>Assunto</label>
                <mat-form-field>
                    <mat-select 
                    [(ngModel)]="selectedSubject" 
                    (selectionChange)="handleSelectSubjectsFilterChange($event.value)" 
                    [value]="selectedSubject" >
                        <ng-container *ngIf="selectedModule" >
                            <mat-option [value]="-1">Todos</mat-option>
                            <mat-option 
                                *ngFor="let subject of selectedModule.subjects" 
                                style="color: black" 
                                [value]="subject"
                            >
                                {{subject.title}}
                            </mat-option>
                        </ng-container>  
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="moduleQuestions" class="mat-elevation-z8 table-top">

        <ng-container matColumnDef="module">
            <th width="20%" mat-header-cell *matHeaderCellDef>Modulo</th>
            <td width="20%" mat-cell *matCellDef="let row">
                {{row.module ? row.module.title : '--'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="subject">
            <th width="20%" mat-header-cell *matHeaderCellDef>Assunto</th>
            <td width="20%" mat-cell *matCellDef="let row">
                {{row.subject ? row.subject.title : '--'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="question">
            <th width="20%" mat-header-cell *matHeaderCellDef>Questão</th>
            <td width="20%" mat-cell *matCellDef="let row">
                {{row.moduleQuestion.text}}
            </td>
        </ng-container>

        <ng-container matColumnDef="everageGrade">
            <th width="5%" mat-header-cell *matHeaderCellDef>Nota Média</th>
            <td width="5%" mat-cell *matCellDef="let row">
                {{getModuleQuestionEverageGrade(row) | number: '1.2-2'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="positive">
            <th width="5%" mat-header-cell *matHeaderCellDef>
                <img
                    class="icon"
                    src="./assets/img/like.png"
                    title="Avaliações positivas"
                />
            </th>
            <td width="5%" mat-cell *matCellDef="let row">
                {{row.moduleQuestion.likes}}
            </td>
        </ng-container>

        <ng-container matColumnDef="negative">
            <th width="5%" mat-header-cell *matHeaderCellDef>
                <img
                    class="icon"
                    style="transform: rotate(180deg);"
                    src="./assets/img/like.png"
                    title="Avaliações negativas"
                />
            </th>
            <td width="5%" mat-cell *matCellDef="let row">
                {{row.moduleQuestion.dislikes}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th width="10%" mat-header-cell *matHeaderCellDef>Status</th>
            <td width="10%" mat-cell *matCellDef="let row">
                <p [ngStyle]="{'color': getModuleTagStatusColor(row)}">
                    {{getModuleQuestionStatus(row)}}
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="approval">
            <th width="10%" mat-header-cell *matHeaderCellDef>Aprovação</th>
            <td width="10%" mat-cell *matCellDef="let row">
                <div class="approval-container">
                    <img
                        class="icon left-icon icon-click"
                        src="./assets/img/right-answer-colored.png"
                        title="Aprovar"
                        (click)="changeModuleQuestionStatus(row, 2)"
                    />
                    <img
                        class="icon icon-click"
                        src="./assets/img/wrong-answer-colored.png"
                        title="Reprovar"
                        (click)="changeModuleQuestionStatus(row, 3)"
                    />
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th width="5%" mat-header-cell *matHeaderCellDef></th>
            <td width="5%" mat-cell *matCellDef="let row">
                <img
                    class="icon icon-click"
                    src="./assets/img/view.png"
                    title="Aprovar"
                    (click)="moduleQuestionDialog(row)"
                />
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <app-pagination *ngIf="moduleQuestionsCount"
        [setPagination]="{
            'itemsCount': moduleQuestionsCount,
            'pageSize': pageSize
        }"
        (goToPage)="goToPage($event)">
    </app-pagination>
</div>