<h3>{{ content.title }}</h3>
<h4>{{ content.subjectTitle }}</h4>

<div id="htmlEditor"></div>

<div class="content">
  <div class="info">
    <p class="label">
       URL DO LINK
      </p>
        <mat-form-field>
            <input matInput placeholder="URL"
            [(ngModel)]="url" required />
        </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Sobre (máx. MMM caracteres)"
        [(ngModel)]="content.excerpt" required></textarea>
    </mat-form-field>
  </div>
</div>

<div class="actions">
  <button class="btn-outline" (click)="dismiss()">
    Cancelar
  </button>
  <button class="btn-test" (click)="save()">
    Salvar
  </button>
</div>
