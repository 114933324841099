import { NpsAnswerModule } from './../../settings/nps/nps-answer/nps-answer.module';
import { NpsAnswerComponent } from 'src/app/settings/nps/nps-answer/nps-answer.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ExamComponent } from './exam.component';
import { ExamStartComponent } from './exam-start/exam-start.component';
import { ExamQuestionComponent } from './exam-question/exam-question.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExamFooterComponent } from './exam-footer/exam-footer.component';
import { ProgressBarModule } from '../../shared/components/layout/progress-bar/progress-bar.module';
import { ExamFinishComponent } from './exam-finish/exam-finish.component';
import { ExamReviewComponent } from './exam-review/exam-review.component';
import { ContentModule } from '../content/content.module';
import { BadgesProgressModule } from '../../shared/components/layout/badges-progress/badges.progress.module';
import { ExamAnswerComponent } from './exam-question/answer/answer.component';
import { ExamReviewContentListComponent } from './exam-review/content-list/content-list.component';
import { ContentExamService } from '../_services/exam.service';
import { RouterModule } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { MatIconModule } from '@angular/material/icon';
import { ExamQuestionDiscursiveComponent } from './exam-question/exam-question-discursive/exam-question-discursive.component';
import { ExamHeaderComponent } from './exam-header/exam-header.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExamDialogBlockedComponent } from './exam-dialog-blocked/exam-dialog-blocked.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ExamNpsComponent } from './exam-nps/exam-nps.component';

@NgModule({
    declarations: [
        ExamComponent,
        ExamStartComponent,
        ExamQuestionComponent,
        ExamAnswerComponent,
        ExamFooterComponent,
        ExamFinishComponent,
        ExamReviewComponent,
        ExamReviewContentListComponent,
        ExamQuestionDiscursiveComponent,
        ExamHeaderComponent,
        ExamDialogBlockedComponent,
        ExamNpsComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressBarModule,
        ContentModule,
        BadgesProgressModule,
        RouterModule,
        MarkdownToHtmlModule,
        MatIconModule,
        DirectivesModule,
        MatTooltipModule,
        MatDialogModule,
        NpsAnswerModule,
    ],
    providers: [ContentExamService, AnalyticsService],
    exports: [ExamQuestionComponent, ExamFooterComponent, ExamQuestionDiscursiveComponent, ExamNpsComponent, NpsAnswerComponent]
})
export class ExamModule {}
