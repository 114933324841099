<div class="step">
  <div class="title-wrapper">
    <span class="title">Informações do programa</span>
  </div>

  <div class="wrapper">
    <form [formGroup]="form" autocomplete="off" action="">
      <input autocomplete="false" name="hidden" type="text" style="display:none;">

      <mat-form-field appearance="standard">
        <mat-label>NOME DO PROGRAMA</mat-label>
        <input formControlName="title" matInput />
        <mat-error>É necessário informar um nome para o programa</mat-error>
      </mat-form-field>

      <div class="input-group">
        <mat-form-field appearance="standard">
          <mat-label>DATA INÍCIO</mat-label>
          <input (click)="startDatePicker" formControlName="startDate" matInput [matDatepicker]="startDatePicker" />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error>É necessário informar uma data de início para o programa</mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>DATA FIM</mat-label>
          <input formControlName="endDate" matInput [matDatepicker]="endDatePicker" />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <mat-error>É necessário informar uma data de término para o programa</mat-error>
        </mat-form-field>
      </div>

      <app-cascate-select #cascateSelect (valueChange)="handleSelectSectorAndSegment($event)"
        [selectedSegment]="selectedSegment" [selectedSector]="selectedSector">
      </app-cascate-select>

      <mat-form-field appearance="standard">
        <mat-label>DESCRIÇÃO</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="10" formControlName="description"> </textarea>
        <mat-error>É necessário informar uma descrição para o programa</mat-error>
      </mat-form-field>



    </form>

  </div>

</div>
