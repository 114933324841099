import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  A,
  Z,
  ZERO,
  NINE,
  SPACE
} from '@angular/cdk/keycodes';
import { debounceTime } from 'rxjs/operators';
import { isEmpty, isNil } from 'lodash';
@Component({
  selector: 'acd-array-filter',
  templateUrl: './array-filter.component.html',
  styleUrls: ['./array-filter.component.scss']
})
export class ArrayFilterComponent implements OnInit {
  @ViewChild('input', { static: true }) input;

  private _data: any[];
  @Input()
  public get data(): any[] { return this._data; }
  public set data(value: any[]) { this._data = value; }

  @Input() placeholder: string;
  @Input() color: string;
  @Input() displayMember: string;
  @Input() showProgress = true;
  @Input() noResultsMessage = 'Sem resultados';
  @Input() hasGroup: boolean;
  @Input() groupArrayName: string;

  public noResults = false;

  @Input() public isLoading = false;
  @Output() public filteredReturn = new EventEmitter<any>();
  @Output() public valueChange = new EventEmitter<string>();

  public filteredItems: any = [];
  public formGroup: FormGroup;

  constructor(fb: FormBuilder) {
    this.formGroup = fb.group({ filter: '' });
  }

  public ngOnInit() {
    this.formGroup
      .get('filter')
      .valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => {
        if (this.showProgress) {
          this.isLoading = true;
        }
        if (value) {
          // IF THE DISPLAY MEMBER INPUT IS SET WE CHECK THE SPECIFIC PROPERTY
          if (this.displayMember == null) {
            this.filteredItems = this.data.filter(name => name.toLowerCase().includes(value.toLowerCase()));
            // OTHERWISE, WE CHECK THE ENTIRE STRING
          } else if (this.hasGroup && this.groupArrayName && this.displayMember) {
            this.filteredItems = this.data.map(a => {
              const objCopy = Object.assign({}, a);
              objCopy[this.groupArrayName] = objCopy[this.groupArrayName]
              .filter(g => g[this.displayMember]
                .toLowerCase()
                .includes(value.toLowerCase()));
              return objCopy;
            }).filter(x => x[this.groupArrayName].length > 0);
          } else {
            this.filteredItems = this.data.filter(name => name[this.displayMember].toLowerCase().includes(value.toLowerCase()));
          }
          // NO RESULTS VALIDATION
          this.noResults = isEmpty(this.filteredItems) || isNil(this.filteredItems);

        } else {
          this.filteredItems = this.data.slice();
          this.noResults = false;
        }

        this.valueChange.emit(value);
        this.filteredReturn.emit(this.filteredItems);

      });

    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 500);
    if (!this.placeholder) {
      this.placeholder = 'Buscar...';
    }
  }

  public clearInput(input: HTMLInputElement) {
    input.value = '';
    this.valueChange.emit('');
  }

  public handleKeydown(evt: KeyboardEvent) {
    // PREVENT PROPAGATION FOR ALL ALPHANUMERIC CHARACTERS IN ORDER TO AVOID SELECTION ISSUES
    if ((evt.key && evt.key.length === 1) ||
      // tslint:disable-next-line: deprecation
      (evt.keyCode >= A && evt.keyCode <= Z) ||
      // tslint:disable-next-line: deprecation
      (evt.keyCode >= ZERO && evt.keyCode <= NINE) ||
      // tslint:disable-next-line: deprecation
      (evt.keyCode === SPACE)) {
      evt.stopPropagation();
    }
  }

}
