import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentComponent } from './segment.component';
import { NewSegmentComponent } from './new-segment/new-segment.component';
import { NavigationBackModule } from 'src/app/shared/components/navigation-back/navigation-back.module';
import { PaginationModule } from 'src/app/shared/components/pagination/pagination.module';
import { MatIconModule } from '@angular/material/icon';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SegmentComponent, NewSegmentComponent],
  imports: [
    CommonModule,
    NavigationBackModule,
    PaginationModule,
    MatIconModule,
    MaterialComponentsModule,
    FormsModule
  ]
})
export class SegmentModule { }
