<div class="inner-content" >
  <nav>
    <a href="javascript:;" (click)="back()" >
      <img src="./assets/img/arrow-back.png" />
      voltar para minhas vagas
    </a>
    <h2>{{ job?.title | uppercase }}</h2>
    <button class="btn-test primary" (click)="searchJobApplicants()">
      Buscar Candidatos
    </button>
  </nav>

  <div class="header">
    <div class="logo-container">
      <!--img [src]="job?.logoUrl ? job.logoUrl : './assets/img/logo-proseek.png'" /-->
      <img src="./assets/img/logo-proseek.png"/>      
      <div class="dropdown-container">
        <p><span>Status: </span></p>
        <mat-select [(ngModel)]="jobStatus" (selectionChange)="changeJobStatus()">
          <mat-option [value]="1">
            Vaga Aberta
          </mat-option>
          <mat-option [value]="2">
            Vaga Fechada
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="info-container">
      <p>Nome Vaga: <b>{{ job?.title }}</b></p>
      <p>Prazo de Conclusão: <b>{{ job?.dueTo | date: 'dd/MM/yyyy'}}</b></p>
      <p>Prioridade: <b class="danger">{{ getPriorityStatus() }}</b></p>
    </div>
  </div>

  <mat-tab-group>
    
    <mat-tab class="labelColor"label="CANDIDATOS CONFIRMADOS">
      <app-rec-confirmed></app-rec-confirmed>
    </mat-tab>

    <mat-tab>
        <ng-template matTabLabel>
            <span matBadge="400" 
              matBadgeOverlap="false"
              matBadgePosition="above after"
              >CANDIDATOS PEDENTES
            </span>
        </ng-template>
          <app-rec-pendents></app-rec-pendents>    
      </mat-tab>

  </mat-tab-group>
</div>
  