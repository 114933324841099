import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, isArray, isEmpty, isNil } from 'lodash';
import { NotificationClass } from 'src/app/shared/classes/notification';
@Component({
  selector: 'acd-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent<T = Partial<{ id: string }>> extends NotificationClass {

  private _value: T | T[];
  @Input()
  public get value(): T | T[] {
    return this._value;
  }
  public set value(v: T | T[]) {
    this._value = v;
    this.selectedItems = v instanceof Array ? v : [];
    this.valueChange.emit(this._value);
  }

  @Output() valueChange = new EventEmitter<T | T[]>();

  @Input() readonly displayMember: string;
  @Input() public showFilter: boolean;

  private _data: T[];
  @Input() public get data(): T[] { return this._data; }
  public set data(value: T[]) {
    if (isArray(value) && !isEmpty(value) && value.length > 0) {
      value = value.map(v => {
        v['showValue'] = true;
        return v;
      });
      this._data = value;
      this.filteredReturn = value;
      if (!this.disabledSelectedItems) {
        this._data = this.setSelectedData(this.data);
        this.filteredReturn = this.setSelectedData(this.filteredReturn);
      }
      if (isNil(this._initialData)) this._initialData = value;
    } else {
      this._data = [];
      this.filteredReturn = [];
    }
  }

  private _initialData: any | any[];

  @Input() disabled = false;
  @Input() disabledSelectedItems = false;
  @Input() readonly placeholder: string = 'Selecione';
  @Input() readonly filterPlaceholder: string = 'Digite';
  @Input() public isLoading: boolean;
  @Input() public multiple: boolean;
  @Output() public selectionChange = new EventEmitter<T[] | T>();
  @Output() public focusOut = new EventEmitter<T[] | T>();
  @Output() public openedChange = new EventEmitter<boolean>();
  @Output() public filterChange = new EventEmitter<string>();

  public selectedItems = [];
  public filteredReturn: T[];

  constructor(
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);
  }

  public selectionChange$($event: MatSelectChange) {
    if (!this.multiple) {
      this.value = $event.value;
      this.selectionChange.emit(this.value);
    }
  }

  public selectionChangeMultiple(event: any) {
    if (this.multiple) {
      const index = this.selectedItems.findIndex(value => value.id === event.id);
      if (index === -1) {
        this.selectedItems.push(event);
      } else {
        this.selectedItems.splice(index, 1);
      }

      this.value = this.selectedItems;

      this.selectionChange.emit(this.value);
    }
  }

  public openedChange$(isOpened: boolean) {
    if (!this.displayMember && isOpened) throw new Error('Necessário informar o identificador displayMember');

    if (isNil(this.filteredReturn) && this.data && this.data.length) {
      this.filteredReturn = [...this.data];
    }

    if (!isOpened) {
      if (this.disabledSelectedItems) {
        this.value = undefined;
      } else {
        this._initialData = this.setSelectedData(this._initialData, true);
        this._data = this.setSelectedData(this._data, true);
      }

      this.filteredReturn = this._initialData;
    }

    this.openedChange.emit(isOpened);
  }

  public filterValues(values) {
    if (!isNil(values)) {
      this.filteredReturn = [...values];
    }
  }

  compareFn(t1: any, t2: any): boolean {
    return t1 && t2 && t1.id === t2.id;
  }

  public setSelectedData(arr: Array<any>, showAllValues: boolean = false) {
    let arrValues = cloneDeep(arr);
    this.selectedItems.forEach(value => {
      const index = arrValues.findIndex(item => item['id'] === value.id);
      if (index === -1) {
        value['showValue'] = showAllValues ? true : false;
        arrValues = [...arrValues, value];
      } else {
        value['showValue'] = true;
        arrValues.splice(index, 1);
        arrValues = [...arrValues, value];
      }
    });

    return arrValues;
  }
}
