<div class="step">
  <h2>Informações da Trilha</h2>
  <form autocomplete="off" method="post" action="" [formGroup]="formGroup">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">

    <div class="info">
      <div class="validation-field">
        <mat-form-field>
          <input matInput placeholder="Nome" formControlName="title" required />
        </mat-form-field>
        <control-messages [control]="formGroup.get('title')"></control-messages>
      </div>
      <div style="display: flex;">
        <div class="validation-field">
          <mat-form-field>
            <mat-select placeholder="Visibilidade" formControlName="published" required>
              <mat-option [value]="false">Curso</mat-option>
              <mat-option [value]="true">Aberta</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="validation-field">
          <mat-form-field>
            <mat-select placeholder="Disponibilidade" formControlName="availability" required>
              <mat-option [value]="courseAvailabilityEnum.available">Disponível</mat-option>
              <mat-option [value]="courseAvailabilityEnum.unavailable">Indisponível</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="validation-field">
          <mat-form-field>
            <input matInput placeholder="Valido por quantos dias?" formControlName="validFor" />
          </mat-form-field>
        </div>
      </div>
      <div class="validation-field" *ngIf="hasEcommerceIntegration">
        <ng-container *ngIf="!track.id">
          <mat-form-field>
            <mat-select [placeholder]="'Criar Trilha no Ecommerce'" formControlName="createInEcommerce"
              [required]="!track.id">
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
      <div style="display: flex;">
        <div class="validation-field">
          <mat-form-field>
            <!-- placeholder="Dados de carreira obrigatórios?" -->
            <mat-select placeholder="Dados de perfil profissional obrigatórios?" formControlName="requireUserCareer"
              (selectionChange)="requiredUserCareerDisabled" required>
              <mat-option [value]="true">Sim</mat-option>
              <mat-option [value]="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="validation-field">
          <mat-form-field>
            <input type="text" matInput placeholder="A partir de quantos % bloquear a trilha?" mask="00"
              formControlName="allowedPercentageWithoutCareerInfo" />
          </mat-form-field>
        </div>
      </div>
      <div style="display: flex;">
        <div class="validation-field">
          <mat-form-field>
            <input matInput placeholder="URL do Produto" formControlName="storeUrl" />
          </mat-form-field>
          <control-messages [control]="formGroup.get('storeUrl')"></control-messages>
        </div>
        <div class="validation-field">
          <mat-form-field>
            <input matInput placeholder="Tempo de duração em horas" type="number" formControlName="duration" />
          </mat-form-field>
          <control-messages [control]="formGroup.get('duration')"></control-messages>
          <mat-error class="error"
            *ngIf="formGroup.get('duration')?.errors?.durationIsInvalid && formGroup.get('duration').touched">0 Não é um
            valor válido
          </mat-error>
        </div>
      </div>
      <div class="validation-field">
        <mat-form-field>
          <input matInput placeholder="URL do E-commerce" formControlName="ecommerceUrl" />
        </mat-form-field>
        <control-messages [control]="formGroup.get('ecommerceUrl')"></control-messages>
      </div>

      <div class="validation-field">
        <label>Solicitante</label>
        <app-list-search [noPadding]="true" placeholder="Digite o nome de um usuário para adicioná-lo como Solicitante"
          (triggerSearch)="triggerRequesterSearch($event)"></app-list-search>
        <ul class="user-list" *ngIf="requesters && requesters.length > 0" (clickOutside)="resetRequestersSearch()">
          <li *ngFor="let requester of requesters" (click)="addRequester(requester)">
            <img class="logo"
              [src]="requester.imageUrl ? requester.imageUrl : './assets/img/user-image-placeholder.png'" />
            <span>{{ requester.name }}</span>
          </li>
        </ul>
        <div class="chips" *ngIf="selectedRequester && selectedRequester != ''">
          <p>
            <img class="logo"
              [src]="RequestersimageUrl ? RequestersimageUrl : './assets/img/user-image-placeholder.png'" />
            {{ selectedRequester }}
            <span (click)="removeSelectedRequester()">X</span>
          </p>
        </div>
      </div>

      <div class="validation-field">
        <label class="title">Tipo de cálculo de engajamento</label>
        <div class="select-type">
          <mat-radio-group aria-label="Tipo de cálculo de engajamento" formControlName="engagementMethod">
            <mat-radio-button [value]="engagementMethod.ByDays">Por Dias</mat-radio-button>
            <mat-radio-button [value]="engagementMethod.ByCutOfDate">Por data de corte</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="validation-field">
        <mat-form-field>
          <textarea matInput placeholder="Resumo (max 600 caracteres)" formControlName="description"
            required></textarea>
        </mat-form-field>
        <control-messages [control]="formGroup.get('description')"></control-messages>
      </div>


      <div class="tags col-start-1 col-end-3">
        <p class="title">TAGS</p>
        <div class="tags">
          <img class="busca" src="./assets/img/seach-black.png" />
          <input matInput required (keyup.enter)="addTag($event)"
            placeholder="Escreva os temas pertinentes a este módulo para adicioná-los como TAGS" />
          <div class="tag" *ngFor="let tag of formGroup.get('tags').value; let index = index">
            {{ tag }}
            <img (click)="removeTag(index)" src="./assets/img/close.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="images">
      <div class="image-upload">
        <p class="title">Imagem de Capa (420x210)</p>
        <img [src]="formGroup.get('imageUrl').value" />
        <button type="button" class="btn-test" (click)="uploadImage(420, 210, 'imageUrl')">
          Fazer Upload de Imagem
        </button>
      </div><br>
      <div class="validation-field" *ngIf="hasProfileTest">
        <label>Teste de Perfil</label>
        <app-list-search [inputValue]="selectedTest" [noPadding]="true" (triggerSearch)="triggerTestSearch($event)">
        </app-list-search>
        <ul class="user-list" *ngIf="tests && tests.length > 0" (clickOutside)="removeTest()">
          <li *ngFor="let test of tests" (click)="setTest(test)">
            <span>{{ test.title }}</span>
          </li>
        </ul>
        <div class="tag" *ngIf="formGroup.get('profileTestId').value && formGroup.get('profileTestId').value !== ''">
          {{ selectedTest }}
          <img (click)="removeTest()" src="./assets/img/close.png" />
        </div>
      </div>

      <div>
        <div class="validation-field">
          <mat-form-field>
            <mat-select (selectionChange)="changeTrackAreaId($event)" formControlName="trackAreaId" placeholder="Área"
              [compareWith]="comparefn">
              <mat-option *ngFor="let trackArea of trackAreas" [value]="trackArea.id">{{ trackArea.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div>
        <div class="validation-field">
          <mat-form-field>
            <mat-select (selectionChange)="changeTrackSubAreaId($event)" formControlName="trackSubAreaId"
              placeholder="Sub-Área" [compareWith]="comparefn">
              <mat-option *ngFor="let trackSubArea of trackSubAreas" [value]="trackSubArea.id">{{ trackSubArea.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="validation-field">
        <app-cascate-select #cascateSelect [selectedSegment]="track.segment" [selectedSector]="track.sector">
        </app-cascate-select>
      </div>

      <div class="validation-field">
        <mat-form-field floatLabel="always">
          <input matInput floatLabel="never" placeholder="Tempo em dias para finalização da trilha" type="number"
            formControlName="timeInDaysToCompleteTheTrack" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
