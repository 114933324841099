import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged } from 'rxjs/operators';
import { Module } from 'src/app/models/module.model';
import { ModulePreview } from 'src/app/models/previews/module.interface';
import { TrackPreview } from 'src/app/models/previews/track.interface';
import { StudyPlanService } from 'src/app/settings/_services/study-plan.service';
import { IRequestFilter } from '../Interface/request-filter';
import { IRequesrtSort } from '../Interface/request-sort';
import { MyCoursesService } from '../services/my-courses.service';
import { ProgramPreview } from 'src/app/settings/programs/Models/programPreview.model';
import { ProgramsService } from '../../_services/programs.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-my-vision',
  templateUrl: './my-vision.component.html',
  styleUrls: ['./my-vision.component.scss']
})
export class MyVisionComponent implements OnInit, OnDestroy {
  public readonly pageSize: number = 8;
  public replacement = environment.replecement;

  public courses: Array<TrackPreview | ModulePreview> = [];
  public coursesPage: number = 1;
  public coursesCount: number = 0;

  public library: Array<TrackPreview | ModulePreview> = [];
  public libraryPage: number = 1;
  public libraryCount: number = 0;

  public programs: Array<ProgramPreview>;
  public programsCount: number = 0;
  private _programsPage: number = 1;

  public sectorFilters: IRequestFilter[];
  public sort: IRequesrtSort;
  private subscriptionArray: Subscription[] = [];

  constructor(private _studyPlanService: StudyPlanService,
    private _myCoursesService: MyCoursesService,
    private _programsService: ProgramsService) { }

  ngOnInit() {
    this.callFunctionLoad();
    this.setSubscriptionFilters();
    this.setSubscriptionSort();
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  public LoadMyFavorite(page: number) {
    this._studyPlanService.GetMyFavoriteCourse(page, this.pageSize, null, this.sectorFilters, this.sort).subscribe(response => {
      this.courses = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
      this.coursesCount = response.data.itemsCount;
    });
  }

  public LoadMyLibrary(page: number) {
    this._studyPlanService.GetMyLibrary(page, this.pageSize, this.sectorFilters, this.sort).subscribe(response => {
      this.library = this._studyPlanService.ParseStudyPlanItem(response.data.courses);
      this.libraryCount = response.data.itemsCount;
    });
  }

  private _loadPrograms(): void {
    this._programsService.getPagedProgramsFilteredList(this._programsPage, 8).subscribe((response) => {
      this.programs = response.data.programs;
      this.programsCount = response.data.itemsCount;
    });
  }

  public setSubscriptionFilters() {
    this.subscriptionArray.push(this._myCoursesService.getFilter().pipe(distinctUntilChanged()).subscribe(value => {
      this.sectorFilters = value;
      this.callFunctionLoad();
    }));
  }

  public setSubscriptionSort() {
    this.subscriptionArray.push(this._myCoursesService.getSort().pipe(distinctUntilChanged()).subscribe(value => {
      this.sort = value;
      this.callFunctionLoad();
    }));
  }

  public callFunctionLoad() {
    this.LoadMyFavorite(this.coursesPage);
    this.LoadMyLibrary(this.libraryPage);
    this._loadPrograms();
  }

  public goToPage(data: { page: number, type: 'myFavorite' | 'myLibrary' }) {
    const functions = {
      'myFavorite': () => {
        this.coursesPage = data.page;
        this.LoadMyFavorite(this.coursesPage);
      },
      'myLibrary': () => {
        this.libraryPage = data.page;
        this.LoadMyLibrary(this.libraryPage);
      }
    };

    functions[data.type]();
  }
}
