<div class="inner-content">
  <app-navigation-back></app-navigation-back>
  <app-study-plan-header [defaultValue]="studyPlan.name" (planStudyNameEmiiter)="setStudyPlanName($event)"
    (buttonClick)="save()">
  </app-study-plan-header>
  <div class="main">
    <div class="content">
      <div class="header">
        <button class="btn-test primary" (click)="openDialog(EnumConnect.course)">Vincular Módulo/Trilha</button>
      </div>
      <div style="height: calc(100% - 53px)">
        <app-draging-list [dataSource]="studyPlansItems" (remove)="remove($event, EnumConnect.course)">
        </app-draging-list>
      </div>
    </div>
    <div class="content">
      <div class="header">
        <button class="btn-test primary" (click)="confirmRemoveAllUsers()">Desvincular Todos</button>
        <button class="btn-test primary" (click)="openDialogImport()">Importar Alunos</button>
        <button class="btn-test primary" (click)="openDialog(EnumConnect.users)">Vincular Alunos</button>
      </div>
      <div style="height: calc(100% - 63px)">
        <app-paged-list [users]="pagedUsers" [itensCount]="itensCount" [pageSize]="pageSize"
          (goToPage)="goToPage($event)" (search)="search($event)" (removeUser)="removeUser($event)"></app-paged-list>
      </div>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="header">
      <h3>TREINAMENTOS INSTITUCIONAIS</h3>
      <button class="btn-test" (click)="openDialog(EnumConnect.institutionalTraining)">Vincular Módulo/Trilha</button>
    </div>

    <div class="content">
      <app-draging-list [dataSource]="institutionalTraining"
        (remove)="remove($event, EnumConnect.institutionalTraining)"></app-draging-list>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="header">
      <h3>TREINAMENTOS OBRIGATÓRIOS</h3>
      <button class="btn-test" (click)="openDialog(EnumConnect.mandatoryTraining)">Vincular Módulo/Trilha</button>
    </div>

    <div class="content">
      <app-draging-list [dataSource]="mandatoryTraining" (remove)="remove($event, EnumConnect.mandatoryTraining)">
      </app-draging-list>
    </div>
  </div>
</div>
