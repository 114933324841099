<div class="video-content" [ngClass]="{ 'resumed': resumedView }">
  <div class="inner-content">
    <div id="videoContent" [style.pointerEvents]="preventPointerEvents ? 'none' : 'initial'"></div>
  </div>
  <!-- <div style="color:white">{{markers | json}}</div> -->

  <div class="timeline-container" *ngIf="!resumedView">
    <div class="inner-content">
      <div #timelineBar class="timeline">
        <p class="tooltip-container left50">
          <app-custom-tooltip 
            [showTooltip]="tooltip1"
            [headerText]="'Passo 01/02'"
            [text]="'Clique em um ponto da barra do vídeo para adicionar uma tag.'"
            [footerText]="'Clique em qualquer lugar para continuar'"
            (closeTooltip)="setTooltip(2)"
          ></app-custom-tooltip>
        </p>
        <ng-container *ngFor="let marker of markers">
          <p class="marker"
          (mouseenter)="marker.hovered = true"
          (mouseleave)="marker.hovered = false"
          [style.left]="getMarkerPosition(marker.position)"
          (click)="setVideoPosition(marker.position)"
          [ngClass]="{ 'userMarker': marker.tag }">
            c
          </p>
          <ng-container *ngIf="marker.hovered">
            <div class="triangle" [style.left]="getMarkerPosition(marker.position, 2)" [ngClass]="{ 'userTriangle': marker.tag }"></div>
            <p class="tooltip" 
            [style.left]="getMarkerPosition(marker.position, -5)"
            [ngClass]="{ 'userTooltip': marker.tag }">
              {{ marker.concept }}
            </p>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="add-tag-container">
    
    <button class="btn-test" (click)="OpenTagDialog()" *ngIf="canCreateTags">
      Adicionar/Avaliar Conceito
      <p class="tooltip-container button">
        <app-custom-tooltip
          [showTooltip]="tooltip2" 
          [headerText]="'Passo 02/02'"
          [text]="'Ou clique em adicionar conceito para poder configurar novo tag.'"
          (closeTooltip)="setTooltip()"
        >
        </app-custom-tooltip>
      </p>
    </button>
  </div>
</div>
