<div class="inner-content">
  <nav>
    <a href="javascript:history.back()">
      <i class="logo icon-seta_direita" style="font-size: 26px;"></i>
      voltar
    </a>
    <div class="buttons" *ngIf="track?.storeUrl || track?.ecommerceUrl">
      <button class="btn-test" *ngIf="track?.storeUrl" (click)="goToPageStoreUrl()">Saiba Mais</button>
      <button class="btn-test" *ngIf="track?.ecommerceUrl" (click)="goToPageEcommerceUrl()">Cadastrar-se </button>
    </div>
  </nav>

  <div class="header">
    <app-track-description [track]="track"></app-track-description>
  </div>

  <mat-tab-group *ngIf="track" [selectedIndex]="selectedTab" (selectedTabChange)="onTabSelected($event)" animationDuration="0ms">

    <mat-tab [label]="tabLabel.OPERATIONAL">
      <ng-template matTabContent>
        <div class="content">
          <app-track-overview-path
            [track]="track"
            [lateStudents]="true"
            [allowOverview]="true"
            [hasSubprogress]="true">
          </app-track-overview-path>

          <app-track-overview-students
            [track]="trackStudents"
            [itemsCount]="track?.studentsCount"
            (goToPage)="goToPage($event)"
            (searchStudent)="searchStudent($event)">
          </app-track-overview-students>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [label]="tabLabel.MANAGEMENT" >
      <ng-template matTabContent>
        <app-track-overview-badges
          [progresses]="track?.studentsProgress"
          [modulesConfiguration]="track?.modulesConfiguration"
          [topPerformants]="track?.topPerformants">
        </app-track-overview-badges>

        <div *ngIf="!userProgress;else Dashboard">
          <div class="loader-wrapper">
            <span>Carregando os dados...</span>
            <mat-spinner [diameter]="25"></mat-spinner>
          </div>
        </div>
        <ng-template #Dashboard>

          <div class="content">
            <app-top-bottom-performes [track]="track" [itemsCount]="track?.studentsCount"> </app-top-bottom-performes>

            <app-track-overview-performance-radar
              [title]="'PERFORMANCE DA TURMA NOS MÓDULOS'"
              [track]="track"
              [labels]="getRadarLabels()"
              [titleCallback]="getRadarTitleCallback.bind(this)"
              [dataset]="getRadarDataset()"
              [tooltipCallback]="getRadarTooltipCallback.bind(this)"
              [hasTotalPoints]="false">
            </app-track-overview-performance-radar>

            <app-track-overview-wrong-concepts [concepts]="track?.wrongConcepts"></app-track-overview-wrong-concepts>
          </div>
          <div class="student-grade-filters" *ngIf="hasFeatureCockpitMetrics">
            <button class="btn-test" (click)="openTrackRankingDialog()">
              Monte seu próprio ranking
            </button>
          </div>
          <app-student-grade [showRanking]="true" [students]="trackParticipation"></app-student-grade>
        </ng-template>

      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="isManager || isAdmin" [label]="tabLabel.MANAGER">
      <ng-template matTabContent>
        <div *ngIf="userProgress" class="content">
          <div class="stacked">

            <app-track-modules-progress
              [title]="'PERFORMANCE DA TURMA NOS MÓDULOS'"
              [track]="track"
              [labels]="getGanttProgressLabels()"
              [dataset]="getGanttProgressDataset()"
              [totalDays]="totalDays"
              [userProgress]="userProgress"
              [expectedProgress]="expectedProgress"
              [titleCallback]="getGantProgressTitleCallback.bind(this)"
              [tooltipCallback]="getGantProgressTooltipCallback.bind(this)"></app-track-modules-progress>

          </div>
        </div>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</div>
