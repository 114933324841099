import { Component, Input } from '@angular/core';
import { EventPreview } from '../../../models/previews/event.interface';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {

  @Input() readonly event: EventPreview;
  @Input() readonly showDate: boolean = true;

}
