import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { ImportExcelFilesComponent } from "src/app/shared/components/import-excel-files/import-excel-files.component";
import { ExcelService } from 'src/app/shared/services/excel.service';
import { mandatoryCollumnEnum } from './Enums/mandatoryCollumns';
import { IErroList } from '../../../shared/components/import-excel-files/Models/erro-list.interface';
import { IExemptionUsers } from './Models/exemption-users.interface';
import { ExemptionService } from '../../_services/exemption.service';

@Component({
  selector: 'app-exemption-user-dialog',
  templateUrl: '../../../shared/components/import-excel-files/import-excel-files.component.html',
  styleUrls: ['../../../shared/components/import-excel-files/import-excel-files.component.scss']
})
export class ExemptionUserDialogComponent extends ImportExcelFilesComponent {
  public pathTemplate: string = "./../../../../assets/download/PLANILHA_MODELO_IMPORTAÇÃO_ISENCOES.csv";
  public downloadName: string = "PLANILHA_MODELO_IMPORTAÇÃO_ALUNOS_PLANO_DE_ESTUDOS.xlsx";
  public showRadionButtons: boolean = false;
  public name = 'Isenções';
  public subtitle = '';
  public get mandatoryCollumns(): string[] {
    return [
      mandatoryCollumnEnum.name,
      mandatoryCollumnEnum.email,
      mandatoryCollumnEnum.moduleId,
      mandatoryCollumnEnum.exemptionUser,
      mandatoryCollumnEnum.reason,
      mandatoryCollumnEnum.userName
    ];
  }

  public loading: boolean = false;
  public erroList: any;
  public data: IExemptionUsers[] = [];
  constructor(_excelService: ExcelService,
    _snackBar: MatSnackBar,
    private _exemptionService: ExemptionService,
    private _dialogRef: MatDialogRef<ExemptionUserDialogComponent>) {
    super(_excelService, _snackBar);
  }


  public validateJson(contentJson: any, headers: any): IErroList[] {
    let index = 0;
    const erroList: IErroList[] = [];
    while (index < contentJson.length) {
      const excelContent = contentJson[index];

      if (isNil(excelContent[mandatoryCollumnEnum.name])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.name),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      if (isNil(excelContent[mandatoryCollumnEnum.email])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.email),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }
      if (isNil(excelContent[mandatoryCollumnEnum.exemptionUser])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.exemptionUser),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }
      if (isNil(excelContent[mandatoryCollumnEnum.moduleId])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.moduleId),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      }

      if (isNil(excelContent[mandatoryCollumnEnum.exemptionUser])) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.exemptionUser),
          row: excelContent['row'],
          description: 'Este campo não pode ser nulo'
        });
      } else if (this.normalizeString(excelContent[mandatoryCollumnEnum.exemptionUser]) !== 'sim' &&
        this.normalizeString(excelContent[mandatoryCollumnEnum.exemptionUser]) !== 'nao'
      ) {
        erroList.push({
          collumn: this.getCollum(mandatoryCollumnEnum.exemptionUser),
          row: excelContent['row'],
          description: 'Esperava receber sim ou não'
        });
      } else if (this.normalizeString(excelContent[mandatoryCollumnEnum.exemptionUser]) === 'sim') {
        if (isNil(excelContent[mandatoryCollumnEnum.reason])) {
          erroList.push({
            collumn: this.getCollum(mandatoryCollumnEnum.reason),
            row: excelContent['row'],
            description: 'Este campo não pode ser nulo'
          });
        }
      }

      index++;
    }
    return erroList;
  }
  public setValues(contentJson: any, headers: any): void {
    let index = 0;
    while (index < contentJson.length) {
      const excelContent = contentJson[index];
      this.data.push({
        email: excelContent[mandatoryCollumnEnum.email],
        name: excelContent[mandatoryCollumnEnum.name],
        userName: excelContent[mandatoryCollumnEnum.userName],
        canExemption: excelContent[mandatoryCollumnEnum.exemptionUser],
        moduleId: excelContent[mandatoryCollumnEnum.moduleId],
        reason: excelContent[mandatoryCollumnEnum.reason],
      });
      index++;
    }
  }
  public save(): void {
    this.loading = true;
    this._exemptionService.createUserExemption(this.data).subscribe(response => {
      this.loading = false;
      this._dialogRef.close();
    }, error => {
      this.loading = false;
      error.error.errors.forEach(err => {
        this.erroList.push({ description: err });
      });
    });
  }

  public dismiss() {
    this._dialogRef.close();
  }
}

