import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorsService extends NotificationClass {


  constructor(protected _snackBar: MatSnackBar, protected _injector: Injector) {
    super(_snackBar);
  }

  protected get _router(): Router { return this._injector.get(Router); }

}
