import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ValuationTestComponent } from './valuation-test.component';
import { ModuleSidebarModule } from '../../shared/components/module-sidebar/module-sidebar.module';
import { MaterialComponentsModule } from 'src/app/shared/material.module';
import { FormsModule } from '@angular/forms';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { CourseworkComponent } from './coursework/coursework.component';
import { ExamModule } from '../exam/exam.module';

@NgModule({
  declarations: [
    ValuationTestComponent,
    CourseworkComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ExamModule,
    MaterialComponentsModule,
    MarkdownToHtmlModule,
    ModuleSidebarModule
  ]
})
export class ValuationTestModule { }
