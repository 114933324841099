import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Event } from '../../../../../models/event.model';
import { EventSchedule } from '../../../../../models/event-schedule.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { UtilService } from '../../../../../shared/services/util.service';
import * as moment from 'moment/moment';
import { UserRelationalItem } from 'src/app/settings/users/user-models/user-relational-item';
import { SettingsUsersService } from 'src/app/settings/_services/users.service';
import { Observable, Subject } from 'rxjs';
import { cloneDeep, isNil } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material/dialog';
import { EventScheduleTypeEnum } from 'src/app/models/enums/event-schedule.enum';
import { User } from 'src/app/settings/users/user-models/user';
@Component({
  selector: 'app-new-event-date',
  templateUrl: './date.component.html',
  styleUrls: ['../new-event-steps.scss', './date.component.scss']
})
export class NewEventDateComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() event$: Observable<Event>;
  public event: Event;
  @Output() setEventDates = new EventEmitter();
  @Input() updateSchedules: Function;
  @Input() deleteSchedule: Function;

  public formGroup: FormGroup;
  public users: Array<Array<User>> = [];
  public userName: string = '';
  private _selectedUser: any;
  public locations: UserRelationalItem[];
  public selectedLocations: string[];
  private _unsubscribeAll = new Subject<any>();
  public eventScheduleType = EventScheduleTypeEnum;

  constructor(
    protected _snackBar: MatSnackBar,
    private _utilService: UtilService,
    private _usersService: SettingsUsersService,
    private _dialog: MatDialog
  ) {
    super(_snackBar);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.event$) {
      this.event$.pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(eventItems => {
        this.event = cloneDeep(eventItems);
        this.users = new Array(this.event.schedules.length);
        this.selectedLocations = [];
        if (this.formGroup) {
          this.updateComponent(eventItems.schedules);
        } else {
          this.formGroup = this._createFormGroup(eventItems);
        }
        this._getAllLocations();
      });
    }
  }

  public updateComponent(schedules: EventSchedule[]) {
    let eventSchedules = this.formGroup.get('schedules') as FormArray;

    eventSchedules ? eventSchedules.clear() : eventSchedules = new FormArray([]);

    const selectedLocations = [];

    if (!schedules || schedules.length === 0) {
      eventSchedules.push(this._createScheduleForm(null));
    } else {
      schedules.forEach((sch) => {
        if (sch.location) {
          selectedLocations.push(sch.location.id);
        } else {
          selectedLocations.push('');
        }
        eventSchedules.push(this._createScheduleForm(sch));
      });
      this.selectedLocations = selectedLocations;
    }
  }

  public nextStep(): void {
    const eventDates = this.formGroup.getRawValue();
    eventDates.schedules = eventDates.schedules.map((schedule, index) => {
      schedule = this.normalizeSchedule(index);
      schedule.location = schedule.location ? {
        id: schedule.location.id
      } : null;
      return schedule;
    });

    if (this.formGroup.valid) {
      eventDates.schedules.map((sch) => {
        const date = moment(sch.eventDate);
        sch.eventDate = date.utc().format('YYYY-MM-DDTHH:mm:ssZ');

        return sch;
      });

      this.setEventDates.emit(eventDates.schedules);
    } else {
      this.notify('Formulário Inválido');
    }
  }

  public scheduleUpdate(index: number) {

    if (this.formGroup.get('schedules')['controls'][index].pristine) {
      return;
    }

    if (this.formGroup.invalid) {
      this.notify('Formulário Inválido');
      return;
    }
    const scheduleNormalized = this.normalizeSchedule(index);

    if (scheduleNormalized.subscriptionEndDate > scheduleNormalized.eventDate) {
      return this.notify('A data término das inscrições não pode ser maior do que a de inicio do evento');
    }

    const date = moment(scheduleNormalized.eventDate);

    scheduleNormalized.eventDate = date.utc().format('YYYY-MM-DDTHH:mm:ssZ') as any;

    const dateSubscriptionEndDate = moment(scheduleNormalized.subscriptionEndDate);
    scheduleNormalized.subscriptionEndDate = dateSubscriptionEndDate.utc().format('YYYY-MM-DDTHH:mm:ssZ') as any;
    this.updateSchedules([scheduleNormalized]);

    if (this.formGroup.get('schedules')['controls'][index]) {
      this.formGroup.get('schedules')['controls'][index].markAsPristine();
    }
  }

  public addSchedule(): void {
    const schedules = this.formGroup.get('schedules') as FormArray;
    schedules.push(this._createScheduleForm());
    this.users.push([]);
  }

  private _createFormGroup(event: Event): FormGroup {
    return new FormGroup({
      'schedules': this._setSchedulesFormArray(event)
    });
  }

  private _setSchedulesFormArray(event: Event): FormArray {

    if (event && event.schedules && event.schedules.length > 0) {

      for (let index = 0; index < event.schedules.length; index++) {
        if (event.schedules[index].location) {
          this.selectedLocations.push(event.schedules[index].location.id);
        } else {
          this.selectedLocations.push('');
        }
      }
      return new FormArray(
        event.schedules.map((sc) => this._createScheduleForm(sc))
      );
    } else {
      return new FormArray([
        this._createScheduleForm()
      ]);
    }
  }

  private _createScheduleForm(schedule: EventSchedule = null): FormGroup {

    return new FormGroup({
      'id': new FormControl(schedule ? schedule.id : null),
      'eventId': new FormControl(this.event.id),
      'subscriptionStartDate': new FormControl(
        schedule ? schedule.subscriptionStartDate : '', [Validators.required]
      ),
      'subscriptionEndDate': new FormControl(
        schedule ? schedule.subscriptionEndDate : '', [Validators.required]
      ),
      'endHour': new FormControl(
        schedule && schedule.subscriptionEndDate ?
          moment(schedule.subscriptionEndDate).format('HH:mm') : '23:59', [Validators.required]
      ),
      'forumStartDate': new FormControl(
        schedule ? schedule.forumStartDate : ''
      ),
      'forumEndDate': new FormControl(
        schedule ? schedule.forumEndDate : ''
      ),
      'published': new FormControl(
        schedule ? schedule.published : '', [Validators.required]
      ),
      'eventDate': new FormControl(
        schedule ? schedule.eventDate : '', [Validators.required]
      ),
      'startHour': new FormControl(
        schedule && schedule.eventDate ?
          moment(schedule.eventDate).format('HH:mm') : '', [Validators.required]
      ),
      'duration': new FormControl(
        schedule && schedule.duration ?
          this._utilService.formatDurationToHour(schedule.duration) : '00:00:00',
        [Validators.required]
      ),
      'webinarUrl': new FormControl(
        schedule ? schedule.webinarUrl : ''
      ),
      'location': new FormControl(
        schedule && !isNil(schedule.location) ? schedule.location.id : null
      ),
      'applicationLimit': new FormControl(
        schedule ? schedule.applicationLimit : ''
      ),
      'eventScheduleType': new FormControl(
        schedule ? schedule.eventScheduleType : EventScheduleTypeEnum.DefaultScheduleType
      ),
      'commercialArea': new FormControl(
        schedule ? schedule.commercialArea : ''
      ),
      'productAddressed': new FormControl(
        schedule ? schedule.productAddressed : ''
      ),
      'productClass': new FormControl(
        schedule ? schedule.productClass : ''
      ),
      'trainingType': new FormControl(
        schedule ? schedule.trainingType : ''
      ),
      'instructor': new FormControl(
        schedule ? typeof schedule.instructor === 'string' ? schedule.instructor : schedule?.instructor?.name : ''
      ),
      'instructorId': new FormControl(
        schedule ? schedule.instructorId : ''
      )
    });
  }

  public normalizeSchedule(index: number): EventSchedule {

    const schedules = this.formGroup.get('schedules') as FormArray;
    const schedule = (schedules.controls[index] as FormGroup).getRawValue();
    schedule.eventId = this.event.id;
    schedule.location = this.locations.find(x => x.id === schedule.location);
    schedule.duration = this._utilService.getDurationFromFormattedHour(
      schedule.duration
    );


    schedule.subscriptionEndDate = new Date(schedule.subscriptionEndDate);

    schedule.endHour = schedule.endHour.split(':').join('');
    const endHours = schedule.endHour.substring(0, 2);
    schedule.subscriptionEndDate.setHours(parseInt(endHours, 10));

    const endMinutes = schedule.endHour.substring(2, 4);
    schedule.subscriptionEndDate.setMinutes(parseInt(endMinutes, 10));

    schedule.eventDate = new Date(schedule.eventDate);
    schedule.startHour = schedule.startHour.split(':').join('');

    const startHours = schedule.startHour.substring(0, 2);
    schedule.eventDate.setHours(parseInt(startHours, 10));

    const startMinutes = schedule.startHour.substring(2, 4);
    schedule.eventDate.setMinutes(parseInt(startMinutes, 10));

    return schedule;
  }

  public async scheduleDelete(index: number): Promise<void> {
    const schedules = this.formGroup.get('schedules') as FormArray;
    const scheduleForm = (schedules.controls[index] as FormGroup);
    const schedule: EventSchedule = scheduleForm.getRawValue();
    schedule.eventId = this.event.id;

    if (!schedule.id) {
      schedules.removeAt(index);
      return;
    }

    const scheduleNormalized = this.normalizeSchedule(index);
    await this.deleteSchedule(scheduleNormalized);
  }

  public confirmRemoveSchedule(index: number): void {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: 'Tem certeza que deseja remover esta data?' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result)
        this.scheduleDelete(index);
    });
  }

  private _getAllLocations(): void {
    this._usersService.getAllLocations().subscribe((response) => {
      this.locations = response.data;
    }, (error) => this.notify(this.getErrorNotification(error)));

  }

  public changeLocation(locationId: string, scheduleIndex: number) {
    const formArray = this.formGroup.get('schedules') as FormArray;
    const schedule: EventSchedule = (formArray.controls[scheduleIndex] as FormGroup).getRawValue();
    schedule.location = this.locations.find(x => x.id === locationId);
  }

  public triggerUserSearch(searchValue: string, index: number) {
    if (searchValue && searchValue.trim() !== '') {
      this._loadUsers(searchValue, index);

      this._selectedUser = { 'name': searchValue };
      this.userName = searchValue;
    }
  }

  private _loadUsers(searchValue: string = '', index: number): void {
    this._usersService.getProfessors(searchValue).subscribe(response => {
      this.users[index] = response.data;
    });
  }

  public addUser(user: User, index: number) {
    const formArray = this.formGroup.get('schedules') as FormArray;
    const control = formArray.controls[index] as FormGroup;
    control.get('instructor').setValue(user.name);
    control.get('instructor').markAsDirty();
    control.get('instructorId').setValue(user.id);
    control.get('instructorId').markAsDirty();
    this._selectedUser = user;
    this.userName = user.name;
    this.users[index] = [];
  }
}
