import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValuationTest, ValuationTestQuestionTypeEnum } from 'src/app/models/valuation-test.interface';
import { NotificationClass } from '../../classes/notification';
import {
  QuestionAndAnswer,
  ValuationTestQuestionType,
} from './models/question';

@Component({
  selector: 'app-questions-and-answers-dialog',
  templateUrl: './questions-and-answers.dialog.html',
  styleUrls: ['./questions-and-answers.dialog.scss'],
})
export class QuestionsAndAnswersDialogComponent
  extends NotificationClass
  implements OnInit {
  valuationTest: ValuationTest;
  studentTest: any;
  finalGrade: string;

  questionsAndAnswers: QuestionAndAnswer[] = [];
  valuationTitle: string;
  valuationTestTypeEnum = ValuationTestQuestionTypeEnum;

  constructor(
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<QuestionsAndAnswersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(_snackBar);
    this.valuationTest = data.valuationTest;
    this.studentTest = data.test;
  }

  ngOnInit(): void {
    this._organizeQuestion();
    this.finalGrade = this._setGrade(this.questionsAndAnswers);
  }


  isMultipleChoice(question: QuestionAndAnswer): boolean {
    return question.type === ValuationTestQuestionType.Discursive;
  }

  isMultipleDiscursive(question: QuestionAndAnswer): boolean {
    return question.type === ValuationTestQuestionType.MultipleChoice;
  }

  getRightAnswer(selectedQuestion: QuestionAndAnswer) {
    if (
      this.valuationTest &&
      this.valuationTest.testQuestions &&
      this.valuationTest.testQuestions.length > 0
    ) {
      const question = this.valuationTest.testQuestions.find(
        (q) => q.id === selectedQuestion.questionId
      );
      if (question.options && question.options.length > 0) {
        const rightAnswer = question.options.find((x) => x.correct);
        return rightAnswer.text;
      }
    }
  }

  private _setGrade(answers: QuestionAndAnswer[]) {
    if (answers.some((a) => a.type ==4)) {
      return 'Obrigado pela avaliação!';
    }
    if (answers.every((a) => a.grade != null)) {
      return (answers.reduce((sum, a) => sum + a.grade, 0) / 10)
        .toFixed(2)
        .replace('.', ',');
    } else {
      return 'Aguardando todas as respostas serem avaliadas';
    }
  }

  getQuestionTitle(selectedQuestion: QuestionAndAnswer) {
    if (
      this.valuationTest &&
      this.valuationTest.testQuestions &&
      this.valuationTest.testQuestions.length > 0
    ) {
      const question = this.valuationTest.testQuestions.find(
        (q) => q.id === selectedQuestion.questionId
      );
      return question.title;
    }
  }

  private _organizeQuestion() {
    if (
      !this.studentTest ||
      !this.studentTest.answered ||
      !this.studentTest.answers
    ) {
    } else {
      this.valuationTitle = this.studentTest.title;
      this.studentTest.answers.forEach((answer) => {
        if (answer) {
          const currentQuestion = this.valuationTest.testQuestions.find(question => question.id === answer.questionId);
          if (currentQuestion !== null) answer.canceled = currentQuestion.canceled;
          this.questionsAndAnswers.push(new QuestionAndAnswer(answer));
        }
      });
    }
  }
}
