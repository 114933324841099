import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../../../../shared/classes/notification';
import { UtilService } from '../../../../../shared/services/util.service';
import { EcommerceProduct } from 'src/app/models/ecommerce-product.model';
import { Module } from 'src/app/models/module.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-module-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['../new-module-steps.scss', './ecommerce.component.scss']
})
export class ModuleEcommerceComponent extends NotificationClass implements OnInit, OnDestroy {

  @Input() module$: Observable<Module>;
  public module: Module;
  @Output() manageEcommerceInfo = new EventEmitter();

  public formGroup: FormGroup;

  private _unsubscribeAll = new Subject<any>();

  constructor(
    protected _snackBar: MatSnackBar,
    private _utilService: UtilService
  ) {
    super(_snackBar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (this.module$) {
      this.module$.pipe(
        takeUntil(this._unsubscribeAll),
      ).subscribe(moduleItems => {
        this.module = moduleItems;
        if (this.formGroup && moduleItems.ecommerceProducts) {
          this.updateComponent(moduleItems.ecommerceProducts);
        } else {
          this.formGroup = this._createFormGroup();
          this._fillForm(moduleItems);
        }
      });
    }
  }

  public updateComponent(ecommerceProducts: EcommerceProduct[]) {
    let eProducts = this.formGroup.get('ecommerceProducts') as FormArray;

    eProducts ? eProducts.clear() : eProducts = new FormArray([]);

    ecommerceProducts.forEach((ecommerce) => {
      eProducts.push(this._createEcommerceProductForm(ecommerce));
    });
  }

  public addEcommerceId(): void {
    const products = this.formGroup.get('ecommerceProducts') as FormArray;
    products.push(
      this._createEcommerceProductForm()
    );
  }

  public removeEcommerceProduct(index: number): void {
    const products = this.formGroup.get('ecommerceProducts') as FormArray;
    products.removeAt(index);
  }

  public nextStep(): void {
    if (this.formGroup.valid) {
      const formContent = this.formGroup.getRawValue();

      this._checkProducts( formContent.ecommerceProducts ) ?
        this.manageEcommerceInfo.emit( formContent.ecommerceProducts ) :
        this.notify('Há Ids de Ecommerce duplicado');

    } else {
      this.formGroup = this._utilService.markFormControlsAsTouch( this.formGroup );
      this.notify('Por favor, preencha todos os campos obrigatórios');
    }
  }

  private _createFormGroup(): FormGroup {
    return new FormGroup({
      'ecommerceProducts': new FormArray([])
    });
  }

  private _createEcommerceProductForm(ecommerceProduct: EcommerceProduct = null): FormGroup {
    return new FormGroup({
      'ecommerceId': new FormControl(
        ecommerceProduct ? ecommerceProduct.ecommerceId : '', [Validators.required]
      ),
      'usersAmount': new FormControl(
        ecommerceProduct ? ecommerceProduct.usersAmount : '', [Validators.required]
      ),
      'disableEcommerce': new FormControl(
        ecommerceProduct ? ecommerceProduct.disableEcommerce : '', [Validators.required]
      ),
      'price': new FormControl(
        ecommerceProduct ? ecommerceProduct.price : '', [Validators.required]
      ),
      'disableFreeTrial': new FormControl(
        ecommerceProduct ? ecommerceProduct.disableFreeTrial : '', [Validators.required]
      ),
      'linkEcommerce': new FormControl(
        ecommerceProduct ? ecommerceProduct.linkEcommerce : '', [Validators.required]
      ),
      'linkProduct': new FormControl(
        ecommerceProduct ? ecommerceProduct.linkProduct : '', [Validators.required]
      ),
      'subject': new FormControl(
        ecommerceProduct ? ecommerceProduct.subject : '', [Validators.required]
      ),
      'hours': new FormControl(
        ecommerceProduct ? ecommerceProduct.hours : '', [Validators.required]
      )
    });
  }

  private _fillForm(module: Module): void {
    if (module.ecommerceProducts) {
      const products = this.formGroup.get('ecommerceProducts') as FormArray;
      module.ecommerceProducts.forEach(product => {
        products.push(
          this._createEcommerceProductForm(product)
        );
      });
    }
  }

  private _checkProducts(formContent: Array<EcommerceProduct>): boolean {
    return formContent.every(outerProd => formContent.filter(innerProd => innerProd.ecommerceId === outerProd.ecommerceId).length === 1);
  }

  public cleanLevel(index: number): void {
    const formArray = this.formGroup.get('ecommerceProducts') as FormArray;
    const formControl = formArray.controls[index];

    if (formControl.get('disableFreeTrial').value) {
      formControl.get('price').setValue('');
      formControl.get('price').disable();
    } else {
      formControl.get('price').enable();
    }
  }
}
