import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TeamsService } from 'src/app/settings/teams/services/teams.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Program } from 'src/app/settings/programs/Models/program.model';
import { NotificationClass } from 'src/app/shared/classes/notification';
import { chain, differenceWith, isArray, isEqual, isNil } from 'lodash';
import { TeamOption } from 'src/app/settings/teams/services/viewModels/paged-team-options.model';
import { Team, TeamStudents } from 'src/app/settings/teams/Models/team.model';

@Component({
  selector: 'app-settings-program-teams',
  templateUrl: './program-teams.component.html',
  styleUrls: ['./program-teams.component.scss']
})
export class ProgramTeamsComponent extends NotificationClass implements OnInit {


  private _form: FormGroup = this._createForm();
  public get form(): FormGroup { return this._form; }
  public set form(value: FormGroup) {
    this._form = value;
    this.formChange.emit(this.form);
  }

  @Output() formChange = new EventEmitter<FormGroup>();

  private _value: Partial<Program>;
  public get value(): Partial<Program> { return this._value; }
  public set value(value: Partial<Program>) {
    this._value = value;
    if (this._value) {
      const mappedTeam = this._value.teams.map(team => ({
        id: team.teamId,
        studentsCount: team.studentsCount,
        title: team.title
      } as TeamOption));

      this.countAllStudent(mappedTeam);

      this._setSelectedTeams(mappedTeam);
    }
  }

  public teamsOptions: TeamOption[] = [];
  public selectedTeams: TeamOption[] = [];
  public isLoading = false;
  public selectedTeam: Team;
  public allTeamsCount: number;

  private _temporarySelectedItems: TeamOption[] = [];
  private _temporarySelectedUsers: TeamStudents[] = [];

  constructor(
    protected _snackBar: MatSnackBar,
    private _teamsService: TeamsService,

    private _formBuilder: FormBuilder
  ) {
    super(_snackBar);
  }


  ngOnInit() {
    this._loadTeams();
  }


  public handleSelectionChange(evt: any) {
    const items = isArray(evt) ? [...evt] : [evt];
    this._temporarySelectedItems = items;
  }

  public handleSelectOpenedChange(isOpened: boolean) {
    if (!isOpened) {
      this._setSelectedTeams(this._temporarySelectedItems);
    }
    this._temporarySelectedItems = [];
  }

  public handleSearchItem(teamTitle: string) {
    this._loadTeams(teamTitle);
  }

  public removeSelectedTeam(index: number) {
    this.selectedTeams.splice(index, 1);
    this.countAllStudent(this.selectedTeams);
    this.selectedTeam = undefined;
    this._updateFormValue();
  }

  public selectTeam(teamOption: TeamOption) {
    this._loadTeam(teamOption.id);
  }

  private _updateFormValue() {
    if (this.selectedTeams.length)
      this.form.get('teamIds').setValue(this.selectedTeams.map(value => value.id));
  }

  private _setSelectedTeams(options: TeamOption[]) {
    const temporarySelectedItems = chain(this.selectedTeams || [])
      .concat(...options)
      .filter(item => !!item.id)
      .uniqBy(item => item.id)
      .value();

    const itensToUpdate = differenceWith(temporarySelectedItems, this.selectedTeams, isEqual);
    this.selectedTeams.push(...itensToUpdate);
    this.countAllStudent(this.selectedTeams);

    if (this.selectedTeams.length) {
      if (isNil(this.selectedTeam)) this.selectTeam(this.selectedTeams[0]);
      this._updateFormValue();
    }
  }

  private _loadTeams(teamTitle: string = '') {
    const PAGE_SIZE = 10;
    const PAGE = 1;

    this._teamsService.getTeamOptions({ pageSize: PAGE_SIZE, page: PAGE, teamTitle: teamTitle }).subscribe(response => {
      this.teamsOptions = response.data.teamOptions;
    });
  }

  private _createForm(value = null) {
    return this._formBuilder.group({ 'teamIds': [] });
  }

  private _loadTeam(teamId: string) {
    this._teamsService.getTeamById(teamId).subscribe(response => {
      this.selectedTeam = response.data;
      this._temporarySelectedUsers = this.selectedTeam.students;
    });
  }

  public selectAllTeam() {
    this._teamsService.getStudentsTeamByIds(this.selectedTeams.map(team => team.id)).subscribe(response => {
      this.selectedTeam = {
        name: 'Todas as Turmas',
        students: response.data,
        countStudents: (response.data as TeamStudents[]).length
      } as Team;

      this._temporarySelectedUsers = this.selectedTeam.students;
    });
  }

  public search(searchTem: string) {
    if (isNil(searchTem) || searchTem === '') {
      this.selectedTeam.students = this._temporarySelectedUsers;
      return;
    }

    this.selectedTeam.students = this.selectedTeam.students.filter(student => student.name.toLowerCase().includes(searchTem.toLowerCase()));
  }

  public countAllStudent(data: TeamOption[]) {
    if (data.length > 0)
      this.allTeamsCount = data.map(team => team.studentsCount).reduce((acc, data) => acc + data);
  }
}
