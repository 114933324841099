<div class="inner-content">
   <div class="content">
    <div>
      <p class="sub-title bottom">Criar uma pergunta *</p>
      <div class="editor-wrapper">
        <div id="htmlEditor"></div>
      </div>
    </div>
    <div class="info">
      <p class="sub-title">Adicione os conceitos avaliados na pergunta *</p>
      <app-list-search
        placeholder="Digite..."
        (triggerSearch)="triggerConceptSearch($event)"
      ></app-list-search>
      <ul class="user-list"
        *ngIf="concepts && concepts.length > 0"
        (clickOutside)="resetConceptSearch()" >
        <li *ngFor="let concept of concepts"
          (click)="addConcept(concept)" >
          <span>{{ concept }}</span>
        </li>
      </ul>
      <div class="chips-container" [ngClass]="{ 'overflow-scroll': question.concepts && question.concepts.length > 2 }">
        <div class="chips"
          *ngIf="(concepts && concepts.length === 0) && (question.concepts && question.concepts.length > 0)">
          <p *ngFor="let concept of question.concepts" >
            {{ concept }}
            <span (click)="removeSelectedConcept(concept)">X</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="explanation">
    <p class="sub-title ">Defina as possiveis respostas *</p>
    <p>- Apenas 1 pergunta deve estar certa</p>
    <p>- Indique quais conceitos estão errados em cada resposta</p>
    <p>- Min. 4 perguntas</p>
  </div>

  <div class="answers">
    <table>
      <thead>
        <tr>
          <th style="width: 15%;">Tipo de resposta</th>
          <th style="width: 35%;">Digite a resposta</th>
          <th style="width: 50%;">Selecione os conceitos errados</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let answer of question.answers; let index = index">
          <td>{{getAnswerType(answer)}}</td>
          <td>
            <textarea matInput placeholder="Resposta" [(ngModel)]="answer.text" required></textarea>
          </td>
          <td>
            <ng-container *ngIf="answer.type === 1">
              <p>Todos os conceitos estão corretos</p>
            </ng-container>
            <ng-container *ngIf="answer.type !== 1">
              <app-list-search
                placeholder="Digite..."
                (triggerSearch)="triggerConceptSearch($event, answer.type)"
              ></app-list-search>
              <ul class="user-list user-list-x"
                *ngIf="getConcepts(answer.type) && getConcepts(answer.type).length > 0"
                (clickOutside)="resetConceptSearch(answer.type)" >
                <li *ngFor="let concept of getConcepts(answer.type)"
                  (click)="addConcept(concept, answer.type)" >
                  <span>{{ concept }}</span>
                </li>
              </ul>
              <div class="chips-container chips-container-x"
              [ngClass]="{ 'overflow-scroll': answer.concepts && answer.concepts.length > 2 }">
                <div class="chips chips-x"
                  *ngIf="answer.concepts && answer.concepts.length > 0"
                  [ngClass]="{ 'hide': !showAllSelectedConcepts }" >
                  <p *ngFor="let concept of answer.concepts" >
                    {{ concept }}
                    <span (click)="removeSelectedConcept(concept, answer.type)">X</span>
                  </p>
                </div>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div class="card-footer">
    <button class="btn-outline" (click)="goBack.emit()">
      Sair
    </button>
    <button class="btn-test" (click)="save()">
      Atualizar e ir para avaliações
    </button>
  </div>
</div>  