<h1>
  {{ newTrack && newTrack.title ? newTrack.title : 'NOVA TRILHA' }}
</h1>
<div class="subheader">
  <div class="draft-title" [ngClass]="{ 'opened': showDraftOptions, 'disabled': !newTrack?.isDraft }">
    <span (click)="showDraftOptions = !showDraftOptions" *ngIf="newTrack?.isDraft">
      MODIFICAÇÕES EM RASCUNHO &nbsp;&nbsp;&nbsp;&nbsp;
      <img src="./assets/img/arrow-back-white.png" />
    </span>
    <span *ngIf="!newTrack?.isDraft">
      SEM MODIFICAÇÕES
    </span>
    <ul class="draft-options" *ngIf="showDraftOptions">
      <li (click)="publishDraftChanges()">
        PUBLICAR ALTERAÇÕES
      </li>
      <li (click)="rejectDraftChanges()">
        REJEITAR ALTERAÇÕES
      </li>
    </ul>
  </div>
</div>
<div class="new-track inner-content">
  <mat-horizontal-stepper #stepper [ngClass]="{ 'no-action': !allowEditing }"
    (selectionChange)="stepChanged($event, false)">
    <mat-step>
      <ng-template matStepLabel>
        Informações
      </ng-template>
      <app-new-track-track-info *ngIf="newTrack" #trackInfo [track$]="this._newTrack"
        (setTrackInfo)="setTrackInfo($event)"></app-new-track-track-info>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Vídeos
      </ng-template>
      <app-new-track-video *ngIf="newTrack" #trackVideo [track$]="this._newTrack" [isCourse]="!newTrack.published"
        (setTrackVideo)="setTrackVideo($event)"></app-new-track-video>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Módulos e Eventos
      </ng-template>
      <app-new-track-modules-events *ngIf="newTrack" #modulesEvents [track$]="this._newTrack"
        (addModulesAndEvents)="addModulesAndEvents($event)"></app-new-track-modules-events>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Peso Itens
      </ng-template>
      <app-new-track-modules-weight *ngIf="newTrack" #modulesEventsWeight [track]="this._newTrack"
        (addModulesEventsWeight)="addModulesEventsWeight($event)"></app-new-track-modules-weight>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Liberação Itens
      </ng-template>
      <app-new-track-modules-dates *ngIf="newTrack" #modulesEventsAvailability [track]="this._newTrack"
        (error)="handleErrorModulesEventsAvailability()" (addModulesEventsDate)="addModulesEventsDate($event)">
      </app-new-track-modules-dates>
    </mat-step>

    <mat-step [completed]="allowEditing">
      <ng-template matStepLabel>
        Datas Relevantes
      </ng-template>
      <app-new-track-relevant-dates *ngIf="newTrack" #relevantDates [track$]="this._newTrack"
        (manageRelevantDates)="manageRelevantDates($event)"></app-new-track-relevant-dates>
    </mat-step>

    <mat-step [completed]="allowEditing" *ngIf="hasEcommerceIntegration">
      <ng-template matStepLabel>
        E-commerce
      </ng-template>
      <app-new-track-ecommerce *ngIf="newTrack" #ecommerce [track$]="this._newTrack"
        (manageEcommerceInfo)="manageEcommerceInfo($event)"></app-new-track-ecommerce>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="footer">
    <button class="previous" type="button" mat-button [hidden]="stepIndex === 0" (click)="saveStep(true, false)">
      Voltar
    </button>
    <button class="save" type="button" mat-button (click)="saveStep(false, false)">
      Salvar {{ (!hasEcommerceIntegration && stepIndex === lastStep - 1) || stepIndex === lastStep ?
         'e Finalizar' : '' }}
    </button>
    <button class="next" type="button" mat-button [disabled]="loading"
      [hidden]="(!hasEcommerceIntegration && stepIndex === lastStep - 1) || stepIndex === lastStep"
      (click)="saveStep(true, true)">
      continuar e salvar
    </button>
  </div>
</div>
